export const buttonGroupStyles = {
  root: {
    disableElevation: true,
    variant: 'outlined',
  },
};

export const buttonItemStyles = {
  outlined: {
    fontFamily: 'Open Sans',
    background: '#fff',
    color: "#595959",
    height: '25px',
    '& $wrapper': {
      opacity: 1,
    },
  },
  //Chart zoom control buttons
  text: {
    fontFamily: 'Open Sans',
    background: '#fff',
    size: 'medium',
    color: "#475D7A",
    height: '25px',
    minWidth : '32px',
    opacity: 0.9,
    '& $wrapper': {
      opacity: 1,
    },
  },
};
