import { api } from '../jcs';

class EventProfileService {

	constructor(httpService, $q) {
		'ngInject';

		this.httpService = httpService;
		this.$q = $q;

		this.map = {};
		this.is_loading_event_profile = false;
	}

	isLoadingEventProfile(){
		return this.is_loading_event_profile;
	}

	getEventProfile(uuid) {

		var deferred = this.$q.defer();
		var self = this;

		// do we already have the info in our cache?
		if (this.map.hasOwnProperty(uuid)){

			deferred.resolve(this.map[uuid]);

		} else {

			this.is_loading_event_profile = true;

			this.httpService.get(api.url + '/streamprofiles/' + uuid, {withCredentials: true},
				function (response) { // success

					self.map[uuid] = response.data;
					deferred.resolve(response.data);

				},
				function (reason) { // error

					deferred.reject(reason);

				},
				function () { // always called

					self.is_loading_event_profile = false;

				});

		}

		return deferred.promise;
	}
}

export default EventProfileService;
