import { google_charts_url } from '../jcs';
import lazyload from 'little-loader';

class GoogleChartsService {
	constructor() {
		this.hasFrameworkBeenLoaded = false;
	}

	load(callback) {
		if (this.hasFrameworkBeenLoaded) {
			callback();
		} else {
			lazyload([google_charts_url], () => {
				this.hasFrameworkBeenLoaded = true;
				callback();
			});
		}
	}
}

export default GoogleChartsService;
