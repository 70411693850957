import 'angular-route';
import angular from 'angular';
import { react2angular } from 'react2angular';
import router from './module.eventanalytics.routes';
import commonServicesModule from '../../services';

// react components
import { EventAnalytics } from '../../../../src/pages';

export const eventAnalyticsModule = {
  name: 'eventanalytics',
  controllers: {
    default: 'eventanalyticsCtrl'
  },
  routes: {
    eventanalytics: '/event-analytics',
    eventanalyticsId: '/event-analytics/:eventId',
  }
};

export default angular
  .module(eventAnalyticsModule.name, ['ngRoute', commonServicesModule])
  .config(router)
  .component('eventAnalytics', react2angular(EventAnalytics, ['customerId', 'eventId', 'auth', 'ldFlags']))
  .controller(eventAnalyticsModule.controllers.default, require('./eventanalyticsCtrl')).name;