export class BitReader {
  private bitIndex = 0;
  private buffer: Uint8Array;

  constructor(buffer: Uint8Array) {
    this.buffer = buffer;
  }

  read(numBits: number): number {
    numBits |= 0;

    if (numBits <= 0) {
      throw new Error("numBits must be > 0");
    }
    if (this.bitIndex + numBits > this.buffer.length * 8) {
      throw new Error(`Reading ${numBits} bits reads past the end`);
    }

    let result = 0;

    for (let i = this.bitIndex; i < this.bitIndex + numBits; ++i) {
      result |= (this.buffer[(i / 8) | 0] >>> (7 - (i % 8))) & 1;
      if (i !== this.bitIndex + numBits - 1) {
        result <<= 1;
      }
    }

    this.bitIndex += numBits;
    return result;
  }
}
