import { ChartTypeRegistry, TooltipItem } from 'chart.js';
import Chart from 'chart.js/auto';
import startCase from 'lodash/startCase';
import PropTypes from 'prop-types';
import React, { FC, useEffect, useRef } from 'react';
import { barChartDataType } from '../../../pages/EventAnalytics/types';
import { legendItemType } from '../types';
import {
  barChartBoxSpanStyle,
  BarChartCanvas,
  BarChartLegendList,
  BarChartLegendWrapper,
  BarChartWrapper,
} from './bar-chart.styles';
import { getBarChartProps, getStackedBarChartProps } from './utils';
export interface BarChartProps {
  data: barChartDataType;
  stacked?: boolean;
  label?: string;
  tooltipLabel: string;
}

const BarChart: FC<BarChartProps> = ({ data, label, stacked, tooltipLabel }): JSX.Element => {
  const barChartRef = useRef<Chart>(null);
  const barChartObjectRef = useRef<Chart>(null);
  const barChartLegendRef = useRef<HTMLUListElement>(null);
  const dataObject = stacked ? getStackedBarChartProps(data) : getBarChartProps(data, false, label);

  const chartOptions = {
    interaction: {
      mode: 'point',
    },
    maintainAspectRatio: false,
    animation: false,
    scales: {
      y: {
        ticks: {
          precision: 0,
          beginAtZero: true,
          fontFamily: 'Verdana',
          callback: function (label: number) {
            return Math.abs(label) > 999 ? Number(label / 1000).toFixed(1) + 'K' : label;
          },
        },
        stacked: true,
      },
      x: {
        grid: {
          display: false,
        },
        stacked: true,
        ticks: {
          fontFamily: 'Verdana',
          autoSkip: true,
          minRotation: 0,
          maxRotation: 45,
        },
      },
    },
    plugins: {
      barChartLegend: {
        // ID of the container to put the legend in
        containerID: 'bar-chart-legend',
      },
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
        position: 'nearest',
        callbacks: {
          label: (tooltipItem: TooltipItem<keyof ChartTypeRegistry>) => {
            return ` ${startCase(tooltipItem.dataset.label) || ''} : ${
              tooltipItem.dataset.data[tooltipItem.dataIndex]
            } ${tooltipLabel}`;
          },
        },
      },
    },
  };

  const htmlLegendPlugin = {
    id: 'barChartLegend',
    afterUpdate(chart: Chart) {
      const ul = barChartLegendRef.current;
      if (!ul) {
        return;
      }
      ul.style.flexWrap = 'wrap';
      ul.style.justifyContent = 'space-between';

      // Remove old legend items
      while (ul.firstChild) {
        ul.firstChild.remove();
      }
      // Reuse the built-in legendItems generator
      const items = chart.options.plugins.legend.labels.generateLabels(chart);

      items.forEach((item: legendItemType, index: number) => {
        const li = document.createElement('li');
        li.style.alignItems = 'center';
        li.style.cursor = 'pointer';
        li.style.display = 'flex';
        li.style.flexDirection = 'row';
        li.style.marginLeft = '10px';

        li.onclick = () => {
          chart.setDatasetVisibility(item.datasetIndex, !chart.isDatasetVisible(item.datasetIndex));
          chart.update();
        };
        // Color box
        const boxSpan = document.createElement('span');
        boxSpan.style.background = dataObject.datasets[index].borderColor;
        boxSpan.style.borderColor = item.strokeStyle;
        boxSpan.style.borderWidth = item.lineWidth + 'px';
        boxSpan.style.display = barChartBoxSpanStyle.display;
        boxSpan.style.height = barChartBoxSpanStyle.height;
        boxSpan.style.marginRight = barChartBoxSpanStyle.marginHight;
        boxSpan.style.width = barChartBoxSpanStyle.width;
        boxSpan.style.opacity = item.hidden ? '0.4' : '1';
        boxSpan.style.borderRadius = barChartBoxSpanStyle.borderRadius;

        // Text
        const textContainer = document.createElement('p');
        textContainer.style.color = item.fontColor;
        textContainer.style.fontWeight = 'bold';
        textContainer.style.margin = '0 0 0 5px';
        textContainer.style.padding = '0';
        textContainer.style.textDecoration = item.hidden ? 'line-through' : '';
        textContainer.style.opacity = item.hidden ? '0.4' : '1';

        const text = document.createTextNode(startCase(item.text));
        textContainer.appendChild(text);

        li.appendChild(boxSpan);
        li.appendChild(textContainer);
        ul.appendChild(li);
      });
    },
  };

  useEffect(() => {
    if (barChartRef.current !== null) {
      barChartObjectRef.current?.destroy();
      barChartObjectRef.current = new Chart(barChartRef.current, {
        data: dataObject,
        type: 'bar',
        options: chartOptions,
        plugins: [htmlLegendPlugin],
      });
    }
  }, [barChartRef.current, barChartRef, dataObject]);

  return (
    <BarChartWrapper data-testid="bar-chart">
      <BarChartCanvas ref={barChartRef} />
      <BarChartLegendWrapper>
        <BarChartLegendList ref={barChartLegendRef} />
      </BarChartLegendWrapper>
    </BarChartWrapper>
  );
};

BarChart.propTypes = {
  data: PropTypes.shape({
    labels: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    datasets: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        data: PropTypes.arrayOf(PropTypes.number.isRequired),
      })
    ),
  }),
  tooltipLabel: PropTypes.string.isRequired,
  stacked: PropTypes.bool,
};

BarChart.displayName = 'BarChart';

export default BarChart;
