import styled from '@emotion/styled';
import { customProperties as vars } from '@resi-media/resi-ui';

export const FillBlockSpinnerContainer = styled.div`
  color: ${vars.colorPrimary};
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 100%;
`;
