'use strict';

const jcs = require('../../jcs');
const moment = require('moment');

// NOTE: that not all statuses are show on the dashboard page. Totals reflect all units, so totals may appear larger
// than the unit displayed on the table.

function UnitDashboardController($scope, $http, $timeout, $q, httpService, focus) {
	'ngInject';

	$(window).trigger('resize'); // ensure footer is properly positioned

	$scope.DATE_FORMAT = 'MM/DD/YY';
	$scope.DATE_FORMAT_SEARCH = 'YYYY-MM-DD';

	$scope.STATUS_CUSTOMER_OWNED = 'Customer Owned';
	$scope.STATUS_DEALER_OWNED = 'Dealer Owned';
	$scope.STATUS_DECOM = 'Decommissioned';
	$scope.STATUS_DEMO_OUT = 'Demo - Out';
	$scope.STATUS_INTERNAL_USE = 'Internal Use';
	$scope.STATUS_NEEDS_REFURB = 'Needs Refurb';
	$scope.STATUS_NEEDS_REPAIR_THEN_REFURB = 'Needs Repair, Then Refurb';
	$scope.STATUS_NEEDS_REPAIR_THEN_RETURN = 'Needs Repair, Then Return';
	$scope.STATUS_RENTAL_OUT = 'Rental - Out';
	$scope.STATUS_STOCK_INTERNAL_USE = 'Stock - Internal Use';
	$scope.STATUS_STOCK_NEW = 'Stock - New';
	$scope.STATUS_STOCK_REFURB = 'Stock - Refurbished';
	$scope.STATUS_UNKNOWN = 'Unknown';

	$scope.is_loading = false;
	$scope.is_busy = false;
	$scope.error_msg = null;

	$scope.show_discontinued = false; // don't show discontinued table by default

	$scope.model_by_name = {};
	$scope.cycles_by_id = {};

	$scope.current_gen_data = null;
	$scope.end_of_life_data = null;
	$scope.discontinued_data = null;

	$scope.current_gen_total = 0;
	$scope.end_of_life_total = 0;
	$scope.discontinued_total = 0;

	$scope.current_gen_status_counts = {}; // index by status
	$scope.current_gen_model_counts = {}; // index by model

	$scope.end_of_life_status_counts = {};
	$scope.end_of_life_model_counts = {};

	$scope.discontinued_status_counts = {};
	$scope.discontinued_model_counts = {};

	$scope.report_criteria = null;
	$scope.has_error = null;
	$scope.report_results = null;
	$scope.report_total = 0;

	$scope.enterReportMode = function () {
		$scope.report_criteria = {
			build_date_start: null,
			build_date_end: null,
		};
	};

	$scope.cancel = function () {
		$scope.report_criteria = null;
		$scope.error_msg = null;
		$scope.report_results = null;
	};

	$scope.isEmpty = function (value) {
		return value == null || value === '';
	};

	$scope.doesBuildReportFailValidation = function () {
		$scope.has_error = {};

		// check required fields to ensure they aren't empty
		$scope.has_error.build_date_start = $scope.isEmpty($scope.report_criteria.build_date_start);
		$scope.has_error.build_date_end = $scope.isEmpty($scope.report_criteria.build_date_end);

		var error_count = 0;
		for (var property in $scope.has_error) {
			if ($scope.has_error[property] === true) {
				error_count++;
			}
		}
		var has_validation_error = error_count > 0;

		$scope.error_msg = has_validation_error ? 'Please specify a value for the highlighted fields below.' : null;

		return has_validation_error;
	};

	$scope.buildAACReport = function () {
		// if we have form validation errors, then don't go any further
		if ($scope.doesBuildReportFailValidation()) return false;

		var formatted_start_date = moment($scope.report_criteria.build_date_start, $scope.DATE_FORMAT).format(
			$scope.DATE_FORMAT_SEARCH
		);
		var formatted_end_date = moment($scope.report_criteria.build_date_end, $scope.DATE_FORMAT).format(
			$scope.DATE_FORMAT_SEARCH
		);
		var query_string =
			'buildDateStart=' +
			encodeURIComponent(formatted_start_date) +
			'&buildDateEnd=' +
			encodeURIComponent(formatted_end_date);

		$scope.is_busy = true;

		// perform decoder search
		$http
			.get(jcs.api.internal_url + '/hardwareunits/count?' + query_string, { withCredentials: true })
			.then(
				function (response) {
					// success

					console.log('REPORT:');
					console.log(response.data);
					$scope.report_results = response.data;

					// calculate our total count
					$scope.report_total = 0;
					for (var i = 0; i < $scope.report_results.length; i++) {
						$scope.report_total += $scope.report_results[i].count;
					}
				},
				function () {
					// error

					$scope.error_msg =
						'There was a problem trying to build the report. Please try again, or report the problem if it persists.';
				}
			)
		['finally'](function () {
			// always called

			$scope.is_busy = false;
		});
	};

	$scope.showFormattedCount = function (count) {
		if (count == null || count == 0) {
			return '-';
		}
		return count;
	};

	$scope.getBaseModel = function (model) {
		var index = model.indexOf('-');
		if (index !== -1) {
			return model.substring(0, index);
		}
		return model;
	};

	$scope.getModelCount = function (model_info, model) {
		if (model_info.hasOwnProperty(model)) {
			return model_info[model];
		}
		return 0;
	};

	$scope.getStatusCount = function (status_info, status) {
		if (status_info.hasOwnProperty(status)) {
			return status_info[status];
		}
		return 0;
	};

	$scope.getCurGenStatusCount = function (status) {
		return $scope.getStatusCount($scope.current_gen_status_counts, status);
	};

	// EOL = End of Life
	$scope.getEOLStatusCount = function (status) {
		return $scope.getStatusCount($scope.end_of_life_status_counts, status);
	};

	$scope.getDiscontStatusCount = function (status) {
		return $scope.getStatusCount($scope.discontinued_status_counts, status);
	};

	$scope.getClassificationLabelForModel = function (model) {
		// get the model info (which will include the cycleId)
		if ($scope.model_by_name.hasOwnProperty(model)) {
			var model_info = $scope.model_by_name[model];
			// now that we have the cycleId, we should be able to get the label
			if ($scope.cycles_by_id.hasOwnProperty(model_info.cycleId)) {
				return $scope.cycles_by_id[model_info.cycleId];
			} else {
				console.log(
					'Error: unable to classify model because cycleId is unknown. Model = ' +
					model +
					' CycleId = ' +
					model_info.cycleId
				);
			}
		} else {
			console.log('Error: unable to classify model because it is unknown: ' + model);
		}
		return 'Unknown';
	};

	$scope.isCurrentGen = function (model) {
		return $scope.getClassificationLabelForModel(model) == 'Current Gen';
	};

	$scope.isEndOfLife = function (model) {
		return $scope.getClassificationLabelForModel(model) == 'End of Life';
	};

	$scope.isDiscontinued = function (model) {
		return $scope.getClassificationLabelForModel(model) == 'Discontinued';
	};

	$scope.isEncoder = function (model) {
		if (typeof model === 'string' && model[0] == 'E') {
			return true;
		}
		return false;
	};

	$scope.updateModelAndStatusCounts = function (model, status, count) {
		if ($scope.isCurrentGen(model)) {
			// model
			if (!$scope.current_gen_model_counts.hasOwnProperty(model)) {
				$scope.current_gen_model_counts[model] = 0;
			}
			$scope.current_gen_model_counts[model] += count;
			// status
			if (!$scope.current_gen_status_counts.hasOwnProperty(status)) {
				$scope.current_gen_status_counts[status] = 0;
			}
			$scope.current_gen_status_counts[status] += count;
		} else if ($scope.isEndOfLife(model)) {
			// model
			if (!$scope.end_of_life_model_counts.hasOwnProperty(model)) {
				$scope.end_of_life_model_counts[model] = 0;
			}
			$scope.end_of_life_model_counts[model] += count;
			// status
			if (!$scope.end_of_life_status_counts.hasOwnProperty(status)) {
				$scope.end_of_life_status_counts[status] = 0;
			}
			$scope.end_of_life_status_counts[status] += count;
		} else if ($scope.isDiscontinued(model)) {
			// model
			if (!$scope.discontinued_model_counts.hasOwnProperty(model)) {
				$scope.discontinued_model_counts[model] = 0;
			}
			$scope.discontinued_model_counts[model] += count;
			// status
			if (!$scope.discontinued_status_counts.hasOwnProperty(status)) {
				$scope.discontinued_status_counts[status] = 0;
			}
			$scope.discontinued_status_counts[status] += count;
		}
	};

	/*			$scope.genFakeData = function (){
				  var statuses = [
					  $scope.STATUS_CUSTOMER_OWNED,
					  $scope.STATUS_DEALER_OWNED,
					  $scope.STATUS_DECOM,
					  $scope.STATUS_DEMO_OUT,
					  $scope.STATUS_INTERNAL_USE,
					  $scope.STATUS_NEEDS_REFURB,
					  $scope.STATUS_NEEDS_REPAIR_THEN_REFURB,
					  $scope.STATUS_NEEDS_REPAIR_THEN_RETURN,
					  $scope.STATUS_RENTAL_OUT,
					  $scope.STATUS_STOCK_INTERNAL_USE,
					  $scope.STATUS_STOCK_NEW,
					  $scope.STATUS_STOCK_REFURB,
					  $scope.STATUS_UNKNOWN
				  ];

				  var models = [
					  'D2100',
					  'D2110',
					  'D2200',
					  'D2200-1',
					  'D2200-2',
					  'D2201',
					  'D2201-1',
					  'D2201-2',
					  'D2202',
					  'D2202-1',
					  'D2202-2',
					  'E1100',
					  'E1110',
					  'E1120',
					  'E1200',
					  'E1200-1',
					  'E1200-2',
					  'E2100',
					  'E2101',
					  'E2110',
					  'E2111',
					  'E2200',
					  'E2210',
					  'E2211',
					  'E2211-1',
					  'E2211-2',
					  'E2211-S'
				  ];

				  var data = [];
				  for (var i=0; i < statuses.length; i++){

					  for (var j=0; j < models.length; j++){

						  data.push({
							  model: models[j],
							  status: statuses[i],
							  count: 1
						  });
					  }
				  }

				  return data;
			  };*/

	$scope.updateAllCounts = function (data) {
		// reset our counts to zero
		$scope.current_gen_total = 0;
		$scope.end_of_life_total = 0;
		$scope.discontinued_total = 0;
		$scope.current_gen_status_counts = {}; // index by status
		$scope.current_gen_model_counts = {}; // index by model
		$scope.end_of_life_status_counts = {};
		$scope.end_of_life_model_counts = {};
		$scope.discontinued_status_counts = {};
		$scope.discontinued_model_counts = {};

		//data = $scope.genFakeData(); // TEST

		// API will return one record for every status and model combination. First we will organize these by
		// model number using an object for easy access. Then we'll convert to an array that the HTML template
		// can use to create our tables.
		var pre_data = {};

		for (var i = 0; i < data.length; i++) {
			// So we will receive data sets for every model in the system, but we want to group their output by the base model number.
			// So for example, D2200, D2200-1, and D2200-2 should all be grouped together.
			var base_model = $scope.getBaseModel(data[i].model);
			var status = data[i].status;
			var count = data[i].count;

			// process our counts based on base_model classification (these are the totals that appear on our column and row "headers")
			$scope.updateModelAndStatusCounts(base_model, status, count);

			// add base_model if it doesn't already exist
			if (!pre_data.hasOwnProperty(base_model)) {
				pre_data[base_model] = {};
			}

			// update count for given status
			if (!pre_data[base_model].hasOwnProperty(status)) {
				// init status with count if it doesn't already exist
				pre_data[base_model][status] = count;
			} else {
				pre_data[base_model][status] += count;
			}

			// keep track of totals for each category
			if ($scope.isCurrentGen(base_model)) {
				$scope.current_gen_total += count;
			} else if ($scope.isEndOfLife(base_model)) {
				$scope.end_of_life_total += count;
			} else if ($scope.isDiscontinued(base_model)) {
				$scope.discontinued_total += count;
			} else {
				console.log('ERROR: unable to classify base_model: ' + base_model + ' from model: ' + data[i].model);
			}
		}

		// convert into various arrays for html template to use
		$scope.current_gen_data = [];
		$scope.end_of_life_data = [];
		$scope.discontinued_data = [];
		for (var model in pre_data) {
			// need to convert these to one word ("demoOut" instead of "Demo - Out"),
			// because that is what our table sorting needs to reference them
			var model_info = {
				model: model,
				customerOwned: pre_data[model][$scope.STATUS_CUSTOMER_OWNED],
				dealerOwned: pre_data[model][$scope.STATUS_DEALER_OWNED],
				decommissioned: pre_data[model][$scope.STATUS_DECOM],
				demoOut: pre_data[model][$scope.STATUS_DEMO_OUT],
				internalUse: pre_data[model][$scope.STATUS_INTERNAL_USE],
				needsRefurb: pre_data[model][$scope.STATUS_NEEDS_REFURB],
				needsRepairThenRefurb: pre_data[model][$scope.STATUS_NEEDS_REPAIR_THEN_REFURB],
				needsRepairThenReturn: pre_data[model][$scope.STATUS_NEEDS_REPAIR_THEN_RETURN],
				rentalOut: pre_data[model][$scope.STATUS_RENTAL_OUT],
				stockInternalUse: pre_data[model][$scope.STATUS_STOCK_INTERNAL_USE],
				stockNew: pre_data[model][$scope.STATUS_STOCK_NEW],
				stockRefurbished: pre_data[model][$scope.STATUS_STOCK_REFURB],
				unknown: pre_data[model][$scope.STATUS_UNKNOWN],
			};

			if ($scope.isCurrentGen(model)) {
				$scope.current_gen_data.push(model_info);
			} else if ($scope.isEndOfLife(model)) {
				$scope.end_of_life_data.push(model_info);
			} else if ($scope.isDiscontinued(model)) {
				$scope.discontinued_data.push(model_info);
			} else {
				console.log('ERROR: unable to classify model: ' + model);
			}
		}
	};

	$scope.load = function () {
		$scope.is_loading = true;

		var promises = [];
		promises.push($http.get(jcs.api.internal_url + '/hardwareunits/summary', { withCredentials: true }));
		promises.push($http.get(jcs.api.internal_url + '/hardwaremodels', { withCredentials: true }));
		promises.push($http.get(jcs.api.internal_url + '/hardwarecycles', { withCredentials: true }));

		$q.all(promises)
			.then(
				function (response) {
					// organize models by name (ex: D2200)
					$scope.model_by_name = {};
					var models = response[1].data;
					for (var i = 0; i < models.length; i++) {
						var model = models[i];
						$scope.model_by_name[model.name] = model;
					}

					// organize cycle names by uuid
					$scope.cycles_by_id = {};
					var cycles = response[2].data;
					for (var i = 0; i < cycles.length; i++) {
						var cycle = cycles[i];
						$scope.cycles_by_id[cycle.uuid] = cycle.name;
					}

					// only do this after we have init'd our model_by_name and cycles_by_id objects (those are used to determine
					// the classification of a given set of model info).
					$scope.updateAllCounts(response[0].data);
				},
				function (reason) {
					if (!httpService.isStatus406(reason)) {
						$scope.error_msg =
							'An error occurred while attempting to load the dashboard data. Please try again, or report the problem if it persists.';
					}
				}
			)
			.finally(function () {
				$scope.is_loading = false;
			});
	};

	//
	// initialize by loading our system monitoring info
	//
	$scope.load();

	$scope.date_picker_options = {
		singleDatePicker: true,
		autoApply: true,
		autoUpdateInput: false, // allows date field to be blank
		locale: {
			format: $scope.DATE_FORMAT, //'ll' // <= looks like this is using moment.js formatting options
		},
	};
	$scope.report_build_date_start_callback = function (start, end, label) {
		$scope.report_criteria.build_date_start = moment(start).format($scope.DATE_FORMAT);
		$('#report-build-date-start').val($scope.report_criteria.build_date_start);
	};
	$scope.report_build_date_end_callback = function (start, end, label) {
		$scope.report_criteria.build_date_end = moment(start).format($scope.DATE_FORMAT);
		$('#report-build-date-end').val($scope.report_criteria.build_date_end);
	};

	$('#report-build-date-start').daterangepicker($scope.date_picker_options, $scope.report_build_date_start_callback);
	$('#report-build-date-end').daterangepicker($scope.date_picker_options, $scope.report_build_date_end_callback);

	// build our tooltips
	//			$timeout(function(){
	//				$('[data-toggle="tooltip"]').tooltip();	// needs to be done in timeout, otherwise for some reason the tooltip gets built before angular does it's magic
	//			});
}

module.exports = UnitDashboardController;
