// import { perms } from '../../jcs';
import { licenseinfoModule } from './index';

function routeConfig($provide, $routeProvider) {
	'ngInject';

	$provide.factory('$routeProvider', () => {
		return $routeProvider;
	});
	$routeProvider.when(licenseinfoModule.routes.list, {
		controller: licenseinfoModule.controllers.default,
		template: require('./licenseinfo.tmpl.html').default,
		activenav: licenseinfoModule.name,
		access: {
			loginRequired: true,
			//permissions: [perms['events.get']]
		},
	});
}

export default routeConfig;
