import { usersModule } from './index';

function routeConfig($provide, $routeProvider) {
	'ngInject';

	$provide.factory('$routeProvider', () => {
		return $routeProvider;
	});

	$routeProvider.when(usersModule.routes.list, {
		controller: usersModule.controllers.default,
		template: require('./users.tmpl.html').default,
		activenav: usersModule.name,
		access: {
			loginRequired: true,
			// note: no perm required. Users is a bit of a special case. Each user needs to be able to access their profile and
			// change their password (which is handled by users module). The controller code checks to see if they are viewing the user list
			// and if so checks for proper perms there.
			permissions: [],
		},
	});
}

export default routeConfig;
