import 'angular-route';
import angular from 'angular';
import router from './module.eventprofiles.routes';
import commonServicesModule from '../../services';

export const eventprofilesModule = {
	name: 'eventprofiles',
	controllers: {
		default: 'eventProfilesCtrl',
	},
	routes: {
		list: '/eventprofiles',
		details: '/eventprofiles/:id',
	},
};

export default angular
	.module(eventprofilesModule.name, ['ngRoute', commonServicesModule])
	.config(router)
	.controller(eventprofilesModule.controllers.default, require('./eventProfilesCtrl')).name;
