import React, { FC, useEffect, useRef } from 'react';
import { customProperties as vars } from '@resi-media/resi-ui';
import { ChartTypeRegistry, TooltipItem } from 'chart.js';
import { DOUGHNUT_COLOR_SET } from '../../../pages/EventAnalytics/event-analytics-constants';
import { doughnutLegendItemsType } from '../types';
import {
  DoughnutChartCanvas,
  DoughnutChartContent,
  DoughnutChartLegendList,
  DoughnutChartLegendsWrapper,
  DoughnutChartWrapper,
} from './doughnut.styles';
import Chart from 'chart.js/auto';
import PropTypes from 'prop-types';

type DoughnutChartData = {
  label: string | number;
  data: number;
};

interface DoughnutChartProps {
  data: DoughnutChartData[];
  tooltipLabel: string;
  isLegendOnRightSide: boolean;
  showPercentage: boolean;
}

const DoughnutChart: FC<DoughnutChartProps> = ({ data, tooltipLabel, isLegendOnRightSide, showPercentage }): JSX.Element => {
  const doughnutChartRef = useRef<Chart>(null);
  const doughnutChartObjRef = useRef<Chart>(null);
  const doughnutChartLegendRef = useRef<Chart>(null);
  const colors: string[] = DOUGHNUT_COLOR_SET.map((color) => color.baseColor);
  const dataObject = {
    labels: data ? data.map((value) => value.label) : [],
    datasets: [
      {
        backgroundColor: colors,
        data: data ? data.map((value) => value.data) : [],
      },
    ],
  };

  const chartOptions = {
    aspectRatio: 1,
    cutout: 50,
    maintainAspectRatio: true,
    elements: {
      arc: {
        borderWidth: 0,
      },
    },
    plugins: {
      doughnutChartLegend: {
        // ID of the container to put the legend in
        containerID: 'doughnut-legend-container',
      },
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
        callbacks: {
          label: (tooltipItem: TooltipItem<keyof ChartTypeRegistry>) => {
            return ` ${!isNaN(Number(tooltipItem.label)) ? `${tooltipItem.label}p` : tooltipItem.label} : ${
              tooltipItem.formattedValue
            } ${tooltipLabel}`;
          },
        },
      },
    },
  };

  const htmlLegendPlugin = {
    id: 'doughnutChartLegend',
    afterUpdate(chart: Chart) {
      const ul = doughnutChartLegendRef.current;
      if (!ul) {
        return;
      }
      ul.style.flexWrap = 'wrap';

      // Remove old legend items
      while (ul.firstChild) {
        ul.firstChild.remove();
      }
      // Reuse the built-in legendItems generator
      const items = showPercentage
        ? calculatePercentage(chart.options.plugins.legend.labels.generateLabels(chart))
        : chart.options.plugins.legend.labels.generateLabels(chart);

      items.forEach((item: doughnutLegendItemsType, index: number) => {
        const li = document.createElement('li');
        li.style.alignItems = 'left';
        li.style.cursor = 'pointer';
        li.style.display = 'flex';
        li.style.flexDirection = 'row';
        li.style.marginLeft = '10px';
        li.style.width = 'fit-content';

        li.onclick = () => {
          chart.toggleDataVisibility(item.index);
          chart.update();
        };

        // Color box
        const boxSpan = document.createElement('span');
        boxSpan.style.background = dataObject.datasets[0].backgroundColor[index];
        boxSpan.style.borderColor = item.strokeStyle;
        boxSpan.style.borderWidth = item.lineWidth + 'px';
        boxSpan.style.display = 'inline-block';
        boxSpan.style.height = vars.spacingM;
        boxSpan.style.marginRight = vars.borderRadius2;
        boxSpan.style.width = vars.spacingM;
        boxSpan.style.opacity = item.hidden ? '0.4' : '1';
        boxSpan.style.borderRadius = vars.borderRadius2;

        // Text
        const textContainer = document.createElement('p');
        textContainer.style.fontWeight = 'bold';
        textContainer.style.width = 'max-content';
        textContainer.style.margin = '0 0 0 5px';
        textContainer.style.textDecoration = item.hidden ? 'line-through' : '';
        textContainer.style.opacity = item.hidden ? '0.4' : '1';

        const legendText = !isNaN(Number(item.text)) ? `${item.text}p` : item.text;
        const text = document.createTextNode(legendText);
        textContainer.appendChild(text);

        //Percentage
        const percentageContainer = document.createElement('span');
        percentageContainer.style.fontWeight = 'light';
        percentageContainer.style.width = 'max-content';
        percentageContainer.style.margin = '0 0 0 10px';
        percentageContainer.style.textDecoration = item.hidden ? 'line-through' : '';
        percentageContainer.style.opacity = item.hidden ? '0.4' : '0.8';

        const percentageSpan = document.createTextNode(item.percentage ? item.percentage : '');
        percentageContainer.appendChild(percentageSpan);

        li.appendChild(boxSpan);
        li.appendChild(textContainer);
        li.appendChild(percentageContainer);
        ul.appendChild(li);
      });
    },
  };

  const calculatePercentage = (items: doughnutLegendItemsType[]): doughnutLegendItemsType[] => {
    const dataArray: number[] = dataObject.datasets[0].data;
    let totalViewers: number;
    if (dataArray.length > 0) {
      totalViewers = dataArray.reduce((accumulator, current) => {
        return Number(accumulator || 0) + Number(current || 0);
      });
    }
    items = items.map((value: doughnutLegendItemsType, index: number) => {
      const percentagePerData = ((dataArray[index] / totalViewers) * 100).toFixed(1);
      return {
        ...value,
        percentage: `${percentagePerData} %`,
      };
    });
    return items;
  };

  useEffect(() => {
    if (doughnutChartRef.current !== null) {
      doughnutChartObjRef.current?.destroy();
      doughnutChartObjRef.current = new Chart(doughnutChartRef.current, {
        type: 'doughnut',
        data: dataObject,
        options: chartOptions,
        plugins: [htmlLegendPlugin],
      });
    }
  }, [doughnutChartRef.current, doughnutChartRef, dataObject]);

  return (
    <DoughnutChartWrapper isLegendOnRightSide={isLegendOnRightSide} data-testid="doughnut-chart">
      <DoughnutChartContent>
        <DoughnutChartCanvas ref={doughnutChartRef} />
      </DoughnutChartContent>
      <DoughnutChartLegendsWrapper>
        <DoughnutChartLegendList ref={doughnutChartLegendRef} />
      </DoughnutChartLegendsWrapper>
    </DoughnutChartWrapper>
  );
};

DoughnutChart.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      tableLabels: PropTypes.string,
      data: PropTypes.number,
    })
  ),
  tooltipLabel: PropTypes.string.isRequired,
  isLegendOnRightSide: PropTypes.bool.isRequired,
  showPercentage: PropTypes.bool.isRequired,
};

DoughnutChart.displayName = 'DoughnutChart';

export default DoughnutChart;
