import { perms } from '../../jcs';
import { playersModule } from './index';
import { usersModule } from '../users/';

function routeConfig($provide, $routeProvider) {
  'ngInject';

  $provide.factory('$routeProvider', () => {
    return $routeProvider;
  });

  $routeProvider.when(playersModule.routes.list, {
    controller: playersModule.controllers.default,
    template: require('./players.tmpl.html').default,
    activenav: usersModule.name, // <= currently when player status are viewed, users will be the active nav
    access: {
      loginRequired: true,
      permissions: [perms['users.get']],
    },
  });
}

export default routeConfig;
