import React, { FC, Reducer, useReducer } from 'react';
import { Cue } from '../../helpers/events/types';
import Modal from '../Modal';
import ModalHeader from '../ModalHeader';
import Portal from '../Portal';
import ArchiveSaveWebEventForm from '../ArchiveSaveWebEventForm';
import ArchiveTrimWebEventForm from '../ArchiveTrimWebEventForm';
import { ArchiveWebEventState, Action, reducer, ArchiveWebEventContext } from './context';

interface ArchiveWebEventModalProps {
  auth: any;
  cues?: Cue[];
  handleCloseModal: () => void;
  webEventId?: string;
  customerId?: string;
  webEventProfileId?: string;
  webEventName?: string;
  webEventDuration?: number;
  isWebEventLive?: boolean;
}

const ArchiveWebEventModal: FC<ArchiveWebEventModalProps> = ({
  auth,
  cues,
  handleCloseModal,
  webEventId,
  customerId,
  webEventProfileId,
  webEventName,
  webEventDuration,
  isWebEventLive
}) => {
  const initialState: ArchiveWebEventState = {
    webEventName,
    startPosition: null,
    endPosition: null,
    published: false,
    customerId,
    webEventId,
    webEventProfileId,
    progress: 0,
    newWebEventLocation: '',
    originalAndTrimmedDurations: {
      formattedDuration: '',
      trimmedFormattedDuration: ''
    }
  };
  const [state, dispatch] = useReducer<Reducer<ArchiveWebEventState, Action>>(reducer, initialState);

  return (
    <Portal>
      <Modal>
        <ModalHeader titleText="Save to Library" closeButton={state.newWebEventLocation.length > 0} onClose={handleCloseModal} badgeText={isWebEventLive ? "Live" : undefined}/>
        {state.newWebEventLocation?.length > 0 ? (
          <ArchiveSaveWebEventForm
            auth={auth}
            newWebEventLocation={state.newWebEventLocation}
            webEventProfileId={webEventProfileId}
            customerId={customerId}
            webEventId={webEventId}
            originalAndTrimmedDurations={state.originalAndTrimmedDurations}
          />
        ) : (
          <ArchiveWebEventContext.Provider value={{ state, dispatch }}>
            <ArchiveTrimWebEventForm
              cues={cues}
              handleCloseModal={handleCloseModal}
              webEventProfileId={webEventProfileId}
              customerId={customerId}
              webEventId={webEventId}
              webEventDuration={webEventDuration}
              isWebEventLive={isWebEventLive}
            />
          </ArchiveWebEventContext.Provider>
        )}
      </Modal>
    </Portal>
  );
};

ArchiveWebEventModal.displayName = 'ArchiveWebEventModal';

export default ArchiveWebEventModal;
