import React, { FC, FormEvent, useReducer, useEffect, useState } from 'react';
import { CueOption } from '../../helpers/events/types';
import { Representation } from '../EventDownloadButton/eventDownloadApi';
import './eventDownloadForm.scss';

interface EventDownloadFormProps {
  videoOptions: Representation[];
  audioOptions: Representation[];
  cues: CueOption[];
  loading: boolean;
  live: boolean;
  onValidate: (valid: boolean) => void;
  onSubmit: (values: EventDownloadFormValues) => void;
}

export interface EventDownloadFormValues {
  videoTrack: number | undefined;
  audioTrack: number | undefined;
  startPosition: string;
  stopPosition: string;
}

const formValuesReducer = (state: EventDownloadFormValues, { field, value }: any): EventDownloadFormValues => {
  return {
    ...state,
    [field]: value,
  };
};

export const liveWithCuesBlurb =
  'For live events which have not yet ended, a Cue is required to be used for the Stop Position. The End of Event option will be available to use as a Stop Position once the event has ended.';

export const liveNoCuesBlurb =
  'This event currently has no cues. Please close the Download Web Event window and add a Cue to be used for the Stop Position.';

const initialState: EventDownloadFormValues = {
  videoTrack: undefined,
  audioTrack: undefined,
  startPosition: '',
  stopPosition: '',
};

const EventDownloadForm: FC<EventDownloadFormProps> = ({
  videoOptions,
  audioOptions,
  cues,
  loading,
  live,
  onValidate,
  onSubmit,
}) => {
  const [state, dispatch] = useReducer(formValuesReducer, initialState);
  const { videoTrack, audioTrack, startPosition, stopPosition } = state;

  const [validStartPosition, setValidStartPosition] = useState({ valid: true, message: '' });
  const [validEndPosition, setValidEndPosition] = useState({ valid: true, message: '' });

  useEffect(() => {
    if (videoOptions.find((x) => x !== undefined)) {
      dispatch({ field: 'videoTrack', value: videoOptions[0].id });
    }

    if (audioOptions.find((x) => x !== undefined)) {
      dispatch({ field: 'audioTrack', value: audioOptions[0].id });
    }
  }, [videoOptions, audioOptions]);

  useEffect(() => {
    if (live && cues.length > 0) {
      dispatch({ field: 'stopPosition', value: cues[0].position.toString() });
    }
  }, [cues, live]);

  useEffect(() => {
    let isValid = true;
    if (state.startPosition === state.stopPosition && state.startPosition !== '' && state.stopPosition !== '') {
      isValid = false;
      setValidEndPosition({ valid: false, message: 'Start Position and Stop Position cannot be the same' });
    } else {
      setValidEndPosition({ valid: true, message: '' });
    }

    if (parseInt(state.startPosition) > parseInt(state.stopPosition)) {
      isValid = false;
      setValidStartPosition({ valid: false, message: 'Start Position must occur before Stop Postion' });
    } else {
      setValidStartPosition({ valid: true, message: '' });
    }

    if (live && cues.length === 0) {
      isValid = false;
    }

    onValidate(isValid);
  }, [state]);

  const handleChange = (event: FormEvent<HTMLSelectElement>): void => {
    dispatch({ field: event.currentTarget.name, value: event.currentTarget.value });
  };

  const handleSubmit = (event: FormEvent): void => {
    event.preventDefault();
    onSubmit(state);
  };

  const downloadForm = (
    <form className="form-horizontal" id="webEventDownloadForm" onSubmit={handleSubmit} data-testid="form">
      <div style={{ width: '75%', marginBottom: '1em' }}>
        Downloaded files may have compatibility issues with some third-party software. To learn how to fix the problem,
        <a
          href="https://docs.resi.io/articles/resi-resources-setup-actions-and-behaviors-within-control/download-web-event-from-control"
          target="_blank"
          rel="noopener noreferrer"
        >
          {' '}
          click here.
        </a>
      </div>
      <div className="form-group">
        <label htmlFor="videoTrack" className="col-sm-3 control-label">
          Video
        </label>
        <div className="col-sm-9">
          <select
            className="form-control"
            id="videoTrack"
            name="videoTrack"
            value={videoTrack}
            onChange={handleChange}
            onBlur={handleChange}
          >
            {videoOptions.map((option: Representation) => {
              return (
                <option key={option.id} value={option.id}>
                  {option.name}
                </option>
              );
            })}
          </select>
        </div>
      </div>
      <div className="form-group">
        <label htmlFor="audioTrack" className="col-sm-3 control-label">
          Audio
        </label>
        <div className="col-sm-9">
          <select
            className="form-control"
            id="audioTrack"
            name="audioTrack"
            value={audioTrack}
            onChange={handleChange}
            onBlur={handleChange}
          >
            {audioOptions.map((option: Representation) => {
              return (
                <option key={option.id} value={option.id}>
                  {option.name}
                </option>
              );
            })}
          </select>
        </div>
      </div>
      <div className={`form-group ${!validStartPosition.valid ? 'has-error' : ''}`}>
        <label htmlFor="startPosition" className="col-sm-3 control-label">
          Start Position
        </label>
        <div className="col-sm-9">
          <select
            className="form-control"
            id="startPosition"
            name="startPosition"
            value={startPosition}
            onChange={handleChange}
            onBlur={handleChange}
            data-testid="startPositionTestId"
          >
            <option value="">Beginning of Event</option>
            {cues.map((cue) => {
              return (
                <option key={cue.uuid} value={cue.position}>
                  {cue.name}
                </option>
              );
            })}
          </select>
          {!validStartPosition.valid && (
            <span className="help-block" data-testid="startPositionError">
              {validStartPosition.message}
            </span>
          )}
          {startPosition !== '' && validStartPosition.valid && (
            <div className="well well-sm" style={{ marginTop: 5, marginBottom: 0 }} data-testid="startPositionCueNote">
              <strong>NOTE:</strong>
              {` If you choose a Start Position other than Beginning of Event, the actual start time
            may be up to 6 seconds earlier than the selected position.`}
            </div>
          )}
        </div>
      </div>
      <div className={`form-group ${!validEndPosition.valid ? 'has-error' : ''}`}>
        <label htmlFor="stopPosition" className="col-sm-3 control-label">
          Stop Position
        </label>
        <div className="col-sm-9">
          <select
            className="form-control"
            id="stopPosition"
            name="stopPosition"
            value={stopPosition}
            onChange={handleChange}
            onBlur={handleChange}
            data-testid="stopPositionTestId"
          >
            {!live && <option value="">End of Event</option>}
            {cues.map((cue) => {
              return (
                <option key={cue.uuid} value={cue.position}>
                  {cue.name}
                </option>
              );
            })}
          </select>
          {!validEndPosition.valid && (
            <span className="help-block" data-testid="stopPositionError">
              {validEndPosition.message}
            </span>
          )}
          {stopPosition !== '' && validEndPosition.valid && (
            <div className="well well-sm" style={{ marginTop: 5, marginBottom: 0 }} data-testid="stopPositionCueNote">
              <strong>NOTE:</strong>
              {` If you choose a Stop Position other than End of Event, the actual end time may be up
            to 6 seconds later than the selected position.`}
            </div>
          )}
          {live && (
            <div className="la1-cues-info">
              <div>
                <i className="fa fa-info-circle fa-lg" style={{ color: '#3c8dbc' }}></i>
              </div>
              <div style={{ marginLeft: 7 }} data-testid="liveCuesMessage">
                {cues.length > 0 ? liveWithCuesBlurb : liveNoCuesBlurb}
              </div>
            </div>
          )}
        </div>
      </div>
    </form>
  );

  const loadingSpinner = (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 195,
      }}
      data-testid="loadingSpinner"
    >
      <i className="fa fa-spinner fa-spin fa-4x"></i>
    </div>
  );

  return loading ? loadingSpinner : downloadForm;
};

EventDownloadForm.displayName = 'EventDownloadForm';

export default EventDownloadForm;
