import { Context, createContext, Dispatch } from 'react';
import { EVENT_ANALYTICS_CONTEXT_KEYS } from '../../../pages/EventAnalytics/event-analytics-constants';
import { DateType, SelectOption, Token } from '../../../pages/EventAnalytics/types';

export interface EventAnalyticsActions<T> {
  payload: T;
  type: EVENT_ANALYTICS_CONTEXT_KEYS;
}

export interface EventAnalyticsState {
  viewAllData: boolean;
  customerId: string;
  dateRange: DateType;
  dateRangeOption: SelectOption;
  eventId?: string | null;
  token: { Authorization: string };
}

export interface EventAnalyticsContextProps {
  EventAnalyticsState: EventAnalyticsState;
  dispatch: Dispatch<EventAnalyticsActions<any>>;
}

export const EventAnalyticsContext: Context<EventAnalyticsContextProps> = createContext<EventAnalyticsContextProps>(
  {} as EventAnalyticsContextProps
);

export const dispatchers = {
  [EVENT_ANALYTICS_CONTEXT_KEYS.EVENT_ID]: (
    state: EventAnalyticsState,
    action: EventAnalyticsActions<string>
  ): EventAnalyticsState => {
    return { ...state, eventId: action.payload };
  },
  [EVENT_ANALYTICS_CONTEXT_KEYS.CUSTOMER_ID]: (
    state: EventAnalyticsState,
    action: EventAnalyticsActions<string>
  ): EventAnalyticsState => {
    return { ...state, customerId: action.payload };
  },
  [EVENT_ANALYTICS_CONTEXT_KEYS.TOKEN]: (
    state: EventAnalyticsState,
    action: EventAnalyticsActions<Token>
  ): EventAnalyticsState => {
    return { ...state, token: action.payload };
  },
  [EVENT_ANALYTICS_CONTEXT_KEYS.DATE_RANGE]: (
    state: EventAnalyticsState,
    action: EventAnalyticsActions<DateType>
  ): EventAnalyticsState => {
    return { ...state, dateRange: action.payload };
  },
  [EVENT_ANALYTICS_CONTEXT_KEYS.DATE_RANGE_VALUE]: (
    state: EventAnalyticsState,
    action: EventAnalyticsActions<SelectOption>
  ): EventAnalyticsState => {
    return { ...state, dateRangeOption: action.payload };
  },
  [EVENT_ANALYTICS_CONTEXT_KEYS.VIEW_ALL_DATA]: (
    state: EventAnalyticsState,
    action: EventAnalyticsActions<boolean>
  ): EventAnalyticsState => {
    return { ...state, viewAllData: action.payload };
  },
};

export const EventAnalyticsReducer = (
  state: EventAnalyticsState,
  action: EventAnalyticsActions<any>
): EventAnalyticsState => {
  return dispatchers[action.type](state, action);
};
