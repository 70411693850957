import React, { ReactElement } from 'react';
import Tab from '@material-ui/core/Tab';
import { withStyles, createStyles } from '@material-ui/styles';
import { navTabItemStyles } from '../material-styles';
import TabIconWrapper from '../TabIconWrapper/tab-icon-wrapper';

interface StyledTabProps {
  value?: string;
  label: string;
  icon: ReactElement;
}

const NavTab = withStyles(() => createStyles(navTabItemStyles))((props: StyledTabProps) => (
  <Tab disableRipple {...props} icon={<TabIconWrapper>{props.icon}</TabIconWrapper>} />
));

export default NavTab;
