import { COLOR_SET_SOURCE } from './color-set';
import { api, socialAnalyticsProxyApi, telemetryApi } from '../../../v2.snapshot/js/jcs';
import { EmbedPlayerContextType } from '../../contexts/eventanalytics/embedplayer';
import { StreamContextType } from '../../contexts/eventanalytics/stream';
import { AllDestinationsContextType } from '../../contexts/eventanalytics/alldestinations';
import { FacebookContextType } from '../../contexts/eventanalytics/facebook';
import { BracketReference, BracketRefNumber } from './types';
import { YoutubeContextType } from '../../contexts/eventanalytics/youtube';

export const baseURL = `${telemetryApi.v1}/customers`;

export const socialAnalyticsProxyUrl = `${socialAnalyticsProxyApi.v1}/customers`;

export const centralBaseURL = `${api.url_v3}/customers`;

export enum DESTINATION_TYPES {
  ALL_DESTINATIONS = 'all_destinations',
  EMBED = 'embed',
  STREAM = 'stream_url',
  YOUTUBE = 'youtube',
  FACEBOOK = 'facebook',
}

export enum SORT_DIRECTION {
  asc,
  desc,
}

export const fixedDateRangeValues = ['m0', 'm1', 'm2', 'y0', 'y1'];

export enum TOOLTIP_LABEL {
  VIEWERS = 'viewers',
  SECONDS = 'seconds',
  VIEWS = 'views',
}

export const STATS_KEYS_ALL_DESTINATIONS = {
  VIEWS: {
    COUNT_KEY: 'views',
    PERCENTAGE_KEY: 'viewsPercentageInc',
  },
};

export const ALL_STATS_KEYS = {
  VIEWERS: {
    COUNT_KEY: 'viewers',
    PERCENTAGE_KEY: 'viewersPercentageInc',
  },
  VIEWS: {
    COUNT_KEY: 'views',
    PERCENTAGE_KEY: 'viewsPercentageInc',
  },
  AVG_WATCH_TIME: {
    COUNT_KEY: 'avgTimeWatched',
    PERCENTAGE_KEY: 'avgTimeWatchedPercentageInc',
  },
  TOTAL_TIME_WATCHED: {
    COUNT_KEY: 'totalTimeWatched',
    PERCENTAGE_KEY: 'totalTimeWatchedPercentageInc',
  },
  NEW_VIEWERS: {
    COUNT_KEY: 'newViewers',
    PERCENTAGE_KEY: 'newViewersPercentageInc',
  },
  RETURN_VIEWERS: {
    COUNT_KEY: 'returnViewers',
    PERCENTAGE_KEY: 'returnViewersPercentageInc',
  },
  PEAK_CONCURRENT: {
    COUNT_KEY: 'peakConcurrentViewers',
    PERCENTAGE_KEY: 'peakConcurrentPercentageViewersInc',
  },
  MEDIAN_TIME_WATCHED: {
    COUNT_KEY: 'medianTimeWatched',
    PERCENTAGE_KEY: 'medianTimeWatchedPercentageInc',
  },
  PEAK_CONCURRENT_LIVE_VIEWS: {
    COUNT_KEY: 'peakConcurrentLiveViews',
    PERCENTAGE_KEY: 'peakConcurrentLiveViewsPercentageInc',
  },
  TOTAL_MINUTES_WATCHED: {
    COUNT_KEY: 'totalMinutesWatched',
    PERCENTAGE_KEY: 'totalMinutesWatchedPercentageInc',
  },
  LIVE_VIEWS: {
    COUNT_KEY: 'views',
    PERCENTAGE_KEY: 'viewsPercentageInc',
  },
};

export const STATS_KEYS_EMBED = {
  VIEWERS: {
    COUNT_KEY: 'viewers',
    PERCENTAGE_KEY: 'viewersPercentageInc',
  },
  VIEWS: {
    COUNT_KEY: 'views',
    PERCENTAGE_KEY: 'viewsPercentageInc',
  },
  AVG_WATCH_TIME: {
    COUNT_KEY: 'avgTimeWatched',
    PERCENTAGE_KEY: 'avgTimeWatchedPercentageInc',
  },
  TOTAL_TIME_WATCHED: {
    COUNT_KEY: 'totalTimeWatched',
    PERCENTAGE_KEY: 'totalTimeWatchedPercentageInc',
  },
  NEW_VIEWERS: {
    COUNT_KEY: 'newViewers',
    PERCENTAGE_KEY: 'newViewersPercentageInc',
  },
  RETURN_VIEWERS: {
    COUNT_KEY: 'returnViewers',
    PERCENTAGE_KEY: 'returnViewersPercentageInc',
  },
  PEAK_CONCURRENT: {
    COUNT_KEY: 'peakConcurrentViewers',
    PERCENTAGE_KEY: 'peakConcurrentPercentageViewersInc',
  },
  MEDIAN_TIME_WATCHED: {
    COUNT_KEY: 'medianTimeWatched',
    PERCENTAGE_KEY: 'medianTimeWatchedPercentageInc',
  },
};

export const STATS_KEYS_STREAM = {
  VIEWERS: {
    COUNT_KEY: 'viewers',
    PERCENTAGE_KEY: 'viewersPercentageInc',
  },
  NEW_VIEWERS: {
    COUNT_KEY: 'newViewers',
    PERCENTAGE_KEY: 'newViewersPercentageInc',
  },
  RETURN_VIEWERS: {
    COUNT_KEY: 'returnViewers',
    PERCENTAGE_KEY: 'returnViewersPercentageInc',
  },
};

export const STATS_KEYS_LIVE_YOUTUBE = {
  LIVE_VIEWS: {
    COUNT_KEY: 'views',
    PERCENTAGE_KEY: 'viewsPercentageInc',
  },
};

export const HISTORICAL_YT_STATS_KEYS = {
  VIEWS: {
    COUNT_KEY: 'views',
    PERCENTAGE_KEY: 'viewsPercentageInc',
  },
  AVG_WATCH_TIME: {
    COUNT_KEY: 'avgTimeWatched',
    PERCENTAGE_KEY: 'avgTimeWatchedPercentageInc',
  },
  TOTAL_TIME_WATCHED: {
    COUNT_KEY: 'totalTimeWatched',
    PERCENTAGE_KEY: 'totalTimeWatchedPercentageInc',
  },
};

export const STATS_KEYS_FACEBOOK = {
  VIEWERS: {
    COUNT_KEY: 'viewers',
    PERCENTAGE_KEY: 'viewersPercentageInc',
  },
  VIEWS: {
    COUNT_KEY: 'views',
    PERCENTAGE_KEY: 'viewsPercentageInc',
  },
  AVG_WATCH_TIME: {
    COUNT_KEY: 'avgTimeWatched',
    PERCENTAGE_KEY: 'avgTimeWatchedPercentageInc',
  },
  TOTAL_TIME_WATCHED: {
    COUNT_KEY: 'totalTimeWatched',
    PERCENTAGE_KEY: 'totalTimeWatchedPercentageInc',
  },
};

export const STATS_KEYS_FACEBOOK_PAGE = {
  VIEWERS: {
    COUNT_KEY: 'viewers',
    PERCENTAGE_KEY: 'viewersPercentageInc',
  },
  VIEWS: {
    COUNT_KEY: 'views',
    PERCENTAGE_KEY: 'viewsPercentageInc',
  },
  AVG_WATCH_TIME: {
    COUNT_KEY: 'avgTimeWatched',
    PERCENTAGE_KEY: 'avgTimeWatchedPercentageInc',
  },
  TOTAL_TIME_WATCHED: {
    COUNT_KEY: 'totalTimeWatched',
    PERCENTAGE_KEY: 'totalTimeWatchedPercentageInc',
  },
  PEAK_CONCURRENT_LIVE_VIEWS: {
    COUNT_KEY: 'peakConcurrentLiveViews',
    PERCENTAGE_KEY: 'peakConcurrentLiveViewsPercentageInc',
  },
};

export const STATS_KEYS_FACEBOOK_GROUP_TIMELINE = {
  PEAK_CONCURRENT_LIVE_VIEWS: {
    COUNT_KEY: 'peakConcurrentLiveViews',
    PERCENTAGE_KEY: 'peakConcurrentLiveViewsPercentageInc',
  },
};

export enum PAGE_VALUE {
  VIEWERS = 'viewers',
  AVG_WATCH_TIME = 'avgWatchTime',
  MINUTE_BY_MINUTE = 'minuteByminute',
  LIVE_VIEWS = 'liveViews',
  ALL_DESTINATIONS = 'views',
  VIEWS = 'views',
}

export enum PAGE {
  VIEWERS,
  AVG_WATCH_TIME,
  MINUTE_BY_MINUTE,
}

export enum STREAM_URL_PAGES {
  VIEWERS,
  MINUTE_BY_MINUTE,
}

export enum GROUP_BY_ALL_DESTINATION {
  NONE = 'none',
  DESTINATION_TYPE = 'destinations',
}

export enum GROUP_VALUES {
  NONE = 'none',
  EVENT_TYPE = 'event_type',
  DEVICE_TYPE = 'device_type',
}

export enum ALL_DESTINATIONS_STATE_CONTEXT_KEYS {
  GROUP_BY = 'groupBy',
}

export enum EMBED_PAGE_STATE_CONTEXT_KEYS {
  GROUP_BY = 'groupBy',
}

export enum EVENT_ANALYTICS_CONTEXT_KEYS {
  VIEW_ALL_DATA = 'viewAllData',
  EVENT_ID = 'eventId',
  CUSTOMER_ID = 'customerId',
  TOKEN = 'token',
  DATE_RANGE = 'dateRange',
  DATE_RANGE_VALUE = 'dateRangeValue',
}

export enum TAB_VALUES_NUMBERS {
  NONE,
  EVENT_TYPE,
  DEVICE_TYPE,
}

export enum STREAM_STATE_CONTEXT_KEYS {
  GROUP_BY = 'groupBy',
}

export enum FACEBOOK_DESTINATION_TYPES {
  PAGE = 'page',
  TIMELINE = 'timeline',
  GROUP = 'group',
}

export enum YOUTUBE_PAGE_STATE_CONTEXT_KEYS {
  GROUP_BY = 'groupBy',
  PAGE = 'page',
}

export enum FACEBOOK_PAGE_STATE_CONTEXT_KEYS {
  DESTINATION_TYPE = 'destinationType',
}

export const INITIAL_ALL_DESTINATIONS_PAGE_STATE: AllDestinationsContextType = {
  groupBy: GROUP_BY_ALL_DESTINATION.NONE,
};

export const INITIAL_EMBED_PAGE_STATE: EmbedPlayerContextType = {
  [EMBED_PAGE_STATE_CONTEXT_KEYS.GROUP_BY]: GROUP_VALUES.NONE,
};

export const INITIAL_STREAM_PAGE_STATE: StreamContextType = {
  groupBy: GROUP_VALUES.NONE,
};

export const INITIAL_YOUTUBE_PAGE_STATE: YoutubeContextType = {
  [YOUTUBE_PAGE_STATE_CONTEXT_KEYS.GROUP_BY]: GROUP_VALUES.NONE,
  [YOUTUBE_PAGE_STATE_CONTEXT_KEYS.PAGE]: PAGE_VALUE.LIVE_VIEWS,
};

export const INITIAL_FACEBOOK_PAGE_STATE: FacebookContextType = {
  [FACEBOOK_PAGE_STATE_CONTEXT_KEYS.DESTINATION_TYPE]: null,
};

export enum TAB_VALUES_EMBED {
  RESOLUTION,
  WATCH_TIME,
  DEVICE_TYPE,
  LOCATION,
}
export enum TABLE_HEADER_EMBED {
  RESOLUTION = 'Resolution',
  WATCH_TIME = 'Watch Time',
  DEVICE_TYPE = 'Device Type',
  LOCATION = 'Location',
}

export enum TAB_VALUES_STREAM {
  DEVICE_TYPE,
  LOCATION,
}
export enum TABLE_HEADER_STREAM {
  DEVICE_TYPE = 'Device Type',
  LOCATION = 'Location',
}

export const API_RESPONSE_DIFFS = {
  RESOLUTION: 'resolution',
  WATCH_TIME: 'watchTime',
  EVENT_TYPE: 'eventType',
  DEVICE_TYPE: 'deviceType',
  START_TIME: 'startTime',
  END_TIME: 'endTime',
  LOCATION: {
    COUNTRY: 'country',
    REGION: 'region',
    CITY: 'city',
  },
};

export enum LOCATIONS_TAB_VALUES {
  COUNTRY,
  REGION,
  CITY,
}

export enum SUB_LOCATIONS {
  COUNTRY = 'Country',
  REGION = 'Region',
  CITY = 'City',
}

export enum LOCATION_VALUES {
  COUNTRY,
  REGION,
  CITY,
}

export enum TIME_WINDOW_VALUES {
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
  MINUTE = 'minute',
}

export const pages: BracketRefNumber<string> = {
  0: PAGE_VALUE.VIEWERS,
  1: PAGE_VALUE.AVG_WATCH_TIME,
};

export const EMBED_PLAYER_PAGES: BracketRefNumber<string> = {
  0: PAGE_VALUE.VIEWERS,
  1: PAGE_VALUE.AVG_WATCH_TIME,
  2: PAGE_VALUE.MINUTE_BY_MINUTE,
};

export const STRAM_URL_DATA_PAGES: BracketRefNumber<string> = {
  0: PAGE_VALUE.VIEWERS,
  1: PAGE_VALUE.MINUTE_BY_MINUTE,
};

export const FACEBOOK_PAGE: BracketRefNumber<string> = {
  0: PAGE_VALUE.VIEWERS,
  1: PAGE_VALUE.AVG_WATCH_TIME,
  2: PAGE_VALUE.LIVE_VIEWS,
};

export const FACEBOOK_GROUP_TIMELINE: BracketRefNumber<string> = {
  0: PAGE_VALUE.LIVE_VIEWS,
};

export const YOUTUBE_PAGE: BracketRefNumber<string> = {
  0: PAGE_VALUE.VIEWS,
  1: PAGE_VALUE.AVG_WATCH_TIME,
};

export const ALL_DESTINATIONS_GROUP_SET: BracketRefNumber<string> = {
  0: GROUP_BY_ALL_DESTINATION.NONE,
  1: GROUP_BY_ALL_DESTINATION.DESTINATION_TYPE,
};

export const GROUP_SET: BracketRefNumber<string> = {
  0: GROUP_VALUES.NONE,
  1: GROUP_VALUES.EVENT_TYPE,
  2: GROUP_VALUES.DEVICE_TYPE,
};

export const TIME_WINDOW_SET: BracketRefNumber<string> = {
  0: TIME_WINDOW_VALUES.DAY,
  1: TIME_WINDOW_VALUES.WEEK,
  2: TIME_WINDOW_VALUES.MONTH,
};

export const tabHeaders: BracketRefNumber<string> = {
  0: 'Viewers By Resolution',
  1: 'Views By Total Watch Time',
  2: 'Viewers By Device Type',
};

export const tabHeadersStreamURL: BracketRefNumber<string> = {
  0: 'Viewers By Device Type',
};

export const locationTabHeaders: BracketRefNumber<string> = {
  0: 'Countries By Viewers',
  1: 'Regions By Viewers',
  2: 'Cities By Viewers',
};

export const EXTRA_INFO = 'extraInfo';

export const allDeviceTypeOption = {
  label: 'All Devices',
  value: 'All',
};

export const API_RESPONSE_LEGENDS = {
  EMBED: 'embed',
  STREAM_URL: 'streamUrl',
  YOUTUBE: 'youtube',
  FACEBOOK: 'facebook',
  NONE: 'none',
  LIVE: 'live',
  SIM_LIVE: 'simLive',
  ON_DEMAND: 'onDemand',
  DESKTOP: 'desktop',
  MOBILE: 'mobile',
  TABLET: 'tablet',
  GAMECONSOLE: 'gameConsole',
  TV: 'tv',
  SET_TOP_BOX: 'setTopBox',
  OTHER: 'other',
};

export const LEGEND_NAMES: BracketReference<string> = {
  embed: 'Embed Player',
  streamUrl: 'Stream URL',
  youtube: 'YouTube',
  facebook: 'Facebook',
  totalViewers: 'Total Viewers',
  avgWatchTime: 'Avg Watch Time',
  totalViews: 'Total Views',
  live: 'Live',
  simLive: 'Sim-Live',
  onDemand: 'On Demand',
  desktop: 'Desktop',
  mobile: 'Mobile',
  tablet: 'Tablet',
  gameConsole: 'Game Console',
  tv: 'TV',
  setTopBox: 'Set Top Box',
  other: 'Other',
};

export enum SUB_LOCATIONS_ENUM {
  'Country',
  'Region',
  'City',
}

export enum CHART_TYPES {
  DOUGHNUT = 'doughnut',
  BAR = 'bar',
}

export const LOADER_SIZE_COMPONENT = '46px';
export const LOADER_SIZE_SUB_COMPONENT = '24px';

export const LINE_CHART_DEFAULTS = {
  data: [],
  label: '',
  fill: true,
  pointStyle: 'circle' as const,
  pointBorderWidth: 3,
  pointRadius: 3,
  lineTension: 0.05,
  backgroundColor: COLOR_SET_SOURCE.UI_POSITIVE1.fillColor,
  borderColor: COLOR_SET_SOURCE.UI_POSITIVE1.baseColor,
  borderCapStyle: 'butt' as const,
  borderDash: [],
  borderDashOffset: 0.0,
  borderJoinStyle: 'miter' as const,
  pointBorderColor: COLOR_SET_SOURCE.UI_POSITIVE1.baseColor,
  pointBackgroundColor: '#fff',
  pointHoverRadius: 5,
  pointHoverBackgroundColor: COLOR_SET_SOURCE.UI_POSITIVE1.baseColor,
  pointHoverBorderColor: COLOR_SET_SOURCE.UI_POSITIVE1.baseColor,
  pointHoverBorderWidth: 4,
  pointHitRadius: 10,
};

// COLORSET:=========================
export const BRAND_NAVY_COLOR = '#475d7a';

export const BORDER_GREY = '#e9e9e9';

// COLOR SOURCE OBJECT
export const COLOR_SET = Object.values(COLOR_SET_SOURCE);

export const DOUGHNUT_COLOR_SET = [
  COLOR_SET_SOURCE.UI_ERROR,
  COLOR_SET_SOURCE.BRAND_DEEP_NAVY,
  COLOR_SET_SOURCE.CHART_DULL_YELLOW,
  COLOR_SET_SOURCE.CHART_SKY,
  COLOR_SET_SOURCE.NAVY_TINT4,
  COLOR_SET_SOURCE.UI_WARNING2,
  COLOR_SET_SOURCE.CHART_VIOLET2,
  COLOR_SET_SOURCE.CHART_FADE_RED,
  COLOR_SET_SOURCE.CHART_GOLD,
  COLOR_SET_SOURCE.CHART_EARTH_GREEN,
  COLOR_SET_SOURCE.UI_WARNING1,
  COLOR_SET_SOURCE.CHART_LAVENDER,
  COLOR_SET_SOURCE.CHART_VIOLET,
  COLOR_SET_SOURCE.CHART_TILE_RED,
  COLOR_SET_SOURCE.CHART_BLOOM_GREEN,
];

export enum TOP_LOCATION_STATS_TYPE {
  TOP_COUNTRY_STATS = 'top10countryStats',
  TOP_REGION_STATS = 'top10regionStats',
  TOP_CITY_STATS = 'top10cityStats',
  TOP_COUNTRY_STATS_GROUP_BY = 'top10countryStatsGroupBy',
  TOP_REGION_STATS_GROUP_BY = 'top10regionStatsGroupBy',
  TOP_CITY_STATS_GROUP_BY = 'top10cityStatsGroupBy',
}

export enum EMBED_SEGMENTED_REDUCER_TYPE {
  TAB_VALUE = 'tabValue',
  LOCATION_TAB_VALUE = 'locationTabValue',
  SHOW_LOCATION_TAB = 'showLocationTab',
  RESOLUTION_STATS = 'resolutionStats',
  WATCHTIME_STATS = 'watchTimeStats',
  WATCHTIME_TABLE_STATS = 'watchTimeTableStats',
  DEVICE_STATS = 'deviceStats',
  COUNTRY_STATS = 'countryStats',
  REGION_STATS = 'regionStats',
  CITY_STATS = 'cityStats',
  RESOLUTION_STATS_GROUP_BY = 'resolutionStatsGroupBy',
  DEVICE_STATS_GROUP_BY = 'deviceStatsGroupBy',
  COUNTRY_STATS_GROUP_BY = 'countryStatsGroupBy',
  REGION_STATS_GROUP_BY = 'regionStatsGroupBy',
  CITY_STATS_GROUP_BY = 'cityStatsGroupBy',
}

export enum STREAM_SEGMENTED_REDUCER_TYPE {
  TAB_VALUE = 'tabValue',
  LOCATION_TAB_VALUE = 'locationTabValue',
  SHOW_LOCATION_TAB = 'showLocationTab',
  DEVICE_STATS = 'deviceStats',
  COUNTRY_STATS = 'countryStats',
  REGION_STATS = 'regionStats',
  CITY_STATS = 'cityStats',
  DEVICE_STATS_GROUP_BY = 'deviceStatsGroupBy',
  COUNTRY_STATS_GROUP_BY = 'countryStatsGroupBy',
  REGION_STATS_GROUP_BY = 'regionStatsGroupBy',
  CITY_STATS_GROUP_BY = 'cityStatsGroupBy',
}

export const MAX_FETCH_LIMIT_FOR_HISTORICAL_EVENTS = 10;
export const MAX_FETCH_LIMIT_FOR_CSV_EXPORT = 1000;
export const MAX_FETCH_CALLS_FOR_CSV = 100;
export const EVENT_LIST_TABLE_PAGE_SIZE = 6;

export const CSV_EXPORT_FIELDS = [
  'eventId',
  'clientId',
  'sessionId',
  'timestamp',
  'eventType',
  'ipAddress',
  'city',
  'state',
  'country',
  'latitude',
  'longitude',
  'totalTimeWatchedSeconds',
  'userAgent',
  'resolution',
  'deviceClass',
  'deviceBrand',
  'osClass',
  'osNameVersion',
];

export const STREAM_CSV_EXPORT_FIELDS = CSV_EXPORT_FIELDS.filter(
  (field) => !['totalTimeWatchedSeconds'].includes(field)
);

export const FB_CSV_EXPORT_FIELDS = [
  'eventId',
  'socialId',
  'destinationId',
  'videoId',
  'liveVideoId',
  'destinationType',
  'apiSource',
  'timestamp',
  'views',
  'viewers',
  'averageTimeWatchedSeconds',
  'totalTimeWatchedSeconds',
  'liveViews',
];

export const EVENT_NAMES_EXPORT = 'Events_CSV';

export const ZOOM_CTRL_TOOLTIP =
  'This is a zoom-enabled chart. You can pinch, use your touchpad or use your mouse wheel to zoom in and out. You can also use the reset button to reset the zoom.';

export const FB_PAGE = 'This data only includes viewers for events sent to pages.';
export const FB_GROUP = 'This data only includes viewers for events sent to groups.';
export const FB_TIMELINE = 'This data only includes viewers for events sent to Facebook timeline.';
