'use strict';

class CueListCtrl {

	constructor(Authentication) {
		'ngInject';

		this.current_user = Authentication.getCurrentUser();

		this.is_admin = this.current_user.hasPerm('cues.delete');
	}

	canShowGoToBtn = function () {
		return this.isVideoPlayerAvailable;
	}

	canShowUpdateBtn = function (cue) {
		return this.isVideoPlayerAvailable && (this.current_user.name == cue.user || this.is_admin);
	}

	canShowEditBtn = function (cue) {
		return this.current_user.name == cue.user || this.is_admin;
	}

	canShowDeleteBtn = function (cue) {
		return this.current_user.name == cue.user || this.is_admin;
	}
}

export default class CueListComponent {
	constructor() {
		this.template = require('./cueList.html').default;
		this.bindings = {
			cues: '<',
			isLoading: '<',
			isVideoPlayerAvailable: '<', // used to determine if some UI button elements should be visible (ex: GoTo)
			onGoto: '&',
			onUpdate: '&',
			onEdit: '&',
			onDelete: '&',
		};
		this.controller = CueListCtrl;
	}
}
