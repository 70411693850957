'use strict';

const jcs = require('../../jcs');
const moment = require('moment');
const daterangepicker = require('daterangepicker');
const { trackMixpanelEvent, MPEventProperty, MPEventName} = require('../../../../src/mixpanel');
require('bootstrap');

function EncodersController($scope, $timeout, $http, $q, formValidationService, uiService, encoderService, focus, Authentication) {
	'ngInject';

	$(window).trigger('resize'); // ensure footer is properly positioned

	$scope.encoderSvc = encoderService;
	$scope.validation = formValidationService.init();

	$scope.TIME_DATE_FORMAT = 'MMM D, YYYY h:mm:ss A';

	$scope.loading_encoders_timeout_id = null;
	$scope.loading_encoder_info_timeout_id = null;
	$scope.CHECK_ENCODER_STATUS_TIME_DELAY_SHORT = 8500; // 8.5 seconds
	$scope.CHECK_ENCODER_STATUS_TIME_DELAY_LONG = 45000; // 45 seconds
	$scope.CHECK_ENCODER_INFO_TIME_DELAY = 15000; // 15 seconds

	$scope.LAN_MODE_ENABLED = 'On';
	$scope.LAN_MODE_DISABLED = 'Off';
	$scope.LAN_MODE_DEFAULT_OPTION = $scope.LAN_MODE_DISABLED;
	$scope.lan_mode_options = [$scope.LAN_MODE_ENABLED, $scope.LAN_MODE_DISABLED];

	$scope.DHCP_ON = true;
	$scope.DHCP_OFF = false;
	$scope.nic_dhcp_options = [$scope.DHCP_ON, $scope.DHCP_OFF];

	$scope.CIDR_DEFAULT = 24;
	$scope.nic_cidr_options = [
		{ name: '255.255.255.255', value: 32 },
		{ name: '255.255.255.254', value: 31 },
		{ name: '255.255.255.252', value: 30 },
		{ name: '255.255.255.248', value: 29 },
		{ name: '255.255.255.240', value: 28 },
		{ name: '255.255.255.224', value: 27 },
		{ name: '255.255.255.192', value: 26 },
		{ name: '255.255.255.128', value: 25 },
		{ name: '255.255.255.0', value: 24 },
		{ name: '255.255.254.0', value: 23 },
		{ name: '255.255.252.0', value: 22 },
		{ name: '255.255.248.0', value: 21 },
		{ name: '255.255.240.0', value: 20 },
		{ name: '255.255.224.0', value: 19 },
	];

	$scope.LOG_TYPE_DEFAULT = 'info';
	$scope.log_type_options = [
		{ name: 'Show All Log Types', value: 'debug' },
		{ name: 'Show Only Info +', value: 'info' },
		{ name: 'Show Only Warn +', value: 'warn' },
		{ name: 'Show Only Error', value: 'error' },
	];

	// capture card description formatting
	$scope.CAP_CARD_DESC_FRONT_DELIMITER = 'at ';
	$scope.CAP_CARD_DESC_BACK_DELIMITER = ' fps';
	$scope.FPS_LOOKUP = {
		'24000/1001': '23.98',
		'24000/1000': '24',
		'25000/1000': '25',
		'30000/1001': '29.97',
		'30000/1000': '30',
		'50000/1000': '50',
		'60000/1000': '60',
		'60000/1001': '59.94',
	};

	$scope.encoders = null;
	$scope.loading_encoders = false;
	$scope.encoders_error_msg = null;

	$scope.is_loading = false;

	$scope.profiles = null; // used to display dropdown
	$scope.streams = null; // used to display dropdown
	$scope.video_input_sources = null; // used to display dropdown
	$scope.encoder_version = '';

	$scope.show_serial_numbers = false;

	// encoder view
	$scope.encoder_to_view = null;
	$scope.encoder_status_data = null;
	$scope.is_loading_sensor_data = false; // activity indicator
	$scope.encoder_status_data_error = null;

	// encoder we want to view logs for
	$scope.encoder_to_view_logs = null;
	$scope.is_busy_loading_logs = false; // activity indicator
	$scope.encoder_logs = null; // the encoder logs that have been loaded
	$scope.encoder_log_warning_count = 0;
	$scope.encoder_log_error_count = 0;
	$scope.log_start_time = '';
	$scope.log_end_time = '';
	$scope.view_logs_error = null;
	$scope.log_type_filter = $scope.LOG_TYPE_DEFAULT;

	// encoder edit
	$scope.encoder_to_edit = null;
	$scope.is_busy_editing_encoder = false; // activity indicator
	$scope.encoder_to_edit_name = '';
	$scope.encoder_to_edit_status = '';
	$scope.encoder_to_edit_profile = '';
	$scope.encoder_to_edit_stream = '';
	$scope.encoder_to_edit_video_input_source = '';
	$scope.encoder_to_edit_lan_mode = $scope.LAN_MODE_DEFAULT_OPTION;
	$scope.encoder_to_edit_lan_capable = false;
	$scope.edit_encoder_error = null;
	// encoder capture card data (which is current on encoder edit page)
	$scope.capture_cards = null;
	$scope.capture_cards_last_update = null;
	$scope.is_busy_loading_capture_cards = false;
	$scope.capture_card_error = null;

	// teamviewer data
	$scope.teamviewer_id = null;
	$scope.teamviewer_pass = null;

	// nic to edit
	$scope.nic_to_edit = null;
	$scope.is_busy_loading_nic = false; // activity indicator
	$scope.is_busy_editing_nic = false; // activity indicator
	$scope.nic_to_edit_name = '';
	$scope.nic_to_edit_dhcp = '';
	$scope.nic_to_edit_ipv4Address = '';
	$scope.nic_to_edit_ipv4Gateway = '';
	$scope.nic_to_edit_ipv4Dns1 = '';
	$scope.nic_to_edit_ipv4Dns2 = '';
	$scope.nic_to_edit_ipv4Dns3 = '';
	$scope.nic_to_edit_ipv4Cidr = '';
	$scope.edit_nic_error = null;

	// encoder to start
	$scope.encoder_to_start = null;
	$scope.is_busy_starting_encoder = false; // activity indicator
	$scope.encoder_to_start_stream = '';
	$scope.encoder_to_start_encoder_profile = '';
	// ============== Manually Start Encoder Warning Code ============== BEGIN
	$scope.encoder_to_start_upcoming_events = null;
	$scope.time_zone_list = null;
	// ============== Manually Start Encoder Warning Code ============== END
	$scope.start_encoder_error = null;

	// encoder to stop
	$scope.encoder_to_stop = null;
	$scope.is_busy_stopping_encoder = false; // activity indicator
	$scope.stop_encoder_error = null;

	// encoder to update
	$scope.encoder_to_update = null;
	$scope.is_busy_updating_encoder = false; // activity indicator
	$scope.update_encoder_error = null;

	// encoder to move
	$scope.encoder_to_move = null;
	$scope.is_busy_moving_encoder = false; // activity indicator
	$scope.encoder_to_move_customer = '';
	$scope.load_customers_error = null;
	$scope.move_encoder_error = null;

	// encoder to delete
	$scope.encoder_to_delete = null;
	$scope.is_busy_deleting_encoder = false; // activity indicator
	$scope.delete_encoder_error = null;

	// encoder to restart
	$scope.encoder_to_restart = null;
	$scope.is_busy_restarting_encoder = false; // activity indicator
	$scope.restart_encoder_error = null;

	// encoder to upload logs
	$scope.encoder_to_upload_logs = null;
	$scope.is_busy_uploading_logs = false; // activity indicator
	$scope.upload_encoder_logs_error = null;

	$scope.getCurrentUser = function () {
		return Authentication.getCurrentUser();
	};

	$scope.isSoftwareEncoder = function (encoder) {
		return encoderService.isSoftwareEncoder(encoder);
	};

	$scope.canShowDetailsBtn = function () {
		return true;
	};

	$scope.canUpdateEncoders = function () {
		return Authentication.getCurrentUser().hasPerm('encoders.update');
	};

	$scope.canShowViewLogsBtn = function () {
		return Authentication.getCurrentUser().hasPerm('encoders.view_logs');
	};

	$scope.canShowUploadLogsBtn = function (encoder) {
		return Authentication.getCurrentUser().hasPerm('encoders.upload_logs') && encoder.status == 'stopped' && !encoderService.isSoftwareEncoder(encoder);
	};

	$scope.canShowUpdateBtn = function (encoder) {
		if (Authentication.getCurrentUser().hasPerm('la1only')) {
			// always show update btn to resi admins (unless software encoder or not stopped)
			return !encoderService.isSoftwareEncoder(encoder) && encoder.status === "stopped";
		} else {
			// TODO: (eStacy 7/12/2022 ) will revert the comment for all customers who have the permission after testing with resi admins
			// return Authentication.getCurrentUser().hasPerm('encoders.update_version') && !encoderService.isSoftwareEncoder(encoder) && encoder.updateAvailable && encoder.status === "stopped";
			return false;
		}
	};

	$scope.canShowRestartBtn = function (encoder) {
		return Authentication.getCurrentUser().hasPerm('encoders.restart') && !encoderService.isSoftwareEncoder(encoder) && encoder.status === "stopped";
	};

	$scope.canSeeBMDVersion = function(encoder_to_view){
		return Authentication.getCurrentUser().hasPerm('la1only') && !encoderService.isSoftwareEncoder(encoder_to_view);
	};

	$scope.canShowMoveBtn = function () {
		return Authentication.getCurrentUser().hasPerm('encoders.move');
	};

	$scope.canShowDeleteBtn = function (encoder) {
		if (encoderService.isSoftwareEncoder(encoder)){
			return Authentication.getCurrentUser().hasPerm('uploaders.delete') && encoder.status === 'offline';
		}
		return Authentication.getCurrentUser().hasPerm('encoders.delete') && encoder.status === 'offline';
	};

	$scope.canShowStartBtn = function (encoder) {
		return encoder.requestedStatus != 'start' && encoder.status == 'stopped';
	};

	$scope.canShowStopBtn = function (encoder) {
		return encoder.requestedStatus == 'start';
	};

	$scope.canShowVideoInputSource = function (encoder) {
		if (encoder === null){
			return false;
		}
		if (encoder.input_src_chg_requires_reboot){
			// if a encoder requires a reboot to change the input source, then only let la1 users perform that change. For now, customers
			// can only change the input source on encoders that don't require a restart.
			return Authentication.getCurrentUser().hasPerm('la1only');
		}
		return Authentication.getCurrentUser().hasPerm('encoders.input.update');
	};

	$scope.toggleSerialNumbers = function (){
		$scope.show_serial_numbers = !$scope.show_serial_numbers;
	};

	$scope.formatSerialNumber = function (serial_number){
		if (serial_number === $scope.encoderSvc.SOFTWARE_ENCODER_MODEL){
			return 'Software Encoder';
		}
		return serial_number;
	};

	$scope.enterViewEncoderMode = function (encoder) {
		$scope.encoder_to_view = encoder;

		$scope.loadEncoderSensorData(encoder);

		// fetch the encoder capture cards
		$scope.loadEncoderCaptureCards(encoder);

		// fetch the encoder teamviewer
		if (Authentication.getCurrentUser().hasPerm('encoders.teamviewer')) {
			$scope.loadTeamViewer(encoder);
		}

		trackMixpanelEvent(MPEventName.ENCODER_DETAILS, {
			[MPEventProperty.ENCODER_PROFILE_NAME]: $scope.encoder_to_view.name,
			[MPEventProperty.ENCODER_UUID]: $scope.encoder_to_view.uuid,
			[MPEventProperty.ENCODER_VERSION]: $scope.encoder_to_view.encoderVersion,
		});

		$scope.loading_encoder_info_timeout_id = window.setTimeout(
			$scope.loadEncoderInfoSilent,
			$scope.CHECK_ENCODER_INFO_TIME_DELAY
		);
	};

	$scope.loadEncoderInfoSilent = function () {
		// if the user is no longer viewing the encoder details page, then we don't need to do anything
		if ($scope.encoder_to_view != null) {
			$scope.loadEncoderSensorDataSilent();
			$scope.loadEncoderCaptureCardsSilent();

			$scope.loading_encoder_info_timeout_id = window.setTimeout(
				$scope.loadEncoderInfoSilent,
				$scope.CHECK_ENCODER_INFO_TIME_DELAY
			);
		}
	};

	// note: $scope.encoder_to_view needs to be set before calling this method
	$scope.loadEncoderSensorData = function () {
		if ($scope.encoder_to_view != null) {
			$scope.encoder_status_data = null;
			$scope.is_loading_sensor_data = true;
			$scope.encoder_status_data_error = null;

			// load the sensor data for this encoder
			$http
				.get(jcs.api.url + '/encoders/' + $scope.encoder_to_view.uuid + '/status', {
					withCredentials: true,
				})
				.then(
					function (response) {
						// success

						$scope.encoder_status_data = response.data;
					},
					function () {
						// error

						$scope.encoder_status_data_error = true;
						$scope.encoder_status_data = null;
					}
				)
			['finally'](function () {
				// always called

				$scope.is_loading_sensor_data = false;
			});
		}
	};

	// note: $scope.encoder_to_view needs to be set before calling this method
	// Normally we would show a "loading ..." message when the sensor data is loading, but since this function is being called in the background,
	// we just want the data to auto update without any flashing. So that is why we have a "silent" version of this method.
	$scope.loadEncoderSensorDataSilent = function () {
		if ($scope.encoder_to_view != null) {
			// load the sensor data for this encoder
			$http
				.get(jcs.api.url + '/encoders/' + $scope.encoder_to_view.uuid + '/status', {
					withCredentials: true,
				})
				.then(
					function (response) {
						// success

						$scope.encoder_status_data_error = null; // if we did have an error, clear it since we had a success
						$scope.encoder_status_data = response.data;
					},
					function () {
						// error
					}
				);
		}
	};

	$scope.enterStartEncoderMode = function (encoder) {
		$scope.start_encoder_error = null;
		$scope.encoder_to_start_upcoming_events = null; // <=== Manually Start Encoder Warning Code

		$scope.is_loading = true;

		const promises = [];
		promises.push($http.get(`${jcs.api.url}/encoderprofiles`, { withCredentials: true }));
		promises.push($http.get(`${jcs.api.url}/streamprofiles`, { withCredentials: true }));
		promises.push($http.get(`${jcs.api.url}/schedules/upcoming?12hours=true`, { withCredentials: true })); // <=== Manually Start Encoder Warning Code
		promises.push(encoderService.getEncoderVersion(encoder.uuid));

		$q.all(promises).then(([profiles, streams, upcoming_schedules, encoder_version]) => {

			// process responses
			$scope.profiles = profiles.data;
			$scope.profiles.sort(function (a, b) {
				return a.name.localeCompare(b.name);
			});

			$scope.streams = streams.data;
			$scope.streams.sort(function (a, b) {
				return a.description.localeCompare(b.description);
			});

			// ============== Manually Start Encoder Warning Code ============== BEGIN
			if (upcoming_schedules.data != null && upcoming_schedules.data.length > 0) {
				$scope.encoder_to_start_upcoming_events = [];
				for (let i = 0; i < upcoming_schedules.data.length; i++) {
					const event = upcoming_schedules.data[i];
					if (event.encoderName === encoder.name && event.enabled === true) {
						$scope.encoder_to_start_upcoming_events.push(event);
					}
				}
			}
			// ============== Manually Start Encoder Warning Code ============== END

			$scope.encoder_version = encoder_version;

			// do remaining work
			$scope.encoder_to_start = encoder;
			// initialize selected event and encoder profiles
			$scope.encoder_to_start_stream = encoder.streamProfile != null ? encoder.streamProfile.uuid : '';
			$scope.encoder_to_start_encoder_profile = encoder.encoderProfile != null ? encoder.encoderProfile.uuid : '';

		}).catch(reason => {

			$scope.profiles = null;
			$scope.streams = null;
			$scope.encoders_error_msg = 'An error was encountered trying to load information needed to start this encoder. Please try again, or report the problem if it persists. ';

		}).finally(() => {

			$scope.is_loading = false;

		});
	};

	$scope.enterStopEncoderMode = function (encoder) {
		$scope.encoder_to_stop = encoder;
	};

	$scope.enterUpdateEncoderMode = function (encoder) {
		$scope.encoder_to_update = encoder;
	};

	$scope.enterRestartEncoderMode = function (encoder) {
		$scope.encoder_to_restart = encoder;
	};

	$scope.enterUploadEncoderLogsMode = function (encoder) {
		$scope.encoder_to_upload_logs = encoder;
	};

	$scope.enterMoveEncoderMode = function (encoder) {
		$scope.encoder_to_move = encoder;
		$scope.encoder_to_move_customer = {};
		$scope.load_customers_error = null;

		$scope.is_loading = true;

		// fetch our stream profiles (to build our dropdown)
		$http.get(`${jcs.api.url}/customers/names`, { withCredentials: true }).then(response => {

			$scope.customers = response.data;

		}).catch(reason => {

			$scope.load_customers_error = 'An error occurred while retrieving available customers. Please try again, or report the problem if it persists.';

		}).finally(() => {

			$scope.is_loading = false;

		});
	};

	$scope.enterDeleteEncoderMode = function (encoder) {
		$scope.encoder_to_delete = encoder;
	};

	$scope.enterEditNicMode = function (nic) {
		$scope.nic_to_edit = nic;
		$scope.edit_nic_error = null;
		$scope.is_busy_loading_nic = true;

		$http
			.get(jcs.api.url + '/encoders/' + $scope.encoder_to_view.uuid + '/network', {
				withCredentials: true,
			})
			.then(
				function (response) {
					// success

					if (response.data.length == 0) {
						// no previous settings, so adding network info for first time
						$scope.nic_to_edit_name = nic.displayName;
						$scope.nic_to_edit_dhcp = nic.ipV4s.length >= 1 ? nic.ipV4s[0].dhcp : false;
						$scope.nic_to_edit_ipv4Address = '';
						$scope.nic_to_edit_ipv4Gateway = '';
						$scope.nic_to_edit_ipv4Dns1 = '';
						$scope.nic_to_edit_ipv4Dns2 = '';
						$scope.nic_to_edit_ipv4Dns3 = '';
						$scope.nic_to_edit_ipv4Cidr = $scope.CIDR_DEFAULT;
					} else {
						// editing previous settings
						var network = response.data[0];

						$scope.nic_to_edit_name = network.displayName;
						$scope.nic_to_edit_dhcp = network.dhcp;
						$scope.nic_to_edit_ipv4Address = network.ipv4Address;
						$scope.nic_to_edit_ipv4Gateway = network.ipv4Gateway;
						$scope.nic_to_edit_ipv4Dns1 = network.ipv4Dns1;
						$scope.nic_to_edit_ipv4Dns2 = network.ipv4Dns2;
						$scope.nic_to_edit_ipv4Dns3 = network.ipv4Dns3;
						$scope.nic_to_edit_ipv4Cidr = network.ipv4Cidr;
					}

					// see app.js for where focus is defined
					focus('edit-nic-input');
				},
				function () {
					// error

					$scope.edit_nic_error =
						'An error occurred while retrieving the NIC settings. Please try again, or report the problem if it persists.';
				}
			)
		['finally'](function () {
			// always called

			$scope.is_busy_loading_nic = false;
		});
	};

	$scope.enterViewLogMode = function (encoder) {
		$scope.encoder_to_view_logs = encoder;
		$scope.encoder_logs = null;
	};

	$scope.getEncoderLogsLastXMin = function (minutes) {
		// set start end times to be previous minutes provided
		$scope.log_start_time = moment()
			.subtract(minutes, 'minutes')
			.format($scope.TIME_DATE_FORMAT); // x minutes ago
		$scope.log_end_time = moment().format($scope.TIME_DATE_FORMAT); // right now

		$('#log-time-range')
			.data('daterangepicker')
			.setStartDate($scope.log_start_time);
		$('#log-time-range')
			.data('daterangepicker')
			.setEndDate($scope.log_end_time);

		// fetch the logs for the given time range
		$scope.getEncoderLogs();
	};

	$scope.getEncoderLogs = function () {
		$scope.view_logs_error = null; // if we had an error, clear it

		$scope.is_busy_loading_logs = true;

		// build our query string which will include the start/end times
		var query_string = '';
		if ($scope.log_start_time != '') {
			var formatted_start_time = moment($scope.log_start_time, $scope.TIME_DATE_FORMAT).toISOString();
			query_string += 'start=' + encodeURIComponent(formatted_start_time);
		}
		if ($scope.log_end_time != '') {
			if (query_string != '') {
				query_string += '&';
			}
			var formatted_end_time = moment($scope.log_end_time, $scope.TIME_DATE_FORMAT).toISOString();
			query_string += 'end=' + encodeURIComponent(formatted_end_time);
		}
		// add log type filter
		if ($scope.log_type_filter != '') {
			if (query_string != '') {
				query_string += '&';
			}
			query_string += 'minlevel=' + encodeURIComponent($scope.log_type_filter);
		}

		// fetch our encoder logs
		$http
			.get(jcs.api.url + '/encoders/' + $scope.encoder_to_view_logs.uuid + '/logs?' + query_string, {
				withCredentials: true,
			})
			.then(
				function (response) {
					// success

					$scope.encoder_logs = response.data;
					// determine number of warnings and errors
					$scope.encoder_log_warning_count = 0;
					$scope.encoder_log_error_count = 0;
					for (var i = 0; i < $scope.encoder_logs.length; i++) {
						var log_level = $scope.encoder_logs[i].level.toLowerCase();
						if (log_level == 'warn') {
							$scope.encoder_log_warning_count++;
						} else if (log_level == 'error') {
							$scope.encoder_log_error_count++;
						}
					}

					const start_moment = moment($scope.log_start_time);
					const end_moment = moment($scope.log_end_time);
					const duration_minutes = end_moment.diff(start_moment) / 60000; // convert milliseconds to minutes

					trackMixpanelEvent(MPEventName.LOGS_VIEW, {
						[MPEventProperty.LOG_DURATION]: `${$scope.log_start_time} - ${$scope.log_end_time}`,
						[MPEventProperty.LOG_DURATION_MIN]: duration_minutes,
						[MPEventProperty.LOG_TYPE]: $scope.log_type_filter === 'debug' ? "All" : $scope.log_type_filter,
						[MPEventProperty.ENCODER_UUID]: $scope.encoder_to_view_logs.uuid
					});
				},
				function () {
					// error

					$scope.view_logs_error =
						'An error occurred while retrieving the encoder logs. Please try again, or report the problem if it persists.';
					$scope.encoder_logs = null;
				}
			)
		['finally'](function () {
			// always called

			$scope.is_busy_loading_logs = false;
		});
	};

	// if the input source is changed, then encoders require a reboot unless they have the new INPUT_DETECTION feature
	$scope.doesInputSourceChangeRequireReboot = function (status) {
		if (status !== null && status.hasOwnProperty('encoderFeatures') && status.encoderFeatures !== null){
			if (status.encoderFeatures.includes('INPUT_DETECTION')){
				return false;
			}
		}
		return true;
	}

	$scope.enterEditEncoderMode = function (encoder) {

		$scope.edit_encoder_error = null;

		$scope.is_loading = true;

		let promises = [];
		promises.push($http.get(`${jcs.api.url}/encoderprofiles`, { withCredentials: true }));
		promises.push($http.get(`${jcs.api.url}/streamprofiles`, { withCredentials: true }));
		promises.push(encoderService.getEncoderStatus(encoder.uuid));

		$q.all(promises).then(([profiles, streams, encoder_status]) => {

			// process responses
			$scope.profiles = profiles.data;
			$scope.profiles.sort(function (a, b) {
				return a.name.localeCompare(b.name);
			});

			$scope.streams = streams.data;
			$scope.streams.sort(function (a, b) {
				return a.description.localeCompare(b.description);
			});

			$scope.video_input_sources = encoder_status.availableVideoInputSources;
			$scope.encoder_version = encoder_status.encoderVersion;

			encoder.input_src_chg_requires_reboot = $scope.doesInputSourceChangeRequireReboot(encoder_status);

			// do remaining work
			$scope.encoder_to_edit = encoder;
			$scope.encoder_to_view = null;

			$scope.encoder_to_edit_name = encoder.name;
			$scope.encoder_to_edit_status = encoder.status;
			$scope.encoder_to_edit_profile = encoder.encoderProfile.uuid;
			if (encoder.streamProfile != null) {
				$scope.encoder_to_edit_stream = encoder.streamProfile.uuid;
			}
			$scope.encoder_to_edit_video_input_source = encoder.requestedVideoInputSource;

			$scope.encoder_to_edit_lan_mode = encoder.lanModeEnabled ? $scope.LAN_MODE_ENABLED : $scope.LAN_MODE_DISABLED;
			$scope.encoder_to_edit_lan_capable = encoder.lanModeCapable;

			// see app.js for where focus is defined
			focus('edit-encoder-input');

		}).catch(reason => {

			$scope.encoders_error_msg = 'An error was encountered trying to load information needed to configure this encoder. Please try again, or report the problem if it persists. ';

		}).finally(() => {

			$scope.is_loading = false;

		});
	};

	// reformats description to be more readable; descriptions are returned as: 1920x1080 at 30000/1001 fps
	// but we would like to display something like: 1920x1080 at 29.97 fps
	$scope.reformatCaptureCardDescription = function (description) {
		if (description === null){
			return '';
		}

		var front_index = description.indexOf($scope.CAP_CARD_DESC_FRONT_DELIMITER);
		var back_index = description.indexOf($scope.CAP_CARD_DESC_BACK_DELIMITER);

		// isolate our frame rate
		if (front_index != -1 && back_index != -1) {
			var fps = description.substring(front_index + $scope.CAP_CARD_DESC_FRONT_DELIMITER.length, back_index);
			if ($scope.FPS_LOOKUP.hasOwnProperty(fps)) {
				return (
					description.substring(0, front_index + $scope.CAP_CARD_DESC_FRONT_DELIMITER.length) +
					$scope.FPS_LOOKUP[fps] +
					description.substring(back_index)
				);
			}
		}

		return description;
	};

	// format capture card descriptions to be more readable
	$scope.formatCaptureCardDescriptions = function () {
		if ($scope.capture_cards != null) {
			for (var i = 0; i < $scope.capture_cards.length; i++) {
				for (var j = 0; j < $scope.capture_cards[i].formats.length; j++) {
					$scope.capture_cards[i].formats[j].description = $scope.reformatCaptureCardDescription(
						$scope.capture_cards[i].formats[j].description
					);
				}
			}
		}
	};

	// loads the capture card data for the given encoder
	$scope.loadEncoderCaptureCards = function (encoder) {
		$scope.is_busy_loading_capture_cards = true;
		$scope.capture_cards = null; // ensure we aren't looking at old data
		$scope.capture_cards_last_update = null;

		$http
			.get(jcs.api.url + '/encoders/' + encoder.uuid + '/options', { withCredentials: true })
			.then(
				function (response) {
					// success

					$scope.capture_cards = response.data.captureCards;
					$scope.capture_cards_last_update = response.data.lastUpdate;
					$scope.formatCaptureCardDescriptions();
				},
				function () {
					// error

					$scope.capture_cards = null;
					$scope.capture_cards_last_update = null;
					$scope.capture_card_error =
						'An error occurred while retrieving the capture card info. Please try again, or report the problem if it persists.';
				}
			)
		['finally'](function () {
			// always called

			$scope.is_busy_loading_capture_cards = false;
		});
	};

	// note: $scope.encoder_to_view needs to be set before calling this method
	// Normally we would show a "loading ..." message when the capture card data is loading, but since this function is being called in the background,
	// we just want the data to auto update without any flashing. So that is why we have a "silent" version of this method.
	$scope.loadEncoderCaptureCardsSilent = function () {
		if ($scope.encoder_to_view != null) {
			// only pull capture card data if the encoder is in the 'stopped' state; if for some reason we can't determine the state
			// then go ahead and grab the capture card data.
			if ($scope.encoder_status_data == null || $scope.encoder_status_data.status == 'stopped') {
				$http
					.get(jcs.api.url + '/encoders/' + $scope.encoder_to_view.uuid + '/options', {
						withCredentials: true,
					})
					.then(
						function (response) {
							// success

							$scope.capture_cards = response.data.captureCards;
							$scope.capture_cards_last_update = response.data.lastUpdate;
							$scope.formatCaptureCardDescriptions();
						},
						function () {
							// error
						}
					);
			}
		}
	};

	// loads the teamviewer data for the given encoder
	$scope.loadTeamViewer = function (encoder) {
		$http
			.get(jcs.api.internal_url + '/encoders/' + encoder.uuid + '/teamviewer', { withCredentials: true })
			.then(
				function (response) {
					// success
					$scope.teamviewer_id = response.data.id;
					$scope.teamviewer_pass = response.data.password;
				}
			)
	};

	$scope.cancelView = function () {
		$scope.encoder_to_view = null;
		$scope.capture_cards = null;
		$scope.capture_cards_last_update = null;
		$scope.capture_card_error = null;
		$scope.teamviewer_id = null;
		$scope.teamviewer_pass = null;

		if ($scope.loading_encoder_info_timeout_id != null) {
			window.clearTimeout($scope.loading_encoder_info_timeout_id);
			$scope.loading_encoder_info_timeout_id = null;
		}
	};

	$scope.cancelStartEncoder = function () {
		$scope.validation.clear();

		$scope.encoder_to_start = null;
		$scope.start_encoder_error = null;
	};

	$scope.cancelStopEncoder = function () {
		$scope.encoder_to_stop = null;
		$scope.stop_encoder_error = null;
	};

	$scope.cancelUpdateEncoder = function () {
		$scope.encoder_to_update = null;
		$scope.update_encoder_error = null;
	};

	$scope.cancelRestartEncoder = function () {
		$scope.encoder_to_restart = null;
		$scope.restart_encoder_error = null;
	};

	$scope.cancelUploadEncoderLogs = function () {
		$scope.encoder_to_upload_logs = null;
		$scope.upload_encoder_logs_error = null;
	};

	$scope.cancelMoveEncoder = function () {
		$scope.encoder_to_move = null;
		$scope.move_encoder_error = null;
		$scope.load_customers_error = null;
	};

	$scope.cancelDeleteEncoder = function () {
		$scope.encoder_to_delete = null;
		$scope.delete_encoder_error = null;
	};

	$scope.cancelViewLogMode = function () {
		$scope.encoder_to_view_logs = null;
		$scope.view_logs_error = null;
	};

	$scope.cancelEdit = function () {
		$scope.validation.clear();

		$scope.encoder_to_edit = null;
		$scope.edit_encoder_error = null;
	};

	$scope.cancelEditNic = function () {
		$scope.nic_to_edit = null;
		$scope.edit_nic_error = null;
	};

	$scope.getProfileForID = function (uuid) {
		for (var i = 0; i < $scope.profiles.length; i++) {
			var profile = $scope.profiles[i];
			if (profile.uuid == uuid) return profile;
		}
		return null;
	};

	$scope.getStreamForID = function (uuid) {
		for (var i = 0; i < $scope.streams.length; i++) {
			var stream = $scope.streams[i];
			if (stream.uuid == uuid) return stream;
		}
		return null;
	};

	$scope.doesStartEncoderFailValidation = function () {
		$scope.start_encoder_error = null;
		$scope.validation.clear();

		// ensure required fields are not empty
		$scope.validation.checkForEmpty('encoder_to_start_stream', $scope.encoder_to_start_stream);
		if (!encoderService.isSoftwareEncoder($scope.encoder_to_start)){
			$scope.validation.checkForEmpty('encoder_to_start_encoder_profile', $scope.encoder_to_start_encoder_profile);
		}

		const has_validation_error = $scope.validation.hasError();
		if (has_validation_error) {
			$scope.start_encoder_error = 'Please specify a value for the highlighted fields.';
		}

		return has_validation_error;
	};

	$scope.startEncoder = function () {
		// if we have form validation errors, then don't go any further
		if ($scope.doesStartEncoderFailValidation()){
			return false;
		}

		// did the user select different profiles?
		if ($scope.encoder_to_start_stream !== $scope.encoder_to_start.streamProfile.uuid ||
			$scope.encoder_to_start_encoder_profile !== $scope.encoder_to_start.encoderProfile.uuid) {
			//
			// either a different event profile or encoder profile (or both) were selected, so we need to save
			// the new profile before starting the encoder
			//

			const config_data = {
				streamProfile: {
					uuid: $scope.encoder_to_start_stream,
				},
			};
			if (!encoderService.isSoftwareEncoder($scope.encoder_to_start)){
				config_data.encoderProfile = {
					uuid: $scope.encoder_to_start_encoder_profile,
				};
			}

			$scope.is_busy_starting_encoder = true;

			// update encoder
			$http.patch(`${jcs.api.url}/encoders/${$scope.encoder_to_start.uuid}`, config_data, { withCredentials: true }).then(response => {

				$scope.encoder_to_start.streamProfile = $scope.getStreamForID($scope.encoder_to_start_stream);

				const encoder_data = {
					requestedStatus: 'start',
				};



				// start encoder
				$http.patch(`${jcs.api.url}/encoders/${$scope.encoder_to_start.uuid}`, encoder_data, { withCredentials: true }).then(response => {
					trackMixpanelEvent(MPEventName.ENCODER_START, {
						[MPEventProperty.ENCODER_EVENT_PROFILE_NAME]: $scope.getStreamForID($scope.encoder_to_start_stream)?.name,
						[MPEventProperty.ENCODER_EVENT_PROFILE_UUID]: $scope.getStreamForID($scope.encoder_to_start_stream)?.uuid,
						[MPEventProperty.EVENT_UUID]: $scope.getStreamForID($scope.encoder_to_start_stream)?.uuid,
						[MPEventProperty.ENCODER_PROFILE_NAME]: $scope.getProfileForID($scope.encoder_to_start_encoder_profile)?.name,
						[MPEventProperty.ENCODER_PROFILE_UUID]: $scope.getProfileForID($scope.encoder_to_start_encoder_profile)?.uuid,
						[MPEventProperty.ENCODER_UUID]: $scope.getProfileForID($scope.encoder_to_start_encoder_profile)?.uuid,
					});

					// clear encoder start variables and reload our encoder list
					$scope.encoder_to_start = null;
					$scope.start_encoder_error = null;
					$scope.loadEncoders();

				}).catch(reason => {

					$scope.start_encoder_error = 'An error occurred while attempting to start the encoder. Please try again, or report the problem if it persists.';

				}).finally(() => {

					$scope.is_busy_starting_encoder = false;

				});

			}).catch(reason => {

				$scope.start_encoder_error = 'An error occurred while attempting to start the encoder. Please try again, or report the problem if it persists.';
				$scope.is_busy_starting_encoder = false;

			});

		} else {
			//
			// since the user didn't change the streamprofile, we can start the encoder
			//

			const encoder_data = {
				requestedStatus: 'start',
			};

			$scope.is_busy_starting_encoder = true;


			// start encoder
			$http.patch(`${jcs.api.url}/encoders/${$scope.encoder_to_start.uuid}`, encoder_data, { withCredentials: true }).then(response => {

				trackMixpanelEvent(MPEventName.ENCODER_START, {
					[MPEventProperty.ENCODER_EVENT_PROFILE_NAME]: $scope.encoder_to_start.streamProfile.name,
					[MPEventProperty.ENCODER_EVENT_PROFILE_UUID]: $scope.encoder_to_start.streamProfile.uuid,
					[MPEventProperty.ENCODER_PROFILE_NAME]: $scope.encoder_to_start.encoderProfile.name,
					[MPEventProperty.ENCODER_PROFILE_UUID]: $scope.encoder_to_start.encoderProfile.uuid,
					[MPEventProperty.EVENT_UUID]: $scope.encoder_to_start.streamProfile.uuid,
					[MPEventProperty.ENCODER_UUID]: $scope.encoder_to_start.uuid,
				});

				// clear encoder start variables and reload our encoder list
				$scope.encoder_to_start = null;
				$scope.start_encoder_error = null;
				$scope.loadEncoders();

			}).catch(reason => {

				$scope.start_encoder_error = 'An error occurred while attempting to start the encoder. Please try again, or report the problem if it persists.';

			}).finally(() => {

				$scope.is_busy_starting_encoder = false;

			});
		}
	};

	$scope.stopEncoder = function () {
		var encoder_data = {
			requestedStatus: 'stop',
		};

		$scope.is_busy_stopping_encoder = true;

		// update encoder
		$http
			.patch(jcs.api.url + '/encoders/' + $scope.encoder_to_stop.uuid, encoder_data, {
				withCredentials: true,
			})
			.then(
				function () {
					// success
					trackMixpanelEvent(MPEventName.ENCODER_STOP, {
						[MPEventProperty.EVENT_UUID]: $scope.encoder_to_stop.streamProfile.uuid,
						[MPEventProperty.ENCODER_UUID]: $scope.encoder_to_stop.uuid,
					});
					// clear encoder stop variables and reload our encoder list
					$scope.encoder_to_stop = null;
					$scope.stop_encoder_error = null;
					$scope.loadEncoders();
				},
				function () {
					// error

					$scope.stop_encoder_error =
						'An error occurred while attempting to stop the encoder. Please try again, or report the problem if it persists.';
				}
			)
		['finally'](function () {
			// always called

			$scope.is_busy_stopping_encoder = false;
		});
	};

	$scope.getMixpanelDataForUpdate = function () {
		return {
			[MPEventProperty.ENCODER_UUID]: $scope.encoder_to_update.uuid,
			[MPEventProperty.ENCODER_NAME]: $scope.encoder_to_update.name,
			[MPEventProperty.ENCODER_VERSION]: $scope.encoder_to_update.encoderVersion,
			[MPEventProperty.ENCODER_EVENT_PROFILE_NAME]: $scope.encoder_to_update.streamProfile.name,
			[MPEventProperty.ENCODER_MODEL]: $scope.encoder_to_update.model,
		};
	};

	$scope.updateEncoder = function () {
		var encoder_data = {
			requestedStatus: 'update',
		};

		$scope.is_busy_updating_encoder = true;

		const mixpanel_data = $scope.getMixpanelDataForUpdate();

		// update encoder
		$http
			.patch(jcs.api.url + '/encoders/' + $scope.encoder_to_update.uuid, encoder_data, {
				withCredentials: true,
			})
			.then(
				function () { // success

					// clear encoder update variables and reload our encoder list
					$scope.encoder_to_update = null;
					$scope.update_encoder_error = null;
					$scope.loadEncoders();

					trackMixpanelEvent(MPEventName.ENCODER_UPDATE, mixpanel_data);
				},
				function () { // error

					$scope.update_encoder_error = 'An error occurred while attempting to update the encoder. Please try again, or report the problem if it persists.';
				}
			)
		['finally'](function () { // always called

			$scope.is_busy_updating_encoder = false;
		});
	};

	$scope.getMixpanelDataForRestart = function () {
		return {
			[MPEventProperty.ENCODER_UUID]: $scope.encoder_to_restart.uuid,
			[MPEventProperty.ENCODER_NAME]: $scope.encoder_to_restart.name,
			[MPEventProperty.ENCODER_EVENT_PROFILE_NAME]: $scope.encoder_to_restart.streamProfile.name,
			[MPEventProperty.ENCODER_MODEL]: $scope.encoder_to_restart.model,
		};
	};

	$scope.restartEncoder = function () {
		var encoder_data = {
			requestedStatus: 'restart',
		};

		$scope.is_busy_restarting_encoder = true;

		const mixpanel_data = $scope.getMixpanelDataForRestart();

		// restart encoder
		$http
			.patch(jcs.api.url + '/encoders/' + $scope.encoder_to_restart.uuid, encoder_data, {
				withCredentials: true,
			})
			.then(
				function () { // success

					// clear encoder restart variables and reload our encoder list
					$scope.encoder_to_restart = null;
					$scope.restart_encoder_error = null;
					$scope.loadEncoders();
					trackMixpanelEvent(MPEventName.ENCODER_RESTART, mixpanel_data);
				},
				function () { // error

					$scope.restart_encoder_error = 'An error occurred while attempting to restart the encoder. Please try again, or report the problem if it persists.';
				}
			)
		['finally'](function () { // always called

			$scope.is_busy_restarting_encoder = false;
		});
	};

	$scope.deleteEncoder = function () {
		$scope.is_busy_deleting_encoder = true;

		// delete encoder
		// NOTE: I normally would not include the "data: null", but IE11 and below seem to require it in order to work
		// see: https://github.com/angular/angular.js/issues/12141
		const url = encoderService.isSoftwareEncoder($scope.encoder_to_delete) ?
			`${jcs.api.url_v3}/customers/${Authentication.getCurrentUser().customerID}/uploaders/${$scope.encoder_to_delete.uuid}` :
			`${jcs.api.url}/encoders/${$scope.encoder_to_delete.uuid}`;
		$http.delete(url, { withCredentials: true, data: null }).then(response => {

			trackMixpanelEvent(MPEventName.ENCODER_DELETE, {
				[MPEventProperty.ENCODER_UUID]: $scope.encoder_to_delete.uuid,
				[MPEventProperty.NUMBER_OF_ENCODERS]: $scope.encoders.length
			});
			// clear encoder delete variables and reload our encoder list
			$scope.encoder_to_delete = null;
			$scope.delete_encoder_error = null;
			$scope.loadEncoders();

		}).catch(reason => {

			$scope.delete_encoder_error = 'An error occurred while attempting to delete the encoder. Please try again, or report the problem if it persists.';

		}).finally(() => {

			$scope.is_busy_deleting_encoder = false;

		});
	};

	$scope.getMixpanelDataForUploadLogs = function () {
		return {
			[MPEventProperty.ENCODER_UUID]: $scope.encoder_to_upload_logs.uuid,
			[MPEventProperty.ENCODER_NAME]: $scope.encoder_to_upload_logs.name,
			[MPEventProperty.ENCODER_EVENT_PROFILE_NAME]: $scope.encoder_to_upload_logs.streamProfile.name,
		};
	};

	$scope.uploadEncoderLogs = function () {
		var encoder_data = {
			requestedStatus: 'uploadLogs',
		};

		$scope.is_busy_uploading_logs = true;

		const mixpanel_data = $scope.getMixpanelDataForUploadLogs();

		// request upload encoder logs
		$http
			.patch(jcs.api.url + '/encoders/' + $scope.encoder_to_upload_logs.uuid, encoder_data, {
				withCredentials: true,
			})
			.then(
				function () { // success

					// clear encoder restart variables and reload our encoder list
					$scope.encoder_to_upload_logs = null;
					$scope.upload_encoder_logs_error = null;
					$scope.loadEncoders();
					trackMixpanelEvent(MPEventName.LOGS_UPLOAD, mixpanel_data);
				},
				function () { // error

					$scope.upload_encoder_logs_error = 'An error occurred while attempting to upload the encoder logs. Please try again, or report the problem if it persists.';
				}
			)
		['finally'](function () { // always called

			$scope.is_busy_uploading_logs = false;
		});
	};

	$scope.moveEncoder = function () {
		var encoder_data = {
			// moving doesnt really need to change the state, but if it were started it needs to use new profiles and stuff
			customerId: $scope.encoder_to_move_customer.uuid,
		};

		$scope.is_busy_moving_encoder = true;

		// move encoder
		$http.put(`${jcs.api.url}/encoders/${$scope.encoder_to_move.uuid}/move`, encoder_data, { withCredentials: true }).then(response => {

			trackMixpanelEvent(MPEventName.ENCODER_MOVE, {
				[MPEventProperty.ENCODER_UUID]: $scope.encoder_to_move.uuid,
				[MPEventProperty.ACCOUNT_MODIFIED_UUID]: $scope.encoder_to_move_customer.uuid
			});

			// clear encoder move variables and reload our encoder list
			$scope.encoder_to_move = null;
			$scope.move_encoder_error = null;
			$scope.loadEncoders();

		}).catch(reason => {

			$scope.move_encoder_error = 'An error occurred while attempting to move the encoder. Please try again, or report the problem if it persists.';

		}).finally(() => {

			$scope.is_busy_moving_encoder = false;

		});
	};

	$scope.doesSaveEditFailValidation = function () {
		$scope.edit_encoder_error = null;
		$scope.validation.clear();

		// ensure required fields are not empty
		$scope.validation.checkForEmpty('encoder_to_edit_name', $scope.encoder_to_edit_name);

		var has_validation_error = $scope.validation.hasError();
		if (has_validation_error) {
			$scope.edit_encoder_error = 'Please specify a value for the highlighted fields.';
		}

		return has_validation_error;
	};

	$scope.getMixpanelDataForEdit = function (updated_encoder_data) {
		const mixpanel_data = {
			[MPEventProperty.ENCODER_UUID]: $scope.encoder_to_edit.uuid,
			[MPEventProperty.ENCODER_NAME]: $scope.encoder_to_edit_name,
			[MPEventProperty.ENCODER_EVENT_PROFILE_UUID]: $scope.encoder_to_edit_stream,
			[MPEventProperty.ENCODER_EVENT_PROFILE_NAME]: $scope.encoder_to_edit.streamProfile.name,
		};
		if (updated_encoder_data.hasOwnProperty('encoderProfile')){
			mixpanel_data[MPEventProperty.ENCODER_PROFILE_UUID] = $scope.encoder_to_edit_profile;
		}
		if (updated_encoder_data.hasOwnProperty('lanModeEnabled')){
			mixpanel_data[MPEventProperty.LAN_MODE] = updated_encoder_data.lanModeEnabled;
		}
		if (updated_encoder_data.hasOwnProperty('requestedVideoInputSource')){
			mixpanel_data[MPEventProperty.VIDEO_INPUT_SOURCE] = updated_encoder_data.requestedVideoInputSource;
		}
		return mixpanel_data;
	};

	$scope.saveEdit = function () {
		// if we have form validation errors, then don't go any further
		if ($scope.doesSaveEditFailValidation()){
			return false;
		}

		let can_show_video_input_source = false;

		const updated_encoder_data = {
			name: $scope.encoder_to_edit_name,
			streamProfile: {
				uuid: $scope.encoder_to_edit_stream,
			},
		};

		if (!encoderService.isSoftwareEncoder($scope.encoder_to_edit)){
			updated_encoder_data.encoderProfile = {
				uuid: $scope.encoder_to_edit_profile,
			};

			can_show_video_input_source = $scope.canShowVideoInputSource($scope.encoder_to_edit);

			// only LAO admin can set lan mode status
			if (Authentication.getCurrentUser().hasPerm('encoders.lan_mode.update')) {
				updated_encoder_data.lanModeEnabled = $scope.encoder_to_edit_lan_mode == $scope.LAN_MODE_ENABLED ? true : false;
			}
			// only user with proper perms can change video input source
			if (can_show_video_input_source) {
				updated_encoder_data.requestedVideoInputSource = $scope.encoder_to_edit_video_input_source;
			}
		}

		$scope.is_busy_editing_encoder = true;

		const mixpanel_data = $scope.getMixpanelDataForEdit(updated_encoder_data);

		// update encoder
		const config = {
			withCredentials: true,
			headers: {
				'X-Encoder-Version': $scope.encoder_version,
			},
		};
		$http.patch(`${jcs.api.url}/encoders/${$scope.encoder_to_edit.uuid}`, updated_encoder_data, config).then(response => {

			// find the encoder that was updated and update it's properties
			for (let i = 0; i < $scope.encoders.length; i++) {
				if ($scope.encoders[i].uuid === $scope.encoder_to_edit.uuid) {
					$scope.encoders[i].name = $scope.encoder_to_edit_name;
					$scope.encoders[i].streamProfile = $scope.getStreamForID($scope.encoder_to_edit_stream);
					if (!encoderService.isSoftwareEncoder($scope.encoder_to_edit)){
						$scope.encoders[i].encoderProfile = $scope.getProfileForID($scope.encoder_to_edit_profile);
						if (Authentication.getCurrentUser().hasPerm('encoders.lan_mode.get')) {
							$scope.encoders[i].lanModeEnabled = $scope.encoder_to_edit_lan_mode === $scope.LAN_MODE_ENABLED;
						}
						if (can_show_video_input_source) {
							$scope.encoders[i].requestedVideoInputSource = $scope.encoder_to_edit_video_input_source;
						}
					}
					break;
				}
			}

			$scope.encoder_to_edit = null;
			$scope.edit_encoder_error = null;
			trackMixpanelEvent(MPEventName.ENCODER_CONFIGURE, mixpanel_data);

		}).catch(reason => {

			$scope.edit_encoder_error = 'An error occurred while attempting to update the encoder. Please try again, or report the problem if it persists.';

		}).finally(() => {

			$scope.is_busy_editing_encoder = false;

		});
	};

	$scope.saveEditNic = function () {
		// if we have form validation errors, then don't go any further
		if ($scope.doesSaveEditNicFailValidation()) {
			return false;
		}

		var updated_nic_data = {
			name: $scope.nic_to_edit.name,
			displayName: $scope.nic_to_edit_name,
			dhcp: $scope.nic_to_edit_dhcp,
		};
		// only save IPv4 settings if DHCP is false
		if (!$scope.nic_to_edit_dhcp) {
			updated_nic_data.ipv4Address = $scope.nic_to_edit_ipv4Address;
			updated_nic_data.ipv4Gateway = $scope.nic_to_edit_ipv4Gateway;
			updated_nic_data.ipv4Dns1 = $scope.nic_to_edit_ipv4Dns1;
			updated_nic_data.ipv4Dns2 = $scope.nic_to_edit_ipv4Dns2;
			updated_nic_data.ipv4Dns3 = $scope.nic_to_edit_ipv4Dns3;
			updated_nic_data.ipv4Cidr = $scope.nic_to_edit_ipv4Cidr;
		}

		$scope.is_busy_editing_nic = true;

		// update encoder
		$http
			.patch(jcs.api.url + '/encoders/' + $scope.encoder_to_view.uuid + '/network', updated_nic_data, {
				withCredentials: true,
			})
			.then(
				function () {
					// success

					trackMixpanelEvent(MPEventName.IP_ADDRESS_EDIT, {
						[MPEventProperty.ENCODER_UUID]: $scope.encoder_to_view.uuid,
						[MPEventProperty.NIC_STATUS]: $scope.nic_to_edit_dhcp ? 'DHCP' : 'custom'
					});

					// Note: since network settings don't take effect immediately, then don't worry about updating the angular model
					// the encoder details page will auto refresh the status, and eventually the changes will be reflected. If we change
					// this feature from "admin-only" to one customers use, then we may need to update this. We could check an encoders
					// status to see if it is "ApplyingNetwork"(?), which would mean their are pending changes.
					$scope.nic_to_edit = null;
					$scope.edit_nic_error = null;
				},
				function () {
					// error

					$scope.edit_nic_error =
						'An error occurred while attempting to update the NIC settings. Please try again, or report the problem if it persists.';
				}
			)
		['finally'](function () {
			// always called

			$scope.is_busy_editing_nic = false;
		});
	};

	$scope.doesSaveEditNicFailValidation = function () {
		$scope.validation.clear();
		
		// ensure required fields are not empty
		$scope.validation.checkForEmpty('nic_to_edit_ipv4', $scope.nic_to_edit_ipv4Address);
		$scope.validation.checkForEmpty('nic_to_edit_gateway', $scope.nic_to_edit_ipv4Gateway);
		$scope.validation.checkForEmpty('nic_to_edit_dns_1', $scope.nic_to_edit_ipv4Dns1);

		var has_validation_error = $scope.validation.hasError();
		if (has_validation_error) {
			$scope.edit_nic_error = 'Please specify a value for the highlighted fields.';
		}

		return has_validation_error;
	};

	$scope.showStatusActivityIndicator = function (encoder) {
		return (
			encoder.status == 'starting' ||
			encoder.status == 'stopping' ||
			encoder.status == 'updating' ||
			encoder.status == 'restarting' ||
			encoder.requestedStatus == 'uploadLogs' ||
			encoder.status == 'uploadedLogs'
		);
	};

	$scope.getStatusLabel = function (encoder) {
		if (encoder.requestedStatus == 'uploadLogs' || encoder.status == 'uploadedLogs') {
			return 'Uploading Logs';
		}
		return encoder.status;
	};

	// the encoder list should update periodically to ensure the encoder status is accurate. when an encoder is in a transitioning
	// state (starting/stopping/updating/restarting), then we'll want to refresh the status more frequently.
	$scope.getEncoderStatusUpdateTimeInSec = function () {
		// cycle through our encoders and see if any are in transitioning state
		if ($scope.encoders != null) {
			for (var i = 0; i < $scope.encoders.length; i++) {
				if (
					$scope.encoders[i].status == 'starting' ||
					$scope.encoders[i].status == 'stopping' ||
					$scope.encoders[i].status == 'updating' ||
					$scope.encoders[i].status == 'restarting' ||
					$scope.encoders[i].requestedStatus == 'uploadLogs' ||
					$scope.encoders[i].status == 'uploadedLogs'
				)
					return $scope.CHECK_ENCODER_STATUS_TIME_DELAY_SHORT; // if so, then we want a quick update time
			}
		}
		return $scope.CHECK_ENCODER_STATUS_TIME_DELAY_LONG; // otherwise a longer update time is sufficient
	};

	$scope.loadEncoders = function () {
		$scope.loading_encoders = true;

		// check to see if we have a scheduled timeout
		if ($scope.loading_encoders_timeout_id != null) {
			// if so, then clear it (we'll check the encoder status down below and set a timeout if necessary)
			window.clearTimeout($scope.loading_encoders_timeout_id);
			$scope.loading_encoders_timeout_id = null;
		}

		// initialize encoders list
		$http
			.get(`${jcs.api.url}/encoders`, {
				params: { wide: true },
				withCredentials: true ,
			})
			.then(
				function (response) {
					// success

					$scope.encoders_error_msg = null;
					$scope.encoders = response.data;
					$scope.updateRequired = encoderService.isEncoderUpdateRequired($scope.encoders);

					// setup timeout so our encoder list will auto refresh
					$scope.loading_encoders_timeout_id = window.setTimeout(
						$scope.loadEncodersSilent,
						$scope.getEncoderStatusUpdateTimeInSec()
					);
				},
				function () {
					// error

					$scope.encoders_error_msg =
						'An error occurred while loading the encoder information. Please try again (use the refresh button), or report the problem if it persists.';
					$scope.encoders = null;
				}
			)
		['finally'](function () {
			// always called

			$scope.loading_encoders = false;
		});
	};

	// Normally when the encoders are loaded a "loading ..." message is displayed on the screen and the encoder list is only displayed once the
	// load has completed. But there are some cases when we want to load the encoders, and update their status without the users screen flashing.
	// An example of this is when an encoder is starting/stopping and we are periodically checking the status -- we don't want the user to know
	// that we are pinging the server every x seconds. We just want the status to auto update for them. Still debating how I want to handle errors ...
	$scope.loadEncodersSilent = function () {

		if (!uiService.isMenuOpen()){

			// initialize encoders list
			$http.get(`${jcs.api.url}/encoders`, {
					params: { wide: true },
					withCredentials: true ,
				})
				.then(
				function (response) { // success

					$scope.encoders_error_msg = null;
					$scope.encoders = response.data;
					$scope.updateRequired = encoderService.isEncoderUpdateRequired($scope.encoders);

				},
				function () { // error
					// If our "silent" load gets an error, lets not display an error message -- I think it would be confusing for the user to see
					// an error about an action that they did not initiate. So instead of displaying an error, setup another timeout in the hopes
					// that the error we got as a one-time thing, and upcoming check will work.
				})
				['finally'](function () { // always called

					// setup timeout so our encoder list will auto refresh
					$scope.loading_encoders_timeout_id = window.setTimeout($scope.loadEncodersSilent, $scope.getEncoderStatusUpdateTimeInSec());
				});

		} else {

			// setup timeout so our encoder list will auto refresh
			$scope.loading_encoders_timeout_id = window.setTimeout($scope.loadEncodersSilent, $scope.getEncoderStatusUpdateTimeInSec());
		}
	};

	$scope.isSignalDetected = function (card_formats) {
		// cycle thru all the card formats, and return true if one is "available"
		for (var i = 0; i < card_formats.length; i++) {
			if (card_formats[i].available) {
				return true;
			}
		}
		// none of the card formats were available, so return false
		return false;
	};

	// returns TRUE if the given text should be formatted like a "<pre>" tag. We should do this if the text contains a newline.
	// see: https://stackoverflow.com/questions/15131072/check-whether-string-contains-a-line-break
	$scope.formatAsPre = function (entry) {
		return /\r|\n/.exec(entry);
	};

	// for options see: http://www.daterangepicker.com/
	$scope.setLogTimeRange = function (start, end, label) {
		// currently the time appears to be saved as milliseconds or seconds
		$scope.log_start_time = start;
		$scope.log_end_time = end;
	};

	// no seconds
	$scope.showTimeAsLocalHM = function (dateTimeToConvert) {
		// NOTE: we were using the Date "toLocaleString" method, but it turns out this isn't implemented consistently across
		// browsers. That is why we are using a javascript library (momentjs).

		// is the given time today?
		var currentDay = moment().format('MMM D, YYYY');
		var givenTimeDay = moment(dateTimeToConvert).format('MMM D, YYYY');
		if (currentDay == givenTimeDay) {
			return 'today at ' + moment(dateTimeToConvert).format('h:mm a');
		}

		// is the given time this year?
		var currentYear = moment().format('YYYY');
		var givenTimeYear = moment(dateTimeToConvert).format('YYYY');
		if (currentYear == givenTimeYear) {
			return moment(dateTimeToConvert).format('MMM D, h:mm a');
		}

		// for formatting options see: http://momentjs.com/
		return moment(dateTimeToConvert).format('MMM D, YYYY h:mm a');
	};

	//
	// initialize by loading our encoders
	//
	$scope.loadEncoders();

	$scope.$on('$destroy', () => {
		clearTimeout($scope.loading_encoders_timeout_id);
	});

	// prepare our default start/end times
	$scope.log_start_time = moment()
		.subtract(10, 'minutes')
		.format($scope.TIME_DATE_FORMAT); // 10 minutes ago
	$scope.log_end_time = moment().format($scope.TIME_DATE_FORMAT); // right now

	// initialize our date-time range picker that will be used when viewing encoder logs
	// NOTE: there is a "singleDatePicker" option in case we decide we want either start/end time to be optional
	// and would therefore need to have two separate input fields. Each input field would also need it's own callback.
	// for options see: http://www.daterangepicker.com/ (especially the Configuration Generator section)
	$('#log-time-range').daterangepicker(
		{
			//singleDatePicker: true,
			startDate: $scope.log_start_time,
			endDate: $scope.log_end_time,
			timePicker: true,
			timePickerIncrement: 5,
			locale: {
				format: 'lll', // <= looks like this is using moment.js formatting options
			},
		},
		$scope.setLogTimeRange
	);

	// build our tooltips
	$timeout(function () {
		$('[data-toggle="tooltip"]').tooltip(); // needs to be done in timeout, otherwise for some reason the tooltip gets built before angular does it's magic
	});

	// since this page has timers, we need to know when the user changes to another page so we can turn the timers
	// off (this will prevent unnecessary http requests).
	$scope.$on('$locationChangeSuccess', function () {
		// clear any encoder we happen to be viewing
		$scope.cancelView();

		// clear any active timers we might have
		if ($scope.loading_encoders_timeout_id != null) {
			window.clearTimeout($scope.loading_encoders_timeout_id);
			$scope.loading_encoders_timeout_id = null;
		}
		if ($scope.loading_encoder_info_timeout_id != null) {
			window.clearTimeout($scope.loading_encoder_info_timeout_id);
			$scope.loading_encoder_info_timeout_id = null;
		}
	});

}

module.exports = EncodersController;
