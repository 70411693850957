import angular from 'angular';
import AnalyticsCsvButtonComponent from './analyticsCsvButton.component';
import './analyticsCsvButton.scss';

export const analyticsCsvButtonModule = {
  name: 'analyticsCsvButtonComponent',
  component: {
    name: 'analyticsCsvButton',
  },
};

export enum ReportTypeEnum {
  DETAIL = 'DETAIL',
  SUMMARY = 'SUMMARY',
}

export interface WebEvent {
  eventId: string;
  clientId: string;
  city: string;
  country: string;
  latitude: string;
  longitude: string;
  resoultion: string;
  state: string;
  timestamp: string;
  userAgent: string;
  watchTimeMinutes: string;
}

export interface WebEventSummary {
  videoId: string;
  videoTitle: string;
  averageViewDuration: number;
  watchTimeMinutes: number;
  views: number;
  uniqueViewers: number;
}

export interface User {
  customerID: string;
}

export default angular
  .module(analyticsCsvButtonModule.name, ['app.services'])
  .component(analyticsCsvButtonModule.component.name, new AnalyticsCsvButtonComponent()).name;
