import styled from '@emotion/styled';
import { customProperties as vars } from '@resi-media/resi-ui';

export const BarChartWrapper = styled.div`
  position: relative;
  height: 80%;
  width: 100%;
`;

export const BarChartLegendsWrapper = styled.ul`
  display: flex;
  flex: 1;
  width: 100%;
  justify-content: space-between;
  list-style: none;
  padding-inline-start: 0;
`;

export const BarChartLegendWrapper = styled.div`
  margin: 10px 0 0 0;
`;

export const BarChartCanvas = styled.canvas`
  position: relative;
`;

export const BarChartLegendList = styled.ul`
  display: flex;
  flexdirection: row;
  margin: 0;
  padding: 0;
`;

export const barChartBoxSpanStyle = {
  width: vars.spacingM,
  height: vars.spacingM,
  borderRadius: vars.borderRadius2,
  display: 'inline-block',
  marginHight: vars.spacingXs,
};
