import React, { FC } from 'react';
import PropTypes from 'prop-types';

interface ModalContentProps {
  minHeight?: string;
}

const ModalContent: FC<ModalContentProps> = ({ children, minHeight }) => {
  return (
    <div className="modal-body" style={{ minHeight }}>
      {children}
    </div>
  );
};

ModalContent.displayName = 'ModalContent';

export default ModalContent;
