import DurationComponent from './duration.component';

export const durationModule = {
	name: 'durationComponent',
	component: {
		name: 'duration'
	}
};

export default angular
	.module(durationModule.name, [])
	.component(durationModule.component.name, new DurationComponent()).name;
