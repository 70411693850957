import 'angular-route';
import angular from 'angular';
import router from './module.contactsearch.routes';
import commonServicesModule from '../../services';

export const contactSearchModule = {
	name: 'contactsearch',
	controllers: {
		default: 'contactSearchCtrl',
	},
	routes: {
		list: '/contactsearch',
	},
};

export default angular
	.module(contactSearchModule.name, ['ngRoute', commonServicesModule])
	.config(router)
	.controller(contactSearchModule.controllers.default, require('./contactSearchCtrl')).name;
