import { AllDestinationsContextType } from '../../../contexts/eventanalytics/alldestinations';
import axios from 'axios';
import { baseURL, fixedDateRangeValues } from '../event-analytics-constants';
import { API_RESPONSE_TYPE, BracketReference } from '../types';
import { DateTime } from 'luxon';
import { EventAnalyticsState } from '../../../contexts/eventanalytics/event-analytics';
import { dateTimeFormatUTC } from '../date-range-options';

export const fetchData = async (
  EventAnalyticsState: EventAnalyticsState,
  allDestinationsAnalyticsState: AllDestinationsContextType,
  timeWindowValue?: string
): Promise<API_RESPONSE_TYPE> => {
  const {
    customerId,
    token,
    eventId,
    dateRange: { startDate, endDate },
  } = EventAnalyticsState;
  const api = `${baseURL}/${customerId}/allDestinations/statistics/${timeWindowValue}/views`;
  try {
    const { data } = await axios.get(api, {
      headers: token,
      params: {
        startDate: DateTime.fromISO(startDate).startOf('day').toFormat(dateTimeFormatUTC),
        endDate: DateTime.fromISO(endDate).endOf('day').toFormat(dateTimeFormatUTC),
        segmentBy: allDestinationsAnalyticsState.groupBy,
        ...(eventId && { eventId }),
      },
    });
    return data;
  } catch (error) {
    console.error(error);
    return {};
  }
};

export const fetchAllDestinationsURLData = async (
  EventAnalyticsState: EventAnalyticsState
): Promise<BracketReference<number | null>[]> => {
  const {
    customerId,
    token,
    eventId,
    dateRange: { startDate, endDate },
    dateRangeOption,
  } = EventAnalyticsState;
  const SummaryStatsAPI = `${baseURL}/${customerId}/allDestinations/statistics/summary`;
  const dateRangeValue = dateRangeOption.value.toString();
  const isFullMonth = fixedDateRangeValues.includes(dateRangeValue);
  try {
    const { data } = await axios.get(SummaryStatsAPI, {
      headers: token,
      params: {
        startDate: DateTime.fromISO(startDate).startOf('day').toFormat(dateTimeFormatUTC),
        endDate: DateTime.fromISO(endDate).endOf('day').toFormat(dateTimeFormatUTC),
        isFullMonth,
        ...(eventId && { eventId }),
      },
    });
    return data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const fetchSegmentedData = async (
  EventAnalyticsState: EventAnalyticsState
): Promise<BracketReference<BracketReference<number>>> => {
  const {
    customerId,
    token,
    eventId,
    dateRange: { startDate, endDate },
  } = EventAnalyticsState;
  const url = `${baseURL}/${customerId}/allDestinations/statistics/views/summary`;
  try {
    const { data } = await axios.get(url, {
      headers: token,
      params: {
        startDate: DateTime.fromISO(startDate).startOf('day').toFormat(dateTimeFormatUTC),
        endDate: DateTime.fromISO(endDate).endOf('day').toFormat(dateTimeFormatUTC),
        ...(eventId && { eventId }),
      },
    });
    return data;
  } catch (error) {
    console.error(error);
    return {};
  }
};
