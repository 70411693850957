import React, { FC, ReactNode } from 'react';
import './modal.scss';

interface ModalProps {
  width?: string;
  children: ReactNode;
}

const Modal: FC<ModalProps> = ({ children, width = '40%' }) => {
  return (
    <div className="la1-modal-background">
      <div className="la1-modal" role="dialog" style={{ width }}>
        {children}
      </div>
    </div>
  );
};

Modal.displayName = 'Modal';

export default Modal;
