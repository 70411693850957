import { SORT_DIR_ASC, SORT_DIR_DESC } from '../constants';

// this paginationService call provides an init method that will return a unique pagination object, so you should be
// able to have multiple pagination objects on a single page if necessary.
// Usage:
// $scope.pagination = paginationService.init('serial', paginationService.SORT_DIR_ASC, $scope.change_page_callback);
//
// NOTE: pagination object is 1 based (not 0 based) because it seems easier to think about this in terms
// of exactly what will be displayed in the UI. The only time we need to convert to zero based is when
// we are providing a page number for the query string passed along to the API.
class PaginationService {
	constructor() {
		this.SORT_DIR_ASC = SORT_DIR_ASC;
		this.SORT_DIR_DESC = SORT_DIR_DESC;
		this.initialize();
	}

	initialize() {
		this.current_page = 1;
		this.page_size = 200;
		this.allowed_pages = 5;
		this.total_pages = null;
		this.total_records = null;
		this.is_changing_page = false;
		this.buttons = null;
		this.sort_col = null;
		this.sort_dir = null;
	}

	init(init_sort_col, init_sort_dir, init_change_page_callback) {
		this.initialize();
		this.sort_col = init_sort_col;
		this.sort_dir = init_sort_dir;
		this.change_page_callback = init_change_page_callback;
		return this;
	}

	setPage(page) {
		// ignore if we are already in the middle of page change
		if (this.is_changing_page) {
			return;
		}

		this.current_page = page;
		this.change_page_callback();
	}

	gotoPrevPage() {
		// ignore if we are already in the middle of page change
		if (this.is_changing_page) {
			return;
		}

		if (this.canShowPrev()) {
			this.current_page--;
			this.change_page_callback();
		}
	}

	gotoNextPage() {
		// ignore if we are already in the middle of page change
		if (this.is_changing_page) {
			return;
		}

		if (this.canShowNext()) {
			this.current_page++;
			this.change_page_callback();
		}
	}

	setSortCol(column) {
		// ignore if we are already in the middle of page change
		if (this.is_changing_page) {
			return;
		}

		// we are changing how data is sorted, so reset back to page 1. Can't think of
		// any reason why you wouldn't want to do that.
		this.current_page = 1;

		if (this.sort_col == column) {
			this.sort_dir = this.sort_dir == SORT_DIR_ASC ? SORT_DIR_DESC : SORT_DIR_ASC;
		} else {
			// change column we are sorting by, but leave direction as is for now; wasn't
			// sure if "resetting" it would be useful or not.
			this.sort_col = column;
		}

		this.change_page_callback();
	}

	// returns the query string needed for paginated API calls
	getQueryString() {
		const queries = [];
		queries.push(`page=${this.current_page - 1}`);
		queries.push(`size=${this.page_size}`);
		queries.push(`sort=${this.sort_col}`);
		queries.push(`sortDirection=${this.sort_dir}`);
		return queries.join('&');
	}

	// should show prev button be visible/enabled?
	canShowPrev() {
		return this.current_page > 1;
	}

	// should show next button be visible/enabled?
	canShowNext() {
		return this.current_page < this.total_pages;
	}

	canShowButtons() {
		return this.buttons != null && this.buttons.length > 1;
	}

	// get the pagination description
	// ex: Showing 1-10 out of 45 matches
	getDescription() {
		if (this.total_records <= this.page_size) {
			const plural = this.total_records == 1 ? '' : 'es';
			return `Found ${this.total_records} match${plural}`;
		} else {
			const total = this.total_records;
			const start = 1 + (this.current_page - 1) * this.page_size;
			const end = start + this.page_size - 1;
			if (end > total) {
				end = total;
			}

			return `Showing ${start} - ${end} out of ${total} matches`;
		}
	}

	canShowDescription() {
		return this.total_records > 0;
	}

	// is the given column active and in ascending order?
	isActiveColAsc(column) {
		return this.sort_col == column && this.sort_dir == SORT_DIR_ASC;
	}

	// is the given column active and in descending order?
	isActiveColDesc(column) {
		return this.sort_col == column && this.sort_dir == SORT_DIR_DESC;
	}

	// updates our variables and rebuilds our array of pagination buttons
	update(headers) {
		// update our page variables using the given header information
		this.page_size = 'x-page-size' in headers ? parseInt(headers['x-page-size']) : null;
		this.total_pages = 'x-total-pages' in headers ? parseInt(headers['x-total-pages']) : null;
		// only update record count if one is provided
		if ('x-total-records' in headers) {
			this.total_records = parseInt(headers['x-total-records']);
		}

		// determine the page number buttons we need to show
		let first_page = 1;
		let last_page = 1;
		if (this.total_pages <= this.allowed_pages) {
			first_page = 1;
			last_page = first_page + this.total_pages - 1;
		} else {
			first_page = this.current_page - Math.floor(this.allowed_pages / 2); // floor = round down
			if (first_page < 1) {
				first_page = 1;
				last_page = this.allowed_pages;
			} else {
				last_page = first_page + this.allowed_pages - 1;
				// if we've moved beyond our max page, then readjust both last and first page
				if (last_page > this.total_pages) {
					last_page = this.total_pages;
					first_page = last_page - (this.allowed_pages - 1);
				}
			}
		}

		// rebuild our buttons array
		this.buttons = [];
		for (let page = first_page; page <= last_page; page++) {
			this.buttons.push({
				page: page,
				active: this.current_page == page,
			});
		}
	}
}

export default PaginationService;
