import React, { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import { SurfaceContainer, surfaceCSS } from './surface.styles';
import { customProperties as vars } from '@resi-media/resi-ui';

interface SurfacePropType {
  children: ReactNode;
  padding: string;
}
const Surface: FC<SurfacePropType> = ({ children, padding }) => (
  <SurfaceContainer css={surfaceCSS(padding)}>{children}</SurfaceContainer>
);

Surface.defaultProps = {
  padding: vars.spacingM,
};

Surface.propTypes = {
  children: PropTypes.node.isRequired,
  padding: PropTypes.string.isRequired,
};

Surface.displayName = 'Surface';

export default Surface;
