import { api } from '../jcs';

class OrganizationService {
  private map = {};
  private managedMap = {};
  private searchMap = {};
  private isLoading = false;
  private orgNameList = null;
  private orgNameMap = null;

  readonly WEB_PLAN_SOCIAL_ONLY = "Social Only";

  readonly SEARCH_HEADER_TOTAL_PAGES = 'x-total-pages';
  readonly SEARCH_HEADER_TOTAL_RECORDS = 'x-total-records';
  readonly SEARCH_HEADER_PAGE_SIZE = 'x-page-size';

  constructor(private $q: ng.IQService, private $http: ng.IHttpService) {
    'ngInject';
  }

  public isLoadingOrg(): boolean {
    return this.isLoading;
  }

  public hasWebPlan(orgId: string, webPlan: string): ng.IPromise<boolean> {
    return this.getOrganization(orgId)
      .then(response => {
        return response.webPlanName === webPlan;
      });
  }

  public async getDeleteAfter(orgId: string): Promise<number> {
    const response = await this.getOrganization(orgId);
    return response.webMaxDeleteAfter;
  }

  // NOTE: this uses the new v3 API which currently returns a very limited amount of info about the organization.
  public getOrganization(uuid: string): ng.IPromise<any> {
    // do we already have the info in our cache?
    if (Object.prototype.hasOwnProperty.call(this.map, uuid)) {
      return this.$q.resolve(this.map[uuid]);
    }

    this.isLoading = true;
    return this.$http.get(this.getOrgUrl(uuid), { withCredentials: true })
      .then(response => {
        this.map[uuid] = response.data;
        return response.data;
      })
      .finally(() => (this.isLoading = false));
  }

  // returns full list of organization names and uuids
  public getOrganizationNameList(): ng.IPromise<any> {
    // do we already have this cached?
    if (this.orgNameList != null) {
      return this.$q.resolve(this.orgNameList);
    }

    this.isLoading = true;
    return this.$http.get(this.getOrgNameListUrl(), { withCredentials: true })
      .then(response => {
        this.orgNameList = response.data;
        return response.data;
      })
      .finally(() => (this.isLoading = false));
  }

  public getOrganizationNameMap(): ng.IPromise<any> {
    // do we already have the info in our cache?
    if (this.orgNameMap !== null) {
      return this.$q.resolve(this.orgNameMap);
    }

    return this.getOrganizationNameList().then(response => {
      this.orgNameMap = {};
      for (const entry of response){
        this.orgNameMap[entry.uuid] = entry.name;
      }
      return this.orgNameMap;
    });
  }

  public getManagedOrganizationsList(uuid: string): ng.IPromise<any> {
    // do we already have this cached?
    if (Object.prototype.hasOwnProperty.call(this.managedMap, uuid)) {
      return this.$q.resolve(this.managedMap[uuid]);
    }

    this.isLoading = true;
    return this.$http.get(this.getManagedOrgUrl(uuid), { withCredentials: true})
      .then(response => {
        this.managedMap[uuid] = response.data;
        return response.data;
      })
      .finally(() => (this.isLoading = false))
  }

  public getOrgSearchResults(searchTerm: string): ng.IPromise<any> {
    // do we already have this cached?
    if (Object.prototype.hasOwnProperty.call(this.searchMap, searchTerm)) {
      return this.$q.resolve(this.searchMap[searchTerm]);
    }

    this.isLoading = true;
    return this.$http.get(this.getSearchOrgUrl(searchTerm), { withCredentials: true})
      .then(response => {
        const searchResults = {
          term: searchTerm,
          results: response.data,
          totalPages: null,
          totalRecords: null,
          pageSize: null,
        };
        const headers = response.headers();
        if (this.SEARCH_HEADER_TOTAL_PAGES in headers){
          searchResults.totalPages = parseInt(headers[this.SEARCH_HEADER_TOTAL_PAGES]);
        }
        if (this.SEARCH_HEADER_TOTAL_RECORDS in headers){
          searchResults.totalRecords = parseInt(headers[this.SEARCH_HEADER_TOTAL_RECORDS]);
        }
        if (this.SEARCH_HEADER_PAGE_SIZE in headers){
          searchResults.pageSize = parseInt(headers[this.SEARCH_HEADER_PAGE_SIZE]);
        }
        this.searchMap[searchTerm] = searchResults;
        return searchResults;
      })
      .finally(() => (this.isLoading = false))
  }

  public clearSearchCache(): void {
    this.searchMap = {};
  }

  public isUuid(searchTerm: string): boolean {
    const uuid = new RegExp('[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}')
    return uuid.test(searchTerm);
  }

  public async getWebDeleteAfterOptions(customerId: string): Promise<number[]> {
    const maxDeleteAfter = await this.getDeleteAfter(customerId);
    return Array.from(Array(maxDeleteAfter + 1).keys()).slice(1);
  }

  private getSearchOrgUrl(searchTerm: string): string {
    if (this.isUuid(searchTerm)) {
      return `${api.url}/customers?uuid=${encodeURI(searchTerm)}`;
    }
    return `${api.url}/customers?name=${encodeURI(searchTerm)}`;
  }

  private getOrgUrl (uuid: string): string {
    return `${api.url_v3}/customers/${uuid}`;
  }

  private getOrgNameListUrl (): string {
    return `${api.url}/customers/names`;
  }

  private getManagedOrgUrl (uuid: string): string {
    return `${api.url}/customers/${uuid}/manages`;
  }
}

export default OrganizationService;
