export const Authorized = {
	AUTHORIZED: 0,
	LOGIN_REQUIRED: 1,
	NOT_AUTHORIZED: 2,
};

export const PermissionCheckType = {
	AT_LEAST_ONE: 0,
	COMBINATION_REQUIRED: 1,
};
