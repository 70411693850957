import React, { FC } from 'react';
import ResiLayout from '../ResiLayout';
import { BoxDescription, BoxHeader } from '../Styled';
import { BlockButton, LinkButton } from '@resi-media/resi-ui';
import { InvitationExpiredWrap } from './style';

const InvitationExpired: FC = () => {
  return (
    <ResiLayout>
      <InvitationExpiredWrap>
        <BoxHeader>Invitation Expired</BoxHeader>
        <BoxDescription>
          This invite is no longer valid. Contact your system administrator to request a new invite. Or sign in if you
          already have an account
        </BoxDescription>
        <BlockButton label="Sign in" isToggleButton={false} onClick={() => window.location.assign('/login')} />
        <BoxDescription>
          New to Resi?{' '}
          <LinkButton
            label="Create an account"
            onClick={() => (window.location.href = 'https://studio.resi.io/signup/plans')}
          />
        </BoxDescription>
      </InvitationExpiredWrap>
    </ResiLayout>
  );
};

export default InvitationExpired;
