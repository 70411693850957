import React, { FC } from 'react';
import PropTypes from 'prop-types';
import { InsetSquish, LinkButton } from '@resi-media/resi-ui';
import { BlockErrorContainer } from './style';

interface BlockErrorProps {
  errorLinkText?: string | null;
  onClick?: (() => void) | null;
  children: React.ReactNode;
}

const BlockError: FC<BlockErrorProps> = ({ children, errorLinkText, onClick }) => {
  return (
    <BlockErrorContainer>
      <InsetSquish scale="m">
        {`${children} `}
        {errorLinkText && onClick && <LinkButton label={errorLinkText} onClick={onClick} />}
      </InsetSquish>
    </BlockErrorContainer>
  );
};

BlockError.displayName = 'BlockError';
BlockError.propTypes = {
  errorLinkText: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.node,
};

export default BlockError;
