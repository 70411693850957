import { API_RESPONSE_DIFFS } from '../../event-analytics-constants';
import cloneDeep from 'lodash.clonedeep';
import { API_RESPONSE_TYPE, barChartDataType } from '../../types';
import { legendsMapToDataSets, removeElementsfromArray, splitToChunks } from '../../utils';

export const getWatchTimeChartData = (responseObject: API_RESPONSE_TYPE): barChartDataType => {
  const watchTimeChartData: barChartDataType = { labels: [], extraInfo: [], datasets: {} };
  let label: string;
  responseObject[Object.keys(responseObject)[0]].forEach((element, _index) => {
    const startTime = element.startTime;
    const endTime = element.endTime;
    label =
      startTime === 0 && endTime === 89
        ? '0-90s'
        : `${Math.round((Number(startTime) / 60) * 10) / 10}-${Math.round((Number(endTime) / 60) * 10) / 10}m`;
    if (label) {
      // Labels
      watchTimeChartData.labels.push(`${label}`);
      // Legends
      let legends = Object.keys(element);
      legends = removeElementsfromArray(legends, [API_RESPONSE_DIFFS.START_TIME, API_RESPONSE_DIFFS.END_TIME]);
      // Datasets
      watchTimeChartData.datasets = legendsMapToDataSets(element, watchTimeChartData.datasets, legends, _index);
    }
  });
  return watchTimeChartData;
};

export const getWatchTimeTableData = (responseObject: API_RESPONSE_TYPE): barChartDataType => {
  const watchTimeTableData: barChartDataType = { labels: [], extraInfo: [], datasets: {} };
  responseObject[Object.keys(responseObject)[0]].forEach((element, _index) => {
    const startTime = element.startTime;
    const endTime = element.endTime;
    const label =
      startTime === 0 && endTime === 89
        ? 'Less than 90 seconds'
        : `${Math.round((Number(startTime) / 60) * 10) / 10}-${Math.round((Number(endTime) / 60) * 10) / 10} minutes`;
    if (label) {
      // Labels
      watchTimeTableData.labels.push(label);
      // Legends
      let legends = Object.keys(element);
      legends = removeElementsfromArray(legends, [API_RESPONSE_DIFFS.START_TIME, API_RESPONSE_DIFFS.END_TIME]);
      // Datasets
      watchTimeTableData.datasets = legendsMapToDataSets(element, watchTimeTableData.datasets, legends, _index);
    }
  });
  return watchTimeTableData;
};

export const watchTimeDataNormal = (chartData: barChartDataType): barChartDataType => {
  const watchTimeData: barChartDataType = { labels: [], extraInfo: [], datasets: {} };
  const clonedChartData = cloneDeep(chartData);
  const labelsArr = clonedChartData.labels;
  if (labelsArr.length > 5) {
    const labelsSplit = splitToChunks(labelsArr, 5);
    let i = 0;
    labelsSplit.map((element, _index) => {
      if (element.length > 0) {
        const startTimeValue = element[0].split('-')[0];
        const endTimeValue = element[element.length - 1].split('-')[1].replace('m', '').replace('s', '');
        watchTimeData.labels.push(`${startTimeValue}-${endTimeValue}m`);
        element.map(() => {
          Object.keys(clonedChartData.datasets).map((legend) => {
            if (!(legend in watchTimeData.datasets)) {
              watchTimeData.datasets[legend] = new Array(labelsSplit.length).fill(0);
            }
            watchTimeData.datasets[legend][_index] += clonedChartData.datasets[legend][i]
              ? clonedChartData.datasets[legend][i]
              : 0;
          });
          i++;
        });
      }
    });
  }
  else if (labelsArr.length > 0 && labelsArr.length <= 5) {
    return chartData;
  }
  return watchTimeData;
};
