export type UpdateError = (type: VALIDATIONS, invalid?: boolean) => void;

export interface SignedUrlResponse {
  uploadUrl: string;
  statusUrl: string;
  statusCallback: string;
}

interface PersistentUrls {
  enabled: boolean;
  dashUrl: string;
  hlsUrl: string;
  dashUrlEnabled: boolean;
  hlsUrlEnabled: boolean;
}

export interface WebEventProfile {
  uuid: string;
  name: string;
  description: string;
  bucket: string;
  loadBalPrefix: string;
  deleteAfter: number;
  delaySeconds: number;
  persistentUrls: PersistentUrls;
  chromeCastAppId: string;
  regionId: string;
  branded: boolean;
  type: string;
  dvrWindowSeconds?: number;
}

export interface ValidationsObject {
  [key: string]: { message: string; invalid: boolean; key: string };
}

export enum VALIDATIONS {
  BACK_END = 'BACK_END',
  NETWORK_ERROR = 'NETWORK_ERROR',
  NOT_A_VIDEO = 'NOT_A_VIDEO',
  INVALID_FORMAT = 'INVALID_FORMAT',
  INVALID_FPS = 'INVALID_FPS',
  INVALID_FRAMERATE_MODE = 'INVALID_FRAMERATE_MODE',
  INVALID_DIMENSIONS = 'INVALID_DIMENSIONS',
  BLANK_EVENT_NAME = 'BLANK_EVENT_NAME',
  BLANK_EVENT_PROFILE = 'BLANK_EVENT_PROFILE',
  BLANK_WEB_EVENT_PROFILE = 'BLANK_WEB_EVENT_PROFILE',
  NO_UPLOAD_FILE = 'NO_UPLOAD_FILE',
  FILE_SIZE_0 = 'FILE_SIZE_0',
  INVALID_FILE_TYPE = 'INVALID_FILE_TYPE',
  INVALID_CONTAINER_FORMAT = 'INVALID_CONTAINER_FORMAT',
  INVALID_AUDIO_TYPE = 'INVALID_AUDIO_TYPE',
  MAX_DURATION = 'MAX_DURATION',
  MAX_DURATION_RELAXED = 'MAX_DURATION_RELAXED',
  MAX_FILE_SIZE = 'MAX_FILE_SIZE',
  MAX_FILE_SIZE_RELAXED = 'MAX_FILE_SIZE_RELAXED',
  MAX_AUDIO_CHANNELS = 'EXCEEDS_MAX_AUDIO_CHANNELS',
  MAX_AUDIO_BITRATE = 'EXCEEDS_MAX_AUDIO_BITRATE',
  MAX_VIDEO_CHANNELS = 'EXCEEDS_MAX_VIDEO_CHANNELS',
  MAX_VIDEO_BITRATE = 'EXCEEDS_MAX_VIDEO_BITRATE_RELAXED',
  MAX_RESOLUTION = 'MAX_RESOLUTION',
  MAX_GOP = 'MAX-GOP',
  INVALID_AUDIO_SAMPLERATE = 'INVALID_AUDIO_SAMPLERATE',
  NO_AUDIO = 'NO_AUDIO',
}

export enum CHECKSUM_MESSAGE {
  SUCCESS = 'success',
  ERROR = 'error',
  PROGRESS = 'progress',
}

export enum DescriptorTag {
  DecoderConfigDescriptorTag = 0x04,
  DecoderSpecificInfoTag = 0x05,
}
