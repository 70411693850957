'use strict';
import { trackMixpanelEvent, MPEventName, MPEventProperty } from '../../../../src/mixpanel';
const jcs = require('../../jcs');

class CueManagerCtrl {

	constructor($http, Authentication) {
		'ngInject';

		this.http = $http;
		this.auth = Authentication;

		this.has_perm_add = Authentication.getCurrentUser().hasPerm('cues.add');

		this.customer_id = null;

		this.cues = null;
		this.can_share_cues = false;

		this.is_showing_cues = true;
		this.cue_to_add = null;
		this.cue_to_edit = null;
		this.cue_to_update = null;
		this.cue_to_delete = null;

		this.loading_cues_timeout_id = null;
		this.UPDATE_CUES_TIME_DELAY = 10000; // update cues every 10 seconds

		this.error_msg = null;
		this.is_loading = false;
		this.is_working = false;

		this.OPTION_YES = 'Yes';
		this.OPTION_NO = 'No';
		this.share_cue_options = [this.OPTION_YES, this.OPTION_NO];
	}

	$onChanges(changes) {

		if (changes.webEventId && changes.webEventId.currentValue) { // web event
			// init customer ID
			this.customer_id = this.auth.getCurrentUser().customerID;
			this.loadCues();

		} else if (changes.encoderEventId && changes.encoderEventId.currentValue) { // encoder event

			this.loadCues();
		}
	}

	$onDestroy () {
		// check to see if we have a scheduled timeout
		if (this.loading_cues_timeout_id != null) {
			window.clearTimeout(this.loading_cues_timeout_id);
			this.loading_cues_timeout_id = null;
		}
	}

	canShowAddBtn () {
		return this.has_perm_add;
	}

	canShowCueList () {
		// don't show cues list if we are editing, updating, or deleting a cue. also don't show if adding a cue, but we don't have any cues in our list
		// if we do have 1 or more cues, then the list remains displayed when adding a new cue -- so user can refer to them if necessary.
		return this.is_showing_cues && ( !this.cue_to_edit && !this.cue_to_update && !this.cue_to_delete && !(this.cue_to_add && this.cues.length == 0));
	}

	toggleShowCues (showCues) {
		this.is_showing_cues = showCues

		trackMixpanelEvent(
			showCues ? MPEventName.CUE_SHOW : MPEventName.CUE_HIDE,
			this.isWebEvent ? {[MPEventProperty.TRANSCODED_EVENT_UUID]: this.webEventId} : {[MPEventProperty.EVENT_UUID]: this.encoderEventId}
		)
	}

	isWebEvent () {
		return this.webEventId;
	}

	// returns the matching cue (we check UUID) from the cues list. Since the cues list gets auto
	// updated periodically, you should use this method to ensure you are working with a cue that is in the list.
	findCue (cueToFind) {
		for (var i = 0; i < this.cues.length; i++) {
			if (this.cues[i].uuid == cueToFind.uuid) {
				return this.cues[i];
			}
		}
		return null;
	}

	enterAddCueMode () {

		this.cue_to_add = {
			name: '',
			position: '00:00:00.000',
			shared: this.can_share_cues ? this.OPTION_YES : this.OPTION_NO
		};

		if (this.isVideoPlayerAvailable){
			this.cue_to_add.position = this.getVideoCurrentPosition();
		}
	}

	enterEditCueMode (cue) {

		this.cue_to_add = null;
		this.cue_to_edit = {
			uuid: cue.uuid,
			user: cue.user,
			name: cue.name,
			position: cue.position,
			shared: cue.privateCue ? this.OPTION_NO : this.OPTION_YES
		};
	}

	enterUpdateCueMode (cue) {

		this.cue_to_add = null;
		this.cue_to_update = {
			uuid: cue.uuid,
			user: cue.user,
			name: cue.name,
			position: cue.position,
			new_position: this.getVideoCurrentPosition()
		};
	}

	enterDeleteCueMode (cue) {

		this.cue_to_add = null;
		this.cue_to_delete = cue;
	}

	performAddCue () {

		this.is_working = true;

		var data = {
			name: this.cue_to_add.name,
			position: this.cue_to_add.position,
			privateCue: this.cue_to_add.shared === this.OPTION_NO,
		};

		var url = this.isWebEvent() ?
			`${jcs.api.url_v3}/customers/${this.customer_id}/webevents/${this.webEventId}/cues` :
			`${jcs.api.url}/streamprofiles/${this.streamProfileId}/events/${this.encoderEventId}/cues`;

		this.http.post(url, data, { withCredentials: true }).then(() => {

			const mixPanelProps = {
				[MPEventProperty.CUE_NAME]: this.cue_to_add.name,
				[MPEventProperty.CUE_SHARED]: this.cue_to_add.shared === this.OPTION_YES,
			}

			if (this.isWebEvent()) {
				mixPanelProps[MPEventProperty.TRANSCODED_EVENT_UUID] = this.webEventId;
			} else {
				mixPanelProps[MPEventProperty.EVENT_UUID] = this.encoderEventId;
			}

			trackMixpanelEvent(MPEventName.CUE_ADD, mixPanelProps)

			this.cue_to_add = null;
			this.error_msg = null;

			this.loadCues();

		}).catch(() => {

			this.error_msg = 'An error occurred trying to create the cue. Please try again, or report the problem if it persists.';

		}).finally(() => {

			this.is_working = false;

		});
	}

	performEditCue () {

		this.is_working = true;

		var data = {
			name: this.cue_to_edit.name,
			position: this.cue_to_edit.position,
		};

		// when editing a cue, only set the privateCue value if the user is able to share cues. Otherwise the UI doesn't display a editable
		// field and so we don't need to be changing this value.
		if (this.can_share_cues){
			data.privateCue = this.cue_to_edit.shared == this.OPTION_NO;
		}

		var url = this.isWebEvent() ?
			`${jcs.api.url_v3}/customers/${this.customer_id}/webevents/${this.webEventId}/cues/${this.cue_to_edit.uuid}` :
			`${jcs.api.url}/streamprofiles/${this.streamProfileId}/events/${this.encoderEventId}/cues/${this.cue_to_edit.uuid}`;

		this.http.patch(url, data, { withCredentials: true }).then(() => {

			// use findCue to ensure we are updating a matching cue that is currently in cues list
			var cue = this.findCue(this.cue_to_edit);
			if (cue != null) {
				cue.name = this.cue_to_edit.name;
				cue.position = this.cue_to_edit.position;
				cue.privateCue = this.cue_to_edit.shared == this.OPTION_NO;
				cue.user = this.cue_to_edit.user;
			}

			this.cue_to_edit = null;
			this.error_msg = null;

		}).catch(() => {

			this.error_msg = 'An error occurred while attempting to update the cue. Please try again, or report the problem if it persists.';

		}).finally(() => {

			this.is_working = false;

		});
	}

	performUpdateCue () {

		this.is_working = true;

		var data = {
			position: this.cue_to_update.new_position
		};

		var url = this.isWebEvent() ?
			`${jcs.api.url_v3}/customers/${this.customer_id}/webevents/${this.webEventId}/cues/${this.cue_to_update.uuid}` :
			`${jcs.api.url}/streamprofiles/${this.streamProfileId}/events/${this.encoderEventId}/cues/${this.cue_to_update.uuid}`;

		this.http.patch(url, data, { withCredentials: true }).then(() => {
			const mixPanelProps = {
				[MPEventProperty.CUE_NAME]: this.cue_to_update.name,
				[MPEventProperty.CUE_SHARED]: this.cue_to_update.shared === this.OPTION_YES
			}

			if (this.isWebEvent()) {
				mixPanelProps[MPEventProperty.TRANSCODED_EVENT_UUID] = this.webEventId;
			} else {
				mixPanelProps[MPEventProperty.EVENT_UUID] = this.encoderEventId;
			}

			trackMixpanelEvent(MPEventName.CUE_UPDATE, mixPanelProps)

			// use findCue to ensure we are updating a matching cue that is currently in cues list
			var cue = this.findCue(this.cue_to_update);
			if (cue != null) {
				cue.position = data.position;
			}

			this.cue_to_update = null;
			this.error_msg = null;

		}).catch(() => {

			this.error_msg = 'An error occurred while attempting to update the cue. Please try again, or report the problem if it persists.';

		}).finally(() => {

			this.is_working = false;

		});
	}

	performDeleteCue () {

		this.is_working = true;

		var url = this.isWebEvent() ?
			`${jcs.api.url_v3}/customers/${this.customer_id}/webevents/${this.webEventId}/cues/${this.cue_to_delete.uuid}` :
			`${jcs.api.url}/streamprofiles/${this.streamProfileId}/events/${this.encoderEventId}/cues/${this.cue_to_delete.uuid}`;

		// NOTE: I normally would not include the "data: null", but IE11 and below seem to require it in order to work
		// see: https://github.com/angular/angular.js/issues/12141
		this.http.delete(url, { withCredentials: true, data: null }).then(() => {
			const mixPanelProps = {
				[MPEventProperty.CUE_NAME]: this.cue_to_delete.name,
			}

			if (this.isWebEvent()) {
				mixPanelProps[MPEventProperty.TRANSCODED_EVENT_UUID] = this.webEventId;
			} else {
				mixPanelProps[MPEventProperty.EVENT_UUID] = this.encoderEventId;
			}

			trackMixpanelEvent(MPEventName.CUE_DELETE, mixPanelProps)

			// use findCue to ensure we have a matching cue that is currently in cues list
			var cue = this.findCue(this.cue_to_delete);
			if (cue != null) {
				// get the index of the cue so we can remove it from the cue list
				var index = this.cues.indexOf(cue);
				if (index > -1) {
					this.cues.splice(index, 1);
				}
			}

			this.cue_to_delete = null;
			this.error_msg = null;

		}).catch(() => {

			this.error_msg = 'An error occurred while attempting to delete the cue. Please try again, or report the problem if it persists.';

		}).finally(() => {

			this.is_working = false;

		});
	}

	cancel () {

		this.cue_to_add = null;
		this.cue_to_edit = null;
		this.cue_to_update = null;
		this.cue_to_delete = null;
		this.error_msg = null;
	}

	loadCues () {

		this.is_loading = true;

		var url = this.isWebEvent() ?
			`${jcs.api.url_v3}/customers/${this.customer_id}/webevents/${this.webEventId}/cues?canISetCues=1` :
			`${jcs.api.url}/streamprofiles/${this.streamProfileId}/events/${this.encoderEventId}/cues?canISetCues=1`;

		this.http.get(url, { withCredentials: true }).then((response) => {

			this.cues = response.data;

			// cansetcues is returned in response header if we send "canISetCues=1" param to API
			var can_set_cues_header = response.headers('cansetcues');
			if (can_set_cues_header){
				this.can_share_cues = can_set_cues_header.toLowerCase() == 'true';
			}

			// setup timeout so our cue list will auto refresh
			this.loading_cues_timeout_id = window.setTimeout(this.loadCuesSilent.bind(this), this.UPDATE_CUES_TIME_DELAY);

		}).catch(() => {

			this.error_msg = 'There was an error loading the cues.';

		}).finally(() => {

			this.is_loading = false;

		});
	}

	loadCuesSilent () {

		// check to see if we have a scheduled timeout
		if (this.loading_cues_timeout_id != null) {
			// if so, then clear it (we'll check the encoder status down below and set a timeout if necessary)
			window.clearTimeout(this.loading_cues_timeout_id);
			this.loading_cues_timeout_id = null;
		}

		var url = this.isWebEvent() ?
			`${jcs.api.url_v3}/customers/${this.customer_id}/webevents/${this.webEventId}/cues?canISetCues=1` :
			`${jcs.api.url}/streamprofiles/${this.streamProfileId}/events/${this.encoderEventId}/cues`;

		// retrieve cues for this stream from server
		this.http.get(url, { withCredentials: true }).then((response) => {

			this.cues = response.data;

		}).catch(() => {
			// If our "silent" load gets an error, lets not display an error message -- I think it would be confusing for the user to see
			// an error about an action that they did not initiate. So instead of displaying an error, setup another timeout in the hopes
			// that the error we got as a one-time thing, and upcoming check will work.
		}).finally(() => {

			// setup timeout so our cues list will auto refresh
			this.loading_cues_timeout_id = window.setTimeout(this.loadCuesSilent.bind(this), this.UPDATE_CUES_TIME_DELAY);

		});
	};
}

export default class CueManagerComponent {
	constructor() {
		this.template = require('./cueManager.html').default;
		this.bindings = {

			webEventId: '<',
			streamProfileId: '<',
			encoderEventId: '<',

			isVideoPlayerAvailable: '<',
			getVideoCurrentPosition: '&',
			getVideoSeekRange: '&',

			onGoto: '&',
		};
		this.controller = CueManagerCtrl;
	}
}
