import CountryComponent from './country.component';

export const countryModule = {
	name: 'countryComponent',
	component: {
		name: 'country'
	}
};

export default angular
	.module(countryModule.name, [])
	.component(countryModule.component.name, new CountryComponent()).name;
