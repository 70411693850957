import React, { FC } from 'react';
import { FooterWrap, Innerwrap, Link, Version } from './style';

const ResiFooter: FC = () => (
  <FooterWrap>
    <Innerwrap>
      Copyright © 2015-2020{' '}
      <Link target="_blank" href="https://resi.io">
        Resi Media
      </Link>
      . All rights reserved.
      <Version>{process.env.VERSION}</Version>
    </Innerwrap>
  </FooterWrap>
);

export default ResiFooter;
