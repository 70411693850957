import { AxiosInstance, AxiosResponse, AxiosRequestConfig, La1AxiosRetryInstance, La1AxiosError, La1AxiosRequestConfig, AxiosError } from "axios";

export enum La1BackOffType {
  EXPONENTIAL = 'EXPONENTIAL',
  STATIC = 'STATIC',
}

export interface La1RetryConfigOptions {
  isResumable?: boolean;
  retries: number;
  instance: AxiosInstance;
  backoffType?: La1BackOffType;
  initialRetryDelay?: number;
  currentRetryAttempt?: number;
  ignoreNetworkErrors?: boolean;
  onRetryAttempt?: (err: La1AxiosError | Error) => Promise<Partial<AxiosRequestConfig> | void> | void;
  onSuccess?: (response: AxiosResponse) => AxiosResponse | Promise<AxiosResponse>;
}

export type La1ClientRetryConfig = {
  la1RetryConfig: La1RetryConfig;
} & AxiosRequestConfig;

const CONFIG_DEFAULTS = {
  BACKOFF_TYPE: La1BackOffType.EXPONENTIAL,
  INITIAL_RETRY_DELAY: 1000,
  CURRENT_RETRY_ATTEMPT: 0,
  IS_RESUMABLE: true,
  IGNORE_NETWORK_ERRORS: false,
} as const;

export class La1RetryConfig {
  retries: number;
  instance: AxiosInstance;
  backoffType: La1BackOffType;
  initialRetryDelay: number;
  currentRetryAttempt: number;
  isResumable: boolean;
  ignoreNetworkErrors: boolean;
  onSuccess?: (response: AxiosResponse) => AxiosResponse | Promise<AxiosResponse>;
  onRetryAttempt?: ((err: La1AxiosError | Error) => Promise<Partial<AxiosRequestConfig> | void> | void);
  constructor(config: La1RetryConfigOptions) {
    this.retries = config.retries;
    this.instance = config.instance;
    this.backoffType = config.backoffType ?? CONFIG_DEFAULTS.BACKOFF_TYPE;
    this.initialRetryDelay = config.initialRetryDelay ?? CONFIG_DEFAULTS.INITIAL_RETRY_DELAY;
    this.currentRetryAttempt = config.currentRetryAttempt ?? CONFIG_DEFAULTS.CURRENT_RETRY_ATTEMPT;
    this.isResumable = config.isResumable ?? CONFIG_DEFAULTS.IS_RESUMABLE;
    this.ignoreNetworkErrors = config.ignoreNetworkErrors ?? CONFIG_DEFAULTS.IGNORE_NETWORK_ERRORS;
    this.onRetryAttempt = config.onRetryAttempt;
    this.onSuccess = config.onSuccess;
  }
}

export default class La1Retry {
  initialRetries: number | null = null;
  isInitialCall = true;
  isResuming = false;
  axiosInstance: La1AxiosRetryInstance;

  constructor(instance: La1AxiosRetryInstance) {
    this.axiosInstance = instance;
    this.axiosInstance.defaults.onUploadProgress = this.onUploadProgress.bind(this);
    instance.interceptors.response.use(this.successHandler.bind(this), this.errorHandler.bind(this));
  }

  onUploadProgress(progressEvent: ProgressEvent) {
    if (this.axiosInstance.onUploadProgress) {
      this.axiosInstance.onUploadProgress(progressEvent);
    }
    if (!this.isInitialCall) {
      this.isResuming = true;
    }
  }

  getConfig(error: La1AxiosError | Error) {
    if ("config" in error) {
      return (error.config as La1ClientRetryConfig).la1RetryConfig;
    }
  }

  successHandler(axiosResponse: AxiosResponse): Promise<AxiosResponse> {
    return Promise.resolve((axiosResponse.config as La1ClientRetryConfig).la1RetryConfig.onSuccess?.(axiosResponse) ?? axiosResponse);
  }

  retryRequest(axiosConfig: La1AxiosRequestConfig, error: AxiosError | Error): Promise<AxiosResponse<any>> {
    const config = axiosConfig.la1RetryConfig;
    if (!config) {
      console.error(error);
      throw new Error("No La1RetryConfig in retryRequest");
    }
    if (this.initialRetries === null) {
      this.initialRetries = config.retries;
    }
    if (this.isResuming && !this.isInitialCall && config.isResumable) {
      config.retries = this.initialRetries;
      config.currentRetryAttempt = 0;
      this.isResuming = false;
    }
    this.isInitialCall = false;
    if (config.currentRetryAttempt >= config.retries) {
      return Promise.reject(error);
    }

    const onBackoffPromise = new Promise(resolve => {
      let delay: number;

      if (config.backoffType === La1BackOffType.STATIC) {
        delay = config.initialRetryDelay;
      } else {
        delay = ((Math.pow(2, config.currentRetryAttempt) + 1) / 2) * config.initialRetryDelay;
      }

      if (config.ignoreNetworkErrors && "request" in error && error.message === "Network Error") {
        console.log("Ignoring Network Error in retry attempt count");
      } else {
        config.currentRetryAttempt += 1;
      }
      setTimeout(resolve, delay);
    });

    const onRetryAttemptPromise = config.onRetryAttempt
      ? Promise.resolve(config.onRetryAttempt(error))
      : Promise.resolve(undefined);

    return onBackoffPromise
      .then(() => onRetryAttemptPromise)
      .then(configChanges => config.instance.request({ ...axiosConfig, ...configChanges }));

  }

  errorHandler(error: La1AxiosError): Promise<AxiosResponse<any>> | undefined {
    if (!error.config) {
      console.warn("No La1RetryConfig in errorHandler", error);
      return;
    }

    return this.retryRequest(error.config, error);
  }
}

declare module 'axios' {
  type ModifiedAxiosRequestConfig = Omit<AxiosRequestConfig, "onUploadProgress">;

  export interface La1AxiosRetryInstance extends AxiosInstance {
    onUploadProgress?: (progressEvent: ProgressEvent) => void;
  }
  export interface La1AxiosRequestConfig extends ModifiedAxiosRequestConfig {
    la1RetryConfig?: La1RetryConfig;
  }
  export interface La1AxiosError extends AxiosError {
    config: La1AxiosRequestConfig;
  }
}
