import 'angular-route';
import angular from 'angular';
import router from './module.licenseinfo.routes';

export const licenseinfoModule = {
	name: 'licenseinfo',
	controllers: {
		default: 'licenseInfoCtrl',
	},
	routes: {
		list: '/licenseinfo',
	},
};

export default angular
	.module(licenseinfoModule.name, ['ngRoute'])
	.config(router)
	.controller(licenseinfoModule.controllers.default, require('./licenseInfoCtrl')).name;
