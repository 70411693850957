import TimeComponent from './time.component';

export const timeModule = {
	name: 'timeComponent',
	component: {
		name: 'time'
	}
};

export default angular
	.module(timeModule.name, [])
	.component(timeModule.component.name, new TimeComponent()).name;
