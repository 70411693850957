class SimulcastErrorDlgContentCtrl {
	constructor(socialMedia) {
		'ngInject';
		this.socialMedia = socialMedia.init();
	}
}

// example usage:
// <simulcast-error-dlg-content code="obj.code" type="obj.type" title="obj.title" web-event-name="obj.web_event_name"></simulcast-error-dlg-content>
//
export default class SimulcastErrorDlgContentComponent {
	constructor() {
		this.template = require('./simulcastErrorDlgContent.html').default;
		this.bindings = {
			code: '<',
			type: '<',
			webEventName: '<',
			title: '<',
		};
		this.controller = SimulcastErrorDlgContentCtrl;
	}
}
