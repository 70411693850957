import angular from 'angular';
import FormValidationService from './formValidation.service';
import HttpService from './http.service';
import SocialMediaService from './socialMedia.service';
import SocialMediaAuthService from './socialMediaAuth.service';
import WebEventsService from './webEvents.service';
import SimulcastsService from './simulcasts.service';
import EventService from './event.service';
import GoogleChartsService from './googleCharts.service';
import PaginationService from './pagination.service';
import EncoderService from './encoder.service';
import UserService from './user.service';
import CueService from './cue.service';
import RegionService from './region.service';
import EventProfileService from './eventProfile.service';
import OrganizationService from './organization.service';
import HardwwareService from './hardware.service';
import VersionService from './version.service';
import WebEventProfileService from './webEventProfile.service';
import UIService from './ui.service';
import TimeZoneService from './timeZone.service';
import CsvService from './csv.service';
import UploadsInProgressService from './uploadsInProgress.service';
import LibraryService from './library.service';
import EmbedCodeService from './embedCode.service';
import LaunchDarklyService from './launchDarkly.service';

export default angular
	.module('app.services', ['ngPromiseAddons'])
	.service('formValidationService', FormValidationService)
	.service('httpService', HttpService)
	.service('socialMedia', SocialMediaService)
	.service('socialMediaAuth', SocialMediaAuthService)
	.service('webEventsService', WebEventsService)
	.service('simulcastsService', SimulcastsService)
	.service('eventService', EventService)
	.service('webPlayerService', require('../../plugins/webplayer/app.webplayer'))
	.service('googleChartsService', GoogleChartsService)
	.service('encoderService', EncoderService)
	.service('userService', UserService)
	.service('cueService', CueService)
	.service('regionService', RegionService)
	.service('eventProfileService', EventProfileService)
	.service('orgService', OrganizationService)
	.service('hardwareService', HardwwareService)
	.service('versionService', VersionService)
	.service('webEventProfileService', WebEventProfileService)
	.service('uiService', UIService)
	.service('timeZoneService', TimeZoneService)
	.service('CsvService', CsvService)
	.service('uploadsInProgressService', UploadsInProgressService)
	.service('libraryService', LibraryService)
	.service('embedCodeService', EmbedCodeService)
	.service('launchdarklyService', LaunchDarklyService)
	.service('paginationService', PaginationService).name;

