import { FC } from 'react';
import { createPortal } from 'react-dom';

const Portal: FC = ({ children }) => {
  const modalRoot = document.getElementById('react-portal') as HTMLElement;

  return createPortal(children, modalRoot);
};

Portal.displayName = 'Portal';

export default Portal;
