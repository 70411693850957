import React, { ChangeEvent, FC, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Paper } from '@material-ui/core';
import { DateTime } from 'luxon';
import {
  EventStatsGraphWrapper,
  EventStatsHeaderDiv,
  GraphWrapper,
  TabsDiv,
  DropDownWrapper,
  ChartControls,
  InfoBoxWrapper,
} from './youtuber-stats-graph.style';
import Tooltip from '@material-ui/core/Tooltip';
import { Inline, Select, Grid, Text, Option, Stack } from '@resi-media/resi-ui';
import { FieldLabel, ToggleInput, ThemeProvider, theme } from '@resi-media/resi-ui-latest'
import {
  ClockCircleOutlined,
  TeamOutlined,
  UndoOutlined,
  ZoomInOutlined,
  ZoomOutOutlined,
} from '@ant-design/icons';
import { GROUP_BY_VALUE, lineChartDataType, SelectOption, TIME_WINDOWS, YOUTUBE_PAGES } from '../../types';
import { Modal, ModalContent, ModalFooter, ModalHeader, Portal } from '../../../../components';
import { DateRange, Range } from 'react-date-range';
import { renameKey } from '../../utils';
import {
  DESTINATION_TYPES,
  LEGEND_NAMES,
  LOADER_SIZE_COMPONENT,
  PAGE_VALUE,
  TOOLTIP_LABEL,
  ZOOM_CTRL_TOOLTIP,
} from '../../event-analytics-constants';
import {
  ContainedButton,
  ContainedTab,
  ContainedTabs,
  FillBlockSpinner,
  InfoBox,
  LineChart,
  NavTab,
  NavTabs,
  Surface,
  ViewEvents,
} from '../../../../components';
import { EventAnalyticsContext } from '../../../../contexts/eventanalytics/event-analytics';
import { LIFE_TIME_DATE_LOCAL, START_DATE_LOCAL } from '../../date-range-options';
// DateRangeSelector CSS dependencies
import 'react-date-range/dist/styles.css'; // main style
import 'react-date-range/dist/theme/default.css'; // theme css file
// END

interface YoutubeGraphViewProps {
  areChannelsLoading: boolean;
  channelOptions: SelectOption[];
  data: lineChartDataType;
  dateRangeOptions: SelectOption[];
  isDataCollectionOn: boolean;
  isMonthTimeWindowDisabled: boolean;
  isChartLoading: boolean;
  isDatePickerOpen: boolean;
  isTogglingDataCollection: boolean;
  hasGraphPerms: boolean;
  timeWindow: number;
  dataType: number;
  dataPage: number;
  dataPageName: string;
  handleChannelChange: (channel: string) => void;
  handleGroupByChange: (event: ChangeEvent<unknown>, newValue: GROUP_BY_VALUE) => void;
  handleDataPageChange: (event: ChangeEvent<unknown>, newValue: YOUTUBE_PAGES) => void;
  handleDateRangeChange: (item: Option[]) => void;
  handleTimeWindowChange: (event: ChangeEvent<unknown>, newValue: TIME_WINDOWS) => void;
  handleCustomDateRangeSelection: (startDate: string, endDate: string) => void;
  handleIsDataCollectionToggled: React.Dispatch<React.SetStateAction<boolean>>;
  toggleDataCollection: (isToggled: boolean) => void;
  closeDatePicker: () => void;
  selectedChannel: string;
}

const YoutubeStatsGraphView: FC<YoutubeGraphViewProps> = ({
  areChannelsLoading,
  channelOptions,
  data,
  dateRangeOptions,
  isMonthTimeWindowDisabled,
  isChartLoading,
  isDataCollectionOn,
  isDatePickerOpen,
  isTogglingDataCollection,
  hasGraphPerms,
  timeWindow,
  dataType,
  dataPage,
  dataPageName,
  closeDatePicker,
  handleChannelChange,
  handleGroupByChange,
  handleDataPageChange,
  handleDateRangeChange,
  handleTimeWindowChange,
  handleCustomDateRangeSelection,
  handleIsDataCollectionToggled,
  toggleDataCollection,
  selectedChannel,
}): JSX.Element => {
  const {
    EventAnalyticsState: {
      viewAllData,
      dateRange: { startDate, endDate },
      eventId,
      dateRangeOption,
    },
  } = useContext(EventAnalyticsContext);
  const [selectionRange, setSelectionRange] = useState<Range>({
    startDate: new Date(startDate),
    endDate: new Date(endDate),
    key: 'selection',
  });
  const [isInfoBoxDisplayed, setInfoBoxDisplayStatus] = useState<boolean>(true);
  const [isDataCollectionModalOpen, setIsDataCollectionModalOpen] = useState<boolean>(false);
  const disableTab = dataPageName !== PAGE_VALUE.LIVE_VIEWS ? true : false;
  const disableDeviceTab = dataPageName === PAGE_VALUE.AVG_WATCH_TIME ? true : false;

  const handleDateRangeSelect = (range: { selection: Range }) => {
    const { selection } = range;
    const { hour, minute, second, millisecond } = DateTime.local().toObject();
    setSelectionRange({
      startDate: selection.startDate,
      endDate: selection.endDate,
      key: 'selection',
    });
    if (selectionRange.startDate && selectionRange.endDate) {
      const selectionStartDate = selection.startDate
        ? DateTime.fromJSDate(selection.startDate)
            .plus({
              hours: hour,
              minutes: minute,
              seconds: second,
              milliseconds: millisecond,
            })
            .toISO() || ''
        : '';
      const selectionEndDate = selection.endDate
        ? DateTime.fromJSDate(selection.endDate)
            .plus({
              hours: hour,
              minutes: minute,
              seconds: second,
              milliseconds: millisecond,
            })
            .toISO() || ''
        : '';
      handleCustomDateRangeSelection(selectionStartDate, selectionEndDate);
    }
  };

  const handleTurnOffDataCollection = async () => {
    await toggleDataCollection(false);
    handleIsDataCollectionToggled(false);
    setIsDataCollectionModalOpen(false);
  }

  Object.keys(data.datasets).map((el) => {
    if (el in LEGEND_NAMES) {
      data.datasets = renameKey(data.datasets, el, `${el}`);
    }
  });

  return (
    <Grid.Item>
      <Paper elevation={0} variant="outlined" data-testid="youtube-stats-view">
        <EventStatsGraphWrapper hasGraphPerms={hasGraphPerms} isInfoBoxDisplayed={isInfoBoxDisplayed}>
          <Stack scale="m" noImportant>
            <Inline justifyContent="space-between" alignItems="center" noImportant>
              <div style={{ width: '350px', maxWidth: '100%' }}>
                <Text.Body size="xs" isUppercase isBold color="secondary">
                  YouTube Channel
                </Text.Body>
                <DropDownWrapper>
                  <Select
                    disabled={areChannelsLoading || !channelOptions?.length}
                    disableToggle
                    fullWidth
                    size="m"
                    value={selectedChannel}
                    options={channelOptions}
                    placeholder="Select a Channel"
                    handleChange={(newValue) => {
                      if (newValue[0].value) {
                        handleChannelChange(newValue[0].value);
                      }
                    }}
                  />
                </DropDownWrapper>
              </div>
              <ThemeProvider theme={theme}>
                <ToggleInput
                  checked={isDataCollectionOn} 
                  endNode={<FieldLabel fieldLabel="Collect Channel Data" hint="You can turn on and off the collection of YouTube channel data here." />} 
                  onChange={() => {
                    if(isDataCollectionOn) {
                      setIsDataCollectionModalOpen(true);
                    } else {
                      handleIsDataCollectionToggled(true);
                      toggleDataCollection(true);
                    }
                  }}
                />
              </ThemeProvider>
            </Inline>
            <EventStatsHeaderDiv>
              <Inline alignItems="center">
                <div>
                  <Text.Body size="xs" isUppercase isBold color="secondary">
                    {`${DateTime.fromISO(startDate).toFormat('MMM dd yyyy')} - \
                ${DateTime.fromISO(endDate).toFormat('MMM dd yyyy')}`}
                  </Text.Body>
                  <DropDownWrapper>
                    <Select
                      size="m"
                      value={dateRangeOption?.value}
                      options={dateRangeOptions}
                      handleChange={(newValue) => {
                        if (newValue[0].value) {
                          handleDateRangeChange(newValue);
                        }
                      }}
                    />
                  </DropDownWrapper>
                  {isDatePickerOpen && (
                    <Modal width="fit-content">
                      <Surface padding={'0'}>
                        <ModalHeader titleText="Pick custom date range" closeButton onClose={() => closeDatePicker()} />
                        <DateRange
                          ranges={[selectionRange]}
                          editableDateInputs
                          onChange={handleDateRangeSelect}
                          minDate={viewAllData ? LIFE_TIME_DATE_LOCAL.toJSDate() : START_DATE_LOCAL.toJSDate()}
                        />
                      </Surface>
                    </Modal>
                  )}
                </div>
                <ViewEvents
                  dateRangeOptions={dateRangeOptions}
                  dateRangeSelection={dateRangeOption}
                  page={DESTINATION_TYPES.YOUTUBE}
                />
              </Inline>
              <Inline justifyContent="center" alignItems="center">
                <NavTabs onChange={handleDataPageChange} value={dataPage}>
                  <NavTab data-testid="youtube-analytics-views-tab" icon={<TeamOutlined />} label="Views" />
                  <NavTab
                    data-testid="youtube-avgViewDuration-tab"
                    icon={<ClockCircleOutlined />}
                    label="Avg View Duration"
                  />
                </NavTabs>
              </Inline>
            </EventStatsHeaderDiv>
          </Stack>
          {hasGraphPerms && (
            <>
              <TabsDiv>
                <ContainedTabs onChange={handleGroupByChange} value={dataType}>
                  <ContainedTab data-testid="youtube-none-tab" label="None" />
                  <ContainedTab data-testid="youtube-eventType-tab" label="Event Type" />
                  <ContainedTab disabled={disableDeviceTab} data-testid="youtube-deviceType-tab" label="Device Type" />
                </ContainedTabs>
                <Tooltip title={<h6>{ZOOM_CTRL_TOOLTIP}</h6>} arrow placement="top">
                  <ChartControls>
                    <ContainedButton id="zoomPlus">
                      <ZoomInOutlined style={{ fontSize: '20px' }} />
                    </ContainedButton>
                    <ContainedButton id="zoomMinus">
                      <ZoomOutOutlined style={{ fontSize: '20px' }} />
                    </ContainedButton>
                    <ContainedButton id="reset-zoom">
                      <UndoOutlined style={{ fontSize: '20px' }} />
                    </ContainedButton>
                  </ChartControls>
                </Tooltip>
                <ContainedTabs onChange={handleTimeWindowChange} value={timeWindow}>
                  <ContainedTab data-testid="youtube-day-tab" label="Day" />
                  <ContainedTab disabled={disableTab} data-testid="youtube-week-tab" label="Week" />
                  <ContainedTab
                    disabled={disableTab || isMonthTimeWindowDisabled}
                    data-testid="youtube-month-tab"
                    label="Month"
                  />
                </ContainedTabs>
              </TabsDiv>
              <GraphWrapper data-testid="youtube-stats-view-lineChart">
                {isInfoBoxDisplayed && dataPageName !== PAGE_VALUE.LIVE_VIEWS && (
                  <InfoBoxWrapper>
                    <InfoBox
                      closeButton
                      infoText={
                        'Historical data will be available after 2-3 days of live streaming. This is due to the limitation of YouTube API.'
                      }
                      onClose={() => setInfoBoxDisplayStatus(false)}
                    />
                  </InfoBoxWrapper>
                )}
                {isChartLoading ? (
                  <FillBlockSpinner fontSize={LOADER_SIZE_COMPONENT} />
                ) : (
                  <LineChart data={data} dataPageName={dataPageName} tooltipLabel={TOOLTIP_LABEL.VIEWS} />
                )}
              </GraphWrapper>
            </>
          )}
        </EventStatsGraphWrapper>
      </Paper>
      {isDataCollectionModalOpen && (
        <Portal>
          <Modal width="482px">
            <ModalHeader closeButton onClose={() => setIsDataCollectionModalOpen(false)} titleText="Disable YouTube Data Collection" />
            <ModalContent>
              <Stack scale="m">
                <Text.Body>
                    This will remove all of your YouTube channel data that is stored by Resi, and prevent the collection
                    of any future data.
                </Text.Body>
                <Inline>
                  <Text.Body>Channel:</Text.Body>
                  <Text.Body isBold>
                    {channelOptions.find((x) => x.value === selectedChannel)?.label}
                  </Text.Body>
                </Inline>
              </Stack>
            </ ModalContent>
            <ModalFooter>
              <button className="btn btn-default" type="button" onClick={() => setIsDataCollectionModalOpen(false)}>
                Cancel
              </button>
              <button
                className="btn btn-danger"
                type="button"
                disabled={isTogglingDataCollection}
                onClick={handleTurnOffDataCollection}
              >
                {isTogglingDataCollection && (
                  <i className="fa fa-spinner fa-spin" aria-hidden="true" style={{ marginRight: 7 }}></i>
                )}
                Continue
              </button>
            </ModalFooter>
          </Modal>
        </Portal>
      )}

    </Grid.Item>
  );
};

YoutubeStatsGraphView.defaultProps = {
  data: {
    labels: ['Aug 9', 'Sep 1', 'Sep 2', 'Sep 9', 'Sep 15', 'Oct 1', 'Oct 8'],
    datasets: { data: [12, 7, 14, 30, 11, 12, 4, 79, 13, 26, 12, 21, 10] },
  },
};

YoutubeStatsGraphView.propTypes = {
  isChartLoading: PropTypes.bool.isRequired,
  data: PropTypes.objectOf(
    PropTypes.exact({
      labels: PropTypes.arrayOf(PropTypes.string).isRequired,
      datasets: PropTypes.arrayOf(
        PropTypes.objectOf(
          PropTypes.exact({
            data: PropTypes.arrayOf(PropTypes.number).isRequired,
            label: PropTypes.string.isRequired,
          })
        )
      ),
    })
  ).isRequired,
  isMonthTimeWindowDisabled: PropTypes.bool.isRequired,
  dateRangeOptions: PropTypes.arrayOf(
    PropTypes.exact({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.number.isRequired]).isRequired,
    }).isRequired
  ).isRequired,
  handleDateRangeChange: PropTypes.func.isRequired,
  hasGraphPerms: PropTypes.bool.isRequired,
  dataPageName: PropTypes.string.isRequired,
  handleTimeWindowChange: PropTypes.func.isRequired,
  timeWindow: PropTypes.number.isRequired,
  dataType: PropTypes.number.isRequired,
  dataPage: PropTypes.number.isRequired,
  handleGroupByChange: PropTypes.func.isRequired,
  handleDataPageChange: PropTypes.func.isRequired,
  isDatePickerOpen: PropTypes.bool.isRequired,
  closeDatePicker: PropTypes.func.isRequired,
  handleCustomDateRangeSelection: PropTypes.func.isRequired,
};

YoutubeStatsGraphView.displayName = 'YoutubeStatsGraphView';

export default YoutubeStatsGraphView;
