import { perms } from '../../jcs';
import { eventsModule } from './index';

function routeConfig($provide, $routeProvider) {
	'ngInject';

	$provide.factory('$routeProvider', () => {
		return $routeProvider;
	});

	var default_options = {
		controller: eventsModule.controllers.default,
		template: require('./events.tmpl.html').default,
		activenav: eventsModule.name,
		access: {
			loginRequired: true,
			permissions: [perms['events.get']],
		}
	};

	$routeProvider.when(eventsModule.routes.list, default_options);
	$routeProvider.when(eventsModule.routes.watch_web, default_options);
	$routeProvider.when(eventsModule.routes.details_web, default_options);
	$routeProvider.when(eventsModule.routes.edit_web, default_options);
	$routeProvider.when(eventsModule.routes.delete_web, default_options);
	$routeProvider.when(eventsModule.routes.stop_web, default_options);
	$routeProvider.when(eventsModule.routes.analytics_web, default_options);
}

export default routeConfig;
