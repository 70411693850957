import React, { FC, useEffect, useState, useContext } from 'react';
import VirtualizedTable from '../VirtualizedTable/virtualized-table';
import { Paper } from '@material-ui/core';
import { Text, customProperties as vars } from '@resi-media/resi-ui';
import clone from 'lodash.clone';
import { fetchVideoCueData } from './api';
import { EventAnalyticsContext } from '../../contexts/eventanalytics/event-analytics';
import { VirtualizedTableData } from '../VirtualizedTable/types';
import { VideoCuesHeader, VideoCuesTableContainer } from './video-cues.styles';
import { SORT_DIRECTION } from '../../pages/EventAnalytics/event-analytics-constants';
import { FillBlockSpinner } from '../../components';
import { LOADER_SIZE_COMPONENT } from '../../pages/EventAnalytics/event-analytics-constants';
import { SORT_ORDER } from '../../pages/EventAnalytics/types';
import { ChangeEvent } from 'react';
import { DataObject } from '../../pages/EventAnalytics/types';
import { sortArray } from '../../pages/EventAnalytics/utils';

export interface VideoCuesDataAPIResponseType {
  name: string;
  position: string;
}

const VideoCues: FC = () => {
  const [sortDirection, setSortDirection] = useState<SORT_ORDER>(SORT_DIRECTION.asc);
  const [isVideoCueLoading, setVideoCueLoading] = useState<boolean>(false);
  const [columnSortBy, setColumnBy] = useState<string>('position');
  const { EventAnalyticsState } = useContext(EventAnalyticsContext);
  const [responseData, setResponse] = useState<DataObject[]>([]);
  const [tableData, setTableData] = useState<VirtualizedTableData>({
    data: [],
    header: [],
  });
  const getVideoCuesData = async () => {
    setVideoCueLoading(true);
    const response = await fetchVideoCueData(EventAnalyticsState);
    setVideoCueLoading(false);
    setResponse(response);
  };

  const sortTableData = () => {
    const sortedTableData = sortArray(responseData, columnSortBy, sortDirection);
    const cloneData: VideoCuesDataAPIResponseType[] = clone(sortedTableData as VideoCuesDataAPIResponseType[]);
    const tableFormatedData: VirtualizedTableData = {
      header: [
        { label: 'CUE NAME', sortable: true },
        { label: 'POSITION', sortable: true },
      ],
      data: [],
    };
    cloneData?.map((element) => {
      tableFormatedData.data.push({
        payload: element,
        row: [element.name, element.position],
      });
    });
    setTableData(tableFormatedData);
  };

  useEffect(() => {
    getVideoCuesData();
  }, [EventAnalyticsState]);

  useEffect(() => {
    sortTableData();
  }, [sortDirection, responseData]);

  const handleSort = (event: ChangeEvent<unknown>, direction: string | undefined, column: string) => {
    setSortDirection(direction === SORT_DIRECTION[0] ? SORT_DIRECTION.desc : SORT_DIRECTION.asc);
    switch (column) {
      case 'POSITION':
        setColumnBy('position');
        break;
      case 'CUE NAME':
        setColumnBy('name');
        break;
      default:
        setColumnBy(columnSortBy);
    }
  };
  return (
    <Paper elevation={0} variant="outlined" style={{ height: 'fit-content' }} data-testid="video-cues-table">
      {isVideoCueLoading ? (
        <FillBlockSpinner fontSize={LOADER_SIZE_COMPONENT} />
      ) : (
        <>
          <VideoCuesHeader>
            <Text.Heading type="h4" customCss={`color: ${vars.colorAccent800};`}>Video Cues</Text.Heading>
            <Text.Body size="m">
                Cues are like bookmarks within your video. Use these cues to navigate to specified parts of the graph above
            </Text.Body>
          </VideoCuesHeader>
          {tableData.data.length > 0 ? (
            <VideoCuesTableContainer>
              <VirtualizedTable
                data={tableData}
                isDataSortable={true}
                handleSort={(event: ChangeEvent<unknown>, sortDirectionName: string | undefined, column: string) => {
                  handleSort(event, sortDirectionName, column);
                }}
                sortDirectionName={SORT_DIRECTION[sortDirection]}
              />
            </VideoCuesTableContainer>
          ) : (
            <VideoCuesTableContainer>
              <Text.Body>No video cues data available</Text.Body>
            </VideoCuesTableContainer>
          )}
        </>
      )}
    </Paper>
  );
};
VideoCues.displayName = 'VideoCues';

export default VideoCues;
