import angular from 'angular';

// Dynamic Tooltips. These tooltips can be used on elements that are dynamically created by angular. The tooltips are static though.
// (makes use of bootstrap tooltips)
// usage:
// <i class="fa fa-whatever" dyntooltip data-tooltip-position="left" title="Tooltip text goes here"></i>
function dyntooltip() {
	return {
		restrict: 'A',
		link: function (scope, element, attrs) {
			var is_initialized = false;

			// create full jquery element. Otherwise JQLite element does not have hover method.
			element = $(element);

			element.hover(
				function () {
					// on mouseenter
					if (!is_initialized) {
						is_initialized = true;
						element.tooltip({
							title: attrs.title,
							placement: attrs.tooltipPosition, // data-tooltip-position gets converted to tooltipPosition
						});
					}
					element.tooltip('show');
				},
				function () {
					// on mouseleave
					element.tooltip('hide');
				}
			);

			element.on('$destroy', function () {
				element.tooltip('destroy');
			});
		},
	};
}

export default angular.module('directives.dyntooltip', []).directive('dyntooltip', dyntooltip).name;
