import 'angular-route';
import angular from 'angular';
import { react2angular } from 'react2angular';
import router from './module.getstarted.routes';
import commonServicesModule from '../../services';

// react components
import { GetStarted } from '../../../../src/pages';

export const getStartedModule = {
  name: 'getstarted',
  controllers: {
    default: 'getStartedCtrl'
  },
  routes: {
    emailVerification: '/emailverification/:token',
    finishSetup: '/finishsetup'
  },
};

export default angular
  .module(getStartedModule.name, ['ngRoute', commonServicesModule])
  .config(router)
  .component('getStarted', react2angular(GetStarted, ['token']))
  .controller(getStartedModule.controllers.default, require('./getStartedCtrl')).name;
