import 'angular-route';
import angular from 'angular';
import router from './module.schedule.routes';
import commonServicesModule from '../../services';

export const scheduleModule = {
	name: 'schedule',
	controllers: {
		default: 'scheduleCtrl',
	},
	routes: {
		list: '/schedule',
	},
};

export default angular
	.module(scheduleModule.name, ['ngRoute', commonServicesModule])
	.config(router)
	.controller(scheduleModule.controllers.default, require('./scheduleCtrl')).name;
