import SimulcastSelectorComponent from './simulcastSelector.component';
import { react2angular } from 'react2angular';

// react components
import { ImageUpload } from '../../../../src/components';

export const simulcastSelectorModule = {
  name: 'simulcastSelectorComponent',
  component: {
    name: 'simulcastSelector',
  },
};

export default angular
  .module(simulcastSelectorModule.name, [])
  .component('imageUpload', react2angular(ImageUpload, ['destination', 'customerId', 'imageUrl', 'label', 'width', 'onFilePathChanged', 'onImageCropped', 'onCancel', 'getRegion', 'getRoutingKey']))
  .component(simulcastSelectorModule.component.name, new SimulcastSelectorComponent()).name;
