import { api } from '../jcs';

class UserService {
  private isLoadingRoles = false;
  private availableRoles = null;
  private eventBus = null;

  public RESI_ROLE = 'Resi';
  public SOCIAL_MEDIA_ROLE = 'socialMedia';

  constructor(private $q: ng.IQService, private $http: ng.IHttpService) {
    'ngInject';
  }

  public isLoading(): boolean {
    return this.isLoadingRoles;
  }

  public getAvailableRoles(customerId: string): ng.IPromise<any> {
    // do we already have this cached?
    if (this.availableRoles !== null) {
      return this.$q.resolve(this.availableRoles);
    }

    this.isLoadingRoles = true;
    return this.$http
      .get(this.getAvailableRolesUrl(customerId), { withCredentials: true })
      .then(response => {
        this.availableRoles = response.data;

        // put "Resi" roles at the bottom of the list; order of non-Resi items will remain unchanged
        this.availableRoles.sort((a, b) =>{
          const aIsResi = a.description.includes(this.RESI_ROLE);
          const bIsResi = b.description.includes(this.RESI_ROLE);
          if (aIsResi && bIsResi){
            if (a.description < b.description){
              return -1;
            } else if (b.description < a.description){
              return 1;
            }
          } else if (aIsResi && !bIsResi){
            return 1;
          } else if (!aIsResi && bIsResi){
            return -1;
          }
          return 0;
        });

        return this.availableRoles;
      })
      .finally(() => (this.isLoadingRoles = false))
  }

  public clearCache(): void {
    this.availableRoles = null;
  }

  private getAvailableRolesUrl(customerId: string): string {
    return `${api.url_v3}/customers/${customerId}/roles`;
  }

}

export default UserService;