import { Context, Dispatch, createContext } from 'react';
import { STREAM_STATE_CONTEXT_KEYS } from '../../../pages/EventAnalytics/event-analytics-constants';
import { EVENT_TYPES } from '../../../pages/EventAnalytics/types';

export interface StreamActions<T> {
  payload: T;
  type: STREAM_STATE_CONTEXT_KEYS;
}

export interface StreamContextType {
  groupBy: EVENT_TYPES;
}

export interface StreamContextProps {
  streamAnalyticsState: StreamContextType;
  dispatch: Dispatch<StreamActions<any>>;
}

export const StreamContext: Context<StreamContextProps> = createContext<StreamContextProps>({} as StreamContextProps);

export const dispatchers = {
  [STREAM_STATE_CONTEXT_KEYS.GROUP_BY]: (
    state: StreamContextType,
    action: StreamActions<EVENT_TYPES>
  ): StreamContextType => {
    return { ...state, groupBy: action.payload };
  },
};

export const StreamReducer = (state: StreamContextType, action: StreamActions<any>): StreamContextType => {
  return dispatchers[action.type](state, action);
};
