class FormValidationService {
	init() {
		return {
			has_error: {},

			// clears all errors
			clear: function () {
				this.has_error = {};
			},

			// will set the has_error value using field_id
			checkForEmpty: function (field_id, value) {
				this.has_error[field_id] = value == null || value == '';
				return this.has_error[field_id];
			},

			// specialty checks can use this to set the has_error object
			setError: function (field_id) {
				this.has_error[field_id] = true;
			},

			// clears error for provided field
			clearError: function (field_id) {
				this.has_error[field_id] = false;
			},

			hasError: function () {
				var error_count = 0;
				for (var property in this.has_error) {
					if (this.has_error[property] === true) {
						error_count++;
					}
				}
				return error_count > 0;
			},
		};
	}
}

export default FormValidationService;
