class CityCtrl {

	constructor() {
		'ngInject';

	}
}

// example usage:
// <city name="Plano" region="TX" country="US"></city>
//
export default class CityComponent {
	constructor() {
		this.template = require('./city.html').default;
		this.bindings = {
			name: '<',
			region: '<',
			country: '<',
		};
		this.controller = CityCtrl;
	}
}
