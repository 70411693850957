import { css, SerializedStyles } from '@emotion/react';
import styled from '@emotion/styled';
import { customProperties as vars } from '@resi-media/resi-ui';
import { CustomRangeDisabledDivType } from '../../types';

export const SegmentedStatsWrapper = styled.div`
  height: 600px;
  padding: ${vars.spacingXl} 4em 4em 4em;
`;

export const SegmentedStatsModalContent = styled.div`
  width: 900px;
  min-height: 390px;
  padding: ${vars.spacingXl} 4em 4em 4em;
`;

export const LegendsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: ${vars.spacingXl};
  margin-right: ${vars.spacingXl};
`;

export const ChartWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  height: 390px;
  position: relative;
`;

export const ChartContent = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
`;

export const ChartSubWrapper = (width: string): SerializedStyles => css`
  width: ${width};
`;

export const CustomRangeStyles = (width: string, padding: string): SerializedStyles => css`
  width: ${width};
  padding: ${padding};
`;

export const HeaderDiv = styled.div`
  display: flex;
  justify-content: space-between;
  margin: ${vars.spacingL} 0;
  font-family: ${vars.fontFamily};
  align-items: center;
`;

export const TableContent = styled.div`
  width: 100%;
  max-width: 800px;
`;

export const RangeFieldDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.75em;
  margin: 0 0 ${vars.spacingL} 0;
  align-items: flex-start;
`;

export const DropDownDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 0 ${vars.spacingM} 0 0;
`;

export const CustomRangeDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.75em;
  margin: 0 0 ${vars.spacingL} 0;
  align-items: flex-end;
  pointer-events: ${(props: CustomRangeDisabledDivType) => (props.customRangeDisabled ? 'none' : 'all')};
  opacity: ${(props: CustomRangeDisabledDivType) => (props.customRangeDisabled ? '0.4' : '1')};
`;

export const HorizontalLine = styled.hr`
  width: 65px;
  margin: ${vars.spacingXs} 0 0 0.75em;
  border: none;
  height: 5px;
  border-top: 1px solid lightgrey;
`;

export const LocationTabsDiv = styled.div`
  padding: 0 0 0 0.75em;
`;

export const PrimaryButtonWrapper = styled.div`
  height: 7px;
  margin-bottom: 1.25rem;
`;

export const ModalWrapper = styled.div`
  width: fit-content;
`;

export const DropDownWrapper = styled.div`
  margin: 0.25em 0px 0px !important;
`;
