import React, { ChangeEvent, FC } from 'react';
import { EventHeaderPillWrapper, EventLabelWrapper, Slash } from './event-header-pill.styles';
import { CloseCircleFilled, LoadingOutlined } from '@ant-design/icons';

export interface EventHeaderPillPropType {
  eventHeaderLabel: string;
  isLoading?: boolean;
  onClose: (event: ChangeEvent<unknown>) => void;
}
export const EventHeaderPill: FC<EventHeaderPillPropType> = ({ eventHeaderLabel, onClose, isLoading }): JSX.Element => {
  const handleOnClose = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    onClose(e);
  };
  return (
    <>
      <Slash>{'/'}</Slash>
      <EventHeaderPillWrapper>
        {isLoading ? (
          <LoadingOutlined style={{ fontSize: 14 }} data-testid="loading-spinner"/>
        ) : (
          <>
            <EventLabelWrapper data-testid="header-label">{`${eventHeaderLabel}`}</EventLabelWrapper>
            <CloseCircleFilled
              data-testid="close-icon"
              onClick={(e) => {
                handleOnClose(e);
              }}
            />
          </>
        )}
      </EventHeaderPillWrapper>
    </>
  );
};

EventHeaderPill.displayName = 'EventHeaderPill';

export default EventHeaderPill;
