import angular from 'angular';
import Authentication from './authentication';
import Authorization from './authorization';
import core from '../../core';

export * from './constants';

export default angular
	.module('auth', [core])
	.service('Authentication', Authentication)
	.service('Authorization', Authorization).name;
