import AddEditCueComponent from './addEditCue.component';

export const addEditCueModule = {
	name: 'addEditCueComponent',
	component: {
		name: 'addEditCue'
	}
};

export default angular
	.module(addEditCueModule.name, [])
	.component(addEditCueModule.component.name, new AddEditCueComponent()).name;
