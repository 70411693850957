import {
  BASE_URLS_DEFAULTS,
  LOCATIONS,
  YOUTUBE_CLIENT_DEFAULTS,
  MIXPANEL_API_KEYS,
  LD_CLIENT_ID,
  STUDIO_URLS,
} from './constants';

let centralBaseUrl;
let mediaStatusBaseUrl;
let mediaMetadataBaseUrl;
let onboardingBaseUrl;
let telemetryBaseUrl;
let socialAnalyticsProxyBaseUrl;
let ytClientId;
let ytClientSecret;
let webplayerEnvQueryParam = '';
let webplayerEnvAttribute = '';
let mpApiKey = MIXPANEL_API_KEYS.INTEGRATION;
let ldClientId = LD_CLIENT_ID.INTEGRATION;
let studioUrl = '';
switch (window.location.host) {
  case LOCATIONS.RESI_PROD:
    centralBaseUrl = 'https://prod-central.resi.io';
    mediaStatusBaseUrl = BASE_URLS_DEFAULTS.MEDIA_STATUS;
    mediaMetadataBaseUrl = BASE_URLS_DEFAULTS.MEDIA_METADATA;
    onboardingBaseUrl = BASE_URLS_DEFAULTS.ONBOARDING;
    telemetryBaseUrl = BASE_URLS_DEFAULTS.TELEMETRY;
    socialAnalyticsProxyBaseUrl = BASE_URLS_DEFAULTS.SOCIAL_ANALYTICS_PROXY;
    ytClientId = YOUTUBE_CLIENT_DEFAULTS.ID;
    ytClientSecret = YOUTUBE_CLIENT_DEFAULTS.SECRET;
    mpApiKey = MIXPANEL_API_KEYS.PRODUCTION;
    ldClientId = LD_CLIENT_ID.PRODUCTION;
    studioUrl = STUDIO_URLS.PROD;
    break;
  case LOCATIONS.RESI_MAIN:
    centralBaseUrl = BASE_URLS_DEFAULTS.CENTRAL;
    mediaStatusBaseUrl = BASE_URLS_DEFAULTS.MEDIA_STATUS;
    mediaMetadataBaseUrl = BASE_URLS_DEFAULTS.MEDIA_METADATA;
    onboardingBaseUrl = BASE_URLS_DEFAULTS.ONBOARDING;
    telemetryBaseUrl = BASE_URLS_DEFAULTS.TELEMETRY;
    socialAnalyticsProxyBaseUrl = BASE_URLS_DEFAULTS.SOCIAL_ANALYTICS_PROXY;
    ytClientId = YOUTUBE_CLIENT_DEFAULTS.ID;
    ytClientSecret = YOUTUBE_CLIENT_DEFAULTS.SECRET;
    mpApiKey = MIXPANEL_API_KEYS.PRODUCTION;
    ldClientId = LD_CLIENT_ID.PRODUCTION;
    studioUrl = STUDIO_URLS.MAIN;
    break;
  case LOCATIONS.RESI_INT:
  default:
      centralBaseUrl = 'https://int-central.resi.io';
      mediaStatusBaseUrl = 'https://int-media-status.resi.io';
      mediaMetadataBaseUrl = 'https://int-media-metadata.livingasone.com'; // SSL currently not working for int-media-metadata.resi.io
      onboardingBaseUrl = 'https://int-onboarding.resi.io';
      telemetryBaseUrl = 'https://int-telemetry.resi.io';
      socialAnalyticsProxyBaseUrl = 'https://int-social-analytics-proxy.resi.io';
      ytClientId = '526066391594-l392j3vgcrbhlkjv67v7qnpcut3q41if.apps.googleusercontent.com';
      ytClientSecret = '2xdtL_JVS-CWc3e0hCX67Iyn';
      webplayerEnvQueryParam = '&env=int';
      webplayerEnvAttribute = 'data-env="int"';
      studioUrl = STUDIO_URLS.INT;
}

export const youtubeClientId = ytClientId;
export const youtubeClientSecret = ytClientSecret;
export const mixpanelApiKey = mpApiKey;
export const launchDarklyClientId = ldClientId;
export const launchDarklyAnonymousUserId = '1098ad1b-a7e0-459b-916d-1f5d5555f195';
export { studioUrl };

export const api = {
  url: `${centralBaseUrl}/api_v2.svc`,
  url_v3: `${centralBaseUrl}/api/v3`,
  login_url: `${centralBaseUrl}/api/v3`,
  internal_url: `${centralBaseUrl}/internal/api/v1`,
};

export const mediaStatusApi = {
  v1: `${mediaStatusBaseUrl}/api/v1`,
};

export const onboardingApi = {
  v1: `${onboardingBaseUrl}/api/v1`,
};

export const telemetryApi = {
  v1: `${telemetryBaseUrl}/api/v1`,
};

export const socialAnalyticsProxyApi = {
  v1: `${socialAnalyticsProxyBaseUrl}/api/v1`
}

export const mediaMetaDataApi = {
  v1: `${mediaMetadataBaseUrl}/api/v1`,
};

export const webplayerEnv = {
  queryParam: webplayerEnvQueryParam,
  attribute: webplayerEnvAttribute,
};

export const shaka = {
  v30: '3.0.5'
};

export const google_charts_url = 'https://www.gstatic.com/charts/loader.js';
export const perms = {
  la1only: 'deleteCustomers',

  'analytics.get': 'getAnalyticsV2',

  'dashboard.get': 'getDashboard',
  'dashboard.multisite.get': 'getMultisiteDashboard',

  'encoders.get': 'getEncoders',
  'encoders.update': 'updateEncoders',
  'encoders.add': 'addEncoders',
  'encoders.delete': 'deleteEncoders',
  'encoders.abort': 'abortEncoders',
  'encoders.update_version': 'updateEncodersVersion',
  'encoders.restart': 'restartEncoders',
  'encoders.move': 'moveEncoders',
  'encoders.cpu_usage': 'deleteEncoders', // todo: switch to unique capability
  'encoders.capture_card': 'getEncodersLogs', // todo: switch to unique capability
  'encoders.view_logs': 'getEncodersLogs',
  'encoders.upload_logs': 'uploadEncodersLogs',
  'encoders.update_network': 'updateEncodersNetwork',
  'encoders.lan_mode.get': 'updateEncodersLanMode',
  'encoders.lan_mode.update': 'updateEncodersLanMode',
  'encoders.input.update': 'updateEncodersInput',
  'encoders.teamviewer': 'getTeamViewer',

  'uploaders.delete': 'deleteUploaders',

  'encoder_profiles.get': 'getEncoderProfiles',
  'encoder_profiles.update': 'updateEncoderProfiles',
  'encoder_profiles.add': 'addEncoderProfiles',
  'encoder_profiles.delete': 'deleteEncoderProfiles',
  'encoder_profiles.options.get': 'deleteEncoderProfiles', // todo: switch to unique capability
  'encoder_profiles.options.update': 'deleteEncoderProfiles', // todo: switch to unique capability

  'web_encoder_profiles.get': 'getWebEncoderProfiles',
  'web_encoder_profiles.update': 'updateWebEncoderProfiles',
  'web_encoder_profiles.add': 'addWebEncoderProfiles',
  'web_encoder_profiles.delete': 'deleteWebEncoderProfiles',

  'events.get': 'getEvents',
  'events.update': 'updateEvents',
  'events.add': 'addEvents',
  'events.delete': 'deleteEvents',
  'events.start_transcoding': 'startTranscoder',

  'web_events.get': 'getTranscodedEvents',
  'web_events.update': 'updateTranscodedEvents',
  'web_events.delete': 'deleteTranscodedEvents',

  'campus_review.get': 'getCampusReview',

  'library.get': 'getMediaLibrary',
  'library.add': 'addMediaLibrary',
  'library.update': 'updateMediaLibrary',
  'library.delete': 'deleteMediaLibrary',
  'library.download': 'downloadMediaLibrary',

  'cues.get': 'getCues',
  'cues.update': 'updateCues',
  'cues.add': 'addCues',
  'cues.delete': 'deleteCues',

  // social media accounts
  'social_accts.get': 'getSocialMedia',
  'social_accts.update': 'updateSocialMedia',
  'social_accts.add': 'addSocialMedia',
  'social_accts.delete': 'deleteSocialMedia',
  // social media schedules & events
  'social_media.get': 'getSocialMediaEvents',
  'social_media.update': 'updateSocialMediaEvents',
  'social_media.add': 'addSocialMediaEvents',
  'social_media.delete': 'deleteSocialMediaEvents',
  'social_media.publish': 'addSocialMediaEvents',

  'event_profiles.get': 'getEventProfiles',
  'event_profiles.update': 'updateEventProfiles',
  'event_profiles.add': 'addEventProfiles',
  'event_profiles.delete': 'deleteEventProfiles',
  'event_profiles.lan_only': 'deleteEventProfiles', // todo: switch to unique capability
  'event_profiles.view_bucket': 'deleteEventProfiles', // todo: switch to unique capability
  'event_profiles.users.get': 'getEventProfileUsers',
  'event_profiles.users.update': 'updateEventProfiles',
  'event_profiles.users.add': 'addEventProfileUsers',
  // todo: eventually switch to addEventProfiles or unique; should remain LA1 for now; when we release to customers
  // the auto complete field should be switched with a dropdown (they shouldn't have to know what orgs are shared to them)
  'event_profiles.users.add_by_org': 'deleteEventProfiles',
  'event_profiles.users.delete': 'deleteEventProfileUsers',

  'web_event_profiles.get': 'getTranscodedEventProfiles',
  'web_event_profiles.update': 'updateTranscodedEventProfiles',
  'web_event_profiles.add': 'addTranscodedEventProfiles',
  'web_event_profiles.delete': 'deleteTranscodedEventProfiles',
  'web_event_profiles.update_persistent_urls': 'addStreamUrls',
  'web_event_profiles.bandwidth_usage': 'getWebEventProfileUsage',
  'web_event_profiles.bandwidth_cost': 'getWebEventProfileCost',

  'schedules.get': 'getSchedules',
  'schedules.update': 'updateSchedules',
  'schedules.add': 'addSchedules',
  'schedules.delete': 'deleteSchedules',

  'transcoder_schedules.get': 'getTranscodedEventSchedules',
  'transcoder_schedules.update': 'updateTranscodedEventSchedules',
  'transcoder_schedules.add': 'addTranscodedEventSchedules',
  'transcoder_schedules.delete': 'deleteTranscodedEventSchedules',

  'users.get': 'getUsers',
  'users.update': 'updateUsers',
  'users.add': 'addUsers',
  'users.delete': 'deleteUsers',
  'users.billing': 'updateUsersBilling',
  'users.decoder_status': 'addUsers', // User's decoder status (which really shouldn't be available since they shouldn't login to decoder)
  'users.player_logs': 'getPlayerLogs',

  'invited_users.get': 'getUserInvites',
  'invited_users.add': 'addUserInvites',
  'invited_users.resend': 'resendUserInvites',
  'invited_users.revoke': 'revokeUserInvites',

  'contacts.get': 'getContacts',
  'contacts.update': 'updateContacts',
  'contacts.add': 'addContacts',
  'contacts.delete': 'deleteContacts',
  'contacts.import': 'deleteCustomers', // la1 only

  'contact_search.get': 'deleteCustomers',

  'monitor.get': 'deleteCustomers',

  'customers.get_all': 'getCustomers',
  'customers.get_one': 'getCustomer',
  'customers.update': 'updateCustomers',
  'customers.add': 'addCustomers',
  'customers.delete': 'deleteCustomers',

  'toggles.view': 'getCustomers',
  'toggles.update': 'addCustomers',

  'orders.get': 'getHardwareOrders',
  'hardware_units.dashboard': 'getHardwareDashboard',
  'hardware_units.get': 'getHardware',
  'hardware_options.get': 'getHardware',
  'hardware_options.update': 'updateHardwareOptions',

  'destination_groups.get': 'getDestinationGroups',
  'destination_groups.update': 'updateDestinationGroups',
  'destination_groups.add': 'addDestinationGroups',
  'destination_groups.delete': 'deleteDestinationGroups',
};
