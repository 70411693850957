import React, { FC, useContext, useEffect, useReducer, useState } from 'react';
import { AllDestinationsContext, AllDestinationsReducer } from '../../../contexts/eventanalytics';
import { EventAnalyticsContext } from '../../../contexts/eventanalytics/event-analytics';
import { INITIAL_ALL_DESTINATIONS_PAGE_STATE, STATS_KEYS_ALL_DESTINATIONS } from '../event-analytics-constants';
import { StatsArray } from '../types';
import { getStatsGroupsFromResponse } from '../utils';
import AllDestinationsAnalyticsView from './alldestinations-analytics-view';
import { fetchAllDestinationsURLData } from './api';

export const AllDestinationsAnalytics: FC = (): JSX.Element => {
  const [allDestinationsAnalyticsState, dispatch] = useReducer(
    AllDestinationsReducer,
    INITIAL_ALL_DESTINATIONS_PAGE_STATE
  );
  const [statsArray, setStatsArray] = useState<StatsArray>([]);
  const { EventAnalyticsState } = useContext(EventAnalyticsContext);
  const [isCardLoading, setCardLoading] = useState<boolean>(true);

  const getData = async () => {
    setCardLoading(true);
    const response = await fetchAllDestinationsURLData(EventAnalyticsState);
    setStatsArray(getStatsGroupsFromResponse(STATS_KEYS_ALL_DESTINATIONS, response[0]));
    setCardLoading(false);
  };

  useEffect(() => {
    getData();
  }, [EventAnalyticsState.eventId,EventAnalyticsState.dateRange]);

  return (
    <AllDestinationsContext.Provider value={{ allDestinationsAnalyticsState, dispatch }}>
      <AllDestinationsAnalyticsView statsArray={statsArray} isCardLoading={isCardLoading} />
    </AllDestinationsContext.Provider>
  );
};

AllDestinationsAnalytics.displayName = 'AllDestinationsAnalytics';

export default AllDestinationsAnalytics;
