import 'angular-route';
import angular from 'angular';
import router from './module.webbilling.routes';
import commonServicesModule from '../../services';

export const webbillingModule = {
	name: 'webbilling',
	controllers: {
		default: 'webBillingCtrl',
	},
	routes: {
		list: '/webbilling',
	},
};

export default angular
	.module(webbillingModule.name, ['ngRoute', commonServicesModule])
	.config(router)
	.controller(webbillingModule.controllers.default, require('./webBillingCtrl')).name;
