import { API_RESPONSE_DIFFS, GROUP_VALUES, TOP_LOCATION_STATS_TYPE } from './event-analytics-constants';
import { Dispatch } from 'react';
import { API_RESPONSE_TYPE, barChartDataType, BracketReference, doughnutChartDataType, StateDispatcher } from './types';
import { getBarChartData, getDoughnutChartData } from './utils';

interface Top10LocationStatsActions<T> {
  payload: T;
  type: TOP_LOCATION_STATS_TYPE;
}

interface Top10LocationStatsType {
  top10countryStats: doughnutChartDataType[];
  top10regionStats: doughnutChartDataType[];
  top10cityStats: doughnutChartDataType[];
  top10countryStatsGroupBy: barChartDataType;
  top10regionStatsGroupBy: barChartDataType;
  top10cityStatsGroupBy: barChartDataType;
}

export const initialTop10LocationStatsState: Top10LocationStatsType = {
  top10countryStats: [],
  top10regionStats: [],
  top10cityStats: [],
  top10countryStatsGroupBy: { labels: [], extraInfo: [], datasets: {} },
  top10regionStatsGroupBy: { labels: [], extraInfo: [], datasets: {} },
  top10cityStatsGroupBy: { labels: [], extraInfo: [], datasets: {} },
};

const dispatchers = {
  // Doughnut Chart Data Type
  [TOP_LOCATION_STATS_TYPE.TOP_COUNTRY_STATS]: (
    state: Top10LocationStatsType,
    action: Top10LocationStatsActions<doughnutChartDataType[]>
  ) => ({
    ...state,
    [TOP_LOCATION_STATS_TYPE.TOP_COUNTRY_STATS]: action.payload,
  }),
  [TOP_LOCATION_STATS_TYPE.TOP_REGION_STATS]: (
    state: Top10LocationStatsType,
    action: Top10LocationStatsActions<doughnutChartDataType[]>
  ) => ({
    ...state,
    [TOP_LOCATION_STATS_TYPE.TOP_REGION_STATS]: action.payload,
  }),
  [TOP_LOCATION_STATS_TYPE.TOP_CITY_STATS]: (
    state: Top10LocationStatsType,
    action: Top10LocationStatsActions<doughnutChartDataType[]>
  ) => ({
    ...state,
    [TOP_LOCATION_STATS_TYPE.TOP_CITY_STATS]: action.payload,
  }),

  // Bar Chart Data Type
  [TOP_LOCATION_STATS_TYPE.TOP_COUNTRY_STATS_GROUP_BY]: (
    state: Top10LocationStatsType,
    action: Top10LocationStatsActions<barChartDataType>
  ) => ({
    ...state,
    [TOP_LOCATION_STATS_TYPE.TOP_COUNTRY_STATS_GROUP_BY]: action.payload,
  }),

  [TOP_LOCATION_STATS_TYPE.TOP_REGION_STATS_GROUP_BY]: (
    state: Top10LocationStatsType,
    action: Top10LocationStatsActions<barChartDataType>
  ) => ({
    ...state,
    [TOP_LOCATION_STATS_TYPE.TOP_REGION_STATS_GROUP_BY]: action.payload,
  }),

  [TOP_LOCATION_STATS_TYPE.TOP_CITY_STATS_GROUP_BY]: (
    state: Top10LocationStatsType,
    action: Top10LocationStatsActions<barChartDataType>
  ) => ({
    ...state,
    [TOP_LOCATION_STATS_TYPE.TOP_CITY_STATS_GROUP_BY]: action.payload,
  }),
};

export const Top10LocationReducer = (
  state: Top10LocationStatsType,
  action: Top10LocationStatsActions<any>
): Top10LocationStatsType => {
  return dispatchers[action.type](state, action);
};

interface getDispatcherParams {
  groupBy: string;
  dispatch: Dispatch<Top10LocationStatsActions<any>>;
  countriesList: BracketReference<string>;
  regionsList: BracketReference<BracketReference<string>>;
}
export const getLocationDispatcher = ({
  groupBy,
  dispatch,
  countriesList,
  regionsList,
}: getDispatcherParams): {
  setCountry: StateDispatcher;
  setRegion: StateDispatcher;
  setCity: StateDispatcher;
} => {
  let setCountry: StateDispatcher;
  let setRegion: StateDispatcher;
  let setCity: StateDispatcher;
  if (groupBy === GROUP_VALUES.NONE) {
    setCountry = (country: API_RESPONSE_TYPE) => {
      dispatch({
        type: TOP_LOCATION_STATS_TYPE.TOP_COUNTRY_STATS,
        payload: getDoughnutChartData({
          responseObject: country,
          countriesList,
          regionsList,
          chartName: API_RESPONSE_DIFFS.LOCATION.COUNTRY,
        }),
      });
    };
    setRegion = (region: API_RESPONSE_TYPE) => {
      if (region) {
        dispatch({
          type: TOP_LOCATION_STATS_TYPE.TOP_REGION_STATS,
          payload: getDoughnutChartData({
            responseObject: region,
            countriesList,
            regionsList,
            chartName: API_RESPONSE_DIFFS.LOCATION.REGION,
          }),
        });
      }
    };
    setCity = (city: API_RESPONSE_TYPE) => {
      dispatch({
        type: TOP_LOCATION_STATS_TYPE.TOP_CITY_STATS,
        payload: getDoughnutChartData({
          responseObject: city,
          countriesList,
          regionsList,
          chartName: API_RESPONSE_DIFFS.LOCATION.CITY,
        }),
      });
    };
  }
  // Event or device type chart data combinations
  else {
    setCountry = (country: API_RESPONSE_TYPE) => {
      dispatch({
        type: TOP_LOCATION_STATS_TYPE.TOP_COUNTRY_STATS_GROUP_BY,
        payload: getBarChartData({
          responseObject: country,
          countriesList,
          regionsList,
          chartName: API_RESPONSE_DIFFS.LOCATION.COUNTRY,
        }),
      });
    };
    setRegion = (region: API_RESPONSE_TYPE) => {
      dispatch({
        type: TOP_LOCATION_STATS_TYPE.TOP_REGION_STATS_GROUP_BY,
        payload: getBarChartData({
          responseObject: region,
          countriesList,
          regionsList,
          chartName: API_RESPONSE_DIFFS.LOCATION.REGION,
        }),
      });
    };
    setCity = (city: API_RESPONSE_TYPE) => {
      dispatch({
        type: TOP_LOCATION_STATS_TYPE.TOP_CITY_STATS_GROUP_BY,
        payload: getBarChartData({
          responseObject: city,
          countriesList,
          regionsList,
          chartName: API_RESPONSE_DIFFS.LOCATION.CITY,
        }),
      });
    };
  }
  return {
    setCountry,
    setRegion,
    setCity,
  };
};
