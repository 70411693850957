import 'angular-route';
import angular from 'angular';
import router from './module.uploader.routes';
import commonServicesModule from '../../services';

export const uploaderModule = {
	name: 'uploader',
	controllers: {
		default: 'uploaderCtrl',
	},
	routes: {
		login: '/uploader',
		success: '/uploader/success', // this needs to be a subfolder of the login route
	},
};

export default angular
	.module(uploaderModule.name, ['ngRoute', commonServicesModule])
	.config(router)
	.controller(uploaderModule.controllers.default, require('./uploaderCtrl')).name;
