; (function (angular) {
	'use strict';

	// see: http://jsfiddle.net/Zenuka/pHEf9/

	angular.module('ngPromiseAddons', []).config(['$provide', function ($provide) {
		$provide.decorator('$q', ['$delegate', function ($delegate) {
			var $q = $delegate;

			// adding allSettled method to $q
			$q.allSettled = $q.allSettled || function (promises) {

				var deferred = $q.defer();
				if (angular.isArray(promises)) {
					var states = [];
					var results = [];
					var didAPromiseFail = false;

					// initialize promise states to false (meaning they are not finished)
					angular.forEach(promises, function (promise, key) {
						states[key] = false;
					});

					// checks to see if all states are finished
					var checkStates = function (states, results, deferred, failed) {
						var allFinished = true;
						angular.forEach(states, function (state, key) {
							if (!state) {
								allFinished = false;
								return;
							}
						});
						if (allFinished) {
							if (failed) {
								deferred.reject(results);
							} else {
								deferred.resolve(results);
							}
						}
					}

					// Loop through the promises
					// a second loop to be sure that checkStates is called when all states are set to false first
					angular.forEach(promises, function (promise, key) {
						$q.when(promise).then(function (result) {
							states[key] = true;
							results[key] = result;
							checkStates(states, results, deferred, didAPromiseFail);
						}, function (reason) {
							states[key] = true;
							results[key] = reason;
							didAPromiseFail = true;
							checkStates(states, results, deferred, didAPromiseFail);
						});
					});
				} else {
					throw 'promises must be an array';
				}

				return deferred.promise;
			};

			return $q;
		}]);
	}]);

})(window.angular);
