'use strict';

var jcs = require('../../jcs');
var libphonenumber = require('libphonenumber-js');
var mixpanel = require('../../../../src/mixpanel');

function ContactsController($scope, $timeout, $http, $routeParams, $q, formValidationService, focus, Authentication) {
	'ngInject';
	$(window).trigger('resize'); // ensure footer is properly positioned

	$scope.validation = formValidationService.init();

	// NOTES:
	// sendBillingAlerts field has been repurposed as a "Is Billing Contact" field. So the code will refer
	// to "send billing alert", but UI will call it "is billing contact".

	$scope.FIRST_NAME_MAX_SIZE = 45;
	$scope.LAST_NAME_MAX_SIZE = 45;
	$scope.TITLE_MAX_SIZE = 45;

	$scope.contacts = null;
	$scope.loading_contacts = true;
	$scope.contacts_error_msg = null;
	$scope.stream_id = $routeParams.streamID;
	$scope.event_id = $routeParams.eventID;

	$scope.ALERTS_YES = 'Yes';
	$scope.ALERTS_NO = 'No';
	$scope.ALERTS_STREAMING_DEFAULT = $scope.ALERTS_YES;
	$scope.ALERTS_BILLING_DEFAULT = $scope.ALERTS_NO;
	$scope.alert_options = [$scope.ALERTS_YES, $scope.ALERTS_NO];

	// view contact
	$scope.contact_to_view = null;

	// contact edit
	$scope.contact_to_edit = null;
	$scope.is_busy_editing_contact = false; // activity indicator
	$scope.contact_to_edit_first = '';
	$scope.contact_to_edit_last = '';
	$scope.contact_to_edit_title = '';
	$scope.contact_to_edit_email = '';
	$scope.contact_to_edit_mobile = '';
	$scope.contact_to_edit_billing_alert = $scope.ALERTS_BILLING_DEFAULT;
	$scope.contact_to_edit_streaming_alert = $scope.ALERTS_STREAMING_DEFAULT;
	$scope.contact_to_edit_notes = '';
	$scope.contact_to_edit_url = ''; // the api url we need to use to update this contact
	$scope.edit_contact_error = null;

	// contact create
	$scope.is_adding_contact = false;
	$scope.is_busy_adding_contact = false; // activity indicator
	$scope.contact_to_add_first = '';
	$scope.contact_to_add_last = '';
	$scope.contact_to_add_title = '';
	$scope.contact_to_add_email = '';
	$scope.contact_to_add_mobile = '';
	$scope.contact_to_add_billing_alert = $scope.ALERTS_BILLING_DEFAULT;
	$scope.contact_to_add_streaming_alert = $scope.ALERTS_STREAMING_DEFAULT;
	$scope.contact_to_add_notes = '';
	$scope.add_contact_error = null;

	// contact delete
	$scope.contact_to_delete = null;
	$scope.is_busy_deleting_contact = false; // activity indicator
	$scope.delete_contact_error = null;

	// import contacts
	$scope.import_contacts = null;
	$scope.is_busy_importing_contacts = false; // activity indicator
	$scope.import_contacts_error = null;

	$scope.canShowImportBtn = function () {
		return Authentication.getCurrentUser().hasPerm('contacts.import');
	};

	$scope.enterViewContactMode = function (contact) {
		$scope.contact_to_view = contact;
	};

	$scope.enterEditContactMode = function (contact) {
		$scope.validation.clear();

		$scope.contact_to_view = null;
		$scope.contact_to_edit = contact;

		$scope.contact_to_edit_first = contact.first;
		$scope.contact_to_edit_last = contact.last;
		$scope.contact_to_edit_title = contact.title;
		$scope.contact_to_edit_email = contact.email;
		$scope.contact_to_edit_mobile = contact.mobile;
		$scope.contact_to_edit_billing_alert = contact.sendBillingAlerts ? $scope.ALERTS_YES : $scope.ALERTS_NO;
		$scope.contact_to_edit_streaming_alert = contact.sendStreamingAlerts ? $scope.ALERTS_YES : $scope.ALERTS_NO;
		$scope.contact_to_edit_notes = contact.notes;
		$scope.contact_to_edit_url = contact.url;

		// see app.js for where focus is defined
		focus('edit-contact-input');
	};

	$scope.enterAddContactMode = function () {
		$scope.validation.clear();

		$scope.is_adding_contact = true;
		$scope.contact_to_add_first = '';
		$scope.contact_to_add_last = '';
		$scope.contact_to_add_title = '';
		$scope.contact_to_add_email = '';
		$scope.contact_to_add_mobile = '';
		$scope.contact_to_add_billing_alert = $scope.ALERTS_BILLING_DEFAULT;
		$scope.contact_to_add_streaming_alert = $scope.ALERTS_STREAMING_DEFAULT;
		$scope.contact_to_add_notes = '';

		// see app.js for where focus is defined
		focus('add-contact-input');
	};

	$scope.enterDeleteContactMode = function (contact) {
		$scope.contact_to_delete = contact;
	};

	$scope.enterImportContactsMode = function () {
		$scope.import_contacts = { data: '' };
	};

	$scope.cancelView = function () {
		$scope.contact_to_view = null;
	};

	$scope.cancelEdit = function () {
		$scope.contact_to_edit = null;
		$scope.edit_contact_error = null;
		$scope.validation.clear();
	};

	$scope.cancelAdd = function () {
		$scope.is_adding_contact = false;
		$scope.add_contact_error = null;
		$scope.validation.clear();
	};

	$scope.cancelDelete = function () {
		$scope.contact_to_delete = null;
		$scope.delete_contact_error = null;
	};

	$scope.cancelImport = function () {
		$scope.import_contacts = null;
		$scope.import_contacts_error = null;
	};

	$scope.deleteContact = function () {
		$scope.is_busy_deleting_contact = true;

		// make http request to delete contact
		// NOTE: I normally would not include the "data: null", but IE11 and below seem to require it in order to work
		// see: https://github.com/angular/angular.js/issues/12141
		$http
			.delete($scope.contact_to_delete.url, {
				withCredentials: true,
				data: null,
			})
			.then(
				function () {
					// success

					// remove contact from our contacts list
					var index = $scope.contacts.indexOf($scope.contact_to_delete);
					if (index > -1) {
						$scope.contacts.splice(index, 1);
					}

					$scope.contact_to_delete = null;
					$scope.delete_contact_error = null;
				},
				function () {
					// error

					$scope.delete_contact_error =
						'An error occurred while attempting to delete the contact. Please try again, or report the problem if it persists.';
				}
			)
		['finally'](function () {
			// always called

			$scope.is_busy_deleting_contact = false;
		});
	};

	// returns TRUE if we have a form validation issue that the user needs to address
	$scope.doesSaveNewContactFailValidation = function () {
		$scope.add_contact_error = null;
		$scope.validation.clear();

		// ensure required fields are not empty
		$scope.validation.checkForEmpty('contact_to_add_first', $scope.contact_to_add_first);
		$scope.validation.checkForEmpty('contact_to_add_last', $scope.contact_to_add_last);
		$scope.validation.checkForEmpty('contact_to_add_email', $scope.contact_to_add_email);

		var has_validation_error = $scope.validation.hasError();
		if (has_validation_error) {
			$scope.add_contact_error = 'Please specify a value for the highlighted fields.';
		}

		return has_validation_error;
	};

	$scope.saveNewContact = function () {
		// if we have form validation errors, then don't go any further
		if ($scope.doesSaveNewContactFailValidation()) return false;

		var new_contact_data = {
			first: $scope.contact_to_add_first,
			last: $scope.contact_to_add_last,
			title: $scope.contact_to_add_title,
			email: $scope.contact_to_add_email,
			mobile: $scope.getPhoneFormattedForDB($scope.contact_to_add_mobile),
			sendBillingAlerts: $scope.contact_to_add_billing_alert == $scope.ALERTS_YES ? true : false,
			sendStreamingAlerts: $scope.contact_to_add_streaming_alert == $scope.ALERTS_YES ? true : false,
			notes: $scope.contact_to_add_notes,
		};

		$scope.is_busy_adding_contact = true;

		mixpanel.trackMixpanelEvent(mixpanel.MPEventName.CONTACT_ADD, { [mixpanel.MPEventProperty.CONTACT_EMAIL]: $scope.contact_to_add_email });
		// send ajax request to create new contact
		$http
			.post(`${jcs.api.url}/contacts`, new_contact_data, {
				withCredentials: true,
			})
			.then(
				function () {
					// success

					$scope.is_adding_contact = false;
					$scope.contact_to_add_first = '';
					$scope.contact_to_add_last = '';
					$scope.contact_to_add_title = '';
					$scope.contact_to_add_email = '';
					$scope.contact_to_add_mobile = '';
					$scope.contact_to_add_billing_alert = $scope.ALERTS_BILLING_DEFAULT;
					$scope.contact_to_add_streaming_alert = $scope.ALERTS_STREAMING_DEFAULT;
					$scope.contact_to_add_notes = '';
					$scope.add_contact_error = null;

					$scope.loadContacts();
				},
				function () {
					// error

					$scope.add_contact_error =
						'An error occurred while attempting to create the contact. Please try again, or report the problem if it persists.';
				}
			)
		['finally'](function () {
			// always called

			$scope.is_busy_adding_contact = false;
		});
	};

	// returns TRUE if we have a form validation issue that the user needs to address
	$scope.doesSaveEditContactFailValidation = function () {
		$scope.edit_contact_error = null;
		$scope.validation.clear();

		// ensure required fields are not empty
		$scope.validation.checkForEmpty('contact_to_edit_first', $scope.contact_to_edit_first);
		$scope.validation.checkForEmpty('contact_to_edit_last', $scope.contact_to_edit_last);
		$scope.validation.checkForEmpty('contact_to_edit_email', $scope.contact_to_edit_email);

		var has_validation_error = $scope.validation.hasError();
		if (has_validation_error) {
			$scope.edit_contact_error = 'Please specify a value for the highlighted fields.';
		}

		return has_validation_error;
	};

	$scope.saveEdit = function () {
		// if we have form validation errors, then don't go any further
		if ($scope.doesSaveEditContactFailValidation()) return false;

		var updated_contact_data = {
			first: $scope.contact_to_edit_first,
			last: $scope.contact_to_edit_last,
			title: $scope.contact_to_edit_title,
			email: $scope.contact_to_edit_email,
			mobile: $scope.getPhoneFormattedForDB($scope.contact_to_edit_mobile),
			sendBillingAlerts: $scope.contact_to_edit_billing_alert == $scope.ALERTS_YES ? true : false,
			sendStreamingAlerts: $scope.contact_to_edit_streaming_alert == $scope.ALERTS_YES ? true : false,
			notes: $scope.contact_to_edit_notes,
		};

		$scope.is_busy_editing_contact = true;

		// update contact
		$http
			.patch($scope.contact_to_edit_url, updated_contact_data, {
				withCredentials: true,
			})
			.then(
				function () {
					// success

					// update was successful, so update our actual contact in the model
					$scope.contact_to_edit.first = $scope.contact_to_edit_first;
					$scope.contact_to_edit.last = $scope.contact_to_edit_last;
					$scope.contact_to_edit.title = $scope.contact_to_edit_title;
					$scope.contact_to_edit.email = $scope.contact_to_edit_email;
					$scope.contact_to_edit.mobile = $scope.contact_to_edit_mobile;
					$scope.contact_to_edit.sendBillingAlerts =
						$scope.contact_to_edit_billing_alert == $scope.ALERTS_YES ? true : false;
					$scope.contact_to_edit.sendStreamingAlerts =
						$scope.contact_to_edit_streaming_alert == $scope.ALERTS_YES ? true : false;
					$scope.contact_to_edit.notes = $scope.contact_to_edit_notes;

					$scope.contact_to_edit = null;
					$scope.contact_to_edit_url = null;
					$scope.edit_contact_error = null;
				},
				function () {
					// error

					$scope.edit_contact_error =
						'An error occurred while attempting to update the contact. Please try again, or report the problem if it persists.';
				}
			)
		['finally'](function () {
			// always called

			$scope.is_busy_editing_contact = false;
		});
	};

	// returns the full input line for given field
	$scope.getLineFromImportData = function (field) {
		// iterate through our import data
		for (var i = 0; i < $scope.import_contacts.list.length; i++) {
			if ($scope.import_contacts.list[i].indexOf(field) != -1) {
				return $scope.import_contacts.list[i];
			}
		}
		// if can't find match, then throw exception
		throw 'Unable to find the following entry: ' + field;
	};

	// returns the data associated with the given field
	$scope.parseImportData = function (field) {
		var entry = $scope.getLineFromImportData(field);
		var index = entry.indexOf(field);
		return entry.substring(index + field.length).trim();
	};

	// splits name into first and last
	$scope.parseName = function (full_name) {
		var parsed_name = { first: '', last: '' };

		// split name on first space
		var index = full_name.indexOf(' ');
		if (index != -1) {
			parsed_name.first = full_name.substring(0, index);
			parsed_name.last = full_name.substring(index + 1);
		} else {
			parsed_name.first = full_name;
		}

		return parsed_name;
	};

	$scope.doesImportContactsFailValidation = function () {
		$scope.import_contacts_error = null;
		$scope.validation.clear();

		// ensure required fields are not empty
		$scope.validation.checkForEmpty('data', $scope.import_contacts);

		var has_validation_error = $scope.validation.hasError();
		if (has_validation_error) {
			$scope.edit_contact_error = 'Please provide contact information from Freshdesk.';
		}

		if (!has_validation_error) {
			try {
				// convert our imported data into lines
				$scope.import_contacts.list = $scope.import_contacts.data.split('\n');
				if ($scope.import_contacts.list.length <= 1) {
					throw 'Unable to parse contact data into separate lines';
				}

				// parse primary contact
				var primary_contact = { billing_contact: false, streaming_alerts: true, notes: 'Primary Contact' };
				primary_contact.name = $scope.parseImportData('#3: "Name"');
				primary_contact.title = $scope.parseImportData('#4: "Position / Title"');
				primary_contact.email = $scope.parseImportData('#5: "E-Mail Address"');
				primary_contact.mobile = $scope.getPhoneFormattedForDB($scope.parseImportData('#6: "Phone Number (Mobile)"'));
				// parse alternate contact
				var alt_contact = { billing_contact: false, streaming_alerts: true };
				alt_contact.name = $scope.parseImportData('#7: "Name"');
				alt_contact.title = $scope.parseImportData('#8: "Position Title"');
				alt_contact.email = $scope.parseImportData('#9: "E-Mail Address"');
				alt_contact.mobile = $scope.getPhoneFormattedForDB($scope.parseImportData('#10: "Phone Number (Mobile)"'));
				// parse network/IT contact
				var network_contact = { billing_contact: false, streaming_alerts: true };
				network_contact.name = $scope.parseImportData('#11: "Name"');
				network_contact.title = $scope.parseImportData('#12: "Position Title"');
				network_contact.email = $scope.parseImportData('#13: "E-Mail Address"');
				network_contact.mobile = $scope.getPhoneFormattedForDB($scope.parseImportData('#14: "Phone Number"'));

				// billing contact may be one of the three persons already parsed, or a completely new person
				var billing_contact = null;

				var billing_contact_selection = $scope.parseImportData('#15: "Who is the primary billing contact?"');
				if (billing_contact_selection.indexOf('Primary') != -1) {
					// 'Primary Technical Production Contact'
					primary_contact.billing_contact = true;
				} else if (billing_contact_selection.indexOf('Alternate') != -1) {
					// 'Alternate Technical Production Contact'
					alt_contact.billing_contact = true;
				} else if (billing_contact_selection.indexOf('Network') != -1) {
					// 'Network / I.T Contact'
					network_contact.billing_contact = true;
				} else if (billing_contact_selection.indexOf('Other') != -1) {
					// 'Other (Enter on next page...)'
					// parse billing contact
					billing_contact = { billing_contact: true, streaming_alerts: false };
					billing_contact.name = $scope.parseImportData('#16: "Name"');
					billing_contact.title = $scope.parseImportData('#17: "Position Title"');
					billing_contact.email = $scope.parseImportData('#18: "E-Mail Address"');
					billing_contact.mobile = $scope.getPhoneFormattedForDB($scope.parseImportData('#19: "Phone Number"'));
				} else {
					throw 'Unable to determine who the billing contact is on line #15';
				}

				$scope.import_contacts.primary = primary_contact;
				$scope.import_contacts.alternate = alt_contact;
				$scope.import_contacts.network = network_contact;
				$scope.import_contacts.billing = billing_contact;
			} catch (error) {
				has_validation_error = true;
				$scope.import_contacts_error = error;
			}
		}

		return has_validation_error;
	};

	$scope.getContactPayload = function (contact) {
		var payload = {};
		if (contact.hasOwnProperty('name')) {
			// divide into first and last name
			var parsed_name = $scope.parseName(contact.name);
			payload.first = parsed_name.first;
			payload.last = parsed_name.last;
		}
		payload.title = contact.hasOwnProperty('title') ? contact.title : '';
		payload.email = contact.hasOwnProperty('email') ? contact.email : '';
		payload.mobile = contact.hasOwnProperty('mobile') ? contact.mobile : '';
		payload.notes = contact.hasOwnProperty('notes') ? contact.notes : '';
		payload.sendStreamingAlerts = contact.hasOwnProperty('streaming_alerts') && contact.streaming_alerts ? true : false;
		payload.sendBillingAlerts = contact.hasOwnProperty('billing_contact') && contact.billing_contact ? true : false;

		return payload;
	};

	// this method cleans/massages the data to be imported so it is ready for validation
	$scope.cleanDataToImport = function () {
		// replace smart quotes with regular quotes. Mac users appear to be getting smart quotes in Freshdesk while PC users are getting regular quotes.
		var smart_left_quote_regex = /“/g;
		var smart_right_quote_regex = /”/g;
		var regular_quote = '"';
		$scope.import_contacts.data = $scope.import_contacts.data.replace(smart_left_quote_regex, regular_quote);
		$scope.import_contacts.data = $scope.import_contacts.data.replace(smart_right_quote_regex, regular_quote);
	};

	$scope.performImportContacts = function () {
		// clean our data to import
		$scope.cleanDataToImport();

		// if we have form validation errors, then don't go any further
		if ($scope.doesImportContactsFailValidation()) return false;

		var promises = [];
		promises.push($http.post(jcs.api.url + '/contacts', $scope.getContactPayload($scope.import_contacts.primary), {withCredentials: true}));
		promises.push($http.post(jcs.api.url + '/contacts', $scope.getContactPayload($scope.import_contacts.alternate), {withCredentials: true}));
		promises.push($http.post(jcs.api.url + '/contacts', $scope.getContactPayload($scope.import_contacts.network), {withCredentials: true}));
		if ($scope.import_contacts.billing != null) {
			promises.push($http.post(jcs.api.url + '/contacts', $scope.getContactPayload($scope.import_contacts.billing), {withCredentials: true}));
		}

		$scope.is_busy_importing_contacts = true;

		$q.all(promises).then(
			function (response) {
				$scope.import_contacts = null;
				$scope.import_contacts_error = null;

				$scope.loadContacts();
			},
			function (reason) { // error

				if (!httpService.isStatus406(reason)) {
					$scope.import_contacts_error = 'An error occurred while attempting to import the contact information. Please review the list of contacts to see if any were added, and if not then try again. If some were added, then you will need to do the others manually.';
				}

			}).finally(function () { // always called

				$scope.is_busy_importing_contacts = false;
			});
	};

	$scope.loadContacts = function () {
		$scope.loading_contacts = true;

		$http
			.get(`${jcs.api.url}/contacts`, {
				withCredentials: true,
			})
			.then(
				function (response) {
					// success

					//console.log("CONTACTS:");
					//console.log(response.data);
					$scope.contacts_error_msg = null;
					$scope.contacts = response.data;
					// formatting
					for (var i = 0; i < $scope.contacts.length; i++) {
						$scope.contacts[i].mobile = $scope.getPhoneFormattedForUI($scope.contacts[i].mobile);
					}
				},
				function () {
					// error

					$scope.contacts_error_msg =
						'An error occurred while attempting to retrieve the contact information. Please try again, or report the problem if it persists.';
					$scope.contacts = null;
				}
			)
		['finally'](function () {
			// always called

			$scope.loading_contacts = false;
		});
	};

	$scope.getAlertAsText = function (alert) {
		return alert ? 'Yes' : 'No';
	};

	$scope.COUNTRY_CODE_USA = '+1';
	$scope.getPhoneFormattedForUI = function (phone_number) {
		if (typeof libphonenumber != 'undefined') {
			const phoneNumber = libphonenumber.parsePhoneNumberFromString(phone_number, 'US');
			if (phoneNumber) {
				return phoneNumber.country == 'US' ? phoneNumber.formatNational() : phoneNumber.formatInternational();
			}
		}
		return phone_number;
	};

	$scope.getPhoneFormattedForDB = function (phone_number) {
		if (typeof libphonenumber != 'undefined') {
			const phoneNumber = libphonenumber.parsePhoneNumberFromString(phone_number, 'US');
			if (phoneNumber) {
				return phoneNumber.number; // will return number with no spaces (ex: +12149718025 or +4915254193315)
			}
		}
		return phone_number;
	};

	//
	// initialize by loading our contacts
	//
	$scope.loadContacts();

	// define and build our tooltips
	//			$scope.billing_alert_tooltip = 'This person will receive billing related alerts, such as if there is a problem processing your payment.';
	$scope.is_billing_contact_tooltip = 'This person may be contacted for any billing related issues or questions.';
	$scope.streaming_alert_tooltip =
		'This person will receive streaming related alerts, such as if an encoder is having bandwidth issues, or if problems are detected that may affect playback.';
	$timeout(function () {
		$('[data-toggle="tooltip"]').tooltip(); // needs to be done in timeout, otherwise for some reason the tooltip gets built before angular does it's magic
	});
}

module.exports = ContactsController;
