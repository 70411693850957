import React, { FC, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Portal, Modal, ModalFooter, ModalHeader, ModalContent, EventDownloadForm } from '../index';
import {
  fetchDownloadUrl,
  fetchEventSummary,
  fetchCues,
  fetchEncoderEventCues,
  EventSummary,
  TimeRange,
} from '../../components/EventDownloadButton/eventDownloadApi';
import { EventDownloadFormValues } from '../../components/EventDownloadForm';
import { EventType } from '../../constants';
import { CueOption } from '../../helpers/events/types';
import { trackMixpanelEvent, MPEventName, MPEventProperty } from '../../mixpanel'

interface EventDownloadButtonProps {
  customerId: string;
  eventId?: string;
  eventType: EventType;
  streamProfileId?: string;
  webPlayer?: any;
}

const defaultEventSummaryData = {
  live: false,
  transcodeRequired: false,
  downloadDisabled: false,
  audioRepresentations: [],
  videoRepresentations: [],
} as EventSummary;

const EventDownloadButton: FC<EventDownloadButtonProps> = ({
  customerId,
  eventId,
  eventType,
  streamProfileId,
  webPlayer,
}) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [validDownloadForm, setValidDownloadForm] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [ disabled, setDisabled] = useState(false);
  const [data, setData] = useState({
    eventSummary: defaultEventSummaryData,
    cues: [] as CueOption[],
    isFetching: false,
  });

  const fetchSummary = async (): Promise<void> => {
    if (!eventId) {
      return;
    }

    try {
      setData({ eventSummary: data.eventSummary, cues: data.cues, isFetching: true });
      const eventSummary = await fetchEventSummary(customerId, eventId, eventType);
      setData({eventSummary: eventSummary, cues: data.cues, isFetching: false});
      if (eventSummary.transcodeRequired || eventSummary.downloadDisabled) {
        setDisabled(true);
      }
    } catch (error) {
      setData({ eventSummary: data.eventSummary, cues: data.cues, isFetching: false });
    }
  }

  useEffect(() => {
    (async () => {
      await fetchSummary()
    })();
  }, []);

  const getCues = (customerId: string, eventId: string, type: EventType, streamProfileId?: string): Promise<any> => {
    if (streamProfileId) {
      return fetchEncoderEventCues(streamProfileId, eventId);
    }
    return fetchCues(customerId, eventId, type);
  };

  const filterCues = (cues: CueOption[]): CueOption[] => {
    if (webPlayer && webPlayer.canGetTimeRange()) {
      const timeRange: TimeRange = webPlayer.getTimeRange();
      return cues.filter((cue: CueOption) => {
        const positionSeconds = cue.position / 1000;
        if (positionSeconds >= timeRange.start && positionSeconds <= timeRange.end) {
          return cue;
        }
      });
    }
    return []; // if we can't detect time range don't return any cues
  };

  const fetchEventOptions = async (): Promise<void> => {
    try {
      setData({ eventSummary: data.eventSummary, cues: data.cues, isFetching: true });
      const [eventSummary, cues] = await Promise.all([
        fetchEventSummary(customerId, eventId, eventType),
        getCues(customerId, eventId, eventType, streamProfileId).then((cues: CueOption[]) => {
          if (eventType === EventType.WEB_EVENT) {
            return filterCues(cues);
          }
          return cues;
        }),
      ]);
      setData({ eventSummary, cues, isFetching: false });
    } catch (error) {
      setData({ eventSummary: data.eventSummary, cues: data.cues, isFetching: false });
    }
  };

  const downloadEvent = async (downloadParams: EventDownloadFormValues): Promise<void> => {
    try {
      setLoading(true);
      setShowSuccessMessage(false);
      const response = await fetchDownloadUrl(customerId, eventId, eventType, downloadParams);
      const link = document.createElement('a');
      link.href = response.data.downloadUrl;
      document.body.appendChild(link);
      link.setAttribute('target', '_blank');
      link.setAttribute('download', 'webevent.mp4');
      link.click();
      link.remove();
      // set delay of 1.5 seconds to give browser download mananger time to initialize
      // not great, but we don't have any way to know when download manager opens
      setTimeout(() => {
        setLoading(false);
        setShowSuccessMessage(true);
      }, 1500);
      trackMixpanelEvent(MPEventName.EVENT_DOWNLOAD, {
        [MPEventProperty.CUE_START]:  downloadParams.startPosition || 'Beginning of Event',
        [MPEventProperty.CUE_STOP]:  downloadParams.stopPosition || 'End of Event',
        [eventType === 'event' ? MPEventProperty.EVENT_UUID : MPEventProperty.TRANSCODED_EVENT_UUID]: eventId,
        [MPEventProperty.AUDIO_FORMAT]: data.eventSummary.audioRepresentations?.find((e) => e.id === parseInt(downloadParams.audioTrack))?.name,
        [MPEventProperty.VIDEO_FORMAT]: data.eventSummary.videoRepresentations?.find((e) => e.id === parseInt(downloadParams.videoTrack))?.name
      });
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const pauseWebPlayer = (): void => {
    if (webPlayer) {
      webPlayer.getVideo().pause();
    }
  };

  const openModal = (): void => {
    setShowSuccessMessage(false);
    pauseWebPlayer();
    fetchEventOptions();
    setOpen(true);
    document.body.classList.add('la1-modal-background__noscroll');
  };

  const closeModal = (): void => {
    setOpen(false);
    document.body.classList.remove('la1-modal-background__noscroll');
  };

  const modal = open && (
    <Portal>
      <Modal>
        <ModalHeader
          titleText={eventType === EventType.EVENT ? 'Download Encoder Event' : 'Download Web Event'}
          closeButton
          onClose={closeModal}
        >
          {data.eventSummary.live && (
            <span className="label bg-red label-readability" style={{ marginTop: 2, marginBottom: 2, fontSize: 16 }}>
              Live
            </span>
          )}
        </ModalHeader>
        <ModalContent>
          {showSuccessMessage && (
            <div className="callout callout-success">
              Your download has started. You can close this window or create another download.
            </div>
          )}
          <EventDownloadForm
            videoOptions={data.eventSummary.videoRepresentations}
            audioOptions={data.eventSummary.audioRepresentations}
            cues={data.cues}
            loading={data.isFetching}
            live={data.eventSummary.live}
            onSubmit={downloadEvent}
            onValidate={(valid) => setValidDownloadForm(valid)}
          />
        </ModalContent>
        <ModalFooter>
          <button className="btn btn-default" type="button" onClick={closeModal}>
            Close
          </button>
          <button
            className="btn btn-primary"
            type="submit"
            form="webEventDownloadForm"
            disabled={loading || data.isFetching || !validDownloadForm}
          >
            {loading ? (
              <i className="fa fa-spinner fa-spin" aria-hidden="true" style={{ marginRight: 7 }}></i>
            ) : (
              <i className="fa fa-video-camera" aria-hidden="true" style={{ marginRight: 7 }}></i>
            )}
            Download MP4
          </button>
        </ModalFooter>
      </Modal>
    </Portal>
  );

  return (
    <>
      <button disabled={disabled} type="button" className="btn btn-primary pull-right horizontally-center" onClick={openModal}>
        <i className="fa fa-cloud-download" aria-hidden="true" style={{ marginRight: 7 }}/>
        Download
      </button>
      {disabled && (
        <div className="text-muted">
          <i className="fa fa-question-circle fa-icon-muted" style={{ marginRight: 7 }}/>Content unavailable for download. This content was encoded using HEVC
        </div>
      )}
      {modal}
    </>
  );
};

EventDownloadButton.propTypes = {
  customerId: PropTypes.string.isRequired,
  eventId: PropTypes.string,
  eventType: PropTypes.any.isRequired,
  streamProfileId: PropTypes.string,
  webPlayer: PropTypes.any,
};

EventDownloadButton.displayName = 'EventDownloadButton';

export default EventDownloadButton;
