'use strict';

const jcs = require('../../jcs');
const destinationGroupModule = require('./index').destinationGroupModule;
const { trackMixpanelEvent, MPEventName, MPEventProperty } = require( '../../../../src/mixpanel.ts');

function DestinationGroupsController($scope, $route, $http, $timeout, httpService, socialMedia, webEventProfileService, Authentication) {
  'ngInject';

  $(window).trigger('resize'); // ensure footer is properly positioned

  $scope.VIEW = Object.freeze({
    DEST_LIST: 'DEST_LIST',
    ADD_DEST: 'ADD_DEST,',
    DETAILS: 'DETAILS',
    DELETE_MODAL: 'DELETE_MODAL',
  });

  $scope.STATUS_OPTIONS = Object.freeze({
    ENABLED: 'ENABLED',
    DISABLED: 'DISABLED,',
  });

  $scope.DESTINATION_TYPE = Object.freeze({
    FACEBOOK: 'FACEBOOK',
    YOUTUBE: 'YOUTUBE,',
  });

  $scope.PRIVACY_TYPE = Object.freeze({
    PUBLIC: 'PUBLIC',
    UNLISTED: 'UNLISTED',
    PRIVATE: 'PRIVATE',
  });

  $scope.PUBLISH_STATUS = Object.freeze({
    PUBLISHED: 'PUBLISHED',
    UNPUBLISHED: 'UNPUBLISHED',
  });

  const ERROR_MSG = Object.freeze({
    GENERIC: 'An error occurred. Please try again, or report the problem if it persists.',
    DELETE: 'An error occurred deleting the destination group. Please try again, or report the problem if it persists.',
  });

  $scope.currentView = $scope.VIEW.DEST_LIST;
  $scope.is_loading = true;
  $scope.error_msg = null;
  $scope.has_error = {};
  $scope.submitting = false;
  $scope.editing = false;
  $scope.has_social_media_perm = Authentication.getCurrentUser().hasPerm('social_media.get');

  $scope.destGroupId = null;
  $scope.destinationGroupName = '';
  $scope.status = $scope.STATUS_OPTIONS.ENABLED;
  $scope.streamProfileId = '';
  $scope.webEventProfileId = '';
  $scope.socialMediaDestinations = [];

  $scope.event_profile_list = [];
  $scope.transcoder_event_profile_list = [];
  $scope.social_media_acct_list = [];
  $scope.destinationGroups = [];
  $scope.social_media = socialMedia.init();
  $scope.is_loading_social_media_accts = false;
  $scope.detailsView = {};
  $scope.deleteGroupDetails = {};

  // TOGGLES
  $scope.canShowEditBtn = function () {
    return Authentication.getCurrentUser().hasPerm('destination_groups.update');
  };

  $scope.canShowDeleteBtn = function () {
    return Authentication.getCurrentUser().hasPerm('destination_groups.delete');
  };

  $scope.canShowNewDestinationGroups = function () {
    return Authentication.getCurrentUser().hasPerm('destination_groups.add');
  };

  $scope.goToAddDestGroup = function () {
    resetForViewSwitch();

    $scope.destGroupId = null;
    $scope.destinationGroupName = '';
    $scope.status = $scope.STATUS_OPTIONS.ENABLED;
    $scope.streamProfileId = '';
    $scope.webEventProfileId = '';
    $scope.socialMediaDestinations = [];
    $scope.currentView = $scope.VIEW.ADD_DEST;
    $scope.is_loading = true;
    Promise.all([initializeEventProfileList(), loadSocialMediaAccts(), initializeWebEventProfileList()]).then(
      () => {
        // if there is only 1 profile, then auto select it
        if ($scope.event_profile_list !== null && $scope.event_profile_list.length === 1){
          $scope.streamProfileId = $scope.event_profile_list[0].uuid;
        }
        if ($scope.transcoder_event_profile_list !== null && $scope.transcoder_event_profile_list.length === 1){
          $scope.webEventProfileId = $scope.transcoder_event_profile_list[0].uuid;
        }
        $scope.is_loading = false;
        $scope.$apply();
      }
    );
  };

  $scope.goToDestList = function () {
    resetForViewSwitch();
    $scope.currentView = $scope.VIEW.DEST_LIST;
  };

  $scope.goToDetailsPage = function (destGroup) {
    resetForViewSwitch();
    $scope.detailsView = destGroup;
    $scope.currentView = $scope.VIEW.DETAILS;
  };

  $scope.editDestGroup = function (destGroup) {
    resetForViewSwitch();
    $scope.editing = true;

    if (!destGroup) {
      destGroup = $scope.detailsView;
    }

    $scope.destGroupId = destGroup.uuid;
    $scope.destinationGroupName = destGroup.name;
    $scope.status = destGroup.enabled ? $scope.STATUS_OPTIONS.ENABLED : $scope.STATUS_OPTIONS.DISABLED;
    $scope.streamProfileId = destGroup.streamProfileId;
    $scope.webEventProfileId = destGroup.webEventProfileId;
    $scope.socialMediaDestinations = destGroup.simulcasts ? [...destGroup.simulcasts] : [];
    $scope.currentView = $scope.VIEW.ADD_DEST;
    $scope.is_loading = true;
    // call all promises on load
    Promise.all([initializeEventProfileList(), loadSocialMediaAccts(), initializeWebEventProfileList()]).then(
      () => {
        $scope.is_loading = false;
        $scope.$apply();
      }
    );
  };

  $scope.showDeleteModal = function (uuid, name) {
    $scope.deleteGroupDetails = { name, uuid };
    $scope.currentView = $scope.VIEW.DELETE_MODAL;
  };

  $scope.exitDeleteModal = function () {
    $scope.deleteGroupDetails = {};
    $scope.goToDestList();
  };

  $scope.deleteGroup = async function () {
    $scope.submitting = true;

    const requestUrl = `${jcs.api.url_v3}/customers/${Authentication.getCurrentUser().customerID}/destinationgroups/${
      $scope.deleteGroupDetails.uuid
    }`;
    try {
      const res = await $http.delete(requestUrl, { withCredentials: true });
      if (res.status > 399) {
        $scope.error_msg = ERROR_MSG.DELETE;
        $scope.submitting = false;
        return;
      }
      trackMixpanelEvent(MPEventName.DESTINATION_GROUP_DELETE, {
        [MPEventProperty.DESTINATION_GROUP_NAME]: $scope.deleteGroupDetails.name,
        [MPEventProperty.DESTINATION_GROUP_UUID]: $scope.deleteGroupDetails.uuid
      });
      $route.reload();
    } catch (e) {
      $scope.error_msg = ERROR_MSG.DELETE;
      console.error(e);
    }
    $scope.deleteGroupDetails = {};
    $scope.submitting = false;
    $scope.$apply();
  };

  $scope.saveOrUpdateGroup = async function () {
    if ($scope.submitting) {
      return;
    }
    $scope.error_msg = null;
    $scope.submitting = true;

    const payload = {
      name: $scope.destinationGroupName,
      enabled: $scope.status === $scope.STATUS_OPTIONS.ENABLED,
      streamProfileId: $scope.streamProfileId,
      webEventProfileId: $scope.webEventProfileId,
      simulcasts: null,
    };

    if ($scope.socialMediaDestinations.length > 0) {
      payload.simulcasts = $scope.socialMediaDestinations.map((s) => ({
        uuid: s.uuid,
        type: s.type,
        channelId: s.channelId,
        channelName: s.channelName,
        destinationId: s.destinationId,
        destinationName: s.destinationName,
        privacy: s.privacy,
        publishStatus: s.publishStatus,
        crossposts: s.crossposts,
        title: s.title,
        description: s.description,
      }));
    }

    if (!validateSavePayload(payload)) {
      $scope.submitting = false;
      return;
    }

    const requestUrl = `${jcs.api.url_v3}/customers/${Authentication.getCurrentUser().customerID}/destinationgroups`;
    const mixpanelObject = {
      [MPEventProperty.DESTINATION_GROUP_NAME]: payload.name,
      [MPEventProperty.DESTINATION_GROUP_UUID]: $scope.destGroupId,
      [MPEventProperty.ENCODER_EVENT_PROFILE_NAME]: getEventProfileName($scope.event_profile_list, $scope.streamProfileId),
      [MPEventProperty.ENCODER_EVENT_PROFILE_UUID]: $scope.streamProfileId,
      [MPEventProperty.WEB_EVENT_PROFILE_NAME]: getEventProfileName($scope.transcoder_event_profile_list, $scope.webEventProfileId),
      [MPEventProperty.WEB_EVENT_PROFILE_UUID]: $scope.webEventProfileId,
      [MPEventProperty.AVAILABLE_IN_PROPRESENTER]: payload.enabled,
      [MPEventProperty.FACEBOOK_DESTINATION_ACCOUNT_NAME]: getSocialDestDetails(payload.simulcasts, 'fb_page')?.channelName,
      [MPEventProperty.FACEBOOK_DESTINATION]: getSocialDestDetails(payload.simulcasts, 'fb_page')?.destinationName,
      [MPEventProperty.FACEBOOK_PRIVACY]: getSocialDestDetails(payload.simulcasts, 'fb_page')?.privacy,
      [MPEventProperty.FACEBOOK_PUBLISH_STATUS]: getSocialDestDetails(payload.simulcasts, 'fb_page')?.publishStatus,
      [MPEventProperty.FACEBOOK_CROSSPOST_DESTINATIONS]: getSocialDestDetails(payload.simulcasts, 'fb_page')?.crossposts?.map((cp) => cp.destinationName),
      [MPEventProperty.YOUTUBE_DESTINATION_ACCOUNT_NAME]: getSocialDestDetails(payload.simulcasts, 'yt_create_new')?.channelName,
      [MPEventProperty.YOUTUBE_PRIVACY]: getSocialDestDetails(payload.simulcasts, 'yt_create_new')?.privacy,
      [MPEventProperty.YOUTUBE_PUBLISH_STATUS]: getSocialDestDetails(payload.simulcasts, 'yt_create_new')?.publishStatus,
    }

    // UDPATE
    if ($scope.destGroupId) {
      try {
        const res = await $http.patch(`${requestUrl}/${$scope.destGroupId}`, payload, { withCredentials: true });
        if (res.status > 399) {
          $scope.error_msg = ERROR_MSG.GENERIC;
          $scope.submitting = false;
          return;
        }
        trackMixpanelEvent(MPEventName.DESTINATION_GROUP_EDIT, mixpanelObject);
        $route.reload();
      } catch (e) {
        $scope.error_msg = ERROR_MSG.GENERIC;
        // angularjs not recognizing state update for is_loading without apply()
        console.error(e);
      }
      $scope.submitting = false;
      $scope.$apply();
      return;
    }

    // SAVE NEW
    try {
      const res = await $http.post(requestUrl, payload, { withCredentials: true });
      if (res.status > 399) {
        $scope.error_msg = ERROR_MSG.GENERIC;
        $scope.submitting = false;
        return;
      }
      trackMixpanelEvent(MPEventName.DESTINATION_GROUP_ADD, mixpanelObject);
      console.log('mixpanelObject: ', mixpanelObject);
      $route.reload();
    } catch (e) {
      $scope.error_msg = ERROR_MSG.GENERIC;
      // angularjs not recognizing state update for is_loading without apply()
      $scope.$apply();
      console.error(e);
    }
    $scope.submitting = false;
    $scope.$apply();
  };

  const getEventProfileName = (profileList, streamProfileId) => profileList?.find((event) => event.uuid === streamProfileId)?.name;

  const getSocialDestDetails = (simulcasts, type) => simulcasts?.find((simulcast) => simulcast.type === type);

  function validateSavePayload(payload) {
    let valid = true;
    if (!payload.name) {
      $scope.has_error.destinationGroupName = true;
      valid = false;
    }
    if (!payload.streamProfileId) {
      $scope.has_error.streamProfileId = true;
      valid = false;
    }
    if (!payload.webEventProfileId) {
      $scope.has_error.webEventProfileId = true;
      valid = false;
    }
    return valid;
  }

  // reset validations on chnage
  $scope.$watch('destinationGroupName', () => delete $scope.has_error.destinationGroupName);
  $scope.$watch('streamProfileId', () => delete $scope.has_error.streamProfileId);
  $scope.$watch('webEventProfileId', () => delete $scope.has_error.webEventProfileId);

  function resetForViewSwitch() {
    delete $scope.has_error.destinationGroupName;
    delete $scope.has_error.streamProfileId;
    delete $scope.has_error.webEventProfileId;
    $scope.error_msg = null;
    $scope.has_error = {};
    $scope.submitting = false;
    $scope.error_msg = null;
    $scope.editing = false;
  }

  //
  // initialize
  //

  function updateSocialMediaAccountsStatus() {
    if ($scope.social_media_acct_list && $scope.social_media_acct_list.length) {
      const acctIdList = [];

      for (const acct of $scope.social_media_acct_list) {
        acct.status = $scope.social_media.STATUS_OK;
        acct.formatted_name = '[' + $scope.social_media.getTypeAsLabel(acct.type) + '] ' + acct.name;
        acctIdList.push({
          id: acct.uuid,
          type: acct.type,
        });
      }

      $scope.social_media.checkAccountStatus(acctIdList, Authentication.getCurrentUser().customerID, updateAcctStatus);
    }
  }

  // this is a callback we use with the socialMedia service (as it determines account status, it calls this method)
  function updateAcctStatus(account_id, info) {
    for (const socialMediaAccount of $scope.social_media_acct_list) {
      if (socialMediaAccount.uuid === account_id) {
        socialMediaAccount.status = info.status;
        if (info.status !== $scope.social_media.STATUS_OK) {
          socialMediaAccount.formatted_name += ` (${$scope.social_media.getSocialMediaStatusDesc(socialMediaAccount.status)})`;
        }
        return;
      }
    }
  }

  async function loadSocialMediaAccts() {
    $scope.is_loading_social_media_accts = true;

    try {
      const response = await $http.get(
        jcs.api.url_v3 + '/customers/' + Authentication.getCurrentUser().customerID + '/youtube/channels',
        {
          withCredentials: true,
        }
      );

      $scope.social_media_acct_list = response.data;
      $scope.social_media.sortByType($scope.social_media_acct_list);
      updateSocialMediaAccountsStatus();
    } catch (e) {
      console.error(e);
      $scope.error =
        'An error occurred while attempting to load social media information. Please try again, or report the problem if it persists.';
    }
    $scope.is_loading_social_media_accts = false;
  }

  async function initializeEventProfileList() {
    if (!$scope.event_profile_list.length) {
      try {
        const res = await $http.get(jcs.api.url + '/streamprofiles', { withCredentials: true });
        $scope.load_event_profile_list_error = null;
        $scope.event_profile_list = res.data;
      } catch (e) {
        console.error(e);
        $scope.load_event_profile_list_error = `An error occurred while gathering information. Please try again, or report the problem if it persists.`;
        $scope.event_profile_list = null;
      }
      $scope.is_loading_event_profile_list = false;
    }
  }

  async function initializeWebEventProfileList() {
    if (!$scope.transcoder_event_profile_list.length) {
      try {
        const response = await httpService.get(
          jcs.api.url_v3 + '/customers/' + Authentication.getCurrentUser().customerID + '/webeventprofiles',
          { withCredentials: true }
        );
        $scope.load_transcoder_event_profile_list_error = null;
        $scope.transcoder_event_profile_list = response.data;
		// format profile name to include additional info if they are not public
		webEventProfileService.formatName($scope.transcoder_event_profile_list);
      } catch (e) {
        $scope.load_transcoder_event_profile_list_error =
          'An error occurred while gathering information. Please try again, or report the problem if it persists.';
        $scope.transcoder_event_profile_list = null;
      }
      $scope.is_loading_transcoder_event_profile_list = false;
    }
  }

  async function getDestinationGroups() {
    try {
      const response = await httpService.get(
        jcs.api.url_v3 + '/customers/' + Authentication.getCurrentUser().customerID + '/destinationgroups',
        { withCredentials: true }
      );
      $scope.destinationGroups = response.data;
    } catch (e) {
      console.error(e);
      $scope.error_msg = ERROR_MSG.GENERIC;
    }
  }

  $timeout(function () {
    $('[data-toggle="tooltip"]').tooltip();
    // this tooltip uses a html "title/body" with special formatting
    $('.destination-group-tooltip').tooltip({
      template:
        '<div class="tooltip" role="tooltip"><div class="tooltip-arrow"></div><div class="tooltip-inner tooltip-inner-wide tooltip-inner-left"></div></div>',
      placement: 'right',
      title:
        '<div class="tooltip-section">The Destination Group Name can be used to describe the specific event you will use for this destination group or it can be used to indicate the streaming destinations configured within the group that may be used for multiple event types.<br/><br/> Ex - 9:15 Main Service; Main Embed, Church FB, Church YT; Youth Embed, Youth YT',
      html: true,
    });
  });

  getDestinationGroups().then(() => {
    // trigger resize to ensure footer is set to the bottom post load
    window.dispatchEvent(new Event('resize'));
    $scope.is_loading = false;
    // angularjs not recognizing state update for is_loading without apply()
    $scope.$apply();
  });
}

module.exports = DestinationGroupsController;
