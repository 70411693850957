// The httpService can be used for single api requests, but does not work with lists of promises. In those cases
// we'll need to use the httpService.isStatus406 helper function.
// myApp.factory('httpService', function($rootScope, $http) {
class HttpService {
  constructor($rootScope, $http) {
    'ngInject';
    this.$rootScope = $rootScope;
    this.$http = $http;
  }

  get(url, config, successCallback, failureCallback, alwaysCallback) {
    return this.$http
      .get(url, config)
      .then(data => {
        if (successCallback) {
          successCallback(data);
        } else {
          return data;
        }
      })
      .catch(error => {
        if (error.status === 406) {
          this.$rootScope.status_406 = true;
        } else if (failureCallback) {
          failureCallback(error);
        } else {
          throw error;
        }
      })
      .finally(() => {
        if (alwaysCallback) {
          alwaysCallback();
        } else {
          return;
        }
      });
  }

  post(url, data, config, successCallback, failureCallback, alwaysCallback) {
    return this.$http
      .post(url, data, config)
      .then(data => {
        if (successCallback) {
          successCallback(data);
        } else {
          return data;
        }
      })
      .catch(error => {
        if (error.status === 406) {
          this.$rootScope.status_406 = true;
        } else if (failureCallback) {
          failureCallback(error);
        } else {
          throw error;
        }
      })
      .finally(() => {
        if (alwaysCallback) {
          alwaysCallback();
        } else {
          return;
        }
      });
  }

  put(url, data, config, successCallback, failureCallback, alwaysCallback) {
    return this.$http
      .put(url, data, config)
      .then(data => {
        if (successCallback) {
          successCallback(data);
        } else {
          return data;
        }
      })
      .catch(error => {
        if (error.status === 406) {
          this.$rootScope.status_406 = true;
        } else if (failureCallback) {
          failureCallback(error);
        } else {
          throw error;
        }
      })
      .finally(() => {
        if (alwaysCallback) {
          alwaysCallback();
        } else {
          return;
        }
      });
  }

  patch(url, data, config, successCallback, failureCallback, alwaysCallback) {
    return this.$http
      .patch(url, data, config)
      .then(data => {
        if (successCallback) {
          successCallback(data);
        } else {
          return data;
        }
      })
      .catch(error => {
        if (error.status === 406) {
          this.$rootScope.status_406 = true;
        } else if (failureCallback) {
          failureCallback(error);
        } else {
          throw error;
        }
      })
      .finally(() => {
        if (alwaysCallback) {
          alwaysCallback();
        } else {
          return;
        }
      });
  }

  delete(url, config, successCallback, failureCallback, alwaysCallback) {
    return this.$http
      .delete(url, config)
      .then(data => {
        if (successCallback) {
          successCallback(data);
        } else {
          return data;
        }
      })
      .catch(error => {
        if (error.status === 406) {
          this.$rootScope.status_406 = true;
        } else if (failureCallback) {
          failureCallback(error);
        } else {
          throw error;
        }
      })
      .finally(() => {
        if (alwaysCallback) {
          alwaysCallback();
        } else {
          return;
        }
      });
  }

  // if we return true, then this function will handle processing the error. In most cases that will mean
  // the calling function won't need to do anything else (in terms of error reporting).
  isStatus406(result) {
    if (result.status === 406) {
      this.$rootScope.status_406 = true;
      return true;
    }
    return false;
  }
}

export default HttpService;
