import { perms } from '../../jcs';
import { contactSearchModule } from './index';

function routeConfig($provide, $routeProvider) {
	'ngInject';

	$provide.factory('$routeProvider', () => {
		return $routeProvider;
	});

	$routeProvider.when(contactSearchModule.routes.list, {
		controller: contactSearchModule.controllers.default,
		template: require('./contactsearch.tmpl.html').default,
		activenav: contactSearchModule.name,
		access: {
			loginRequired: true,
			permissions: [perms['contact_search.get']],
		},
	});
}

export default routeConfig;
