const jcs = require('../../jcs');

class HardwarePrintButtonCtrl {

	constructor($http, $timeout, hardwareService) {
		'ngInject';
	}

	print = function () {
		var printWindowContents = document.getElementById("activation-code-printout").innerHTML;
		var printWindow = window.open('', '', 'height=800, width=800');
		printWindow.document.write('<html><body>');
		printWindow.document.write(printWindowContents);
		printWindow.document.write('</body></html>');
		printWindow.print();
		// using a timeout works because the callback fn won't execute until the next "cycle", which won't occur until after the user
		// has finished interacting with the print dialog (whether they actually print or decide to cancel).
		window.setTimeout(()=>{
			printWindow.close();
		},1);
	}
}

export default class HardwarePrintButtonComponent {
	constructor() {
		this.template = require('./hardwarePrintButton.html').default;
		this.bindings = {
			unit: '<',
		};
		this.controller = HardwarePrintButtonCtrl;
	}
}
