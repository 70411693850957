'use strict';

class AddEditCueCtrl {

	constructor(focus, formValidationService, cueService) {
		'ngInject';

		this.CUE_MAX_MINUTES_PER_HOUR = 59;
		this.CUE_MAX_SECONDS_PER_MINUTE = 59;

		this.focus = focus;
		this.cueService = cueService;
		this.validation = formValidationService.init();
		this.is_new = false;
	}

	$onInit () {

		// build tooltips requiring html
		$('.unable-to-share-tooltip').tooltip({
			template: '<div class="tooltip" role="tooltip"><div class="tooltip-arrow"></div><div class="tooltip-inner tooltip-inner-wide tooltip-inner-left"></div></div>',
			placement: 'auto top',
			title: '<div class="tooltip-section">You are not allowed to share cues because you have not been granted that permission for this event. While you can still create cues, they will only be accessible to you and will not be visible to other users.</div>In order to share cues, you will need to find the encoder event profile that was used to create this event, and select "User Access". You will then need to ensure that your user has been added to the user access list and granted permission to share cues.',
			html: true,
		});
	}

	$onChanges(changes) {

		if (changes.hasOwnProperty('cue') && changes.cue.currentValue) {
			this.validation.clear();
			this.focus('edit-cue-input');
		}
	}

	isNew () {
		if(this.cue){
			return !this.cue.hasOwnProperty('uuid');
		}
		return false;
	}

	save () {
		// if we have form validation errors, then don't go any further
		if (this.failsValidation()){
			return false;
		}
		this.onSubmit();
	}

	cancel () {
		this.validation.clear();
		this.onCancel();
	}

	failsValidation () {

		this.validation.clear();
		this.errorMsg = null;

		// check required fields to ensure they aren't empty
		this.validation.checkForEmpty('name', this.cue.name);
		this.validation.checkForEmpty('position', this.cue.position);

		let has_validation_error = this.validation.hasError();
		if (has_validation_error) {
			this.errorMsg = 'Please specify a value for the highlighted fields.';
		}

		// if no "required field" errors, then ...
		if (!has_validation_error) {
			// ensure position is valid format
			// regex guide: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions
			// hours = 2 or more digits are required
			// minutes & seconds = 2 digits are required
			// milliseconds = 1 to 3 digits are required
			const regex = /^\d{2,}\:\d{2}\:\d{2}\.\d{1,3}$/;
			if (!regex.test(this.cue.position)) {
				has_validation_error = true;
				this.validation.setError('position');
				this.errorMsg = 'Position must be in the format: "HH:MM:SS.mmm" where H = hours, M = minutes, S = seconds, m = milliseconds';
			}
		}

		if (!has_validation_error) {
			// ensure minutes is <= 59
			const regex = /\:(\d{2})\:/;
			const found = regex.exec(this.cue.position);
			const minutes =  parseInt(found[1]);
			if (minutes > this.CUE_MAX_MINUTES_PER_HOUR) {
				has_validation_error = true;
				this.validation.setError('position');
				this.errorMsg = `Minutes must be less than or equal to ${this.CUE_MAX_MINUTES_PER_HOUR}.`;
			}
		}

		if (!has_validation_error) {
			// ensure seconds is <= 59
			const regex = /\:(\d{2})\./;
			const found = regex.exec(this.cue.position);
			const seconds =  parseInt(found[1]);
			if (seconds > this.CUE_MAX_SECONDS_PER_MINUTE) {
				has_validation_error = true;
				this.validation.setError('position');
				this.errorMsg = `Seconds must be less than or equal to ${this.CUE_MAX_SECONDS_PER_MINUTE}.`;
			}
		}

		if (!has_validation_error) {
			const seek_range = this.getVideoSeekRange();
console.log({name: 'seek_range', value: seek_range});
			if (seek_range){
				const cue_position_sec = this.cueService.getCuePositionInSeconds(this.cue.position);
				if (cue_position_sec > seek_range.end){
					has_validation_error = true;
					this.validation.setError('position');
					this.errorMsg = 'Time position is beyond the end of this event.';
				}
			}
		}

		return has_validation_error;
	}

}

export default class AddEditCueComponent {
	constructor() {
		this.template = require('./addEditCue.html').default;
		this.bindings = {
			cue: '<',
			isBusy: '<',
			canShareCues: '<',
			shareCueOptions: '<',
			errorMsg: '=',
			onSubmit: '&',
			onCancel: '&',
			getVideoSeekRange: '&',
		};
		this.controller = AddEditCueCtrl;
	}
}
