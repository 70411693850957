import styled from '@emotion/styled';

export const SubmitButtonWrap = styled.div`
  margin-top: 6em;
`;

export const ToolTipText = styled.p`
  width: 176px;
  white-space: normal;
`;
