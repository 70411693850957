import { api } from '../jcs';

class EventService {

	constructor ($rootScope, httpService, $q, Authentication, webEventsService) {
		'ngInject';

		this.$rootScope = $rootScope;
		this.httpService = httpService;
		this.$q = $q;
		this.Authentication = Authentication;
		this.webEventSvc = webEventsService;

		this.web_events = null;
		this.encoder_events = null;
	}

	findWebEventById(uuid) {
		if (this.web_events != null){
			for (var i = 0; i < this.web_events.length; i++) {
				var web_event = this.web_events[i];
				if (web_event.uuid == uuid) {
					return web_event;
				}
			}
		}
		return null;
	}

	clearWebCache () {
		this.web_events = null;
	}

	clearEncoderCache () {
		this.encoder_events = null;
	}

	clearCache () {
		this.clearWebCache();
		this.clearEncoderCache();
	}

	getWebEvent (uuid) {

		var deferred = this.$q.defer();
		var self = this;

		// do we already have events in our cache?
		var matching_event = this.findWebEventById(uuid);
		if (matching_event != null){

			deferred.resolve(matching_event);

		} else {

			this.httpService.get(api.url_v3 + '/customers/' + this.Authentication.getCurrentUser().customerID + '/webevents/' + uuid, { withCredentials: true },
				function (response) { // success

					// note: we don't currently cache single events b/c I didn't see a benefit to that. Should we?
					deferred.resolve(response.data);

				},
				function (reason) { // error

					deferred.reject(reason);

				},
				function () { // always called
				});
		}

		return deferred.promise;
	}

	loadEncoderEvents () {

		var deferred = this.$q.defer();
		var self = this;

		// do we already have the data in our cache?
		if (this.encoder_events != null){

			deferred.resolve(this.encoder_events);

		} else {

			this.httpService.get(api.url_v3 + '/customers/' + this.Authentication.getCurrentUser().customerID + '/events', { withCredentials: true },
				function (response) { // success

					self.encoder_events = response.data;
					deferred.resolve(response.data);

				},
				function (reason) { // error

					deferred.reject(reason);

				},
				function () { // always called
				});
		}

		return deferred.promise;
	}

	loadWebEvents () {

		var deferred = this.$q.defer();
		var self = this;

		// do we already have the data in our cache?
		if (this.web_events != null){

			deferred.resolve(this.web_events);

		} else {

			this.httpService.get(api.url_v3 + '/customers/' + this.Authentication.getCurrentUser().customerID + '/webevents', { withCredentials: true },
				function (response) { // success

					self.web_events = response.data;
					deferred.resolve(response.data);

				},
				function (reason) { // error

					deferred.reject(reason);

				},
				function () { // always called
				});
		}

		return deferred.promise;
	}

	stopWebEvent (web_event_to_stop) {

		var deferred = this.$q.defer();
		var self = this;

		this.httpService.post(api.url_v3 + '/customers/' + this.Authentication.getCurrentUser().customerID + '/webevents/' + web_event_to_stop.uuid + '/stop', {}, { withCredentials: true },
			function () {

				self.webEventSvc.add(web_event_to_stop.uuid);
				deferred.resolve();

			},
			function (reason) { // error

				deferred.reject(reason);

			},
			function () { // always called
			}
		);

		return deferred.promise;
	}

	editWebEvent (web_event_to_edit, updated_event_data) {

		var deferred = this.$q.defer();
		var self = this;

		this.httpService.patch(api.url_v3 + '/customers/' + this.Authentication.getCurrentUser().customerID + '/webevents/' + web_event_to_edit.uuid, updated_event_data, { withCredentials: true },
			function () {

				// modify web_event_to_edit with our changes, which will modify the cache
				web_event_to_edit.name = updated_event_data.name;
				web_event_to_edit.description = updated_event_data.description;

				deferred.resolve();

			},
			function (reason) { // error

				deferred.reject(reason);

			},
			function () { // always called
			}
		);

		return deferred.promise;
	}

	getEventStats(uuid) {
		const url = `${api.url_v3}/customers/${this.Authentication.getCurrentUser().customerID}/webevents/${uuid}/export/statistics`;
		const deferred = this.$q.defer();

		this.httpService.get(url, { withCredentials: true },
			response => deferred.resolve(response.data),
			reason => deferred.reject(reason));

		return deferred.promise;
	}

	deleteWebEvent (web_event_to_delete) {

		var deferred = this.$q.defer();
		var self = this;

		// NOTE: I normally would not include the "data: null", but IE11 and below seem to require it in order to work
		// see: https://github.com/angular/angular.js/issues/12141
		this.httpService.delete(api.url_v3 + '/customers/' + this.Authentication.getCurrentUser().customerID + '/webevents/' + web_event_to_delete.uuid, { withCredentials: true, data: null },
			function () {

				// ensure web event is removed from stopping web events list
				self.webEventSvc.remove(web_event_to_delete.uuid);
				// remove web event from our list
				if (self.web_events != null){
					var index = self.web_events.indexOf(web_event_to_delete);
					if (index > -1) {
						self.web_events.splice(index, 1);
					}
				}

				deferred.resolve();

			},
			function (reason) { // error

				deferred.reject(reason);

			},
			function () { // always called
			}
		);

		return deferred.promise;
	}
}

export default EventService;
