/** @jsx jsx */

import { FC, useState } from 'react';
import PropTypes from 'prop-types';
import { jsx } from '@emotion/react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { MailOutlined, LoadingOutlined } from '@ant-design/icons';
import { Stack, BlockButton, TextField, theme, ThemeProvider } from '@resi-media/resi-ui';
import { BlockError, ResiLayout } from '../../components';
import { resetPasswordRequest } from './api';
import { BoxHeader, BoxWrap, BoxDescription } from '../../components/Styled';

interface PasswordResetFormProps {
  error?: string;
  onSubmit: (email: string) => void;
}

export const PasswordResetForm: FC<PasswordResetFormProps> = ({ error, onSubmit }) => {
  const initialValues = { email: '' };
  const passwordResetSchema = Yup.object().shape({
    email: Yup.string()
      .email('Email address is not valid')
      .required('Email address is required')
      .max(254, 'Email address cannot exceed 254 characters'),
  });
  return (
    <ThemeProvider theme={theme}>
      <Formik
        initialValues={initialValues}
        validationSchema={passwordResetSchema}
        onSubmit={(values) => onSubmit(values.email)}
      >
        {(formik) => (
          <BoxWrap>
            <form onSubmit={formik.handleSubmit}>
              <Stack scale="xl">
                <Stack scale="m" alignItems="center">
                  <BoxHeader>Reset Password</BoxHeader>
                  <BoxDescription>
                    Enter your email associated with your account and we’ll send you an email on how to reset your
                    password
                  </BoxDescription>
                </Stack>
                {error && <BlockError>{error}</BlockError>}
                <Stack scale="m">
                  <TextField
                    id="email"
                    title="email"
                    name="email"
                    error={formik.errors.email}
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    touched={formik.touched.email}
                    leftIcon={<MailOutlined />}
                  />
                </Stack>
                <Stack scale="m">
                  <BlockButton
                    label="Send recovery email"
                    type="submit"
                    isToggleButton={false}
                    iconLeft={formik.isSubmitting ? <LoadingOutlined /> : undefined}
                    isDisabled={formik.isSubmitting || !formik.dirty || !formik.isValid}
                  />
                </Stack>
              </Stack>
            </form>
          </BoxWrap>
        )}
        </Formik>
      </ThemeProvider>
  );
};

PasswordResetForm.displayName = 'PasswordResetForm';
PasswordResetForm.propTypes = {
  error: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
};

interface PasswordResetSentProps {
  email: string;
  error?: string;
  onSubmit: (email: string) => void;
}

export const PasswordResetSent: FC<PasswordResetSentProps> = ({ email, error, onSubmit }) => {
  const initialValues = { email };
  return (
    <Formik initialValues={initialValues} onSubmit={(values) => onSubmit(values.email)}>
      {(formik) => (
        <BoxWrap>
          <form onSubmit={formik.handleSubmit}>
            <Stack scale="xl">
              <Stack scale="m" alignItems="center">
                <BoxHeader>Recovery Email Sent!</BoxHeader>
                <BoxDescription data-testid="page-subtitle">
                  A recovery email is on the way! Please check <strong>{email}</strong> for instructions on resetting
                  your password.
                </BoxDescription>
                <input name="email" type="hidden" value={formik.values.email} readOnly></input>
              </Stack>
              {error && <BlockError>{error}</BlockError>}
              <BlockButton
                label="Resend recovery email"
                type="submit"
                isToggleButton={false}
                iconLeft={formik.isSubmitting ? <LoadingOutlined /> : undefined}
              />
            </Stack>
          </form>
        </BoxWrap>
      )}
    </Formik>
  );
};

PasswordResetSent.displayName = 'PasswordResetSent';
PasswordResetSent.propTypes = {
  error: PropTypes.string,
  email: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

const PasswordResetPage: FC = () => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState(undefined);
  const handleSubmit = async (email: string): Promise<void> => {
    try {
      setError(undefined);
      await resetPasswordRequest(email);
      setEmail(email);
    } catch (e) {
      setError(e.message);
    }
  };

  return (
    <ResiLayout>
      {email.length > 0 ? (
        <PasswordResetSent email={email} onSubmit={handleSubmit} error={error} />
      ) : (
        <PasswordResetForm onSubmit={handleSubmit} error={error} />
      )}
    </ResiLayout>
  );
};

PasswordResetPage.displayName = 'PasswordResetPage';

export default PasswordResetPage;
