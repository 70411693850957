import React, { FC, useState, useContext, ChangeEvent } from 'react';
import PropTypes from 'prop-types';
import { Paper } from '@material-ui/core';
import { DateTime } from 'luxon';
import { LineChart, ContainedTabs, ContainedTab, Surface, FillBlockSpinner, ViewEvents, ContainedButton } from '../../../../components';
import {
  TabsDiv,
  GraphWrapper,
  EventStatsGraphWrapper,
  EventStatsHeaderDiv,
  DropDownWrapper,
  ChartControls,
} from './alldestinations-stats-graph.styles';
import Tooltip from '@material-ui/core/Tooltip';
import { lineChartDataType, SelectOption, TIME_WINDOWS } from '../../types';
import Modal from '../../../../components/Modal';
import ModalHeader from '../../../../components/ModalHeader';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRange, Range } from 'react-date-range';
import { Inline, Select, Grid, Text, Option, customProperties as vars } from '@resi-media/resi-ui';
import { DESTINATION_TYPES, LEGEND_NAMES, LOADER_SIZE_COMPONENT, PAGE_VALUE, ZOOM_CTRL_TOOLTIP } from '../../event-analytics-constants';
import { renameKey } from '../../utils';
import { EventAnalyticsContext } from '../../../../contexts/eventanalytics/event-analytics';
import { LIFE_TIME_DATE_LOCAL, START_DATE_LOCAL } from '../../date-range-options';
import { UndoOutlined, ZoomInOutlined, ZoomOutOutlined } from '@ant-design/icons';

interface AllDestinationsGraphViewProps {
  data: lineChartDataType;
  isChartLoading: boolean;
  dateRangeOptions: SelectOption[];
  dateRangeDropdownValue: SelectOption;
  isDatePickerOpen: boolean;
  timeWindow: number;
  dataType: number;
  disableMonthTab: boolean;
  handleDateRangeChange: (item: Option[]) => void;
  handleGroupByChange: (event: ChangeEvent<unknown>, newValue: 0 | 1) => void;
  handleTimeWindowChange: (event: ChangeEvent<unknown>, newValue: TIME_WINDOWS) => void;
  handleCustomDateRangeSelection: (startDate: string, endDate: string) => void;
  closeDatePicker: () => void;
}

const AllDestinationsStatsGraphView: FC<AllDestinationsGraphViewProps> = ({
  data,
  isChartLoading,
  dateRangeOptions,
  dateRangeDropdownValue,
  isDatePickerOpen,
  timeWindow,
  dataType,
  disableMonthTab,
  handleGroupByChange,
  handleDateRangeChange,
  handleTimeWindowChange,
  handleCustomDateRangeSelection,
  closeDatePicker,
}): JSX.Element => {
  const {
    EventAnalyticsState: {
      viewAllData,
      dateRange: { startDate, endDate },
    },
  } = useContext(EventAnalyticsContext);
  const [selectionRange, setSelectionRange] = useState<Range>({
    startDate: new Date(startDate),
    endDate: new Date(endDate),
    key: 'selection',
  });

  const handleDateRangeSelect = (range: { selection: Range }) => {
    const { selection } = range;
    const { hour, minute, second, millisecond } = DateTime.local().toObject();
    setSelectionRange({
      startDate: selection.startDate,
      endDate: selection.endDate,
      key: 'selection',
    });
    if (selectionRange.startDate && selectionRange.endDate) {
      const selectionStartDate = selection.startDate
        ? DateTime.fromJSDate(selection.startDate)
            .plus({
              hours: hour,
              minutes: minute,
              seconds: second,
              milliseconds: millisecond,
            })
            .toISO() || ''
        : '';
      const selectionEndDate = selection.endDate
        ? DateTime.fromJSDate(selection.endDate)
            .plus({
              hours: hour,
              minutes: minute,
              seconds: second,
              milliseconds: millisecond,
            })
            .toISO() || ''
        : '';
      handleCustomDateRangeSelection(selectionStartDate, selectionEndDate);
    }
  };

  Object.keys(data.datasets).map((legend) => {
    if (legend in LEGEND_NAMES) {
      data.datasets = renameKey(data.datasets, legend, `${legend}`);
    }
  });

  return (
    <Grid.Item>
      <Paper elevation={0} variant="outlined" data-testid="alldestinations-stats-graph-view">
        <EventStatsGraphWrapper>
          <EventStatsHeaderDiv>
            <Inline alignItems="center">
              <div>
                <Text.Body size="xs" isUppercase isBold color="secondary">
                  {`${DateTime.fromISO(startDate).toFormat('MMM dd yyyy')} - \
                ${DateTime.fromISO(endDate).toFormat('MMM dd yyyy')}`}
                </Text.Body>
                <DropDownWrapper>
                  <Select
                    size="m"
                    value={dateRangeDropdownValue?.value}
                    options={dateRangeOptions}
                    handleChange={(newValue) => {
                      if (newValue[0].value) {
                        handleDateRangeChange(newValue);
                      }
                    }}
                  />
                </DropDownWrapper>
                {isDatePickerOpen && (
                  <Modal width="fit-content">
                    <Surface padding="0">
                      <ModalHeader titleText="Pick custom date range" onClose={() => closeDatePicker()} closeButton />
                      <DateRange
                        ranges={[selectionRange]}
                        editableDateInputs
                        onChange={handleDateRangeSelect}
                        minDate={viewAllData ? LIFE_TIME_DATE_LOCAL.toJSDate() : START_DATE_LOCAL.toJSDate()}
                      />
                    </Surface>
                  </Modal>
                )}
              </div>
              <ViewEvents
                dateRangeOptions={dateRangeOptions}
                dateRangeSelection={dateRangeDropdownValue}
                page={DESTINATION_TYPES.ALL_DESTINATIONS}
              />
            </Inline>
            <Inline justifyContent="center" alignItems="center">
              <Text.Heading
                type="h4"
                customCss={`color: ${vars.colorAccent800};`}
                data-testid="alldestinations-stats-graph-view-pageHeader"
              >
                All Destination Views
              </Text.Heading>
            </Inline>
          </EventStatsHeaderDiv>
          <TabsDiv>
            <ContainedTabs onChange={handleGroupByChange} value={dataType}>
              <ContainedTab data-testid="all-destionation-none-tab" label="None" />
              <ContainedTab data-testid="all-destinations-type-tab" label="Destination Type" />
            </ContainedTabs>
            <Tooltip title={<h6>{ZOOM_CTRL_TOOLTIP}</h6>}  arrow placement="top">
              <ChartControls>
                <ContainedButton id="zoomPlus">
                  <ZoomInOutlined style={{ fontSize: '20px' }} />
                </ContainedButton>
                <ContainedButton id="zoomMinus">
                  <ZoomOutOutlined style={{ fontSize: '20px' }} />
                </ContainedButton>
                <ContainedButton id="reset-zoom">
                  <UndoOutlined style={{ fontSize: '20px' }} />
                </ContainedButton>
              </ChartControls>
            </Tooltip>
            <ContainedTabs onChange={handleTimeWindowChange} value={timeWindow}>
              <ContainedTab data-testid="all-destinations-day-tab" label="Day" />
              <ContainedTab data-testid="all-destinations-week-tab" label="Week" />
              <ContainedTab disabled={disableMonthTab} data-testid="all-destinations-month-tab" label="Month" />
            </ContainedTabs>
          </TabsDiv>
          <GraphWrapper data-testid="alldestinations-stats-graph-view-lineChart">
            {isChartLoading ? (
              <FillBlockSpinner fontSize={LOADER_SIZE_COMPONENT} />
            ) : (
              <LineChart dataPageName={PAGE_VALUE.ALL_DESTINATIONS} data={data} tooltipLabel="Views" />
            )}
          </GraphWrapper>
        </EventStatsGraphWrapper>
      </Paper>
    </Grid.Item>
  );
};

AllDestinationsStatsGraphView.defaultProps = {
  data: {
    labels: ['Aug 9', 'Sep 1', 'Sep 2', 'Sep 9', 'Sep 15', 'Oct 1', 'Oct 8'],
    datasets: { data: [12, 7, 14, 30, 11, 12, 4, 79, 13, 26, 12, 21, 10] },
  },
};

AllDestinationsStatsGraphView.propTypes = {
  data: PropTypes.objectOf(
    PropTypes.exact({
      labels: PropTypes.arrayOf(PropTypes.string),
      datasets: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.number)),
    })
  ).isRequired,
  isChartLoading: PropTypes.bool.isRequired,
  dateRangeOptions: PropTypes.arrayOf(
    PropTypes.exact({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.number.isRequired]).isRequired,
    }).isRequired
  ).isRequired,
  dateRangeDropdownValue: PropTypes.exact({
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.number.isRequired]).isRequired,
  }).isRequired,
  handleDateRangeChange: PropTypes.func.isRequired,
  handleGroupByChange: PropTypes.func.isRequired,
  handleTimeWindowChange: PropTypes.func.isRequired,
  timeWindow: PropTypes.number.isRequired,
  dataType: PropTypes.number.isRequired,
  disableMonthTab: PropTypes.bool.isRequired,
  isDatePickerOpen: PropTypes.bool.isRequired,
  closeDatePicker: PropTypes.func.isRequired,
  handleCustomDateRangeSelection: PropTypes.func.isRequired,
};

AllDestinationsStatsGraphView.displayName = 'AllDestinationsStatsGraphView';

export default AllDestinationsStatsGraphView;
