import { DateTime } from 'luxon';
import { DateRangeValuesType, SelectOption } from './types';

// ====================================================
// Used as common time object for multiple files.
export const NOW_LOCAL = DateTime.local();
export const NOW_UTC = DateTime.utc();
export const START_DATE_LOCAL = DateTime.local(2021, 6, 7);
export const LIFE_TIME_DATE_LOCAL = DateTime.local(2021, 1, 1);
export const dateTimeFormatUTC = `yyyy-MM-dd'T'HH:mm:ss'Z'`;
const { hour, minute, second, millisecond } = DateTime.local().toObject();
const localTimeObj = { hours: hour, minutes: minute, seconds: second, milliseconds: millisecond };
// ====================================================

// FRAGILE: other code contains hardcoded indexes into this array. If you add/remove items you need to ensure
// that code is still working properly. Ex: event-analytics.tsx
export const dayRangeOptions: SelectOption[] = [
  {
    label: 'Last 7 Days',
    value: '7',
  },
  {
    label: 'Last 28 Days',
    value: '28',
  },
  {
    label: 'Last 90 Days',
    value: '90',
  },
  {
    label: 'Last 365 Days',
    value: '365',
  },
  {
    label: 'Lifetime',
    value: '0',
  },
];

export const customDateRangeOption: SelectOption = {
  label: 'Custom',
  value: 'c0',
};

export const currentAndPreviousYearOptions: SelectOption[] = [
  {
    label: String(NOW_LOCAL.year),
    value: 'y0',
  },
  {
    label: String(NOW_LOCAL.year - 1),
    value: 'y1',
  },
];
export const previous3MonthOption: SelectOption[] = [
  {
    label: NOW_LOCAL.minus({ months: 0 }).toFormat('MMMM yyyy'),
    value: 'm0',
  },
  {
    label: NOW_LOCAL.minus({ months: 1 }).toFormat('MMMM yyyy'),
    value: 'm1',
  },
  {
    label: NOW_LOCAL.minus({ months: 2 }).toFormat('MMMM yyyy'),
    value: 'm2',
  },
];

export const lifeTimeValues: DateRangeValuesType = {
  0: {
    startDate: LIFE_TIME_DATE_LOCAL.toISO(),
    endDate: NOW_LOCAL.toISO(),
  },
}

export const dateRangeValues: DateRangeValuesType = {
  7: {
    startDate: NOW_LOCAL.minus({ days: 6 }).toISO(),
    endDate: NOW_LOCAL.toISO(),
  },
  28: {
    startDate: NOW_LOCAL.minus({ days: 27 }).toISO(),
    endDate: NOW_LOCAL.toISO(),
  },
  90: {
    startDate: NOW_LOCAL.minus({ days: 89 }).toISO(),
    endDate: NOW_LOCAL.toISO(),
  },
  365: {
    startDate: NOW_LOCAL.minus({ days: 364 }).toISO(),
    endDate: NOW_LOCAL.toISO(),
  },
  0: {
    startDate: START_DATE_LOCAL.toISO(),
    endDate: NOW_LOCAL.toISO(),
  },
  y0: {
    startDate: NOW_LOCAL.minus({ years: 0 }).startOf('year').plus(localTimeObj).toISO(),
    endDate: NOW_LOCAL.minus({ years: 0 }).endOf('year').toISO(),
  },
  y1: {
    startDate: NOW_LOCAL.minus({ years: 1 }).startOf('year').plus(localTimeObj).toISO(),
    endDate: NOW_LOCAL.minus({ years: 1 }).endOf('year').toISO(),
  },
  m0: {
    startDate: NOW_LOCAL.minus({ months: 0 }).startOf('month').plus(localTimeObj).toISO(),
    endDate: NOW_LOCAL.minus({ months: 0 }).endOf('month').toISO(),
  },
  m1: {
    startDate: NOW_LOCAL.minus({ months: 1 }).startOf('month').plus(localTimeObj).toISO(),
    endDate: NOW_LOCAL.minus({ months: 1 }).endOf('month').toISO(),
  },
  m2: {
    startDate: NOW_LOCAL.minus({ months: 2 }).startOf('month').plus(localTimeObj).toISO(),
    endDate: NOW_LOCAL.minus({ months: 2 }).endOf('month').toISO(),
  },
  c1: {
    startDate: '',
    endDate: '',
  },
};
