'use strict';

const {MPEventName, MPEventProperty, trackMixpanelEvent} = require('../../../../src/mixpanel')
const jcs = require('../../jcs');
const campusreviewModule = require('./index').campusreviewModule;

function CampusReviewController(
	$rootScope,
	$scope,
	$timeout,
	$http,
	$q,
	$routeParams,
	focus,
	httpService,
	cueService,
	webPlayerService,
	webEventsService,
	eventService,
	Authentication,
	uiService,
) {
	'ngInject';

	$(window).trigger('resize'); // ensure footer is properly positioned

	let userAgent = navigator.userAgent || navigator.vendor || window.opera;
	$scope.isiOSDevice = /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream;

	$scope.loading_web_events_timeout_id = null;
	$scope.CHECK_WEB_EVENT_STATUS_TIME_DELAY_SHORT = 10000; // 10 seconds
	$scope.CHECK_WEB_EVENT_STATUS_TIME_DELAY_LONG = 60000; // 60 seconds

	$scope.DESTINATION_TYPE_FB_PAGE = 'fb_page';

	$scope.STATUS_STARTED = 'started';
	$scope.STATUS_STARTING = 'starting';
	$scope.STATUS_STOPPED = 'stopped';
	$scope.STATUS_STOPPING = 'stopping';
	$scope.STATUS_ABORTED = 'aborted';
	$scope.STATUS_ERROR = 'error';
	$scope.STATUS_IDLE = 'idle';

	$scope.OPTION_YES = 'Yes';
	$scope.OPTION_NO = 'No';
	$scope.yes_no_options = [$scope.OPTION_YES, $scope.OPTION_NO];

	$scope.loading_webplayer_timeout_id = null;
	$scope.CHECK_MANIFEST_AVAILABILITY_TIME_DELAY = 1500;
	$scope.DISPLAY_STATUS_SHOW = 'show-player';
	$scope.DISPLAY_STATUS_NOT_YET_AVAILABLE = 'not-yet-available';
	$scope.DISPLAY_STATUS_ERROR = 'error';
	$scope.web_event_webplayer_display_status = $scope.DISPLAY_STATUS_SHOW;

	$scope.web_events = null;

	$scope.is_loading = false; // show "loading ..." message
	$scope.is_working = false; // activity indicator (spinner icon)
	$scope.error_msg = null;

	// web event to watch
	$scope.web_event_to_watch = null;
	$scope.WEB_PLAYER_WRAPPER_ID = 'campus-review-video-player-wrapper'; // needs to be unique across control
	$scope.web_player = null;
	$scope.cues = null;
	$scope.is_busy_loading_cues = false;
	$scope.cues_error = null;
	$scope.can_show_cues = true;
	$scope.can_share_cues_web_event = false;
	$scope.cue_time_range_filter = null;
	$scope.visible_cue_count = 0;
	$scope.update_cue_visibility_interval_id = null;
	$scope.update_cues_interval_id = null;
	$scope.UPDATE_CUES_INTERVAL = 10000; // update cues once every 10 seconds
	$scope.UPDATE_CUE_VISIBILITY_INTERVAL = 2000; // update every 2 seconds (no api calls are made)
	$scope.is_showing_cues = true; // controls visibility of cues (can be toggled by user)
	$scope.is_src_event_available = true; // can't show cues if source event isn't available

	// web event cues
	$scope.CUE_MILLISECOND_DIGITS = 3;
	$scope.cue_to_add = null;
	$scope.cue_to_edit = null;
	$scope.cue_to_update = null;
	$scope.cue_to_delete = null;
	$scope.cue_error_msg = null;

	$scope.getCurrentUser = function () {
		return Authentication.getCurrentUser();
	};

	$scope.canShowAddCueBtn = function () {
		return Authentication.getCurrentUser().hasPerm('cues.add');
	};

	// this should be called when we get a updated list of web events from the api; we will need to update
	// our list of "stopping" web events (remove them if they are already stopped)
	$scope.updateWebEventsThatAreStopping = function (web_events) {
		for (let i = 0; i < web_events.length; i++) {
			let web_evt = web_events[i];
			if (web_evt.status == $scope.STATUS_STOPPED) {
				webEventsService.remove(web_evt.uuid);
			}
		}
	};

	$scope.gotoCue = function (cue) {
		webPlayerService.seek($scope.WEB_PLAYER_WRAPPER_ID, cueService.getCuePositionInSeconds(cue.position));

		const mixpanelProps = {
			[MPEventProperty.CUE_NAME]: cue.name,
			[MPEventProperty.TRANSCODED_EVENT_UUID]: $scope.web_event_to_watch.uuid
		}

		trackMixpanelEvent(MPEventName.CUE_GOTO, mixpanelProps)
	};

	$scope.loadCuesForWatchedWebEvent = function () {
		$scope.cues_error = null;
		$scope.is_busy_loading_cues = true;

		// pull the cues for the web event that is being watched
		$http.get(`${jcs.api.url_v3}/customers/${Authentication.getCurrentUser().customerID}/webevents/${$scope.web_event_to_watch.uuid}/cues?canISetCues=1`, { withCredentials: true }).then(response => {

			let cues = response.data;
			// add a position field that is formatted as seconds only -- so we can compare to our time range filter
			for (let i = 0; i < cues.length; i++) {
				cues[i].position_in_seconds = cueService.getCuePositionInSeconds(cues[i].position);
			}
			$scope.cues = cues;

			// cansetcues is returned in response header if we send "canISetCues=1" param to API
			let can_share_cues_header = response.headers('cansetcues');
			if (can_share_cues_header){
				$scope.can_share_cues_web_event = can_share_cues_header.toLowerCase() == 'true';
			}

			// now that new position field is added, determine the visibility of each cue
			$scope.updateCueVisibility();

		}).catch(reason => {

			if (reason.status === 412){
				// NOTE: if we get a 412, then it means the source event no longer exists, and therefore we can't show cues for this web event.
				// But this is temporary. Eventually, we'll be able to manage cues on web events regardless if the source encoder event has been deleted or not.
				$scope.is_src_event_available = false;
				if ($scope.update_cues_interval_id != null) {
					window.clearInterval($scope.update_cues_interval_id);
					$scope.update_cues_interval_id = null;
				}
			} else {
				$scope.cues_error = 'There was an error loading the cues.';
			}

		}).finally(() => {

			$scope.is_busy_loading_cues = false;

		});
	};

	$scope.loadCuesForWatchedWebEventSilent = function () {

		// pull the cues for the web event that is being watched
		$http.get(`${jcs.api.url_v3}/customers/${Authentication.getCurrentUser().customerID}/webevents/${$scope.web_event_to_watch.uuid}/cues`, { withCredentials: true }).then(response => {

			$scope.cues_error = null;

			let cues = response.data;
			// add a position field that is formatted as seconds only -- so we can compare to our time range filter
			for (let i = 0; i < cues.length; i++) {
				cues[i].position_in_seconds = cueService.getCuePositionInSeconds(cues[i].position);
			}
			$scope.cues = cues;

			// now that new position field is added, determine the visibility of each cue
			$scope.updateCueVisibility();

		}).catch(reason => {

			if (reason.status === 412){
				// NOTE: if we get a 412, then it means the source event no longer exists, and therefore we can't show cues for this web event.
				// But this is temporary. Eventually, we'll be able to manage cues on web events regardless if the source encoder event has been deleted or not.
				$scope.is_src_event_available = false;
				if ($scope.update_cues_interval_id != null) {
					window.clearInterval($scope.update_cues_interval_id);
					$scope.update_cues_interval_id = null;
				}
			}
		});
	};

	// TODO: once the backed is updated and we don't have to set the cue visibility anymore, remove is_visible check from cueList.html
	$scope.updateCueVisibility = function () {
		let count = 0;
		if ($scope.cues != null) {
			for (let i = 0; i < $scope.cues.length; i++) {
				let is_visible = $scope.isCueVisible($scope.cues[i]);
				$scope.cues[i].is_visible = is_visible;
				if (is_visible) count++;
			}
		}
		$scope.visible_cue_count = count;
	};

	$scope.updateCueVisibilityOnInterval = function () {
		$scope.$apply(function () {
			$scope.cue_time_range_filter = $scope.web_player.getTimeRange(); // time range will constantly be changing for live events
			$scope.updateCueVisibility();
		});
	};

	$scope.isCueVisible = function (cue) {
		// don't display cues if not able, or time range filter has not been initialized
		if (cue == null || !$scope.can_show_cues || $scope.cue_time_range_filter == null){
			return false;
		}
		// cue is visible if it resides within our time range filter
		if (cue.position_in_seconds >= $scope.cue_time_range_filter.start &&cue.position_in_seconds <= $scope.cue_time_range_filter.end){
			return true;
		}
		return false;
	};

	$scope.toggleShowCues = function (showCues) {
		$scope.is_showing_cues = showCues

		trackMixpanelEvent(
			showCues ? MPEventName.CUE_SHOW : MPEventName.CUE_HIDE,
			{[MPEventProperty.TRANSCODED_EVENT_UUID]: $scope.web_event_to_watch.uuid}
		)
	}

	$scope.initializeCueUpdates = function () {
		$scope.web_player.getVideo().removeEventListener('playing', $scope.initializeCueUpdates);

		$scope.$apply(function () {
			// get the time range we will filter the cues by (we don't want to show cues that are outside our range)
			$scope.cue_time_range_filter = $scope.web_player.getTimeRange();
			$scope.loadCuesForWatchedWebEvent();

			// setup intervals that will keep our cues up-to-date and visibility accurate
			$scope.update_cues_interval_id = window.setInterval($scope.loadCuesForWatchedWebEventSilent, $scope.UPDATE_CUES_INTERVAL);
			$scope.update_cue_visibility_interval_id = window.setInterval($scope.updateCueVisibilityOnInterval, $scope.UPDATE_CUE_VISIBILITY_INTERVAL);
		});
	};

	// returns the matching cue (we check UUID) from the $scope.cues list. Since the cues list gets auto
	// updated periodically, you should use this method to ensure you are working with a cue that is in the list.
	$scope.findCue = function (cueToFind) {
		for (let i = 0; i < $scope.cues.length; i++) {
			if ($scope.cues[i].uuid == cueToFind.uuid) {
				return $scope.cues[i];
			}
		}
		return null;
	};

	$scope.getTimeWithLeadingZero = function (time) {
		if (time >= 10) return time;
		return '0' + time;
	};

	$scope.convertSecondsToCuePosition = function (seconds) {
		if (seconds > 3600) {
			let hours = parseInt(seconds / 3600);
			let remainingSeconds = seconds % 3600;
			let minutes = parseInt(remainingSeconds / 60);
			remainingSeconds = remainingSeconds % 60;
			remainingSeconds = remainingSeconds.toFixed($scope.CUE_MILLISECOND_DIGITS);
			return (`${$scope.getTimeWithLeadingZero(hours)}:${$scope.getTimeWithLeadingZero(minutes)}:${$scope.getTimeWithLeadingZero(remainingSeconds)}`);
		} else if (seconds > 60) {
			let minutes = parseInt(seconds / 60);
			let remainingSeconds = seconds % 60;
			remainingSeconds = remainingSeconds.toFixed($scope.CUE_MILLISECOND_DIGITS);
			return `00:${$scope.getTimeWithLeadingZero(minutes)}:${$scope.getTimeWithLeadingZero(remainingSeconds)}`;
		} else {
			seconds = seconds.toFixed($scope.CUE_MILLISECOND_DIGITS);
			return `00:00:${$scope.getTimeWithLeadingZero(seconds)}`;
		}
	};

	$scope.getVideoCurrentPosition = function (){
		if ($scope.web_player.getVideo() != null){
			return $scope.convertSecondsToCuePosition($scope.web_player.getVideo().currentTime);
		}
		return '00:00:00.000';
	};

	$scope.canShowCueList = function () {
		// don't show cues list if we are editing, updating, or deleting a cue. don't show cues if on iOS device. also don't show if adding a cue, but we don't have any cues in our list
		// if we do have 1 or more cues, then the list remains displayed when adding a new cue -- so user can refer to them if necessary.
		return $scope.is_showing_cues && !$scope.isiOSDevice && ( !$scope.cue_to_edit && !$scope.cue_to_update && !$scope.cue_to_delete && !($scope.cue_to_add && $scope.cues.length == 0));
	}

	$scope.enterUpdateCueMode = function (cue) {

		$scope.cue_to_add = null;
		$scope.cue_to_update = {
			uuid: cue.uuid,
			user: cue.user,
			name: cue.name,
			position: cue.position,
			new_position: $scope.getVideoCurrentPosition()
		};
	};

	$scope.cancelUpdateCue = function () {
		$scope.cue_to_update = null;
		$scope.cue_error_msg = null;
	};

	$scope.updateCue = function () {

		$scope.is_working = true;

		let updated_cue_data = {
			position: $scope.cue_to_update.new_position
		};

		$http.patch(`${jcs.api.url_v3}/customers/${Authentication.getCurrentUser().customerID}/webevents/${$scope.web_event_to_watch.uuid}/cues/${$scope.cue_to_update.uuid}`, updated_cue_data, { withCredentials: true }).then(() => {
			const mixpanelProps = {
				[MPEventProperty.CUE_NAME]: $scope.cue_to_update.name,
				[MPEventProperty.TRANSCODED_EVENT_UUID]: $scope.web_event_to_watch.uuid,
				[MPEventProperty.CUE_SHARED]: $scope.cue_to_update.shared === $scope.OPTION_YES
			}
			
			trackMixpanelEvent(MPEventName.CUE_UPDATE, mixpanelProps)
			
			let cue = $scope.findCue($scope.cue_to_update);
			if (cue != null) {
				cue.position = updated_cue_data.position
			}

			$scope.cue_to_update = null;
			$scope.cue_error_msg = null;

		}).catch(() => {

			$scope.cue_error_msg = 'An error occurred while attempting to update the cue. Please try again, or report the problem if it persists.';

		}).finally(() => {

			$scope.is_working = false;

		});
	};

	$scope.enterDeleteCueMode = function (cue) {
		$scope.cue_to_add = null;
		$scope.cue_to_delete = cue;
	};

	$scope.cancelDeleteCue = function (){
		$scope.cue_to_delete = null;
		$scope.cue_error_msg = null;
	};

	$scope.deleteCue = function (){

		$scope.is_working = true;

		// NOTE: I normally would not include the "data: null", but IE11 and below seem to require it in order to work
		// see: https://github.com/angular/angular.js/issues/12141
		$http.delete(`${jcs.api.url_v3}/customers/${Authentication.getCurrentUser().customerID}/webevents/${$scope.web_event_to_watch.uuid}/cues/${$scope.cue_to_delete.uuid}`, { withCredentials: true, data: null }).then(() => {
			const mixpanelProps = {
				[MPEventProperty.CUE_NAME]: $scope.cue_to_delete.name,
				[MPEventProperty.TRANSCODED_EVENT_UUID]: $scope.web_event_to_watch.uuid
			}
			
			trackMixpanelEvent(MPEventName.CUE_DELETE, mixpanelProps)
			// use findCue to ensure we have a matching cue that is currently in $scope.cues list
			let cue = $scope.findCue($scope.cue_to_delete);
			if (cue != null) {
				// get the index of the cue so we can remove it from the cue list
				let index = $scope.cues.indexOf(cue);
				if (index > -1) {
					$scope.cues.splice(index, 1);
				}
			}

			$scope.cue_to_delete = null;
			$scope.cue_error_msg = null;

		}).catch(() => {

			$scope.cue_error_msg = 'An error occurred trying to create the cue. Please try again, or report the problem if it persists.';

		}).finally(() => {

			$scope.is_working = false;

		});
	};

	$scope.enterEditCueMode = function (cue){
		$scope.cue_to_add = null;
		$scope.cue_to_edit = cue;
		$scope.cue_to_edit.shared = cue.privateCue ? $scope.OPTION_NO : $scope.OPTION_YES;
	};

	$scope.cancelEditCue = function (){
		$scope.cue_to_edit = null;
		$scope.cue_error_msg = null;
	};

	$scope.editCue = function (){

		let data = {
			name: $scope.cue_to_edit.name,
			position: $scope.cue_to_edit.position,
		};

		// when editing a cue, only set the privateCue value if the user is able to share cues. Otherwise the UI doesn't display a editable
		// field and so we don't need to be changing this value.
		if ($scope.can_share_cues_web_event){
			data.privateCue = $scope.cue_to_edit.shared == $scope.OPTION_NO;
		}

		$scope.is_working = true;

		$http.patch(`${jcs.api.url_v3}/customers/${Authentication.getCurrentUser().customerID}/webevents/${$scope.web_event_to_watch.uuid}/cues/${$scope.cue_to_edit.uuid}`, data, { withCredentials: true }).then(() => {

			// use findCue to ensure we are updating a matching cue that is currently in $scope.cues list
			let cue = $scope.findCue($scope.cue_to_edit);
			if (cue != null) {
				cue.name = $scope.cue_to_edit.name;
				cue.position = $scope.cue_to_edit.position;
				cue.privateCue = $scope.cue_to_edit.shared == $scope.OPTION_NO;
				cue.user = $scope.cue_to_edit.user;
			}

			$scope.cue_to_edit = null;
			$scope.cue_error_msg = null;

		}).catch(() => {

			$scope.cue_error_msg = 'An error occurred trying to create the cue. Please try again, or report the problem if it persists.';

		}).finally(() => {

			$scope.is_working = false;

		});
	};

	$scope.enterAddCueMode = function (){
		$scope.cue_to_add = {
			name: '',
			position: '',
			shared: $scope.OPTION_YES
		};

		if ($scope.web_player != null){
			let video = $scope.web_player.getVideo();
			if (video != null){
				$scope.cue_to_add.position = $scope.convertSecondsToCuePosition(video.currentTime);
			}
		}
	};

	$scope.addCue = function (){

		let data = {
			name: $scope.cue_to_add.name,
			position: $scope.cue_to_add.position,
			privateCue: $scope.cue_to_add.shared == $scope.OPTION_NO,
		};

		$scope.is_working = true;

		$http.post(`${jcs.api.url_v3}/customers/${Authentication.getCurrentUser().customerID}/webevents/${$scope.web_event_to_watch.uuid}/cues`, data, { withCredentials: true }).then(() => {
			const mixpanelProps = {
				[MPEventProperty.CUE_NAME]: $scope.cue_to_add.name,
				[MPEventProperty.CUE_SHARED]: $scope.cue_to_add.shared === $scope.OPTION_YES,
				[MPEventProperty.TRANSCODED_EVENT_UUID]: $scope.web_event_to_watch.uuid
			}
			
			trackMixpanelEvent(MPEventName.CUE_ADD, mixpanelProps)
			
			$scope.cue_to_add = null;
			$scope.cue_error_msg = null;
			// reload our cues
			$scope.loadCuesForWatchedWebEvent();

		}).catch(() => {

			$scope.cue_error_msg = 'An error occurred trying to create the cue. Please try again, or report the problem if it persists.';

		}).finally(() => {

			$scope.is_working = false;

		});
	};

	$scope.cancelAddCue = function (){
		$scope.cue_to_add = null;
		$scope.cue_error_msg = null;
	};

	$scope.loadWebplayerForEvent = function (event) {

		const manifest_url = $scope.isiOSDevice ? event.cloudUrlHls : event.cloudUrlDash;

		// check to see if the manifest is ready; if not, then we'll want to try again in a bit
		$http.get(`https://${manifest_url}`).then(response => {

			$scope.web_event_webplayer_display_status = $scope.DISPLAY_STATUS_SHOW;

			const options = {
				chromeCastAppId: event.chromeCastAppId
			};

			webPlayerService.initializeByManifestUrl(
				$scope.WEB_PLAYER_WRAPPER_ID,
				event.cloudUrlDash,
				event.cloudUrlHls,
				function (webplayer, error) {
					$timeout(() => {
						if (webplayer == null) {

							$scope.error_msg = error;

						} else {

							$scope.web_player = webplayer;

							// currently we don't support showing cues on iOS devices (we are unable to do filtering using the <video> tag element info)
							// this is because iOS always seems to show the start time as zero, instead of the actual start position within the entire stream
							$scope.can_show_cues = $scope.web_player.canGetTimeRange();

							// load cues
							if ($scope.can_show_cues) {
								// choose an event listener that will fire once the video is going (and we can access the time range)
								$scope.web_player.getVideo().addEventListener('playing', $scope.initializeCueUpdates);
							}

						}
					});
				},
				options
			);

		}).catch(reason => {

			if ((reason.status === 403 || reason.status === 404) && event.status !== $scope.STATUS_STOPPED && event.status !== $scope.STATUS_ERROR && event.status !== $scope.STATUS_ABORTED){

				$scope.web_event_webplayer_display_status = $scope.DISPLAY_STATUS_NOT_YET_AVAILABLE;

				if ($scope.loading_webplayer_timeout_id !== null){
					window.clearTimeout($scope.loading_webplayer_timeout_id);
					$scope.loading_webplayer_timeout_id = null;
				}
				// manifest isn't ready yet, so try again shortly
				$scope.loading_webplayer_timeout_id = window.setTimeout(() => {
					$scope.loadWebplayerForEvent(event);
				}, $scope.CHECK_MANIFEST_AVAILABILITY_TIME_DELAY);

			} else {

				$scope.web_event_webplayer_display_status = $scope.DISPLAY_STATUS_ERROR;

			}
		});
	};

	$scope.enterWebEventWatchMode = function (event) {

		$scope.cues = null;
		$scope.visible_cue_count = 0;
		$scope.cue_time_range_filter = null;
		$scope.is_showing_cues = true;

		$scope.web_event_to_watch = event;

		// ensure we are scrolled to the top
		$(window).scrollTop(0);

		$scope.loadWebplayerForEvent(event);
	};

	$scope.showActivityIndicator = function (status) {
		return status == $scope.STATUS_STARTING || status == $scope.STATUS_STOPPING;
	};

	// instead of displaying "idle", lets say "starting"
	$scope.formatWebEventStatus = function (web_event) {
		if (web_event == null) {
			return '';
		}
		if (web_event.status == $scope.STATUS_IDLE) {
			return $scope.STATUS_STARTING;
		}
		if (web_event.status == $scope.STATUS_STARTED && webEventsService.isStopping(web_event.uuid)) {
			return $scope.STATUS_STOPPING;
		}
		return web_event.status;
	};

	// the web event list should update periodically to ensure the status is accurate. when a web event has just started (status=idle)
	// then we'll want to refresh the status more frequently.
	$scope.getWebEventStatusUpdateTimeInSec = function () {

		if ($scope.web_events != null) {
			for (let i = 0; i < $scope.web_events.length; i++) {
				let status = $scope.formatWebEventStatus($scope.web_events[i]);
				// transitional states will use a quick update time
				if (status == $scope.STATUS_STARTING || status == $scope.STATUS_STOPPING){
					return $scope.CHECK_WEB_EVENT_STATUS_TIME_DELAY_SHORT;
				}
			}
		}
		return $scope.CHECK_WEB_EVENT_STATUS_TIME_DELAY_LONG; // otherwise a longer update time is sufficient
	};

	$scope.loadEvents = function () {

		// retrieve web events (if user has permission)
		if (Authentication.getCurrentUser().hasPerm('web_events.get')) {

			$scope.is_loading = true;
			$scope.error_msg = null;

			// check to see if we have a scheduled timeout
			if ($scope.loading_web_events_timeout_id != null) {
				// if so, then clear it (we'll check the web event status down below and set a timeout if necessary)
				window.clearTimeout($scope.loading_web_events_timeout_id);
				$scope.loading_web_events_timeout_id = null;
			}

			eventService.loadWebEvents().then(response => {

				$scope.web_events = response;
				// remove any stopped web events from our web events stopping list
				$scope.updateWebEventsThatAreStopping($scope.web_events);

				// setup timeout so our web event list will auto refresh
				$scope.loading_web_events_timeout_id = window.setTimeout($scope.loadWebEventsSilent, $scope.getWebEventStatusUpdateTimeInSec());

			}).catch(() => {

				$scope.error_msg = 'An error occurred while attempting to retrieve the web events. Please try again, or report the problem if it persists.';
				$scope.web_events = null;

			}).finally(() => {

				$scope.is_loading = false;

			});
		}
	};

	$scope.findMatchingWebEvent = function (event){
		if ($scope.web_events != null){
			for (let i = 0; i < $scope.web_events.length; i++) {
				let web_event = $scope.web_events[i];
				if (web_event.uuid == event.uuid) {
					return web_event;
				}
			}
		}
		return null;
	};

//	$scope.findMatchingSimulcast = function (web_event, simulcast_uuid){
//		if (web_event.simulcasts != null && web_event.simulcasts.length > 0){
//			for (let i=0; i < web_event.simulcasts.length; i++){
//				let simulcast = web_event.simulcasts[i];
//				if (simulcast.uuid == simulcast_uuid){
//					return simulcast;
//				}
//			}
//		}
//		return null;
//	};

	$scope.updateWatchedOrViewedWebEvent = function (){

		if ($scope.web_event_to_watch != null){

			let match = $scope.findMatchingWebEvent($scope.web_event_to_watch);
			if (match != null){
				// update web event status
				$scope.web_event_to_watch.status = match.status;
				// update status of each simulcast
//				if ($scope.web_event_to_watch.simulcasts != null && $scope.web_event_to_watch.simulcasts.length > 0){
//					for (let i=0; i < $scope.web_event_to_watch.simulcasts.length; i++){
//						let matching_simulcast = $scope.findMatchingSimulcast(match, $scope.web_event_to_watch.simulcasts[i].uuid);
//						$scope.web_event_to_watch.simulcasts[i].status = matching_simulcast.status;
//					}
//				}
			}

		}
	};

	$scope.loadWebEventsSilent = function () {

		// retrieve web events (if user has permission)
		if (Authentication.getCurrentUser().hasPerm('web_events.get')) {

			if (!uiService.isMenuOpen()){

				// check to see if we have a scheduled timeout
				if ($scope.loading_web_events_timeout_id != null) {
					// if so, then clear it (we'll check the web event status down below and set a timeout if necessary)
					window.clearTimeout($scope.loading_web_events_timeout_id);
					$scope.loading_web_events_timeout_id = null;
				}

				eventService.clearWebCache();
				eventService.loadWebEvents().then(response => {

					$scope.error_msg = null;
					$scope.web_events = response;
					// remove any stopped web events from our web events stopping list
					$scope.updateWebEventsThatAreStopping($scope.web_events);
					// update our watched or viewed web event info
					$scope.updateWatchedOrViewedWebEvent();

				}).catch(() => {
					// don't show error for the "silent" load since the user didn't initiate the action. We'll just setup another timeout in the
					// finally clause in the hope that the error was a one-time thing and the upcoming check will work.
				}).finally(() => {

					// setup timeout so our web event list will auto refresh
					$scope.loading_web_events_timeout_id = window.setTimeout($scope.loadWebEventsSilent, $scope.getWebEventStatusUpdateTimeInSec());

				});

			} else {

				// setup timeout so our web event list will auto refresh
				$scope.loading_web_events_timeout_id = window.setTimeout($scope.loadWebEventsSilent, $scope.getWebEventStatusUpdateTimeInSec());
			}
		}

	};

	$scope.initWebEvent = function (web_event_id, success_func){

		$scope.is_loading = true;

		// fetch data for the specified web event
		eventService.getWebEvent(web_event_id).then(response => {

			if (success_func){
				success_func(response);
			}

		}).catch(() => {

			$scope.error_msg = 'An error occurred while attempting to retrieve the web event. Please try again, or report the problem if it persists.';
			$scope.web_events = null;

		}).finally(() => {

			$scope.is_loading = false;

		});
	};

	//
	// initialize
	//

	// if we are coming from another page/module in control, then lets clear our cache to ensure we are working with fresh data.
	// only if we are moving between pages within the events module do we want to keep the cache.
	if(!$rootScope.isPrevPathFromSameModule(campusreviewModule.routes.list)){
		eventService.clearCache();
	}

	// check to see if watching web event
	if (typeof $routeParams.watchwebeventid !== 'undefined') {

		$scope.initWebEvent($routeParams.watchwebeventid, function(response){
			// if we don't use $timeout, then for some reason loading our webplayer can't find the proper DIV (like it isn't loaded yet).
			$timeout(function(){
				$scope.enterWebEventWatchMode(response);
			});
		});
		// update events silently in background (which will also update the status of this event)
		$scope.loading_web_events_timeout_id = window.setTimeout($scope.loadWebEventsSilent, $scope.getWebEventStatusUpdateTimeInSec());

	} else {

		$scope.loadEvents();
	}

	// build our tooltips
	$timeout(function () {
		$('[data-toggle="tooltip"]').tooltip(); // needs to be done in timeout, otherwise for some reason the tooltip gets built before angular does it's magic
	});

	// if the video player has been created, then we need to make sure it is cleaned up if the user changes to another page.
	// we need to clean up the web player if the user changes to another page. When they change back to this page, all the
	// html will be reloaded, so all the webplayer UI will need to be rebuilt.
	$scope.$on('$locationChangeSuccess', function () {
		console.log('Unloading web player');
		webPlayerService.unload($scope.WEB_PLAYER_WRAPPER_ID);
		$scope.web_player = null;

		// clear any active timers we might have
		if ($scope.loading_web_events_timeout_id != null) {
			window.clearTimeout($scope.loading_web_events_timeout_id);
			$scope.loading_web_events_timeout_id = null;
		}
		if ($scope.update_cue_visibility_interval_id != null) {
			window.clearInterval($scope.update_cue_visibility_interval_id);
			$scope.update_cue_visibility_interval_id = null;
		}
		if ($scope.update_cues_interval_id != null) {
			window.clearInterval($scope.update_cues_interval_id);
			$scope.update_cues_interval_id = null;
		}
	});

}

module.exports = CampusReviewController;
