import { Dispatch } from 'react';

export interface LibraryThumbnailPreviewState {
  imageBroken: boolean;
  showImage: boolean;
  showAnimate: boolean;
}
export interface LibraryThumbnailPreviewProps {
  imageUrl?: string;
  showDelete?: boolean;
  onClickDelete?: () => void;
}
export interface Action {
  type: ACTION_TYPE;
  payload?: any;
}

export enum ACTION_TYPE {
  BROKEN = 'BROKEN',
  DISPLAY_IMAGE = 'DISPLAY_IMAGE',
  SHOW_ANIMATE = 'SHOW_ANIMATE',
}

export function reducer(state: LibraryThumbnailPreviewState, action: Action): LibraryThumbnailPreviewState {
  switch (action.type) {
    case ACTION_TYPE.BROKEN:
      return { ...state, imageBroken: action.payload };
    case ACTION_TYPE.DISPLAY_IMAGE:
      return { ...state, showImage: action.payload };
    case ACTION_TYPE.SHOW_ANIMATE:
      return { ...state, showAnimate: action.payload };
    default:
      throw new Error('Invalid Action Type: ' + action.type);
  }
}
