import angular from 'angular';
import { authModule } from './index';
import { encodersModule } from '../encoders';
import { Authorized } from './services';
import { socialmediaModule } from '../socialmedia/index';

export default angular.module('auth').run([
	'$rootScope',
	'$location',
	'$window',
	'socialMediaAuth',
	'Authorization',
	'Authentication',
	($rootScope, $location, $window, socialMediaAuth, Authorization, Authentication) => {
		'ngInject';
		var routeChangeRequiredAfterLogin = false,
			loginRedirectUrl;

		// If user isn't logged in and opens up a tab with something like
		// http://localhost:3000/events/web/edit/6192cb40-c161-4d2c-90be-b38036729d99
		// the next.originalPath variable will actually be
		// http://localhost:3000/events/web/edit/:editwebeventid
		// ... with pathParam saved in another variable.
		// So we will need to rebuild the URL ourselves. Solution below is from
		// https://stackoverflow.com/questions/31434246/url-from-routechangestart-route-params-in-angularjs-routes
		// ... with slight modifications.
		function buildPathFromRoute(routeObj)
		{
			var path = routeObj.originalPath;
			for (var property in routeObj.pathParams)
			{
				if (routeObj.pathParams.hasOwnProperty(property))
				{
					var regEx = new RegExp(":" + property, "gi");
					path = path.replace(regEx, routeObj.pathParams[property].toString());
				}
			}
			return path;
		}

		$rootScope.$on('$routeChangeStart', (event, next) => {
			// determine if we have been redirected from YouTube.
			socialMediaAuth.init();
			if (socialMediaAuth.isRedirectedFromYoutube()) {
				// save off youtube info because we'll need it after we reload to our social media page
				socialMediaAuth.saveYoutubeInfo();

				// reload to our social media page (which will clean up our url)
				// couldn't figure out a way to do this using history.replaceState or angular $window or $location objects, so
				// just decided best way would be to simply reload the page (after saving auth data off to a cookie)
				var target_url = $window.location.origin + socialmediaModule.routes.list;
				$window.location.href = target_url;
			} else if (socialMediaAuth.isRedirectedFromFacebook()) {
				// save off facebook info because we'll need it after we reload to our social media page
				socialMediaAuth.saveFacebookInfo();

				// reload to our social media page (which will clean up our url)
				// couldn't figure out a way to do this using history.replaceState or angular $window or $location objects, so
				// just decided best way would be to simply reload the page (after saving auth data off to a cookie)
				var target_url = $window.location.origin + socialmediaModule.routes.list;
				$window.location.href = target_url;
			}

			// are we heading to the login page? If so, check to see if the user is already logged in. If they are, then redirect to the encoders list page.
			if (next.originalPath === authModule.routes.login) {
				if (Authentication.getCurrentLoginUser()) {
					$location.path(encodersModule.routes.list);
				}
			}

			let authorised;
			// only use the loginRedirectUrl if the user is currently logged in. We had some authentication issues when a user was logged out
			// but was using a bookmark, where we still show them the page. You can reproduce the same thing by simply pasting a URL (such as
			// the events page) into the address bar. The first time you will see the login page, and then the 2nd time you will see the actual
			// events list (but without any sidebar nav). So the system thought the 2nd time thru was a redirect after logging in. Adding a check
			// to ensure the user is logged in before using the loginRedirectUrl fixes that issue.
			if (
				routeChangeRequiredAfterLogin &&
				next.originalPath !== authModule.routes.login &&
				Authentication.getCurrentLoginUser()
			) {
				routeChangeRequiredAfterLogin = false;
				$location.path(loginRedirectUrl).replace();
			} else if (next.access !== undefined) {
				authorised = Authorization.authorize(
					next.access.loginRequired,
					next.access.permissions,
					next.access.permissionCheckType
				);
				if (authorised === Authorized.LOGIN_REQUIRED) {
					routeChangeRequiredAfterLogin = true;
					loginRedirectUrl = buildPathFromRoute(next);
					$location.path(authModule.routes.login);
				} else if (authorised === Authorized.NOT_AUTHORIZED) {
					$location.path(authModule.routes.notAuthorised).replace();
				}
			}
		});

		$rootScope.$on('$routeChangeSuccess', function (event, current, previous) {
			if (current.$$route != undefined) {
				$rootScope.activenav = current.$$route.activenav;
			}
		});
	},
]).name;
