'use strict';

const jcs = require('../../jcs');
const moment = require('moment');
const { trackMixpanelEvent, MPEventProperty, MPEventName} = require('../../../../src/mixpanel');

// WEB PLAYER NOTES
// So I was originally going to remove all the video player code from this page and just use our webPlayerService instead.
// But this video player has some special features that may take some time to add.
// - need to be able to register a callback for when the video is loaded. Then the video width/height can be examined in order
//   to determine if we are dealing with a dual video or not (which effects some styling that we do).
// - need to be able to optionally remove the fullscreen button (not an immediate need since users can't see this video yet)
// - need to be able to disable auto playback on page load
// So perhaps the user can pass some options during initialization. Then a method like checkCanUseFullscreen (and others) would
// make use of those options.

function CuesController($scope, $http, $routeParams, $window, $timeout, Authentication, uiService, cueService, $rootScope) {
	'ngInject';

	$(window).trigger('resize'); // ensure footer is properly positioned
	var shaka;

	$scope.isiOSDevice = uiService.isiOSDevice();

	$scope.CUE_MILLISECOND_DIGITS = 3;

	$scope.stream_id = $routeParams.streamID;
	$scope.event_id = $routeParams.eventID;

	// event
	$scope.event = null;
	$scope.is_loading_event = false;
	$scope.event_error_msg = null;

	// video player
	$scope.video_player = null;
	$scope.video = null;
	$scope.bitrate_values = []; // currently we use video height
	$scope.BITRATE_AUTO = 'Auto';
	$scope.selected_bitrate = $scope.BITRATE_AUTO;
	$scope.show_bitrates_menu = false;
	$scope.default_abr_restrictions = null;

	$scope.getCurrentUser = function () {
		return Authentication.getCurrentUser();
	};

	$scope.showDateTimeAsLocal = function (dateTimeToConvert) {
		return moment(dateTimeToConvert).format('ddd, MMM D, YYYY h:mm:ss A');
	};

	$scope.getVideoCurrentPosition = function (){
		if ($scope.video != null){
			return $scope.convertSecondsToCuePosition($scope.video.currentTime);
		}
		return '00:00:00.000';
	};

	$scope.getVideoSeekRange = function () {
		if ($scope.video_player !== null && !$scope.showVideoErrorMsg){
			return $scope.video_player.seekRange();
		}
		return null;
	};

	$scope.loadEvent = async function () {

		$scope.is_loading_event = true;
		$scope.event = null;
		$scope.event_error_msg = null;

		// fetch details about the event we are loading
		try {
			const response = await $http.get(`${jcs.api.url}/streamprofiles/${$routeParams.streamID}/events/${$scope.event_id}`, { withCredentials: true, })
			$scope.event = response.data;
		} catch(e) {
			console.error(e);
			$scope.event_error_msg = 'An error occurred while attempting to retrieve the event information. Please try again, or report the problem if it persists.';
		}

		$scope.is_loading_event = false;
	};

	//
	// Video Player Methods
	// This code is based off the Shaka Player Demo. See: https://shaka-player-demo.appspot.com/
	// The default controls did not seem to handle live video well. See the following page for description
	// why player times can look very odd: http://v1-6-2.shaka-player-demo.appspot.com/docs/tutorial-live.html
	// Because of that we wrote our own player controls using the demo as an example.
	//

	$scope.isSeeking = false;
	$scope.isVideoPlaying = false;
	$scope.isVideoBuffering = false;
	$scope.seekBarEl = null;
	$scope.currentTimeEl = null;
	$scope.volumeBarEl = null;
	$scope.giantPlayButtonContainerEl = null;
	$scope.videoPlayerIntervalId = null;
	$scope.originalPlayState = false;
	$scope.showVideoErrorMsg = false;
	//        $scope.videoStatusMessage = null;

	// originally we were using a longer message (to better explain what was going on), but since our timeout is so short, there was
	// no way to read the message. So for now will just use a shortened message to avoid any confusion.
	//        $scope.CHECKING_LAN_FOR_VIDEO_MSG = "Loading ...";//"Checking to see if video is located on Local Area Network. This may take a moment ...";
	//        $scope.LAN_TIMEOUT = 300; // milliseconds

	$scope.onErrorEvent = function (event) {
		console.error('Error code', error.code, 'object', error);
	};

	$scope.onLoadError = function (error) {
		console.error('Error code', error.code, 'object', error);
		console.log(
			'A problem with the video was encountered. (Category = ' + error.category + ' Code = ' + error.code + ')'
		);
		$scope.showVideoErrorMsg = true;
		window.setTimeout($scope.onMyResize, 50);
		$scope.$apply();
	};

	$scope.videoPlayerPlayPause = function (event) {
		if (!$scope.video || !$scope.video.duration) {
			// video isn't loaded yet, so ignore
			return;
		}

		if ($scope.video.paused) {
			$scope.video.play();
		} else {
			$scope.video.pause();
		}
	};

	$scope.onSeekStart = function (event) {
		$scope.originalPlayState = $scope.isVideoPlaying;
		$scope.isSeeking = true;
		$scope.video.pause();
	};

	$scope.onSeekInput = function (event) {
		if (!$scope.video.duration) {
			// can't seek yet
			return;
		}
		// update the UI right away
		$scope.updateTimeAndSeekBar();
	};

	$scope.onSeekEnd = function (event) {
		$scope.video.currentTime = parseFloat($scope.seekBarEl.value);
		$scope.isSeeking = false;

		if ($scope.originalPlayState) {
			$scope.video.play();
		}
	};

	$scope.updateTime = function () {
		var displayTime = $scope.isSeeking ? $scope.seekBarEl.value : $scope.video.currentTime;

		var seekRange = $scope.video_player.seekRange();
		var seekRangeSize = seekRange.end - seekRange.start;
		var showHour = seekRangeSize >= 3600;

		if ($scope.video_player.isLive()) {
			// The amount of time we are behind the live edge.
			var behindLive = Math.floor(seekRange.end - displayTime);
			displayTime = Math.max(0, behindLive);

			// Consider "LIVE" when less than 1 second behind the live-edge.  Always
			// show the full time string when seeking, including the leading '-';
			// otherwise, the time string "flickers" near the live-edge.
			if (displayTime >= 1 || $scope.isSeeking) {
				$scope.currentTimeEl.textContent = '- ' + $scope.buildTimeString(displayTime, showHour);
				//            $scope.currentTimeEl.style.cursor = 'pointer';
			} else {
				$scope.currentTimeEl.textContent = 'LIVE';
				//            $scope.currentTimeEl.style.cursor = '';
			}
		} else {
			$scope.currentTimeEl.textContent = $scope.buildTimeString(displayTime, showHour);
			//        $scope.currentTimeEl.style.cursor = '';
		}
	};

	$scope.updateSeekBar = function () {
		var seekRange = $scope.video_player.seekRange();
		$scope.seekBarEl.min = seekRange.start;
		$scope.seekBarEl.max = seekRange.end;

		if (!$scope.isSeeking) {
			$scope.seekBarEl.value = $scope.video.currentTime;
		}

		var seekRangeSize = seekRange.end - seekRange.start;
		// NOTE: the fallback to zero eliminates NaN.
		var playheadFraction = $scope.video.currentTime / seekRangeSize || 0;
		var currentPosFraction = $scope.seekBarEl.value / seekRangeSize || 0;

		// we always first draw light gray up to our current position (the seek bar handle). Then after that
		// we may draw our playhead position (in a darker gray). And then from then to the end is black. Because
		// we always draw in that order, we need to ensure the playheadFraction is always >= currentPosFraction.
		playheadFraction = Math.max(playheadFraction, currentPosFraction);

		// https://css-tricks.com/css3-gradients/
		var gradient = ['to right'];
		// 1) draw light gray up to the seek bar handle
		gradient.push('#ccc');
		gradient.push('#ccc ' + currentPosFraction * 100 + '%');
		// 2) draw darker gray up to the playhead position (which sometimes may be farther along than the handle)
		gradient.push('#444 ' + currentPosFraction * 100 + '%');
		gradient.push('#444 ' + playheadFraction * 100 + '%');
		// 3) draw black to the end of the bar
		gradient.push('#000 ' + playheadFraction * 100 + '%');

		$scope.seekBarEl.style.background = 'linear-gradient(' + gradient.join(',') + ')';
	};

	$scope.updateTimeAndSeekBar = function () {
		$scope.updateTime();
		$scope.updateSeekBar();
	};

	$scope.onBufferingStateChange = function (event) {
		$scope.isVideoBuffering = event.buffering;
		$scope.$apply();
	};

	$scope.onMuteClick = function () {
		if (!$scope.video || !$scope.video.duration) {
			// video isn't loaded yet, so ignore
			return;
		}
		$scope.video.muted = !$scope.video.muted;
	};

	$scope.onPlayStateChange = function () {
		// Video is paused during seek, so don't show the play arrow while seeking
		if ($scope.video.paused && !$scope.isSeeking) {
			$scope.isVideoPlaying = false;
			//                $scope.giantPlayButtonContainerEl.style.display = 'inline';
		} else {
			$scope.isVideoPlaying = true;
			//                $scope.giantPlayButtonContainerEl.style.display = 'none';
		}
		$scope.$apply();
	};

	$scope.onVolumeStateChange = function () {
		if ($scope.video.muted) {
			$scope.volumeBarEl.value = 0;
		} else {
			$scope.volumeBarEl.value = $scope.video.volume;
		}

		var gradient = ['to right'];
		gradient.push('#ccc ' + $scope.volumeBarEl.value * 100 + '%');
		gradient.push('#000 ' + $scope.volumeBarEl.value * 100 + '%');
		gradient.push('#000 100%');
		$scope.volumeBarEl.style.background = 'linear-gradient(' + gradient.join(',') + ')';
	};

	$scope.onVolumeInput = function () {
		$scope.video.volume = parseFloat($scope.volumeBarEl.value);
		$scope.video.muted = false;
	};

	$scope.buildTimeString = function (displayTime, showHour) {
		var h = Math.floor(displayTime / 3600);
		var m = Math.floor((displayTime / 60) % 60);
		var s = Math.floor(displayTime % 60);
		if (s < 10) s = '0' + s;
		var text = m + ':' + s;
		if (showHour) {
			if (m < 10) text = '0' + text;
			text = h + ':' + text;
		}
		return text;
	};

	$scope.gotoCue = function (cue) {
		if ($scope.video != null) {
			var positionInSeconds = cueService.getCuePositionInSeconds(cue.position);
			$scope.video.currentTime = positionInSeconds;
		}

		const mixpanelProps = {
			[MPEventProperty.CUE_NAME]: cue.name,
			[MPEventProperty.TRANSCODED_EVENT_UUID]: $scope.event_id
		}

		trackMixpanelEvent(MPEventName.CUE_GOTO, mixpanelProps)
	};

	$scope.convertSecondsToCuePosition = function (seconds) {
		if (seconds > 3600) {
			var hours = parseInt(seconds / 3600);
			var remainingSeconds = seconds % 3600;
			var minutes = parseInt(remainingSeconds / 60);
			remainingSeconds = remainingSeconds % 60;
			remainingSeconds = remainingSeconds.toFixed($scope.CUE_MILLISECOND_DIGITS);
			return (
				$scope.getTimeWithLeadingZero(hours) +
				':' +
				$scope.getTimeWithLeadingZero(minutes) +
				':' +
				$scope.getTimeWithLeadingZero(remainingSeconds)
			);
		} else if (seconds > 60) {
			var minutes = parseInt(seconds / 60);
			var remainingSeconds = seconds % 60;
			remainingSeconds = remainingSeconds.toFixed($scope.CUE_MILLISECOND_DIGITS);
			return '00:' + $scope.getTimeWithLeadingZero(minutes) + ':' + $scope.getTimeWithLeadingZero(remainingSeconds);
		} else {
			seconds = seconds.toFixed($scope.CUE_MILLISECOND_DIGITS);
			return '00:00:' + $scope.getTimeWithLeadingZero(seconds);
		}
	};

	$scope.getTimeWithLeadingZero = function (time) {
		if (time >= 10) return time;
		return '0' + time;
	};

	$scope.doesLanUrlExist = function (lanUrl) {
		$http.head(lanUrl, { withCredentials: true }).then(
			function (response) { // success

				return true;
			},
			function () { // error

				return false;
			}
		);
	};

	$scope.loadVideoPlayerWithUrl = function (manifest_url) {
		// Install built-in polyfills to patch browser incompatibilities.
		shaka.polyfill.installAll();

		// Check to see if the browser supports the basic APIs Shaka needs.
		if (shaka.Player.isBrowserSupported()) {
			// Create a Player instance.
			$scope.video = document.getElementById('video');
			$scope.video_player = new shaka.Player($scope.video);

			// Attach player to the window to make it easy to access in the JS console.
			window.player = $scope.video_player;

			$scope.video_player.addEventListener('buffering', $scope.onBufferingStateChange.bind(this));

			$scope.video.addEventListener('play', $scope.onPlayStateChange.bind(this));
			$scope.video.addEventListener('pause', $scope.onPlayStateChange.bind(this));
			$scope.video.addEventListener('volumechange', $scope.onVolumeStateChange.bind(this));

			$scope.currentTimeEl = document.getElementById('currentTime');
			$scope.seekBarEl = document.getElementById('seekBar');
			$scope.seekBarEl.addEventListener('mousedown', $scope.onSeekStart.bind(this));
			$scope.seekBarEl.addEventListener('touchstart', $scope.onSeekStart.bind(this));
			$scope.seekBarEl.addEventListener('input', $scope.onSeekInput.bind(this));
			$scope.seekBarEl.addEventListener('touchend', $scope.onSeekEnd.bind(this));
			$scope.seekBarEl.addEventListener('mouseup', $scope.onSeekEnd.bind(this));

			$scope.volumeBarEl = document.getElementById('volumeBar');
			$scope.volumeBarEl.addEventListener('input', $scope.onVolumeInput.bind(this));

			$scope.giantPlayButtonContainerEl = document.getElementById('giantPlayButtonContainer');

			var aspectRatioEl = document.getElementById('aspectRatio');

			// Listen for error events.
			$scope.video_player.addEventListener('error', $scope.onErrorEvent);

			$scope.videoPlayerIntervalId = window.setInterval($scope.updateTimeAndSeekBar.bind(this), 125);

			// determine video width. we'll need to allocate more space for dual videos.
			$scope.video.addEventListener(
				'loadedmetadata',
				function (e) {
					if ($scope.isDualVideo()) {
						$('#videoContainerWrapper')
							.removeClass('col-lg-6')
							.addClass('col-lg-9');
						$('#eventInfoSection')
							.removeClass('col-lg-6')
							.addClass('col-lg-3');
						aspectRatioEl.style['padding-top'] = '28.125%';
					} else {
						$('#videoContainerWrapper')
							.removeClass('col-lg-9')
							.addClass('col-lg-6');
						$('#eventInfoSection')
							.removeClass('col-lg-3')
							.addClass('col-lg-6');
						aspectRatioEl.style['padding-top'] = ''; // use value in css file
					}
				},
				false
			);

			// Try to load a manifest. This is an asynchronous process.
			$scope.video_player
				.load(manifest_url)
				.then(function () {
					// This runs if the asynchronous load is successful.
					//                    console.log('The video was successfully loaded using ' + manifest_url);
					$scope.onPlayStateChange();
					$scope.onVolumeStateChange();
					window.setTimeout($scope.onMyResize, 50);

					// save off default Shaka ABR Settings
					var config = $scope.video_player.getConfiguration();
					$scope.default_abr_restrictions = {
						abr: {
							restrictions: config.abr.restrictions,
						},
					};

					// initialize our available bitrate options
					$scope.initBitrateValues();
				})
				.catch($scope.onLoadError); // onLoadError is executed if the asynchronous load fails.
		} else {
			// This browser does not have the minimum set of APIs we need.
			$scope.showVideoErrorMsg = true;
			window.setTimeout($scope.onMyResize, 50);
			console.error('Browser not supported!');
		}
	};

	$scope.toggleShowBitrates = function () {
		$scope.show_bitrates_menu = !$scope.show_bitrates_menu;
	};

	$scope.selectBitrate = function (bitrate) {
		if ($scope.selected_bitrate != bitrate) {
			$scope.selected_bitrate = bitrate;
			$scope.show_bitrates_menu = false;

			// update the video player's ABR restriction config
			if (bitrate == $scope.BITRATE_AUTO) {
				console.log('ABR on auto');
				$scope.video_player.configure($scope.default_abr_restrictions);
			} else {
				console.log('ABR restricted to ' + bitrate + 'p');
				// need to use parseInt, otherwise "+" will just perform a string concat
				var new_max_height = parseInt(bitrate) + parseInt(1);
				var new_min_height = bitrate - 1;

				var updated_config = {
					abr: {
						restrictions: {
							maxHeight: new_max_height,
							minHeight: new_min_height,
						},
					},
				};

				$scope.video_player.configure(updated_config);
			}
		}
	};

	$scope.initBitrateValues = function () {
		$scope.bitrate_values = [];
		var tracks = $scope.video_player.getVariantTracks();
		for (var i = 0; i < tracks.length; i++) {
			var track = tracks[i];
			if ($scope.bitrate_values.indexOf(track.height) == -1) $scope.bitrate_values.push(track.height);
		}
		// ensure height options are in proper order
		$scope.bitrate_values.sort(function (a, b) {
			return b - a; // high to low
			//return a - b; // low to high
		});
		// add our auto option
		$scope.bitrate_values.push($scope.BITRATE_AUTO);
	};

	$scope.isDualVideo = function () {
		var width = $scope.video.videoWidth;
		var height = $scope.video.videoHeight;
		if (width > 0 && height > 0) {
			var ratio = width / height;
			// a single video at 16x9 will return 1.77; dual 16x9 video will return 3.55
			// dual video at 4x3 would be 2.66. So will use value just under that.
			return ratio > 2.6;
		}
		return false;
	};

	$scope.scrubUrl = function (urlToScrub) {
		// replaces "http://storage.googleapis.com" with "//storage.googleapis.com"
		return urlToScrub.replace(/^https?\:/i, '');
	};

	// if event has lanUrls, we check to see if we can reach them first. If not, then we'll use the event's cloudUrl.
	$scope.determineManifestUrl = function (eventInfo) {
		// NOTE: it looks like we may not be able to load our lanUrls (since they are not https), so cloudUrl
		// may be our only option
		var manifest_url = $scope.scrubUrl(eventInfo.cloudUrl);
		if ($scope.endsWith(manifest_url, '.isml/Manifest')) {
			console.log('Only DASH videos are supported');
			$scope.showVideoErrorMsg = true;
			window.setTimeout($scope.onMyResize, 50);
		} else {
			$scope.loadVideoPlayerWithUrl(manifest_url);
		}
	};

	$scope.initVideoPlayer = function () {
		// assign the 'scrollable-cues' class to our list
		$('#scrollable-cues').addClass('scrollable-cues');

		// only load the video player javascript (since it is kinda large) if the user needs it. If someone
		// isn't watching videos (or doesn't have perm) then we don't want them to waste bandwidth fetching it.
		import(
      /* webpackChunkName: "shaka" */
      `../../../plugins/webplayer/shaka-player-${jcs.shaka.v30}.compiled.js`
    ).then((module) => {
      shaka = module.default;

			if($routeParams.streamID) {
				$http
					.get(jcs.api.url + '/streamprofiles/' + $routeParams.streamID + '/events/' + $scope.event_id, {
						withCredentials: true,
					})
					.then(
						function (response) {
							// success

							$scope.determineManifestUrl(response.data);
						},
						function () {
							// error
						}
					)
					['finally'](function () {
						// always called
					});
				} else {
					console.log('ERROR: invalid streamID: ' + $routeParams.streamID);
				}
    });
	};

	// resize callback used to size the cues list
	$scope.onMyResize = function () {

		// calculate how much space should be allocated to the cues list
		var window_height = $(window).height();
		var video_height = $('#videoContainer').height();
		var controls_height = $('#controlsContainer').height();

		var misc_height =
			50 + // navbar
			41 + // breadcrumb area
			15 + // content padding
			20 + // margin between cues & video player
			15; // padding below video player (otherwise it'll show up right at the bottom edge)

		var cue_list_height = window_height - video_height - controls_height - misc_height;
		$('.scrollable-cues').css('max-height', cue_list_height);
	};

	// endsWith polyfill (used to check proper file extension)
	// based on: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/endsWith
	$scope.endsWith = function (origStr, searchStr, Position) {
		// This works much better than >= because it compensates for NaN:
		if (!(Position < origStr.length)) Position = origStr.length;
		else Position |= 0; // round position
		return origStr.substr(Position - searchStr.length, searchStr.length) === searchStr;
	};

	//
	// initialize by loading our event
	//
	$scope.loadEvent();

	//
	// load video player
	//
	$scope.initVideoPlayer();

	// set our resize callback
	angular.element($window).on('resize', $scope.onMyResize);

	$timeout(function () {
		// regular tooltips
		$('[data-toggle="tooltip"]').tooltip(); // needs to be done in timeout, otherwise for some reason the tooltip gets built before angular does it's magic
		// build tooltips requiring html
		$('.unable-to-share-tooltip').tooltip({
			template: '<div class="tooltip" role="tooltip"><div class="tooltip-arrow"></div><div class="tooltip-inner tooltip-inner-wide tooltip-inner-left"></div></div>',
			placement: 'top',
			title: '<div class="tooltip-section">You are not allowed to share cues because you have not been granted that permission for this event. While you can still create cues, they will only be accessible to you and will not be visible to other users.</div>In order to share cues, you will need to find the encoder event profile that was used to create this event, and select "User Access". You will then need to ensure that your user has been added to the user access list and granted permission to share cues.',
			html: true,
		});
		$('.encoder-upload-issue-tooltip').tooltip({
			template: '<div class="tooltip" role="tooltip"><div class="tooltip-arrow"></div><div class="tooltip-inner tooltip-inner-wide tooltip-inner-left"></div></div>',
			placement: 'right',
			title: '<div class="tooltip-section">Video playback is delayed by approximately 5-10 seconds from live. If the Encoder experiences upload issues, you may experience buffering or pausing.</div>If the Encoder has a substantial delay uploading, this preview may take excessive time to load. (i.e. the Encoder is 5 minutes behind on upload, staying on the page for 5 minutes would allow the preview to load properly.)',
			html: true,
		});
	});

	// if the video player has been created, then we need to make sure it is cleaned up if the user changes to another page.
	$scope.$on('$locationChangeSuccess', function () {
		if ($scope.video_player != null) {
			$scope.video_player.destroy();
			$scope.video_player = null;
		}
		if ($scope.videoPlayerIntervalId != null) {
			clearInterval($scope.videoPlayerIntervalId);
			$scope.videoPlayerIntervalId = null;
		}

		// remove our resize callback
		angular.element($window).off('resize', $scope.onMyResize);
	});
}

module.exports = CuesController;
