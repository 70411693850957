import { perms } from '../../jcs';
import { unitdashboardModule } from './index';

function routeConfig($provide, $routeProvider) {
	'ngInject';

	$provide.factory('$routeProvider', () => {
		return $routeProvider;
	});

	$routeProvider.when(unitdashboardModule.routes.list, {
		controller: unitdashboardModule.controllers.default,
		template: require('./unitdashboard.tmpl.html').default,
		activenav: unitdashboardModule.name,
		access: {
			loginRequired: true,
			permissions: [perms['hardware_units.dashboard']],
		},
	});
}

export default routeConfig;
