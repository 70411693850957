import React, { FC, useContext, useEffect, useState } from 'react';
import { YoutubeContext } from '../../../../contexts/eventanalytics';
import { barChartDataType, doughnutChartDataType } from '../../types';
import { API_RESPONSE_DIFFS, GROUP_VALUES, LEGEND_NAMES } from '../../event-analytics-constants';
import { EventAnalyticsContext } from '../../../../contexts/eventanalytics/event-analytics';
import YoutubeSegmentedStatsView from './youtube-segmented-stats-view';
import { fetchDeviceTypeData } from '../api';
import { getBarChartData, getDoughnutChartData } from '../../utils';

const YoutubeSegmentedStats: FC = (): JSX.Element => {
  const { youtubeAnalyticsState: {groupBy} } = useContext(YoutubeContext);
  const { EventAnalyticsState } = useContext(EventAnalyticsContext);
  const [isChartLoading, setChartLoading] = useState<boolean>(false);
  const [chartData, setChartData] = useState<doughnutChartDataType[] | barChartDataType >({ labels: [], extraInfo: [], datasets: {} });

  const getData = async () => {
    setChartLoading(true);
      const response = await fetchDeviceTypeData(EventAnalyticsState, groupBy);
      if (groupBy === GROUP_VALUES.EVENT_TYPE){
        setChartData(getBarChartData({
          responseObject: response,
          countriesList: {},
          regionsList: {},
          chartName: API_RESPONSE_DIFFS.DEVICE_TYPE,
        }))
      } else {
        setChartData(getDoughnutChartData({
          responseObject: response,
          countriesList: {},
          regionsList: {},
          chartName: API_RESPONSE_DIFFS.DEVICE_TYPE,
        }))
      }
      setChartLoading(false);
  };

  useEffect(() => {
    getData();
  }, [EventAnalyticsState.dateRange, EventAnalyticsState.eventId, groupBy]);

  return <YoutubeSegmentedStatsView chartData={chartData} isChartLoading={isChartLoading} />;
  
};

YoutubeSegmentedStats.displayName = 'YoutubeSegmentedStats';

export default YoutubeSegmentedStats;