import { perms } from '../../jcs';
import { uploaderModule } from './index';

function routeConfig($provide, $routeProvider) {
	'ngInject';

	$provide.factory('$routeProvider', () => {
		return $routeProvider;
	});

	const default_options = {
		controller: uploaderModule.controllers.default,
		template: require('./uploader.tmpl.html').default,
		activenav: uploaderModule.name,
		access: {
			loginRequired: false,
		},
	};

	$routeProvider.when(uploaderModule.routes.login, default_options);
	$routeProvider.when(uploaderModule.routes.success, default_options);
}

export default routeConfig;
