interface RouteParams extends ng.route.IRouteParamsService {
  resetToken: string;
}

class PasswordSetController {
  public encryptedToken: string;

  constructor(private $routeParams: RouteParams) {
    'ngInject';
    this.encryptedToken = this.$routeParams.resetToken;
  }
}
module.exports = PasswordSetController;
