import React, { FC, useEffect, useReducer, Reducer } from 'react';
import PropTypes from 'prop-types';
import { fetchUploadUrls, uploadFilePut, uploadFilePost, readFile, isFileValid } from './imageUploadApi';
import { getCroppedImg } from './cropImage';
import { ImageUploadProps, ImageUploadState, Action, CroppedImageInfo, Area, ACTION_TYPE, UploadPOSTUrls, UPLOAD_DESTINATION, UploadRequestParameters, ImageMetadata } from './imageUploadTypes';
import { reducer } from './imageUploadHelpers';
import { FileDropzone, ProgressBar, ImageCropper } from '../index';
import './style.scss';

const initialCropAreaPixels: Area = {
  x: 0,
  y: 0,
  width: 0,
  height: 0,
};

const initialCroppedImageInfo: CroppedImageInfo = {
  croppedImage: null,
  croppedImageUrl: '',
};

const initialUploadPOSTUrls: UploadPOSTUrls = {
  downloadUrl: '',
  uploadUrl: '',
  filePath: '',
};

const initialImageMetadata: ImageMetadata = {
  width: 0,
  height: 0,
  type: 'image/jpeg',
}

const initialState: ImageUploadState = {
  file: undefined,
  fileSrc: '',
  isUploading: false,
  isUploadFinished: false,
  uploadProgress: 0,
  cropAreaPixels: initialCropAreaPixels,
  croppedImageInfo: initialCroppedImageInfo,
  uploadUrls: initialUploadPOSTUrls,
  imageDeleted: false,
  error: '',
  metadata: initialImageMetadata,
  retries: 0,
  width: undefined,
};

const MAX_RETRIES = 3;

const ImageUpload: FC<ImageUploadProps> = ({
    destination,
    customerId,
    label,
    imageUrl,
    webEventId,
    webEventProfileId,
    width,
    showLoading,
    getRegion,
    getRoutingKey,
    onFilePathChanged,
    onImageCropped,
    onCancel,
    onUploadComplete,
  }) => {
  const [state, dispatch] = useReducer<Reducer<ImageUploadState, Action>>(reducer, initialState);
  
  const makeUploadRequest = () => {
    const requestParams: UploadRequestParameters = {
      extension: destination === UPLOAD_DESTINATION.ARCHIVE ? '.jpg' : 'jpg',
    };

    if (destination === UPLOAD_DESTINATION.ARCHIVE) {
      requestParams.webEventId = webEventId;
      requestParams.webEventProfileId = webEventProfileId;
      requestParams.path = '/thumbnails';

      if (getRegion) {
        requestParams.region = getRegion();
      }
      if (getRoutingKey) {
        requestParams.routingKey = getRoutingKey();
      }
    }
    fetchUploadUrls(destination, customerId, requestParams).then((uploadUrls) => {
      dispatch({type: ACTION_TYPE.UPLOAD_URLS, payload: uploadUrls});
    }).catch((e) => {
      // retry a few times to make sure its not failing due to data not being loaded at first attempt
      if (state.retries < MAX_RETRIES) {
        dispatch({type: ACTION_TYPE.RETRIES, payload: state.retries++});
        setTimeout(makeUploadRequest, 1000);
      }
    });
  };

  const renderProgress = (): JSX.Element => {
    return (
      <div>
        <ProgressBar progressUpdate={state.uploadProgress} />
      </div>
    );
  };

  const onUploaded = (croppedImageInfo: CroppedImageInfo): void => {
    if (onFilePathChanged) {
      const metadata = state.metadata;
      const img = croppedImageInfo.croppedImage ? croppedImageInfo.croppedImage : undefined;
      if (img) {
        const fr = new FileReader;
        fr.onload = () => {
          const image = new Image;
          image.onload = () => {
            metadata.height = image.height;
            metadata.width = image.width;
            dispatch({ type: ACTION_TYPE.METADATA_CHANGED, payload: metadata });
          };
          image.src = fr.result as string;
        };
        fr.readAsDataURL(img);
      }
      onFilePathChanged(state.uploadUrls.downloadUrl, metadata);
    }
  };

  const handleFilesAdded = async (files: File[]): Promise<void> => {
    dispatch({ type: ACTION_TYPE.ERROR, payload: '' });
    makeUploadRequest();

    try {
      // only grab the first file
      const file = files[0];
      if (isFileValid(file)) {
        dispatch({ type: ACTION_TYPE.FILE, payload: file });
        await readFile(file).then((src) => {
          dispatch({ type: ACTION_TYPE.FILE_SRC, payload: src });
          if (onImageCropped) {
            onImageCropped();
          }
        });
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: ACTION_TYPE.ERROR, payload: error?.message });
    }
  };

  const handleImageDelete = (): void => {
    dispatch({ type: ACTION_TYPE.IMAGE_DELETED, payload: true });
    dispatch({ type: ACTION_TYPE.FILE, payload: undefined });
    dispatch({ type: ACTION_TYPE.CROPPED_IMAGE_INFO, payload: initialCroppedImageInfo });
    dispatch({ type: ACTION_TYPE.METADATA_CHANGED, payload: initialImageMetadata });
    const element: HTMLElement | null = document.getElementById('image-url');
    if (element) {
      element.setAttribute('value', '');
    }
    if (onCancel) {
      onCancel();
    }
  };

  const handleFileUpload = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    event.stopPropagation();
    getCroppedImg(state.fileSrc, state.cropAreaPixels).then((croppedImageInfo: CroppedImageInfo) => {
      dispatch({ type: ACTION_TYPE.CROPPED_IMAGE_INFO, payload: croppedImageInfo });

      if (croppedImageInfo.croppedImage) {
        let uploadFile = uploadFilePost;
        if (destination === UPLOAD_DESTINATION.ARCHIVE) {
          uploadFile = uploadFilePut;
        }
        onUploaded(croppedImageInfo);
        dispatch({ type: ACTION_TYPE.IS_UPLOADING, payload: true });
        uploadFile({
          file: croppedImageInfo.croppedImage,
          uploadUrl: state.uploadUrls.uploadUrl,
          dispatch,
          metadata: state.metadata,
        })
          .then(() => {
            dispatch({ type: ACTION_TYPE.UPLOAD_FINISHED, payload: true });
          })
          .catch((error: any) => {
            console.log(error);
            dispatch({ type: ACTION_TYPE.ERROR, payload: error?.message });
            handleImageDelete();
          })
          .finally(() => {
            dispatch({ type: ACTION_TYPE.IS_UPLOADING, payload: false });
            if (onUploadComplete) {
              onUploadComplete();
            }
          });
      }
    });
  };
  
  return (
    <div>
      {imageUrl && state.isUploadFinished ? (
        <div>
          <img className="la1-imageupload-thumbnail" src={imageUrl} alt="custom" style={{maxWidth: width}}></img>
        </div>
      ) : (
        <>
          {!state.file && (
            <div>
              <FileDropzone
                disabled={state.isUploading}
                label={label}
                onFilesAdded={handleFilesAdded}
                accept="image/jpeg, image/png"
              />
            </div>
          )}
          {state.croppedImageInfo.croppedImage && (
            <div style={{position: 'relative'}}>
              {(state.isUploading && showLoading) &&
              <div style={{
                  width: '100%',
                  height: '100%',
                  position: 'absolute',
                  backgroundColor: 'rgba(0,0,0,.8)',
                  zIndex: 1,
                  color: 'rgba(255,255,255,1)',
                  textAlign: 'center',
                  padding: '25%',
              }}>
                <i className="fa fa-gear fa-spin" aria-hidden="true" style={{ marginRight: 7 }}></i> Uploading
              </div>
              }
              <img
                className="la1-imageupload-thumbnail"
                src={state.croppedImageInfo.croppedImageUrl}
                alt="custom"
                style={{maxWidth: width}}
              ></img>
            </div>
          )}
          {state.file && !state.croppedImageInfo.croppedImageUrl && (
            <div>
              <div className="la1-imageupload-crop-container">
                <ImageCropper
                  fileSrc={state.fileSrc}
                  aspect={16 / 9}
                  onCropComplete={(cropArea): void =>
                    dispatch({ type: ACTION_TYPE.CROP_AREA_PIXELS, payload: cropArea })
                  }
                />
              </div>
              <button className="btn btn-primary" disabled={state.isUploading && showLoading} style={{ marginRight: '5px' }} onClick={handleFileUpload}>
                Upload
              </button>
              <button className="btn btn-default" onClick={handleImageDelete}>
                Cancel
              </button>
            </div>
          )}
          <div>
            {(state.file && state.isUploading) ||
              (state.isUploadFinished && (
                <div key={state.file?.name} className="la1-imageupload-row">
                  <span className="la1-imageupload-filename">Name: {state.file?.name}</span>
                  {renderProgress()}
                </div>
              ))}
          </div>
          {state.error && (
            <div className="la1-imageupload-err-msg">
              <i className="fa fa-exclamation-circle" />
              {state.error}
            </div>
          )}
        </>
      )}
    </div>
  );
};

ImageUpload.displayName = 'ImageUpload';

export default ImageUpload;
