import styled from '@emotion/styled';
import { customProperties as vars } from '@resi-media/resi-ui';

// small mobile
const MOBILE_WIDTH = 100;
const MOBILE_HEIGHT = 67;

// tablet/mobile
const TABLET_WIDTH = 140;
const TABLET_HEIGHT = 94;

// desktop
const DESKTOP_WIDTH = 264;
const DESKTOP_HEIGHT = 176;

export const LogoContainer = styled.div`
  width: ${MOBILE_WIDTH}px;
  height: ${MOBILE_HEIGHT}px;

  @media (min-width: ${vars.breakPointTablet}) {
    width: ${TABLET_WIDTH}px;
    height: ${TABLET_HEIGHT}px;
  }

  @media (min-width: ${vars.breakPointDesktop}) {
    width: ${DESKTOP_WIDTH}px;
    height: ${DESKTOP_HEIGHT}px;
  }
`;
