import React, { FC, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import GoogleMapReact from 'google-map-react';
import { ControlsWrapper, MapWrapper, HeatMapWrapper, HeaderWrapper } from './heat-map.styles';
import { Text, Grid, CheckboxInput, customProperties as vars } from '@resi-media/resi-ui';
import { HeatmapPropType, LocationType, WeightedLocation } from './types';
import FillBlockSpinner from '../FillBlockSpinner/fill-block-spinner';
import { GOOGLE_API_KEY } from '../../../v2.snapshot/js/constants';
import { LOADER_SIZE_COMPONENT } from '../../pages/EventAnalytics/event-analytics-constants';
import { Paper } from '@material-ui/core';

const HeatMap: FC<HeatmapPropType> = ({ positions, defaultZoomLevel, isLoading }) => {
  const [heatmapVisible, setHeatMapVisible] = useState<boolean>(true);
  const [markersVisible, setMarkersVisible] = useState<boolean>(true);
  const [heatMap, setHeatMap] = useState<google.maps.visualization.HeatmapLayer>();
  const [markers, setMarkers] = useState<google.maps.Marker[]>([]);
  const [map, setMap] = useState<google.maps.Map>();
  const [defaultCenter, setDefaultCenter] = useState<LocationType>();

  const handleApiLoaded = (map: google.maps.Map, maps: any, positions: LocationType[]): void => {
    const markerArray: google.maps.Marker[] = [];

    setMap(map);

    const heatMapData: WeightedLocation[] = [];
    positions.map((pos) => {
      heatMapData.push({
        location: new maps.LatLng(pos.lat, pos.lng),
        weight: pos.weight,
      });
    });

    setHeatMap(
      new maps.visualization.HeatmapLayer({ 
        data: heatMapData,
        radius: 32,
        map: map,
      })
    );

    positions.forEach((place) => {
      const currentMarker = new maps.Marker({
        position: {
          lat: place.lat,
          lng: place.lng,
        },
        map,
      });
      currentMarker.addListener('click', () => {
        const viewers = place.weight;
        new maps.InfoWindow({
          content: `${viewers > 1 ? `${viewers.toString()} viewers` : `${viewers.toString()} viewer`}`,
        }).open({
          anchor: currentMarker,
          map,
          shouldFocus: false,
        });
      });
      markerArray.push(currentMarker);
    });
    setMarkers(markerArray);
  };

  const toggleHeatMap = () => {
    setHeatMapVisible((state) => {
      if (heatMap && map) {
        heatMap.setMap(heatMap.getMap() ? null : map);
      }
      return !state;
    });
  };

  const toggleMarkers = () => {
    function setMapOnAll(map: google.maps.Map | null) {
      for (let i = 0; i < markers?.length; i++) {
        markers ? markers[i].setMap(map) : null;
      }
    }
    setMarkersVisible((state) => {
      if (markers && map) {
        markersVisible ? setMapOnAll(null) : setMapOnAll(map);
      }
      return !state;
    });
  };

  useEffect(() => {
    let centerValue = positions[0],
      maxWeight = centerValue.weight;
    positions.map((data) => {
      if (data.weight > maxWeight) {
        maxWeight = data.weight;
        centerValue = data;
      }
    });
    setDefaultCenter(centerValue);
    setHeatMapVisible(true);
    setMarkersVisible(true);
  }, [positions]);

  return (
    <Paper elevation={0} variant="outlined">
      <HeatMapWrapper>
        <HeaderWrapper data-testid="heat-map-heading">
          <Text.Heading type="h4" customCss={`color: ${vars.colorAccent800};`}>
            Heat Map
          </Text.Heading>
        </HeaderWrapper>
        {isLoading ? (
          <FillBlockSpinner fontSize={LOADER_SIZE_COMPONENT} />
        ) : (
          <MapWrapper data-testid="heat-map-controls">
            <ControlsWrapper>
              <Grid display="grid" gridGap={vars.spacingM} gridTemplateColumns="auto auto">
                <CheckboxInput
                  isChecked={heatmapVisible}
                  label="Heat Map"
                  onClick={toggleHeatMap}
                  data-testid="heatmap-check"
                />
                <CheckboxInput
                  isChecked={markersVisible}
                  label="Markers"
                  onClick={toggleMarkers}
                  data-testid="markers-check"
                />
              </Grid>
            </ControlsWrapper>
            {!!defaultCenter && (
              <GoogleMapReact
                defaultZoom={defaultZoomLevel}
                defaultCenter={defaultCenter}
                yesIWantToUseGoogleMapApiInternals
                onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps, positions)}
                bootstrapURLKeys={{
                  key: GOOGLE_API_KEY,
                  libraries: ['visualization'],
                }}
              />
            )}
          </MapWrapper>
        )}
      </HeatMapWrapper>
    </Paper>
  );
};

HeatMap.defaultProps = {
  positions: [],
  defaultZoomLevel: 0,
};

HeatMap.propTypes = {
  positions: PropTypes.arrayOf(
    PropTypes.exact({
      lat: PropTypes.number.isRequired,
      lng: PropTypes.number.isRequired,
      weight: PropTypes.number.isRequired,
    }).isRequired
  ).isRequired,
  defaultZoomLevel: PropTypes.number.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

HeatMap.displayName = 'HeatMap';

export default HeatMap;
