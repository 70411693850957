class CueService {

  // converts cue position (ex: 01:23:45) into seconds
  public getCuePositionInSeconds = function (cuePosition) {

    let hours = 0;
    let minutes = 0;
    let seconds = 0;

    if (cuePosition.indexOf(':') >= 0) {
      const tokens = cuePosition.split(':');
      if (tokens.length == 1) {
        seconds = parseFloat(tokens[0]);
      } else if (tokens.length == 2) {
        minutes = parseInt(tokens[0]);
        seconds = parseFloat(tokens[1]);
      } else {
        hours = parseInt(tokens[0]);
        minutes = parseInt(tokens[1]);
        seconds = parseFloat(tokens[2]);
      }

      return 3600 * hours + 60 * minutes + seconds;

    } else return 0;
  };

}

export default CueService;