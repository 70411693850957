import 'angular-route';
import angular from 'angular';
import router from './module.eventprofileusers.routes';
import commonServicesModule from '../../services';

export const eventprofileusersModule = {
	name: 'eventprofileusers',
	controllers: {
		default: 'eventProfileUsersCtrl',
	},
	routes: {
		list: '/eventprofiles/:streamID/users',
	},
};

export default angular
	.module(eventprofileusersModule.name, ['ngRoute', commonServicesModule])
	.config(router)
	.controller(eventprofileusersModule.controllers.default, require('./eventProfileUsersCtrl')).name;
