import styled from '@emotion/styled';
import { customProperties as vars } from '@resi-media/resi-ui';

export const EventHeaderPillWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: ${vars.spacingXs} ${vars.spacingS};
  box-sizing: border-box;
  color: ${vars.colorAccent200};
  border-radius: ${vars.borderRadius20};
  background-color: ${vars.colorPrimary800};
`;

export const EventLabelWrapper = styled.div`
  color: ${vars.colorAccent200};
  font-size: ${vars.fontSize16};
  font-weight: bold;
  text-transform: capitalize;
  margin-right: ${vars.spacingS};
`;

export const Slash = styled.div`
  margin: 0 ${vars.spacingS};
  color: ${vars.colorPrimary800};
  font-size: ${vars.fontSize24};
`;
