import React, { FC, useEffect, useReducer, Reducer } from 'react';
import './style.scss';
import ModalFooter from '../ModalFooter';
import ModalContent from '../ModalContent';
import { AxiosResponse } from 'axios';
import { customProperties as vars } from '@resi-media/resi-ui';
import { Loading3QuartersOutlined } from '@ant-design/icons';
import { ArchiveSaveWebEventPayload, archiveSaveWebEvent, getWebEventStatus, setArchiveWebEventStarted, ArchiveResponse } from './api';
import { Action, ACTION_TYPE, ArchiveTrimWebEventFormErrors, ArchiveSaveEventState, reducer } from './context';
import ImageUpload from '../ImageUpload';
import { UPLOAD_DESTINATION, ImageMetadata } from '../ImageUpload/imageUploadTypes';
import { Thumbnail, Representation } from '../../constants';
import { MPEventName, MPEventProperty, trackMixpanelEvent } from '../../mixpanel';
import moment from 'moment';

enum WebEventFields {
  TITLE = 'title',
  DESCRIPTION = 'description',
}

interface ArchiveSaveWebEventFormProps {
  auth: any;
  newWebEventLocation: string;
  webEventProfileId?: string;
  customerId?: string;
  webEventId?: string;
  originalAndTrimmedDurations?: {
    formattedDuration: '',
    trimmedFormattedDuration: ''
  };
}
const ArchiveSaveWebEventForm: FC<ArchiveSaveWebEventFormProps> = ({
  auth,
  newWebEventLocation,
  webEventProfileId,
  customerId,
  webEventId,
  originalAndTrimmedDurations
}): JSX.Element => {

  const initialState: ArchiveSaveEventState = {
    title: '',
    description: '',
    startTime: undefined,
    isEventStopped: false,
    isLoading: true,
    isDisabled: true,
    newWebEventUuid: '',
    formErrors: [],
    thumbnails: new Array<Thumbnail>(),
    isUploading: false,
  };
  const [state, dispatch] = useReducer<Reducer<ArchiveSaveEventState, Action>>(reducer, initialState);
  let fetchInterval = -1;


  useEffect(() => {
    fetchInterval = window.setInterval(async () => {
      await fetchWebEvent();
    }, 2000);
  }, []);

  const fetchWebEvent = async () => {
    const response = await getWebEventStatus(newWebEventLocation);
    if (response.data.status === 'stopped') {
      dispatch({type: ACTION_TYPE.NEW_WEB_EVENT_UUID, payload: response.data.uuid});
      dispatch({type: ACTION_TYPE.EVENT_STOPPED, payload: true});
      dispatch({type: ACTION_TYPE.DISABLED, payload: false});
      dispatch({type: ACTION_TYPE.LOADING, payload: false});
      dispatch({type: ACTION_TYPE.TITLE, payload: response.data.name});
      dispatch({type: ACTION_TYPE.DESCRIPTION, payload: response.data.description});
      dispatch({type: ACTION_TYPE.START_TIME, payload: response.data.startTime});
      clearInterval(fetchInterval);
      return;
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    validateText(event.currentTarget.name, event.currentTarget.value);
    switch (event.currentTarget.name){
      case WebEventFields.TITLE:
        dispatch({ type: ACTION_TYPE.TITLE, payload: event.currentTarget.value });
        break;
      case WebEventFields.DESCRIPTION:
        dispatch({ type: ACTION_TYPE.DESCRIPTION, payload: event.currentTarget.value });
        break;
    }
  };

  const handleSave = async () => {
    const formattedStartDate = moment(state.startTime).format('MMM D, YYYY');
    const { title, description, thumbnails } = state;
    const archiveWebEventPayload: ArchiveSaveWebEventPayload = {
      title,
      description,
      webEventProfileId,
      thumbnails,
    };
    dispatch({type: ACTION_TYPE.EVENT_STOPPED, payload: false});
    dispatch({type: ACTION_TYPE.DISABLED, payload: true});
    dispatch({type: ACTION_TYPE.LOADING, payload: true});
    try {
      const response: AxiosResponse<ArchiveResponse> = await archiveSaveWebEvent(archiveWebEventPayload, customerId, state.newWebEventUuid);
      if (response.status === 200 || response.status === 201) {
        await setArchiveWebEventStarted(response.data.startCallbackUrl);

        trackMixpanelEvent(
          MPEventName.LIBRARY_SAVE_COMPLETE, {
            [MPEventProperty.TITLE]: title,
            [MPEventProperty.PARENT_ID]: state.newWebEventUuid,
            [MPEventProperty.AIRDATE]: formattedStartDate,
            [MPEventProperty.DURATION]: originalAndTrimmedDurations?.trimmedFormattedDuration ? originalAndTrimmedDurations?.trimmedFormattedDuration : originalAndTrimmedDurations?.formattedDuration,
            [MPEventProperty.DESCRIPTION]: state.description,
            [MPEventProperty.IS_TRIMMED]: originalAndTrimmedDurations?.trimmedFormattedDuration === originalAndTrimmedDurations?.formattedDuration
          }
        )

        onCancel();
        window.location.assign('/library');
      }
    } catch (error) {
      state.formErrors.push(ArchiveTrimWebEventFormErrors.STATUS_ERROR);
      dispatch({type: ACTION_TYPE.FORM_ERROR, payload: state.formErrors});
      dispatch({type: ACTION_TYPE.EVENT_STOPPED, payload: true});
      dispatch({type: ACTION_TYPE.DISABLED, payload: false});
      dispatch({type: ACTION_TYPE.LOADING, payload: false});
      console.error(error);
    }
  };

  const validateText = (field: string, value: string): void => {
    if (field === WebEventFields.TITLE) {
      if (value.length > 0 && value.replace(/\s/g, '') !== '') {
        dispatch({type: ACTION_TYPE.FORM_ERROR, payload: state.formErrors.filter((er) => er !== ArchiveTrimWebEventFormErrors.TITLE)});
        dispatch({type: ACTION_TYPE.DISABLED, payload: false});
      }
      else {
        state.formErrors.push(ArchiveTrimWebEventFormErrors.TITLE);
        dispatch({type: ACTION_TYPE.FORM_ERROR, payload: state.formErrors});
        dispatch({type: ACTION_TYPE.DISABLED, payload: true});
      }
    }
  };

  const onCancel = () => {
    dispatch({type: ACTION_TYPE.THUMBNAILS, payload: []});
    dispatch({type: ACTION_TYPE.UPLOADING, payload: false});
  };

  const onImageUploaded = async (publicUrl?:string, metadata?: ImageMetadata) => {
    if (publicUrl && metadata) {
      const {title} = state;
      const rep: Representation = {
        quality: 'default',
        width: metadata?.width ?? 0,
        height: metadata?.height ?? 0,
        publicUrl: publicUrl ?? '',
      };
      const tempThumb: Thumbnail = {
        name: title,
        language: '',
        author: '',
        encodingFormat: 'jpeg',
        bucket: 'thumbnails',
        representations: [rep],
      };
      dispatch({type: ACTION_TYPE.THUMBNAILS, payload: [tempThumb]});
      dispatch({type: ACTION_TYPE.UPLOADING, payload: true});
    }
  };

  const onUploadComplete = () => {
    dispatch({type: ACTION_TYPE.UPLOADING, payload: false});
  };

  return (
    <>
      <ModalContent>
        <p className="resi-save-event-progress-progress-description">
          Great! Now before we save your media lets give it some basic information so it&apos;s easier to find it later.
        </p>
        {state.isEventStopped ? (
          <div className="form-horizontal" id="webEventArchiveForm" style={{ width: '75%', margin: 'auto' }}>
            <div className="form-group">
              <label htmlFor="title" className="col-sm-3 control-label">
                Title
              </label>
              <div className="col-sm-9">
                <input
                  data-testid="title-input"
                  className="form-control"
                  name="title"
                  value={state.title}
                  onChange={handleChange}
                  maxLength={50}
                />
              </div>
              {state.formErrors.includes(ArchiveTrimWebEventFormErrors.TITLE) && (
                <>
                  <div className="col-sm-3"/>
                  <div className="col-sm-9">
                    <p style={{ color: vars.colorError, margin: '0' }}>Please provide a title</p>
                  </div>
                </>
              )}
            </div>
            <div className="form-group">
              <label htmlFor="description" className="col-sm-3 control-label">
                Description
              </label>
              <div className="col-sm-9">
                <textarea
                  data-testid="description-input"
                  className="form-control"
                  name="description"
                  value={state.description}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="description" className="col-sm-3 control-label">
                Thumbnail
              </label>
              <div className="col-sm-9">
                <ImageUpload
                  destination={UPLOAD_DESTINATION.ARCHIVE}
                  customerId={customerId ?? ''}
                  label="Upload a thumbnail"
                  imageUrl=""
                  webEventProfileId={webEventProfileId}
                  webEventId={webEventId}
                  onFilePathChanged={onImageUploaded}
                  onCancel={onCancel}
                  onUploadComplete={onUploadComplete}
                  showLoading={true}
                  width="100%"
                />
              </div>
            </div>
            {state.formErrors.includes(ArchiveTrimWebEventFormErrors.STATUS_ERROR) && (
              <div className="form-group">
                <div className="col-sm-3"/>
                <div className="col-sm-9">
                  <p style={{ color: vars.colorError, margin: '0' }}>
                    Unable to save web event.
                  </p>
                </div>
              </div>
            )}
          </div>
        ) : (
          <div className="resi-trim-event-progress-icon-div">
            <Loading3QuartersOutlined spin style={{ color: vars.colorPrimary, fontSize: 36 }} />
          </div>
        )}
      </ModalContent>
      <ModalFooter>
        <button
          disabled={state.newWebEventUuid.length === 0 || state.isDisabled || state.isUploading}
          className="btn btn-primary"
          onClick={handleSave}
        >
          {(state.isLoading) &&
            <i className="fa fa-gear fa-spin" aria-hidden="true" style={{ marginRight: 7 }}></i>
          }
          Save
        </button>
      </ModalFooter>
    </>
  );
};

ArchiveSaveWebEventForm.displayName = "ArchiveSaveWebEventForm"

export default ArchiveSaveWebEventForm;
