import 'angular-route';
import angular from 'angular';
import router from './module.orders.routes';

export const ordersModule = {
	name: 'orders',
	controllers: {
		default: 'ordersCtrl',
	},
	routes: {
		list: '/orders',
	},
};

export default angular
	.module(ordersModule.name, ['ngRoute'])
	.config(router)
	.controller(ordersModule.controllers.default, require('./ordersCtrl')).name;
