import React, { FC, Reducer, useReducer } from 'react';
import {LibraryThumbnailUploaderState, Action, ACTION_TYPE, reducer} from './context';
import {ImageUploadProps, ImageMetadata} from '../ImageUpload/imageUploadTypes';
import ImageUpload from '../ImageUpload';
import LibraryThumbnailPreview from '../LibraryThumbnailPreview';

const initialState: LibraryThumbnailUploaderState = {
  display: true,
};

const LibraryThumbnailUploader: FC<ImageUploadProps> = ({
    destination,
    customerId,
    label,
    imageUrl,
    webEventId,
    webEventProfileId,
    width,
    getRegion,
    getRoutingKey,
    onFilePathChanged,
    onImageCropped,
    onCancel,
    onDeleteThumbnails,
}) => {
  const [state, dispatch] = useReducer<Reducer<LibraryThumbnailUploaderState, Action>>(reducer, initialState);

  const onFileSelected = () => {
    dispatch({type: ACTION_TYPE.DISPLAY, payload: false});
    if(onImageCropped) {
      onImageCropped();
    }
  };

  const onUploadFileCanceled = () => {
    dispatch({type: ACTION_TYPE.DISPLAY, payload: true});
    if(onCancel) {
      onCancel();
    }
  };

  return (
    <div>
      {state.display &&
        <LibraryThumbnailPreview 
            showDelete={true}
            onClickDelete={onDeleteThumbnails}
            imageUrl={imageUrl}
        />
      }
      <ImageUpload
          destination={destination}
          customerId={customerId}
          label={label}
          webEventId={webEventId}
          webEventProfileId={webEventProfileId}
          width={width}
          getRegion={getRegion}
          getRoutingKey={getRoutingKey}
          onFilePathChanged={onFilePathChanged}
          onImageCropped={onFileSelected}
          onCancel={onUploadFileCanceled}
      />
    </div>
  );
};

LibraryThumbnailUploader.displayName = 'LibraryThumbnailUploader';

export default LibraryThumbnailUploader;
