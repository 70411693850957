import { ExportOutlined } from '@ant-design/icons';
import { Paper } from '@material-ui/core';
import { Grid, Inline, Text, customProperties as vars } from '@resi-media/resi-ui';
import Link from '@material-ui/core/Link';
import Tooltip from '@material-ui/core/Tooltip';
import PropTypes from 'prop-types';
import React, { FC, useContext } from 'react';
import { DoughnutChart, VirtualizedTable } from '../../../../components';
import FillBlockSpinner from '../../../../components/FillBlockSpinner/fill-block-spinner';
import { VirtualizedTableData } from '../../../../components/VirtualizedTable/types';
import { EventAnalyticsContext } from '../../../../contexts/eventanalytics/event-analytics';
import { MPEventName, MPEventProperty, trackMixpanelEvent } from '../../../../mixpanel';
import { LOADER_SIZE_COMPONENT } from '../../event-analytics-constants';
import { LinkButtonContainer } from '../../event-analytics.styles';
import { doughnutChartDataType } from '../../types';
import { convertTableToCSV, downloadAsCSV } from '../../utils';
import {
  ChartContent,
  ChartHeaderDiv,
  HeaderDiv,
  SegmentedStatsWrapper,
  TableContent,
} from './alldestinations-segmented-stats.styles';

interface AllDestinationsSegmentedStatsViewPropType {
  chartData: doughnutChartDataType[];
  isChartLoading: boolean;
}
type renderChartandTableType = {
  chart: JSX.Element;
  table: JSX.Element;
};

const AllDestinationsSegmentedStatsView: FC<AllDestinationsSegmentedStatsViewPropType> = ({
  chartData,
  isChartLoading,
}): JSX.Element => {
  let tableDataCSV: string;
  const {
    EventAnalyticsState: { eventId },
  } = useContext(EventAnalyticsContext);

  const formatTableData = (inputChartData: doughnutChartDataType[]): VirtualizedTableData => {
    const tableData: VirtualizedTableData = { header: [], data: [] };
    if (inputChartData !== null) {
      tableData.header = [{ label: 'Destination Source' }, { label: 'Views' }];
      inputChartData.map((item) => tableData.data.push({ payload: '', row: [item.label, item.data] }));
    }
    tableDataCSV = convertTableToCSV(
      tableData.header,
      tableData.data.map((rowData) => rowData.row)
    );
    return tableData;
  };

  const renderChartandTable = (): renderChartandTableType => {
    let chart: JSX.Element, table: JSX.Element;

    if (!chartData || Object.keys(chartData).length === 0) {
      chart = <div />;
      table = <div />;
    } else {
      chart = <DoughnutChart data={chartData} tooltipLabel="Views" showPercentage isLegendOnRightSide />;
      table = <VirtualizedTable data={formatTableData(chartData)} />;
    }
    const dataRender: renderChartandTableType = { chart, table };
    return dataRender;
  };

  const { chart: renderChart, table: renderTable } = renderChartandTable();
  const chart: JSX.Element = renderChart;
  const table: JSX.Element = renderTable;

  const handleDownloadCSV = () => {
    downloadAsCSV(tableDataCSV);

    const mixpanelProps = {
      [MPEventProperty.TRANSCODED_EVENT_UUID]: eventId,
      [MPEventProperty.CSV_DETAIL]: 'All Data',
      [MPEventProperty.ANALYTICS_TYPE]: 'All Destinations',
    };

    if (!eventId) {
      delete mixpanelProps[MPEventProperty.TRANSCODED_EVENT_UUID];
    }

    trackMixpanelEvent(MPEventName.CSV_EXPORT, mixpanelProps);
  };

  return (
    <Paper elevation={0} variant="outlined" data-testid="alldestinations-segmented-stats-view">
      <SegmentedStatsWrapper>
        <HeaderDiv>
          <ChartHeaderDiv data-testid="alldestinations-segmented-chartTitle">
            <Text.Heading type="h4" customCss={`color: ${vars.colorAccent800};`}>
              Views by Destination Source
            </Text.Heading>
          </ChartHeaderDiv>
          <Tooltip title={<h6>{'Download all data as CSV'}</h6>} arrow placement="top">
            <Link
              component="button"
              underline="none"
              variant="body2"
              onClick={handleDownloadCSV}
              data-testid="alldestinations-segmented-exportCSV"
            >
              <LinkButtonContainer>
                <ExportOutlined />
                <span style={{ marginLeft: '5px' }}>Export CSV</span>
              </LinkButtonContainer>
            </Link>
          </Tooltip>
        </HeaderDiv>
        <Grid display="grid" gridTemplateColumns="8fr 4fr">
          {isChartLoading ? (
            <FillBlockSpinner fontSize={LOADER_SIZE_COMPONENT} />
          ) : (
            <>
              <Grid.Item>
                <Inline justifyContent="center">
                  <ChartContent data-testid="alldestinations-segmented-chart">{chart}</ChartContent>
                </Inline>
              </Grid.Item>
              <Grid.Item>
                <Inline justifyContent="center">
                  <TableContent data-testid="alldestinations-segmented-table">{table}</TableContent>
                </Inline>
              </Grid.Item>
            </>
          )}
        </Grid>
      </SegmentedStatsWrapper>
    </Paper>
  );
};

AllDestinationsSegmentedStatsView.propTypes = {
  chartData: PropTypes.arrayOf(
    PropTypes.exact({
      label: PropTypes.string.isRequired,
      data: PropTypes.number.isRequired,
    }).isRequired
  ).isRequired,
  isChartLoading: PropTypes.bool.isRequired,
};

AllDestinationsSegmentedStatsView.displayName = 'AllDestinationsSegmentedStatsView';

export default AllDestinationsSegmentedStatsView;
