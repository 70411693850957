import React from 'react';
import { withStyles, createStyles } from '@material-ui/styles';
import { buttonItemStyles } from '../material-styles';
import { Button } from '@material-ui/core';

interface StyledButtonProps {
  id: string;
  disabled?: boolean;
}

const ContainedButton = withStyles(() => createStyles(buttonItemStyles))((props: StyledButtonProps) => (
  <Button disableFocusRipple disableRipple {...props} />
));

export default ContainedButton;
