import { api } from '../jcs';

class HardwareService {

  private statusList = null;
  private locationList = null;
  private modelList = null;

  private isLoadingStatusList = false;
  private isLoadingLocationList = false;
  private isLoadingModelList = false;

  private ACTIVATION_CODE_LENGTH = 8;
  private ACTIVATION_CODE_SEPERATOR = '-';

  constructor(private $q: ng.IQService, private $http: ng.IHttpService) {
    'ngInject';
  }

  public isLoading(): boolean {
    return this.isLoadingStatusList || this.isLoadingLocationList || this.isLoadingModelList;
  }

  public getStatusList(): ng.IPromise<any> {
    // do we already have the info in our cache?
    if (this.statusList !== null) {
      return this.$q.resolve(this.statusList);
    }

    this.isLoadingStatusList = true;
    return this.$http.get(this.getStatusUrl(), { withCredentials: true })
      .then(response => {
        this.statusList = response.data;
        return response.data;
      })
      .finally(() => (this.isLoadingStatusList = false));
  }

  public getLocationList(): ng.IPromise<any> {
    // do we already have the info in our cache?
    if (this.locationList !== null) {
      return this.$q.resolve(this.locationList);
    }

    this.isLoadingLocationList = true;
    return this.$http.get(this.getLocationUrl(), { withCredentials: true })
      .then(response => {
        this.locationList = response.data;
        return response.data;
      })
      .finally(() => (this.isLoadingLocationList = false));
  }

  public getModelList(): ng.IPromise<any> {
    // do we already have the info in our cache?
    if (this.modelList !== null) {
      return this.$q.resolve(this.modelList);
    }

    this.isLoadingModelList = true;
    return this.$http.get(this.getModelUrl(), { withCredentials: true })
      .then(response => {
        this.modelList = response.data;
        return response.data;
      })
      .finally(() => (this.isLoadingModelList = false));
  }

  public clearStatusList (): void {
    this.statusList = null;
  }

  public clearLocationList (): void {
    this.locationList = null;
  }

  public clearModelList (): void {
    this.modelList = null;
  }

  public getFormatActivationCode (code: string): string {
    if (code !== null && code.length === this.ACTIVATION_CODE_LENGTH){
      return code.slice(0,4) + this.ACTIVATION_CODE_SEPERATOR + code.slice(4,8);
    }
    return code;
  }

	public getBaseModel = function (model: string): string {
		const index = model.indexOf('-');
		if (index !== -1) {
			return model.substring(0, index);
		}
		return model;
	};

  private getStatusUrl (): string {
    return `${api.internal_url}/hardwarestatuses`;
  }

  private getLocationUrl (): string {
    return `${api.internal_url}/hardwarelocations`;
  }

  private getModelUrl (): string {
    return `${api.internal_url}/hardwaremodels`;
  }
}

export default HardwareService;
