import React, { FC, useContext, useEffect, useState, ChangeEvent, useMemo } from 'react';
import StreamStatsGraphView from './stream-stats-graph-view';
import { StreamContext } from '../../../../contexts/eventanalytics';
import {
  TIME_WINDOW_VALUES,
  TIME_WINDOW_SET,
  GROUP_SET,
  STREAM_STATE_CONTEXT_KEYS,
  MAX_FETCH_LIMIT_FOR_CSV_EXPORT,
  STREAM_CSV_EXPORT_FIELDS,
  EVENT_ANALYTICS_CONTEXT_KEYS,
  GROUP_VALUES,
  TAB_VALUES_NUMBERS,
  allDeviceTypeOption,
  STREAM_URL_PAGES,
  STRAM_URL_DATA_PAGES,
} from '../../event-analytics-constants';
import { dateRangeValues, customDateRangeOption, lifeTimeValues } from '../../date-range-options';
import {
  DataObject,
  DateType,
  GROUP_BY_VALUE,
  lineChartDataType,
  STREAM_DATA_PAGES,
  STREAM_TAB_VALUES,
  TIME_WINDOWS,
} from '../../types';
import { Option } from '@resi-media/resi-ui';
import {
  downloadAsCSV,
  fetchAndMergeResponse,
  parseLineChartData,
  isMonthTabDisabled,
  getDateRangeOptions,
  parseLineChartDataForDeviceType,
  parseMinByMinLineChartData,
  parseLineChartDataMinByMinDeviceBrand,
} from '../../utils';
import { exportCSVData, fetchLineChartData, fetchMinByMinData } from '../api';
import { EventAnalyticsContext } from '../../../../contexts/eventanalytics/event-analytics';
import { Parser } from 'json2csv';
import { MPEventName, MPEventProperty, trackMixpanelEvent } from '../../../../mixpanel';
import { fetchVideoCueData } from '../../../../components/VideoCues/api';

interface StreamStatsGraphProps {
  csvDetail: STREAM_TAB_VALUES;
  hasGraphPerms: boolean;
  setSegmentType: (value: GROUP_BY_VALUE) => void;
}

const StreamStatsGraph: FC<StreamStatsGraphProps> = ({ csvDetail, hasGraphPerms, setSegmentType }): JSX.Element => {
  const { streamAnalyticsState, dispatch } = useContext(StreamContext);
  const { EventAnalyticsState, dispatch: dispatchEventAnalyticsState } = useContext(EventAnalyticsContext);
  const { viewAllData, dateRangeOption, eventId } = EventAnalyticsState;
  const isMonthTimeWindowDisabled = isMonthTabDisabled(dateRangeOption?.value);
  const [dataType, setDataType] = useState<number>(0);
  const [dataPage, setDataPage] = useState<STREAM_DATA_PAGES>(0);
  const [dataPageName, setDataPageName] = useState<string>(STRAM_URL_DATA_PAGES[0]);
  const [deviceType, setDeviceType] = useState<string>('');
  const [timeWindow, setTimeWindow] = useState<number>(0);
  const [isDatePickerOpen, setDatePickerOpen] = useState<boolean>(false);
  const [timeWindowValue, setTimeWindowValue] = useState<string>(TIME_WINDOW_VALUES.DAY);
  const [chartData, setChartData] = useState<lineChartDataType>({ labels: [], datasets: {} });
  const [isChartLoading, setChartLoading] = useState<boolean>(false);
  const [isExportCSVLoading, setExportCSVLoading] = useState<boolean>(false);
  const [videoCuesData, setVideoCuesData] = useState<DataObject[]>();

  const handleGroupByChange = (event: ChangeEvent<unknown>, newValue: GROUP_BY_VALUE) => {
    setDataType(newValue);
    if (newValue !== TAB_VALUES_NUMBERS.DEVICE_TYPE) {
      dispatch({ type: STREAM_STATE_CONTEXT_KEYS.GROUP_BY, payload: GROUP_SET[newValue] });
    }
    setSegmentType(newValue);

    let tabString = '';

    switch (newValue) {
      case 0:
        tabString = 'None';
        break;
      case 1:
        tabString = 'Event Type';
        break;
      case 2:
        tabString = 'Device Type';
        break;
    }

    const mixpanelProps = {
      [MPEventProperty.TRANSCODED_EVENT_UUID]: eventId,
      [MPEventProperty.ANALYTICS_TYPE]: 'Stream URL',
      [MPEventProperty.ANALYTICS_SEGMENT]: tabString,
    };

    if (!eventId) {
      delete mixpanelProps[MPEventProperty.TRANSCODED_EVENT_UUID];
    }

    trackMixpanelEvent(MPEventName.ANALYTICS_SEGMENT, mixpanelProps);
  };

  const handleDataPageChange = (event: ChangeEvent<unknown>, newValue: STREAM_DATA_PAGES): void => {
    setDataPage(newValue);
    setDataPageName(STRAM_URL_DATA_PAGES[newValue]);
  };

  const handleTimeWindowChange = (event: ChangeEvent<unknown> | null, newValue: TIME_WINDOWS) => {
    setTimeWindowValue(TIME_WINDOW_SET[newValue]);
    setTimeWindow(newValue);
  };

  const handleDeviceTypeChange = (newDeviceType: string): void => {
    if (newDeviceType !== allDeviceTypeOption.value) {
      setDeviceType(newDeviceType);
    } else {
      setDeviceType('');
    }
  };

  const handleDateRangeChange = (item: Option[]) => {
    const selection: string = item[0]?.value;
    let dateRange: DateType = { startDate: '', endDate: '' };
    // Since we have only one custom handling case "c0".
    if (selection === customDateRangeOption.value) {
      setDatePickerOpen(true);
    } else if (selection === '0' && viewAllData) {
      dateRange = lifeTimeValues[selection];
      dispatchEventAnalyticsState({ type: EVENT_ANALYTICS_CONTEXT_KEYS.DATE_RANGE, payload: dateRange });
      dispatchEventAnalyticsState({ type: EVENT_ANALYTICS_CONTEXT_KEYS.DATE_RANGE_VALUE, payload: item[0] });
    } else if (selection) {
      dateRange = dateRangeValues[selection];
      dispatchEventAnalyticsState({ type: EVENT_ANALYTICS_CONTEXT_KEYS.DATE_RANGE, payload: dateRange });
      dispatchEventAnalyticsState({ type: EVENT_ANALYTICS_CONTEXT_KEYS.DATE_RANGE_VALUE, payload: item[0] });
    }
  };

  const handleCustomDateRangeSelection = (startDate: string, endDate: string) => {
    if (startDate && endDate) {
      const dateRange = {
        startDate,
        endDate,
      };
      dispatchEventAnalyticsState({ type: EVENT_ANALYTICS_CONTEXT_KEYS.DATE_RANGE, payload: dateRange });
      dispatchEventAnalyticsState({
        type: EVENT_ANALYTICS_CONTEXT_KEYS.DATE_RANGE_VALUE,
        payload: customDateRangeOption,
      });
    }
  };

  const exportCSV = async () => {
    setExportCSVLoading(true);
    const response = await fetchAndMergeResponse(
      (offSetValue1?: string | number, offSetValue2?: string | number) =>
        exportCSVData(EventAnalyticsState, offSetValue1, offSetValue2),
      MAX_FETCH_LIMIT_FOR_CSV_EXPORT,
      'clientId',
      'sessionId'
    );
    setExportCSVLoading(false);
    const json2csvParser = new Parser({ fields: STREAM_CSV_EXPORT_FIELDS });
    const csv = json2csvParser.parse(response);
    downloadAsCSV(csv, `StreamURL-Analytics.csv`);

    const mixpanelProps = {
      [MPEventProperty.TRANSCODED_EVENT_UUID]: eventId,
      [MPEventProperty.CSV_DETAIL]: csvDetail === 0 ? 'Device Type' : 'Location',
      [MPEventProperty.ANALYTICS_TYPE]: 'Stream URL',
    };

    if (!eventId) {
      delete mixpanelProps[MPEventProperty.TRANSCODED_EVENT_UUID];
    }

    trackMixpanelEvent(MPEventName.CSV_EXPORT, mixpanelProps);
  };

  const getChartData = async () => {
    let lineChartData;
    setChartLoading(true);
    if (dataPage !== STREAM_URL_PAGES.MINUTE_BY_MINUTE) {
      const response = await fetchLineChartData(
        EventAnalyticsState,
        streamAnalyticsState,
        timeWindowValue,
        deviceType,
        dataPage
      );
      if (streamAnalyticsState.groupBy !== GROUP_VALUES.DEVICE_TYPE) {
        lineChartData = parseLineChartData(response, timeWindowValue);
      } else {
        lineChartData = parseLineChartDataForDeviceType(response, timeWindowValue);
      }
      setChartData(lineChartData);
      setChartLoading(false);
    } else {
      const response = await fetchMinByMinData(EventAnalyticsState, streamAnalyticsState, 5);
      const videoCuesData = await fetchVideoCueData(EventAnalyticsState);
      if (streamAnalyticsState.groupBy !== GROUP_VALUES.DEVICE_TYPE) {
        setChartData(parseMinByMinLineChartData(response));
      } else {
        setChartData(parseLineChartDataMinByMinDeviceBrand(response));
      }
      setVideoCuesData(videoCuesData);
      setChartLoading(false);
    }
  };

  useEffect(() => {
    getChartData();
  }, [streamAnalyticsState, timeWindowValue, EventAnalyticsState, deviceType, dataPage]);

  useMemo(() => {
    if (eventId === null) {
      setDataPage(0);
      setDataPageName(STRAM_URL_DATA_PAGES[0]);
    }
  }, [eventId]);

  return (
    <StreamStatsGraphView
      data={chartData}
      videoCuesData={videoCuesData}
      dataPageName={dataPageName}
      isChartLoading={isChartLoading}
      dateRangeOptions={getDateRangeOptions(viewAllData)}
      dataType={dataType}
      isDatePickerOpen={isDatePickerOpen}
      timeWindow={timeWindow}
      handleDeviceTypeChange={handleDeviceTypeChange}
      dataPage={dataPage}
      disableMonthTab={isMonthTimeWindowDisabled}
      handleDataPageChange={handleDataPageChange}
      handleGroupByChange={handleGroupByChange}
      handleDateRangeChange={handleDateRangeChange}
      handleTimeWindowChange={handleTimeWindowChange}
      handleCustomDateRangeSelection={handleCustomDateRangeSelection}
      hasGraphPerms={hasGraphPerms}
      exportCSV={exportCSV}
      isExportCSVLoading={isExportCSVLoading}
      closeDatePicker={() => setDatePickerOpen(false)}
    />
  );
};

StreamStatsGraph.displayName = 'StreamStatsGraph';

export default StreamStatsGraph;
