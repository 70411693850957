import { perms } from '../../jcs';
import { webbillingModule } from './index';

function routeConfig($provide, $routeProvider) {
	'ngInject';

	$provide.factory('$routeProvider', () => {
		return $routeProvider;
	});

	$routeProvider.when(webbillingModule.routes.list, {
		controller: webbillingModule.controllers.default,
		template: require('./webbilling.tmpl.html').default,
		activenav: webbillingModule.name,
		access: {
			loginRequired: true,
			permissions: [perms['la1only']],
		},
	});
}

export default routeConfig;
