import axios from 'axios';
import { DateTime } from 'luxon';
import {
  DESTINATION_TYPES,
  baseURL,
  TIME_WINDOW_VALUES,
  GROUP_VALUES,
  centralBaseURL,
  MAX_FETCH_LIMIT_FOR_HISTORICAL_EVENTS,
} from '../../pages/EventAnalytics/event-analytics-constants';
import { EventAnalyticsState } from '../../contexts/eventanalytics/event-analytics';
import { DateType, HistoricalWebEventResponseObject } from '../../pages/EventAnalytics/types';
import { EventsDataAPIResponseType } from './view-events';
import { dateTimeFormatUTC } from '../../pages/EventAnalytics/date-range-options';

export const fetchEventsData = async (
  EventAnalyticsState: EventAnalyticsState,
  page: string,
  dateRange: DateType
): Promise<EventsDataAPIResponseType[]> => {
  let url: string, destinationType: string | null;
  const timeWindow = TIME_WINDOW_VALUES.DAY;
  if (page === DESTINATION_TYPES.YOUTUBE) {
    url = `${baseURL}/${EventAnalyticsState.customerId}/youTube/statistics/${timeWindow}/views/allEvents`;
    destinationType = null;
  } else if (page === DESTINATION_TYPES.FACEBOOK) {
    url = `${baseURL}/${EventAnalyticsState.customerId}/facebook/statistics/${timeWindow}/views/allEvents`;
    destinationType = null;
  } else if (page === DESTINATION_TYPES.ALL_DESTINATIONS) {
    url = `${baseURL}/${EventAnalyticsState.customerId}/allDestinations/statistics/${timeWindow}/views/allEvents`;
    destinationType = null;
  } else {
    url = `${baseURL}/${EventAnalyticsState.customerId}/webevents/statistics/views/allEvents`;
    destinationType = page;
  }
  try {
    const { data } = await axios.get(url, {
      headers: EventAnalyticsState.token,
      params: {
        startDate: DateTime.fromISO(dateRange.startDate).startOf('day').toFormat(dateTimeFormatUTC),
        endDate: DateTime.fromISO(dateRange.endDate).endOf('day').toFormat(dateTimeFormatUTC),
        destinationType,
        segmentBy: GROUP_VALUES.NONE,
      },
    });
    return data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const fetchEventNamesFromEventIdList = async (
  eventIDList: string[],
  customerId: string
): Promise<HistoricalWebEventResponseObject[]> => {
  if (eventIDList.length > 0 && eventIDList.length <= MAX_FETCH_LIMIT_FOR_HISTORICAL_EVENTS) {
    const eventNameAPIURL = `${centralBaseURL}/${customerId}/historicalwebevents?id=${eventIDList.join('&id=')}`;
    try {
      const { data } = await axios.get(eventNameAPIURL, {
        withCredentials: true,
      });
      return data;
    } catch (error) {
      console.error(error);
      return [];
    }
  } else {
    console.error(
      `[ERROR:INVALID INPUT]: Maximum input length is ${MAX_FETCH_LIMIT_FOR_HISTORICAL_EVENTS} and Minimum is 1. Given value is ${eventIDList.length}`
    );
    return [];
  }
};
