export interface CroppedImageInfo {
  croppedImage: Blob | null;
  croppedImageUrl: string;
}

export interface ImageUploadProps {
  destination: UPLOAD_DESTINATION;
  customerId: string;
  label?: string;
  imageUrl?: string;
  webEventId?: string;
  webEventProfileId?: string;
  width?: string;
  showLoading?: boolean;
  getRegion?: () => undefined;
  getRoutingKey?: () => undefined;
  onFilePathChanged?: (filePath?: string, metadata?: ImageMetadata) => void;
  onImageCropped?: () => void;
  onCancel?: () => void;
  onDeleteThumbnails?: () => void;
  onUploadComplete?: () => void;
}

export interface UploadRequestParameters {
  extension: string;
  webEventId?: string;
  webEventProfileId?: string;
  path?: string;
  routingKey?: string;
  region?: string;
}

export interface Area {
  x: number;
  y: number;
  width: number;
  height: number;
}

export interface UploadPOST {
  extension: string;
}

export interface UploadPOSTUrls {
  uploadUrl: string;
  downloadUrl: string;
  filePath: string;
}

export interface ImageMetadata {
  width?: number;
  height?: number;
  type?: string;
}

export interface ImageUploadState {
  file?: File;
  fileSrc: string;
  isUploading: boolean;
  isUploadFinished: boolean;
  uploadProgress: number;
  cropAreaPixels: Area;
  croppedImageInfo: CroppedImageInfo;
  uploadUrls: UploadPOSTUrls;
  imageDeleted: boolean;
  error: string;
  metadata: ImageMetadata;
  retries: number;
  width?: string;
}

export interface Action {
  type: ACTION_TYPE;
  payload: any;
}

export interface ValidationsObject {
  [key: string]: { message: string; invalid: boolean; key: string };
}

export enum ACTION_TYPE {
  FILE = 'FILE',
  FILE_SRC = 'FILE_SRC',
  IS_UPLOADING = 'IS_UPLOADING',
  UPLOAD_FINISHED = 'UPLOAD_FINISHED',
  UPLOAD_PROGRESS = 'UPLOAD_PROGRESS',
  CROP_AREA_PIXELS = 'CROP_AREA_PIXELS',
  CROPPED_IMAGE_INFO = 'CROPPED_IMAGE_INFO',
  UPLOAD_URLS = 'UPLOAD_URLS',
  IMAGE_DELETED = 'IMAGE_DELETED',
  METADATA_CHANGED = 'METADATA_CHANGED',
  RETRIES = 'RETRIES',
  ERROR = 'ERROR',
}

export enum UPLOAD_DESTINATION {
  SOCIAL_MEDIA = 'socialmedia',
  ARCHIVE = 'archive',
}
