import CopyToClipboardButtonComponent from './copyToClipboardButton.component';

export const copyToClipboardButtonModule = {
	name: 'copyToClipboardButtonComponent',
	component: {
		name: 'copyToClipboardButton'
	}
};

export default angular
	.module(copyToClipboardButtonModule.name, [])
	.component(copyToClipboardButtonModule.component.name, new CopyToClipboardButtonComponent()).name;
