import { AxiosResponse } from 'axios';
import React, {FC, useState, useContext, useEffect} from 'react';
import { ModalContent, ModalFooter } from '..';
import { Cue } from '../../helpers/events/types';
import { archiveTrimWebEvent, ArchiveTrimWebEventPayload } from './api';
import { customProperties as vars } from '@resi-media/resi-ui';
import { ArchiveWebEventContext, ACTION_TYPE } from '../ArchiveWebEventModal/context';
import './style.scss';
import { MPEventName, MPEventProperty, trackMixpanelEvent } from '../../mixpanel';
import moment from 'moment';

interface ArchiveTrimWebEventFormProps {
  cues?: Cue[];
  handleCloseModal: () => void;
  webEventProfileId?: string;
  customerId?: string;
  webEventId?: string;
  webEventDuration?: number;
  isWebEventLive?: boolean;
}

enum ArchiveTrimWebEventFormErrors {
  NAME = 'name',
  START_END_CUES = 'start-end-cues',
  TRIM_EVENT_FAILED = 'trim-event-failed',
  ERROR_409 = 'error-409',
}

const ArchiveTrimWebEventForm: FC<ArchiveTrimWebEventFormProps> = ({
  cues,
  handleCloseModal,
  webEventProfileId,
  customerId,
  webEventId,
  webEventDuration,
  isWebEventLive
}): JSX.Element => {
  const [formErrors, setFormErrors] = useState<ArchiveTrimWebEventFormErrors[]>([]);
  const [isButtonDisabled, setDisabled] = useState<boolean>(false);
  const [isButtonLoading, setLoading] = useState<boolean>(false);
  const {state, dispatch} = useContext(ArchiveWebEventContext);

  useEffect(() => {
    if (isWebEventLive) {
      dispatch({
        type: ACTION_TYPE.END_POSITION,
        payload: cues?.length && cues?.length > 0 ? cues[cues.length - 1] : null,
      });
    }
  }, []);

  const handleCreateEvent = async (): Promise<void> => {
    setDisabled(true);
    setLoading(true);
    const errors = [];
    const { startPosition, endPosition, webEventName } = state;
    const published = false;
    const hidden = true;
    const formattedDuration = webEventDuration && moment.utc(webEventDuration * 1000).format('HH:mm:ss');
    const trimmedDuration = endPosition && startPosition && (endPosition.position_in_seconds - startPosition.position_in_seconds);
    const trimmedFormattedDuration = trimmedDuration && moment.utc(trimmedDuration * 1000).format('HH:mm:ss');

    const originalAndTrimmedDurations = {
      formattedDuration, trimmedFormattedDuration
    }

    dispatch({
      type: ACTION_TYPE.WEB_EVENT_DURATIONS,
      payload: originalAndTrimmedDurations,
    });

    if (startPosition && endPosition) {
      if (startPosition.position_in_seconds >= endPosition.position_in_seconds) {
        errors.push(ArchiveTrimWebEventFormErrors.START_END_CUES);
      }
    }

    if (errors.length > 0) {
      setFormErrors(errors);
      setDisabled(false);
      setLoading(false);
      return;
    }

    const archiveWebEventPayload: ArchiveTrimWebEventPayload = {
      webEventName,
      published,
      webEventProfileId,
      hidden,
    };

    if (startPosition) {
      archiveWebEventPayload.startPosition = startPosition.position;
    }

    if (endPosition) {
      archiveWebEventPayload.endPosition = endPosition.position;
    }

    try {
      if (webEventId && customerId) {
        const response: AxiosResponse = await archiveTrimWebEvent(archiveWebEventPayload, customerId, webEventId);
        if (response.status === 200 || response.status === 201) {
          dispatch({
            type: ACTION_TYPE.NEW_WEB_EVENT_LOCATION,
            payload: response.headers.location,
          });
        }
      }
    } catch (error) {
      if (error.response.status === 409) {
        setFormErrors([ArchiveTrimWebEventFormErrors.ERROR_409]);
      }
      else {
        setFormErrors([ArchiveTrimWebEventFormErrors.TRIM_EVENT_FAILED]);
      }
      setDisabled(false);
      setLoading(false);
      console.error(error);
    }

    trackMixpanelEvent(
      MPEventName.LIBRARY_SAVE_TRIM, {
        [MPEventProperty.CUE_START]: startPosition,
        [MPEventProperty.CUE_STOP]: endPosition,
        [MPEventProperty.EVENT_DURATION]: formattedDuration,
        [MPEventProperty.TRIMMED_DURATION]: trimmedFormattedDuration,
        [MPEventProperty.WEB_EVENT_NAME]: webEventName,
        [MPEventProperty.TRANSCODED_EVENT_UUID]: webEventId
      }
    )
  };

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
    const cueByUuid: Cue | undefined = cues?.find((cue) => cue.uuid === event.currentTarget.value);
    validateCue(cueByUuid, event.currentTarget.name);

    if (event.currentTarget.name == 'startPosition'){
      dispatch({
        type: ACTION_TYPE.START_POSITION,
        payload: cueByUuid,
      });
    }
    if (event.currentTarget.name == 'endPosition'){
      dispatch({
        type: ACTION_TYPE.END_POSITION,
        payload: cueByUuid,
      });
    }
  };

  const validateCue = (cue: Cue | undefined, name: string): void => {
    if (cue) {
      if (name == 'startPosition'){
        if(state.endPosition && state.endPosition.position_in_seconds <= cue.position_in_seconds) {
          setFormErrors([ArchiveTrimWebEventFormErrors.START_END_CUES]);
          setDisabled(true);
        } else {
          setFormErrors(formErrors.filter((er) => er !== ArchiveTrimWebEventFormErrors.START_END_CUES));
          setDisabled(false);
        }
      }
      else if (name == 'endPosition'){
        if (state.startPosition && state.startPosition.position_in_seconds >= cue.position_in_seconds) {
          setFormErrors([ArchiveTrimWebEventFormErrors.START_END_CUES]);
          setDisabled(true);
        } else {
          setFormErrors(formErrors.filter((er) => er !== ArchiveTrimWebEventFormErrors.START_END_CUES));
          setDisabled(false);
        }
      }
    } else {
      setFormErrors(formErrors.filter((er) => er !== ArchiveTrimWebEventFormErrors.START_END_CUES));
      setDisabled(false);
    }
  };

  return (
    <div data-testid="archive-web-event-form">
      <ModalContent>
        <div className="resi-trim-archive-progress-progress-description">
          First, before you save your web event to your library, we recommend you trim your content down to the part that matters most.
          This ensures that your audience isn&apos;t sitting through any unintended dead air in the future. Just select cues below!
        </div>
        <div className="resi-trim-archive-progress-progress-description resi-trim-archive-progress-progress-description-bottom">
          Next, you&apos;ll have an opportunity to add or edit any appropriate metadata like a title &amp; description.
        </div>
        <div className="form-horizontal" id="webEventDownloadForm" style={{ width: '75%', margin: 'auto' }}>
          <div className={`form-group`}>
            <label htmlFor="startPosition" className="col-sm-3 control-label">
              Start Cue
            </label>
            <div className="col-sm-9">
              <select
                className="form-control"
                id="startPosition"
                name="startPosition"
                style={{ width: '100%' }}
                defaultValue=""
                data-testid="start-position-select"
                onChange={handleChange}
                onBlur={handleChange}
              >
                <option value="">
                  Beginning of Event
                </option>
                {cues?.map((cue) => {
                  return (
                    <option key={cue.uuid} value={cue.uuid} data-testid={cue.uuid}>
                      {`Cue: ${cue.position} - ${cue.name}`}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="endPosition" className="col-sm-3 control-label">
              Stop Cue
            </label>
            <div className="col-sm-9">
              <select
                className="form-control"
                id="endPosition"
                name="endPosition"
                style={{ width: '100%' }}
                value={state.endPosition?.uuid}
                data-testid="end-position-select"
                onChange={handleChange}
                onBlur={handleChange}
                disabled={isWebEventLive && cues?.length === 0}
              >
                {!isWebEventLive && (<option value="">
                  End of Event
                </option>)}
                {cues?.map((cue) => {
                  return (
                    <option key={cue.uuid} value={cue.uuid} data-testid="select-option">
                      {`Cue: ${cue.position} - ${cue.name}`}
                    </option>
                  );
                })}
              </select>
            </div>
            {(isWebEventLive && cues?.length === 0) && (
                <>
                  <div className="col-sm-3"></div>
                  <div className="col-sm-9">
                    <p style={{ color: vars.colorError, margin: '10px 0 0 4px' }}> Your event is live. Please create an end cue to select.</p>
                  </div>
                </>
            )}
            {formErrors.includes(ArchiveTrimWebEventFormErrors.START_END_CUES) && (
              <>
                <div className="col-sm-3"></div>
                <div className="col-sm-9">
                  <p style={{ color: vars.colorError, margin: '10px 0 0 4px' }}> The start cue must occur before the end cue</p>
                </div>
              </>
            )}
          </div>
          {(state?.startPosition || state?.endPosition || (isWebEventLive && cues?.length !== 0)) && (
            <div className="form-group">
              <div className="col-sm-3 control-label"></div>
              <div className="col-sm-9">
                <div
                  className="well well-sm"
                  style={{ marginTop: 5, marginBottom: 0 }}
                  data-testid="startPositionCueNote"
                >
                  <strong>NOTE:</strong>
                  {` The actual start or stop time may be up to 6 seconds outside the selected position.`}
                </div>
              </div>
            </div>
          )}
          {formErrors.includes(ArchiveTrimWebEventFormErrors.TRIM_EVENT_FAILED) && (
            <div className="form-group">
              <div className="col-sm-3"></div>
              <div className="col-sm-9">
                <p style={{ color: vars.colorError, margin: '0' }}>
                  Unable to trim web event.
                </p>
              </div>
            </div>
          )}
          {formErrors.includes(ArchiveTrimWebEventFormErrors.ERROR_409) && (
            <div className="form-group">
              <div className="col-sm-3"></div>
              <div className="col-sm-9">
                <p style={{ color: vars.colorError, margin: '0' }}>
                  The Web Event Profile is currently in use due to a scheduled or manually started Web Event. Try again when this Web Event Profile is no longer in use.
                </p>
              </div>
            </div>
          )}
        </div>
      </ModalContent>
      <ModalFooter>
        <button data-testid="cancel-btn" className="btn btn-default" onClick={handleCloseModal}>
          Cancel
        </button>
        <button
          disabled={isButtonDisabled || (isWebEventLive && cues?.length === 0)}
          data-testid="create-event-btn"
          className="btn btn-primary"
          onClick={handleCreateEvent}
        >
          {isButtonLoading &&
            <i className="fa fa-gear fa-spin" aria-hidden="true" style={{ marginRight: 7 }}></i>
          }
          Next
        </button>
      </ModalFooter>
    </div>
  );
};

ArchiveTrimWebEventForm.displayName = 'ArchiveTrimWebEventForm';

export default ArchiveTrimWebEventForm;
