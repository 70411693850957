import axios, { AxiosResponse } from 'axios';
import {
  AUTH_NAME,
  AUTH_CUSTOMER_NAME,
  AUTH_CUSTOMER_ID,
  AUTH_PERMS,
  AUTH_TOGGLES,
  AUTH_USER_ID,
  AUTH_TOKEN,
} from '../../../v2.snapshot/js/constants';
import { api, onboardingApi } from '../../../v2.snapshot/js/jcs';
import { User } from "../../constants";

export interface EmailTokenValidation {
  adminUserId: string;
  adminUserEmail: string;
  passwordResetToken: string;
}

export function validateEmailToken(token: string): Promise<AxiosResponse<EmailTokenValidation>> {
  return axios.post(`${onboardingApi.v1}/onboarding/customers?data=${token}`);
}

export function logout(): Promise<AxiosResponse<void>> {
  sessionStorage.removeItem(AUTH_NAME);
  sessionStorage.removeItem(AUTH_CUSTOMER_NAME);
  sessionStorage.removeItem(AUTH_CUSTOMER_ID);
  sessionStorage.removeItem(AUTH_PERMS);
  sessionStorage.removeItem(AUTH_TOGGLES);
  sessionStorage.removeItem(AUTH_USER_ID);
  sessionStorage.removeItem(AUTH_TOKEN);
  return axios.post(`${api.url_v3}/logout`, undefined, { withCredentials: true });
}

export async function getUser(): Promise<User> {
  const user = await axios.get(`${api.url}/users/me`, {withCredentials: true});
  return user.data;
}