'use strict';

const jcs = require('../../jcs');
const moment = require('moment');

function OrdersController($scope, $http, focus) {
	'ngInject';
	$(window).trigger('resize'); // ensure footer is properly positioned

	$scope.DATE_FORMAT = 'MM/DD/YY';

	$scope.is_loading = false;
	$scope.error_msg = null;

	$scope.backorders = null;
	$scope.rentals = null;

	$scope.rental_totals = null;
	$scope.backorder_totals = null;

	$scope.formatDate = function (date_to_format) {
		if (date_to_format == null) return '';

		return moment(date_to_format).format($scope.DATE_FORMAT);
	};

	$scope.getPriorityAsText = function (priority) {
		if (priority == null) return '';

		if (priority == 1) return '1 - Low';
		if (priority == 2) return '2 - Medium';
		if (priority == 3) return '3 - High';
		if (priority == 4) return '4 - Urgent';
		return priority + ' - Unknown';
	};

	$scope.getPriorityClass = function (priority) {
		if (priority == 1) return 'status-block-low';
		if (priority == 2) return 'status-block-medium';
		if (priority == 3) return 'status-block-high';
		if (priority == 4) return 'status-block-urgent';
		return '';
	};

	$scope.getAgeClass = function (age) {
		if (age == null) return '';

		if (age >= 14) return 'status-block-urgent';
		if (age >= 7) return 'status-block-high';
		return 'status-block-low';
	};

	$scope.getShipAgeClass = function (age) {
		if (age == null) return '';

		if (age <= 3) return 'status-block-urgent';
		if (age <= 6) return 'status-block-high';
		return 'status-block-low';
	};

	$scope.getArriveAgeClass = function (age) {
		if (age == null) return '';

		if (age <= 3) return 'status-block-urgent';
		if (age <= 6) return 'status-block-high';
		return 'status-block-low';
	};

	// return text after last slash
	// For https://livingasone.freshdesk.com/a/tickets/4532
	// we return just "4532"
	$scope.getShortTicketUrl = function (url) {
		// look for slash
		var lastIndex = url.lastIndexOf('/');
		if (lastIndex != -1) {
			return url.substring(lastIndex + 1);
		}
		return url;
	};

	$scope.getUnitsAsInt = function (value) {
		var value_as_int = Number.parseInt(value);
		return isNaN(value_as_int) ? 0 : value_as_int;
	};

	$scope.updateOrderTotals = function (totals, order) {
		var d2200 = $scope.getUnitsAsInt(order.d2200);
		var d2201 = $scope.getUnitsAsInt(order.d2201);
		var d2202 = $scope.getUnitsAsInt(order.d2202);
		var e1200 = $scope.getUnitsAsInt(order.e1200);
		var e1210 = $scope.getUnitsAsInt(order.e1210);
		var e2211 = $scope.getUnitsAsInt(order.e2211);
		var e2221 = $scope.getUnitsAsInt(order.e2221);
		var e4300 = $scope.getUnitsAsInt(order.e4300);

		totals.d2200 += d2200;
		totals.d2201 += d2201;
		totals.d2202 += d2202;
		totals.e1200 += e1200;
		totals.e1210 += e1210;
		totals.e2211 += e2211;
		totals.e2221 += e2221;
		totals.e4300 += e4300;
		totals.total += d2200 + d2201 + d2202 + e1200 + e1210 + e2211 + e2221 + e4300;
	};

	$scope.loadOrders = function () {
		$scope.is_loading = true;

		// fetch our encoders that are available for onboarding
		$http.get(jcs.api.internal_url + '/freshdesk/tickets?groupId=17000121643&type=Ship Request', {withCredentials: true,}).then(
			function (response) { // success

				$scope.backorders = [];
				$scope.rentals = [];

				$scope.rental_totals = { total: 0, d2200: 0, d2201: 0, d2202: 0, e1200: 0, e1210: 0, e2211: 0, e2221: 0, e4300: 0 };
				$scope.backorder_totals = { total: 0, d2200: 0, d2201: 0, d2202: 0, e1200: 0, e1210: 0, e2211: 0, e2221: 0, e4300: 0 };

				var now = moment();
				// separate orders into backorders and upcoming rentals
				for (var i = 0; i < response.data.length; i++) {
					var order = response.data[i];

					// calculate age
					if (order.createdAt == null) {
						order.age = null;
					} else {
						var order_created_date = moment(order.createdAt);
						// strip off everything but the date
						var order_created_date_only = moment(order_created_date.format('YYYY-MM-DD'), 'YYYY-MM-DD');
						// determine diff between order date and now, convert number to integer (drop fraction)
						var duration = moment.duration(now.diff(order_created_date_only));
						order.age = Number.parseInt(duration.as('days'));
					}

					// determine diff between ship by date and now
					if (order.shipNeedBy == null) {
						order.ship_age = null;
					} else {
						var ship_need_by_date = moment(order.shipNeedBy, 'YYYY-MM-DD');
						var ship_duration = moment.duration(ship_need_by_date.diff(now));
						order.ship_age = Number.parseInt(ship_duration.as('days')) + 1; // it is showing tomorrow as zero so add 1
					}

					// determine diff between arrive by date and now
					if (order.arriveBy == null) {
						order.arrive_age = null;
					} else {
						var arrive_by_date = moment(order.arriveBy, 'YYYY-MM-DD');
						var arrive_duration = moment.duration(arrive_by_date.diff(now));
						order.arrive_age = Number.parseInt(arrive_duration.as('days')) + 1; // it is showing tomorrow as zero so add 1
					}

					// determine which list to add to
					if (order.shipRental) {
						$scope.rentals.push(order);
						$scope.updateOrderTotals($scope.rental_totals, order);
					} else {
						$scope.backorders.push(order);
						$scope.updateOrderTotals($scope.backorder_totals, order);
					}
				}
			},
			function () { // error

				$scope.error_msg = 'Unable to retrieve list of hardware orders. Please try again, or report the problem if it persists.';
				$scope.backorders = [];
				$scope.rentals = [];

			})['finally'](function () { // always called

				$scope.is_loading = false;

			});
	};

	//
	// initialize by loading our list of encoders available for onboarding
	//
	$scope.loadOrders();
}

module.exports = OrdersController;
