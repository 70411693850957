import React, { FC, useReducer, Reducer, useEffect } from 'react';
import PropTypes from 'prop-types';
import GetStartedContext, {
  getStartedReducer,
  initialGetStartedState,
  GetStartedContextState,
  GetStartedAction,
  GET_STARTED_PAGES,
  GET_STARTED_ACTIONS,
} from '../../contexts/get-started';
import { validateEmailToken, logout, getUser } from './api';
import { resetPassword } from '../PasswordSet/api';
import { EventProfileOnboarding, SetPassword, Loading } from '../../components';
import { theme, ThemeProvider } from '@resi-media/resi-ui';

interface GetStartedPageProps {
  token?: string;
}

const GetStartedPage: FC<GetStartedPageProps> = ({ token }): JSX.Element => {
  const [state, dispatch] = useReducer<Reducer<GetStartedContextState, GetStartedAction<any>>>(
    getStartedReducer,
    initialGetStartedState
  );

  useEffect(() => {
    (async (): Promise<void> => {
      if (!token) {
        if (!state.user || !state.user.customerId) {
          dispatch({ type: GET_STARTED_ACTIONS.USER, payload: await getUser() });
        }
        dispatch({ type: GET_STARTED_ACTIONS.PAGE, payload: GET_STARTED_PAGES.EVENT_PROFILES });
        return;
      }
      await logout();
      try {
        const response = await validateEmailToken(token);
        dispatch({ type: GET_STARTED_ACTIONS.EMAIL_VALIDATION, payload: response.data });
        // pause for 3/4 of a second to avoid loading screen flash
        setTimeout(() => {
          if (response.data.passwordResetToken === null) {
            window.location.assign('/encoders');
          } else {
            dispatch({ type: GET_STARTED_ACTIONS.PAGE, payload: GET_STARTED_PAGES.SET_PASSWORD });
          }
        }, 750);
      } catch (error) {
        if (error.response.status === 409) {
          dispatch({
            type: GET_STARTED_ACTIONS.ERROR,
            payload: {
              errorMessage: 'Your account has been validated already.',
              errorLinkText: 'Click here to login.',
              onClick: (): void => {
                window.location.assign('/login');
              },
            },
          });
        } else {
          dispatch({
            type: GET_STARTED_ACTIONS.ERROR,
            payload: { errorMessage: 'There was an error validating your email.' },
          });
          console.error(error);
        }
      }
    })();
  }, [token]);

  const setPasswordSubmit = async (password: string): Promise<void> => {
    dispatch({ type: GET_STARTED_ACTIONS.PASSWORD, payload: password });

    const response = await resetPassword(state.emailTokenValidation.passwordResetToken, password);
    dispatch({ type: GET_STARTED_ACTIONS.USER, payload: response.data });
    dispatch({ type: GET_STARTED_ACTIONS.PAGE, payload: GET_STARTED_PAGES.EVENT_PROFILES });
  };

  return (
    <ThemeProvider theme={theme}>
      <GetStartedContext.Provider value={{ state, dispatch }}>
        {state.page === GET_STARTED_PAGES.LOADING && (
          <Loading
            header="Validating Email..."
            subheader="Please wait while we access your account."
            error={state.error}
          />
        )}
        {state.page === GET_STARTED_PAGES.SET_PASSWORD && (
          <SetPassword
            email={state.emailTokenValidation.adminUserEmail}
            header="Set Password"
            subheader="Only a few more steps before you can begin streaming."
            buttonLabel="Continue to setup"
            onSubmit={setPasswordSubmit}
          />
        )}
        {state.page === GET_STARTED_PAGES.EVENT_PROFILES && <EventProfileOnboarding />}
        </GetStartedContext.Provider>
      </ThemeProvider>
  );
};

GetStartedPage.displayName = 'GetStartedPage';
GetStartedPage.propTypes = {
  token: PropTypes.string.isRequired,
};

export default GetStartedPage;
