'use strict';

class UpdateCueCtrl {

	constructor() {
		'ngInject';
	}
}

export default class UpdateCueComponent {
	constructor() {
		this.template = require('./updateCue.html').default;
		this.bindings = {
			cue: '<',
			newPosition: '<',
			isBusy: '<',
			onSubmit: '&',
			onCancel: '&',
		};
		this.controller = UpdateCueCtrl;
	}
}
