import axios, { AxiosResponse } from 'axios';
import { api } from '../../../v2.snapshot/js/jcs';
import { NewEncoderEventProfilePayload, NewWebEventProfilePayload, NewDestinationGroupPayload } from './index';
import {
  AUTH_NAME,
  AUTH_CUSTOMER_NAME,
  AUTH_CUSTOMER_ID,
  AUTH_PERMS,
  AUTH_TOGGLES,
  AUTH_USER_ID,
} from '../../../v2.snapshot/js/constants';
import { User } from '../../constants';
import * as rax from 'retry-axios';

export interface EncoderEventProfileResponse {
  uuid: string;
  bucket: string;
  name: string;
  description: string;
  deleteAfter: number;
  lanOnly: boolean;
  regionId: string;
}

export interface WebEventProfileResponse {
  uuid: string;
  name: string;
  description: string;
}

export interface FirstTimeSetupResponse {
  hasContact: boolean;
  hasEncoder: boolean;
  hasEventProfile: boolean;
  hasWebEventProfile: boolean;
  hasWebPlan: boolean;
}

export async function createEncoderEventProfile(payload: NewEncoderEventProfilePayload): Promise<AxiosResponse | null> {
  const axiosInstance = axios.create();

  let response;
  try {
    rax.attach(axiosInstance);
    response = await axiosInstance.post(`${api.url}/streamprofiles/`, payload, {
      withCredentials: true,
      raxConfig: {
        instance: axiosInstance,
        retry: 2,
        statusCodesToRetry: [
          [429, 429],
          [503, 503],
        ],
        backoffType: 'exponential',
        onRetryAttempt: (err): void => {
          const cfg = rax.getConfig(err);
          console.error(`Retry attempt #${cfg?.currentRetryAttempt}`);
        },
      },
    });
  } catch (e) {
    console.error(e);
  }
  return axios.get(response?.headers['location'], { withCredentials: true });
}

export async function createWebEventProfile(
  payload: NewWebEventProfilePayload,
  customerId: string
): Promise<AxiosResponse | null> {
  const axiosInstance = axios.create();
  let response;

  try {
    rax.attach(axiosInstance);
    response = await axiosInstance.post(`${api.url_v3}/customers/${customerId}/webeventprofiles`, payload, {
      withCredentials: true,
      raxConfig: {
        instance: axiosInstance,
        retry: 2,
        statusCodesToRetry: [
          [429, 429],
          [503, 503],
        ],
        backoffType: 'exponential',
        onRetryAttempt: (err): void => {
          const cfg = rax.getConfig(err);
          console.error(`Retry attempt #${cfg?.currentRetryAttempt}`);
        },
      },
    });
  } catch (e) {
    console.error(e);
  }
  return axios.get(response?.headers['location'], { withCredentials: true });
}

export async function createDestinationGroup(
  payload: NewDestinationGroupPayload,
  customerId: string
): Promise<AxiosResponse | null> {
  const axiosInstance = axios.create();

  try {
    rax.attach(axiosInstance);
    return await axiosInstance.post(`${api.url_v3}/customers/${customerId}/destinationgroups`, payload, {
      withCredentials: true,
      raxConfig: {
        instance: axiosInstance,
        retry: 2,
        statusCodesToRetry: [
          [429, 429],
          [503, 503],
        ],
        backoffType: 'exponential',
        onRetryAttempt: (err): void => {
          const cfg = rax.getConfig(err);
          console.error(`Retry attempt #${cfg?.currentRetryAttempt}`);
        },
      },
    });
  } catch (e) {
    console.error(e);
  }
  return null;
}

export async function refreshCredentials(): Promise<void> {
  const response: AxiosResponse<User> = await axios.get(`${api.url}/users/me`, { withCredentials: true });

  // save in session storage so angular side will know user is logged in
  if (response.data && response.data.userName) {
    const { userName, capabilities, toggles, userId, customerName, customerId } = response.data;
    sessionStorage.setItem(AUTH_NAME, userName);
    sessionStorage.setItem(AUTH_CUSTOMER_NAME, customerName || '');
    sessionStorage.setItem(AUTH_CUSTOMER_ID, customerId);
    sessionStorage.setItem(AUTH_PERMS, JSON.stringify(capabilities));
    sessionStorage.setItem(AUTH_TOGGLES, JSON.stringify(toggles));
    sessionStorage.setItem(AUTH_USER_ID, userId);
  }
}

export async function getFirstTimeSetup(customerId: string): Promise<AxiosResponse | null> {
  try {
    return await axios.get(`${api.url_v3}/customers/${customerId}/fts`, { withCredentials: true });
  } catch (e) {
    console.error(e);
  }
  return null;
}
