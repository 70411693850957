import EventBus from '../../core';
import { api, perms } from '../../../jcs';
import { authModule } from '../index';
import {
	AUTH_NAME,
	AUTH_CUSTOMER_NAME,
	AUTH_CUSTOMER_ID,
	AUTH_PERMS,
	AUTH_TOGGLES,
	AUTH_USER_ID,
	AUTH_TOKEN,
	AUTH_SYNTHETIC_TOGGLES,
} from '../../../constants';
import {
	trackMixpanelEvent,
	identifyMixpanelUser,
	mixpanelRegister,
	setMixpanelUserProps,
	setMixpanelGroupProperty,
	setMixpanelGroup,
	resetMixpanelUser,
	MPEventProperty,
	MPEventName,
	MPBuiltInProps,
} from '../../../../../src/mixpanel';

// We retrieve the User's authentication details from session storage on page refresh.

class Authentication {
	authCookie = 'livingas1';
	

	constructor($rootScope, $http, $location, EventBus, launchdarklyService) {
		'ngInject';
		const currentUser = null;
		this.$rootScope = $rootScope;
		this.$http = $http;
		this.$location = $location;
		this.EventBus = EventBus;
		this.syntheticToggles = [];
		this.launchdarklyService = launchdarklyService;
	}

	createUser(name, permissions, toggles, userID, customerName, customerID) {
		return {
			name,
			permissions, // this is a list of the users capabilities
			toggles, // beta features
			userID,
			customerName, // currently this is only provided if sudoLogin is used
			customerID,
			// Note: hasPerm takes a permission string because I was unable to access the jcs.js constants from the ng-show
			// statements inside the html files. So instead the html file will just pass a string that we will use to lookup
			// what the capability code is using the jcs.perms constants.
			hasPerm: permission => {
				// convert the given permission string into a capability
				const capability_perm_code = perms[permission];

				if (capability_perm_code != null) {
					// look through our list of permissions for a match
					for (let i = 0; i < permissions.length; i++) {
						if (permissions[i] == capability_perm_code) {
							return true;
						}
					}
				} else {
					console.log(`Unable to find permission for: ${permission}`);
				}
				return false;
			},
			hasToggle: toggle => {
				// look through our list of toggles for a match
				if (typeof toggles != 'undefined' && toggles instanceof Array) {
					for (var i = 0; i < toggles.length; i++) {
						if (toggles[i] == toggle) return true;
					}
				}
				return this.syntheticToggles.includes(toggle);
			},
		};
	}

	login(email, password) {
		return this.$http
			.post(`${api.login_url}/login?newToken=true`, { userName: email, password: password }, { withCredentials: true })
			.then(response => {
				const permissions = response.data.capabilities;
				const toggles = response.data.toggles;
				const userID = response.data.userId;
				const customerID = response.data.customerId;
				const customerName = response.data.customerName;
				this.currentUser = this.createUser(email, permissions, toggles, userID, customerName, customerID);
				// save user info to session storage (that way we stay logged in if user refreshes the page)
				sessionStorage.setItem(AUTH_NAME, email);
				sessionStorage.setItem(AUTH_CUSTOMER_NAME, customerName);
				sessionStorage.setItem(AUTH_CUSTOMER_ID, customerID);
				sessionStorage.setItem(AUTH_PERMS, JSON.stringify(permissions));
				sessionStorage.setItem(AUTH_TOGGLES, JSON.stringify(toggles));
				sessionStorage.setItem(AUTH_USER_ID, userID);

				this.getAuthToken();
				this.trackMixpanelLogin(this.currentUser);
				this.launchdarklyService.trackLaunchDarkly(userID, email, customerID, customerName);

				this.EventBus.broadcast(authModule.events.userLoggedIn, this.currentUser);
			})
			.catch(result => {
				// called asynchronously if an error occurs or server returns response with an error status.
				console.log(result);
				throw new Error('Invalid username and/or password.');
			});
	}

	sudoLogin(email, password, customerID) {
		return this.$http
			.post(
				`${api.login_url}/login?newToken=true`,
				{ userName: email, password: password, sudoCustomer: customerID },
				{ withCredentials: true }
			)
			.then(response => {
				const permissions = response.data.capabilities;
				const toggles = response.data.toggles;
				const userID = response.data.userId;
				const customerName = response.data.customerName;

				this.currentUser = this.createUser(email, permissions, toggles, userID, customerName, customerID);
				// save user info to session storage (that way we stay logged in if user refreshes the page)
				sessionStorage.setItem(AUTH_NAME, email);
				sessionStorage.setItem(AUTH_CUSTOMER_NAME, customerName);
				sessionStorage.setItem(AUTH_CUSTOMER_ID, customerID);
				sessionStorage.setItem(AUTH_PERMS, JSON.stringify(permissions));
				sessionStorage.setItem(AUTH_TOGGLES, JSON.stringify(toggles));
				sessionStorage.setItem(AUTH_USER_ID, userID);

				this.getAuthToken();
				this.EventBus.broadcast(authModule.events.userLoggedIn, this.currentUser);
				this.launchdarklyService.trackLaunchDarkly(userID, email, customerID, customerName);
				return this.currentUser;
			})
			.catch(result => {
				// called asynchronously if an error occurs or server returns response with an error status.
				console.log(result);
				throw new Error('Invalid username and/or password.');
			});
	}

	logout() {
		// we should only remove the current user.
		this.currentUser = null;
		// remove session storage data
		sessionStorage.removeItem(AUTH_NAME);
		sessionStorage.removeItem(AUTH_CUSTOMER_NAME);
		sessionStorage.removeItem(AUTH_CUSTOMER_ID);
		sessionStorage.removeItem(AUTH_PERMS);
		sessionStorage.removeItem(AUTH_TOGGLES);
		sessionStorage.removeItem(AUTH_USER_ID);
		sessionStorage.removeItem(AUTH_TOKEN);
		sessionStorage.removeItem(AUTH_SYNTHETIC_TOGGLES);

		trackMixpanelEvent(MPEventName.USER_LOGOUT);
		resetMixpanelUser();

		this.$http.post(`${api.url_v3}/logout`, {}, { withCredentials: true })
			.catch(result => {
				console.log('There was an error logging out of API.');
				console.log(result);
			});

		// clear 406 error message if it is displayed
		this.$rootScope.status_406 = false;

		// note: while we are broadcasting a message, I don't think anyone is currently listening, so this doesn't do anything at
		// the moment. The only reason the signout button ends up on the login page is b/c the ng-href attribute on the link.
		this.EventBus.broadcast(authModule.events.userLoggedOut);

		// redirect to login page. Original developer said "routing back to login login page is something we shouldn't do here as we
		// are mixing responsibilities if we do" ... but going to do it anyway for now. Currently there is no one registered to listen
		// for logout messages and not sure where the best place to put that listener would be. This will be fine for now.
		// (I need this for when a user rename's their self, and the server returns 401, which means they need to log back in)
		this.$location.path(authModule.routes.login);

		this.syntheticToggles = [];
		this.launchdarklyService.trackLaunchDarkly('Anonymous');
	}

	getCurrentLoginUser() {
		// if our current user is undefined, see if we can restore user info from session storage (this will occur if the user presses F5)
		//console.log("Authentication::getCurrentLoginUser currentUser:");
		//console.log(currentUser);
		if (!this.currentUser) {
			// attempt to restore user from session storage
			const name = sessionStorage.getItem(AUTH_NAME);
			//console.log("Authentication::getCurrentLoginUser restoring user from session storage
			// only bother getting permissions if name is valid
			if (name && name.length > 0) {
				const permissions = JSON.parse(sessionStorage.getItem(AUTH_PERMS));
				const toggles = JSON.parse(sessionStorage.getItem(AUTH_TOGGLES));
				const userID = sessionStorage.getItem(AUTH_USER_ID);
				const customerName = sessionStorage.getItem(AUTH_CUSTOMER_NAME);
				const customerID = sessionStorage.getItem(AUTH_CUSTOMER_ID);
				this.syntheticToggles = JSON.parse(sessionStorage.getItem(AUTH_SYNTHETIC_TOGGLES)) ?? [];
				this.currentUser = this.createUser(name, permissions, toggles, userID, customerName, customerID);
			}
		}
		return this.currentUser;
	}

	getCurrentUser() {
		return this.getCurrentLoginUser();
	}

	async getAuthToken() {
		try {
			const response = await this.$http.get(`${api.url}/token`, { withCredentials: true });
			sessionStorage.setItem(AUTH_TOKEN, response.data.token);
		} catch(e) {
			console.error(e);
		}
	}

	trackMixpanelLogin(currentUser) {
		try {
			identifyMixpanelUser(currentUser.userID);
			setMixpanelUserProps({ [MPBuiltInProps.NAME]: currentUser.name, [MPEventProperty.USER_PERMISSIONS]: currentUser.permissions });
			mixpanelRegister({ [MPEventProperty.ORG_UUID]: currentUser.customerID, [MPEventProperty.ORG_NAME]: currentUser.customerName, [MPEventProperty.USER_NAME]: currentUser.name });
			setMixpanelGroup(currentUser.customerID);
			trackMixpanelEvent(MPEventName.USER_LOGIN);
			setMixpanelGroupProperty(MPEventProperty.ORG_UUID, currentUser.customerID, { [MPBuiltInProps.NAME]: currentUser.customerName });
		} catch(err) {
			console.error(err);
		}
	}
}

export default Authentication;
