/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import { FC, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { BlockButton, PasswordField, CheckboxInput, Stack, customProperties as vars } from '@resi-media/resi-ui';
import { LockOutlined, LoadingOutlined } from '@ant-design/icons';
import {
  PasswordStrengthDescriptor,
  PasswordStrengthSegment,
  PasswordStrengthWrapper,
  Text,
  ToolTipText,
} from './style';
import ResiLayout from '../ResiLayout';
import { BoxHeader, BoxWrap, BoxDescription } from '../Styled';
import { BlockError } from '../../components';

interface Window {
  zxcvbn: () => {
    score: number;
  };
}

interface SetPasswordProps {
  email?: string;
  header: string;
  subheader: string;
  buttonLabel: string;
  error?: string;
  onSubmit: (password: string) => void;
}

// as an object so we can mock the function in tests
export const zxcvbnFns = {
  init(): void {
    if (!document.getElementById('zxcvbn-script')) {
      const script = document.createElement('script');
      script.setAttribute('src', 'https://cdnjs.cloudflare.com/ajax/libs/zxcvbn/4.4.2/zxcvbn.js');
      script.id = 'zxcvbn-script';
      document.body.appendChild(script);
    }
  },
};

const SetPassword: FC<SetPasswordProps> = ({ email, header, subheader, buttonLabel, error, onSubmit }): JSX.Element => {
  const SetPasswordSchema = Yup.object().shape({
    password: Yup.string()
      .required('Please enter a password')
      .min(10, 'Password must contain a minumum of 10 characters'),
    confirmPassword: Yup.string()
      .required('Please confirm your password')
      .oneOf([Yup.ref('password')], 'Passwords must match'),
    passwordStrength: Yup.number().min(3, 'Please choose a stronger password'),
  });

  const initialValues = {
    password: '',
    confirmPassword: '',
    showPassword: false,
    passwordStrength: 0,
  };

  useEffect(() => {
    zxcvbnFns.init();
  }, []);

  return (
    <ResiLayout>
      <Formik
        validationSchema={SetPasswordSchema}
        initialValues={initialValues}
        onSubmit={(values) => onSubmit(values.password)}
      >
        {(formik): JSX.Element => {
          const { passwordStrength } = formik.values;
          const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
            if (!window.zxcvbn) {
              console.warn('zxcvbn has not loaded');
              return;
            }
            const passwordScore = window.zxcvbn(event.target.value).score;
            formik.setFieldValue('passwordStrength', passwordScore);
            formik.handleChange(event);
          };

          const passwordStrengthColor = (): string => {
            switch (passwordStrength) {
              case 0:
                return vars.colorAccent500;
              case 1:
                return vars.colorError;
              case 2:
                return vars.colorWarning;
              case 3:
                return '#95DE64';
              case 4:
                return vars.colorSuccess;
              default:
                return vars.colorAccent500;
            }
          };

          const passwordStrengthDescriptorText = ():
            | 'Very Weak'
            | 'Weak'
            | 'Fair'
            | 'Strong'
            | 'Very Strong'
            | undefined => {
            switch (passwordStrength) {
              case 0:
                return 'Very Weak';
              case 1:
                return 'Weak';
              case 2:
                return 'Fair';
              case 3:
                return 'Strong';
              case 4:
                return 'Very Strong';
            }
          };

          return (
            <BoxWrap>
              <form onSubmit={formik.handleSubmit}>
                <Stack scale="xl">
                  <Stack scale="m">
                    <Stack scale="m">
                      <BoxHeader>{header}</BoxHeader>
                      <BoxDescription>{subheader}</BoxDescription>
                    </Stack>
                    {error && <BlockError>{error}</BlockError>}
                    <Stack scale="s">
                      {email && email.length > 0 && (
                        <Stack scale="xs">
                          <Text isUppercase isBold fontSize={vars.fontSize10}>
                            Email
                          </Text>
                          <Text fontSize={vars.fontSize14}>{email}</Text>
                        </Stack>
                      )}
                      <Stack scale="s">
                        <PasswordField
                          value={formik.values.password}
                          title="Password"
                          name="password"
                          touched={formik.touched.password}
                          onChange={handlePasswordChange}
                          onBlur={formik.handleBlur}
                          error={formik.errors.password || formik.errors.passwordStrength}
                          isPasswordVisible={formik.values.showPassword}
                          leftIcon={<LockOutlined />}
                          data-testid="password-field"
                          hint={
                            <ToolTipText>
                              A strong password consists of at least 10 characters, numbers, and upper and lowercase
                              letters.
                            </ToolTipText>
                          }
                          hintPosition="top-right"
                        />
                        <div>
                          <PasswordStrengthWrapper>
                            <PasswordStrengthSegment
                              css={css`
                                background-color: ${passwordStrengthColor()};
                              `}
                              data-testid="password-strength-color"
                            />
                            <PasswordStrengthSegment
                              css={css`
                                background-color: ${passwordStrength >= 2
                                  ? passwordStrengthColor()
                                  : vars.colorAccent500};
                              `}
                            />
                            <PasswordStrengthSegment
                              css={css`
                                background-color: ${passwordStrength >= 3
                                  ? passwordStrengthColor()
                                  : vars.colorAccent500};
                              `}
                            />
                            <PasswordStrengthSegment
                              css={css`
                                background-color: ${passwordStrength === 4
                                  ? passwordStrengthColor()
                                  : vars.colorAccent500};
                              `}
                            />
                          </PasswordStrengthWrapper>
                          <PasswordStrengthDescriptor
                            css={css`
                              color: ${passwordStrength === 0 ? vars.colorAccent600 : passwordStrengthColor()};
                            `}
                            data-testid="password-strength-descriptor"
                          >
                            {passwordStrengthDescriptorText()}
                          </PasswordStrengthDescriptor>
                        </div>
                      </Stack>
                    </Stack>
                    <PasswordField
                      value={formik.values.confirmPassword}
                      title="Confirm Password"
                      name="confirmPassword"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.errors.confirmPassword}
                      touched={formik.touched.confirmPassword}
                      isPasswordVisible={formik.values.showPassword}
                      leftIcon={<LockOutlined />}
                      data-testid="confirm-password-field"
                    />
                    <CheckboxInput
                      label="Show password"
                      isChecked={formik.values.showPassword}
                      onClick={(): void => formik.setFieldValue('showPassword', !formik.values.showPassword)}
                    />
                  </Stack>
                  <BlockButton
                    label={buttonLabel}
                    type="submit"
                    isToggleButton={false}
                    iconLeft={formik.isSubmitting ? <LoadingOutlined /> : undefined}
                    isDisabled={formik.isSubmitting || !formik.dirty || !formik.isValid}
                  />
                </Stack>
              </form>
            </BoxWrap>
          );
        }}
      </Formik>
    </ResiLayout>
  );
};

SetPassword.propTypes = {
  email: PropTypes.string,
  header: PropTypes.string.isRequired,
  subheader: PropTypes.string.isRequired,
  buttonLabel: PropTypes.string.isRequired,
  error: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
};

SetPassword.displayName = 'SetPassword';

export default SetPassword;
