'use strict';

const jcs = require('../../jcs');
const moment = require('moment');
const momentTimeZones = require('moment-timezone');
const constants = require('../../constants');
const { trackMixpanelEvent, MPEventProperty, MPEventName} = require('../../../../src/mixpanel');

function ScheduleController($scope, $timeout, $location, $http, $q, focus, httpService, socialMedia, webEventProfileService, Authentication, timeZoneService, encoderService) {
	'ngInject';
	$(window).trigger('resize'); // ensure footer is properly positioned

	$scope.has_social_media_perm = Authentication.getCurrentUser().hasPerm('social_media.get');

	$scope.SCHEDULE_NAME_MAX_LENGTH = 50;

	$scope.SOCIAL_MEDIA_EVENT_STREAM_NOW_LABEL = 'Stream Now';
	$scope.DESTINATION_TYPE_FB_PAGE = 'fb_page';

	$scope.DEFAULT_MONTHS_TO_INIT = 3;
	$scope.ADDITIONAL_MONTHS_TO_LOAD = 3;

	$scope.API_PATH_WEB_EVENT_SCHEDULE = '/webeventschedules/';

	$scope.SCHEDULE_TYPE_ENCODER = 'Live Schedule';
	$scope.SCHEDULE_TYPE_SIM_LIVE = 'Sim-Live Schedule';

	$scope.YES = 'Yes';
	$scope.NO = 'No';

	$scope.TODAY = moment();
	$scope.DATE_FORMAT = 'YYYY-MM-DD';
	$scope.TIME_12H_FORMAT = 'h:mm A';
	$scope.TIME_24H_FORMAT = 'HH:mm';
	$scope.DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm';
	$scope.DATE_TIME_MERIDIEM_FORMAT = 'YYYY-MM-DD H:mm A';
	$scope.NEVER_END_DATE = '2040-12-31'; // date way off in future that we will use for repeating events that "never end"

	$scope.social_media = socialMedia.init();
	$scope.timeZoneService = timeZoneService;

	$scope.current_time_zone = null; // used to auto select timezone when creating new schedules

	$scope.months = null;
	$scope.upcoming_encoder_events = null;
	$scope.upcoming_web_events = null;
	$scope.calendar_error = null;
	$scope.is_loading_calendar = false;

	$scope.calendar_end_date = null; // the final day of the last visible month on the calendar page
	$scope.calendar_next_month_error = null;
	$scope.is_busy_loading_next_month = false;

	$scope.calendar_start_date = null; // gets set if we show events for past dates
	$scope.calendar_prev_events_error = null;
	$scope.is_loading_past_events = false;
	$scope.is_loading_prev_month = false;

	$scope.event_to_view = null;
	$scope.is_loading_view_event = false;
	$scope.view_event_error = null;

	$scope.show_add_event = false;
	$scope.reg_event_to_add = null;
	$scope.web_event_to_add = null; // transcoded event
	$scope.add_schedule_selected_date = null;
	$scope.schedule_to_add_type = null; // regular or transcoded?
	$scope.hide_schedule_type = false; // sometimes we need to hide Schedule Type dropdown if user doesn't have certain permissions
	$scope.show_update_event = false;
	$scope.is_busy_saving_event = false;
	$scope.add_update_event_error = null; // used for encoder schedule errors
	$scope.add_update_event_errors = []; // used for web event errors

	$scope.show_web_event_schedule_warning = false;

	// this is for web events added to a encoder schedule
	$scope.add_web_event_to_encoder_schedule = false;
	$scope.web_event_to_add_to_encoder_schedule = null;

	$scope.event_to_delete = null;
	$scope.is_busy_deleting_event = false;
	$scope.delete_event_error = null;

	// form validation errors for add/update event form
	$scope.add_event_type_error = false;
	$scope.add_event_form_validation_error = null;
	$scope.add_web_event_to_encoder_schedule_error = null;

	// validation error object: if the field isn't there, no validation class will be added; We can reset validation by simply assigning a new empty object.
	$scope.has_error = {};

	$scope.temp_id_counter = 1;

	$scope.social_media_acct_list = null;
	$scope.is_loading_social_media_accts = false;
	$scope.social_media_form = null;
	$scope.dlg_has_error = null; // popup dialogs will need their own "has_error" field

	$scope.social_media_event_list = null;
	$scope.social_media_event_list_cache = {};
	$scope.is_loading_social_event_list = false;
	$scope.social_media_event_list_error = null;

	$scope.social_media_privacy_options = [];
	$scope.social_media_publish_options = [];

	$scope.destination_cache = {};

	$scope.original_encoder_stop_time = null;
	$scope.prev_answer_to_sync_web_event_stop_time = null;

	$scope.canShowYouTubeIcon = function (event) {
		return (
			$scope.isWebEventStreamingToYouTube(event) &&
			!$scope.hasProblemStreamingToSocialMedia(event) &&
			$scope.has_social_media_perm
		);
	};

	$scope.canShowFacebookIcon = function (event) {
		return (
			$scope.isWebEventStreamingToFacebook(event) &&
			!$scope.hasProblemStreamingToSocialMedia(event) &&
			$scope.has_social_media_perm
		);
	};

	$scope.canShowSocialMediaWarningIcon = function (event) {
		return $scope.hasProblemStreamingToSocialMedia(event) && $scope.has_social_media_perm;
	};

	$scope.canShowAddAllSchedulesBtn = function () {
		return (
			Authentication.getCurrentUser().hasPerm('schedules.add') &&
			Authentication.getCurrentUser().hasPerm('transcoder_schedules.add')
		);
	};

	$scope.canShowAddEncoderScheduleOnlyBtn = function () {
		return (
			Authentication.getCurrentUser().hasPerm('schedules.add') &&
			!Authentication.getCurrentUser().hasPerm('transcoder_schedules.add')
		);
	};

	$scope.canShowAddWebScheduleOnlyBtn = function () {
		return (
			!Authentication.getCurrentUser().hasPerm('schedules.add') &&
			Authentication.getCurrentUser().hasPerm('transcoder_schedules.add')
		);
	};

	$scope.canShowWebSchedules = function () {
		return Authentication.getCurrentUser().hasPerm('transcoder_schedules.get');
	};

	$scope.canShowEditEncoderScheduleBtn = function () {
		return Authentication.getCurrentUser().hasPerm('schedules.update');
	};

	$scope.canShowDeleteEncoderScheduleBtn = function () {
		return Authentication.getCurrentUser().hasPerm('schedules.delete');
	};

	$scope.canShowEditWebScheduleBtn = function () {
		return Authentication.getCurrentUser().hasPerm('transcoder_schedules.update');
	};

	$scope.canEditSimulcast = function(simulcast) {
		for(const simulcast of simulcasts) {
			if(!this.social_media.isFacebookType(simulcast.type)) {
				continue;
			} else if (simulcast.broadcastId || simulcast.publishStatus === this.social_media.SCHEDULED_POST) {
				return false;
			}
		}

		return true;
	}

	$scope.canShowDeleteWebScheduleBtn = function () {
		return Authentication.getCurrentUser().hasPerm('transcoder_schedules.delete');
	};

	$scope.canShowAddWebScheduleBtn = function () {
		return Authentication.getCurrentUser().hasPerm('transcoder_schedules.add');
	};

	$scope.canShowAddSocialMediaDestinationBtn = function (simulcasts) {

		if (simulcasts == null){
			return true;
		}

		var hasYouTube = false;
		var hasFacebook = false;

		for(var i=0; i < simulcasts.length; i++){
			var simulcast = simulcasts[i];
			if ($scope.social_media.isFacebookType(simulcast.type)){
				hasFacebook = true;
			} else if ($scope.social_media.isYouTubeType(simulcast.type)){
				hasYouTube = true;
			}
		}

		// don't show the "add social media destination" button if they already have all types
		return !(hasYouTube && hasFacebook);
	};

	$scope.disableWebEventTime = function (simulcasts) {
		if(simulcasts !== undefined && simulcasts !== null) {
			for(const simulcast of simulcasts) {
				if (this.social_media.isFacebookType(simulcast.type) && simulcast.publishStatus === this.social_media.SCHEDULED_POST && simulcast.uuid !== undefined && simulcast.uuid !== null) {
					return true;
				}
			}
		}

		return false;
	};

	$scope.disableWebEventTimeByEvents = function (events) {
		if (events !== undefined && events !== null) {
			for (const event of events) {
				if($scope.disableWebEventTime(event.simulcasts)) {
					return true;
				}
			}
		}

		return false;
	}

	$scope.canShowLoadPastEventsBtn = function () {
		return $scope.is_loading_past_events || ($scope.calendar_start_date == null && $scope.TODAY.date() != 1);
	};

	$scope.canShowLoadPrevMonthBtn = function () {
		return !$scope.is_loading_past_events && ($scope.calendar_start_date != null || $scope.TODAY.date() == 1);
	};

	$scope.showCannotExtendWarning = function () {
		return $scope.web_event_to_add_to_encoder_schedule && $scope.reg_event_to_add && !$scope.hasSameStopTime($scope.web_event_to_add_to_encoder_schedule, $scope.reg_event_to_add);
	};

	$scope.getCopy = function (obj_to_copy) {
		// perform deep copy
		return JSON.parse(JSON.stringify(obj_to_copy));
	};

	$scope.isWebEventProfileInUse = function (web_event_profile_id) {
		if ($scope.web_events_list != null) {
			for (var i = 0; i < $scope.web_events_list.length; i++) {
				var web_event = $scope.web_events_list[i];
				if (
					(web_event.status == 'started' || web_event.status == 'idle') &&
					web_event.webEventProfileId == web_event_profile_id
				) {
					return true;
				}
			}
		}
		return false;
	};

	$scope.showAddOrUpdateEncoderScheduleWebEvent = function (event) {
		$scope.add_web_event_to_encoder_schedule = true;
		$scope.add_web_event_to_encoder_schedule_error = null;
		$scope.show_web_event_schedule_warning = false;

		if (event) {
			$scope.web_event_to_add_to_encoder_schedule = $scope.getCopy(event);
			// show a warning if the web event profile this web event uses is already in use
			$scope.show_web_event_schedule_warning = $scope.isWebEventProfileInUse(event.web_event_profile);

			trackMixpanelEvent(MPEventName.WEB_EVENT_SCHEDULE_EDIT, {
				[MPEventProperty.TRANSCODED_SCHEDULE_UUID]: $scope.web_event_to_add_to_encoder_schedule.web_event_profile
			  });

		} else {
			$scope.web_event_to_add_to_encoder_schedule = {
				temp_id: null,
				name: '',
				enabled: 'Enabled',
				name_event_using: $scope.NAME_WEB_EVENT_USING_DEFAULT,
				web_event_profile: $scope.getDefaultSelection($scope.transcoder_event_profile_list, 'uuid'),
				web_encoder_profile: null,
				time_hours: $scope.reg_event_to_add.time_hours,
				time_minutes: $scope.reg_event_to_add.time_minutes,
				time_meridiem: $scope.reg_event_to_add.time_meridiem,
				duration_hours: $scope.reg_event_to_add.duration_hours,
				duration_minutes: $scope.reg_event_to_add.duration_minutes,
			};
		}
		// see app.js for where focus is defined
		focus('add-web-schedule-to-encoder-schedule-input');
	};

	$scope.doesAddOrUpdateEncoderScheduleWebEventFailValidation = function () {
		$scope.has_error = {};

		// check required fields to ensure they aren't empty
		$scope.has_error.web_event_to_add_to_encoder_schedule_name = $scope.isEmpty($scope.web_event_to_add_to_encoder_schedule.name);
		//                $scope.has_error.web_event_to_add_to_encoder_schedule_web_encoder_profile = $scope.isEmpty($scope.web_event_to_add_to_encoder_schedule.web_encoder_profile);
		$scope.has_error.web_event_to_add_to_encoder_schedule_web_event_profile = $scope.isEmpty(
			$scope.web_event_to_add_to_encoder_schedule.web_event_profile
		);

		var error_count = 0;
		for (var property in $scope.has_error) {
			if ($scope.has_error[property] === true) {
				error_count++;
			}
		}
		var has_validation_error = error_count > 0;

		$scope.add_web_event_to_encoder_schedule_error = has_validation_error
			? 'Please specify a value for the highlighted fields.'
			: null;

		return has_validation_error;
	};

	$scope.getWebEventProfileNameForID = function (profileId) {
		if ($scope.transcoder_event_profile_list != null) {
			for (var i = 0; i < $scope.transcoder_event_profile_list.length; i++) {
				if ($scope.transcoder_event_profile_list[i].uuid === profileId) {
					return $scope.transcoder_event_profile_list[i].name;
				}
			}
		}
		return '';
	};

	$scope.getWebEncoderProfileNameForID = function (profileId) {
		if ($scope.web_encoder_profile_list != null) {
			for (var i = 0; i < $scope.web_encoder_profile_list.length; i++) {
				if ($scope.web_encoder_profile_list[i].uuid === profileId) {
					return $scope.web_encoder_profile_list[i].name;
				}
			}
		}
		return '';
	};

	// searches web_events list for entry with matching temp_id, and if found, returns the index for that entry
	$scope.getExistingWebEventIndex = function (event) {
		for (var i = 0; i < $scope.reg_event_to_add.web_events.length; i++) {
			if (event.temp_id === $scope.reg_event_to_add.web_events[i].temp_id) {
				return i;
			}
		}
		return -1;
	};

	// In general our event lists will be kept in UI format (converted from the format returned by the API). Only when it comes time to update
	// the API will we convert the data back into API format.
	$scope.convertApiToUiFormat = function (entry) {
		var localStartTime = moment(entry.localStartTime, $scope.TIME_24H_FORMAT);
		var web_duration_hours = parseInt(entry.length / 60);
		if (web_duration_hours == 0) web_duration_hours = '0';
		var web_duration_minutes = entry.length % 60;

		var converted = {
			uuid: null,
			temp_id: null,
			name: entry.name,
			enabled: entry.enabled ? 'Enabled' : 'Disabled',
			name_event_using: entry.eventUsesScheduleName ? $scope.NAME_EVT_USING_WEB_SCHEDULE_DESC : $scope.NAME_EVT_USING_WEB_EVT_PROFILE,
			web_encoder_profile: entry.hasOwnProperty('webEncoderProfileId') ? entry.webEncoderProfileId : null, // API doesn't return this field yet, so set to null when it isn't provided
			web_event_profile: entry.transcodedEventProfileId || entry.webEventProfileId,
			web_event_profile_name: entry.transcodedEventProfileName,
			time_hours: localStartTime.format('h'),
			time_minutes: localStartTime.format('mm'),
			time_meridiem: localStartTime.format('A'),
			duration_hours: web_duration_hours.toString(),
			duration_minutes: web_duration_minutes.toString(),
			gmtTime: entry.gmtTime, // this is needed to properly display time on the calendar
			simulcasts: entry.simulcasts,
		};

		if (entry.hasOwnProperty('uuid')) {
			converted.uuid = entry.uuid;
		} else if (entry.hasOwnProperty('webEventScheduleId')) {
			converted.uuid = entry.webEventScheduleId;
		} else if (entry.hasOwnProperty('temp_id')) {
			converted.temp_id = entry.temp_id;
		}

		if (entry.simulcasts != null) {
			converted.simulcasts = [];
			for (var i = 0; i < entry.simulcasts.length; i++) {
				var sim_entry = entry.simulcasts[i];
				var data = {
					uuid: sim_entry.uuid,
					destinationId: sim_entry.destinationId,
					// if destinationName is null (which may be case with old events), then ensure UI displays it as "Stream Now"
					destinationName: sim_entry.destinationName != null ? sim_entry.destinationName : $scope.SOCIAL_MEDIA_EVENT_STREAM_NOW_LABEL,
					title: sim_entry.title,
					description: sim_entry.description,
					privacy: sim_entry.privacy,
					publishStatus: sim_entry.publishStatus,
					channelId: sim_entry.channelId,
					channelName: sim_entry.channelName,
					type: sim_entry.type,
					crossposts: sim_entry.crossposts,
					imageUrl: sim_entry.imageUrl,
				};
				converted.simulcasts.push(data);
			}
		}

		return converted;
	};

	// TODO: are we even using this anymore?
	$scope.convertUitoApiFormat = function (entry) {
		var startDateTime = moment(
			$scope.reg_event_to_add.date +
			' ' +
			web_event.time_hours +
			':' +
			web_event.time_minutes +
			' ' +
			web_event.time_meridiem,
			$scope.DATE_TIME_MERIDIEM_FORMAT
		);
		var duration_in_minutes = $scope.convertTimeToMinutes(web_event.duration_hours, web_event.duration_minutes);

		// ensure special chars don't blow up our json; see for more options:
		// http://stackoverflow.com/questions/4253367/how-to-escape-a-json-string-containing-newline-characters-using-javascript
		var escaped_name = web_event.name.replace(/\\"/g, '\\"');

		var web_event_data = {
			name: escaped_name,
			enabled: true,
			scheduleStartDay: startDateTime.format($scope.DATE_FORMAT),
			wholeEvent: false,
			localStartTime: startDateTime.format('HH:mm'),
			length: duration_in_minutes,
			timeZone: $scope.reg_event_to_add.time_zone,
			frequency: $scope.reg_event_to_add.frequency_days,
			transcodedEventProfileId: web_event.web_event_profile,
			scheduleId: results.data.uuid,
		};
	};

	$scope.findEntry = function (list, entry) {
		for (var i = 0; i < list.length; i++) {
			var list_entry = list[i];

			// determine if we should check "uuid" or "temp_id"
			if (entry.hasOwnProperty('uuid') && entry.uuid !== null) {
				if (list_entry.hasOwnProperty('uuid') && entry.uuid === list_entry.uuid) {
					return i;
				}
			} else {
				if (list_entry.temp_id === entry.temp_id) {
					return i;
				}
			}
		}
		return -1;
	};

	$scope.removeEntry = function (list, entry) {
		if (list) {
			var index = $scope.findEntry(list, entry);
			if (index > -1) {
				list.splice(index, 1);
			}
		}
	};

	$scope.replaceEntry = function (list, entry) {
		var index = $scope.findEntry(list, entry);
		if (index > -1) {
			list[index] = entry;
		} else {
			console.log('ERROR: Unable to find matching entry for:');
			console.log(entry);
		}
	};

	// this is called by the web event "Done" button. So after you add/edit a specific web event and then press "Done".
	$scope.AddOrUpdateEncoderScheduleWebEvent = function () {
		// if we have form validation errors, then don't go any further
		if ($scope.doesAddOrUpdateEncoderScheduleWebEventFailValidation()) return false;

		if ($scope.reg_event_to_add.uuid != null) {
			//
			// we are working with an existing encoder schedule
			//

			if ($scope.web_event_to_add_to_encoder_schedule.uuid) {
				// we are updating an existing web event
				var entry = $scope.getCopy($scope.web_event_to_add_to_encoder_schedule);
				entry.web_event_profile_name = $scope.getWebEventProfileNameForID($scope.web_event_to_add_to_encoder_schedule.web_event_profile);
				entry.web_encoder_profile_name = $scope.getWebEncoderProfileNameForID($scope.web_event_to_add_to_encoder_schedule.web_encoder_profile);
				// update list that UI uses to display info
				$scope.replaceEntry($scope.reg_event_to_add.web_events, entry);
				// update list we use when saving to API
				$scope.removeEntry($scope.reg_event_to_add.web_events_update, entry);
				$scope.reg_event_to_add.web_events_update.push(entry);
			} else if ($scope.web_event_to_add_to_encoder_schedule.temp_id === null) {
				// we are adding a brand new web event
				var entry = $scope.getCopy($scope.web_event_to_add_to_encoder_schedule);
				entry.temp_id = $scope.temp_id_counter++;
				entry.web_event_profile_name = $scope.getWebEventProfileNameForID($scope.web_event_to_add_to_encoder_schedule.web_event_profile);
				entry.web_encoder_profile_name = $scope.getWebEncoderProfileNameForID($scope.web_event_to_add_to_encoder_schedule.web_encoder_profile);
				// update list that UI uses to display info
				$scope.reg_event_to_add.web_events.push(entry);
				// update list we use when saving to API
				$scope.reg_event_to_add.web_events_add.push(entry);
			} else {
				// we are editing a brand new web event
				var entry = $scope.getCopy($scope.web_event_to_add_to_encoder_schedule);
				entry.web_event_profile_name = $scope.getWebEventProfileNameForID($scope.web_event_to_add_to_encoder_schedule.web_event_profile);
				entry.web_encoder_profile_name = $scope.getWebEncoderProfileNameForID($scope.web_event_to_add_to_encoder_schedule.web_encoder_profile);
				// update list that UI uses to display info
				$scope.replaceEntry($scope.reg_event_to_add.web_events, entry);
				// update list we use when saving to API
				$scope.replaceEntry($scope.reg_event_to_add.web_events_add, entry);
			}
		} else {
			//
			// we are working with a brand new encoder schedule
			//

			if ($scope.web_event_to_add_to_encoder_schedule.temp_id === null) {
				var entry = $scope.getCopy($scope.web_event_to_add_to_encoder_schedule);
				entry.temp_id = $scope.temp_id_counter++;
				entry.web_event_profile_name = $scope.getWebEventProfileNameForID($scope.web_event_to_add_to_encoder_schedule.web_event_profile);
				entry.web_encoder_profile_name = $scope.getWebEncoderProfileNameForID($scope.web_event_to_add_to_encoder_schedule.web_encoder_profile);
				// update list that UI uses to display info (we'll also use this for API)
				$scope.reg_event_to_add.web_events.push(entry);
			} else {
				var entry = $scope.getCopy($scope.web_event_to_add_to_encoder_schedule);
				entry.web_event_profile_name = $scope.getWebEventProfileNameForID($scope.web_event_to_add_to_encoder_schedule.web_event_profile);
				entry.web_encoder_profile_name = $scope.getWebEncoderProfileNameForID($scope.web_event_to_add_to_encoder_schedule.web_encoder_profile);
				// update list that UI uses to display info
				$scope.replaceEntry($scope.reg_event_to_add.web_events, entry);
			}
		}

		$scope.add_web_event_to_encoder_schedule = false;
		$scope.web_event_to_add_to_encoder_schedule = null;
	};

	$scope.cancelAddOrUpdateEncoderScheduleWebEvent = function () {
		$scope.add_web_event_to_encoder_schedule = false;
		$scope.web_event_to_add_to_encoder_schedule = null;
		$scope.add_web_event_to_encoder_schedule_error = null;
		$scope.has_error = {};
	};

	$scope.deleteWebEventFromEncoderSchedule = function (event_to_delete) {
		// remove from list that is used to display UI
		var index = $scope.reg_event_to_add.web_events.indexOf(event_to_delete);
		if (index > -1) {
			$scope.reg_event_to_add.web_events.splice(index, 1);
		}
		// if this event existed in the DB ...
		if (event_to_delete.hasOwnProperty('uuid') && event_to_delete.uuid != null) {
			// remove this entry if it has been added to the update list
			$scope.removeEntry($scope.reg_event_to_add.web_events_update, event_to_delete);
			// add it to our delete list
			$scope.reg_event_to_add.web_events_delete.push(event_to_delete);
		} else {
			// if this is new item, then remove it from add list
			$scope.removeEntry($scope.reg_event_to_add.web_events_add, event_to_delete);
		}

		trackMixpanelEvent(MPEventName.WEB_EVENT_SCHEDULE_DELETE, {
			[MPEventProperty.TRANSCODED_SCHEDULE_UUID]: event_to_delete.web_event_profile,
		  });
		
	};

	$scope.isEncoderSchedule = function (event) {
		return event.eventType == $scope.SCHEDULE_TYPE_ENCODER;
	};

	$scope.isSimLiveSchedule = function (event) {
		return event.eventType == $scope.SCHEDULE_TYPE_SIM_LIVE;
	};

	$scope.getPreviousMonthFillerDays = function (month, year) {
		var filler_days = [];

		// create moment object for first day of given month
		var start = new Date(year, month - 1, 1); // Date months are zero based, so subtract 1
		var first_of_month = moment(start);

		// init our previous day variables
		var prev_day = first_of_month.subtract(1, 'days');
		var prev_day_day_of_week = prev_day.format('ddd'); // Sun, Mon, etc

		// keep looping and subtracting days until we hit Saturday
		while (prev_day_day_of_week != 'Sat') {
			var filler_day = {
				day_of_month: prev_day.format('D'),
				is_current_month: false,
				events: null,
				date: prev_day.format('M/D/YYYY'),
				is_past: true,
				is_today: false,
			};
			// add this day to the front of the list
			filler_days.unshift(filler_day);

			// subtract another day
			prev_day = prev_day.subtract(1, 'days');
			prev_day_day_of_week = prev_day.format('ddd'); // Sun, Mon, etc
		}

		return filler_days;
	};

	$scope.getNextMonthFillerDayCount = function (month, year) {
		var filler_days = [];

		// create moment object for last day of given month
		var month_year_formatted = month + '/' + year;
		var days_in_month = moment(month_year_formatted, 'MM/YYYY').daysInMonth();
		var end = new Date(year, month - 1, days_in_month); // Date months are zero based, so subtract 1
		var end_of_month = moment(end);

		// init our previous day variables
		var next_day = end_of_month.add(1, 'days');
		var next_day_day_of_week = next_day.format('ddd'); // Sun, Mon, etc

		// keep looping and adding days until we hit Sunday
		while (next_day_day_of_week != 'Sun') {
			var filler_day = {
				day_of_month: next_day.format('D'),
				is_current_month: false,
				events: null,
				date: next_day.format('M/D/YYYY'),
				is_past: false,
				is_today: false,
			};
			// add this day to the list
			filler_days.push(filler_day);

			// add another day
			next_day = next_day.add(1, 'days');
			next_day_day_of_week = next_day.format('ddd'); // Sun, Mon, etc
		}

		return filler_days;
	};

	// TODO: probably room to optimize this. Instead of iterating thru each entire list, what if when events are loaded
	// we organized them by date. So we'd have an events object that would have properties for the dates of the events.
	// So if you wanted events for a particular date, you'd just get a list by doing $scope.events[date] ... and you'd
	// get a sorted list of events for that date.
	$scope.getEvents = function (date) {
		var events = [];
		var given_date = moment(date);

		if ($scope.upcoming_encoder_events != null) {
			for (var i = 0; i < $scope.upcoming_encoder_events.length; i++) {
				var event = $scope.upcoming_encoder_events[i];
				if (given_date.isSame(event.date, 'day')) {
					events.push(event);
				}
			}
		}

		if ($scope.upcoming_web_events != null) {
			for (var i = 0; i < $scope.upcoming_web_events.length; i++) {
				var event = $scope.upcoming_web_events[i];
				if (given_date.isSame(event.date, 'day')) {
					events.push(event);
				}
			}
		}

		events.sort($scope.sortEvents);

		return events;
	};

	$scope.getRegEvents = function (date) {
		var events = [];
		var given_date = moment(date);

		if ($scope.upcoming_encoder_events != null) {
			for (var i = 0; i < $scope.upcoming_encoder_events.length; i++) {
				var event = $scope.upcoming_encoder_events[i];
				if (given_date.isSame(event.date, 'day')) {
					events.push(event);
				}
			}
		}

		events.sort($scope.sortEvents);

		return events;
	};

	$scope.getWebEvents = function (date) {
		var events = [];
		var given_date = moment(date);

		if ($scope.upcoming_web_events != null) {
			for (var i = 0; i < $scope.upcoming_web_events.length; i++) {
				var event = $scope.upcoming_web_events[i];
				if (given_date.isSame(event.date, 'day')) {
					events.push(event);
				}
			}
		}

		events.sort($scope.sortEvents);

		return events;
	};

	$scope.findParentEncoderEvent = function (date, scheduleId) {
		var events = $scope.getRegEvents(date);
		for (var i = 0; i < events.length; i++) {
			var event = events[i];
			if (event.scheduleId === scheduleId) return event;
		}
		return null;
	};

	$scope.loadDayListForMonth = function (month, year) {

		// 1. load "filler" days for previous month
		var days = $scope.getPreviousMonthFillerDays(month, year);

		// 2. load days for this month
		var month_year_formatted = month + '/' + year;
		var days_in_month = moment(month_year_formatted, 'MM/YYYY').daysInMonth();

		for (var i = 1; i <= days_in_month; i++) {
			var formatted_date_str = month + '/' + i + '/' + year;
			var formatted_date = new Date(year, month - 1, i); // month is zero based

			var day = {
				day_of_month: i,
				is_current_month: true,
				events: $scope.getEvents(formatted_date),
				date: formatted_date_str,
				is_past: moment(formatted_date).isBefore($scope.TODAY, 'day'),
				is_today: $scope.TODAY.isSame(formatted_date, 'day'),
			};
			// add this day to the list
			days.push(day);
		}

		// 3. load "filler" days for next month
		days = days.concat($scope.getNextMonthFillerDayCount(month, year));

		return days;
	};

	$scope.cancelUpdateEvent = function () {
		$scope.cancelAddOrUpdateEncoderScheduleWebEvent();

		//                $scope.event_to_view = $scope.reg_event_to_add;
		$scope.reg_event_to_add = null;
		$scope.show_update_event = false;
		$scope.removeWarnOnPageChange();
		$scope.add_update_event_error = null;
		$scope.add_update_event_errors = [];
		$scope.add_event_form_validation_error = null;
		$scope.load_web_events_list_error = null;
	};

	$scope.showDeleteEvent = function (event) {
		$scope.event_to_view = null;
		$scope.event_to_delete = event;
	};

	$scope.cancelDeleteEvent = function () {
		$scope.event_to_view = $scope.event_to_delete;
		$scope.event_to_delete = null;
		$scope.delete_event_error = null;
	};

	$scope.deleteEvent = function () {
		$scope.is_busy_deleting_event = true;

		var delete_url =
			$scope.event_to_delete.eventType === $scope.SCHEDULE_TYPE_ENCODER
				? jcs.api.url + '/schedules/' + $scope.event_to_delete.scheduleId
				: jcs.api.url_v3 +
				'/customers/' +
				Authentication.getCurrentUser().customerID +
				$scope.API_PATH_WEB_EVENT_SCHEDULE +
				$scope.event_to_delete.transcodedEventScheduleId;

		const mixpanel_data = $scope.event_to_delete.eventType === $scope.SCHEDULE_TYPE_ENCODER ? {
			[MPEventProperty.SCHEDULE_TYPE]: 'live',
			[MPEventProperty.SCHEDULE_UUID]: $scope.event_to_delete.scheduleId,
			[MPEventProperty.SCHEDULE_NAME]: $scope.event_to_delete.name,
			[MPEventProperty.EVENT_DURATION]: $scope.event_to_delete.length,
			[MPEventProperty.EVENT_STATUS]: $scope.event_to_delete.enabled ? 'enabled' : 'disabled',
			[MPEventProperty.ENCODER_UUID]: $scope.event_to_delete.encoderId,
			[MPEventProperty.ENCODER_NAME]: $scope.event_to_delete.encoderName,
			[MPEventProperty.ENCODER_PROFILE_NAME]: $scope.event_to_delete.isSoftwareEncoder ? 'Configured in ProPresenter' : $scope.event_to_delete.encoderProfileName,
			[MPEventProperty.ENCODER_EVENT_PROFILE_NAME]: $scope.event_to_delete.streamProfileName,
			[MPEventProperty.WEB_EVENT_COUNT]: $scope.event_to_delete.web_events?.length,
			[MPEventProperty.START_DATE]: $scope.event_to_delete.scheduleStartDay,
			[MPEventProperty.START_TIME_24H]: $scope.event_to_delete.localStartTime,
			[MPEventProperty.TIMEZONE]: $scope.event_to_delete.timeZone,
		} : {
			[MPEventProperty.SCHEDULE_TYPE]: 'sim-live',
			[MPEventProperty.SCHEDULE_UUID]: $scope.event_to_delete.transcodedEventScheduleId,
			[MPEventProperty.SCHEDULE_NAME]: $scope.event_to_delete.name,
			[MPEventProperty.EVENT_STATUS]: $scope.event_to_delete.enabled ? 'enabled' : 'disabled',
			[MPEventProperty.WEB_EVENT_PROFILE_UUID]: $scope.event_to_delete.webEventProfileId,
			[MPEventProperty.WEB_EVENT_PROFILE_NAME]: $scope.event_to_delete.webEventProfileName,
			[MPEventProperty.SOCIAL_DESTINATION_COUNT]: $scope.event_to_delete.simulcasts?.length,
			[MPEventProperty.START_DATE]: $scope.event_to_delete.scheduleStartDay,
			[MPEventProperty.START_TIME_24H]: $scope.event_to_delete.localStartTime,
			[MPEventProperty.TIMEZONE]: $scope.event_to_delete.timeZone,
		};

		// send ajax request to create new profile
		httpService.delete(
			delete_url,
			{ withCredentials: true },
			function () { // success

				$scope.event_to_delete = null;
				$scope.delete_event_error = null;

				// TODO: this fadeOut call is a hack; find a better way to do this (I need a way for restoreLocation to call a given function,
				// but then to wait until it is done before continuing; if I pass a function that calls loadInitialCalendar, then it returns
				// immediately before it is done and therefore begins the fadein to early, so you don't end up seeing the fadein at all.)
				$('.calendar-wrapper').fadeOut(0);
				$scope.loadInitialCalendar(function () {
					$scope.restoreScrollLocation();
				});

				trackMixpanelEvent(MPEventName.EVENT_SCHEDULE_DELETE, mixpanel_data);
			},
			function () { // error

				$scope.delete_event_error = 'An error occurred while attempting to delete the event. Please try again, or report the problem if it persists.';

			},
			function () { // always called

				$scope.is_busy_deleting_event = false;
			}
		);
	};

	$scope.scroll_location = null;
	$scope.saveScrollLocation = function () {
		$scope.scroll_location = $(window).scrollTop();
		$(window).scrollTop(0); // ensure we are at top
	};
	$scope.restoreScrollLocation = function (functionToExecute) {
		if ($scope.scroll_location != null) {
			$('.calendar-wrapper').fadeOut(0);
		}

		if (functionToExecute != null) {
			functionToExecute();
		}

		if ($scope.scroll_location != null) {
			// we need to ensure that our UI is updated before we restore our scrollbar positions, so in order to do that we
			// need to do our stuff after this function finishes and the $apply() is done ... so we'll use $timeout
			$timeout(function () {
				$('.calendar-wrapper').fadeIn('fast');

				$(window).scrollTop($scope.scroll_location);
			}, 0);
		}
	};

	$scope.getEncoderInfo = function (uuid, encoder_list) {
		for (const encoder of encoder_list) {
			if (uuid === encoder.uuid){
				return encoder;
			}
		}
		return null;
	}

	$scope.onEncoderChanged = function () {
		const encoder_info = $scope.getEncoderInfo($scope.reg_event_to_add.encoder, $scope.encoder_list);
		$scope.reg_event_to_add.is_software_encoder = encoderService.isSoftwareEncoder(encoder_info);
	};

	$scope.onFrequencyChoiceChanged = function (useFade) {
		// if useFade is not specified, then we will fade (only when specifically asked not to fade will we not)
		var fade_duration = useFade == true || useFade == null ? 'fast' : 0;

		var the_event =
			$scope.schedule_to_add_type === $scope.SCHEDULE_TYPE_ENCODER ? $scope.reg_event_to_add : $scope.web_event_to_add;

		if (the_event.frequency_yesno == $scope.YES) {
			$('.select-frequency-days-elements').fadeIn(fade_duration);
			$('.end-schedule-elements').fadeIn(fade_duration);
		} else {
			$('.select-frequency-days-elements').fadeOut(fade_duration);
			$('.end-schedule-elements').fadeOut(fade_duration);
		}
	};

	$scope.onStopScheduleChoiceChanged = function (useFade) {
		// if useFade is not specified, then we will fade (only when specifically asked not to fade will we not)
		var fade_duration = useFade == true || useFade == null ? 'fast' : 0;

		var the_event =
			$scope.schedule_to_add_type === $scope.SCHEDULE_TYPE_ENCODER ? $scope.reg_event_to_add : $scope.web_event_to_add;

		if (the_event.end_schedule_yesno == $scope.YES) {
			$('.do-not-end-schedule-hint').fadeOut(0, function () {
				$('#end-reg-schedule-date, #end-web-schedule-date').fadeIn(fade_duration);
			});
		} else {
			$('#end-reg-schedule-date, #end-web-schedule-date').fadeOut(0, function () {
				$('.do-not-end-schedule-hint').fadeIn(fade_duration);
			});
		}
	};

	$scope.hasSameStopTime = function (this_event, that_event) {

		const this_time = moment(`${this_event.time_hours}:${this_event.time_minutes} ${this_event.time_meridiem}`, $scope.TIME_12H_FORMAT);
		this_time.add(this_event.duration_hours, 'hours');
		this_time.add(this_event.duration_minutes, 'minutes');

		const that_time = moment(`${that_event.time_hours}:${that_event.time_minutes} ${that_event.time_meridiem}`, $scope.TIME_12H_FORMAT);
		that_time.add(that_event.duration_hours, 'hours');
		that_time.add(that_event.duration_minutes, 'minutes');

		return this_time.isSame(that_time);
	};

	$scope.hasWebEventWithMatchingEndTime = function (end_time_to_match){

		for (const event of $scope.reg_event_to_add.web_events){
			// we want to ignore items being edited since their stop time may have changed; a special check for items being edited is performed after this loop
			const is_being_edited = $scope.web_event_to_add_to_encoder_schedule !== null && $scope.web_event_to_add_to_encoder_schedule.uuid === event.uuid;
			if (!is_being_edited && $scope.hasSameStopTime(end_time_to_match, event)){
				return true;
			}
		}
		// check any web event that is being edited
		return $scope.web_event_to_add_to_encoder_schedule !== null && $scope.hasSameStopTime(end_time_to_match, $scope.web_event_to_add_to_encoder_schedule);
	};

	$scope.updateWebEventEndTimeToMatch = function (source_event, event_to_update){

		const source_end_time = moment(`${source_event.time_hours}:${source_event.time_minutes} ${source_event.time_meridiem}`, $scope.TIME_12H_FORMAT);
		source_end_time.add(source_event.duration_hours, 'hours');
		source_end_time.add(source_event.duration_minutes, 'minutes');

		const event_to_update_start_time = moment(`${event_to_update.time_hours}:${event_to_update.time_minutes} ${event_to_update.time_meridiem}`, $scope.TIME_12H_FORMAT);

		const duration = moment.duration(source_end_time.diff(event_to_update_start_time));

		event_to_update.duration_hours = `${duration.hours()}`;
		event_to_update.duration_minutes = `${duration.minutes()}`;
	};

	$scope.onScheduleStopTimeChange = function (prev_hours, prev_minutes){

		$scope.original_encoder_stop_time = {
			'time_hours': $scope.reg_event_to_add.time_hours,
			'time_minutes': $scope.reg_event_to_add.time_minutes,
			'time_meridiem': $scope.reg_event_to_add.time_meridiem,
			'duration_hours': prev_hours,
			'duration_minutes': prev_minutes,
		};

		if ($scope.hasWebEventWithMatchingEndTime($scope.original_encoder_stop_time)){

			// all existing web events with stop times that match the encoder event need to be added to our web_events_update list -- regardless of whether the user says yes/no
			// on the modal dialog. This is because web events have an attribute called "wholeEvent" which causes a special behavior on the backend. A web event is considered a
			// "wholeEvent" if both its start time and duration match the encoder schedule. In this case, if the encoder event duration is extended, then any wholeEvents will
			// also be extended, even if the web event itself was not edited. This has the potential to cause confusion with our new prompt dialog that asks if the user wants to
			// change web event stop time when the encoder stop time is changed. To avoid this, anytime a encoder stop time is changed, then we need to call the API to also update
			// all existing web events (to prevent the special "wholeEvent" behavior from happening -- and possibly contradicting what the user requested).
			for (const event of $scope.reg_event_to_add.web_events){
				if (event.uuid && $scope.findEntry($scope.reg_event_to_add.web_events_update, event) === -1 && $scope.hasSameStopTime($scope.original_encoder_stop_time, event)){
					$scope.reg_event_to_add.web_events_update.push(event);
				}
			}

			// if they have not answered the modal before, then show modal. If they have answered modal, then use the previous answer
			if ($scope.prev_answer_to_sync_web_event_stop_time === null){
				$('#auto-update-web-event-stop-time').modal('show');
			} else if ($scope.prev_answer_to_sync_web_event_stop_time){
				$scope.updateWebEventStopTimeToMatchEncoderSchedule();
			}
		}
	};

	$scope.updateWebEventStopTimeToMatchEncoderSchedule = function (){
		// save this so we only ask once per encoder schedule "edit session"
		$scope.prev_answer_to_sync_web_event_stop_time = true;

		// update any of our web_events that have a matching stop time, unless they are being edited; updates to an entry in the web_events list will also update corresponding
		// entry in the web_events_* lists we use for our API calls (web_events_add, web_events_update, etc); we don't want to update any web_event entry that is being edited
		// because the user could still cancel the edit and we wouldn't want the web_event entry to be changed in that case.
		for (const event_to_update of $scope.reg_event_to_add.web_events){
			const is_being_edited = $scope.web_event_to_add_to_encoder_schedule !== null && $scope.web_event_to_add_to_encoder_schedule.uuid === event_to_update.uuid;
			if (!is_being_edited && $scope.hasSameStopTime($scope.original_encoder_stop_time, event_to_update)){
				$scope.updateWebEventEndTimeToMatch($scope.reg_event_to_add, event_to_update);
			}
		}

		if ($scope.web_event_to_add_to_encoder_schedule !== null && $scope.hasSameStopTime($scope.original_encoder_stop_time, $scope.web_event_to_add_to_encoder_schedule)){
			$scope.updateWebEventEndTimeToMatch($scope.reg_event_to_add, $scope.web_event_to_add_to_encoder_schedule);
		}

		$('#auto-update-web-event-stop-time').modal('hide');
	};

	$scope.getLocalEndTimeForEncoderSchedule = function (schedule) {
		if (schedule) {
			var start_time = moment(schedule.localStartTime, $scope.TIME_24H_FORMAT);
			start_time.add(schedule.length, 'minutes');
			return start_time.format($scope.TIME_12H_FORMAT);
		}
		return '';
	};

	// gets the local end time using combination of web event's local start time and duration in minutes
	$scope.getLocalEndTimeWebEvent = function (web_event) {
		var total_minutes = $scope.convertTimeToMinutes(web_event.duration_hours, web_event.duration_minutes);
		var start_time = moment(
			web_event.time_hours + ':' + web_event.time_minutes + ' ' + web_event.time_meridiem,
			$scope.TIME_12H_FORMAT
		);
		start_time.add(total_minutes, 'minutes');
		return start_time.format($scope.TIME_12H_FORMAT);
	};

	$scope.getWebEventDelayMin = function (web_event) {
		// TODO: Use webEventProfile.delaySeconds + presentation delay
		return 2; // defaults to 2 minutes
	};

	$scope.getAvailableToWatchTime = function (event, web_event) {
		// get event delay time
		var delay = $scope.getWebEventDelayMin(web_event);
		// get web event start time
		var delayedTime = moment(
			web_event.time_hours + ':' + web_event.time_minutes + ' ' + web_event.time_meridiem,
			$scope.TIME_12H_FORMAT
		).add(delay, 'minutes');
		// add the two and return a time
		return 'Approx ' + delayedTime.format($scope.TIME_12H_FORMAT);
	};

	$scope.getSimLiveAvailableToWatchTime = function (web_event) {
		if (web_event != null) {
			// get event delay
			var delay = 1;
			// get web event start time
			var delayedTime = moment(web_event.localStartTime, $scope.TIME_24H_FORMAT).add(delay, 'minutes');
			// add the two and return a time
			return 'Approx ' + delayedTime.format($scope.TIME_12H_FORMAT);
		}
		return '';
	};

	$scope.convertTimeToMinutes = function (hours, minutes) {
		var duration_in_minutes = hours ? parseInt(hours) * 60 : 0;
		if (minutes) {
			duration_in_minutes += parseInt(minutes);
		}
		return duration_in_minutes;
	};

	$scope.getFormattedFrequency = function (event) {
		if (event != null) {
			if (event.oneTime == true) {
				return 'No';
			} else if (event.frequency == 1) {
				return 'Yes. This event repeats every day.';
			} else {
				return 'Yes. This event repeats every ' + event.frequency + ' days.';
			}
		}
		return '';
	};

	$scope.getFormattedEndDate = function (event) {
		if (event != null) {
			if (event.oneTime == true) {
				return '';
			} else if (event.scheduleEndDay == $scope.NEVER_END_DATE) {
				return 'No (schedule runs forever)';
			} else {
				return 'Ends after ' + $scope.formatEventDate(event.scheduleEndDay);
			}
		}
		return '';
	};

	$scope.getFormattedGMT = function (event) {
		if (event != null) {
			var gmt = moment(event.gmtTime);
			var meridiem = gmt.format('a');
			var formatted_meridiem = meridiem == 'am' ? 'a' : 'p';
			return gmt.format('h:mm') + formatted_meridiem;
		}
		return '';
	};

	$scope.getFormattedStatus = function (event) {
		if (event != null) {
			return event.enabled ? 'Enabled' : 'Disabled';
		}
		return '';
	};

	$scope.is_loading_encoder_list = false;
	$scope.load_encoder_list_error = null;
	$scope.encoder_list = null; // used to build dropdown list

	$scope.is_loading_encoder_profile_list = false;
	$scope.load_encoder_profile_list_error = null;
	$scope.encoder_profile_list = null; // used to build dropdown list

	$scope.is_loading_event_profile_list = false;
	$scope.load_event_profile_list_error = null;
	$scope.event_profile_list = null; // used to build dropdown list

	$scope.is_loading_transcoder_event_profile_list = false;
	$scope.load_transcoder_event_profile_list_error = null;
	$scope.transcoder_event_profile_list = null; // used to build dropdown list

	$scope.is_loading_web_encoder_profile_list = false;
	$scope.load_web_encoder_profile_list_error = null;
	$scope.web_encoder_profile_list = null; // used to build dropdown list

	$scope.is_loading_time_zone_list = false;
	$scope.load_time_zone_list_error = null;
	$scope.time_zone_list = null; // used to build dropdown list

	$scope.is_busy_loading_web_events = false;
	$scope.load_web_events_list_error = null;
	$scope.web_events_list = null; // used to determine if we should display warning message (if web event profile is in use)

	$scope.time_hours_list = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'];
	$scope.time_minutes_list_5 = ['00', '05', '10', '15', '20', '25', '30', '35', '40', '45', '50', '55'];
	$scope.time_minutes_list_1 = [
		'00',
		'01',
		'02',
		'03',
		'04',
		'05',
		'06',
		'07',
		'08',
		'09',
		'10',
		'11',
		'12',
		'13',
		'14',
		'15',
		'16',
		'17',
		'18',
		'19',
		'20',
		'21',
		'22',
		'23',
		'24',
		'25',
		'26',
		'27',
		'28',
		'29',
		'30',
		'31',
		'32',
		'33',
		'34',
		'35',
		'36',
		'37',
		'38',
		'39',
		'40',
		'41',
		'42',
		'43',
		'44',
		'45',
		'46',
		'47',
		'48',
		'49',
		'50',
		'51',
		'52',
		'53',
		'54',
		'55',
		'56',
		'57',
		'58',
		'59',
	];
	$scope.time_meridiem_list = ['AM', 'PM'];

	$scope.duration_hours_list = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11'];
	$scope.duration_minutes_list_5 = ['0', '5', '10', '15', '20', '25', '30', '35', '40', '45', '50', '55'];
	$scope.duration_minutes_list_1 = [
		'0',
		'1',
		'2',
		'3',
		'4',
		'5',
		'6',
		'7',
		'8',
		'9',
		'10',
		'11',
		'12',
		'13',
		'14',
		'15',
		'16',
		'17',
		'18',
		'19',
		'20',
		'21',
		'22',
		'23',
		'24',
		'25',
		'26',
		'27',
		'28',
		'29',
		'30',
		'31',
		'32',
		'33',
		'34',
		'35',
		'36',
		'37',
		'38',
		'39',
		'40',
		'41',
		'42',
		'43',
		'44',
		'45',
		'46',
		'47',
		'48',
		'49',
		'50',
		'51',
		'52',
		'53',
		'54',
		'55',
		'56',
		'57',
		'58',
		'59',
	];

	$scope.frequency_choice_list = [$scope.NO, $scope.YES];
	$scope.frequency_days_list = [
		'1',
		'2',
		'3',
		'4',
		'5',
		'6',
		'7',
		'8',
		'9',
		'10',
		'11',
		'12',
		'13',
		'14',
		'15',
		'16',
		'17',
		'18',
		'19',
		'20',
		'21',
		'22',
		'23',
		'24',
		'25',
		'26',
		'27',
		'28',
	];
	$scope.stop_schedule_list = [$scope.NO, $scope.YES];

	$scope.whole_event_list = [$scope.YES, $scope.NO];

	$scope.status_list = ['Enabled', 'Disabled'];

	$scope.NAME_EVT_USING_SCHEDULE_DESC = 'Schedule Description';
	$scope.NAME_EVT_USING_WEB_SCHEDULE_DESC = 'Web Description';
	$scope.NAME_EVT_USING_EVT_PROFILE = 'Event Profile Name';
	$scope.NAME_EVT_USING_WEB_EVT_PROFILE = 'Web Event Profile Name';
	$scope.NAME_EVT_USING_ORIG_WEB_EVT_NAME = 'Original Web Event Name';

	$scope.NAME_ENC_EVENT_USING_DEFAULT = $scope.NAME_EVT_USING_SCHEDULE_DESC;
	$scope.name_enc_event_using_options = [$scope.NAME_EVT_USING_SCHEDULE_DESC, $scope.NAME_EVT_USING_EVT_PROFILE];

	$scope.NAME_WEB_EVENT_USING_DEFAULT = $scope.NAME_EVT_USING_WEB_SCHEDULE_DESC;
	$scope.name_web_event_using_options = [
		$scope.NAME_EVT_USING_WEB_SCHEDULE_DESC,
		$scope.NAME_EVT_USING_WEB_EVT_PROFILE,
	];

	$scope.NAME_SIMLIVE_EVENT_USING_DEFAULT = $scope.NAME_EVT_USING_SCHEDULE_DESC;
	$scope.name_simlive_event_using_options = [
		$scope.NAME_EVT_USING_SCHEDULE_DESC,
		$scope.NAME_EVT_USING_ORIG_WEB_EVT_NAME,
	];

	$scope.getEncScheduleNameEventUsingLabel = function (value) {
		if (value) {
			return $scope.NAME_EVT_USING_SCHEDULE_DESC;
		}
		return $scope.NAME_EVT_USING_EVT_PROFILE;
	};

	$scope.getSimLiveNameEventUsingLabel = function (value) {
		if (value) {
			return $scope.NAME_EVT_USING_SCHEDULE_DESC;
		}
		return $scope.NAME_EVT_USING_ORIG_WEB_EVT_NAME;
	};

	$scope.isLoadingAddEventUI = function () {
		return (
			$scope.show_add_event &&
			($scope.is_loading_encoder_list ||
				$scope.is_loading_encoder_profile_list ||
				$scope.is_loading_event_profile_list ||
				$scope.is_loading_time_zone_list ||
				$scope.is_loading_transcoder_event_profile_list ||
				$scope.is_loading_web_encoder_profile_list)
		);
	};

	$scope.isLoadingUpdateEventUI = function () {
		return (
			$scope.is_busy_loading_web_events ||
			$scope.is_loading_encoder_list ||
			$scope.is_loading_encoder_profile_list ||
			$scope.is_loading_event_profile_list ||
			$scope.is_loading_time_zone_list ||
			$scope.is_loading_transcoder_event_profile_list ||
			$scope.is_loading_web_encoder_profile_list
		);
	};

	$scope.initializeEncoderList = function (promises) {
		if ($scope.encoder_list == null) {
			$scope.is_loading_encoder_list = true;
			const promise = $http
				.get(`${jcs.api.url}/encoders`, { withCredentials: true })
				.then(
					function (response) {
						// success

						$scope.load_encoder_list_error = null;
						$scope.encoder_list = response.data;
					},
					function () {
						// error

						$scope.load_encoder_list_error =
							'An error occurred while gathering information needed to create an event. Please try again, or report the problem if it persists.';
						$scope.encoder_list = null;
					}
				)
			['finally'](function () {
				// always called

				$scope.is_loading_encoder_list = false;
			});

			if (promises) {
				promises.push(promise);
			}
		}
	};

	$scope.initializeEncoderProfileList = function (promises) {
		if ($scope.encoder_profile_list == null) {
			$scope.is_loading_encoder_profile_list = true;
			var promise = $http
				.get(jcs.api.url + '/encoderprofiles', { withCredentials: true })
				.then(
					function (response) {
						// success

						$scope.load_encoder_profile_list_error = null;
						$scope.encoder_profile_list = response.data;
					},
					function () {
						// error

						$scope.load_encoder_profile_list_error =
							'An error occurred while gathering information needed to create an event. Please try again, or report the problem if it persists.';
						$scope.encoder_profile_list = null;
					}
				)
			['finally'](function () {
				// always called

				$scope.is_loading_encoder_profile_list = false;
			});

			if (promises) {
				promises.push(promise);
			}
		}
	};

	$scope.initializeEventProfileList = function (promises) {
		if ($scope.event_profile_list == null) {
			$scope.is_loading_event_profile_list = true;
			var promise = $http
				.get(jcs.api.url + '/streamprofiles', { withCredentials: true })
				.then(
					function (response) {
						// success

						$scope.load_event_profile_list_error = null;
						$scope.event_profile_list = response.data;
					},
					function () {
						// error

						$scope.load_event_profile_list_error =
							'An error occurred while gathering information needed to create an event. Please try again, or report the problem if it persists.';
						$scope.event_profile_list = null;
					}
				)
			['finally'](function () {
				// always called

				$scope.is_loading_event_profile_list = false;
			});

			if (promises) {
				promises.push(promise);
			}
		}
	};

	$scope.initializeWebEventProfileList = function (promises) {
		if ($scope.transcoder_event_profile_list == null) {
			$scope.is_loading_transcoder_event_profile_list = true;
			var promise = httpService.get(jcs.api.url_v3 + '/customers/' + Authentication.getCurrentUser().customerID + '/webeventprofiles', { withCredentials: true },
				function (response) { // success

					$scope.load_transcoder_event_profile_list_error = null;
					$scope.transcoder_event_profile_list = response.data;

					// format profile name to include additional info if they are not public
					webEventProfileService.formatName($scope.transcoder_event_profile_list);
				},
				function () { // error

					$scope.load_transcoder_event_profile_list_error = 'An error occurred while gathering information needed to create an event. Please try again, or report the problem if it persists.';
					$scope.transcoder_event_profile_list = null;
				},
				function () { // always called

					$scope.is_loading_transcoder_event_profile_list = false;
				}
			);

			if (promises) {
				promises.push(promise);
			}
		}
	};

	$scope.initializeWebEncoderProfileList = function (promises) {
		if ($scope.web_encoder_profile_list == null) {
			$scope.is_loading_web_encoder_profile_list = true;
			var promise = httpService.get(
				jcs.api.url_v3 + '/customers/' + Authentication.getCurrentUser().customerID + '/webencoderprofiles',
				{ withCredentials: true },
				function (response) {
					// success

					$scope.load_web_encoder_profile_list_error = null;
					$scope.web_encoder_profile_list = response.data;
					// add blank entry to front of array
					$scope.web_encoder_profile_list.unshift({ name: 'Default', uuid: null });
				},
				function () {
					// error

					$scope.load_web_encoder_profile_list_error =
						'An error occurred while gathering information needed to create an event. Please try again, or report the problem if it persists.';
					$scope.web_encoder_profile_list = null;
				},
				function () {
					// always called

					$scope.is_loading_web_encoder_profile_list = false;
				}
			);

			if (promises) {
				promises.push(promise);
			}
		}
	};

	$scope.initializeTimeZoneList = function (promises) {
		if ($scope.time_zone_list == null) {
			$scope.is_loading_time_zone_list = true;

			var promise = timeZoneService.getTimeZones().then(
				function (response) { // success

					$scope.load_time_zone_list_error = null;
					$scope.time_zone_list = response;

				},
				function () { // error

					$scope.load_time_zone_list_error = 'An error occurred while retrieving time zone information. Please try again, or report the problem if it persists.';
					$scope.time_zone_list = null;

				})['finally'](function () { // always called

					$scope.is_loading_time_zone_list = false;
				});

			if (promises) {
				promises.push(promise);
			}
		}
	};

	$scope.hasSimulcastOfType = function (web_event, simulcast_type) {
		if (web_event.hasOwnProperty('simulcasts') && web_event.simulcasts != null){
			for (const simulcast of web_event.simulcasts){
				if (simulcast.type == simulcast_type){
					return true;
				}
			}
		}
		return false;
	}

	$scope.getNumberOfCrossposts = function (simulcast) {
		if (simulcast.hasOwnProperty('crossposts') && simulcast.crossposts != null){
			return simulcast.crossposts.length;
		}
		return 0;
	}

	$scope.showUpdateEvent = function (event_to_update) {
		// don't show the "Schedule Type" dropdown, and assign our schedule type
		$scope.hide_schedule_type = true;
		$scope.prev_answer_to_sync_web_event_stop_time = null;
		$scope.schedule_to_add_type = event_to_update.eventType;

		if (event_to_update.eventType === $scope.SCHEDULE_TYPE_SIM_LIVE) {
			// clear form validation errors and messages; init our dropdown lists
			// by passing a callback here it allows us to initialize fields and update UI after we are assured list options are loaded
			$scope.prepareAddOrUpdateSimLiveEventForm(function () {
				var event_date_time = moment(event_to_update.scheduleStartDay + ' ' + event_to_update.localStartTime, $scope.DATE_TIME_FORMAT);
				var frequency_yesno = event_to_update.scheduleStartDay == event_to_update.scheduleEndDay ? $scope.NO : $scope.YES;
				var end_schedule_yesno = frequency_yesno == $scope.YES ? (event_to_update.scheduleEndDay == $scope.NEVER_END_DATE ? $scope.NO : $scope.YES) : $scope.NO;
				var enabled = event_to_update.enabled ? 'Enabled' : 'Disabled';

				$scope.date_picker_set_date(moment(event_date_time));
				if (event_to_update.scheduleEndDay != $scope.NEVER_END_DATE) {
					$scope.date_picker_set_end_date(moment(event_to_update.scheduleEndDay));
				} else {
					// if the event is set to never end, then init the end date field to be equal to start date
					$scope.date_picker_set_end_date(moment(event_to_update.scheduleStartDay));
				}

				$scope.show_update_event = true;
				$scope.addWarnOnPageChange();
				$scope.add_update_event_error = null;
				$scope.add_update_event_errors = [];

				$scope.web_event_to_add = {
					uuid: event_to_update.transcodedEventScheduleId,
					name: event_to_update.name,
					enabled: enabled,
					name_event_using: event_to_update.eventUsesScheduleName ? $scope.NAME_EVT_USING_SCHEDULE_DESC : $scope.NAME_EVT_USING_ORIG_WEB_EVT_NAME,
					transcoder_event_profile: event_to_update.transcodedEventProfileId,
					date: event_to_update.scheduleStartDay,
					time_hours: event_date_time.format('h'),
					time_minutes: event_date_time.format('mm'),
					time_meridiem: event_date_time.format('A'),
					time_zone: event_to_update.timeZone,
					frequency_yesno: frequency_yesno,
					frequency_days: event_to_update.frequency.toString(),
					end_schedule_yesno: end_schedule_yesno,
					end_date: event_to_update.scheduleEndDay,
					simulcasts: event_to_update.simulcasts == null ? null : JSON.parse(JSON.stringify(event_to_update.simulcasts)), // deep copy of array
				};

				// ensure our frequency UI elements have appropriate visibility
				$scope.onFrequencyChoiceChanged(false);
				$scope.onStopScheduleChoiceChanged(false);

				// see app.js for where focus is defined
				focus('add-web-schedule-input');
			});
		} else if (event_to_update.eventType === $scope.SCHEDULE_TYPE_ENCODER) {

			$scope.is_busy_loading_web_events = true;

			var promises = [];
			promises.push($http.get(`${jcs.api.url_v3}/customers/${Authentication.getCurrentUser().customerID}/webevents`, { withCredentials: true }));
			promises.push($http.get(`${jcs.api.url}/schedules/${event_to_update.scheduleId}`, { withCredentials: true }));

			$q.all(promises).then((response) => {

				// web events
				$scope.web_events_list = response[0].data;
				$scope.load_web_events_list_error = null;

				// encoder schedule
				var loaded_event = response[1].data;

				// clear form validation errors and messages; init our dropdown lists
				// by passing a callback here it allows us to initialize fields and update UI after we are assured list options are loaded
				$scope.prepareAddOrUpdateRegEventForm(function () {

					var event_date_time = moment(`${loaded_event.scheduleStartDay} ${loaded_event.localStartTime}`, $scope.DATE_TIME_FORMAT);

					$scope.date_picker_set_date(moment(event_date_time));
					if (loaded_event.scheduleEndDay != $scope.NEVER_END_DATE) {
						$scope.date_picker_set_end_date(moment(loaded_event.scheduleEndDay));
					} else {
						// if the event is set to never end, then init the end date field to be equal to start date
						$scope.date_picker_set_end_date(moment(loaded_event.scheduleStartDay));
					}

					$scope.show_update_event = true;
					$scope.addWarnOnPageChange();
					$scope.add_update_event_error = null;
					$scope.add_update_event_errors = [];

					var duration_hours = parseInt(loaded_event.length / 60);
					if (duration_hours == 0){
						duration_hours = '';
					}
					var duration_minutes = loaded_event.length % 60;
					var frequency_yesno = loaded_event.scheduleStartDay == loaded_event.scheduleEndDay ? $scope.NO : $scope.YES;
					var end_schedule_yesno = frequency_yesno == $scope.YES ? (loaded_event.scheduleEndDay == $scope.NEVER_END_DATE ? $scope.NO : $scope.YES) : $scope.NO;
					var enabled = loaded_event.enabled ? 'Enabled' : 'Disabled';

					$scope.reg_event_to_add = {
						uuid: loaded_event.uuid,
						name: loaded_event.name,
						enabled: enabled,
						name_event_using: loaded_event.eventUsesScheduleName ? $scope.NAME_EVT_USING_SCHEDULE_DESC : $scope.NAME_EVT_USING_EVT_PROFILE,
						encoder: loaded_event.encoderId,
						is_software_encoder: encoderService.isSoftwareEncoder($scope.getEncoderInfo(loaded_event.encoderId, $scope.encoder_list)),
						encoder_profile: loaded_event.encoderProfileId,
						event_profile: loaded_event.streamProfileId,
						date: loaded_event.scheduleStartDay,
						time_hours: event_date_time.format('h'),
						time_minutes: event_date_time.format('mm'),
						time_meridiem: event_date_time.format('A'),
						time_zone: loaded_event.timeZone,
						frequency_yesno: frequency_yesno,
						frequency_days: loaded_event.frequency.toString(),
						end_schedule_yesno: end_schedule_yesno,
						end_date: loaded_event.scheduleEndDay,
						duration_hours: duration_hours.toString(),
						duration_minutes: duration_minutes.toString(),
						web_events: [],
						web_events_add: [],
						web_events_update: [],
						web_events_delete: [],
					};

					// load web events for this encoder schedule
					for (var i = 0; i < event_to_update.web_events.length; i++) {
						var web_event = event_to_update.web_events[i];
						// add copy of web_event to the temp object used for editing
						$scope.reg_event_to_add.web_events.push($scope.getCopy(web_event));
					}

					// ensure our frequency UI elements have appropriate visibility
					$scope.onFrequencyChoiceChanged(false);
					$scope.onStopScheduleChoiceChanged(false);

					// see app.js for where focus is defined
					focus('add-event-input');
				});

			}).catch(() => {

				$scope.load_web_events_list_error = 'An error occurred while attempting to load the event for editing. Please try again, or report the problem if it persists.';

			}).finally(() => {

				$scope.is_busy_loading_web_events = false;

			});
		}
	};

	// this function is used to initialize our dropdowns selections. If a dropdown only has 1 option, then we should go ahead and auto select it.
	// But if there is more than 1 option, then we return null, because we want the user to specifically choose something.
	$scope.getDefaultSelection = function (list, property) {
		// if list only has 1 option, then return the specified property
		if (list != null && list.length == 1) {
			return list[0][property];
		}
		return null;
	};

	$scope.getCurrentTimeZone = function (){

		if ($scope.current_time_zone == null){
			var time_zone_guess = moment.tz.guess();
			var guess_upper = time_zone_guess.toUpperCase();
			// ensure the guessed timezone matches a valid timezone returned by the API
			for (var i=0; i < $scope.time_zone_list.length; i++){
				if ($scope.time_zone_list[i].name_upper == guess_upper){
					$scope.current_time_zone = time_zone_guess;
					return $scope.current_time_zone;
				}
			}
			// no matching timezone found (set to blank to prevent further attempts to find a match)
			$scope.current_time_zone = '';
		}
		return $scope.current_time_zone;
	};

	$scope.onScheduleTypeChange = function () {
		// clear any schedule type validation errors we might have had
		// TODO: should we move this into prepareAddOrUpdateRegEventForm??
		$scope.add_event_type_error = false;
		$scope.add_event_form_validation_error = null;

		var selected_moment = $scope.add_schedule_selected_date != null ? moment($scope.add_schedule_selected_date.date, 'M/D/YYYY') : $scope.TODAY;
		$scope.date_picker_set_date(selected_moment);
		$scope.date_picker_set_end_date(selected_moment);

		if ($scope.schedule_to_add_type === $scope.SCHEDULE_TYPE_SIM_LIVE) {
			// clear form validation errors and messages; init our dropdown lists
			// by passing a callback here it allows us to initialize fields and update UI after we are assured list options are loaded
			$scope.prepareAddOrUpdateSimLiveEventForm(function () {
				if ($scope.web_event_to_add == null) {
					$scope.web_event_to_add = {
						uuid: null,
						name: null,
						enabled: 'Enabled',
						name_event_using: $scope.NAME_SIMLIVE_EVENT_USING_DEFAULT,
						date: selected_moment.format($scope.DATE_FORMAT),
						transcoder_event_profile: $scope.getDefaultSelection($scope.transcoder_event_profile_list, 'uuid'),
						web_schedule_id: null,
						reg_schedule_id: null,
						whole_event: $scope.YES,
						time_hours: '9',
						time_minutes: '00',
						time_meridiem: 'AM',
						time_zone: $scope.getCurrentTimeZone(),
						frequency_yesno: $scope.NO,
						frequency_days: '7',
						end_schedule_yesno: $scope.NO,
						end_date: selected_moment.format($scope.DATE_FORMAT),
						duration_hours: '3',
						duration_minutes: '0',
					};
				}

				// ensure our frequency UI elements have appropriate visibility
				$scope.onFrequencyChoiceChanged(false);
				$scope.onStopScheduleChoiceChanged(false);

				// see app.js for where focus is defined
				focus('add-web-schedule-input');
			});
		} else if ($scope.schedule_to_add_type === $scope.SCHEDULE_TYPE_ENCODER) {
			// clear form validation errors and messages; init our dropdown lists
			// by passing a callback here it allows us to initialize fields and update UI after we are assured list options are loaded
			$scope.prepareAddOrUpdateRegEventForm(function () {
				if ($scope.reg_event_to_add == null) {
					const default_encoder = $scope.getDefaultSelection($scope.encoder_list, 'uuid');
					$scope.reg_event_to_add = {
						uuid: null,
						name: null,
						enabled: 'Enabled',
						name_event_using: $scope.NAME_ENC_EVENT_USING_DEFAULT,
						encoder: default_encoder,
						is_software_encoder: encoderService.isSoftwareEncoder($scope.getEncoderInfo(default_encoder, $scope.encoder_list)),
						encoder_profile: $scope.getDefaultSelection($scope.encoder_profile_list, 'uuid'),
						event_profile: $scope.getDefaultSelection($scope.event_profile_list, 'uuid'),
						date: selected_moment.format($scope.DATE_FORMAT),
						time_hours: '9',
						time_minutes: '00',
						time_meridiem: 'AM',
						time_zone: $scope.getCurrentTimeZone(),
						frequency_yesno: $scope.NO,
						frequency_days: '7',
						end_schedule_yesno: $scope.NO,
						end_date: selected_moment.format($scope.DATE_FORMAT),
						duration_hours: '3',
						duration_minutes: '0',
						web_events: [],
						web_events_add: [],
						web_events_update: [],
						web_events_delete: [],
					};
				}

				// ensure our frequency UI elements have appropriate visibility
				$scope.onFrequencyChoiceChanged(false);
				$scope.onStopScheduleChoiceChanged(false);

				// see app.js for where focus is defined
				focus('add-reg-schedule-input');
			});
		}
	};

	$scope.showAddEncoderSchedule = function (selected_date) {
		$scope.saveScrollLocation();

		$scope.schedule_to_add_type = $scope.SCHEDULE_TYPE_ENCODER;
		$scope.hide_schedule_type = !Authentication.getCurrentUser().hasPerm('transcoder_schedules.add'); // if they don't have perm for other schedule type, then hide type selection
		$scope.reg_event_to_add = null;
		$scope.web_event_to_add = null;

		$scope.add_schedule_selected_date = selected_date;

		$scope.show_add_event = true;
		$scope.addWarnOnPageChange();
		$scope.add_update_event_error = null;
		$scope.add_update_event_errors = [];

		$scope.onScheduleTypeChange();
	};

	$scope.showAddSimLiveWebSchedule = function (selected_date) {
		$scope.saveScrollLocation();

		$scope.schedule_to_add_type = $scope.SCHEDULE_TYPE_SIM_LIVE;
		$scope.hide_schedule_type = !Authentication.getCurrentUser().hasPerm('schedules.add'); // if they don't have perm for other schedule type, then hide type selection
		$scope.reg_event_to_add = null;
		$scope.web_event_to_add = null;

		$scope.add_schedule_selected_date = selected_date;

		$scope.show_add_event = true;
		$scope.addWarnOnPageChange();
		$scope.add_update_event_error = null;
		$scope.add_update_event_errors = [];

		$scope.onScheduleTypeChange();
	};

	// sim-live schedule
	// this method prepares the add/update event form by clearing all form validation errors and messages, and ensures the
	// form dropdown options are loaded; the callback should be called once the dropdown lists are known to be loaded
	$scope.prepareAddOrUpdateSimLiveEventForm = function (lists_are_loaded_callback) {
		// clear validation errors
		$scope.has_error = {};
		$scope.add_event_form_validation_error = '';

		var promises = [];
		// ensure that we have loaded the lists that will be used for our dropdowns
		$scope.initializeWebEventProfileList(promises);
		$scope.initializeEventProfileList(promises);
		$scope.initializeTimeZoneList(promises);

		// do any of our lists need to be loaded?
		if (promises.length > 0) {
			$q.allSettled(promises)
				.then(
					function (response) {
						// don't need to do anything b/c each promise will handle success itself
					},
					function (reasons) {
						// don't need to do anything b/c each promise will handle error itself
					}
				)
				.finally(function () {
					if (lists_are_loaded_callback != null) {
						lists_are_loaded_callback();
					}
				});
		} else {
			if (lists_are_loaded_callback != null) {
				lists_are_loaded_callback();
			}
		}
	};

	// encoder schedule
	// this method prepares the add/update event form by clearing all form validation errors and messages, and ensures the
	// form dropdown options are loaded; the callback should be called once the dropdown lists are known to be loaded
	$scope.prepareAddOrUpdateRegEventForm = function (lists_are_loaded_callback) {
		// clear validation errors
		$scope.has_error = {};
		$scope.add_event_form_validation_error = '';

		var promises = [];
		// ensure that we have loaded the lists that will be used for our dropdowns
		$scope.initializeEncoderList(promises);
		$scope.initializeEncoderProfileList(promises);
		$scope.initializeEventProfileList(promises);
		$scope.initializeTimeZoneList(promises);
		$scope.initializeWebEventProfileList(promises);
		$scope.initializeWebEncoderProfileList(promises);

		// do any of our lists need to be loaded?
		if (promises.length > 0) {
			$q.allSettled(promises)
				.then(
					function (response) {
						// don't need to do anything b/c each promise will handle success itself
					},
					function (reasons) {
						// don't need to do anything b/c each promise will handle error itself
					}
				)
				.finally(function () {
					if (lists_are_loaded_callback != null) {
						lists_are_loaded_callback();
					}
				});
		} else {
			if (lists_are_loaded_callback != null) {
				lists_are_loaded_callback();
			}
		}
	};

	$scope.isEmpty = function (value) {
		return value == null || value === '';
	};

	// returns TRUE if we have a form validation issue that the user needs to address
	$scope.doesAddOrUpdateSimLiveEventFormFailValidation = function () {
		$scope.has_error = {};

		// check required fields to ensure they aren't empty
		$scope.has_error.add_web_event_name = $scope.isEmpty($scope.web_event_to_add.name);
		$scope.has_error.add_web_event_date = $scope.isEmpty($scope.web_event_to_add.date);
		$scope.has_error.add_web_event_transcoder_event_profile = $scope.isEmpty(
			$scope.web_event_to_add.transcoder_event_profile
		);
		$scope.has_error.add_web_event_time_zone = $scope.isEmpty($scope.web_event_to_add.time_zone);

		var error_count = 0;
		for (var property in $scope.has_error) {
			if ($scope.has_error[property] === true) {
				error_count++;
			}
		}
		var has_validation_error = error_count > 0;

		$scope.add_event_form_validation_error = has_validation_error ? 'Please specify a value for the highlighted fields below.' : null;

		// if no "required field" errors, then ...
		if (!has_validation_error) {
			// ... check for date validation issues
			if ($scope.web_event_to_add.frequency_yesno == $scope.YES && $scope.web_event_to_add.end_schedule_yesno == $scope.YES) {
				// end date must come after start date (not before or equal to)
				$scope.has_error.add_web_event_end_date =
					moment($scope.web_event_to_add.date).isSame($scope.web_event_to_add.end_date) ||
					moment($scope.web_event_to_add.date).isAfter($scope.web_event_to_add.end_date);
				has_validation_error = $scope.has_error.add_web_event_end_date;
				$scope.add_event_form_validation_error = has_validation_error ? 'Please select an "End Schedule Date" that comes after the "Start Date".' : null;
			}
		}

		if ($scope.web_event_to_add.hasOwnProperty('simulcasts') && $scope.web_event_to_add.simulcasts != null && $scope.web_event_to_add.simulcasts.length > 0) {

			// if recurring event, then ensure no facebook simulcasts have publish status of "Scheduled Post"
			if (!has_validation_error && $scope.web_event_to_add.frequency_yesno == $scope.YES) {
				if ($scope.hasSimulcastWithScheduledPost($scope.web_event_to_add)){
					has_validation_error = true;
					$scope.add_event_form_validation_error = 'You cannot choose a recurring schedule when streaming to Facebook as a "Scheduled Post".';
				}
			}

			// if "Scheduled Post", then schedule start date/time must fall in a certain time range
			if (!has_validation_error && $scope.hasSimulcastWithScheduledPost($scope.web_event_to_add)) {
				let start_date_time = moment(`${$scope.web_event_to_add.date} ${$scope.web_event_to_add.time_hours}:${$scope.web_event_to_add.time_minutes} ${$scope.web_event_to_add.time_meridiem}`, $scope.DATE_TIME_MERIDIEM_FORMAT);
				let earliest_acceptable_time = moment(start_date_time).subtract(7, 'days');
				let latest_acceptable_time = moment(start_date_time).subtract(10, 'minutes');
				let now = moment();
				// ensure start date/time isn't more than 7 days away or less than 10 minutes away
				if (now.isBefore(earliest_acceptable_time)){
					has_validation_error = true;
					$scope.add_event_form_validation_error = 'An event that contains a Facebook Scheduled Post or YouTube Scheduled Event (events that appear in the destination in advance) cannot be scheduled more than 7 days in advance.';
				}
				// ensure start date/time isn't less than 10 minutes away
				else if (now.isAfter(latest_acceptable_time)){
					has_validation_error = true;
					$scope.add_event_form_validation_error = 'An event that contains a Facebook Scheduled Post or YouTube Scheduled Event (events that appear in the destination in advance) must be scheduled more than 10 minutes prior to the event start.';
				}
			}
		}

		return has_validation_error;
	};

	$scope.formatEventDateAsISO = function (event) {
		if (event){
			return moment(`${event.date} ${event.time_hours}:${event.time_minutes} ${event.time_meridiem}`, $scope.DATE_TIME_MERIDIEM_FORMAT).toISOString();
		}
		return '';
	}

	// takes the date from the event, and the time from the web event
	$scope.formatWebEventDateAsISO = function (event, web_event){
		if (event && web_event){
			return moment(`${event.date} ${web_event.time_hours}:${web_event.time_minutes} ${web_event.time_meridiem}`, $scope.DATE_TIME_MERIDIEM_FORMAT).toISOString();
		}
		return '';
	}

	$scope.isWebEventBeforeEncoderSchedule = function (web_event) {
		var schedule_time = moment(
			$scope.reg_event_to_add.time_hours +
			':' +
			$scope.reg_event_to_add.time_minutes +
			' ' +
			$scope.reg_event_to_add.time_meridiem,
			$scope.TIME_12H_FORMAT
		);
		var event_time = moment(
			web_event.time_hours + ':' + web_event.time_minutes + ' ' + web_event.time_meridiem,
			$scope.TIME_12H_FORMAT
		);
		return event_time.isBefore(schedule_time);
	};

	$scope.isWebEventAfterEncoderSchedule = function (web_event) {
		var schedule_time = moment(
			$scope.reg_event_to_add.time_hours +
			':' +
			$scope.reg_event_to_add.time_minutes +
			' ' +
			$scope.reg_event_to_add.time_meridiem,
			$scope.TIME_12H_FORMAT
		);
		schedule_time.add(
			$scope.convertTimeToMinutes($scope.reg_event_to_add.duration_hours, $scope.reg_event_to_add.duration_minutes),
			'minutes'
		);
		var event_time = moment(
			web_event.time_hours + ':' + web_event.time_minutes + ' ' + web_event.time_meridiem,
			$scope.TIME_12H_FORMAT
		);
		event_time.add($scope.convertTimeToMinutes(web_event.duration_hours, web_event.duration_minutes), 'minutes');
		return event_time.isAfter(schedule_time);
	};

	// returns true if there is overlap between the two events; it is okay if the start/end times are equal.
	$scope.doWebEventsOverlap = function (web_event1, web_event2) {
		var start_time1 = moment(
			web_event1.time_hours + ':' + web_event1.time_minutes + ' ' + web_event1.time_meridiem,
			$scope.TIME_12H_FORMAT
		);
		var start_time2 = moment(
			web_event2.time_hours + ':' + web_event2.time_minutes + ' ' + web_event2.time_meridiem,
			$scope.TIME_12H_FORMAT
		);

		var end_time1 = moment(start_time1);
		var end_time2 = moment(start_time2);
		end_time1.add($scope.convertTimeToMinutes(web_event1.duration_hours, web_event1.duration_minutes), 'minutes');
		end_time2.add($scope.convertTimeToMinutes(web_event2.duration_hours, web_event2.duration_minutes), 'minutes');

		// check to see if both start/end times for an event, are before the other event's start time
		var is_event1_before_event2 =
			start_time1.isBefore(start_time2) && (end_time1.isBefore(start_time2) || end_time1.isSame(start_time2));
		var is_event2_before_event1 =
			start_time2.isBefore(start_time1) && (end_time2.isBefore(start_time1) || end_time2.isSame(start_time1));

		if (is_event1_before_event2 || is_event2_before_event1) return false;
		return true;
	};

	$scope.getEncoderProfileForID = function (uuid) {
		for (const profile of $scope.encoder_profile_list){
			if (profile.uuid === uuid){
				return profile;
			}
		}
		return null;
	};

	// returns TRUE if we have a form validation issue that the user needs to address
	$scope.doesAddOrUpdateRegEventFormFailValidation = function () {
		$scope.has_error = {};

		// check required fields to ensure they aren't empty
		$scope.has_error.add_event_name_error = $scope.isEmpty($scope.reg_event_to_add.name);
		$scope.has_error.add_event_time_zone_error = $scope.isEmpty($scope.reg_event_to_add.time_zone);
		$scope.has_error.add_event_encoder_error = $scope.isEmpty($scope.reg_event_to_add.encoder);
		if (!$scope.reg_event_to_add.is_software_encoder){
			$scope.has_error.add_event_encoder_profile_error = $scope.isEmpty($scope.reg_event_to_add.encoder_profile);
		}
		$scope.has_error.add_event_event_profile_error = $scope.isEmpty($scope.reg_event_to_add.event_profile);
		$scope.has_error.add_event_date_error = $scope.isEmpty($scope.reg_event_to_add.date);
		$scope.has_error.add_event_end_date_error =
			$scope.reg_event_to_add.frequency_yesno == $scope.YES &&
			$scope.reg_event_to_add.end_schedule_yesno == $scope.YES &&
			($scope.reg_event_to_add.end_date == null || $scope.reg_event_to_add.end_date == '');

		let has_validation_error = Object.values($scope.has_error).some(x => x);
		$scope.add_event_form_validation_error = has_validation_error ? 'Please specify a value for the highlighted fields below.' : null;

		// if no "required field" errors, then ...
		if (!has_validation_error) {
			// ... check for date validation issues
			if ($scope.reg_event_to_add.frequency_yesno == $scope.YES && $scope.reg_event_to_add.end_schedule_yesno == $scope.YES) {
				// end date must come after start date (not before or equal to)
				$scope.has_error.add_event_end_date_error = moment($scope.reg_event_to_add.date).isSame($scope.reg_event_to_add.end_date) || moment($scope.reg_event_to_add.date).isAfter($scope.reg_event_to_add.end_date);
				has_validation_error = $scope.has_error.add_event_end_date_error;
				$scope.add_event_form_validation_error = has_validation_error ? 'Please select an "End Schedule Date" that comes after the event "Date".' : null;
			}
		}

		// if no errors, then check to ensure web events occur within the bounds of the encoder schedule
		if (!has_validation_error && $scope.reg_event_to_add.web_events.length > 0) {
			for (var i = 0; i < $scope.reg_event_to_add.web_events.length; i++) {
				var web_event = $scope.reg_event_to_add.web_events[i];
				// only look at web events that are not "wholeEvent"
				if (!web_event.wholeEvent) {
					if ($scope.isWebEventBeforeEncoderSchedule(web_event)) {
						has_validation_error = true;
						var web_event_start_time = `${web_event.time_hours}:${web_event.time_minutes} ${web_event.time_meridiem}`;
						var encoder_start_time = `${$scope.reg_event_to_add.time_hours}:${$scope.reg_event_to_add.time_minutes} ${$scope.reg_event_to_add.time_meridiem}`;
						$scope.add_event_form_validation_error =
							`Web event start times must not occur before the encoder schedule's start time. Web event "${web_event.name}" starts at ` +
							`${web_event_start_time}, whereas the encoder is not scheduled to start until ${encoder_start_time}. Please adjust the web event start time.`;
						break;
					} else if ($scope.isWebEventAfterEncoderSchedule(web_event)) {
						has_validation_error = true;
						var event_time = moment(`${web_event.time_hours}:${web_event.time_minutes} ${web_event.time_meridiem}`, $scope.TIME_12H_FORMAT);
						event_time.add($scope.convertTimeToMinutes(web_event.duration_hours, web_event.duration_minutes), 'minutes');
						var schedule_time = moment(`${$scope.reg_event_to_add.time_hours}:${$scope.reg_event_to_add.time_minutes} ${$scope.reg_event_to_add.time_meridiem}`, $scope.TIME_12H_FORMAT);
						schedule_time.add($scope.convertTimeToMinutes($scope.reg_event_to_add.duration_hours, $scope.reg_event_to_add.duration_minutes), 'minutes');
						$scope.add_event_form_validation_error =
							`A web event runs beyond the encoder stop time. Web event "${web_event.name}" runs until ${event_time.format($scope.TIME_12H_FORMAT)}` +
							`, whereas the encoder is scheduled to stop at ${schedule_time.format($scope.TIME_12H_FORMAT)}. Please adjust the web event to not run beyond the encoder stop time.`;
						break;
					}
				}
			}
		}

		// ensure web event do not overlap (if they are using same Web Event Profile)
		if (!has_validation_error && $scope.reg_event_to_add.web_events.length > 1) {
			for (var i = 0; i < $scope.reg_event_to_add.web_events.length && !has_validation_error; i++) {
				if (i < $scope.reg_event_to_add.web_events.length - 1) {
					for (var j = i + 1; j < $scope.reg_event_to_add.web_events.length && !has_validation_error; j++) {
						var this_event = $scope.reg_event_to_add.web_events[i];
						var that_event = $scope.reg_event_to_add.web_events[j];
						if (this_event.web_event_profile === that_event.web_event_profile) {
							if ($scope.doWebEventsOverlap(this_event, that_event)) {
								has_validation_error = true;
								$scope.add_event_form_validation_error = `Web events that use the same web event profile must not overlap. Please adjust the times for "${this_event.name}" and "${that_event.name}".`;
							}
						}
					}
				}
			}
		}

		// if recurring event, then ensure no facebook simulcasts have publish status of "Scheduled Post"
		if (!has_validation_error && $scope.reg_event_to_add.web_events.length > 0 && $scope.reg_event_to_add.frequency_yesno == $scope.YES) {
			if ($scope.hasWebEventWithScheduledPost($scope.reg_event_to_add.web_events)){
				has_validation_error = true;
				$scope.add_event_form_validation_error = 'You cannot choose a recurring schedule if a web event streams to Facebook as a "Scheduled Post"';
			}
		}

		// if "Scheduled Post", then schedule start date/time must fall in a certain time range
		if (!has_validation_error && $scope.reg_event_to_add.web_events.length > 0 && $scope.hasWebEventWithScheduledPost($scope.reg_event_to_add.web_events)) {
			if ($scope.doesAnyScheduledPostStartLaterThan(7, 'days', $scope.reg_event_to_add.date, $scope.reg_event_to_add.web_events)){
				has_validation_error = true;
				$scope.add_event_form_validation_error = 'An event that contains a Facebook Scheduled Post or YouTube Scheduled Event (events that appear in the destination in advance) cannot be scheduled more than 7 days in advance.';
			} else if ($scope.doesAnyScheduledPostStartSoonerThan(10, 'minutes', $scope.reg_event_to_add.date, $scope.reg_event_to_add.web_events)){
				has_validation_error = true;
				$scope.add_event_form_validation_error = 'An event that contains a Facebook Scheduled Post or YouTube Scheduled Event (events that appear in the destination in advance) must be scheduled more than 10 minutes prior to the event start.';
			}
		}
		if (!has_validation_error && $scope.hasInvalidIntervalBuffer($scope.reg_event_to_add)) {
			has_validation_error = true;
			$scope.add_event_form_validation_error = `Encoder events may not be
				scheduled within 5 minutes of one another.
				We include this window of time to allow for the
				possibility of a network issue which provides a
				small window for the encoder to catch up before starting a new event.`;
		}

		// if we have web events, ensure the web event profile region matches the encoder profile region
		if (!has_validation_error && $scope.reg_event_to_add.web_events.length > 0){
			const event_profile_region_id = $scope.getEventProfileRegionId($scope.reg_event_to_add.event_profile);
			// region ID is always null on older event profiles, therefore we won't be able to do any region checking on those profiles
			if (event_profile_region_id !== null){

				const mismatched_web_event = $scope.findRegionMismatch(event_profile_region_id, $scope.reg_event_to_add.web_events);
				if (mismatched_web_event){
					has_validation_error = true;
					$scope.add_event_form_validation_error = `The web event profile "${mismatched_web_event.web_event_profile_name}" must match the region of the encoder event profile. Please select profiles with matching regions.`;
				}
			}
		}

		return has_validation_error;
	};

	$scope.getWebEventProfileRegionId = function (web_event_profile_uuid) {
		if ($scope.transcoder_event_profile_list){
			const match = $scope.transcoder_event_profile_list.find(profile => profile.uuid === web_event_profile_uuid);
			if (match){
				return match.regionId;
			}
		}
		return null;
	}

	$scope.getEventProfileRegionId = function (event_profile_uuid) {
		if ($scope.event_profile_list){
			const match = $scope.event_profile_list.find(profile => profile.uuid === event_profile_uuid);
			if (match){
				return match.regionId;
			}
		}
		return null;
	};

	$scope.findRegionMismatch = function (region_id, web_event_list) {
		if (web_event_list){
			for (const web_event of web_event_list){
				const web_event_profile_region_id = $scope.getWebEventProfileRegionId(web_event.web_event_profile);
				if (web_event_profile_region_id !== region_id && web_event_profile_region_id !== constants.INTEGRATION_REGION_UUID){
					return web_event;
				}
			}
		}
		return null;
	};

	$scope.doesAnyScheduledPostStartLaterThan = function (time_value, time_unit, date, web_events){
		for (let web_event of web_events){
			if ($scope.hasSimulcastWithScheduledPost(web_event)){
				let start_date_time = moment(`${date} ${web_event.time_hours}:${web_event.time_minutes} ${web_event.time_meridiem}`, $scope.DATE_TIME_MERIDIEM_FORMAT);
				let earliest_acceptable_time = moment(start_date_time).subtract(time_value, time_unit);
				let now = moment();
				if (now.isBefore(earliest_acceptable_time)){
					return true;
				}
			}
		}
		return false;
	};

	$scope.doesAnyScheduledPostStartSoonerThan = function (time_value, time_unit, date, web_events){
		for (let web_event of web_events){
			if ($scope.hasSimulcastWithScheduledPost(web_event)){
				let start_date_time = moment(`${date} ${web_event.time_hours}:${web_event.time_minutes} ${web_event.time_meridiem}`, $scope.DATE_TIME_MERIDIEM_FORMAT);
				let latest_acceptable_time = moment(start_date_time).subtract(time_value, time_unit);
				let now = moment();
				if (now.isAfter(latest_acceptable_time)){
					return true;
				}
			}
		}
		return false;
	};

	$scope.hasWebEventWithScheduledPost = function (web_events){
		for (let web_event of web_events) {
			if (web_event.hasOwnProperty('simulcasts') && web_event.simulcasts != null && web_event.simulcasts.length > 0) {
				if ($scope.hasSimulcastWithScheduledPost(web_event)){
					return true;
				}
			}
		}
		return false;
	};

	$scope.hasSimulcastWithScheduledPost = function (web_event) {
		if (web_event.hasOwnProperty('simulcasts') && web_event.simulcasts != null && web_event.simulcasts.length > 0) {
			for (let simulcast of web_event.simulcasts) {
				if (simulcast.publishStatus == $scope.social_media.SCHEDULED_POST){
					return true;
				}
			}
		}
		return false;
	};

	$scope.getApiDataForEncoderScheduleWebEvent = function (encoder_schedule, web_event) {
		var startDateTime = moment(
			encoder_schedule.date + ' ' + web_event.time_hours + ':' + web_event.time_minutes + ' ' + web_event.time_meridiem,
			$scope.DATE_TIME_MERIDIEM_FORMAT
		);

		// ensure special chars don't blow up our json; see for more options:
		// http://stackoverflow.com/questions/4253367/how-to-escape-a-json-string-containing-newline-characters-using-javascript
		var escaped_name = web_event.name.replace(/\\"/g, '\\"');

		var web_event_data = {
			name: escaped_name,
			enabled: web_event.enabled == 'Enabled',
			eventUsesScheduleName: web_event.name_event_using == $scope.NAME_EVT_USING_WEB_SCHEDULE_DESC,
			scheduleStartDay: startDateTime.format($scope.DATE_FORMAT),
			timeZone: encoder_schedule.time_zone,
			frequency: encoder_schedule.frequency_days,
			webEventProfileId: web_event.web_event_profile,
			webEncoderProfileId: web_event.web_encoder_profile,
			scheduleId: encoder_schedule.uuid,
			simulcasts: null,
		};

		// check to see if whole or partial event
		// note: one potential issue. if a web event is set to wholeEvent being true, if the time/duration of the encoder schedule changes, the web event time will change to match.
		// it's possible the UI doesn't make this clear.
		var is_whole_event =
			encoder_schedule.time_hours === web_event.time_hours &&
			encoder_schedule.time_minutes === web_event.time_minutes &&
			encoder_schedule.time_meridiem === web_event.time_meridiem &&
			encoder_schedule.duration_hours === web_event.duration_hours &&
			encoder_schedule.duration_minutes === web_event.duration_minutes;
		if (is_whole_event) {
			web_event_data.wholeEvent = true;
		} else {
			var duration_in_minutes = $scope.convertTimeToMinutes(web_event.duration_hours, web_event.duration_minutes);

			web_event_data.wholeEvent = false;
			web_event_data.localStartTime = startDateTime.format('HH:mm');
			web_event_data.length = duration_in_minutes;
		}

		// if this is not a recurring event, then set the end day to same date as start day
		if (encoder_schedule.frequency_yesno == $scope.NO) {
			web_event_data.scheduleEndDay = web_event_data.scheduleStartDay;
		} else {
			// we have a recurring event, so see if the user specified an end date
			web_event_data.scheduleEndDay = encoder_schedule.end_schedule_yesno == $scope.YES ? encoder_schedule.end_date : $scope.NEVER_END_DATE;
		}

		// save simulcast data
		if (web_event.hasOwnProperty('simulcasts') && web_event.simulcasts != null && web_event.simulcasts.length > 0) {
			web_event_data.simulcasts = [];
			for (var i = 0; i < web_event.simulcasts.length; i++) {
				var entry = web_event.simulcasts[i];
				var simulcast_data = {
					type: entry.type,
					channelId: entry.channelId,
					channelName: entry.channelName,
					destinationId: entry.destinationId,
					destinationName: entry.destinationName,
					privacy: entry.privacy,
					publishStatus: entry.publishStatus,
					crossposts: entry.crossposts,
					title: entry.title,
					description: entry.description,
					imageUrl: entry.imageUrl,
				};
				if (entry.hasOwnProperty('uuid')){
					simulcast_data.uuid = entry.uuid;
				}
				web_event_data.simulcasts.push(simulcast_data);
			}
		}

		return web_event_data;
	};

	$scope.addOrUpdateEvent = function () {
		if ($scope.schedule_to_add_type === $scope.SCHEDULE_TYPE_ENCODER) {
			$scope.addOrUpdateRegEvent();
		} else if ($scope.schedule_to_add_type === $scope.SCHEDULE_TYPE_SIM_LIVE) {
			$scope.addOrUpdateSimLiveEvent();
		} else {
			$scope.add_event_type_error = true;
			$scope.add_event_form_validation_error = 'Please specify a schedule type.';
		}
	};

	// returns event with matching UUID from given list
	$scope.getEventByIDFromList = function (list, uuid) {
		for (var i = 0; i < list.length; i++) {
			var item = list[i];
			if (item.uuid == uuid) return item;
		}
		return null;
	};

	$scope.getDeletedWebEventNameForID = function (uuid) {
		// NOTE: this is only for web events marked for deletion
		for (var i = 0; i < $scope.reg_event_to_add.web_events_delete.length; i++) {
			var web_event = $scope.reg_event_to_add.web_events_delete[i];
			if (web_event.uuid == uuid) {
				return web_event.name;
			}
		}
		return 'Unknown';
	};
	$scope.convertWebEventDelUrlToName = function (url) {
		// pull uuid off the end
		var index = url.indexOf($scope.API_PATH_WEB_EVENT_SCHEDULE);
		if (index != -1) {
			index += $scope.API_PATH_WEB_EVENT_SCHEDULE.length;
			var uuid = url.substr(index);
			return $scope.getDeletedWebEventNameForID(uuid);
		}
		return 'Unknown';
	};

	$scope.getStopTime = function (event) {
		if (event != null) {
			// create start time
			var start = moment(
				event.time_hours + ':' + event.time_minutes + ' ' + event.time_meridiem,
				$scope.TIME_12H_FORMAT
			);
			// add duration
			start.add(event.duration_hours, 'hours');
			start.add(event.duration_minutes, 'minutes');
			// return stop time
			return start.format($scope.TIME_12H_FORMAT);
		}
		return '';
	};

	$scope.getMatchingWebEventByStartTime = function (list, event) {
		for (var i = 0; i < list.length; i++) {
			var list_event = list[i];
			// compare start time (hours/minutes/meridiem)
			if (
				list_event.time_hours == event.time_hours &&
				list_event.time_minutes == event.time_minutes &&
				list_event.time_meridiem == event.time_meridiem
			) {
				return list_event;
			}
		}
		return null;
	};

	$scope.willEncoderRunNowForSchedule = function (schedule, current_time) {
		if (schedule.scheduleStartDay == schedule.scheduleEndDay) {
			// one time event

			if (current_time.isSame(schedule.scheduleStartDay, 'day')) {
				var schedule_start_time = moment(
					schedule.scheduleStartDay + ' ' + schedule.localStartTime,
					$scope.DATE_TIME_FORMAT
				);
				var schedule_end_time = moment(schedule_start_time);
				schedule_end_time.add(schedule.length, 'minutes');

				return current_time.isBetween(schedule_start_time, schedule_end_time);
			}

			return false; // no, schedule is for different day
		} else {
			// repeating event

			var schedule_start_time = moment(
				schedule.scheduleStartDay + ' ' + schedule.localStartTime,
				$scope.DATE_TIME_FORMAT
			);
			var schedule_end_date = moment(schedule.scheduleEndDay, $scope.DATE_FORMAT); // end date of repeating event

			// check our date unless it is in the future
			while (
				current_time.isAfter(schedule_start_time) &&
				(schedule_end_date.isAfter(schedule_start_time, 'day') || schedule_end_date.isSame(schedule_start_time, 'day'))
			) {
				var schedule_end_time = moment(schedule_start_time);
				schedule_end_time.add(schedule.length, 'minutes');

				if (current_time.isBetween(schedule_start_time, schedule_end_time)) {
					return true;
				}
				// increment date by schedule's frequency
				schedule_start_time.add(schedule.frequency, 'days');
			}

			return false;
		}
	};

	$scope.getEncoderRequestedStatus = function (uuid, encoder_list) {
		for (var i = 0; i < encoder_list.length; i++) {
			if (uuid == encoder_list[i].uuid) return encoder_list[i].requestedStatus;
		}
		return null;
	};

	// loads the info that will will be used by the willScheduleChangeStopEncoder method
	$scope.loadWillScheduleChangeStopEncoder = function () {
		$scope.is_busy_saving_event = true;

		// get the encoder's requestedStatus (which will let us know if it is running)
		$http
			.get(`${jcs.api.url}/encoders`, { withCredentials: true })
			.then(
				function (response) {
					// success

					var encoder_requested_status = $scope.getEncoderRequestedStatus(
						$scope.reg_event_to_add.encoder,
						response.data
					);

					// ensure we have a valid requestedStatus
					if (encoder_requested_status == null) {
						console.log(
							'Encoders requestedStatus is invalid. Unable to determine if we should warn user that schedule change may stop encoder.'
						);
						$scope.add_event_form_validation_error =
							'An error occurred while attempting to modify the schedule. Please try again, or report the problem if it persists.';
						$scope.is_busy_saving_event = false;
					} else {
						$http
							.get(jcs.api.url + '/schedules', { withCredentials: true })
							.then(
								function (response) {
									// success

									var schedule_list = response.data;

									// check to see if we need to warn the user about the encoder stopping (this check needs to be made whether they are adding
									// a new schedule or editing an existing schedule)
									if ($scope.willScheduleChangeStopEncoder(encoder_requested_status, schedule_list)) {
										$('#schedule-warning-modal').modal('show');
										$scope.is_busy_saving_event = false;
									} else {
										$scope.performSaveForAddOrUpdateRegEvent();
									}
								},
								function () {
									// error

									$scope.add_event_form_validation_error =
										'An error occurred while attempting to modify the schedule. Please try again, or report the problem if it persists.';
									$scope.is_busy_saving_event = false;
								}
							)
						['finally'](function () {
							// always called
						});
					}
				},
				function () {
					// error

					$scope.add_event_form_validation_error =
						'An error occurred while attempting to modify the schedule. Please try again, or report the problem if it persists.';
					$scope.is_busy_saving_event = false;
				}
			)
		['finally'](function () {
			// always called
		});
	};

	$scope.willScheduleChangeStopEncoder = function (encoder_requested_state, schedule_list) {
		var current_time = moment();

		var encoder_will_be_running = false;
		for (var i = 0; i < schedule_list.length && !encoder_will_be_running; i++) {
			var schedule = schedule_list[i];

			if (schedule.encoderId == $scope.reg_event_to_add.encoder) {
				if (schedule.uuid == $scope.reg_event_to_add.uuid) {
					// create moment using reg_event_to_add's timezone, and then convert to the user's local timezone
					var startDateTime = moment
						.tz(
							$scope.reg_event_to_add.date +
							' ' +
							$scope.reg_event_to_add.time_hours +
							':' +
							$scope.reg_event_to_add.time_minutes +
							' ' +
							$scope.reg_event_to_add.time_meridiem,
							$scope.DATE_TIME_MERIDIEM_FORMAT,
							$scope.reg_event_to_add.time_zone
						)
						.tz(moment.tz.guess());
					var duration_in_minutes = $scope.convertTimeToMinutes(
						$scope.reg_event_to_add.duration_hours,
						$scope.reg_event_to_add.duration_minutes
					);

					var modified_schedule = {
						scheduleStartDay: startDateTime.format($scope.DATE_FORMAT),
						scheduleEndDay:
							$scope.reg_event_to_add.frequency_yesno == $scope.NO
								? startDateTime.format($scope.DATE_FORMAT)
								: $scope.reg_event_to_add.end_schedule_yesno == $scope.YES
									? $scope.reg_event_to_add.end_date
									: $scope.NEVER_END_DATE,
						localStartTime: startDateTime.format($scope.TIME_24H_FORMAT),
						length: duration_in_minutes,
						frequency: $scope.reg_event_to_add.frequency_days,
					};

					encoder_will_be_running = $scope.willEncoderRunNowForSchedule(modified_schedule, current_time);
				} else {
					encoder_will_be_running = $scope.willEncoderRunNowForSchedule(schedule, current_time);
				}
			}
		}

		// if encoder requestedState is start, but will no longer be running after our schedule changes, then return true so we can warn user
		if (encoder_requested_state == 'start' && !encoder_will_be_running) {
			return true;
		}

		return false;
	};

	// this method still performs regular form validation, but does not warn the user about consequences of the schedule change. This should only
	// be done after warning the user, and they've decided to proceed with the save anyway. We will also use this if they are adding a brand new
	// schedule.
	$scope.forceAddOrUpdateRegEvent = function () {
		// ensure warning modal is closed
		$('#schedule-warning-modal').modal('hide');

		// since our error variable is an array, reset errors before we validate and attempt to save
		$scope.add_update_event_errors = [];

		// if we have form validation errors, then don't go any further
		if ($scope.doesAddOrUpdateRegEventFormFailValidation()){
			return false;
		}

		$scope.performSaveForAddOrUpdateRegEvent();
	};

	// this method will do the regular form validation check, and then will check to see if we need to warn the user about the schedule change causing
	// a currently running encoder to stop.
	$scope.addOrUpdateRegEvent = function () {
		// we only need to check if the encoder will be stopped if the user is editing an existing schedule
		if ($scope.reg_event_to_add.uuid != null) {
			// updating existing schedule

			// since our error variable is an array, reset errors before we validate and attempt to save
			$scope.add_update_event_errors = [];

			// if we have form validation errors, then don't go any further
			if ($scope.doesAddOrUpdateRegEventFormFailValidation()){
				return false;
			}

			// load necessary info and then check to see if schedule change will result in running encoder being stopped
			$scope.loadWillScheduleChangeStopEncoder();
		} else {
			// adding brand new schedule

			$scope.forceAddOrUpdateRegEvent();
		}
	};

	$scope.getMixpanelDataForEncoderSchedule = function (reg_event_to_add, event_data) {
		return {
			[MPEventProperty.SCHEDULE_TYPE]: 'live',
			[MPEventProperty.SCHEDULE_UUID]: reg_event_to_add.uuid,
			[MPEventProperty.SCHEDULE_NAME]: event_data.name,
			[MPEventProperty.EVENT_DURATION]: event_data.length,
			[MPEventProperty.EVENT_STATUS]: event_data.enabled ? 'enabled' : 'disabled',
			[MPEventProperty.EVENT_FREQUENCY]: reg_event_to_add.frequency_yesno === $scope.NO ? '0' : reg_event_to_add.frequency_days,
			[MPEventProperty.ENCODER_UUID]: event_data.encoderId,
			[MPEventProperty.ENCODER_NAME]: $scope.encoder_list?.find(item => item.uuid === event_data.encoderId)?.name,
			[MPEventProperty.ENCODER_PROFILE_UUID]: reg_event_to_add.is_software_encoder ? 'Configured in ProPresenter' : event_data.encoderProfileId,
			[MPEventProperty.ENCODER_PROFILE_NAME]: reg_event_to_add.is_software_encoder ? 'Configured in ProPresenter' : $scope.encoder_profile_list?.find(item => item.uuid === event_data.encoderProfileId)?.name,
			[MPEventProperty.ENCODER_EVENT_PROFILE_UUID]: event_data.streamProfileId,
			[MPEventProperty.ENCODER_EVENT_PROFILE_NAME]: $scope.event_profile_list?.find(item => item.uuid === event_data.streamProfileId)?.name,
			[MPEventProperty.WEB_EVENT_COUNT]: reg_event_to_add.web_events?.length,
			[MPEventProperty.START_DATE]: event_data.scheduleStartDay,
			[MPEventProperty.START_TIME_24H]: event_data.localStartTime,
			[MPEventProperty.TIMEZONE]: event_data.timeZone,
		};
	};

	$scope.getMixpanelDataForEncoderScheduleWebEvent = function (reg_event_to_add, event_data, web_event) {
		const start_time_24h = moment(`${event_data.scheduleStartDay} ${web_event.time_hours}:${web_event.time_minutes} ${web_event.time_meridiem}`, $scope.DATE_TIME_MERIDIEM_FORMAT).format($scope.TIME_24H_FORMAT);
		return {
			[MPEventProperty.SCHEDULE_UUID]: reg_event_to_add.uuid,
			[MPEventProperty.SCHEDULE_NAME]: event_data.name,
			[MPEventProperty.WEB_EVENT_NAME]: web_event.name,
			[MPEventProperty.EVENT_DURATION]: $scope.convertTimeToMinutes(web_event.duration_hours, web_event.duration_minutes),
			[MPEventProperty.EVENT_STATUS]: web_event.enabled,
			[MPEventProperty.WEB_ENCODER_PROFILE_UUID]: web_event.web_encoder_profile,
			[MPEventProperty.WEB_ENCODER_PROFILE_NAME]: web_event.web_encoder_profile_name,
			[MPEventProperty.WEB_EVENT_PROFILE_UUID]: web_event.web_event_profile,
			[MPEventProperty.WEB_EVENT_PROFILE_NAME]: web_event.web_event_profile_name,
			[MPEventProperty.SOCIAL_DESTINATION_COUNT]: web_event.simulcasts?.length,
			[MPEventProperty.START_TIME_24H]: start_time_24h,
		};
	};

	$scope.getMixpanelDataForEncoderScheduleSimulcast = function (reg_event_to_add, event_data, web_event, simulcast) {
		const start_time_24h = moment(`${event_data.scheduleStartDay} ${web_event.time_hours}:${web_event.time_minutes} ${web_event.time_meridiem}`, $scope.DATE_TIME_MERIDIEM_FORMAT).format($scope.TIME_24H_FORMAT);
		return {
			[MPEventProperty.SCHEDULE_UUID]: reg_event_to_add.uuid,
			[MPEventProperty.SCHEDULE_NAME]: event_data.name,
			[MPEventProperty.WEB_EVENT_NAME]: web_event.name,
			[MPEventProperty.CHANNEL_NAME]: simulcast.channelName,
			[MPEventProperty.SOCIAL_DESTINATION]: simulcast.destinationName,
			[MPEventProperty.PUBLISH_STATUS]: simulcast.publishStatus,
			[MPEventProperty.PRIVACY]: simulcast.privacy,
			[MPEventProperty.TYPE]: simulcast.type,
			[MPEventProperty.SOCIAL_CROSSPOST_COUNT]: simulcast.crossposts?.length,
			[MPEventProperty.START_TIME_24H]: start_time_24h,
		};
	};

	$scope.performSaveForAddOrUpdateRegEvent = function () {
		const startDateTime = moment(`${$scope.reg_event_to_add.date} ${$scope.reg_event_to_add.time_hours}:${$scope.reg_event_to_add.time_minutes} ${$scope.reg_event_to_add.time_meridiem}`, $scope.DATE_TIME_MERIDIEM_FORMAT);
		const duration_in_minutes = $scope.convertTimeToMinutes($scope.reg_event_to_add.duration_hours, $scope.reg_event_to_add.duration_minutes);

		// ensure special chars don't blow up our json; see for more options:
		// http://stackoverflow.com/questions/4253367/how-to-escape-a-json-string-containing-newline-characters-using-javascript
		const escaped_name = $scope.reg_event_to_add.name.replace(/\\"/g, '\\"');

		// determine encoder profile ID. if a software encoder is specified for this schedule then just use the encoder profile that is already assigned to it
		// (software encoders are assigned a default encoder profile when they are first added to the system -- even though the encoder profile isn't used by
		// the software encoder), since the API currently requires a encoder profile to be specified. it was decided that this would be easiest for now,
		// rather than trying to update the backend & db to support encoders without encoder profiles. if a hardware encoder, then of course use the encoder
		// profile chosen in the dropdown.
		let encoder_profile_id = null;
		if ($scope.reg_event_to_add.is_software_encoder){
			const encoder_info = $scope.getEncoderInfo($scope.reg_event_to_add.encoder, $scope.encoder_list);
			encoder_profile_id = encoder_info.encoderProfile.uuid;
		} else {
			encoder_profile_id = $scope.reg_event_to_add.encoder_profile;
		}

		const event_data = {
			name: escaped_name,
			encoderId: $scope.reg_event_to_add.encoder,
			encoderProfileId: encoder_profile_id,
			streamProfileId: $scope.reg_event_to_add.event_profile,
			scheduleStartDay: startDateTime.format($scope.DATE_FORMAT),
			localStartTime: startDateTime.format($scope.TIME_24H_FORMAT),
			timeZone: $scope.reg_event_to_add.time_zone,
			frequency: $scope.reg_event_to_add.frequency_days,
			length: duration_in_minutes,
			enabled: $scope.reg_event_to_add.enabled == 'Enabled',
			eventUsesScheduleName: $scope.reg_event_to_add.name_event_using == $scope.NAME_EVT_USING_SCHEDULE_DESC,
		};

		// if this is not a recurring event, then set the end day to same date as start day
		if ($scope.reg_event_to_add.frequency_yesno == $scope.NO) {
			event_data.scheduleEndDay = event_data.scheduleStartDay;
		} else {
			// we have a recurring event, so see if the user specified an end date
			event_data.scheduleEndDay = $scope.reg_event_to_add.end_schedule_yesno == $scope.YES ? $scope.reg_event_to_add.end_date : $scope.NEVER_END_DATE;
		}

		$scope.is_busy_saving_event = true;

		if ($scope.reg_event_to_add.uuid != null) {

			// ensure our encoder schedule date matches the date for each web event
			if ($scope.reg_event_to_add.hasOwnProperty('web_events') && $scope.reg_event_to_add.web_events.length > 0){
				for (let web_event of $scope.reg_event_to_add.web_events){
					// only check existing web events
					if (web_event.hasOwnProperty('uuid') && web_event.uuid !== null) {
						// if dates don't match, then put web event in web_events_update (unless it is already in there)
						let encoder_schedule_date = moment($scope.reg_event_to_add.date, $scope.DATE_FORMAT);
						let web_event_date = moment(web_event.gmtTime);
						if (!encoder_schedule_date.isSame(web_event_date, 'day')){
							let find_index = $scope.findEntry($scope.reg_event_to_add.web_events_update, web_event);
							if (find_index == -1){
								$scope.reg_event_to_add.web_events_update.push(web_event);
							}
						}
					}
				}
			}

			// send ajax request to update the existing event
			$http.patch(`${jcs.api.url}/schedules/${$scope.reg_event_to_add.uuid}`, event_data, { withCredentials: true }).then(() => {

				var web_events_to_process_count = $scope.reg_event_to_add.web_events_add.length + $scope.reg_event_to_add.web_events_update.length + $scope.reg_event_to_add.web_events_delete.length;

				if (web_events_to_process_count > 0) {
					// create our promises
					var promises = [];
					// adds
					$scope.reg_event_to_add.web_events_add.forEach(function (web_event) {
						var web_event_data = $scope.getApiDataForEncoderScheduleWebEvent($scope.reg_event_to_add, web_event);
						var url = jcs.api.url_v3 + '/customers/' + Authentication.getCurrentUser().customerID + $scope.API_PATH_WEB_EVENT_SCHEDULE;
						promises.push(
							$http.post(url, web_event_data, { withCredentials: true }).then(function (result) {
								var indexToRemove = $scope.reg_event_to_add.web_events_add.indexOf(web_event);
								// since we succeeded, remove entry from list
								if (indexToRemove != -1) {
									$scope.reg_event_to_add.web_events_add.splice(indexToRemove, 1);
								}

								// since these items were successfully created, we want to update the uuid of their matching counterpart
								// in the reg_event_to_add.web_events list (that way if the user changes them, they will update properly)

								// convert web event to UI format
								var web_event_in_ui_format = $scope.convertApiToUiFormat(web_event_data);
								// find our match in the reg_event_to_add.web_events and upate the uuid
								var match = $scope.getMatchingWebEventByStartTime($scope.reg_event_to_add.web_events, web_event_in_ui_format);
								if (match != null) {
									match.uuid = result.data.uuid;
								}
							})
						);
					});
					// updates
					$scope.reg_event_to_add.web_events_update.forEach(function (web_event) {
						var web_event_data = $scope.getApiDataForEncoderScheduleWebEvent($scope.reg_event_to_add, web_event);
						var url = jcs.api.url_v3 + '/customers/' + Authentication.getCurrentUser().customerID + $scope.API_PATH_WEB_EVENT_SCHEDULE + web_event.uuid;
						promises.push(
							$http.patch(url, web_event_data, { withCredentials: true }).then(function (result) {
								var indexToRemove = $scope.reg_event_to_add.web_events_update.indexOf(web_event);
								if (indexToRemove != -1) {
									$scope.reg_event_to_add.web_events_update.splice(indexToRemove, 1);
								}
							})
						);
					});
					// deletes
					$scope.reg_event_to_add.web_events_delete.forEach(function (web_event) {
						var url = jcs.api.url_v3 + '/customers/' + Authentication.getCurrentUser().customerID + $scope.API_PATH_WEB_EVENT_SCHEDULE + web_event.uuid;
						promises.push(
							$http.delete(url, { withCredentials: true }).then(function (result) {
								var indexToRemove = $scope.reg_event_to_add.web_events_delete.indexOf(web_event);
								if (indexToRemove != -1) {
									$scope.reg_event_to_add.web_events_delete.splice(indexToRemove, 1);
								}
								// since we are deleting, remove from web events for event we are viewing -- otherwise if they press cancel it will look
								// like those items are still there.
								if ($scope.event_to_view != null) {
									var item_to_remove = $scope.getEventByIDFromList($scope.event_to_view.web_events, web_event.uuid);
									if (item_to_remove) {
										var index_to_remove = $scope.event_to_view.web_events.indexOf(item_to_remove);
										if (index_to_remove != -1) {
											$scope.event_to_view.web_events.splice(index_to_remove, 1);
										}
									}
								}
							})
						);
					});

					$q.allSettled(promises)
						.then(
							function (response) {
								$scope.show_update_event = false;
								$scope.removeWarnOnPageChange();
								$scope.add_update_event_error = null;
								$scope.event_to_view = null;

								// TODO: this fadeOut call is a hack; find a better way to do this (I need a way for restoreLocation to call a given function,
								// but then to wait until it is done before continuing; if I pass a function that calls loadInitialCalendar, then it returns
								// immediately before it is done and therefore begins the fadein to early, so you don't end up seeing the fadein at all.)
								$('.calendar-wrapper').fadeOut(0);
								$scope.loadInitialCalendar(function () {
									$scope.restoreScrollLocation();
								});
							},
							function (reasons) {
								// ensure reason is an array
								if (!angular.isArray(reasons)) {
									reasons = [reasons];
								}

								// process each of the errors
								reasons.forEach(function (reason) {
									// if we have multiple API calls, and some get an error, we appear to get back a list for all calls. Ones that succeeded
									// return reason as undefined. So we can ignore those.
									if (typeof reason !== 'undefined') {
										if (!httpService.isStatus406(reason)) {
											if (reason.status == 409) {
												var this_web_event_name = reason.config.data.name;
												var that_web_event_name = reason.data.conflictName;
												var web_evt_profile_name = $scope.getWebEventProfileNameForID(
													reason.config.data.webEventProfileId
												);
												$scope.add_update_event_errors.push(
													'Web event profiles cannot be used by multiple web or sim-live events at the same time. The web event "' +
													this_web_event_name +
													'" ' +
													'is using web event profile "' +
													web_evt_profile_name +
													'", but that profile is already in use at that time by web event "' +
													that_web_event_name +
													'" ' +
													'(which belongs to a different encoder schedule, or is a sim-live schedule). ' +
													'Please either change the times of the events to not overlap, or use a different web event profile.'
												);
											} else {

												var message = '';

												if (reason.config.method == 'POST') {
													message = 'Unable to create web event "' + reason.config.data.name + '".';
												} else if (reason.config.method == 'PATCH') {
													message = 'Unable to save changes for web event "' + reason.config.data.name + '".';
												} else if (reason.config.method == 'DELETE') {
													message = 'Unable to delete web event "' + $scope.convertWebEventDelUrlToName(reason.config.url) + '".';
												} else {
													message = 'Unable to process web event request.';
													console.log('Unable to determine error type for:');
													console.log(reason);
												}

												message += ' Please try submitting your changes again, or report the problem if it persists.';

												$scope.add_update_event_errors.push({
													message: message,
													reason: reason
												});
											}
										}
									}
								});
							}
						)
						.finally(function () {
							$scope.is_busy_saving_event = false;
						});
				} else {
					$scope.show_update_event = false;
					$scope.removeWarnOnPageChange();
					$scope.add_update_event_error = null;
					$scope.add_update_event_errors = [];
					$scope.event_to_view = null;

					$scope.is_busy_saving_event = false;

					// TODO: this fadeOut call is a hack; find a better way to do this (I need a way for restoreLocation to call a given function,
					// but then to wait until it is done before continuing; if I pass a function that calls loadInitialCalendar, then it returns
					// immediately before it is done and therefore begins the fadein to early, so you don't end up seeing the fadein at all.)
					$('.calendar-wrapper').fadeOut(0);
					$scope.loadInitialCalendar(function () {
						$scope.restoreScrollLocation();
					});
				}

				// send mixpanel data for encoder event
				trackMixpanelEvent(MPEventName.EVENT_SCHEDULE_EDIT, $scope.getMixpanelDataForEncoderSchedule($scope.reg_event_to_add, event_data));
				// send mixpanel data for each web event
				$scope.reg_event_to_add.web_events?.forEach(web_event => {
					trackMixpanelEvent(MPEventName.WEB_EVENT_SCHEDULE_EDIT, $scope.getMixpanelDataForEncoderScheduleWebEvent($scope.reg_event_to_add, event_data, web_event));
					// send mixpanel data for each social destination
					web_event.simulcasts?.forEach(simulcast => {
						trackMixpanelEvent(MPEventName.SOCIAL_DESTINATION_SCHEDULE_EDIT, $scope.getMixpanelDataForEncoderScheduleSimulcast($scope.reg_event_to_add, event_data, web_event, simulcast));
					});
				});

			}).catch(reason => {

				$scope.add_update_event_error = 'An error occurred while attempting to update the event. Please try again, or report the problem if it persists.';
				$scope.is_busy_saving_event = false;

			});

		} else {
			// send ajax request to create new event
			$http
				.post(jcs.api.url + '/schedules', event_data, { withCredentials: true })
				.then(
					function (results) {
						// success

						// assign the granted uuid to our event; this will do two things:
						// 1) allow api data to be created correctly (web event will have proper parent uuid assigned)
						// 2) if errors are encountered, then we want the user to be able to save changes again without creating a new encoder event
						$scope.reg_event_to_add.uuid = results.data.uuid;

						if ($scope.reg_event_to_add.web_events.length > 0) {
							// load our API requests as promises (to be executed at once)
							var promises = [];
							$scope.reg_event_to_add.web_events.forEach(function (web_event) {
								var web_event_data = $scope.getApiDataForEncoderScheduleWebEvent($scope.reg_event_to_add, web_event);
								var url = jcs.api.url_v3 + '/customers/' + Authentication.getCurrentUser().customerID + $scope.API_PATH_WEB_EVENT_SCHEDULE;
								promises.push(
									$http.post(url, web_event_data, { withCredentials: true }).then(function (result) {
										// since these items were successfully created, we want to update the uuid of their matching counterpart
										// in the reg_event_to_add.web_events list (that way if the user changes them, they will update properly)

										// convert web event to UI format
										var web_event_in_ui_format = $scope.convertApiToUiFormat(web_event_data);
										// find our match in the reg_event_to_add.web_events and upate the uuid
										var match = $scope.getMatchingWebEventByStartTime(
											$scope.reg_event_to_add.web_events,
											web_event_in_ui_format
										);
										if (match != null) {
											match.uuid = result.data.uuid;
										}
									})
								);
							});

							$q.allSettled(promises)
								.then(
									function (response) {
										$scope.show_add_event = false;
										$scope.removeWarnOnPageChange();
										$scope.add_update_event_error = null;

										// TODO: this fadeOut call is a hack; find a better way to do this (I need a way for restoreLocation to call a given function,
										// but then to wait until it is done before continuing; if I pass a function that calls loadInitialCalendar, then it returns
										// immediately before it is done and therefore begins the fadein to early, so you don't end up seeing the fadein at all.)
										$('.calendar-wrapper').fadeOut(0);
										$scope.loadInitialCalendar(function () {
											$scope.restoreScrollLocation();
										});
									},
									function (reasons) {
										// ensure reason is an array
										if (!angular.isArray(reasons)) {
											reasons = [reasons];
										}

										// since our encoder event saved successfully, but one of our web events failed, we'll need to switch from "show_add_event" to "show_update_event"
										$scope.show_add_event = false;
										$scope.show_update_event = true;
										$scope.addWarnOnPageChange();

										// process each of the errors
										reasons.forEach(function (reason) {
											// if we have multiple API calls, and some get an error, we appear to get back a list for all calls. Ones that succeeded
											// return reason as undefined. So we can ignore those.
											if (typeof reason !== 'undefined') {
												// add this item to our "web_events_add" list, so if the user saves again, we'll retry this item
												var match = $scope.getMatchingWebEventByStartTime(
													$scope.reg_event_to_add.web_events,
													$scope.convertApiToUiFormat(reason.config.data)
												);
												$scope.reg_event_to_add.web_events_add.push($scope.getCopy(match));

												if (!httpService.isStatus406(reason)) {
													if (reason.status == 409) {
														var this_web_event_name = reason.config.data.name;
														var that_web_event_name = reason.data.conflictName;
														var web_evt_profile_name = $scope.getWebEventProfileNameForID(
															reason.config.data.webEventProfileId
														);
														$scope.add_update_event_errors.push(
															'Web event profiles cannot be used by multiple web or sim-live events at the same time. The web event "' +
															this_web_event_name +
															'" ' +
															'is using web event profile "' +
															web_evt_profile_name +
															'", but that profile is already in use at that time by web event "' +
															that_web_event_name +
															'" ' +
															'(which belongs to a different encoder schedule, or is a sim-live schedule). ' +
															'Please either change the times of the events to not overlap, or use a different web event profile.'
														);
													} else {

														$scope.add_update_event_errors.push({
															message: 'Unable to create web event "' + reason.config.data.name + '". Please try submitting your changes again, or report the problem if it persists.',
															reason: reason
														});

													}
												}
											}
										});
									}
								)
								.finally(function () {
									$scope.is_busy_saving_event = false;
								});
						} else {
							$scope.show_add_event = false;
							$scope.removeWarnOnPageChange();
							$scope.add_update_event_error = null;
							$scope.add_update_event_errors = [];

							$scope.is_busy_saving_event = false;

							// TODO: this fadeOut call is a hack; find a better way to do this (I need a way for restoreLocation to call a given function,
							// but then to wait until it is done before continuing; if I pass a function that calls loadInitialCalendar, then it returns
							// immediately before it is done and therefore begins the fadein to early, so you don't end up seeing the fadein at all.)
							$('.calendar-wrapper').fadeOut(0);
							$scope.loadInitialCalendar(function () {
								$scope.restoreScrollLocation();
							});
						}

						// send mixpanel data for encoder event
						trackMixpanelEvent(MPEventName.EVENT_SCHEDULE_ADD, $scope.getMixpanelDataForEncoderSchedule($scope.reg_event_to_add, event_data));
						// send mixpanel data for each web event
						$scope.reg_event_to_add.web_events?.forEach(web_event => {
							trackMixpanelEvent(MPEventName.WEB_EVENT_SCHEDULE_ADD, $scope.getMixpanelDataForEncoderScheduleWebEvent($scope.reg_event_to_add, event_data, web_event));
							// send mixpanel data for each social destination
							web_event.simulcasts?.forEach(simulcast => {
								trackMixpanelEvent(MPEventName.SOCIAL_DESTINATION_SCHEDULE_ADD, $scope.getMixpanelDataForEncoderScheduleSimulcast($scope.reg_event_to_add, event_data, web_event, simulcast));
							});
						});

					},
					function () { // error

						$scope.add_update_event_error = 'An error occurred while attempting to create the encoder schedule. Please try again, or report the problem if it persists.';
						$scope.is_busy_saving_event = false;

					})['finally'](function () { // always called
//                  	$scope.is_busy_saving_event = false;
					});
		}
	};

	$scope.addOrUpdateSimLiveEvent = function () {
		// if we have form validation errors, then don't go any further
		if ($scope.doesAddOrUpdateSimLiveEventFormFailValidation()) return false;

		var startDateTime = moment($scope.web_event_to_add.date + ' ' + $scope.web_event_to_add.time_hours + ':' +$scope.web_event_to_add.time_minutes + ' ' + $scope.web_event_to_add.time_meridiem, $scope.DATE_TIME_MERIDIEM_FORMAT);

		// ensure special chars don't blow up our json; see for more options:
		// http://stackoverflow.com/questions/4253367/how-to-escape-a-json-string-containing-newline-characters-using-javascript
		var escaped_name = $scope.web_event_to_add.name.replace(/\\"/g, '\\"');

		var event_data = {
			name: escaped_name,
			enabled: $scope.web_event_to_add.enabled == 'Enabled',
			eventUsesScheduleName: $scope.web_event_to_add.name_event_using == $scope.NAME_EVT_USING_SCHEDULE_DESC,
			scheduleStartDay: startDateTime.format($scope.DATE_FORMAT),
			localStartTime: startDateTime.format('HH:mm'),
			timeZone: $scope.web_event_to_add.time_zone,
			frequency: $scope.web_event_to_add.frequency_days,
			webEventProfileId: $scope.web_event_to_add.transcoder_event_profile,
			wholeEvent: true,
		};

		// if this is not a recurring event, then set the end day to same date as start day
		if ($scope.web_event_to_add.frequency_yesno == $scope.NO) {
			event_data.scheduleEndDay = event_data.scheduleStartDay;
		} else {
			// we have a recurring event, so see if the user specified an end date
			event_data.scheduleEndDay = $scope.web_event_to_add.end_schedule_yesno == $scope.YES ? $scope.web_event_to_add.end_date : $scope.NEVER_END_DATE;
		}

		// save simulcast data
		if ($scope.web_event_to_add.hasOwnProperty('simulcasts') && $scope.web_event_to_add.simulcasts != null && $scope.web_event_to_add.simulcasts.length > 0) {
			event_data.simulcasts = [];
			for (var i = 0; i < $scope.web_event_to_add.simulcasts.length; i++) {
				var entry = $scope.web_event_to_add.simulcasts[i];
				var simulcast_data = {
					type: entry.type,
					channelId: entry.channelId,
					channelName: entry.channelName,
					destinationId: entry.destinationId,
					destinationName: entry.destinationName,
					privacy: entry.privacy,
					publishStatus: entry.publishStatus,
					crossposts: entry.crossposts,
					title: entry.title,
					description: entry.description,
					imageUrl: entry.imageUrl,
				};
				if (entry.hasOwnProperty('uuid')){
					simulcast_data.uuid = entry.uuid;
				}
				event_data.simulcasts.push(simulcast_data);
			}
		} else {
			event_data.simulcasts = null;
		}

		$scope.is_busy_saving_event = true;

		if ($scope.web_event_to_add.uuid != null) {
			// send ajax request to update the existing event
			httpService.patch(jcs.api.url_v3 + '/customers/' + Authentication.getCurrentUser().customerID + $scope.API_PATH_WEB_EVENT_SCHEDULE + $scope.web_event_to_add.uuid, event_data, { withCredentials: true },
				function (data) { // success

					$scope.show_update_event = false;
					$scope.removeWarnOnPageChange();
					$scope.add_update_event_error = null;
					$scope.event_to_view = null;

					// TODO: this fadeOut call is a hack; find a better way to do this (I need a way for restoreLocation to call a given function,
					// but then to wait until it is done before continuing; if I pass a function that calls loadInitialCalendar, then it returns
					// immediately before it is done and therefore begins the fadein to early, so you don't end up seeing the fadein at all.)
					$('.calendar-wrapper').fadeOut(0);
					$scope.loadInitialCalendar(function () {
						$scope.restoreScrollLocation();
					});

					// send mixpanel data for sim-live event
					trackMixpanelEvent(MPEventName.EVENT_SCHEDULE_EDIT, {
						[MPEventProperty.SCHEDULE_TYPE]: 'sim-live',
						[MPEventProperty.SCHEDULE_UUID]: $scope.web_event_to_add.uuid,
						[MPEventProperty.SCHEDULE_NAME]: event_data.name,
						[MPEventProperty.EVENT_STATUS]: event_data.enabled ? 'enabled' : 'disabled',
						[MPEventProperty.EVENT_FREQUENCY]: $scope.web_event_to_add.frequency_yesno === $scope.NO ? '0' : $scope.web_event_to_add.frequency_days,
						[MPEventProperty.WEB_EVENT_PROFILE_UUID]: event_data.webEventProfileId,
						[MPEventProperty.WEB_EVENT_PROFILE_NAME]: $scope.transcoder_event_profile_list?.find(item => item.uuid === event_data.webEventProfileId)?.name,
						[MPEventProperty.SOCIAL_DESTINATION_COUNT]: event_data.simulcasts?.length,
					});
					// send mixpanel data for each social destination
					event_data.simulcasts?.forEach(simulcast => {
						trackMixpanelEvent(MPEventName.SOCIAL_DESTINATION_SCHEDULE_EDIT, {
							[MPEventProperty.SCHEDULE_UUID]: $scope.web_event_to_add.uuid,
							[MPEventProperty.SCHEDULE_NAME]: event_data.name,
							[MPEventProperty.CHANNEL_NAME]: simulcast.channelName,
							[MPEventProperty.SOCIAL_DESTINATION]: simulcast.destinationName,
							[MPEventProperty.PUBLISH_STATUS]: simulcast.publishStatus,
							[MPEventProperty.PRIVACY]: simulcast.privacy,
							[MPEventProperty.TYPE]: simulcast.type,
							[MPEventProperty.SOCIAL_CROSSPOST_COUNT]: simulcast.crossposts?.length,
						});
					});
				},
				function (reason) { // error
					if (!httpService.isStatus406(reason)) {
						if (reason.status == 409) {
							var that_web_event_name = reason.data.conflictName;
							var web_evt_profile_name = $scope.getWebEventProfileNameForID(reason.config.data.webEventProfileId);
							$scope.add_update_event_error =
								'Web event profiles cannot be used by multiple web or sim-live events at the same time. This sim-live schedule ' +
								'is using web event profile "' +
								web_evt_profile_name +
								'", but that profile is already in use at the same time by web event "' +
								that_web_event_name +
								'" ' +
								'(which belongs to an encoder schedule, or is a sim-live schedule). ' +
								'Please either change the times of the events to not overlap, or use a different web event profile.';
						} else {
							$scope.add_update_event_error = {
								message: 'An error occurred while attempting to update the schedule. Please try again, or report the problem if it persists.',
								reason: reason
							};
						}
					}
				},
				function () { // always called
					$scope.is_busy_saving_event = false;
				}
			);
		} else {
			// send ajax request to create new event
			httpService.post(jcs.api.url_v3 + '/customers/' + Authentication.getCurrentUser().customerID + $scope.API_PATH_WEB_EVENT_SCHEDULE, event_data, { withCredentials: true },
				function (result) { // success

					$scope.show_add_event = false;
					$scope.removeWarnOnPageChange();
					$scope.add_update_event_error = null;

					// TODO: this fadeOut call is a hack; find a better way to do this (I need a way for restoreLocation to call a given function,
					// but then to wait until it is done before continuing; if I pass a function that calls loadInitialCalendar, then it returns
					// immediately before it is done and therefore begins the fadein to early, so you don't end up seeing the fadein at all.)
					$('.calendar-wrapper').fadeOut(0);
					$scope.loadInitialCalendar(function () {
						$scope.restoreScrollLocation();
					});

					// send mixpanel data for sim-live event
					trackMixpanelEvent(MPEventName.EVENT_SCHEDULE_ADD, {
						[MPEventProperty.SCHEDULE_TYPE]: 'sim-live',
						[MPEventProperty.SCHEDULE_UUID]: result.data.uuid,
						[MPEventProperty.SCHEDULE_NAME]: event_data.name,
						[MPEventProperty.EVENT_STATUS]: event_data.enabled ? 'enabled' : 'disabled',
						[MPEventProperty.EVENT_FREQUENCY]: $scope.web_event_to_add.frequency_yesno === $scope.NO ? '0' : $scope.web_event_to_add.frequency_days,
						[MPEventProperty.WEB_EVENT_PROFILE_UUID]: event_data.webEventProfileId,
						[MPEventProperty.WEB_EVENT_PROFILE_NAME]: $scope.transcoder_event_profile_list?.find(item => item.uuid === event_data.webEventProfileId)?.name,
						[MPEventProperty.SOCIAL_DESTINATION_COUNT]: event_data.simulcasts?.length,
					});
					// send mixpanel data for each social destination
					event_data.simulcasts?.forEach(simulcast => {
						trackMixpanelEvent(MPEventName.SOCIAL_DESTINATION_SCHEDULE_ADD, {
							[MPEventProperty.SCHEDULE_UUID]: result.data.uuid,
							[MPEventProperty.SCHEDULE_NAME]: event_data.name,
							[MPEventProperty.CHANNEL_NAME]: simulcast.channelName,
							[MPEventProperty.SOCIAL_DESTINATION]: simulcast.destinationName,
							[MPEventProperty.PUBLISH_STATUS]: simulcast.publishStatus,
							[MPEventProperty.PRIVACY]: simulcast.privacy,
							[MPEventProperty.TYPE]: simulcast.type,
							[MPEventProperty.SOCIAL_CROSSPOST_COUNT]: simulcast.crossposts?.length,
						});
					});

				},
				function (reason) { // error
					if (!httpService.isStatus406(reason)) {
						if (reason.status == 409) {
							var that_web_event_name = reason.data.conflictName;
							var web_evt_profile_name = $scope.getWebEventProfileNameForID(reason.config.data.webEventProfileId);
							$scope.add_update_event_error =
								'Web event profiles cannot be used by multiple web or sim-live events at the same time. This sim-live schedule ' +
								'is using web event profile "' +
								web_evt_profile_name +
								'", but that profile is already in use at the same time by web event "' +
								that_web_event_name +
								'" ' +
								'(which belongs to an encoder schedule, or is a sim-live schedule). ' +
								'Please either change the times of the events to not overlap, or use a different web event profile.';
						} else {
							$scope.add_update_event_error = {
								message: 'An error occurred while attempting to create the schedule. Please try again, or report the problem if it persists.',
								reason: reason
							};
						}
					}
				},
				function () { // always called

					$scope.is_busy_saving_event = false;
				}
			);
		}
	};

	$scope.cancelAddEvent = function () {
		$scope.cancelAddOrUpdateEncoderScheduleWebEvent();

		$scope.restoreScrollLocation(function () {
			$scope.show_add_event = false;
			$scope.removeWarnOnPageChange();
			$scope.reg_event_to_add = null;
			$scope.web_event_to_add = null;
			$scope.add_update_event_error = null;
			$scope.add_update_event_errors = [];
			$scope.add_event_form_validation_error = null;
		});
	};

	$scope.replaceCrossposts = function (response_data, event){

		// store off the crossposts for each of the simulcasts, so we can easily access them later
		const crosspost_cache = {};
		if (response_data.hasOwnProperty('simulcasts') && response_data.simulcasts !== null && response_data.simulcasts.length > 0){
			for (const simulcast of response_data.simulcasts){
				crosspost_cache[simulcast.uuid] = simulcast.crossposts;
			}
		}

		// iterate our web event and find any simulcasts that need their crossposts replaced; note: we could just replace the entire
		// web event, but it turns out the fields returned when we get a list of web events is different from the fields that are returned
		// when you fetch a particular web event. So we'll just swap out the crossposts info.
		if (event.hasOwnProperty('simulcasts') && event.simulcasts !== null && event.simulcasts.length > 0){
			for (const simulcast of event.simulcasts){
				if (simulcast.type == $scope.DESTINATION_TYPE_FB_PAGE && crosspost_cache.hasOwnProperty(simulcast.uuid)){
					simulcast.crossposts = crosspost_cache[simulcast.uuid];
				}
			}
		}
	};

	$scope.viewEvent = function (event) {
		$scope.saveScrollLocation();
		$scope.event_to_view = event;
		$scope.view_event_error = null;

		// init our timezone list so we can show more friendly time zone alias
		$scope.initializeTimeZoneList();
		$scope.initializeWebEncoderProfileList();

		//  if we have a transcoded event, we'll need to fetch more information
		if ($scope.event_to_view.eventType == $scope.SCHEDULE_TYPE_SIM_LIVE) {

			if ($scope.event_to_view.scheduleId != null) {

				$scope.is_loading_view_event = true;

				let promises = [];
				promises.push($http.get(`${jcs.api.url}/schedules/${$scope.event_to_view.scheduleId}`, { withCredentials: true }));

				// do we have any simulcasts that may include crossposts?
				if ($scope.hasSimulcastOfType($scope.event_to_view, $scope.DESTINATION_TYPE_FB_PAGE)){
					promises.push($http.get(`${jcs.api.url_v3}/customers/${Authentication.getCurrentUser().customerID}/webeventschedules/${$scope.event_to_view.webEventScheduleId}`, { withCredentials: true, }));
				};

				$q.all(promises).then((responses) => {

					$scope.event_to_view.encoderName = responses[0].data.encoderName;
					$scope.event_to_view.encoderProfileName = responses[0].data.encoderProfileName;
					$scope.event_to_view.streamProfileName = responses[0].data.streamProfileName;

					if (responses.length > 1){
						// replace crossposts because they aren't returned when fetching list of web schedules
						$scope.replaceCrossposts(responses[1].data, $scope.event_to_view);
					}

				}).catch(() => {

					$scope.view_event_error = 'An error occurred while retrieving some of the profile information. Please try again, or report the problem if it persists.';

				}).finally(() => {

					$scope.is_loading_view_event = false;
				});

			} else if ($scope.event_to_view.streamProfileId != null) {

				$scope.is_loading_view_event = true;

				let promises = [];
				promises.push($http.get(`${jcs.api.url}/streamprofiles/${$scope.event_to_view.streamProfileId}`, { withCredentials: true }));

				// do we have any simulcasts that may include crossposts?
				if ($scope.hasSimulcastOfType($scope.event_to_view, $scope.DESTINATION_TYPE_FB_PAGE)){
					promises.push($http.get(`${jcs.api.url_v3}/customers/${Authentication.getCurrentUser().customerID}/webeventschedules/${$scope.event_to_view.webEventScheduleId}`, { withCredentials: true, }));
				};

				$q.all(promises).then((responses) => {

					$scope.event_to_view.streamProfileName = responses[0].data.name;

					if (responses.length > 1){
						// replace crossposts because they aren't returned when fetching list of web schedules
						$scope.replaceCrossposts(responses[1].data, $scope.event_to_view);
					}

				}).catch(() => {

					$scope.view_event_error = 'An error occurred while retrieving some of the profile information. Please try again, or report the problem if it persists.';

				}).finally(() => {

					$scope.is_loading_view_event = false;
				});

			} else if ($scope.event_to_view.transcodedEventProfileId != null) {

				$scope.is_loading_view_event = true;

				let promises = [];
				promises.push($http.get(`${jcs.api.url_v3}/customers/${Authentication.getCurrentUser().customerID}/transcodedeventprofiles/${$scope.event_to_view.transcodedEventProfileId}`, { withCredentials: true }));

				// do we have any simulcasts that may include crossposts?
				if ($scope.hasSimulcastOfType($scope.event_to_view, $scope.DESTINATION_TYPE_FB_PAGE)){
					promises.push($http.get(`${jcs.api.url_v3}/customers/${Authentication.getCurrentUser().customerID}/webeventschedules/${$scope.event_to_view.webEventScheduleId}`, { withCredentials: true, }));
				};

				$q.all(promises).then((responses) => {

					$scope.event_to_view.transcodedEventProfileName = responses[0].data.name;

					if (responses.length > 1){
						// replace crossposts because they aren't returned when fetching list of web schedules
						$scope.replaceCrossposts(responses[1].data, $scope.event_to_view);
					}

				}).catch(() => {

					$scope.view_event_error = 'An error occurred while retrieving some of the profile information. Please try again, or report the problem if it persists.';

				}).finally(() => {

					$scope.is_loading_view_event = false;
				});

			}
		} else {

			$scope.is_loading_view_event = true;

			const promises = [];
			$scope.initializeEncoderList(promises);

			// does this encoder event contain web events that have simulcasts that could have crossposts? If so, then we'll need to load those web events,
			// because the schedule list we get back will not contain crosspost information.
			if ($scope.event_to_view.hasOwnProperty('web_events') && $scope.event_to_view.web_events !== null && $scope.event_to_view.web_events.length > 0){

				let web_events_to_load = [];
				for (let web_event of $scope.event_to_view.web_events){
					// check our web events for simulcasts of type "fb_page". If we have any, then we'll need to fetch that web event specifically to see
					// if there is any crosspost info. Crosspost info isn't returned when fetching list of web events.
					if ($scope.hasSimulcastOfType(web_event, $scope.DESTINATION_TYPE_FB_PAGE)){
						web_events_to_load.push(web_event);
					}
				}

				if (web_events_to_load.length > 0){

					for (const web_event of web_events_to_load){
						promises.push($http.get(`${jcs.api.url_v3}/customers/${Authentication.getCurrentUser().customerID}/webeventschedules/${web_event.uuid}`, { withCredentials: true, }).then(response => {

							$scope.replaceCrossposts(response.data, web_event);
							return response;

						}));
					}
				}
			}

			$q.all(promises).then(responses => {

				// determine if encoder is software encoder
				const encoder_info = $scope.getEncoderInfo($scope.event_to_view.encoderId, $scope.encoder_list);
				$scope.event_to_view.isSoftwareEncoder = encoderService.isSoftwareEncoder(encoder_info);

			}).catch(() => {

				$scope.view_event_error = 'An error occurred while attempting to load the event. Please try again, or report the problem if it persists.';

			}).finally(() => {

				$scope.is_loading_view_event = false;

			});

		}
	};

	$scope.cancelViewEvent = function () {
		$scope.restoreScrollLocation(function () {
			$scope.event_to_view = null;
			$scope.view_event_error = null;
			$scope.load_time_zone_list_error = null;
			$scope.load_web_events_list_error = null;
			$scope.add_update_event_error = null;
		});
	};

	$scope.formatEventDate = function (date_to_format) {
		return moment(date_to_format).format('ll');
	};

	// order events by start time; if time is same, put regular events before transcoded events
	$scope.sortEvents = function (a, b) {
		if (a.localStartTime < b.localStartTime) return -1;
		if (a.localStartTime > b.localStartTime) return 1;
		if (a.eventType != b.eventType) {
			if (a.eventType == $scope.SCHEDULE_TYPE_ENCODER) return -1;
			if (a.eventType == $scope.SCHEDULE_TYPE_SIM_LIVE) return 1;
		}
		return 0;
	};

	$scope.getFormattedOption = function (option){
		if (option == null || option == ''){
			return '';
		}
		if (option == 'all_friends'){
			return 'Friends';
		}
		if (option == 'friends_of_friends'){
			return 'Friends Of Friends';
		}
		if (option == $scope.social_media.SCHEDULED_POST){
			return 'Scheduled Post';
		}
		// capitalize first letter
		return option.charAt(0).toUpperCase() + option.slice(1);
	};

	$scope.getSocialMediaAcctById = function (account_id) {
		if ($scope.social_media_acct_list != null) {
			for (var i = 0; i < $scope.social_media_acct_list.length; i++) {
				var acct = $scope.social_media_acct_list[i];
				if (acct.uuid == account_id) {
					return acct;
				}
			}
		}
		return null;
	};

	$scope.haveAccessToSocialMediaAccount = function (info) {
		var acct = $scope.getSocialMediaAcctById(info.channelId);
		if (acct != null) {
			return acct.status == $scope.social_media.STATUS_OK;
		}
		return true; // lets only show errors if we know for a fact we have one
	};

	// only returns true if web event has 1 or more social media destinations that are unable to live stream
	// if the web event does not have any social media destinations, then return false (it has no problems)
	$scope.hasProblemStreamingToSocialMedia = function (web_event) {
		if (web_event.simulcasts != null && web_event.simulcasts.length > 0) {
			for (var i = 0; i < web_event.simulcasts.length; i++) {
				if (!$scope.haveAccessToSocialMediaAccount(web_event.simulcasts[i])) {
					return true;
				}
			}
		}
		return false;
	};

	$scope.isWebEventStreamingToYouTube = function (web_event) {
		if (web_event.hasOwnProperty('simulcasts') && web_event.simulcasts != null && web_event.simulcasts.length > 0) {
			for (var i = 0; i < web_event.simulcasts.length; i++) {
				if ($scope.social_media.isYouTubeType(web_event.simulcasts[i].type)) {
					return true;
				}
			}
		}
		return false;
	};

	$scope.isWebEventStreamingToFacebook = function (web_event) {
		if (web_event.hasOwnProperty('simulcasts') && web_event.simulcasts != null && web_event.simulcasts.length > 0) {
			for (var i = 0; i < web_event.simulcasts.length; i++) {
				if ($scope.social_media.isFacebookType(web_event.simulcasts[i].type)) {
					return true;
				}
			}
		}
		return false;
	};

	$scope.showMoreInfoDialog = function (schedule) {
		const acct = $scope.getSocialMediaAcctById(schedule.channelId);

		$scope.more_info = {
			title: 'Unable to Stream',
			code: acct.status,
			type: acct.type,
		};

		// update title in some situations
		switch ($scope.more_info.code) {
			case 'TOKEN_INVALID':
				$scope.more_info.title = `Unable to Access ${$scope.social_media.getTypeAsLabel($scope.more_info.type)} Account`;
				break;
			case 'NOT_ENABLED':
				if ($scope.more_info.type == $scope.social_media.TYPE_YOUTUBE) {
					$scope.more_info.title = 'Not Allowed to Live Stream';
				} else if ($scope.more_info.type == $scope.social_media.TYPE_FACEBOOK) {
					$scope.more_info.title = 'Not Allowed to Publish Video';
				}
				break;
			case 'ENABLED_BUT_NOT_ACTIVE':
				$scope.more_info.title = 'Live Streaming Not Yet Approved';
				break;
		}

		$timeout(function () {
			$('#simulcast-error-more-info').modal('show');
		});
	};

	$scope.getSocialMediaStatusDesc = function (status) {
		switch (status) {
			case $scope.social_media.STATUS_TOKEN_INVALID:
				return 'No Access';
			case $scope.social_media.STATUS_NOT_ENABLED:
				return 'Not allowed to Live Stream';
			case $scope.social_media.STATUS_ENABLED_BUT_NOT_ACTIVE:
				return 'Channel Update Required'; // or 'Live Stream Approval Pending' or 'Possible Channel Update Required'
			default:
				return 'Unknown Status';
		}
	};

	// this is a callback we use with the socialMedia service (as it determines account status, it calls this method)
	$scope.updateAcctStatus = function (account_id, info) {
		for (var i = 0; $scope.social_media_acct_list.length; i++) {
			if ($scope.social_media_acct_list[i].uuid == account_id) {
				// update status
				$scope.social_media_acct_list[i].status = info.status;
				// update formatted name (which is used in dropdown) if account has a problem
				if (info.status != $scope.social_media.STATUS_OK){
					$scope.social_media_acct_list[i].formatted_name += ` (${$scope.getSocialMediaStatusDesc($scope.social_media_acct_list[i].status)})`;
				}
				return;
			}
		}
	};

	$scope.updateSocialMediaAccountsStatus = function () {
		// determine the status of each social media account. We don't want this to prevent displaying the calendar though, so
		// it will still show immediately after the events are loaded. The web events that have warnings will then have their icons
		// updated automatically after the social media status check completes. Even on slow connections this shouldn't take long.
		if ($scope.social_media_acct_list != null && $scope.social_media_acct_list.length > 0) {
			var acct_id_list = [];

			// add a "status" field for each account and queue up an API call to check the actual status; status will default to "OK"
			// until we know otherwise. We only want to warn the user about issues if we know for sure there is one.
			for (var i = 0; i < $scope.social_media_acct_list.length; i++) {
				var acct = $scope.social_media_acct_list[i];
				acct.status = $scope.social_media.STATUS_OK;
				acct.formatted_name = '[' + $scope.social_media.getTypeAsLabel(acct.type) + '] ' + acct.name;
				acct_id_list.push({
					id: acct.uuid,
					type: acct.type,
				});
			}

			$scope.social_media.checkAccountStatus(acct_id_list, Authentication.getCurrentUser().customerID, $scope.updateAcctStatus);
		}
	};

	// iterate thru schedules and either add to encoder parent as web event, or will add to upcoming_web_events as a sim-live event
	$scope.processTranscodedSchedules = function (schedules) {

		for (var i = 0; i < schedules.length; i++) {

			var web_event = schedules[i];
			web_event.eventType = $scope.SCHEDULE_TYPE_SIM_LIVE;

			var parent = $scope.findParentEncoderEvent(web_event.date, web_event.scheduleId);
			if (parent != null) {
				parent.web_events.push($scope.convertApiToUiFormat(web_event));
			} else {
				// if destinationName is null (which may be case with old events), then ensure UI displays it as "Stream Now"
				if (web_event.simulcasts != null) {
					for (var x = 0; x < web_event.simulcasts.length; x++) {
						var simulcast = web_event.simulcasts[x];
						web_event.simulcasts[x].formattedDestination = simulcast.destinationName != null ? simulcast.destinationName : $scope.SOCIAL_MEDIA_EVENT_STREAM_NOW_LABEL;
						web_event.simulcasts[x].destinationName = simulcast.destinationName != null ? simulcast.destinationName : $scope.SOCIAL_MEDIA_EVENT_STREAM_NOW_LABEL;
					}
				}
				$scope.upcoming_web_events.push(web_event);
			}
		}
	};

	// loads the schedules for days in the current month that have already past
	$scope.loadPastEvents = function () {

		// if today is the 1st, then there are no past events for this month to load (so bail out)
		if ($scope.TODAY.date() == 1 || $scope.calendar_start_date != null) {
			return;
		}

		$scope.is_loading_past_events = true;
		$scope.calendar_prev_events_error = null;

		// determine start date (which will be the start of the current month)
		$scope.calendar_start_date = moment().startOf('month');
		var start_date_str = $scope.calendar_start_date.format($scope.DATE_FORMAT);
		var end_date_str = moment().subtract(1, 'day').format($scope.DATE_FORMAT);

		$scope.loadSchedulesByDateRange(start_date_str, end_date_str)
			.then(function () {

				// find current month in $scope.months and update the date_blocks entry (each date_block contains the events for that date)
				var month_int = parseInt($scope.TODAY.format('M'));
				var year_int = parseInt($scope.TODAY.format('YYYY'));
				var current_name = $scope.TODAY.format('MMMM');
				var current_year = $scope.TODAY.format('YYYY');
				for (var i = 0; i < $scope.months.length; i++) {
					var month = $scope.months[i];
					if (month.name == current_name && month.year == current_year) {
						$scope.months[i].date_blocks = $scope.loadDayListForMonth(month_int, year_int);
						break;
					}
				}

				trackMixpanelEvent(MPEventName.EVENT_SHOW_PAST);
			},
			function (reason) {

				if (!httpService.isStatus406(reason)) {
					$scope.calendar_prev_events_error = 'An error occurred while attempting to load past events. Please try again, or report the problem if it persists.';
					$scope.calendar_start_date = null;
				}

			}).finally(function () {

				$scope.is_loading_past_events = false;

			});
	};

	// loads the schedules for the previous month
	$scope.loadPrevMonths = function () {

		$scope.is_loading_prev_month = true;
		$scope.calendar_prev_events_error = null;

		// determine start date (which will be start of the following month)
		if ($scope.calendar_start_date == null){
			$scope.calendar_start_date = moment().startOf('month');
		}
		$scope.calendar_start_date.subtract(1, 'month');
		var end_date = moment($scope.calendar_start_date);
		var start_date_str = $scope.calendar_start_date.startOf('month').format($scope.DATE_FORMAT);
		var end_date_str = end_date.endOf('month').format($scope.DATE_FORMAT);

		$scope.loadSchedulesByDateRange(start_date_str, end_date_str)
			.then(function () {

				// add previous month to calendar
				var month_int = parseInt($scope.calendar_start_date.format('M'));
				var year_int = parseInt($scope.calendar_start_date.format('YYYY'));
				var month = {
					name: $scope.calendar_start_date.format('MMMM'),
					year: $scope.calendar_start_date.format('YYYY'),
					date_blocks: $scope.loadDayListForMonth(month_int, year_int)
				};
				$scope.months.unshift(month);

				trackMixpanelEvent(MPEventName.EVENT_SHOW_PREVIOUS_MONTH);

			},
			function (reason) {

				if (!httpService.isStatus406(reason)) {
					$scope.calendar_prev_events_error = 'An error occurred while attempting to load past events. Please try again, or report the problem if it persists.';
				}

			}).finally(function () {

				$scope.is_loading_prev_month = false;

			});
	};

	// the calendar defaults to show a certain number of months. This method is used to show additional months beyond what the calendar is already displaying.
	$scope.loadAdditionalMonths = function () {

		$scope.is_busy_loading_next_month = true;
		$scope.calendar_next_month_error = null;

		// save off the current calendar end date; we will need this when we load our calendar grid months after we get our upcoming events back.
		var grid_month_to_load = moment($scope.calendar_end_date);

		// determine start date (which will be start of the following month)
		$scope.calendar_end_date.add(1, 'month');
		var start_date_str = $scope.calendar_end_date.startOf('month').format($scope.DATE_FORMAT);
		// if we are loading more than 1 month, then adjust date to the proper month
		if ($scope.ADDITIONAL_MONTHS_TO_LOAD > 1) {
			$scope.calendar_end_date.add($scope.ADDITIONAL_MONTHS_TO_LOAD - 1, 'month');
		}
		var end_date_str = $scope.calendar_end_date.endOf('month').format($scope.DATE_FORMAT);

		$scope.loadSchedulesByDateRange(start_date_str, end_date_str)
			.then(function () {

				// add more months to calendar
				for (var i = 0; i < $scope.ADDITIONAL_MONTHS_TO_LOAD; i++) {
					grid_month_to_load.add(1, 'month');

					var month_int = parseInt(grid_month_to_load.format('M'));
					var year_int = parseInt(grid_month_to_load.format('YYYY'));

					var month = {
						name: grid_month_to_load.format('MMMM'),
						year: grid_month_to_load.format('YYYY'),
						date_blocks: $scope.loadDayListForMonth(month_int, year_int),
					};
					$scope.months.push(month);
				}

				trackMixpanelEvent(MPEventName.EVENT_SHOW_FUTURE);

			},
				function (reason) {

					if (!httpService.isStatus406(reason)) {
						$scope.calendar_next_month_error = 'An error occurred while attempting to load upcoming events. Please try again, or report the problem if it persists.';
					}

				}).finally(function () {

					$scope.is_busy_loading_next_month = false;

				});
	};

	$scope.loadSocialMediaAccts = function () {

		$scope.is_loading_social_media_accts = true;

		$http.get(jcs.api.url_v3 + '/customers/' + Authentication.getCurrentUser().customerID + '/youtube/channels', { withCredentials: true })
			.then(function (response) {

				// initialize our social media account list
				$scope.social_media_acct_list = response.data;
				$scope.social_media.sortByType($scope.social_media_acct_list);
				// determine what the status is for each social media account
				$scope.updateSocialMediaAccountsStatus();

			},
				function () { // error

					$scope.calendar_error = 'An error occurred while attempting to load the schedule. Please try again, or report the problem if it persists.';

				})['finally'](function () { // always called

					$scope.is_loading_social_media_accts = false;

				});
	};

	$scope.loadSchedulesByDateRange = function (start_date_str, end_date_str) {

		var deferred = $q.defer();

		// load our API requests as promises (to be executed at once)
		var promises = [];

		// regular schedules
		promises.push($http.get(jcs.api.url + '/schedules/upcoming?start=' + start_date_str + '&end=' + end_date_str, { withCredentials: true }));
		// transcoder schedules (if they have permission)
		if (Authentication.getCurrentUser().hasPerm('transcoder_schedules.get')) {
			promises.push($http.get(jcs.api.url_v3 + '/customers/' + Authentication.getCurrentUser().customerID + $scope.API_PATH_WEB_EVENT_SCHEDULE + 'upcoming?start=' + start_date_str + '&end=' + end_date_str, { withCredentials: true }));
		}

		$q.all(promises)
			.then(function (response) {

				// we always fetch regular schedules, so response[0] will always contain those results
				for (var i = 0; i < response[0].data.length; i++) {
					response[0].data[i].eventType = $scope.SCHEDULE_TYPE_ENCODER;
					response[0].data[i].web_events = [];
				}
				// based on how we render events, it does not matter if upcoming_encoder_events is in order (so it is safe to always add whatever events we have to the end)
				$scope.upcoming_encoder_events = $scope.upcoming_encoder_events.concat(response[0].data);
				// did we fetch transcoded schedules? if so, then response[1] will contain the transcoded results
				if (promises.length > 1) {
					$scope.processTranscodedSchedules(response[1].data);
				}

				deferred.resolve();
			},
				function (reason) {

					deferred.reject(reason);

				});

		return deferred.promise;
	};

	$scope.loadInitialCalendar = function (functionToExecute) {

		$scope.is_loading_calendar = true;
		$scope.calendar_error = null;

		var start_date_str = $scope.calendar_start_date != null ? $scope.calendar_start_date.format($scope.DATE_FORMAT) : $scope.TODAY.format($scope.DATE_FORMAT);
		// determine end date. if calendar_end_date is null, then init it using the DEFAULT_MONTHS_TO_INIT constant.
		if ($scope.calendar_end_date == null) {
			$scope.calendar_end_date = moment().add($scope.DEFAULT_MONTHS_TO_INIT - 1, 'months').endOf('month');
		}
		var end_date_str = $scope.calendar_end_date.format($scope.DATE_FORMAT);

		$scope.months = [];
		$scope.upcoming_encoder_events = [];
		$scope.upcoming_web_events = [];

		$scope.loadSchedulesByDateRange(start_date_str, end_date_str)
			.then(function () {

				// add appropriate months to calendar; if calendar_start_date exists then use that as the starting month, otherwise use the current month
				var working_date = $scope.calendar_start_date != null ? moment($scope.calendar_start_date) : moment();
				var diff_in_months = $scope.calendar_end_date.diff(working_date, 'months');
				for (var i = 0; i <= diff_in_months; i++) {
					var month_int = parseInt(working_date.format('M'));
					var year_int = parseInt(working_date.format('YYYY'));

					var month = {
						name: working_date.format('MMMM'),
						year: working_date.format('YYYY'),
						date_blocks: $scope.loadDayListForMonth(month_int, year_int),
					};
					$scope.months.push(month);

					working_date.add(1, 'months');
				}

			},
				function (reason) {

					if (!httpService.isStatus406(reason)) {
						$scope.calendar_error = 'An error occurred while attempting to load the schedule. Please try again, or report the problem if it persists.';
					}

				}).finally(function () {

					$scope.is_loading_calendar = false;
					if (functionToExecute != null) {
						functionToExecute();
					}

				});
	};

	//
	// initialize our starting calendar display
	//
	$scope.loadInitialCalendar();

	if ($scope.has_social_media_perm) {
		$scope.loadSocialMediaAccts();
	}

	// initialize our date picker
	$scope.date_picker_options = {
		singleDatePicker: true,
		autoApply: true,
		locale: {
			format: 'll', // <= looks like this is using moment.js formatting options
		},
	};
	$scope.date_picker_set_date = function (date_as_moment) {
		$('#add-reg-event-date').data('daterangepicker').setStartDate(date_as_moment);
		$('#add-reg-event-date').data('daterangepicker').setEndDate(date_as_moment);
		// for some reason we need to reset our options each time we set the date, otherwise the date picker will default
		// back to displaying dual date pickers (and we just want to show a single picker)
		$('#add-reg-event-date').daterangepicker($scope.date_picker_options, $scope.reg_date_picker_callback);

		$('#add-web-event-date').data('daterangepicker').setStartDate(date_as_moment);
		$('#add-web-event-date').data('daterangepicker').setEndDate(date_as_moment);
		// for some reason we need to reset our options each time we set the date, otherwise the date picker will default
		// back to displaying dual date pickers (and we just want to show a single picker)
		$('#add-web-event-date').daterangepicker($scope.date_picker_options, $scope.web_date_picker_callback);
	};
	$scope.date_picker_set_end_date = function (date_as_moment) {
		$('#end-reg-schedule-date').data('daterangepicker').setStartDate(date_as_moment);
		$('#end-reg-schedule-date').data('daterangepicker').setEndDate(date_as_moment);
		// for some reason we need to reset our options each time we set the date, otherwise the date picker will default
		// back to displaying dual date pickers (and we just want to show a single picker)
		$('#end-reg-schedule-date').daterangepicker($scope.date_picker_options, $scope.reg_date_picker_end_date_callback);

		$('#end-web-schedule-date').data('daterangepicker').setStartDate(date_as_moment);
		$('#end-web-schedule-date').data('daterangepicker').setEndDate(date_as_moment);
		// for some reason we need to reset our options each time we set the date, otherwise the date picker will default
		// back to displaying dual date pickers (and we just want to show a single picker)
		$('#end-web-schedule-date').daterangepicker($scope.date_picker_options, $scope.web_date_picker_end_date_callback);
	};
	$scope.reg_date_picker_callback = function (start, end, label) {
		$scope.reg_event_to_add.date = moment(start).format($scope.DATE_FORMAT);
	};
	$scope.web_date_picker_callback = function (start, end, label) {
		$scope.web_event_to_add.date = moment(start).format($scope.DATE_FORMAT);
	};
	$scope.reg_date_picker_end_date_callback = function (start, end, label) {
		$scope.reg_event_to_add.end_date = moment(start).format($scope.DATE_FORMAT);
	};
	$scope.web_date_picker_end_date_callback = function (start, end, label) {
		$scope.web_event_to_add.end_date = moment(start).format($scope.DATE_FORMAT);
	};
	$('#add-reg-event-date').daterangepicker($scope.date_picker_options, $scope.reg_date_picker_callback);
	$('#end-reg-schedule-date').daterangepicker($scope.date_picker_options, $scope.reg_date_picker_end_date_callback);
	$('#add-web-event-date').daterangepicker($scope.date_picker_options, $scope.web_date_picker_callback);
	$('#end-web-schedule-date').daterangepicker($scope.date_picker_options, $scope.web_date_picker_end_date_callback);

	// build our tooltips
	$timeout(function () {
		// this tooltip uses a html "title/body" with special formatting
		$('.available-to-watch-tooltip').tooltip({
			template: '<div class="tooltip" role="tooltip"><div class="tooltip-arrow"></div><div class="tooltip-inner tooltip-inner-wide tooltip-inner-left"></div></div>',
			placement: 'top',
			title: '<div class="tooltip-section">The "Available to Watch" time is an approximation of when the web event will be ready to watch by the audience. You should not make the web video player available to your audience until after this time.</div>Web events are not available until a few minutes after the start time because it takes time for the encoder to upload the content to the cloud, and then for that content to be converted into the multiple streams that will be made available to the web audience. The "Available to Watch" time may be further delayed if the encoder has problems uploading to the cloud.',
			html: true,
		});
		$('.web-event-profile-tooltip').tooltip({
			placement: 'top',
			title: 'A Sim-Live Web Event will play back the most recent web event associated with the selected Web Event Profile.',
		});
		$('.available-to-watch-tooltip-sim-live').tooltip({
			template: '<div class="tooltip" role="tooltip"><div class="tooltip-arrow"></div><div class="tooltip-inner tooltip-inner-wide tooltip-inner-left"></div></div>',
			placement: 'top',
			title: '<div class="tooltip-section">The "Available to Watch" time is an approximation of when the web event will be ready to watch by the audience. You should not make the web video player available to your audience until after this time.</div>Sim-Live web events are not available until around 1 minute after the start time in order to ensure your audience has sufficient buffer when they initially load the event (which will result in smoother playback).',
			html: true,
		});
		$('.start-web-event-at-tooltip-sim-live').tooltip({
			template: '<div class="tooltip" role="tooltip"><div class="tooltip-arrow"></div><div class="tooltip-inner tooltip-inner-wide tooltip-inner-left"></div></div>',
			placement: 'right',
			title: 'Sim-Live web events are not available until around 1 minute after the start time in order to ensure your audience has sufficient buffer when they initially load the event (which will result in smoother playback).',
			html: true,
		});
		$('.start-web-event-at-tooltip-enc-web-event').tooltip({
			template: '<div class="tooltip" role="tooltip"><div class="tooltip-arrow"></div><div class="tooltip-inner tooltip-inner-wide tooltip-inner-left"></div></div>',
			placement: 'right',
			title: 'Web events are not available until a few minutes after the start time because it takes time for the encoder to upload the content to the cloud, and then for that content to be converted into the multiple streams that will be made available to the web audience.',
			html: true,
		});
		$('.cannot-extend-warning-tooltip').tooltip({
			template: '<div class="tooltip" role="tooltip"><div class="tooltip-arrow"></div><div class="tooltip-inner tooltip-inner-wide tooltip-inner-left"></div></div>',
			placement: 'top',
			title: '<div class="tooltip-section">Only web events that stop at the same time as the scheduled live event can be extended if a live event runs longer than expected.</div>Web events that do not stop at the same time as the scheduled live event cannot be extended.',
			html: true,
		});
		$('.cannot-update-web-event-time').tooltip({
			template: '<div class="tooltip" role="tooltip"><div class="tooltip-arrow"></div><div class="tooltip-inner tooltip-inner-wide tooltip-inner-left"></div></div>',
			placement: 'right',
			title: 'The Web Event start time cannot be updated while a Facebook scheduled post exists. Please delete the scheduled post first.',
			html: true,
		});
		$('.cannot-update-timezone').tooltip({
			template: '<div class="tooltip" role="tooltip"><div class="tooltip-arrow"></div><div class="tooltip-inner tooltip-inner-wide tooltip-inner-left"></div></div>',
			placement: 'right',
			title: 'The timezone cannot be updated while a Facebook scheduled post exists on a Web Event. Please delete the scheduled post first.',
			html: true,
		});
	});

	// if we are on a "edit" page, then we'll warn user about unsaved changes
	$scope.hasUnsavedChanges = function () {
		if ($scope.show_add_event || $scope.show_update_event) return true;
		return false;
	};

	$scope.isListeningForBeforeUnload = false;
	$scope.addWarnOnPageChange = function () {
		if (!$scope.isListeningForBeforeUnload) {
			$scope.isListeningForBeforeUnload = true;
			// by adding a "beforeunload" listener, the browser should automatically prompt the user before leaving
			// we don't appear to have any control over what the popup dialog will say.
			// (this will handle both URL change and browser tab close)
			window.addEventListener('beforeunload', $scope.processBeforeUnloadEvent);
		}
	};
	$scope.removeWarnOnPageChange = function () {
		if ($scope.isListeningForBeforeUnload) {
			$scope.isListeningForBeforeUnload = false;
			window.removeEventListener('beforeunload', $scope.processBeforeUnloadEvent);
		}
	};

	// this will handle if the user tries to navigate to a different site, or closes the browser window
	$scope.processBeforeUnloadEvent = function (event) {
		event.returnValue = 'Any unsaved changes will be lost.'; // message text is displayed in IE
		return 'Any unsaved changes will be lost.';
	};

	$scope.hasInvalidIntervalBuffer = function ({ date, encoder, time_hours: timeHours, time_minutes: timeMinutes, time_meridiem: timeMeridium, time_zone: timeZone, uuid, enabled: newScheduleEnabledStatus }) {
		if (newScheduleEnabledStatus === "Disabled" || !$scope.upcoming_encoder_events) {
			return false;
		}

		const startDate = moment.tz(`${date} ${timeHours}:${timeMinutes} ${timeMeridium}`, $scope.DATE_TIME_MERIDIEM_FORMAT, timeZone).toISOString();
		return $scope.upcoming_encoder_events.some(({ scheduleId, gmtTime, gmtEndTime, encoderId, enabled }) => {
			// do not check if the event is referencing a previously saved version of itself or if different encoder is used
			// ignore disabled schedules
			if (!enabled || uuid === scheduleId || encoderId !== encoder) {
				return false;
			}
			// if start time is after start time of checked event, ensure it does not start < 5 min after.
			if (moment(gmtTime).isSameOrAfter(startDate)) {
				const listedStartDate = moment(gmtTime).toISOString()
				const listedStartDateAdjusted = moment(listedStartDate).subtract(5, 'minutes');
				const startDateMinutes = $scope.convertTimeToMinutes(
					$scope.reg_event_to_add.duration_hours,
					$scope.reg_event_to_add.duration_minutes
				);
				const startDateEnd = moment(startDate).add(startDateMinutes, 'minutes');
				return moment(startDateEnd).isAfter(listedStartDateAdjusted)
			}
			const endDate = moment(gmtEndTime).toISOString()
			const endDateAdjusted = moment(endDate).add(5, 'minutes');

			// is invalid if startDate of new event is before end date + 5 minutes of event in existing list
			return moment(startDate).isBefore(endDateAdjusted);
		});
	}

	// if a user has unsaved changes, will need to warn them before they change the page
	// this function will handle if they try to goto a different page within angular
	$scope.$on('$locationChangeStart', function (event, next, current) {
		if ($scope.hasUnsavedChanges()) {
			if (!confirm('Are you sure you want to leave this page? Any unsaved changes will be lost.')) {
				event.preventDefault(); // they decided not to leave
			} else {
				// user is leaving, so remove beforeunload listener
				$scope.removeWarnOnPageChange();
			}
		} else {
			// user is leaving, so remove beforeunload listener
			$scope.removeWarnOnPageChange();
		}
	});
}

module.exports = ScheduleController;
