import React, { FC } from 'react';
import './style.scss';

const ResiLogoSvg: FC = () => (
  <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 432 288">
    <defs></defs>
    <path
      className="cls-1"
      d="M122,178c-1.11-1.22-3-3.17-4.14-4.33l-6.56-6.57a1.61,1.61,0,0,1-.28-1.91l14.42-26.48a1.63,1.63,0,0,0,0-1.6l-7.1-12a1.59,1.59,0,0,0-1.4-.79l-15.59.09a1.62,1.62,0,0,1-1.41-.82l-7.24-12.69a1.61,1.61,0,0,1,1.4-2.42h31.8a1.62,1.62,0,0,1,1.41.82l15.81,27.76a1.63,1.63,0,0,1,0,1.6l-11.52,20.51a1.62,1.62,0,0,0,.27,1.93l17.55,17.55a1.62,1.62,0,0,0,2.54-.34l19.34-33.48a1.62,1.62,0,0,0,0-1.62L143.05,94.32a1.61,1.61,0,0,0-1.4-.8H85.23a1.61,1.61,0,0,0-1.4.8L55.62,143.2a1.62,1.62,0,0,0,0,1.62l28.21,48.86a1.61,1.61,0,0,0,1.4.8h7.08a1.63,1.63,0,0,1,1.4.81l7.22,12.39a1.62,1.62,0,0,1-1.4,2.43H76.21a1.61,1.61,0,0,1-1.4-.81L37.59,144.82a1.62,1.62,0,0,1,0-1.62L74.81,78.7a1.61,1.61,0,0,1,1.4-.81h74.46a1.62,1.62,0,0,1,1.4.81l37.24,64.5a1.62,1.62,0,0,1,0,1.62l-36.73,63.59a1.61,1.61,0,0,1-2.59.28Z"
    />
    <path
      className="cls-1"
      d="M232.46,127.31a27.91,27.91,0,0,1,10.95,2.18,27.86,27.86,0,0,1,14.94,14.58,26,26,0,0,1,2.25,10.8,26.8,26.8,0,0,1-4.43,15A27.92,27.92,0,0,1,244.79,180l18.27,26.4c.29.38.19.77-.29,1.16a2.36,2.36,0,0,1-1.45.58h-13a1.55,1.55,0,0,1-1.31-.58l-.87-1.16L229.7,182.72H219v23.65a1.74,1.74,0,0,1-1.74,1.74h-10.3a1.86,1.86,0,0,1-1.38-.51,1.68,1.68,0,0,1-.51-1.23V129.06c0-1.16.63-1.75,1.89-1.75Zm0,41.63a14.16,14.16,0,0,0,13.12-8.63,13.56,13.56,0,0,0,1.09-5.44,13.57,13.57,0,0,0-4.13-9.71,14.74,14.74,0,0,0-4.57-3,14.29,14.29,0,0,0-5.51-1.09H219v27.85Z"
    />
    <path
      className="cls-1"
      d="M311.65,199.26a29.42,29.42,0,0,1-9.21,6.67,26.53,26.53,0,0,1-11.38,2.47,26.84,26.84,0,0,1-10.81-2.18,27.65,27.65,0,0,1-8.78-6,28.77,28.77,0,0,1-5.94-8.92,27.68,27.68,0,0,1-2.18-11,31.5,31.5,0,0,1,1.89-10.95,27.76,27.76,0,0,1,5.29-9,24.24,24.24,0,0,1,8.2-6,25.29,25.29,0,0,1,10.59-2.17,24.2,24.2,0,0,1,15.37,5,26.72,26.72,0,0,1,9,12.84,31.8,31.8,0,0,1,1.38,5.95,40.38,40.38,0,0,1,.07,8.84,1.71,1.71,0,0,1-.5,1.24,1.67,1.67,0,0,1-1.24.5H278.58a14.46,14.46,0,0,0,5,5.66,13.14,13.14,0,0,0,7.47,2.18,13.84,13.84,0,0,0,8.7-3.05l1.74-1.74a1.76,1.76,0,0,1,2.61,0l7.54,7.11A1.92,1.92,0,0,1,311.65,199.26ZM289.32,165.9A10.75,10.75,0,0,0,282.5,168a12.59,12.59,0,0,0-4.06,5.54h21.61a12.43,12.43,0,0,0-4.13-5.54A10.63,10.63,0,0,0,289.32,165.9Z"
    />
    <path
      className="cls-1"
      d="M341.1,208.4a27.84,27.84,0,0,1-11.39-2.35,27.44,27.44,0,0,1-9.21-6.6,2,2,0,0,1,0-2.64l7.54-7.12a1.74,1.74,0,0,1,2.61,0q5.37,4.78,10.45,4.93a15.75,15.75,0,0,0,2.53-.22,14.55,14.55,0,0,0,2.62-.65,6.64,6.64,0,0,0,2.1-1.16,2.33,2.33,0,0,0,.87-1.88,2.06,2.06,0,0,0-1.16-1.73,13.33,13.33,0,0,0-3-1.3c-1.21-.39-2.61-.75-4.2-1.09s-3.22-.74-4.86-1.22a55.41,55.41,0,0,1-5.8-2.1,21.22,21.22,0,0,1-5.22-3.11,14.7,14.7,0,0,1-3.78-4.62,15.92,15.92,0,0,1,.73-14.68,16.34,16.34,0,0,1,5.44-5.13,25,25,0,0,1,7.18-2.74,33.6,33.6,0,0,1,7.25-.87,27.59,27.59,0,0,1,20.6,8.94,2,2,0,0,1,0,2.64l-7.54,7.13a1.77,1.77,0,0,1-2.62,0,24.41,24.41,0,0,0-5.43-3.63,14,14,0,0,0-5-1.3,17,17,0,0,0-2.54.21,15.1,15.1,0,0,0-2.61.66,6.82,6.82,0,0,0-2.1,1.15,2.33,2.33,0,0,0-.87,1.88,2.17,2.17,0,0,0,1.09,1.88,11.64,11.64,0,0,0,3,1.3c1.26.38,2.66.75,4.21,1.08a49.26,49.26,0,0,1,4.93,1.38q2.89.87,5.8,2a21.48,21.48,0,0,1,5.22,3,15.2,15.2,0,0,1,3.05,19.3,17.29,17.29,0,0,1-5.44,5.13,22.74,22.74,0,0,1-7.18,2.75A36.65,36.65,0,0,1,341.1,208.4Z"
    />
    <path
      className="cls-1"
      d="M383.29,146.46H372.88c-1.18,0-1.76-.63-1.76-1.88V135a1.68,1.68,0,0,1,.51-1.23,1.71,1.71,0,0,1,1.25-.51h10.41a1.7,1.7,0,0,1,1.24.51A1.68,1.68,0,0,1,385,135v9.58C385,145.83,384.46,146.46,383.29,146.46Zm0,61.65H372.88a1.54,1.54,0,0,1-1.76-1.74V154.15a1.68,1.68,0,0,1,.51-1.23,1.71,1.71,0,0,1,1.25-.51h10.41a1.7,1.7,0,0,1,1.24.51,1.68,1.68,0,0,1,.51,1.23v52.22A1.54,1.54,0,0,1,383.29,208.11Z"
    />
  </svg>
);

export default ResiLogoSvg;
