import { perms } from '../../jcs';
import { eventprofileusersModule } from './index';
import { eventprofilesModule } from '../eventprofiles/index';

function routeConfig($provide, $routeProvider) {
	'ngInject';

	$provide.factory('$routeProvider', () => {
		return $routeProvider;
	});

	$routeProvider.when(eventprofileusersModule.routes.list, {
		controller: eventprofileusersModule.controllers.default,
		template: require('./eventprofileusers.tmpl.html').default,
		activenav: eventprofilesModule.name, // <= currently when event profile users are viewed, event profiles will be the active nav
		access: {
			loginRequired: true,
			permissions: [perms['event_profiles.get']],
		},
	});
}

export default routeConfig;
