import React, { Context, createContext } from 'react';
import { FACEBOOK_PAGE_STATE_CONTEXT_KEYS } from '../../../pages/EventAnalytics/event-analytics-constants';

export interface FacebookActions<T> {
  payload: T;
  type: FACEBOOK_PAGE_STATE_CONTEXT_KEYS;
}

export interface FacebookContextType {
  destinationType: string | null;
}

export interface FacebookContextProps {
  facebookAnalyticsState: FacebookContextType;
  dispatch: React.Dispatch<FacebookActions<any>>;
}

export const FacebookContext: Context<FacebookContextProps> = createContext<FacebookContextProps>(
  {} as FacebookContextProps
);

export const dispatchers = {
  [FACEBOOK_PAGE_STATE_CONTEXT_KEYS.DESTINATION_TYPE]: (
    state: FacebookContextType,
    action: FacebookActions<string>
  ): FacebookContextType => {
    return { ...state, destinationType: action.payload };
  },
};

export const FacebookReducer = (state: FacebookContextType, action: FacebookActions<any>): FacebookContextType => {
  return dispatchers[action.type](state, action);
};
