import TimezoneComponent from './timezone.component';

export const timezoneModule = {
	name: 'timezoneComponent',
	component: {
		name: 'timezone'
	}
};

export default angular
	.module(timezoneModule.name, [])
	.component(timezoneModule.component.name, new TimezoneComponent()).name;
