import { Context, createContext, Dispatch } from 'react';
import { ALL_DESTINATIONS_STATE_CONTEXT_KEYS } from '../../../pages/EventAnalytics/event-analytics-constants';

export interface AllDestinationsActions<T> {
  payload: T;
  type: ALL_DESTINATIONS_STATE_CONTEXT_KEYS;
}

export interface AllDestinationsContextType {
  groupBy: string;
}

export interface AllDestinationsContextProps {
  allDestinationsAnalyticsState: AllDestinationsContextType;
  dispatch: Dispatch<AllDestinationsActions<any>>;
}

export const AllDestinationsContext: Context<AllDestinationsContextProps> = createContext<AllDestinationsContextProps>(
  {} as AllDestinationsContextProps
);

export const dispatchers = {
  [ALL_DESTINATIONS_STATE_CONTEXT_KEYS.GROUP_BY]: (
    state: AllDestinationsContextType,
    action: AllDestinationsActions<string>
  ): AllDestinationsContextType => {
    return { ...state, groupBy: action.payload };
  },
};

export const AllDestinationsReducer = (
  state: AllDestinationsContextType,
  action: AllDestinationsActions<any>
): AllDestinationsContextType => {
  return dispatchers[action.type](state, action);
};
