'use strict';

const jcs = require('../../jcs');
const moment = require('moment');
const $ = require('jquery');
const { trackMixpanelEvent, MPEventName, MPEventProperty } = require( '../../../../src/mixpanel' );
const { SOCIAL_MEDIA_TYPE_FACEBOOK, SOCIAL_MEDIA_TYPE_YOUTUBE } = require('../../constants');

function SocialMediaController(
	$scope,
	$timeout,
	$http,
	$q,
	$window,
	$cookies,
	$routeParams,
	focus,
	Authentication,
	socialMedia,
	socialMediaAuth
) {
	'ngInject';
	$(window).trigger('resize'); // ensure footer is properly positioned

	$scope.ACCOUNT_NAME_MAX_LENGTH = 64;
	$scope.EXPIRATION_UNKNOWN = 'Unknown';

	socialMediaAuth.init();
	$scope.social_media = socialMedia.init();

	$scope.is_loading = false;
	$scope.is_busy = false;

	$scope.error_msg = null;
	$scope.has_error = null;

	$scope.accounts = null;
	$scope.account_to_view = null;
	$scope.account_to_edit = null;
	$scope.account_to_delete = null;

	$scope.show_youtube_redirect_warning = false;
	$scope.show_facebook_redirect_warning = false;

	$scope.mismatched_accts_info = null;
	$scope.extend_expiration_info = null;

	$scope.add_youtube_fields = null;
	$scope.add_facebook_fields = null;
	$scope.edit_fields = null;

	$scope.canShowFacebookBtn = function () {
		return Authentication.getCurrentUser().hasToggle('facebook');
	};

	$scope.canShowAddAccountBtns = function () {
		return Authentication.getCurrentUser().hasPerm('social_accts.add');
	};

	$scope.canShowEditBtn = function () {
		return Authentication.getCurrentUser().hasPerm('social_accts.update');
	};

	$scope.canShowDeleteBtn = function () {
		return Authentication.getCurrentUser().hasPerm('social_accts.delete');
	};

	$scope.canShowGrantAccessBtn = function (account){
		return account.status == $scope.social_media.STATUS_TOKEN_INVALID && $scope.canShowAddAccountBtns();
	};

	$scope.canShowExtendAccessBtn = function (account){
		return account.status == $scope.social_media.STATUS_OK && account.expires_at != null && $scope.canShowAddAccountBtns();
	};

	$scope.canShowExpirationWarning = function (account){
		return !account.has_expired && account.days_away !== null && account.days_away <= 14;
	};

	$scope.canShowExpiredWarning = function (account){
		return account.has_expired && account.expires_at !== null;
	};

	$scope.getExpirationWarningClass = function (days){
		if (days != null){
			if (days <= 3){
				return 'bg-red';
			}
			if (days <= 7){
				return 'bg-orange';
			}
			if (days <= 14){
				return 'bg-gray';
			}
		}
		return '';
	};

	// return value is fractional (not a whole number)
	$scope.getDaysAway = function (expiration_date){
		if (expiration_date != null){
			var today = moment();
			var expires = moment(expiration_date);
			return moment.duration(expires.diff(today)).asDays();
		}
		return null;
	}

	$scope.getDaysAwayFormatted = function (days){

		if (days != null){
			if (days <= 0){
				return "Expired";
			} else if (days < 1){
				return "Less than 1 day";
			} else {
				let days_formatted = Math.floor(days);
				if (days_formatted == 1){
					return "1 day";
				} else {
					return days_formatted + " days";
				}
			}
		}
		return '';
	};

	$scope.enterExtendExpirationMode = function (account){
		$scope.error_msg = null;
		$scope.extend_expiration_info = account;
	};

	$scope.cancelView = function () {
		$scope.account_to_view = null;
	};

	$scope.cancel = function () {
		$scope.error_msg = null;
		$scope.account_to_edit = null;
		$scope.account_to_delete = null;
		$scope.add_youtube_fields = null;
		$scope.edit_fields = null;
		$scope.show_youtube_redirect_warning = false;
		$scope.show_facebook_redirect_warning = false;
		$scope.mismatched_accts_info = null;
		$scope.extend_expiration_info = null;

		$scope.add_facebook_fields = null;

		// if the user cancels after a redirect, then we'll need to load our accounts
		if ($scope.accounts == null) {
			$scope.loadAccounts();
		}
	};

	$scope.enterAddYouTubeAcctMode = function () {
		$scope.show_youtube_redirect_warning = true;
		$scope.error_msg = null;
	};

	$scope.enterAddFacebookAcctMode = function () {
		$scope.show_facebook_redirect_warning = true;
		$scope.error_msg = null;
	};

	$scope.enterViewMode = function (acct) {
		$scope.error_msg = null;
		$scope.account_to_view = acct;
	};

	$scope.enterEditMode = function (acct) {
		$scope.error_msg = null;
		$scope.account_to_edit = acct;

		$scope.edit_fields = {
			name: $scope.account_to_edit.name,
			description: $scope.account_to_edit.description,
		};

		// see app.js for where focus is defined
		focus('edit-name');
	};

	$scope.isAccountUsedByWebSchedules = function (acct) {
		if (acct != null && acct.hasOwnProperty('web_event_schedules')) {
			return acct.web_event_schedules != null && acct.web_event_schedules.length > 0;
		}
		return false;
	};

	$scope.enterDeleteMode = function (acct) {
		$scope.error_msg = null;
		$scope.account_to_delete = acct;

		$scope.is_loading = true;

		$http.get(jcs.api.url_v3 + '/customers/' + Authentication.getCurrentUser().customerID + '/webeventschedules?channelId=' + acct.uuid, { withCredentials: true }).then(
			function (response) { // success

				$scope.account_to_delete.web_event_schedules = response.data;
			},
			function () { // error

				$scope.account_to_delete.web_event_schedules = null;

			})['finally'](function () { // always called

				$scope.is_loading = false;
			});
	};

	$scope.deleteAccount = function () {
		$scope.is_busy = true;

		var url = $scope.account_to_delete.type == $scope.social_media.TYPE_YOUTUBE ?
			jcs.api.url_v3 + '/customers/' + Authentication.getCurrentUser().customerID + '/youtube/channels/' + $scope.account_to_delete.uuid :
			jcs.api.url_v3 + '/customers/' + Authentication.getCurrentUser().customerID + '/facebook/channels/' + $scope.account_to_delete.uuid;

		$http.delete(url, { withCredentials: true, data: null }).then(
			function () { // success
				trackMixpanelEvent(MPEventName.SOCIAL_DESTINATION_DELETE, {
					[MPEventProperty.SOCIAL_DESTINATION]: $scope.account_to_delete.type === SOCIAL_MEDIA_TYPE_YOUTUBE ? 'YouTube' : 'Facebook',
					[MPEventProperty.DESTINATION_ACCOUNT_NAME]: $scope.account_to_delete.name,
				})

				// remove account from our list
				var index = $scope.accounts.indexOf($scope.account_to_delete);
				if (index > -1) {
					$scope.accounts.splice(index, 1);
				}

				$scope.account_to_delete = null;
				$scope.error_msg = null;
			},
			function () { // error

				$scope.error_msg = 'An error occurred while attempting to delete access to the social media account. Please try again, or report the problem if it persists.';

			})['finally'](function () { // always called

				$scope.is_busy = false;
			});
	};

	$scope.isEmpty = function (value) {
		return value == null || value == '';
	};

	$scope.doesAddOrEditAccountFailValidation = function (fields) {
		// start with clean slate
		$scope.has_error = {};
		$scope.error_msg = null;

		// check required fields to ensure they aren't empty
		$scope.has_error.name = $scope.isEmpty(fields.name);

		var error_count = 0;
		for (var property in $scope.has_error) {
			if ($scope.has_error[property] === true) {
				error_count++;
			}
		}
		var has_validation_error = error_count > 0;

		if (has_validation_error) {
			$scope.error_msg = 'Please specify a value for the highlighted fields below.';
			return has_validation_error;
		}

		return false;
	};

	$scope.saveYoutubeAcct = function () {
		// if we have form validation errors, then don't go any further
		if ($scope.doesAddOrEditAccountFailValidation($scope.add_youtube_fields)) {
			return false;
		}

		$scope.is_busy = true;

		var data = {
			name: $scope.add_youtube_fields.name,
			description: $scope.add_youtube_fields.description,
			refreshToken: $scope.add_youtube_fields.youtube_refresh_token,
			partnerChannelId: $scope.add_youtube_fields.channel_id,
			type: $scope.social_media.TYPE_YOUTUBE,
		};

		trackMixpanelEvent(MPEventName.SOCIAL_DESTINATION_ADD, {
			[MPEventProperty.SOCIAL_DESTINATION] : 'YouTube',
			[MPEventProperty.DESTINATION_ACCOUNT_NAME] : $scope.add_youtube_fields.name,
		});

		$http.post(jcs.api.url_v3 + '/customers/' + Authentication.getCurrentUser().customerID + '/youtube/channels', data, { withCredentials: true, }).then(
			function () { // success
				$scope.add_youtube_fields = null;

				// refresh our accounts list
				$scope.loadAccounts();
			},
			function () { // error

				$scope.error_msg = 'An error occurred while attempting to add the YouTube account. Please try again, or report the problem if it persists.';

			})['finally'](function () { // always called

				$scope.is_busy = false;
			});
	};

	$scope.editAccount = function () {
		// if we have form validation errors, then don't go any further
		if ($scope.doesAddOrEditAccountFailValidation($scope.edit_fields)) {
			return false;
		}

		$scope.is_busy = true;

		var data = {
			name: $scope.edit_fields.name,
			description: $scope.edit_fields.description,
		};

		$http
			.patch(
				jcs.api.url_v3 +
				'/customers/' +
				Authentication.getCurrentUser().customerID +
				'/youtube/channels/' +
				$scope.account_to_edit.uuid,
				data,
				{ withCredentials: true }
			)
			.then(
				function () {
					// success
					trackMixpanelEvent(MPEventName.SOCIAL_DESTINATION_EDIT, {
						[MPEventProperty.SOCIAL_DESTINATION]: $scope.account_to_edit.type === SOCIAL_MEDIA_TYPE_YOUTUBE ? 'YouTube' : 'Facebook',
						[MPEventProperty.DESTINATION_ACCOUNT_NAME]: $scope.edit_fields.name,
					});

					// update the existing account info that is in our list
					$scope.account_to_edit.name = $scope.edit_fields.name;
					$scope.account_to_edit.description = $scope.edit_fields.description;

					$scope.account_to_edit = null;
					$scope.edit_fields = null;
				},
				function () {
					// error

					$scope.error_msg =
						'An error occurred while attempting to update the account. Please try again, or report the problem if it persists.';
				}
			)
		['finally'](function () {
			// always called

			$scope.is_busy = false;
		});
	};

	$scope.saveFacebookAcct = function () {
		// if we have form validation errors, then don't go any further
		if ($scope.doesAddOrEditAccountFailValidation($scope.add_facebook_fields)) {
			return false;
		}

		$scope.is_busy = true;

		var data = {
			name: $scope.add_facebook_fields.name,
			userId: $scope.add_facebook_fields.id,
			description: $scope.add_facebook_fields.description,
			refreshToken: $scope.add_facebook_fields.access_token,
			type: $scope.social_media.TYPE_FACEBOOK,
			expirationTime: $scope.add_facebook_fields.expiration_time,
		};

		$http
		.post(jcs.api.url_v3 + '/customers/' + Authentication.getCurrentUser().customerID + '/facebook/channels', data, {
			withCredentials: true,
		})
		.then(
			function () {
				//	success
				trackMixpanelEvent(MPEventName.SOCIAL_DESTINATION_ADD, {
				[MPEventProperty.SOCIAL_DESTINATION] : 'Facebook',
				[MPEventProperty.DESTINATION_ACCOUNT_NAME] : $scope.add_facebook_fields.name,
			});

				$scope.add_facebook_fields = null;

				// refresh our accounts list
				$scope.loadAccounts();
			},
				function () {
					// error

					$scope.error_msg =
						'An error occurred while attempting to add the Facebook account. Please try again, or report the problem if it persists.';
				}
			)
		['finally'](function () {
			// always called

			$scope.is_busy = false;
		});
	};

	$scope.showMoreInfoDialog = function (simulcast) {
		$scope.more_info = {
			title: 'A Problem was Encountered',
			code: simulcast.status,
			type: simulcast.type,
		};

		// update title in some situations
		switch ($scope.more_info.code) {
			case 'TOKEN_INVALID':
				$scope.more_info.title =
					'Unable to Access ' + $scope.social_media.getTypeAsLabel($scope.more_info.type) + ' Account';
				break;
			case 'NOT_ENABLED':
				if ($scope.more_info.type == $scope.social_media.TYPE_YOUTUBE) {
					$scope.more_info.title = 'Not Allowed to Live Stream';
				} else if ($scope.more_info.type == $scope.social_media.TYPE_FACEBOOK) {
					$scope.more_info.title = 'Not Allowed to Publish Video';
				}
				break;
			case 'ENABLED_BUT_NOT_ACTIVE':
				$scope.more_info.title = 'Live Streaming Not Yet Approved';
				break;
		}

		$timeout(function () {
			$('#simulcast-error-more-info').modal('show');
		});
	};

	$scope.getFormattedExpiration = function (expiration_date_time, status) {
		if (status != $scope.social_media.STATUS_OK && expiration_date_time == null){
			return '';
		}
		if (expiration_date_time == null) {
			return $scope.EXPIRATION_UNKNOWN;
		}
		return moment(expiration_date_time).format('ddd, MMM D, YYYY h:mm A');
	};

	$scope.getStatusAsLabel = function (status) {
		switch (status) {
			case $scope.social_media.STATUS_CHECKING:
				return 'Checking ...';
			case $scope.social_media.STATUS_OK:
				return 'Access verified';
			case $scope.social_media.STATUS_TOKEN_INVALID:
				return 'No Access';
			case $scope.social_media.STATUS_NOT_ENABLED:
				return 'Not allowed to Live Stream';
			case $scope.social_media.STATUS_ENABLED_BUT_NOT_ACTIVE:
				return 'Channel Update Required'; // or 'Live Stream Approval Pending' or 'Possible Channel Update Required'
			default:
				return 'Unknown';
		}
	};

	$scope.hasExpired = function (expires_at_date) {
		if (expires_at_date){
			const now = moment();
			const expiration = moment(expires_at_date);
			return now.isAfter(expiration);
		}
		return true;
	};

	// this is a callback we use with the socialMedia service (as it determines account status, it calls this method)
	$scope.updateAcctStatus = function (account_id, info) {
		for (const account of $scope.accounts) {
			if (account.uuid === account_id) {
				account.status = info.status;
				account.status_label = $scope.getStatusAsLabel(info.status);
				account.expires_at = info.expires_at;
				account.expires_at_formatted = $scope.getFormattedExpiration(info.expires_at, info.status);
				account.days_away = $scope.getDaysAway(info.expires_at);
				account.days_away_formatted = $scope.getDaysAwayFormatted(account.days_away);
				account.has_expired = $scope.hasExpired(info.expires_at);
				return;
			}
		}
	};

	$scope.loadAccounts = function () {
		$scope.is_loading = true;

		$http.get(jcs.api.url_v3 + '/customers/' + Authentication.getCurrentUser().customerID + '/youtube/channels', {withCredentials: true}).then(
			function (response) { // success

				$scope.accounts = response.data;

				var acct_list = [];

				// add a "status" field for each account and queue up an API call to check the actual status
				for (var i = 0; i < $scope.accounts.length; i++) {
					$scope.accounts[i].status = $scope.social_media.STATUS_CHECKING;
					$scope.accounts[i].status_label = $scope.getStatusAsLabel($scope.social_media.STATUS_CHECKING);
					$scope.accounts[i].expires_at_formatted = 'Checking ...';
					acct_list.push({
						id: $scope.accounts[i].uuid,
						type: $scope.accounts[i].type,
					});
				}

				// check the status for each account
				$scope.social_media.checkAccountStatus(
					acct_list,
					Authentication.getCurrentUser().customerID,
					$scope.updateAcctStatus
				);
			},
			function () { // error

				$scope.error_msg = 'Unable to retrieve YouTube account information. Please try again, or report the problem if it persists.';
				$scope.accounts = null;

			})['finally'](function () { // always called

				$scope.is_loading = false;
			});
	};

	$scope.getStatusClass = function (status) {
		if (status == $scope.social_media.STATUS_OK) {
			return 'status-block-low';
		}
		if (
			status == $scope.social_media.STATUS_TOKEN_INVALID ||
			status == $scope.social_media.STATUS_NOT_ENABLED ||
			$scope.social_media.STATUS_ENABLED_BUT_NOT_ACTIVE
		) {
			return 'status-block-urgent';
		}
		return 'status-block-unknown';
	};

	$scope.getYouTubeChannelResponseInfo = function (response) {
		var info = {
			title: '',
			description: '',
			id: '',
		};
		if (response.data.hasOwnProperty('items')) {
			if (response.data.items.length > 0) {
				(info.title = response.data.items[0].snippet.title),
					(info.description = response.data.items[0].snippet.description);
				info.id = response.data.items[0].id;
			}
		}
		return info;
	};

	$scope.getYoutubeAddAccessUrl = function () {
		return socialMediaAuth.getYoutubeAddAccessUrl();
	};

	$scope.getFacebookAddAccessUrl = function () {
		return socialMediaAuth.getFacebookAddAccessUrl();
	};

	$scope.getGrantAccessUrl = function (type, uuid) {
		return socialMediaAuth.getGrantAccessUrl(type, uuid);
	};

	$scope.handleMixpanelGrantOrExtendEvent = function(type, name, MPEvent) {
		trackMixpanelEvent(MPEvent === 'grant' ? MPEventName.SOCIAL_DESTINATION_GRANT : MPEventName.SOCIAL_DESTINATION_EXTEND, {
			[MPEventProperty.SOCIAL_DESTINATION]: type === SOCIAL_MEDIA_TYPE_YOUTUBE ? 'YouTube' : 'Facebook',
			[MPEventProperty.DESTINATION_ACCOUNT_NAME]: name,
		});
	}

	$scope.processYoutubeRedirect = function () {
		// clear our cookie
		socialMediaAuth.clearYoutubeInfo();

		// get auth code and prepare data we'll send to get back a refresh token
		var auth_code = socialMediaAuth.getAuthCode();
		var oauth_data = socialMediaAuth.getYoutubeOAuthData();

		if (socialMediaAuth.isForExistingYoutubeAccount()) {
			//
			// we are re-granting access to an existing social media account
			//

			// ensure youtube account access was successful
			if (auth_code !== false) {
				$scope.is_loading = true;

				$http.post(socialMediaAuth.YOUTUBE_REFRESH_AND_ACCESS_TOKEN_URL, oauth_data).then(
					function (response) {// success

						var account_uuid = socialMediaAuth.getAccountUUID();

						// hit api with new access and refresh token
						var data = {
							refreshToken: response.data.refresh_token,
						};

						$http.patch(jcs.api.url_v3 + '/customers/' + Authentication.getCurrentUser().customerID + '/youtube/channels/' + account_uuid, data, { withCredentials: true }).then(
							function () { // success

								// show our accounts list
								$scope.loadAccounts();
							},
							function () { // error

								$scope.error_msg = 'An error occurred while attempting to update access to the YouTube account. Please try again, or report the problem if it persists.';
								$scope.is_loading = false;

							})['finally'](function () { // always called
								// don't set is_loading false. Since we will load accounts on success, the transition will be
								// smoother is we leave is_loading as is and let loadAccounts set to false.
							});
					},
					function (result) { // error

						// note: do not set is_loading to false; we will let loadAccounts handle that.

						$scope.error_msg = 'An error occurred trying to obtain YouTube account access. Sometimes this occurs when you already have access to the selected YouTube account. Please check the status for the account below. If you still do not have access, you can try to grant access again, or report the problem if it persists.';
						// show our accounts list
						$scope.loadAccounts();

					}) ['finally'](function () { // always called
						// don't need to set is_loading here because of nested http calls
					});

			} else {

				var error_code = socialMediaAuth.getErrorCode();

				if (error_code == 'access_denied') {
					$scope.error_msg = 'Obtaining YouTube account access was not completed successfully. Most likely this is because the process was canceled.';
				} else {
					$scope.error_msg = 'An error occurred trying to obtain YouTube account access. Error = ' + error_code + '. Please try again, or report the problem if it persists.';
				}
				// show our accounts list
				$scope.loadAccounts();
			}
		} else {
			//
			// we are creating a new social media account
			//

			// ensure youtube account access was successful
			if (auth_code !== false) {
				$scope.is_loading = true;

				$http.post(socialMediaAuth.YOUTUBE_REFRESH_AND_ACCESS_TOKEN_URL, oauth_data).then(
					function (response) { // success

						// helpful youtube api links:
						// https://developers.google.com/youtube/v3/sample_requests
						// https://developers.google.com/youtube/v3/docs/
						// https://developers.google.com/youtube/v3/docs/channels#resource
						$http.get('https://www.googleapis.com/youtube/v3/channels?part=snippet&mine=true&access_token=' + response.data.access_token).then(
							function (inner_response) { // success

								// set our access and refresh tokens, and prepopulate the name and description fields
								var channel_info = $scope.getYouTubeChannelResponseInfo(inner_response);
								$scope.add_youtube_fields = {
									youtube_access_token: response.data.access_token,
									youtube_refresh_token: response.data.refresh_token,
									name: channel_info.title,
									description: channel_info.description,
									channel_id: channel_info.id,
								};
							},
							function (reason) { // error

								$scope.add_youtube_fields = {
									youtube_access_token: response.data.access_token,
									youtube_refresh_token: response.data.refresh_token,
									name: '',
									description: '',
									channel_id: '',
								};
								// see app.js for where focus is defined
								// note: this doesn't appear to be working
								$timeout(function () {
									focus('add-youtube-name');
								}, 150);

							})['finally'](function () { // always called
							});
					},
					function (result) { // error

						// TODO: can we improve this error message?
						$scope.error_msg = 'An error occurred trying to obtain YouTube account access. Sometimes this occurs when you select a YouTube account for which you have already granted access. You can check your YouTube account to see if access has already been granted, or you can try another account.';
						// put them back on the youtube redirect warning page (so right back where they were before we sent them to youtube)
						$scope.show_youtube_redirect_warning = true;

					})['finally'](function () { // always called

						$scope.is_loading = false;
					});

			} else {
				var error_code = socialMediaAuth.getErrorCode();

				if (error_code == 'access_denied') {
					$scope.error_msg = 'Obtaining YouTube account access was not completed successfully. Most likely this is because the process was canceled.';
				} else {
					$scope.error_msg = 'An error occurred trying to obtain YouTube account access. Error = ' + error_code + '. Please try again, or report the problem if it persists.';
				}
				// put them back on the youtube redirect warning page (so right back where they were before we sent them to youtube)
				$scope.show_youtube_redirect_warning = true;
			}
		}
	};

	$scope.processFacebookRedirect = function () {
		// clear our cookie
		socialMediaAuth.clearFacebookInfo();

		// get auth code and prepare data we'll send to get back a refresh token
		// TODO: once we start implementing reauthorizing existing accounts we can see if we need to change the
		// while getAuthCode and getFacebookOAuthData calls. Perhaps we can just use a single method.
		var auth_code = socialMediaAuth.getAuthCode();
		var oauth_data = socialMediaAuth.getFacebookOAuthData();

		// The expiration time is in epoch seconds.
		const expiration_time = new Date(socialMediaAuth.getFacebookTokenExpirationTime() * 1000).toISOString();
		if (socialMediaAuth.isForExistingFacebookAccount()) {
			//
			// we are re-granting access to an existing social media account
			//

			// ensure account access was successful
			if (auth_code !== false) {
				var account_uuid = socialMediaAuth.getAccountUUID();

				// hit api with new access and refresh tokens & expiration time
				var data = {
					refreshToken: oauth_data.access_token,
					expirationTime: expiration_time
				};

				var promises = [];
				// make call to facebook to fetch name/id of the account for the access token we got back
				promises.push($http.get(`${jcs.api.url_v3}/customers/${Authentication.getCurrentUser().customerID}/facebook/channels/info?accessToken=${oauth_data.access_token}`, { withCredentials: true }));
				// make call to central to fetch the name/userId for the account we wanted to re-grant access to
				promises.push($http.get(jcs.api.url_v3 + '/customers/' + Authentication.getCurrentUser().customerID + '/facebook/channels/' + account_uuid, {withCredentials: true}));

				$q.all(promises).then(
					function (response) {

						var acct_id_from_fb = response[0].data.id;
						var acct_id_from_central = response[1].data.userId;

						// ensure that the account we just got access for is the same account that was originally used to create the social media account.
						// if they are different, then we'll need to warn the user.
						if (acct_id_from_fb != acct_id_from_central){

							$scope.mismatched_accts_info = {
								acct_id_from_fb: acct_id_from_fb,
								acct_id_from_central: acct_id_from_central,
								acct_name_from_fb: response[0].data.name,
								acct_name_from_central: response[1].data.name,
								type: $scope.social_media.TYPE_FACEBOOK,
								type_label: $scope.social_media.getTypeAsLabel($scope.social_media.TYPE_FACEBOOK),
								account_uuid: account_uuid
							};

						} else { // accounts match, so proceed like we normally would

							$scope.is_loading = true;

							$http.patch(jcs.api.url_v3 + '/customers/' + Authentication.getCurrentUser().customerID + '/facebook/channels/' + account_uuid, data, { withCredentials: true }).then(
								function () { // success

									// show our accounts list
									$scope.loadAccounts();
								},
								function () { // error

									$scope.error_msg = 'An error occurred while attempting to update access to the Facebook account. Please try again, or report the problem if it persists.';
									$scope.is_loading = false;

								})['finally'](function () { // always called
									// don't set is_loading false. Since we will load accounts on success, the transition will be
									// smoother is we leave is_loading as is and let loadAccounts set to false.
								});

						}

					},
					function (reason) { // error

						// unable to verify if same account was used

					}).finally(function () { // always called
					});

			} else {
				var error_code = socialMediaAuth.getErrorCode();

				if (error_code == 'access_denied') {
					$scope.error_msg = 'Obtaining Facebook account access was not completed successfully. Most likely this is because the process was canceled.';
				} else {
					$scope.error_msg = 'An error occurred trying to obtain Facebook account access. Error = ' + error_code + '. Please try again, or report the problem if it persists.';
				}
				// show our accounts list
				$scope.loadAccounts();
			}
		} else {
			//
			// we are creating a new social media account
			//

			// ensure account access was successful
			if (auth_code !== false) {
				$scope.is_loading = true;

				$http.get(`${jcs.api.url_v3}/customers/${Authentication.getCurrentUser().customerID}/facebook/channels/info?accessToken=${oauth_data.access_token}`, { withCredentials: true }).then(
					function (response) { // success

							$scope.add_facebook_fields = {
								access_token: oauth_data.access_token,
								name: response.data.name,
								id: response.data.id,
								description: '',
								expiration_time,
							};
							// see app.js for where focus is defined
							// note: this doesn't appear to be working
							$timeout(function () {
								focus('add-facebook-name');
							}, 150);
					},
					function (result) { // error

						// TODO: what causes us to get here for Facebook??
						$scope.error_msg = 'An error occurred trying to obtain Facebook account access. Sometimes this occurs when you select a Facebook account for which you have already granted access. You can check your Facebook account to see if access has already been granted, or you can try another account.';
						// put them back on the redirect warning page (so right back where they were before we sent them to facebook)
						$scope.show_facebook_redirect_warning = true;

					})['finally'](function () { // always called

						$scope.is_loading = false;
					});
			} else {
				var error_code = socialMediaAuth.getErrorCode();

				if (error_code == 'access_denied') {
					$scope.error_msg = 'Obtaining Facebook account access was not completed successfully. Most likely this is because the process was canceled.';
				} else {
					$scope.error_msg = 'An error occurred trying to obtain Facebook account access. Error = ' + error_code + '. Please try again, or report the problem if it persists.';
				}
				// put them back on the redirect warning page (so right back where they were before we sent them to facebook)
				$scope.show_facebook_redirect_warning = true;
			}
		}
	};

	// check to see if we are arriving here after a redirect, or whether it was normal user navigation
	if (socialMediaAuth.hasYoutubeInfo()) {
		$scope.processYoutubeRedirect();
	} else if (socialMediaAuth.hasFacebookInfo()) {
		$scope.processFacebookRedirect();
	} else {
		//
		// initialize by loading our social media accounts
		//
		$scope.loadAccounts();
	}
}

module.exports = SocialMediaController;
