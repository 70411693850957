import angular from 'angular';
import { AsYouType } from 'libphonenumber-js';

function autoFormatPhone() {
	return {
		restrict: 'A',
		require: 'ngModel',
		link: function (scope, element, attrs, controller) {
			var el = element[0];
			var previous = '';

			function format(value) {
				if (typeof AsYouType != 'undefined') {
					return new AsYouType('US').input(value);
				}
				return value;
			}

			// returns how many digits are before the current cursor position
			function getCursorPosRelToNum(val, currentCursorPos) {
				var count = 0;
				for (var i = 0; i < currentCursorPos; i++) {
					var char = val.charAt(i);
					if (!isNaN(parseInt(char))) {
						count++;
					}
				}
				return count;
			}

			// returns the character position of the nth digit in the given string
			function getPositionOfNthNumber(val, numberCount) {
				var position = 0;
				var count = 0;
				for (var i = 0; i < val.length; i++) {
					var char = val.charAt(i);
					if (!isNaN(parseInt(char))) {
						count++;
						if (count >= numberCount) return i;
					}
				}
				return val.length;
			}

			// $formatters = array of functions to execute, as a pipeline, whenever the bound ngModel expression changes programmatically.
			// so this is a good place to initialize our "previous" value
			controller.$formatters.push(function (val) {
				previous = val;
				return val;
			});

			// $parsers = array of functions to execute, as a pipeline, whenever the control updates the ngModelController
			// with a new $viewValue from the DOM, usually via user input.
			// so this is when we need to do our auto formatting and ensure cursor is in proper position afterwards
			controller.$parsers.push(function (val) {
				var oldLength = previous.length;
				var newLength = val.length;
				var cursorPos = el.selectionStart;

				// cursor positioning after we do our auto formatting is a little tricky. In some cases the formatting will add
				// additional characters to the string (such as when adding parenthesis for USA numbers). So what we try to do is
				// keep the cursor position consistent with the digit. So if you added a 3rd digit, then we try to ensure the
				// cursor is still after the 3rd digit when auto formatting is complete. So the cursor "position" we are getting
				// here is the number of digits that are before the cursor. After formatting is complete we will try to ensure that
				// the cursor remains after the same digit.
				var cursorPosByNum = getCursorPosRelToNum(val, cursorPos);

				// only perform auto formatting if the entered value is getting larger. If they are deleting characters,
				// then keep everything as is (otherwise the formatter may insert the deleted character back).
				if (newLength > oldLength) {
					var formatted = format(val);

					// if the user enters a non-digit in the middle of the string, the formatter doesn't seem to
					// like it and will return an empty string. In that case, simply use the previous value
					// (which is what the formatter does when you add a non-digit to the end of the string).
					if (formatted.length == 0) {
						formatted = previous;
					}

					// element.val(formatted) does not behave with repeated invalid elements
					controller.$setViewValue(formatted);
					controller.$render();

					// our cursor position should only be updated after the view has been rendered, otherwise
					// updating the value will put the cursor at then end.
					var newCursorPos = getPositionOfNthNumber(formatted, cursorPosByNum) + 1;
					el.setSelectionRange(newCursorPos, newCursorPos);

					previous = formatted;
					return formatted;
				}

				previous = val;
				return val;
			});
		},
	};
}

export default angular.module('directives.autoFormatPhone', []).directive('autoFormatPhone', autoFormatPhone).name;
