import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { customProperties as vars } from '@resi-media/resi-ui';
import { BRAND_NAVY_COLOR } from '../../pages/EventAnalytics/event-analytics-constants';

export const ViewEventsDiv = styled.div`
  margin-right:${vars.spacingM};
  padding: ${vars.spacingM} 0 0 0;
`;

export const ViewEventsButton = css`
  font-size: ${vars.fontSize12};
  color: ${BRAND_NAVY_COLOR};
`;

export const EventsModalContent = styled.div`
  height: 90%;
  min-width: 75vw;
  max-width: 900px;
  padding: ${vars.spacingXl} 4em 4em 4em;
`;

export const EventStatsHeaderDiv = styled.div`
  display: flex;
  justify-content: space-between;
  font-family: ${vars.fontFamily};
  align-items: center;
`;

export const EventStatsBodyContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const EventsModalTableContainer = styled.div`
  flex: 1 0 auto;
  padding: ${vars.spacingL} 0;
`;

export const EventsModalPagination = styled.div`
  display: flex;
  flex-shrink: 0;
  justify-content: flex-end;
`;
