import 'angular-route';
import angular from 'angular';
import router from './module.monitor.routes';
import commonServicesModule from '../../services';

export const monitorModule = {
	name: 'monitor',
	controllers: {
		default: 'monitorCtrl',
	},
	routes: {
		list: '/monitor',
	},
};

export default angular
	.module(monitorModule.name, ['ngRoute', commonServicesModule])
	.config(router)
	.controller(monitorModule.controllers.default, require('./monitorCtrl')).name;
