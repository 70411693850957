import {
  ClockCircleOutlined,
  ExportOutlined,
  TeamOutlined,
  UndoOutlined,
  ZoomInOutlined,
  ZoomOutOutlined,
} from '@ant-design/icons';
import { Paper } from '@material-ui/core';
import { Grid, Inline, Option, Select, Text } from '@resi-media/resi-ui';
import Tooltip from '@material-ui/core/Tooltip';
import Link from '@material-ui/core/Link';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import React, { FC, useContext, useState } from 'react';
import { DateRange, Range } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import {
  ContainedButton,
  ContainedTab,
  ContainedTabs,
  FillBlockSpinner,
  LineChart,
  NavTab,
  NavTabs,
  Surface,
  ViewEvents,
} from '../../../../components';
import Modal from '../../../../components/Modal';
import ModalHeader from '../../../../components/ModalHeader';
import { FacebookContext } from '../../../../contexts/eventanalytics';
import { EventAnalyticsContext } from '../../../../contexts/eventanalytics/event-analytics';
import { LIFE_TIME_DATE_LOCAL, START_DATE_LOCAL } from '../../date-range-options';
import {
  DESTINATION_TYPES,
  FACEBOOK_DESTINATION_TYPES,
  FB_GROUP,
  FB_PAGE,
  FB_TIMELINE,
  LEGEND_NAMES,
  LOADER_SIZE_COMPONENT,
  LOADER_SIZE_SUB_COMPONENT,
  PAGE,
  PAGE_VALUE,
  ZOOM_CTRL_TOOLTIP,
} from '../../event-analytics-constants';
import { ExportCSVWrapper, LinkButtonContainer } from '../../event-analytics.styles';
import { lineChartDataType, SelectOption, TIME_WINDOWS } from '../../types';
import { formatSecondsToMinutesString, formatTooltipToNormalisedTime, getToolTipLabel, renameKey } from '../../utils';
import {
  ChartControls,
  DropDownWrapper,
  EventStatsGraphWrapper,
  EventStatsHeaderDiv,
  GraphWrapper,
  IconWrapper,
  InfoWrapper,
  TabsDiv,
} from './facebook-stats-graph.style';

interface FacebookStatsGraphViewProps {
  data: lineChartDataType;
  dateRangeOptions: SelectOption[];
  isChartLoading: boolean;
  isDestinationTypeFetching: boolean;
  isExportCSVLoading: boolean;
  isDatePickerOpen: boolean;
  hasGraphPerms: boolean;
  disableMonthTab: boolean;
  dataPage: number;
  timeWindow: number;
  dataPageName: string;
  handleDataPageChange: (event: React.ChangeEvent<unknown>, newValue: 0 | 1) => void;
  handleDateRangeChange: (item: Option[]) => void;
  handleTimeWindowChange: (event: React.ChangeEvent<unknown>, newValue: TIME_WINDOWS) => void;
  handleCustomDateRangeSelection: (startDate: string, endDate: string) => void;
  closeDatePicker: () => void;
  exportCSV: () => void;
}

const FacebookStatsGraphView: FC<FacebookStatsGraphViewProps> = ({
  data,
  dateRangeOptions,
  isChartLoading,
  isDestinationTypeFetching,
  isDatePickerOpen,
  hasGraphPerms,
  disableMonthTab,
  dataPage,
  timeWindow,
  dataPageName,
  handleDataPageChange,
  handleDateRangeChange,
  handleTimeWindowChange,
  handleCustomDateRangeSelection,
  exportCSV,
  isExportCSVLoading,
  closeDatePicker,
}): JSX.Element => {
  const {
    EventAnalyticsState: {
      viewAllData,
      dateRange: { startDate, endDate },
      eventId,
      dateRangeOption,
    },
  } = useContext(EventAnalyticsContext);
  const { facebookAnalyticsState } = useContext(FacebookContext);
  const [selectionRange, setSelectionRange] = useState<Range>({
    startDate: new Date(startDate),
    endDate: new Date(endDate),
    key: 'selection',
  });

  const getPageName = (pageName: string | null) => {
    switch (pageName) {
      case FACEBOOK_DESTINATION_TYPES.PAGE:
        return FB_PAGE;
      case FACEBOOK_DESTINATION_TYPES.GROUP:
        return FB_GROUP;
      case FACEBOOK_DESTINATION_TYPES.TIMELINE:
        return FB_TIMELINE;
      default:
        return FB_PAGE;
    }
  };

  const handleDateRangeSelect = (range: { selection: Range }) => {
    const { selection } = range;
    const { hour, minute, second, millisecond } = DateTime.local().toObject();
    setSelectionRange({
      startDate: selection.startDate,
      endDate: selection.endDate,
      key: 'selection',
    });
    if (selectionRange.startDate && selectionRange.endDate) {
      const selectionStartDate = selection.startDate
        ? DateTime.fromJSDate(selection.startDate)
            .plus({
              hours: hour,
              minutes: minute,
              seconds: second,
              milliseconds: millisecond,
            })
            .toISO() || ''
        : '';
      const selectionEndDate = selection.endDate
        ? DateTime.fromJSDate(selection.endDate)
            .plus({
              hours: hour,
              minutes: minute,
              seconds: second,
              milliseconds: millisecond,
            })
            .toISO() || ''
        : '';
      handleCustomDateRangeSelection(selectionStartDate, selectionEndDate);
    }
  };

  Object.keys(data.datasets).map((el) => {
    if (el in LEGEND_NAMES) {
      data.datasets = renameKey(data.datasets, el, `${el}`);
    }
  });

  const viewAbleTabs = (destinationType: string | null) => {
    if (eventId !== null) {
      switch (destinationType) {
        case FACEBOOK_DESTINATION_TYPES.GROUP:
          return (
            <NavTabs onChange={handleDataPageChange} value={dataPage}>
              <NavTab
                data-testid="facebook-live-views-tab"
                icon={<IconWrapper className="fa fa-video-camera" />}
                label="Live Views"
              />
            </NavTabs>
          );
        case FACEBOOK_DESTINATION_TYPES.TIMELINE:
          return (
            <NavTabs onChange={handleDataPageChange} value={dataPage}>
              <NavTab
                data-testid="facebook-live-views-tab"
                icon={<IconWrapper className="fa fa-video-camera" />}
                label="Live Views"
              />
            </NavTabs>
          );
        case FACEBOOK_DESTINATION_TYPES.PAGE:
          return (
            <NavTabs onChange={handleDataPageChange} value={dataPage}>
              <NavTab data-testid="facebook-viewers-tab" icon={<TeamOutlined />} label="Viewers" />
              <NavTab data-testid="facebook-avgWatchtime-tab" icon={<ClockCircleOutlined />} label="Avg Watch Time" />
              <NavTab
                data-testid="facebook-live-views-tab"
                icon={<IconWrapper className="fa fa-video-camera" />}
                label="Live Views"
              />
            </NavTabs>
          );
        default:
          return (
            <NavTabs onChange={handleDataPageChange} value={dataPage}>
              <NavTab data-testid="facebook-viewers-tab" icon={<TeamOutlined />} label="Viewers" />
              <NavTab data-testid="facebook-avgWatchtime-tab" icon={<ClockCircleOutlined />} label="Avg Watch Time" />
            </NavTabs>
          );
      }
    } else {
      return (
        <NavTabs onChange={handleDataPageChange} value={dataPage}>
          <NavTab data-testid="facebook-viewers-tab" icon={<TeamOutlined />} label="Viewers" />
          <NavTab data-testid="facebook-avgWatchtime-tab" icon={<ClockCircleOutlined />} label="Avg Watch Time" />
        </NavTabs>
      );
    }
  };

  return (
    <Grid.Item>
      <Paper elevation={0} variant="outlined" data-testid="facebook-stats-view">
        <EventStatsGraphWrapper hasGraphPerms={hasGraphPerms}>
          <EventStatsHeaderDiv>
            <Inline alignItems="center">
              <div>
                <Text.Body size="xs" isUppercase isBold color="secondary">
                  {`${DateTime.fromISO(startDate).toFormat('MMM dd yyyy')} - \
                ${DateTime.fromISO(endDate).toFormat('MMM dd yyyy')}`}
                </Text.Body>
                <DropDownWrapper>
                  <Select
                    size="m"
                    value={dateRangeOption?.value}
                    options={dateRangeOptions}
                    handleChange={(newValue) => {
                      if (newValue[0].value) {
                        handleDateRangeChange(newValue);
                      }
                    }}
                  />
                </DropDownWrapper>
                {isDatePickerOpen ? (
                  <Modal width="fit-content">
                    <Surface padding={'0'}>
                      <ModalHeader titleText="Pick custom date range" closeButton onClose={() => closeDatePicker()} />
                      <DateRange
                        ranges={[selectionRange]}
                        editableDateInputs
                        onChange={handleDateRangeSelect}
                        minDate={viewAllData ? LIFE_TIME_DATE_LOCAL.toJSDate() : START_DATE_LOCAL.toJSDate()}
                      />
                    </Surface>
                  </Modal>
                ) : null}
              </div>
              <ViewEvents
                dateRangeOptions={dateRangeOptions}
                dateRangeSelection={dateRangeOption}
                page={DESTINATION_TYPES.FACEBOOK}
              />
            </Inline>
            <Inline justifyContent="center" alignItems="center">
              {viewAbleTabs(facebookAnalyticsState.destinationType)}
            </Inline>
            <Inline justifyContent="flex-end" alignItems="center">
              <ExportCSVWrapper>
                <>
                  <Tooltip
                    title={
                      <h6>
                        {isExportCSVLoading ? 'Please wait, your file is being prepared. ' : 'Download all data as CSV'}
                      </h6>
                    }
                    arrow
                    placement="top"
                  >
                    <Link
                      component="button"
                      underline="none"
                      variant="body2"
                      disabled={isExportCSVLoading}
                      onClick={exportCSV}
                    >
                      <LinkButtonContainer>
                        <ExportOutlined />
                        <span style={{ marginLeft: '5px' }}>Export CSV</span>
                      </LinkButtonContainer>
                    </Link>
                  </Tooltip>
                  {isExportCSVLoading ? <FillBlockSpinner fontSize={LOADER_SIZE_SUB_COMPONENT} /> : null}
                </>
              </ExportCSVWrapper>
            </Inline>
          </EventStatsHeaderDiv>
          {hasGraphPerms && (
            <>
              <TabsDiv>
                <ContainedTabs style={{ width: '215px' }}></ContainedTabs>
                <Tooltip title={<h6>{ZOOM_CTRL_TOOLTIP}</h6>} arrow placement="top">
                  <ChartControls>
                    <ContainedButton id="zoomPlus">
                      <ZoomInOutlined style={{ fontSize: '20px' }} />
                    </ContainedButton>
                    <ContainedButton id="zoomMinus">
                      <ZoomOutOutlined style={{ fontSize: '20px' }} />
                    </ContainedButton>
                    <ContainedButton id="reset-zoom">
                      <UndoOutlined style={{ fontSize: '20px' }} />
                    </ContainedButton>
                  </ChartControls>
                </Tooltip>
                {dataPageName !== PAGE_VALUE.LIVE_VIEWS ? (
                  <ContainedTabs onChange={handleTimeWindowChange} value={timeWindow}>
                    <ContainedTab data-testid="facebook-view-day-tab" label="Day" />
                    <ContainedTab data-testid="facebook-view-week-tab" label="Week" />
                    <ContainedTab disabled={disableMonthTab} data-testid="facebook-view-month-tab" label="Month" />
                  </ContainedTabs>
                ) : (
                  <ContainedTabs style={{ width: '154px' }}></ContainedTabs>
                )}
              </TabsDiv>
              <GraphWrapper data-testid="facebook-stats-view-lineChart">
                {isChartLoading || isDestinationTypeFetching ? (
                  <FillBlockSpinner fontSize={LOADER_SIZE_COMPONENT} />
                ) : (
                  <>
                    <LineChart
                      data={data}
                      dataPageName={dataPageName}
                      tooltipLabel={getToolTipLabel(dataPageName)}
                      yAxisTickGenerator={dataPage === PAGE.AVG_WATCH_TIME ? formatSecondsToMinutesString : undefined}
                      toolTipFormatGenerator={dataPage === PAGE.AVG_WATCH_TIME ? formatTooltipToNormalisedTime : undefined}
                    />
                    <InfoWrapper>
                      <Text.Body color="secondary">{`*${getPageName(facebookAnalyticsState.destinationType)}`}</Text.Body>
                    </InfoWrapper>
                  </>
                )}
              </GraphWrapper>
            </>
          )}
        </EventStatsGraphWrapper>
      </Paper>
    </Grid.Item>
  );
};

FacebookStatsGraphView.defaultProps = {
  data: {
    labels: ['Aug 9', 'Sep 1', 'Sep 2', 'Sep 9', 'Sep 15', 'Oct 1', 'Oct 8'],
    datasets: { data: [12, 7, 14, 30, 11, 12, 4, 79, 13, 26, 12, 21, 10] },
  },
};

FacebookStatsGraphView.propTypes = {
  data: PropTypes.objectOf(
    PropTypes.exact({
      labels: PropTypes.arrayOf(PropTypes.string).isRequired,
      datasets: PropTypes.arrayOf(
        PropTypes.objectOf(
          PropTypes.exact({
            data: PropTypes.arrayOf(PropTypes.number).isRequired,
            label: PropTypes.string.isRequired,
          })
        )
      ),
    })
  ).isRequired,
  dateRangeOptions: PropTypes.arrayOf(
    PropTypes.exact({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.number.isRequired]).isRequired,
    }).isRequired
  ).isRequired,
  dataPage: PropTypes.number.isRequired,
  dataPageName: PropTypes.string.isRequired,
  isChartLoading: PropTypes.bool.isRequired,
  isExportCSVLoading: PropTypes.bool.isRequired,
  disableMonthTab: PropTypes.bool.isRequired,
  timeWindow: PropTypes.number.isRequired,
  handleDataPageChange: PropTypes.func.isRequired,
  handleDateRangeChange: PropTypes.func.isRequired,
  handleTimeWindowChange: PropTypes.func.isRequired,
  handleCustomDateRangeSelection: PropTypes.func.isRequired,
  hasGraphPerms: PropTypes.bool.isRequired,
  exportCSV: PropTypes.func.isRequired,
  isDatePickerOpen: PropTypes.bool.isRequired,
  closeDatePicker: PropTypes.func.isRequired,
};

FacebookStatsGraphView.displayName = 'FacebookStatsGraphView';

export default FacebookStatsGraphView;
