import { authModule } from './../modules/auth/index';

// this is just a helper class for general UI functions
class UIService {

	constructor($location) {
		'ngInject';

		this.$location = $location;

		let userAgent = navigator.userAgent || navigator.vendor || window.opera;
		this.isiOSDeviceFlag = /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream;
	}

	isMenuOpen (){
		// if we get rid of jQuery, we could try this. see: https://developer.mozilla.org/en-US/docs/Web/API/Element/querySelector
		// return document.querySelector('.btn-group.open') != null;
		return $('.btn-group.open').length > 0;
	}

	// redirects user to the "not authorized" page
	notAuthorized (){
		// we use replace, because otherwise if they press the back button you'll take them back to the same page that they were
		// not authorized to see, when in turn loops them back to the not-authorized page. So by replacing the not authorized page,
		// when they press back it will take them to the previous page they were allowed to see.
		this.$location.path(authModule.routes.notAuthorised).replace();
	}

	isiOSDevice (){
		return this.isiOSDeviceFlag;
	}
}

export default UIService;
