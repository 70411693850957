import styled from '@emotion/styled';
import { customProperties as vars } from '@resi-media/resi-ui';

const PasswordStrengthWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const PasswordStrengthSegment = styled.div`
  height: 4px;
  width: 24.75%;
`;

const PasswordStrengthDescriptor = styled.p`
  margin: 0;
  text-align: right;
  font-size: ${vars.fontSize10};
  line-height: 15px;
`;

interface TextProps {
  fontSize: string;
  isBold?: boolean;
  isUppercase?: boolean;
}

const Text = styled.span<TextProps>`
  font-size: ${({ fontSize }): string => fontSize};
  font-weight: ${({ isBold }): string => (isBold ? 'bold' : 'normal')};
  text-transform: ${({ isUppercase }): string => (isUppercase ? 'uppercase' : 'none')};
  margin: 0;
`;

const ToolTipText = styled.p`
  width: 176px;
  white-space: normal;
`;

export { PasswordStrengthWrapper, PasswordStrengthSegment, PasswordStrengthDescriptor, Text, ToolTipText };
