import styled from '@emotion/styled';
import { customProperties as vars } from '@resi-media/resi-ui';

export const DoughnutChartContent = styled.div`
  width: 99%;
  position: relative;
  height: 100%;
  max-height: 305px;
  max-width: 305px;
`;

export const DoughnutChartCanvas = styled.canvas``;

export const DoughnutChartLegendsWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  list-style: none;
  padding-inline-start: ${vars.spacingM};
  margin-right: ${vars.spacingL};
  width: 200px;
`;

export const DoughnutChartLegendList = styled.ul`
  display: flex;
  flex-direction: row;
  width: ${vars.spacingXl};
`;

export const DoughnutChartWrapper = styled.div<{ isLegendOnRightSide?: boolean }>`
  width: 90%;
  height: 100%;
  display: flex;
  flex-direction: ${({ isLegendOnRightSide }) => (isLegendOnRightSide ? 'row' : 'column')};
  justify-content: space-between;
  align-items: center;
`;
