import { api } from '../../jcs';
import { ReportTypeEnum, WebEvent, WebEventSummary, User } from './index';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import CsvService from '../../services/csv.service';
import { MPEventName, MPEventProperty, trackMixpanelEvent } from '../../../../src/mixpanel';

class AnalyticsCsvButtonCtrl {
  public buttonText: string;
  public user: User;
  public eventId: string;
  public reportType: ReportTypeEnum;
  public errorCallbackFn: (any) => void;
  public loading = false;
  private events: WebEvent[] = [];
  private maxPageSize: number;

  constructor(private CsvService: CsvService, private $http: ng.IHttpService) {
    'ngInject';
  }

  public $onInit(): void {
    this.maxPageSize = this.maxPageSize || 500;
  }

  public onClickHandler(): void {
    if (this.loading) {
      // ignore muliple click events if we are already working on the csv
      return;
    }
    this.events = [];
    this.loading = true;
    if (this.reportType === ReportTypeEnum.DETAIL) {
      this.processDetailReport();
      trackMixpanelEvent(MPEventName.DETAILED_REPORT_DOWNLOAD, {[MPEventProperty.TRANSCODED_EVENT_UUID]: this.eventId})
    } else {
      this.processSummaryReport();
      trackMixpanelEvent(MPEventName.SUMMARY_REPORT_DOWNLOAD, {[MPEventProperty.TRANSCODED_EVENT_UUID]: this.eventId})
    }
  }

  private processDetailReport(): void {
    this.fetchDataPage()
      .then(() => {
        const fields = this.filterFields(this.events[0]);
        return this.generateCsv(this.events, { fields });
      })
      .then((csv) => {
        const url = window.URL.createObjectURL(new Blob([csv]));
        const link = document.createElement('a');
        link.href = url;
        document.body.appendChild(link);
        link.setAttribute('download', 'event_detailed_report.csv');
        link.click();
        link.remove();
        if (url) {
          window.URL.revokeObjectURL(url);
        }
      })
      .catch((error) => this.handleError(error))
      .finally(() => (this.loading = false));
  }

  private processSummaryReport(): void {
    this.fetchWebEventSummary()
      .then((data) => this.generateCsv(data))
      .then((csv) => {
        const url = window.URL.createObjectURL(new Blob([csv]));
        const link = document.createElement('a');
        link.href = url;
        document.body.appendChild(link);
        link.setAttribute('download', 'event_summary_report.csv');
        link.click();
        link.remove();
        if (url) {
          window.URL.revokeObjectURL(url);
        }
      })
      .catch((error) => this.handleError(error))
      .finally(() => (this.loading = false));
  }

  private fetchDataPage(offset?: string): ng.IPromise<WebEvent[]> {
    return this.fetchWebEventDetails(offset).then((webEvents) => {
      if (webEvents.length === this.maxPageSize) {
        this.events = [...this.events, ...webEvents];
        const event: WebEvent = webEvents[webEvents.length - 1];
        return this.fetchDataPage(event.clientId);
      } else {
        this.events = [...this.events, ...webEvents];
      }
    });
  }

  private handleError(error: unknown): void {
    console.log(error);
    if (this.errorCallbackFn) {
      this.errorCallbackFn({
        error:
          'An error occurred while attempting to download the requested report. Please try again, or report the problem if it persists.',
      });
    }
  }

  private generateCsv(jsonData: unknown, config?: unknown): Promise<string> {
    return this.CsvService.generateCsvFromJson(jsonData, config);
  }

  private filterFields(event): string[] {
    if (event) {
      return Object.getOwnPropertyNames(event).filter((key) => {
        if (key !== 'eventId' && key !== 'clientId') {
          return key;
        }
      });
    } else {
      return [];
    }
  }

  private fetchWebEventDetails(offset?: string): ng.IPromise<WebEvent[]> {
    return this.$http
      .get<WebEvent[]>(`${api.url_v3}/customers/${this.user.customerID}/webevents/${this.eventId}/export`, {
        withCredentials: true,
        params: { max: this.maxPageSize, offset },
      })
      .then((response) => response.data);
  }

  private fetchWebEventSummary(): ng.IPromise<WebEventSummary> {
    return this.$http
      .get<WebEventSummary>(
        `${api.url_v3}/customers/${this.user.customerID}/webevents/${this.eventId}/export/statistics`,
        {
          withCredentials: true,
        }
      )
      .then((response) => response.data);
  }
}

export default class AnalyticsCsvButtonComponent implements ng.IComponentOptions {
  public template = require('./analyticsCsvButton.html').default; // eslint-disable-line  @typescript-eslint/no-var-requires
  public controller = AnalyticsCsvButtonCtrl;
  public bindings = {
    buttonText: '@',
    user: '<',
    eventId: '<',
    reportType: '@',
    maxPageSize: '@',
    errorCallbackFn: '&',
  };
}
