import HelpCenterComponent from './helpCenter.component';

export const helpCenterModule = {
	name: 'helpCenterComponent',
	component: {
		name: 'helpCenter',
	},
};

export default angular
	.module(helpCenterModule.name, [])
	.component(helpCenterModule.component.name, new HelpCenterComponent()).name;
