import styled from '@emotion/styled';
import { customProperties as vars } from '@resi-media/resi-ui';
import { BORDER_GREY, BRAND_NAVY_COLOR } from '../../event-analytics-constants';

export const EventStatsGraphWrapper = styled.div<{ hasGraphPerms: boolean }>`
  height: ${({ hasGraphPerms }) => (hasGraphPerms ? '600px' : '100px')};
  padding: ${vars.spacingL} 3rem;
`;

export const EventStatsHeaderDiv = styled.div`
  display: grid;
  grid-template-columns: 4fr 5fr 3fr;
  gap: ${vars.spacingM};
`;

export const DateControlsDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const TabsDiv = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: ${vars.fontSize12};
  margin: ${vars.spacingM} 0;
`;

export const GraphWrapper = styled.div`
  height: 510px;
`;

export const ChartControls = styled.div`
  max-width : ${vars.constraintS};
`;

export const dropDownStyleProps = {
  width: '9em',
  borderRadius: '2px',
  color: BRAND_NAVY_COLOR,
  border: `1px solid ${BORDER_GREY}`,
  marginTop: '0.25em',
};

export const DropDownWrapper = styled.div`
  margin: 0.25em 0px 0px !important;
`;
