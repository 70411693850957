export const COLOR_SET_SOURCE = {
  UI_POSITIVE1: {
    baseColor: '#389E0D',
    fillColor: 'rgba(209,239,205,1)',
    borderColor: '#389E0D',
  },
  UI_WARNING1: {
    baseColor: '#FD973A',
    fillColor: 'rgba(255,235,219,1)',
    borderColor: '#FD973A',
  },
  BRAND_NAVY: {
    baseColor: '#5E7CA7',
    fillColor: '#DFE5EE',
    borderColor: '#5E7CA7',
  },
  UI_ERROR: {
    baseColor: '#CD4631',
    fillColor: '#FFCCC5',
    borderColor: '#CD4631',
  },
  CHART_YELLOW: {
    baseColor: '#EDD70C',
    fillColor: '#FCF3A5',
    borderColor: '#EDD70C',
  },
  CYAN7: {
    baseColor: '#08979C',
    fillColor: '#95F0F3',
    borderColor: '#08979C',
  },
  CHART_VIOLET: {
    baseColor: '#583296',
    fillColor: '#B994F9',
    borderColor: '#583296',
  },
  BRAND_DEEP_NAVY: {
    baseColor: '#253746',
    fillColor: '#2369A3',
    borderColor: '#253746',
  },
  CHART_PINK: {
    baseColor: '#B0327B',
    fillColor: '#F6A1D1',
    borderColor: '#B0327B',
  },
  DAY_BREAK_BLUE6: {
    baseColor: '#459BBF',
    fillColor: '#AAE1F7',
    borderColor: '#459BBF',
  },
  CHART_BLUE: {
    baseColor: '#425FD8',
    fillColor: '#6B84E9',
    borderColor: '#425FD8',
  },
  GRAY8: {
    baseColor: '#595959',
    fillColor: '#C1C1C1',
    borderColor: '#595959',
  },
  CHART_GREEN: {
    baseColor: '#0F7141',
    fillColor: '#90F7C4',
    borderColor: '#0F7141',
  },
  CHART_GOLD: {
    baseColor: '#C09E46',
    fillColor: '#EDDBAF',
    borderColor: '#C09E46',
  },
  CHART_BASE_BROWN: {
    baseColor: '#BEBEBE',
    fillColor: '#D9D7D7',
    borderColor: '#BEBEBE',
  },
  UI_WARNING2: {
    baseColor: '#B95E06',
    fillColor: '#FDDCBE',
    borderColor: '#B95E06',
  },
  NAVY_TINT4: {
    baseColor: '#7F96B4',
    fillColor: '#D4DCE6',
    borderColor: '#7F96B4',
  },
  CHART_TILE_RED: {
    baseColor: '#914135',
    fillColor: '#F3D1CC',
    borderColor: '#914135',
  },
  CHART_BLOOM_GREEN: {
    baseColor: '#7AC250',
    fillColor: '#DFF2D3',
    borderColor: '#7AC250',
  },
  CHART_VIOLET2: {
    baseColor: '#AE62C1',
    fillColor: '#A5DD82',
    borderColor: '#AE62C1',
  },
  CHART_EARTH_GREEN: {
    baseColor: '#465700',
    fillColor: '#708900',
    borderColor: '#465700',
  },
  CHART_DULL_YELLOW: {
    baseColor: '#CDE07F',
    fillColor: '#C0CB8F',
    borderColor: '#CDE07F',
  },
  CHART_FADE_RED: {
    baseColor: '#CBA49E',
    fillColor: '#CDB0AC',
    borderColor: '#CBA49E',
  },
  CHART_LAVENDER: {
    baseColor: '#CFBDEC',
    fillColor: '#D3C6EB',
    borderColor: '#CFBDEC',
  },
  CHART_SKY: {
    baseColor: '#C3CDF5',
    fillColor: '#D3D9F2',
    borderColor: '#C3CDF5',
  },
};
