import { SOCIAL_MEDIA_TYPE_FACEBOOK, SOCIAL_MEDIA_TYPE_YOUTUBE } from '../constants';
import { youtubeClientId, youtubeClientSecret } from '../jcs';

class SocialMediaAuthService {
	constructor($window, $location) {
		'ngInject';

		this.$window = $window;
		this.$loction = $location;

		this.YT_COOKIE_PATH = 'la1-youtube-redirect';
		this.YT_STATE_FOR_NEW_ACCT = 'addYTAuthCodeRedirect'; // we are adding a new youtube account to control
		this.YT_STATE_FOR_EXISTING_ACCT = 'updateYTAuthCodeRedirect'; // we are updating an existing account in control
		this.UPDATE_DELIMITER = '__'; // delimiter in state before UUID

		this.FB_COOKIE_PATH = 'la1-facebook-redirect';
		this.FB_STATE_FOR_NEW_ACCT = 'addFBAuthCodeRedirect'; // we are adding a new facebook account to control
		this.FB_STATE_FOR_EXISTING_ACCT = 'updateFBAuthCodeRedirect'; // we are updating an existing account in control

		this.FB_CLIENT_ID = '363573127593481';

		this.query_string = null;

		this.FB_AUTH_CODE_URL = 'https://www.facebook.com/v3.2/dialog/oauth';

		this.YT_AUTH_CODE_URL = 'https://accounts.google.com/o/oauth2/v2/auth';
		this.YOUTUBE_REFRESH_AND_ACCESS_TOKEN_URL = 'https://www.googleapis.com/oauth2/v4/token';
	}

	init() {
		this.query_string = null;
	}

	// determine if we have been redirected from YouTube. Our URL will look like this:
	// http://.../control/web/?state=addAuthCodeRedirect&code=xxxxxxxxxxxxxxxxxxxxxxxxx&scope=https://www.googleapis.com/auth/youtube
	// or
	// http://.../control/web/?state=updateAuthCodeRedirect__xxxxxxxxxxxxxxxxxxxx&code=xxxxxxxxxxxxxxxxxxxxxxxxx&scope=https://www.googleapis.com/auth/youtube
	// if the user decided to cancel, then the URL will look like this:
	// http://.../control/web/?error=access_denied&state=addAuthCodeRedirect
	// or
	// http://.../control/web/?error=access_denied&state=updateAuthCodeRedirect__xxxxxxxxxxxxxxxxxxxx
	isRedirectedFromYoutube() {
		return (
			this.$window.location.search.indexOf(this.YT_STATE_FOR_NEW_ACCT) != -1 ||
			this.$window.location.search.indexOf(this.YT_STATE_FOR_EXISTING_ACCT) != -1
		);
	}

	// determine if we have been redirected from Facebook. Our URL will look like this:
	// http://.../control/web/#access_token=xxxxxx&data_access_expiration_time=1562087092&expires_in=5184000&code=xxxxxx&state=addFBAuthCodeRedirect
	// if the user decides to cancel, then the URL will look like this:
	// http://.../control/web/?error=access_denied&error_code=200&error_description=Permissions+error&error_reason=user_denied&state=addFBAuthCodeRedirect#/login
	isRedirectedFromFacebook() {
		const redirect_success =
			this.$window.location.hash.indexOf(this.FB_STATE_FOR_NEW_ACCT) != -1 ||
			this.$window.location.hash.indexOf(this.FB_STATE_FOR_EXISTING_ACCT) != -1;
		const redirect_failure =
			this.$window.location.search.indexOf(this.FB_STATE_FOR_NEW_ACCT) != -1 ||
			this.$window.location.search.indexOf(this.FB_STATE_FOR_EXISTING_ACCT) != -1;
		return redirect_success || redirect_failure;
	}

	// after the redirect from youtube, our browser URL will have a long query string we need to save and get rid of. So we will save the info
	// to session storage so we can access it after the page reload.
	saveYoutubeInfo() {
		sessionStorage.setItem(this.YT_COOKIE_PATH, this.cleanLocationSearch(this.$window.location.search));
	}

	saveFacebookInfo() {
		// if successful, then info will be in location.hash; if error, then info will be in location.search
		if (
			this.$window.location.hash.indexOf(this.FB_STATE_FOR_NEW_ACCT) != -1 ||
			this.$window.location.hash.indexOf(this.FB_STATE_FOR_EXISTING_ACCT) != -1
		) {
			sessionStorage.setItem(this.FB_COOKIE_PATH, this.cleanLocationHash(this.$window.location.hash));
		} else {
			sessionStorage.setItem(this.FB_COOKIE_PATH, this.cleanLocationSearch(this.$window.location.search));
		}
	}

	// strip "?" off the front of the string
	cleanLocationSearch(search) {
		const PREFIX_TO_REMOVE = '?';
		if (search != null && search.indexOf(PREFIX_TO_REMOVE) == 0) {
			return search.substring(PREFIX_TO_REMOVE.length);
		}
		return search;
	}

	// strips "#/" off the front of the string
	cleanLocationHash(hash) {
		const PREFIX_TO_REMOVE = '#';
		if (hash != null && hash.indexOf(PREFIX_TO_REMOVE) == 0) {
			return hash.substring(PREFIX_TO_REMOVE.length);
		}
		return hash;
	}

	// if we have youtube info stored, then it should mean the page was just reloaded
	hasYoutubeInfo() {
		this.query_string = sessionStorage.getItem(this.YT_COOKIE_PATH);
		return this.query_string != null;
	}

	// if we have facebook info stored, then it should mean the page was just reloaded
	hasFacebookInfo() {
		this.query_string = sessionStorage.getItem(this.FB_COOKIE_PATH);
		return this.query_string != null;
	}

	// need to make sure we clear our youtube info after page reload
	clearYoutubeInfo() {
		sessionStorage.removeItem(this.YT_COOKIE_PATH);
	}

	// need to make sure we clear our facebook info after page reload
	clearFacebookInfo() {
		sessionStorage.removeItem(this.FB_COOKIE_PATH);
	}

	getState() {
		return this.getQueryVariable(this.query_string, 'state');
	}

	getAuthCode() {
		return this.getQueryVariable(this.query_string, 'code');
	}

	getErrorCode() {
		return this.getQueryVariable(this.query_string, 'error');
	}

	getFacebookTokenExpirationTime() {
		return this.getQueryVariable(this.query_string, 'data_access_expiration_time');
	}

	getFacebookClientId() {
		return this.FB_CLIENT_ID;
	}

	getYoutubeClientId() {
		return youtubeClientId;
	}

	getYoutubeClientSecret() {
		return youtubeClientSecret;
	}

	// parses the account UUID from the returned state
	getAccountUUID() {
		const redirect_state = this.getState();
		const delimiter_pos = redirect_state.indexOf(this.UPDATE_DELIMITER);
		return redirect_state.substring(delimiter_pos + this.UPDATE_DELIMITER.length);
	}

	isForNewYoutubeAccount() {
		const redirect_state = this.getState();
		return redirect_state.indexOf(this.YT_STATE_FOR_NEW_ACCT) != -1;
	}

	isForExistingYoutubeAccount() {
		const redirect_state = this.getState();
		return redirect_state.indexOf(this.YT_STATE_FOR_EXISTING_ACCT) != -1;
	}

	isForExistingFacebookAccount() {
		const redirect_state = this.getState();
		return redirect_state.indexOf(this.FB_STATE_FOR_EXISTING_ACCT) != -1;
	}

	getStateForNewYTAccount() {
		return this.YT_STATE_FOR_NEW_ACCT;
	}

	getStateForNewFBAccount() {
		return this.FB_STATE_FOR_NEW_ACCT;
	}

	getStateForExistingYTAccount(uuid) {
		return `${this.YT_STATE_FOR_EXISTING_ACCT}${this.UPDATE_DELIMITER}${uuid}`;
	}

	getStateForExistingFBAccount(uuid) {
		return `${this.FB_STATE_FOR_EXISTING_ACCT}${this.UPDATE_DELIMITER}${uuid}`;
	}

	getFacebookOAuthData() {
		return {
			access_token: this.getQueryVariable(this.query_string, 'access_token'),
			code: this.getQueryVariable(this.query_string, 'code'),
		};
	}

	getYoutubeOAuthData() {
		return {
			client_id: this.getYoutubeClientId(),
			client_secret: this.getYoutubeClientSecret(),
			grant_type: 'authorization_code',
			redirect_uri: `${this.$window.location.origin}`,
			code: decodeURIComponent(this.getAuthCode()),
		};
	}

	getFacebookAddAccessUrl() {
		const search_queries = [
			`client_id=${this.getFacebookClientId()}`,
			'response_type=code%20token',
			'scope=email,publish_to_groups,publish_video,pages_manage_posts,read_insights,pages_show_list,pages_manage_metadata,pages_read_engagement,pages_read_user_content,pages_manage_engagement,pages_manage_ads',
			// 'scope=manage_pages,publish_pages,publish_to_groups,publish_video,read_insights',
//			'scope=manage_pages,publish_pages,publish_to_groups,user_events,publish_video',
			`state= + ${this.getStateForNewFBAccount()}`,
			`redirect_uri=${this.$window.location.origin}`,
		];

		return `${this.FB_AUTH_CODE_URL}?${search_queries.join('&')}`;
	}

	// returns the link used to get youtube access for a NEW account
	getYoutubeAddAccessUrl() {
		const search_queries = [
			`client_id=${this.getYoutubeClientId()}`,
			'response_type=code',
			'access_type=offline',
			'prompt=consent',
			'scope=https://www.googleapis.com/auth/youtube',
			`state=${this.getStateForNewYTAccount()}`,
			`redirect_uri=${this.$window.location.origin}`,
		];

		return `${this.YT_AUTH_CODE_URL}?${search_queries.join('&')}`;
	}

	// returns the link used to grant access to an EXISTING account
	getGrantAccessUrl(type, id) {
		if (type == SOCIAL_MEDIA_TYPE_YOUTUBE) {
			const search_queries = [
				`client_id=${this.getYoutubeClientId()}`,
				'response_type=code',
				'access_type=offline',
				'prompt=consent',
				'scope=https://www.googleapis.com/auth/youtube',
				`state=${this.getStateForExistingYTAccount(id)}`,
				`redirect_uri=${this.$window.location.origin}`,
			];
			return this.YT_AUTH_CODE_URL + '?' + search_queries.join('&');
		} else if (type == SOCIAL_MEDIA_TYPE_FACEBOOK) {
			var search_queries = [
				`client_id=${this.getFacebookClientId()}`,
				'response_type=code%20token',
				'scope=email,publish_to_groups,publish_video,pages_manage_posts,read_insights,pages_show_list,pages_manage_metadata,pages_read_engagement,pages_read_user_content,pages_manage_engagement,pages_manage_ads',
				`state=${this.getStateForExistingFBAccount(id)}`,
				`redirect_uri=${this.$window.location.origin}`,
			];
			return this.FB_AUTH_CODE_URL + '?' + search_queries.join('&');
		}
		return '';
	}

	getQueryVariable(search_string, variable) {
		const vars = search_string.split('&');
		for (let i = 0; i < vars.length; i++) {
			const pair = vars[i].split('=');
			if (pair[0] == variable) {
				return pair[1];
			}
		}
		return false;
	}
}

export default SocialMediaAuthService;
