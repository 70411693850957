import React, { FC, useEffect, useRef } from 'react';
import { ChartTypeRegistry, TooltipItem } from 'chart.js';
import Chart from 'chart.js/auto';
import startCase from 'lodash/startCase';
import PropTypes from 'prop-types';
import { barChartDataType } from '../../../pages/EventAnalytics/types';
import { legendItemType } from '../types';
import { BarChartLegendList, BarChartLegendWrapper } from './bar-chart.styles';
import {
  BarChartContainer,
  FixedYAxis,
  scrollableBarChartBoxSpanStyle,
  ScrollChartAreaWrapper,
  ScrollChartWrapper,
} from './scrollable-bar.styles';
import { calculateWidth, getBarChartProps, getStackedBarChartProps } from './utils';

type BarChartProps = {
  data: barChartDataType;
  label?: string;
  stacked?: boolean;
  tooltipLabel: string;
};

const ScrollableBarChart: FC<BarChartProps> = ({ data, label, stacked, tooltipLabel }): JSX.Element => {
  const { labels, datasets } = data;
  const updatedData = { labels, datasets };
  const fixedYAxisChartData = stacked ? getStackedBarChartProps(updatedData, true) : getBarChartProps(updatedData, true, label);
  const chartData = stacked ? getStackedBarChartProps(updatedData, false) : getBarChartProps(updatedData, false, label);
  const scrollableBarChartRef = useRef<Chart>(null);
  const scrollableBarChartObjectChartRef = useRef<Chart>(null);
  const fixedYAxisRef = useRef<Chart>(null);
  const fixedYAxisObjectRef = useRef<Chart>(null);
  const scrollableBarChartLegendRef = useRef<HTMLUListElement>(null);

  const updateDataSetOnLegendClick = (datasetIndex: number) => {
    const { current: fixedYAxisChart } = fixedYAxisObjectRef;
    const { current: scrollableBarChart } = scrollableBarChartObjectChartRef;
    fixedYAxisChart.setDatasetVisibility(datasetIndex, !fixedYAxisChart.isDatasetVisible(datasetIndex));
    scrollableBarChart.setDatasetVisibility(datasetIndex, !scrollableBarChart.isDatasetVisible(datasetIndex));
    fixedYAxisChart.update();
    scrollableBarChart.update();
  };

  const createFixedYAxisChart = () => {
    return new Chart(fixedYAxisRef.current, {
      data: fixedYAxisChartData,
      type: 'bar',
      options: {
        maintainAspectRatio: false,
        animation: false,
        responsive: false,
        elements: {
          line: {
            fill: false,
          },
        },
        scales: {
          y: {
            type: 'linear',
            display: true,
            stacked: stacked,
            grid: {
              display: true,
            },
            ticks: {
              display: true,
              beginAtZero: true,
              precision: 0,
            },
          },
          x: {
            max : 100,
            display: true,
            grid: {
              display: false,
            },
            stacked: stacked,
            ticks: {
              color: 'rgba(255, 255, 255, 0)',
              beginAtZero: true,
            },
          },
        },
        plugins: {
          legend: {
            display: false,
          },
        },
      },
      plugins: [htmlLegendPluginYaxis],
    });
  };

  const createScrollableChart = (): Chart => {
    return new Chart(scrollableBarChartRef.current, {
      data: chartData,
      type: 'bar',
      options: {
        maintainAspectRatio: false,
        interaction: {
          mode: 'point',
        },
        animation: false,
        responsive: false,
        elements: {
          line: {
            fill: false,
          },
        },
        scales: {
          y: {
            type: 'linear',
            display: true,
            stacked: stacked,
            grid: {
              display: false,
              drawBorder: false,
            },
            ticks: {
              color: 'rgba(255, 255, 255, 0)',
              display: true,
              beginAtZero: true,
              precision: 0,
            },
          },
          x: {
            max : 100,
            grid: {
              display: false,
            },
            stacked: true,
            ticks: {
              fontFamily: 'Verdana',
              autoSkip: true,
              beginAtZero: true,
            },
          },
        },
        plugins: {
          barChartScrollableLegend: {
            // ID of the container to put the legend in
            containerID: 'scrollable-bar-chart-legend-container',
          },
          legend: {
            display: false,
          },
          tooltip: {
            enabled: true,
            position: 'nearest',
            callbacks: {
              label: (tooltipItem: TooltipItem<keyof ChartTypeRegistry>) => {
                return ` ${startCase(tooltipItem.dataset.label) || ''} : ${
                  tooltipItem.dataset.data[tooltipItem.dataIndex]
                } ${tooltipLabel}`;
              },
            },
          },
        },
      },
      plugins: [htmlLegendPlugin],
    });
  };

  const htmlLegendPlugin = {
    id: 'barChartScrollableLegend',
    afterUpdate(chart: Chart) {
      const ul = scrollableBarChartLegendRef.current;
      if (!ul) {
        return;
      }
      ul.style.flexWrap = 'wrap';
      ul.style.justifyContent = 'space-between';

      // Remove old legend items
      while (ul.firstChild) {
        ul.firstChild.remove();
      }
      // Reuse the built-in legendItems generator
      const items = chart.options.plugins.legend.labels.generateLabels(chart);

      items.forEach((item: legendItemType, index: number) => {
        const li = document.createElement('li');
        li.style.alignItems = 'center';
        li.style.cursor = 'pointer';
        li.style.display = 'flex';
        li.style.flexDirection = 'row';
        li.style.marginLeft = '10px';

        li.onclick = () => {
          updateDataSetOnLegendClick(item.datasetIndex);
        };

        // Color box
        const boxSpan = document.createElement('span');
        boxSpan.style.background = chartData.datasets[index].borderColor;
        boxSpan.style.borderColor = item.strokeStyle;
        boxSpan.style.borderWidth = item.lineWidth + 'px';
        boxSpan.style.display = scrollableBarChartBoxSpanStyle.display;
        boxSpan.style.height = scrollableBarChartBoxSpanStyle.height;
        boxSpan.style.marginRight = scrollableBarChartBoxSpanStyle.marginHight;
        boxSpan.style.width = scrollableBarChartBoxSpanStyle.width;
        boxSpan.style.opacity = item.hidden ? '0.4' : '1';
        boxSpan.style.borderRadius = scrollableBarChartBoxSpanStyle.borderRadius;

        // Text
        const textContainer = document.createElement('p');
        textContainer.style.color = item.fontColor;
        textContainer.style.fontWeight = 'bold';
        textContainer.style.margin = '0 0 0 5px';
        textContainer.style.padding = '0';
        textContainer.style.textDecoration = item.hidden ? 'line-through' : '';
        textContainer.style.opacity = item.hidden ? '0.4' : '1';

        const text = document.createTextNode(startCase(item.text));
        textContainer.appendChild(text);

        li.appendChild(boxSpan);
        li.appendChild(textContainer);
        ul.appendChild(li);
      });
    },
  };

  const htmlLegendPluginYaxis = {
    id: 'fixedYAxisPluginId',
    afterUpdate(chart: Chart) {
      fixedYAxisObjectRef.current = chart;
    },
  };

  useEffect(() => {
    if (fixedYAxisRef.current !== null && scrollableBarChartRef.current !== null) {
      fixedYAxisObjectRef.current?.destroy();
      fixedYAxisObjectRef.current = createFixedYAxisChart();
      scrollableBarChartObjectChartRef.current?.destroy();
      scrollableBarChartObjectChartRef.current = createScrollableChart();
    }
  }, [
    fixedYAxisRef,
    scrollableBarChartRef,
    fixedYAxisRef.current,
    scrollableBarChartRef.current,
    chartData,
    fixedYAxisChartData,
  ]);

  return (
    <ScrollChartWrapper>
      <ScrollChartAreaWrapper>
        <FixedYAxis>
          <canvas height={350} width={calculateWidth(data)} ref={fixedYAxisRef} id="fixedYAxis-bar-chart"></canvas>
        </FixedYAxis>
        <BarChartContainer>
          <canvas
            height={350}
            width={calculateWidth(data)}
            ref={scrollableBarChartRef}
            id="scrollableBarChartRef-bar-chart"
          ></canvas>
        </BarChartContainer>
      </ScrollChartAreaWrapper>
      <BarChartLegendWrapper>
        <BarChartLegendList ref={scrollableBarChartLegendRef} />
      </BarChartLegendWrapper>
    </ScrollChartWrapper>
  );
};

ScrollableBarChart.propTypes = {
  data: PropTypes.shape({
    labels: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    datasets: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        data: PropTypes.arrayOf(PropTypes.number.isRequired),
      })
    ),
  }),
  tooltipLabel: PropTypes.string.isRequired,
  stacked: PropTypes.bool,
};

ScrollableBarChart.displayName = 'ScrollableBarChart';

export default ScrollableBarChart;
