import React, { FC } from 'react';
import PropTypes from 'prop-types';
import ProgressBar from '../ProgressBar';
import './style.scss';

interface UploadProgressToastProps {
  progressPercent?: number;
  progressState?: UploadProgress;
  progressText?: string;
  errorMessage?: string;
  color?: string;
  finishedLink?: string;
}

export enum UploadProgress {
  IN_PROGRESS,
  FINISHED,
  FAILED,
}

const ERROR_COLOR = '#D33724';

const UploadProgressToast: FC<UploadProgressToastProps> = ({ progressPercent = 0, color = "#008D4C", progressState = UploadProgress.FAILED, progressText = '', errorMessage = '', finishedLink }): JSX.Element => {

  function toastContent(): JSX.Element {
    switch (progressState) {
      case UploadProgress.IN_PROGRESS:
        return (
          <>
            <div className="la1-upload-progress-toast__progress-label" data-testid="in-progress-text">Progress (Processing)</div>
            <div className="la1-upload-progress-toast__flex-load-bar">
              <ProgressBar progressUpdate={progressPercent} color={color} />
              <div className="la1-upload-progress-toast__progress-test">{progressText}</div>
            </div>
          </>);
      case UploadProgress.FINISHED:
        return <div className="la1-upload-progress-toast__progress-label" data-testid="finished-progress-txt">Finished Processing {finishedLink && (<a href={finishedLink}>See in Events List</a>)}</div>;
      default:
        return (
          <>
            <div className="la1-upload-progress-toast__progress-label" data-testid="error-upload-progress-txt">Processing Failed</div>
            <p className="la1-upload-progress-toast__error-message">{errorMessage}</p>
          </>)
    }
  }

  return (
    <div className="la1-upload-progress-toast">
      <div className="la1-upload-progress-toast__left-side" style={{ backgroundColor: progressState === UploadProgress.FAILED ? ERROR_COLOR : color }} data-testid="left-border" />
      <div className="la1-upload-progress-toast__inner">
        <h3 className="la1-upload-progress-toast__header">Encoder Name Upload</h3>
        {toastContent()}
      </div>
    </div>
  )
}


UploadProgressToast.propTypes = {
  progressPercent: PropTypes.number,
  progressState: PropTypes.number,
  progressText: PropTypes.string,
  errorMessage: PropTypes.string,
  color: PropTypes.string,
  finishedLink: PropTypes.string,
};

UploadProgressToast.displayName = 'UploadProgressToast';

export default UploadProgressToast;