import React, { FC } from 'react';
import PropTypes from 'prop-types';
import { Paper } from '@material-ui/core';
import './status-card.styles.ts';
import { StatusCardProps } from './types';
import { CaretDownFilled, CaretUpFilled } from '@ant-design/icons';
import { CountWrapper, StatusCardContentWrapper } from './status-card.styles';
import { Grid, Text } from '@resi-media/resi-ui';
import { spaceCamel } from '../../pages/EventAnalytics/utils';
import { COLOR_SET_SOURCE } from '../../pages/EventAnalytics/color-set';

const StatusCard: FC<StatusCardProps> = ({ label, count, change, isTrendUp }: StatusCardProps) => {
  const camelCaseLabel = spaceCamel(String(label));
  return (
    <Paper elevation={0} variant="outlined" data-testid="status-card">
      <StatusCardContentWrapper>
        <Grid display="grid" style={{ height: '100%' }}>
          <Grid display="grid" justifyContent="start">
            <Text.Body isUppercase isBold size="xs" color="secondary" data-testid="card-label">
              {camelCaseLabel}
            </Text.Body>
            <Text.Heading type="h2">
              <CountWrapper data-testid="card-count"> {count}</CountWrapper>
            </Text.Heading>
          </Grid>
          <Grid display="grid" alignItems="end" justifyContent="end">
            {change ? (
              <Text.Body>
                {isTrendUp ? (
                  <>
                    <span data-testid="card-percentage" style={{ color: COLOR_SET_SOURCE.UI_POSITIVE1.baseColor }}>
                      {`${Math.abs(change)}%`}
                    </span>
                    <CaretUpFilled style={{ color: COLOR_SET_SOURCE.UI_POSITIVE1.baseColor }} width={12} />
                  </>
                ) : (
                  <>
                    <span data-testid="card-percentage" style={{ color: COLOR_SET_SOURCE.UI_ERROR.baseColor }}>
                      {`${Math.abs(change)}%`}
                    </span>
                    <CaretDownFilled style={{ color: COLOR_SET_SOURCE.UI_ERROR.baseColor }} width={12} />
                  </>
                )}
              </Text.Body>
            ) : null}
          </Grid>
        </Grid>
      </StatusCardContentWrapper>
    </Paper>
  );
};

StatusCard.propTypes = {
  label: PropTypes.string.isRequired,
  count: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  change: PropTypes.number.isRequired,
  isTrendUp: PropTypes.bool.isRequired,
};

StatusCard.displayName = 'StatusCard';

export default StatusCard;
