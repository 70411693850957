import { getStartedModule } from './index';

function routeConfig($provide, $routeProvider) {
  'ngInject';
  $provide.factory('$routeProvider', () => $routeProvider);
  const defaultOptions = {
    controllerAs: '$ctrl',
    controller: getStartedModule.controllers.default,
    template: require('./getStarted.tmpl.html').default,
    access: { loginRequired: false },
  };
  $routeProvider.when(getStartedModule.routes.emailVerification, defaultOptions);

  $routeProvider.when(getStartedModule.routes.finishSetup, {
      controllerAs: '$ctrl',
      controller: getStartedModule.controllers.default,
      template: require('./getStarted.tmpl.html').default,
      access: { loginRequired: true },
  });
}

export default routeConfig;