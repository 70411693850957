import DateComponent from './date.component';

export const dateModule = {
	name: 'dateComponent',
	component: {
		name: 'date'
	}
};

export default angular
	.module(dateModule.name, [])
	.component(dateModule.component.name, new DateComponent()).name;
