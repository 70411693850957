import styled from '@emotion/styled';
import { customProperties as vars } from '@resi-media/resi-ui';
import { FOOTER_VERTICAL_PADDING } from '../ResiFooter/style';

// label targeted to undo bootstrap styles in control
export const LayoutWrapper = styled.div`
  font-size: 16px;

  label {
    font-weight: 600;
    margin-bottom: 0;
    display: inline-block;
  }
`;

export const FooterDownWrap = styled.div`
  min-height: calc(97vh - ${FOOTER_VERTICAL_PADDING});
`;

export const ResiLogoWrapper = styled.div`
  width: 140px;
  margin: auto;
  padding-top: 2em;

  @media(min-width: ${vars.breakPointTablet}) {
    width: 264px;
  }
`;
