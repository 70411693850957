import { api } from '../jcs';
import HttpService from './http.service';

interface EncoderInfo {
  model: string;
}

class EncoderService {
  private map = {};
  private versionMap = {};
  private isLoading = false;

  private SOFTWARE_ENCODER_MODEL = 'softwareUploader';

  // minimum encoder version with MQTT
  private minEncoderVersion = ['1', '8', '0', '26'];

  constructor(private httpService: HttpService, private $q: ng.IQService) {
    'ngInject';
  }

  public isLoadingEncoder(): boolean {
    return this.isLoading;
  }

  public isSoftwareEncoder(encoder: EncoderInfo): boolean {
    return encoder?.model === this.SOFTWARE_ENCODER_MODEL;
  }

  public getEncoder(uuid: string): ng.IPromise<any> {
    // do we already have the info in our cache?
    if (Object.prototype.hasOwnProperty.call(this.map, uuid)) {
      return this.$q.resolve(this.map[uuid]);
    } else {
      this.isLoading = true;

      return this.httpService
        .get(`${api.url}/encoders/${uuid}`, { withCredentials: true })
        .then((response) => {
          this.map[uuid] = response.data;
          return response.data;
        })
        .finally(() => (this.isLoading = false));
    }
  }

  // results are not cached because status returns info like sensor and cpu data that changes frequently
  public getEncoderStatus(uuid: string): ng.IPromise<{ encoderVersion: string }> {
    return this.httpService
      .get(`${api.url}/encoders/${uuid}/status`, { withCredentials: true })
      .then((response) => {
        return response.data;
      })
      .finally(() => (this.isLoading = false));
  }

  public getEncoderVersion(uuid: string): ng.IPromise<any> {
    // do we already have the info in our cache?
    if (Object.prototype.hasOwnProperty.call(this.versionMap, uuid)) {
      return this.$q.resolve(this.versionMap[uuid]);
    }
    return this.getEncoderStatus(uuid).then((response) => {
      this.versionMap[uuid] = response.encoderVersion;
      return response.encoderVersion;
    });
  }

  public isEncoderUpdateRequired(encoders: any[]): boolean {
    const minEncoderVersion = this.minEncoderVersion;
    const encoderVersions = encoders.map((encoder) => {
      if (encoder.model === this.SOFTWARE_ENCODER_MODEL || !encoder.encoderVersion) return '';
      return encoder.encoderVersion?.split('.');
    });

    const versionIsTooOld = (encoderVersion) => {
      for (let i = 0; i < minEncoderVersion.length; i++) {
        const a = Number(minEncoderVersion[i]);
        const b = Number(encoderVersion?.[i]);
        if (a > b) return true;
        if (a < b) return false;
      }
      return false;
    };
    return encoderVersions.some((value) => versionIsTooOld(value));
  }
}

export default EncoderService;
