import { perms } from '../../jcs';
import { unregisteruploaderModule } from './index';

function routeConfig($provide, $routeProvider) {
	'ngInject';

	$provide.factory('$routeProvider', () => {
		return $routeProvider;
	});

	const default_options = {
		controller: unregisteruploaderModule.controllers.default,
		template: require('./unregisteruploader.tmpl.html').default,
		activenav: unregisteruploaderModule.name,
		access: {
			loginRequired: false,
		},
	};

	$routeProvider.when(unregisteruploaderModule.routes.login, default_options);
	$routeProvider.when(unregisteruploaderModule.routes.success, default_options);
}

export default routeConfig;
