import axios from 'axios';
import {
  baseURL,
  centralBaseURL,
  fixedDateRangeValues,
  MAX_FETCH_LIMIT_FOR_CSV_EXPORT,
  socialAnalyticsProxyUrl,
  TIME_WINDOW_VALUES,
} from '../event-analytics-constants';
import { EventAnalyticsState } from '../../../contexts/eventanalytics/event-analytics';
import { API_RESPONSE_TYPE, BracketReference, CsvDataObject } from '../types';
import { DateTime, DurationObjectUnits } from 'luxon';
import { dateTimeFormatUTC } from '../date-range-options';
import { YoutubeContextType } from '../../../contexts/eventanalytics/youtube';

export const fetchLineChartData = async (
  EventAnalyticsState: EventAnalyticsState,
  timeWindowValue: string,
  channelId?: string
): Promise<API_RESPONSE_TYPE> => {
  const {
    customerId,
    token,
    eventId,
    dateRange: { startDate, endDate },
  } = EventAnalyticsState;
  const pathValue = eventId !== null ? 'liveViews' : 'views';
  timeWindowValue = eventId !== null ? TIME_WINDOW_VALUES.MINUTE : timeWindowValue;
  const url = channelId
    ? `${baseURL}/${customerId}/youTube/statistics/${channelId}/${timeWindowValue}/${pathValue}`
    : `${baseURL}/${customerId}/youTube/statistics/${timeWindowValue}/${pathValue}`;

  try {
    const { data } = await axios.get(url, {
      headers: token,
      params: {
        startDate: DateTime.fromISO(startDate).startOf('day').toFormat(dateTimeFormatUTC),
        endDate: DateTime.fromISO(endDate).endOf('day').toFormat(dateTimeFormatUTC),
        ...(eventId && { eventId }),
      },
    });

    let i = DateTime.fromISO(startDate).startOf(timeWindowValue as keyof DurationObjectUnits);
    let iFormatted = i.toFormat(timeWindowValue === 'day' ? `yyyy-MM-dd HH:mm:ss` : `yyyy-MM-dd`);
    const sanitizedData = [];

    do {
      sanitizedData.push(
        data.dates.find((d: { date: string }) => d.date === iFormatted) ?? {
          date: iFormatted,
        }
      );
      i = i.plus({ [`${timeWindowValue}s`]: 1 });
      iFormatted = i.toFormat(timeWindowValue === 'day' ? `yyyy-MM-dd HH:mm:ss` : `yyyy-MM-dd`);
    } while (
      timeWindowValue !== TIME_WINDOW_VALUES.MINUTE &&
      i <
        (timeWindowValue === 'day' ? DateTime.fromISO(endDate).endOf('day') : DateTime.fromISO(endDate).startOf('day'))
    );

    return timeWindowValue === TIME_WINDOW_VALUES.MINUTE ? data : { dates: sanitizedData };
  } catch (error) {
    console.error(error);
    return {};
  }
};

export const fetchLineChartOnDemandData = async (
  EventAnalyticsState: EventAnalyticsState,
  youtubeAnalyticsState: YoutubeContextType,
  timeWindowValue: string
): Promise<API_RESPONSE_TYPE> => {
  const {
    customerId,
    token,
    eventId,
    dateRange: { startDate, endDate },
  } = EventAnalyticsState;
  const url = `${socialAnalyticsProxyUrl}/${customerId}/youTubeOnDemand/statistics/${timeWindowValue}/${youtubeAnalyticsState.page}`;
  try {
    const { data } = await axios.get(url, {
      headers: token,
      params: {
        startDate: DateTime.fromISO(startDate).startOf('day').toFormat(dateTimeFormatUTC),
        endDate: DateTime.fromISO(endDate).endOf('day').toFormat(dateTimeFormatUTC),
        segmentBy: youtubeAnalyticsState.groupBy,
        ...(eventId && { eventId }),
      },
    });
    let i = DateTime.fromISO(startDate)
      .startOf(timeWindowValue as keyof DurationObjectUnits)
      .minus({ day: timeWindowValue === 'week' ? 1 : 0 });
    let iFormatted = i.toFormat(timeWindowValue === 'day' ? `yyyy-MM-dd HH:mm:ss` : `yyyy-MM-dd`);
    const sanitizedData = [];

    do {
      sanitizedData.push(
        data.dates.find((d: { date: string }) => d.date === iFormatted) ?? {
          date: iFormatted,
        }
      );
      i = i.plus({ [`${timeWindowValue}s`]: 1 });
      iFormatted = i.toFormat(timeWindowValue === 'day' ? `yyyy-MM-dd HH:mm:ss` : `yyyy-MM-dd`);
    } while (
      i <
      (timeWindowValue === 'day' ? DateTime.fromISO(endDate).endOf('day') : DateTime.fromISO(endDate).startOf('day'))
    );

    return { dates: sanitizedData };
  } catch (error) {
    console.error(error);
    return {};
  }
};

export const fetchSummaryStats = async (
  EventAnalyticsState: EventAnalyticsState,
  page: string
): Promise<BracketReference<number | null>[]> => {
  const {
    customerId,
    token,
    eventId,
    dateRange: { startDate, endDate },
    dateRangeOption,
  } = EventAnalyticsState;
  const liveAPI = `${baseURL}/${customerId}/youTube/statistics/summary`;
  const onDemandAPI = `${socialAnalyticsProxyUrl}/${customerId}/youTubeOnDemand/statistics/summary`;
  const summaryStatsAPI = page !== 'liveViews' ? onDemandAPI : liveAPI;
  const dateRangeValue = dateRangeOption.value.toString();
  const isFullMonth = fixedDateRangeValues.includes(dateRangeValue);
  try {
    const { data } = await axios.get(summaryStatsAPI, {
      headers: token,
      params: {
        startDate: DateTime.fromISO(startDate).startOf('day').toFormat(dateTimeFormatUTC),
        endDate: DateTime.fromISO(endDate).endOf('day').toFormat(dateTimeFormatUTC),
        isFullMonth,
        ...(eventId && { eventId }),
      },
    });
    return data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const fetchDeviceTypeData = async (
  EventAnalyticsState: EventAnalyticsState,
  groupBy: string
): Promise<API_RESPONSE_TYPE> => {
  const {
    customerId,
    token,
    eventId,
    dateRange: { startDate, endDate },
  } = EventAnalyticsState;
  const url = `${socialAnalyticsProxyUrl}/${customerId}/youTubeOnDemand/statistics/views/deviceType`;
  groupBy = groupBy === 'device_type' ? 'none' : groupBy;
  try {
    const { data } = await axios.get(url, {
      headers: token,
      params: {
        startDate: DateTime.fromISO(startDate).startOf('day').toFormat(dateTimeFormatUTC),
        endDate: DateTime.fromISO(endDate).endOf('day').toFormat(dateTimeFormatUTC),
        segmentBy: groupBy,
        ...(eventId && { eventId }),
      },
    });
    return data;
  } catch (error) {
    console.error(error);
    return {};
  }
};

export const exportCSVData = async (
  EventAnalyticsState: EventAnalyticsState,
  offset1?: string | number | undefined,
  offset2?: string | number | undefined
): Promise<CsvDataObject[]> => {
  const {
    customerId,
    eventId,
    token,
    dateRange: { startDate, endDate },
  } = EventAnalyticsState;
  const url = `${baseURL}/${customerId}/youTube/export`;
  let offset;
  if (offset1 && offset2) {
    offset = `${offset1},${offset2}`;
  }
  const defaultParams = {
    startDate: DateTime.fromISO(startDate).startOf('day').toFormat(dateTimeFormatUTC),
    endDate: DateTime.fromISO(endDate).endOf('day').toFormat(dateTimeFormatUTC),
    max: MAX_FETCH_LIMIT_FOR_CSV_EXPORT,
  };
  const params = {
    ...defaultParams,
    ...(eventId && { eventId }),
    ...(offset && { offset }),
  };
  try {
    const { data } = await axios.get(url, {
      headers: token,
      params,
    });
    return data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const exportCsvOnDemand = async (EventAnalyticsState: EventAnalyticsState) => {
  const {
    customerId,
    eventId,
    token,
    dateRange: { startDate, endDate },
  } = EventAnalyticsState;
  const url = `${socialAnalyticsProxyUrl}/${customerId}/youTubeOnDemand/export`;
  const defaultParams = {
    startDate: DateTime.fromISO(startDate).startOf('day').toFormat(dateTimeFormatUTC),
    endDate: DateTime.fromISO(endDate).endOf('day').toFormat(dateTimeFormatUTC),
  };
  const params = {
    ...defaultParams,
    ...(eventId && { eventId }),
  };
  try {
    const { data } = await axios.get(url, {
      headers: token,
      params,
    });
    return data;
  } catch (error) {
    console.error(error);
    return [];
  }
};
type SocialMediaAccount = {
  analyticDataCollection?: boolean;
  customerId: string;
  description: string;
  name: string;
  partnerChannelId: string | null;
  type: 'fb' | 'yt';
  userId: string | null;
  uuid: string;
  viewUrl: string | null;
  status?: string;
  expiresAt?: string | null;
};

export const fetchDataCollectionToggle = async (
  EventAnalyticsState: EventAnalyticsState,
  channelId: string
): Promise<boolean> => {
  const { customerId } = EventAnalyticsState;

  const url = `${centralBaseURL}/${customerId}/socialmedia/${channelId}`;

  try {
    const { data } = await axios.get<SocialMediaAccount>(url, {
      withCredentials: true,
    });

    return data?.analyticDataCollection ?? false;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const fetchSocialMediaAccounts = async (
  EventAnalyticsState: EventAnalyticsState
): Promise<SocialMediaAccount[]> => {
  const { customerId, token } = EventAnalyticsState;

  const url = `${centralBaseURL}/${customerId}/socialmedia`;

  try {
    const { data } = await axios.get<SocialMediaAccount[]>(url, {
      withCredentials: true,
    });

    return data?.length ? data.filter((channel) => channel.type === 'yt') : [];
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const toggleDataCollection = async (
  EventAnalyticsState: EventAnalyticsState,
  channelId: string,
  isToggled: boolean
): Promise<boolean> => {
  const { customerId } = EventAnalyticsState;

  const url = `${centralBaseURL}/${customerId}/socialmedia/${channelId}`;

  try {
    await axios.patch<SocialMediaAccount[]>(
      url,
      {
        analyticDataCollection: isToggled,
      },
      { withCredentials: true }
    );

    return true;
  } catch (error) {
    console.error(error);
    return false;
  }
};
