interface Version {
  major: number;
  minor: number;
  build: number;
  revision: number;
}

class VersionService {
  readonly VERSION_DELIMITER = '.';
  readonly V3_ENC_PROFILE_MIN_REQ_VERSION = '1.5.0.32';

  // returns true if this version is less than that version
  public isVersionLessThan = function (thisVersion: string, thatVersion: string): boolean {
    const thisParsed = this.parseVersion(thisVersion);
    const thatParsed = this.parseVersion(thatVersion);

    if (thisParsed.major < thatParsed.major) {
      return true;
    }
    if (thisParsed.minor < thatParsed.minor) {
      return true;
    }
    if (thisParsed.build < thatParsed.build) {
      return true;
    }
    if (thisParsed.revision < thatParsed.revision) {
      return true;
    }
    return false;
  };

  // returns the parsed value as a number, or zero if parseInt returns NaN
  private parseVersionComponent = function (value: string): number {
    const parsedValue = parseInt(value);
    return isNaN(parsedValue) ? 0 : parsedValue;
  };

  private parseVersion = function (version: string): Version {
    let major = 0;
    let minor = 0;
    let build = 0;
    let revision = 0;

    const dotIndex1 = version.indexOf(this.VERSION_DELIMITER);
    if (dotIndex1 !== -1) {
      major = this.parseVersionComponent(version.substring(0, dotIndex1));
      // remove the major version and try to find the next "."
      let remainingChars = version.substring(dotIndex1 + 1);
      const dotIndex2 = remainingChars.indexOf(this.VERSION_DELIMITER);
      if (dotIndex2 !== -1) {
        minor = this.parseVersionComponent(remainingChars.substring(0, dotIndex2));
        // remove minor version and try to find the next "."
        remainingChars = remainingChars.substring(dotIndex2 + 1);
        const dotIndex3 = remainingChars.indexOf(this.VERSION_DELIMITER);
        if (dotIndex3 !== -1) {
          build = this.parseVersionComponent(remainingChars.substring(0, dotIndex3));
          revision = this.parseVersionComponent(remainingChars.substring(dotIndex3 + 1));
        } else {
          build = this.parseVersionComponent(remainingChars);
        }
      } else {
        minor = this.parseVersionComponent(remainingChars);
      }
    } else {
      major = this.parseVersionComponent(version);
    }

    return {
      major,
      minor,
      build,
      revision,
    };
  };
}

export default VersionService;
