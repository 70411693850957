import React, { FC, useReducer } from 'react';
import * as LDClient from 'launchdarkly-js-client-sdk';
import { AUTH_TOKEN } from '../../../v2.snapshot/js/constants';
import PropTypes from 'prop-types';
import { ThemeProvider } from '@material-ui/core';
import materialTheme from './materialTheme';
import EventAnalyticsView from './event-analytics-view';
import { EventAnalyticsContext, EventAnalyticsReducer } from '../../contexts/eventanalytics/event-analytics';
import { dayRangeOptions, NOW_LOCAL, START_DATE_LOCAL } from './date-range-options';
import { InfoBox } from '../../components';

interface EventAnalyticsProps {
  customerId: string;
  eventId?: string;
  ldFlags?: LDClient.LDFlagSet;
  auth?: any;
}

const EventAnalytics: FC<EventAnalyticsProps> = ({ customerId, eventId, auth, ldFlags }): JSX.Element => {
  const hasPermission: boolean = auth.currentUser.hasPerm('analytics.get');
  const token = { Authorization: `X-Bearer ${sessionStorage.getItem(AUTH_TOKEN)}` };
  const [EventAnalyticsState, dispatch] = useReducer(EventAnalyticsReducer, {
    viewAllData: false,
    customerId,
    eventId: eventId || null,
    token,
    dateRange: {
      startDate: eventId ? START_DATE_LOCAL.toISO() : NOW_LOCAL.minus({ days: 6 }).toISO(),
      endDate: NOW_LOCAL.toISO(),
    },
    dateRangeOption: eventId ? dayRangeOptions[4] : dayRangeOptions[0],
  });

  return (
    <ThemeProvider theme={materialTheme}>
      <EventAnalyticsContext.Provider value={{ EventAnalyticsState, dispatch }}>
        {hasPermission ? (
          <EventAnalyticsView ldFlags={ldFlags} />
        ) : (
          <InfoBox infoText={'Sorry! You do not have enough permissions to access this page.'} />
        )}
      </EventAnalyticsContext.Provider>
    </ThemeProvider>
  );
};

EventAnalytics.displayName = 'EventAnalytics';

EventAnalytics.propTypes = {
  customerId: PropTypes.string.isRequired,
  eventId: PropTypes.string,
};

export default EventAnalytics;
