import styled from '@emotion/styled';
import { customProperties as vars } from '@resi-media/resi-ui';

export const VideoCuesHeader = styled.div`
  display: flex;
  justify-content: space-between;
  font-family: ${vars.fontFamily};
  margin-left: ${vars.spacingXl};
  margin-top: ${vars.spacingXl};
  flex-direction: column
`;
export const VideoCuesTableContainer = styled.div`
  flex: 1 0 auto;
  display:flex;
  justify-content:center;
  margin-left:${vars.spacingXl};
  margin-right:${vars.spacingXl};
  padding: ${vars.spacingL} 0;
`;