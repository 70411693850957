import { perms } from '../../jcs';
import { customersModule } from './index';

function routeConfig($provide, $routeProvider) {
  'ngInject';

  $provide.factory('$routeProvider', () => {
    return $routeProvider;
  });

  // do more specific route first
  $routeProvider.when(customersModule.routes.switch_to, {
    controller: customersModule.controllers.default,
    template: require('./organizations.tmpl.html').default,
    activenav: customersModule.name,
    access: {
      loginRequired: true,
      permissions: [perms['customers.get_all']],
    },
  });
  $routeProvider.when(customersModule.routes.details, {
    controller: customersModule.controllers.default,
    template: require('./organizations.tmpl.html').default,
    activenav: customersModule.name,
    access: {
      loginRequired: true,
      permissions: [perms['customers.get_all']],
    },
  });
  $routeProvider.when(customersModule.routes.list, {
    controller: customersModule.controllers.default,
    template: require('./organizations.tmpl.html').default,
    activenav: customersModule.name,
    access: {
      loginRequired: true,
      permissions: [perms['customers.get_all']],
    },
  });
}

export default routeConfig;
