import { perms } from '../../jcs';
import { monitorModule } from './index';

function routeConfig($provide, $routeProvider) {
	'ngInject';

	$provide.factory('$routeProvider', () => {
		return $routeProvider;
	});

	$routeProvider.when(monitorModule.routes.list, {
		controller: monitorModule.controllers.default,
		template: require('./monitor.tmpl.html').default,
		activenav: monitorModule.name,
		access: {
			loginRequired: true,
			permissions: [perms['monitor.get']],
		},
	});
}

export default routeConfig;
