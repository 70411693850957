'use strict';

const jcs = require('../../jcs');
const moment = require('moment');

function PlayersController($scope, $http, $routeParams, $location, focus, Authentication) {
	'ngInject';
	$(window).trigger('resize'); // ensure footer is properly positioned

	$scope.loading_players_data_timeout_id = null;
	$scope.UPDATE_PLAYER_DATA_TIME_DELAY = 1000 * 30; // 30 seconds

	$scope.TIME_DATE_FORMAT = 'MMM D, YYYY h:mm:ss A';

	$scope.players = null;
	$scope.loading_players = true;
	$scope.players_error_msg = null;
	$scope.player_data_last_update = null;
	$scope.user_id = $routeParams.userID;
	$scope.username = $location.search().username; // get from query arguments

	$scope.LOG_TYPE_DEFAULT = 'info';
	$scope.log_type_options = [
		{ name: 'Show All Log Types', value: 'debug' },
		{ name: 'Show Only Info +', value: 'info' },
		{ name: 'Show Only Warn +', value: 'warn' },
		{ name: 'Show Only Error', value: 'error' },
	];

	// player we want to view logs for
	$scope.player_to_view_logs = null;
	$scope.is_busy_loading_logs = false; // activity indicator
	$scope.player_logs = null; // the player logs that have been loaded
	$scope.player_log_warning_count = 0;
	$scope.player_log_error_count = 0;
	$scope.log_start_time = '';
	$scope.log_end_time = '';
	$scope.view_logs_error = null;
	$scope.log_type_filter = $scope.LOG_TYPE_DEFAULT;

	$scope.canShowViewLogsBtn = function () {
		return Authentication.getCurrentUser().hasPerm('users.player_logs');
	};

	$scope.enterViewLogMode = function (player) {
		$scope.player_to_view_logs = player;
		$scope.player_logs = null;
		$scope.view_logs_error = null;
	};

	$scope.cancelViewLogMode = function () {
		$scope.player_to_view_logs = null;
	};

	$scope.getPlayerLogs = function () {
		$scope.view_logs_error = null; // if we had an error, clear it

		$scope.is_busy_loading_logs = true;

		// build our query string which will include the start/end times
		var query_string = '';
		if ($scope.log_start_time != '') {
			var formatted_start_time = moment($scope.log_start_time, $scope.TIME_DATE_FORMAT).toISOString();
			query_string += 'start=' + encodeURIComponent(formatted_start_time);
		}
		if ($scope.log_end_time != '') {
			if (query_string != '') {
				query_string += '&';
			}
			var formatted_end_time = moment($scope.log_end_time, $scope.TIME_DATE_FORMAT).toISOString();
			query_string += 'end=' + encodeURIComponent(formatted_end_time);
		}
		// add log type filter
		if ($scope.log_type_filter != '') {
			if (query_string != '') {
				query_string += '&';
			}
			query_string += 'minlevel=' + encodeURIComponent($scope.log_type_filter);
		}

		// fetch our players logs
		$http
			.get(
				jcs.api.url +
				'/users/' +
				$routeParams.userID +
				'/players/' +
				$scope.player_to_view_logs.playerHardwareId +
				'/logs?' +
				query_string,
				{ withCredentials: true }
			)
			.then(
				function (response) {
					// success

					$scope.player_logs = response.data;
					// determine number of warnings and errors
					$scope.player_log_warning_count = 0;
					$scope.player_log_error_count = 0;
					for (var i = 0; i < $scope.player_logs.length; i++) {
						var log_level = $scope.player_logs[i].level.toLowerCase();
						if (log_level == 'warn') {
							$scope.player_log_warning_count++;
						} else if (log_level == 'error') {
							$scope.player_log_error_count++;
						}
					}
				},
				function () {
					// error

					$scope.view_logs_error =
						'An error occurred while retrieving the player logs. Please try again, or report the problem if it persists.';
					$scope.player_logs = null;
				}
			)
		['finally'](function () {
			// always called

			$scope.is_busy_loading_logs = false;
		});
	};

	$scope.getPlayerLogsLastXMin = function (minutes) {
		// set start end times to be previous minutes provided
		$scope.log_start_time = moment()
			.subtract(minutes, 'minutes')
			.format($scope.TIME_DATE_FORMAT); // x minutes ago
		$scope.log_end_time = moment().format($scope.TIME_DATE_FORMAT); // right now

		$('#log-time-range')
			.data('daterangepicker')
			.setStartDate($scope.log_start_time);
		$('#log-time-range')
			.data('daterangepicker')
			.setEndDate($scope.log_end_time);

		// fetch the logs for the given time range
		$scope.getPlayerLogs();
	};

	$scope.loadPlayersSilent = function () {
		// retrieve players status for this user from server
		$http
			.get(jcs.api.url + '/users/' + $routeParams.userID + '/players', { withCredentials: true })
			.then(
				function (response) {
					// success

					$scope.players_error_msg = null;
					$scope.players = response.data;

					$scope.player_data_last_update = moment().format();

					// calculate our buffer sizes
					for (var i = 0; i < $scope.players.length; i++) {
						var player = $scope.players[i];
						$scope.players[i].bufferSize = $scope.getBufferSize(player);
					}
				},
				function () {
					// error
					// If our "silent" load gets an error, lets not display an error message -- I think it would be confusing for the user to see
					// an error about an action that they did not initiate. So instead of displaying an error, setup another timeout in the hopes
					// that the error we got as a one-time thing, and upcoming check will work.
				}
			)
		['finally'](function () {
			// always called

			// setup timeout so our data will auto refresh
			$scope.loading_players_data_timeout_id = window.setTimeout(
				$scope.loadPlayersSilent,
				$scope.UPDATE_PLAYER_DATA_TIME_DELAY
			);
		});
	};

	$scope.loadPlayers = function () {
		$scope.loading_players = true;

		// check to see if we have a scheduled timeout
		if ($scope.loading_players_data_timeout_id != null) {
			// if so, then clear it (we'll pull data down below and set a timeout if necessary)
			window.clearTimeout($scope.loading_players_data_timeout_id);
			$scope.loading_players_data_timeout_id = null;
		}

		// retrieve players status for this user from server
		$http
			.get(jcs.api.url + '/users/' + $routeParams.userID + '/players', { withCredentials: true })
			.then(
				function (response) {
					// success

					$scope.players_error_msg = null;
					$scope.players = response.data;

					$scope.player_data_last_update = moment().format();

					// calculate our buffer sizes
					for (var i = 0; i < $scope.players.length; i++) {
						var player = $scope.players[i];
						$scope.players[i].bufferSize = $scope.getBufferSize(player);
					}

					// setup timeout so our data will auto refresh
					$scope.loading_players_data_timeout_id = window.setTimeout(
						$scope.loadPlayersSilent,
						$scope.UPDATE_PLAYER_DATA_TIME_DELAY
					);
				},
				function () {
					// error

					$scope.players_error_msg =
						'An error occurred while attempting to retrieve player status. Please try again, or report the problem if it persists.';
					$scope.players = null;
				}
			)
		['finally'](function () {
			// always called

			$scope.loading_players = false;
		});
	};

	// for options see: http://www.daterangepicker.com/
	$scope.setLogTimeRange = function (start, end, label) {
		// currently the time appears to be saved as milliseconds or seconds
		$scope.log_start_time = start;
		$scope.log_end_time = end;
	};

	// no seconds
	$scope.showTimeAsLocalHM = function (dateTimeToConvert) {
		// NOTE: we were using the Date "toLocaleString" method, but it turns out this isn't implemented consistently across
		// browsers. That is why we are using a javascript library (momentjs).

		// is the given time today?
		var currentDay = moment().format('MMM D, YYYY');
		var givenTimeDay = moment(dateTimeToConvert).format('MMM D, YYYY');
		if (currentDay == givenTimeDay) {
			return 'today at ' + moment(dateTimeToConvert).format('h:mm a');
		}

		// is the given time this year?
		var currentYear = moment().format('YYYY');
		var givenTimeYear = moment(dateTimeToConvert).format('YYYY');
		if (currentYear == givenTimeYear) {
			return moment(dateTimeToConvert).format('MMM D, h:mm a');
		}

		// for formatting options see: http://momentjs.com/
		return moment(dateTimeToConvert).format('MMM D, YYYY h:mm a');
	};

	$scope.formatBandwidth = function (bandwidth) {
		if (bandwidth != null) {
			if (bandwidth > 0 && bandwidth < 10) return bandwidth.toFixed(1);

			return bandwidth.toFixed(0);
		}

		return bandwidth;
	};

	$scope.formatPosition = function (position) {
		var decimalPosition = position.indexOf('.');
		if (decimalPosition != -1) {
			//        			console.log("Position:" + position + " Formatted:" + position.substring(0,decimalPosition+3));
			// only show 2 places after the decimal
			return position.substring(0, decimalPosition + 3);
		}
		// position does not need formatting
		return position;
	};

	// returns TRUE if the given text should be formatted like a "<pre>" tag. We should do this if the text contains a newline.
	// see: https://stackoverflow.com/questions/15131072/check-whether-string-contains-a-line-break
	$scope.formatAsPre = function (entry) {
		return /\r|\n/.exec(entry);
	};

	$scope.getBufferSize = function (playerInfo) {
		// calculate the diff between player position and buffer position in milliseconds
		var player_pos_ms = moment.duration(playerInfo.position).asMilliseconds();
		var buffer_pos_ms = moment.duration(playerInfo.buffer).asMilliseconds();
		var diff_ms = buffer_pos_ms > player_pos_ms ? buffer_pos_ms - player_pos_ms : 0;

		// create duration object, which can convert our milliseconds duration into hours/minutes/seconds
		var duration = moment.duration(diff_ms);
		var hours = duration.hours();
		var minutes = duration.minutes();
		var seconds = duration.seconds();

		// format with leading zeros
		var hours_formatted = hours < 10 ? '0' + hours : hours;
		var minutes_formatted = minutes < 10 ? '0' + minutes : minutes;
		var seconds_formatted = seconds < 10 ? '0' + seconds : seconds;

		return hours_formatted + ':' + minutes_formatted + ':' + seconds_formatted;
	};

	//			$scope.showFormattedStreamUrl = function (url){
	//
	//				// we'll hide the domain part of the url, since I think that is a given, and we could use the extra space
	//				return url.replace('http://storage.googleapis.com', '');
	//
	//			};

	//
	// initialize by loading our players
	//
	$scope.loadPlayers();

	// since this page has timers, we need to know when the user changes to another page so we can turn the timers
	// off (this will prevent unnecessary http requests).
	$scope.$on('$locationChangeSuccess', function () {
		// clear any active timers we might have
		if ($scope.loading_players_data_timeout_id != null) {
			window.clearTimeout($scope.loading_players_data_timeout_id);
			$scope.loading_players_data_timeout_id = null;
		}
	});

	// prepare our default start/end times
	$scope.log_start_time = moment()
		.subtract(30, 'minutes')
		.format($scope.TIME_DATE_FORMAT); // 30 minutes ago
	$scope.log_end_time = moment().format($scope.TIME_DATE_FORMAT); // right now

	// initialize our date-time range picker that will be used when viewing player logs
	// NOTE: there is a "singleDatePicker" option in case we decide we want either start/end time to be optional
	// and would therefore need to have two separate input fields. Each input field would also need it's own callback.
	// for options see: http://www.daterangepicker.com/ (especially the Configuration Generator section)
	$('#log-time-range').daterangepicker(
		{
			//singleDatePicker: true,
			startDate: $scope.log_start_time,
			endDate: $scope.log_end_time,
			timePicker: true,
			timePickerIncrement: 5,
			locale: {
				format: 'lll', // <= looks like this is using moment.js formatting options
			},
		},
		$scope.setLogTimeRange
	);
}

module.exports = PlayersController;
