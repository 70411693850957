import React, { FC, useContext, useState, ChangeEvent, MouseEvent, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { MenuItem, Paper, Popover } from '@material-ui/core';
import { DateTime } from 'luxon';
import { Grid, Text, Select, Inline, Option } from '@resi-media/resi-ui';
import Link from '@material-ui/core/Link';
import Tooltip from '@material-ui/core/Tooltip';
import {
  LineChart,
  ContainedTabs,
  ContainedTab,
  NavTabs,
  NavTab,
  Surface,
  ViewEvents,
  FillBlockSpinner,
  ContainedButton,
} from '../../../../components';
import { ExportCSVWrapper, LinkButtonContainer } from '../../event-analytics.styles';
import {
  TabsDiv,
  GraphWrapper,
  EventStatsGraphWrapper,
  EventStatsHeaderDiv,
  ChartControls,
  dropDownStyleProps,
  DropDownWrapper,
} from './embed-stats-graph.styles';
import {
  AreaChartOutlined,
  ClockCircleOutlined,
  DownOutlined,
  ExportOutlined,
  TeamOutlined,
  UndoOutlined,
  ZoomInOutlined,
  ZoomOutOutlined,
} from '@ant-design/icons';
import {
  SelectOption,
  lineChartDataType,
  EMBED_DATA_PAGES,
  GROUP_BY_VALUE,
  TIME_WINDOWS,
  DataObject,
} from '../../types';
import Modal from '../../../../components/Modal';
import ModalHeader from '../../../../components/ModalHeader';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRange, Range } from 'react-date-range';
import {
  allDeviceTypeOption,
  API_RESPONSE_LEGENDS,
  DESTINATION_TYPES,
  EMBED_PAGE_STATE_CONTEXT_KEYS,
  GROUP_SET,
  LEGEND_NAMES,
  LOADER_SIZE_COMPONENT,
  LOADER_SIZE_SUB_COMPONENT,
  PAGE,
  TAB_VALUES_NUMBERS,
  ZOOM_CTRL_TOOLTIP,
} from '../../event-analytics-constants';
import { formatSecondsToMinutesString, formatTooltipToNormalisedTime, getToolTipLabel, renameKey } from '../../utils';
import { EventAnalyticsContext } from '../../../../contexts/eventanalytics/event-analytics';
import { LIFE_TIME_DATE_LOCAL, START_DATE_LOCAL } from '../../date-range-options';
import { EmbedPlayerContext } from '../../../../contexts/eventanalytics';
import startCase from 'lodash/startCase';

interface EmbedStatsGraphViewProps {
  data: lineChartDataType;
  videoCuesData?: DataObject[];
  isChartLoading: boolean;
  dateRangeOptions: SelectOption[];
  dateRangeDropdownValue: SelectOption;
  isDatePickerOpen: boolean;
  dataType: number;
  dataPage: number;
  dataPageName: string;
  timeWindow: number;
  disableMonthTab: boolean;
  handleGroupByChange: (event: ChangeEvent<unknown>, newValue: GROUP_BY_VALUE) => void;
  handleDataPageChange: (event: ChangeEvent<unknown>, newValue: EMBED_DATA_PAGES) => void;
  handleDateRangeChange: (item: Option[]) => void;
  handleTimeWindowChange: (event: ChangeEvent<unknown>, newValue: TIME_WINDOWS) => void;
  handleCustomDateRangeSelection: (startDate: string, endDate: string) => void;
  closeDatePicker: () => void;
  exportCSV: () => void;
  handleDeviceTypeChange: (menuItem: string) => void;
  hasGraphPerms: boolean;
  isExportCSVLoading: boolean;
}

const useStyles = makeStyles({
  wrapper: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    gap: '10px',
    flexDirection: 'row-reverse',
  },
});

const EmbedStatsGraphView: FC<EmbedStatsGraphViewProps> = ({
  data,
  videoCuesData,
  isChartLoading,
  dateRangeOptions,
  dateRangeDropdownValue,
  isDatePickerOpen,
  dataType,
  dataPage,
  dataPageName,
  timeWindow,
  disableMonthTab,
  handleDeviceTypeChange,
  handleGroupByChange,
  handleDataPageChange,
  handleDateRangeChange,
  handleTimeWindowChange,
  handleCustomDateRangeSelection,
  hasGraphPerms,
  exportCSV,
  isExportCSVLoading,
  closeDatePicker,
}): JSX.Element => {
  const classes = useStyles();
  const { dispatch } = useContext(EmbedPlayerContext);
  const {
    EventAnalyticsState: {
      viewAllData,
      eventId,
      dateRange: { startDate, endDate },
    },
  } = useContext(EventAnalyticsContext);
  const [selectionRange, setSelectionRange] = useState<Range>({
    startDate: new Date(startDate),
    endDate: new Date(endDate),
    key: 'selection',
  });
  const [selectedDeviceBrand, setSelectedDeviceBrand] = useState(allDeviceTypeOption.value);
  const [deviceTypeDropdownOptions, setDeviceTypeDropdownOptions] = useState([allDeviceTypeOption]);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  const restrictedDropDownOptions = [
    API_RESPONSE_LEGENDS.LIVE,
    API_RESPONSE_LEGENDS.ON_DEMAND,
    API_RESPONSE_LEGENDS.SIM_LIVE,
    API_RESPONSE_LEGENDS.NONE,
  ];
  const handleDateRangeSelect = (range: { selection: Range }) => {
    const { selection } = range;
    const { hour, minute, second, millisecond } = DateTime.local().toObject();
    setSelectionRange({
      startDate: selection.startDate,
      endDate: selection.endDate,
      key: 'selection',
    });
    if (selectionRange.startDate && selectionRange.endDate) {
      const selectionStartDate = selection.startDate
        ? DateTime.fromJSDate(selection.startDate)
            .plus({
              hours: hour,
              minutes: minute,
              seconds: second,
              milliseconds: millisecond,
            })
            .toISO() || ''
        : '';
      const selectionEndDate = selection.endDate
        ? DateTime.fromJSDate(selection.endDate)
            .plus({
              hours: hour,
              minutes: minute,
              seconds: second,
              milliseconds: millisecond,
            })
            .toISO() || ''
        : '';
      handleCustomDateRangeSelection(selectionStartDate, selectionEndDate);
    }
  };
  Object.keys(data.datasets).map((el) => {
    if (el in LEGEND_NAMES) {
      data.datasets = renameKey(data.datasets, el, `${el}`);
    }
  });

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (menuItem: string) => {
    setSelectedDeviceBrand(startCase(menuItem));
    handleDeviceTypeChange(menuItem);
    setAnchorEl(null);
  };

  useMemo(() => {
    if (dataType === TAB_VALUES_NUMBERS.DEVICE_TYPE) {
      setSelectedDeviceBrand(allDeviceTypeOption.value);
      dispatch({ type: EMBED_PAGE_STATE_CONTEXT_KEYS.GROUP_BY, payload: GROUP_SET[2] });
      handleDeviceTypeChange(allDeviceTypeOption.value);
    }
  }, [dataType]);

  useEffect(() => {
    const updatedDeviceTypeOptions = [];
    updatedDeviceTypeOptions.push(allDeviceTypeOption);
    if (dataType === TAB_VALUES_NUMBERS.DEVICE_TYPE && !isChartLoading) {
      Object.keys(data?.datasets || {}).map((item, _index) => {
        if (!restrictedDropDownOptions.includes(item)) {
          updatedDeviceTypeOptions.push({ value: item, label: startCase(item) });
        }
      });
    }
    if (selectedDeviceBrand === allDeviceTypeOption.value) {
      setDeviceTypeDropdownOptions(updatedDeviceTypeOptions);
    }
  }, [data, isChartLoading]);
  return (
    <Grid.Item>
      <Paper elevation={0} variant="outlined" data-testid="embed-stats-view">
        <EventStatsGraphWrapper hasGraphPerms={hasGraphPerms}>
          <EventStatsHeaderDiv>
            <Inline alignItems="center">
              <div>
                <Text.Body size="xs" isUppercase isBold color="secondary">
                  {`${DateTime.fromISO(startDate).toFormat('MMM dd yyyy')} - \
                ${DateTime.fromISO(endDate).toFormat('MMM dd yyyy')}`}
                </Text.Body>
                <DropDownWrapper>
                  <Select
                    size="m"
                    value={dateRangeDropdownValue?.value}
                    options={dateRangeOptions}
                    handleChange={(newValue) => {
                      if (newValue[0].value) {
                        handleDateRangeChange(newValue);
                      }
                    }}
                  />
                </DropDownWrapper>
                {isDatePickerOpen && (
                  <Modal width="fit-content">
                    <Surface padding={'0'}>
                      <ModalHeader titleText="Pick custom date range" onClose={() => closeDatePicker()} closeButton />
                      <DateRange
                        ranges={[selectionRange]}
                        editableDateInputs
                        onChange={handleDateRangeSelect}
                        minDate={viewAllData ? LIFE_TIME_DATE_LOCAL.toJSDate() : START_DATE_LOCAL.toJSDate()}
                      />
                    </Surface>
                  </Modal>
                )}
              </div>
              <ViewEvents
                dateRangeOptions={dateRangeOptions}
                dateRangeSelection={dateRangeDropdownValue}
                page={DESTINATION_TYPES.EMBED}
              />
            </Inline>
            <Inline justifyContent="center" alignItems="center">
              <NavTabs onChange={handleDataPageChange} value={dataPage}>
                <NavTab data-testid="embed-stats-view-viewers-tab" icon={<TeamOutlined />} label="Viewers" />
                <NavTab
                  data-testid="embed-stats-view-avgWatchtime-tab"
                  icon={<ClockCircleOutlined />}
                  label="Avg Watch Time"
                />
                {/*eventId && (
                  <NavTab
                    data-testid="embed-minute-by-minute-tab"
                    icon={<AreaChartOutlined />}
                    label="Minute-by-Minute"
                  />
                )*/}
              </NavTabs>
            </Inline>
            <Inline justifyContent="flex-end" alignItems="center">
              <ExportCSVWrapper>
                <>
                  <Tooltip
                    title={
                      <h6>
                        {isExportCSVLoading ? 'Please wait, your file is being prepared. ' : 'Download all data as CSV'}
                      </h6>
                    }
                    arrow
                    placement="top"
                  >
                    <Link
                      component="button"
                      underline="none"
                      variant="body2"
                      disabled={isExportCSVLoading}
                      onClick={exportCSV}
                    >
                      <LinkButtonContainer>
                        <ExportOutlined />
                        <span style={{ marginLeft: '5px' }}>Export CSV</span>
                      </LinkButtonContainer>
                    </Link>
                  </Tooltip>
                  {isExportCSVLoading ? <FillBlockSpinner fontSize={LOADER_SIZE_SUB_COMPONENT} /> : null}
                </>
              </ExportCSVWrapper>
            </Inline>
          </EventStatsHeaderDiv>
          { hasGraphPerms && (
            <>
              <TabsDiv>
                <ContainedTabs onChange={handleGroupByChange} value={dataType}>
                  <ContainedTab data-testid="embed-stats-view-none-tab" label="None" />
                  <ContainedTab data-testid="embed-stats-view-eventType-tab" label="Event Type" />
                  <ContainedTab
                    data-testid="embed-stats-view-deviceType-tab"
                    label={
                      dataType !== TAB_VALUES_NUMBERS.DEVICE_TYPE ? 'Device Type' : `Device Type : ${selectedDeviceBrand}`
                    }
                    onClick={handleClick}
                    icon={
                      <DownOutlined
                        style={{
                          marginTop: '8px',
                        }}
                      />
                    }
                    classes={{ wrapper: classes.wrapper }}
                  ></ContainedTab>
                </ContainedTabs>
                {open && (
                  <Popover
                    id={'simple-popover'}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    PaperProps={{
                      style: dropDownStyleProps,
                    }}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                    }}
                  >
                    {deviceTypeDropdownOptions.map((eachElement, _index) => {
                      return (
                        <MenuItem key={_index} onClick={() => handleMenuItemClick(eachElement.value)}>
                          <Text.Body size="s">{eachElement.label}</Text.Body>
                        </MenuItem>
                      );
                    })}
                  </Popover>
                )}
                <Tooltip title={<h6>{ZOOM_CTRL_TOOLTIP}</h6>} arrow placement="top">
                  <ChartControls>
                    <ContainedButton id="zoomPlus">
                      <ZoomInOutlined style={{ fontSize: '20px' }} />
                    </ContainedButton>
                    <ContainedButton id="zoomMinus">
                      <ZoomOutOutlined style={{ fontSize: '20px' }} />
                    </ContainedButton>
                    <ContainedButton id="reset-zoom">
                      <UndoOutlined style={{ fontSize: '20px' }} />
                    </ContainedButton>
                  </ChartControls>
                </Tooltip>
                {dataPage !== PAGE.MINUTE_BY_MINUTE ? (
                  <ContainedTabs onChange={handleTimeWindowChange} value={timeWindow}>
                    <ContainedTab data-testid="embed-stats-view-day-tab" label="Day" />
                    <ContainedTab data-testid="embed-stats-view-week-tab" label="Week" />
                    <ContainedTab disabled={disableMonthTab} data-testid="embed-stats-view-month-tab" label="Month" />
                  </ContainedTabs>
                ) : (
                  <ContainedTabs style={{ width: '154px' }}></ContainedTabs>
                )}
              </TabsDiv>
              <GraphWrapper data-testid="embed-stats-view-lineChart">
                {isChartLoading ? (
                  <FillBlockSpinner fontSize={LOADER_SIZE_COMPONENT} />
                ) : (
                  <LineChart
                    data={data}
                    dataPageName={dataPageName}
                    tooltipLabel={getToolTipLabel(dataPageName)}
                    yAxisTickGenerator={dataPage === PAGE.AVG_WATCH_TIME ? formatSecondsToMinutesString : undefined}
                    toolTipFormatGenerator={dataPage === PAGE.AVG_WATCH_TIME ? formatTooltipToNormalisedTime : undefined}
                    videoCuesData={dataPage === PAGE.MINUTE_BY_MINUTE ? videoCuesData : null}
                  />
                )}
              </GraphWrapper>
            </>
          )}
        </EventStatsGraphWrapper>
      </Paper>
    </Grid.Item>
  );
};

EmbedStatsGraphView.defaultProps = {
  data: {
    labels: ['Aug 9', 'Sep 1', 'Sep 2', 'Sep 9', 'Sep 15', 'Oct 1', 'Oct 8'],
    datasets: { data: [12, 7, 14, 30, 11, 12, 4, 79, 13, 26, 12, 21, 10] },
  },
};

EmbedStatsGraphView.propTypes = {
  isChartLoading: PropTypes.bool.isRequired,
  data: PropTypes.objectOf(
    PropTypes.exact({
      labels: PropTypes.arrayOf(PropTypes.string).isRequired,
      datasets: PropTypes.objectOf(
        PropTypes.shape({
          data: PropTypes.arrayOf(PropTypes.number),
        })
      ),
    })
  ).isRequired,
  dateRangeOptions: PropTypes.arrayOf(
    PropTypes.exact({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.number.isRequired]).isRequired,
    }).isRequired
  ).isRequired,
  dateRangeDropdownValue: PropTypes.exact({
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.number.isRequired]).isRequired,
  }).isRequired,
  dataType: PropTypes.number.isRequired,
  dataPage: PropTypes.number.isRequired,
  dataPageName: PropTypes.string.isRequired,
  timeWindow: PropTypes.number.isRequired,
  disableMonthTab:PropTypes.bool.isRequired,
  handleDeviceTypeChange: PropTypes.func.isRequired,
  handleGroupByChange: PropTypes.func.isRequired,
  handleDataPageChange: PropTypes.func.isRequired,
  handleDateRangeChange: PropTypes.func.isRequired,
  handleTimeWindowChange: PropTypes.func.isRequired,
  handleCustomDateRangeSelection: PropTypes.func.isRequired,
  hasGraphPerms: PropTypes.bool.isRequired,
  exportCSV: PropTypes.func.isRequired,
  isExportCSVLoading: PropTypes.bool.isRequired,
  isDatePickerOpen: PropTypes.bool.isRequired,
  closeDatePicker: PropTypes.func.isRequired,
};

EmbedStatsGraphView.displayName = 'EmbedStatsGraphView';

export default EmbedStatsGraphView;
