import moment from 'moment';
import { textEncoderPolyfill } from '../../polyfills';
const { trackMixpanelEvent, MPEventProperty, MPEventName} = require('../../../../src/mixpanel');

// Originally the simulcast-selector was going to load the social media accts itself (if they weren't provided). The problem with that
// is they would be loaded even if the simulcast-selector control was never visible. There didn't really seem to be a good way to tell
// the component to wait until a certain point before loading the social media accts. So for now the social media accts will have to be
// loaded by the page and provided to the component -- that will ensure we only load the accts if we need to.

const destination = require('../../../../src/components/ImageUpload/imageUploadTypes');

const jcs = require('../../jcs');

 // used so each simulcast selector will be guaranteed to have a unique model ID attribute; This will allow placing multiple simulcast
 // selectors on one page.
var simulcastSelectorCtrlCounter = 1;

class SimulcastSelectorCtrl {

	constructor($http, $q, $timeout, $scope, socialMedia, formValidationService, Authentication) {
		'ngInject';

		this.$http = $http;
		this.$q = $q;
		this.$timeout = $timeout;
		this.$scope = $scope;
		this.social_media = socialMedia.init();
		this.validation = formValidationService.init();
		this.auth = Authentication;

		this.SOCIAL_MEDIA_EVENT_STREAM_NOW_LABEL = 'Stream Now';
		this.DESTINATION_TYPE_FB_PAGE = 'fb_page';

		// based on https://developers.google.com/youtube/v3/docs/videos?authuser=1#snippet.title, YouTube has the stricter restrictions on the max size
		// for title and description; To keep the UI experience consistent, we will use the stricter restrictions for all simulcast types.
		this.TITLE_MAX_LENGTH = 100;
		this.TITLE_MAX_BYTE_LENGTH = 128;
		this.DESCRIPTION_MAX_LENGTH = 5000;

		this.MAX_CROSSPOSTS = 10;

		this.privacy_options = [];
		this.publish_options = [];

		this.social_media_form = null;
		this.error_msg = null;

		this.destination_options = null;
		this.destination_options_cache = {};
		this.is_loading_destination_options = false;
		this.destination_options_error = null;

		this.crosspost_destinations_cache = {};
		this.is_loading_crosspost_destinations = false;
		this.crosspost_destination_error = null;

		this.destination_cache = {};

		this.social_media_dest_modal_id = 'add-social-media-modal-' + simulcastSelectorCtrlCounter;
		this.more_info_modal_id = 'simulcast-error-more-info-' + simulcastSelectorCtrlCounter++;

		this.show_remove_scheduled_post_msg = false;
		this.show_change_to_scheduled_post_msg = false;
		this.show_create_scheduled_post_msg = false;

		this.imageCroppedButNotUploaded = false;

		this.social_media_account_type = null;
		// the account type when edit is initially loaded
		this.social_media_original_account_type = null;

		this.upload_destination = destination.UPLOAD_DESTINATION.SOCIAL_MEDIA;
	}

	// initialize our bindings if a value wasn't provided
	$onInit (){

		// if no message has been provided for when there are no social media accounts, then provide one
		if (typeof this.noSimulcastsMsg === 'undefined'){
			this.noSimulcastsMsg = 'No social media destinations have been selected.';
		}
		// if no sim-live info has been provided, then default to false
		if (typeof this.isSimLive === 'undefined'){
			this.isSimLive = false;
		}
		if (typeof this.isUploader === 'undefined'){
			this.isUploader = false;
		}

		$('.remove-scheduled-post-tooltip').tooltip({
			template: '<div class="tooltip" role="tooltip"><div class="tooltip-arrow"></div><div class="tooltip-inner tooltip-inner-wide tooltip-inner-left"></div></div>',
			placement: 'top',
			title: 'Changing the Publish Status from Scheduled Post to Published or Unpublished will delete the Facebook Post connected to this event. Any references to that link will result in a "Not Found" error on Facebook. This event will be available on Facebook at the scheduled event time.',
			html: true,
		});
		$('.chg-to-scheduled-post-tooltip-fb').tooltip({
			template: '<div class="tooltip" role="tooltip"><div class="tooltip-arrow"></div><div class="tooltip-inner tooltip-inner-wide tooltip-inner-left"></div></div>',
			placement: 'top',
			title: 'Changing the Publish Status from Published or Unpublished to Scheduled Post will immediately create a Facebook post stating that you will be live at the scheduled event time.',
			html: true,
		});
		$('.chg-to-scheduled-post-tooltip-yt').tooltip({
			template: '<div class="tooltip" role="tooltip"><div class="tooltip-arrow"></div><div class="tooltip-inner tooltip-inner-wide tooltip-inner-left"></div></div>',
			placement: 'top',
			title: 'Changing the Publish Status from Published to Scheduled Event will immediately create a YouTube event stating that you will be live at the scheduled event time.',
			html: true,
		});
		$('.create-scheduled-post-tooltip-fb').tooltip({
			template: '<div class="tooltip" role="tooltip"><div class="tooltip-arrow"></div><div class="tooltip-inner tooltip-inner-wide tooltip-inner-left"></div></div>',
			placement: 'top',
			title: 'Creating a Scheduled Post will immediately create a Facebook post stating that you will be live at the scheduled event time.',
			html: true,
		});
		$('.create-scheduled-post-tooltip-yt').tooltip({
			template: '<div class="tooltip" role="tooltip"><div class="tooltip-arrow"></div><div class="tooltip-inner tooltip-inner-wide tooltip-inner-left"></div></div>',
			placement: 'top',
			title: 'Creating a Scheduled Event will immediately build a YouTube Event stating that it will be live at the scheduled event time.',
			html: true,
		});
		$('.no-edit-scheduled-post-tooltip-fb').tooltip({
			template: '<div class="tooltip" role="tooltip"><div class="tooltip-arrow"></div><div class="tooltip-inner tooltip-inner-wide tooltip-inner-left"></div></div>',
			placement: 'top',
			title: "A scheduled post cannot be edited once created. To change its details, delete and create a new one.",
			html: true,
		});
		$('.no-edit-scheduled-post-tooltip-yt').tooltip({
			template: '<div class="tooltip" role="tooltip"><div class="tooltip-arrow"></div><div class="tooltip-inner tooltip-inner-wide tooltip-inner-left"></div></div>',
			placement: 'top',
			title: "A scheduled event cannot be edited once created. To change its details, delete and create a new one.",
			html: true,
		});
	}

	disablePublishStatus() {
		return (
			this.social_media_account_type === this.social_media.TYPE_YOUTUBE &&
			this.social_media_original_account_type === this.social_media.TYPE_YOUTUBE &&
			this.social_media_form.is_new === false
		);
	}

	displayCannotEditStatusMsg() {
		return this.social_media_form?.publish_status === this.social_media.PUBLISHED_POST && this.social_media_account_type === this.social_media.TYPE_YOUTUBE;
	}

	hasRegisteredSocialMediaAccts (){
		return this.socialMediaAccts != null && this.socialMediaAccts.length > 0;
	}

	hasCrossposts (simulcast) {
		return simulcast.type == this.DESTINATION_TYPE_FB_PAGE && simulcast.hasOwnProperty('crossposts') && simulcast.crossposts != null && simulcast.crossposts.length > 0;
	}

	isCrosspostInList (crosspost_id, crosspost_list){

		if (crosspost_list != null){
			for (let crosspost of crosspost_list){
				if (crosspost.destinationId == crosspost_id){
					return true;
				}
			}
		}
		return false;
	}

	showEditSocialMediaDestinationDlg (entry_to_edit) {

		trackMixpanelEvent(MPEventName.SOCIAL_DESTINATION_SCHEDULE_EDIT, {
         [MPEventProperty.TRANSCODED_SCHEDULE_UUID]: entry_to_edit.channelId,
		 [MPEventProperty.EVENT_STATUS]: entry_to_edit.publishStatus
        }); 

		this.validation.clear();
		this.social_media_form_validation_error = null;
		this.destination_options_error = null;
		this.crosspost_destination_error = null;

		// determine index of item to be edited
		var index_to_edit = this.simulcasts.indexOf(entry_to_edit);

		this.show_remove_scheduled_post_msg = false;
		this.show_change_to_scheduled_post_msg = false;
		this.show_create_scheduled_post_msg = false;

		this.social_media_form = {
			is_new: false,
			index: index_to_edit,
			account_id: entry_to_edit.channelId,
			title: entry_to_edit.title,
			description: entry_to_edit.description,
			privacy: entry_to_edit.privacy,
			publish_status: entry_to_edit.publishStatus,
			publish_status_original: entry_to_edit.publishStatus,
			crosspost_destinations: null,
			imageUrl: entry_to_edit.imageUrl
		};

		// ensure proper destination list is loaded for selected account_id
		this.onSocialMediaAcctChanged().then(() => {

			// destination_id needs to be set after the dropdown is loaded, otherwise the proper item won't get selected
			this.social_media_form.destination_id = entry_to_edit.destinationId;
			this.onDestinationChanged().then(() => {

				this.social_media_form.privacy = entry_to_edit.privacy;
				this.social_media_form.publish_status = entry_to_edit.publishStatus;

				var cache_index = this.getDestinationCacheIndex(this.social_media_form.account_id, this.social_media_form.destination_id);
				if (this.crosspost_destinations_cache.hasOwnProperty(cache_index)){
					this.social_media_form.crosspost_destinations = this.crosspost_destinations_cache[cache_index];
					// look through our list of crosspost destinations and set them as selected if they are in the entry_to_edit list
					for (let dest of this.social_media_form.crosspost_destinations){
						if (this.isCrosspostInList(dest.destinationId, entry_to_edit.crossposts)){
							dest.isSelected = true;
						}
					}
				}

			});
		});

		$('#' + this.social_media_dest_modal_id).modal('show');
	}

	disableSaveButton () {
		return this.imageCroppedButNotUploaded || this.is_loading_destination_options;
	}

	canShowEditBtn () {
		return this.auth.getCurrentUser().hasPerm('social_media.update');
	}

	canEditSimulcast(simulcast) {
		return !simulcast.uuid || simulcast.publishStatus !== this.social_media.SCHEDULED_POST;
	}

	canShowDeleteBtn () {
		return this.auth.getCurrentUser().hasPerm('social_media.delete');
	}

	canShowAddSocialMediaDestinationBtn () {

		if (!this.auth.getCurrentUser().hasPerm('social_media.add')){
			return false;
		}

		if (this.simulcasts == null){
			return true;
		}

		var hasYouTube = false;
		var hasFacebook = false;

		for(var i=0; i < this.simulcasts.length; i++){
			var simulcast = this.simulcasts[i];
			if (this.social_media.isFacebookType(simulcast.type)){
				hasFacebook = true;
			} else if (this.social_media.isYouTubeType(simulcast.type)){
				hasYouTube = true;
			}
		}

		// don't show the "add social media destination" button if they already have all types
		return !(hasYouTube && hasFacebook);
	}

	showAddSocialMediaDestinationDlg (){

		this.validation.clear();
		this.social_media_form_validation_error = null;
		this.destination_options_error = null;
		this.crosspost_destination_error = null;

		this.destination_options = null;

		this.show_remove_scheduled_post_msg = false;
		this.show_change_to_scheduled_post_msg = false;
		this.show_create_scheduled_post_msg = false;

		this.social_media_form = {
			is_new: true,
			account_id: this.socialMediaAccts.length == 1 ? this.socialMediaAccts[0].uuid : null,
			destination_id: null,
			title: this.defaultTitle || '',
			description: '',
			privacy: null,
			publish_status: null,
			publish_status_original: null,
			crosspost_destinations: null,
			imageUrl: null,
		};
		// if account is selected, we need to load the destination list for that account
		if (this.social_media_form.account_id != null) {
			this.onSocialMediaAcctChanged();
		}
		$('#' + this.social_media_dest_modal_id).modal('show');
	}

	getDestinationType (destination_id) {
		for (var i = 0; i < this.destination_options.length; i++) {
			if (this.destination_options[i].id == destination_id) {
				return this.destination_options[i].type;
			}
		}
		return '';
	}

	getDestinationNameFormatted (destination_id) {
		if (this.destination_options != null) {
			for (var i = 0; i < this.destination_options.length; i++) {
				var item = this.destination_options[i];
				if (item.id == destination_id) {
					return item.title;
				}
			}
		}
		return 'Unknown';
	}

	getSocialMediaAccountName (account_id) {
		var acct = this.getSocialMediaAcctById(account_id);
		if (acct != null) {
			return acct.name;
		}
		return 'Unknown';
	}

	doesSaveSocialMediaDestinationFailValidation () {

		this.validation.clear();

		// check required fields to ensure they aren't empty
		this.validation.checkForEmpty('account_id', this.social_media_form.account_id);
		this.validation.checkForEmpty('destination_id', this.social_media_form.destination_id);
		this.validation.checkForEmpty('privacy', this.social_media_form.privacy);
		this.validation.checkForEmpty('publish_status', this.social_media_form.publish_status);
		if (this.social_media_form.publish_status === this.social_media.SCHEDULED_POST){
			this.validation.checkForEmpty('title', this.social_media_form.title);
		}

		var has_validation_error = this.validation.hasError();

		this.social_media_form_validation_error = has_validation_error ? 'Please specify a value for the highlighted fields above.' : null;

		// if no "required field" errors, then ...
		if (!has_validation_error) {

			// ensure the selected account's status is good. Otherwise don't allow user to save.
			// The idea behind this was it was felt that if we allowed users to save using social media accounts that we know have an issue,
			// then we are just asking for lots of support calls. So in order to avoid that, we will display an error message instead (which
			// will hopefully make clear to the user what the issue is and they can resolve themselves). The one exception to this is if they
			// are editing an existing entry that happens to use an invalid social media account. In that case we will still allow them to edit
			// the other fields. But we still have some possible annoying side effects. When a user first adds an account, if they don't have live streaming
			// enabled, then they won't be able to setup their schedule at that time. They'll have to set a reminder to come back later and do it.
			// While making users set reminders is a situation we'd like to avoid, we are okay with it for phase 1. Can revisit later.
			if (!this.social_media_form.hasOwnProperty('index') || this.social_media_form.account_id != this.simulcasts[this.social_media_form.index].channelId) {

				var acct = this.getSocialMediaAcctById(this.social_media_form.account_id);
				if (acct != null) {
					if (acct.status != this.social_media.STATUS_OK) {
						has_validation_error = true;
						this.validation.setError('account_id');

						const type_label = this.social_media.getTypeAsLabel(acct.type);

						if (acct.status == this.social_media.STATUS_TOKEN_INVALID) {
							this.social_media_form_validation_error = 'We do not currently have access to the selected social media account. In order to use this account, you will need to provide access. This can be done from the Social Media page.';
						} else if (acct.status == this.social_media.STATUS_NOT_ENABLED) {
							this.social_media_form_validation_error = `The selected social media account does not have live streaming enabled on ${type_label}. In order to use this account, you will first need to visit ${type_label} and enable live streaming. More info can be found on the Social Media page.`;
						} else if (acct.status == this.social_media.STATUS_ENABLED_BUT_NOT_ACTIVE) {
							this.social_media_form_validation_error = `Live streaming has not yet been approved for this ${type_label} channel. More info can be found on the Social Media page.`;
						} else if (acct.status == this.social_media.STATUS_UNKNOWN) {
							this.social_media_form_validation_error = `We are unable to reach ${type_label} to determine the status of this account. In order to use this account you can try refreshing the page, or try again later.`;
						} else {
							console.log(`Unable to determine error message because account status is invalid. Account Status:  ${acct.status}`);
						}
					}
				}
			}

			// if we don't already have an error to report, and we have entries in our simulcasts list, then ensure we aren't
			// adding a simulcast with the same type (fb, yt, etc) of an existing entry. There can only be 1 entry for each type.
			if (!has_validation_error && this.simulcasts != null) {

				if (this.social_media_form.is_new){
					// ensure type of new destination isn't same type as existing destinations
					var acct_type = this.getSocialMediaAccountType(this.social_media_form.account_id);
					if (this.hasMatchingSimulcastType(this.simulcasts, acct_type)){
						var type_as_label = this.social_media.getTypeAsLabel(acct_type);
						this.social_media_form_validation_error = `A ${type_as_label} social media destination already exists. Only 1 ${type_as_label} destination is allowed per Web Event.`;
						has_validation_error = true;
					}
				} else {
					// ensure type of destination isn't same type as existing destinations (but ignore itself)
					var acct_type = this.getSocialMediaAccountType(this.social_media_form.account_id);
					if (this.hasMatchingSimulcastTypeButExclude(this.simulcasts, acct_type, this.social_media_form.index)){
						var type_as_label = this.social_media.getTypeAsLabel(acct_type);
						this.social_media_form_validation_error = `A ${type_as_label} social media destination already exists. Only 1 ${type_as_label} destination is allowed per Web Event.`;
						has_validation_error = true;
					}
				}
			}

			// if we have a recurring schedule, then we can't have any Facebook simulcasts with Publish Status of "Scheduled Post"
			if (!has_validation_error && this.isRecurring && this.social_media_form.publish_status == this.social_media.SCHEDULED_POST) {
				this.social_media_form_validation_error = 'A "Scheduled Post" cannot be used with a recurring schedule. Please either change the Publish Status to something other than "Scheduled Post", or make this a non-recurring schedule.';
				has_validation_error = true;
			}

			// if "Scheduled Post", then schedule start date/time must fall in a certain time range
			if (!has_validation_error && this.social_media_form.publish_status == this.social_media.SCHEDULED_POST) {
				let start_date_time = moment(this.eventStartDateTime);
				let earliest_acceptable_time = moment(start_date_time).subtract(7, 'days');
				let latest_acceptable_time = moment(start_date_time).subtract(10, 'minutes');
				let now = moment();
				// ensure start date/time isn't more than 7 days away or less than 10 minutes away
				if (now.isBefore(earliest_acceptable_time)){
					has_validation_error = true;
					this.social_media_form_validation_error = 'An event that contains a Facebook Scheduled Post or YouTube Scheduled Event (events that appear in the destination in advance) cannot be scheduled more than 7 days in advance.';
				}
				// ensure start date/time isn't less than 10 minutes away
				else if (now.isAfter(latest_acceptable_time)){
					has_validation_error = true;
					this.social_media_form_validation_error = 'An event that contains a Facebook Scheduled Post or YouTube Scheduled Event (events that appear in the destination in advance) must be scheduled more than 10 minutes prior to the event start.';
				}
			}

			// ensure title and description fields do not contain a '<' or '>'
			if (!has_validation_error){
				if (this.social_media_form.title.indexOf('<') !== -1 || this.social_media_form.title.indexOf('>') !== -1){
					has_validation_error = true;
					this.validation.setError('title');
					this.social_media_form_validation_error = "Title must not contain '<' or '>'.";
				} else if (this.social_media_form.description.indexOf('<') !== -1 || this.social_media_form.description.indexOf('>') !== -1){
					has_validation_error = true;
					this.validation.setError('description');
					this.social_media_form_validation_error = "Description must not contain '<' or '>'.";
				}
			}

			// ensure title does not exceed 128 bytes (Youtube's Limit)
			if (!has_validation_error){
				const validateTitleByteSize = (textEncoder) => {
					if ((textEncoder.encode(this.social_media_form.title)).length > this.TITLE_MAX_BYTE_LENGTH){
						has_validation_error = true;
						this.validation.setError('title');
						this.social_media_form_validation_error = "Title limit exceeded";
					}
				}
				if (typeof TextEncoder === "undefined") {
					const textEncoder = textEncoderPolyfill();
					validateTitleByteSize(textEncoder);
				} else {
					const textEncoder = new TextEncoder();
					validateTitleByteSize(textEncoder);
				}
			}

			// ensure number of selected crossposts doesn't exceed max allowed
			if (!has_validation_error){
				const account_type = this.getSocialMediaAccountType(this.social_media_form.account_id);
				const dest_type = this.getDestinationType(this.social_media_form.destination_id);
				if (this.social_media.isFacebookType(account_type) && dest_type === this.DESTINATION_TYPE_FB_PAGE && this.getSelectedCrossposts().length > this.MAX_CROSSPOSTS ){
					has_validation_error = true;
					this.validation.setError('crossposts');
					this.social_media_form_validation_error = `More than ${this.MAX_CROSSPOSTS} crossposts have been selected. `;
				}
			}
		}

		return has_validation_error;
	}

	// returns true if the given list of simulcasts contains one with the given type, but one of the indexes can be ignored (used when editing an existing item)
	hasMatchingSimulcastTypeButExclude (simulcasts, type, index_to_exclude){
		for (var i=0; i < simulcasts.length; i++){
			var simulcast = simulcasts[i];
			if (i != index_to_exclude){
				if (this.social_media.isFacebookType(simulcast.type) && this.social_media.isFacebookType(type)){
					return true;
				} else if (this.social_media.isYouTubeType(simulcast.type) && this.social_media.isYouTubeType(type)){
					return true;
				}
			}
		}
		return false;
	};

	// returns true if the given list of simulcasts contains one with the given type (used when adding a new item)
	hasMatchingSimulcastType (simulcasts, type){
		for (var i=0; i < simulcasts.length; i++){
			var simulcast = simulcasts[i];
			if (this.social_media.isFacebookType(simulcast.type) && this.social_media.isFacebookType(type)){
				return true;
			} else if (this.social_media.isYouTubeType(simulcast.type) && this.social_media.isYouTubeType(type)){
				return true;
			}
		}
		return false;
	};

	getSelectedCrossposts () {

		var list = [];
		if (this.social_media_form && this.social_media_form.crosspost_destinations){
			for (var dest of this.social_media_form.crosspost_destinations){
				if (dest.isSelected){
					list.push({
						destinationId: dest.destinationId,
						destinationName: dest.destinationName
					});
				}
			}
		}
		return list;
	}

	saveSocialMediaDestination () {

		// if we have form validation errors, then don't go any further
		if (this.doesSaveSocialMediaDestinationFailValidation()){
			return false;
		}

		if (this.social_media_form.hasOwnProperty('index')) {

			// update existing entry
			var index = this.social_media_form.index;
			this.simulcasts[index].channelId = this.social_media_form.account_id;
			this.simulcasts[index].type = this.getDestinationType(this.social_media_form.destination_id);
			this.simulcasts[index].destinationId = this.social_media_form.destination_id;
			this.simulcasts[index].destinationName = this.getDestinationNameFormatted(this.social_media_form.destination_id);
			this.simulcasts[index].title = this.social_media_form.title,
			this.simulcasts[index].description = this.social_media_form.description,
			this.simulcasts[index].privacy = this.social_media_form.privacy;
			this.simulcasts[index].publishStatus = this.social_media_form.publish_status;
			this.simulcasts[index].channelName = this.getSocialMediaAccountName(this.social_media_form.account_id);
			this.simulcasts[index].crossposts = this.getSelectedCrossposts();
			this.simulcasts[index].imageUrl = this.social_media_form.imageUrl;

		} else {

			if (typeof this.simulcasts === 'undefined' || this.simulcasts == null){
				this.simulcasts = [];
			}
			// add new entry
			this.simulcasts.push({
				channelId: this.social_media_form.account_id,
				type: this.getDestinationType(this.social_media_form.destination_id),
				destinationId: this.social_media_form.destination_id,
				destinationName: this.getDestinationNameFormatted(this.social_media_form.destination_id),
				title: this.social_media_form.title,
				description: this.social_media_form.description,
				privacy: this.social_media_form.privacy,
				publishStatus: this.social_media_form.publish_status,
				channelName: this.getSocialMediaAccountName(this.social_media_form.account_id),
				crossposts: this.getSelectedCrossposts(),
				imageUrl: this.social_media_form.imageUrl,
			});
		}

		$('#' + this.social_media_dest_modal_id).modal('hide');
	}

	getSocialMediaAcctById (account_id) {
		if (this.socialMediaAccts != null) {
			for (var i = 0; i < this.socialMediaAccts.length; i++) {
				var acct = this.socialMediaAccts[i];
				if (acct.uuid == account_id) {
					return acct;
				}
			}
		}
		return null;
	}

	getSocialMediaAccountType (account_id) {
		var acct = this.getSocialMediaAcctById(account_id);
		if (acct != null) {
			return acct.type;
		}
		return '';
	}

	// formats both privacy and publish status options
	getFormattedOption (option){
		if (option == null || option == ''){
			return '';
		}
		if (option == 'all_friends'){
			return 'Friends';
		}
		if (option == 'friends_of_friends'){
			return 'Friends Of Friends';
		}
		if (option == this.social_media.SCHEDULED_POST){
			if (this.social_media_account_type == this.social_media.TYPE_YOUTUBE) {
				return 'Scheduled Event';
			}
			return 'Scheduled Post';
		}
		// capitalize first letter
		return option.charAt(0).toUpperCase() + option.slice(1);
	}

	convertToNameValueList (options) {
		var list = [];
		for (var i=0; i < options.length; i++){
			var option = options[i];
			list.push({
				name: this.getFormattedOption(option),
				value: option
			});
		}
		return list;
	}

	setCrosspostDestinationsSelectionStatus (isSelected) {

		if (this.social_media_form.crosspost_destinations != null){
			for (var dest of this.social_media_form.crosspost_destinations){
				dest.isSelected = isSelected;
			}
		}
	}

	loadCrosspostDestinations () {

		var deferred = this.$q.defer();

		this.social_media_form.crosspost_destinations = null;
		this.crosspost_destination_error = null;

		// is this a facebook account?
		var type = this.getSocialMediaAccountType(this.social_media_form.account_id);
		if (this.social_media.isFacebookType(type)) {

			// is the destination a page?
			if (this.getDestinationType(this.social_media_form.destination_id) == this.DESTINATION_TYPE_FB_PAGE){

				// do we already have the crosspost destinations in our cache?
				var cache_index = this.getDestinationCacheIndex(this.social_media_form.account_id, this.social_media_form.destination_id);
				if (this.crosspost_destinations_cache.hasOwnProperty(cache_index)){

					this.social_media_form.crosspost_destinations = this.crosspost_destinations_cache[cache_index];

					deferred.resolve();

				} else {

					this.is_loading_crosspost_destinations = true;

					var url = `${jcs.api.url_v3}/customers/${this.auth.getCurrentUser().customerID}/socialmedia/${this.social_media_form.account_id}/destinations/${this.social_media_form.destination_id}/crosspostdestinations`;
					this.$http.get(url, { withCredentials: true }).then((response) => {

						// TODO: remove this check for empty string once backend gets updated
						if (response.data == ""){
							response.data = [];
						}

						this.crosspost_destinations_cache[cache_index] = response.data;
						this.social_media_form.crosspost_destinations = response.data;

						deferred.resolve();

					}).catch((reason) => {

						this.crosspost_destination_error = 'Unable to load crossposts for the selected Facebook page.';
						deferred.reject(reason);

					}).finally(() => {

						this.is_loading_crosspost_destinations = false;

					});

				}
			} else {

				deferred.resolve();
			}
		} else {

			deferred.resolve();
		}

		return deferred.promise;
	}

	onDestinationChanged () {

		const deferred = this.$q.defer();

		this.show_change_to_scheduled_post_msg = false;
		this.show_create_scheduled_post_msg = false;

		// load the privacy and publish options associated with the selected destination
		const cache_index = this.getDestinationCacheIndex(this.social_media_form.account_id, this.social_media_form.destination_id);
		if (this.destination_cache.hasOwnProperty(cache_index)){
			const destination = this.destination_cache[cache_index];

			// fbPublishOnly essentially overrides manualStart since it filters out everything but 'published'
			// for both manual and scheduled
			if (this.fbPublishOnly) {
				destination.manualPublishTypes = destination.manualPublishTypes.filter(dest => dest === 'published');
				destination.schedulePublishTypes = destination.schedulePublishTypes.filter(dest => dest === 'published');
			}

			this.privacy_options = this.convertToNameValueList(destination.privacyOptions);
			this.publish_options = this.convertToNameValueList(this.manualStart ? destination.manualPublishTypes : destination.schedulePublishTypes);

			// clear our selected privacy and publish options
			this.social_media_form.privacy = null;
			this.social_media_form.publish_status = null;
			// auto select option if there is only 1
			if (this.privacy_options.length == 1) {
				this.social_media_form.privacy = this.privacy_options[0].value;
			}
			if (this.publish_options.length == 1) {
				this.social_media_form.publish_status = this.publish_options[0].value;
			}

			this.loadCrosspostDestinations().then(() => {
				// ensure all crosspost destinations are initially not selected
				this.setCrosspostDestinationsSelectionStatus(false);
				deferred.resolve();

			}).catch((reason) => {

				deferred.reject(reason);

			});

		} else {

			deferred.reject('Unable to load destination information.');
		}

		return deferred.promise;
	}

	getDestinationCacheIndex (account_id, destination_id) {
		return account_id + destination_id;
	}

	onFilePathChanged = (filePath) => {
		this.social_media_form.imageUrl = filePath;
		this.imageCroppedButNotUploaded = false;
		this.$scope.$apply();
	}

	onImageCropped = () => {
		this.imageCroppedButNotUploaded = true;
		this.$scope.$apply();
	}

	onImageUploadCancel = () => {
		this.imageCroppedButNotUploaded = false;
		this.$scope.$apply();
	}

	onPublishStatusChange () {

		this.show_remove_scheduled_post_msg = false;
		this.show_change_to_scheduled_post_msg = false;
		this.show_create_scheduled_post_msg = false;

		// determine if warning message needs to be displayed
		if (this.social_media_form.publish_status_original !== null){
			if (this.social_media_form.publish_status_original === this.social_media.SCHEDULED_POST && this.social_media_form.publish_status !== this.social_media.SCHEDULED_POST){
				this.show_remove_scheduled_post_msg = true;
			} else if (this.social_media_form.publish_status_original !== this.social_media.SCHEDULED_POST && this.social_media_form.publish_status === this.social_media.SCHEDULED_POST){
				this.show_change_to_scheduled_post_msg = true;
			}
		} else if (this.social_media_form.publish_status === this.social_media.SCHEDULED_POST){
			this.show_create_scheduled_post_msg = true;
		}
	}

	onSocialMediaAcctChanged () {

		let deferred = this.$q.defer();

		this.destination_options_error = null;
		this.social_media_form.destination_id = null;

		this.show_change_to_scheduled_post_msg = false;
		this.show_create_scheduled_post_msg = false;

		this.social_media_account_type = this.getSocialMediaAccountType(this.social_media_form.account_id);

		if (!this.social_media_original_account_type) {
			this.social_media_original_account_type = this.social_media_account_type;
		}

		// check to see if we already have this account's destination list cached
		if (this.destination_options_cache.hasOwnProperty(this.social_media_form.account_id)) {

			this.destination_options = this.destination_options_cache[this.social_media_form.account_id];
			// auto select if only 1 item
			if (this.destination_options.length == 1) {
				this.social_media_form.destination_id = this.destination_options[0].id;
				this.onDestinationChanged().then(() => {
					deferred.resolve();
				}).catch((reason) => {
					deferred.reject(reason);
				});
			} else {
				deferred.resolve();
			}

		} else { // not cached, so we'll need to fetch it

			let type_for_url = this.social_media_account_type == this.social_media.TYPE_FACEBOOK ? 'facebook' : 'youtube';

			this.is_loading_destination_options = true;

			let url = `${jcs.api.url_v3}/customers/${this.auth.getCurrentUser().customerID}/${type_for_url}/channels/${this.social_media_form.account_id}/destinations`;
			this.$http.get(url, { withCredentials: true }).then((response) => {

				this.destination_options = response.data;
				// cache our destination list by account id
				this.destination_options_cache[this.social_media_form.account_id] = this.destination_options;

				// cache each destination by account + destination (so we can access privacy/publish options)
				this.destination_options.forEach(destination => {
					this.destination_cache[this.getDestinationCacheIndex(this.social_media_form.account_id, destination.id)] = destination;
				});

				// rename yt_streamnow title
				this.destination_options.forEach(destination => {
					if (destination.type == 'yt_streamnow') {
						destination.title = this.SOCIAL_MEDIA_EVENT_STREAM_NOW_LABEL;
					} else if (destination.type == 'fb_timeline') {
						destination.title = 'Timeline';
					}
				});

				// auto select if only 1 item
				if (this.destination_options.length == 1) {
					this.social_media_form.destination_id = this.destination_options[0].id;
					this.onDestinationChanged().then(() => {
						deferred.resolve();
					}).catch((reason) => {
						deferred.reject(reason);
					});
				} else {
					deferred.resolve();
				}

			}).catch((reason) => {

				this.destination_options_error = 'Unable to load destinations';
				this.destination_options = null;
				deferred.reject(reason);

			}).finally(() => {

				this.is_loading_destination_options = false;

			});
		}

		return deferred.promise;
	}

	getSocialMediaStatusDesc (status) {
		switch (status) {
			case this.social_media.STATUS_TOKEN_INVALID:
				return 'No Access';
			case this.social_media.STATUS_NOT_ENABLED:
				return 'Not allowed to Live Stream';
			case this.social_media.STATUS_ENABLED_BUT_NOT_ACTIVE:
				return 'Channel Update Required'; // or 'Live Stream Approval Pending' or 'Possible Channel Update Required'
			default:
				return 'Unknown Status';
		}
	}

	removeSocialMediaDestination (destination) {

		if (this.simulcasts != null) {
			var indexToRemove = this.simulcasts.indexOf(destination);
			if (indexToRemove != -1) {
				this.simulcasts.splice(indexToRemove, 1);
			}
		}

		trackMixpanelEvent(MPEventName.SOCIAL_DESTINATION_SCHEDULE_DELETE, {
			[MPEventProperty.TRANSCODED_SCHEDULE_UUID]: destination.channelId,
		   }); 
	}

	haveAccessToSocialMediaAccount (info) {
		var acct = this.getSocialMediaAcctById(info.channelId);
		if (acct != null) {
			return acct.status == this.social_media.STATUS_OK;
		}
		return true; // lets only show errors if we know for a fact we have one
	}

	// this doesn't currently work; see note at top;
	showMoreInfoDialog (schedule) {
		const acct = this.getSocialMediaAcctById(schedule.channelId);

		this.more_info = {
			title: 'Unable to Stream',
			code: acct.status,
			type: acct.type,
		};

		// update title in some situations
		switch (this.more_info.code) {
			case 'TOKEN_INVALID':
				this.more_info.title = `Unable to Access ${this.social_media.getTypeAsLabel(this.more_info.type)} Account`;
				break;
			case 'NOT_ENABLED':
				if (this.more_info.type == this.social_media.TYPE_YOUTUBE) {
					this.more_info.title = 'Not Allowed to Live Stream';
				} else if (this.more_info.type == this.social_media.TYPE_FACEBOOK) {
					this.more_info.title = 'Not Allowed to Publish Video';
				}
				break;
			case 'ENABLED_BUT_NOT_ACTIVE':
				this.more_info.title = 'Live Streaming Not Yet Approved';
				break;
		}

		$('#' + this.more_info_modal_id).modal('show');
	}
}

// example usage:
// <simulcast-selector simulcasts="obj.web_event_simulcasts" social-media-accts="obj.social_media_accts" no-simulcasts-msg="optional message goes here"></simulcast-selector>
// or
// <simulcast-selector simulcasts="obj.web_event_simulcasts" social-media-accts="obj.social_media_accts" no-simulcasts-msg="optional message goes here" is-sim-live="true" manual-start="true"></simulcast-selector>
//
export default class SimulcastSelectorComponent {
	constructor() {
		this.template = require('./simulcastSelector.html').default;
		this.bindings = {
			simulcasts: '=',
			socialMediaAccts: '<',
			noSimulcastsMsg: '<',
			isSimLive: '<',
			isUploader: '<',
			defaultTitle: '<',
			isRecurring: '<',
			manualStart: '@',
			eventStartDateTime: '<',
			fbPublishOnly: '<',
		};
		this.controller = SimulcastSelectorCtrl;
	}
}
