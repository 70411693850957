import { Dispatch } from 'react';

export interface LibraryThumbnailUploaderState {
  display: boolean;
}

export interface Action {
  type: ACTION_TYPE;
  payload?: any;
}

export enum ACTION_TYPE {
  DISPLAY = 'DISPLAY',
}

export function reducer(state: LibraryThumbnailUploaderState, action: Action): LibraryThumbnailUploaderState {
  switch (action.type) {
    case ACTION_TYPE.DISPLAY:
      return { ...state, display: action.payload };
    default:
      throw new Error('Invalid Action Type: ' + action.type);
  }
}
