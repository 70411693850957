import { perms } from '../../jcs';
import { contactsModule } from './index';

function routeConfig($provide, $routeProvider) {
	'ngInject';

	$provide.factory('$routeProvider', () => {
		return $routeProvider;
	});

	$routeProvider.when(contactsModule.routes.list, {
		controller: contactsModule.controllers.default,
		template: require('./contacts.tmpl.html').default,
		activenav: contactsModule.name,
		access: {
			loginRequired: true,
			permissions: [perms['users.get']],
		},
	});
}

export default routeConfig;
