import axios, { AxiosResponse } from "axios";
import { LatencyDetails } from "../../constants";
import * as rax from "retry-axios";

const axiosInstance = axios.create();

export async function checkDataCenterLatency(url: string): Promise<AxiosResponse<LatencyDetails> | null> {
  let startTime: number;
  let endTime: number;

  const requestInterceptor = axiosInstance.interceptors.request.use(
    (config) => {
      startTime = new Date().getTime();
      return config;
    },
    (error) => Promise.reject(error)
  );

  const responseInterceptor = axiosInstance.interceptors.response.use(
    (response) => {
      endTime = new Date().getTime();
      response.data = { dataCenterName: response.data, duration: endTime - startTime };
      return response;
    },
    (error) => Promise.reject(error)
  );

  try {
    rax.attach(axiosInstance);
    return await axiosInstance.head(url, {
      raxConfig: {
        instance: axiosInstance,
        retry: 5,
        noResponseRetries: 5,
        backoffType: 'exponential',
        onRetryAttempt: (err): void => {
          const cfg = rax.getConfig(err);
          console.log(`Retry attempt #${cfg?.currentRetryAttempt}`);
        },
      },
    });
  } catch (e) {
    console.error(e);
  } finally {
    axiosInstance.interceptors.request.eject(requestInterceptor);
    axiosInstance.interceptors.response.eject(responseInterceptor);
  }
  return null;
}
