import React, { FC } from 'react';
import PropTypes from 'prop-types';

const ModalFooter: FC = ({ children }) => {
  return <div className="modal-footer">{children}</div>;
};

ModalFooter.propTypes = {
  children: PropTypes.node,
};

ModalFooter.displayName = 'ModalFooter';

export default ModalFooter;
