import axios, {
  AxiosResponse,
  AxiosRequestConfig,
  La1AxiosRetryInstance,
  La1AxiosRequestConfig,
  CancelTokenSource,
} from 'axios';
import { api } from '../../../v2.snapshot/js/jcs';
import { SignedUrlResponse, WebEventProfile } from './eventUploadTypes';
import { Dispatch } from 'react';
import La1Retry, { La1BackOffType } from '../../custom_modules/retry';
import { La1RetryConfig } from '../../custom_modules/retry/';
import { ACTION_TYPE } from './context';
import * as rax from 'retry-axios';

export async function getEncoderProfiles() {
  const url = `${api.url}/streamprofiles`;

  try {
    const response = await axios.get(url, { withCredentials: true });
    return response.data;
  } catch (e) {
    console.error(e);
    return [];
  }
}

export async function getSignedUrl(
  customerId: string,
  eventProfile: string,
  payload: string
): Promise<AxiosResponse<SignedUrlResponse>> {
  const url = `${api.url_v3}/customers/${customerId}/eventprofiles/${eventProfile}/upload`;
  const config: AxiosRequestConfig = {
    headers: {
      'Content-Type': 'application/json',
    },
    withCredentials: true,
  };

  return axios.post(url, payload, config);
}

export async function updateUploadStatus(statusCallbackUrl: string): Promise<AxiosResponse> {
  const axiosInstance = axios.create();

  rax.attach(axiosInstance);
  return axiosInstance.put(
    statusCallbackUrl,
    { status: 'PENDING' },
    {
      withCredentials: true,
      raxConfig: {
        instance: axiosInstance,
        retry: 5,
        statusCodesToRetry: [
          [429, 429],
          [502, 504],
        ],
        backoffType: 'exponential',
        onRetryAttempt: (err): void => {
          const cfg = rax.getConfig(err);
          console.error(`Retry attempt #${cfg?.currentRetryAttempt}`);
        },
      },
    }
  );
}

async function getResumeStartByteIndex(
  url: string,
  contentLength: number,
  checksumBase64: string,
  supplyCancelToken: (source: CancelTokenSource) => void
): Promise<number | null> {
  // Query the number of bytes that were received by Google Cloud Storage via the Range header.
  // See https://cloud.google.com/storage/docs/performing-resumable-uploads#xml-api_3
  const cancelToken = axios.CancelToken;
  const source = cancelToken.source();
  supplyCancelToken(source);

  const axiosInstance: La1AxiosRetryInstance = axios.create();
  const la1Retry = new La1Retry(axiosInstance);

  const config: La1AxiosRequestConfig = {
    headers: {
      'Content-Range': `bytes */${contentLength}`,
      'X-Goog-Hash': `crc32c=${checksumBase64}`,
    },
    validateStatus: (statusCode) => statusCode === 308 || (statusCode >= 200 && statusCode < 300),
    cancelToken: source.token,
    la1RetryConfig: new La1RetryConfig({
      onSuccess: (response) => {
        // This header can take several seconds to appear.
        if (response.status === 308 && typeof response.headers.range !== 'string') {
          return la1Retry.retryRequest(response.config, new Error('Range header not found'));
        }
        return response;
      },
      instance: axiosInstance,
      retries: 5,
      initialRetryDelay: 5000,
      backoffType: La1BackOffType.STATIC,
      ignoreNetworkErrors: true,
      onRetryAttempt: (err) => {
        console.log('Failed to query range header', err);
      },
    }),
  };
  const response = await axiosInstance.put(url, null, config);

  if (response === undefined || (response.status >= 200 && response.status < 300)) {
    // The response can be undefined if the request was canceled.
    // If we got a 2xx, the upload is complete and we will never find a range header.
    return null;
  }

  const rangeHeader = response.headers.range;

  return Number(rangeHeader.slice(rangeHeader.indexOf('-') + 1)) + 1;
}

export const uploadFile = ({
  file,
  checksumBase64,
  uploadUrl,
  dispatch,
  setSource,
}: {
  file: Blob;
  checksumBase64: string;
  uploadUrl: string;
  dispatch: Dispatch<any>;
  setSource: (source: any) => void;
}): Promise<AxiosResponse> => {
  const cancelToken = axios.CancelToken;
  const source = cancelToken.source();

  const axiosInstance: La1AxiosRetryInstance = axios.create();

  let resumedProgressOffset = 0;
  axiosInstance.onUploadProgress = function (progressEvent: ProgressEvent) {
    dispatch({ type: ACTION_TYPE.UPLOAD_PROGRESS, payload: resumedProgressOffset + progressEvent.loaded });
    dispatch({ type: ACTION_TYPE.UPLOAD_TOTAL, payload: file.size });
  };
  const la1Retry = new La1Retry(axiosInstance);

  const headers = {
    'Content-Type': 'application/octet-stream',
    'X-Goog-Hash': `crc32c=${checksumBase64}`,
  } as const;
  const config: La1AxiosRequestConfig = {
    headers,
    cancelToken: source.token,
    la1RetryConfig: new La1RetryConfig({
      instance: axiosInstance,
      retries: 5,
      initialRetryDelay: 5000,
      onRetryAttempt: async (err) => {
        const cfg = la1Retry.getConfig(err);
        console.log(`Upload File Retry Attempt #${cfg?.currentRetryAttempt}`);

        if ('response' in err && Number(err.response?.status) < 500) {
          // Start the upload from the beginning if there was a validation error.
          resumedProgressOffset = 0;
          return {
            headers,
            data: file,
          };
        }

        try {
          // Query the position to resume and continue the upload.
          const nextByte = await getResumeStartByteIndex(uploadUrl, file.size, checksumBase64, (retrySource): void => {
            setSource({
              tokens: [source, retrySource],
              cancel() {
                for (const token of this.tokens) {
                  token.cancel();
                }
              },
            });
          });

          if (nextByte === null) {
            // The request is complete.
            source.cancel();
            return;
          }

          resumedProgressOffset = nextByte;
          return {
            headers: {
              ...headers,
              'Content-Range': `bytes ${nextByte}-${file.size - 1}/${file.size}`,
            },
            data: file.slice(nextByte),
          };
        } catch (e) {
          console.error('Failed to query the range. Starting the upload from the beginning...');
          console.error(e);
          resumedProgressOffset = 0;
          return {
            headers,
            data: file,
          };
        }
      },
    }),
  };
  setSource(source);
  return axiosInstance.put(uploadUrl, file, config);
};

export async function getWebEventProfiles(customerId: string): Promise<WebEventProfile[]> {
  try {
    const response = await axios.get(`${api.url_v3}/customers/${customerId}/webeventprofiles`, {
      withCredentials: true,
    });
    return response.data;
  } catch (e) {
    console.error(e);
  }
  return [];
}
