import styled from '@emotion/styled';
import { customProperties as vars } from '@resi-media/resi-ui';

export const BlockErrorContainer = styled.div`
  font-size: ${vars.fontSize12};
  line-height: ${vars.fontSize16};
  background-color: #fff1f0;
  color: #cd4631;
  border: 2px solid #ffa39e;
  border-radius: ${vars.borderRadius2};
  text-align: center;
`;
