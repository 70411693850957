import 'angular-route';
import angular from 'angular';
import { react2angular } from 'react2angular';
import router from './module.passwordreset.routes';

import { PasswordResetPage, PasswordSetPage } from '../../../../src/pages';

export const passwordResetModule = {
  name: 'passwordreset',
  controllers: {
    passwordSet: 'passwordSetCtrl',
  },
  routes: {
    passwordReset: '/passwordreset',
    passwordSet: '/passwordreset/:resetToken',
  },
};

export default angular
  .module(passwordResetModule.name, ['ngRoute'])
  .config(router)
  .controller(passwordResetModule.controllers.passwordSet, require('./passwordSetCtrl'))
  .component('passwordReset', react2angular(PasswordResetPage))
  .component('passwordSet', react2angular(PasswordSetPage, ['encryptedToken'])).name;
