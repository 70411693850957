/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { FC, createRef, useState, ChangeEvent, DragEvent } from 'react';
import './style.scss';

interface FileDropzoneProps {
  disabled?: boolean;
  multiple?: boolean;
  label?: string;
  limitTxt?: string;
  flexDirection?: 'row' | 'row-reverse' | 'column' | 'column-reverse';
  accept?: string;
  minHeight?: string;
  onFilesAdded?: (fileList: File[]) => void;
}

const FileDropzone: FC<FileDropzoneProps> = ({
  disabled,
  multiple,
  label,
  flexDirection = 'row',
  limitTxt,
  accept,
  minHeight = 'unset',
  onFilesAdded,
}) => {
  const [highlight, setHighlight] = useState(false);
  const fileInputRef = createRef<HTMLInputElement>();

  const openFileDialog = (): void => {
    if (!disabled && fileInputRef && fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const fileListToArray = (files: FileList | null): File[] => {
    if (files) {
      return Array.from(files);
    }
    return [];
  };

  const handleFilesAdded = (event: ChangeEvent<HTMLInputElement>): void => {
    if (disabled) return;

    const files = event.target.files;
    if (onFilesAdded) {
      const array = fileListToArray(files);
      onFilesAdded(array);
    }
  };

  const handleDragOver = (event: DragEvent<HTMLDivElement>): void => {
    event.preventDefault();
    if (disabled) return;

    setHighlight(true);
  };

  const handleDragLeave = (): void => {
    setHighlight(false);
  };

  const handleDrop = (event: DragEvent<HTMLDivElement>): void => {
    event.preventDefault();

    if (disabled) return;

    const files = event.dataTransfer?.files;
    if (onFilesAdded) {
      const array = fileListToArray(files);
      onFilesAdded(array);
    }
    setHighlight(false);
  };

  return (
    <div
      className={`la1-dropzone ${highlight ? 'la1-dropzone__highlight' : ''}`}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
      onClick={openFileDialog}
      style={{ cursor: disabled ? 'default' : 'pointer', minHeight, flexDirection }}
    >
      <input
        ref={fileInputRef}
        className="la1-dropzone-file-input"
        type="file"
        accept={accept}
        multiple={multiple}
        onChange={handleFilesAdded}
      />
      <i className="fa fa-cloud-upload fa-2x la1-dropzone-icon" />
      {label && <span>{label}</span>}
      {!!limitTxt && <div className="la1-dropzone-limit-txt">{limitTxt}</div>}
    </div>
  );
};

FileDropzone.displayName = 'FileDropzone';

export default FileDropzone;
