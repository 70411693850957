import React, { ChangeEvent, FC, useContext, useEffect, useMemo, useState } from 'react';
import {
  EVENT_ANALYTICS_CONTEXT_KEYS,
  FACEBOOK_DESTINATION_TYPES,
  FB_CSV_EXPORT_FIELDS,
  MAX_FETCH_LIMIT_FOR_CSV_EXPORT,
  pages,
  PAGE_VALUE,
  FACEBOOK_GROUP_TIMELINE,
  FACEBOOK_PAGE,
  TIME_WINDOW_SET,
  TIME_WINDOW_VALUES,
} from '../../event-analytics-constants';
import { FacebookContext } from '../../../../contexts/eventanalytics';
import FacebookStatsGraphView from './facebook-stats-graph-view';
import { Option } from '@resi-media/resi-ui';
import { dateRangeValues, customDateRangeOption, lifeTimeValues } from '../../date-range-options';
import {
  downloadAsCSV,
  fetchAndMergeResponse,
  parseLineChartData,
  isMonthTabDisabled,
  getDateRangeOptions,
} from '../../utils';
import { exportCSVData, fetchLineChartData } from '../api';
import { DATA_PAGE_NUMBERS, DateType, lineChartDataType, TIME_WINDOWS } from '../../types';
import { EventAnalyticsContext } from '../../../../contexts/eventanalytics/event-analytics';
import { Parser } from 'json2csv';
import { MPEventName, MPEventProperty, trackMixpanelEvent } from '../../../../mixpanel';

interface FacebookStatsGraphProps {
  isDestinationTypeFetching: boolean;
  hasGraphPerms: boolean;
}

const FacebookStatsGraph: FC<FacebookStatsGraphProps> = ({ isDestinationTypeFetching, hasGraphPerms }): JSX.Element => {
  const { facebookAnalyticsState } = useContext(FacebookContext);
  const { EventAnalyticsState, dispatch } = useContext(EventAnalyticsContext);
  const { viewAllData, dateRangeOption, dateRange, eventId } = EventAnalyticsState;
  const { destinationType } = facebookAnalyticsState;
  const isMonthTimeWindowDisabled = isMonthTabDisabled(dateRangeOption?.value);
  const [dataPage, setDataPage] = useState<number>(0);
  const [dataPageName, setDataPageName] = useState<string>(PAGE_VALUE.VIEWERS);
  const [timeWindow, setTimeWindow] = useState<number>(0);
  const [isDatePickerOpen, setDatePickerOpen] = useState<boolean>(false);
  const [timeWindowValue, setTimeWindowValue] = useState<string>(TIME_WINDOW_VALUES.DAY);
  const [chartData, setChartData] = useState<lineChartDataType>({ labels: [], datasets: {} });
  const [isChartLoading, setChartLoading] = useState<boolean>(false);
  const [isExportCSVLoading, setExportCSVLoading] = useState<boolean>(false);

  const handleDataPageChange = (event: ChangeEvent<unknown>, newValue: DATA_PAGE_NUMBERS) => {
    setDataPage(newValue);
    if (!eventId) {
      setDataPageName(pages[newValue]);
    } else if (destinationType === FACEBOOK_DESTINATION_TYPES.PAGE) {
      setDataPageName(FACEBOOK_PAGE[newValue]);
    } else {
      setDataPageName(FACEBOOK_GROUP_TIMELINE[newValue]);
    }
  };

  const handleTimeWindowChange = (event: ChangeEvent<unknown> | null, newValue: TIME_WINDOWS) => {
    setTimeWindowValue(TIME_WINDOW_SET[newValue]);
    setTimeWindow(newValue);
  };

  const handleDateRangeChange = (item: Option[]) => {
    const selection = item[0]?.value;
    let dateRange: DateType = { startDate: '', endDate: '' };
    // Since we have only one custom handling case "c0".
    if (selection === customDateRangeOption.value) {
      setDatePickerOpen(true);
      dispatch({
        type: EVENT_ANALYTICS_CONTEXT_KEYS.DATE_RANGE_VALUE,
        payload: customDateRangeOption,
      });
    } else if (selection === '0' && viewAllData) {
      dateRange = lifeTimeValues[selection];
      dispatch({ type: EVENT_ANALYTICS_CONTEXT_KEYS.DATE_RANGE_VALUE, payload: item[0] });
      dispatch({ type: EVENT_ANALYTICS_CONTEXT_KEYS.DATE_RANGE, payload: dateRange });
    } else if (selection) {
      dateRange = dateRangeValues[selection];
      dispatch({ type: EVENT_ANALYTICS_CONTEXT_KEYS.DATE_RANGE_VALUE, payload: item[0] });
      dispatch({ type: EVENT_ANALYTICS_CONTEXT_KEYS.DATE_RANGE, payload: dateRange });
    }
  };

  const handleCustomDateRangeSelection = (startDate: string, endDate: string) => {
    if (startDate && endDate) {
      const dateRange = {
        startDate,
        endDate,
      };
      dispatch({ type: EVENT_ANALYTICS_CONTEXT_KEYS.DATE_RANGE, payload: dateRange });
      dispatch({
        type: EVENT_ANALYTICS_CONTEXT_KEYS.DATE_RANGE_VALUE,
        payload: customDateRangeOption,
      });
    }
  };

  const getChartData = async () => {
    setChartLoading(true);
    const response = await fetchLineChartData(
      EventAnalyticsState,
      facebookAnalyticsState,
      timeWindowValue,
      dataPageName
    );
    setChartLoading(false);
    const lineChartData = parseLineChartData(response, timeWindowValue, dataPageName);
    setChartData(lineChartData);
  };

  const exportCSV = async () => {
    setExportCSVLoading(true);
    const response = await fetchAndMergeResponse(
      (offSetValue1: string | number | undefined, offSetValue2: string | number | undefined) =>
        exportCSVData(EventAnalyticsState, facebookAnalyticsState, offSetValue1, offSetValue2),
      MAX_FETCH_LIMIT_FOR_CSV_EXPORT,
      'videoId',
      'timestamp'
    );
    setExportCSVLoading(false);
    const json2csvParser = new Parser({ fields: FB_CSV_EXPORT_FIELDS });
    const csv = json2csvParser.parse(response);
    downloadAsCSV(csv, 'Facebook-Analytics.csv');

    const mixpanelProps = {
      [MPEventProperty.TRANSCODED_EVENT_UUID]: eventId,
      [MPEventProperty.ANALYTICS_TYPE]: 'Facebook',
    };

    if (!eventId) {
      delete mixpanelProps[MPEventProperty.TRANSCODED_EVENT_UUID];
    }

    trackMixpanelEvent(MPEventName.CSV_EXPORT, mixpanelProps);
  };

  useMemo(() => {
    setDataPage(0);
    if (!eventId) {
      setDataPageName(pages[0]);
    } else if (destinationType === FACEBOOK_DESTINATION_TYPES.PAGE) {
      setDataPageName(FACEBOOK_PAGE[0]);
    } else {
      setDataPageName(FACEBOOK_GROUP_TIMELINE[0]);
    }
  }, [eventId, destinationType]);

  useEffect(() => {
    getChartData();
  }, [eventId, dateRange, destinationType, timeWindowValue, dataPageName]);

  return (
    <FacebookStatsGraphView
      data={chartData}
      dateRangeOptions={getDateRangeOptions(viewAllData)}
      isDestinationTypeFetching={isDestinationTypeFetching}
      isChartLoading={isChartLoading}
      isExportCSVLoading={isExportCSVLoading}
      isDatePickerOpen={isDatePickerOpen}
      hasGraphPerms={hasGraphPerms}
      timeWindow={timeWindow}
      dataPageName={dataPageName}
      dataPage={dataPage}
      disableMonthTab={isMonthTimeWindowDisabled}
      handleDataPageChange={handleDataPageChange}
      handleDateRangeChange={handleDateRangeChange}
      handleTimeWindowChange={handleTimeWindowChange}
      handleCustomDateRangeSelection={handleCustomDateRangeSelection}
      exportCSV={exportCSV}
      closeDatePicker={() => setDatePickerOpen(false)}
    />
  );
};

FacebookStatsGraph.displayName = 'FacebookStatsGraph';

export default FacebookStatsGraph;
