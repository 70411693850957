import React, { FC } from 'react';
import PropTypes from 'prop-types';
import { IconWrapper } from './tab-icon-wrapper.styles';

const TabIconWrapper: FC = ({ children }) => <IconWrapper>{children}</IconWrapper>;

TabIconWrapper.propTypes = {
  children: PropTypes.node,
};

export default TabIconWrapper;
