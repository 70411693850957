import React, { FC, useCallback, useEffect, ReactNode } from 'react';
import PropTypes from 'prop-types';
import { KEYS } from '../../constants';
import './modalHeader.scss';

interface ModalHeaderProps {
  titleText: string;
  titleIconClass?: string;
  closeButton?: boolean;
  onClose?: () => void;
  children?: ReactNode;
  badgeText?: string;
}

const ModalHeader: FC<ModalHeaderProps> = ({ titleText, titleIconClass, closeButton, onClose, children , badgeText}) => {
  const escFunction = useCallback((event: KeyboardEvent) => {
    if (onClose && event.keyCode === KEYS.ESCAPE_KEY) {
      onClose();
    }
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false);

    return (): void => {
      document.removeEventListener('keydown', escFunction, false);
    };
  }, []);

  return (
    <div className="la1-modal-header">
      <div className="la1-modal-header-left">
        {titleIconClass && <span className={`la1-modal-header-icon ${titleIconClass}`}></span>}
        <h4 className="modal-title" style={{ marginRight: 10 }}>
          {titleText}
        </h4>
        {badgeText && (<span className="label bg-red label-readability" style={{ marginTop: 2, marginBottom: 2, fontSize: 16 }}>{badgeText}</span>)}
        {children}
      </div>
      <div>
        {closeButton && (
          <button type="button" className="close" aria-label="Close" onClick={onClose}>
            <span aria-hidden="true">x</span>
          </button>
        )}
      </div>
    </div>
  );
};

ModalHeader.displayName = 'ModalHeader';

export default ModalHeader;
