import 'angular-route';
import angular from 'angular';
import router from './module.units.routes';
import commonServicesModule from '../../services';

export const unitsModule = {
	name: 'units',
	controllers: {
		default: 'unitsCtrl',
	},
	routes: {
		list: '/units',
	},
};

export default angular
	.module(unitsModule.name, ['ngRoute', commonServicesModule])
	.config(router)
	.controller(unitsModule.controllers.default, require('./unitsCtrl')).name;
