import { ErrorType } from '../../components/Loading';

export enum ACCEPT_INVITE_PAGES {
  LOADING = 'LOADING',
  SET_PASSWORD = 'SET_PASSWORD',
  INVITATION_EXPIRED = 'INVITATION_EXPIRED',
}

export interface AcceptInviteAction<T> {
  payload: T;
  type: ACCEPT_INVITE_ACTIONS;
}

export interface AcceptInviteState {
  page: ACCEPT_INVITE_PAGES;
  email?: string;
  error?: ErrorType;
}

export const initialAcceptInviteState: AcceptInviteState = {
  page: ACCEPT_INVITE_PAGES.LOADING,
  email: '',
  error: undefined,
};

export enum ACCEPT_INVITE_ACTIONS {
  PAGE = 'PAGE',
  EMAIL = 'EMAIL',
  ERROR = 'ERROR',
}

export const dispatchers = {
  [ACCEPT_INVITE_ACTIONS.PAGE]: (
    state: AcceptInviteState,
    action: AcceptInviteAction<ACCEPT_INVITE_PAGES>
  ): AcceptInviteState => {
    return { ...state, page: action.payload };
  },
  [ACCEPT_INVITE_ACTIONS.EMAIL]: (state: AcceptInviteState, action: AcceptInviteAction<string>): AcceptInviteState => {
    return { ...state, email: action.payload };
  },
  [ACCEPT_INVITE_ACTIONS.ERROR]: (
    state: AcceptInviteState,
    action: AcceptInviteAction<ErrorType>
  ): AcceptInviteState => {
    return { ...state, error: action.payload };
  },
};

export function acceptInviteReducer(state: AcceptInviteState, action: AcceptInviteAction<any>): AcceptInviteState {
  return dispatchers[action.type](state, action);
}
