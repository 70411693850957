import styled from '@emotion/styled';
import { customProperties as vars } from '@resi-media/resi-ui';

export const SpacerS = styled.div`
  height: ${vars.spacingS};
`;

export const SubmitButtonWrap = styled.div`
  margin-top: ${vars.spacingL};
`;

export const DataCenterDetailsWrap = styled.div`
  width: 180px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const DataCenterHeader = styled.h3<{ error?: boolean }>`
  font-weight: 600;
  font-size: ${vars.fontSize14};
  margin: 0;
  color: ${({ error }): string => (error ? vars.colorError : vars.colorAccent800)};
  margin-top: ${vars.spacingXs};
`;

export const MsText = styled.span<{ passing: boolean }>`
  color: ${({ passing }): string => (passing ? vars.colorSuccess : vars.colorError)};
`;

export const DataCenterDescription = styled.p<{ errorText?: boolean }>`
  font-size: ${vars.fontSize10};
  color: ${({ errorText }): string => (errorText ? vars.colorError : vars.colorAccent700)};
  font-weight: normal;
  margin: 0;
`;

export const Link = styled.a`
  color: ${vars.colorPrimary};
  cursor: pointer;
`;
