import 'angular-route';
import angular from 'angular';
import router from './module.unregisteruploader.routes';
import commonServicesModule from '../../services';

export const unregisteruploaderModule = {
	name: 'unregisteruploader',
	controllers: {
		default: 'unregisterUploaderCtrl',
	},
	routes: {
		login: '/unregisteruploader',
		success: '/unregisteruploader/success', // this needs to be a subfolder of the login route
	},
};

export default angular
	.module(unregisteruploaderModule.name, ['ngRoute', commonServicesModule])
	.config(router)
	.controller(unregisteruploaderModule.controllers.default, require('./unregisterUploaderCtrl')).name;
