import React, { FC, useContext, useState, useEffect, ChangeEvent, useMemo } from 'react';
import EmbedStatsGraphView from './embed-stats-graph-view';
import { EmbedPlayerContext } from '../../../../contexts/eventanalytics';
import {
  allDeviceTypeOption,
  EMBED_PAGE_STATE_CONTEXT_KEYS,
  EMBED_PLAYER_PAGES,
  EVENT_ANALYTICS_CONTEXT_KEYS,
  GROUP_SET,
  GROUP_VALUES,
  PAGE,
  TAB_VALUES_NUMBERS,
  TIME_WINDOW_SET,
  TIME_WINDOW_VALUES,
} from '../../event-analytics-constants';
import { DataObject, DateType, EMBED_DATA_PAGES, GROUP_BY_VALUE, lineChartDataType, TIME_WINDOWS } from '../../types';
import { Option } from '@resi-media/resi-ui';
import { dateRangeValues, customDateRangeOption, lifeTimeValues } from '../../date-range-options';
import {
  parseLineChartData,
  isMonthTabDisabled,
  getDateRangeOptions,
  parseLineChartDataForDeviceType,
  parseMinByMinLineChartData,
  parseLineChartDataMinByMinDeviceBrand,
} from '../../utils';
import { fetchLineChartData, fetchMinByMinData } from '../api';
import { EventAnalyticsContext } from '../../../../contexts/eventanalytics/event-analytics';
import { MPEventName, MPEventProperty, trackMixpanelEvent } from '../../../../mixpanel';
import { fetchVideoCueData } from '../../../../components/VideoCues/api';

interface EmbedStatsGraphProps {
  exportCSV: () => void;
  isExportingCSV: boolean;
  hasGraphPerms: boolean;
  setSegmentType: (value: GROUP_BY_VALUE) => void;
}

const EmbedStatsGraph: FC<EmbedStatsGraphProps> = ({ exportCSV, isExportingCSV, hasGraphPerms, setSegmentType }): JSX.Element => {
  const { embedPlayerAnalyticsState, dispatch } = useContext(EmbedPlayerContext);
  const { EventAnalyticsState, dispatch: dispatchEventAnalyticsState } = useContext(EventAnalyticsContext);
  const {
    viewAllData,
    eventId,
    dateRangeOption,
  } = EventAnalyticsState;
  const isMonthTimeWindowDisabled = isMonthTabDisabled(dateRangeOption?.value);
  const [dataType, setDataType] = useState<GROUP_BY_VALUE>(0);
  const [deviceType, setDeviceType] = useState<string>('');
  const [dataPage, setDataPage] = useState<EMBED_DATA_PAGES>(0);
  const [dataPageName, setDataPageName] = useState<string>(EMBED_PLAYER_PAGES[0]);
  const [timeWindow, setTimeWindow] = useState<TIME_WINDOWS>(0);
  const [isDatePickerOpen, setDatePickerOpen] = useState<boolean>(false);
  const [timeWindowValue, setTimeWindowValue] = useState<string>(TIME_WINDOW_VALUES.DAY);
  const [chartData, setChartData] = useState<lineChartDataType>({ labels: [], datasets: {} });
  const [videoCuesData, setVideoCuesData] = useState<DataObject[]>();
  const [isChartLoading, setChartLoading] = useState<boolean>(false);

  const handleGroupByChange = (event: ChangeEvent<unknown>, newValue: GROUP_BY_VALUE): void => {
    setDataType(newValue);
    if (newValue !== TAB_VALUES_NUMBERS.DEVICE_TYPE) {
      dispatch({ type: EMBED_PAGE_STATE_CONTEXT_KEYS.GROUP_BY, payload: GROUP_SET[newValue] });
    }
    setSegmentType(newValue);

    let dataSegmentType = '';

    switch (newValue) {
      case 0:
        dataSegmentType = 'None';
        break;
      case 1:
        dataSegmentType = 'Event Type';
        break;
      case 2:
        dataSegmentType = 'Device Type';
        break;
    }

    const mixpanelProps = {
      [MPEventProperty.TRANSCODED_EVENT_UUID]: EventAnalyticsState.eventId,
      [MPEventProperty.ANALYTICS_SEGMENT]: dataSegmentType,
      [MPEventProperty.ANALYTICS_TYPE]: 'Embed Player',
    };

    if (!EventAnalyticsState.eventId) {
      delete mixpanelProps[MPEventProperty.TRANSCODED_EVENT_UUID];
    }

    trackMixpanelEvent(MPEventName.ANALYTICS_SEGMENT, mixpanelProps);
  };

  const handleDataPageChange = (event: ChangeEvent<unknown>, newValue: EMBED_DATA_PAGES): void => {
    setDataPage(newValue);
    setDataPageName(EMBED_PLAYER_PAGES[newValue])
  };

  const handleTimeWindowChange = (event: ChangeEvent<unknown> | null, newValue: TIME_WINDOWS): void => {
    setTimeWindowValue(TIME_WINDOW_SET[newValue]);
    setTimeWindow(newValue);
  };

  const handleDeviceTypeChange = (newDeviceType: string): void => {
    if (newDeviceType !== allDeviceTypeOption.value) {
      setDeviceType(newDeviceType);
    } else {
      setDeviceType('');
    }
  };

  const handleDateRangeChange = (item: Option[]): void => {
    const selection = item[0]?.value;
    let dateRange: DateType = { startDate: '', endDate: '' };
    // Since we have only one custom handling case "c0".
    if (selection === customDateRangeOption.value) {
      setDatePickerOpen(true);
    } else if (selection === '0' && viewAllData) {
      dateRange = lifeTimeValues[selection];
      dispatchEventAnalyticsState({ type: EVENT_ANALYTICS_CONTEXT_KEYS.DATE_RANGE, payload: dateRange });
      dispatchEventAnalyticsState({ type: EVENT_ANALYTICS_CONTEXT_KEYS.DATE_RANGE_VALUE, payload: item[0] });
    } else if (selection) {
      dateRange = dateRangeValues[selection];
      dispatchEventAnalyticsState({ type: EVENT_ANALYTICS_CONTEXT_KEYS.DATE_RANGE, payload: dateRange });
      dispatchEventAnalyticsState({ type: EVENT_ANALYTICS_CONTEXT_KEYS.DATE_RANGE_VALUE, payload: item[0] });
    }
  };

  const handleCustomDateRangeSelection = (startDate: string, endDate: string): void => {
    if (startDate && endDate) {
      const dateRange = {
        startDate,
        endDate,
      };
      dispatchEventAnalyticsState({ type: EVENT_ANALYTICS_CONTEXT_KEYS.DATE_RANGE, payload: dateRange });
      dispatchEventAnalyticsState({
        type: EVENT_ANALYTICS_CONTEXT_KEYS.DATE_RANGE_VALUE,
        payload: customDateRangeOption,
      });
    }
  };

  const getChartData = async () => {
    let lineChartData;
    setChartLoading(true);
    if (dataPage !== PAGE.MINUTE_BY_MINUTE) {
      const response = await fetchLineChartData(
        EventAnalyticsState,
        embedPlayerAnalyticsState,
        timeWindowValue,
        deviceType,
        dataPage
      );
      if (embedPlayerAnalyticsState.groupBy !== GROUP_VALUES.DEVICE_TYPE) {
        lineChartData = parseLineChartData(response, timeWindowValue);
      } else {
        lineChartData = parseLineChartDataForDeviceType(response, timeWindowValue);
      }
      setChartData(lineChartData);
      setChartLoading(false);
    } else {
      const response = await fetchMinByMinData(EventAnalyticsState, embedPlayerAnalyticsState, 5);
      const videoCuesData = await fetchVideoCueData(EventAnalyticsState);
      if (embedPlayerAnalyticsState.groupBy !== GROUP_VALUES.DEVICE_TYPE) {
        setChartData(parseMinByMinLineChartData(response));
      } else {
        setChartData(parseLineChartDataMinByMinDeviceBrand(response));
      }
      setVideoCuesData(videoCuesData);
      setChartLoading(false);
    }
  };

  useEffect(() => {
    getChartData();
  }, [embedPlayerAnalyticsState, timeWindowValue, EventAnalyticsState, deviceType, dataPage]);

  useMemo(() => {
    if (eventId === null) {
      setDataPage(0);
      setDataPageName(EMBED_PLAYER_PAGES[0])
    }
  }, [eventId]);

  return (
    <EmbedStatsGraphView
      data={chartData}
      dataPageName={dataPageName}
      videoCuesData={videoCuesData}
      dateRangeOptions={getDateRangeOptions(viewAllData)}
      dateRangeDropdownValue={EventAnalyticsState.dateRangeOption}
      dataType={dataType}
      isDatePickerOpen={isDatePickerOpen}
      timeWindow={timeWindow}
      handleDeviceTypeChange={handleDeviceTypeChange}
      dataPage={dataPage}
      disableMonthTab={isMonthTimeWindowDisabled}
      isChartLoading={isChartLoading}
      handleGroupByChange={handleGroupByChange}
      handleDataPageChange={handleDataPageChange}
      handleDateRangeChange={handleDateRangeChange}
      handleTimeWindowChange={handleTimeWindowChange}
      handleCustomDateRangeSelection={handleCustomDateRangeSelection}
      hasGraphPerms={hasGraphPerms}
      exportCSV={exportCSV}
      isExportCSVLoading={isExportingCSV}
      closeDatePicker={(): void => setDatePickerOpen(false)}
    />
  );
};

EmbedStatsGraph.displayName = 'EmbedStatsGraph';

export default EmbedStatsGraph;
