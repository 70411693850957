'use strict';

const jcs = require('../../jcs');
const moment = require('moment');

function DashboardController($scope, $http, $timeout, $q, httpService, encoderService, focus, Authentication) {
	'ngInject';

	$(window).trigger('resize'); // ensure footer is properly positioned

	$scope.loading_monitor_data_timeout_id = null;
	$scope.UPDATE_MONITOR_DATA_TIME_DELAY = 1000 * 60; // 1 minute

	$scope.monitor_data_last_update = null;

	$scope.is_loading = false;
	$scope.error_msg = null;

	$scope.site_monitor_data = null;
	$scope.sites = null;

	$scope.encoder_monitor_data = null;
	$scope.encoders = null;

	$scope.site_data = []; // site and monitor data combined
	$scope.encoder_data = []; // encoder and monitor data combined

	$scope.getMonitorUpdateTimeInSec = function () {
		return $scope.UPDATE_MONITOR_DATA_TIME_DELAY;
	};

	$scope.getBufferSize = function (playerInfo) {
		// calculate the diff between player position and buffer position in milliseconds
		var player_pos_ms = moment.duration(playerInfo.position).asMilliseconds();
		var buffer_pos_ms = moment.duration(playerInfo.buffer).asMilliseconds();
		var diff_ms = buffer_pos_ms > player_pos_ms ? buffer_pos_ms - player_pos_ms : 0;

		// create duration object, which can convert our milliseconds duration into hours/minutes/seconds
		var duration = moment.duration(diff_ms);
		var hours = duration.hours();
		var minutes = duration.minutes();
		var seconds = duration.seconds();

		// format with leading zeros
		var hours_formatted = hours < 10 ? '0' + hours : hours;
		var minutes_formatted = minutes < 10 ? '0' + minutes : minutes;
		var seconds_formatted = seconds < 10 ? '0' + seconds : seconds;

		return hours_formatted + ':' + minutes_formatted + ':' + seconds_formatted;
	};

	$scope.getSortableDecoderVersionNumber = function (version) {
		if (version == null) return '';

		// break version into parts
		var firstDotIndex = version.indexOf('.');
		var first = parseInt(version.substring(0, firstDotIndex));
		var secondDotIndex = version.indexOf('.', firstDotIndex + 1);
		var second = parseInt(version.substring(firstDotIndex + 1, secondDotIndex));
		var thirdDotIndex = version.indexOf('.', secondDotIndex + 1);
		var third = parseInt(version.substring(secondDotIndex + 1, thirdDotIndex));
		var fourth = parseInt(version.substring(thirdDotIndex + 1));
		// this should work as long as components of version number don't get too large (100+)
		var sortable = first * 1000000 + second * 10000 + third * 100 + fourth;
		return sortable;
	};

	// converts encoder version number (1.2.3) into int that can be sorted
	$scope.getSortableEncoderVersionNumber = function (version) {
		// break version into parts
		var firstDotIndex = version.indexOf('.');
		var first = parseInt(version.substring(0, firstDotIndex));
		var secondDotIndex = version.indexOf('.', firstDotIndex + 1);
		var second = parseInt(version.substring(firstDotIndex + 1, secondDotIndex));
		var third = parseInt(version.substring(secondDotIndex + 1));
		// this should work as long as components of version number don't get too large (100+)
		var sortable = first * 10000 + second * 100 + third;
		return sortable;
	};

	// so offline status will show up as last
	$scope.getSortableStatus = function (status) {
		if (status == 'playing') return 'aaaplaying';
		if (status == 'paused') return 'bbbpaused';
		if (status == 'idle') return 'cccidle';
		if (status == 'offline') return 'zzzoffline';
		return status;
	};

	$scope.formatAvgBandwidth = function (bandwidth) {
		if (bandwidth == null) return '';

		return bandwidth + ' Mbps';
	};

	$scope.formatPosition = function (position) {
		if (position == null) return '';

		var decimalPosition = position.indexOf('.');
		if (decimalPosition != -1) {
			// only show 2 places after the decimal
			return position.substring(0, decimalPosition + 3);
		}
		// position does not need formatting
		return position;
	};

	$scope.formatBandwidth = function (bandwidth) {
		if (bandwidth != null) {
			if (bandwidth < 10) return bandwidth.toFixed(1);

			return bandwidth.toFixed(0);
		}

		return bandwidth;
	};

	$scope.showTimeAsLocal = function (dateTimeToConvert) {
		if (dateTimeToConvert == null) return '';

		// is the given time today?
		var currentDay = moment().format('MMM D, YYYY');
		var givenTimeDay = moment(dateTimeToConvert).format('MMM D, YYYY');
		if (currentDay == givenTimeDay) {
			return 'Today at ' + moment(dateTimeToConvert).format('h:mm:ss A');
		}

		// is the given time this year?
		var currentYear = moment().format('YYYY');
		var givenTimeYear = moment(dateTimeToConvert).format('YYYY');
		if (currentYear == givenTimeYear) {
			var as_moment = moment(dateTimeToConvert);
			return as_moment.format('ddd, MMM D') + ' at ' + as_moment.format('h:mm:ss A');
		}

		// NOTE: we were using the Date "toLocaleString" method, but it turns out this isn't implemented consistently across
		// browsers. That is why we are using a javascript library (momentjs).

		// for formatting options see: http://momentjs.com/
		return moment(dateTimeToConvert).format('ddd, MMM D, YYYY h:mm:ss A');
	};

	// no seconds
	$scope.showTimeAsLocalHM = function (dateTimeToConvert) {
		if (dateTimeToConvert == null) return '';

		// NOTE: we were using the Date "toLocaleString" method, but it turns out this isn't implemented consistently across
		// browsers. That is why we are using a javascript library (momentjs).

		// is the given time today?
		var currentDay = moment().format('MMM D, YYYY');
		var givenTimeDay = moment(dateTimeToConvert).format('MMM D, YYYY');
		if (currentDay == givenTimeDay) {
			return 'today at ' + moment(dateTimeToConvert).format('h:mm a');
		}

		// is the given time this year?
		var currentYear = moment().format('YYYY');
		var givenTimeYear = moment(dateTimeToConvert).format('YYYY');
		if (currentYear == givenTimeYear) {
			return moment(dateTimeToConvert).format('MMM D, h:mm a');
		}

		// for formatting options see: http://momentjs.com/
		return moment(dateTimeToConvert).format('MMM D, YYYY h:mm a');
	};

	$scope.getMonitorDataForSite = function (site) {
		var dataList = [];

		if ($scope.site_monitor_data.hasOwnProperty(site.userName)) {
			var list = $scope.site_monitor_data[site.userName];
			if (list.length > 1) {
				// look at two most recent entries
				// if they are within 5 minutes and have a different hardwareId, then return both
				var entryA = list[0];
				var entryB = list[1];
				if (entryA.playerHardwareId == entryB.playerHardwareId) {
					dataList.push(entryA);
				} else {
					var lastUpdateA = moment(entryA.lastUpdate);
					var lastUpdateB = moment(entryB.lastUpdate);

					lastUpdateA.subtract(5, 'minutes');
					if (lastUpdateB.isAfter(lastUpdateA)) {
						// looks like we have duplicate logins, so return both
						dataList.push(entryA);
						dataList.push(entryB);
					} else {
						// 2nd entry is older than 5 minutes, so ignore it
						dataList.push(entryA);
					}
				}
			} else if (list.length == 1) {
				return list;
			}
		}
		return dataList;
	};

	$scope.getMonitorDataForEncoder = function (encoder) {
		for (var i = 0; i < $scope.encoder_monitor_data.length; i++) {
			var data = $scope.encoder_monitor_data[i];
			if (encoder.uuid == data.encoderId) {
				return data;
			}
		}
		return null;
	};

	$scope.isUser = function (item) {
		// make sure planName doesn't happen to be null
		if (item.planName == null) return false;
		return item.planName.toLowerCase() == 'user';
	};

	$scope.sortLastUpdateByMostRecentFirst = function (a, b) {
		var lastUpdateA = moment(a.lastUpdate);
		var lastUpdateB = moment(b.lastUpdate);
		if (lastUpdateA.isAfter(lastUpdateB)) {
			return -1;
		}
		if (lastUpdateA.isBefore(lastUpdateB)) {
			return 1;
		}
		// names must be equal
		return 0;
	};

	$scope.groupSiteMonitoringData = function (data) {
		var grouped_data = {};

		for (var i = 0; i < data.length; i++) {
			var entry = data[i];

			if (!grouped_data.hasOwnProperty(entry.userName)) {
				grouped_data[entry.userName] = [];
			}
			grouped_data[entry.userName].push(entry);
		}

		for (var userName in grouped_data) {
			// sort entries by lastUpdate, most recent first
			grouped_data[userName].sort($scope.sortLastUpdateByMostRecentFirst);

			//					console.log("userName: " + userName);
			//					console.log("Ordering...");
			//					for (var i=0; i < grouped_data[userName].length; i++){
			//						console.log(grouped_data[userName][i].lastUpdate);
			//					}
		}

		return grouped_data;
	};

	$scope.prepareSiteMonitorData = function (site_status_data) {
		$scope.site_monitor_data = $scope.groupSiteMonitoringData(site_status_data);

		$scope.site_data = [];
		for (var i = 0; i < $scope.sites.length; i++) {
			var site = $scope.sites[i];
			var list = $scope.getMonitorDataForSite(site);
			if (list.length == 0) {
				// add a "offline" entry
				$scope.site_data.push({
					userName: site.userName,
					status: 'offline',
					statusSortable: 'zzzoffline',
					eventName: 'Unknown',
				});
			} else {
				list.map(site => {
					site.bufferSize = $scope.getBufferSize(site);
					site.playerVersionSortable = $scope.getSortableDecoderVersionNumber(site.playerVersion);
					site.statusSortable = $scope.getSortableStatus(site.status);
					site.eventName = site.eventName || 'Unknown';
					$scope.site_data.push(site);
				});
			}
		}
	};

	$scope.prepareEncoderMonitorData = function (encoder_status_data) {
		$scope.encoder_monitor_data = encoder_status_data;

		$scope.encoder_data = [];
		for (var i = 0; i < $scope.encoders.length; i++) {
			var encoder = $scope.encoders[i];
			var data = $scope.getMonitorDataForEncoder(encoder);
			if (data == null) {
				// add "offline" entry
				$scope.encoder_data.push({
					encoderName: encoder.name,
					status: 'offline',
					statusSortable: 'zzzoffline',
				});
			} else {
				data.statusSortable = $scope.getSortableStatus(data.status);
				data.encoderVersionSortable = $scope.getSortableEncoderVersionNumber(data.encoderVersion);
				$scope.encoder_data.push(data);
			}
		}
	};

	$scope.loadSilent = function () {
		// pull fresh monitoring data
		$http.get(jcs.api.url_v3 + '/customers/' + Authentication.getCurrentUser().customerID + '/monitors', { withCredentials: true, }).then(
			function (response) { // success

				$scope.monitor_data_last_update = moment().format();

				// build our dashboard data for sites
				$scope.prepareSiteMonitorData(response.data.decoderStatus);

				// build our dashboard data for encoders
				$scope.prepareEncoderMonitorData(response.data.encoderStatus);

			},
			function () { // error

				// If our "silent" load gets an error, lets not display an error message -- I think it would be confusing for the user to see
				// an error about an action that they did not initiate. So instead of displaying an error, setup another timeout in the hopes
				// that the error we got as a one-time thing, and upcoming check will work.

			})['finally'](function () { // always called

				// setup timeout so our monitor data will auto refresh
				$scope.loading_monitor_data_timeout_id = window.setTimeout(
					$scope.loadSilent,
					$scope.getMonitorUpdateTimeInSec()
				);
			});
	};

	$scope.load = function () {
		$scope.is_loading = true;

		// load our API requests as promises (to be executed at once)
		const promises = [
			$http.get(`${jcs.api.url}/users`, { withCredentials: true }),
			$http.get(`${jcs.api.url}/encoders`, { params: { wide: true }, withCredentials: true }),
			$http.get(`${jcs.api.url_v3}/customers/` + Authentication.getCurrentUser().customerID + '/monitors', { withCredentials: true, })
		];
		$q.all(promises).then(
			function (response) {
				$scope.monitor_data_last_update = moment().format();

				// filter out our sites from our users data
				$scope.sites = [];
				for (var i = 0; i < response[0].data.length; i++) {
					var item = response[0].data[i];
					if (!$scope.isUser(item)) {
						$scope.sites.push(item);
					}
				}

				// encoders
				$scope.encoders = response[1].data;
				$scope.updateRequired = encoderService.isEncoderUpdateRequired($scope.encoders);

				// build our dashboard data for sites
				$scope.prepareSiteMonitorData(response[2].data.decoderStatus);

				// build our dashboard data for encoders
				$scope.prepareEncoderMonitorData(response[2].data.encoderStatus);

				// setup timeout so our monitor data will auto refresh
				$scope.loading_monitor_data_timeout_id = window.setTimeout(
					$scope.loadSilent,
					$scope.getMonitorUpdateTimeInSec()
				);

			},
			function (reason) {

				if (!httpService.isStatus406(reason)) {
					$scope.error_msg = 'An error occurred while attempting to load the dashboard data. Please try again, or report the problem if it persists.';
				}

			}).finally(function () {
				$scope.is_loading = false;
			});
	};

	$scope.getCurrentUser = function () {
		return Authentication.getCurrentUser();
	};

	//
	// initialize by loading our system monitoring info
	//
	$scope.load();

	// build our tooltips
	$timeout(function () {
		$('[data-toggle="tooltip"]').tooltip(); // needs to be done in timeout, otherwise for some reason the tooltip gets built before angular does it's magic

		// this tooltip uses a html "title/body" with special formatting
		$('.site-mode-tooltip').tooltip({
			template: '<div class="tooltip" role="tooltip"><div class="tooltip-arrow"></div><div class="tooltip-inner tooltip-inner-wide tooltip-inner-left"></div></div>',
			placement: 'top',
			title: '<div class="tooltip-section">Describes the source and network connection type which the decoder is using to download content. Options are Cloud or LAN.</div><div class="tooltip-section">In Cloud Mode, the decoder is downloading content from Resi\'s Content Delivery Network (CDN) over a public internet connection.</div><div class="tooltip-section">In LAN Mode, the decoder is downloading content directly from the encoder at the broadcast location. LAN Mode should never be used with VPN connections, but only with dedicated internet connections (Local Area Networks, VPL, or MPLS).',
			html: true,
		});
	});

	// since this page has timers, we need to know when the user changes to another page so we can turn the timers
	// off (this will prevent unnecessary http requests).
	$scope.$on('$locationChangeSuccess', function () {
		// clear any active timers we might have
		if ($scope.loading_monitor_data_timeout_id != null) {
			window.clearTimeout($scope.loading_monitor_data_timeout_id);
			$scope.loading_monitor_data_timeout_id = null;
		}
	});
}

module.exports = DashboardController;
