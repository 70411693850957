import * as LDClient from 'launchdarkly-js-client-sdk';
interface RouteParams extends ng.route.IRouteParamsService {
  eventId: string;
}

class EventAnalyticsController {
  public eventId: string;
  public customerId: string;
  public auth: any;
  public ldFlags: LDClient.LDFlagSet;

  constructor(private $routeParams: RouteParams, private Authentication, private $rootScope) {
    'ngInject';
    $(window).trigger('resize'); // ensure footer is properly positioned

    this.eventId = this.$routeParams.eventId;
    this.customerId = this.Authentication.getCurrentUser().customerID;
    this.auth = this.Authentication;
    const scope = this.$rootScope;
    this.ldFlags = scope.ldFlags;
    
    scope.$on('ldFlagsChanged', (_, data) => {
      this.ldFlags = data;
      scope.$digest();
    });

    // this ensures we are at top if coming from events page
    $(document).scrollTop(0);
  }
}
module.exports = EventAnalyticsController;
