import React, { FC, useEffect, useState } from 'react';
import { CloseCircleFilled, Loading3QuartersOutlined } from '@ant-design/icons';
import { DataCenterHeader, DataCenterDetailsWrap, MsText, DataCenterDescription, Link } from './style';
import { LinkButton, Inline, theme, ThemeProvider, customProperties as vars } from '@resi-media/resi-ui';
import { getClosestRegion } from '../../helpers/event-profile-onboarding';
import { LatencyDetails } from '../../constants';

export enum DATA_TESTID {
  LOADING_SPINNER_WRAP = 'loading-spinner-wrap',
  TIMED_OUT_HEADER = 'timed-out-header',
  BEST_LOCATION_HEADER = 'best-location-header',
}

type DataCenterLatencyProps = {
  onRegionFound: (bestLatency: LatencyDetails | null) => void;
  regionIdToInclude?: string[];
  onTimeout?: () => void;
};

const DataCenterLatency: FC<DataCenterLatencyProps> = ({
  onRegionFound,
  regionIdToInclude = [],
  onTimeout,
}): JSX.Element => {
  const [bestLatency, setBestLatency] = useState<LatencyDetails | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [isTimedout, setTimedout] = useState<boolean>(false);
  const [canRetry, setCanRetry] = useState<boolean>(true);

  async function checkDataCenterLatencies(lastRetry: boolean): Promise<void> {
    setLoading(true);
    if (lastRetry) {
      setCanRetry(false);
    }
    const bestLatency = await getClosestRegion(regionIdToInclude);
    setLoading(false);
    setBestLatency(bestLatency);

    if (bestLatency === null) {
      setTimedout(true);
      if (onTimeout) {
        onTimeout();
      }
    } else if (bestLatency.isPassing) {
      onRegionFound(bestLatency);
    }
  }

  const RetryOrContactText = (): JSX.Element => {
    if (canRetry) {
      return (
        <>
          We failed to locate a data center with suitable performance. Please&nbsp;
          <LinkButton label={'try again.'} onClick={() => checkDataCenterLatencies(true)} />
        </>
      );
    }
    return (
      <>
        We failed to locate a data center with suitable performance. Please&nbsp;
        <Link target="_blank" href="https://resi.io/support/">
          contact support
        </Link>
        &nbsp;for further assistance.
      </>
    );
  };

  useEffect(() => {
    checkDataCenterLatencies(false);
  }, []);

  return (
    <ThemeProvider theme={theme}>
      {loading && (
        <>
          <DataCenterDetailsWrap data-testid={DATA_TESTID.LOADING_SPINNER_WRAP}>
            <DataCenterHeader>Locating Data Center</DataCenterHeader>
            <Loading3QuartersOutlined spin style={{ color: vars.colorPrimary, fontSize: 14 }} />
          </DataCenterDetailsWrap>
          <DataCenterDescription>
            This is configured based on your location. Please conduct this test at your streaming location if possible.
          </DataCenterDescription>
        </>
      )}
      {!loading && bestLatency && (
        <>
          {bestLatency.isPassing && (
            <>
              <DataCenterHeader data-testid={DATA_TESTID.BEST_LOCATION_HEADER}>
                {bestLatency.dataCenter.displayName}
                {bestLatency.duration && (
                  <>
                    {' '}
                    - <MsText passing>{bestLatency.duration}ms</MsText>
                  </>
                )}
              </DataCenterHeader>
              <DataCenterDescription>
                If your data center appears incorrect, please&nbsp;
                <LinkButton label={'try again'} onClick={() => checkDataCenterLatencies(false)} />
                &nbsp;at your streaming location or&nbsp;
                <Link target="_blank" href="https://resi.io/support/">
                  contact support.
                </Link>
              </DataCenterDescription>
            </>
          )}
          {!bestLatency.isPassing && (
            <Inline alignItems="center">
              <CloseCircleFilled style={{ color: vars.colorError, fontSize: 8.75, marginRight: 3 }} />{' '}
              <DataCenterDescription errorText>
                <RetryOrContactText />
              </DataCenterDescription>
            </Inline>
          )}
        </>
      )}
      {!loading && isTimedout && (
        <>
          <DataCenterDetailsWrap>
            <DataCenterHeader data-testid={DATA_TESTID.TIMED_OUT_HEADER} error>
              Timeout Error
            </DataCenterHeader>
          </DataCenterDetailsWrap>
          <Inline alignItems="center">
            <CloseCircleFilled style={{ color: vars.colorError, fontSize: 8.75, marginRight: 3 }} />{' '}
            <DataCenterDescription errorText>
              <RetryOrContactText />
            </DataCenterDescription>
          </Inline>
        </>
      )}
    </ThemeProvider>
  );
};

DataCenterLatency.displayName = 'DataCenterLatency';

export default DataCenterLatency;
