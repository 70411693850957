'use strict';

const jcs = require('../../jcs');
const constants = require('../../constants');
const DATA_CENTERS = require('../../../../src/constants').DATA_CENTERS;
const { trackMixpanelEvent, MPEventProperty, MPEventName} = require('../../../../src/mixpanel');

function EventProfilesController(
  $scope,
  $timeout,
  $http,
  $location,
  httpService,
  webEventProfileService,
  formValidationService,
  Authentication,
  orgService
) {
  'ngInject';

  $(window).trigger('resize'); // ensure footer is properly positioned

  $scope.webEventProfileSvc = webEventProfileService;
  $scope.validation = formValidationService.init();

  $scope.WEB_PROFILE_NAME_MAX_LENGTH = 46; // limit is 50, but backend adds 4 characters to ensure uniqueness

  $scope.is_working = false;
  $scope.error_msg = null;

  $scope.delete_after_options = [];
  $scope.DELETE_AFTER_DEFAULT = 6;
  $scope.DELAY_SECONDS_DEFAULT = 90;
  $scope.SUBTITLES_ENABLED = 'Enabled';
  $scope.SUBTITLES_DISABLED = 'Disabled';
  $scope.SUBTITLES_DEFAULT_OPTION = $scope.SUBTITLES_DISABLED;
  $scope.SUBTITLES_OPTIONS = [$scope.SUBTITLES_ENABLED, $scope.SUBTITLES_DISABLED];

  $scope.event_profiles = null;

  // regions
  $scope.region_options = [];
  $scope.region_ids = [];

  $scope.is_manual_region = false;

  // web event profile to create
  $scope.name = '';
  $scope.description = '';
  $scope.type = '';
  $scope.delete_after = $scope.DELETE_AFTER_DEFAULT;
  $scope.delay_seconds = $scope.DELAY_SECONDS_DEFAULT;
  $scope.region = null;
  $scope.subtitlesEnabled = $scope.SUBTITLES_DEFAULT_OPTION;

  $scope.usage_summary = {
    subtitlesHoursPerMonth: 0,
  };

  $scope.getCurrentUser = function () {
    return Authentication.getCurrentUser();
  };

  $scope.cancel = function () {
    $location.url('/eventprofiles');
  };

  $scope.editRegion = function () {
    $scope.is_manual_region = true;
  };

  try {
    $http
      .get(`${jcs.api.url_v3}/customers/${$scope.getCurrentUser().customerID}`, { withCredentials: true })
      .then((response) => {
		$scope.type = response.data.defaultWebEventProfileType;
      });
  } catch (error) {
    console.error(error);
    $scope.error_msg = 'We were not able to retrieve information about your account.';
  }

  $scope.getMixpanelDataForWebEvtProfile = function (profile_data) {
    const mixpanel_data = {
      [MPEventProperty.WEB_EVENT_PROFILE_NAME]: profile_data.name,
      [MPEventProperty.DELETE_AFTER]: profile_data.deleteAfter,
      [MPEventProperty.WEB_EVENT_DELAY]: profile_data.delaySeconds,
      [MPEventProperty.SHOW_LOGO]: profile_data.branded,
    };
    if (profile_data.hasOwnProperty('regionId')){
      mixpanel_data[MPEventProperty.REGION] = $scope.region_options.find(option => option.value === profile_data.regionId)?.name;
    }
    if (profile_data.hasOwnProperty('type')){
      mixpanel_data[MPEventProperty.TYPE] = profile_data.type;
    }
    if (profile_data.hasOwnProperty('subtitlesEnabled')) { // TODO: remove when $scope.canShowSubtitlesOptions() is no longer needed
      mixpanel_data[MPEventProperty.SUBTITLES_ENABLED] = profile_data.subtitlesEnabled;
    }
    return mixpanel_data;
  };

  $scope.saveNewWebEventProfile = function () {
    $scope.validation.clear();

    // if we have form validation errors, then don't go any further
    if ($scope.doesSaveNewWebEventProfileFailValidation()) {
      return false;
    }

    const newProfileData = {
      name: $scope.name,
      description: $scope.description,
      regionId: $scope.region,
      loadBalPrefix: null,
      delaySeconds: $scope.delay_seconds,
      deleteAfter: $scope.delete_after,
      type: $scope.type.toLowerCase(),
      dvrWindowSeconds: null,
      chromeCastAppId: null,
      branded: false,
    };

    if ($scope.canShowSubtitlesOptions()) {
      newProfileData.subtitlesEnabled = $scope.subtitlesEnabled === $scope.SUBTITLES_ENABLED;
    }

    $scope.is_working = true;

    const mixpanel_data = $scope.getMixpanelDataForWebEvtProfile(newProfileData);

    httpService.post(
      `${jcs.api.url_v3}/customers/${Authentication.getCurrentUser().customerID}/webeventprofiles`,
      newProfileData,
      { withCredentials: true },
      () => {
        // success
        $scope.error_msg = null;
        $location.url('/eventprofiles');

        trackMixpanelEvent(MPEventName.WEB_EVENT_PROFILE_ADD, mixpanel_data);
      },
      (reason) => {
        // error
        $scope.error_msg = {
          message:
            'An error occurred while attempting to create the web event profile. Please try again, or report the problem if it persists.',
          reason: reason,
        };
      },
      () => {
        // finally
        $scope.is_working = false;
      }
    );
  };

  $scope.doesSaveNewWebEventProfileFailValidation = function () {
    $scope.error_msg = null;
    $scope.validation.clear();

    // ensure required fields are not empty
    $scope.validation.checkForEmpty('name', $scope.name);
    $scope.validation.checkForEmpty('region', $scope.region);

    let hasValidationError = $scope.validation.hasError();
    if (hasValidationError) {
      $scope.error_msg = 'Please specify a value for the highlighted fields below.';
    }

    // if no "required field" errors, then ...
    if (!hasValidationError) {
      // only allow letters, numbers, spaces, underscores, and dashes for profile name
      const name_restricted_chars = /[^a-zA-Z0-9\-\_ ]+/g;
      if (name_restricted_chars.test($scope.name)) {
        hasValidationError = true;
        $scope.validation.setError('name');
        $scope.error_msg =
          'The "Profile Name" contains some invalid characters. The name may only contain letters, numbers, spaces, underscores, and dashes.';
      }
    }

    return hasValidationError;
  };

  $scope.onRegionFound = function (latencyDetails) {
    if (!$scope.is_manual_region && latencyDetails && latencyDetails.dataCenter) {
      $scope.region = latencyDetails.dataCenter.id;
    }
  };

  const formatRegionOptions = (regions) =>
    regions.map((dataCenter) => ({
      value: dataCenter.id,
      name: dataCenter.displayName,
    }));

  $scope.load = function () {
    (async () => {
      const numericOptions = await orgService.getWebDeleteAfterOptions(Authentication.getCurrentUser().customerID);
      $scope.delete_after_options = numericOptions.map((n) => ({
        value: n,
        name: `${n} Day${n > 1 ? 's' : ''}`,
      }));
      $scope.$apply();
    })();

    $http
      .get(jcs.api.url + '/streamprofiles', { withCredentials: true })
      .then(
        function (response) {
          // success
          $scope.event_profiles = response.data;
        },
        function (e) {
          // error
          console.warn('Failed to load event profiles. All web regions will display. ', e);
        }
      )
      ['finally'](function () {
        if ($scope.event_profiles?.length && $scope.event_profiles.every(x => x.regionId)) {
          $scope.region_ids = $scope.event_profiles.map(({ regionId }) => regionId);
          const includedRegions = DATA_CENTERS.filter(({ id }) => $scope.region_ids.includes(id));
          $scope.region_options = formatRegionOptions(includedRegions);
          return;
        }
        $scope.region_options = formatRegionOptions(DATA_CENTERS);
        $scope.region_ids = DATA_CENTERS.map(({ id }) => id);
      });
  };

  $http
    .get(`${jcs.api.url_v3}/customers/${Authentication.getCurrentUser().customerID}`, { withCredentials: true })
    .then(
      function (response) {
        // success
        $scope.usage_summary = response.data.usageSummary;
      },
      function (e) {
        // error
        console.warn('Failed to load customer data. ', e);
      }
    )
    ['finally'](function () {
  });

  $scope.load();

  // can show the Subtitles feature UI (controled by LaunchDarkly)
  $scope.canShowSubtitlesOptions = function () {
    if ($scope.usage_summary.subtitlesHoursPerMonth > 0) {
      return true;
    } else if (Authentication.$rootScope.ldClient) {
      return Authentication.$rootScope.ldClient.allFlags()['automated-subtitles'];
    }
    return false;
  };

  $timeout(function () {
    // tooltip for "Automated Subtitles" that shows when creating a new web event profile
    $('.subtitles-tooltip').tooltip({
      template: '<div class="tooltip" role="tooltip"><div class="tooltip-arrow"></div><div class="tooltip-inner tooltip-inner-left"></div></div>',
      placement: 'top',
      title: 'Enables subtitles to be automatically created for your video content to make it more accessible to your audience. You can learn more about subtitles in our documentation.',
      html: true,
    });
  });
}

module.exports = EventProfilesController;
