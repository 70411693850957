class UploaderLoginCtrl {

	constructor() {
		'ngInject';
	}

	login() {
		this.loginCtrl.uploaderLogin().then(() =>{
			this.onLoginSuccess();
		});
	}
}

// example usage:
// <uploader-login on-login-success="loginSuccess()" login-ctrl="$ctrl" unregister="true"></uploader-login>
//
export default class UploaderLoginComponent {
	constructor() {
		this.template = require('./uploaderLogin.html').default;
		this.bindings = {
			loginCtrl: '<',
			onLoginSuccess: '&',
			unregister: '<',
		};
		this.controller = UploaderLoginCtrl;
	}
}
