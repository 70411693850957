import styled from '@emotion/styled';
import { customProperties as vars } from '@resi-media/resi-ui';

export const InfoBoxContainer = styled.div`
  font-color: #595959;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  background-color: #ecf0f5;
  color: #6789a8;
  border: 1px solid #b8c5d5;
  border-radius: ${vars.borderRadius4};
  text-align: flex-start;
  margin-top: 1rem;
`;

export const CloseCircleFilledWrapper = styled.div`
  padding: ${vars.spacingS};
`;

export const InfoWrapper = styled.div`
  display: flex;
  word-break: break-all;
  align-items: center;
  justify-content: flex-start;
`;