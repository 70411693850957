import * as React from 'react';
import './style.scss';
import ModalFooter from '../ModalFooter';
import ModalContent from '../ModalContent';
import { customProperties as vars } from '@resi-media/resi-ui';
import { CheckOutlined, Loading3QuartersOutlined } from '@ant-design/icons';
import { getWebEventStatus } from './api';
import PropTypes from "prop-types"

interface TrimWebEventCompletedProps {
  newWebEventLocation: string;
}

const TrimWebEventCompleted: React.FC<TrimWebEventCompletedProps> = ({ newWebEventLocation }): JSX.Element => {
  const [isEventStopped, setEventStopped] = React.useState(false);
  const [newWebEventUuid, setNewWebEventUuid] = React.useState<string>('');

  const fetchWebEvent = async () => {
    const response = await getWebEventStatus(newWebEventLocation);
    if (response.data.status === 'stopped') {
      setNewWebEventUuid(response.data.uuid);
      setEventStopped(true);
      return;
    }

    setTimeout(() => {
      fetchWebEvent();
    }, 2000)
  };
  React.useEffect(() => {
    setTimeout(async () => {
      await fetchWebEvent();
    }, 3000);
  }, []);

  return (
    <>
      <ModalContent>
        <p className="resi-trim-event-progress-progress-description">
          A new web event has been created and is processing. The new event can be found at the top of the web events
          list to see status, watch, or copy the embed code.
        </p>
        <div className="resi-trim-event-progress-icon-div">
          {isEventStopped ? (
            <CheckOutlined style={{ color: vars.colorSuccess, fontSize: 36 }} />
          ) : (
            <Loading3QuartersOutlined spin style={{ color: vars.colorPrimary, fontSize: 36 }} />
          )}
        </div>
      </ModalContent>
      <ModalFooter>
        <button className="btn btn-default" onClick={(): void => window.location.assign('/events')}>
          Back to Events
        </button>
        <button
          disabled={newWebEventUuid.length === 0}
          className="btn btn-primary"
          onClick={(): void => window.location.assign(`/events/web/watch/${newWebEventUuid}`)}
        >
          View Trimmed Event
        </button>
      </ModalFooter>
    </>
  );
};

TrimWebEventCompleted.propTypes = {
  newWebEventLocation: PropTypes.string.isRequired
}

TrimWebEventCompleted.displayName = "TrimWebEventCompleted"

export default TrimWebEventCompleted;
