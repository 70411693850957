import 'angular-route';
import angular from 'angular';
import router from './module.dashboard.routes';
import commonServicesModule from '../../services';

export const dashboardModule = {
	name: 'dashboard',
	controllers: {
		default: 'dashboardCtrl',
	},
	routes: {
		list: '/dashboard',
	},
};

export default angular
	.module(dashboardModule.name, ['ngRoute', commonServicesModule])
	.config(router)
	.controller(dashboardModule.controllers.default, require('./dashboardCtrl')).name;
