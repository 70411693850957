import React, { FC, useContext, useEffect, useReducer, useState } from 'react';
import { StatsArray } from '../types';
import YoutubeAnalyticsView from './youtube-analytics-view';
import { getStatsGroupsFromResponse } from '../utils';
import { fetchSummaryStats } from './api';
import { YoutubeContext, YoutubeReducer } from '../../../contexts/eventanalytics';
import { INITIAL_YOUTUBE_PAGE_STATE, STATS_KEYS_LIVE_YOUTUBE, HISTORICAL_YT_STATS_KEYS } from '../event-analytics-constants';
import { EventAnalyticsContext } from '../../../contexts/eventanalytics/event-analytics';

export const YoutubeAnalytics: FC<{ hasGraphPerms: boolean }> = ({ hasGraphPerms }): JSX.Element => {
  const [youtubeAnalyticsState, dispatch] = useReducer(YoutubeReducer, INITIAL_YOUTUBE_PAGE_STATE);
  const { EventAnalyticsState } = useContext(EventAnalyticsContext);
  const [statsArray, setStatsArray] = useState<StatsArray>([]);
  const [isCardLoading, setCardLoading] = useState<boolean>(true);

  const getData = async () => {
    setCardLoading(true);
    if ( youtubeAnalyticsState.page === 'liveViews' ){
      const statsArray = await fetchSummaryStats(EventAnalyticsState, youtubeAnalyticsState.page);
      setStatsArray(getStatsGroupsFromResponse(STATS_KEYS_LIVE_YOUTUBE, statsArray[0]));
    } else {
      const statsArray = await fetchSummaryStats(EventAnalyticsState, youtubeAnalyticsState.page);
      setStatsArray(getStatsGroupsFromResponse(HISTORICAL_YT_STATS_KEYS, statsArray[0]));
    }
    setCardLoading(false);
  };

  useEffect(() => {
    getData();
  }, [EventAnalyticsState.eventId, EventAnalyticsState.dateRange, youtubeAnalyticsState.page]);

  return (
    <YoutubeContext.Provider value={{ youtubeAnalyticsState, dispatch }}>
      <YoutubeAnalyticsView isCardLoading={isCardLoading} hasGraphPerms={hasGraphPerms} statsArray={statsArray} />
    </YoutubeContext.Provider>
  );
};

YoutubeAnalytics.displayName = 'YoutubeAnalytics';
