import 'angular-route';
import angular from 'angular';
import router from './module.players.routes';

export const playersModule = {
	name: 'players',
	controllers: {
		default: 'playersCtrl',
	},
	routes: {
		list: '/users/:userID/players',
	},
};

export default angular
	.module(playersModule.name, ['ngRoute'])
	.config(router)
	.controller(playersModule.controllers.default, require('./playersCtrl')).name;
