import 'angular-route';
import angular from 'angular';
import { react2angular } from 'react2angular';
import router from './module.events.routes';
import commonServicesModule from '../../services';

// react components
import {
  EventDownloadButton,
  EventUploadModal,
  UploadStatusProgressChart,
  TrimWebEventButton,
  ArchiveWebEventButton,
} from '../../../../src/components';

export const eventsModule = {
  name: 'events',
  controllers: {
    default: 'eventsCtrl',
  },
  routes: {
    list: '/events',
    watch_web: '/events/web/watch/:watchwebeventid',
    details_web: '/events/web/details/:detailswebeventid',
    edit_web: '/events/web/edit/:editwebeventid',
    delete_web: '/events/web/delete/:deletewebeventid',
    stop_web: '/events/web/stop/:stopwebeventid',
    analytics_web: '/events/web/analytics/:analyticswebeventid',
  },
};

export default angular
  .module(eventsModule.name, ['ngRoute', commonServicesModule])
  .config(router)
  .component(
    'eventDownloadButton',
    react2angular(EventDownloadButton, ['customerId', 'eventId', 'eventType', 'streamProfileId', 'webPlayer'])
  )
  .component(
    'eventUploadModal',
    react2angular(EventUploadModal, ['closeFunction', 'customerId', 'getRecentUploads', 'ldFlags'])
  )
  .component('uploadStatusProgressChart', react2angular(UploadStatusProgressChart, ['eventList', 'backToEvents', "isResiAdmin"]))
  .component(
    'trimWebEventButton',
    react2angular(TrimWebEventButton, ['cues', 'webPlayer', 'customerId', 'webEventId', 'webEventProfileId', 'webEventName', 'isWebEventLive', 'getIsWebEventProfileInUse', 'webEvents'])
  )
  .component(
    'archiveWebEventButton',
    react2angular(ArchiveWebEventButton, ['disableButton', 'auth', 'cues', 'webPlayer', 'customerId', 'webEventId', 'webEventProfileId', 'webEventName', 'startTime', 'isWebEventLive'])
  )
  .controller(eventsModule.controllers.default, require('./eventsCtrl')).name;
