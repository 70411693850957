import img from './youtube-icon.png';

// shows YouTube Icon only
class YouTubeIconCtrl {

	constructor() {
		'ngInject';
		this.icon_img = img;
	}

	$onInit () {
		this.margins = `5px 8px 5px 0px`;
		this.yt_url = this.url ? this.url : 'https://www.youtube.com/';
	}
}

// example usage:
// <youtube-icon left-margin="0px"></youtube-icon>
//
export default class YouTubeIconComponent {
	constructor() {
		this.template = require('./youtubeIcon.html').default;
		this.bindings = {
			url: '<',
		};
		this.controller = YouTubeIconCtrl;
	}
}
