import { perms } from '../../jcs';
import { libraryModule } from './index';

function routeConfig($provide, $routeProvider) {
	'ngInject';

	$provide.factory('$routeProvider', () => {
		return $routeProvider;
	});

	const default_options = {
		controller: libraryModule.controllers.default,
		template: require('./library.tmpl.html').default,
		activenav: libraryModule.name,
		access: {
			loginRequired: true,
			permissions: [perms['library.get']],
		}
	};

	const edit_options = {
		controller: libraryModule.controllers.default,
		template: require('./library.tmpl.html').default,
		activenav: libraryModule.name,
		access: {
			loginRequired: true,
			permissions: [perms['library.update']],
		}
	};

	$routeProvider.when(libraryModule.routes.list, default_options);
	$routeProvider.when(libraryModule.routes.details, default_options);
	$routeProvider.when(libraryModule.routes.edit, edit_options);
}

export default routeConfig;
