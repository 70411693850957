import React, { FC, useContext, useEffect, useState } from 'react';
import { LinkButton, PrimaryButton, SecondaryButton, FieldMessage, Stack } from '@resi-media/resi-ui';
import { FileDropzone, ModalContent, ModalFooter, UploadFileRequirements } from '../../..';
import { ACTION_TYPE, EventUploadContext } from '../../../EventUploadModal/context';
import { MAX_FILE_SIZE_GB_RELAXED, toPercent } from '../../../EventUploadModal/eventUploadHelpers';
import ProgressBarLarge from '../../../ProgressBarLarge';
import { UploadWizardSteps } from '../../upload-wizard';
import { MODAL_CONTENT_MIN_HEIGHT } from '../../../../constants/wizard-constants';
import { trackMixpanelEvent, MPEventName, MPEventProperty } from '../../../../mixpanel';

interface Step3UploadAndFinishProps {
  handleFileChange: (files: File[]) => void;
  closeModal: () => void;
  cancelUpload: () => void;
}

const Step3UploadAndFinish: FC<Step3UploadAndFinishProps> = ({ handleFileChange, closeModal, cancelUpload }) => {
  const { state, dispatch } = useContext(EventUploadContext);
  const {
    isUploading,
    uploadProgress,
    uploadTotal,
    isAnalysisRunning,
    analyzingLoaded,
    analyzingTotal,
    fileChecksumProgress,
  } = state;
  const [finished, setFinished] = useState(false);
  const errorMessages = Object.values(state.errors)
    .filter(({ invalid }) => invalid)
    .map(({ message }) => message);
  const hideBackButton = !errorMessages.length && (finished || isUploading || isAnalysisRunning || analyzingLoaded > 0);

  const hasNoErrors = (): boolean => Object.values(state.errors).every(({ invalid }) => !invalid);
  const checkFinished = () => {
    const uploadComplete = Math.floor(uploadProgress / uploadTotal) >= 1 && uploadTotal > 0;
    const analyzingComplete = Math.floor(analyzingLoaded / analyzingTotal) >= 1;
    const checksumComplete = fileChecksumProgress >= analyzingTotal;
    if (isUploading) {
      setFinished(false);
      return;
    }
    if (analyzingComplete && checksumComplete && uploadComplete && hasNoErrors()) {
      trackMixpanelEvent(MPEventName.VIDEO_UPLOAD_COMPLETE, {
        [MPEventProperty.EVENT_NAME]: state.eventName,
        [MPEventProperty.ENCODER_EVENT_PROFILE_UUID]: state.eventProfile,
        [MPEventProperty.SUCCESS_STATE]: 1,
      });
      setFinished(true);
      return;
    }
    setFinished(false);
    return;
  };

  useEffect(() => {
    checkFinished();
  }, [isUploading, uploadProgress, uploadTotal, analyzingLoaded, analyzingTotal, fileChecksumProgress]);

  return (
    <>
      <ModalContent minHeight={MODAL_CONTENT_MIN_HEIGHT}>
        <div className="split-elements">
          <p>Select a file to upload.</p>
          <div className="la1-evt-upload-link-wrap">
            <span
              className="la1-evt-upload-link"
              tabIndex={0}
              role="link"
              onKeyPress={(e) =>
                e.key === 'Enter' &&
                dispatch({ type: ACTION_TYPE.SHOW_FILE_REQUIREMENTS, payload: !state.showFileRequirements })
              }
              onClick={() =>
                dispatch({ type: ACTION_TYPE.SHOW_FILE_REQUIREMENTS, payload: !state.showFileRequirements })
              }
            >
              {state.showFileRequirements ? 'Hide' : 'View'} File Requirements
            </span>
          </div>
        </div>
        <UploadFileRequirements visible={state.showFileRequirements} />
        {!state.file && (
          <FileDropzone
            accept=".mp4,video/mp4"
            onFilesAdded={(files: File[]) => handleFileChange(files)}
            flexDirection="column"
            label="Drag video here or click to browse"
            limitTxt={`mp4 up to ${MAX_FILE_SIZE_GB_RELAXED} GB`}
            minHeight="250px"
          />
        )}
        {!!state.file && (
          <>
            <ProgressBarLarge
              label="Analyze"
              isFinished={Math.floor(analyzingLoaded / analyzingTotal) >= 1 && fileChecksumProgress >= analyzingTotal}
              cancel={cancelUpload}
              progressUpdate={toPercent((state.fileChecksumProgress + state.analyzingLoaded) / 2, state.analyzingTotal)}
              hasError={!!errorMessages.length}
            />
            {(state.isUploading || finished) && (
              <>
                <br />
                <ProgressBarLarge
                  cancel={cancelUpload}
                  isFinished={toPercent(state.uploadProgress, state.uploadTotal) >= 100}
                  label={state.fileName}
                  progressUpdate={toPercent(state.uploadProgress, state.uploadTotal)}
                  hasError={!!errorMessages.length}
                />
              </>
            )}
            {!!state.warnings && (
              <Stack scale="m" alignItems="flex-start">
                <div>
                  {state.warnings.map((warning) => (
                    <div className="la1-progress-bar-lg-error-msg-wrap" key={warning}>
                      <FieldMessage warning={warning} isVisible />
                    </div>
                  ))}
                </div>
              </Stack>
            )}
            {!!errorMessages.length && (
              <Stack scale="m" alignItems="flex-start">
                <div>
                  {errorMessages.map((error, i) => (
                    <div className="la1-progress-bar-lg-error-msg-wrap" key={error + i}>
                      <FieldMessage error={error} isVisible />
                    </div>
                  ))}
                </div>
                <SecondaryButton
                  label="Choose a Different File"
                  onClick={() => {
                    cancelUpload();
                    dispatch({ type: ACTION_TYPE.FILE, payload: null });
                  }}
                />
              </Stack>
            )}
          </>
        )}
      </ModalContent>
      <ModalFooter>
        <div className="la1-evt-upload-m-t-1 float-left">
          <a
            href="https://docs.resi.io/articles/#!easy-upload-video-guide/uploading-a-video-file-in-control"
            target="_blank"
            rel="noopener noreferrer"
          >
            Upload Video Documentation
          </a>
        </div>
        <div
          className="resi-upload-wizard-footer-wrap split-elements"
          style={{ justifyContent: hideBackButton ? 'flex-end' : 'space-between' }}
        >
          {!hideBackButton && (
            <LinkButton
              onClick={() => {
                dispatch({ type: ACTION_TYPE.SET_WIZARD_STEP, payload: UploadWizardSteps.WEB_EVENT_CREATE });
                if (errorMessages.length) {
                  cancelUpload();
                }
              }}
              label="Back"
            />
          )}
          <PrimaryButton isDisabled={!finished} onClick={closeModal} label="Done" />
        </div>
      </ModalFooter>
    </>
  );
};

Step3UploadAndFinish.displayName = 'Step3UploadAndFinish';

export default Step3UploadAndFinish;
