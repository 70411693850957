import { Dispatch } from 'react';
import { Thumbnail } from '../../constants';

export interface Action {
  type: ACTION_TYPE;
  payload?: any;
}

export enum ArchiveTrimWebEventFormErrors {
  TITLE = 'title',
  STATUS_ERROR = 'status-error',
}

export interface ArchiveSaveEventState {
  title: string;
  description?: string;
  startTime?: Date;
  isLoading: boolean;
  isDisabled: boolean;
  isEventStopped: boolean;
  newWebEventUuid: string;
  formErrors: ArchiveTrimWebEventFormErrors[];
  thumbnails: Thumbnail[];
  isUploading: boolean;
}

export interface ArchiveSaveWebEventContext {
  state: ArchiveSaveEventState;
  dispatch: Dispatch<Action>;
}

export enum ACTION_TYPE {
  TITLE = 'TITLE',
  DESCRIPTION = 'DESCRIPTION',
  START_TIME = 'START_TIME',
  LOADING = 'LOADING',
  DISABLED = 'DISABLED',
  EVENT_STOPPED = 'EVENT_STOPPED',
  NEW_WEB_EVENT_UUID = 'NEW_WEB_EVENT_UUID',
  FORM_ERROR = 'FORM_ERROR',
  THUMBNAILS = 'THUMBNAILS',
  UPLOADING = 'UPLOADING',
}

export function reducer(state: ArchiveSaveEventState, action: Action): ArchiveSaveEventState {
  switch (action.type) {
    case ACTION_TYPE.TITLE:
      return { ...state, title: action.payload };
    case ACTION_TYPE.DESCRIPTION:
      return { ...state, description: action.payload };
    case ACTION_TYPE.THUMBNAILS:
      return { ...state, thumbnails: action.payload };
    case ACTION_TYPE.START_TIME:
      return { ...state, startTime: action.payload };
    case ACTION_TYPE.LOADING:
      return { ...state, isLoading: action.payload};
    case ACTION_TYPE.DISABLED:
      return { ...state, isDisabled: action.payload };
    case ACTION_TYPE.EVENT_STOPPED:
      return { ...state, isEventStopped: action.payload };
    case ACTION_TYPE.NEW_WEB_EVENT_UUID:
      return { ...state, newWebEventUuid: action.payload };
    case ACTION_TYPE.FORM_ERROR:
      return { ...state, formErrors: action.payload };
    case ACTION_TYPE.UPLOADING:
      return { ...state, isUploading: action.payload };
    default:
      throw new Error('Invalid Action Type: ' + action.type);
  }
}
