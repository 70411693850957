'use strict';

const jcs = require('../../jcs');
const moment = require('moment');

function UnitsController($scope, $http, $q, $routeParams, $location, $timeout, httpService, paginationService, hardwareService, orgService, focus) {
	'ngInject';

	$(window).trigger('resize'); // ensure footer is properly positioned

	$scope.MODEL_TYPE_DECODER = 'decoder';
	$scope.MODEL_TYPE_ENCODER = 'encoder';

	$scope.SEARCH_FILTER_ALL = 'all';
	$scope.SEARCH_FILTER_ENCODERS = 'encoders';
	$scope.SEARCH_FILTER_DECODERS = 'decoders';

	$scope.DATE_FORMAT = 'MM/DD/YY';
	$scope.DATE_FORMAT_ZULU = 'YYYY-MM-DDTHH:MM:SS';
	$scope.DATE_FORMAT_SEARCH = 'YYYY-MM-DD';

	$scope.is_loading = false;
	$scope.is_busy = false;
	$scope.error_msg = null;

	$scope.search_filter = $scope.SEARCH_FILTER_ALL;
	$scope.search_term_serial = ''; // contents of search text field
	$scope.search_term_model = null;
	$scope.search_term_vendor_invoice = null;
	$scope.search_term_status = null;
	$scope.search_term_location = null;
	$scope.search_term_build_date_start = null;
	$scope.search_term_build_date_end = null;
	$scope.search_error = null;
	$scope.decoder_results = null;

	$scope.hardware_to_view = null;

	$scope.decoder_to_edit = null; // only used when editing existing decoder
	// instead of having a variable for each form field, just trying an object instead. This will be
	// used for both adding new units, and editing existing units
	$scope.decoder_to_edit_fields = null; // used for both existing and new decoders

	$scope.has_error = null;

	$scope.decoder_to_delete = null;

	// allows easy access to info values; use uuid as key to value. ex: name = hardware_map[uuid];
	$scope.hardware_location_map = {};
	$scope.hardware_model_map = {};
	$scope.hardware_status_map = {};
	$scope.hardware_owner_map = {};

	// loaded from API
	$scope.hardware_owner_options = [];
	$scope.hardware_location_options = [];
	$scope.hardware_model_options = [{ uuid: null, name: '' }]; // both encoders and decoders (base models only)
	$scope.hardware_model_decoder_options = [{ uuid: null, name: '' }]; // all decoder models only
	$scope.hardware_model_encoder_options = [{ uuid: null, name: '' }]; // all encoder models only
	$scope.add_edit_hardware_model_options = null; // assigned options based on whether we are adding or editing
	$scope.hardware_status_options = [];
	$scope.hardware_warranty_length_options = [1, 2];

	$scope.enterViewDecoderMode = function (hardware) {
		$scope.hardware_to_view = hardware;

		if (hardware.type == $scope.MODEL_TYPE_ENCODER) {
			$scope.is_loading = true;

			$http.get(`${jcs.api.internal_url}/hardwareunits/${hardware.uuid}/assigned`, { withCredentials: true })
			.then(response => {
				$scope.hardware_to_view.assignedTo = response.data;
			})
			.catch(reason => {
				$scope.hardware_to_view.assignedTo = {
					name: 'There was an error retrieving this info. Please try refreshing the browser.',
					uuid: null,
				}
			})
			.finally(() => {
				$scope.is_loading = false;
			});
		}
	};

	$scope.enterAddDecoderMode = function () {
		var build_date_as_moment = moment();

		$scope.add_edit_hardware_model_options = $scope.hardware_model_decoder_options; // decoders only

		$scope.decoder_to_edit_fields = {
			modelId: null,
			customerOwnerId: '6dcebe46-41c1-49f9-a318-8081d14394e3', // Living As One
			locationId: '41c3c0d1-a231-11e8-b8f3-42010a800133', // JK Solution
			statusId: 'cfd87325-a231-11e8-b8f3-42010a800133', // Stock - New
			buildDate: build_date_as_moment.format($scope.DATE_FORMAT),
			saleDate: null,
			warrantyLength: 1,
		};
		// initialize the date picker itself to today's date, even if input field is empty
		$scope.build_date_picker_set_date(build_date_as_moment);
		$scope.sale_date_picker_set_date(build_date_as_moment);

		focus('edit-decoder-serial');
	};

	$scope.enterEditDecoderMode = function (decoder) {
		// determine what list to show in our model dropdown
		if (decoder.type == $scope.MODEL_TYPE_DECODER) {
			$scope.add_edit_hardware_model_options = $scope.hardware_model_decoder_options;
		} else if (decoder.type == $scope.MODEL_TYPE_ENCODER) {
			$scope.add_edit_hardware_model_options = $scope.hardware_model_encoder_options;
		} else {
			$scope.add_edit_hardware_model_options = $scope.hardware_model_options; // both encoders and decoders
		}

		$scope.decoder_to_edit = decoder;
		$scope.decoder_to_edit_fields = {
			serial: decoder.serial,
			modelId: decoder.modelId,
			customerOwnerId: decoder.customerOwnerId,
			statusId: decoder.statusId,
			locationId: decoder.locationId,
			la1SaleInvoice: decoder.la1SaleInvoice,
			vendorSaleInvoice: decoder.vendorSaleInvoice,
			notes: decoder.notes,
			warrantyLength: decoder.warrantyLength,
			type: decoder.type,
			uuid: decoder.uuid
		};

		if (decoder.saleDate) {
			var sale_date_as_moment = moment(decoder.saleDate);
			$scope.decoder_to_edit_fields.saleDate = sale_date_as_moment.format($scope.DATE_FORMAT);
			$scope.sale_date_picker_set_date(sale_date_as_moment);
		}
		if (decoder.buildDate) {
			var build_date_as_moment = moment(decoder.buildDate);
			$scope.decoder_to_edit_fields.buildDate = build_date_as_moment.format($scope.DATE_FORMAT);
			$scope.build_date_picker_set_date(build_date_as_moment);
		}

		focus('edit-decoder-serial');
	};

	$scope.enterDeleteDecoderMode = function (decoder) {
		$scope.decoder_to_delete = decoder;
	};

	$scope.cancelViewHardware = function () {
		$scope.hardware_to_view = null;

		focus('search-term-input');
	};

	$scope.cancelEditDecoder = function () {
		$scope.decoder_to_edit = null;
		$scope.decoder_to_edit_fields = null;
		$scope.error_msg = null;
		$scope.has_error = {};

		focus('search-term-input');
	};

	$scope.cancelDeleteDecoder = function () {
		$scope.decoder_to_delete = null;
		$scope.error_msg = null;

		focus('search-term-input');
	};

	$scope.getLocationLabel = function (uuid) {
		if ($scope.hardware_location_map.hasOwnProperty(uuid)) {
			return $scope.hardware_location_map[uuid];
		}
		return '';
	};

	$scope.getModelLabel = function (uuid) {
		if ($scope.hardware_model_map.hasOwnProperty(uuid)) {
			return $scope.hardware_model_map[uuid];
		}
		return '';
	};

	$scope.isBaseModel = function (model) {
		var index = model.indexOf('-');
		return index === -1;
	};

	$scope.getStatusLabel = function (uuid) {
		if ($scope.hardware_status_map.hasOwnProperty(uuid)) {
			return $scope.hardware_status_map[uuid];
		}
		return '';
	};

	$scope.getOwnerName = function (uuid) {
		if ($scope.hardware_owner_map.hasOwnProperty(uuid)) {
			return $scope.hardware_owner_map[uuid];
		}
		return '';
	};

	$scope.formatDateAsZulu = function (date_to_format) {
		if (date_to_format == null || date_to_format == '') return '';

		// couldn't just add 'Z' to our moment format string, b/c moment.js was converting it to a time offset (+1:00)
		return (
			moment(date_to_format, $scope.DATE_FORMAT)
				.utc()
				.format($scope.DATE_FORMAT_ZULU) + 'Z'
		);
	};

	$scope.loadHardwareOptions = function () {
		$scope.is_loading = true;

		var promises = [];
		promises.push(hardwareService.getStatusList());
		promises.push(hardwareService.getLocationList());
		promises.push(hardwareService.getModelList());
		promises.push(orgService.getOrganizationNameList());

		$q.all(promises)
			.then(
				function ([status_response, location_response, model_response, org_name_response]) {
					// save status options
					$scope.hardware_status_options = status_response;
					// push "empty" option to front of status list
					$scope.hardware_status_options.unshift({ uuid: null, name: '' });
					// build map (which allows us to easily look up names)
					for (var i = 0; i < $scope.hardware_status_options.length; i++) {
						var entry = $scope.hardware_status_options[i];
						$scope.hardware_status_map[entry.uuid] = entry.name;
					}
					// save location options and build map (which allows us to easily look up names)
					$scope.hardware_location_options = location_response;
					// push "empty" option to front of status list
					$scope.hardware_location_options.unshift({ uuid: null, name: '' });
					// build map (which allows us to easily look up names)
					for (var i = 0; i < $scope.hardware_location_options.length; i++) {
						var entry = $scope.hardware_location_options[i];
						$scope.hardware_location_map[entry.uuid] = entry.name;
					}
					// save model options and build map (which allows us to easily look up names)
					var model_options = model_response;
					for (var i = 0; i < model_options.length; i++) {
						var entry = model_options[i];
						$scope.hardware_model_map[entry.uuid] = entry.name;
						// only add base models to our search criteria model options list
						if ($scope.isBaseModel(entry.name)) {
							$scope.hardware_model_options.push(entry);
						}
						// also split our model list into separate encoder & decoder lists
						if (entry.type == $scope.MODEL_TYPE_DECODER) {
							$scope.hardware_model_decoder_options.push(entry);
						} else {
							$scope.hardware_model_encoder_options.push(entry);
						}
					}

					// save customers and build map (which allows us to easily look up names)
					$scope.hardware_owner_options = org_name_response;
					for (var i = 0; i < $scope.hardware_owner_options.length; i++) {
						var entry = $scope.hardware_owner_options[i];
						$scope.hardware_owner_map[entry.uuid] = entry.name;
					}
				},
				function (reason) {
					if (!httpService.isStatus406(reason)) {
						$scope.error_msg =
							'An error occurred while loading the hardware options. Please try again, or report the problem if it persists.';
					}
				}
			)
			.finally(function () {
				$scope.is_loading = false;

				// see app.js for where focus is defined
				focus('search-term-input');
			});
	};

	// builds query string for search and page changes based on current search criteria
	$scope.buildQueryString = function () {
		var search_queries = [];
		if ($scope.search_filter == $scope.SEARCH_FILTER_DECODERS) {
			search_queries.push('type=decoder');
		} else if ($scope.search_filter == $scope.SEARCH_FILTER_ENCODERS) {
			search_queries.push('type=encoder');
		}

		if (!$scope.isEmpty($scope.search_term_serial)) {
			search_queries.push('serial=' + encodeURIComponent($scope.search_term_serial));
		}
		if ($scope.search_term_model != null) {
			search_queries.push('modelId=' + encodeURIComponent($scope.search_term_model));
		}
		if (!$scope.isEmpty($scope.search_term_vendor_invoice)) {
			search_queries.push('vendorSaleInvoice=' + encodeURIComponent($scope.search_term_vendor_invoice));
		}
		if ($scope.search_term_status != null) {
			search_queries.push('statusId=' + encodeURIComponent($scope.search_term_status));
		}
		if ($scope.search_term_location != null) {
			search_queries.push('locationId=' + encodeURIComponent($scope.search_term_location));
		}
		if (!$scope.isEmpty($scope.search_term_build_date_start)) {
			var formattedDate = moment($scope.search_term_build_date_start, $scope.DATE_FORMAT).format(
				$scope.DATE_FORMAT_SEARCH
			);
			search_queries.push('buildDateStart=' + encodeURIComponent(formattedDate));
		}
		if (!$scope.isEmpty($scope.search_term_build_date_end)) {
			var formattedDate = moment($scope.search_term_build_date_end, $scope.DATE_FORMAT).format(
				$scope.DATE_FORMAT_SEARCH
			);
			search_queries.push('buildDateEnd=' + encodeURIComponent(formattedDate));
		}

		search_queries.push($scope.pagination.getQueryString());

		return search_queries.join('&');
	};

	$scope.performSearch = function () {
		$scope.search_error = null;
		$scope.is_busy = true;

		$scope.pagination.current_page = 1;

		// perform decoder search
		$http
			.get(jcs.api.internal_url + '/hardwareunits?' + $scope.buildQueryString(), {
				withCredentials: true,
			})
			.then(
				function (response) {
					// success

					$scope.pagination.update(response.headers());
					$scope.decoder_results = response.data;

					// some of our data needs formatting
					for (var i = 0; i < $scope.decoder_results.length; i++) {
						$scope.decoder_results[i].modelFormatted = $scope.getModelLabel($scope.decoder_results[i].modelId);
						$scope.decoder_results[i].statusFormatted = $scope.getStatusLabel($scope.decoder_results[i].statusId);
						$scope.decoder_results[i].locationFormatted = $scope.getLocationLabel($scope.decoder_results[i].locationId);
						$scope.decoder_results[i].ownerFormatted = $scope.getOwnerName($scope.decoder_results[i].customerOwnerId);
					}
				},
				function () {
					// error

					$scope.search_error =
						'There was a problem trying to search for decoders. Please try again, or report the problem if it persists.';
					$scope.decoder_results = null;
				}
			)
		['finally'](function () {
			// always called

			$scope.is_busy = false;
		});
	};

	$scope.changePage = function () {
		// only perform search if a search term was provided
		$scope.search_error = null;
		$scope.pagination.is_changing_page = true;

		// perform decoder search
		$http
			.get(jcs.api.internal_url + '/hardwareunits?' + $scope.buildQueryString(), {
				withCredentials: true,
			})
			.then(
				function (response) {
					// success

					$scope.pagination.update(response.headers());
					$scope.decoder_results = response.data;

					// some of our data needs formatting
					for (var i = 0; i < $scope.decoder_results.length; i++) {
						$scope.decoder_results[i].modelFormatted = $scope.getModelLabel($scope.decoder_results[i].modelId);
						$scope.decoder_results[i].statusFormatted = $scope.getStatusLabel($scope.decoder_results[i].statusId);
						$scope.decoder_results[i].locationFormatted = $scope.getLocationLabel($scope.decoder_results[i].locationId);
						$scope.decoder_results[i].ownerFormatted = $scope.getOwnerName($scope.decoder_results[i].customerOwnerId);
					}
				},
				function () {
					// error

					$scope.search_error =
						'There was a problem trying to fetch the decoder data. Please try again, or report the problem if it persists.';
					$scope.decoder_results = null;
				}
			)
		['finally'](function () {
			// always called

			$scope.pagination.is_changing_page = false;
		});
	};

	$scope.deleteDecoder = function () {
		$scope.is_busy = true;

		// update profile
		$http
			.delete(jcs.api.internal_url + '/hardwareunits/' + $scope.decoder_to_delete.uuid, {
				withCredentials: true,
			})
			.then(
				function () {
					// success

					// Note: while we will remove the item from our list, we aren't going to try to update the pagination
					// buttons. They will stay as is. The only time this would come up is if a user deletes all the items
					// on a page, which seems unlikely (unless say the last page only had a couple items on it). Since this
					// seems rare and isn't customer facing, not going to worry about it for now. Can revisit later if necessary.
					// (If they user simply clicks on the existing pagination buttons, they will auto update themselves).

					// remove the deleted decoder from our result list
					$scope.removeDecoderFromResultsList($scope.decoder_to_delete);

					$scope.error_msg = null;
					$scope.decoder_to_delete = null;

					focus('search-term-input');
				},
				function () {
					// error

					$scope.error_msg =
						'An error occurred while attempting to delete the decoder. Please try again, or report the problem if it persists.';
				}
			)
		['finally'](function () {
			// always called

			$scope.is_busy = false;
		});
	};

	$scope.isEmpty = function (value) {
		return value == null || value === '';
	};

	$scope.doesAddOrUpdateDecoderFailValidation = function () {
		$scope.has_error = {};

		// check required fields to ensure they aren't empty
		$scope.has_error.serial = $scope.isEmpty($scope.decoder_to_edit_fields.serial);
		$scope.has_error.modelId = $scope.isEmpty($scope.decoder_to_edit_fields.modelId);
		$scope.has_error.statusId = $scope.isEmpty($scope.decoder_to_edit_fields.statusId);
		$scope.has_error.locationId = $scope.isEmpty($scope.decoder_to_edit_fields.locationId);
		$scope.has_error.customerOwnerId = $scope.isEmpty($scope.decoder_to_edit_fields.customerOwnerId);
		$scope.has_error.buildDate = $scope.isEmpty($scope.decoder_to_edit_fields.buildDate);
		$scope.has_error.warrantyLength = $scope.isEmpty($scope.decoder_to_edit_fields.warrantyLength);

		var error_count = 0;
		for (var property in $scope.has_error) {
			if ($scope.has_error[property] === true) {
				error_count++;
			}
		}
		var has_validation_error = error_count > 0;

		$scope.error_msg = has_validation_error ? 'Please specify a value for the highlighted fields below.' : null;

		return has_validation_error;
	};

	$scope.addOrUpdateDecoder = function () {
		// if we have form validation errors, then don't go any further
		if ($scope.doesAddOrUpdateDecoderFailValidation()) return false;

		$scope.performSaveForAddOrUpdateDecoder();
	};

	$scope.removeDecoderFromResultsList = function (decoder_to_remove) {
		if (decoder_to_remove != null) {
			for (var i = 0; i < $scope.decoder_results.length; i++) {
				if ($scope.decoder_results[i].uuid == decoder_to_remove.uuid) {
					$scope.decoder_results.splice(i, 1);
					$scope.pagination.total_records--;
				}
			}
		}
	};

	$scope.refreshDecoderListWithSavedData = function () {
		// find decoder in $scope.decoder_results and update entry
		if ($scope.decoder_to_edit != null) {
			for (var i = 0; i < $scope.decoder_results.length; i++) {
				if ($scope.decoder_to_edit.uuid == $scope.decoder_results[i].uuid) {
					var uuid = $scope.decoder_results[i].uuid;
					var type = $scope.decoder_results[i].type;
					$scope.decoder_results[i] = $scope.decoder_to_edit_fields;
					$scope.decoder_results[i].uuid = uuid;
					$scope.decoder_results[i].type = type;
					$scope.decoder_results[i].modelFormatted = $scope.getModelLabel($scope.decoder_results[i].modelId);
					$scope.decoder_results[i].statusFormatted = $scope.getStatusLabel($scope.decoder_results[i].statusId);
					$scope.decoder_results[i].locationFormatted = $scope.getLocationLabel($scope.decoder_results[i].locationId);
					$scope.decoder_results[i].ownerFormatted = $scope.getOwnerName($scope.decoder_results[i].customerOwnerId);
					if ($scope.decoder_to_edit_fields.buildDate != '') {
						$scope.decoder_results[i].buildDate = $scope.formatDateAsZulu($scope.decoder_to_edit_fields.buildDate);
					}
					if ($scope.decoder_to_edit_fields.saleDate != '') {
						$scope.decoder_results[i].saleDate = $scope.formatDateAsZulu($scope.decoder_to_edit_fields.saleDate);
					}
					break;
				}
			}
		}

		// update $scope.hardware_to_view if it exists
		if ($scope.hardware_to_view != null) {
			var uuid = $scope.hardware_to_view.uuid;
			var type = $scope.hardware_to_view.type;
			$scope.hardware_to_view = $scope.decoder_to_edit_fields;
			$scope.hardware_to_view.uuid = uuid;
			$scope.hardware_to_view.type = type;
			// TODO: should we move this to a method? since we do the same thing after loading search results??
			// may not be that easy ...
			$scope.hardware_to_view.modelFormatted = $scope.getModelLabel($scope.hardware_to_view.modelId);
			$scope.hardware_to_view.statusFormatted = $scope.getStatusLabel($scope.hardware_to_view.statusId);
			$scope.hardware_to_view.locationFormatted = $scope.getLocationLabel($scope.hardware_to_view.locationId);
			$scope.hardware_to_view.ownerFormatted = $scope.getOwnerName($scope.hardware_to_view.customerOwnerId);
		}
	};

	$scope.performSaveForAddOrUpdateDecoder = function () {
		$scope.is_busy = true;

		// normally we could just do our save using the decoder_to_edit_fields directly, but since we need
		// to modify the format of the date fields, we need to create a separate object. Otherwise if the
		// user gets an error, then when we try to save again, formatting the date fields will fail because
		// they will already be in the updated format.
		var api_data = {};
		for (var property in $scope.decoder_to_edit_fields) {
			api_data[property] = $scope.decoder_to_edit_fields[property];
		}

		if (api_data.buildDate) {
			api_data.buildDate = $scope.formatDateAsZulu(api_data.buildDate);
		}
		if (api_data.saleDate) {
			api_data.saleDate = $scope.formatDateAsZulu(api_data.saleDate);
		} else if (api_data.saleDate == '') {
			api_data.saleDate = null; // don't send blank to api, convert to null
		}

		if ($scope.decoder_to_edit != null) {
			// save existing decoder

			// update profile
			$http
				.patch(jcs.api.internal_url + '/hardwareunits/' + $scope.decoder_to_edit.uuid, api_data, {
					withCredentials: true,
				})
				.then(
					function () {
						// success

						// do before we null out the edit related variables
						$scope.refreshDecoderListWithSavedData();

						$scope.has_error = {};
						$scope.error_msg = null;
						$scope.decoder_to_edit = null;
						$scope.decoder_to_edit_fields = null;

						focus('search-term-input');
					},
					function () {
						// error

						$scope.error_msg =
							'An error occurred while attempting to save your changes. Please try again, or report the problem if it persists.';
					}
				)
			['finally'](function () {
				// always called

				$scope.is_busy = false;
			});
		} else {
			// create new decoder

			// update profile
			$http
				.post(jcs.api.internal_url + '/hardwareunits', api_data, { withCredentials: true })
				.then(
					function () {
						// success

						// Note: we are not going to try to insert the new item into our current results list, since
						// we don't even know if the new item would meet the current search requirements. If the user
						// wants the list updated they can just hit search again. We can change this later if necessary.

						$scope.has_error = {};
						$scope.error_msg = null;
						$scope.decoder_to_edit = null;
						$scope.decoder_to_edit_fields = null;

						focus('search-term-input');
					},
					function () {
						// error

						$scope.error_msg =
							'An error occurred while attempting to create the new decoder. Please try again, or report the problem if it persists.';
					}
				)
			['finally'](function () {
				// always called

				$scope.is_busy = false;
			});
		}
	};

	// since our pagination has to be initialized after we have declared our changePage method
	$scope.pagination = paginationService.init('serial', paginationService.SORT_DIR_ASC, $scope.changePage);

	$scope.loadHardwareOptions();

	$scope.date_picker_options = {
		singleDatePicker: true,
		autoApply: true,
		autoUpdateInput: false, // allows date field to be blank
		locale: {
			format: $scope.DATE_FORMAT, //'ll' // <= looks like this is using moment.js formatting options
		},
	};
	// sets the current selected date of the datepicker; even if corresponding input field is empty, we may
	// want to set the datepicker to today's date.
	$scope.build_date_picker_set_date = function (date_as_moment) {
		if (date_as_moment != null) {
			$('#edit-decoder-build-date')
				.data('daterangepicker')
				.setStartDate(date_as_moment);
			$('#edit-decoder-build-date')
				.data('daterangepicker')
				.setEndDate(date_as_moment);
		}
	};
	// sets the current selected date of the datepicker; even if corresponding input field is empty, we may
	// want to set the datepicker to today's date.
	$scope.sale_date_picker_set_date = function (date_as_moment) {
		if (date_as_moment != null) {
			$('#edit-decoder-sale-date')
				.data('daterangepicker')
				.setStartDate(date_as_moment);
			$('#edit-decoder-sale-date')
				.data('daterangepicker')
				.setEndDate(date_as_moment);
		}
	};
	$scope.build_date_picker_callback = function (start, end, label) {
		$scope.decoder_to_edit_fields.buildDate = moment(start).format($scope.DATE_FORMAT);
		$('#edit-decoder-build-date').val($scope.decoder_to_edit_fields.buildDate);
	};
	$scope.sale_date_picker_callback = function (start, end, label) {
		$scope.decoder_to_edit_fields.saleDate = moment(start).format($scope.DATE_FORMAT);
		$('#edit-decoder-sale-date').val($scope.decoder_to_edit_fields.saleDate);
	};
	$scope.search_build_date_start_callback = function (start, end, label) {
		$scope.search_term_build_date_start = moment(start).format($scope.DATE_FORMAT);
		$('#search-build-date-start').val($scope.search_term_build_date_start);
	};
	$scope.search_build_date_end_callback = function (start, end, label) {
		$scope.search_term_build_date_end = moment(start).format($scope.DATE_FORMAT);
		$('#search-build-date-end').val($scope.search_term_build_date_end);
	};

	$('#edit-decoder-build-date').daterangepicker($scope.date_picker_options, $scope.build_date_picker_callback);
	$('#edit-decoder-sale-date').daterangepicker($scope.date_picker_options, $scope.sale_date_picker_callback);
	$('#search-build-date-start').daterangepicker($scope.date_picker_options, $scope.search_build_date_start_callback);
	$('#search-build-date-end').daterangepicker($scope.date_picker_options, $scope.search_build_date_end_callback);

	$timeout(function () {
		$('[data-toggle="tooltip"]').tooltip(); // needs to be done in timeout, otherwise for some reason the tooltip gets built before angular does it's magic
	});
}

module.exports = UnitsController;
