import { dashboardModule } from '../../dashboard';
import { encodersModule } from '../../encoders';
import { eventsModule } from '../../events';
import { campusreviewModule } from '../../campusreview';
import { getStartedModule } from '../../getstarted';
import { passwordResetModule } from '../../passwordreset';
import { unregisteruploaderModule } from '../../unregisteruploader/index';
import { api, studioUrl } from '../../../jcs';
import { AUTH_NAME, AUTH_CUSTOMER_NAME, AUTH_CUSTOMER_ID, Toggles, TT_ORG_ID } from '../../../constants';
import { refreshSyntheticToggles } from '../../../helpers/synthetic-toggles';
import { trackMixpanelEvent, MPEventName } from '../../../../../src/mixpanel';

export default class LoginController {
	constructor($location, $http, Authentication, orgService, $rootScope) {
		'ngInject';
		this.$location = $location;
		this.$http = $http;
		this.$rootScope = $rootScope;
		this.orgService = orgService;
		this.Authentication = Authentication;
		this.loginModel = {};
		this.isBusy = false;
		this.invalidLogin = false;
		this.showStudioBanner = false;
		this.studioUrl = studioUrl;

		$(window).trigger('resize'); // ensure footer is properly positioned (especially when we show "not authorized" page)
	}

	blankLayout() {
		const urlLocation = window.location.pathname;
		const blankLayoutLocations = [
			'/login',
			'/uploader',
			'/emailverification',
			unregisteruploaderModule.routes.login,
			getStartedModule.routes.getStarted,
			getStartedModule.routes.finishSetup,
			passwordResetModule.routes.passwordReset,
			'/userinvites',
		];
		const onBlankLayoutPage = blankLayoutLocations.some((x) => urlLocation.includes(x));
		return !this.getCurrentUser() || onBlankLayoutPage;
	}

	login() {
		this.invalidLogin = false;
		this.isBusy = true;

		this.Authentication.login(this.loginModel.email, this.loginModel.password)
			.then(async () => {
				try {
					if(await this.hasNewSchedulerVersion() && !this.getCurrentUser().hasPerm('la1only') && this.getCurrentUser().customerID !== TT_ORG_ID) {
						sessionStorage.removeItem(AUTH_NAME);
						sessionStorage.removeItem(AUTH_CUSTOMER_NAME);
						sessionStorage.removeItem(AUTH_CUSTOMER_ID);
						window.location.href = this.studioUrl;
						return;
					}
				} catch (error) {
					console.error(error);
				}
				try {
					if ((await this.hasWebPlan()) && !(await this.hasCompletedSetup())) {
						window.location.assign('/finishsetup');
						return;
					}
				} catch (error) {
					console.error(error);
				}
				if (!this.Authentication.currentUser.toggles.includes(Toggles.FACEBOOK)) {
					this.Authentication.syntheticToggles = await refreshSyntheticToggles(
						this.$http,
						this.Authentication.currentUser.customerID
					);
				}
				const can_see_dashboard =
					this.Authentication.getCurrentUser().hasPerm('dashboard.get') ||
					this.Authentication.getCurrentUser().hasPerm('dashboard.multisite.get');
				if (can_see_dashboard) {
					this.$location.path(dashboardModule.routes.list);
				} else if (this.Authentication.getCurrentUser().hasPerm('encoders.get')) {
					this.$location.path(encodersModule.routes.list);
				} else if (this.Authentication.getCurrentUser().hasPerm('campus_review.get')) {
					this.$location.path(campusreviewModule.routes.list);
				} //otherwise just show them the events list
				else {
					this.$location.path(eventsModule.routes.list);
				}
			})
			.catch((e) => {
				console.log(e);
				this.invalidLogin = true;
			})
			.finally(() => {
				this.isBusy = false;
			});
	}

	async hasCompletedSetup() {
		const response = await this.$http.get(`${api.url_v3}/customers/${this.getCurrentUser().customerID}/fts`, {
			withCredentials: true,
		});
		return response.data && response.data.hasEventProfile && response.data.hasWebEventProfile;
	}

	async hasWebPlan() {
		const organization = await this.orgService.getOrganization(this.getCurrentUser().customerID);
		return organization && organization.webPlanId;
	}

	async hasNewSchedulerVersion() {
		const response = await this.$http.get(`${api.url_v3}/customers/${this.getCurrentUser().customerID}`, {
			withCredentials: true,
		});
		return response.data.schedulerVersion === 2;
	}

	uploaderLogin() {
		this.invalidLogin = false;
		this.isBusy = true;

		return this.Authentication.login(this.loginModel.email, this.loginModel.password)
			.catch((e) => {
				console.log(e);
				this.invalidLogin = true;
				throw e;
			})
			.finally(() => {
				this.isBusy = false;
			});
	}

	logout() {
		this.Authentication.logout();
	}

	getCurrentUser() {
		return this.Authentication.getCurrentUser();
	}

	trackMediaPanelNavItem(menu_name) {
		// use switch statement if tracking other nav item mixpanel view events
		trackMixpanelEvent(MPEventName.LIBRARY_VIEW);
	}

	canShowMenu(menu_name) {
		const current_user = this.getCurrentUser();
		if (current_user != null) {
			switch (menu_name) {
				case 'dashboard':
					return current_user.hasPerm('dashboard.get') || current_user.hasPerm('dashboard.multisite.get');
				case 'encoders':
					return current_user.hasPerm('encoders.get');
				case 'events':
					return current_user.hasPerm('events.get');
				case 'campusreview':
					return current_user.hasPerm('campus_review.get');
				case 'library':
					return current_user.hasPerm('library.get');
				case 'destinationgroups':
					return current_user.hasPerm('destination_groups.get');
				case 'schedule':
					return current_user.hasPerm('schedules.get');
				case 'socialmedia':
					return current_user.hasPerm('social_accts.get');
				case 'encoderprofiles':
					return current_user.hasPerm('encoder_profiles.get');
				case 'eventprofiles':
					return current_user.hasPerm('event_profiles.get');
				case 'users':
					return current_user.hasPerm('users.get');
				case 'contacts':
					return current_user.hasPerm('contacts.get');
				case 'customers':
					return current_user.hasPerm('customers.get_all');
				case 'monitor':
					return current_user.hasPerm('monitor.get');
				case 'analyticsV2':
					return current_user.hasPerm('analytics.get');
				case 'contactsearch':
					return current_user.hasPerm('contact_search.get');
				case 'onboarding':
					return current_user.hasPerm('encoders.add');
				case 'orders':
					return current_user.hasPerm('orders.get');
				case 'unitdashboard':
					return current_user.hasPerm('hardware_units.dashboard');
				case 'units':
					return current_user.hasPerm('hardware_units.get');
				case 'unitoptions':
					return current_user.hasPerm('hardware_options.get');
			}
		}
		return false;
	}

	canClickOnCustomerName() {
		const user = this.getCurrentUser();
		if (user != null) {
			return user.hasPerm('la1only');
		}

		return false;
	}

	canShowHardwareMenu() {
		const current_user = this.getCurrentUser();
		if (current_user != null) {
			return (
				current_user.hasPerm('encoders.add') ||
				current_user.hasPerm('orders.get') ||
				current_user.hasPerm('hardware_units.get') ||
				current_user.hasPerm('hardware_options.get') ||
				current_user.hasPerm('hardware_units.dashboard')
			);
		}
		return false;
	}
}
