import { Context, createContext } from 'react';
import { YOUTUBE_PAGE_STATE_CONTEXT_KEYS } from '../../../pages/EventAnalytics/event-analytics-constants';

export interface YoutubeActions<T> {
  payload: T;
  type: YOUTUBE_PAGE_STATE_CONTEXT_KEYS;
}

export interface YoutubeContextType {
  groupBy: string;
  page: string;
}

export interface YoutubeContextProps {
  youtubeAnalyticsState: YoutubeContextType;
  dispatch: React.Dispatch<YoutubeActions<any>>;
}

export const YoutubeContext: Context<YoutubeContextProps> = createContext<YoutubeContextProps>(
  {} as YoutubeContextProps
);

export const dispatchers = {
  [YOUTUBE_PAGE_STATE_CONTEXT_KEYS.GROUP_BY]: (
    state: YoutubeContextType,
    action: YoutubeActions<string>
  ): YoutubeContextType => {
    return { ...state, groupBy: action.payload };
  },
  [YOUTUBE_PAGE_STATE_CONTEXT_KEYS.PAGE]: (
    state: YoutubeContextType,
    action: YoutubeActions<string>
  ): YoutubeContextType => {
    return { ...state, page: action.payload };
  },
};

export const YoutubeReducer = (state: YoutubeContextType, action: YoutubeActions<any>): YoutubeContextType => {
  return dispatchers[action.type](state, action);
};
