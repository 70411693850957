import RegionComponent from './region.component';

export const regionModule = {
	name: 'regionComponent',
	component: {
		name: 'region'
	}
};

export default angular
	.module(regionModule.name, [])
	.component(regionModule.component.name, new RegionComponent()).name;
