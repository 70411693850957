import { Mp4BoxInfo, TrackSample, ISOFile, Mp4aBox } from 'mp4box';
import { ValidationsObject, VALIDATIONS, UpdateError, CHECKSUM_MESSAGE, DescriptorTag } from './eventUploadTypes';
import ChecksumWorker from './checksum.worker';
import { Action, ACTION_TYPE, EventUploadState } from './context';
import { MPEventProperty } from '../../mixpanel';
import React from 'react';
import { BitReader } from './bit-reader';

export const MAX_KEYFRAME_INTERVAL = 3.2;
export const KB = 1024;
export const MB = KB * KB;
const GB = MB * KB;

export const MAX_DURATION_HOURS = 6;
export const MAX_DURATION_HOURS_RELAXED = 12;
export const MAX_DURATION_SEC_RELAXED = 60 * 60 * MAX_DURATION_HOURS_RELAXED;
export const MAX_DURATION_SEC = 60 * 60 * MAX_DURATION_HOURS;

export const MAX_FILE_SIZE_GB = 15;
export const MAX_FILE_SIZE_GB_RELAXED = 64;
export const MAX_FILE_SIZE = MAX_FILE_SIZE_GB * GB;
export const MAX_FILE_SIZE_RELAXED = MAX_FILE_SIZE_GB_RELAXED * GB;

const MAX_FRAGMENT_MBPS = 24;
const AUDIO_SAMPLERATE = 48000;

export const validations: ValidationsObject = Object.freeze({
  [VALIDATIONS.BACK_END]: {
    message: 'Something went wrong with your upload. Please try again or report the problem if it persists.',
    invalid: false,
    key: 'back-end-error',
  },
  [VALIDATIONS.NETWORK_ERROR]: {
    message: 'Upload failed due to a connection issue. Please try again or report the problem if it persists.',
    invalid: false,
    key: 'back-end-error',
  },
  [VALIDATIONS.NOT_A_VIDEO]: {
    message: 'File must be a video file',
    invalid: false,
    key: 'not-a-video',
  },
  [VALIDATIONS.INVALID_FORMAT]: {
    message: 'File format must be H.264',
    invalid: false,
    key: 'bad-format',
  },
  [VALIDATIONS.INVALID_DIMENSIONS]: {
    message: 'File must have a 16:9 aspect ratio',
    invalid: false,
    key: 'invalid-dimensions',
  },
  [VALIDATIONS.BLANK_EVENT_NAME]: {
    message: 'Event Name cannot be blank',
    invalid: false,
    key: 'invalid-event-name',
  },
  [VALIDATIONS.BLANK_EVENT_PROFILE]: {
    message: 'Encoder Event Profile must be selected',
    invalid: false,
    key: 'invalid-event-profile',
  },
  [VALIDATIONS.BLANK_WEB_EVENT_PROFILE]: {
    message: "Web Event Profile must be selected if 'Create a web event on upload' is checked",
    invalid: false,
    key: 'invalid-event-profile',
  },
  [VALIDATIONS.NO_UPLOAD_FILE]: {
    message: 'Please select a file to upload',
    invalid: false,
    key: 'invalid-upload-selection',
  },
  [VALIDATIONS.FILE_SIZE_0]: {
    message: 'The file you tried to upload appears empty. Cancel and try again, or report the problem if it persists.',
    invalid: false,
    key: 'empty-file-size',
  },
  [VALIDATIONS.INVALID_FILE_TYPE]: {
    message: 'File must be an mp4 file',
    invalid: false,
    key: 'invalid-file-type',
  },
  [VALIDATIONS.INVALID_CONTAINER_FORMAT]: {
    message: 'File does not contain MP4 content. Please re-encode the file as an MP4 and try again.',
    invalid: false,
    key: 'invalid-container-format',
  },
  [VALIDATIONS.INVALID_AUDIO_TYPE]: {
    message: 'Audio codec must be aac',
    invalid: false,
    key: 'invalid-audio-type',
  },
  [VALIDATIONS.MAX_DURATION]: {
    message: `Max duration is ${MAX_DURATION_HOURS} hours`,
    invalid: false,
    key: 'invalid-max-duration',
  },
  [VALIDATIONS.MAX_DURATION_RELAXED]: {
    message: `Max duration is ${MAX_DURATION_HOURS_RELAXED} hours`,
    invalid: false,
    key: 'invalid-max-duration-relaxed',
  },
  [VALIDATIONS.MAX_FILE_SIZE]: {
    message: `Max file size is ${MAX_FILE_SIZE_GB} GB`,
    invalid: false,
    key: 'invalid-max-file-size',
  },
  [VALIDATIONS.MAX_FILE_SIZE_RELAXED]: {
    message: `Max file size is ${MAX_FILE_SIZE_GB_RELAXED} GB`,
    invalid: false,
    key: 'invalid-max-file-size-relaxed',
  },
  [VALIDATIONS.MAX_AUDIO_CHANNELS]: {
    message: 'File cannot exceed 2 audio channels',
    invalid: false,
    key: 'invalid-max-audio-channels',
  },
  [VALIDATIONS.MAX_AUDIO_BITRATE]: {
    message: 'Audio bitrate cannot exceed 384kbps',
    invalid: false,
    key: 'invalid-max-bit-rate',
  },
  [VALIDATIONS.MAX_VIDEO_CHANNELS]: {
    message: 'File has more than one video channel',
    invalid: false,
    key: 'invalid-max-video-channels',
  },
  [VALIDATIONS.MAX_VIDEO_BITRATE]: {
    message: 'Video bitrate cannot exceed 8 Mbps for 59.94/60 fps, or 6 Mbps for all other framerates',
    invalid: false,
    key: 'invalid-max-video-bitrate',
  },
  [VALIDATIONS.MAX_VIDEO_BITRATE]: {
    message: 'Video bitrate cannot exceed 12 Mbps for 59.94/60 fps, or 8 Mbps for all other framerates',
    invalid: false,
    key: 'invalid-max-video-bitrate-relaxed',
  },
  [VALIDATIONS.MAX_RESOLUTION]: {
    message: 'Max resolution is 3840 x 2160',
    invalid: false,
    key: 'invalid-max-resolution',
  },
  [VALIDATIONS.INVALID_FPS]: {
    message:
      'Frame rate must be 24, 25, 30, or 60 fps (NTSC-interoperable frame rates are also supported).',
    invalid: false,
    key: 'invalid-max-fps',
  },
  [VALIDATIONS.INVALID_FRAMERATE_MODE]: {
    message:
      'Frame rate must be constant, detected variable frame rate',
    invalid: false,
    key: 'invalid-frame-rate-mode',
  },
  [VALIDATIONS.MAX_GOP]: {
    message:
      'Keyframe Interval is too large for sim-live or social destination streaming.  See Upload File Requirements for more information.',
    invalid: false,
    key: 'invalid-max-gop-interval',
  },
  [VALIDATIONS.INVALID_AUDIO_SAMPLERATE]: {
    message: 'Audio sample rate must be 48 kHz.',
    invalid: false,
    key: 'invalid_audio_samplerate',
  },
  [VALIDATIONS.NO_AUDIO]: {
    message: 'Upload file must contain audio.',
    invalid: false,
    key: 'invalid_no_audio',
  },
});

const areDefined = (...args: any[]) => [...args].every((x) => x !== undefined && x !== null);

export const toPercent = (progress: number, total: number): number => Math.floor(100 * (progress / total)) || 0;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const readFile = (file: File): Promise<any> => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => resolve(reader.result), false);
    reader.readAsDataURL(file);
  });
};

export interface ChecksumTask {
  hash(): Promise<string | null>;
  cancel(): void;
}

class InternalChecksumTask implements ChecksumTask {
  constructor(private worker: Worker, private resultPromise: Promise<string | null>, private onCancel?: () => void) {}

  public hash(): Promise<string | null> {
    return this.resultPromise;
  }

  public cancel(): void {
    this.onCancel?.();
    this.worker.terminate();
  }
}

export function computeChecksum(blob: Blob, onProgress: (progressBytes: number) => void): ChecksumTask {
  const worker = new ChecksumWorker();
  let onCancel;
  const resultPromise = new Promise<string | null>((resolve, reject) => {
    worker.onerror = (e) => reject(e.error);
    onCancel = () => {
      console.log('Checksum task was cancelled');
      resolve(null);
    };
    worker.onmessage = ({ data: message }) => {
      switch (message.type) {
        case CHECKSUM_MESSAGE.PROGRESS:
          onProgress(message.payload);
          break;
        case CHECKSUM_MESSAGE.SUCCESS:
          resolve(message.payload);
          break;
        case CHECKSUM_MESSAGE.ERROR:
          reject(message.payload);
          break;
      }
    };
    worker.postMessage(blob);
  });

  return new InternalChecksumTask(worker, resultPromise, onCancel);
}

function readUint64(dataView: DataView, offset: number, littleEndian: boolean): number {
  // This can't represent all 64 bit numbers, but 9007199254740991 bytes (~8192 TiB) ought to be enough for anybody.
  // From https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/DataView#64-bit_integer_values
  const left = dataView.getUint32(offset, littleEndian);
  const right = dataView.getUint32(offset + 4, littleEndian);
  return littleEndian ? left + 2 ** 32 * right : 2 ** 32 * left + right;
}

function readAsArrayBuffer(blob: Blob): Promise<ArrayBuffer> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => {
      if (reader.result instanceof ArrayBuffer) {
        resolve(reader.result);
        return;
      }
      reject(new Error('result must be an ArrayBuffer'));
    });
    reader.addEventListener('error', () => reject(reader.error));
    reader.readAsArrayBuffer(blob);
  });
}

export async function sliceLargeBlob(
  blob: Blob,
  addToBuffer: (blob: Blob, pos: number) => Promise<void>
): Promise<void> {
  const maxSliceBytes = 32 * MB;
  let currentValue = 0;

  if (blob.size > maxSliceBytes) {
    while (currentValue < blob.size || blob.size - currentValue > maxSliceBytes) {
      const smallBlob = blob.slice(currentValue, currentValue + maxSliceBytes + 1, 'video/mp4');
      await addToBuffer(smallBlob, currentValue);
      currentValue += maxSliceBytes + 1;
    }
  }

  // handle last remaining blob that was less than maxSliceBytes
  const smallBlob = blob.slice(currentValue, blob.size);
  await addToBuffer(smallBlob, currentValue);
}

export class Mp4ValidationError extends Error {}

const MDAT = ('m'.charCodeAt(0) << 24) | ('d'.charCodeAt(0) << 16) | ('a'.charCodeAt(0) << 8) | 't'.charCodeAt(0);
const FTYP = ('f'.charCodeAt(0) << 24) | ('t'.charCodeAt(0) << 16) | ('y'.charCodeAt(0) << 8) | 'p'.charCodeAt(0);

export async function spliceMp4(
  blob: Blob,
  addToBuffer: (buffer: ArrayBuffer, fileStart: number) => void,
  reportProgress: (progressBytes: number) => void,
  isCanceled: () => boolean
): Promise<void> {
  const bufferSize = 32 * MB;

  let data = new DataView(new ArrayBuffer(0));
  let fileIndex = 0; // Where we are in the file
  let bufferIndex = 0; // Where we are in the buffer
  let processedSize = 0; // How many bytes have been read excluding mdat boxes
  let isFirstBox = true;
  while (fileIndex < blob.size) {
    if (isCanceled()) {
      return;
    }
    // We may read up to 16 bytes of data for the size, box type and optional 64 bit size
    if (bufferIndex + 15 >= data.byteLength) {
      bufferIndex = 0;
      data = new DataView(await readAsArrayBuffer(blob.slice(fileIndex, fileIndex + bufferSize)));
      reportProgress(fileIndex);
    }
    const boxSize = (() => {
      const size = data.getUint32(bufferIndex, false);
      if (size === 0) {
        // The box takes up the remainder of the file
        return blob.size - fileIndex;
      }
      if (size === 1) {
        // The box has a 64 bit size
        return readUint64(data, bufferIndex + 8, false);
      }
      return size;
    })();

    const boxType = data.getUint32(bufferIndex + 4, false);
    if (isFirstBox && boxType !== FTYP) {
      throw new Mp4ValidationError('failed to find ftyp box at the start of the file; likely not a valid MP4');
    }
    isFirstBox = false;

    if (boxType !== MDAT) {
      const boxBuffer =
        bufferIndex + boxSize >= data.byteLength
          ? await readAsArrayBuffer(blob.slice(fileIndex, fileIndex + boxSize))
          : data.buffer.slice(bufferIndex, bufferIndex + boxSize);
      addToBuffer(boxBuffer, processedSize);
      processedSize += boxSize;
    }
    fileIndex += boxSize;
    bufferIndex += boxSize;
  }

  reportProgress(blob.size);
}

function hasAudio(info: Mp4BoxInfo): boolean {
  if (!info?.audioTracks || !info.audioTracks.length) {
    console.log('No audio tracks found for this video upload');
    return false;
  }
  return true;
}

// validations
export function validateDuration(info: Mp4BoxInfo, updateError: UpdateError) {
  const [videoTrack] = info.videoTracks;
  const seconds = videoTrack.duration / videoTrack.timescale;
  if (seconds > MAX_DURATION_SEC_RELAXED) {
    updateError(VALIDATIONS.MAX_DURATION_RELAXED);
    return;
  }

  updateError(VALIDATIONS.MAX_DURATION_RELAXED, false);
}

export function validateFileSize(info: Mp4BoxInfo, updateError: UpdateError) {
  const [videoTrack] = info.videoTracks;

  if (videoTrack.size > MAX_FILE_SIZE_RELAXED) {
    updateError(VALIDATIONS.MAX_FILE_SIZE_RELAXED);
    return;
  }

  updateError(VALIDATIONS.MAX_FILE_SIZE_RELAXED, false);
}

export function validateVideoType(info: Mp4BoxInfo, updateError: UpdateError) {
  const supportedVideoTypes = ['avc1'];
  let supportedVideoType = false;

  for (const type of supportedVideoTypes) {
    if (!info?.videoTracks[0].codec) {
      continue;
    }

    if (info.videoTracks[0].codec.toLowerCase().indexOf(type) > -1) {
      supportedVideoType = true;
      break;
    }
  }

  if (!supportedVideoType) {
    updateError(VALIDATIONS.INVALID_FORMAT);
    return;
  }
  updateError(VALIDATIONS.INVALID_FORMAT, false);
}

export function validateAudioType(info: Mp4BoxInfo, updateError: UpdateError) {
  if (!hasAudio(info)) {
    return;
  }

  const [audioTrack] = info.audioTracks;

  if (/^mp4a.40/.test(audioTrack.codec) === false) {
    updateError(VALIDATIONS.INVALID_AUDIO_TYPE);
    return;
  }
  updateError(VALIDATIONS.INVALID_AUDIO_TYPE, false);
}

export function validateMaxAudioChannels(info: Mp4BoxInfo, updateError: UpdateError, mixPanelData = {}) {
  if (!hasAudio(info)) {
    return;
  }

  const [audioTrack] = info.audioTracks;
  console.log(`Max Audio Tracks: 2 -- Actual Audio Tracks: ${audioTrack.audio.channel_count}`);
  Object.assign(mixPanelData, {
    [MPEventProperty.AUDIO_TRACKS]: audioTrack.audio.channel_count,
  });
  if (audioTrack.audio.channel_count > 2) {
    updateError(VALIDATIONS.MAX_AUDIO_CHANNELS);
    return;
  }
  updateError(VALIDATIONS.MAX_AUDIO_CHANNELS, false);
}

export function validateAudioBitrate(info: Mp4BoxInfo, updateError: UpdateError, dispatch: React.Dispatch<Action>, mixPanelData = {}) {
  // allows max bit rate and some extra.
  // Technically max bit rate is 384kbps, but we allow 9% over this
  if (!hasAudio(info)) {
    return;
  }

  const [audioTrack] = info.audioTracks;

  const bitrate = audioTrack.bitrate / 1000;
  console.log(`Max Bitrate: 384kbps -- Actual Audio Bitrate: ${bitrate}kbps`);
  Object.assign(mixPanelData, {
    [MPEventProperty.AUDIO_BITRATE_KBPS]: bitrate,
  });
  if (bitrate > 420) {
    updateError(VALIDATIONS.MAX_AUDIO_BITRATE);
    return;
  }

  updateError(VALIDATIONS.MAX_AUDIO_BITRATE, false);
  dispatch({type: ACTION_TYPE.AUDIO_BITRATE, payload: bitrate });
}

export function validateMaxVideoChannels(info: Mp4BoxInfo, updateError: UpdateError, mixPanelData = {}) {
  console.log(`Max Video Channels: 1 -- Actual Video Channels: ${info.videoTracks.length}`);
  Object.assign(mixPanelData, {
    [MPEventProperty.VIDEO_CHANNELS]: info.videoTracks.length,
  });

  if (info.videoTracks && info.videoTracks.length > 1) {
    updateError(VALIDATIONS.MAX_VIDEO_CHANNELS);
    return;
  }
  updateError(VALIDATIONS.MAX_VIDEO_CHANNELS, false);
}

export function validateMaxVideoBitrate(info: Mp4BoxInfo, updateError: UpdateError, dispatch: React.Dispatch<Action>, mixPanelData = {}) {
  if (info.videoTracks && info.videoTracks.length) {
    const [videoTrack] = info.videoTracks;
    const { bitrate, timescale, samples_duration: samplesDuration, nb_samples: nbSamples } = videoTrack;

    const bitrateMbps = bitrate / KB / KB;
    const fps = timescale / (samplesDuration / nbSamples);

    const bitrateLimits = {
      lowFramerate: 8,
      highFramerate: 12,
    } as const;

    console.log(
      `Max Video Bitrate: ${bitrateLimits.highFramerate}Mbps (> 30fps only) / ${bitrateLimits.lowFramerate}Mbps  -- Actual Video Bitrate: ${bitrateMbps}Mbps (${fps}fps)`
    );
    Object.assign(mixPanelData, {
      [MPEventProperty.VIDEO_BITRATE_MBPS]: bitrateMbps,
    });

    if ((fps <= 30 && bitrateMbps > bitrateLimits.lowFramerate) || bitrateMbps > bitrateLimits.highFramerate) {
      updateError(VALIDATIONS.MAX_VIDEO_BITRATE);
      return;
    }
    dispatch({
      type: ACTION_TYPE.VIDEO_BITRATE,
      payload: bitrate / 1000
    });
  }
  updateError(VALIDATIONS.MAX_VIDEO_BITRATE, false);
}

export function validateMaxResolution(info: Mp4BoxInfo, updateError: UpdateError, dispatch: React.Dispatch<Action>, mixPanelData = {}) {
  if (info.videoTracks && info.videoTracks.length) {
    const [videoTrack] = info.videoTracks;

    console.log(
      `Max resolution is 3840 x 2160 -- Actual Resolution: ${videoTrack.track_width} x ${videoTrack.track_height}`
    );
    Object.assign(mixPanelData, {
      [MPEventProperty.RESOLUTION_WIDTH]: videoTrack.track_width,
      [MPEventProperty.RESOLUTION_HEIGHT]: videoTrack.track_height,
    });

    if (videoTrack.track_height > 2160) {
      updateError(VALIDATIONS.MAX_RESOLUTION);
      return;
    }

    dispatch({
      type: ACTION_TYPE.RESOLUTION_HEIGHT,
      payload: videoTrack.track_height
    });
  }

  updateError(VALIDATIONS.MAX_RESOLUTION, false);
}

// Our sample duration is found by taking sample_durations divided by nb_samples.
export function logSampleDuration(info: Mp4BoxInfo, mixPanelData = {}) {
  if (info.videoTracks && info.videoTracks.length) {
    const [videoTrack] = info.videoTracks;
    const { samples_duration: totalSampleDuration, nb_samples: sampleCount } = videoTrack;

    const sampleDuration = totalSampleDuration / sampleCount;
    console.log(`Acceptable Sample Durations: 1000, 1001 -- Actual Sample Duration: ${sampleDuration}`);
    Object.assign(mixPanelData, {
      [MPEventProperty.SAMPLE_DURATION]: sampleDuration,
    });
  }
}

export function logTimescale(info: Mp4BoxInfo, mixPanelData = {}) {
  if (info.videoTracks && info.videoTracks.length) {
    const [videoTrack] = info.videoTracks;
    const { timescale } = videoTrack;

    console.log(`Acceptable Timescales: 30000, 60000 -- Actual Timescale: ${timescale}`);
    Object.assign(mixPanelData, {
      [MPEventProperty.TIMESCALE]: timescale,
    });
  }
}

export function validateFps(info: Mp4BoxInfo, updateError: UpdateError, dispatch: React.Dispatch<Action>, mixPanelData = {}) {
  const supportedFramerates = [24, 25, 30, 50, 60];

  const [videoTrack] = info.videoTracks;
  const { timescale, samples_duration: totalSamplesDuration, nb_samples: nbSamples } = videoTrack;
  const sampleDuration = totalSamplesDuration / nbSamples;

  const fps = timescale / sampleDuration;

  if (fps - Math.floor(fps) === 0) {
    // Integer framerate must be exactly equal to a supported framerate

    console.log(`Supported Integer FPS: 24, 25, 30, 50, 60 -- Actual FPS: ${fps}`);
    Object.assign(mixPanelData, {
      [MPEventProperty.FRAMERATE]: fps,
    });

    if (supportedFramerates.includes(fps)) {
      updateError(VALIDATIONS.INVALID_FPS, false);
      dispatch({
        type: ACTION_TYPE.FRAMERATE,
        payload: `${fps * 1000}/1000`
      });
      return;
    }
  } else {
    // Non-integer framerate requirements:
    // 1. sampleDuration is an integer multiple, N, of 1001
    // 2. timescale is an integer multiple, M, of a supported framerate * 1000
    // 3. N == M
    const sampleDurationMultiple = sampleDuration / 1001;
    console.log(
      `Supported Non-Integer FPS: 24000/1001, 30000/1001, 60000/1001 -- Actual FPS: ${
        timescale / sampleDurationMultiple
      }/1001`
    );

    if (sampleDuration % 1001 === 0 && supportedFramerates.includes(timescale / sampleDurationMultiple / 1000)) {
      updateError(VALIDATIONS.INVALID_FPS, false);
      dispatch({
        type: ACTION_TYPE.FRAMERATE,
        payload: `${timescale/sampleDurationMultiple}/${sampleDuration}`
      });
      return;
    }
  }

  updateError(VALIDATIONS.INVALID_FPS);
}

export function validateDimensions(info: Mp4BoxInfo, updateError: UpdateError) {
  if (info.videoTracks && info.videoTracks.length > 0) {
    const { height, width } = info.videoTracks[0].video;
    console.log('Expected Aspect Ratio: 1.77 repeating');
    console.log(`Aspect Ratio: ${width / height} width: ${width} height: ${height}`);

    const requiredAspectRatio = 16 / 9;
    if (width / height !== requiredAspectRatio) {
      updateError(VALIDATIONS.INVALID_DIMENSIONS);
      return;
    }
    updateError(VALIDATIONS.INVALID_DIMENSIONS, false);
  }
}

export function validateRequiredField(
  value: string | undefined | null,
  errorKey: VALIDATIONS,
  updateError: UpdateError
): boolean {
  if (!value?.toLowerCase().trim()) {
    updateError(errorKey);
    return false;
  }
  updateError(errorKey, false);
  return true;
}

export function validateFileType(value: string, updateError: UpdateError) {
  const splitValues: string[] = value.split('.');

  if (splitValues.length < 2) {
    updateError(VALIDATIONS.INVALID_FILE_TYPE);
    return;
  }

  const ext = splitValues[splitValues.length - 1];

  if (ext.toLowerCase() !== 'mp4') {
    updateError(VALIDATIONS.INVALID_FILE_TYPE);
    return;
  }

  updateError(VALIDATIONS.INVALID_FILE_TYPE, false);
}


const SAMPLE_RATE_TABLE = [96000, 88200, 64000, 48000, 44100, 32000, 24000, 22050, 16000, 12000, 8000, 7350] as const;

export function validateSampleRate(file: ISOFile, info: Mp4BoxInfo, updateError: UpdateError, dispatch: React.Dispatch<Action>, allowNon48KhzAudio: boolean, mixPanelData = {}) {
  if (!hasAudio(info)) {
    return;
  }

  const [audioTrack] = info.audioTracks;
  let sampleRate = audioTrack.audio.sample_rate;
  if (allowNon48KhzAudio && sampleRate === 0) {
    // The top level audio track sample rate is a 16-bit integer
    // and can not contain high sample rates like 96000 Hz.
    // Larger sample rates are stored in the DecoderSpecificInfo (defined in the AAC spec).

    const mp4aBox = file.moov.traks[audioTrack.id - 1].mdia.minf.stbl.stsd.entries.find(
      (box) => box.type === 'mp4a'
    ) as Mp4aBox;

    if (mp4aBox === undefined) {
      throw new Error('Failed to find mp4a box');
    }

    const decoderConfig = mp4aBox.esds.esd.descs.find(
      (descriptor) => descriptor.tag === DescriptorTag.DecoderConfigDescriptorTag
    );
    if (decoderConfig === undefined) {
      throw new Error('failed to find DecoderConfigDescriptor');
    }

    const decoderSpecificInfo = decoderConfig.descs.find(
      (descriptor) => descriptor.tag === DescriptorTag.DecoderSpecificInfoTag
    );

    if (decoderSpecificInfo === undefined) {
      throw new Error('Failed to find DecoderSpecificInfo');
    }

    if (decoderSpecificInfo.data === undefined) {
      throw new Error("expected DecoderSpecificInfo to have a 'data' property; got undefined");
    }

    const bitReader = new BitReader(decoderSpecificInfo.data);

    const objectType = bitReader.read(5);
    // Read an extended object type if we get the escape value
    if (objectType == 0x1f) {
      bitReader.read(6);
    }

    // The DecoderSpecificInfo's sample rate is an index into a list of
    // pre-defined sample rates.
    const sampleRateIndex = bitReader.read(4);
    if (sampleRateIndex !== 0xf) {
      sampleRate = SAMPLE_RATE_TABLE[sampleRateIndex];
    } else {
      // Read an extended sample rate if we get the escape value
      sampleRate = bitReader.read(24);
    }
  }

  console.log(`Supported Audio Sample Rate 48000 Hz -- Actual Audio Sample Rate: ${sampleRate} Hz`);
  Object.assign(mixPanelData, {
    [MPEventProperty.AUDIO_SAMPLE_RATE_KHZ]: sampleRate,
  });

  if (!allowNon48KhzAudio && sampleRate !== AUDIO_SAMPLERATE) {
    updateError(VALIDATIONS.INVALID_AUDIO_SAMPLERATE);
    return;
  }
  updateError(VALIDATIONS.INVALID_AUDIO_SAMPLERATE, false);
  dispatch({
    type: ACTION_TYPE.AUDIO_SAMPLE_RATE,
    payload: sampleRate
  });
}

export function validateUploadFileHasAudio(info: Mp4BoxInfo, updateError: UpdateError) {
  if (!hasAudio(info)) {
    updateError(VALIDATIONS.NO_AUDIO);
    return;
  }

  updateError(VALIDATIONS.NO_AUDIO, false);
}

export function validateFramerateMode(
  mp4BoxFile: any,
  info: Mp4BoxInfo,
  updateError: UpdateError,
  allowVariableFrameRate: boolean
): boolean {
  let initialTimeScaleRatio: number | undefined;

  for (const track of info.videoTracks) {
    const samples: TrackSample[] = mp4BoxFile.getTrackSamplesInfo(track.id);

    if(!samples.length){
      updateError(VALIDATIONS.INVALID_FRAMERATE_MODE, false);
      return false;
    }
    
    if (initialTimeScaleRatio === undefined) {
      const { timescale, duration } = samples[0];
      initialTimeScaleRatio = timescale / duration;
    }
  
    const isConstantFramerate = samples.every((sample) => {
      const sampleRatio = sample.timescale / sample.duration;
      const isValid = sampleRatio === initialTimeScaleRatio;

      if (!isValid) {
        console.error(`Constant frame rate expected, but detected variable frame rate. Initial frame rate: ${initialTimeScaleRatio} and found second frame rate ${sampleRatio}`)
      }
      
      return isValid;
    });

    updateError(VALIDATIONS.INVALID_FRAMERATE_MODE, !isConstantFramerate && !allowVariableFrameRate);
    if (allowVariableFrameRate) {
      return !isConstantFramerate;
    }
  }
  return false;
}

export function calculateGop(
  mp4BoxFile: any,
  info: Mp4BoxInfo,
  updateError: UpdateError,
  dispatch: React.Dispatch<Action>,
  mixPanelData = {}
): void {
  // GOP is from true is_sync to next true is_sync
  // see samples tab: http://download.tsi.telecom-paristech.fr/gpac/mp4box.js/filereader.html

  let lastCts = 0;
  let largestTimeElapsed = 0;
  let largestMbps = 0;
  for (const track of info.videoTracks) {
    const samples: TrackSample[] = mp4BoxFile.getTrackSamplesInfo(track.id);
    let totalBytes = 0;
    for (const sample of samples) {
      const { cts, timescale, is_sync: isSync, size } = sample;

      if (!areDefined(cts, timescale, isSync)) {
        console.error(`CTS: ${cts}, TimeScale: ${timescale}, or isSync: ${isSync} is undefined`);
        updateError(VALIDATIONS.MAX_GOP);
        return;
      }

      /*
        GOP is from true isSync to next true isSync.
        We calculate time from the current isSync minus the time of the last
        to get the time of each interval.
        We also add totalBytes between isSyncs then use them to calculate mbps
        then set totalBytes to 0 for the next series of samples until the next true isSync
      */
      if (isSync) {
        const timeElapsed = (cts - lastCts) / timescale;

        if (timeElapsed > largestTimeElapsed) {
          largestTimeElapsed = timeElapsed;
        }

        const mbps = totalBytes / timeElapsed / MB;
        
        if (mbps > largestMbps) {
          largestMbps = mbps;
        }

        lastCts = cts;
        totalBytes = 0;
      }

      totalBytes += size;
    }
  }
  updateError(VALIDATIONS.MAX_GOP, false);
  dispatch({ type: ACTION_TYPE.GOP_SECONDS, payload: largestTimeElapsed });
  console.log(`Largest GOP duration is ${largestTimeElapsed} seconds`);
  console.log(`Keyframe mbps max is 24mbps --- Actual: ${largestMbps.toFixed(2)} mbps`);

  Object.assign(mixPanelData, {
    [MPEventProperty.GOP_DURATION]: largestTimeElapsed,
    [MPEventProperty.KEYFRAME_MBPS]: parseFloat(largestMbps.toFixed(2)),
  });
}

export function validateSubmit(state: EventUploadState, updateError: UpdateError) {
  // Encoder Event Profile
  const invalidEventProfile = validateRequiredField(state.eventProfile, VALIDATIONS.BLANK_EVENT_PROFILE, updateError);

  // Event Name
  const invalidEventName = validateRequiredField(state.eventName, VALIDATIONS.BLANK_EVENT_NAME, updateError);

  // Event Upload
  let invalidEventUpload = false;
  if (!state?.file?.toString().trim()) {
    invalidEventUpload = true;
    updateError(VALIDATIONS.NO_UPLOAD_FILE);
  }
  return invalidEventProfile || invalidEventName || invalidEventUpload;
}
