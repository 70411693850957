import CueListComponent from './cueList.component';

export const cueListModule = {
	name: 'cueListComponent',
	component: {
		name: 'cueList'
	}
};

export default angular
	.module(cueListModule.name, [])
	.component(cueListModule.component.name, new CueListComponent()).name;
