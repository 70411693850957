import { useContext } from 'react';
import { ACTION_TYPE, EventUploadContext } from '../components/EventUploadModal/context';
import { VALIDATIONS, ValidationsObject } from '../components/EventUploadModal/eventUploadTypes';

function useUpdateUploadError() {
  const { state, dispatch } = useContext(EventUploadContext);

  return {
    updateError(type: VALIDATIONS, invalid = true) {
      const errorsClone: ValidationsObject = { ...state.errors };
      errorsClone[type].invalid = invalid;
      dispatch({
        type: ACTION_TYPE.ERROR_LIST,
        payload: { ...errorsClone },
      });
    },
  };
}

export default useUpdateUploadError;
