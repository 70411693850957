import { perms } from '../../jcs';
import { cuesModule } from './index';
import { eventsModule } from '../events';

function routeConfig($provide, $routeProvider) {
	'ngInject';

	$provide.factory('$routeProvider', () => {
		return $routeProvider;
	});

	$routeProvider.when(cuesModule.routes.list, {
		controller: cuesModule.controllers.default,
		template: require('./cues.tmpl.html').default,
		activenav: eventsModule.name, // <= current when cues are viewed, streams will be the active nav
		access: {
			loginRequired: true,
			permissions: [perms['cues.get']],
		},
	});
}

export default routeConfig;
