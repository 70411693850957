import React, { FC } from 'react';
import { ResiLogoSvg } from '../../svgs';
import { LogoContainer } from './style';

const LogoHeader: FC = () => (
  <LogoContainer>
    <ResiLogoSvg />
  </LogoContainer>
);

LogoHeader.displayName = 'LogoHeader';

export default LogoHeader;
