import 'angular-route';
import angular from 'angular';
import router from './module.onboarding.routes';
import commonServicesModule from '../../services';

export const onboardingModule = {
	name: 'onboarding',
	controllers: {
		default: 'onboardingCtrl',
	},
	routes: {
		list: '/onboarding',
	},
};

export default angular
	.module(onboardingModule.name, ['ngRoute', commonServicesModule])
	.config(router)
	.controller(onboardingModule.controllers.default, require('./onboardingCtrl')).name;
