import axios, { AxiosResponse } from 'axios';
import { duration } from 'moment';
import { api } from '../../../v2.snapshot/js/jcs';
import { EventType } from '../../constants';
import { Cue, CueOption } from '../../helpers/events/types';

export interface EventSummary {
  live: boolean;
  transcodeRequired: boolean;
  downloadDisabled: boolean;
  videoRepresentations: Representation[];
  audioRepresentations: Representation[];
}

export interface Representation {
  id: number;
  name: string;
}

export interface TimeRange {
  start: number;
  end: number;
}

export const formatFramerate = (framerate: string): string => {
  if (!framerate) {
    return '';
  }

  const framerateValues = framerate.split('/');
  if (framerateValues.length !== 2) {
    return '';
  } else {
    if (framerateValues[0] && framerateValues[1]) {
      const frames = parseInt(framerateValues[0]);
      const frequency = parseInt(framerateValues[1]);
      const framesPerSecond = frames / frequency;
      const decimal = frames % frequency !== 0;
      return `${decimal ? framesPerSecond.toFixed(2) : framesPerSecond}fps`;
    }
  }
  return '';
};

export const formatBitsPerSecond = (bytes: number, decimals?: number): string => {
  if (bytes === 0) {
    return '0 bps';
  }

  const unitMultiple = 1000;
  const unitNames = ['bps', 'Kbps', 'Mbps', 'Gbps', 'Tbps', 'Pbps'];
  const unitChanges = Math.floor(Math.log(bytes) / Math.log(unitMultiple));
  // force decimal precision to 0 for Kbps and lower
  const precision = unitChanges <= 1 ? 0 : decimals || 0;
  return `${parseFloat((bytes / Math.pow(unitMultiple, unitChanges)).toFixed(precision))} ${unitNames[unitChanges]}`;
};

export const extractVideoTracks = (videoAdaptationSets: any): Representation[] => {
  const includeTrackLabel = videoAdaptationSets.length > 1;
  const adaptationSets = videoAdaptationSets.map((adaptation: any, index: number) => {
    return adaptation.representations.map((representation: any) => {
      return {
        id: representation.id,
        name: includeTrackLabel
          ? `Track ${index + 1}: ${representation.width}x${representation.height} ${formatFramerate(
              representation.frameRate
            )} / ${representation.bandwidth}`
          : `${representation.width}x${representation.height}  ${formatFramerate(
              representation.frameRate
            )} /  ${formatBitsPerSecond(representation.bandwidth, 1)}`,
      } as Representation;
    });
  });
  return [].concat(...adaptationSets);
};

export const extractAudioTracks = (audioAdaptationSets: any): Representation[] => {
  const includeTrackLabel = audioAdaptationSets.length > 1;
  const adaptationSets = audioAdaptationSets.map((adaptation: any, index: number) => {
    return adaptation.representations.map((representation: any) => {
      return {
        id: representation.id,
        name: includeTrackLabel
          ? `Track ${index + 1}: ${representation.channels} Ch / ${representation.bandwidth}`
          : `${representation.channels} Ch / ${formatBitsPerSecond(representation.bandwidth, 0)}`,
      } as Representation;
    });
  });
  return [].concat(...adaptationSets);
};

export const fetchEventSummary = async (
  customerId: string,
  eventId: string,
  eventType: EventType
): Promise<EventSummary> => {
  const url = `${api.url_v3}/customers/${customerId}/${eventType}s/${eventId}/summary`;
  const response = await axios.get(url, { withCredentials: true });
  return {
    live: response.data.live,
    transcodeRequired: response.data.transcodeRequired,
    downloadDisabled: response.data.downloadDisabled,
    videoRepresentations: extractVideoTracks(response.data.videoAdaptationSets),
    audioRepresentations: extractAudioTracks(response.data.audioAdaptationSets),
  } as EventSummary;
};

export const fetchCues = async (customerId: string, eventId: string, eventType: EventType): Promise<CueOption[]> => {
  const url = `${api.url_v3}/customers/${customerId}/${eventType}s/${eventId}/cues`;
  try {
    const response = await axios.get(url, { withCredentials: true });
    return response.data
      .map(
        (cue: Cue): CueOption => {
          return {
            uuid: cue.uuid,
            position: duration(cue.position).asMilliseconds(),
            name: `Cue: ${cue.position} - ${cue.name}`,
          };
        }
      )
      .sort((a: CueOption, b: CueOption) => {
        return a.position < b.position ? -1 : a.position > b.position ? 1 : 0;
      });
  } catch (error) {
    console.error(error);
    return [];
  }
};

// NOTE: After a v3 api is written for encoder event cues, we should use the fetchCues function
// with the eventType differentiating the url. After that is in place, NUKE this function
export const fetchEncoderEventCues = async (streamProfileId: string, eventId: string): Promise<CueOption[]> => {
  const url = `${api.url}/streamprofiles/${streamProfileId}/events/${eventId}/cues`;
  try {
    const response = await axios.get(url, { withCredentials: true });
    return response.data
      .map(
        (cue: Cue): CueOption => {
          return {
            uuid: cue.uuid,
            position: duration(cue.position).asMilliseconds(),
            name: `Cue: ${cue.position} - ${cue.name}`,
          };
        }
      )
      .sort((a: CueOption, b: CueOption) => {
        return a.position < b.position ? -1 : a.position > b.position ? 1 : 0;
      });
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const fetchDownloadUrl = async (
  customerId: string,
  eventId: string,
  eventType: EventType,
  downloadParams: any
): Promise<AxiosResponse<any>> => {
  const url = `${api.url_v3}/customers/${customerId}/${eventType}s/${eventId}/download`;
  return await axios.post(
    url,
    {
      audioRepresentationId: downloadParams.audioTrack,
      videoRepresentationId: downloadParams.videoTrack,
      startTimeMs: downloadParams.startPosition,
      endTimeMs: downloadParams.stopPosition,
    },
    { withCredentials: true }
  );
};
