import styled from '@emotion/styled';
import { customProperties as vars } from '@resi-media/resi-ui';

export const SegmentedStatsWrapper = styled.div`
  height: 530px;
  padding: ${vars.spacingXl} 4em 4em 4em;
`;

export const SegmentedStatsModalContent = styled.div`
  width: 900px;
  min-height: 390px;
  padding: ${vars.spacingXl} 4em 4em 4em;
`;

export const LegendsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: ${vars.spacingXl};
  margin-right: ${vars.spacingXl};
`;

export const HeaderDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: ${vars.spacingXl} 0 4em 0;
  font-family: ${vars.fontFamily};
`;

export const ChartContent = styled.div`
  display: flex;
  height: 320px;
  min-width: 500px;
  max-width: 600px;
`;

export const TableContent = styled.div`
  width: 100%;
  max-width: 800px;
`;

export const ChartHeaderDiv = styled.div`
  display: grid;
  gap: ${vars.spacingXl};
  grid-template-columns: auto 1fr;
  font-family: ${vars.fontFamily};
`;
