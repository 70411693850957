'use strict';

const jcs = require('../../jcs');
var libphonenumber = require('libphonenumber-js');

function ContactsSearchController(
	$scope,
	$http,
	$q,
	$routeParams,
	$location,
	$timeout,
	httpService,
	paginationService,
	focus
) {
	'ngInject';
	$(window).trigger('resize'); // ensure footer is properly positioned

	$scope.is_loading = false;
	$scope.is_busy = false;
	$scope.error_msg = null;

	$scope.search_terms = {};
	$scope.search_results = null;

	$scope.contact_to_view = null;

	$scope.has_error = null;

	$scope.getAlertAsText = function (alert) {
		return alert ? 'Yes' : 'No';
	};

	$scope.COUNTRY_CODE_USA = '+1';
	$scope.getPhoneFormattedForUI = function (phone_number) {
		if (typeof libphonenumber != 'undefined' && phone_number != null) {
			if (typeof libphonenumber != 'undefined' && phone_number != null) {
				const phoneNumber = libphonenumber.parsePhoneNumberFromString(phone_number, 'US');
				if (phoneNumber) {
					return phoneNumber.country == 'US' ? phoneNumber.formatNational() : phoneNumber.formatInternational();
				}
			}
			return phone_number;
		}
		return phone_number;
	};

	$scope.enterDetailMode = function (contact) {
		$scope.contact_to_view = contact;
	};

	$scope.cancel = function () {
		$scope.contact_to_view = null;

		focus('search-term-phone');
	};

	$scope.doesFailValidation = function () {
		// ensure that at least one search criteria has been entered
		if (
			$scope.isEmpty($scope.search_terms.first_name) &&
			$scope.isEmpty($scope.search_terms.last_name) &&
			$scope.isEmpty($scope.search_terms.email) &&
			$scope.isEmpty($scope.search_terms.phone)
		) {
			$scope.error_msg = 'Please enter at least one search criteria.';
			return true;
		}

		// looks good
		return false;
	};

	$scope.isEmpty = function (value) {
		return value == null || value === '';
	};

	// builds query string for search and page changes based on current search criteria
	$scope.buildQueryString = function () {
		var search_queries = [];

		if (!$scope.isEmpty($scope.search_terms.first_name)) {
			search_queries.push('first=' + $scope.search_terms.first_name);
		}
		if (!$scope.isEmpty($scope.search_terms.last_name)) {
			search_queries.push('last=' + $scope.search_terms.last_name);
		}
		if (!$scope.isEmpty($scope.search_terms.phone)) {
			search_queries.push('mobile=' + $scope.search_terms.phone);
		}
		if (!$scope.isEmpty($scope.search_terms.email)) {
			search_queries.push('email=' + $scope.search_terms.email);
		}

		return search_queries.join('&');
	};

	$scope.performSearch = function () {
		if ($scope.doesFailValidation()) {
			return;
		}

		$scope.error_msg = null;
		$scope.is_busy = true;

		// perform decoder search
		$http
			.get(jcs.api.internal_url + '/contactsearch?' + $scope.buildQueryString(), {
				withCredentials: true,
			})
			.then(
				function (response) {
					// success

					$scope.search_results = response.data;
				},
				function () {
					// error

					$scope.error_msg =
						'There was a problem trying to search for decoders. Please try again, or report the problem if it persists.';
					$scope.search_results = null;
				}
			)
		['finally'](function () {
			// always called

			$scope.is_busy = false;
		});
	};

	// see app.js for where focus is defined
	focus('search-term-phone');

	// define and build our tooltips
	$scope.is_billing_contact_tooltip = 'This person may be contacted for any billing related issues or questions.';
	$scope.streaming_alert_tooltip =
		'This person will receive streaming related alerts, such as if an encoder is having bandwidth issues, or if problems are detected that may affect playback.';
	$timeout(function () {
		$('[data-toggle="tooltip"]').tooltip(); // needs to be done in timeout, otherwise for some reason the tooltip gets built before angular does it's magic
	});
}

module.exports = ContactsSearchController;
