interface RouteParams extends ng.route.IRouteParamsService {
  id: string;
}

class AcceptInviteController {
  public inviteId: string;

  constructor(private $routeParams: RouteParams) {
    'ngInject';
    this.inviteId = this.$routeParams.id;
  }
}
module.exports = AcceptInviteController;
