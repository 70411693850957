export enum COOKIES {
  AUTH_COOKIE = 'livingas1',
}

export enum EventType {
  EVENT = 'event',
  WEB_EVENT = 'webevent',
}

export enum KEYS {
  ESCAPE_KEY = 27,
}

export interface User {
  userId: string;
  userName: string;
  userType: string;
  customerId: string;
  customerName?: string;
  features: string[];
  toggles: string[];
  capabilities: string[];
}

export interface DataCenter {
  readonly id: string;
  readonly url: string;
  readonly displayName: string;
}

export interface LatencyDetails {
  dataCenter: DataCenter;
  duration?: number;
  isPassing: boolean;
}

export interface WebEventStatus {
  uuid: string;
  name: string;
  description: string;
  startTime: Date;
  status: string;
  thumbnials?: Thumbnail[];
}

export interface Representation {
  quality: string;
  width: number;
  height: number;
  publicUrl: string;
}

export interface Thumbnail {
  name: string;
  language: string;
  author: string;
  encodingFormat: string;
  bucket: string;
  representations: Representation[];
}

export const DATA_CENTERS: readonly DataCenter[] = [
  {
    id: '1290ae83-b319-4cf4-9069-41a7296c8c2c',
    url: 'https://stream-export.livingasone.com/api/v1/ping',
    displayName: 'North America',
  },
  {
    id: '4a42f2d6-56d2-4ff0-9ee8-3b23ac3ac454',
    url: 'https://stream-export-sea.livingasone.com/api/v1/ping',
    displayName: 'Asia',
  },
  {
    id: '66418af3-985b-4abb-9ec0-6868f7c523ee',
    url: 'https://stream-export-au.livingasone.com/api/v1/ping',
    displayName: 'Australia',
  },
  {
    id: '7d856f44-04f0-4b85-8dd9-27cdb92d7668',
    url: 'https://stream-export-eu.livingasone.com/api/v1/ping',
    displayName: 'Europe',
  },
  {
    id: 'd31e68ae-22f7-40fb-b2f6-0706730cc957',
    url: 'https://stream-export-sa.livingasone.com/api/v1/ping',
    displayName: 'South America',
  },
];
