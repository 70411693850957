import 'angular-route';
import angular from 'angular';
import router from './module.destinationgroups.routes';
import commonServicesModule from '../../services';

export const destinationGroupModule = {
  name: 'destinationgroups',
  controllers: {
    default: 'destinationGroupsCtrl',
  },
  routes: {
    list: '/destinationgroups',
  },
};

export default angular
  .module(destinationGroupModule.name, ['ngRoute', commonServicesModule])
  .config(router)
  .controller(destinationGroupModule.controllers.default, require('./destinationGroupsCtrl')).name;
