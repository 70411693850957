'use strict';

const jcs = require('../../jcs');
const moment = require('moment');

function OnboardingController($scope, $http, $q, httpService, focus, hardwareService, orgService) {
	'ngInject';
	$(window).trigger('resize'); // ensure footer is properly positioned

	$scope.DATE_FORMAT = 'MM/DD/YY';
	$scope.DATE_FORMAT_ZULU = 'YYYY-MM-DDTHH:MM:SS';

	$scope.MODEL_TYPE_ENCODER = 'encoder';
	$scope.MAX_LENGTH_SERIAL_NUM = 9;

	$scope.error_msg = null;

	$scope.is_loading = false;
	$scope.is_busy = false;

	$scope.encoders = null;
	$scope.load_encoders_error_msg = null;

	$scope.options_loaded = false;
	$scope.model_options = null;
	$scope.status_options = null;
	$scope.location_options = null;
	$scope.owner_options = null;

	// allows easy access to info values; use uuid as key to value. ex: name = hardware_map[uuid];
	$scope.hardware_location_map = {};
	$scope.hardware_model_map = {};
	$scope.hardware_status_map = {};
	$scope.hardware_owner_map = {};

	$scope.edit_fields = null;
	$scope.has_error = null;

	// onboard encoder
	$scope.encoder_to_onboard = null;

	// encoder to view (after onboarding)
	$scope.encoder_to_view = null;
	$scope.encoder_to_view_error = false;

	// reimage encoder
	$scope.encoder_to_reimage = null;
	$scope.has_verified_serial = false; // false = show verify serial form; true = show confirm reimage form

	// placeholders for customerOwnerId, modelId, and notes values from hardwareUnit API response
	$scope.customerOwnerId = null;
	$scope.modelId = null;
	$scope.notes = null;

	$scope.loadHardwareOptions = function (successCallback, failCallback) {
		// don't load options if we already have them
		if ($scope.options_loaded) {
			if (successCallback) {
				successCallback();
			}
			return;
		}

		$scope.is_loading = true;

		var promises = [];
		promises.push(hardwareService.getStatusList());
		promises.push(hardwareService.getLocationList());
		promises.push(orgService.getOrganizationNameList());
		promises.push(hardwareService.getModelList());

		$q.all(promises)
			.then(
				function ([status_response, location_response, org_name_response, model_response]) {
					$scope.options_loaded = true;

					$scope.status_options = status_response;
					// build map (which allows us to easily look up names)
					for (var i = 0; i < $scope.status_options.length; i++) {
						var entry = $scope.status_options[i];
						$scope.hardware_status_map[entry.uuid] = entry.name;
					}

					$scope.location_options = location_response;
					// build map (which allows us to easily look up names)
					for (var i = 0; i < $scope.location_options.length; i++) {
						var entry = $scope.location_options[i];
						$scope.hardware_location_map[entry.uuid] = entry.name;
					}

					$scope.owner_options = org_name_response;
					// build map (which allows us to easily look up names)
					for (var i = 0; i < $scope.owner_options.length; i++) {
						var entry = $scope.owner_options[i];
						$scope.hardware_owner_map[entry.uuid] = entry.name;
					}

					$scope.model_options = [];
					// iterate list and get encoder model numbers
					for (var i = 0; i < model_response.length; i++) {
						var option = model_response[i];
						$scope.hardware_model_map[option.uuid] = option.name;
						if (option.type == $scope.MODEL_TYPE_ENCODER) {
							$scope.model_options.push(option);
						}
					}

					if (successCallback) {
						successCallback();
					}
				},
				function (reason) {
					if (!httpService.isStatus406(reason)) {
						$scope.error_msg =
							'An error occurred while loading the encoder options. Please try again, or report the problem if it persists.';
					}
					if (failCallback) {
						failCallback();
					}
				}
			)
			.finally(function () {
				$scope.is_loading = false;
			});
	};

	$scope.enterOnboardEncoderMode = function (encoder) {
		var build_date_as_moment = moment();

		$scope.error_msg = null;
		$scope.has_error = {};

		$scope.encoder_to_onboard = encoder;

		$scope.edit_fields = {
			serial: '',
			modelId: null,
			customerOwnerId: '6dcebe46-41c1-49f9-a318-8081d14394e3', // Living As One
			locationId: '41c3c0d1-a231-11e8-b8f3-42010a800133', // JK Solution
			statusId: 'cfd87325-a231-11e8-b8f3-42010a800133', // Stock - New
			buildDate: build_date_as_moment.format($scope.DATE_FORMAT),
		};
		// initialize the date picker itself to today's date, even if input field is empty
		$scope.build_date_picker_set_date(build_date_as_moment);

		$scope.loadHardwareOptions(
			function () {
				// success
				// see app.js for where focus is defined
				focus('onboard-encoder-input');
			},
			function () {
				// error
				$scope.encoder_to_onboard = null;
			}
		);
	};

	$scope.enterReimageEncoderMode = function (encoder) {
		var build_date_as_moment = moment();

		$scope.has_verified_serial = false;
		$scope.error_msg = null;
		$scope.has_error = {};

		$scope.encoder_to_reimage = encoder;

		$scope.edit_fields = {
			serial: '',
		};

		$scope.loadHardwareOptions(
			function () {
				// success
				// see app.js for where focus is defined
				focus('reimage-encoder-input');
			},
			function () {
				// error
				$scope.encoder_to_reimage = null;
			}
		);
	};

	$scope.cancelViewEncoder = function () {
		$scope.encoder_to_onboard = null;
		$scope.encoder_to_view = null;
		$scope.encoder_to_view_error = false;
		$scope.loadEncoders();
	};

	$scope.cancelEdit = function () {
		$scope.encoder_to_onboard = null;
		$scope.encoder_to_reimage = null;
		$scope.has_verified_serial = false;
		$scope.error_msg = null;
	};

	$scope.get1stCaptureCardName = function (encoder) {
		if (encoder.captureCards && encoder.captureCards.length >= 1) {
			return encoder.captureCards[0].name;
		}
		return 'Not Available';
	};

	$scope.get2ndCaptureCardName = function (encoder) {
		if (encoder.captureCards && encoder.captureCards.length >= 2) {
			return encoder.captureCards[1].name;
		}
		return '';
	};

	$scope.isEmpty = function (value) {
		return value == null || value === '';
	};

	$scope.doesReimagingFormFailValidation = function (encoder) {
		// start with clean slate
		$scope.has_error = {};
		$scope.error_msg = null;

		// check required fields to ensure they aren't empty
		$scope.has_error.serial = $scope.isEmpty($scope.edit_fields.serial);

		var error_count = 0;
		for (var property in $scope.has_error) {
			if ($scope.has_error[property] === true) {
				error_count++;
			}
		}
		var has_validation_error = error_count > 0;

		if (has_validation_error) {
			$scope.error_msg = 'Please specify a value for the highlighted fields below.';
			return has_validation_error;
		}

		// make specialized checks (field length, etc)
		if ($scope.edit_fields.serial.length != $scope.MAX_LENGTH_SERIAL_NUM) {
			$scope.has_error.serial = true;
			$scope.error_msg = 'Serial number must be ' + $scope.MAX_LENGTH_SERIAL_NUM + ' characters long.';
			return true;
		}
		if ($scope.edit_fields.serial.toUpperCase().charAt(0) != 'E') {
			$scope.has_error.serial = true;
			$scope.error_msg = "Serial number must be begin with an 'E'.";
			return true;
		}

		return false;
	};

	$scope.doesOnboardingFormFailValidation = function (encoder) {
		// start with clean slate
		$scope.has_error = {};
		$scope.error_msg = null;

		// check required fields to ensure they aren't empty
		$scope.has_error.serial = $scope.isEmpty($scope.edit_fields.serial);
		$scope.has_error.model = $scope.isEmpty($scope.edit_fields.modelId);
		$scope.has_error.buildDate = $scope.isEmpty($scope.edit_fields.buildDate);
		$scope.has_error.status = $scope.isEmpty($scope.edit_fields.statusId);
		$scope.has_error.owner = $scope.isEmpty($scope.edit_fields.customerOwnerId);
		$scope.has_error.location = $scope.isEmpty($scope.edit_fields.locationId);

		var error_count = 0;
		for (var property in $scope.has_error) {
			if ($scope.has_error[property] === true) {
				error_count++;
			}
		}
		var has_validation_error = error_count > 0;

		if (has_validation_error) {
			$scope.error_msg = 'Please specify a value for the highlighted fields below.';
			return has_validation_error;
		}

		// make specialized checks (field length, etc)
		if ($scope.edit_fields.serial.length != $scope.MAX_LENGTH_SERIAL_NUM) {
			$scope.has_error.serial = true;
			$scope.error_msg = 'Serial number must be ' + $scope.MAX_LENGTH_SERIAL_NUM + ' characters long.';
			return true;
		}
		if ($scope.edit_fields.serial.toUpperCase().charAt(0) != 'E') {
			$scope.has_error.serial = true;
			$scope.error_msg = "Serial number must be begin with an 'E'.";
			return true;
		}

		return false;
	};

	$scope.getLocationLabel = function (uuid) {
		if ($scope.hardware_location_map.hasOwnProperty(uuid)) {
			return $scope.hardware_location_map[uuid];
		}
		return '';
	};

	$scope.getModelLabel = function (uuid) {
		if ($scope.hardware_model_map.hasOwnProperty(uuid)) {
			return $scope.hardware_model_map[uuid];
		}
		return '';
	};

	$scope.getStatusLabel = function (uuid) {
		if ($scope.hardware_status_map.hasOwnProperty(uuid)) {
			return $scope.hardware_status_map[uuid];
		}
		return '';
	};

	$scope.getOwnerName = function (uuid) {
		if ($scope.hardware_owner_map.hasOwnProperty(uuid)) {
			return $scope.hardware_owner_map[uuid];
		}
		return '';
	};

	$scope.formatDate = function (date_to_format) {
		if (date_to_format == null || date_to_format == '') return '';

		return moment(date_to_format).format($scope.DATE_FORMAT);
	};

	$scope.formatDateAsZulu = function (date_to_format) {
		if (date_to_format == null || date_to_format == '') return '';

		// couldn't just add 'Z' to our moment format string, b/c moment.js was converting it to a time offset (+1:00)
		return (
			moment(date_to_format, $scope.DATE_FORMAT)
				.utc()
				.format($scope.DATE_FORMAT_ZULU) + 'Z'
		);
	};

	$scope.getOnboardedEncoderDetails = function (hardware_unit_id) {

		$scope.is_busy = true;

		$http.get(`${jcs.api.internal_url}/hardwareunits/${hardware_unit_id}`, { withCredentials: true }).then(response => {

			$scope.encoder_to_onboard = null;
			$scope.encoder_to_view_error = false;
			$scope.encoder_to_view = response.data;

		}).catch(reason => {

			$scope.encoder_to_view_error = true;

		}).finally(() => {

			$scope.is_busy = false;

		});
	};

	$scope.performOnboarding = function () {
		// if we have form validation errors, then don't go any further
		if ($scope.doesOnboardingFormFailValidation()) return false;

		var encoder_data = {
			uuid: $scope.encoder_to_onboard.uuid,
			modelId: $scope.edit_fields.modelId,
			serial: $scope.edit_fields.serial.toUpperCase(),
			buildDate: $scope.formatDateAsZulu($scope.edit_fields.buildDate),
			statusId: $scope.edit_fields.statusId,
			customerOwnerId: $scope.edit_fields.customerOwnerId,
			locationId: $scope.edit_fields.locationId,
		};

		$scope.is_busy = true;

		// onboard encoder
		$http.post(`${jcs.api.url}/onboarding`, encoder_data, { withCredentials: true }).then(response => {

			$scope.has_error = {};
			$scope.error_msg = null;

			$scope.getOnboardedEncoderDetails(response.data.hardwareUnitId);

		}).catch(reason => {

			if (reason.status === 409) {
				$scope.error_msg = 'The serial number you specified already exists. Please ensure the provided serial number is correct. Encoders with existing serial numbers may only be reimaged.';
			} else {
				$scope.error_msg = 'An error occurred while attempting to onboard the encoder. Please try again, or report the problem if it persists.';
			}

			$scope.is_busy = false;

		});
	};

	$scope.verifyReimgaging = function () {
		// if we have form validation errors, then don't go any further
		if ($scope.doesReimagingFormFailValidation()) return false;

		$scope.is_busy = true;

		// perform decoder search
		$http
			.get(jcs.api.internal_url + '/hardwareunits?type=encoder&serial=' + $scope.edit_fields.serial.toUpperCase(), {
				withCredentials: true,
			})
			.then(
				function (response) {
					// success

					// lets just go ahead and use $scope.edit_fields instead of creating a new object
					$scope.edit_fields.serial = $scope.edit_fields.serial.toUpperCase();
					$scope.edit_fields.formattedModel = $scope.getModelLabel(response.data[0].modelId);
					$scope.edit_fields.formattedBuildDate = $scope.formatDate(response.data[0].buildDate);
					$scope.edit_fields.formattedStatus = $scope.getStatusLabel(response.data[0].statusId);
					$scope.edit_fields.formattedOwner = $scope.getOwnerName(response.data[0].customerOwnerId);
					$scope.edit_fields.formattedLocation = $scope.getLocationLabel(response.data[0].locationId);

					$scope.customerOwnerId = response.data[0].customerOwnerId;
					$scope.modelId = response.data[0].modelId;
					$scope.notes = response.data[0].notes;

					$scope.has_verified_serial = true;
				},
				function () {
					// error

					$scope.error_msg =
						'There was a problem trying to verify if the serial number exists. Please try again, or report the problem if it persists.';
				}
			)
		['finally'](function () {
			// always called

			$scope.is_busy = false;
		});
	};

	$scope.performReimaging = function () {
		// if we have form validation errors, then don't go any further
		if ($scope.doesReimagingFormFailValidation()) return false;

		var encoder_data = {
			uuid: $scope.encoder_to_reimage.uuid,
			serial: $scope.edit_fields.serial.toUpperCase(),
			buildDate: $scope.formatDateAsZulu($scope.edit_fields.formattedBuildDate),
			customerOwnerId: $scope.customerOwnerId,
			modelId: $scope.modelId,
			notes: $scope.notes
		};

		$scope.is_busy = true;

		// onboard encoder
		$http
			.post(jcs.api.url + '/onboarding?replace=true', encoder_data, { withCredentials: true })
			.then(
				function () {
					// success

					$scope.encoder_to_reimage = null;
					$scope.has_error = {};
					$scope.error_msg = null;

					$scope.loadEncoders();
				},
				function (reason) {
					// error

					if (reason.status === 409) {
						$scope.error_msg =
							'The serial number you specified does not exist. Please ensure the provided serial number is correct. Encoders with new serial numbers may only be onboarded.';
					} else {
						$scope.error_msg =
							'An error occurred while attempting to onboard the encoder. Please try again, or report the problem if it persists.';
					}
				}
			)
		['finally'](function () {
			// always called

			$scope.is_busy = false;
		});
	};

	$scope.getInternalIPs = function (encoder) {
		let ips = [];
		if (encoder.hasOwnProperty('nics') && encoder.nics != null){
			for (let nic of encoder.nics){
				if (nic.hasOwnProperty('ipV4s') && nic.ipV4s != null){
					for (let ipV4 of nic.ipV4s){
						if (ipV4.hasOwnProperty('ip')){
							ips.push(ipV4.ip);
						}
					}
				}
			}
		}
		return ips;
	};

	$scope.loadEncoders = function () {
		$scope.is_loading = true;
		$scope.load_encoders_error_msg = null;
		$scope.error_msg = null;

		// fetch our encoders that are available for onboarding
		$http.get(jcs.api.url + '/onboarding', { withCredentials: true }).then((response) => {

			$scope.load_encoders_error_msg = null;
			$scope.encoders = response.data;

			// build list of internal IPs for each encoder
			if ($scope.encoders != null){
				for (let encoder of $scope.encoders){
					encoder.internalIPs = $scope.getInternalIPs(encoder);
				}
			}

		}).catch(() => {

			$scope.load_encoders_error_msg = 'Unable to retrieve list of encoders for onboarding. Please try again, or report the problem if it persists.';
			$scope.encoders = null;

		}).finally(() => {

			$scope.is_loading = false;
		});
	};

	//
	// initialize by loading our list of encoders available for onboarding
	//
	$scope.loadEncoders();

	$scope.date_picker_options = {
		singleDatePicker: true,
		autoApply: true,
		autoUpdateInput: false, // allows date field to be blank
		locale: {
			format: $scope.DATE_FORMAT, //'ll' // <= looks like this is using moment.js formatting options
		},
	};
	// sets the current selected date of the datepicker; even if corresponding input field is empty, we may
	// want to set the datepicker to today's date.
	$scope.build_date_picker_set_date = function (date_as_moment) {
		if (date_as_moment != null) {
			$('#onboard-build-date')
				.data('daterangepicker')
				.setStartDate(date_as_moment);
			$('#onboard-build-date')
				.data('daterangepicker')
				.setEndDate(date_as_moment);
		}
	};
	$scope.build_date_picker_callback = function (start, end, label) {
		$scope.edit_fields.buildDate = moment(start).format($scope.DATE_FORMAT);
		$('#onboard-build-date').val($scope.edit_fields.buildDate);
	};

	$('#onboard-build-date').daterangepicker($scope.date_picker_options, $scope.build_date_picker_callback);
}

module.exports = OnboardingController;
