import React, { FC, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import Cropper from 'react-easy-crop';
import { Area } from '../ImageUpload/cropImage';

interface ImageCropperProps {
  fileSrc: string;
  aspect?: number;
  onCropComplete?: (croppedAreaPixels: Area) => void;
}

const ImageCropper: FC<ImageCropperProps> = ({ fileSrc, aspect, onCropComplete }) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const handleCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    if (onCropComplete) {
      onCropComplete(croppedAreaPixels);
    }
  }, [onCropComplete]);
  return (
    <div>
      <Cropper
        image={fileSrc}
        crop={crop}
        zoom={zoom}
        aspect={aspect}
        onCropChange={setCrop}
        onCropComplete={handleCropComplete}
        onZoomChange={setZoom}
      />
    </div>
  );
};

ImageCropper.propTypes = {
  fileSrc: PropTypes.string.isRequired,
  aspect: PropTypes.number,
  onCropComplete: PropTypes.func,
};

ImageCropper.displayName = 'ImageCropper';

export default ImageCropper;
