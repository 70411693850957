import Chart from 'chart.js/auto';
import {
  LINE_CHART_DEFAULTS,
  GROUP_VALUES,
  LEGEND_NAMES,
  COLOR_SET,
  PAGE_VALUE,
  DESTINATION_TYPES,
} from '../../../pages/EventAnalytics/event-analytics-constants';


const getLabel = (item: string, dataPageName: string, destinationPage?:DESTINATION_TYPES) => {
  if (item === GROUP_VALUES.NONE) {
    if (destinationPage === DESTINATION_TYPES.STREAM && dataPageName === PAGE_VALUE.MINUTE_BY_MINUTE) {
      return LEGEND_NAMES.totalViewers;
    } else {
      switch (dataPageName) {
        case PAGE_VALUE.VIEWERS:
          return LEGEND_NAMES.totalViewers;
        case PAGE_VALUE.MINUTE_BY_MINUTE:
          return LEGEND_NAMES.totalViews
        case PAGE_VALUE.AVG_WATCH_TIME:
          return LEGEND_NAMES.avgWatchTime;
        case PAGE_VALUE.LIVE_VIEWS:
          return LEGEND_NAMES.totalViews;
        case PAGE_VALUE.ALL_DESTINATIONS:
          return LEGEND_NAMES.totalViews;
        default:
          return LEGEND_NAMES.totalViewers;
      }
    }
   
  } else {
    return item;
  }
};

export const getLineChartProps = (parsedObject: Chart.ChartData, dataPageName:string,destinationPage?:DESTINATION_TYPES): Chart.ChartData => {
  const datasets = Object.keys(parsedObject.datasets || {}).map((item, _index) => {
    return {
      ...LINE_CHART_DEFAULTS,
      data: parsedObject?.datasets?.[item],
      label: getLabel(item, dataPageName, destinationPage),
      backgroundColor: COLOR_SET[_index]?.fillColor,
      borderColor: COLOR_SET[_index]?.baseColor,
      pointBorderColor: COLOR_SET[_index]?.baseColor,
      pointBackgroundColor: COLOR_SET[_index]?.baseColor,
      pointHoverBackgroundColor: COLOR_SET[_index]?.baseColor,
      pointHoverBorderColor: COLOR_SET[_index]?.baseColor,
    };
  });
  return {
    labels: parsedObject.labels,
    datasets,
  };
};
