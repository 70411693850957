import { passwordResetModule } from './index';

function routeConfig($provide, $routeProvider) {
  'ngInject';

  $provide.factory('$routeProvider', () => {
    return $routeProvider;
  });

  const resetOptions = {
    template: '<password-reset></password-reset>',
    access: {
      loginRequired: false,
    },
  };

  const setOptions = {
    controllerAs: '$ctrl',
    controller: passwordResetModule.controllers.passwordSet,
    template: require('./passwordSet.tmpl.html').default,
    access: {
      loginRequired: false,
    },
  };

  $routeProvider.when(passwordResetModule.routes.passwordReset, resetOptions);
  $routeProvider.when(passwordResetModule.routes.passwordSet, setOptions);
}

export default routeConfig;
