import { Context, createContext, Dispatch } from 'react';
import { EMBED_PAGE_STATE_CONTEXT_KEYS } from '../../../pages/EventAnalytics/event-analytics-constants';
import { EVENT_TYPES } from '../../../pages/EventAnalytics/types';

export interface EmbedPlayerActions<T> {
  payload: T;
  type: EMBED_PAGE_STATE_CONTEXT_KEYS;
}

export interface EmbedPlayerContextType {
  groupBy: EVENT_TYPES;
}

export interface EmbedPlayerContextProps {
  embedPlayerAnalyticsState: EmbedPlayerContextType;
  dispatch: Dispatch<EmbedPlayerActions<any>>;
}

export const EmbedPlayerContext: Context<EmbedPlayerContextProps> = createContext<EmbedPlayerContextProps>(
  {} as EmbedPlayerContextProps
);

export const dispatchers = {
  [EMBED_PAGE_STATE_CONTEXT_KEYS.GROUP_BY]: (
    state: EmbedPlayerContextType,
    action: EmbedPlayerActions<EVENT_TYPES>
  ): EmbedPlayerContextType => {
    return { ...state, groupBy: action.payload };
  },
};

export function EmbedPlayerReducer(
  state: EmbedPlayerContextType,
  action: EmbedPlayerActions<any>
): EmbedPlayerContextType {
  return dispatchers[action.type](state, action);
}
