import { Context, createContext, Dispatch } from 'react';
import { Cue } from '../../helpers/events/types';

export interface Action {
  type: ACTION_TYPE;
  payload?: any;
}

interface originalAndTrimmedDurations {
  formattedDuration: string,
  trimmedFormattedDuration?: string
}

export interface ArchiveWebEventState {
  webEventName?: string;
  startPosition: Cue | null;
  endPosition: Cue | null;
  published: boolean;
  customerId?: string;
  webEventId?: string;
  webEventProfileId?: string;
  progress: number;
  newWebEventLocation: string;
  originalAndTrimmedDurations: originalAndTrimmedDurations;
}

export interface ArchiveWebEventContext {
  state: ArchiveWebEventState;
  dispatch: Dispatch<Action>;
}

export enum ACTION_TYPE {
  WEB_EVENT_NAME = 'WEB_EVENT_NAME',
  START_POSITION = 'START_POSITION',
  END_POSITION = 'END_POSITION',
  PUBLISHED = 'PUBLISHED',
  CUSTOMER_ID = 'CUSTOMER_ID',
  WEB_EVENT_ID = 'WEB_EVENT_ID',
  WEB_EVENT_PROFILE_ID = 'WEB_EVENT_PROFILE_ID',
  PROGRESS = 'PROGRESS',
  NEW_WEB_EVENT_LOCATION = 'NEW_WEB_EVENT_LOCATION',
  WEB_EVENT_DURATIONS = 'WEB_EVENT_DURATIONS'
}

export const initialState: ArchiveWebEventState = {
  webEventName: '',
  startPosition: null,
  endPosition: null,
  published: false,
  customerId: '',
  webEventId: '',
  webEventProfileId: '',
  progress: 0,
  newWebEventLocation: '',
  originalAndTrimmedDurations: {
    formattedDuration: '',
    trimmedFormattedDuration: ''
  }
};

export function reducer(state: ArchiveWebEventState, action: Action): ArchiveWebEventState {
  switch (action.type) {
    case ACTION_TYPE.WEB_EVENT_NAME:
      return { ...state, webEventName: action.payload };
    case ACTION_TYPE.START_POSITION:
      return { ...state, startPosition: action.payload };
    case ACTION_TYPE.END_POSITION:
      return { ...state, endPosition: action.payload };
    case ACTION_TYPE.PUBLISHED:
      return { ...state, published: action.payload };
    case ACTION_TYPE.CUSTOMER_ID:
      return { ...state, customerId: action.payload };
    case ACTION_TYPE.WEB_EVENT_ID:
      return { ...state, webEventId: action.payload };
    case ACTION_TYPE.WEB_EVENT_PROFILE_ID:
      return { ...state, webEventProfileId: action.payload };
    case ACTION_TYPE.PROGRESS:
      return { ...state, progress: action.payload };
    case ACTION_TYPE.NEW_WEB_EVENT_LOCATION:
      return { ...state, newWebEventLocation: action.payload };
    case ACTION_TYPE.WEB_EVENT_DURATIONS:
      return { ...state, originalAndTrimmedDurations: action.payload };
    default:
      throw new Error('Invalid Action Type: ' + action.type);
  }
}

export const ArchiveWebEventContext: Context<ArchiveWebEventContext> = createContext<ArchiveWebEventContext>(
  {} as ArchiveWebEventContext
);
