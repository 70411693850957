import axios, { AxiosResponse } from 'axios';
import { api } from '../../../v2.snapshot/js/jcs';

export interface UserInvite {
  uuid: string;
  email: string;
}

export function acceptInvite(inviteId: string, password: string): Promise<AxiosResponse<void>> {
  const url = `${api.url_v3}/userinvites/${inviteId}/accept`;
  return axios.post(url, { password }, { withCredentials: true });
}

export function getInvite(inviteId: string): Promise<AxiosResponse<UserInvite>> {
  return axios.get(`${api.url_v3}/userinvites/${inviteId}`);
}

export function logout(): Promise<AxiosResponse<void>> {
  return axios.post(`${api.url_v3}/logout`, undefined, { withCredentials: true });
}
