'use strict';

const jcs = require('../../jcs');
const moment = require('moment');
const customersModule = require('./index').customersModule;
const dashboardModule = require('../dashboard').dashboardModule;

function OrganizationsController(
	$scope,
	$timeout,
	$routeParams,
	$location,
	$http,
	$q,
	httpService,
	paginationService,
	orgService,
	focus,
	Authentication
) {
	'ngInject';
	$(window).trigger('resize'); // ensure footer is properly positioned

	// NOTE: not using the tablesort plugin to handle sorting because I can't seem to get it to work in combination
	// with the filtering. Following the example on the github page never worked for me, even using a more up-to-date
	// version of the tablesort plugin and angular. Our "filtered_customers" list would always be null. So instead we
	// are just handling sorting ourselves.

	$scope.DATE_FORMAT = 'MM/DD/YY';
	$scope.DATE_FORMAT_ZULU = 'YYYY-MM-DDTHH:MM:SS';

	$scope.OPTION_YES = 'Yes';
	$scope.OPTION_NO = 'No';
	$scope.BOOLEAN_OPTIONS = [$scope.OPTION_YES, $scope.OPTION_NO];

	$scope.OPTION_BILLING_MONTHLY = 'Monthly';
	$scope.OPTION_BILLING_YEARLY = 'Yearly';
	$scope.OPTION_BILLING_NONE = '';
	$scope.BILLING_FREQ_OPTIONS = [$scope.OPTION_BILLING_NONE, $scope.OPTION_BILLING_MONTHLY, $scope.OPTION_BILLING_YEARLY];

	$scope.RETURN_TO_LIST = 'list';
	$scope.RETURN_TO_VIEW = 'view';

	$scope.REGEX_INTEGER = /^\d+$/;

	$scope.LEGACY_PLAN = '(Legacy)';

	$scope.QUICK_SEARCH_MIN_CHAR_LENGTH = 3; // minimum number of characters that must be entered to initiate a search
	$scope.QUICK_SEARCH_TIMER_DELAY = 350;

	$scope.GIGABYTES_IN_TB = 1000; // helper to convert from GB to TB and vice versa

	$scope.is_busy = false;
	$scope.is_loading = false;

	$scope.customers = null;
	$scope.is_loading_quick_search = false;
	$scope.customers_error_msg = null;

	$scope.prev_used_filter_total_pages = 0;
	$scope.customer_filter = null;
	$scope.filtered_customers = null;
	$scope.fetch_search_results_timeout_promise = null;
	$scope.search_map = {};

	$scope.ACTIVE_YES = 'Active'; // 'Yes';
	$scope.ACTIVE_NO = 'Disabled'; // 'No';
	$scope.ACTIVE_DEFAULT = $scope.ACTIVE_NO;
	$scope.active_options = [$scope.ACTIVE_YES, $scope.ACTIVE_NO];

	$scope.sortableColumns = {
		name: { reverse: false },
		planName: { reverse: null },
		webPlanName: { reverse: null },
	};
	$scope.columnToSortBy = 'name';

	$scope.selectedRow = -1; // allow use of arrow keys (up/down) to select customer from list

	$scope.orgService = orgService;
	$scope.orgService.clearSearchCache();

	// advanced search
	$scope.advanced_search = null;
	$scope.adv_customers = null;

	// switch to customer
	$scope.customer_to_switch_to = null;
	$scope.customer_to_switch_to_name = '';
	$scope.customer_to_switch_to_error = null;
	$scope.password = null;

	$scope.toggles_map = null; // mapping of toggle IDs to toggle names

	// view customer
	$scope.customer_to_view = null;
	$scope.customer_data = null; // data for the customer_to_view (this must be loaded separately)
	$scope.customer_toggles = null; // toggles for this customer, sites, users, and encoders
	$scope.is_loading_customer_data = false;
	$scope.customer_data_error = null;
	// customer contacts
	$scope.customer_contacts = null; // additional data for the current customer_to_view
	$scope.is_loading_customer_contacts = false; // activity indicator
	$scope.customer_contacts_error = null;
	// customer sites
	$scope.customer_sites = null; // additional data for the current customer_to_view
	$scope.customer_users = null;
	$scope.is_loading_customer_sites = false; // activity indicator
	$scope.customer_sites_error = null;
	// managed orgs
	$scope.customer_manages = null;
	$scope.customer_manages_error = null;

	$scope.edit_fields = null; // used when adding or editing customer
	$scope.has_error = {};
	$scope.addedit_customer_validation_error = null;

	// add customer
	$scope.addedit_customer_error = null;

	// edit customer
	$scope.customer_to_edit = null;
	$scope.return_after_edit = $scope.RETURN_TO_LIST;

	// used by both add/edit
	$scope.customer_plans = null;
	$scope.web_plans = null;
	$scope.vod_plans = null;
	$scope.loading_plans_error = null;

	// delete customer
	$scope.customer_to_delete = null;
	$scope.delete_customer_error = null;

	// dropdown options (will eventually fetch from API)
	$scope.web_allowance_options = [
		{ name: 'Custom', value: null },
		{ name: '0 TB', value: 0 },
		{ name: '0.20 TB', value: 0.20 },
		{ name: '0.25 TB', value: 0.25 },
		{ name: '0.35 TB', value: 0.35 },
		{ name: '0.5 TB', value: 0.5 },
		{ name: '1 TB', value: 1 },
		{ name: '2 TB', value: 2 },
		{ name: '4 TB', value: 4 },
		{ name: '6 TB', value: 6 },
		{ name: '8 TB', value: 8 },
		{ name: '9 TB', value: 9 },
		{ name: '10 TB', value: 10 },
		{ name: '11 TB', value: 11 },
		{ name: '12 TB', value: 12 },
		{ name: '13 TB', value: 13 },
		{ name: '15 TB', value: 15 },
	];
	$scope.WEB_ALLOWANCE_DEFAULT = $scope.web_allowance_options[1].value;

	$scope.organization_list = [];

	$scope.getCurrentUser = function () {
		return Authentication.getCurrentUser();
	};

	$scope.convertGBtoTB = function (value) {
		return value / $scope.GIGABYTES_IN_TB;
	};

	$scope.convertTBtoGB = function (value) {
		return value * $scope.GIGABYTES_IN_TB;
	};

	$scope.formatBool = function (boolean_to_format) {
		return boolean_to_format ? 'Yes' : 'No';
	};

	$scope.enterAdvSearchMode = function () {
		$scope.customers_error_msg = null;
		$scope.advanced_search = {
			name: '',
			hasWebPlan: $scope.OPTION_NO,
		};
	};

	$scope.cancelAdvSearchMode = function () {
		$scope.customers_error_msg = null;
		$scope.advanced_search = null;
		focus('filter-customer');
	};

	// builds query string for search and page changes based on current search criteria
	$scope.buildQueryString = function () {
		var search_queries = [];

		if (!$scope.isEmpty($scope.advanced_search.name)) {
			if (orgService.isUuid($scope.advanced_search.name)) {
				search_queries.push('uuid=' + encodeURIComponent($scope.advanced_search.name));
			} else {
			 	search_queries.push('name=' + encodeURIComponent($scope.advanced_search.name));
			}
		}
		if ($scope.advanced_search.hasWebPlan == $scope.OPTION_YES) {
			search_queries.push('hasWebPlan=true');
		}

		search_queries.push($scope.pagination.getQueryString());

		return search_queries.join('&');
	};

	$scope.performAdvancedSearch = function () {
		$scope.is_busy = true;

		$scope.pagination.current_page = 1;

		// all we have is a uuid, so fetch details for this customer so we can display a name
		$http
			.get(jcs.api.url + '/customers?' + $scope.buildQueryString(), { withCredentials: true })
			.then(
				function (response) {
					// success

					$scope.pagination.update(response.headers());
					$scope.adv_customers = response.data;
				},
				function () {
					// error

					// borrowing this error msg field
					$scope.customers_error_msg =
						'An error occurred while searching. Please try again, or report the problem if it persists.';
				}
			)
		['finally'](function () {
			// always called

			$scope.is_busy = false;
		});
	};

	$scope.changePage = function () {
		// only perform search if a search term was provided
		$scope.search_error = null;
		$scope.pagination.is_changing_page = true;

		// perform decoder search
		$http
			.get(jcs.api.url + '/customers?' + $scope.buildQueryString(), { withCredentials: true })
			.then(
				function (response) {
					// success

					$scope.pagination.update(response.headers());
					$scope.adv_customers = response.data;
				},
				function () {
					// error

					// borrowing this error msg field
					$scope.customers_error_msg =
						'An error occurred while searching. Please try again, or report the problem if it persists.';
				}
			)
		['finally'](function () {
			// always called

			$scope.pagination.is_changing_page = false;
		});
	};

	$scope.enterSwitchToCustomerMode = function (customer) {
		// check to see if we have all the info we need. If the user was on the org list page, then we should be fine,
		// but if they are coming from another page (such as hardware search page), then all we'll have is a uuid, and
		// we'll need to load the customer details in order to have the customer name.
		if (customer.hasOwnProperty('name')) {
			$scope.customer_to_switch_to = customer;
			$scope.customer_to_switch_to_name = customer.name;

			// see app.js for where focus is defined
			focus('password-again');
		} else {
			$scope.is_loading = true;

			// all we have is a uuid, so fetch details for this customer so we can display a name
			$http
				.get(jcs.api.url + '/customers/' + customer.uuid, { withCredentials: true })
				.then(
					function (response) {
						// success

						$scope.customer_to_switch_to = response.data;
						$scope.customer_to_switch_to_name = response.data.name;

						// see app.js for where focus is defined
						focus('password-again');
					},
					function () {
						// error

						// borrowing this error msg field
						$scope.customers_error_msg =
							'An error occurred while attempting to load the organization info. Please try again, or report the problem if it persists.';
					}
				)
			['finally'](function () {
				// always called

				$scope.is_loading = false;
			});
		}
	};

	$scope.enterAddCustomerMode = function () {

		$scope.addedit_customer_validation_error = null;

		$scope.edit_fields = {
			name: '',
			managed_by: null,
			plan: null,
			webplan: null,
			web_allowance: $scope.WEB_ALLOWANCE_DEFAULT,
			web_allowance_custom: '',
			vodplan: null,
			vod_bandwidth: $scope.WEB_ALLOWANCE_DEFAULT,
			vod_bandwidth_custom: '',
			vod_storage: $scope.WEB_ALLOWANCE_DEFAULT,
			vod_storage_custom: '',
			active: $scope.ACTIVE_DEFAULT,
			notes: '',
			logo_permission: $scope.OPTION_NO,
			logo_live_on_site: $scope.OPTION_NO,
			hubspot: $scope.OPTION_NO,
			logoNotes: '',
			msa: $scope.OPTION_NO,
			monitor: $scope.OPTION_NO,
			monitorNotes: '',
			auth: $scope.OPTION_NO,
			exempt: $scope.OPTION_NO,
			site_license_broadcast: 0,
			site_license_pro: 0,
			site_license_ent: 0,
			site_license_lan_cloud: 0,
			additional_web_events: 0,
			additional_stream_minutes: 0,
			additional_viewer_minutes: 0,
			additional_software_encoders: 0,
			billing_web_freq: $scope.OPTION_BILLING_NONE,
			billing_multisite_freq: $scope.OPTION_BILLING_NONE,
			billing_vod_freq: $scope.OPTION_BILLING_NONE,
			sixteen_channel_audio: $scope.OPTION_NO,
      subtitles_hours_per_month: 0,
			concurrent_rtmp_streams: 0,
		};

		$scope.has_error = {};

		$scope.is_loading = true;

		var promises = [];
		// retrieve web event profiles
		promises.push($http.get(jcs.api.url + '/plans', { withCredentials: true }));
		promises.push($http.get(jcs.api.url + '/webPlans', { withCredentials: true }));
		promises.push($http.get(jcs.api.url_v3 + '/vodPlans', { withCredentials: true }));
		promises.push(orgService.getOrganizationNameList());

		$q.all(promises).then(response => {

			$scope.loading_plans_error = null;

			$scope.customer_plans = response[0].data?.sort($scope.customPlanSort);
			$scope.web_plans = response[1].data?.sort($scope.customPlanSort);
			$scope.vod_plans = response[2].data?.sort($scope.customPlanSort);
			$scope.organization_list = response[3];
			// add "None" option to our lists
			$scope.customer_plans.unshift({ name: 'None', uuid: null });
			$scope.web_plans.unshift({ name: 'None', uuid: null });
			$scope.vod_plans.unshift({ name: 'None', uuid: null });

			// initialize date pickers
			let today_formatted = moment().format($scope.DATE_FORMAT);
			$scope.setMultisiteStartDatePicker(today_formatted);
			$scope.setWebStartDatePicker(today_formatted);
			$scope.setVODStartDatePicker(today_formatted);

		}).catch(reason => {

			if (!httpService.isStatus406(reason)) {
				$scope.loading_plans_error = 'Unable to retrieve plan information.';
			}
			$scope.customer_plans = null;
			$scope.web_plans = null;
			$scope.vod_plans = null;
			$scope.edit_fields = null;

		}).finally(() => {

			$scope.is_loading = false;
			// see app.js for where focus is defined
			focus('add-customer-input');

		});
	};

	$scope.getFormattedVODPlan = function (customer) {
		if (customer == null) return '';
		if (customer.vodPlanId == null) {
			return 'None';
		}
		return customer.vodPlanName;
	};

	// returns web plan name, including data allowance and number of concurrent events
	$scope.getFormattedWebPlan = function (customer) {
		if (customer == null) return '';
		if (customer.webPlanId == null) {
			return 'None';
		}
		return customer.webPlanName;
	};

	$scope.enterViewCustomerMode = function (customer) {
		$scope.customer_to_view = customer;

		$scope.customer_manages = null;
		$scope.customer_manages_error = null;

		// load more details customer data
		$scope.loadCustomerData();
		// load the customer contact info
		$scope.loadCustomerContacts();
		// load the customer site info
		$scope.loadCustomerSites();
	};

	$scope.isUser = function (item) {
		// make sure planName doesn't happen to be null
		if (item.planName == null) return false;
		return item.planName.toLowerCase() == 'user';
	};

	$scope.getToggleName = function (toggleId) {
		if ($scope.toggles_map != null) {
			if ($scope.toggles_map.hasOwnProperty(toggleId)) {
				return $scope.toggles_map[toggleId];
			}
		}
		return 'Unknown';
	};

	// returns a string of text for the given list of toggle IDs
	$scope.getTogglesAsText = function (list) {
		if (list == null) {
			return '';
		}

		var toggle_names = [];
		for (var i = 0; i < list.length; i++) {
			toggle_names.push($scope.getToggleName(list[i]));
		}
		return toggle_names.join(', ');
	};

	$scope.loadCustomerSites = function () {
		if ($scope.customer_to_view != null) {
			$scope.customer_sites = null;
			$scope.customer_users = null;
			$scope.is_loading_customer_sites = true;
			$scope.customer_sites_error = null;

			// load the sensor data for this encoder
			$http.get(`${jcs.api.url}/customers/${$scope.customer_to_view.uuid}/users`, { withCredentials: true }).then(response => {

				$scope.customer_sites = [];
				$scope.customer_users = [];
				for (let i = 0; i < response.data.length; i++) {
					const item = response.data[i];
					// split sites and users into separate lists
					if ($scope.isUser(item)) {
						$scope.customer_users.push(item);
					} else {
						$scope.customer_sites.push(item);
					}
				}

			}).catch(reason => {

				$scope.customer_sites_error = 'An error occurred while attempting to load the organization site info. Please try again, or report the problem if it persists.';

			}).finally(() => {

				$scope.is_loading_customer_sites = false;

			});
		}
	};

	// note: $scope.customer_to_view needs to be set before calling this method
	$scope.loadCustomerContacts = function () {
		if ($scope.customer_to_view != null) {
			$scope.customer_contacts = null;
			$scope.is_loading_customer_contacts = true;
			$scope.customer_contacts_error = null;

			$http
				.get(jcs.api.url + '/customers/' + $scope.customer_to_view.uuid + '/contacts', { withCredentials: true })
				.then(
					function (response) {
						// success

						$scope.customer_contacts = response.data;
					},
					function () {
						// error

						$scope.customer_contacts_error =
							'An error occurred while attempting to load the organization contact info. Please try again, or report the problem if it persists.';
						$scope.customer_contacts = null;
					}
				)
			['finally'](function () {
				// always called

				$scope.is_loading_customer_contacts = false;
			});
		}
	};

	// note: $scope.customer_to_view needs to be set before calling this method
	$scope.loadCustomerData = function () {
		if ($scope.customer_to_view != null) {
			$scope.customer_data = null;
			$scope.customer_toggles = null;
			$scope.is_loading_customer_data = true;
			$scope.customer_data_error = null;

			var promises = [];
			var loadTogglesList = $scope.toggles_map == null;
			// load the data for this customer
			promises.push($http.get(jcs.api.url + '/customers/' + $scope.customer_to_view.uuid, { withCredentials: true }));
			// load the toggles for this customer
			promises.push($http.get(jcs.api.url_v3 + '/customers/' + $scope.customer_to_view.uuid + '/toggleregs', { withCredentials: true }));
			// if it hasn't already been loaded, load the list of all available toggles
			if (loadTogglesList) {
				promises.push($http.get(jcs.api.url_v3 + '/customers/' + $scope.customer_to_view.uuid + '/toggles', { withCredentials: true }));
			}

			$q.all(promises).then(response => {

				$scope.customer_data = response[0].data;

				// if complete toggle list was loaded, then format it into a map (for easy lookup of toggle names)
				if (loadTogglesList) {
					var list = response[2].data;
					// format
					$scope.toggles_map = {};
					for (var i = 0; i < list.length; i++) {
						var entry = list[i];
						$scope.toggles_map[entry.uuid] = entry.name;
					}
				}

				// convert toggles response into format that is easier to work with
				var toggles = response[1].data;
				$scope.customer_toggles = [];
				// add customer
				if (toggles.toggleIds != null && toggles.toggleIds.length > 0) {
					$scope.customer_toggles.push({
						name: $scope.customer_data.name,
						toggle_list: $scope.getTogglesAsText(toggles.toggleIds),
					});
				}
				// add users
				for (var i = 0; i < toggles.users.length; i++) {
					var user = toggles.users[i];
					if (user.toggleIds != null && user.toggleIds.length > 0) {
						$scope.customer_toggles.push({
							name: user.userName,
							toggle_list: $scope.getTogglesAsText(user.toggleIds),
						});
					}
				}

				// if this org is managed by another, then load the managing organization's info
				if ($scope.customer_data.managedBy != null){
					$scope.customer_data.managed_by_name = "Loading ...";
					orgService.getOrganization($scope.customer_data.managedBy).then(inner_response => {

						$scope.customer_data.managed_by_name = inner_response.name;

					}).catch(reason => {

						$scope.customer_data.managed_by_name = "Unknown";

					});
				}

			}).catch(reason => {

				if (!httpService.isStatus406(reason)) {
					$scope.customer_data_error = 'An error occurred while attempting to load the organization info. Please try again, or report the problem if it persists.';
				}
				$scope.customer_data = null;
				$scope.customer_toggles = null;

			}).finally(() => {

				$scope.is_loading_customer_data = false;

			});
		}
	};

	$scope.isCustomWebAllowanceOption = function (web_allowance) {
		for (let i=0; i < $scope.web_allowance_options.length; i++){
			if (web_allowance == $scope.web_allowance_options[i].value){
				return false;
			}
		}
		return true;
	};

	$scope.getBillingFreqAsText = function (billing_yearly){
		if (billing_yearly === null){
			return $scope.OPTION_BILLING_NONE;
		}
		return billing_yearly ? $scope.OPTION_BILLING_YEARLY : $scope.OPTION_BILLING_MONTHLY;
	};

	$scope.enterEditMode = function (customer, return_to) {
		$scope.customer_to_edit = customer;
		$scope.return_after_edit = return_to; // should be one of RETURN_TO_* constants
		$scope.customer_to_view = null;
		$scope.customer_data = null;
		$scope.customer_contacts = null;
		$scope.has_error = {};
		$scope.addedit_customer_validation_error = null;

		$scope.is_loading = true;

		var promises = [];
		// retrieve web event profiles and customer details
		promises.push($http.get(jcs.api.url + '/plans', { withCredentials: true }));
		promises.push($http.get(jcs.api.url + '/webPlans', { withCredentials: true }));
		promises.push($http.get(jcs.api.url_v3 + '/vodPlans', { withCredentials: true }));
		promises.push(orgService.getOrganizationNameList());
		promises.push($http.get(jcs.api.url + '/customers/' + $scope.customer_to_edit.uuid, { withCredentials: true }));

		$q.all(promises).then(response => {

			$scope.loading_plans_error = null;

			$scope.customer_plans = response[0].data?.sort($scope.customPlanSort);
			$scope.web_plans = response[1].data?.sort($scope.customPlanSort);
			$scope.vod_plans = response[2].data?.sort($scope.customPlanSort);
			$scope.organization_list = response[3];
			// add "None" option to our lists
			$scope.customer_plans.unshift({ name: 'None', uuid: null });
			$scope.web_plans.unshift({ name: 'None', uuid: null });
			$scope.vod_plans.unshift({ name: 'None', uuid: null });

			var customer_info = response[4].data;

			$scope.edit_fields = {
				uuid: customer_info.uuid,
				name: customer_info.name,
				managed_by: customer_info.managedBy,
				notes: customer_info.notes,
				plan: customer_info.planId,
				active: customer_info.active ? $scope.ACTIVE_YES : $scope.ACTIVE_NO,
				webplan: customer_info.webPlanId,
				web_allowance: $scope.isCustomWebAllowanceOption(customer_info.webBandwidth) ? null : customer_info.webBandwidth,
				web_allowance_custom: $scope.isCustomWebAllowanceOption(customer_info.webBandwidth) ? customer_info.webBandwidth : '',
				vodplan: customer_info.vodPlanId,
				vod_bandwidth: $scope.isCustomWebAllowanceOption($scope.convertGBtoTB(customer_info.additionalVodBandwidthGb)) ? null : $scope.convertGBtoTB(customer_info.additionalVodBandwidthGb),
				vod_bandwidth_custom: $scope.isCustomWebAllowanceOption($scope.convertGBtoTB(customer_info.additionalVodBandwidthGb)) ? $scope.convertGBtoTB(customer_info.additionalVodBandwidthGb) : '',
				vod_storage: $scope.isCustomWebAllowanceOption($scope.convertGBtoTB(customer_info.additionalVodStorageGb)) ? null : $scope.convertGBtoTB(customer_info.additionalVodStorageGb),
				vod_storage_custom: $scope.isCustomWebAllowanceOption($scope.convertGBtoTB(customer_info.additionalVodStorageGb)) ? $scope.convertGBtoTB(customer_info.additionalVodStorageGb) : '',
				logo_permission: customer_info.logoPermission ? $scope.OPTION_YES : $scope.OPTION_NO,
				logo_live_on_site: customer_info.logoLiveOnSite ? $scope.OPTION_YES : $scope.OPTION_NO,
				hubspot: customer_info.hubspot ? $scope.OPTION_YES : $scope.OPTION_NO,
				logo_notes: customer_info.logoNotes,
				msa: customer_info.msa ? $scope.OPTION_YES : $scope.OPTION_NO,
				monitor: customer_info.monitor ? $scope.OPTION_YES : $scope.OPTION_NO,
				monitor_notes: customer_info.monitorNotes,
				auth: customer_info.auth,
				exempt: customer_info.exempt,
				site_license_broadcast: customer_info.broadcastSiteLicenses,
				site_license_pro: customer_info.professionalSiteLicenses,
				site_license_ent: customer_info.enterpriseSiteLicenses,
				site_license_lan_cloud: customer_info.lanPlusCloudSiteLicenses,
				additional_web_events: customer_info.additionalConcurrentWebEvents,
				additional_stream_minutes: customer_info.subscriptionDetails.additionalStreamMinutes,
				additional_viewer_minutes: customer_info.subscriptionDetails.additionalViewerMinutes,
				additional_software_encoders: customer_info.softwareEncoderLicenses,
				billing_web_freq: $scope.getBillingFreqAsText(customer_info.billingYearlyWeb),
				billing_multisite_freq: $scope.getBillingFreqAsText(customer_info.billingYearlyMultiSite),
				billing_vod_freq: $scope.getBillingFreqAsText(customer_info.billingYearlyVod),
				sixteen_channel_audio: customer_info.allowed16ChannelAudio ? $scope.OPTION_YES : $scope.OPTION_NO,
				subtitles_hours_per_month: customer_info.subscriptionDetails.subtitlesHoursPerMonth,
				concurrent_rtmp_streams: customer_info.subscriptionDetails.concurrentRtmpStreams,
			};

			// initialize date fields and corresponding date picker
			if (customer_info.multisiteStartDate) {
				var date_as_moment = moment(customer_info.multisiteStartDate);
				$scope.edit_fields['multisite_start_date'] = date_as_moment.format($scope.DATE_FORMAT);
				$scope.setMultisiteStartDatePicker(date_as_moment);
			}
			if (customer_info.webStartDate) {
				var date_as_moment = moment(customer_info.webStartDate);
				$scope.edit_fields['web_start_date'] = date_as_moment.format($scope.DATE_FORMAT);
				$scope.setWebStartDatePicker(date_as_moment);
			}
			if (customer_info.vodStartDate) {
				var date_as_moment = moment(customer_info.vodStartDate);
				$scope.edit_fields['vod_start_date'] = date_as_moment.format($scope.DATE_FORMAT);
				$scope.setVODStartDatePicker(date_as_moment);
			}

		}).catch(reason => {

			if (!httpService.isStatus406(reason)) {
				$scope.loading_plans_error = 'There was an error retrieving the organization information. Please try again, or report the problem if it persists.';
			}
			$scope.customer_plans = null;
			$scope.web_plans = null;
			$scope.vod_plans = null;

		}).finally(() => {

			$scope.is_loading = false;
			// see app.js for where focus is defined
			focus('edit-customer-input');

		});
	};

	$scope.enterDeleteCustomerMode = function (customer) {
		$scope.customer_to_delete = customer;
	};

	$scope.cancelSwitchCustomer = function () {
		$scope.customer_to_switch_to = null;
		$scope.customer_to_switch_to_error = null;
		$scope.password = null;
		focus('filter-customer');
	};

	$scope.cancelAddEdit = function () {

		if ($scope.edit_fields.uuid){

			const temp_customer = $scope.customer_to_edit;

			$scope.customer_to_edit = null;
			$scope.edit_fields = null;
			$scope.has_error = {};
			$scope.addedit_customer_error = null;
			$scope.addedit_customer_validation_error = null;

			$scope.returnAfterEdit(temp_customer);

		} else {

			$scope.edit_fields = null;
			$scope.has_error = {};
			$scope.addedit_customer_error = null;
			$scope.addedit_customer_validation_error = null;
			focus('filter-customer');

		}
	};

	$scope.returnAfterEdit = function (customer) {
		if ($scope.return_after_edit == $scope.RETURN_TO_VIEW) {
			$scope.enterViewCustomerMode(customer);
		} else {
			// RETURN_TO_LIST

			// ensure our url path is just /customers (and doesn't include customer uuid)
			$location.path(customersModule.routes.list);
			focus('filter-customer');
		}
	};

	$scope.cancelView = function () {
		$scope.customer_to_view = null;
		$scope.customer_data = null;
		$scope.customer_data_error = null;
		// ensure our url path is just /customers (and doesn't include customer uuid)
		$location.path(customersModule.routes.list);
		focus('filter-customer');
	};

	$scope.cancelDelete = function () {
		$scope.customer_to_delete = null;
		$scope.delete_customer_error = null;
		focus('filter-customer');
	};

	$scope.switchCustomer = function () {
		$scope.is_busy = true;

		const currentUserName = Authentication.getCurrentUser().name;

		Authentication.sudoLogin(currentUserName, $scope.password, $scope.customer_to_switch_to.uuid)
			.then(
				function () {
					// success
					$scope.customer_to_switch_to = null;
					$scope.customer_to_switch_to_error = null;
					$scope.password = null;

					// redirect to homepage
					$location.path(dashboardModule.routes.list);
				},
				function () {
					// error

					$scope.customer_to_switch_to_error =
						'An error occurred while attempting to switch organizations. You may have entered your password incorrectly. Please try again, or report the problem if it persists.';
				}
			)
		['finally'](function () {
			// always called

			$scope.is_busy = false;
		});
	};

	// returns true if the selected plan is a "Demo"
	$scope.isDemoPlan = function(plan_list, plan_uuid){
		for (const entry of plan_list){
			if (entry.uuid === plan_uuid && entry.name === "Demo"){
				return true;
			}
		}
		return false;
	};

	$scope.doesAddEditFormFailValidation = function () {
		$scope.has_error = {};
		$scope.addedit_customer_validation_error = null;

		// check required fields to ensure they aren't empty
		$scope.has_error.name = $scope.isEmpty($scope.edit_fields.name);
		$scope.has_error.sixteen_channel_audio = $scope.isEmpty($scope.edit_fields.sixteen_channel_audio);
		if ($scope.edit_fields.webplan !== null && $scope.edit_fields.web_allowance === null){
			$scope.has_error.web_allowance = $scope.isEmpty($scope.edit_fields.web_allowance_custom);
		}
		if ($scope.edit_fields.vodplan !== null && $scope.edit_fields.vod_bandwidth === null){
			$scope.has_error.vod_bandwidth = $scope.isEmpty($scope.edit_fields.vod_bandwidth_custom);
		}
		if ($scope.edit_fields.vodplan !== null && $scope.edit_fields.vod_storage === null){
			$scope.has_error.vod_storage = $scope.isEmpty($scope.edit_fields.vod_storage_custom);
		}
		// if a web or multisite plan is selected, then the corresponding start date is required
		if ($scope.edit_fields.plan !== null && !$scope.isDemoPlan($scope.customer_plans, $scope.edit_fields.plan)){
			$scope.has_error.multisite_start_date = $scope.isEmpty($scope.edit_fields.multisite_start_date);
		}
		if ($scope.edit_fields.webplan !== null && !$scope.isDemoPlan($scope.web_plans, $scope.edit_fields.webplan)){
			$scope.has_error.web_start_date = $scope.isEmpty($scope.edit_fields.web_start_date);
		}
		if ($scope.edit_fields.vodplan !== null && !$scope.isDemoPlan($scope.vod_plans, $scope.edit_fields.vodplan)){
			$scope.has_error.vod_start_date = $scope.isEmpty($scope.edit_fields.vod_start_date);
		}

		var error_count = 0;
		for (var property in $scope.has_error) {
			if ($scope.has_error[property] === true) {
				error_count++;
			}
		}
		var has_validation_error = error_count > 0;

		if (has_validation_error) {
			$scope.addedit_customer_validation_error = 'Please specify a value for the highlighted fields above.';
			return true;
		}

		// perform specialty checks
		const integer_check_list = [
			{field:'site_license_broadcast', error_text:'Broadcast Site Licenses'},
			{field:'site_license_ent', error_text:'Enterprise Site Licenses'},
			{field:'site_license_pro', error_text:'Professional Site Licenses'},
			{field:'site_license_lan_cloud', error_text:'LAN + Cloud Site Licenses'},
			{field:'additional_web_events', error_text:'Additional Web Events'},
			{field:'additional_stream_minutes', error_text:'Additional Stream Minutes'},
			{field:'additional_viewer_minutes', error_text:'Additional Viewer Minutes'},
      { field: 'subtitles_hours_per_month', error_text: 'Subtitles Hours/Month' },
			{ field: 'concurrent_rtmp_streams', error_text: 'Concurrent Rtmp Streams'}
		];
		for (const item of integer_check_list){
			if (!$scope.isEmpty($scope.edit_fields[item.field]) && !$scope.REGEX_INTEGER.test($scope.edit_fields[item.field])) {
				$scope.has_error[item.field] = true;
				$scope.addedit_customer_validation_error = `Please specify a integer value for number of ${item.error_text}.`;
				return true;
			}
		}

		if ($scope.edit_fields.webplan !== null && $scope.edit_fields.web_allowance === null){
			if (!$scope.REGEX_INTEGER.test($scope.edit_fields.web_allowance_custom)) {
				$scope.has_error.web_allowance = true;
				$scope.addedit_customer_validation_error = 'Please specify a integer value for the custom Web Data Allowance.';
				return true;
			}
			if (parseInt($scope.edit_fields.web_allowance_custom) <= 0){
				$scope.has_error.web_allowance = true;
				$scope.addedit_customer_validation_error = 'Please specify a custom Web Data Allowance value greater than 0.';
				return true;
			}
		}

		if ($scope.edit_fields.vodplan !== null && $scope.edit_fields.vod_bandwidth === null){
			if (!$scope.REGEX_INTEGER.test($scope.edit_fields.vod_bandwidth_custom)) {
				$scope.has_error.vod_bandwidth = true;
				$scope.addedit_customer_validation_error = 'Please specify a integer value for the custom VOD Bandwidth.';
				return true;
			}
			if (parseInt($scope.edit_fields.vod_bandwidth_custom) <= 0){
				$scope.has_error.vod_bandwidth = true;
				$scope.addedit_customer_validation_error = 'Please specify a custom VOD Bandwidth value greater than 0.';
				return true;
			}
		}
		if ($scope.edit_fields.vodplan !== null && $scope.edit_fields.vod_storage === null){
			if (!$scope.REGEX_INTEGER.test($scope.edit_fields.vod_storage_custom)) {
				$scope.has_error.vod_storage = true;
				$scope.addedit_customer_validation_error = 'Please specify a integer value for the custom VOD Storage.';
				return true;
			}
			if (parseInt($scope.edit_fields.vod_storage_custom) <= 0){
				$scope.has_error.vod_storage = true;
				$scope.addedit_customer_validation_error = 'Please specify a custom VOD Storage value greater than 0.';
				return true;
			}
		}

		return false;
	};

	$scope.saveAddEditOrganization = function () {
		// if we have form validation errors, then don't go any further
		if ($scope.doesAddEditFormFailValidation()) {
			return false;
		}

		const customer_data = {
			name: $scope.edit_fields.name,
			managedBy: $scope.edit_fields.managed_by,
			notes: $scope.edit_fields.notes,
			planId: $scope.edit_fields.plan,
			active: $scope.edit_fields.active == $scope.ACTIVE_YES,
			multisiteStartDate: $scope.formatDateForDB($scope.edit_fields.multisite_start_date),
			webPlanId: $scope.edit_fields.webplan,
			webBandwidth: $scope.edit_fields.web_allowance !== null ? $scope.edit_fields.web_allowance : parseInt($scope.edit_fields.web_allowance_custom),
			webStartDate: $scope.formatDateForDB($scope.edit_fields.web_start_date),
			vodPlanId: $scope.edit_fields.vodplan,
			vodStartDate: $scope.formatDateForDB($scope.edit_fields.vod_start_date),
			additionalVodBandwidthGb: $scope.convertTBtoGB($scope.edit_fields.vod_bandwidth !== null ? $scope.edit_fields.vod_bandwidth : parseInt($scope.edit_fields.vod_bandwidth_custom)),
			additionalVodStorageGb: $scope.convertTBtoGB($scope.edit_fields.vod_storage !== null ? $scope.edit_fields.vod_storage : parseInt($scope.edit_fields.vod_storage_custom)),
			broadcastSiteLicenses: $scope.isEmpty($scope.edit_fields.site_license_broadcast) ? 0 : parseInt($scope.edit_fields.site_license_broadcast),
			professionalSiteLicenses: $scope.isEmpty($scope.edit_fields.site_license_pro) ? 0 : parseInt($scope.edit_fields.site_license_pro),
			enterpriseSiteLicenses: $scope.isEmpty($scope.edit_fields.site_license_ent) ? 0 : parseInt($scope.edit_fields.site_license_ent),
			lanPlusCloudSiteLicenses: $scope.isEmpty($scope.edit_fields.site_license_lan_cloud) ? 0 : parseInt($scope.edit_fields.site_license_lan_cloud),
			additionalConcurrentWebEvents: $scope.isEmpty($scope.edit_fields.additional_web_events) ? 0 : parseInt($scope.edit_fields.additional_web_events),
			softwareEncoderLicenses: $scope.isEmpty($scope.edit_fields.additional_software_encoders) ? 0 : parseInt($scope.edit_fields.additional_software_encoders),
			billingYearlyWeb: $scope.isEmpty($scope.edit_fields.billing_web_freq) ? null : $scope.edit_fields.billing_web_freq === $scope.OPTION_BILLING_YEARLY,
			billingYearlyMultiSite: $scope.isEmpty($scope.edit_fields.billing_multisite_freq) ? null : $scope.edit_fields.billing_multisite_freq === $scope.OPTION_BILLING_YEARLY,
			billingYearlyVod: $scope.isEmpty($scope.edit_fields.billing_vod_freq) ? null : $scope.edit_fields.billing_vod_freq === $scope.OPTION_BILLING_YEARLY,
			allowed16ChannelAudio: $scope.edit_fields.sixteen_channel_audio === $scope.OPTION_YES,
			numConcurrentTranscodes: 0, // NOTE: the backend currently requires this field; this field should be removed from the backend at some point
			subscriptionDetails: {
				subtitlesHoursPerMonth: $scope.isEmpty($scope.edit_fields.subtitles_hours_per_month) ? 0 : parseInt($scope.edit_fields.subtitles_hours_per_month),
				additionalStreamMinutes: $scope.isEmpty($scope.edit_fields.additional_stream_minutes) ? 0 : parseInt($scope.edit_fields.additional_stream_minutes),
				additionalViewerMinutes: $scope.isEmpty($scope.edit_fields.additional_viewer_minutes) ? 0 : parseInt($scope.edit_fields.additional_viewer_minutes),
				concurrentRtmpStreams: $scope.isEmpty($scope.edit_fields.concurrent_rtmp_streams) ? 0 : parseInt($scope.edit_fields.concurrent_rtmp_streams),
			},
		};

		if ($scope.edit_fields.uuid != null){ // save existing org

			$scope.is_busy = true;

			$http.patch(`${jcs.api.url}/customers/${$scope.edit_fields.uuid}`, customer_data, { withCredentials: true }).then(response => {

				// we only need to update the fields that are displayed in the list view
				$scope.customer_to_edit.name = $scope.edit_fields.name;
				$scope.customer_to_edit.planName = $scope.getMultisitePlanNameForId($scope.edit_fields.plan);
				$scope.customer_to_edit.webPlanName = $scope.getWebPlanNameForId($scope.edit_fields.webplan);

				const temp_customer = $scope.customer_to_edit;

				$scope.customer_to_edit = null;
				$scope.edit_fields = null;
				$scope.has_error = {};
				$scope.addedit_customer_error = null;

				$scope.returnAfterEdit(temp_customer);

			}).catch(reason => {

				$scope.addedit_customer_error = `An error occurred while attempting to update the organization. Please try again, or report the problem if it persists. Status Code: ${reason.status} Status Text: ${reason.statusText}`;

			}).finally(() => {

				$scope.is_busy = false;

			});

		} else { // save new org

			$scope.is_busy = true;

			$http.post(`${jcs.api.url}/customers`, customer_data, { withCredentials: true }).then(response => {

				$scope.edit_fields = null;
				$scope.has_error = {};
				$scope.addedit_customer_error = null;

			}).catch(reason => {

				$scope.addedit_customer_error = `An error occurred while attempting to create the organization. Please try again, or report the problem if it persists. Status Code: ${reason.status} Status Text: ${reason.statusText}`;

			}).finally(() => {

				$scope.is_busy = false;

			});
		}
	};

	$scope.getMultisitePlanNameForId = function (uuid) {
		for (var i = 0; i < $scope.customer_plans.length; i++) {
			if (uuid == $scope.customer_plans[i].uuid) return $scope.customer_plans[i].name;
		}
		return '';
	};

	$scope.getWebPlanNameForId = function (uuid) {
		for (var i = 0; i < $scope.web_plans.length; i++) {
			if (uuid == $scope.web_plans[i].uuid) {
				return $scope.web_plans[i].name;
			}
		}
		return '';
	};

	$scope.isEmpty = function (value) {
		return value === null || value === '' || typeof value === 'undefined';
	};

	$scope.formatDateForDB = function (date_to_format) {
		if (date_to_format) {
			return (
				moment(date_to_format, $scope.DATE_FORMAT)
					.utc()
					.format($scope.DATE_FORMAT_ZULU) + 'Z'
			);
		} else if (date_to_format == '') {
			return null; // don't send blank to api, convert to null
		}
		return date_to_format;
	};

	$scope.deleteCustomer = function () {
		$scope.is_busy = true;

		// make http request to delete customer
		// NOTE: I normally would not include the "data: null", but IE11 and below seem to require it in order to work
		// see: https://github.com/angular/angular.js/issues/12141
		$http
			.delete(jcs.api.url + '/customers/' + $scope.customer_to_delete.uuid, { withCredentials: true, data: null })
			.then(
				function () {
					// success

					// remove customer from our customers list
					var index = $scope.customers.indexOf($scope.customer_to_delete);
					if (index > -1) {
						$scope.customers.splice(index, 1);
					}

					$scope.customer_to_delete = null;
					$scope.delete_customer_error = null;
				},
				function () {
					// error

					$scope.delete_customer_error =
						'An error occurred while attempting to delete the organization. Please try again, or report the problem if it persists.';
				}
			)
		['finally'](function () {
			// always called

			$scope.is_busy = false;
		});
	};

	$scope.loadManagedOrgs = function () {

		$scope.customer_manages_error = null;

		orgService.getManagedOrganizationsList($scope.customer_data.uuid).then(response => {

			$scope.customer_manages = [];
			for (let i=0; i < response.length; i++){
				const entry = response[i];
				if ($scope.customer_data.uuid != entry.uuid){
					$scope.customer_manages.push(entry);
				}
			}

		}).catch(reason => {

			$scope.customer_manages_error = 'An error occurred while attempting to load the managed organizations. Please try again, or report the problem if it persists.';

		});
	};

	// finds searches that the given search term starts with, and returns the longest one
	$scope.findPreviousSearch = function (search_term) {
		let match = null;
		for (const property in $scope.search_map){
			if ($scope.startsWith(search_term, property)){
				if (match === null || property.length > match.length){
					match = property;
				}
			}
		}
		return match === null ? null : $scope.search_map[match];
	};

	$scope.isQuickSearchLoading = function () {
		return $scope.fetch_search_results_timeout_promise !== null || $scope.is_loading_quick_search;
	};

	$scope.isMinQuickSearchReqMet = function () {
		return $scope.customer_filter !== null && $scope.customer_filter.length >= $scope.QUICK_SEARCH_MIN_CHAR_LENGTH;
	};

	$scope.performQuickSearch = function (current_filter){
		if ($scope.fetch_search_results_timeout_promise !== null){
			$timeout.cancel($scope.fetch_search_results_timeout_promise);
			$scope.fetch_search_results_timeout_promise = null;
		}

		$scope.fetch_search_results_timeout_promise = $timeout(function(){
			$scope.fetch_search_results_timeout_promise = null;

			$scope.customers_error_msg = null;
			$scope.is_loading_quick_search = true;

			orgService.getOrgSearchResults(current_filter).then(response => {

				$scope.search_map[current_filter] = response;
				// since we may have made an API call, and the user may have kept typing, only update the result list if the current search term
				// matches the search term used to fetch these results.
				if (current_filter === $scope.customer_filter){
					$scope.customers = response.results;
					$scope.prev_used_filter_total_pages = response.totalPages;
				}

			}).catch(reason => {

				$scope.customers_error_msg = 'Unable to perform organization search. Clear your search term and try again, or report the problem if it persists.';

			}).finally(() => {

				$scope.is_loading_quick_search = false;

			});

		}, $scope.QUICK_SEARCH_TIMER_DELAY);
	};

	// processes key press in search field (we allow the user to select a customer by using the up/down keys
	// and auto "switch" to the customer by pressing the enter key)
	$scope.processFilterKeyPress = function (event) {
		const current_filter = $scope.customer_filter;
		const maxValue = $scope.filtered_customers != null ? $scope.filtered_customers.length - 1 : 0;

		switch (event.key) {
			case 'Escape':
				$scope.customer_filter = '';
				break;
			case 'Down': // MS Edge uses this
			case 'ArrowDown':
				if ($scope.selectedRow < maxValue) $scope.selectedRow++;
				break;
			case 'Up': // MS Edge uses this
			case 'ArrowUp':
				if ($scope.selectedRow > 0) $scope.selectedRow--;
				break;
			case 'Enter':
				if ($scope.filtered_customers.length == 1) {
					$scope.enterSwitchToCustomerMode($scope.filtered_customers[0]);
				} else if ($scope.filtered_customers.length > 1) {
					if ($scope.selectedRow >= 0 && $scope.selectedRow < $scope.filtered_customers.length)
						$scope.enterSwitchToCustomerMode($scope.filtered_customers[$scope.selectedRow]);
				}
				break;
			default:
				// as filtered list changes while the user types, ensure selected row is valid
				if ($scope.selectedRow > maxValue){
					$scope.selectedRow = maxValue;
				}

				if (current_filter !== null && current_filter.length >= $scope.QUICK_SEARCH_MIN_CHAR_LENGTH){

					// do we have any previous searches that our current search starts with?
					const prev_search = $scope.findPreviousSearch(current_filter);
					if (prev_search !== null){
						// if we have a previous search that is an exact match, or returned only a single page of results, then just use those results (we don't need to hit the API again)
						if (prev_search.term === current_filter || prev_search.totalPages === 1){

							$scope.customers = prev_search.results;
							$scope.prev_used_filter_total_pages = prev_search.totalPages;
							$scope.is_loading_quick_search = false;

						} else {
							// if we had a previous search result that returned more than 2 pages of results, then search again, because we have a longer search term now
							$scope.performQuickSearch(current_filter);
						}

					} else {
						$scope.performQuickSearch(current_filter);
					}

				} else {
					$scope.customers = null;
					$scope.prev_used_filter_total_pages = 0;
					$scope.customers_error_msg = null;
					$scope.is_loading_quick_search = false;
				}
		}
	};

	$scope.customPlanSort = function (a, b) {
		// check for legacy plans, they should always come last
		if (a.name.includes($scope.LEGACY_PLAN) && !b.name.includes($scope.LEGACY_PLAN)){
			return 1;
		}
		if (!a.name.includes($scope.LEGACY_PLAN) && b.name.includes($scope.LEGACY_PLAN)){
			return -1;
		}
		// otherwise sort alphabetically
		if (a.name < b.name){
			return -1;
		}
		if (a.name > b.name){
			return 1;
		}
		return 0;
	}

	$scope.sortBy = function (columnName) {
		// if sorting by different column, then null reverse value of previous column
		if (columnName != $scope.columnToSortBy) {
			$scope.sortableColumns[$scope.columnToSortBy].reverse = null;
		}

		// sort in ascending order first time clicking on column; subsequent clicks will reverse the order
		if ($scope.sortableColumns[columnName].reverse == null) {
			$scope.sortableColumns[columnName].reverse = false;
		} else {
			$scope.sortableColumns[columnName].reverse = !$scope.sortableColumns[columnName].reverse;
		}

		// remember the column we are sorting by
		$scope.columnToSortBy = columnName;
	};

	$scope.customSortComparator = function (a, b) {
		var aval = a.value;
		var bval = b.value;

		if (aval === undefined || aval === null) {
			aval = '';
		}
		if (bval === undefined || bval === null) {
			bval = '';
		}

		if (aval > bval) {
			return 1;
		} else if (aval < bval) {
			return -1;
		}
		return 0;
	};

	// returns true if target starts with the searchTerm
	$scope.startsWith = function (target, searchTerm) {
		var front = target.substring(0, searchTerm.length);
		var same = front.toUpperCase() === searchTerm.toUpperCase();
		return same;
	};

	$scope.getCityStateZip = function (address) {
		var addressAsStr = '';

		if (address != null) {
			var hasCity = address.city != null && address.city.length > 0 ? true : false;
			var hasState = address.state != null && address.state.length > 0 ? true : false;

			// determine how to display city & state
			if (hasCity && hasState) {
				addressAsStr = address.city + ', ' + address.state;
			} else if (hasCity) {
				addressAsStr = address.city;
			} else if (hasState) {
				addressAsStr = address.state;
			}
			// add zip if it is available
			if (address.zip != null && address.zip.length > 0) {
				if (addressAsStr.length > 0) addressAsStr += ' ';
				addressAsStr += address.zip;
			}
		}

		return addressAsStr;
	};

	$scope.getStatusText = function (site) {
		return site.active ? 'Active' : 'Disabled';
	};

	$scope.getActiveAsText = function (active) {
		return active ? $scope.ACTIVE_YES : $scope.ACTIVE_NO;
	};

	$scope.getAlertAsText = function (alert) {
		return alert ? 'Yes' : 'No';
	};

	$scope.getWebsiteHref = function (href) {
		if (href == null) return '';
		return href.indexOf('http://') !== -1 || href.indexOf('https://') !== -1 ? href : 'http://' + href;
	};

	$scope.formatPhoneNumber = function (phone_number) {
		if (phone_number) {
			var numbersOnly = phone_number.replace(/\D/g, '');
			if (numbersOnly.length == 10) {
				var area_code = numbersOnly.substring(0, 3);
				var first_three = numbersOnly.substring(3, 6);
				var last_four = numbersOnly.substring(6, 10);
				return '(' + area_code + ') ' + first_three + '-' + last_four;
			} else if (numbersOnly.length == 7) {
				var first_three = numbersOnly.substring(0, 3);
				var last_four = numbersOnly.substring(3, 7);
				return first_three + '-' + last_four;
			}
		}

		// if phone number isn't provided, or isn't 7 or 10 digits long, then it isn't clear how we should format it,
		// so just return back whatever we were given
		return phone_number;
	};

	// returns the customer object with a matching UUID. Otherwise null is returned;
	$scope.findCustomer = function (customer_uuid) {
		for (var i = 0; i < $scope.customers.length; i++) {
			var customer = $scope.customers[i];
			if (customer.uuid == customer_uuid) return customer;
		}
		return null;
	};

	// since our pagination has to be initialized after we have declared our changePage method
	$scope.pagination = paginationService.init('name', paginationService.SORT_DIR_ASC, $scope.changePage);

	//
	// initialize by either loading the specified customer, or loading all customers
	//
	if (typeof $routeParams.switch_to !== 'undefined' && $routeParams.switch_to == 'switch') {
		// if a specific customer has been provided and also the "/switch" path is included, then we'll want
		// to go directly to the "switch to customer" page for the given customer.
		// example URL: http://localhost/livingasone/control/organizations/5add0669-ad1f-4ee7-96ec-b9b6c6337e66/switch
		$scope.enterSwitchToCustomerMode({ uuid: $routeParams.id });
	} else if (typeof $routeParams.id !== 'undefined') {
		// if a specific customer has been provided, then we'll want to go directly to the customer detail
		// page. Do this immediately after loading our customer list.
		// example URL: http://localhost/livingasone/control/organizations/5add0669-ad1f-4ee7-96ec-b9b6c6337e66
		$scope.enterViewCustomerMode({ uuid: $routeParams.id });
	} else {
		focus('filter-customer');
	}

	$scope.date_picker_options = {
		singleDatePicker: true,
		autoUpdateInput: false, // allows date field to be blank
		autoApply: true,
		locale: {
			format: $scope.DATE_FORMAT, //'ll' // <= looks like this is using moment.js formatting options
		},
	};
	// sets the current selected date of the datepicker; even if corresponding input field is empty, we may
	// want to set the datepicker to today's date.
	$scope.setMultisiteStartDatePicker = function (date_as_moment) {
		if (date_as_moment != null) {
			$('#multisite-start-date').data('daterangepicker').setStartDate(date_as_moment);
			$('#multisite-start-date').data('daterangepicker').setEndDate(date_as_moment);
		}
	};
	$scope.setWebStartDatePicker = function (date_as_moment) {
		if (date_as_moment != null) {
			$('#web-start-date').data('daterangepicker').setStartDate(date_as_moment);
			$('#web-start-date').data('daterangepicker').setEndDate(date_as_moment);
		}
	};
	$scope.setVODStartDatePicker = function (date_as_moment) {
		if (date_as_moment != null) {
			$('#vod-start-date').data('daterangepicker').setStartDate(date_as_moment);
			$('#vod-start-date').data('daterangepicker').setEndDate(date_as_moment);
		}
	};

	$scope.editMultisiteStartDatePickerCB = function (start, end, label) {
		$scope.edit_fields.multisite_start_date = moment(start).format($scope.DATE_FORMAT);
		$('#multisite-start-date').val($scope.edit_fields.multisite_start_date);
	};
	$scope.editWebStartDatePickerCB = function (start, end, label) {
		$scope.edit_fields.web_start_date = moment(start).format($scope.DATE_FORMAT);
		$('#web-start-date').val($scope.edit_fields.web_start_date);
	};
	$scope.editVODStartDatePickerCB = function (start, end, label) {
		$scope.edit_fields.vod_start_date = moment(start).format($scope.DATE_FORMAT);
		$('#vod-start-date').val($scope.edit_fields.vod_start_date);
	};

	$('#multisite-start-date').daterangepicker($scope.date_picker_options, $scope.editMultisiteStartDatePickerCB);
	$('#web-start-date').daterangepicker($scope.date_picker_options, $scope.editWebStartDatePickerCB);
	$('#vod-start-date').daterangepicker($scope.date_picker_options, $scope.editVODStartDatePickerCB);

	// build our tooltips
	$timeout(function () {
		$('[data-toggle="tooltip"]').tooltip(); // needs to be done in timeout, otherwise for some reason the tooltip gets built before angular does it's magic
	});
}

module.exports = OrganizationsController;
