import styled from '@emotion/styled';
import { css, SerializedStyles } from '@emotion/react';
import { customProperties as vars } from '@resi-media/resi-ui';
import { BRAND_NAVY_COLOR } from './event-analytics-constants';

export const NavTabDiv = styled.div`
  height: fit-content;
`;

export const EventAnalyticsWrapper = styled.div``;
export const AnalyticsTitleWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const AnalyticsTitleIcon = styled.div`
  display: flex;
  align-items: center;
  margin-right: ${vars.spacingS};
`;

export const BrandNavyColor = (isDisabled: boolean): SerializedStyles => css`
  opacity: ${isDisabled ? '0.4' : '1'};
  color: ${BRAND_NAVY_COLOR};
`;

export const BottomPadding = (value: string): SerializedStyles => css`
  padding-bottom: ${value};
`;

export const ExpandButtonWrapper = styled.div`
  margin: ${vars.spacingS} ${vars.spacingM};
`;

export const ErrorMessage = styled.div`
  padding-top: 10%;
  text-align: center;
  font-family: ${vars.fontFamily};
  font-size: 2rem;
`;

export const ExportCSVWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const LinkButtonContainer = styled.div`
  display: flex;
  color: ${BRAND_NAVY_COLOR};
  marginRight:'2px';
  width: 6rem;
  align-items: center;
`;