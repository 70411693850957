import React, { FC, useState } from 'react';
import { Cue } from '../../helpers/events/types';
import { trackMixpanelEvent, MPEventProperty, MPEventName } from '../../mixpanel';
import ArchiveWebEventModal from '../ArchiveWebEventModal';
import moment from 'moment';
import './style.scss';
import { theme, ThemeProvider, Tooltip } from '@resi-media/resi-ui';

interface ArchiveWebEventButtonProps {
  disableButton: boolean;
  auth: any;
  cues?: Cue[];
  webPlayer: any;
  webEventId?: string;
  webEventProfileId?: string;
  customerId?: string;
  webEventName?: string;
  startTime?: string;
  isWebEventLive?: boolean;
}

const ArchiveWebEventButton: FC<ArchiveWebEventButtonProps> = ({
  disableButton,
  auth,
  cues,
  webPlayer,
  webEventId,
  customerId,
  webEventProfileId,
  webEventName,
  startTime,
  isWebEventLive
}): JSX.Element => {
  const [isOpenArchiveWebEventModal, setIsOpenArchiveWebEventModal] = useState(false);
  const [displayTooltip, setDisplayTooltip] = useState(false);
  const [buttonHasBeenHovered, setButtonHasBeenHovered] = useState(false);
  const [hideTooltipTimeout, setHideTooltipTimeout] = useState<ReturnType<typeof setTimeout>>(null!);


  const handleArchiveWebEventBtnClick = (): void => {

    const duration = webPlayer.getDuration();
    const formattedDuration = moment.utc(duration * 1000).format('HH:mm:ss');
    const formattedStartDate = moment(startTime).format('MMM D, YYYY');

    trackMixpanelEvent(
      MPEventName.LIBRARY_SAVE_INITIATED, {
        [MPEventProperty.TITLE]: webEventName,
        [MPEventProperty.START_DATE]: formattedStartDate,
        [MPEventProperty.TRANSCODED_EVENT_UUID]: webEventId,
        [MPEventProperty.DURATION]: formattedDuration,
      }
    )

    if (webPlayer) {
      webPlayer.getVideo().pause();
    }
    setIsOpenArchiveWebEventModal(true);
  };

  const tooltipMsg = (
    <span>Unfortunately, the web event profile used<br/>for this web event is not currently compatible<br/>with the Library. <a target="_blank" rel="noreferrer" href='https://docs.resi.io/articles/#!resi-resources-setup-actions-and-behaviors-within-control/save-to-library-compatibility'>Learn more</a></span>
  );

  const onHover = () => {
    clearTimeout(hideTooltipTimeout);
    setDisplayTooltip(true);
    setButtonHasBeenHovered(true);
  };
  const onExitHover = () => {
    setHideTooltipTimeout(setTimeout(() => setDisplayTooltip(false), 250));
  };

  const getAnimationCssClassName = () => {
    let classname = "";
    if (buttonHasBeenHovered) {
      classname = displayTooltip ? "show-tooltip" : "hide-tooltip";
    }
    return classname;
  }

  return (
    <div>
      {disableButton ?
        <div className="resi-save-event-button-tooltip-parent" onMouseEnter={onHover} onMouseLeave={onExitHover} role="tooltip">
          <div className={`resi-save-event-button-tooltip ${getAnimationCssClassName()}`} role="tooltip">
            {tooltipMsg}
            <div className="resi-save-event-button-tooltip-arrow"></div>
          </div>
          <span className="btn btn-primary" disabled>
            <i className="fa fa-save" aria-hidden="true" style={{ marginRight: 7 }}></i>
            Save to Library
          </span>
        </div>
      :
        <button className="btn btn-primary" onClick={(): void => handleArchiveWebEventBtnClick()}>
          <i className="fa fa-save" aria-hidden="true" style={{ marginRight: 7 }}></i>
          Save to Library
        </button> 
      }
      {isOpenArchiveWebEventModal && (
        <ArchiveWebEventModal
          auth={auth}
          cues={cues}
          handleCloseModal={(): void => setIsOpenArchiveWebEventModal(false)}
          webEventId={webEventId}
          customerId={customerId}
          webEventProfileId={webEventProfileId}
          webEventName={webEventName}
          webEventDuration={webPlayer.getDuration()}
          isWebEventLive={isWebEventLive}
        />
      )}
    </div>
  );
};

ArchiveWebEventButton.displayName = 'ArchiveWebEventButton';

export default ArchiveWebEventButton;
