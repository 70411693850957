import React, { FC, useState } from 'react';
import { Cue } from '../../helpers/events/types';
import LibraryWatchModal from '../LibraryWatchModal';

interface LibraryWatchButtonProps {
  mediaId: string;
  embedUrl: string;
  mediaTitle: string;
}

const LibraryWatchButton: FC<LibraryWatchButtonProps> = ({
  mediaId,
  embedUrl,
  mediaTitle,
}): JSX.Element => {
  const [isWatchModalOpen, setIsWatchModalOpen] = useState(false);

  const handleWatchBtnClick = (): void => {
    setIsWatchModalOpen(true);
  };

  return (
    <>
      <button className="btn btn-default" onClick={(): void => handleWatchBtnClick()}>
        <i className="fa fa-tv" aria-hidden="true" style={{ marginRight: 7 }}></i>
        Watch
      </button>
      {isWatchModalOpen && (
        <LibraryWatchModal
          mediaId={mediaId}
          embedUrl={embedUrl}
          mediaTitle={mediaTitle} 
          handleCloseModal={(): void => setIsWatchModalOpen(false)}/>
      )}
    </>
  );
};

LibraryWatchButton.displayName = 'LibraryWatchButton';

export default LibraryWatchButton;
