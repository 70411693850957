import React, { FC } from 'react';
import PropTypes from 'prop-types';
import { FillBlockSpinner, StatusCard } from '../../../components';
import '../event-analytics.styles.ts';
import { StatsArray } from '../types';
import { Grid, customProperties as vars } from '@resi-media/resi-ui';
import AllDestinationsSegmentedStats from './AllDestinationsSegmentedStats/alldestinations-segmented-stats';
import AllDestinationsStatsGraph from './AllDestinationsStatsGraph/alldestinations-stats-graph';
import { LOADER_SIZE_COMPONENT } from '../event-analytics-constants';

export interface AllDestinationAnalyticsProps {
  statsArray: StatsArray;
  isCardLoading: boolean;
}

const AllDestinationsAnalyticsView: FC<AllDestinationAnalyticsProps> = ({ statsArray, isCardLoading }): JSX.Element => {
  return (
    <Grid display="grid" gridGap={vars.spacingXl} data-testid="alldestinations-analytics-page">
      <Grid.Item>
        <AllDestinationsStatsGraph />
      </Grid.Item>
      {isCardLoading ? (
        <FillBlockSpinner fontSize={LOADER_SIZE_COMPONENT} />
      ) : (
        <Grid display="grid" gridGap={vars.spacingXl} gridAutoColumns="1fr" gridTemplateColumns="repeat(4, 1fr)">
          {statsArray.map((item) => (
            <Grid.Item key={item.label}>
              <StatusCard
                label={item.label}
                change={item.change}
                count={item.count}
                isTrendUp={item.isTrendUp}
                data-testid="alldestinations-status-card"
              />
            </Grid.Item>
          ))}
        </Grid>
      )}
      <Grid.Item>
        <AllDestinationsSegmentedStats />
      </Grid.Item>
    </Grid>
  );
};

AllDestinationsAnalyticsView.propTypes = {
  statsArray: PropTypes.array.isRequired,
};

AllDestinationsAnalyticsView.displayName = 'AllDestinationsAnalyticsView';

export default AllDestinationsAnalyticsView;
