import React, { FC, useEffect, useState, ChangeEvent, useContext, useRef } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Text, Inline, customProperties as vars } from '@resi-media/resi-ui';
import Link from '@material-ui/core/Link';
import Tooltip from '@material-ui/core/Tooltip';
import Modal from '../../components/Modal';
import ModalHeader from '../../components/ModalHeader';
import ModalBody from '../../components/Modal';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { Paper } from '@material-ui/core';
import { DateTime } from 'luxon';
import { ExportOutlined } from '@ant-design/icons';
import VirtualizedTable from '../VirtualizedTable/virtualized-table';
import Pagination from '@material-ui/lab/Pagination';
import {
  EventsModalContent,
  EventsModalPagination,
  EventsModalTableContainer,
  EventStatsHeaderDiv,
  ViewEventsDiv,
  EventStatsBodyContent,
} from './view-events.styles';
import { DateControlsDiv } from '../../pages/EventAnalytics/EmbedPlayer/EmbedStatsGraph/embed-stats-graph.styles';
import { onRowClick, VirtualizedTableData } from '../VirtualizedTable/types';
import { styled, ThemeProvider } from '@material-ui/styles';
import { createTheme } from '@material-ui/core/styles';
import { ExportCSVWrapper, LinkButtonContainer } from '../../pages/EventAnalytics/event-analytics.styles';
import { SelectOption, HistoricalWebEventResponseObject, BracketReference } from '../../pages/EventAnalytics/types';
import { convertTableToCSV, downloadAsCSV } from '../../pages/EventAnalytics/utils';
import { fetchEventsData } from './api';
import { EventAnalyticsContext } from '../../contexts/eventanalytics/event-analytics';
import {
  BRAND_NAVY_COLOR,
  DESTINATION_TYPES,
  EVENT_ANALYTICS_CONTEXT_KEYS,
  EVENT_LIST_TABLE_PAGE_SIZE,
  EVENT_NAMES_EXPORT,
  FACEBOOK_DESTINATION_TYPES,
  FACEBOOK_PAGE_STATE_CONTEXT_KEYS,
  LOADER_SIZE_COMPONENT,
  LOADER_SIZE_SUB_COMPONENT,
  MAX_FETCH_LIMIT_FOR_HISTORICAL_EVENTS,
} from '../../pages/EventAnalytics/event-analytics-constants';
import { FillBlockSpinner } from '../FillBlockSpinner';
import { MPEventName, MPEventProperty, trackMixpanelEvent } from '../../mixpanel';
import getCachedEventNamesFetch from './utils';
import { FacebookContext } from '../../contexts/eventanalytics';
import startCase from 'lodash/startCase';

interface ViewEventsProps {
  dateRangeOptions: SelectOption[];
  dateRangeSelection: SelectOption;
  page: string;
  groupBy?: string;
}
export interface EventsDataAPIResponseType {
  date: string;
  eventId: string;
  destinationType?: string;
  views: number;
}
export interface EventsDataWithName {
  date: string;
  eventId: string;
  destinationType?: string;
  views: number | string;
  name: string;
}

const ViewEventsWrapper = styled('div')(({ theme }) => ({
  palette: {
    primary: {
      main: `${vars.colorPrimary}`,
    },
  },
}));

const useStyles = makeStyles(() => ({
  ul: {
    '& .MuiPaginationItem-root': {
      color: BRAND_NAVY_COLOR,
    },
  },
}));

const ViewEvents: FC<ViewEventsProps> = ({ page }) => {
  const { EventAnalyticsState, dispatch } = useContext(EventAnalyticsContext);
  const { dispatch: facebookDispatch } = useContext(FacebookContext);
  const { dateRange } = EventAnalyticsState;
  const [isEventsModalOpen, setEventsModalOpen] = useState<boolean>(false);
  const [isCSVExporting, setCSVExport] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [pageValue, setPageValue] = useState<number>(1);
  const [eventsData, setEventsData] = useState<EventsDataAPIResponseType[]>([]);
  const eventNameCache = useRef<BracketReference<HistoricalWebEventResponseObject | null>>({});
  const [tableData, setTableData] = useState<VirtualizedTableData>({
    data: [],
    header: [],
  });
  const defaultViewEventsHeader = [{ label: 'Air Date' }, { label: 'Event Name' }, { label: 'Views' }];

  const tablePageChange = (event: ChangeEvent<unknown>, value: number): void => {
    setPageValue(value);
  };
  const theme = createTheme();
  const classes = useStyles();
  const getMergedEventNamesToEventDataResponse = async (
    responseWithEventID: EventsDataAPIResponseType[]
  ): Promise<EventsDataWithName[]> => {
    const withEventIdAsKeyNameAsValue: { [key: string]: { [key: string]: string } } = {};
    const eventIdList: string[] = responseWithEventID.map(({ eventId }) => eventId);
    const { customerId } = EventAnalyticsState;
    const uniqueEventIds = Array.from(new Set(eventIdList));
    const { data: eventNameResponse } = await getCachedEventNamesFetch(
      uniqueEventIds,
      customerId,
      eventNameCache.current
    );
    eventNameResponse?.forEach((historicalWebEventObject: HistoricalWebEventResponseObject) => {
      const { webEventId, name, startRequestTime } = historicalWebEventObject;
      if (webEventId) {
        withEventIdAsKeyNameAsValue[webEventId] = {
          ...(name && { name }),
          ...(startRequestTime && { date: DateTime.fromISO(startRequestTime).toFormat(`yyyy-MM-dd HH:mm:ss`) }),
        };
      }
    });
    return responseWithEventID
      .map((element) => {
        let views: number | string = element.views;
        if (page === DESTINATION_TYPES.FACEBOOK) {
          if (
            [FACEBOOK_DESTINATION_TYPES.TIMELINE, FACEBOOK_DESTINATION_TYPES.GROUP].includes(
              element.destinationType as FACEBOOK_DESTINATION_TYPES
            )
          ) {
            views = element.views || 'N/A';
          }
        }
        return {
          ...element,
          views,
          name: withEventIdAsKeyNameAsValue[element.eventId]?.name,
          ...(withEventIdAsKeyNameAsValue[element.eventId]?.date && {
            date: withEventIdAsKeyNameAsValue[element.eventId].date,
          }),
        };
      })
      .sort((a, b) => new Date(b.date).valueOf() - new Date(a.date).valueOf());
  };

  const getEventsData = async (): Promise<void> => {
    const response = await fetchEventsData(EventAnalyticsState, page, dateRange);
    setEventsData(response);
  };

  const getViewEventsHeader = (): VirtualizedTableData['header'] => {
    switch (page) {
      case DESTINATION_TYPES.FACEBOOK:
        return [{ label: 'Air Date' }, { label: 'Event Name' }, { label: 'Destination Type' }, { label: 'Views' }];
      default:
        return defaultViewEventsHeader;
    }
  };

  const getExpectedViewEventsFields = (element: EventsDataWithName) => {
    switch (page) {
      case DESTINATION_TYPES.FACEBOOK:
        return [
          DateTime.fromSQL(element.date).toFormat(`yyyy-MM-dd HH:mm:ss`),
          element.name || `Event ID: ${element.eventId}`,
          startCase(element.destinationType || ''),
          element.views,
        ];
      default:
        return [
          DateTime.fromSQL(element.date).toFormat(`yyyy-MM-dd HH:mm:ss`),
          element.name || `Event ID: ${element.eventId}`,
          element.views,
        ];
    }
  };

  const updateTableData = async () => {
    const tableFormatedData: VirtualizedTableData = {
      header: getViewEventsHeader(),
      data: [],
    };

    setLoading(true);
    const eventNameMergedData = await getMergedEventNamesToEventDataResponse(
      eventsData.slice(EVENT_LIST_TABLE_PAGE_SIZE * (pageValue - 1), EVENT_LIST_TABLE_PAGE_SIZE * pageValue)
    );
    setLoading(false);
    eventNameMergedData?.map((element) => {
      tableFormatedData.data.push({
        payload: element,
        row: getExpectedViewEventsFields(element),
      });
    });
    setTableData(tableFormatedData);
  };

  useEffect(() => {
    if (isEventsModalOpen) {
      updateTableData();
    }
  }, [eventsData, pageValue]);

  useEffect(() => {
    if (isEventsModalOpen) {
      getEventsData();
    }
    setPageValue(1);
  }, [EventAnalyticsState, isEventsModalOpen]);

  const onEventRowClick: onRowClick = (rowData) => {
    const {
      payload: { eventId, destinationType },
    } = rowData;
    dispatch({ payload: eventId, type: EVENT_ANALYTICS_CONTEXT_KEYS.EVENT_ID });

    if (destinationType && page === DESTINATION_TYPES.FACEBOOK) {
      facebookDispatch({
        payload: destinationType.toLowerCase(),
        type: FACEBOOK_PAGE_STATE_CONTEXT_KEYS.DESTINATION_TYPE,
      });
    }
    setEventsModalOpen(false);
  };
  const setStateOnLinkButtonClick = () => {
    setEventsModalOpen(true);
  };

  const handleCSVDownload = async () => {
    setCSVExport(true);
    const tableFormatedData: VirtualizedTableData = {
      header: getViewEventsHeader(),
      data: [],
    };
    let startSlice = 0;
    const eventNameWithNameData: EventsDataWithName[] = [];
    for (
      let fetchCount = 1;
      fetchCount <= Math.ceil(eventsData?.length / MAX_FETCH_LIMIT_FOR_HISTORICAL_EVENTS);
      fetchCount++
    ) {
      const eventNameMergedData = await getMergedEventNamesToEventDataResponse(
        eventsData.slice(startSlice, MAX_FETCH_LIMIT_FOR_HISTORICAL_EVENTS * fetchCount)
      );
      startSlice = MAX_FETCH_LIMIT_FOR_HISTORICAL_EVENTS * fetchCount;
      eventNameMergedData.map((eachEventObj) => {
        eventNameWithNameData.push(eachEventObj);
      });
    }
    eventNameWithNameData?.map((element: EventsDataWithName) => {
      tableFormatedData.data.push({
        payload: element,
        row: getExpectedViewEventsFields(element),
      });
    });
    const tableDataCSV = convertTableToCSV(
      tableFormatedData.header,
      tableFormatedData.data.map((rowData) => rowData.row)
    );
    downloadAsCSV(tableDataCSV, `${EVENT_NAMES_EXPORT}.csv`);
    setCSVExport(false);

    let analyticsType = '';

    switch (page) {
      case 'embed':
        analyticsType = 'Embed Player';
        break;
      case 'stream_url':
        analyticsType = 'Stream URL';
        break;
      case 'all_destinations':
        analyticsType = 'All Destinations';
        break;
      case 'facebook':
        analyticsType = 'Facebook';
        break;
    }

    const mixpanelProps = {
      [MPEventProperty.TRANSCODED_EVENT_UUID]: EventAnalyticsState.eventId,
      [MPEventProperty.ANALYTICS_TYPE]: analyticsType,
    };

    if (!EventAnalyticsState.eventId) {
      delete mixpanelProps[MPEventProperty.TRANSCODED_EVENT_UUID];
    }

    trackMixpanelEvent(MPEventName.CSV_EXPORT, mixpanelProps);
  };

  return (
    <ThemeProvider theme={theme}>
      <ViewEventsWrapper>
        <ViewEventsDiv data-testid="view-event">
          <Link component="button" underline="none" variant="body2" onClick={() => setStateOnLinkButtonClick()}>
            <LinkButtonContainer>View Events</LinkButtonContainer>
          </Link>
        </ViewEventsDiv>
        {isEventsModalOpen && (
          <Modal>
            <ModalBody width="fit-content">
              <Paper elevation={0} variant="outlined" style={{ height: '44em' }}>
                <ModalHeader
                  closeButton
                  titleText={`Events in the last
                        ${Math.round(
                          Number(
                            DateTime.fromISO(dateRange.endDate)
                              .diff(DateTime.fromISO(dateRange.startDate), 'days')
                              .plus({ days: 1 })
                              .toObject().days
                          )
                        )}
                         Days`}
                  onClose={() => setEventsModalOpen(false)}
                />
                <EventsModalContent>
                  <EventStatsHeaderDiv>
                    <DateControlsDiv>
                      <div>
                        <Text.Body isUppercase isBold size="xs" color="secondary">
                          {`${DateTime.fromISO(dateRange?.startDate).toFormat('MMM dd yyyy')} - ${DateTime.fromISO(
                            dateRange?.endDate
                          ).toFormat('MMM dd yyyy')}`}
                        </Text.Body>
                      </div>
                    </DateControlsDiv>
                    {page !== DESTINATION_TYPES.YOUTUBE && (
                      <Inline justifyContent="flex-end" alignItems="center">
                        <ExportCSVWrapper>
                          <Link
                            component="button"
                            underline="none"
                            variant="body2"
                            disabled={isCSVExporting}
                            onClick={handleCSVDownload}
                            data-testid="view-events-exportCSV"
                          >
                            <Tooltip
                              title={
                                <h6>
                                  {isCSVExporting
                                    ? 'Please wait, your file is being prepared. '
                                    : 'Download all data as CSV'}
                                </h6>
                              }
                              arrow
                              placement="top"
                              style={{ zIndex: 5000 }}
                            >
                              <LinkButtonContainer>
                                <ExportOutlined />
                                <span style={{ marginLeft: '5px' }}>Export CSV</span>
                              </LinkButtonContainer>
                            </Tooltip>
                          </Link>

                          {isCSVExporting ? <FillBlockSpinner fontSize={LOADER_SIZE_SUB_COMPONENT} /> : null}
                        </ExportCSVWrapper>
                      </Inline>
                    )}
                  </EventStatsHeaderDiv>
                  <EventStatsBodyContent>
                    <EventsModalTableContainer>
                      {isLoading ? (
                        <FillBlockSpinner fontSize={LOADER_SIZE_COMPONENT} />
                      ) : (
                        <VirtualizedTable
                          onRowClick={onEventRowClick}
                          data={tableData}
                          data-testid="view-events-table"
                        />
                      )}
                    </EventsModalTableContainer>
                    <EventsModalPagination>
                      <Pagination
                        count={Math.ceil(eventsData?.length / EVENT_LIST_TABLE_PAGE_SIZE)}
                        defaultPage={1}
                        page={pageValue}
                        variant="outlined"
                        shape="rounded"
                        classes={{ ul: classes.ul }}
                        onChange={tablePageChange}
                        siblingCount={1}
                        data-testid="view-events-pagination"
                      />
                    </EventsModalPagination>
                  </EventStatsBodyContent>
                </EventsModalContent>
              </Paper>
            </ModalBody>
          </Modal>
        )}
      </ViewEventsWrapper>
    </ThemeProvider>
  );
};

ViewEvents.propTypes = {
  dateRangeOptions: PropTypes.arrayOf(
    PropTypes.exact({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.number.isRequired]).isRequired,
    }).isRequired
  ).isRequired,
  page: PropTypes.string.isRequired,
  groupBy: PropTypes.string,
};

ViewEvents.displayName = 'ViewEvents';

export default ViewEvents;
