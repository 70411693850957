// The api does not currently return a "stopping" status for web events. Until it does, the UI will attempt to keep track
// of what web events are stopped itself -- so we can show feedback to the user that a web event is being stopped. Once
// the API is updated to return a "stopping" state, then we can remove this service.
class WebEventsService {
  private list: string[] = [];

  public isStopping(uuid: string): boolean {
    return this.list.indexOf(uuid) != -1;
  }

  public add(uuid: string): void {
    this.list.push(uuid);
  }

  public remove(uuid: string): void {
    const index = this.list.indexOf(uuid);
    if (index > -1) {
      this.list.splice(index, 1);
    }
  }

  public getGoogleLogUrl (uuid: string): string {
    return `https://console.cloud.google.com/logs/query;query=resource.type%3D%22k8s_container%22%0AlogName%3D%22projects%2Flao-multisite%2Flogs%2Fstdout%22%0Alabels.streamId%3D%22${uuid}%22;timeRange=PT1H;summaryFields=resource%252Flabels%252Fnamespace_name:true?project=lao-multisite`
  }
}

export default WebEventsService;
