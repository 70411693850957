export const tabsStyles = {
  indicator: {
    color: '#627EA3',
    background: '#627EA3',
    width: '2px',
    height: '20px',
    left: '0px',
  },
};
export const tabItemStyles = {
  root: {
    minHeight: '20px',
    maxHeight: '25px',
    minWidth:'fit-content',
    display:'flex',
    justifyContent:'flex-start',
    fontFamily: 'Open Sans',
    fontSize: '14px',
    textTransform: 'capitalize',
    background: '#fff',
    color: '#595959',
    opacity: 1,
  },
  selected: {
    minHeight: '20px',
    maxHeight: '25px',
    fontWeight: 'bold',
    '& $wrapper': {
      opacity: 1,
      color: '#627EA3',
    },
  },
  wrapper: {
    display: 'inline-grid',
    opacity: 0.7,
    justifyContent: 'start',
    whiteSpace: 'nowrap',
  },
};

export const navTabsStyles = {
  indicator: {
    background: '#627EA3',
    height: '2px',
  },
};
export const navTabItemStyles = {
  root: {
    fontFamily: 'Open Sans',
    textTransform: 'capitalize',
    background: '#fff',
    color: '#595959',
    opacity: 1,
  },
  selected: {
    '& $wrapper': {
      opacity: 1,
    },
  },
  wrapper: {
    opacity: 0.7,
  },
};
