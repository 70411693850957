import { customProperties as vars } from '@resi-media/resi-ui';

export const tabsStyles = {
  root:{
    minHeight: '30px',
  },
  indicator: {
    display: 'none',
  },
};
export const tabItemStyles = {
  root: {
    minHeight: '15px',
    maxHeight: '26px',
    minWidth: 'fit-content', 
    fontFamily: 'Open Sans',
    fontSize: '0.75rem',
    textTransform: 'capitalize',
    border: '1px solid #e9e9e9',
    '&:not(:first-of-type)': {
      marginLeft: -1,
    },
    '&:first-of-type': {
      borderRadius: vars.borderRadius2 + ' 0 0 ' + vars.borderRadius2,
    },
    '&:last-of-type': {
      borderRadius: '0 ' + vars.borderRadius2 + ' ' + vars.borderRadius2 + ' 0',
    },
    background: '#fff',
    color: '#595959',
    opacity: 1,
  },
  selected: {
    background: '#627EA3',
    minHeight: '15px',
    maxHeight: '26px',
    '& $wrapper': {
      opacity: 1,
      color: vars.colorSurface,
    },
  },
  wrapper: {
    opacity: 0.7,
    lineHeight: 0,
  },
};

export const navTabsStyles = {
  root:{
    minHeight: '43px',
    height: '43px'
  },
  indicator: {
    background: '#627EA3',
    height: '2px',
  },
};
export const navTabItemStyles = {
  root: {
    minHeight: '43px',
    height: '43px',
    display: 'inline',
    fontFamily: 'Open Sans',
    textTransform: 'capitalize',
    color: '#595959',
    opacity: 1,
  },
  selected: {
    '& $wrapper': {
      opacity: 1,
      color: '#627EA3',
    },
  },
  wrapper: {
    opacity: 0.7,
    display:'inline-block',
    alignItems:'center',
  },
};
