import React, { FC, useContext, useEffect, useState } from 'react';
import { ResiLayout } from '..';
import * as Yup from 'yup';
import { LoadingOutlined } from '@ant-design/icons';
import { SubmitButtonWrap, ToolTipText } from './style';
import { BlockButton, FieldLabel, theme, ThemeProvider } from '@resi-media/resi-ui';
import GetStartedContext from '../../contexts/get-started';
import {
  createDestinationGroup,
  createEncoderEventProfile,
  createWebEventProfile,
  refreshCredentials,
  getFirstTimeSetup,
} from './api';
import { LatencyDetails } from '../../constants';
import { BoxHeader, BoxWrap, BoxDescription } from '../Styled';
import { Formik } from 'formik';
import DataCenterLatency from '../DataCenterLatency';

export const MAX_ACCEPTABLE_LATENCY = 150;

export interface NewUser {
  userId: string;
  canSetCues: boolean;
}

export interface NewEncoderEventProfilePayload {
  users: NewUser[];
  name: string;
  regionId: string;
}

export interface NewWebEventProfilePayload {
  description: string;
  name: string;
  regionId: string;
}

export interface NewDestinationGroupPayload {
  name: string;
  streamProfileId: string;
  webEventProfileId: string;
  enabled: boolean;
}

const EventProfileOnboarding: FC = (): JSX.Element => {
  const { state } = useContext(GetStartedContext);
  const [bestLatency, setBestLatency] = useState<LatencyDetails | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [passing, setPassing] = useState<boolean>(false);
  const [isTimedout, setTimedout] = useState<boolean>(false);
  const [isWebCustomer, setWebCustomer] = useState<boolean>(false);

  const EventProfileOnboardingSchema = Yup.object().shape({
    encoderName: Yup.string().max(50, 'Encoder Event Profile must have 50 characters or less'),
    webEventProfileName: Yup.string().max(46, 'Web Event Profile Name must have 46 characters or less'), //limit is 50, but backend adds 4 characters to ensure uniqueness
  });

  const initialValues = {
    encoderName: state.encoderName,
    webEventProfileName: state.webEventProfileName,
  };

  async function submit(): Promise<void> {
    try {
      if (bestLatency?.dataCenter.id) {
        const payload: NewEncoderEventProfilePayload = {
          users: [{ userId: state.user.userId, canSetCues: true }],
          name: state.encoderName,
          regionId: bestLatency.dataCenter.id,
        };
        const eventProfileResponse = await createEncoderEventProfile(payload);

        if (isWebCustomer) {
          const webEventProfilePayload: NewWebEventProfilePayload = {
            name: state.webEventProfileName,
            description: 'Web Event Profile',
            regionId: bestLatency.dataCenter.id,
          };
          const webEventProfileResponse = await createWebEventProfile(webEventProfilePayload, state.user.customerId);

          const destinationGroupPayload: NewDestinationGroupPayload = {
            name: 'Default Destination Group',
            streamProfileId: eventProfileResponse?.data.uuid,
            webEventProfileId: webEventProfileResponse?.data.uuid,
            enabled: true,
          };
          await createDestinationGroup(destinationGroupPayload, state.user.customerId);
        }
      }

      await refreshCredentials();
    } catch (error) {
      console.error(error);
    }
    localStorage.setItem('controlPrefs.showSetupMenu', 'true');
    window.location.assign('/encoders');
  }

  const onRegionFound = (latencyDetails: LatencyDetails | null): void => {
    setBestLatency(latencyDetails);
    setLoading(false);
  };

  const onRegionTimeout = (): void => {
    setBestLatency(null);
    setTimedout(true);
    setLoading(false);
  };

  // calculate passing
  useEffect(() => {
    (async () => {
      const firstTimeSetupResponse = await getFirstTimeSetup(state.user.customerId);
      if (firstTimeSetupResponse?.data && firstTimeSetupResponse.status === 200) {
        if (firstTimeSetupResponse.data?.hasWebPlan) {
          setWebCustomer(true);
        }
      } else {
        console.error(`unable to get first time setup: ${firstTimeSetupResponse}`);
      }
    })();

    if (!loading && bestLatency) {
      setPassing(bestLatency.duration <= MAX_ACCEPTABLE_LATENCY);
    }
  }, [passing, bestLatency, loading]);

  return (
    <ThemeProvider theme={theme}>
      <Formik validationSchema={EventProfileOnboardingSchema} initialValues={initialValues} onSubmit={submit}>
        {(formik): JSX.Element => {
          return (
            <ResiLayout>
              <BoxWrap>
                <BoxHeader>Locating Data Center</BoxHeader>
                <BoxDescription>
                  You’re almost done! Please wait while we determine the closest data center to your location. This will
                  help us configure your account for the best performance.
                </BoxDescription>
                <FieldLabel
                  title="Regional Data Center"
                  hint={
                    <ToolTipText>
                      Each Encoder Event Profile is tied to a region where content is stored. You must have a latency of
                      less than
                      {' ' + MAX_ACCEPTABLE_LATENCY}ms to the nearest region.
                    </ToolTipText>
                  }
                />
                <DataCenterLatency onRegionFound={onRegionFound} onTimeout={onRegionTimeout} />
                <SubmitButtonWrap>
                  <BlockButton
                    onClick={formik.handleSubmit}
                    isDisabled={!passing || loading || isTimedout}
                    isToggleButton={false}
                    label="Continue to Control"
                    iconLeft={formik.isSubmitting ? <LoadingOutlined /> : undefined}
                  />
                </SubmitButtonWrap>
              </BoxWrap>
            </ResiLayout>
          );
        }}
      </Formik>
    </ThemeProvider>
  );
};

EventProfileOnboarding.displayName = 'EventProfileOnboarding';

export default EventProfileOnboarding;
