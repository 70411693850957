'use strict';

const jcs = require('../../jcs');
const constants = require('../../constants');
const moment = require('moment');
const { trackMixpanelEvent, MPEventProperty, MPEventName} = require('../../../../src/mixpanel');

function EventProfilesController(
	$scope,
	$routeParams,
	$timeout,
	$http,
	$q,
	$window,
	focus,
	httpService,
	webPlayerService,
	webEventProfileService,
	formValidationService,
	eventService,
	regionService,
	Authentication,
	googleChartsService,
	embedCodeService,
	orgService
) {
	'ngInject';

	$(window).trigger('resize'); // ensure footer is properly positioned

	$scope.webplayerEnv = jcs.webplayerEnv;

	$scope.validation = formValidationService.init();

	$scope.webEventProfileSvc = webEventProfileService;
	$scope.regionSvc = regionService;

	$scope.PROFILE_NAME_MAX_LENGTH = 50;
	$scope.WEB_PROFILE_NAME_MAX_LENGTH = 46; // limit is 50, but backend adds 4 characters to ensure uniqueness
	$scope.DATE_TIME_FORMAT = 'lll'; // using moment.js formatting options
	$scope.DATE_FORMAT = 'YYYY-MM-DD';
	$scope.TERRABYTE_IN_BYTES = 1000000000000; // 1,000,000,000,000
	$scope.GIGABYTE_IN_BYTES = 1000000000; // 1,000,000,000
	$scope.TERRABYTE_IN_KB = 1000000000; // convert from KB to TB and back

	$scope.OPTION_YES = 'Yes';
	$scope.OPTION_NO = 'No';
	$scope.BOOLEAN_OPTIONS = [$scope.OPTION_YES, $scope.OPTION_NO];

	$scope.SUBTITLES_ENABLED = 'Enabled';
	$scope.SUBTITLES_DISABLED = 'Disabled';
	$scope.SUBTITLES_DEFAULT_OPTION = $scope.SUBTITLES_DISABLED;
	$scope.SUBTITLES_OPTIONS = [$scope.SUBTITLES_ENABLED, $scope.SUBTITLES_DISABLED];

	$scope.selected_embed_code_type = constants.EMBED_CODE_OPTIONS.DEFAULT;

	$scope.event_profiles = null;
	$scope.loading_event_profiles = false;
	$scope.event_profiles_error_msg = null;

	$scope.transcoder_profiles = null;
	$scope.loading_transcoder_profiles = false;
	$scope.transcoder_error_msg = null;

	$scope.is_loading = false;
	$scope.is_loading_bandwidth_usage = false;
	$scope.is_working = false; // activity indicator (spinner icon)
	$scope.is_fetching = false;
	$scope.error_msg = null;
	$scope.error_msg_evt_profile = null;

	$scope.delete_after_options_encoder_event = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 20, 30];
	$scope.delete_after_options_web_event = [];
	$scope.DELETE_AFTER_DEFAULT = 6;

	$scope.DELAY_SECONDS_DEFAULT = 90;
	$scope.DELAY_SECONDS_MAX = 600;
	$scope.DELAY_SECONDS_MIN = 90;

	$scope.delaySecondsSlider = {
		value: $scope.DELAY_SECONDS_DEFAULT,
		options: {
			step: 5,
			floor: $scope.DELAY_SECONDS_MIN,
			ceil: $scope.DELAY_SECONDS_MAX,
			hideLimitLabels: true,
			getPointerColor: () => { return '#3c8dbc' },
			translate: (value) => {
				return $scope.formatSecondsToMinutes(value);
			},
		},
	}

	$scope.formatSecondsToMinutes = (seconds) => {
		const numOfMinutes =  Math.floor(seconds / 60);
		const minutesVerbiage = numOfMinutes > 1 ? 'Minutes' : 'Minute';
		const numOfSeconds = seconds - numOfMinutes * 60;

		return `${numOfMinutes} ${minutesVerbiage} ${numOfSeconds} Seconds`;
	}

	$scope.multisite_region_options = null;

	$scope.LAN_ONLY_ENABLED = 'On';
	$scope.LAN_ONLY_DISABLED = 'Off';
	$scope.LAN_ONLY_DEFAULT_OPTION = $scope.LAN_ONLY_DISABLED;
	$scope.lan_only_options = [$scope.LAN_ONLY_ENABLED, $scope.LAN_ONLY_DISABLED];

	$scope.web_event_type_options = [webEventProfileService.TYPE_PUBLIC, webEventProfileService.TYPE_RETURN_FEED, webEventProfileService.TYPE_SOCIAL];

	$scope.DVR_OPTION_FULL = "Full Rewind";
	$scope.DVR_OPTION_LIMITED = "Limited Rewind";
	$scope.DVR_OPTION_NO = "No Rewind";
	$scope.DVR_OPTION_DEFAULT = $scope.DVR_OPTION_FULL;
//	$scope.dvr_options = [$scope.DVR_OPTION_FULL, $scope.DVR_OPTION_LIMITED, $scope.DVR_OPTION_NO]; // NOTE: also restore tooltip when "no rewind" option restored
	$scope.dvr_options = [$scope.DVR_OPTION_FULL, $scope.DVR_OPTION_LIMITED];

	$scope.DVR_DURATION_MIN = Authentication.getCurrentUser().hasToggle('dvrWindowLessThan5Min') ? 1 : 5;
	$scope.DVR_DURATION_MAX = 720;
	$scope.DVR_DURATION_DEFAULT = 5;

	// regions
	$scope.web_region_options = null;
	$scope.region_error_msg = null;

	// event profile view
	$scope.evt_profile_to_view = null;

	// event profile edit
	$scope.evt_profile_to_edit = null;
	$scope.evt_profile_to_edit_name = '';
	$scope.evt_profile_to_edit_delete_after = '';
	$scope.evt_profile_to_edit_lan_only = $scope.LAN_ONLY_DEFAULT_OPTION;

	// event profile create
	$scope.is_adding_evt_profile = false;
	$scope.is_busy_loading_evt_profile = false; // activity indicator
	$scope.evt_profile_to_add_name = '';
	$scope.evt_profile_to_add_delete_after = $scope.DELETE_AFTER_DEFAULT;
	$scope.evt_profile_to_add_lan_only = $scope.LAN_ONLY_DEFAULT_OPTION;
	$scope.evt_profile_to_add_region = null;
	$scope.customer_user_list = [];
	$scope.evt_profile_to_add_users = null;

	// event profile delete
	$scope.evt_profile_to_delete = null;

	// transcoder profile to view
	$scope.transcoder_to_view = null;

	// transcoder profile to watch
	$scope.transcoder_to_watch = null;
	$scope.WEB_PLAYER_WRAPPER_ID = 'web-profile-video-player-wrapper'; // needs to be unique across control
	$scope.web_player = null;

	// transcoder profile to create
	$scope.is_adding_transcoder = false;
	$scope.transcoder_to_add_name = '';
	$scope.transcoder_to_add_description = '';
	$scope.transcoder_to_add_type = '';
	$scope.transcoder_to_add_delete_after = $scope.DELETE_AFTER_DEFAULT;
	$scope.transcoder_to_add_chromecast_id = '';
	$scope.transcoder_to_add_region = null;
	$scope.transcoder_to_add_dvr = $scope.DVR_OPTION_DEFAULT;
	$scope.transcoder_to_add_dvr_minutes = $scope.DVR_DURATION_DEFAULT;
	$scope.transcoder_to_add_loadBalPrefix = '';
	$scope.transcoder_to_add_branded = $scope.OPTION_NO;
	$scope.transcoder_to_add_subtitlesEnabled = $scope.SUBTITLES_DEFAULT_OPTION;
	$scope.transcoder_to_add_delay_seconds = $scope.DELAY_SECONDS_DEFAULT;


	// transcoder profile edit
	$scope.transcoder_to_edit = null;
	$scope.transcoder_to_edit_name = '';
	$scope.transcoder_to_edit_delete_after = '';
	$scope.transcoder_to_edit_chromecast_id = '';
	$scope.transcoder_to_edit_dvr = $scope.DVR_OPTION_DEFAULT;
	$scope.transcoder_to_edit_dvr_minutes = $scope.DVR_DURATION_DEFAULT;
	$scope.transcoder_to_edit_description = '';
	$scope.transcoder_to_edit_enable_stream_urls = false;
	$scope.transcoder_to_edit_branded = false;
	$scope.transcoder_to_edit_subtitlesEnabled = $scope.SUBTITLES_DEFAULT_OPTION;
	$scope.transcoder_to_edit_delay_seconds = '';


	// transcoder profile delete
	$scope.web_evt_prof_to_delete = null;
	$scope.web_evt_prof_to_delete_warn_events = null;
	$scope.web_evt_prof_to_delete_warn_schedules = null;
	$scope.is_loading_delete_web_evt_prof = false;
	$scope.web_evt_prof_to_delete_error_msg = null;

	// web event profile - remove active content
	$scope.profile_remove_active = null;
	$scope.profile_remove_active_error = null;

	// web event to examine analytics
	$scope.is_analyzing_bandwidth_by_range = false;
	$scope.is_analyzing_bandwidth_by_month = false;
	$scope.web_events_to_analyze = []; // used for web event profile dropdown
	$scope.event_to_analyze = null;
	$scope.analytics_start_time = null;
	$scope.analytics_end_time = null;
	$scope.event_usage_stats = null;

	$scope.chart = null;
	$scope.chartDataTable = null;

	$scope.bandwidth_usage_by_month = null; // list of monthly usage
	$scope.bandwidth_usage_for_current_month_TB = null;
	$scope.bandwidth_usage_current_month_label = null;
	$scope.bandwidth_usage_prev_month_label = null;
	$scope.bandwidth_show_prev_month_as_pending = false;
	$scope.bandwidth_allowance = null;
	// TODO: do we want to change this to just use edit fields??
	$scope.save_usage_month = null;
	$scope.save_usage_year = null;
	$scope.save_usage_bandwidth = null;

	$scope.month_options = [
		{ value: 1, label: '01 - January' },
		{ value: 2, label: '02 - February' },
		{ value: 3, label: '03 - March' },
		{ value: 4, label: '04 - April' },
		{ value: 5, label: '05 - May' },
		{ value: 6, label: '06 - June' },
		{ value: 7, label: '07 - July' },
		{ value: 8, label: '08 - August' },
		{ value: 9, label: '09 - September' },
		{ value: 10, label: '10 - October' },
		{ value: 11, label: '11 - November' },
		{ value: 12, label: '12 - December' },
	];

  $scope.usage_summary = {
    subtitlesHoursPerMonth : 0,
  };

	$scope.getCurrentUser = function () {
		return Authentication.getCurrentUser();
	};

	$scope.canShowAddProfileBtn = function () {
		return Authentication.getCurrentUser().hasPerm('event_profiles.add');
	};

	$scope.canShowUserAccessBtn = function () {
		return Authentication.getCurrentUser().hasPerm('event_profiles.users.get');
	};

	$scope.canShowEditBtn = function () {
		return Authentication.getCurrentUser().hasPerm('event_profiles.update');
	};

	$scope.canShowDeleteBtn = function () {
		return Authentication.getCurrentUser().hasPerm('event_profiles.delete');
	};

	$scope.canShowWebProfileList = function () {
		return Authentication.getCurrentUser().hasPerm('web_event_profiles.get');
	};

	$scope.canShowBandwidthUsageBtn = function () {
		return Authentication.getCurrentUser().hasPerm('web_event_profiles.bandwidth_usage');
	};

	$scope.canShowEditWebBtn = function () {
		return Authentication.getCurrentUser().hasPerm('web_event_profiles.update');
	};

	$scope.canShowDeleteWebBtn = function () {
		return Authentication.getCurrentUser().hasPerm('web_event_profiles.delete');
	};

	$scope.canShowBucketInfo = function () {
		return Authentication.getCurrentUser().hasPerm('event_profiles.view_bucket');
	};

	$scope.canShowWebEventProfileDelay = function () {
		return Authentication.getCurrentUser().hasToggle('webEventProfileDelay');
	};

  // can show the Subtitles feature UI (controled by LaunchDarkly + Control)
	$scope.canShowSubtitlesOptions = function () {
    if($scope.usage_summary.subtitlesHoursPerMonth > 0) {
      return true;
    }
    else if (Authentication.$rootScope.ldClient) {
			return Authentication.$rootScope.ldClient.allFlags()['automated-subtitles'];
		}
		return false;
	};

	// can show bandwidth admin related elements (la1 only)
	$scope.canShowBandwidthAdmin = function () {
		return Authentication.getCurrentUser().hasPerm('web_event_profiles.bandwidth_cost');
	};

	$scope.canShowInternalAddWebProfileBtn = function () {
		return Authentication.getCurrentUser().hasPerm('web_event_profiles.add') &&
			Authentication.getCurrentUser().hasPerm('la1only');
	};

	$scope.canShowCustomerAddWebProfileBtn = function () {
		return Authentication.getCurrentUser().hasPerm('web_event_profiles.add') &&
			!Authentication.getCurrentUser().hasPerm('la1only');
	};

	$scope.canShowStreamUrls = function () {
		return Authentication.getCurrentUser().hasPerm('web_event_profiles.update_persistent_urls');
	};

	$scope.getEmbedCode = function (transcoder) {
		if ($scope.selected_embed_code_type === constants.EMBED_CODE_OPTIONS.STANDARD_IFRAME){
			return embedCodeService.getStandardIframeEmbedCode(transcoder?.uuid, $scope.webplayerEnv.queryParam);
		}
		if ($scope.selected_embed_code_type === constants.EMBED_CODE_OPTIONS.ALTERNATIVE_IFRAME){
			return embedCodeService.getAlternativeIframeEmbedCode(transcoder?.uuid, $scope.webplayerEnv.queryParam);
		}
		// old style embed code
		return `<div id="resi-video-player" data-embed-id="${transcoder?.uuid}" ${$scope.webplayerEnv.attribute}></div>
<script type="application/javascript" src="https://control.resi.io/webplayer/loader.min.js"></script>`;
	};

	$scope.formatBandwidthTB = function (size_in_bytes) {
		var resized = size_in_bytes / $scope.TERRABYTE_IN_BYTES;
		// always show two decimal places, unless first digit after decimal is a zero, then show two significant digits
		var options = resized <= 0.09 ? { maximumSignificantDigits: 2 } : { minimumFractionDigits: 2, maximumFractionDigits: 2 };
		// see: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Number/toLocaleString
		return resized.toLocaleString(undefined, options);
	};

	$scope.formatBandwidthGB = function (size_in_bytes) {
		var resized = size_in_bytes / $scope.GIGABYTE_IN_BYTES;
		// always show two decimal places, unless first digit after decimal is a zero, then show two significant digits
		var options = resized <= 0.09 ? { maximumSignificantDigits: 2 } : { minimumFractionDigits: 2, maximumFractionDigits: 2 };
		// see: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Number/toLocaleString
		return resized.toLocaleString(undefined, options) + ' GB';
	};

	$scope.formatAllowancePercentage = function (usage_in_TB) {
		if ($scope.bandwidth_allowance == null) {
			return '';
		}

		// determine what percent of our allowance has been used
		var percent = (usage_in_TB / $scope.bandwidth_allowance) * 100;
		// always show one decimal places, unless first digit after decimal is a zero, then show one significant digit
		var options = percent <= 0.09 ? { maximumSignificantDigits: 1 } : { minimumFractionDigits: 1, maximumFractionDigits: 1 };
		// see: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Number/toLocaleString
		return percent.toLocaleString(undefined, options) + '%';
	};

	$scope.getPercentUsedClass = function (usage_in_TB) {
		if ($scope.bandwidth_allowance == null) {
			return '';
		}

		var percent = (usage_in_TB / $scope.bandwidth_allowance) * 100;

		if (percent <= 80) return 'status-block-low';
		if (percent <= 100) return 'status-block-high';
		return 'status-block-urgent';
	};

	$scope.getMultisiteRegionName = function (region_uuid) {
		if ($scope.multisite_region_options !== null) {
			for (const region of $scope.multisite_region_options) {
				if (region.uuid === region_uuid) {
					return region.name;
				}
			}
		}
		return 'Not Available';
	}

	$scope.getWebRegionName = function (region_uuid) {
		if ($scope.web_region_options !== null) {
			for (const region of $scope.web_region_options) {
				if (region.uuid === region_uuid) {
					return region.name;
				}
			}
		}
		return '';
	};

	$scope.getRegionUuid = function (region_name) {
		if ($scope.web_region_options !== null) {
			for (const region of $scope.web_region_options) {
				if (region.name === region_name) {
					return region.uuid;
				}
			}
		}
		return null;
	};

	$scope.isDvrFullRewind = function (dvr_window_minutes) {
		return dvr_window_minutes == null;
	};

	$scope.isDvrNoRewind = function (dvr_window_minutes) {
		return dvr_window_minutes == 0;
	};

	$scope.getFormattedLanOnly = function (lan_only) {
		return lan_only ? $scope.LAN_ONLY_ENABLED : $scope.LAN_ONLY_DISABLED;
	};

	$scope.getFormattedDvrWindow = function (dvr_window_minutes) {
		if ($scope.isDvrFullRewind(dvr_window_minutes)){
			return $scope.DVR_OPTION_FULL;
		}
		if ($scope.isDvrNoRewind(dvr_window_minutes)){
			return $scope.DVR_OPTION_NO;
		}
		let minutes_text = dvr_window_minutes == 1 ? 'minute' : 'minutes';
		return `${$scope.DVR_OPTION_LIMITED} (${dvr_window_minutes} ${minutes_text})`;
	};

	$scope.enterViewTranscoderMode = function (transcoder) {

		$scope.transcoder_to_view = transcoder;
		// init DVR fields
		transcoder.dvrWindowMinutes = transcoder.dvrWindowSeconds == null ? null : transcoder.dvrWindowSeconds / 60;
		$scope.transcoder_to_view.dvrWindowFormatted = $scope.getFormattedDvrWindow(transcoder.dvrWindowMinutes);

	};

	$scope.enterWatchTranscoderMode = function (transcoder) {
		$scope.transcoder_to_watch = transcoder;
		webPlayerService.initializeByEmbedCode($scope.WEB_PLAYER_WRAPPER_ID, transcoder.uuid, function (webplayer, error) {
			$scope.web_player = webplayer;
			if (webplayer == null) {
				// since this callback function will be called from outside Angular, we need to call the apply function to ensure the UI
				// gets updated. see: http://jimhoskins.com/2012/12/17/angularjs-and-apply.html
				$scope.$apply(function () {
					$scope.error_msg = error;
				});
			}
		});
	};

	$scope.enterBandwidthUsageByRangeMode = function () {
		$scope.error_msg = null;
		$scope.web_events_to_analyze = [];

		if ($scope.transcoder_profiles.length == 1) {
			// if there is only 1 profile, then auto select it
			$scope.event_to_analyze = $scope.transcoder_profiles[0].uuid;
		} else {
			// only include an "All" option if we have multiple web event profiles
			$scope.web_events_to_analyze.push({
				name: 'All',
				uuid: null,
			});
			$scope.event_to_analyze = null;
		}
		// add web event profiles to dropdown list
		for (var i = 0; i < $scope.transcoder_profiles.length; i++) {
			$scope.web_events_to_analyze.push($scope.transcoder_profiles[i]);
		}

		$scope.is_analyzing_bandwidth_by_month = false;
		$scope.is_analyzing_bandwidth_by_range = true;
	};

	$scope.haveBandwidthDataPrevMonth = function (number_of_months_back) {
		if ($scope.bandwidth_usage_by_month != null) {
			var month_to_check = moment().subtract(number_of_months_back, 'month').format('YYYY-MM');

			for (var i = 0; i < $scope.bandwidth_usage_by_month.length; i++) {
				if ($scope.bandwidth_usage_by_month[i].date.indexOf(month_to_check) === 0) return true;
			}
		}
		return false;
	};

	$scope.enterBandwidthUsageByMonthMode = function () {
		$scope.is_analyzing_bandwidth_by_range = false;
		$scope.is_analyzing_bandwidth_by_month = true;

		$scope.is_loading_bandwidth_usage = true;

		var outer_promises = [];
		// get monthly bandwidth usage
		outer_promises.push($http.get(`${jcs.api.url}/datausage/${Authentication.getCurrentUser().customerID}/range`, {withCredentials: true}));
		// get bandwidth allowance for this customer (currently the API url we are using is for LA1 users only)
		if (Authentication.getCurrentUser().hasPerm('web_event_profiles.bandwidth_cost')) {
			outer_promises.push($http.get(`${jcs.api.url}/customers/${Authentication.getCurrentUser().customerID}`, {withCredentials: true}));
		}

		$q.all(outer_promises).then(
			function (outer_response) {
				if (Authentication.getCurrentUser().hasPerm('web_event_profiles.bandwidth_cost')) {
					$scope.bandwidth_allowance = outer_response[1].data.webBandwidth;
				}

				$scope.updateBandwidthUsageList(outer_response[0].data);

				if ($scope.bandwidth_usage_current_month_label === null){
					$scope.bandwidth_usage_current_month_label = moment().format('MMMM');
				}
				if ($scope.bandwidth_usage_prev_month_label === null){
					$scope.bandwidth_usage_prev_month_label = moment().subtract(1, 'month').format('MMMM');
				}

				// if usage for current month hasn't been already loaded, then load it
				// NOTE: commenting out the current month bandwidth calculation until the dust settles with the cloudflare stuff
/*
				if ($scope.bandwidth_usage_for_current_month_TB == null) {
					$scope.bandwidth_usage_current_month_label = moment().format('MMMM');
					$scope.bandwidth_usage_prev_month_label = moment().subtract(1, 'month').format('MMMM');

					// build query string (for all of last month)
					var startDateTime = moment().startOf('month');
					var query_string = 'start=' + encodeURIComponent(startDateTime.toISOString());

					// build our list of http requests
					var promises = [];
					for (var i = 0; i < $scope.transcoder_profiles.length; i++) {
						var profile = $scope.transcoder_profiles[i];
						promises.push($http.get(jcs.api.url_v3 + '/customers/' + Authentication.getCurrentUser().customerID + '/webeventprofiles/' + profile.uuid + '/bandwidth?' + query_string, { withCredentials: true }));
					}

					$q.all(promises).then(
						function (response) {
							$scope.error_msg = null;

							var total_bytes = 0;
							for (var i = 0; i < response.length; i++) {
								total_bytes += response[i].data.totalBytes;
							}

							$scope.bandwidth_usage_for_current_month_TB = $scope.formatBandwidthTB(total_bytes);
						},
						function (reason) { // error

							if (!httpService.isStatus406(reason)) {
								$scope.error_msg = 'An error occurred while attempting to retrieve the bandwidth usage for this month. Please try again, or report the problem if it persists.';
							}

						}).finally(function () { // always called

							$scope.is_loading_bandwidth_usage = false;
						});
				}
*/
			},
			function (reason) { // error

				if (!httpService.isStatus406(reason)) {
					$scope.error_msg = 'An error occurred while attempting to retrieve the current bandwidth usage. Please try again, or report the problem if it persists.';
				}
//				$scope.is_loading_bandwidth_usage = false;

			}).finally(function () { // always called

//				if ($scope.bandwidth_usage_for_current_month_TB != null) {
					$scope.is_loading_bandwidth_usage = false;
//				}
			});
	};

	$scope.fetchUsageForPriorMonth = function () {
		$scope.is_fetching = true;

		// build query string (for all of last month)
		var startDateTime = moment().subtract(1, 'month').startOf('month');
		var endDateTime = moment().subtract(1, 'month').endOf('month');
		var query_string = 'start=' + encodeURIComponent(startDateTime.toISOString());
		query_string += '&end=' + encodeURIComponent(endDateTime.toISOString());

		// build our list of http requests
		var promises = [];
		for (var i = 0; i < $scope.transcoder_profiles.length; i++) {
			var profile = $scope.transcoder_profiles[i];
			promises.push($http.get(jcs.api.url_v3 + '/customers/' + Authentication.getCurrentUser().customerID + '/webeventprofiles/' + profile.uuid + '/bandwidth?' + query_string, { withCredentials: true }));
		}

		$q.all(promises).then(
			function (response) {
				$scope.error_msg = null;

				var total_bytes = 0;
				for (var i = 0; i < response.length; i++) {
					total_bytes += response[i].data.totalBytes;
				}

				$scope.save_usage_month = parseInt(startDateTime.format('M'));
				$scope.save_usage_year = startDateTime.format('YYYY');
				// TODO: get bandwidth without 'TB' unit ... or remove it.
				$scope.save_usage_bandwidth = $scope.formatBandwidthTB(total_bytes);
			},
			function (reason) { // error

				if (!httpService.isStatus406(reason)) {
					$scope.error_msg = 'An error occurred while attempting to retrieve the bandwidth usage. Please try again, or report the problem if it persists.';
				}
				$scope.event_usage_stats = null;

			}).finally(function () { // always called

				$scope.is_fetching = false;
			});
	};

	$scope.enterEditTranscoderMode = function (transcoder) {

		$scope.validation.clear();

		$scope.transcoder_to_view = null; // needs to be cleared b/c there is an edit btn on the "view" page

		$scope.transcoder_to_edit = transcoder;

		$scope.transcoder_to_edit_name = transcoder.name;
		$scope.transcoder_to_edit_description = transcoder.description;
		$scope.transcoder_to_edit_delete_after = transcoder.deleteAfter;
		$scope.transcoder_to_edit_chromecast_id = transcoder.chromeCastAppId;
		$scope.transcoder_to_edit_enable_stream_urls = transcoder.persistentUrls.dashUrlEnabled || transcoder.persistentUrls.hlsUrlEnabled;
		$scope.transcoder_to_edit_branded = transcoder.branded ? $scope.OPTION_YES : $scope.OPTION_NO;
		$scope.transcoder_to_edit_subtitlesEnabled = transcoder.subtitlesEnabled ? $scope.SUBTITLES_ENABLED : $scope.SUBTITLES_DISABLED;
		$scope.delaySecondsSlider.value = transcoder.delaySeconds

		// init DVR fields
		transcoder.dvrWindowMinutes = transcoder.dvrWindowSeconds == null ? null : transcoder.dvrWindowSeconds / 60;
		if ($scope.isDvrFullRewind(transcoder.dvrWindowMinutes)){
			$scope.transcoder_to_edit_dvr = $scope.DVR_OPTION_FULL;
			$scope.transcoder_to_edit_dvr_minutes = $scope.DVR_DURATION_DEFAULT;
		} else if ($scope.isDvrNoRewind(transcoder.dvrWindowMinutes)) {
			$scope.transcoder_to_edit_dvr = $scope.DVR_OPTION_NO;
			$scope.transcoder_to_edit_dvr_minutes = $scope.DVR_DURATION_DEFAULT;
		} else {
			$scope.transcoder_to_edit_dvr = $scope.DVR_OPTION_LIMITED;
			$scope.transcoder_to_edit_dvr_minutes = transcoder.dvrWindowMinutes;
		}

		focus('edit-transcoder-input');
	};

	$scope.enterViewEvtProfileMode = function (profile) {

		$scope.evt_profile_to_view = profile;

		if ($scope.multisite_region_options === null){
			regionService.getMultisiteRegions().then(response => {

				$scope.multisite_region_options = response;

			}).catch(reason => {

				$scope.region_error_msg = 'An error occurred while attempting to load information for this profile. Please try again, or report the problem if it persists.';

			});
		}
	};

	$scope.enterEditEvtProfileMode = function (profile) {
		$scope.validation.clear();
		$scope.evt_profile_to_view = null;
		$scope.evt_profile_to_edit = profile;

		$scope.evt_profile_to_edit_name = profile.name;
		$scope.evt_profile_to_edit_delete_after = profile.deleteAfter;
		$scope.evt_profile_to_edit_lan_only = profile.lanOnly ? $scope.LAN_ONLY_ENABLED : $scope.LAN_ONLY_DISABLED;

		// see app.js for where focus is defined
		focus('edit-profile-input');
	};

	$scope.enterAddTranscoderMode = function () {
		$scope.validation.clear();

		$scope.is_adding_transcoder = true;
		$scope.transcoder_to_add_name = '';
		$scope.transcoder_to_add_description = '';
		$scope.transcoder_to_add_type = '';
		$scope.transcoder_to_add_delete_after = $scope.DELETE_AFTER_DEFAULT;
		$scope.transcoder_to_add_chromecast_id = '';
		$scope.transcoder_to_add_region = null;
		$scope.transcoder_to_add_dvr = $scope.DVR_OPTION_DEFAULT;
		$scope.transcoder_to_add_dvr_minutes = $scope.DVR_DURATION_DEFAULT;
		$scope.transcoder_to_add_loadBalPrefix = '';
		$scope.transcoder_to_add_branded = $scope.OPTION_NO;
		$scope.transcoder_to_add_subtitlesEnabled = $scope.SUBTITLES_DEFAULT_OPTION;
		$scope.transcoder_to_add_delay_seconds = $scope.DELAY_SECONDS_DEFAULT;

		// see app.js for where focus is defined
		focus('add-transcoder-input');
	};

	$scope.enterAddEvtProfileMode = function () {
		$scope.validation.clear();
		$scope.error_msg_evt_profile = null;
		$scope.error_msg = null;

		$scope.is_adding_evt_profile = true;
		$scope.evt_profile_to_add_name = '';
		$scope.evt_profile_to_add_delete_after = $scope.DELETE_AFTER_DEFAULT;
		$scope.evt_profile_to_add_lan_only = $scope.LAN_ONLY_DEFAULT_OPTION;
		$scope.evt_profile_to_add_region = null;

		$scope.is_busy_loading_evt_profile = true;

		const promises = [];
		promises.push($http.get(`${jcs.api.url}/users`, { withCredentials: true }));
		promises.push(regionService.getMultisiteRegions());

		$q.all(promises).then(([user_response, region_response]) => {

			$scope.customer_user_list = user_response.data;

			// intialize all customer users to be "selected"
			$scope.evt_profile_to_add_users = { selected: {} };
			for (const user of $scope.customer_user_list) {
				const uuid = user.uuid;
				$scope.evt_profile_to_add_users.selected[uuid] = uuid;
			}

			$scope.multisite_region_options = region_response;

		}).catch(reason => {

			$scope.error_msg = 'Unable to retrieve information needed to create a profile. Please try again, or report the problem if it persists.';
			$scope.customer_user_list = null;

		}).finally(() => {

			$scope.is_busy_loading_evt_profile = false;
			// see app.js for where focus is defined
			focus('add-profile-input');

		});
	};

	$scope.enterDeleteEvtProfileMode = function (profile) {
		$scope.evt_profile_to_delete = profile;

		if ($scope.multisite_region_options === null){
			regionService.getMultisiteRegions().then(response => {

				$scope.multisite_region_options = response;

			}).catch(reason => {

				$scope.region_error_msg = 'An error occurred while attempting to load information for this profile. Please try again, or report the problem if it persists.';

			});
		}
	};

	$scope.enterRemoveActiveContentMode = function (profile) {
		$scope.profile_remove_active = profile;
		$scope.profile_remove_active_error = null;
		$('#web-event-profile-remove-active-content').modal('show');
	};

	$scope.removeActiveContent = function () {

		$scope.profile_remove_active_error = null;
		$scope.is_working = true;

		const mixpanel_data = {
			[MPEventProperty.WEB_EVENT_PROFILE_UUID]: $scope.profile_remove_active.uuid,
			[MPEventProperty.WEB_EVENT_PROFILE_NAME]: $scope.profile_remove_active.name,
		};

		$http.post(`${jcs.api.url_v3}/customers/${Authentication.getCurrentUser().customerID}/webeventprofiles/${$scope.profile_remove_active.uuid}/clear`, {}, { withCredentials: true }).then(response => {

			$scope.profile_remove_active.active = null;
			$('#web-event-profile-remove-active-content').modal('hide');

			trackMixpanelEvent(MPEventName.ACTIVE_CONTENT_REMOVE, mixpanel_data);

		}).catch(reason => {

			// For now, if the backend returns a 400 we will treat it as "profile in use". But need to have a long term solution for the backend returning more specific error codes or something.
			if (reason.status === 400){
				$scope.profile_remove_active_error = 'This web event profile is currently in use. Active content cannot be removed until the current event is over.';
			} else {
				$scope.profile_remove_active_error = 'An error occurred while attempting to remove the active content. Please try again, or report the problem if it persists.';
			}

		}).finally(() => {

			$scope.is_working = false;

		});
	};

	$scope.enterDeleteTranscoderMode = function (profile) {

		$scope.web_evt_prof_to_delete = profile;
		// init DVR fields
		profile.dvrWindowMinutes = profile.dvrWindowSeconds == null ? null : profile.dvrWindowSeconds / 60;
		$scope.web_evt_prof_to_delete.dvrWindowFormatted = $scope.getFormattedDvrWindow(profile.dvrWindowMinutes);

		$scope.web_evt_prof_to_delete_warn_events = [];
		$scope.web_evt_prof_to_delete_warn_schedules = [];
		$scope.web_evt_prof_to_delete_error_msg = null;

		var promises = [];
		promises.push(eventService.loadWebEvents());
		promises.push($http.get(`${jcs.api.url_v3}/customers/${Authentication.getCurrentUser().customerID}/webeventschedules?webEventProfileId=${$scope.web_evt_prof_to_delete.uuid}`, { withCredentials: true }));

		$scope.is_loading_delete_web_evt_prof = true;

		$q.all(promises).then(responses => {

			// find matching web events
			for (let web_event of responses[0]){
				if (web_event.webEventProfileId == $scope.web_evt_prof_to_delete.uuid){
					$scope.web_evt_prof_to_delete_warn_events.push(web_event);
				}
			}
			// process web schedules
			if (responses[1].data.length > 0){
				$scope.web_evt_prof_to_delete_warn_schedules = responses[1].data;
				for (let schedule of $scope.web_evt_prof_to_delete_warn_schedules){
					schedule.sortableDate = `${schedule.scheduleStartDay} ${schedule.localStartTime} ${schedule.timeZone}`;
				}
			}

		}).catch(reason => {

			$scope.web_evt_prof_to_delete_error_msg = 'There was an error trying to determine if this web event profile is being used by any web events or web schedules (which will also be deleted). Please report the problem if it persists.';

		}).finally(() => {

			$scope.is_loading_delete_web_evt_prof = false;
		});
	};

	$scope.cancelViewEvtProfile = function () {
		$scope.evt_profile_to_view = null;
	};

	$scope.cancelViewTranscoder = function () {
		$scope.transcoder_to_view = null;
	};

	$scope.cancelWatchTranscoder = function () {
		$scope.transcoder_to_watch = null;
		$scope.error_msg = null;
		if ($scope.web_player != null) {
			$scope.web_player.unload();
		}
	};

	$scope.cancelEventBandwidth = function () {
		$scope.is_analyzing_bandwidth_by_range = false;
		$scope.is_analyzing_bandwidth_by_month = false;
		$scope.event_to_analyze = null;
		$scope.error_msg = null;
	};

	$scope.cancelEditTranscoder = function () {
		$scope.transcoder_to_edit = null;
		$scope.error_msg = null;
		$scope.validation.clear();
	};

	$scope.cancelEdit = function () {
		$scope.evt_profile_to_edit = null;
		$scope.error_msg = null;
		$scope.validation.clear();
	};

	$scope.cancelAdd = function () {
		$scope.is_adding_evt_profile = false;
		$scope.error_msg = null;
	};

	$scope.cancelAddTranscoder = function () {
		$scope.is_adding_transcoder = false;
		$scope.error_msg = null;
		$scope.validation.clear();
	};

	$scope.cancelDeleteTranscoder = function () {
		$scope.web_evt_prof_to_delete = null;
		$scope.web_evt_prof_to_delete_warn_events = null;
		$scope.web_evt_prof_to_delete_warn_schedules = null;
		$scope.web_evt_prof_to_delete_error_msg = null;
		$scope.error_msg = null;
	};

	$scope.cancelDelete = function () {
		$scope.evt_profile_to_delete = null;
		$scope.error_msg = null;
	};

	$scope.getWebEventProfileName = function (uuid) {
		for (var i = 0; i < $scope.transcoder_profiles.length; i++) {
			var profile = $scope.transcoder_profiles[i];
			if (profile.uuid === uuid) return profile.name;
		}
		return '';
	};

	$scope.getEventBandwidth = function () {
		$scope.is_working = true;

		// build query string
		var query_string = '';
		if ($scope.analytics_start_time != '') {
			var formatted_start_time = moment($scope.analytics_start_time, $scope.DATE_TIME_FORMAT).toISOString();
			query_string += 'start=' + encodeURIComponent(formatted_start_time);
		}
		if ($scope.analytics_end_time != '') {
			if (query_string != '') {
				query_string += '&';
			}
			var formatted_end_time = moment($scope.analytics_end_time, $scope.DATE_TIME_FORMAT).toISOString();
			query_string += 'end=' + encodeURIComponent(formatted_end_time);
		}
		if (Authentication.getCurrentUser().hasPerm('web_event_profiles.bandwidth_cost')) {
			if (query_string != '') {
				query_string += '&';
			}
			query_string += 'cost=true';
		}

		// build our list of http requests
		var promises = [];
		if ($scope.event_to_analyze == null) {
			for (var i = 0; i < $scope.transcoder_profiles.length; i++) {
				var profile = $scope.transcoder_profiles[i];
				promises.push(
					$http.get(jcs.api.url_v3 + '/customers/' + Authentication.getCurrentUser().customerID + '/webeventprofiles/' + profile.uuid + '/bandwidth?' + query_string, { withCredentials: true })
				);
			}
		} else {
			promises.push(
				$http.get(jcs.api.url_v3 + '/customers/' + Authentication.getCurrentUser().customerID + '/webeventprofiles/' + $scope.event_to_analyze + '/bandwidth?' + query_string, { withCredentials: true })
			);
		}

		$q.all(promises)
			.then(
				function (response) {
					$scope.error_msg = null;
					$scope.event_usage_stats = response;

					$scope.prepareChart();
					// if we draw the chart immediately, the DIV size won't be correct (maybe b/c it isn't visible yet?), so give a slight delay
					window.setTimeout(function () {
						$scope.drawChart();
					}, 250);

					//                    console.log(response);
					//                    for (var x=0; x < response[0].data.timeSeries.length; x++){
					//                        var item = response[0].data.timeSeries[x];
					//                        console.log("ENTRY: Country=" + item.metric.labels.client_country + "  Cache Result=" + item.metric.labels.cache_result + "  Protocol=" + item.metric.labels.protocol +
					//                         "  Response Code/Class=" + item.metric.labels.response_code + '/' + item.metric.labels.response_code_class + "  Backend Type=" + item.resource.labels.backend_type +
					//                         "  Points=" + item.points.length);
					//                    }

					if (promises.length > 1) {
						var totalBytes = 0;
						var totalCost = 0;
						// assign profile name to each set of usage data
						for (var i = 0; i < $scope.transcoder_profiles.length; i++) {
							$scope.event_usage_stats[i].data.name = $scope.transcoder_profiles[i].name;

							totalBytes += $scope.event_usage_stats[i].data.totalBytes;
							if (Authentication.getCurrentUser().hasPerm('web_event_profiles.bandwidth_cost')) {
								totalCost += $scope.event_usage_stats[i].data.totalCost;
							}
						}
						// since we have more than 1 result set, add a totals row
						$scope.event_usage_stats.push({
							data: {
								name: 'TOTAL',
								totalBytes: totalBytes,
								totalCost: totalCost,
							},
						});
					} else {
						// assign profile name
						$scope.event_usage_stats[0].data.name = $scope.getWebEventProfileName($scope.event_to_analyze);
					}
				},
				function (reason) {
					// error

					if (!httpService.isStatus406(reason)) {
						$scope.error_msg =
							'An error occurred while attempting to retrieve the bandwidth usage. Please try again, or report the problem if it persists.';
					}
					$scope.event_usage_stats = null;
				}
			)
			.finally(function () {
				// always called

				$scope.is_working = false;
			});
	};

	$scope.doesSaveEditTranscoderFailValidation = function (errors){

		$scope.error_msg = null;
		$scope.validation.clear();

		// ensure required fields are not empty
		$scope.validation.checkForEmpty('name', $scope.transcoder_to_edit_name);
		if ($scope.transcoder_to_edit_dvr == $scope.DVR_OPTION_LIMITED){
			$scope.validation.checkForEmpty('dvr_minutes', $scope.transcoder_to_edit_dvr_minutes);
		}

		var has_validation_error = $scope.validation.hasError();
		if (has_validation_error) {
			$scope.error_msg = 'Please specify a value for the highlighted fields below.';
		}

		// if no "required field" errors, then ...
		if (!has_validation_error) {
			// only allow letters, numbers, spaces, underscores, and dashes for profile name
			var name_restricted_chars = /[^a-zA-Z0-9\-\_ ]+/g;
			if (name_restricted_chars.test($scope.transcoder_to_edit_name)) {
				has_validation_error = true;
				$scope.validation.setError('name');
				$scope.error_msg = 'The "Profile Name" contains some invalid characters. The name may only contain letters, numbers, spaces, underscores, and dashes.';
			}
		}
		// special check if DVR "Limited Rewind" option is selected
		if ($scope.transcoder_to_edit_dvr == $scope.DVR_OPTION_LIMITED) {
			let before_parsing = $scope.transcoder_to_edit_dvr_minutes;
			if (!has_validation_error) {
				$scope.transcoder_to_edit_dvr_minutes = parseInt($scope.transcoder_to_edit_dvr_minutes);
				// if "limited rewind" selected for DVR Availability Window, then require minutes value in proper range
				if ($scope.transcoder_to_edit_dvr_minutes < $scope.DVR_DURATION_MIN || $scope.transcoder_to_edit_dvr_minutes > $scope.DVR_DURATION_MAX){
					has_validation_error = true;
					$scope.validation.setError('dvr_minutes');
					$scope.error_msg = `Please enter a DVR Availability Window duration between ${$scope.DVR_DURATION_MIN} and ${$scope.DVR_DURATION_MAX} minutes.`;
				}
			}
			if (!has_validation_error) {
				// if "limited rewind" selected for DVR Availability Window, then require minutes value in proper range
				$scope.transcoder_to_edit_dvr_minutes = parseInt($scope.transcoder_to_edit_dvr_minutes);
				if (Number.isNaN($scope.transcoder_to_edit_dvr_minutes)){
					$scope.transcoder_to_edit_dvr_minutes = before_parsing; // restore the value to the input field so user can see why it caused an error (otherwise the parsing may clear non integer characters)
					has_validation_error = true;
					$scope.validation.setError('dvr_minutes');
					$scope.error_msg = 'Please enter a valid number in minutes for the DVR Availability Window duration';
				} else if ($scope.transcoder_to_edit_dvr_minutes < $scope.DVR_DURATION_MIN || $scope.transcoder_to_edit_dvr_minutes > $scope.DVR_DURATION_MAX){
					$scope.transcoder_to_edit_dvr_minutes = before_parsing; // restore the value to the input field so user can see why it caused an error (otherwise the parsing may clear non integer characters)
					has_validation_error = true;
					$scope.validation.setError('dvr_minutes');
					$scope.error_msg = `Please enter a DVR Availability Window duration between ${$scope.DVR_DURATION_MIN} and ${$scope.DVR_DURATION_MAX} minutes.`;
				}
			}
		}

		return has_validation_error;
	};

	$scope.saveEditTranscoder = function () {

		// if we have form validation errors, then don't go any further
		if ($scope.doesSaveEditTranscoderFailValidation()){
			return false;
		}

		const updated_data = {
			name: $scope.transcoder_to_edit_name,
			deleteAfter: $scope.transcoder_to_edit_delete_after,
			description: $scope.transcoder_to_edit_description,
			chromeCastAppId: $scope.transcoder_to_edit_chromecast_id,
			dvrWindowSeconds: $scope.getDvrSecondsForApi($scope.transcoder_to_edit_dvr, $scope.transcoder_to_edit_dvr_minutes),
			delaySeconds:  $scope.delaySecondsSlider.value
		};
		if (Authentication.getCurrentUser().hasPerm('la1only')){
			updated_data.branded = $scope.transcoder_to_edit_branded === $scope.OPTION_YES;
		}

		if ($scope.canShowSubtitlesOptions()) {
			updated_data.subtitlesEnabled = $scope.transcoder_to_edit_subtitlesEnabled === $scope.SUBTITLES_ENABLED;
		}

		if (Authentication.getCurrentUser().hasPerm('web_event_profiles.update_persistent_urls') && $scope.canShowStreamUrls()) {
			updated_data.persistentUrls = {
				dashUrlEnabled: $scope.transcoder_to_edit_enable_stream_urls,
				hlsUrlEnabled: $scope.transcoder_to_edit_enable_stream_urls,
			};
		}

		$scope.is_working = true;

		const mixpanel_data = $scope.getMixpanelDataForWebEvtProfile(updated_data, $scope.transcoder_to_edit.uuid);

		// update transcoder event profile
		httpService.patch(`${jcs.api.url_v3}/customers/${Authentication.getCurrentUser().customerID}/webeventprofiles/${$scope.transcoder_to_edit.uuid}`, updated_data, { withCredentials: true },
			function () { // success

				$scope.transcoder_to_edit = null;
				$scope.error_msg = null;

				// reload our profile lists (since dash or hls urls may have been enabled, and we don't know what those will be)
				$scope.load();

				trackMixpanelEvent(MPEventName.WEB_EVENT_PROFILE_EDIT, mixpanel_data);
			},
			function () { // error

				$scope.error_msg = 'An error occurred while attempting to update the web event profile. Please try again, or report the problem if it persists.';
			},
			function () { // always called

				$scope.is_working = false;
			}
		);
	};

	// returns TRUE if we have a form validation issue that the user needs to address
	$scope.doesEditEventProfileFailValidation = function () {
		$scope.error_msg = null;
		$scope.validation.clear();

		// ensure required fields are not empty
		$scope.validation.checkForEmpty('evt_profile_to_edit_name', $scope.evt_profile_to_edit_name);
		$scope.validation.checkForEmpty('evt_profile_to_edit_delete_after', $scope.evt_profile_to_edit_delete_after);

		var has_validation_error = $scope.validation.hasError();
		if (has_validation_error) {
			$scope.error_msg = 'Please specify a value for the highlighted fields.';
		}

		return has_validation_error;
	};

	$scope.saveEdit = function () {
		// if we have form validation errors, then don't go any further
		if ($scope.doesEditEventProfileFailValidation()){
			return false;
		}

		var updated_data = {
			name: $scope.evt_profile_to_edit_name,
			deleteAfter: $scope.evt_profile_to_edit_delete_after,
		};
		if (Authentication.getCurrentUser().hasPerm('event_profiles.lan_only')) {
			updated_data.lanOnly = $scope.evt_profile_to_edit_lan_only == $scope.LAN_ONLY_ENABLED ? true : false;
		}

		$scope.is_working = true;

		const mixpanel_data = $scope.getMixpanelDataForEncoderEvtProfile(updated_data, $scope.evt_profile_to_edit.uuid);

		// update event profile
		$http.patch(jcs.api.url + '/streamprofiles/' + $scope.evt_profile_to_edit.uuid, updated_data, { withCredentials: true }).then(
			function () { // success

				// update was successful, so update our actual event profile in the model
				$scope.evt_profile_to_edit.name = $scope.evt_profile_to_edit_name;
				$scope.evt_profile_to_edit.deleteAfter = $scope.evt_profile_to_edit_delete_after;
				$scope.evt_profile_to_edit.lanOnly = $scope.evt_profile_to_edit_lan_only == $scope.LAN_ONLY_ENABLED ? true : false;

				$scope.evt_profile_to_edit = null;
				$scope.error_msg = null;

				trackMixpanelEvent(MPEventName.ENCODER_EVENT_PROFILE_EDIT, mixpanel_data);
			},
			function () { // error

				$scope.error_msg = 'An error occurred while attempting to update the event profile. Please try again, or report the problem if it persists.';

			})['finally'](function () { // always called

				$scope.is_working = false;
			});
	};

	$scope.onTypeChange = function (){
		// return feeds or social types shouldn't have a load balancer, so clear that field if type set to 'Return Feed'
		if ($scope.transcoder_to_add_type == webEventProfileService.TYPE_RETURN_FEED.value || $scope.transcoder_to_add_type == webEventProfileService.TYPE_SOCIAL.value){
			$scope.transcoder_to_add_loadBalPrefix = '';
			$scope.validation.clearError('load_balancer_prefix');
			$scope.transcoder_to_add_subtitlesEnabled = $scope.SUBTITLES_DEFAULT_OPTION;
		}
	};

	$scope.isEmpty = function (value) {
		return value == null || value == '';
	};

	$scope.doesSaveNewTranscoderFailValidation = function (errors) {

		$scope.error_msg = null;
		$scope.validation.clear();

		// ensure required fields are not empty
		$scope.validation.checkForEmpty('name', $scope.transcoder_to_add_name);
		$scope.validation.checkForEmpty('type', $scope.transcoder_to_add_type);
		$scope.validation.checkForEmpty('region', $scope.transcoder_to_add_region);
		if ($scope.transcoder_to_add_type == webEventProfileService.TYPE_PUBLIC.value){
			$scope.validation.checkForEmpty('load_balancer_prefix', $scope.transcoder_to_add_loadBalPrefix);
		}
		if ($scope.transcoder_to_add_dvr == $scope.DVR_OPTION_LIMITED){
			$scope.validation.checkForEmpty('dvr_minutes', $scope.transcoder_to_add_dvr_minutes);
		}

		var has_validation_error = $scope.validation.hasError();
		if (has_validation_error) {
			$scope.error_msg = 'Please specify a value for the highlighted fields below.';
		}

		// if no "required field" errors, then ...
		if (!has_validation_error) {
			// only allow letters, numbers, spaces, underscores, and dashes for profile name
			var name_restricted_chars = /[^a-zA-Z0-9\-\_ ]+/g;
			if (name_restricted_chars.test($scope.transcoder_to_add_name)) {
				has_validation_error = true;
				$scope.validation.setError('name');
				$scope.error_msg = 'The "Profile Name" contains some invalid characters. The name may only contain letters, numbers, spaces, underscores, and dashes.';
			}
		}
		if (!has_validation_error) {
			// only allow letters, numbers, and dashes in the load balancer prefix
			var restricted_chars = /[^a-zA-Z0-9\-]+/g;
			if (restricted_chars.test($scope.transcoder_to_add_loadBalPrefix)) {
				has_validation_error = true;
				$scope.validation.setError('load_balancer_prefix');
				$scope.error_msg = 'The "Load Balancer Prefix" contains some invalid characters. The prefix may only contain letters, numbers, and dashes.';
			}
		}
		if (!has_validation_error && $scope.transcoder_to_add_dvr == $scope.DVR_OPTION_LIMITED) {
			// if "limited rewind" selected for DVR Availability Window, then require minutes value in proper range
			let before_parsing = $scope.transcoder_to_add_dvr_minutes;
			$scope.transcoder_to_add_dvr_minutes = parseInt($scope.transcoder_to_add_dvr_minutes);
			if (Number.isNaN($scope.transcoder_to_add_dvr_minutes)){
				$scope.transcoder_to_add_dvr_minutes = before_parsing; // restore the value to the input field so user can see why it caused an error (otherwise the parsing may clear non integer characters)
				has_validation_error = true;
				$scope.validation.setError('dvr_minutes');
				$scope.error_msg = 'Please enter a valid number in minutes for the DVR Availability Window duration';
			} else if ($scope.transcoder_to_add_dvr_minutes < $scope.DVR_DURATION_MIN || $scope.transcoder_to_add_dvr_minutes > $scope.DVR_DURATION_MAX){
				$scope.transcoder_to_add_dvr_minutes = before_parsing; // restore the value to the input field so user can see why it caused an error (otherwise the parsing may clear non integer characters)
				has_validation_error = true;
				$scope.validation.setError('dvr_minutes');
				$scope.error_msg = `Please enter a DVR Availability Window duration between ${$scope.DVR_DURATION_MIN} and ${$scope.DVR_DURATION_MAX} minutes.`;
			}
		}

		return has_validation_error;
	};

	$scope.getDvrSecondsForApi = function (dvr_option, dvr_minutes) {

		if (dvr_option == $scope.DVR_OPTION_FULL){
			return null;
		}
		if (dvr_option == $scope.DVR_OPTION_NO){
			return 0;
		}
		return dvr_minutes * 60;
	};

	$scope.onCopyToClipboard = function (type, profile){
		trackMixpanelEvent(MPEventName.COPY_TO_CLIPBOARD, {
			[MPEventProperty.WEB_EVENT_PROFILE_UUID]: profile.uuid,
			[MPEventProperty.WEB_EVENT_PROFILE_NAME]: profile.name,
			[MPEventProperty.COPY_TYPE]: type,
		});
	};

	$scope.getMixpanelDataForWebEvtProfile = function (profile_data, uuid) {
		const mixpanel_data = {
			[MPEventProperty.WEB_EVENT_PROFILE_UUID]: uuid,
			[MPEventProperty.WEB_EVENT_PROFILE_NAME]: profile_data.name,
			[MPEventProperty.DELETE_AFTER]: profile_data.deleteAfter,
			[MPEventProperty.CHROMECAST_APP_ID]: profile_data.chromeCastAppId,
			[MPEventProperty.WEB_EVENT_DELAY]: profile_data.delaySeconds,
			[MPEventProperty.SHOW_LOGO]: profile_data.branded,
			[MPEventProperty.STREAM_URLS]: profile_data.persistentUrls?.dashUrlEnabled || profile_data.persistentUrls?.hlsUrlEnabled,
		};

		if (profile_data.hasOwnProperty('regionId')){
			mixpanel_data[MPEventProperty.REGION] = $scope.getWebRegionName(profile_data.regionId);
		}
		if (profile_data.hasOwnProperty('type')){
			mixpanel_data[MPEventProperty.TYPE] = profile_data.type;
		}
		if (profile_data.hasOwnProperty('subtitlesEnabled')) { // TODO: remove when $scope.canShowSubtitlesOptions() is no longer needed
			mixpanel_data[MPEventProperty.SUBTITLES_ENABLED] = profile_data.subtitlesEnabled;
		}

		return mixpanel_data;
	};

	$scope.saveNewTranscoder = function () {

		$scope.validation.clear();

		// if we have form validation errors, then don't go any further
		if ($scope.doesSaveNewTranscoderFailValidation()){
			return false;
		}

		const new_profile_data = {
			name: $scope.transcoder_to_add_name,
			description: $scope.transcoder_to_add_description,
			regionId: $scope.transcoder_to_add_region,
			loadBalPrefix: $scope.transcoder_to_add_loadBalPrefix,
			deleteAfter: $scope.transcoder_to_add_delete_after,
			type: $scope.transcoder_to_add_type,
			dvrWindowSeconds: $scope.getDvrSecondsForApi($scope.transcoder_to_add_dvr, $scope.transcoder_to_add_dvr_minutes),
			// if no chromecast ID was specified, then send null, which will make the API use our default
			chromeCastAppId: $scope.isEmpty($scope.transcoder_to_add_chromecast_id) ? null : $scope.transcoder_to_add_chromecast_id,
			delaySeconds: $scope.delaySecondsSlider.value,
		};
		if (Authentication.getCurrentUser().hasPerm('la1only')){
			new_profile_data.branded = $scope.transcoder_to_add_branded === $scope.OPTION_YES;
		}

		if ($scope.canShowSubtitlesOptions()) {
			new_profile_data.subtitlesEnabled = $scope.transcoder_to_add_subtitlesEnabled === $scope.SUBTITLES_ENABLED;
		}

		$scope.is_working = true;

		const mixpanel_data = $scope.getMixpanelDataForWebEvtProfile(new_profile_data);

		// create transcoder profile
		httpService.post(`${jcs.api.url_v3}/customers/${Authentication.getCurrentUser().customerID}/webeventprofiles`, new_profile_data, { withCredentials: true },
			function () { // success

				$scope.is_adding_transcoder = false;

				$scope.error_msg = null;

				$scope.load();

				trackMixpanelEvent(MPEventName.WEB_EVENT_PROFILE_ADD, mixpanel_data);
			},
			function (reason) { // error

				$scope.error_msg = {
					message: 'An error occurred while attempting to create the web event profile. Please try again, or report the problem if it persists.',
					reason: reason
				};

			},
			function () { // always called

				$scope.is_working = false;
			});
	};

	// returns TRUE if we have a form validation issue that the user needs to address
	$scope.doesNewEventProfileFailValidation = function () {
		$scope.error_msg_evt_profile = null;
		$scope.validation.clear();

		// ensure required fields are not empty
		$scope.validation.checkForEmpty('evt_profile_to_add_name', $scope.evt_profile_to_add_name);
		$scope.validation.checkForEmpty('evt_profile_to_add_delete_after', $scope.evt_profile_to_add_delete_after);
		$scope.validation.checkForEmpty('evt_profile_to_add_region', $scope.evt_profile_to_add_region);

		var has_validation_error = $scope.validation.hasError();
		if (has_validation_error) {
			$scope.error_msg_evt_profile = 'Please specify a value for the highlighted fields.';
		} else if (/[^a-zA-Z0-9\-\_ ]+/g.test($scope.evt_profile_to_add_name)) {
			has_validation_error = true;
			$scope.validation.setError('name');
			$scope.error_msg_evt_profile = 'The "Profile Name" contains some invalid characters. The name may only contain letters, numbers, spaces, underscores, and dashes.';
		}

		return has_validation_error;
	};

	$scope.getMixpanelDataForEncoderEvtProfile = function (profile_data, uuid) {
		const mixpanel_data = {
			[MPEventProperty.ENCODER_EVENT_PROFILE_NAME]: profile_data.name,
			[MPEventProperty.DELETE_AFTER]: profile_data.deleteAfter,
		};
		if (uuid){
			mixpanel_data[MPEventProperty.ENCODER_EVENT_PROFILE_UUID] = uuid;
		}
		if (profile_data.hasOwnProperty('regionId')){
			mixpanel_data[MPEventProperty.REGION] = $scope.getMultisiteRegionName(profile_data.regionId);
		}
		if (profile_data.hasOwnProperty('lanOnly')){
			mixpanel_data[MPEventProperty.LAN_MODE] = profile_data.lanOnly;
		}
		return mixpanel_data;
	};

	$scope.saveNewEvtProfile = function () {
		// if we have form validation errors, then don't go any further
		if ($scope.doesNewEventProfileFailValidation()){
			return false;
		}

		var new_profile_data = {
			name: $scope.evt_profile_to_add_name,
			deleteAfter: $scope.evt_profile_to_add_delete_after,
			regionId: $scope.evt_profile_to_add_region,
		};
		if (Authentication.getCurrentUser().hasPerm('event_profiles.lan_only')) {
			new_profile_data.lanOnly = $scope.evt_profile_to_add_lan_only == $scope.LAN_ONLY_ENABLED ? true : false;
		}

		// determine what users should have access to this event profile (iterate thru user list and add selected users to array)
		var selected_users = [];
		for (var i = 0; i < $scope.customer_user_list.length; i++) {
			var uuid = $scope.customer_user_list[i].uuid;
			if (uuid in $scope.evt_profile_to_add_users.selected && $scope.evt_profile_to_add_users.selected[uuid] != '')
				selected_users.push({ userId: uuid, canSetCues: true });
		}
		new_profile_data.users = selected_users;

		$scope.is_working = true;

		const mixpanel_data = $scope.getMixpanelDataForEncoderEvtProfile(new_profile_data);

		// create event profile
		$http.post(jcs.api.url + '/streamprofiles/', new_profile_data, { withCredentials: true }).then(
			function () { // success

				$scope.is_adding_evt_profile = false;
				$scope.evt_profile_to_add_name = '';
				$scope.evt_profile_to_add_delete_after = $scope.DELETE_AFTER_DEFAULT;
				$scope.evt_profile_to_add_lan_only = $scope.LAN_ONLY_DEFAULT_OPTION;
				$scope.evt_profile_to_add_region = null;
				$scope.error_msg_evt_profile = null;

				$scope.load();

				trackMixpanelEvent(MPEventName.ENCODER_EVENT_PROFILE_ADD, mixpanel_data);
			},
			function () { // error

				$scope.error_msg_evt_profile = 'An error occurred while attempting to create the event profile. Please try again, or report the problem if it persists.';

			})['finally'](function () { // always called

				$scope.is_working = false;
			});
	};

	$scope.formatMonth = function (date) {
		// NOTE: since we are storing the date as UTC 00:00:00 ... we can't display it in our local time zone
		// otherwise it will jump backwards 1 day. So convert the date to UTC before formatting the month.

		if (moment(date).utc().format('YYYY') == moment().utc().format('YYYY')) {
			return moment(date).utc().format('MMMM');
		}
		return moment(date).utc().format('MMMM YYYY');
	};

	$scope.convertKBtoTB = function (kilobytes) {
		return kilobytes / $scope.TERRABYTE_IN_KB;
	};

	$scope.formatBandwidthFromKBtoTB = function (bandwidth_kb) {
		var resized = $scope.convertKBtoTB(bandwidth_kb);
		// always show two decimal places, unless first digit after decimal is a zero, then show two significant digits
		var options = resized <= 0.09 ? { maximumSignificantDigits: 2 } : { minimumFractionDigits: 2, maximumFractionDigits: 2 };
		// see: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Number/toLocaleString
		return resized.toLocaleString(undefined, options);
	};

	$scope.doesSaveBandwidthUsageFailValidation = function () {
		// start with clean slate
		$scope.has_error = {};
		$scope.error_msg = null;

		// check required fields to ensure they aren't empty
		$scope.has_error.month = $scope.isEmpty($scope.save_usage_month);
		$scope.has_error.year = $scope.isEmpty($scope.save_usage_year);
		$scope.has_error.bandwidth = $scope.isEmpty($scope.save_usage_bandwidth);

		var error_count = 0;
		for (var property in $scope.has_error) {
			if ($scope.has_error[property] === true) {
				error_count++;
			}
		}
		var has_validation_error = error_count > 0;

		if (has_validation_error) {
			$scope.error_msg = 'Please specify a value for the highlighted fields below.';
			return has_validation_error;
		}

		// ensure year is 4 digits
		var year_regex = new RegExp('^[0-9]{4}$', 'i');
		if (!year_regex.test($scope.save_usage_year)) {
			$scope.has_error.year = true;
			$scope.error_msg = 'Please enter a valid 4 digit year.';
			return true;
		}
		// ensure bandwidth is valid
		if (Number.isNaN(Number.parseFloat($scope.save_usage_bandwidth))) {
			$scope.has_error.bandwidth = true;
			$scope.error_msg = 'Please enter a valid bandwidth (decimal).';
			return true;
		}

		return false;
	};

	$scope.updateBandwidthUsageList = function (prev_usage) {

		// with the switch to cloudflare, our prev usage list may now contain multiple entries for each month. Therefore, we will need to create a new
		// list that combines entries for the same month. Perhaps at some point the API will change (to clean up the multiple entries itself), and combining
		// multiple entries will no longer be required.
		const combined_list = {};
		for (const entry of prev_usage){
			if (combined_list.hasOwnProperty(entry.date)){
				combined_list[entry.date].kBytes += entry.kBytes;
			} else {
				combined_list[entry.date] = entry;
			}
		}
		// convert combined list object to an array
		const combined_prev_usage = [];
		for (const property in combined_list) {
			combined_prev_usage.push(combined_list[property]);
		}

		$scope.bandwidth_usage_by_month = combined_prev_usage;

		// add formatted bandwidth usage
		for (let i = 0; i < $scope.bandwidth_usage_by_month.length; i++) {
			$scope.bandwidth_usage_by_month[i].formattedBandwidthTB = $scope.formatBandwidthFromKBtoTB($scope.bandwidth_usage_by_month[i].kBytes);
		}

		// we only want to show a "pending" entry for the previous month if 1) we don't have data for previous month, and 2) we
		// do have data for two months ago ... so only last months is missing. If there is a larger gap, then we are assuming web
		// usage was stopped and now restarted (so last month's isn't missing, they weren't using the service).
		$scope.bandwidth_show_prev_month_as_pending = !$scope.haveBandwidthDataPrevMonth(1) && $scope.haveBandwidthDataPrevMonth(2);
	};

	$scope.saveBandwidthUsage = function () {
		// if we have form validation errors, then don't go any further
		if ($scope.doesSaveBandwidthUsageFailValidation()) {
			return false;
		}

		// convert month and year to zulu datetime
		// NOTE: all we care about is the month/year in the date. So storing as UTC 00:00:00 ... which does mean we need to take special care when
		// displaying the date later (can't put it in our local time zone). WHEN we automate this, we'll need to make sure this all still works. I
		// was thinking the database should not store a time, just the date.
		var bandwidth_date =
			moment($scope.save_usage_month + '/' + $scope.save_usage_year, 'M/YYYY')
				.startOf('month')
				.format($scope.DATE_FORMAT) + 'T00:00:00Z';
		// convert bandwidth to KB
		var bandwidth_kb = Number.parseFloat($scope.save_usage_bandwidth) * $scope.TERRABYTE_IN_KB;

		var usage_data = {
			customerId: Authentication.getCurrentUser().customerID,
			date: bandwidth_date,
			kBytes: bandwidth_kb,
		};

		$scope.is_working = true;

		$http
			.post(jcs.api.url + '/datausage/', usage_data, { withCredentials: true })
			.then(
				function () {
					// success

					$scope.save_usage_month = null;
					//$scope.save_usage_year = null;
					$scope.save_usage_bandwidth = null;

					// reload our bandwidth usage
					$http
						.get(jcs.api.url + '/datausage/' + Authentication.getCurrentUser().customerID + '/range', {
							withCredentials: true,
						})
						.then(
							function (response) {
								// success

								$scope.updateBandwidthUsageList(response.data);
							},
							function () {
								// error

								$scope.error_msg =
									'An error occurred while attempting to reload the banwidth information. Please refresh your page.';
							}
						)
					['finally'](function () {
						// always called

						$scope.is_working = false;
					});
				},
				function () {
					// error

					$scope.error_msg =
						'An error occurred while attempting to save the bandwidth usage. Please try again, or report the problem if it persists.';
					$scope.is_working = false;
				}
			)
		['finally'](function () {
			// always called
			//$scope.is_working = false;
		});
	};

	$scope.deleteTranscoderProfile = function () {
		$scope.is_working = true;

		const mixpanel_data = {
			[MPEventProperty.WEB_EVENT_PROFILE_UUID]: $scope.web_evt_prof_to_delete.uuid,
			[MPEventProperty.WEB_EVENT_PROFILE_NAME]: $scope.web_evt_prof_to_delete.name,
		};

		// make http request to delete profile
		// NOTE: I normally would not include the "data: null", but IE11 and below seem to require it in order to work
		// see: https://github.com/angular/angular.js/issues/12141
		httpService.delete(`${jcs.api.url_v3}/customers/${Authentication.getCurrentUser().customerID}/webeventprofiles/${$scope.web_evt_prof_to_delete.uuid}`, { withCredentials: true, data: null }).then(() => {

			// remove profile from our profiles list
			var index = $scope.transcoder_profiles.indexOf($scope.web_evt_prof_to_delete);
			if (index > -1) {
				$scope.transcoder_profiles.splice(index, 1);
			}

			$scope.web_evt_prof_to_delete = null;
			$scope.error_msg = null;

			trackMixpanelEvent(MPEventName.WEB_EVENT_PROFILE_DELETE, mixpanel_data);

		}).catch(reason => {

			if (reason.status === 400){
				$scope.error_msg = `An error occurred while attempting to delete the web event profile. Error: ${reason.data.message}`;
			} else {
				$scope.error_msg = 'An error occurred while attempting to delete the web event profile. Please try again, or report the problem if it persists.';
			}

		}).finally(() => {

			$scope.is_working = false;

		});
	};

	$scope.deleteProfile = function () {
		$scope.is_working = true;

		const mixpanel_data = {
			[MPEventProperty.ENCODER_EVENT_PROFILE_NAME]: $scope.evt_profile_to_delete.name,
			[MPEventProperty.ENCODER_EVENT_PROFILE_UUID]: $scope.evt_profile_to_delete.uuid,
		};

		// make http request to delete profile
		// NOTE: I normally would not include the "data: null", but IE11 and below seem to require it in order to work
		// see: https://github.com/angular/angular.js/issues/12141
		$http.delete(jcs.api.url + '/streamprofiles/' + $scope.evt_profile_to_delete.uuid, { withCredentials: true, data: null }).then(
				function () { // success

					// remove profile from our profiles list
					var index = $scope.event_profiles.indexOf($scope.evt_profile_to_delete);
					if (index > -1) {
						$scope.event_profiles.splice(index, 1);
					}

					$scope.evt_profile_to_delete = null;
					$scope.error_msg = null;

					trackMixpanelEvent(MPEventName.ENCODER_EVENT_PROFILE_DELETE, mixpanel_data);
				},
				function () { // error

					$scope.error_msg = 'An error occurred while attempting to delete the event profile. Please try again, or report the problem if it persists.';

				})['finally'](function () { // always called

					$scope.is_working = false;

				});
	};

	$scope.selectAllUsers = function () {
		for (var i = 0; i < $scope.customer_user_list.length; i++) {
			var uuid = $scope.customer_user_list[i].uuid;
			$scope.evt_profile_to_add_users.selected[uuid] = uuid;
		}
	};

	$scope.clearAllUsers = function () {
		$scope.evt_profile_to_add_users = { selected: {} };
	};

	// returns the profile object with a matching UUID. Otherwise null is returned;
	$scope.findProfile = function (profile_uuid) {
		for (var i = 0; i < $scope.event_profiles.length; i++) {
			var profile = $scope.event_profiles[i];
			if (profile.uuid == profile_uuid) return profile;
		}
		return null;
	};

	$scope.onMyResize = function () {
		$scope.drawChart();
	};

	$scope.prepareChart = function () {
		if (
			$scope.event_usage_stats != null &&
			$scope.event_usage_stats.length == 1 &&
			Authentication.getCurrentUser().hasPerm('web_event_profiles.bandwidth_cost')
		) {
			// the bandwidth data returns a time range, and the bandwidth value for that time range. We need to convert that to specific x/y values.
			// So for the time range we are just going to use the "startTime".
			// also each time series does not necessarily contain data for the time ranges given in other time series. One time series might just have 2 data
			// points while another has 35. So we need to first go through all our time series lists, and compile a complete list of time ranges. When we build
			// our chart data, we'll need that complete list.
			var series_titles = [];
			var timestamps = [];
			var data_sets = [];
			for (var i = 0; i < $scope.event_usage_stats[0].data.timeSeries.length; i++) {
				var name =
					$scope.event_usage_stats[0].data.timeSeries[i].metric.labels.client_country +
					' ' +
					$scope.event_usage_stats[0].data.timeSeries[i].metric.labels.cache_result;
				series_titles.push(name);

				var points = $scope.event_usage_stats[0].data.timeSeries[i].points;

				var data_set = {};
				for (var j = 0; j < points.length; j++) {
					var point = points[j];
					if (timestamps.indexOf(point.interval.startTime) == -1) {
						timestamps.push(point.interval.startTime);
					}
					data_set[point.interval.startTime] = point.value.doubleValue;
				}
				data_sets.push(data_set);
			}

			timestamps.sort();

			$scope.chartDataTable = new google.visualization.DataTable();
			$scope.chartDataTable.addColumn('date', 'Time');
			for (var i = 0; i < series_titles.length; i++) {
				$scope.chartDataTable.addColumn('number', series_titles[i]);
			}

			// iterate thru all our timestamps ...
			for (var j = 0; j < timestamps.length; j++) {
				var timestamp = timestamps[j];

				var row_data = [new Date(timestamp)];

				// ... then for each data series, see if it has a data point for the current timestamp. If so include it, otherwise use null.
				for (var i = 0; i < data_sets.length; i++) {
					var data_set = data_sets[i];

					if (data_set.hasOwnProperty(timestamp)) {
						var value = data_set[timestamp];
						row_data.push({ v: value / $scope.GIGABYTE_IN_BYTES, f: $scope.formatBandwidthGB(value) });
					} else {
						row_data.push(null);
					}
				}
				$scope.chartDataTable.addRow(row_data);
				//$scope.chartDataTable.addRow([new Date(point.interval.startTime), {v: point.value.doubleValue / $scope.GIGABYTE_IN_BYTES, f: $scope.formatBandwidthGB(point.value.doubleValue)} ]);
			}

			// format options: https://developers.google.com/chart/interactive/docs/reference#formatters
			var formatter = new google.visualization.DateFormat({ pattern: 'MMM d, yyyy h:mm aa' });
			formatter.format($scope.chartDataTable, 0);

			//                    $scope.chart = new google.charts.Line(document.getElementById('bandwidth-usage-chart')); // material design chart (beta)
			$scope.chart = new google.visualization.LineChart(document.getElementById('bandwidth-usage-chart'));
		}
	};

	$scope.drawChart = function () {
		if ($scope.chart != null && $scope.chartDataTable != null) {
			var chartDivWidth = $('#bandwidth-usage-chart').width();

			// see: https://developers.google.com/chart/interactive/docs/gallery/linechart
			var options = {
				legend: { position: 'none', maxLines: 3 },
				width: chartDivWidth,
				chartArea: { left: 30, top: 20, width: '95%', height: '90%' },
				// explorer will allow user to zoom and pan (zoom is done with the mouse wheel)
				explorer: {
					actions: ['dragToZoom', 'rightClickToReset'],
					maxZoomIn: 0.01,
				},
				hAxis: {
					// make horizontal gridlines white, so we don't see them
					minorGridlines: { color: '#fff' },
					gridlines: { color: '#fff' },
				},
			};

			$scope.chart.draw($scope.chartDataTable, options);
			//                    $scope.chart.draw($scope.chartDataTable, google.charts.Line.convertOptions(options)); // material design chart (beta)
		}
	};

	$scope.getFormattedType = function (type){

		for (var i = 0; i < $scope.web_event_type_options.length; i++){
			if ($scope.web_event_type_options[i].value == type){
				return $scope.web_event_type_options[i].name;
			}
		}
		return type;
	};

	$scope.load = function (callback) {
		$scope.loading_event_profiles = true;
		$scope.event_profiles_error_msg = null;

		// fetch our event profiles
		// $http.get(jcs.api.url_v3+'/customers/'+Authentication.getCurrentUser().customerID+'/streamprofiles', {withCredentials: true})
		$http.get(jcs.api.url + '/streamprofiles', { withCredentials: true, }).then(
			function (response) { // success

				$scope.event_profiles = response.data;

				// execute our callback method if provided
				if (typeof callback == 'function') callback();
			},
			function () { // error

				$scope.event_profiles_error_msg = 'An error occurred while attempting to retrieve the event profiles. Please try again, or report the problem if it persists.';
				$scope.event_profiles = null;

			})['finally'](function () { // always called
				$scope.loading_event_profiles = false;
				regionService.getWebRegions().then(response => {
					const isOnResiCustomerOrg = Authentication.getCurrentUser().customerName === 'Resi';
					const checkRestricted = restricted => !restricted || isOnResiCustomerOrg;

					if ($scope.event_profiles?.length && $scope.event_profiles.every(x => x.regionId)) {
						const regionIds = $scope.event_profiles.map(({ regionId }) => regionId);

						$scope.web_region_options = response.filter(({ uuid, restricted }) => regionIds.includes(uuid) && checkRestricted(restricted));
						return;
					}
					$scope.web_region_options = response.filter(({ restricted }) => checkRestricted(restricted));
				}).catch(() => {
					$scope.region_error_msg = 'An error occurred while attempting to retrieve the web event profile region information. Please try again, or report the problem if it persists.';
					$scope.web_region_options = null;
				});
			});

		if (Authentication.getCurrentUser().hasPerm('web_event_profiles.get')) {
			$scope.loading_transcoder_profiles = true;
			$scope.transcoder_error_msg = null;

			// multi-request for profiles & customer info
			var loading_promises = [];
			loading_promises.push(httpService.get(jcs.api.url_v3 + '/customers/' + Authentication.getCurrentUser().customerID + '/webeventprofiles', { withCredentials: true }));
			loading_promises.push(httpService.get(`${jcs.api.url_v3}/customers/${Authentication.getCurrentUser().customerID}`, { withCredentials: true }));
			$q.all(loading_promises).then(
				function (loading_response_list) {

					// profiles response
					$scope.transcoder_profiles = loading_response_list[0].data;
					// do some formatting
					if ($scope.transcoder_profiles != null) {
						for (var i = 0; i < $scope.transcoder_profiles.length; i++) {
							$scope.transcoder_profiles[i].type_formatted = $scope.getFormattedType($scope.transcoder_profiles[i].type);
						}
					}

					// customer response
					$scope.usage_summary = loading_response_list[1].data.usageSummary;
				},
				function (reason) { // error
					$scope.transcoder_error_msg = 'An error occurred while attempting to retrieve the transcoder event profiles. Please try again, or report the problem if it persists.';
					$scope.transcoder_profiles = null;
				})
				.finally(function () {
					$scope.loading_transcoder_profiles = false;
				});
		}

		$scope.region_error_msg = null;
	};

	//
	// initialize by loading our profiles
	//
	(async () => {
		$scope.delete_after_options_web_event = await orgService.getWebDeleteAfterOptions(Authentication.getCurrentUser().customerID);
		$scope.$apply();
	})();

	if (typeof $routeParams.id !== 'undefined') {
		// if a specific profile has been provided, then we'll want to go directly to the profile edit
		// page. Do this immediately after loading our profile list.
		// example URL: http://localhost/livingasone/control/eventprofiles/5add0669-ad1f-4ee7-96ec-b9b6c6337e66
		$scope.load(function () {
			var profile = $scope.findProfile($routeParams.id);
			if (profile != null) {
				$scope.enterEditEvtProfileMode(profile);
			}
		});
	} else {
		$scope.load();
	}

	// define and build our tooltips
	$scope.lan_only_tooltip = 'When "On", events will not be uploaded to the cloud and therefore will only be playable on the encoder\'s network.';
	$timeout(function () {
		$('[data-toggle="tooltip"]').tooltip(); // needs to be done in timeout, otherwise for some reason the tooltip gets built before angular does it's magic
	});

	// prepare our default start/end times
	$scope.analytics_start_time = moment().startOf('month').format($scope.DATE_TIME_FORMAT); // 1st day of current month
	$scope.analytics_end_time = moment().format($scope.DATE_TIME_FORMAT); // right now
	// for options see: http://www.daterangepicker.com/
	$scope.setBandwidthUsageTimeRange = function (start, end, label) {
		// currently the time appears to be saved as milliseconds or seconds
		$scope.analytics_start_time = moment(start).format($scope.DATE_TIME_FORMAT);
		$scope.analytics_end_time = moment(end).format($scope.DATE_TIME_FORMAT);
	};
	// initialize our date-time range picker that will be used when viewing encoder logs
	// NOTE: there is a "singleDatePicker" option in case we decide we want either start/end time to be optional
	// and would therefore need to have two separate input fields. Each input field would also need it's own callback.
	// for options see: http://www.daterangepicker.com/ (especially the Configuration Generator section)
	$('#analytics-time-range').daterangepicker(
		{
			//singleDatePicker: true,
			startDate: $scope.analytics_start_time,
			endDate: $scope.analytics_end_time,
			timePicker: true,
			timePickerIncrement: 5,
			locale: {
				format: $scope.DATE_TIME_FORMAT, // <= looks like this is using moment.js formatting options
			},
		},
		$scope.setBandwidthUsageTimeRange
	);

	// build our tooltips
	$timeout(function () {
		// this tooltip uses a html "title/body" with special formatting
		$('.load-balancer-tooltip').tooltip({
			template: '<div class="tooltip" role="tooltip"><div class="tooltip-arrow"></div><div class="tooltip-inner tooltip-inner-wide tooltip-inner-left"></div></div>',
			placement: 'top',
			title: '<div class="tooltip-section">Needs to be a unique value in the format: customer-city-state<br/>So for "North.Church - Oklahoma City, OK" you might use "nc-okc-ok".</div><div class="tooltip-section">Try to keep these values short, since they will be part of the load balancer URL.<br/> Ex: media1.resi.io/nc-okc-ok/</div>NOTE: if a customer has more than one web event profile, each will need to have a unique load balancer value.',
			html: true,
		});
		// tooltip for "type" that shows when creating a new web event profile (customers should not see this)
		$('.web-event-profile-type-internal-tooltip').tooltip({
			template: '<div class="tooltip" role="tooltip"><div class="tooltip-arrow"></div><div class="tooltip-inner tooltip-inner-wide tooltip-inner-left"></div></div>',
			placement: 'top',
			title: '<div class="tooltip-section">Public: uses a load balancer ($100/yr) and supports enterprise bitrates</div><div class="tooltip-section">Return Feed: is meant for a limited audience (game film / most RAY encoders)</div>Social: is meant for when entire audience is watching via social media (not with embed code)',
			html: true,
		});
		// tooltip for "type" that shows on web event profile details page (customers can see this)
		$('.web-event-profile-type-customer-tooltip').tooltip({
			template: '<div class="tooltip" role="tooltip"><div class="tooltip-arrow"></div><div class="tooltip-inner tooltip-inner-wide tooltip-inner-left"></div></div>',
			placement: 'top',
			title: '<div class="tooltip-section">Public: for use with your regular online audience</div><div class="tooltip-section">Return Feed: for limited internal viewing only</div>Social: for use when only streaming to social media',
			html: true,
		});
		// tooltip for "DVR Availability Window"
		$('.dvr-availability-tooltip').tooltip({
			template: '<div class="tooltip" role="tooltip"><div class="tooltip-arrow"></div><div class="tooltip-inner tooltip-inner-wide tooltip-inner-left"></div></div>',
			placement: 'top',
			title: '<div class="tooltip-section">Full Rewind: audience can rewind all the way to the beginning of the web event</div>Limited Rewind: audience can only rewind the specified duration in minutes behind the live position',
//			title: '<div class="tooltip-section">Full Rewind: audience can rewind all the way to the beginning of the web event</div><div class="tooltip-section">Limited Rewind: audience can only rewind the specified duration in minutes behind the live position</div>No Rewind: audience is unable to rewind and will always be at the live position',
			html: true,
		});
		$('.stream-urls-tooltip').tooltip({
			template: '<div class="tooltip" role="tooltip"><div class="tooltip-arrow"></div><div class="tooltip-inner tooltip-inner-wide tooltip-inner-left"></div></div>',
			placement: 'top',
			title: '<div class="tooltip-section">Once Stream URLs are enabled, future events will be available for viewing. Past events are not available on newly enabled Stream URLs.</div>',
			html: true,
		});
		// tooltip for "Automated Subtitles" that shows when creating a new web event profile
		$('.subtitles-tooltip').tooltip({
			template: '<div class="tooltip" role="tooltip"><div class="tooltip-arrow"></div><div class="tooltip-inner tooltip-inner-left"></div></div>',
			placement: 'top',
			title: 'Enables subtitles to be automatically created for your video content to make it more accessible to your audience. You can learn more about subtitles in our documentation.',
			html: true,
		});
		$('.embed-code-tooltip').tooltip({
			template: '<div class="tooltip" role="tooltip"><div class="tooltip-arrow"></div><div class="tooltip-inner tooltip-inner-wide tooltip-inner-left"></div></div>',
			placement: 'top',
			title: '<div class="tooltip-section">In most cases the <strong>Standard iframe</strong> will allow you to embed a video that will adjust to fill the parent container.</div><div class="tooltip-section">The <strong>Alternative iframe</strong> can be used in cases where the parent container is a fixed height and does not match the video\'s aspect ratio.</div>The <strong>Script Tag</strong> may be required for use with certain third-party products such as Planning Center Publishing.',
			html: true,
		});
	});

	if (Authentication.getCurrentUser().hasPerm('web_event_profiles.bandwidth_cost')) {
		googleChartsService.load(function () {
			google.charts.load('current', { packages: ['corechart'] }); // regular line chart
			//                google.charts.load('current', {'packages':['line']}); // for material design line chart (in beta)

			angular.element($window).on('resize', $scope.onMyResize);

			// if the chart has been created, then we need to make sure it is cleaned up if the user changes to another page.
			$scope.$on('$locationChangeSuccess', function () {
				// remove our resize callback
				angular.element($window).off('resize', $scope.onMyResize);
			});
		});
	}

	// if the video player has been created, then we need to make sure it is cleaned up if the user changes to another page.
	// we need to clean up the web player if the user changes to another page. When they change back to this page, all the
	// html will be reloaded, so all the webplayer UI will need to be rebuilt.
	$scope.$on('$locationChangeSuccess', function () {
		console.log('Unloading web player');
		webPlayerService.unload($scope.WEB_PLAYER_WRAPPER_ID);
		$scope.web_player = null;
	});
}

module.exports = EventProfilesController;
