import React, { FC } from 'react';
import PropTypes from 'prop-types';
import { LoadingOutlined } from '@ant-design/icons';
import { FillBlockSpinnerContainer } from './fill-block-spinner.styles';

interface FillBlockSpinnerProps {
  fontSize: string;
}

const FillBlockSpinner: FC<FillBlockSpinnerProps> = ({ fontSize }) => {
  return (
    <FillBlockSpinnerContainer data-testid="fill-block-spinner-container">
      <LoadingOutlined style={{ fontSize }} data-testid="loader-icon" />
    </FillBlockSpinnerContainer>
  );
};

FillBlockSpinner.displayName = 'FillBlockSpinner';

FillBlockSpinner.propTypes = {
  fontSize: PropTypes.string.isRequired,
};

export default FillBlockSpinner;
