class MediaStatusCtrl {

	constructor() {
		'ngInject';
	}

	$onInit () {
		// this tooltip uses a html "title/body" with special formatting
		$('.aborted-status-tooltip').tooltip({
			template: '<div class="tooltip" role="tooltip"><div class="tooltip-arrow"></div><div class="tooltip-inner tooltip-inner-wide tooltip-inner-left"></div></div>',
			placement: 'top',
			title: '<div class="tooltip-section">Aborted due to an error which occurred after the stream started. Possible causes:<ul class="tooltip-list"><li>The stream was stopped on the destination service (i.e. Facebook only allows streams to last a total of 8 hours)</li><li>The encoder ran for more than 12 hours</li><li>The encoder\'s audio/video input caused a problem (i.e. "white noise" or visually random video signal caused an encoding problem)</li></ul></div>',
			html: true,
		});
	}

	isPlaying (status){
		return status && status.toLowerCase() == 'playing';
	}

	isStarted (status){
		return status && status.toLowerCase() == 'started';
	}

	isWarning (status){
		return status && status.toLowerCase() == 'warning';
	}

	isAborted (status){
		return status && status.toLowerCase() == 'aborted';
	}

	showStatusActivityIndicator () {
		return this.showActivityIndicator && this.showActivityIndicator === true;
	}
}

// example usage:
// <media-status status="obj.status" show-activity-indicator="canShowStatusActivityIndicator(obj)"></media-status>
// show-activity-indicator <= needs to be a boolean
//
export default class MediaStatusComponent {
	constructor() {
		this.template = require('./mediaStatus.html').default;
		this.bindings = {
			status: '<',
			showActivityIndicator: '<'
		};
		this.controller = MediaStatusCtrl;
	}
}
