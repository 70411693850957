import React, { FC, useContext, useReducer, useState } from 'react';
import {
  GROUP_VALUES,
  LOCATION_VALUES,
  TAB_VALUES_STREAM,
  API_RESPONSE_DIFFS,
  STREAM_SEGMENTED_REDUCER_TYPE,
  LOCATIONS_TAB_VALUES,
} from '../../event-analytics-constants';
import { StreamContext } from '../../../../contexts/eventanalytics';
import StreamSegmentedStatsView from './stream-segmented-stats-view';
import countries from '../../countries.json';
import regionsList from '../../regions.json';
import { fetchData } from '../api';
import {
  doughnutChartDataType,
  barChartDataType,
  EVENT_TYPES,
  LOCATION_TAB_VALUES,
  STREAM_TAB_VALUES,
  STREAM_CHART_COMBINATIONS_TYPE,
  TOP10_LOCATION_CHART_COMBINATIONS_TYPE,
  GROUP_BY_VALUE,
} from '../../types';
import {
  getLocationDispatcher,
  initialTop10LocationStatsState,
  Top10LocationReducer,
} from '../../top10-location-reducer';
import { getDispatcher, initialSegmentedReducer, StreamSegmentedReducer } from './stream-segmented-stats-reducer';
import { EventAnalyticsContext } from '../../../../contexts/eventanalytics/event-analytics';
import useCachedFetch from '../../../../hooks/use-cached-fetch';
import { MPEventName, MPEventProperty, trackMixpanelEvent } from '../../../../mixpanel';

interface StreamSegmentedStatsProps {
  setCSVDetail: (value: STREAM_TAB_VALUES) => void;
  segmentType: GROUP_BY_VALUE;
}

const StreamSegmentedStats: FC<StreamSegmentedStatsProps> = ({ setCSVDetail, segmentType }): JSX.Element => {
  const { streamAnalyticsState } = useContext(StreamContext);
  const { EventAnalyticsState } = useContext(EventAnalyticsContext);
  const [expandChartOpen, setExpandChartOpen] = useState<boolean>(false);
  const countriesList = countries.countries;

  const [StreamSegmentedStats, dispatch] = useReducer(StreamSegmentedReducer, initialSegmentedReducer);
  const {
    tabValue,
    locationTabValue,
    showLocationTab,
    deviceStats,
    countryStats,
    regionStats,
    cityStats,
    deviceStatsGroupBy,
    countryStatsGroupBy,
    regionStatsGroupBy,
    cityStatsGroupBy,
  } = StreamSegmentedStats;

  const [Top10LocationStatsState, top10dispatch] = useReducer(Top10LocationReducer, initialTop10LocationStatsState);
  const {
    top10countryStats,
    top10regionStats,
    top10cityStats,
    top10countryStatsGroupBy,
    top10regionStatsGroupBy,
    top10cityStatsGroupBy,
  } = Top10LocationStatsState;

  const getLocationData = async (locationTab: LOCATION_TAB_VALUES) => {
    const { setCity, setCountry, setRegion } = getDispatcher({
      groupBy: streamAnalyticsState.groupBy,
      dispatch: dispatch,
      countriesList,
      regionsList,
    });

    const { setCity: setTop10City, setCountry: setTop10Country, setRegion: setTop10Region } = getLocationDispatcher({
      groupBy: streamAnalyticsState.groupBy,
      dispatch: top10dispatch,
      countriesList,
      regionsList,
    });

    switch (locationTab) {
      case LOCATIONS_TAB_VALUES.COUNTRY: {
        if (expandChartOpen) {
          const country = await fetchData(
            EventAnalyticsState,
            API_RESPONSE_DIFFS.LOCATION.COUNTRY,
            streamAnalyticsState
          );
          setCountry(country);
        }
        const top10Country = await fetchData(
          EventAnalyticsState,
          API_RESPONSE_DIFFS.LOCATION.COUNTRY,
          streamAnalyticsState,
          10
        );
        setTop10Country(top10Country);
        break;
      }
      case LOCATIONS_TAB_VALUES.REGION: {
        if (expandChartOpen) {
          const region = await fetchData(EventAnalyticsState, API_RESPONSE_DIFFS.LOCATION.REGION, streamAnalyticsState);
          setRegion(region);
        }
        const top10Region = await fetchData(
          EventAnalyticsState,
          API_RESPONSE_DIFFS.LOCATION.REGION,
          streamAnalyticsState,
          10
        );
        setTop10Region(top10Region);
        break;
      }
      case LOCATIONS_TAB_VALUES.CITY: {
        if (expandChartOpen) {
          const city = await fetchData(EventAnalyticsState, API_RESPONSE_DIFFS.LOCATION.CITY, streamAnalyticsState);
          setCity(city);
        }
        const top10City = await fetchData(
          EventAnalyticsState,
          API_RESPONSE_DIFFS.LOCATION.CITY,
          streamAnalyticsState,
          10
        );
        setTop10City(top10City);
        break;
      }
      default:
        break;
    }
  };

  const getData = async () => {
    const { setDeviceType } = getDispatcher({
      groupBy: streamAnalyticsState.groupBy,
      dispatch: dispatch,
      countriesList,
      regionsList,
    });

    switch (tabValue) {
      case TAB_VALUES_STREAM.DEVICE_TYPE: {
        const deviceType = await fetchData(EventAnalyticsState, API_RESPONSE_DIFFS.DEVICE_TYPE, streamAnalyticsState);
        setDeviceType(deviceType);
        break;
      }
      case TAB_VALUES_STREAM.LOCATION:
        await getLocationData(locationTabValue);
        break;

      default:
        break;
    }
  };

  const { isLoading } = useCachedFetch(
    ['stream-segmented', streamAnalyticsState, EventAnalyticsState, tabValue, locationTabValue, expandChartOpen],
    getData
  );

  const changeTab = (event: React.ChangeEvent<unknown>, tabVal: STREAM_TAB_VALUES): void => {
    dispatch({ type: STREAM_SEGMENTED_REDUCER_TYPE.TAB_VALUE, payload: tabVal });
    if (tabVal === TAB_VALUES_STREAM.LOCATION) {
      dispatch({ type: STREAM_SEGMENTED_REDUCER_TYPE.SHOW_LOCATION_TAB, payload: true });
    } else {
      dispatch({ type: STREAM_SEGMENTED_REDUCER_TYPE.SHOW_LOCATION_TAB, payload: false });
    }

    setCSVDetail(tabVal);

    let segmentTypeString = '';

    switch (segmentType) {
      case 0:
        segmentTypeString = 'None';
        break;
      case 1:
        segmentTypeString = 'Event Type';
        break;
      case 2:
        segmentTypeString = 'Device Type';
        break;
    }
    let detailTypeString = tabVal === 0 ? 'Device Type' : 'Location';

    if (detailTypeString === 'Location') {
      switch (StreamSegmentedStats.locationTabValue) {
        case LOCATIONS_TAB_VALUES.CITY:
          detailTypeString = 'Location - City';
          break;
        case LOCATIONS_TAB_VALUES.REGION:
          detailTypeString = 'Location - Region';
          break;
        case LOCATIONS_TAB_VALUES.COUNTRY:
          detailTypeString = 'Location - Country';
          break;
      }
    }

    const mixpanelProps = {
      [MPEventProperty.TRANSCODED_EVENT_UUID]: EventAnalyticsState.eventId,
      [MPEventProperty.ANALYTICS_TYPE]: 'Stream URL',
      [MPEventProperty.ANALYTICS_SEGMENT]: segmentTypeString,
      [MPEventProperty.ANALYTICS_DETAIL_TYPE]: detailTypeString,
    };

    if (!EventAnalyticsState.eventId) {
      delete mixpanelProps[MPEventProperty.TRANSCODED_EVENT_UUID];
    }

    trackMixpanelEvent(MPEventName.ANALYTICS_VIEW_DETAILS, mixpanelProps);
  };

  const locationOnTabChange = (event: React.ChangeEvent<unknown>, locationVal: LOCATION_TAB_VALUES): void => {
    dispatch({ type: STREAM_SEGMENTED_REDUCER_TYPE.LOCATION_TAB_VALUE, payload: locationVal });
    if (tabValue === TAB_VALUES_STREAM.LOCATION) {
      dispatch({ type: STREAM_SEGMENTED_REDUCER_TYPE.SHOW_LOCATION_TAB, payload: true });
    } else {
      dispatch({ type: STREAM_SEGMENTED_REDUCER_TYPE.SHOW_LOCATION_TAB, payload: false });
    }

    let segmentTypeString = '';

    switch (segmentType) {
      case 0:
        segmentTypeString = 'None';
        break;
      case 1:
        segmentTypeString = 'Event Type';
        break;
      case 2:
        segmentTypeString = 'Device Type';
        break;
    }

    let detailTypeString = '';

    switch (locationVal) {
      case LOCATIONS_TAB_VALUES.CITY:
        detailTypeString = 'Location - City';
        break;
      case LOCATIONS_TAB_VALUES.REGION:
        detailTypeString = 'Location - Region';
        break;
      case LOCATIONS_TAB_VALUES.COUNTRY:
        detailTypeString = 'Location - Country';
        break;
    }

    const mixpanelProps = {
      [MPEventProperty.TRANSCODED_EVENT_UUID]: EventAnalyticsState.eventId,
      [MPEventProperty.ANALYTICS_TYPE]: 'Stream URL',
      [MPEventProperty.ANALYTICS_SEGMENT]: segmentTypeString,
      [MPEventProperty.ANALYTICS_DETAIL_TYPE]: detailTypeString,
    };

    if (!EventAnalyticsState.eventId) {
      delete mixpanelProps[MPEventProperty.TRANSCODED_EVENT_UUID];
    }

    trackMixpanelEvent(MPEventName.ANALYTICS_VIEW_DETAILS, mixpanelProps);
  };

  const eventValue = streamAnalyticsState.groupBy;
  const getChartData = (
    eventValue: EVENT_TYPES,
    tabValue: STREAM_TAB_VALUES,
    locationValue: LOCATION_TAB_VALUES
  ): doughnutChartDataType[] | barChartDataType => {
    const mapChartDataToCombinationsObject: STREAM_CHART_COMBINATIONS_TYPE = {
      [GROUP_VALUES.NONE]: {
        [TAB_VALUES_STREAM.DEVICE_TYPE]: deviceStats,
        [TAB_VALUES_STREAM.LOCATION]: {
          [LOCATION_VALUES.COUNTRY]: countryStats,
          [LOCATION_VALUES.REGION]: regionStats,
          [LOCATION_VALUES.CITY]: cityStats,
        },
      },
      [GROUP_VALUES.EVENT_TYPE]: {
        [TAB_VALUES_STREAM.DEVICE_TYPE]: deviceStatsGroupBy,
        [TAB_VALUES_STREAM.LOCATION]: {
          [LOCATION_VALUES.COUNTRY]: countryStatsGroupBy,
          [LOCATION_VALUES.REGION]: regionStatsGroupBy,
          [LOCATION_VALUES.CITY]: cityStatsGroupBy,
        },
      },
      [GROUP_VALUES.DEVICE_TYPE]: {
        [TAB_VALUES_STREAM.DEVICE_TYPE]: deviceStats,
        [TAB_VALUES_STREAM.LOCATION]: {
          [LOCATION_VALUES.COUNTRY]: countryStatsGroupBy,
          [LOCATION_VALUES.REGION]: regionStatsGroupBy,
          [LOCATION_VALUES.CITY]: cityStatsGroupBy,
        },
      },
    };
    return tabValue === TAB_VALUES_STREAM.DEVICE_TYPE
      ? mapChartDataToCombinationsObject[eventValue][TAB_VALUES_STREAM.DEVICE_TYPE]
      : mapChartDataToCombinationsObject[eventValue][TAB_VALUES_STREAM.LOCATION][locationValue];
  };

  const getTop10LocationChartData = (
    eventValue: EVENT_TYPES,
    locationValue: LOCATION_TAB_VALUES,
    showLocationTab: boolean
  ): doughnutChartDataType[] | barChartDataType | undefined => {
    const mapChartDataToCombinationsObject: TOP10_LOCATION_CHART_COMBINATIONS_TYPE = {
      [GROUP_VALUES.NONE]: {
        [TAB_VALUES_STREAM.LOCATION]: {
          [LOCATION_VALUES.COUNTRY]: top10countryStats,
          [LOCATION_VALUES.REGION]: top10regionStats,
          [LOCATION_VALUES.CITY]: top10cityStats,
        },
      },
      [GROUP_VALUES.EVENT_TYPE]: {
        [TAB_VALUES_STREAM.LOCATION]: {
          [LOCATION_VALUES.COUNTRY]: top10countryStatsGroupBy,
          [LOCATION_VALUES.REGION]: top10regionStatsGroupBy,
          [LOCATION_VALUES.CITY]: top10cityStatsGroupBy,
        },
      },
      [GROUP_VALUES.DEVICE_TYPE]: {
        [TAB_VALUES_STREAM.LOCATION]: {
          [LOCATION_VALUES.COUNTRY]: top10countryStatsGroupBy,
          [LOCATION_VALUES.REGION]: top10regionStatsGroupBy,
          [LOCATION_VALUES.CITY]: top10cityStatsGroupBy,
        },
      },
    };
    return showLocationTab
      ? mapChartDataToCombinationsObject[eventValue][TAB_VALUES_STREAM.LOCATION][locationValue]
      : undefined;
  };

  return (
    <StreamSegmentedStatsView
      onTabChange={changeTab}
      eventValue={eventValue}
      tabValue={tabValue}
      chartData={getChartData(eventValue, tabValue, locationTabValue)}
      top10LocationChartData={getTop10LocationChartData(eventValue, locationTabValue, showLocationTab)}
      locationTabValue={locationTabValue}
      locationOnTabChange={locationOnTabChange}
      showLocationTab={showLocationTab}
      isChartLoading={isLoading}
      expandChartOpen={expandChartOpen}
      setExpandChartOpen={setExpandChartOpen}
    />
  );
};

StreamSegmentedStats.displayName = 'StreamSegmentedStats';

export default StreamSegmentedStats;
