import 'angular-route';
import angular from 'angular';
import router from './module.organizations.routes';
import commonServicesModule from '../../services';

export const customersModule = {
  name: 'customers',
  controllers: {
    default: 'customersCtrl',
  },
  routes: {
    list: '/organizations',
    details: '/organizations/:id',
    switch_to: '/organizations/:id/:switch_to',
  },
};

export default angular
  .module(customersModule.name, ['ngRoute', commonServicesModule])
  .config(router)
  .controller(customersModule.controllers.default, require('./organizationsCtrl')).name;
