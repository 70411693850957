import { userInvitesModule } from './index';

function routeConfig($provide, $routeProvider) {
  'ngInject';

  $provide.factory('$routeProvider', () => {
    return $routeProvider;
  });

  const acceptInviteOptions = {
    controllerAs: '$ctrl',
    controller: userInvitesModule.controllers.acceptInvite,
    template: require('./acceptInvite.tmpl.html').default,
    access: {
      loginRequired: false,
    },
  };

  $routeProvider.when(userInvitesModule.routes.accept, acceptInviteOptions);
}

export default routeConfig;
