class ErrorMsgCtrl {

	constructor() {
		'ngInject';

		this.error_details = null;
	}

	$onChanges (changes){
		this.buildErrorMessage();
	}

	$onInit (){
		this.buildErrorMessage();
	}

	buildErrorMessage (){

		this.error_details = {
			message: null,
			server_code: null,
			server_message: null
		}

		if (typeof this.error != 'undefined' && this.error != null){

			if (typeof this.error === 'string'){

				this.error_details.message = this.error;

			} else if (typeof this.error === 'object'){

				this.error_details.message = this.error.message;

				// only check for additional code/message info if status is 400
				if (this.error.reason.hasOwnProperty('status') && this.error.reason.status == 400){

					if (this.error.reason.hasOwnProperty('data')){
						if (this.error.reason.data.hasOwnProperty('code')){
							this.error_details.server_code = this.error.reason.data.code;
						}
						if (this.error.reason.data.hasOwnProperty('message')){
							this.error_details.server_message = this.error.reason.data.message;
						}
					}

				}

			} else {

				this.error_details.message = 'Unable to build error message. Error is of unknown type: ' + (typeof this.error);
				console.log("Error:");
				console.log(this.error);

			}
		}

	}
}

// example usage:
// <error-msg error="error_msg_as_string_variable"></error-msg>
// OR
// <error-msg error="error_msg_object"></error-msg>
// where error_msg_object has the following fields:
// message: the general error message
// reason: reason <= send the entire reason object, and the data attribute will be searched for "code" and "message"
//
export default class ErrorMsgComponent {
	constructor() {
		this.template = require('./errorMsg.html').default;
		this.bindings = {
			error: '<'
		};
		this.controller = ErrorMsgCtrl;
	}
}
