import * as LDClient from 'launchdarkly-js-client-sdk';
import { launchDarklyClientId, launchDarklyAnonymousUserId } from '../jcs';

class LaunchDarklyService {
  ldClient: LDClient.LDClient;
  constructor(private $rootScope) {
    'ngInject';

    this.$rootScope = $rootScope;
    this.ldClient = LDClient.initialize(launchDarklyClientId, { key: launchDarklyAnonymousUserId, anonymous: true });
    this.$rootScope.ldClient = this.ldClient;

    this.ldClient.on('ready', () => {
      this.setFlags();
    });

    this.ldClient.on('change', () => {
      this.setFlags();
    });
  }

  public trackLaunchDarkly(userID, userName, customerID, customerName): void {
    const ldUser = {
      key: userID,
      name: userName,
      custom: { customer_uuid: customerID, customer_name: customerName },
    };

    try {
      if (userID !== 'Anonymous') {
        this.ldClient.identify(ldUser);
      } else {
        this.ldClient.identify({ key: launchDarklyAnonymousUserId, anonymous: true });
      }
    } catch (err) {
      console.error(err);
    }
  }

  private setFlags() {
    this.$rootScope.ldFlags = this.ldClient.allFlags();
    this.$rootScope.$emit('ldFlagsChanged', this.$rootScope.ldFlags);
  }
}

export default LaunchDarklyService;
