import styled from '@emotion/styled';
import { customProperties as vars } from '@resi-media/resi-ui';

export const InvitationExpiredWrap = styled.div`
  padding: ${vars.spacingXl} ${vars.spacingXl};
  margin: auto;
  width: 95vw;
  background-color: ${vars.colorSurface};
  border-radius: ${vars.borderRadius2};

  @media (min-width: ${vars.breakPointTablet}) {
    width: 464px;
  }
`;
