import { Grid, customProperties as vars } from '@resi-media/resi-ui';
import { Parser } from 'json2csv';
import PropTypes from 'prop-types';
import React, { FC, useContext, useState } from 'react';
import { FillBlockSpinner, HeatMap, StatusCard } from '../../../components';
import VideoCues from '../../../components/VideoCues/video-cues';
import { EventAnalyticsContext } from '../../../contexts/eventanalytics/event-analytics';
import { MPEventName, MPEventProperty, trackMixpanelEvent } from '../../../mixpanel';
import {
  CSV_EXPORT_FIELDS,
  LOADER_SIZE_COMPONENT,
  MAX_FETCH_LIMIT_FOR_CSV_EXPORT,
  TAB_VALUES_EMBED,
} from '../event-analytics-constants';
import '../event-analytics.styles.ts';
import { EventAnalyticsProps, GROUP_BY_VALUE } from '../types';
import { downloadAsCSV, fetchAndMergeResponse } from '../utils';
import { exportCSVData } from './api';
import EmbedSegmentedStats from './EmbedSegmentedStats/embed-segmented-stats';
import EmbedStatsGraph from './EmbedStatsGraph/embed-stats-graph';

const EmbedPlayerAnalyticsView: FC<EventAnalyticsProps> = ({
  statsArray,
  isCardLoading,
  hasGraphPerms,
  heatMapData,
  isMapLoading,
}): JSX.Element => {
  const { EventAnalyticsState } = useContext(EventAnalyticsContext);
  const [isExportingCSV, setExportingCSV] = useState<boolean>(false);
  const [csvDetail, setCSVDetail] = useState<TAB_VALUES_EMBED>(TAB_VALUES_EMBED.LOCATION);
  const [dataType, setDataType] = useState<GROUP_BY_VALUE>(0);

  const exportCSV = async () => {
    let csvDetailString = '';

    switch (csvDetail) {
      case TAB_VALUES_EMBED.RESOLUTION:
        csvDetailString = 'Resolution';
        break;
      case TAB_VALUES_EMBED.WATCH_TIME:
        csvDetailString = 'Watch Time';
        break;
      case TAB_VALUES_EMBED.DEVICE_TYPE:
        csvDetailString = 'Device Type';
        break;
      case TAB_VALUES_EMBED.LOCATION:
        csvDetailString = 'Location';
        break;
      default:
        csvDetailString = 'All Data';
    }

    setExportingCSV(true);
    const response = await fetchAndMergeResponse(
      (offSetValue1: string | number | undefined, offSetValue2: string | number | undefined) =>
        exportCSVData(EventAnalyticsState, offSetValue1, offSetValue2),
      MAX_FETCH_LIMIT_FOR_CSV_EXPORT,
      'clientId',
      'sessionId'
    );
    setExportingCSV(false);
    const json2csvParser = new Parser({ fields: CSV_EXPORT_FIELDS });
    const csv = json2csvParser.parse(response);
    downloadAsCSV(csv, 'EmbedPlayer-Analytics.csv');

    const mixpanelProps = {
      [MPEventProperty.TRANSCODED_EVENT_UUID]: '',
      [MPEventProperty.CSV_DETAIL]: csvDetailString,
      [MPEventProperty.ANALYTICS_TYPE]: 'Embed Player',
    };

    if (EventAnalyticsState.eventId) {
      mixpanelProps[MPEventProperty.TRANSCODED_EVENT_UUID] = EventAnalyticsState.eventId;
    } else {
      delete mixpanelProps[MPEventProperty.TRANSCODED_EVENT_UUID];
    }

    trackMixpanelEvent(MPEventName.CSV_EXPORT, mixpanelProps);
  };

  return (
    <Grid display="grid" gridGap={vars.spacingXl} data-testid="analytics-page">
      <Grid.Item>
        <EmbedStatsGraph exportCSV={exportCSV} isExportingCSV={isExportingCSV} hasGraphPerms={hasGraphPerms} setSegmentType={setDataType} />
      </Grid.Item>
      {EventAnalyticsState.eventId ? (
        <Grid.Item>
          <VideoCues />
        </Grid.Item>
      ) : null}
      {isCardLoading ? (
        <FillBlockSpinner fontSize={LOADER_SIZE_COMPONENT} />
      ) : (
        <Grid display="grid" gridGap={vars.spacingXl} gridAutoColumns="1fr" gridTemplateColumns="repeat(4, 1fr)">
          {statsArray.map((item) => (
            <Grid.Item key={item.label}>
              <StatusCard
                label={item.label}
                change={item.change}
                count={item.count}
                isTrendUp={item.isTrendUp}
                data-testid="status-card"
              />
            </Grid.Item>
          ))}
        </Grid>
      )}
      {hasGraphPerms && (
        <>
        <EmbedSegmentedStats setCSVDetail={setCSVDetail} dataType={dataType} />
        {heatMapData?.length ? <HeatMap positions={heatMapData} defaultZoomLevel={0} isLoading={!!isMapLoading} /> : null}
        </>
      )}
    </Grid>
  );
};

EmbedPlayerAnalyticsView.propTypes = {
  hasGraphPerms: PropTypes.bool.isRequired,
  heatMapData: PropTypes.array.isRequired,
  isMapLoading: PropTypes.bool.isRequired,
  statsArray: PropTypes.array.isRequired,
};

EmbedPlayerAnalyticsView.displayName = 'EmbedPlayerAnalyticsView';

export default EmbedPlayerAnalyticsView;
