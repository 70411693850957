import DatetimeComponent from './datetime.component';

export const datetimeModule = {
	name: 'datetimeComponent',
	component: {
		name: 'datetime'
	}
};

export default angular
	.module(datetimeModule.name, [])
	.component(datetimeModule.component.name, new DatetimeComponent()).name;
