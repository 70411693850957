class EmbedCodeService {

	constructor() {
		'ngInject';
	}

	getStandardIframeEmbedCode (embedId, extraQueryParams) {
		return `<div style="position:relative;overflow:hidden;padding-top:56.25%;">
  <iframe allow="autoplay; fullscreen" allowfullscreen="true" src="https://control.resi.io/webplayer/video.html?id=${embedId}${extraQueryParams}" style="position:absolute;top:0;left:0;width:100%;height:100%;border:none;"></iframe>
</div>`;
	}

	getAlternativeIframeEmbedCode (embedId, extraQueryParams) {
		return `<iframe allow="autoplay; fullscreen" allowfullscreen="true" src="https://control.resi.io/webplayer/video.html?id=${embedId}${extraQueryParams}" style="width:100%;height:100%;border:none;"></iframe>`;
	}
}

export default EmbedCodeService;
