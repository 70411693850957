import { API_RESPONSE_TYPE } from '../../pages/EventAnalytics/types';
import { LocationType } from './types';

export const getHeatMapData = (response: API_RESPONSE_TYPE): LocationType[] => {
  const positions: LocationType[] = [];
  response.latLong?.map((element) => {
    positions.push({
      lat: Number(element.latitude),
      lng: Number(element.longitude),
      weight: element.none?.viewers || 1,
    });
  });
  return positions;
};
