import React, { FC, useContext, useReducer, useState, ChangeEvent } from 'react';
import EmbedSegmentedStatsView from './embed-segmented-stats-view';
import { EmbedPlayerContext } from '../../../../contexts/eventanalytics';
import {
  GROUP_VALUES,
  LOCATION_VALUES,
  TAB_VALUES_EMBED,
  API_RESPONSE_DIFFS,
  EMBED_SEGMENTED_REDUCER_TYPE,
  LOCATIONS_TAB_VALUES,
} from '../../event-analytics-constants';
import countries from '../../countries.json';
import regionsList from '../../regions.json';
import { fetchData } from '../api';
import {
  barChartDataType,
  doughnutChartDataType,
  EMBED_CHART_COMBINATIONS_TYPE,
  EVENT_TYPES,
  LOCATION_TAB_VALUES,
  EMBED_TAB_VALUES,
  TOP10_EMBED_LOCATION_CHART_COMBINATIONS_TYPE,
  GROUP_BY_VALUE,
} from '../../types';
import {
  getLocationDispatcher,
  initialTop10LocationStatsState,
  Top10LocationReducer,
} from '../../top10-location-reducer';
import { getWatchTimeChartData, getWatchTimeTableData } from './utils';
import { EmbedSegmentedStatsReducer, getDispatcher, initialSegmentedStatsState } from './embed-segmented-stats-reducer';
import { EventAnalyticsContext } from '../../../../contexts/eventanalytics/event-analytics';
import useCachedFetch from '../../../../hooks/use-cached-fetch';
import { MPEventName, MPEventProperty, trackMixpanelEvent } from '../../../../mixpanel';

interface EmbedSegmentedStatsProps {
  setCSVDetail: (value: TAB_VALUES_EMBED) => void;
  dataType: GROUP_BY_VALUE;
}

const EmbedSegmentedStats: FC<EmbedSegmentedStatsProps> = ({ setCSVDetail, dataType }): JSX.Element => {
  const { embedPlayerAnalyticsState } = useContext(EmbedPlayerContext);
  const { EventAnalyticsState } = useContext(EventAnalyticsContext);
  const [expandChartOpen, setExpandChartOpen] = useState<boolean>(false);
  const countriesList = countries.countries;

  const watchTimeDropDownOptions = [
    { label: 'Full Video', value: 'full' },
    { label: 'Custom Range', value: 'custom' },
  ];

  const [EmbedSegmentedStatsState, dispatch] = useReducer(EmbedSegmentedStatsReducer, initialSegmentedStatsState);
  const {
    tabValue,
    locationTabValue,
    showLocationTab,
    resolutionStats,
    watchTimeStats,
    watchTimeTableStats,
    deviceStats,
    countryStats,
    regionStats,
    cityStats,
    resolutionStatsGroupBy,
    deviceStatsGroupBy,
    countryStatsGroupBy,
    regionStatsGroupBy,
    cityStatsGroupBy,
  } = EmbedSegmentedStatsState;

  const [top10LocationStatsState, top10dispatch] = useReducer(Top10LocationReducer, initialTop10LocationStatsState);
  const {
    top10countryStats,
    top10regionStats,
    top10cityStats,
    top10countryStatsGroupBy,
    top10regionStatsGroupBy,
    top10cityStatsGroupBy,
  } = top10LocationStatsState;

  const getLocationData = async (locationTabValue: LOCATION_TAB_VALUES) => {
    const { setCity, setCountry, setRegion } = getDispatcher({
      groupBy: embedPlayerAnalyticsState.groupBy,
      dispatch: dispatch,
      countriesList,
      regionsList,
    });
    const { setCity: setTop10City, setCountry: setTop10Country, setRegion: setTop10Region } = getLocationDispatcher({
      groupBy: embedPlayerAnalyticsState.groupBy,
      dispatch: top10dispatch,
      countriesList,
      regionsList,
    });

    switch (locationTabValue) {
      case LOCATIONS_TAB_VALUES.COUNTRY: {
        if (expandChartOpen) {
          const country = await fetchData(
            EventAnalyticsState,
            API_RESPONSE_DIFFS.LOCATION.COUNTRY,
            embedPlayerAnalyticsState
          );
          setCountry(country);
        }
        const top10Country = await fetchData(
          EventAnalyticsState,
          API_RESPONSE_DIFFS.LOCATION.COUNTRY,
          embedPlayerAnalyticsState,
          10
        );
        setTop10Country(top10Country);
        break;
      }
      case LOCATIONS_TAB_VALUES.REGION: {
        if (expandChartOpen) {
          const region = await fetchData(
            EventAnalyticsState,
            API_RESPONSE_DIFFS.LOCATION.REGION,
            embedPlayerAnalyticsState
          );
          setRegion(region);
        }
        const top10Region = await fetchData(
          EventAnalyticsState,
          API_RESPONSE_DIFFS.LOCATION.REGION,
          embedPlayerAnalyticsState,
          10
        );
        setTop10Region(top10Region);
        break;
      }
      case LOCATIONS_TAB_VALUES.CITY: {
        if (expandChartOpen) {
          const city = await fetchData(
            EventAnalyticsState,
            API_RESPONSE_DIFFS.LOCATION.CITY,
            embedPlayerAnalyticsState
          );
          if (city) {
            setCity(city);
          }
        }
        const top10City = await fetchData(
          EventAnalyticsState,
          API_RESPONSE_DIFFS.LOCATION.CITY,
          embedPlayerAnalyticsState,
          10
        );
        setTop10City(top10City);
        break;
      }
      default:
        break;
    }
  };

  const getData = async () => {
    const { setDeviceType, setResolution } = getDispatcher({
      groupBy: embedPlayerAnalyticsState.groupBy,
      dispatch: dispatch,
      countriesList,
      regionsList,
    });

    switch (tabValue) {
      case TAB_VALUES_EMBED.RESOLUTION: {
        const resolution = await fetchData(
          EventAnalyticsState,
          API_RESPONSE_DIFFS.RESOLUTION,
          embedPlayerAnalyticsState
        );
        setResolution(resolution);
        break;
      }
      case TAB_VALUES_EMBED.DEVICE_TYPE: {
        const deviceType = await fetchData(
          EventAnalyticsState,
          API_RESPONSE_DIFFS.DEVICE_TYPE,
          embedPlayerAnalyticsState
        );
        setDeviceType(deviceType);
        break;
      }
      case TAB_VALUES_EMBED.WATCH_TIME: {
        const watchTime = await fetchData(
          EventAnalyticsState,
          API_RESPONSE_DIFFS.WATCH_TIME,
          embedPlayerAnalyticsState
        );
        dispatch({
          type: EMBED_SEGMENTED_REDUCER_TYPE.WATCHTIME_STATS,
          payload: getWatchTimeChartData(watchTime),
        });
        dispatch({
          type: EMBED_SEGMENTED_REDUCER_TYPE.WATCHTIME_TABLE_STATS,
          payload: getWatchTimeTableData(watchTime),
        });
        break;
      }
      case TAB_VALUES_EMBED.LOCATION:
        await getLocationData(locationTabValue);
        break;
      default:
        break;
    }
  };

  let minTimeVal = '',
    maxTimeVal = '';
  const lengthOfLabels = watchTimeStats.labels.length;
  if (lengthOfLabels > 0) {
    minTimeVal = watchTimeStats.labels[0].split('-')[0].replace('m', '');
    maxTimeVal = watchTimeStats.labels[lengthOfLabels - 1].split('-')[1].replace('m', '');
  }

  const changeTab = (event: ChangeEvent<unknown>, tabVal: EMBED_TAB_VALUES): void => {
    dispatch({ type: EMBED_SEGMENTED_REDUCER_TYPE.TAB_VALUE, payload: tabVal });
    if (tabVal === TAB_VALUES_EMBED.LOCATION) {
      dispatch({ type: EMBED_SEGMENTED_REDUCER_TYPE.SHOW_LOCATION_TAB, payload: true });
    } else {
      dispatch({ type: EMBED_SEGMENTED_REDUCER_TYPE.SHOW_LOCATION_TAB, payload: false });
    }
    setCSVDetail(tabVal);

    let tabString = '';
    let dataTypeString = '';

    switch (tabVal) {
      case TAB_VALUES_EMBED.RESOLUTION:
        tabString = 'Resolution';
        break;
      case TAB_VALUES_EMBED.WATCH_TIME:
        tabString = 'Watch Time';
        break;
      case TAB_VALUES_EMBED.DEVICE_TYPE:
        tabString = 'Device Type';
        break;
      case TAB_VALUES_EMBED.LOCATION:
        tabString = 'Location';
    }

    if (tabString === 'Location') {
      switch (EmbedSegmentedStatsState.locationTabValue) {
        case LOCATIONS_TAB_VALUES.CITY:
          tabString = 'Location - City';
          break;
        case LOCATIONS_TAB_VALUES.COUNTRY:
          tabString = 'Location - Country';
          break;
        case LOCATIONS_TAB_VALUES.REGION:
          tabString = 'Location - Region';
          break;
      }
    }

    switch (dataType) {
      case 0:
        dataTypeString = 'None';
        break;
      case 1:
        dataTypeString = 'Event Type';
        break;
      case 2:
        dataTypeString = 'Device Type';
        break;
    }

    const mixpanelProps = {
      [MPEventProperty.TRANSCODED_EVENT_UUID]: EventAnalyticsState.eventId,
      [MPEventProperty.ANALYTICS_TYPE]: 'Embed Player',
      [MPEventProperty.ANALYTICS_SEGMENT]: dataTypeString,
      [MPEventProperty.ANALYTICS_DETAIL_TYPE]: tabString,
    };

    if (!EventAnalyticsState.eventId) {
      delete mixpanelProps[MPEventProperty.TRANSCODED_EVENT_UUID];
    }

    trackMixpanelEvent(MPEventName.ANALYTICS_VIEW_DETAILS, mixpanelProps);
  };

  const locationOnTabChange = (event: ChangeEvent<unknown>, locationVal: LOCATION_TAB_VALUES): void => {
    dispatch({ type: EMBED_SEGMENTED_REDUCER_TYPE.LOCATION_TAB_VALUE, payload: locationVal });
    if (tabValue === TAB_VALUES_EMBED.LOCATION) {
      dispatch({ type: EMBED_SEGMENTED_REDUCER_TYPE.SHOW_LOCATION_TAB, payload: true });
    } else {
      dispatch({ type: EMBED_SEGMENTED_REDUCER_TYPE.SHOW_LOCATION_TAB, payload: false });
    }

    let tabString = '';
    let dataTypeString = '';

    switch (locationVal) {
      case LOCATIONS_TAB_VALUES.CITY:
        tabString = 'Location - City';
        break;
      case LOCATIONS_TAB_VALUES.COUNTRY:
        tabString = 'Location - Country';
        break;
      case LOCATIONS_TAB_VALUES.REGION:
        tabString = 'Location - Region';
        break;
    }

    switch (dataType) {
      case 0:
        dataTypeString = 'None';
        break;
      case 1:
        dataTypeString = 'Event Type';
        break;
      case 2:
        dataTypeString = 'Device Type';
        break;
    }

    const mixpanelProps = {
      [MPEventProperty.TRANSCODED_EVENT_UUID]: EventAnalyticsState.eventId,
      [MPEventProperty.ANALYTICS_TYPE]: 'Embed Player',
      [MPEventProperty.ANALYTICS_SEGMENT]: dataTypeString,
      [MPEventProperty.ANALYTICS_DETAIL_TYPE]: tabString,
    };

    if (!EventAnalyticsState.eventId) {
      delete mixpanelProps[MPEventProperty.TRANSCODED_EVENT_UUID];
    }

    trackMixpanelEvent(MPEventName.ANALYTICS_VIEW_DETAILS, mixpanelProps);
  };

  const eventValue = embedPlayerAnalyticsState.groupBy;
  const getChartData = (
    eventValue: EVENT_TYPES,
    tabValue: EMBED_TAB_VALUES,
    locationValue: LOCATION_TAB_VALUES,
    showLocationTab: boolean
  ): doughnutChartDataType[] | barChartDataType => {
    const mapChartDataToCombinationsObject: EMBED_CHART_COMBINATIONS_TYPE = {
      [GROUP_VALUES.NONE]: {
        [TAB_VALUES_EMBED.RESOLUTION]: resolutionStats,
        [TAB_VALUES_EMBED.WATCH_TIME]: watchTimeStats,
        [TAB_VALUES_EMBED.DEVICE_TYPE]: deviceStats,
        [TAB_VALUES_EMBED.LOCATION]: {
          [LOCATION_VALUES.COUNTRY]: countryStats,
          [LOCATION_VALUES.REGION]: regionStats,
          [LOCATION_VALUES.CITY]: cityStats,
        },
      },
      [GROUP_VALUES.EVENT_TYPE]: {
        [TAB_VALUES_EMBED.RESOLUTION]: resolutionStatsGroupBy,
        [TAB_VALUES_EMBED.WATCH_TIME]: watchTimeStats,
        [TAB_VALUES_EMBED.DEVICE_TYPE]: deviceStatsGroupBy,
        [TAB_VALUES_EMBED.LOCATION]: {
          [LOCATION_VALUES.COUNTRY]: countryStatsGroupBy,
          [LOCATION_VALUES.REGION]: regionStatsGroupBy,
          [LOCATION_VALUES.CITY]: cityStatsGroupBy,
        },
      },
      [GROUP_VALUES.DEVICE_TYPE]: {
        [TAB_VALUES_EMBED.RESOLUTION]: resolutionStatsGroupBy,
        [TAB_VALUES_EMBED.WATCH_TIME]: watchTimeStats,
        [TAB_VALUES_EMBED.DEVICE_TYPE]: deviceStats,
        [TAB_VALUES_EMBED.LOCATION]: {
          [LOCATION_VALUES.COUNTRY]: countryStatsGroupBy,
          [LOCATION_VALUES.REGION]: regionStatsGroupBy,
          [LOCATION_VALUES.CITY]: cityStatsGroupBy,
        },
      },
    };
    if (!showLocationTab && tabValue !== TAB_VALUES_EMBED.LOCATION) {
      return mapChartDataToCombinationsObject[eventValue][tabValue];
    } else {
      return mapChartDataToCombinationsObject[eventValue][TAB_VALUES_EMBED.LOCATION][locationValue];
    }
  };

  const getTop10LocationChartData = (
    eventValue: EVENT_TYPES,
    locationValue: LOCATION_TAB_VALUES,
    showLocationTab: boolean
  ): doughnutChartDataType[] | barChartDataType | undefined => {
    const mapChartDataToCombinationsObject: TOP10_EMBED_LOCATION_CHART_COMBINATIONS_TYPE = {
      [GROUP_VALUES.NONE]: {
        [TAB_VALUES_EMBED.LOCATION]: {
          [LOCATION_VALUES.COUNTRY]: top10countryStats,
          [LOCATION_VALUES.REGION]: top10regionStats,
          [LOCATION_VALUES.CITY]: top10cityStats,
        },
      },
      [GROUP_VALUES.EVENT_TYPE]: {
        [TAB_VALUES_EMBED.LOCATION]: {
          [LOCATION_VALUES.COUNTRY]: top10countryStatsGroupBy,
          [LOCATION_VALUES.REGION]: top10regionStatsGroupBy,
          [LOCATION_VALUES.CITY]: top10cityStatsGroupBy,
        },
      },
      [GROUP_VALUES.DEVICE_TYPE]: {
        [TAB_VALUES_EMBED.LOCATION]: {
          [LOCATION_VALUES.COUNTRY]: top10countryStatsGroupBy,
          [LOCATION_VALUES.REGION]: top10regionStatsGroupBy,
          [LOCATION_VALUES.CITY]: top10cityStatsGroupBy,
        },
      },
    };
    return showLocationTab
      ? mapChartDataToCombinationsObject[eventValue][TAB_VALUES_EMBED.LOCATION][locationValue]
      : undefined;
  };

  const { isLoading } = useCachedFetch(
    [
      'embed-data',
      embedPlayerAnalyticsState,
      EventAnalyticsState,
      tabValue,
      locationTabValue,
      showLocationTab,
      expandChartOpen,
    ],
    getData
  );

  return (
    <EmbedSegmentedStatsView
      onTabChange={changeTab}
      eventValue={eventValue}
      tabValue={tabValue}
      chartData={getChartData(eventValue, tabValue, locationTabValue, showLocationTab)}
      watchTimeTableStats={watchTimeTableStats}
      top10LocationChartData={getTop10LocationChartData(eventValue, locationTabValue, showLocationTab)}
      locationTabValue={locationTabValue}
      locationOnTabChange={locationOnTabChange}
      showLocationTab={showLocationTab}
      isChartLoading={isLoading}
      minTimeVal={minTimeVal}
      maxTimeVal={maxTimeVal}
      watchTimeDropDownOptions={watchTimeDropDownOptions}
      expandChartOpen={expandChartOpen}
      setExpandChartOpen={setExpandChartOpen}
    />
  );
};

EmbedSegmentedStats.displayName = 'EmbedSegmentedStats';

export default EmbedSegmentedStats;
