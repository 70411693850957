import React, { ReactElement } from 'react';
import Tab from '@material-ui/core/Tab';
import { withStyles, createStyles } from '@material-ui/styles';
import { tabItemStyles } from '../material-styles';

interface StyledTabProps {
  label: string;
  disabled?: boolean;
  icon?: ReactElement;
}

const ContainedTab = withStyles(() => createStyles(tabItemStyles))((props: StyledTabProps) => (
  <Tab disableRipple {...props} />
));

export default ContainedTab;
