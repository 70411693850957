'use strict';

/*
The web billing page is being created to help the billing team when they have to record customer bandwidth usage at the beginning of
each month. Currently they have to pull up a list of web customers, and then "switch to" each customer and then pull up their bandwidth
usage on the web event profiles page. We now have over 300+ web customers, so having to re-enter your password for each customer is
insane. With this new automated page it reduces it down to a couple mouse clicks and some waiting. Eventually we'll update the backend
to perform all this work using a cron job or something.

The "Load Customers with Web Plan" button does the following:
0. Fetches all customers with a web plan
1. Fetches details for each customer - in particular the bandwidth limit
2. Determines if bandwidth usage for the previous month has already been saved for this customer
If we do not yet have bandwidth data stored for the previous month for a particular customer, then for that customer we:
3. Fetch the web event profiles for each customer
4. Fetch the bandwidth usage for each of the web event profiles, and calculate a total.

After the customer data has all been loaded, the user can review the information and then "Save Bandwidth Usage". This will save
all the bandwidth totals calculated in step 4.

If an error occurs fetching the customers (step 0), then the user just needs to restart the process over completely. But if an error
occurs during steps 1+, then an error message will be displayed with a "retry" link. Clicking the "retry" link will allow the process
to pick up where it left off -- so we won't make any duplicate API calls (other than the one that failed).
*/

let jcs = require('../../jcs');
const moment = require('moment');

function WebBillingController($scope, $timeout, $http, $routeParams, $q, formValidationService, focus, Authentication) {
	'ngInject';
	$(window).trigger('resize'); // ensure footer is properly positioned

	$scope.progress = document.getElementById('progress-box');
	$scope.progress_msg = null;

	$scope.DATE_FORMAT = 'YYYY-MM-DD';

	$scope.PREV_MONTH = moment().subtract(1, 'month');
	$scope.PREV_MONTH_LABEL = $scope.PREV_MONTH.format('MMMM');

	$scope.TERRABYTE_IN_BYTES = 1000000000000; // 1,000,000,000,000
	$scope.GIGABYTE_IN_BYTES = 1000000000; // 1,000,000,000
	$scope.TERRABYTE_IN_KB = 1000000000; // convert from KB to TB and back

	$scope.BATCH_MAX_SIZE = 5;

	$scope.is_load_btn_disabled = false;
	$scope.is_save_btn_disabled = true;

	$scope.customers = [];
	$scope.customers_not_saved = []; // subset of $scope.customers -- those who don't have bandwidth usage saved in DB yet
	$scope.customer_headers = null;
	$scope.customer_page = 0;
	$scope.error_msg = null;
	$scope.error_msg_retry_load = null; // will include a "retry" link at the end of the message (for loading methods only, but not customer list)
	$scope.is_loading = false;

	$scope.customer_details_loaded_count = 0;
	$scope.customer_profiles_loaded_count = 0;
	$scope.customer_bandwidth_calculated_count = 0;
	$scope.customer_previous_usage_count = 0;
	$scope.customer_bandwidth_saved_count = 0;

	$scope.formatBandwidthBytesToTB = function (size_in_bytes) {
		let resized = size_in_bytes / $scope.TERRABYTE_IN_BYTES;
		// always show two decimal places, unless first digit after decimal is a zero, then show two significant digits
		let options = resized <= 0.09 ? { maximumSignificantDigits: 2 } : { minimumFractionDigits: 2, maximumFractionDigits: 2 };
		// see: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Number/toLocaleString
		return resized.toLocaleString(undefined, options);
	};

	$scope.formatBandwidthFromKBtoTB = function (bandwidth_kb) {
		if (bandwidth_kb == null){
			return '';
		}

		let resized = $scope.convertKBtoTB(bandwidth_kb);
		// always show two decimal places, unless first digit after decimal is a zero, then show two significant digits
		let options = resized <= 0.09 ? { maximumSignificantDigits: 2 } : { minimumFractionDigits: 2, maximumFractionDigits: 2 };
		// see: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Number/toLocaleString
		return resized.toLocaleString(undefined, options);
	};

	$scope.convertKBtoTB = function (kilobytes) {
		return kilobytes / $scope.TERRABYTE_IN_KB;
	};

	$scope.formatPercentForSort = function (usage_TB, bandwidth_limit_TB) {
		if (bandwidth_limit_TB == null) {
			return '';
		}
		// determine what percent of our allowance has been used
		return (usage_TB / bandwidth_limit_TB) * 100;
	};

	$scope.formatUsagePercentage = function (usage_TB, bandwidth_limit_TB) {
		if (bandwidth_limit_TB == null) {
			return '';
		}

		// determine what percent of our allowance has been used
		let percent = (usage_TB / bandwidth_limit_TB) * 100;
		// always show one decimal places, unless first digit after decimal is a zero, then show one significant digit
		let options = percent <= 0.09 ? { maximumSignificantDigits: 1 } : { minimumFractionDigits: 1, maximumFractionDigits: 1 };
		// see: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Number/toLocaleString
		return percent.toLocaleString(undefined, options) + '%';
	};

	$scope.getPercentUsedClass = function (usage_in_TB, bandwidth_limit) {
		if (usage_in_TB == null || bandwidth_limit == null) {
			return '';
		}

		let percent = (usage_in_TB / bandwidth_limit) * 100;

		if (percent <= 80) return 'status-block-low';
		if (percent <= 100) return 'status-block-high';
		return 'status-block-urgent';
	};

	$scope.updateProgress = function (value, max){

		let percent = (value/max)*100.0;

		if ($scope.progress){
			$scope.progress.style['border-image-slice'] = 1;
			$scope.progress.style['border-image-source'] = `linear-gradient(to right, #00a65a ${percent}%, #d2d6de ${percent}%)`;
		}
	};

	$scope.resetProgress = function () {
		$scope.updateProgress(0,1);
	};

	$scope.isLoadedForAllCustomers = function (customers, property) {

		// check to see if each customer has the given property
		for (const customer of customers){
			if (!customer.hasOwnProperty(property)){
				return false;
			}
		}
		return true;
	};

	$scope.getNextCustomers = function (customers, property) {

		let list = [];
		for (const customer of customers){
			if (!customer.hasOwnProperty(property)){
				list.push(customer.uuid);
				if (list.length >= $scope.BATCH_MAX_SIZE)
					return list;
			}
		}
		return list;
	};

	$scope.getNextCustomer = function (customers, property) {

		for (const customer of customers){
			if (!customer.hasOwnProperty(property)){
				return customer;
			}
		}
		return null;
	};

	$scope.updateCustomerProperty = function (customer_uuid, property, value) {
		for (let customer of $scope.customers){
			if (customer.uuid == customer_uuid){
				customer[property] = value;
				return;
			}
		}
	};

	$scope.getCustomerProperty = function (customer_uuid, property) {
		for (let customer of $scope.customers){
			if (customer.uuid == customer_uuid){
				return customer[property];
			}
		}
		return null;
	};

	$scope.getCustomer = function (customers, customer_uuid) {
		for (let customer of customers){
			if (customer.uuid == customer_uuid){
				return customer;
			}
		}
		return null;
	};

	$scope.getPreviousUsageInKB = function (previous_usage_by_month) {

		for (const entry of previous_usage_by_month){
			if (moment(entry.date).utc().isSame($scope.PREV_MONTH, 'month')){
				return entry.kBytes;
			}
		}
		return null;
	};

	$scope.loadCustomerDetails = function () {
		$scope.resetProgress();
		$scope.progress_msg = "Step 1 of 4: Loading customer details ...";
		$scope.error_msg_retry_load = null;
		$scope.continueLoadingCustomerDetails($scope.loadPreviousUsage);
	};

	$scope.continueLoadingCustomerDetails = function (functionToCallAfterComplete) {

		let property = 'bandwidth_limit';

		// check to see if info is already loaded
		if ($scope.isLoadedForAllCustomers($scope.customers, property)){
			if (functionToCallAfterComplete){
				functionToCallAfterComplete();
			}
			return;
		}

		let customer_uuids = $scope.getNextCustomers($scope.customers, property);

		let promises = [];
		for (const uuid of customer_uuids){
			promises.push($http.get(`${jcs.api.url}/customers/${uuid}`, { withCredentials: true }));
		}

		if (promises.length > 0){

			$q.all(promises).then(responses => {

				for (let response of responses){
					$scope.updateCustomerProperty(response.data.uuid, property, response.data.webBandwidth);
					$scope.updateCustomerProperty(response.data.uuid, 'web_plan_start_date', moment(response.data.webStartDate).format('YYYY-MM-DD'));
					$scope.customer_details_loaded_count++;
				}

				$scope.updateProgress($scope.customer_details_loaded_count, $scope.customer_headers.total_records);

				$scope.continueLoadingCustomerDetails(functionToCallAfterComplete);

			}).catch(reason => {

				console.log("REASON:");console.log(reason);
				$scope.error_msg_retry_load = 'An error occurred attempting to download the customer details.';

			});
		}

	};

	$scope.loadPreviousUsage = function () {
		$scope.resetProgress();
		$scope.progress_msg = "Step 2 of 4: Loading previous bandwidth usage ...";
		$scope.continueLoadingPreviousUsage($scope.loadCustomerProfiles);
	};

	$scope.continueLoadingPreviousUsage = function (functionToCallAfterComplete) {

		let property = 'previous_usage';

		// check to see if info is already loaded
		if ($scope.isLoadedForAllCustomers($scope.customers, property)){
			if (functionToCallAfterComplete){
				functionToCallAfterComplete();
			}
			return;
		}

		let customer_uuids = $scope.getNextCustomers($scope.customers, property);

		let promises = [];
		for (const uuid of customer_uuids){
			promises.push($http.get(`${jcs.api.url}/datausage/${uuid}/range`, { withCredentials: true }));
		}

		if (promises.length > 0){

			$q.all(promises).then(responses => {

				for (let i=0; i < responses.length; i++){
					let response = responses[i];
					let customer_uuid = customer_uuids[i];
					$scope.updateCustomerProperty(customer_uuid, property, response.data);

					let previous_usage_kb = $scope.getPreviousUsageInKB(response.data);
					if (previous_usage_kb != null){

						let previous_usage_tb = $scope.formatBandwidthFromKBtoTB(previous_usage_kb);
						$scope.updateCustomerProperty(customer_uuid, 'previous_usage_formatted', previous_usage_tb);
						$scope.updateCustomerProperty(customer_uuid, 'is_bandwidth_saved', true);

						let bandwidth_limit_tb = $scope.getCustomerProperty(customer_uuid, 'bandwidth_limit');
						$scope.updateCustomerProperty(customer_uuid, 'previous_usage_percent', $scope.formatUsagePercentage(previous_usage_tb, bandwidth_limit_tb));
						$scope.updateCustomerProperty(customer_uuid, 'previous_usage_percent_sort', $scope.formatPercentForSort(previous_usage_tb, bandwidth_limit_tb));

					} else {

						// if this customer doesn't yet have usage data stored for the prev month, then add them to our "not saved" list
						let customer = $scope.getCustomer($scope.customers, customer_uuid);
						if (customer){
							customer.is_bandwidth_saved = false;
							$scope.customers_not_saved.push(customer);
						} else {
							console.log("[Loading Prev Usage] could not find customer for: " + customer_uuid);
						}
					}

					$scope.customer_previous_usage_count++;

					$scope.updateProgress($scope.customer_previous_usage_count, $scope.customer_headers.total_records);
				}

				$scope.continueLoadingPreviousUsage(functionToCallAfterComplete);

			}).catch(reason => {

				console.log("REASON:");console.log(reason);
				$scope.error_msg_retry_load = 'An error occurred attempting to download the previous bandwidth usage for each customer.';

			});
		}

	};

	$scope.loadCustomerProfiles = function () {
		$scope.resetProgress();
		$scope.progress_msg = "Step 3 of 4: Loading customer profiles ...";
		$scope.continueLoadingCustomerProfiles($scope.calcCustomerBandwidthUsage);
	};

	$scope.continueLoadingCustomerProfiles = function (functionToCallAfterComplete) {

		let property = 'web_event_profiles';

		// check to see if info is already loaded
		if ($scope.isLoadedForAllCustomers($scope.customers_not_saved, property)){
			if (functionToCallAfterComplete){
				functionToCallAfterComplete();
			}
			return;
		}

		let customer_uuids = $scope.getNextCustomers($scope.customers_not_saved, property);

		let promises = [];
		for (const uuid of customer_uuids){
			promises.push($http.get(`${jcs.api.url_v3}/customers/${uuid}/webeventprofiles`, { withCredentials: true }));
		}

		if (promises.length > 0){

			$q.all(promises).then(responses => {

				for (let i=0; i < responses.length; i++){
					let response = responses[i];
					let customer_uuid = customer_uuids[i];
					$scope.updateCustomerProperty(customer_uuid, property, response.data);
					$scope.customer_profiles_loaded_count++;
				}

				$scope.updateProgress($scope.customer_profiles_loaded_count, $scope.customers_not_saved.length);

				$scope.continueLoadingCustomerProfiles(functionToCallAfterComplete);

			}).catch(reason => {

				console.log("REASON:");console.log(reason);
				$scope.error_msg_retry_load = 'An error occurred attempting to download the customer web event profiles.';

			});
		}

	};

	$scope.calcCustomerBandwidthUsage = function () {
		$scope.resetProgress();
		$scope.progress_msg = "Step 4 of 4: Calculating bandwidth usage ...";
		$scope.continueCalculatingBandwidthUsage();
	};

	// This works a bit differently from the other methods. Instead of just making a single API call to fetch the info we need, we need to fetch the bandwidth usage for each
	// web event profile that a customer has. Then a sum total is calculated for the customer. So instead of using the getNextCustomers method to fetch a "batch" of customers
	// this method simply fetches the next customer, and then makes API calls for each web event profile that the customer has.
	$scope.continueCalculatingBandwidthUsage = function () {

		let property = 'previous_usage_formatted';

		// check to see if info is already loaded
		if ($scope.isLoadedForAllCustomers($scope.customers_not_saved, property)){
			$scope.progress_msg = "Finished loading customers";
			$scope.is_save_btn_disabled = false;
			return;
		}

		// just fetch the next customer, not a batch of customer uuids
		let customer = $scope.getNextCustomer($scope.customers_not_saved, property);

		// ensure web event profiles have already been loaded for this customer
		if (!customer.hasOwnProperty('web_event_profiles') || customer.web_event_profiles == null){
			console.log(`[Calc Bandwidth Usage] web event profiles are not loaded for customer: ${customer.name} [${customer.uuid}]`);
			return;
		}

		if (customer.web_event_profiles.length == 0){

			// set usage to zero and move onto the next customer
			customer.previous_usage_formatted = 0;
			customer.previous_usage_percent = '0%';
			customer.previous_usage_percent_sort = 0;
			$scope.customer_bandwidth_calculated_count++;
			$scope.updateProgress($scope.customer_bandwidth_calculated_count, $scope.customers_not_saved.length);
			$scope.continueCalculatingBandwidthUsage();

		} else {

			let start_date_time = moment($scope.PREV_MONTH).startOf('month');
			let end_date_time = moment($scope.PREV_MONTH).endOf('month');
			let query_string = 'start=' + encodeURIComponent(start_date_time.toISOString());
			query_string += '&end=' + encodeURIComponent(end_date_time.toISOString());

			let promises = [];
			for (const profile of customer.web_event_profiles){
				promises.push($http.get(`${jcs.api.url_v3}/customers/${customer.uuid}/webeventprofiles/${profile.uuid}/bandwidth?${query_string}`, { withCredentials: true }));
			}

			if (promises.length > 0){

				$q.all(promises).then(responses => {

					let total_bandwidth_bytes = 0;

					for (const response of responses){
						total_bandwidth_bytes += response.data.totalBytes;
					}

					customer.previous_usage_formatted = $scope.formatBandwidthBytesToTB(total_bandwidth_bytes);
					customer.previous_usage_percent = $scope.formatUsagePercentage(customer.previous_usage_formatted, customer.bandwidth_limit);
					customer.previous_usage_percent_sort = $scope.formatPercentForSort(customer.previous_usage_formatted, customer.bandwidth_limit);

					$scope.customer_bandwidth_calculated_count++;

					$scope.updateProgress($scope.customer_bandwidth_calculated_count, $scope.customers_not_saved.length);

					$scope.continueCalculatingBandwidthUsage();


				}).catch(reason => {

					console.log("REASON:");console.log(reason);
					$scope.error_msg_retry_load = 'An error occurred attempting to calculate the previous bandwidth usage for certain customers.';

				});
			}

		}
	};

	$scope.saveBandwidthUsage = function () {
		$scope.resetProgress();
		$scope.progress_msg = "Saving bandwidth usage ...";
		$scope.continueSavingBandwidthUsage();
	};

	// returns true if all customers have a property with a certain value
	$scope.isValueForAllCustomers = function (customers, property, value) {

		// check to see if each customer has the given property and it is set to the given value
		for (const customer of customers){
			if (!customer.hasOwnProperty(property) || customer[property] !== value){
				return false;
			}
		}
		return true;
	};

	// returns list of customers that either do not have the given property, or the given property is not set to the given value
	$scope.getNextCustomersWithoutValue = function (customers, property, value) {

		let list = [];
		for (const customer of customers){
			if (!customer.hasOwnProperty(property) || customer[property] !== value){
				list.push(customer);
				if (list.length >= $scope.BATCH_MAX_SIZE)
					return list;
			}
		}
		return list;
	};

	$scope.continueSavingBandwidthUsage = function () {

		// convert month and year to zulu datetime
		// NOTE: all we care about is the month/year in the date. So storing as UTC 00:00:00 ... which does mean we need to take special care when
		// displaying the date later (can't put it in our local time zone). WHEN we automate this, we'll need to make sure this all still works. I
		// was thinking the database should not store a time, just the date.
		let start_of_month = moment($scope.PREV_MONTH).startOf('month');
		let bandwidth_date = start_of_month.format($scope.DATE_FORMAT) + 'T00:00:00Z';

		let property = 'is_bandwidth_saved';
		let value = true;

		// check to see if info is already loaded
		if ($scope.isValueForAllCustomers($scope.customers_not_saved, property, value)){
			$scope.progress_msg = "Saving bandwidth usage ... Completed.";
			return;
		}

		let customers = $scope.getNextCustomersWithoutValue($scope.customers_not_saved, property, value);

		let promises = [];
		for (const customer of customers){

			// convert bandwidth to KB
			let bandwidth_kb = Number.parseFloat(customer.previous_usage_formatted) * $scope.TERRABYTE_IN_KB;
			let usage_data = {
				customerId: customer.uuid,
				date: bandwidth_date,
				kBytes: bandwidth_kb,
			};

			promises.push($http.post(jcs.api.url + '/datausage/', usage_data, { withCredentials: true }));
		}

		if (promises.length > 0){

			$q.allSettled(promises).then(responses => {

				for (let i=0; i < responses.length; i++){
					let customer = customers[i];
					customer.is_bandwidth_saved = true;
					$scope.customer_bandwidth_saved_count++;
				}

				$scope.updateProgress($scope.customer_bandwidth_saved_count, $scope.customers_not_saved.length);

				$scope.continueSavingBandwidthUsage();

			}).catch(reasons => {

				for (let reason of reasons){
					if (reason.status == 201){
						let customer = $scope.getCustomer($scope.customers_not_saved, reason.config.data.customerId);
						if (customer != null){
							customer.is_bandwidth_saved = true;
							$scope.customer_bandwidth_saved_count++;
						} else {
							console.log("[ERROR] Could not find customer for ID: " + reason.config.data.customerId);
							console.log(reason);
						}
					}
				}

				$scope.updateProgress($scope.customer_bandwidth_saved_count, $scope.customers_not_saved.length);

				$scope.error_msg = 'An error occurred while attempting to save the bandwidth usage. Please try again, or report the problem if it persists.';

			});
		}
	};


	$scope.isDoneLoadingCustomers = function () {

		if ($scope.customer_headers != null && $scope.customers.length >= $scope.customer_headers.total_records){
			return true;
		}
		return false;
	};

	// this method will recursively load the entire customer list
	$scope.SIZE = 100;
	$scope.loadCustomers = function () {

		$scope.error_msg = null;
		$scope.progress_msg = "Loading list of customers with web plans ...";
		$scope.is_load_btn_disabled = true;
		$scope.is_loading = true;

		let url = `${jcs.api.url}/customers?hasWebPlan=true&page=${$scope.customer_page++}&size=${$scope.SIZE}&sort=name&sortDirection=asc`;

		$http.get(url, { withCredentials: true }).then(response => {

			if ($scope.customer_headers == null){
				let headers = response.headers();
				$scope.customer_headers = {
					total_records: parseInt(headers['x-total-records']),
					total_pages: parseInt(headers['x-total-pages']),
					page_size: parseInt(headers['x-page-size']),
				};
			}

			$scope.customers = [...$scope.customers, ...response.data];

			// do we need to continue fetching customers?
			if (!$scope.isDoneLoadingCustomers()){
				$scope.loadCustomers();
			} else {
				$scope.is_loading = false;
				$scope.loadCustomerDetails();
			}

		}).catch(reason => {

			$scope.error_msg = 'An error occurred attempting to download the list of web customers. Please try again, or report the problem if it persists.';
			$scope.progress_msg = null;
			$scope.customers = [];
			$scope.customer_headers = null;
			$scope.customer_page = 0;
			$scope.is_loading = false;
			$scope.is_load_btn_disabled = false;

		});
	};

}

module.exports = WebBillingController;
