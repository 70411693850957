import HardwareDetailsComponent from './hardwareDetails.component';

export const hardwareDetailsModule = {
	name: 'hardwareDetailsComponent',
	component: {
		name: 'hardwareDetails'
	}
};

export default angular
	.module(hardwareDetailsModule.name, [])
	.component(hardwareDetailsModule.component.name, new HardwareDetailsComponent()).name;
