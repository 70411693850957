import React, { FC, useContext, useEffect, useReducer, useState } from 'react';
import { INITIAL_STREAM_PAGE_STATE, STATS_KEYS_STREAM } from '../event-analytics-constants';
import { StreamContext, StreamReducer } from '../../../contexts/eventanalytics';
import StreamAnalyticsView from './stream-analytics-view';
import { StatsArray } from '../types';
import { getHeatMapData } from '../../../components/HeatMap/utils';
import { fetchSummaryStats, fetchMapData } from './api';
import { getStatsGroupsFromResponse } from '../utils';
import { LocationType } from '../../../components/HeatMap/types';
import { EventAnalyticsContext } from '../../../contexts/eventanalytics/event-analytics';

export const StreamAnalytics: FC<{ hasGraphPerms: boolean }> = ({ hasGraphPerms }): JSX.Element => {
  const [streamAnalyticsState, dispatch] = useReducer(StreamReducer, INITIAL_STREAM_PAGE_STATE);
  const { EventAnalyticsState } = useContext(EventAnalyticsContext);
  const [statsArray, setStatsArray] = useState<StatsArray>([]);
  const [heatMapPositions, setHeatMapPositions] = useState<LocationType[]>([]);
  const [isMapLoading, setIsMapLoading] = useState<boolean>(true);
  const [isCardLoading, setCardLoading] = useState<boolean>(true);

  const getData = async () => {
    setIsMapLoading(true);
    setCardLoading(true);
    const statsArray = await fetchSummaryStats(EventAnalyticsState);
    setCardLoading(false);
    const heatMap = await fetchMapData(EventAnalyticsState);
    setIsMapLoading(false);
    setStatsArray(getStatsGroupsFromResponse(STATS_KEYS_STREAM, statsArray[0]));
    setHeatMapPositions(getHeatMapData(heatMap));
  };

  useEffect(() => {
    getData();
  }, [EventAnalyticsState.eventId,EventAnalyticsState.dateRange]);

  return (
    <StreamContext.Provider value={{ streamAnalyticsState, dispatch }}>
      <StreamAnalyticsView
        statsArray={statsArray}
        isCardLoading={isCardLoading}
        hasGraphPerms={hasGraphPerms}
        heatMapData={heatMapPositions}
        isMapLoading={isMapLoading}
      />
    </StreamContext.Provider>
  );
};

StreamAnalytics.displayName = 'StreamAnalytics';

export default StreamAnalytics;
