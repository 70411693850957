import React, { FC, ReactNode } from 'react';
import propTypes from 'prop-types';
import { Modal, ModalHeader, ModalContent } from '..';
import ModalFooter from '../ModalFooter';

interface MoreInfoModalProps {
  title: string;
  closeModal: () => void;
  children: ReactNode;
}

const MoreInfoModal: FC<MoreInfoModalProps> = ({ title, children, closeModal }) => {
  return (
    <Modal>
      <ModalHeader
          titleText={title}
          closeButton={true}
          onClose={closeModal}
        />
      <ModalContent>
        {children}
      </ModalContent>
      <ModalFooter>
        <button className="btn btn-default" type="button" onClick={closeModal}>
          Close
        </button>
      </ModalFooter>
    </Modal>
  )
}

MoreInfoModal.propTypes = {
  title: propTypes.string.isRequired,
  closeModal: propTypes.func.isRequired,
  children: propTypes.node.isRequired,
};

MoreInfoModal.displayName = 'UploadStatusProgressChart';

export default MoreInfoModal;
