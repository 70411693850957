import React, { FC, useContext, useEffect, useState } from 'react';
import { Radio, RadioGroup, LinkButton, PrimaryButton } from '@resi-media/resi-ui';
import { ACTION_TYPE, EventUploadContext } from '../../../EventUploadModal/context';
import { validateRequiredField } from '../../../EventUploadModal/eventUploadHelpers';
import { VALIDATIONS } from '../../../EventUploadModal/eventUploadTypes';
import useUpdateUploadError from '../../../../hooks/use-update-upload-errror';
import { ModalContent, ModalFooter } from '../../..';
import { UploadWizardSteps } from '../../upload-wizard';
import { MODAL_CONTENT_MIN_HEIGHT } from '../../../../constants/wizard-constants';
import { trackMixpanelEvent, MPEventName, MPEventProperty } from '../../../../mixpanel';

enum ShowCreateWebEvent {
  YES,
  NO,
}

enum WhenAvailable {
  IMMEDIATELY,
  NEXT_SIM_LIVE,
}

const Step2WebEventCreate: FC = () => {
  const { state, dispatch } = useContext(EventUploadContext);
  const [enableNext, setEnableNext] = useState(false);
  const { updateError } = useUpdateUploadError();

  useEffect(() => {
    if (!state.showCreateWebEvent) {
      setEnableNext(true);
      return;
    }
    setEnableNext(!!state.webEventProfileUuid);
  }, [state.showCreateWebEvent, state.webEventProfileUuid]);

  const handleWebEventProfile = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target;
    validateRequiredField(value, VALIDATIONS.BLANK_WEB_EVENT_PROFILE, updateError);
    dispatch({ type: ACTION_TYPE.WEB_PROFILE_UUID, payload: value });
  };

  return (
    <>
      <ModalContent minHeight={MODAL_CONTENT_MIN_HEIGHT}>
        <p>If you’d like to stream this content to a web destination, you can automatically create a web event now.</p>
        <label className="control-label resi-upload-wizard-label" htmlFor="createWebEvent">
          Automatically create a web event
        </label>
        <div className="resi-upload-wizard-radio-group-wrap">
          <RadioGroup
            group="createWebEvent"
            onChange={(value) =>
              dispatch({ type: ACTION_TYPE.SHOW_CREATE_WEB_EVENT, payload: value === ShowCreateWebEvent.YES })
            }
            selected={state.showCreateWebEvent ? ShowCreateWebEvent.YES : ShowCreateWebEvent.NO}
          >
            <Radio id="webEventYes" isSelected={state.showCreateWebEvent === true} value={ShowCreateWebEvent.YES}>
              Yes
            </Radio>
            <Radio id="webEventNo" isSelected={state.showCreateWebEvent === false} value={ShowCreateWebEvent.NO}>
              No
            </Radio>
          </RadioGroup>
        </div>
        {state.showCreateWebEvent && (
          <>
            <br />
            <div className="resi-upload-wizard-half-input-wrap" data-testid="web-event-profile-wrap">
              <label className="control-label resi-upload-wizard-label" htmlFor="web-event-profile">
                Web Event Profile
              </label>
              {/* eslint-disable-next-line jsx-a11y/no-onchange */}
              <select
                value={state.webEventProfileUuid}
                disabled={state.isUploading}
                onChange={handleWebEventProfile}
                id="web-event-profile"
                data-testid="event-profiles-select"
                className="form-control la1-evt-upload-input"
              >
                <option value="" disabled></option>
                {state.webEventProfileList.map((x) => (
                  <option value={x.uuid} key={x.uuid}>
                    {x.name}
                  </option>
                ))}
              </select>
            </div>
            <br />
            <label className="control-label resi-upload-wizard-label" htmlFor="createWebEvent">
              Available to view on the embed code and Stream URLs
            </label>
            <div data-testid="view-options" className="resi-upload-wizard-radio-group-wrap">
              <RadioGroup
                group="whenToViewEmbed"
                selected={state.webEventImmediatePublish ? WhenAvailable.IMMEDIATELY : WhenAvailable.NEXT_SIM_LIVE}
                onChange={(value) =>
                  dispatch({
                    type: ACTION_TYPE.WEB_EVENT_IMMEDIATE_PUBLISH,
                    payload: WhenAvailable.IMMEDIATELY === value,
                  })
                }
              >
                <Radio id="view-immediately" value={WhenAvailable.IMMEDIATELY}>
                  Immediately
                </Radio>
                <Radio id="next-scheduled-sim-live" value={WhenAvailable.NEXT_SIM_LIVE}>
                  At the next scheduled sim-live time
                </Radio>
              </RadioGroup>
            </div>
          </>
        )}
      </ModalContent>
      <ModalFooter>
        <div className="la1-evt-upload-m-t-1 float-left">
          <a
            href="https://docs.resi.io/articles/#!easy-upload-video-guide/uploading-a-video-file-in-control"
            target="_blank"
            rel="noopener noreferrer"
          >
            Upload Video Documentation
          </a>
        </div>
        <div className="resi-upload-wizard-footer-wrap split-elements">
          <LinkButton
            onClick={() => dispatch({ type: ACTION_TYPE.SET_WIZARD_STEP, payload: UploadWizardSteps.PROFILE_AND_NAME })}
            label="Back"
          />
          <PrimaryButton
            isDisabled={!enableNext}
            onClick={() => {
              const mixPanelData = {
                [MPEventProperty.ENCODER_EVENT_PROFILE_UUID]: state.eventProfile,
                [MPEventProperty.EVENT_NAME]: state.eventName,
                [MPEventProperty.CREATE_WEB_EVENT]: state.showCreateWebEvent ? 1 : 0,
              };
              if (state.showCreateWebEvent) {
                Object.assign(mixPanelData, {
                  [MPEventProperty.PLAYOUT_BEHAVIOR]: state.webEventImmediatePublish
                    ? 'ASAP Active Immediately'
                    : 'ASAP Next Sim-live',
                  [MPEventProperty.WEB_EVENT_PROFILE_NAME]: state.webEventProfileList?.find(
                    (e) => e.uuid === state.webEventProfileUuid
                  )?.name,
                  [MPEventProperty.WEB_EVENT_PROFILE_UUID]: state.webEventProfileUuid,
                });
              }
              dispatch({ type: ACTION_TYPE.SET_WIZARD_STEP, payload: UploadWizardSteps.UPLOAD_AND_FINISH });
              trackMixpanelEvent(MPEventName.VIDEO_UPLOAD_WEB_CREATE, mixPanelData);
            }}
            label="Next"
          />
        </div>
      </ModalFooter>
    </>
  );
};

Step2WebEventCreate.displayName = 'Step2WebEventCreate';

export default Step2WebEventCreate;
