import 'angular-route';
import angular from 'angular';
import router from './module.cues.routes';

export const cuesModule = {
	name: 'cues',
	controllers: {
		default: 'cuesCtrl',
	},
	routes: {
		list: '/eventprofiles/:streamID/events/:eventID',
	},
};

export default angular
	.module(cuesModule.name, ['ngRoute'])
	.config(router)
	.controller(cuesModule.controllers.default, require('./cuesCtrl')).name;
