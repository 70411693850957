const { getName } = require('country-list');

class CountryCtrl {

	constructor() {
		'ngInject';

		this.country_name = '';
	}

	$onChanges(changes) {

		if (changes.hasOwnProperty('code') && changes.code.currentValue) {
			// if country is undefined, then just use the country code
			this.country_name = getName(changes.code.currentValue) || changes.code.currentValue.toUpperCase();
		}
	}
}

// example usage:
// <country code="CA"></country>
//
export default class CountryComponent {
	constructor() {
		this.template = require('./country.html').default;
		this.bindings = {
			code: '<'
		};
		this.controller = CountryCtrl;
	}
}
