interface WebEventProfileInfo {
  type: string;
  name: string;
  formattedName: string;
}

class WebEventProfileService {

  readonly TYPE_PUBLIC = {name: 'Public', value: 'public'};
  readonly TYPE_RETURN_FEED = {name: 'Return Feed', value: 'return_feed'};
  readonly TYPE_SOCIAL = {name: 'Social', value: 'social'};

  public formatName(profileList: WebEventProfileInfo[]): void {

    for (const profile of profileList) {
      profile.formattedName = profile.name;
      if (profile.type === this.TYPE_RETURN_FEED.value) {
        profile.formattedName += ` [${this.TYPE_RETURN_FEED.name}]`;
      } else if (profile.type === this.TYPE_SOCIAL.value) {
        profile.formattedName += ` [${this.TYPE_SOCIAL.name}]`;
      }
    }

  }

}

export default WebEventProfileService;
