import { StreamContextType } from '../../../contexts/eventanalytics/stream';
import axios from 'axios';
import {
  baseURL,
  DESTINATION_TYPES,
  fixedDateRangeValues,
  GROUP_VALUES,
  MAX_FETCH_LIMIT_FOR_CSV_EXPORT,
  pages,
} from '../event-analytics-constants';
import { API_RESPONSE_TYPE, BracketReference, CsvDataObject, STREAM_DATA_PAGES } from '../types';
import { DateTime, DurationObjectUnits } from 'luxon';
import { EventAnalyticsState } from '../../../contexts/eventanalytics/event-analytics';
import { dateTimeFormatUTC } from '../date-range-options';

export const fetchSummaryStats = async (
  EventAnalyticsState: EventAnalyticsState
): Promise<BracketReference<number | null>[]> => {
  const {
    customerId,
    token,
    eventId,
    dateRange: { startDate, endDate },
    dateRangeOption,
  } = EventAnalyticsState;
  const summaryStatsAPI = `${baseURL}/${customerId}/webevents/statistics/summary`;
  const dateRangeValue = dateRangeOption.value.toString();
  const isFullMonth = fixedDateRangeValues.includes(dateRangeValue);
  try {
    const { data } = await axios.get(summaryStatsAPI, {
      headers: token,
      params: {
        startDate: DateTime.fromISO(startDate).startOf('day').toFormat(dateTimeFormatUTC),
        endDate: DateTime.fromISO(endDate).endOf('day').toFormat(dateTimeFormatUTC),
        destinationType: DESTINATION_TYPES.STREAM,
        isFullMonth,
        ...(eventId && { eventId }),
      },
    });
    return data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const fetchMapData = async (EventAnalyticsState: EventAnalyticsState): Promise<API_RESPONSE_TYPE> => {
  const {
    customerId,
    eventId,
    token,
    dateRange: { startDate, endDate },
  } = EventAnalyticsState;
  const url = `${baseURL}/${customerId}/webevents/statistics/viewers/latlong`;
  const defaultParams = {
    startDate: DateTime.fromISO(startDate).startOf('day').toFormat(dateTimeFormatUTC),
    endDate: DateTime.fromISO(endDate).endOf('day').toFormat(dateTimeFormatUTC),
    destinationType: DESTINATION_TYPES.STREAM,
  };
  const params = {
    ...defaultParams,
    ...(eventId && { eventId }),
  };
  try {
    const { data } = await axios.get(url, {
      headers: token,
      params,
    });
    return data;
  } catch (error) {
    console.error(error);
    return {};
  }
};

export const fetchLineChartData = async (
  EventAnalyticsState: EventAnalyticsState,
  streamAnalyticsState: StreamContextType,
  timeWindowValue: string,
  deviceType: string,
  dataPage: STREAM_DATA_PAGES
): Promise<API_RESPONSE_TYPE> => {
  const { groupBy } = streamAnalyticsState;
  const {
    customerId,
    eventId,
    token,
    dateRange: { startDate, endDate },
  } = EventAnalyticsState;
  const url = `${baseURL}/${customerId}/webevents/statistics/${timeWindowValue}/${pages[dataPage]}`;
  const defaultParams = {
    startDate: DateTime.fromISO(startDate).startOf('day').toFormat(dateTimeFormatUTC),
    endDate: DateTime.fromISO(endDate).endOf('day').toFormat(dateTimeFormatUTC),
    segmentBy: groupBy,
    destinationType: DESTINATION_TYPES.STREAM,
  };
  const params = {
    ...defaultParams,
    ...(eventId && { eventId }),
    ...(groupBy === GROUP_VALUES.DEVICE_TYPE && deviceType && { deviceType }),
    ...(groupBy === GROUP_VALUES.DEVICE_TYPE && deviceType && { top: 5 }),
  };
  try {
    const { data } = await axios.get(url, {
      headers: token,
      params,
    });

    let i = DateTime.fromISO(startDate)
      .startOf(timeWindowValue as keyof DurationObjectUnits)
      .minus({ day: timeWindowValue === 'week' ? 1 : 0 });
    let iFormatted = i.toFormat(timeWindowValue === 'day' ? `yyyy-MM-dd HH:mm:ss` : `yyyy-MM-dd`);
    const sanitizedData = [];

    do {
      sanitizedData.push(
        data.dates.find((d: { date: string }) => d.date === iFormatted) ?? {
          date: iFormatted,
        }
      );
      i = i.plus({ [`${timeWindowValue}s`]: 1 });
      iFormatted = i.toFormat(timeWindowValue === 'day' ? `yyyy-MM-dd HH:mm:ss` : `yyyy-MM-dd`);
    } while (
      i <
      (timeWindowValue === 'day' ? DateTime.fromISO(endDate).endOf('day') : DateTime.fromISO(endDate).startOf('day'))
    );

    return { dates: sanitizedData };
  } catch (error) {
    console.error(error);
    return {};
  }
};

export const fetchData = async (
  EventAnalyticsState: EventAnalyticsState,
  TabValue: string,
  streamAnalyticsState: StreamContextType,
  top?: number
): Promise<API_RESPONSE_TYPE> => {
  const groupBy =
    TabValue === 'deviceType' && streamAnalyticsState.groupBy === 'device_type' ? 'none' : streamAnalyticsState.groupBy;
  const {
    customerId,
    eventId,
    token,
    dateRange: { startDate, endDate },
  } = EventAnalyticsState;
  const url = `${baseURL}/${customerId}/webevents/statistics/viewers/${TabValue}`;
  const defaultParams = {
    segmentBy: groupBy,
    startDate: DateTime.fromISO(startDate).startOf('day').toFormat(dateTimeFormatUTC),
    endDate: DateTime.fromISO(endDate).endOf('day').toFormat(dateTimeFormatUTC),
    destinationType: DESTINATION_TYPES.STREAM,
  };
  const params = { ...defaultParams, ...(eventId && { eventId }), ...(top && { top }) };
  try {
    const { data } = await axios.get(url, {
      headers: token,
      params,
    });
    return data;
  } catch (error) {
    console.error(error);
    return {};
  }
};

export const exportCSVData = async (
  EventAnalyticsState: EventAnalyticsState,
  offset1?: string | number,
  offset2?: string | number
): Promise<CsvDataObject[]> => {
  const {
    customerId,
    eventId,
    token,
    dateRange: { startDate, endDate },
  } = EventAnalyticsState;
  const url = `${baseURL}/${customerId}/webevents/export`;
  let offset;
  if (offset1 && offset2) {
    offset = `${offset1},${offset2}`;
  }
  const defaultParams = {
    startDate: DateTime.fromISO(startDate).startOf('day').toFormat(dateTimeFormatUTC),
    endDate: DateTime.fromISO(endDate).endOf('day').toFormat(dateTimeFormatUTC),
    destinationType: DESTINATION_TYPES.STREAM,
    max: MAX_FETCH_LIMIT_FOR_CSV_EXPORT,
  };
  const params = {
    ...defaultParams,
    ...(eventId && { eventId }),
    ...(offset && { offset }),
  };
  try {
    const { data } = await axios.get(url, {
      headers: token,
      params,
    });
    return data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const fetchMinByMinData = async (
  EventAnalyticsState: EventAnalyticsState,
  streamAnalyticsState: StreamContextType,
  graphInterval: number
): Promise<API_RESPONSE_TYPE> => {
  const {
    customerId,
    eventId,
    token,
    dateRange: { startDate, endDate },
  } = EventAnalyticsState;
  const url = `${baseURL}/${customerId}/webevents/statistics/events/${eventId}/minByMin`;
  const defaultParams = {
    startDate: DateTime.fromISO(startDate).startOf('day').toFormat(dateTimeFormatUTC),
    endDate: DateTime.fromISO(endDate).endOf('day').toFormat(dateTimeFormatUTC),
    segmentBy: streamAnalyticsState.groupBy,
    destinationType: DESTINATION_TYPES.STREAM,
    graphInterval,
  };
  const params = { ...defaultParams };
  try {
    const { data } = await axios.get(url, {
      headers: token,
      params,
    });
    return data;
  } catch (error) {
    console.error(error);
    return {};
  }
};
