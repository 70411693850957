import React, { FC } from 'react';
import PropTypes from 'prop-types';
import { Text, customProperties as vars } from '@resi-media/resi-ui';
import { InfoBoxContainer, CloseCircleFilledWrapper, InfoWrapper } from './style';
import { CloseCircleFilled, InfoCircleOutlined } from '@ant-design/icons';

interface InfoBoxProps {
  infoText: string;
  closeButton?: boolean;
  onClose?: () => void;
}

const InfoBox: FC<InfoBoxProps> = ({ infoText, closeButton, onClose }) => {
  return (
    <InfoBoxContainer>
      <InfoWrapper>
        <InfoCircleOutlined style={{ fontSize: 16, padding: vars.spacingS }} />
        <Text.Body size="m">{` ${infoText} `}</Text.Body>
      </InfoWrapper>
      {closeButton && (
        <CloseCircleFilledWrapper>
          <CloseCircleFilled style={{ fontSize: 16 }} onClick={onClose} />
        </CloseCircleFilledWrapper>
      )}
    </InfoBoxContainer>
  );
};

InfoBox.displayName = 'InfoBox';
InfoBox.propTypes = {
  infoText: PropTypes.string.isRequired,
  onClose: PropTypes.func,
  closeButton: PropTypes.bool,
};

export default InfoBox;
