import Chart from 'chart.js/auto';
import { COLOR_SET, LEGEND_NAMES, API_RESPONSE_LEGENDS } from '../../../pages/EventAnalytics/event-analytics-constants';

interface BarChartDataType {
  labels: string[];
  datasets: {
    [key: string]: number[];
  };
}

export const getStackedBarChartProps = (
  data: BarChartDataType | null,
  isFixedYAxisChart = false,
): Chart.ChartData => {
  let datasets: Chart.ChartData = [];
  const barPercentage = 0.5;
  const categoryPercentage = 1;
  const maxBarThickness = 25;
  if (isFixedYAxisChart) {
    datasets = Object.keys(data?.datasets || {}).map((item) => {
      return {
        label: item === API_RESPONSE_LEGENDS.NONE ? LEGEND_NAMES.totalViewers : item,
        borderWidth: 0,
        backgroundColor: 'rgba(255, 255, 255, 0)',
        borderColor: 'rgba(255, 255, 255, 0)',
        hoverBackgroundColor: 'rgba(255, 255, 255, 0)',
        barPercentage: barPercentage,
        categoryPercentage : categoryPercentage,
        maxBarThickness: maxBarThickness,
        data: data?.datasets?.[item],
      };
    });
  } else {
    datasets = Object.keys(data?.datasets || {}).map((item, _index) => {
      return {
        label: item === API_RESPONSE_LEGENDS.NONE ? LEGEND_NAMES.totalViewers : item,
        borderWidth: 0,
        backgroundColor: COLOR_SET[_index]?.baseColor,
        borderColor: COLOR_SET[_index]?.baseColor,
        hoverBackgroundColor: COLOR_SET[_index]?.baseColor,
        barPercentage: barPercentage,
        categoryPercentage : categoryPercentage,
        maxBarThickness: maxBarThickness,
        data: data?.datasets?.[item],
      };
    });
  }

  return {
    labels: data?.labels,
    datasets,
  };
};

export const getBarChartProps = (
  data: BarChartDataType | null,
  isFixedYAxisChart = false,
  label?: string,
): Chart.ChartData => {
  let datasets: Chart.ChartData = [];
  const barPercentage = 0.5;
  const categoryPercentage = 1;
  const maxBarThickness = 25;
  if (isFixedYAxisChart) {
    datasets = [
      {
        label: label ?? LEGEND_NAMES.totalViewers,
        borderWidth: 0,
        backgroundColor: 'rgba(255, 255, 255, 0)',
        borderColor: 'rgba(255, 255, 255, 0)',
        hoverBackgroundColor: 'rgba(255, 255, 255, 0)',
        barPercentage: barPercentage,
        categoryPercentage : categoryPercentage,
        maxBarThickness: maxBarThickness,
        data: data?.datasets[API_RESPONSE_LEGENDS.NONE] || [],
      },
    ];
  } else {
    datasets = [
      {
        label: label ?? LEGEND_NAMES.totalViewers,
        borderWidth: 0,
        backgroundColor: COLOR_SET[0].baseColor,
        borderColor: COLOR_SET[0].baseColor,
        hoverBackgroundColor: COLOR_SET[0].baseColor,
        barPercentage: barPercentage,
        categoryPercentage : categoryPercentage,
        maxBarThickness: maxBarThickness,
        data: data?.datasets[API_RESPONSE_LEGENDS.NONE] || [],
      },
    ];
  }
  return {
    labels: data?.labels,
    datasets,
  };
};

export const calculateWidth = (data: BarChartDataType): number => {
  const numberOfLabels = data.labels.length;
  switch (true) {
    case numberOfLabels >= 0 && numberOfLabels <= 22:
      return 800;
    case numberOfLabels >= 23 && numberOfLabels <= 30:
      return 1000;
    case numberOfLabels >= 31 && numberOfLabels <= 40:
      return 1300;
    case numberOfLabels >= 41 && numberOfLabels <= 50:
      return 1600;
    case numberOfLabels >= 51 && numberOfLabels <= 60:
      return 2000;
    case numberOfLabels >= 61 && numberOfLabels <= 70:
      return 2500;
    case numberOfLabels >= 71 && numberOfLabels <= 80:
      return 3000;
    case numberOfLabels >= 81 && numberOfLabels <= 90:
      return 3500;
    default:
      return 4000;
  }
};
