import json2csv, { parseAsync } from 'json2csv';

class CsvService {
  public generateCsvFromJson(
    data: import('stream').Readable | Readonly<unknown> | readonly unknown[],
    opts?: json2csv.Options<unknown>
  ): Promise<string> {
    return parseAsync(data, opts);
  }
}

export default CsvService;
