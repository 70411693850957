import 'angular-route';
import 'angular-cookies';
import angular from 'angular';
import LoginController from './controllers/login.controller';
import router from './module.auth.routes';
import authServices from './services';
import commonServicesModule from '../../services';
import authRun from './module.auth.run';

export const authModule = {
	name: 'auth',
	controllers: {
		login: 'LoginController'
	},
	events: {
		userLoggedIn: 'auth:user:loggedIn',
		userLoggedOut: 'auth:user:loggedOut',
	},
	routes: {
		login: '/login',
		notAuthorised: '/not-authorised',
	},
};

export default angular
	.module('auth.login', ['ngRoute', 'ngCookies', authServices, commonServicesModule, authRun])
	.config(router)
	.controller(authModule.controllers.login, LoginController).name;
