import styled from '@emotion/styled';
import { customProperties as vars } from '@resi-media/resi-ui';

export const FOOTER_VERTICAL_PADDING = '1.5em';

export const FooterWrap = styled.div`
  font-size: ${vars.fontSize10};
  text-align: center;
  height: calc(3vh + ${FOOTER_VERTICAL_PADDING});
  padding: 1.5em 1.5em 0 1.5em;

  @media (min-width: ${vars.breakPointTablet}) {
    font-size: ${vars.fontSize14};
  }
`;

export const Innerwrap = styled.div`
  position: relative;
`;

export const Link = styled.a`
  color: ${vars.colorError};
  cursor: pointer;
`;

export const Version = styled.div`
  display: inline;
  margin-left: ${vars.spacingS};

  @media (min-width: ${vars.breakPointTablet}) {
    display: block;
    position: absolute;
    right: 0;
    top: 0;
  }
`;
