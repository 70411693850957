import styled from '@emotion/styled';

export const LineChartWrapper = styled.div`
  position: relative;
  height: 80%;
  width: 99%;
`;

export const LineChartLegendWrapper = styled.div`
  margin: 10px 0 0 0;
`;

export const LineChartCanvas = styled.canvas`
  position: relative;
`;

export const LineChartLegendList = styled.ul`
  display: flex;
  flexdirection: row;
  margin: 0;
  padding: 0;
`;