class DurationCtrl {

	constructor() {
		'ngInject';

		this.duration_formatted = '';
	}

	getFormattedDuration (duration_in_minutes) {
		let hours = parseInt(duration_in_minutes / 60);
		let minutes = duration_in_minutes % 60;

		let text = '';

		if (hours > 0){
			let hours_text = hours == 1 ? 'hour' : 'hours';
			text += `${hours} ${hours_text}`;
		}
		if (minutes > 0) {
			if (text.length > 0) {
				text += ' ';
			}
			let minutes_text = minutes == 1 ? 'minute' : 'minutes';
			text += `${minutes} ${minutes_text}`;
		}

		return text;
	}

	convertTimeToMinutes (hours, minutes) {
		let duration_in_minutes = hours ? parseInt(hours) * 60 : 0;
		if (minutes) {
			duration_in_minutes += parseInt(minutes);
		}
		return duration_in_minutes;
	}

	getFormattedDurationForHoursMin (duration_hours, duration_minutes) {
		let duration_in_minutes = this.convertTimeToMinutes(duration_hours, duration_minutes);
		return this.getFormattedDuration(duration_in_minutes);
	}

	$onInit () {
		this.duration_formatted = this.getFormattedDurationForHoursMin(this.hours, this.minutes);
	}

	$onChanges(changes) {
		this.duration_formatted = this.getFormattedDurationForHoursMin(this.hours, this.minutes);
	}
}

// example usage:
// <duration hours="1" minutes="13"></duration>
//
export default class DurationComponent {
	constructor() {
		this.template = require('./duration.html').default;
		this.bindings = {
			minutes: '<',
			hours: '<',
		};
		this.controller = DurationCtrl;
	}
}
