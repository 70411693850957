import HardwarePrintButtonComponent from './hardwarePrintButton.component';

export const hardwarePrintButtonModule = {
	name: 'hardwarePrintButtonComponent',
	component: {
		name: 'hardwarePrintButton'
	}
};

export default angular
	.module(hardwarePrintButtonModule.name, [])
	.component(hardwarePrintButtonModule.component.name, new HardwarePrintButtonComponent()).name;
