import { ExpandAltOutlined, ExportOutlined } from '@ant-design/icons';
import { Paper } from '@material-ui/core';
import {
  Inline,
  PrimaryButton,
  FieldLabel,
  TextField,
  Grid,
  Select,
  Text,
  customProperties as vars,
} from '@resi-media/resi-ui';
import Link from '@material-ui/core/Link';
import Tooltip from '@material-ui/core/Tooltip';
import clone from 'lodash.clone';
import cloneDeep from 'lodash.clonedeep';
import startCase from 'lodash/startCase';
import PropTypes from 'prop-types';
import React, { ChangeEvent, FC, useContext, useEffect, useState } from 'react';
import {
  BarChart,
  DoughnutChart,
  FillBlockSpinner,
  ScrollableBarChart,
  VerticalTab,
  VerticalTabs,
  VirtualizedTable,
} from '../../../../components';
import Modal from '../../../../components/Modal';
import ModalHeader from '../../../../components/ModalHeader';
import { VirtualizedTableData } from '../../../../components/VirtualizedTable/types';
import { EmbedPlayerContext } from '../../../../contexts/eventanalytics';
import { EventAnalyticsContext } from '../../../../contexts/eventanalytics/event-analytics';
import { MPEventName, MPEventProperty, trackMixpanelEvent } from '../../../../mixpanel';
import {
  CHART_TYPES,
  EXTRA_INFO,
  GROUP_VALUES,
  LEGEND_NAMES,
  LOADER_SIZE_COMPONENT,
  LOADER_SIZE_SUB_COMPONENT,
  locationTabHeaders,
  LOCATION_VALUES,
  SUB_LOCATIONS,
  SUB_LOCATIONS_ENUM,
  tabHeaders,
  TABLE_HEADER_EMBED,
  TAB_VALUES_EMBED,
} from '../../event-analytics-constants';
import { ExpandButtonWrapper, LinkButtonContainer } from '../../event-analytics.styles';
import {
  barChartDataType,
  doughnutChartDataType,
  EMBED_EXPANDBTN_COMBINATIONS_TYPE,
  EMBED_TAB_VALUES,
  EVENT_TYPES,
  formatTableDataInputParamsType,
  LOCATION_TAB_VALUES,
  SelectOption,
} from '../../types';
import {
  convertTableToCSV,
  downloadAsCSV,
  getCSVData,
  getIndicesWithValues,
  getLocationCSVData,
  renameKey,
} from '../../utils';
import { fetchData } from '../api';
import {
  ChartContent,
  ChartWrapper,
  CustomRangeDiv,
  CustomRangeStyles,
  DropDownDiv,
  DropDownWrapper,
  HeaderDiv,
  HorizontalLine,
  LocationTabsDiv,
  ModalWrapper,
  PrimaryButtonWrapper,
  RangeFieldDiv,
  SegmentedStatsModalContent,
  SegmentedStatsWrapper,
  TableContent,
} from './embed-segmented-stats.styles';
import { watchTimeDataNormal } from './utils';

interface EmbedSegmentedStatsViewPropType {
  eventValue: EVENT_TYPES;
  tabValue: EMBED_TAB_VALUES;
  onTabChange: (event: ChangeEvent<unknown>, newValue: EMBED_TAB_VALUES) => void;
  chartData?: doughnutChartDataType[] | barChartDataType;
  watchTimeTableStats?: barChartDataType;
  top10LocationChartData?: doughnutChartDataType[] | barChartDataType;
  locationTabValue: LOCATION_TAB_VALUES;
  locationOnTabChange: (event: ChangeEvent<unknown>, newValue: LOCATION_TAB_VALUES) => void;
  showLocationTab: boolean;
  isChartLoading: boolean;
  minTimeVal: string;
  maxTimeVal: string;
  watchTimeDropDownOptions: SelectOption[];
  expandChartOpen: boolean;
  setExpandChartOpen: (value: boolean) => void;
}

const EmbedSegmentedStatsView: FC<EmbedSegmentedStatsViewPropType> = ({
  eventValue,
  tabValue,
  onTabChange,
  chartData,
  watchTimeTableStats,
  top10LocationChartData,
  locationTabValue,
  locationOnTabChange,
  showLocationTab,
  isChartLoading,
  minTimeVal,
  maxTimeVal,
  watchTimeDropDownOptions,
  expandChartOpen,
  setExpandChartOpen,
}): JSX.Element => {
  const { embedPlayerAnalyticsState } = useContext(EmbedPlayerContext);
  const { EventAnalyticsState } = useContext(EventAnalyticsContext);
  const [customRangeDisabled, setCustomRangeDisabled] = useState<boolean>(true);
  const [startTime, setStartTime] = useState<string>('');
  const [endTime, setEndTime] = useState<string>('');
  const [startTimeValue, setStartTimeValue] = useState<string>('');
  const [endTimeValue, setEndTimeValue] = useState<string>('');
  const [isExportCSVLoading, setExportCSVLoading] = useState<boolean>(false);
  let tableDataCSV: string;
  let legendCount = 0;
  const eventId = window.location.pathname.split('/').filter(Boolean)[1];

  useEffect(() => {
    setStartTime(minTimeVal);
    setEndTime(maxTimeVal);
    setStartTimeValue(minTimeVal);
    setEndTimeValue(maxTimeVal);
    setCustomRangeDisabled(true);
  }, [minTimeVal, maxTimeVal, embedPlayerAnalyticsState, EventAnalyticsState]);

  const formatTableData = ({
    inputChartData,
    chartType,
    lastColumnLabel,
    groupBy,
    segmentBy,
  }: formatTableDataInputParamsType): VirtualizedTableData => {
    let tableData: VirtualizedTableData = { header: [], data: [] };

    const formatDoughnutTableData = (inputChartData: doughnutChartDataType[]): VirtualizedTableData => {
      const doughnutTableData: VirtualizedTableData = { header: [], data: [] };
      doughnutTableData.header = [
        { label: segmentBy?.toLowerCase().replace(/^[a-zA-z]|\s(.)/gi, (L) => L.toUpperCase()) },
        { label: lastColumnLabel ?? 'Viewers' },
      ];
      inputChartData?.map((item: doughnutChartDataType) => {
        doughnutTableData.data.push({ row: [item.label, item.data] });
      });
      return doughnutTableData;
    };

    const formatBarTableData = (inputChartData: barChartDataType): VirtualizedTableData => {
      const barTableData: VirtualizedTableData = { header: [], data: [] };
      const labels: string[] = inputChartData.labels;
      const legends: string[] = Object.keys(inputChartData.datasets);
      const extraInfo: string[] = inputChartData[EXTRA_INFO] || [];
      groupBy === GROUP_VALUES.NONE
        ? (barTableData.header = [{ label: segmentBy }, { label: lastColumnLabel ?? 'Viewers' }])
        : (barTableData.header = [
            {
              label: groupBy
                .toLowerCase()
                .replace(/^[a-zA-z]|\s(.)/gi, (L) => L.toUpperCase())
                .replace('_type', ''),
            },
            { label: segmentBy },
            { label: lastColumnLabel ?? 'Viewers' },
          ]);
      for (let i = 0; i < labels.length; i++) {
        for (let j = 0; j < legends.length; j++) {
          if (groupBy === GROUP_VALUES.NONE) {
            barTableData.data.push({ row: [labels[i], inputChartData.datasets[legends[j]][i]] });
          } else {
            barTableData.data.push({
              row: [
                startCase(legends[j]),
                inputChartData[EXTRA_INFO].length > 0 ? `${labels[i]}, ${extraInfo[i]}` : labels[i],
                inputChartData.datasets[legends[j]][i] ? inputChartData.datasets[legends[j]][i] : 0,
              ],
            });
          }
        }
      }
      return barTableData;
    };

    if (inputChartData !== null) {
      if (chartType === CHART_TYPES.DOUGHNUT) {
        tableData = formatDoughnutTableData(inputChartData as doughnutChartDataType[]);
      } else if (chartType === CHART_TYPES.BAR) {
        tableData = formatBarTableData(inputChartData as barChartDataType);
      }
    }
    tableDataCSV = convertTableToCSV(
      tableData.header,
      tableData.data.map((rowData) => rowData.row)
    );
    return tableData;
  };

  const renderExpandBtnState = (
    eventValue: EVENT_TYPES,
    tabValue: EMBED_TAB_VALUES,
    locationTabValue: LOCATION_TAB_VALUES,
    showLocationTab: boolean
  ): boolean => {
    const expandBtnIsDisabledCombinations: EMBED_EXPANDBTN_COMBINATIONS_TYPE = {
      [GROUP_VALUES.NONE]: {
        [TAB_VALUES_EMBED.RESOLUTION]: true,
        [TAB_VALUES_EMBED.WATCH_TIME]: false,
        [TAB_VALUES_EMBED.DEVICE_TYPE]: true,
        [TAB_VALUES_EMBED.LOCATION]: {
          [LOCATION_VALUES.COUNTRY]: false,
          [LOCATION_VALUES.REGION]: false,
          [LOCATION_VALUES.CITY]: false,
        },
      },
      [GROUP_VALUES.EVENT_TYPE]: {
        [TAB_VALUES_EMBED.RESOLUTION]: true,
        [TAB_VALUES_EMBED.WATCH_TIME]: false,
        [TAB_VALUES_EMBED.DEVICE_TYPE]: true,
        [TAB_VALUES_EMBED.LOCATION]: {
          [LOCATION_VALUES.COUNTRY]: false,
          [LOCATION_VALUES.REGION]: false,
          [LOCATION_VALUES.CITY]: false,
        },
      },
      [GROUP_VALUES.DEVICE_TYPE]: {
        [TAB_VALUES_EMBED.RESOLUTION]: true,
        [TAB_VALUES_EMBED.WATCH_TIME]: false,
        [TAB_VALUES_EMBED.DEVICE_TYPE]: true,
        [TAB_VALUES_EMBED.LOCATION]: {
          [LOCATION_VALUES.COUNTRY]: false,
          [LOCATION_VALUES.REGION]: false,
          [LOCATION_VALUES.CITY]: false,
        },
      },
    };
    if (!showLocationTab && tabValue !== TAB_VALUES_EMBED.LOCATION) {
      return expandBtnIsDisabledCombinations[eventValue][tabValue];
    } else {
      return expandBtnIsDisabledCombinations[eventValue][TAB_VALUES_EMBED.LOCATION][locationTabValue];
    }
  };

  type renderChartandTableType = {
    chartNormal: JSX.Element;
    chartExpanded: JSX.Element;
    table: JSX.Element;
  };
  const renderChartandTable = (): renderChartandTableType => {
    let chartNormal: JSX.Element, chartExpanded: JSX.Element, table: JSX.Element;
    const subLocations = [SUB_LOCATIONS.COUNTRY, SUB_LOCATIONS.REGION, SUB_LOCATIONS.CITY];

    chartNormal = <div />;
    chartExpanded = <div />;
    table = <div />;

    let doughnutChartData: doughnutChartDataType[] = [],
      top10LocationDoughnutChartData: doughnutChartDataType[] = [],
      barChartData: barChartDataType = { labels: [], extraInfo: [], datasets: {} },
      top10LocationBarChartData: barChartDataType = { labels: [], extraInfo: [], datasets: {} };
    if (chartData) {
      if (chartData.constructor === Array) {
        doughnutChartData = chartData;
        top10LocationDoughnutChartData = top10LocationChartData as doughnutChartDataType[];
        legendCount = top10LocationDoughnutChartData?.length;
        doughnutChartData.map((el) => (el.label = el.label in LEGEND_NAMES ? `${el.label}` : el.label));
      } else if (eventValue === GROUP_VALUES.NONE && tabValue === TAB_VALUES_EMBED.LOCATION) {
        top10LocationDoughnutChartData = top10LocationChartData as doughnutChartDataType[];
        legendCount = top10LocationDoughnutChartData?.length;
        barChartData = chartData as barChartDataType;
        Object.keys(barChartData.datasets).map((el) => {
          if (el in LEGEND_NAMES) {
            barChartData.datasets = renameKey(barChartData.datasets, el, `${el}`);
          }
        });
      } else {
        barChartData = chartData as barChartDataType;
        Object.keys(barChartData.datasets).map((el) => {
          if (el in LEGEND_NAMES) {
            barChartData.datasets = renameKey(barChartData.datasets, el, `${el}`);
          }
        });
        top10LocationBarChartData = top10LocationChartData as barChartDataType;
        legendCount = top10LocationBarChartData?.labels?.length;
        if (top10LocationBarChartData) {
          Object.keys(top10LocationBarChartData.datasets).map((el) => {
            if (el in LEGEND_NAMES) {
              top10LocationBarChartData.datasets = renameKey(top10LocationBarChartData.datasets, el, `${el}`);
            }
          });
        }
      }
    }

    chartNormal = <div />;
    chartExpanded = <div />;
    table = <div />;
    // None Type Combinations
    if (eventValue === GROUP_VALUES.NONE) {
      if (tabValue === TAB_VALUES_EMBED.RESOLUTION) {
        // Resolution
        chartNormal = (
          <DoughnutChart
            key={`${eventValue}${tabValue}${locationTabValue}`}
            data={doughnutChartData}
            tooltipLabel="Viewers"
            isLegendOnRightSide
            showPercentage
          />
        );
        table = (
          <VirtualizedTable
            data={formatTableData({
              inputChartData: doughnutChartData,
              chartType: CHART_TYPES.DOUGHNUT,
              groupBy: GROUP_VALUES.NONE,
              segmentBy: TABLE_HEADER_EMBED.RESOLUTION,
            })}
          />
        );
      } else if (tabValue === TAB_VALUES_EMBED.WATCH_TIME) {
        // Watch Time
        if (!expandChartOpen) {
          chartNormal = (
            <ChartContent>
              <BarChart
                key={`${eventValue}${tabValue}${locationTabValue}`}
                data={watchTimeDataNormal(barChartData)}
                label={LEGEND_NAMES.totalViews}
                tooltipLabel="Views"
              />
            </ChartContent>
          );
          table = (
            <VirtualizedTable
              data={formatTableData({
                inputChartData: watchTimeTableStats as barChartDataType,
                chartType: CHART_TYPES.BAR,
                groupBy: GROUP_VALUES.NONE,
                segmentBy: TABLE_HEADER_EMBED.WATCH_TIME,
                lastColumnLabel: 'Views'
              })}
            />
          );
        } else {
          const customRangeChartData: barChartDataType = cloneDeep(barChartData);
          const { labels: inputLabels } = barChartData;
          const times = getIndicesWithValues(startTimeValue, endTimeValue, inputLabels);
          const sliceStart = barChartData.labels.indexOf(times[0]);
          const sliceEnd = barChartData.labels.indexOf(times[1]) + 1;
          customRangeChartData.labels = customRangeChartData.labels.slice(sliceStart, sliceEnd);
          Object.keys(customRangeChartData.datasets).forEach((key: string) => {
            const dataset = customRangeChartData.datasets[key];
            customRangeChartData.datasets[key] = dataset.slice(
              barChartData.labels.indexOf(times[0]) === -1 ? 0 : barChartData.labels.indexOf(times[0]),
              barChartData.labels.indexOf(times[1]) === -1
                ? barChartData.labels.length - 1
                : barChartData.labels.indexOf(times[1]) + 1
            );
          });
          if (expandChartOpen && customRangeChartData.labels.length > 10) {
            chartExpanded = (
              <ScrollableBarChart
                key={`${startTimeValue}, ${endTimeValue}`}
                data={customRangeChartData}
                label={LEGEND_NAMES.totalViews}
                stacked={false}
                tooltipLabel="Views"
              />
            );
          } else if (expandChartOpen) {
            chartExpanded = (
              <ChartContent>
                <BarChart
                  key={`${startTimeValue}, ${endTimeValue}`}
                  data={customRangeChartData}
                  label={LEGEND_NAMES.totalViews}
                  stacked={false}
                  tooltipLabel="Views"
                />
              </ChartContent>
            );
          }
        }
      } else if (tabValue === TAB_VALUES_EMBED.DEVICE_TYPE) {
        // Device Type
        chartNormal = (
          <DoughnutChart
            key={`${eventValue}${tabValue}${locationTabValue}`}
            data={doughnutChartData}
            tooltipLabel="Viewers"
            isLegendOnRightSide
            showPercentage
          />
        );
        table = (
          <VirtualizedTable
            data={formatTableData({
              inputChartData: doughnutChartData,
              chartType: CHART_TYPES.DOUGHNUT,
              groupBy: GROUP_VALUES.NONE,
              segmentBy: TABLE_HEADER_EMBED.DEVICE_TYPE,
            })}
          />
        );
      } else if (tabValue === TAB_VALUES_EMBED.LOCATION) {
        // Location
        chartNormal = (
          <DoughnutChart
            key={`${eventValue}${tabValue}${locationTabValue}`}
            data={top10LocationDoughnutChartData}
            tooltipLabel="Viewers"
            isLegendOnRightSide
            showPercentage={false}
          />
        );
        if (expandChartOpen) {
          chartExpanded = (
            <ScrollableBarChart
              key={`${eventValue}${tabValue}${locationTabValue}`}
              data={barChartData}
              stacked={false}
              tooltipLabel="Viewers"
            />
          );
        }
        table = (
          <VirtualizedTable
            data={formatTableData({
              inputChartData: top10LocationDoughnutChartData,
              chartType: CHART_TYPES.DOUGHNUT,
              groupBy: GROUP_VALUES.NONE,
              segmentBy: subLocations[locationTabValue],
            })}
          />
        );
      }
    }

    // Event Type Combinations
    else if (eventValue === GROUP_VALUES.EVENT_TYPE) {
      if (tabValue === TAB_VALUES_EMBED.RESOLUTION) {
        // Resolution
        chartNormal = (
          <ChartContent>
            <BarChart
              key={`${eventValue}${tabValue}${locationTabValue}`}
              data={barChartData}
              stacked
              tooltipLabel="Viewers"
            />
          </ChartContent>
        );
        table = (
          <VirtualizedTable
            data={formatTableData({
              inputChartData: barChartData,
              chartType: CHART_TYPES.BAR,
              groupBy: GROUP_VALUES.EVENT_TYPE,
              segmentBy: TABLE_HEADER_EMBED.RESOLUTION,
            })}
          />
        );
      } else if (tabValue === TAB_VALUES_EMBED.WATCH_TIME) {
        // Watch Time
        if (!expandChartOpen) {
          chartNormal = (
            <ChartContent>
              <BarChart
                key={`${eventValue}${tabValue}${locationTabValue}`}
                data={watchTimeDataNormal(barChartData)}
                stacked
                tooltipLabel="Views"
              />
            </ChartContent>
          );
          table = (
            <VirtualizedTable
              data={formatTableData({
                inputChartData: watchTimeTableStats as barChartDataType,
                chartType: CHART_TYPES.BAR,
                groupBy: GROUP_VALUES.EVENT_TYPE,
                segmentBy: TABLE_HEADER_EMBED.WATCH_TIME,
                lastColumnLabel: 'Views'
              })}
            />
          );
        } else {
          const customRangeChartData: barChartDataType = cloneDeep(barChartData);
          const { labels: inputLabels } = barChartData;
          const times = getIndicesWithValues(startTimeValue, endTimeValue, inputLabels);
          const sliceStart = barChartData.labels.indexOf(times[0]);
          const sliceEnd = barChartData.labels.indexOf(times[1]) + 1;
          customRangeChartData.labels = customRangeChartData.labels.slice(sliceStart, sliceEnd);
          Object.keys(customRangeChartData.datasets).forEach((key: string) => {
            const dataset = customRangeChartData.datasets[key];
            customRangeChartData.datasets[key] = dataset.slice(
              barChartData.labels.indexOf(times[0]) === -1 ? 0 : barChartData.labels.indexOf(times[0]),
              barChartData.labels.indexOf(times[1]) === -1
                ? barChartData.labels.length - 1
                : barChartData.labels.indexOf(times[1]) + 1
            );
          });
          if (expandChartOpen && customRangeChartData.labels.length > 10) {
            chartExpanded = (
              <ScrollableBarChart
                key={`${startTimeValue}, ${endTimeValue}`}
                data={customRangeChartData}
                stacked
                tooltipLabel="Viewers"
              />
            );
          } else if (expandChartOpen) {
            chartExpanded = (
              <ChartContent>
                <BarChart
                  key={`${startTimeValue}, ${endTimeValue}`}
                  data={customRangeChartData}
                  stacked
                  tooltipLabel="Viewers"
                />
              </ChartContent>
            );
          }
        }
      } else if (tabValue === TAB_VALUES_EMBED.DEVICE_TYPE) {
        // Device Type
        chartNormal = (
          <ChartContent>
            <BarChart
              key={`${eventValue}${tabValue}${locationTabValue}`}
              data={barChartData}
              stacked
              tooltipLabel="Viewers"
            />
          </ChartContent>
        );
        table = (
          <VirtualizedTable
            data={formatTableData({
              inputChartData: barChartData,
              chartType: CHART_TYPES.BAR,
              groupBy: GROUP_VALUES.EVENT_TYPE,
              segmentBy: TABLE_HEADER_EMBED.DEVICE_TYPE,
            })}
          />
        );
      } else if (tabValue === TAB_VALUES_EMBED.LOCATION) {
        // Location
        chartNormal = (
          <ChartContent>
            <BarChart
              key={`${eventValue}${tabValue}${locationTabValue}`}
              data={top10LocationBarChartData}
              stacked
              tooltipLabel="Viewers"
            />
          </ChartContent>
        );
        const findLabelsLength: barChartDataType = clone(barChartData);
        if (expandChartOpen && findLabelsLength.labels.length > 10) {
          chartExpanded = (
            <ScrollableBarChart
              key={`${eventValue}${tabValue}${locationTabValue}`}
              data={barChartData}
              stacked
              tooltipLabel="Viewers"
            />
          );
        } else if (expandChartOpen) {
          chartExpanded = (
            <ChartContent>
              <BarChart
                key={`${eventValue}${tabValue}${locationTabValue}`}
                data={barChartData}
                stacked
                tooltipLabel="Viewers"
              />
            </ChartContent>
          );
        }
        table = (
          <VirtualizedTable
            data={formatTableData({
              inputChartData: top10LocationBarChartData,
              chartType: CHART_TYPES.BAR,
              groupBy: GROUP_VALUES.EVENT_TYPE,
              segmentBy: subLocations[locationTabValue],
            })}
          />
        );
      }
    }

    // Device Type Combinations
    else if (eventValue === GROUP_VALUES.DEVICE_TYPE) {
      if (tabValue === TAB_VALUES_EMBED.RESOLUTION) {
        // Resolution
        chartNormal = (
          <ChartContent>
            <BarChart
              key={`${eventValue}${tabValue}${locationTabValue}`}
              data={barChartData}
              stacked
              tooltipLabel="Viewers"
            />
          </ChartContent>
        );
        table = (
          <VirtualizedTable
            data={formatTableData({
              inputChartData: barChartData,
              chartType: CHART_TYPES.BAR,
              groupBy: GROUP_VALUES.DEVICE_TYPE,
              segmentBy: TABLE_HEADER_EMBED.RESOLUTION,
            })}
          />
        );
      } else if (tabValue === TAB_VALUES_EMBED.WATCH_TIME) {
        // Watch Time
        if (!expandChartOpen) {
          chartNormal = (
            <ChartContent>
              <BarChart
                key={`${eventValue}${tabValue}${locationTabValue}`}
                data={watchTimeDataNormal(barChartData)}
                stacked
                tooltipLabel="Views"
              />
            </ChartContent>
          );
        } else {
          const customRangeChartData: barChartDataType = cloneDeep(barChartData);
          const { labels: inputLabels } = barChartData;
          const times = getIndicesWithValues(startTimeValue, endTimeValue, inputLabels);
          const sliceStart = barChartData.labels.indexOf(times[0]);
          const sliceEnd = barChartData.labels.indexOf(times[1]) + 1;
          customRangeChartData.labels = customRangeChartData.labels.slice(sliceStart, sliceEnd);
          Object.keys(customRangeChartData.datasets).forEach((key: string) => {
            const dataset = customRangeChartData.datasets[key];
            customRangeChartData.datasets[key] = dataset.slice(
              barChartData.labels.indexOf(times[0]) === -1 ? 0 : barChartData.labels.indexOf(times[0]),
              barChartData.labels.indexOf(times[1]) === -1
                ? barChartData.labels.length - 1
                : barChartData.labels.indexOf(times[1]) + 1
            );
          });
          if (expandChartOpen && customRangeChartData.labels.length > 10) {
            chartExpanded = (
              <ScrollableBarChart
                key={`${startTimeValue}, ${endTimeValue}`}
                data={customRangeChartData}
                stacked
                tooltipLabel="Viewers"
              />
            );
          } else if (expandChartOpen) {
            chartExpanded = (
              <ChartContent>
                <BarChart
                  key={`${startTimeValue}, ${endTimeValue}`}
                  data={customRangeChartData}
                  stacked
                  tooltipLabel="Viewers"
                />
              </ChartContent>
            );
          }
        }
        table = (
          <VirtualizedTable
            data={formatTableData({
              inputChartData: watchTimeTableStats as barChartDataType,
              chartType: CHART_TYPES.BAR,
              groupBy: GROUP_VALUES.DEVICE_TYPE,
              segmentBy: TABLE_HEADER_EMBED.WATCH_TIME,
              lastColumnLabel: 'Views'
            })}
          />
        );
      } else if (tabValue === TAB_VALUES_EMBED.DEVICE_TYPE) {
        // Device Type
        chartNormal = (
          <DoughnutChart
            key={`${eventValue}${tabValue}${locationTabValue}`}
            data={doughnutChartData}
            tooltipLabel="Viewers"
            isLegendOnRightSide
            showPercentage
          />
        );
        table = (
          <VirtualizedTable
            data={formatTableData({
              inputChartData: doughnutChartData,
              chartType: CHART_TYPES.DOUGHNUT,
              groupBy: GROUP_VALUES.DEVICE_TYPE,
              segmentBy: TABLE_HEADER_EMBED.DEVICE_TYPE,
            })}
          />
        );
      } else if (tabValue === TAB_VALUES_EMBED.LOCATION) {
        // Location
        chartNormal = (
          <ChartContent>
            <BarChart
              key={`${eventValue}${tabValue}${locationTabValue}`}
              data={top10LocationBarChartData}
              stacked
              tooltipLabel="Viewers"
            />
          </ChartContent>
        );
        const findLabelsLength: barChartDataType = clone(barChartData);
        if (expandChartOpen && findLabelsLength.labels.length > 10) {
          chartExpanded = (
            <ScrollableBarChart
              key={`${eventValue}${tabValue}${locationTabValue}`}
              data={barChartData}
              stacked
              tooltipLabel="Viewers"
            />
          );
        } else if (expandChartOpen) {
          chartExpanded = (
            <ChartContent>
              <BarChart
                key={`${eventValue}${tabValue}${locationTabValue}`}
                data={top10LocationBarChartData}
                stacked
                tooltipLabel="Viewers"
              />
            </ChartContent>
          );
        }
        table = (
          <VirtualizedTable
            data={formatTableData({
              inputChartData: top10LocationBarChartData,
              chartType: CHART_TYPES.BAR,
              groupBy: GROUP_VALUES.DEVICE_TYPE,
              segmentBy: subLocations[locationTabValue],
            })}
          />
        );
      }
    }
    const dataRender: renderChartandTableType = {
      chartNormal: chartNormal,
      chartExpanded: chartExpanded,
      table: table,
    };
    return dataRender;
  };

  const exportLocationCSV = async () => {
    setExportCSVLoading(true);
    const locationData = await fetchData(
      EventAnalyticsState,
      SUB_LOCATIONS_ENUM[locationTabValue],
      embedPlayerAnalyticsState
    );
    const exportCSVData = getLocationCSVData(locationData, eventValue, locationTabValue);
    downloadAsCSV(exportCSVData);
    setExportCSVLoading(false);
  };

  const getExportCSVData = (eventValue: string, tabValue: number, tableDataCSV: string) => {
    let headersArray: string[] = [];
    const barChartData = chartData as barChartDataType;
    if (eventValue === GROUP_VALUES.NONE) {
      switch (tabValue) {
        case TAB_VALUES_EMBED.LOCATION:
          exportLocationCSV();
          break;
        default:
          downloadAsCSV(tableDataCSV);
      }
    } else if (eventValue === GROUP_VALUES.EVENT_TYPE) {
      switch (tabValue) {
        case TAB_VALUES_EMBED.RESOLUTION:
          headersArray = ['Resolution', 'live', 'simlive', 'onDemand'];
          getCSVData(headersArray, barChartData, eventValue);
          break;
        case TAB_VALUES_EMBED.WATCH_TIME:
          headersArray = ['Watch Time', 'live', 'simlive', 'onDemand'];
          getCSVData(headersArray, watchTimeTableStats as barChartDataType, eventValue);
          break;
        case TAB_VALUES_EMBED.DEVICE_TYPE:
          headersArray = ['Device Type', 'live', 'simlive', 'onDemand'];
          getCSVData(headersArray, barChartData, eventValue);
          break;
        case TAB_VALUES_EMBED.LOCATION:
          exportLocationCSV();
          break;
        default:
          downloadAsCSV(tableDataCSV);
      }
    } else if (eventValue === GROUP_VALUES.DEVICE_TYPE) {
      switch (tabValue) {
        case TAB_VALUES_EMBED.RESOLUTION:
          headersArray = ['Resolution', 'Mobile', 'Desktop', 'Tablet', 'Game Console', 'TV', 'Set Top Box', 'Other'];
          getCSVData(headersArray, barChartData, eventValue);
          break;
        case TAB_VALUES_EMBED.WATCH_TIME:
          headersArray = ['Watch Time', 'Mobile', 'Desktop', 'Tablet', 'Game Console', 'TV', 'Set Top Box', 'Other'];
          getCSVData(headersArray, watchTimeTableStats as barChartDataType, eventValue);
          break;
        case TAB_VALUES_EMBED.LOCATION:
          exportLocationCSV();
          break;
        default:
          downloadAsCSV(tableDataCSV);
      }
    } else {
      downloadAsCSV(tableDataCSV);
    }

    sendCSVExportMixpanelEvent();
  };

  const sendCSVExportMixpanelEvent = () => {
    let csvDetail = '';

    switch (tabValue) {
      case TAB_VALUES_EMBED.RESOLUTION:
        csvDetail = 'Resolution';
        break;
      case TAB_VALUES_EMBED.WATCH_TIME:
        csvDetail = 'Watch Time';
        break;
      case TAB_VALUES_EMBED.DEVICE_TYPE:
        csvDetail = 'Device Type';
        break;
      case TAB_VALUES_EMBED.LOCATION:
        csvDetail = 'Location';
        break;
      default:
        csvDetail = 'All Data';
    }

    const mixpanelProps = {
      [MPEventProperty.TRANSCODED_EVENT_UUID]: eventId,
      [MPEventProperty.CSV_DETAIL]: csvDetail,
      [MPEventProperty.ANALYTICS_TYPE]: 'Embed Player',
    };

    if (!eventId) {
      delete mixpanelProps[MPEventProperty.TRANSCODED_EVENT_UUID];
    }

    trackMixpanelEvent(MPEventName.CSV_EXPORT, mixpanelProps);
  };

  const {
    chartNormal: renderChartNormal,
    chartExpanded: renderChartExpanded,
    table: renderTable,
  } = renderChartandTable();
  const chartNormal: JSX.Element = renderChartNormal;
  const chartExpanded: JSX.Element = renderChartExpanded;
  const table: JSX.Element = renderTable;
  const isExpandDisabled = renderExpandBtnState(eventValue, tabValue, locationTabValue, showLocationTab);
  return (
    <>
      <Paper elevation={0} variant="outlined">
        <SegmentedStatsWrapper>
          <HeaderDiv>
            <Text.Heading type="h4" customCss={`color: ${vars.colorAccent800};`}>
              {showLocationTab
                ? `Top ${legendCount} ${locationTabHeaders[locationTabValue]}`
                : `${tabHeaders[tabValue]}`}
            </Text.Heading>
            <Inline justifyContent="flex-end" alignItems="center">
              <>
                <Tooltip
                  title={
                    <h6>
                      {isExportCSVLoading ? 'Please wait, your file is being prepared. ' : 'Download all data as CSV'}
                    </h6>
                  }
                  arrow
                  placement="top"
                >
                  <Link
                    component="button"
                    underline="none"
                    variant="body2"
                    disabled={isExportCSVLoading}
                    onClick={() => getExportCSVData(eventValue, tabValue, tableDataCSV)}
                  >
                    <LinkButtonContainer>
                      <ExportOutlined />
                      <span style={{ marginLeft: '5px' }}>Export CSV</span>
                    </LinkButtonContainer>
                  </Link>
                </Tooltip>
                {isExportCSVLoading ? <FillBlockSpinner fontSize={LOADER_SIZE_SUB_COMPONENT} /> : null}
              </>
            </Inline>
          </HeaderDiv>
          <Grid display="grid" gridTemplateColumns="2fr 6fr 4fr">
            <Grid.Item>
              <VerticalTabs orientation="vertical" value={tabValue} onChange={onTabChange}>
                <VerticalTab label="Resolution" />
                <VerticalTab label="Watch Time" />
                <VerticalTab label="Device Type" />
                <VerticalTab label="Location" />
              </VerticalTabs>
              {showLocationTab && (
                <>
                  <HorizontalLine />
                  <LocationTabsDiv>
                    <VerticalTabs orientation="vertical" value={locationTabValue} onChange={locationOnTabChange}>
                      <VerticalTab label="Country" />
                      <VerticalTab label="Region" />
                      <VerticalTab label="City" />
                    </VerticalTabs>
                  </LocationTabsDiv>
                </>
              )}
            </Grid.Item>
            {isChartLoading ? (
              <FillBlockSpinner fontSize={LOADER_SIZE_COMPONENT} />
            ) : (
              <>
                <Grid.Item>
                  <ChartWrapper>
                    <ChartContent>{chartNormal}</ChartContent>
                    <ExpandButtonWrapper>
                      <Tooltip
                        title={<h6>{isExpandDisabled ? 'Data is not Expandable' : 'Click expand to see all'}</h6>}
                        arrow
                        placement="top"
                      >
                        <div style={{ ...(isExpandDisabled && { opacity: '0.4' }) }}>
                          <Link
                            component="button"
                            underline="none"
                            variant="body2"
                            onClick={() => setExpandChartOpen(true)}
                            disabled={isExpandDisabled}
                          >
                            <LinkButtonContainer>
                              <ExpandAltOutlined />
                              <span style={{ marginLeft: '5px' }}>Expand</span>
                            </LinkButtonContainer>
                          </Link>
                        </div>
                      </Tooltip>
                    </ExpandButtonWrapper>
                  </ChartWrapper>
                </Grid.Item>
                <Grid.Item>
                  <Inline justifyContent="flex-end">
                    <TableContent>{table}</TableContent>
                  </Inline>
                </Grid.Item>
              </>
            )}
          </Grid>
        </SegmentedStatsWrapper>
      </Paper>
      {expandChartOpen && (
        <Modal width="fit-content">
          <Paper elevation={0}>
            <ModalWrapper>
              <ModalHeader
                titleText={showLocationTab ? `${locationTabHeaders[locationTabValue]}` : `${tabHeaders[tabValue]}`}
                closeButton
                onClose={() => {
                  setExpandChartOpen(false), setCustomRangeDisabled(true), setStartTime(minTimeVal);
                  setStartTimeValue(minTimeVal);
                  setEndTime(maxTimeVal), setEndTimeValue(maxTimeVal);
                }}
              />
              <SegmentedStatsModalContent>
                {tabValue === TAB_VALUES_EMBED.WATCH_TIME && (
                  <RangeFieldDiv>
                    <DropDownDiv>
                      <FieldLabel title="Graph Time Period" />
                      {/* translate the dropdown labels */}
                      <DropDownWrapper>
                        <Select
                          size="s"
                          value={watchTimeDropDownOptions[0]?.value}
                          options={watchTimeDropDownOptions}
                          handleChange={(newValue) => {
                            if (newValue[0].value) {
                              if (newValue[0].value === 'full') {
                                setCustomRangeDisabled(true);
                                setStartTime(minTimeVal);
                                setEndTime(maxTimeVal);
                                setStartTimeValue(minTimeVal);
                                setEndTimeValue(maxTimeVal);
                              } else {
                                setCustomRangeDisabled(false);
                              }
                            }
                          }}
                        />
                      </DropDownWrapper>
                    </DropDownDiv>
                    <CustomRangeDiv customRangeDisabled={customRangeDisabled}>
                      <div css={CustomRangeStyles('65px', 'inherit')}>
                        <TextField
                          title="Start Time"
                          value={String(startTime)}
                          size="s"
                          horizontalConstraint="s"
                          onChange={(event) => {
                            const startTime = Number(event.target.value);
                            if (!isNaN(startTime)) {
                              setStartTime(String(startTime));
                            }
                          }}
                        />
                      </div>
                      <div css={CustomRangeStyles('65px', 'inherit')}>
                        <TextField
                          title="End Time"
                          value={String(endTime)}
                          size="s"
                          horizontalConstraint="s"
                          onChange={(event) => {
                            const endTime = Number(event.target.value);
                            if (!isNaN(endTime)) {
                              setEndTime(String(endTime));
                            }
                          }}
                        />
                      </div>
                      <PrimaryButtonWrapper>
                        <PrimaryButton
                          label="Apply"
                          size="small"
                          isToggleButton={false}
                          onClick={() => {
                            setStartTimeValue(startTime);
                            setEndTimeValue(endTime);
                          }}
                        />
                      </PrimaryButtonWrapper>
                    </CustomRangeDiv>
                  </RangeFieldDiv>
                )}
                <ChartWrapper>
                  <ChartContent>
                    {isChartLoading ? <FillBlockSpinner fontSize={LOADER_SIZE_COMPONENT} /> : chartExpanded}
                  </ChartContent>
                </ChartWrapper>
              </SegmentedStatsModalContent>
            </ModalWrapper>
          </Paper>
        </Modal>
      )}
    </>
  );
};

EmbedSegmentedStatsView.propTypes = {
  eventValue: PropTypes.oneOf<EVENT_TYPES>([GROUP_VALUES.NONE, GROUP_VALUES.EVENT_TYPE, GROUP_VALUES.DEVICE_TYPE])
    .isRequired,
  tabValue: PropTypes.oneOf<EMBED_TAB_VALUES>([0, 1, 2, 3]).isRequired,
  onTabChange: PropTypes.func.isRequired,
  locationTabValue: PropTypes.oneOf<LOCATION_TAB_VALUES>([0, 1, 2]).isRequired,
  locationOnTabChange: PropTypes.func.isRequired,
  showLocationTab: PropTypes.bool.isRequired,
  isChartLoading: PropTypes.bool.isRequired,
  chartData: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.exact({
        label: PropTypes.string,
        data: PropTypes.number,
      })
    ),
    PropTypes.objectOf(
      PropTypes.exact({
        labels: PropTypes.arrayOf(PropTypes.string),
        extraInfo: PropTypes.arrayOf(PropTypes.string),
        datasets: PropTypes.objectOf(
          PropTypes.exact({
            live: PropTypes.arrayOf(PropTypes.number),
            simLive: PropTypes.arrayOf(PropTypes.number),
            onDemand: PropTypes.arrayOf(PropTypes.number),
            desktop: PropTypes.arrayOf(PropTypes.number),
            mobile: PropTypes.arrayOf(PropTypes.number),
            other: PropTypes.arrayOf(PropTypes.number),
          })
        ),
      })
    ),
  ]),
  top10LocationChartData: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.exact({
        label: PropTypes.string,
        data: PropTypes.number,
      })
    ),
    PropTypes.objectOf(
      PropTypes.exact({
        labels: PropTypes.arrayOf(PropTypes.string),
        extraInfo: PropTypes.arrayOf(PropTypes.string),
        datasets: PropTypes.objectOf(
          PropTypes.exact({
            live: PropTypes.arrayOf(PropTypes.number),
            simLive: PropTypes.arrayOf(PropTypes.number),
            onDemand: PropTypes.arrayOf(PropTypes.number),
            desktop: PropTypes.arrayOf(PropTypes.number),
            mobile: PropTypes.arrayOf(PropTypes.number),
            other: PropTypes.arrayOf(PropTypes.number),
          })
        ),
      })
    ),
  ]),
  minTimeVal: PropTypes.string.isRequired,
  maxTimeVal: PropTypes.string.isRequired,
  watchTimeDropDownOptions: PropTypes.arrayOf(
    PropTypes.exact({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.number.isRequired]).isRequired,
    }).isRequired
  ).isRequired,
};

EmbedSegmentedStatsView.displayName = 'EmbedSegmentedStatsView';

export default EmbedSegmentedStatsView;
