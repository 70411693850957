import { Context, createContext, Dispatch } from 'react';
import { UploadWizardSteps } from '../UploadWizard/upload-wizard';
import { validations } from './eventUploadHelpers';
import { SignedUrlResponse, ValidationsObject, WebEventProfile } from './eventUploadTypes';

export interface Action {
  type: ACTION_TYPE;
  payload?: any;
}

export interface EventUploadContext {
  state: EventUploadState;
  dispatch: Dispatch<Action>;
}

export interface EventUploadState {
  eventProfile: string;
  webEventProfileUuid: string;
  eventName: string;
  gopSeconds?: number;
  audioSampleRate: number;
  audioBitrateKbps: number;
  videoBitrateKbps: number;
  framerate: string;
  resolutionHeight: number;
  uploadProgress: number;
  uploadTotal: number;
  analyzingLoaded: number;
  analyzingTotal: number;
  profileList: WebEventProfile[];
  webEventProfileList: WebEventProfile[];
  errors: ValidationsObject;
  warnings: string[];
  fileName: string;
  file: Blob | null;
  fileChecksum: string | null;
  fileChecksumProgress: number;
  isUploading: boolean;
  uploadCanceled: boolean;
  uploadFinished: boolean;
  signedUrlResponse: SignedUrlResponse | null;
  showFileRequirements: boolean;
  showCreateWebEvent: boolean;
  webEventImmediatePublish: boolean;
  isAnalysisRunning: boolean;
  isChecksumTaskRunning: boolean;
  wizardStep: UploadWizardSteps;
}

export enum ACTION_TYPE {
  RESET_STATE = 'RESET_STATE',
  PROFILE = 'PROFILE',
  WEB_PROFILE_UUID = 'WEB_PROFILE_UUID',
  NAME = 'NAME',
  UPLOAD_PROGRESS = 'UPLOAD_PROGRESS',
  UPLOAD_TOTAL = 'UPLOAD_TOTAL',
  ANALYZING_LOADED = 'ANALYZING_LOADED',
  ANALYZING_TOTAL = 'ANALYZING_TOTAL',
  FILE = 'FILE',
  FILE_CHECKSUM = 'FILE_CHECKSUM',
  FILE_CHECKSUM_PROGRESS = 'FILE_CHECKSUM_PROGRESS',
  FILE_NAME = 'FILE_NAME',
  PROFILE_LIST = 'PROFILE_LIST',
  WEB_EVENT_PROFILE_LIST = 'WEB_EVENT_PROFILE_LIST',
  ERROR_LIST = 'ERROR_LIST',
  IS_UPLOADING = 'IS_UPLOADING',
  UPLOAD_CANCELED = 'UPLOAD_CANCELED',
  UPLOAD_FINISHED = 'UPLOAD_FINISHED',
  SIGNED_URL_RES = 'SIGNED_URL_RES',
  GOP_SECONDS = 'GOP_SECONDS',
  AUDIO_SAMPLE_RATE = 'AUDIO_SAMPLE_RATE',
  AUDIO_BITRATE = 'AUDIO_BITRATE',
  VIDEO_BITRATE = 'VIDEO_BITRATE',
  FRAMERATE = 'FRAMERATE',
  RESOLUTION_HEIGHT = 'RESOLUTION_HEIGHT',
  SHOW_FILE_REQUIREMENTS = 'SHOW_FILE_REQUIREMENTS',
  SHOW_CREATE_WEB_EVENT = 'SHOW_CREATE_WEB_EVENT',
  WEB_EVENT_IMMEDIATE_PUBLISH = 'WEB_EVENT_IMMEDIATE_PUBLISH',
  SET_ANALYSIS_RUNNING = 'SET_ANALYSIS_RUNNING',
  SET_CHECKSUM_TASK_RUNNING = 'SET_CHECKSUM_TASK_RUNNING',
  SET_WIZARD_STEP = 'SET_WIZARD_STEP',
  ADD_WARNING = 'ADD_WARNING',
  CLEAR_WARNINGS = 'CLEAR_WARNINGS',
}

export const initialState: EventUploadState = {
  eventProfile: '',
  webEventProfileUuid: '',
  eventName: '',
  uploadProgress: 0,
  uploadTotal: 0,
  analyzingLoaded: 0,
  analyzingTotal: 0,
  profileList: [],
  webEventProfileList: [],
  errors: { ...validations },
  warnings: [],
  fileName: '',
  file: null,
  fileChecksum: null,
  fileChecksumProgress: 0,
  isUploading: false,
  uploadCanceled: false,
  uploadFinished: false,
  signedUrlResponse: null,
  audioSampleRate: 0,
  audioBitrateKbps: 0,
  videoBitrateKbps: 0,
  framerate: "",
  resolutionHeight: 0,
  showFileRequirements: false,
  showCreateWebEvent: false,
  webEventImmediatePublish: true,
  isAnalysisRunning: false,
  isChecksumTaskRunning: false,
  wizardStep: UploadWizardSteps.PROFILE_AND_NAME,
};

export function reducer(state: EventUploadState, action: Action): EventUploadState {
  switch (action.type) {
    case ACTION_TYPE.RESET_STATE:
      return { ...initialState, webEventProfileList: state.webEventProfileList, profileList: state.profileList };
    case ACTION_TYPE.PROFILE:
      return { ...state, eventProfile: action.payload };
    case ACTION_TYPE.WEB_PROFILE_UUID:
      return { ...state, webEventProfileUuid: action.payload };
    case ACTION_TYPE.NAME:
      return { ...state, eventName: action.payload };
    case ACTION_TYPE.UPLOAD_PROGRESS:
      return { ...state, uploadProgress: action.payload };
    case ACTION_TYPE.UPLOAD_TOTAL:
      return { ...state, uploadTotal: action.payload };
    case ACTION_TYPE.ANALYZING_LOADED:
      return { ...state, analyzingLoaded: action.payload };
    case ACTION_TYPE.ANALYZING_TOTAL:
      return { ...state, analyzingTotal: action.payload };
    case ACTION_TYPE.FILE:
      return { ...state, file: action.payload };
    case ACTION_TYPE.FILE_NAME:
      return { ...state, fileName: action.payload };
    case ACTION_TYPE.FILE_CHECKSUM:
      return { ...state, fileChecksum: action.payload };
    case ACTION_TYPE.FILE_CHECKSUM_PROGRESS:
      return { ...state, fileChecksumProgress: action.payload };
    case ACTION_TYPE.PROFILE_LIST:
      return { ...state, profileList: action.payload };
    case ACTION_TYPE.WEB_EVENT_PROFILE_LIST:
      return { ...state, webEventProfileList: action.payload };
    case ACTION_TYPE.ERROR_LIST:
      return { ...state, errors: action.payload };
    case ACTION_TYPE.IS_UPLOADING:
      return { ...state, isUploading: action.payload };
    case ACTION_TYPE.UPLOAD_CANCELED:
      return { ...state, uploadCanceled: action.payload };
    case ACTION_TYPE.UPLOAD_FINISHED:
      return { ...state, uploadFinished: action.payload };
    case ACTION_TYPE.SIGNED_URL_RES:
      return { ...state, signedUrlResponse: action.payload };
    case ACTION_TYPE.GOP_SECONDS:
      return { ...state, gopSeconds: action.payload };
    case ACTION_TYPE.AUDIO_SAMPLE_RATE:
      return { ...state, audioSampleRate: action.payload };
    case ACTION_TYPE.FRAMERATE:
      return { ...state, framerate: action.payload };
    case ACTION_TYPE.RESOLUTION_HEIGHT:
      return { ...state, resolutionHeight: action.payload };
    case ACTION_TYPE.VIDEO_BITRATE:
      return { ...state, videoBitrateKbps: action.payload };
    case ACTION_TYPE.AUDIO_BITRATE:
      return { ...state, audioBitrateKbps: action.payload };
    case ACTION_TYPE.SHOW_FILE_REQUIREMENTS:
      return { ...state, showFileRequirements: action.payload };
    case ACTION_TYPE.SHOW_CREATE_WEB_EVENT:
      return { ...state, showCreateWebEvent: action.payload };
    case ACTION_TYPE.WEB_EVENT_IMMEDIATE_PUBLISH:
      return { ...state, webEventImmediatePublish: action.payload };
    case ACTION_TYPE.SET_ANALYSIS_RUNNING:
      return { ...state, isAnalysisRunning: action.payload };
    case ACTION_TYPE.SET_CHECKSUM_TASK_RUNNING:
      return { ...state, isChecksumTaskRunning: action.payload };
    case ACTION_TYPE.SET_WIZARD_STEP:
      return { ...state, wizardStep: action.payload };
    case ACTION_TYPE.ADD_WARNING:
      return { ...state, warnings: [...state.warnings, action.payload] };
    case ACTION_TYPE.CLEAR_WARNINGS:
      return { ...state, warnings: [] };
    default:
      throw new Error('Invalid Action Type: ' + action.type);
  }
}

export const EventUploadContext: Context<EventUploadContext> = createContext<EventUploadContext>(
  {} as EventUploadContext
);
