import Axios, { AxiosResponse } from 'axios';
import { api } from '../../../v2.snapshot/js/jcs';

export interface TrimWebEventPayload {
  webEventName?: string;
  startPosition?: string;
  endPosition?: string;
  published: boolean;
  webEventProfileId?: string;
}

export const trimWebEvent = async (
  payload: TrimWebEventPayload,
  customerId: string,
  webEventId: string
): Promise<AxiosResponse> => {
  return Axios.post(`${api.url_v3}/customers/${customerId}/webevents/${webEventId}/republish`, payload, {
    withCredentials: true,
  });
};
