import { useState, useRef, useEffect } from 'react';
import { BracketReference } from '../pages/EventAnalytics/types';

const useCachedFetch = (observerList: any[], fetchFunction: () => Promise<unknown>) => {
  const cache = useRef<BracketReference<boolean>>({});
  const [isLoading, setLoading] = useState(false);
  const [isFetching, setFetching] = useState(false);
  const observerString = JSON.stringify(observerList);
  useEffect(() => {
    const fetchData = async () => {
      if (cache.current[observerString]) {
        setFetching(true);
        await fetchFunction();
        setFetching(false);
        return;
      } else {
        setLoading(true);
        setFetching(true);
        await fetchFunction();
        cache.current[observerString] = true; // set fetched status in cache;
        setFetching(false);
        setLoading(false);
      }
    };

    fetchData();
  }, [observerString]);

  return { isLoading, isFetching };
};

export default useCachedFetch;
