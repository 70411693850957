import React, { FC, useContext, useEffect, useState } from 'react';
import AllDestinationsSegmentedStatsView from './alldestinations-segmented-stats-view';
import { fetchSegmentedData } from '../api';
import { doughnutChartDataType } from '../../types';
import { LEGEND_NAMES } from '../../event-analytics-constants';
import { EventAnalyticsContext } from '../../../../contexts/eventanalytics/event-analytics';

const AllDestinationsSegmentedStats: FC = (): JSX.Element => {
  const { EventAnalyticsState } = useContext(EventAnalyticsContext);
  const [chartData, setChartData] = useState<doughnutChartDataType[]>([]);
  const [isChartLoading, setChartLoading] = useState<boolean>(false);

  const getData = async () => {
    setChartLoading(true);
    const response = await fetchSegmentedData(EventAnalyticsState);
    setChartLoading(false);
    const doughnutChartData: doughnutChartDataType[] = Object.keys(response)?.map((element) => ({
      label: LEGEND_NAMES[element],
      data: response[element].views,
    }));
    setChartData(doughnutChartData);
  };

  useEffect(() => {
    getData();
  }, [EventAnalyticsState.eventId,EventAnalyticsState.dateRange]);

  return <AllDestinationsSegmentedStatsView chartData={chartData} isChartLoading={isChartLoading} />;
};

AllDestinationsSegmentedStats.displayName = 'AllDestinationsSegmentedStats';

export default AllDestinationsSegmentedStats;
