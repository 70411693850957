import React, { FC, useContext, useEffect, useMemo, useReducer, useState } from 'react';
import { FacebookContext, FacebookReducer } from '../../../contexts/eventanalytics';
import {
  FACEBOOK_DESTINATION_TYPES,
  INITIAL_FACEBOOK_PAGE_STATE,
  STATS_KEYS_FACEBOOK_GROUP_TIMELINE,
  STATS_KEYS_FACEBOOK_PAGE,
  STATS_KEYS_FACEBOOK,
  FACEBOOK_PAGE_STATE_CONTEXT_KEYS,
} from '../event-analytics-constants';
import FacebookAnalyticsView from './facebook-analytics-view';
import { fetchFacebookData, fetchDestinationTypeFromEventId } from './api';
import { StatsArray } from '../types';
import { getStatsGroupsFromResponse } from '../utils';
import { EventAnalyticsContext } from '../../../contexts/eventanalytics/event-analytics';

const FacebookAnalytics: FC<{ hasGraphPerms: boolean }> = ({ hasGraphPerms }): JSX.Element => {
  const [facebookAnalyticsState, dispatch] = useReducer(FacebookReducer, INITIAL_FACEBOOK_PAGE_STATE);
  const { EventAnalyticsState } = useContext(EventAnalyticsContext);
  const [statsArray, setStatsArray] = useState<StatsArray>([]);
  const [isCardLoading, setCardLoading] = useState<boolean>(true);
  const [isDestinationTypeFetching, setDestinationTypeFetching] = useState<boolean>(false);

  const getFacebookStatsKeys = () => {
    if (EventAnalyticsState.eventId) {
      switch (facebookAnalyticsState.destinationType) {
        case FACEBOOK_DESTINATION_TYPES.PAGE:
          return STATS_KEYS_FACEBOOK_PAGE;
        case FACEBOOK_DESTINATION_TYPES.GROUP:
          return STATS_KEYS_FACEBOOK_GROUP_TIMELINE;
        case FACEBOOK_DESTINATION_TYPES.TIMELINE:
          return STATS_KEYS_FACEBOOK_GROUP_TIMELINE;
        default:
          return STATS_KEYS_FACEBOOK;
      }
    } else {
      return STATS_KEYS_FACEBOOK;
    }
  };

  const getDestinationType = async (): Promise<void> => {
    const [response] = await fetchDestinationTypeFromEventId(EventAnalyticsState);
    if (response) {
      const { destinationType: destinationType } = response;
      dispatch({ payload: destinationType, type: FACEBOOK_PAGE_STATE_CONTEXT_KEYS.DESTINATION_TYPE });
    } else {
      dispatch({ payload: FACEBOOK_DESTINATION_TYPES.PAGE, type: FACEBOOK_PAGE_STATE_CONTEXT_KEYS.DESTINATION_TYPE });
    }
  };

  const verifyDestinationType = () => {
    if (!EventAnalyticsState.eventId) {
      dispatch({ payload: FACEBOOK_DESTINATION_TYPES.PAGE, type: FACEBOOK_PAGE_STATE_CONTEXT_KEYS.DESTINATION_TYPE });
    } else if (EventAnalyticsState.eventId && !facebookAnalyticsState.destinationType) {
      setDestinationTypeFetching(true);
      getDestinationType();
      setDestinationTypeFetching(false);
    }
  }

  const getData = async () => {
    setCardLoading(true);
    const statsArray = await fetchFacebookData(EventAnalyticsState, facebookAnalyticsState.destinationType);
    setStatsArray(getStatsGroupsFromResponse(getFacebookStatsKeys(), statsArray[0]));
    setCardLoading(false);
  };

  useMemo(()=>{
    verifyDestinationType();
  },[EventAnalyticsState.eventId, facebookAnalyticsState.destinationType])

  useEffect(() => {
    getData();
  }, [EventAnalyticsState.eventId, EventAnalyticsState.dateRange, facebookAnalyticsState.destinationType]);

  return (
    <FacebookContext.Provider value={{ facebookAnalyticsState, dispatch }}>
      <FacebookAnalyticsView
        isCardLoading={isCardLoading}
        isDestinationTypeFetching={isDestinationTypeFetching}
        hasGraphPerms={hasGraphPerms}
        statsArray={statsArray}
        />
    </FacebookContext.Provider>
  );
};

FacebookAnalytics.displayName = 'FacebookAnalytics';

export default FacebookAnalytics;
