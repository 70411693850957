import React, { FC, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './style.scss';

interface ProgressBarProps {
  progressUpdate: number;
  color?: string;
}

const ProgressBar: FC<ProgressBarProps> = ({ progressUpdate, color = '#008D4C' }): JSX.Element => {
  const [progress, setProgress] = useState<number>(0);
  const OPACITY = '.35';

  useEffect(() => {
    setProgress(progressUpdate);
  }, [progressUpdate]);

  function hexToRgba(hex: string): string {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    if (!result) {
      console.error('Failed to convert progress bar color from hex to rgba');
      return hex;
    }

    const r = parseInt(result[1], 16);
    const g = parseInt(result[2], 16);
    const b = parseInt(result[3], 16);

    return `rgba(${r},${g},${b}, ${OPACITY})`;
  }

  return (
    <div
      className="la1-progress-bar"
      style={{ backgroundColor: hexToRgba(color) }}
      data-testid="progress-bar-background"
    >
      <div
        className="la1-progress-bar__progress"
        style={{
          width: progress + '%',
          backgroundColor: color,
          transition: progress ? 'all .2s ease-in' : 'unset',
        }}
        data-testid="progress-bar-progress"
      />
    </div>
  );
};

ProgressBar.propTypes = {
  progressUpdate: PropTypes.number.isRequired,
  color: PropTypes.string,
};

ProgressBar.displayName = 'ProgressBar';

export default ProgressBar;
