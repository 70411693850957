import { AxiosResponse } from 'axios';
import React, { FC, useEffect, useState } from 'react';
import { ModalContent, ModalFooter } from '..';
import { Cue } from '../../helpers/events/types';
import { trimWebEvent, TrimWebEventPayload } from './api';
import { customProperties as vars } from '@resi-media/resi-ui';
import PropTypes from 'prop-types';
import { TrimWebEventState } from '../TrimWebEventModal';
import { trackMixpanelEvent, MPEventName, MPEventProperty } from '../../mixpanel';
import { Loading3QuartersOutlined } from '@ant-design/icons';

export interface TrimWebEventFormProps {
  cues?: Cue[];
  handleCloseModal: () => void;
  webEventProfileId?: string;
  customerId?: string;
  webEventId?: string;
  reducer: { state: TrimWebEventState; dispatch: (reducer: { field: string; value: any }) => void };
  isWebEventLive?: boolean;
  isWebEventProfileInUse?: boolean;
}

enum TrimWebEventFormErrors {
  NAME = 'name',
  START_END_CUES = 'start-end-cues',
  STATUS_409 = 'status-409',
}

const TrimWebEventForm: FC<TrimWebEventFormProps> = ({
  cues,
  handleCloseModal,
  webEventProfileId,
  customerId,
  webEventId,
  reducer,
  isWebEventLive,
  isWebEventProfileInUse,
}): JSX.Element => {
  const [formErrors, setFormErrors] = useState<TrimWebEventFormErrors[]>([]);
  const [isButtonDisabled, setDisabled] = useState<boolean>(false);
  const { state, dispatch } = reducer;

  useEffect(() => {
    if (isWebEventLive) {
      dispatch({
        field: 'endPosition',
        value: cues?.length && cues?.length > 0 ? cues[cues.length - 1] : null,
      });
    }
  }, []);

  const showLoadingSpinner = isWebEventProfileInUse === undefined || isWebEventLive === undefined;

  const disableTrimEventCheckbox = isWebEventProfileInUse || isWebEventLive;

  const getTrimmedWarningMessage = (): string => {
    if (isWebEventLive) {
      return 'Selecting active is blocked while the Web Event Profile is live';
    } else if (isWebEventProfileInUse) {
      return 'Selecting active is blocked while the Web Event Profile is in use';
    }
    return '';
  };

  const handleCreateEvent = async (): Promise<void> => {
    setDisabled(true);
    const errors = [];
    const { webEventName, startPosition, endPosition, published } = state;

    if (!webEventName) {
      errors.push(TrimWebEventFormErrors.NAME);
    }

    if (startPosition && endPosition) {
      if (startPosition.position_in_seconds >= endPosition.position_in_seconds) {
        errors.push(TrimWebEventFormErrors.START_END_CUES);
      }
    }

    if (errors.length > 0) {
      setFormErrors(errors);
      setDisabled(false);
      return;
    }

    const trimWebEventPayload: TrimWebEventPayload = {
      webEventName,
      published,
      webEventProfileId,
    };

    if (startPosition) {
      trimWebEventPayload.startPosition = startPosition.position;
    }

    if (endPosition) {
      trimWebEventPayload.endPosition = endPosition.position;
    }

    try {
      if (webEventId && customerId) {
        const response: AxiosResponse = await trimWebEvent(trimWebEventPayload, customerId, webEventId);
        if (response.status === 200 || response.status === 201) {
          dispatch({ field: 'newWebEventLocation', value: response.headers.location });
        }
        trackMixpanelEvent(MPEventName.WEB_EVENT_TRIM, {
          [MPEventProperty.CUE_START]: trimWebEventPayload.startPosition || 'Beginning of Event',
          [MPEventProperty.CUE_STOP]: trimWebEventPayload.endPosition || 'End of Event',
          [MPEventProperty.PLAYOUT_BEHAVIOR]: trimWebEventPayload.published
            ? 'ASAP Active Immediately'
            : 'ASAP Next Sim-live',
          [MPEventProperty.TRANSCODED_EVENT_UUID]: webEventId,
        });
      }
    } catch (error) {
      if (error.response.status === 409) {
        setFormErrors([TrimWebEventFormErrors.STATUS_409]);
        setDisabled(false);
      }
      console.error(error);
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    validateText(event.currentTarget.name, event.currentTarget.value);
    dispatch({ field: event.currentTarget.name, value: event.currentTarget.value });
  };

  const handleCueChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
    const cueByUuid: Cue | undefined = cues?.find((cue) => cue.uuid === event.currentTarget.value);
    validateCue(cueByUuid, event.currentTarget.name);
    dispatch({ field: event.currentTarget.name, value: cueByUuid });
  };

  const validateCue = (cue: Cue | undefined, name: string): void => {
    if (cue) {
      if (name == 'startPosition') {
        if (state.endPosition && state.endPosition.position_in_seconds <= cue.position_in_seconds) {
          if (!formErrors.includes(TrimWebEventFormErrors.START_END_CUES)) {
            formErrors.push(TrimWebEventFormErrors.START_END_CUES);
          }
          setFormErrors(formErrors);
          checkSubmitButtonStatus(formErrors);
        } else {
          const updatedErrors = formErrors.filter((er) => er !== TrimWebEventFormErrors.START_END_CUES);
          setFormErrors(updatedErrors);
          checkSubmitButtonStatus(updatedErrors);
        }
      } else if (name == 'endPosition') {
        if (state.startPosition && state.startPosition.position_in_seconds >= cue.position_in_seconds) {
          if (!formErrors.includes(TrimWebEventFormErrors.START_END_CUES)) {
            formErrors.push(TrimWebEventFormErrors.START_END_CUES);
          }
          setFormErrors(formErrors);
          checkSubmitButtonStatus(formErrors);
        } else {
          const updatedErrors = formErrors.filter((er) => er !== TrimWebEventFormErrors.START_END_CUES);
          setFormErrors(updatedErrors);
          checkSubmitButtonStatus(updatedErrors);
        }
      }
    } else {
      const updatedErrors = formErrors.filter((er) => er !== TrimWebEventFormErrors.START_END_CUES);
      setFormErrors(updatedErrors);
      checkSubmitButtonStatus(updatedErrors);
    }
  };

  const validateText = (field: string, value: string): void => {
    if (field === 'webEventName') {
      if (value.length > 0 && value.replace(/\s/g, '') !== '') {
        const updatedErrors = formErrors.filter((er) => er !== TrimWebEventFormErrors.NAME);
        setFormErrors(updatedErrors);
        checkSubmitButtonStatus(updatedErrors);
      } else {
        if (!formErrors.includes(TrimWebEventFormErrors.NAME)) {
          formErrors.push(TrimWebEventFormErrors.NAME);
        }
        setFormErrors(formErrors);
        checkSubmitButtonStatus(formErrors);
      }
    }
  };

  const checkSubmitButtonStatus = (errors: TrimWebEventFormErrors[]): void => {
    const hasErrors =
      errors.includes(TrimWebEventFormErrors.NAME) || errors.includes(TrimWebEventFormErrors.START_END_CUES);
    setDisabled(hasErrors);
  };

  return (
    <div data-testid="trim-web-event-form">
      <ModalContent>
        <div className="form-horizontal" id="webEventDownloadForm" style={{ width: '75%', margin: 'auto' }}>
          <div className="form-group">
            <label htmlFor="webEventName" className="col-sm-3 control-label">
              Name
            </label>
            <div className="col-sm-9">
              <input
                data-testid="name-input"
                className="form-control"
                name="webEventName"
                value={state.webEventName}
                onChange={handleChange}
              />
            </div>
            {formErrors.includes(TrimWebEventFormErrors.NAME) && (
              <>
                <div className="col-sm-3"></div>
                <div className="col-sm-9">
                  <p style={{ color: vars.colorError, margin: '0' }}>Please provide a name for the trimmed web event</p>
                </div>
              </>
            )}
          </div>
          <div className={`form-group`}>
            <label htmlFor="startCue" className="col-sm-3 control-label">
              Start Cue
            </label>
            <div className="col-sm-9">
              <select
                className="form-control"
                id="startPosition"
                name="startPosition"
                onChange={handleCueChange}
                onBlur={handleCueChange}
                style={{ width: '100%' }}
                defaultValue=""
                data-testid="start-position-select"
              >
                <option value="">Beginning of Event</option>
                {cues?.map((cue) => {
                  return (
                    <option key={cue.uuid} value={cue.uuid} data-testid={cue.uuid}>
                      {`Cue: ${cue.position} - ${cue.name}`}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="startPosition" className="col-sm-3 control-label">
              Stop Cue
            </label>
            <div className="col-sm-9">
              <select
                className="form-control"
                id="endPosition"
                name="endPosition"
                onChange={handleCueChange}
                onBlur={handleCueChange}
                style={{ width: '100%' }}
                value={state.endPosition?.uuid}
                data-testid="end-position-select"
                disabled={isWebEventLive && cues?.length === 0}
              >
                {!isWebEventLive && <option value="">End of Event</option>}
                {cues?.map((cue) => {
                  return (
                    <option key={cue.uuid} value={cue.uuid} data-testid="select-option">
                      {`Cue: ${cue.position} - ${cue.name}`}
                    </option>
                  );
                })}
              </select>
            </div>
            {isWebEventLive && cues?.length === 0 && (
              <>
                <div className="col-sm-3"></div>
                <div className="col-sm-9">
                  <p style={{ color: vars.colorError, margin: '10px 0 0 4px' }}>
                    Your event is live. Please create an end cue to select.
                  </p>
                </div>
              </>
            )}
            {formErrors.includes(TrimWebEventFormErrors.START_END_CUES) && (
              <>
                <div className="col-sm-3"></div>
                <div className="col-sm-9">
                  <p style={{ color: vars.colorError, margin: '10px 0 0 4px' }}>
                    The start cue must occur before the end cue
                  </p>
                </div>
              </>
            )}
          </div>
          <div className="form-group">
            {!showLoadingSpinner ? (
              <>
                <div className="col-sm-3"></div>
                <div className="col-sm-9" style={{ display: 'flex', alignItems: 'center' }}>
                  <input
                    type="checkbox"
                    name="published"
                    id="published"
                    className="form-check-input"
                    style={{ margin: '0', marginRight: vars.spacingS }}
                    checked={state.published}
                    onChange={(): void => dispatch({ field: 'published', value: !state.published })}
                    data-testid="published-checkbox"
                    disabled={disableTrimEventCheckbox}
                  />
                  <label
                    style={{ margin: '0', fontWeight: 400 }}
                    htmlFor="published"
                    className={disableTrimEventCheckbox ? 'text-muted' : ''}
                  >
                    Set trimmed Web Event as Active
                  </label>
                </div>
                {disableTrimEventCheckbox && (
                  <>
                    <div className="col-sm-3"></div>
                    <div className="col-sm-9" style={{ display: 'flex', alignItems: 'center' }}>
                      <p data-testid="web-event-profile-in-use-error" style={{ color: vars.colorError }}>
                        {getTrimmedWarningMessage()}
                      </p>
                    </div>
                  </>
                )}
              </>
            ) : (
              <>
                <div className="col-sm-3" />
                <div className="col-sm-9" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <Loading3QuartersOutlined spin data-testid="loading-spinner-published" />
                </div>
              </>
            )}
            {formErrors.includes(TrimWebEventFormErrors.STATUS_409) && (
              <div className="form-group">
                <div className="col-sm-3"></div>
                <div className="col-sm-9">
                  <p style={{ color: vars.colorError, margin: '0' }}>
                    Only one web event may run concurrently on each profile.
                  </p>
                </div>
              </div>
            )}
          </div>
          {(state.startPosition ||
            state.endPosition ||
            (!isWebEventLive && cues?.length !== 0) ||
            (!isWebEventProfileInUse && cues?.length !== 0)) && (
            <div className="form-group">
              <div className="col-sm-3 control-label"></div>
              <div className="col-sm-9">
                <div
                  className="well well-sm"
                  style={{ marginTop: 5, marginBottom: 0 }}
                  data-testid="startPositionCueNote"
                >
                  <strong>NOTE:</strong>
                  {` The actual start or stop time may be up to 6 seconds outside the selected position.`}
                </div>
              </div>
            </div>
          )}
        </div>
      </ModalContent>
      <ModalFooter>
        <button data-testid="cancel-btn" className="btn btn-default" onClick={handleCloseModal}>
          Cancel
        </button>
        <button
          disabled={isButtonDisabled || (isWebEventLive && cues?.length === 0)}
          data-testid="create-event-btn"
          className="btn btn-primary"
          onClick={handleCreateEvent}
        >
          Create Event
        </button>
      </ModalFooter>
    </div>
  );
};

TrimWebEventForm.displayName = 'TrimWebEventForm';

TrimWebEventForm.propTypes = {
  cues: PropTypes.array,
  handleCloseModal: PropTypes.func.isRequired,
  webEventProfileId: PropTypes.string,
  customerId: PropTypes.string,
  webEventId: PropTypes.string,
  reducer: PropTypes.any.isRequired,
};

export default TrimWebEventForm;
