import 'angular-route';
import angular from 'angular';
import { react2angular } from 'react2angular';
import router from './module.userinvites.routes';

import { AcceptInvitePage } from '../../../../src/pages';

export const userInvitesModule = {
  name: 'userinvites',
  controllers: {
    acceptInvite: 'acceptInviteCtrl',
  },
  routes: {
    accept: '/userinvites/:id/accept',
  },
};

export default angular
  .module(userInvitesModule.name, ['ngRoute'])
  .config(router)
  .controller(userInvitesModule.controllers.acceptInvite, require('./acceptInviteCtrl'))
  .component('acceptInvite', react2angular(AcceptInvitePage, ['inviteId'])).name;
