import Axios, { AxiosResponse } from 'axios';
import { WebEventStatus, Thumbnail } from '../../constants';
import { api } from '../../../v2.snapshot/js/jcs';

export interface ArchiveSaveWebEventPayload {
  webEventProfileId?: string;
  title?: string;
  description?: string;
  thumbnails?: Thumbnail[];
}

export interface ArchiveResponse {
	startCallbackUrl: string;
}

export const archiveSaveWebEvent = async (
  payload: ArchiveSaveWebEventPayload,
  customerId?: string,
  webEventId?: string
): Promise<AxiosResponse<ArchiveResponse>> => {
  return Axios.post(`${api.url_v3}/customers/${customerId}/webevents/${webEventId}/archive`, payload, {
    withCredentials: true,
  });
};

export const getWebEventStatus = async (url: string): Promise<AxiosResponse<WebEventStatus>> => {
  return Axios.get(url, { withCredentials: true });
};

export const setArchiveWebEventStarted = async (
  url: string,
): Promise<AxiosResponse> => {
  return Axios.post(url);
};
