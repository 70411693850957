import { perms } from '../../jcs';
import { eventprofilesModule } from './index';

function routeConfig($provide, $routeProvider) {
	'ngInject';

	$provide.factory('$routeProvider', () => {
		return $routeProvider;
	});

	// do more specific route first
	$routeProvider.when(eventprofilesModule.routes.details, {
		controller: eventprofilesModule.controllers.default,
		template: require('./eventprofiles.tmpl.html').default,
		activenav: eventprofilesModule.name,
		access: {
			loginRequired: true,
			permissions: [perms['event_profiles.get']],
		},
	});
	$routeProvider.when(eventprofilesModule.routes.list, {
		controller: eventprofilesModule.controllers.default,
		template: require('./eventprofiles.tmpl.html').default,
		activenav: eventprofilesModule.name,
		access: {
			loginRequired: true,
			permissions: [perms['event_profiles.get']],
		},
	});
}

export default routeConfig;
