'use strict';

const jcs = require('../../jcs');

function EventProfileUsersController(
	$scope,
	$http,
	$q,
	$routeParams,
	$location,
	formValidationService,
	focus,
	Authentication
) {
	'ngInject';

	$(window).trigger('resize'); // ensure footer is properly positioned

	$scope.validation = formValidationService.init();

	$scope.users = null;
	$scope.loading_users = false;
	$scope.users_error_msg = null;
	$scope.stream_id = $routeParams.streamID;
	$scope.profile_name = $location.search().profile_name; // get from query args

	$scope.is_by_name_tab_visible = true; // controls which tab is displayed

	$scope.manage_cue_options = ['Yes', 'No'];

	// user add
	$scope.is_adding_user = false;
	$scope.is_busy_adding_user = false; // activity indicator
	$scope.user_to_add_name = '';
	$scope.user_to_add_cues = 'Yes';
	$scope.add_user_error = null;

	// users add (multiple)
	$scope.org_user_list = null;
	$scope.organizations = null; // list of customers/orgs used for autocomplete
	$scope.is_busy_loading_add_users = false; // activity indicator
	$scope.is_busy_loading_org_users = false; // activity indicator
	$scope.is_busy_adding_users = false; // activity indicator
	$scope.load_org_users_error = null;
	$scope.load_add_users_error = null;

	// user edit
	$scope.user_to_edit = null;
	$scope.is_busy_editing_user = false; // activity indicator
	$scope.user_to_edit_name = '';
	$scope.user_to_edit_cues = null;
	$scope.edit_user_error = null;

	// user delete
	$scope.user_to_delete = null;
	$scope.is_busy_deleting_user = false; // activity indicator
	$scope.delete_user_error = null;

	// users delete (multiple)
	$scope.users_to_delete = null;
	$scope.is_busy_deleting_users = false; // activity indicator
	$scope.delete_users_error = null;
	// for some reason using a primitive instead of an object as ng-model doesn't work
	// see: https://stackoverflow.com/questions/18642371/checkbox-not-binding-to-scope-in-angularjs
	$scope.toggles = { selectAllUsers: false };

	$scope.getCurrentUser = function () {
		return Authentication.getCurrentUser();
	};

	$scope.canRemoveUsers = function () {
		return Authentication.getCurrentUser().hasPerm('event_profiles.users.delete');
	};

	$scope.canShowAddUserBtn = function () {
		return Authentication.getCurrentUser().hasPerm('event_profiles.users.add');
	};

	$scope.canShowEditBtn = function () {
		return Authentication.getCurrentUser().hasPerm('event_profiles.users.update');
	};

	$scope.canAddUsersByOrg = function () {
		return Authentication.getCurrentUser().hasPerm('event_profiles.users.add_by_org');
	};

	$scope.enterEditUserMode = function (user) {
		$scope.user_to_edit = user;

		$scope.user_to_edit_name = user.userName;
		$scope.user_to_edit_cues = user.canSetCues ? 'Yes' : 'No';

		// see app.js for where focus is defined
		focus('edit-user-input');
	};

	$scope.enterDeleteUserMode = function (user) {
		$scope.user_to_delete = user;
	};

	$scope.enterDeleteUsersMode = function () {
		var users_to_delete = [];
		for (var i = 0; i < $scope.users.length; i++) {
			if ($scope.users[i].isSelected) users_to_delete.push($scope.users[i]);
		}
		if (users_to_delete.length > 0) {
			$scope.users_to_delete = users_to_delete;
		}
	};

	$scope.enterAddUserMode = function () {
		$scope.is_adding_user = true;
		$scope.user_to_add_name = '';
		$scope.user_to_add_cues = 'Yes';

		// only LA1 users for now
		if (Authentication.getCurrentUser().hasPerm('event_profiles.users.add_by_org')) {
			$scope.enterAddUsersMode();
		}

		// see app.js for where focus is defined
		focus('add-user-input');
	};

	$scope.onOrgSelected = function (data) {
		if (data) {
			$scope.load_org_users_error = null;
			$scope.is_busy_loading_org_users = true;

			var org = data.originalObject;

			$http
				.get(jcs.api.url + '/customers/' + org.uuid + '/users', { withCredentials: true })
				.then(
					function (response) {
						// success

						var users = response.data;
						for (var i = 0; i < users.length; i++) {
							var user = users[i];
							user.alreadyHasAccess = $scope.doesUserAlreadyHaveAccess(user.userName);
							user.isSelected = user.alreadyHasAccess;
							user.canSetCues = $scope.canUserShareCues(user.userName);
						}
						$scope.org_user_list = users;
					},
					function () {
						// error

						$scope.load_org_users_error =
							'An error occurred while retrieving the list of users. Please try again, or report the problem if it persists.';
						// clear auto complete field so user can reenter org name
						$scope.$broadcast('angucomplete-alt:clearInput');
					}
				)
			['finally'](function () {
				// always called

				$scope.is_busy_loading_org_users = false;
			});
		}
	};

	$scope.enterAddUsersMode = function () {
		// ensure org auto complete input field is cleared
		$scope.$broadcast('angucomplete-alt:clearInput');
		$scope.is_busy_loading_org_users = false;
		$scope.is_by_name_tab_visible = true;
		$scope.load_add_users_error = null;

		$scope.is_busy_loading_add_users = true;

		var requests = [];
		var error_count = 0;

		// fetch users for current user's organization
		requests.push(
			$http
				.get(jcs.api.url + '/users', { withCredentials: true })
				.then(
					function (response) {
						// success

						var users = response.data;
						for (var i = 0; i < users.length; i++) {
							var user = users[i];
							user.alreadyHasAccess = $scope.doesUserAlreadyHaveAccess(user.userName);
							user.isSelected = user.alreadyHasAccess;
							user.canSetCues = $scope.canUserShareCues(user.userName);
						}
						$scope.org_user_list = users;
					},
					function () {
						// error

						error_count++;
					}
				)
			['finally'](function () {
				// always called
			})
		);

		// fetch list of organizations if we haven't already; this will be used for our autocomplete
		if ($scope.organizations == null) {
			requests.push(
				$http
					.get(jcs.api.url + '/customers/names', { withCredentials: true })
					.then(
						function (response) {
							// success

							$scope.organizations = response.data;
						},
						function () {
							// error

							error_count++;
						}
					)
				['finally'](function () {
					// always called
				})
			);
		}

		// the following will be performed when all api requests are completed
		$q.all(requests).then(function (data) {
			$scope.is_busy_loading_add_users = false;

			if (error_count > 0) {
				$scope.load_add_users_error =
					'An error occurred retrieving user information. Please try again, or report the problem if it persists.';
			}
		});
	};

	$scope.cancelEdit = function () {
		$scope.user_to_edit = null;
		$scope.edit_user_error = null;
	};

	$scope.cancelDelete = function () {
		$scope.user_to_delete = null;
		$scope.delete_user_error = null;
	};

	$scope.cancelDeleteUsers = function () {
		$scope.users_to_delete = null;

		// if an error is currently displayed, then we'll need to reload our user access list because
		// the current list may no longer be up-to-date
		if ($scope.delete_users_error != null) {
			$scope.delete_users_error = null;
			$scope.load();
		}
	};

	$scope.cancelAdd = function () {
		$scope.validation.clear();

		$scope.is_adding_user = false;
		$scope.add_user_error = null;
	};

	$scope.deleteUser = function () {
		$scope.is_busy_deleting_user = true;

		// make http request to delete user
		// NOTE: I normally would not include the "data: null", but IE11 and below seem to require it in order to work
		// see: https://github.com/angular/angular.js/issues/12141
		$http
			.delete(
				jcs.api.url +
				'/streamprofiles/' +
				$scope.stream_id +
				'/users/' +
				$scope.user_to_delete.userId,
				{ withCredentials: true, data: null }
			)
			.then(
				function () {
					// success

					// remove user from our users list
					var index = $scope.users.indexOf($scope.user_to_delete);
					if (index > -1) {
						$scope.users.splice(index, 1);
					}

					$scope.user_to_delete = null;
					$scope.delete_user_error = null;
				},
				function () {
					// error

					$scope.delete_user_error =
						'An error occurred while attempting to remove user permissions. Please try again, or report the problem if it persists.';
				}
			)
		['finally'](function () {
			// always called

			$scope.is_busy_deleting_user = false;
		});
	};

	$scope.deleteUsers = function () {
		$scope.is_busy_deleting_users = true;

		var requests = [];
		var error_user_names = [];

		$scope.users_to_delete.forEach(function (user) {
			requests.push(
				$http
					.delete(jcs.api.url + '/streamprofiles/' + $scope.stream_id + '/users/' + user.userId, {
						withCredentials: true,
						data: null,
					})
					.then(
						function () {
							// nothing
						},
						function () {
							// if error, then add associated name to list so we can build error message when all requests are done
							error_user_names.push(user.userName);
						}
					)
			);
		});

		// the following will be performed when all api requests are completed
		$q.all(requests).then(function (data) {
			$scope.is_busy_deleting_users = false;

			if (error_user_names.length > 0) {
				// if we have an error, then display the error message but leave the user on the confirmation page -- so they
				// can try again if they want. If they cancel, then we'll need to reload our user access list.
				$scope.delete_users_error =
					'An error occurred removing access for the following users: ' +
					error_user_names.join(', ') +
					'. Please try again, or report the problem if it persists.';
			} else {
				$scope.users_to_delete = null;
				$scope.toggles.selectAllUsers = false;
				// reload the user access list
				$scope.load();
			}
		});
	};

	$scope.saveEdit = function () {
		var updated_user_data = {
			canSetCues: $scope.user_to_edit_cues == 'Yes' ? true : false,
		};

		$scope.is_busy_editing_user = true;

		// update user
		$http
			.patch(
				jcs.api.url +
				'/streamprofiles/' +
				$scope.stream_id +
				'/users/' +
				$scope.user_to_edit.userId,
				updated_user_data,
				{ withCredentials: true }
			)
			.then(
				function () {
					// success

					// update was successful, so update our actual user in the model
					$scope.user_to_edit.canSetCues = $scope.user_to_edit_cues == 'Yes' ? true : false;

					$scope.user_to_edit = null;
					$scope.edit_user_error = null;
				},
				function () {
					// error

					$scope.edit_user_error =
						'An error occurred while attempting to update user permissions. Please try again, or report the problem if it persists.';
				}
			)
		['finally'](function () {
			// always called

			$scope.is_busy_editing_user = false;
		});
	};

	// returns TRUE if we have a form validation issue that the user needs to address
	$scope.doesSaveNewUserFailValidation = function () {
		$scope.add_user_error = null;
		$scope.validation.clear();

		// ensure required fields are not empty
		$scope.validation.checkForEmpty('user_to_add_name', $scope.user_to_add_name);

		var has_validation_error = $scope.validation.hasError();
		if (has_validation_error) {
			$scope.add_user_error = 'Please specify a value for the highlighted fields.';
		}

		return has_validation_error;
	};

	$scope.saveNewUser = function () {
		// if we have form validation errors, then don't go any further
		if ($scope.doesSaveNewUserFailValidation()) return false;

		var new_user_data = {
			userName: $scope.user_to_add_name,
			canSetCues: $scope.user_to_add_cues == 'Yes' ? true : false,
		};

		$scope.is_busy_adding_user = true;

		// send ajax request to create new user
		$http
			.post(jcs.api.url + '/streamprofiles/' + $scope.stream_id + '/users', new_user_data, {
				withCredentials: true,
			})
			.then(
				function () {
					// success

					$scope.is_adding_user = false;
					$scope.user_to_add_name = '';
					$scope.add_user_error = null;

					$scope.load();
				},
				function () {
					// error

					$scope.add_user_error =
						'An error occurred while attempting to add user permissions. Please try again, or report the problem if it persists.';
				}
			)
		['finally'](function () {
			// always called

			$scope.is_busy_adding_user = false;
		});
	};

	$scope.saveNewUsers = function () {
		$scope.is_busy_adding_users = true;

		var requests = [];
		var error_user_names = [];

		$scope.org_user_list.forEach(function (user) {
			if (user.isSelected && !user.alreadyHasAccess) {
				var new_user_data = {
					userName: user.userName,
					canSetCues: user.canSetCues,
				};

				// send ajax request to create new user
				requests.push(
					$http
						.post(jcs.api.url + '/streamprofiles/' + $scope.stream_id + '/users', new_user_data, {
							withCredentials: true,
						})
						.then(
							function () {
								// success
								// nothing
							},
							function () {
								// error
								// if error, then add associated name to list so we can build error message when all requests are done
								error_user_names.push(user.userName);
							}
						)
					['finally'](function () {
						// always called
					})
				);
			}
		});

		// the following will be performed when all api requests are completed
		$q.all(requests).then(function (data) {
			$scope.is_adding_user = false;
			$scope.is_busy_adding_users = false;

			if (error_user_names.length > 0) {
				// if we have an error, then display the error message but leave the user on the confirmation page -- so they
				// can try again if they want. If they cancel, then we'll need to reload our user access list.
				$scope.add_user_error =
					'An error occurred adding access for the following users: ' +
					error_user_names.join(', ') +
					'. Please try again, or report the problem if it persists.';
			} else {
				$scope.org_user_list = null;
				// reload the user access list
				$scope.load();
			}
		});
	};

	// toggle select status of users from main user access list
	$scope.toggleSelectedUsers = function () {
		for (var i = 0; i < $scope.users.length; i++) {
			$scope.users[i].isSelected = $scope.toggles.selectAllUsers;
		}
	};

	// are any users in our main user access list selected?
	$scope.areAnyUsersSelected = function () {
		if ($scope.users != null) {
			for (var i = 0; i < $scope.users.length; i++) {
				if ($scope.users[i].isSelected) return true;
			}
		}
		return false;
	};

	// does the given user already have access to this event profile?
	$scope.doesUserAlreadyHaveAccess = function (userName) {
		if ($scope.users != null) {
			for (var i = 0; i < $scope.users.length; i++) {
				if ($scope.users[i].userName == userName) return true;
			}
		}
		return false;
	};

	// does the given user have permission to share cues?
	$scope.canUserShareCues = function (userName) {
		if ($scope.users != null) {
			for (var i = 0; i < $scope.users.length; i++) {
				if ($scope.users[i].userName == userName) return $scope.users[i].canSetCues;
			}
		}
		return false;
	};

	// sets the select status of users in "add users" page
	$scope.addUsersSetSelectedStatus = function (status) {
		for (var i = 0; i < $scope.org_user_list.length; i++) {
			var user = $scope.org_user_list[i];
			if (!user.alreadyHasAccess) {
				$scope.org_user_list[i].isSelected = status;
			}
		}
	};

	$scope.addUsersCanSetCues = function (canSetCue) {
		for (var i = 0; i < $scope.org_user_list.length; i++) {
			var user = $scope.org_user_list[i];
			if (!user.alreadyHasAccess && user.isSelected) {
				$scope.org_user_list[i].canSetCues = canSetCue;
			}
		}
	};

	$scope.load = function () {
		$scope.loading_users = true;

		// retrieve users for this stream from server
		$http
			.get(jcs.api.url + '/streamprofiles/' + $routeParams.streamID + '/users', {
				withCredentials: true,
			})
			.then(
				function (response) {
					// success

					$scope.users_error_msg = null;
					$scope.users = response.data;

					// initialize "isSelected" status for each user
					for (var i = 0; i < $scope.users.length; i++) {
						$scope.users[i].isSelected = false;
					}
				},
				function () {
					// error

					$scope.users_error_msg =
						'An error occurred while attempting to retrieve the user information. Please try again, or report the problem if it persists.';
					$scope.users = null;
				}
			)
		['finally'](function () {
			// always called

			$scope.loading_users = false;
		});
	};

	$scope.showCanSetCues = function (canSetCues) {
		return canSetCues ? 'Yes' : 'No';
	};

	//
	// initialize by loading our users
	//
	$scope.load();
}

module.exports = EventProfileUsersController;
