const jcs = require('../../jcs');

class HardwareDetailsCtrl {

	constructor($http, $timeout, hardwareService) {
		'ngInject';

		this.DATE_FORMAT = 'MM/DD/YY';
		this.MODEL_TYPE_ENCODER = 'encoder';

		this.ACTIVATION_CODE_TIMEOUT_DELAY = 5000;
		this.ACTIVATION_CODE_MAX_API_CALLS = 10;

		this.$http = $http;
		this.$timeout = $timeout;
		this.hardwareService = hardwareService;

		this.hasLoadedActivationCode = false;
		this.activationCodeApiCallCount = 0;
		this.showRetryButton = false;

		this.hasGeneratedNewCode = false;
		this.isBusy = false;
		this.generateNewCodeError = null;
	}

	$onChanges(changes) {

		if (changes.hasOwnProperty('unit') && changes.unit.currentValue) {

			// if we have a brand new unit, then reset
			if (changes.unit.previousValue === null){
				this.hasLoadedActivationCode = false;
				this.activationCodeApiCallCount = 0;
				this.showRetryButton = false;
				this.hasGeneratedNewCode = false;
				this.isBusy = false;
				this.generateNewCodeError = null;
			}

			this.unit.modelName = this.getModelName(this.unit.modelId);
			this.unit.statusName = this.getStatusName(this.unit.statusId);
			this.unit.locationName = this.getLocationName(this.unit.locationId);
			this.unit.customerName = this.getOrgName(this.unit.customerOwnerId);
			this.unit.baseModelName = this.hardwareService.getBaseModel(this.unit.modelName);

			if (!this.unit.hasOwnProperty('activationCode')){
				this.loadActivationCode();
			} else {
				this.hasLoadedActivationCode = true;
			}
		}
	}

	getModelName = function (uuid){
		return this.getNameFromMap(uuid, this.modelMap);
	}

	getStatusName = function (uuid){
		return this.getNameFromMap(uuid, this.statusMap);
	}

	getLocationName = function (uuid){
		return this.getNameFromMap(uuid, this.locationMap);
	}

	getOrgName = function (uuid){
		return this.getNameFromMap(uuid, this.orgNameMap);
	}

	getNameFromMap = function (uuid, map) {
		if (!uuid){
			return '';
		}
		if (map && map.hasOwnProperty(uuid)){
			return map[uuid];
		}
		return 'Unknown';
	}

	getEmailConfirmationInfo = function () {
		if (this.unit){
			return `Model: ${this.unit.baseModelName}, Serial Number: ${this.unit.serial}, Activation Code: ${this.unit.formattedActivationCode}`;
		}
		return '';
	}

	// when the onboard button in control is pressed, while that initiates the process, it doesn't mean the process is complete when the API call returns. The encoder
	// itself has to talk with central before the process is officially done (and a activation code is created). So this means we have to wait a few seconds before the
	// activation code is available. That is why the following code is set to automatically retry if the api call to fetch the activation code fails. We will perform a
	// certain number of calls automatically, and if the activation code still isn't ready we'll just display a "retry" button for the user.
	loadActivationCode = function () {

		this.showRetryButton = false;
		this.activationCodeApiCallCount++;

		this.$http.get(`${jcs.api.internal_url}/hardwareunits/${this.unit.uuid}/activationcode`, { withCredentials: true }).then(response => {

			this.hasLoadedActivationCode = true;

			this.unit.encoderId = response.data.encoderId;
			this.unit.activationCode = response.data.activationCode;
			this.unit.formattedActivationCode = this.hardwareService.getFormatActivationCode(this.unit.activationCode);

		}).catch(reason => {

			if (this.activationCodeApiCallCount < this.ACTIVATION_CODE_MAX_API_CALLS){
				this.$timeout(this.loadActivationCode.bind(this), this.ACTIVATION_CODE_TIMEOUT_DELAY);
			} else {
				this.showRetryButton = true;
			}

		});
	}

	confirmGenerateNewCode = function () {
		this.generateNewCodeError = null;
		$('#confirm-generate-new-code').modal('show');
	}

	cancelGenerateNewCode = function () {
		$('#confirm-generate-new-code').modal('hide');
	}

	generateNewCode = function () {

		this.isBusy = true;
		this.hasGeneratedNewCode = false;
		this.generateNewCodeError = null;

		this.$http.post(`${jcs.api.internal_url}/encoders/${this.unit.encoderId}/activationcode/generate`, {}, { withCredentials: true }).then(response => {

			this.unit.activationCode = response.data.activationCode;
			this.unit.formattedActivationCode = this.hardwareService.getFormatActivationCode(this.unit.activationCode);
			this.hasGeneratedNewCode = true;
			$('#confirm-generate-new-code').modal('hide');

		}).catch(reason => {

			this.generateNewCodeError = 'There was a problem generating the new activation code. This can happen if the encoder is not in the Encoder Pool.';

		}).finally(() => {

			this.isBusy = false;

		});
	}
}

export default class HardwareDetailsComponent {
	constructor() {
		this.template = require('./hardwareDetails.html').default;
		this.bindings = {
			unit: '<',
			modelMap: '<',
			statusMap: '<',
			locationMap: '<',
			orgNameMap: '<',
		};
		this.controller = HardwareDetailsCtrl;
	}
}
