import styled from '@emotion/styled';
import { css, SerializedStyles } from '@emotion/react';
import { customProperties as vars } from '@resi-media/resi-ui';
import { COLOR_SET_SOURCE } from '../../pages/EventAnalytics/color-set';

export const ChangePercentageColor = (isUp: boolean): SerializedStyles => css`
  color: ${isUp ? COLOR_SET_SOURCE.UI_POSITIVE1.baseColor : COLOR_SET_SOURCE.UI_ERROR.baseColor};
`;

export const changeWrapper = styled('div')`
  margin-right: ${vars.spacingXs};
`;

export const StatusCardContentWrapper = styled('div')`
  padding: ${vars.spacingM};
  height: 100px;
`;

export const CountWrapper = styled('span')`
  width: 90%;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #262626;
`;
