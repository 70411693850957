import styled from '@emotion/styled';
import { customProperties as vars } from '@resi-media/resi-ui';

export const BoxWrap = styled.div`
  padding: ${vars.spacingXl} ${vars.spacingL};
  margin: auto;
  width: 95vw;
  background-color: ${vars.colorSurface};
  border-radius: ${vars.borderRadius2};

  @media (min-width: ${vars.breakPointTablet}) {
    width: 587px;
  }
`;

export const BoxHeader = styled.h3`
  font-size: ${vars.fontSize24};
  margin: 0;
  text-align: center;
  font-weight: bold;
`;

export const BoxDescription = styled.p`
  font-size: ${vars.fontSize14};
  text-align: center;
  color: ${vars.colorAccent800};
  margin: ${vars.spacingL} 0 ${vars.spacingXl} 0;
`;
