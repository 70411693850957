import {
  API_RESPONSE_DIFFS,
  ALL_STATS_KEYS,
  API_RESPONSE_LEGENDS,
  TIME_WINDOW_VALUES,
  MAX_FETCH_LIMIT_FOR_CSV_EXPORT,
  MAX_FETCH_CALLS_FOR_CSV,
  GROUP_VALUES,
  SUB_LOCATIONS_ENUM,
  PAGE_VALUE,
  DESTINATION_TYPES,
  TOOLTIP_LABEL,
} from './event-analytics-constants';
import { DateTime, DurationObject, Duration } from 'luxon';
import {
  StatisticsTabularResponseColumns,
  StatisticsTabularResponseRow,
  BracketReference,
  barChartInputParamsType,
  doughnutChartDataType,
  doughnutChartInputParamsType,
  StatsArray,
  barChartDataType,
  API_RESPONSE_TYPE,
  lineChartDataType,
  DataObject,
  SORT_ORDER,
  CSV_KEY_FIELDS,
  CsvDataObject,
  DataObjectKeys,
  CSVDataType,
  nameConversionInputParamsType,
  SelectOption,
  DEVICE_TYPES,
} from './types';
import clone from 'lodash.clone';
import countries from './countries.json';
import regionsList from './regions.json';
import { ChartTypeRegistry, TooltipItem } from 'chart.js';
import startCase from 'lodash/startCase';
import {
  currentAndPreviousYearOptions,
  customDateRangeOption,
  dateRangeValues,
  dayRangeOptions,
  previous3MonthOption,
  START_DATE_LOCAL,
} from './date-range-options';

const getStatsKeyByStringKey = (key: string): null | { COUNT_KEY: string; PERCENTAGE_KEY: string } => {
  switch (key) {
    case 'VIEWERS':
      return ALL_STATS_KEYS.VIEWERS;
    case 'VIEWS':
      return ALL_STATS_KEYS.VIEWS;
    case 'LIVE_VIEWS':
      return ALL_STATS_KEYS.LIVE_VIEWS;
    case 'AVG_WATCH_TIME':
      return ALL_STATS_KEYS.AVG_WATCH_TIME;
    case 'TOTAL_TIME_WATCHED':
      return ALL_STATS_KEYS.TOTAL_TIME_WATCHED;
    case 'NEW_VIEWERS':
      return ALL_STATS_KEYS.NEW_VIEWERS;
    case 'RETURN_VIEWERS':
      return ALL_STATS_KEYS.RETURN_VIEWERS;
    case 'PEAK_CONCURRENT':
      return ALL_STATS_KEYS.PEAK_CONCURRENT;
    case 'MEDIAN_TIME_WATCHED':
      return ALL_STATS_KEYS.MEDIAN_TIME_WATCHED;
    case 'TOTAL_MINUTES_WATCHED':
      return ALL_STATS_KEYS.TOTAL_MINUTES_WATCHED;
    case 'PEAK_CONCURRENT_LIVE_VIEWS':
      return ALL_STATS_KEYS.PEAK_CONCURRENT_LIVE_VIEWS;
  }
  // default case null throws ts error so we use return null instead
  console.warn(`unexpected STATS_KEYS key ${key}`);
  return null;
};

export const removeElementsfromArray = (inputArray: string[], removalElementsArray: string[]): string[] => {
  for (let i = 0; i < removalElementsArray.length; i++) {
    const removalElementIndex = inputArray.indexOf(removalElementsArray[i]);
    if (removalElementIndex !== -1) {
      inputArray.splice(removalElementIndex, 1);
    }
  }
  return inputArray;
};

export const sortArray = (inputResponse: DataObject[], label: string, sortDirection?: SORT_ORDER): DataObject[] => {
  return sortDirection
    ? inputResponse.sort((a, b) => (b[label] || '').toString().localeCompare((a[label] || '').toString()))
    : inputResponse.sort((a, b) => (a[label] || '').toString().localeCompare((b[label] || '').toString()));
};

//Sorting the dataset for stacked bar chart
export function sortArrayDecending(inputResponse: DataObject[]): DataObject[] {
  return inputResponse.sort((a, b) => {
    const keys_a = Object.keys(a).filter((key) => key !== 'country' && key !== 'region' && key !== 'city');
    const keys_b = Object.keys(b).filter((key) => key !== 'country' && key !== 'region' && key !== 'city');
    let sum_a = 0,
      sum_b = 0;
    keys_a.forEach((key) => (sum_a += a[key].viewers));
    keys_b.forEach((key) => (sum_b += b[key].viewers));
    return sum_b - sum_a;
  });
}

export const getFormatTime = (timeInSeconds: number): string => {
  let durationString = '';
  const normalisedDuration: DurationObject = Duration.fromObject({
    years: 0,
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: timeInSeconds,
  })
    .normalize()
    .toObject();

  if (timeInSeconds === 0) {
    durationString = '0s';
  } else {
    if (normalisedDuration.years) {
      durationString = `${normalisedDuration.years ? `${normalisedDuration.years}y ` : ''}${
        normalisedDuration.days ? `${normalisedDuration.days}d ` : ''
      }${normalisedDuration.hours ? `${normalisedDuration.hours}h ` : ''}`;
    } else if (normalisedDuration.days) {
      durationString = `${normalisedDuration.days ? `${normalisedDuration.days}d ` : ''}${
        normalisedDuration.hours ? `${normalisedDuration.hours}h ` : ''
      }${normalisedDuration.minutes ? `${normalisedDuration.minutes}m ` : ''}`;
    } else {
      durationString = `${normalisedDuration.years ? `${normalisedDuration.years}y ` : ''}${
        normalisedDuration.days ? `${normalisedDuration.days}d ` : ''
      }${normalisedDuration.hours ? `${normalisedDuration.hours}h ` : ''}${
        normalisedDuration.minutes ? `${normalisedDuration.minutes}m ` : ''
      }${normalisedDuration.seconds ? `${Number(normalisedDuration.seconds || 0)?.toFixed(0)}s` : ''}`;
    }
  }
  return durationString;
};

export const legendsMapToDataSets = (
  element: DataObject,
  datasetsObj: BracketReference<number[]>,
  legends: string[],
  index: number
): BracketReference<number[]> => {
  legends.map((legend) => {
    if (legend && Object.values(API_RESPONSE_LEGENDS).includes(legend)) {
      if (!(legend in datasetsObj)) {
        datasetsObj[legend] = [];
      }
      switch (legend) {
        case API_RESPONSE_LEGENDS.NONE:
          datasetsObj.none[index] = element.none?.viewers || element.none?.views || 0;
          break;
        case API_RESPONSE_LEGENDS.LIVE:
          datasetsObj.live[index] = element.live?.viewers || element.live?.views || 0;
          break;
        case API_RESPONSE_LEGENDS.SIM_LIVE:
          datasetsObj.simLive[index] = element.simLive?.viewers || element.simLive?.views || 0;
          break;
        case API_RESPONSE_LEGENDS.ON_DEMAND:
          datasetsObj.onDemand[index] = element.onDemand?.viewers || element.onDemand?.views || 0;
          break;
        case API_RESPONSE_LEGENDS.DESKTOP:
          datasetsObj.desktop[index] = element.desktop?.viewers || element.desktop?.views || 0;
          break;
        case API_RESPONSE_LEGENDS.MOBILE:
          datasetsObj.mobile[index] = element.mobile?.viewers || element.mobile?.views || 0;
          break;
        case API_RESPONSE_LEGENDS.TABLET:
          datasetsObj.tablet[index] = element.tablet?.viewers || element.tablet?.views || 0;
          break;
        case API_RESPONSE_LEGENDS.GAMECONSOLE:
          datasetsObj.gameConsole[index] = element.gameConsole?.viewers || element.gameConsole?.views || 0;
          break;
        case API_RESPONSE_LEGENDS.TV:
          datasetsObj.tv[index] = element.tv?.viewers || element.tv?.views || 0;
          break;
        case API_RESPONSE_LEGENDS.SET_TOP_BOX:
          datasetsObj.setTopBox[index] = element.setTopBox?.viewers || element.setTopBox?.views || 0;
          break;
        case API_RESPONSE_LEGENDS.OTHER:
          datasetsObj.other[index] = element.other?.viewers || element.other?.views || 0;
          break;
      }
    }
  });
  return datasetsObj;
};

export function toCommas(value: string): string {
  if (value === 'null' || value === null) {
    return '';
  }
  return value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export const getStatsGroupsFromResponse = (
  statsKey: BracketReference<BracketReference<string>>,
  responseObject: BracketReference<string | number | null>
): StatsArray => {
  let statsGroupArray;
  if (responseObject === undefined) {
    statsGroupArray = Object.keys(statsKey).map((groupName) => {
      const KEYS = getStatsKeyByStringKey(groupName);
      return {
        label: KEYS?.COUNT_KEY ?? '',
        count: '',
        change: 0,
        isTrendUp: false,
      };
    });
  } else {
    statsGroupArray = Object.keys(statsKey).map((groupName) => {
      const KEYS = getStatsKeyByStringKey(groupName);
      const changePercentage = responseObject[KEYS?.PERCENTAGE_KEY ?? ''] ?? 0;
      if (
        KEYS === ALL_STATS_KEYS.AVG_WATCH_TIME ||
        KEYS === ALL_STATS_KEYS.TOTAL_TIME_WATCHED ||
        KEYS === ALL_STATS_KEYS.MEDIAN_TIME_WATCHED
      ) {
        const timeStampValue = responseObject[KEYS.COUNT_KEY];
        const timeStamp = typeof timeStampValue === 'number' ? timeStampValue : 0;
        return {
          label: KEYS.COUNT_KEY,
          count: getFormatTime(timeStamp),
          change: typeof changePercentage === 'number' ? changePercentage : -1,
          isTrendUp: (responseObject[KEYS.PERCENTAGE_KEY] ?? 0) >= 0,
        };
      } else if (KEYS == ALL_STATS_KEYS.TOTAL_MINUTES_WATCHED) {
        const timeStampValue = responseObject[KEYS.COUNT_KEY];
        const timeStamp = typeof timeStampValue === 'number' ? timeStampValue : 0;
        return {
          label: KEYS.COUNT_KEY,
          count: getFormatTime(timeStamp * 60),
          change: typeof changePercentage === 'number' ? changePercentage : -1,
          isTrendUp: (responseObject[KEYS.PERCENTAGE_KEY] ?? 0) >= 0,
        };
      }
      const count = responseObject[KEYS?.COUNT_KEY ?? ''];
      return {
        label: KEYS?.COUNT_KEY ?? '',
        count: toCommas(count?.toString() ?? ''),
        change: typeof changePercentage === 'number' ? changePercentage : -1,
        isTrendUp: (responseObject[KEYS?.PERCENTAGE_KEY ?? ''] ?? 0) >= 0,
      };
    });
  }
  return statsGroupArray;
};

export const spaceCamel = (text: string): string => {
  return text.replace(/([a-z])([A-Z])/g, '$1 $2');
};

export const convertToCSV = (columns: string[], row: (string | number)[]): string => {
  const array = !Array.isArray(row) ? JSON.parse(row) : row;
  let str = '';
  columns?.forEach((value) => {
    str += `"${value}",`;
  });
  str = str + '\n';
  for (let i = 0; i < array.length; i++) {
    let line = '';
    for (const index in array[i]) {
      if (line !== '') line += ',';
      line += `"${array[i][index]}"`;
    }
    str += line + '\r\n';
  }
  return str;
};

export const convertTableToCSV = (
  header: StatisticsTabularResponseColumns,
  data: StatisticsTabularResponseRow[]
): string => {
  const array = !Array.isArray(data) ? JSON.parse(data) : data;
  let str = '';
  header?.forEach((value) => {
    str += `"${value.label}",`;
  });
  str = str + '\n';
  for (let i = 0; i < array.length; i++) {
    let line = '';
    for (const index in array[i]) {
      if (line !== '') line += ',';
      line += `"${array[i][index]}"`;
    }
    str += line + '\r\n';
  }
  return str;
};

//Dynamic parser for Device brands as the values are not pre defined.
export const parseLineChartDataForDeviceType = (
  response: API_RESPONSE_TYPE,
  timeWindowValue: string
): lineChartDataType => {
  const lineChartData: lineChartDataType = {
    labels: [],
    datasets: {},
  };
  const { dates } = response;
  const legendsArray: string[] = [];
  dates?.forEach((element, _index) => {
    //Labels
    const { date: label = '' } = element;
    lineChartData.labels.push(
      DateTime.fromSQL(label).toFormat(timeWindowValue === TIME_WINDOW_VALUES.MONTH ? 'MMM yyyy' : 'MMM dd yyyy')
    );
    const legends = Object.keys(element).filter((key) => key !== 'date');
    legends.map((eachLegends) => {
      legendsArray.push(eachLegends);
    });
  });
  const filteredLegendsArray = legendsArray.filter((value, index) => legendsArray.indexOf(value) === index);
  filteredLegendsArray.map((eachLegend) => {
    lineChartData.datasets[eachLegend] = [];
  });
  dates?.forEach((element, _index) => {
    filteredLegendsArray.map((eachLegend) => {
      lineChartData.datasets[eachLegend][_index] =
        element[eachLegend as DEVICE_TYPES]?.viewers ||
        element[eachLegend as DEVICE_TYPES]?.avgWatchTime ||
        element[eachLegend as DEVICE_TYPES]?.views ||
        0;
    });
  });
  return lineChartData;
};

// Data parsing function for line chart
export const parseLineChartData = (
  response: API_RESPONSE_TYPE,
  timeWindowValue: string,
  dataPageName?: string
): lineChartDataType => {
  const lineChartData: lineChartDataType = {
    labels: [],
    datasets: {},
  };

  const { dates } = response;

  dates?.forEach((element, _index) => {
    //Labels
    const { date: label = '' } = element;
    if (dataPageName !== PAGE_VALUE.LIVE_VIEWS) {
      lineChartData.labels.push(
        DateTime.fromSQL(label).toFormat(timeWindowValue === TIME_WINDOW_VALUES.MONTH ? 'MMM yyyy' : 'MMM dd yyyy')
      );
    } else {
      lineChartData.labels.push(DateTime.fromSQL(label).toFormat('MMM dd HH:mm'));
    }

    // Legends
    const legends = Object.keys(element).filter((key) => key !== 'date');

    const { length: labelArrayLength } = dates;

    // Datasets
    legends.map((legend) => {
      if (Object.values(API_RESPONSE_LEGENDS).includes(legend)) {
        if (!(legend in lineChartData.datasets)) {
          lineChartData.datasets[legend] = new Array(labelArrayLength).fill(0);
        }

        switch (legend) {
          case API_RESPONSE_LEGENDS.EMBED:
            lineChartData.datasets.embed[_index] = element.embed?.views || 0;
            break;
          case API_RESPONSE_LEGENDS.STREAM_URL:
            lineChartData.datasets.streamUrl[_index] = element.streamUrl?.views || 0;
            break;
          case API_RESPONSE_LEGENDS.YOUTUBE:
            lineChartData.datasets.youtube[_index] = element.youtube?.views || 0;
            break;
          case API_RESPONSE_LEGENDS.FACEBOOK:
            lineChartData.datasets.facebook[_index] = element.facebook?.views || 0;
            break;
          case API_RESPONSE_LEGENDS.NONE:
            lineChartData.datasets.none[_index] =
              element.none?.viewers ||
              element.none?.avgWatchTime ||
              element.none?.views ||
              element.none?.liveViews ||
              0;
            break;
          case API_RESPONSE_LEGENDS.LIVE:
            lineChartData.datasets.live[_index] =
              element.live?.viewers ||
              element.live?.avgWatchTime ||
              element.live?.liveViews ||
              element.live?.views ||
              0;
            break;
          case API_RESPONSE_LEGENDS.SIM_LIVE:
            lineChartData.datasets.simLive[_index] =
              element.simLive?.viewers ||
              element.simLive?.avgWatchTime ||
              element.simLive?.liveViews ||
              element.simLive?.views ||
              0;
            break;
          case API_RESPONSE_LEGENDS.ON_DEMAND:
            lineChartData.datasets.onDemand[_index] =
              element.onDemand?.viewers ||
              element.onDemand?.avgWatchTime ||
              element.onDemand?.liveViews ||
              element.onDemand?.views ||
              0;
            break;
          case API_RESPONSE_LEGENDS.DESKTOP:
            lineChartData.datasets.desktop[_index] =
              element.desktop?.viewers ||
              element.desktop?.avgWatchTime ||
              element.desktop?.liveViews ||
              element.desktop?.views ||
              0;
            break;
          case API_RESPONSE_LEGENDS.MOBILE:
            lineChartData.datasets.mobile[_index] =
              element.mobile?.viewers ||
              element.mobile?.avgWatchTime ||
              element.mobile?.liveViews ||
              element.mobile?.views ||
              0;
            break;
          case API_RESPONSE_LEGENDS.TABLET:
            lineChartData.datasets.tablet[_index] =
              element.tablet?.viewers ||
              element.tablet?.avgWatchTime ||
              element.tablet?.liveViews ||
              element.tablet?.views ||
              0;
            break;
          case API_RESPONSE_LEGENDS.GAMECONSOLE:
            lineChartData.datasets.gameConsole[_index] =
              element.gameConsole?.viewers ||
              element.gameConsole?.avgWatchTime ||
              element.gameConsole?.liveViews ||
              element.gameConsole?.views ||
              0;
            break;
          case API_RESPONSE_LEGENDS.TV:
            lineChartData.datasets.tv[_index] =
              element.tv?.viewers || element.tv?.avgWatchTime || element.tv?.liveViews || element.tv?.views || 0;
            break;
          case API_RESPONSE_LEGENDS.SET_TOP_BOX:
            lineChartData.datasets.setTopBox[_index] =
              element.setTopBox?.viewers ||
              element.setTopBox?.avgWatchTime ||
              element.setTopBox?.liveViews ||
              element.setTopBox?.views ||
              0;
            break;
          case API_RESPONSE_LEGENDS.OTHER:
            lineChartData.datasets.other[_index] =
              element.other?.viewers ||
              element.other?.avgWatchTime ||
              element.other?.liveViews ||
              element.other?.views ||
              0;
            break;
        }
      }
    });
  });
  return lineChartData;
};

// Data parsing function for doughnut chart
export const getDoughnutChartData = ({
  responseObject,
  countriesList,
  regionsList,
  chartName,
}: doughnutChartInputParamsType): doughnutChartDataType[] => {
  const doughnutChartData: doughnutChartDataType[] = [];
  let label: string, countryName: string, regionName: string, cityName: string;
  responseObject[Object.keys(responseObject)[0]].forEach((element) => {
    switch (chartName) {
      case API_RESPONSE_DIFFS.RESOLUTION:
        label = element.resolution === '0' ? '' : element.resolution || '';
        break;
      case API_RESPONSE_DIFFS.DEVICE_TYPE:
        label = element?.deviceType || '';
        break;
      case API_RESPONSE_DIFFS.LOCATION.COUNTRY:
        label = element.country || '';
        label = label in countriesList ? countriesList[label] : label;
        break;
      case API_RESPONSE_DIFFS.LOCATION.REGION:
        countryName = element.country || '';
        regionName = element.region || '';
        if (countryName.length > 0 && regionName.length > 0) {
          if (countryName in regionsList) {
            regionName = regionName in regionsList[countryName] ? regionsList[countryName][regionName] : regionName;
          }
          countryName = countryName in countriesList ? countriesList[countryName] : countryName;
          label = `${regionName}, ${countryName}`;
        }
        break;
      case API_RESPONSE_DIFFS.LOCATION.CITY:
        countryName = element.country || '';
        regionName = element.region || '';
        cityName = element.city || '';
        if (countryName.length > 0 && regionName.length > 0 && cityName.length > 0) {
          if (countryName in regionsList) {
            regionName = regionName in regionsList[countryName] ? regionsList[countryName][regionName] : regionName;
          }
          label = `${cityName}, ${regionName}`;
        }
        break;
    }
    if (label) {
      doughnutChartData.push({
        label,
        data: element.none?.viewers || element.none?.views || 0,
      });
    }
  });
  if (chartName !== API_RESPONSE_DIFFS.RESOLUTION) {
    doughnutChartData.sort(function (a, b) {
      return b.data - a.data;
    });
  }
  return doughnutChartData;
};

// Data parsing function for bar chart
export const getBarChartData = ({
  responseObject,
  countriesList,
  regionsList,
  chartName,
}: barChartInputParamsType): barChartDataType => {
  const barChartData: barChartDataType = { labels: [], extraInfo: [], datasets: {} };
  const keyName = Object.keys(responseObject)[0];
  const dataList = responseObject[keyName];
  switch (chartName) {
    case API_RESPONSE_DIFFS.RESOLUTION:
      dataList.forEach((element) => {
        const label = element.resolution === '0' ? '' : element.resolution || '';
        if (label) {
          // Labels
          barChartData.labels.push(label);

          // Legends
          const legends = Object.keys(element).filter((key) => key !== API_RESPONSE_DIFFS.RESOLUTION);

          // Datasets
          barChartData.datasets = legendsMapToDataSets(
            element,
            barChartData.datasets,
            legends,
            barChartData.labels.length - 1
          );
        }
      });
      return barChartData;
    case API_RESPONSE_DIFFS.DEVICE_TYPE:
      dataList.forEach((element) => {
        const label = element.deviceType || '';
        if (label) {
          // Labels
          barChartData.labels.push(label);

          // Legends
          const legends = Object.keys(element).filter((key) => key !== API_RESPONSE_DIFFS.DEVICE_TYPE);

          // Datasets
          barChartData.datasets = legendsMapToDataSets(
            element,
            barChartData.datasets,
            legends,
            barChartData.labels.length - 1
          );
        }
      });
      return barChartData;
    case API_RESPONSE_DIFFS.LOCATION.COUNTRY: {
      const countrywiseSortedResponse: API_RESPONSE_TYPE = { locations: [] };
      dataList.forEach((element) => {
        let label = element.country || '';
        label = label in countriesList ? countriesList[label] : label;
        element.country = label;
        if (label) {
          countrywiseSortedResponse.locations.push(element);
        }
      });
      countrywiseSortedResponse.locations.forEach((element, _index) => {
        // Labels
        const label = element.country || '';
        barChartData.labels.push(label);

        // Legends
        const legends = Object.keys(element).filter((key) => key !== API_RESPONSE_DIFFS.LOCATION.COUNTRY);

        // Datasets
        barChartData.datasets = legendsMapToDataSets(element, barChartData.datasets, legends, _index);
      });
      return barChartData;
    }
    case API_RESPONSE_DIFFS.LOCATION.REGION: {
      const regionwiseSortedResponse: API_RESPONSE_TYPE = { locations: [] };
      dataList.forEach((element) => {
        let countryName = element.country || '';
        let regionName = element.region || '';
        if (countryName.length > 0 && regionName.length > 0) {
          if (countryName in regionsList) {
            regionName = regionName in regionsList[countryName] ? regionsList[countryName][regionName] : regionName;
          }
          countryName = countryName in countriesList ? countriesList[countryName] : countryName;
          element.region = regionName;
          element.country = countryName;
          regionwiseSortedResponse.locations.push(element);
        }
      });
      regionwiseSortedResponse.locations.forEach((element, _index) => {
        // Labels
        const countryName = element.country || '';
        const regionName = element.region || '';
        barChartData.labels.push(regionName);
        barChartData.extraInfo.push(countryName);

        // Legends
        const legends = Object.keys(element).filter(
          (key) => !(key === API_RESPONSE_DIFFS.LOCATION.COUNTRY || key === API_RESPONSE_DIFFS.LOCATION.REGION)
        );

        // Datasets
        barChartData.datasets = legendsMapToDataSets(element, barChartData.datasets, legends, _index);
      });
      return barChartData;
    }
    case API_RESPONSE_DIFFS.LOCATION.CITY:
      {
        let inc = 0;
        responseObject.locations.forEach((element, _index) => {
          const countryName = element.country || '';
          let regionName = element.region || '';
          const cityName = element.city || '';
          if (Boolean(countryName) && Boolean(regionName) && Boolean(cityName)) {
            if (countryName in regionsList) {
              regionName = regionName in regionsList[countryName] ? regionsList[countryName][regionName] : regionName;
            }
            // Labels
            barChartData.labels.push(cityName);
            barChartData.extraInfo.push(regionName);

            // Legends
            const legends = Object.keys(element).filter(
              (key) =>
                !(
                  key === API_RESPONSE_DIFFS.LOCATION.COUNTRY ||
                  key === API_RESPONSE_DIFFS.LOCATION.REGION ||
                  key === API_RESPONSE_DIFFS.LOCATION.CITY
                )
            );

            // Datasets
            barChartData.datasets = legendsMapToDataSets(element, barChartData.datasets, legends, inc);
            inc += 1;
          }
        });
      }
      return barChartData;

    default:
      return barChartData;
  }
};

export const getCSVData = (headersArray: string[], barChartData: barChartDataType, eventValue?: string): void => {
  const CSVData: CSVDataType = { headers: [], data: [] };
  let dataSetsKeys: string[] = [];
  CSVData.headers = headersArray.map((eachHeader) => {
    return { label: eachHeader };
  });
  if (eventValue === GROUP_VALUES.DEVICE_TYPE) {
    dataSetsKeys = ['mobile', 'desktop', 'tablet', 'gameConsole', 'tv', 'setTopBox', 'other'];
  } else if (eventValue === GROUP_VALUES.EVENT_TYPE) {
    dataSetsKeys = ['live', 'simLive', 'onDemand'];
  } else {
    dataSetsKeys = Object.keys(barChartData.datasets);
  }
  barChartData.labels.map((eachLabel, index) => {
    const row = [];
    row[0] = eachLabel;
    dataSetsKeys.map((el) => {
      if (barChartData.datasets[el]) {
        if (barChartData.datasets[el][index]) {
          row.push(barChartData.datasets[el][index]);
        } else {
          row.push(0);
        }
      } else {
        row.push(0);
      }
    });
    CSVData.data.push({ row: row });
  });
  const exportCSVData = convertTableToCSV(
    CSVData.headers,
    CSVData.data.map((data) => data.row)
  );
  downloadAsCSV(exportCSVData);
};

export const getLocationCSVData = (
  locationData: API_RESPONSE_TYPE,
  eventValue: string,
  locationTabValue: number
): string => {
  const countriesList = countries.countries;
  const locationDataList = expandLocationNames({ responseObject: locationData, countriesList, regionsList });
  const locationList: DataObject[] = locationDataList?.locations;
  const locationCSVData: CSVDataType = { headers: [], data: [] };
  const expectedColumnFields = getexpectedColumnFields(eventValue, locationTabValue);
  const headersArray: string[] = getHeadersArray(eventValue, locationTabValue);
  locationCSVData.headers = headersArray.map((eachHeader) => ({
    label: eachHeader,
  }));
  locationList.forEach((element) => {
    const locationDataArray: StatisticsTabularResponseRow = [];
    expectedColumnFields.map((eachExpectedColumnField) => {
      const currentColumnValue = element[eachExpectedColumnField];
      if (currentColumnValue) {
        if (typeof currentColumnValue === 'object') {
          locationDataArray.push(currentColumnValue?.viewers || '');
        } else {
          locationDataArray.push(currentColumnValue);
        }
      } else {
        locationDataArray.push(0);
      }
    });
    locationCSVData.data.push({ row: locationDataArray });
  });
  const exportCSVData = convertTableToCSV(
    locationCSVData.headers,
    locationCSVData.data.map((rowData) => rowData.row)
  );
  return exportCSVData;
};

export const expandLocationNames = ({
  responseObject,
  countriesList,
  regionsList,
}: nameConversionInputParamsType): API_RESPONSE_TYPE => {
  let label: string, countryName: string, regionName: string;
  responseObject[Object.keys(responseObject)[0]].forEach((element) => {
    let countryNameExpanded = '';
    let regionNameExpanded = '';
    if (element.country) {
      label = element.country || '';
      label = label in countriesList ? countriesList[label] : label;
      countryNameExpanded = label;
    }
    if (element.region && element.country) {
      countryName = element.country || '';
      regionName = element.region || '';
      if (countryName.length > 0 && regionName.length > 0) {
        if (countryName in regionsList) {
          regionName = regionName in regionsList[countryName] ? regionsList[countryName][regionName] : regionName;
        }
        label = `${regionName}`;
      }
      regionNameExpanded = label;
    }
    if (countryNameExpanded) {
      element.country = countryNameExpanded;
    }
    if (regionNameExpanded) {
      element.region = regionNameExpanded;
    }
    return element;
  });
  return responseObject;
};

export const getexpectedColumnFields = (eventValue: string, locationTabValue: number): DataObjectKeys[] => {
  let expectedFieldsArray: DataObjectKeys[] = [];
  if (eventValue === GROUP_VALUES.NONE) {
    if (SUB_LOCATIONS_ENUM[locationTabValue] === 'Country') {
      expectedFieldsArray = ['country', 'none'];
    } else if (SUB_LOCATIONS_ENUM[locationTabValue] === 'Region') {
      expectedFieldsArray = ['region', 'country', 'none'];
    } else if (SUB_LOCATIONS_ENUM[locationTabValue] === 'City') {
      expectedFieldsArray = ['city', 'region', 'country', 'none'];
    }
  } else if (eventValue === GROUP_VALUES.EVENT_TYPE) {
    if (SUB_LOCATIONS_ENUM[locationTabValue] === 'Country') {
      expectedFieldsArray = ['country', 'live', 'simLive', 'onDemand'];
    } else if (SUB_LOCATIONS_ENUM[locationTabValue] === 'Region') {
      expectedFieldsArray = ['region', 'country', 'live', 'simLive', 'onDemand'];
    } else if (SUB_LOCATIONS_ENUM[locationTabValue] === 'City') {
      expectedFieldsArray = ['city', 'region', 'country', 'live', 'simLive', 'onDemand'];
    }
  } else if (eventValue === GROUP_VALUES.DEVICE_TYPE) {
    if (SUB_LOCATIONS_ENUM[locationTabValue] === 'Country') {
      expectedFieldsArray = ['country', 'mobile', 'desktop', 'tablet', 'gameConsole', 'setTopBox', 'tv', 'other'];
    } else if (SUB_LOCATIONS_ENUM[locationTabValue] === 'Region') {
      expectedFieldsArray = [
        'region',
        'country',
        'mobile',
        'desktop',
        'tablet',
        'gameConsole',
        'setTopBox',
        'tv',
        'other',
      ];
    } else if (SUB_LOCATIONS_ENUM[locationTabValue] === 'City') {
      expectedFieldsArray = [
        'city',
        'region',
        'country',
        'mobile',
        'desktop',
        'tablet',
        'gameConsole',
        'setTopBox',
        'tv',
        'other',
      ];
    }
  }
  return expectedFieldsArray;
};

export const getHeadersArray = (eventValue: string, locationTabValue: number) => {
  let headersArray: string[] = [];
  if (eventValue === GROUP_VALUES.NONE) {
    if (SUB_LOCATIONS_ENUM[locationTabValue] === 'Country') {
      headersArray = ['Country', 'Viewers'];
    } else if (SUB_LOCATIONS_ENUM[locationTabValue] === 'Region') {
      headersArray = ['Region', 'Country', 'Viewers'];
    } else if (SUB_LOCATIONS_ENUM[locationTabValue] === 'City') {
      headersArray = ['City', 'Region', 'Country', 'Viewers'];
    }
  } else if (eventValue === GROUP_VALUES.EVENT_TYPE) {
    if (SUB_LOCATIONS_ENUM[locationTabValue] === 'Country') {
      headersArray = ['Country', 'Live', 'simLive', 'onDemand'];
    } else if (SUB_LOCATIONS_ENUM[locationTabValue] === 'Region') {
      headersArray = ['Region', 'Country', 'Live', 'simLive', 'onDemand'];
    } else if (SUB_LOCATIONS_ENUM[locationTabValue] === 'City') {
      headersArray = ['City', 'Region', 'Country', 'Live', 'simLive', 'onDemand'];
    }
  } else if (eventValue === GROUP_VALUES.DEVICE_TYPE) {
    if (SUB_LOCATIONS_ENUM[locationTabValue] === 'Country') {
      headersArray = ['Country', 'Mobile', 'Desktop', 'Tablet', 'Game Console', 'Set Top Box', 'TV', 'Other'];
    } else if (SUB_LOCATIONS_ENUM[locationTabValue] === 'Region') {
      headersArray = ['Region', 'Country', 'Mobile', 'Desktop', 'Tablet', 'Game Console', 'Set Top Box', 'TV', 'Other'];
    } else if (SUB_LOCATIONS_ENUM[locationTabValue] === 'City') {
      headersArray = [
        'City',
        'Region',
        'Country',
        'Mobile',
        'Desktop',
        'Tablet',
        'Game Console',
        'Set Top Box',
        'TV',
        'Other',
      ];
    }
  }
  return headersArray;
};

export const downloadAsCSV = (data: string, exportedFilename = 'export-data.csv'): void => {
  const blob = new Blob([data], { type: 'text/csv;charset=utf-8;' });
  if (navigator.msSaveBlob) {
    // IE 10+
    navigator.msSaveBlob(blob, exportedFilename);
  }
  const link = document.createElement('a');
  if (link.download !== undefined) {
    // feature detection
    // Browsers that support HTML5 download attribute
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', exportedFilename);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};

export const getKeyByValue = (object: BracketReference<string | number>, value: string): string | undefined => {
  return Object.keys(object).find((key: string) => object[key] === value);
};

export const renameKey = (
  object: BracketReference<number[]>,
  key: string,
  newKey: string
): BracketReference<number[]> => {
  const clonedObj = clone(object);
  const targetKey = clonedObj[key];
  delete clonedObj[key];
  clonedObj[newKey] = targetKey;
  return clonedObj;
};

export const getIndicesWithValues = (startTimeValue: string, endTimeValue: string, inputLabels: string[]): string[] => {
  const indexArray = [inputLabels[0], inputLabels[inputLabels.length - 1]];
  const isBetweenRange = (value: string, startTime: number, endTime: number): boolean => {
    const filterTime = Number(value);
    if (!(isNaN(startTime) || isNaN(endTime) || isNaN(filterTime))) {
      return filterTime >= startTime && filterTime <= endTime;
    } else {
      return false;
    }
  };
  let validStartTimeValue = '';
  let validEndTimeValue = '';
  let exactStartTimeValue = '';
  let exactEndTimeValue = '';

  inputLabels.forEach((rangeString) => {
    const [startVal, endVal] = rangeString.split('-');
    const startTime = Number(startVal);
    const endTime = Number(endVal?.slice(0, -1));
    if (isBetweenRange(startTimeValue, startTime, endTime)) {
      exactStartTimeValue = rangeString;
    } else if (!validStartTimeValue && startTime >= Number(startTimeValue)) {
      validStartTimeValue = rangeString;
    }
    if (isBetweenRange(endTimeValue, startTime, endTime)) {
      exactEndTimeValue = rangeString;
    } else if (endTime <= Number(endTimeValue)) {
      validEndTimeValue = rangeString;
    }
  });

  if (exactStartTimeValue) {
    indexArray[0] = exactStartTimeValue;
  } else if (validStartTimeValue) {
    indexArray[0] = validStartTimeValue;
  }
  if (exactEndTimeValue) {
    indexArray[1] = exactEndTimeValue;
  } else if (validEndTimeValue) {
    indexArray[1] = validEndTimeValue;
  }
  return indexArray;
};

export const splitToChunks = (array: string[], parts: number): Array<string[]> => {
  const result = [];
  for (let i = parts; i > 0; i--) {
    result.push(array.splice(0, Math.ceil(array.length / i)));
  }
  return result;
};

export const formatSecondsToMinutesString = (seconds: number): string => {
  const duration = Duration.fromObject({ seconds }).toFormat("m'm's's'");

  return duration.replace(/^(0m)/, '');
};

export const formatTooltipToNormalisedTime = (tooltipItem: TooltipItem<keyof ChartTypeRegistry>): string => {
  return ` ${startCase(tooltipItem.dataset.label) || ''} : ${getFormatTime(
    Number(tooltipItem.dataset.data[tooltipItem.dataIndex]) || 0
  )}`;
};

export const fetchAndMergeResponse = async (
  fetchFunction: (
    offset1: string | number | undefined,
    offset2?: string | number | undefined
  ) => Promise<CsvDataObject[]>,
  maxResponseLimit: number = MAX_FETCH_LIMIT_FOR_CSV_EXPORT,
  offsetKey1: CSV_KEY_FIELDS,
  offsetKey2?: CSV_KEY_FIELDS
): Promise<CsvDataObject[]> => {
  let offsetValue1: string | number | undefined = '';
  let offsetValue2: string | number | undefined = '';
  let responseArray: CsvDataObject[] = [];
  const maxSuccessfulFetchCalls = MAX_FETCH_CALLS_FOR_CSV;
  let succesfullFetchCount = 0;
  let shouldFetchAgain = true;
  while (shouldFetchAgain) {
    try {
      const response: CsvDataObject[] = await fetchFunction(offsetValue1, offsetValue2);
      succesfullFetchCount = succesfullFetchCount + 1;
      responseArray = [...responseArray, ...response];
      shouldFetchAgain = succesfullFetchCount < maxSuccessfulFetchCalls && !(response?.length < maxResponseLimit);

      if (response.length && offsetKey1 && offsetKey2) {
        offsetValue1 = response[response.length - 1][offsetKey1];
        offsetValue2 = response[response.length - 1][offsetKey2];
        if (!offsetValue1 && !offsetValue2) {
          shouldFetchAgain = false;
        }
      }
    } catch (error) {
      console.error(error);
      shouldFetchAgain = false;
    }
  }
  return responseArray;
};

export const isMonthTabDisabled = (value: string | number): boolean => {
  let isMonthTabDisabled = false;
  if (value === 'm0' || value === 'm1' || value === 'm2') {
    isMonthTabDisabled = true;
  }
  return isMonthTabDisabled;
};

const getFilteredSelectOptions = (): SelectOption[] => {
  const filteredDayRangeOptions = dayRangeOptions.filter(
    (dayObj) => dateRangeValues[dayObj.value].startDate >= START_DATE_LOCAL.toISO()
  );

  const filteredPrevious3MonthOption = previous3MonthOption.filter(
    (monthObj) => dateRangeValues[monthObj.value].startDate > START_DATE_LOCAL.toISO()
  );
  const filteredTranslatedPrevious3MonthOption = filteredPrevious3MonthOption.map((monthObj) => {
    const [monthName, yearName] = monthObj.label.split(' ');
    return {
      label: `${monthName} ${yearName}`,
      value: monthObj.value,
    };
  });
  const filteredCurrentAndPreviousYearOptions = currentAndPreviousYearOptions.filter(
    (yearObj) => dateRangeValues[yearObj.value].startDate > START_DATE_LOCAL.toISO()
  );
  const CustomDateRangeOption = {
    label: customDateRangeOption.label,
    value: customDateRangeOption.value,
  };
  return [
    ...filteredDayRangeOptions,
    ...filteredTranslatedPrevious3MonthOption,
    ...filteredCurrentAndPreviousYearOptions,
    CustomDateRangeOption,
  ];
};
export const getDateRangeOptions = (viewAllData: boolean, isYT?: boolean): SelectOption[] => {
  if (isYT) {
    return dayRangeOptions.reduce((agg, dayObj) => {
      if (['7', '28'].includes(dayObj.value)) {
        agg.push({
          label: dayObj.label,
          value: dayObj.value,
        });
      }
      return agg;
    }, []);
  } else if (!viewAllData) {
    return getFilteredSelectOptions();
  } else {
    const translatedDayRangeOptions = dayRangeOptions.map((dayObj) => ({
      label: dayObj.label,
      value: dayObj.value,
    }));
    const translatedPrevious3MonthOption = previous3MonthOption.map((monthObj) => {
      const [monthName, yearName] = monthObj.label.split(' ');
      return {
        label: `${monthName} ${yearName}`,
        value: monthObj.value,
      };
    });
    const tranlatedCustomDateRangeOption = {
      label: customDateRangeOption.label,
      value: customDateRangeOption.value,
    };
    return [
      ...translatedDayRangeOptions,
      ...translatedPrevious3MonthOption,
      ...currentAndPreviousYearOptions,
      tranlatedCustomDateRangeOption,
    ];
  }
};

export const getToolTipLabel = (dataPageName: string, page?: DESTINATION_TYPES): string => {
  if (page === DESTINATION_TYPES.STREAM && dataPageName === PAGE_VALUE.MINUTE_BY_MINUTE) {
    return TOOLTIP_LABEL.VIEWERS;
  } else {
    switch (dataPageName) {
      case PAGE_VALUE.VIEWERS:
        return TOOLTIP_LABEL.VIEWERS;
      case PAGE_VALUE.MINUTE_BY_MINUTE:
        return TOOLTIP_LABEL.VIEWS;
      case PAGE_VALUE.AVG_WATCH_TIME:
        return '';
      case PAGE_VALUE.LIVE_VIEWS:
        return TOOLTIP_LABEL.VIEWS;
      default:
        return '';
    }
  }
};

export const parseMinByMinLineChartData = (response: API_RESPONSE_TYPE): lineChartDataType => {
  const lineChartData: lineChartDataType = {
    labels: [],
    datasets: {},
  };

  const { minByMins } = response;

  minByMins?.forEach((element, _index) => {
    //Labels
    const { minByMin: label = '' } = element;
    lineChartData.labels.push(label.toString().concat('m'));

    // Legends
    const legends = Object.keys(element).filter((key) => key !== 'minByMin');

    const { length: labelArrayLength } = minByMins;

    // Datasets
    legends.map((legend) => {
      if (Object.values(API_RESPONSE_LEGENDS).includes(legend)) {
        if (!(legend in lineChartData.datasets)) {
          lineChartData.datasets[legend] = new Array(labelArrayLength).fill(0);
        }
        switch (legend) {
          case API_RESPONSE_LEGENDS.NONE:
            lineChartData.datasets.none[_index] = element.none?.views || element.none?.viewers || 0;
            break;
          case API_RESPONSE_LEGENDS.LIVE:
            lineChartData.datasets.live[_index] = element.live?.views || element.live?.viewers || 0;
            break;
          case API_RESPONSE_LEGENDS.SIM_LIVE:
            lineChartData.datasets.simLive[_index] = element.simLive?.views || element.simLive?.viewers || 0;
            break;
          case API_RESPONSE_LEGENDS.ON_DEMAND:
            lineChartData.datasets.onDemand[_index] = element.onDemand?.views || element.onDemand?.viewers || 0;
            break;
          case API_RESPONSE_LEGENDS.DESKTOP:
            lineChartData.datasets.desktop[_index] = element.desktop?.views || element.desktop?.viewers || 0;
            break;
          case API_RESPONSE_LEGENDS.MOBILE:
            lineChartData.datasets.mobile[_index] = element.mobile?.views || element.mobile?.viewers || 0;
            break;
          case API_RESPONSE_LEGENDS.TABLET:
            lineChartData.datasets.tablet[_index] = element.tablet?.views || element.tablet?.viewers || 0;
            break;
          case API_RESPONSE_LEGENDS.GAMECONSOLE:
            lineChartData.datasets.gameConsole[_index] =
              element.gameConsole?.views || element.gameConsole?.viewers || 0;
            break;
          case API_RESPONSE_LEGENDS.TV:
            lineChartData.datasets.tv[_index] = element.tv?.views || element.tv?.viewers || 0;
            break;
          case API_RESPONSE_LEGENDS.SET_TOP_BOX:
            lineChartData.datasets.setTopBox[_index] = element.setTopBox?.views || element.setTopBox?.viewers || 0;
            break;
          case API_RESPONSE_LEGENDS.OTHER:
            lineChartData.datasets.other[_index] = element.other?.views || element.other?.viewers || 0;
            break;
        }
      }
    });
  });
  return lineChartData;
};

export const parseLineChartDataMinByMinDeviceBrand = (response: API_RESPONSE_TYPE): lineChartDataType => {
  const lineChartData: lineChartDataType = {
    labels: [],
    datasets: {},
  };
  const { minByMins } = response;
  const legendsArray: string[] = [];
  minByMins?.forEach((element, _index) => {
    //Labels
    const { minByMin: label = '' } = element;
    lineChartData.labels.push(label.toString().concat('m'));
    // Legends
    const legends = Object.keys(element).filter((key) => key !== 'minByMin');

    legends.map((eachLegends) => {
      legendsArray.push(eachLegends);
    });
  });
  const filteredLegendsArray = legendsArray.filter((value, index) => legendsArray.indexOf(value) === index);
  filteredLegendsArray.map((eachLegend) => {
    lineChartData.datasets[eachLegend] = [];
  });
  minByMins?.forEach((element, _index) => {
    filteredLegendsArray.map((eachLegend) => {
      lineChartData.datasets[eachLegend][_index] =
        element[eachLegend as DEVICE_TYPES]?.viewers ||
        element[eachLegend as DEVICE_TYPES]?.avgWatchTime ||
        element[eachLegend as DEVICE_TYPES]?.views ||
        0;
    });
  });
  return lineChartData;
};
