import React, { useEffect, useState, useReducer, FC, useContext } from 'react';
import PropTypes from 'prop-types';
import EmbedPlayerAnalyticsView from './embed-player-analytics-view';
import { EmbedPlayerContext, EmbedPlayerReducer } from '../../../contexts/eventanalytics';
import { INITIAL_EMBED_PAGE_STATE, STATS_KEYS_EMBED } from '../event-analytics-constants';
import { StatsArray } from '../types';
import { getHeatMapData } from '../../../components/HeatMap/utils';
import { fetchMapData, fetchSummaryStats } from './api';
import { getStatsGroupsFromResponse } from '../utils';
import { LocationType } from '../../../components/HeatMap/types';
import { EventAnalyticsContext } from '../../../contexts/eventanalytics/event-analytics';

export const EmbedPlayerAnalytics: FC<{ hasGraphPerms: boolean }> = ({ hasGraphPerms }): JSX.Element => {
  const [statsArray, setStatsArray] = useState<StatsArray>([]);
  const [embedPlayerAnalyticsState, dispatch] = useReducer(EmbedPlayerReducer, INITIAL_EMBED_PAGE_STATE);
  const { EventAnalyticsState } = useContext(EventAnalyticsContext);
  const [heatMapPositions, setHeatMapPositions] = useState<LocationType[]>([]);
  const [isMapLoading, setIsMapLoading] = useState<boolean>(true);
  const [isCardLoading, setCardLoading] = useState<boolean>(true);

  const getData = async () => {
    setIsMapLoading(true);
    setCardLoading(true);
    const statsArray = await fetchSummaryStats(EventAnalyticsState);
    setCardLoading(false);
    const heatMap = await fetchMapData(EventAnalyticsState);
    setIsMapLoading(false);
    setStatsArray(getStatsGroupsFromResponse(STATS_KEYS_EMBED, statsArray[0]));
    setHeatMapPositions(getHeatMapData(heatMap));
  };

  useEffect(() => {
    getData();
  }, [EventAnalyticsState.dateRange, EventAnalyticsState.eventId]);

  return (
    <EmbedPlayerContext.Provider value={{ embedPlayerAnalyticsState, dispatch }}>
      <EmbedPlayerAnalyticsView
        statsArray={statsArray}
        isCardLoading={isCardLoading}
        hasGraphPerms={hasGraphPerms}
        heatMapData={heatMapPositions}
        isMapLoading={isMapLoading}
      />
    </EmbedPlayerContext.Provider>
  );
};

EmbedPlayerAnalytics.propTypes = {
  hasGraphPerms: PropTypes.bool.isRequired,
};

EmbedPlayerAnalytics.displayName = 'EmbedPlayerAnalytics';

export default EmbedPlayerAnalytics;
