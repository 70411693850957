import * as React from 'react';
import { Cue } from '../../helpers/events/types';
import Modal from '../Modal';
import ModalHeader from '../ModalHeader';
import Portal from '../Portal';
import TrimWebEventCompleted from '../TrimWebEventCompleted';
import TrimWebEventForm from '../TrimWebEventForm';
import PropTypes from 'prop-types';

interface TrimWebEventModalProps {
  cues?: Cue[];
  handleCloseModal: () => void;
  webEventId?: string;
  customerId?: string;
  webEventProfileId?: string;
  webEventName?: string;
  isWebEventLive?: boolean;
  isWebEventProfileInUse?: boolean;
}

export interface TrimWebEventState {
  webEventName?: string;
  startPosition: Cue | null;
  endPosition: Cue | null;
  published: boolean;
  customerId?: string;
  webEventId?: string;
  webEventProfileId?: string;
  progress: number;
  newWebEventLocation: string;
}

const TrimWebEventModal: React.FC<TrimWebEventModalProps> = ({
  cues,
  handleCloseModal,
  webEventId,
  customerId,
  webEventProfileId,
  webEventName,
  isWebEventLive,
  isWebEventProfileInUse
}) => {
  const initialState: TrimWebEventState = {
    webEventName,
    startPosition: null,
    endPosition: null,
    published: false,
    customerId,
    webEventId,
    webEventProfileId,
    progress: 0,
    newWebEventLocation: '',
  };

  const trimWebEventReducer = (state: TrimWebEventState, { field, value }: any): TrimWebEventState => {
    return {
      ...state,
      [field]: value,
    };
  };

  const [state, dispatch] = React.useReducer(trimWebEventReducer, initialState);

  return (
    <Portal>
      <Modal>
        <ModalHeader titleText="Trim Web Event" closeButton={state.newWebEventLocation.length > 0} onClose={handleCloseModal} badgeText={isWebEventLive ? "Live" : undefined}/>
        {state.newWebEventLocation && state.newWebEventLocation.length > 0 ? (
          <TrimWebEventCompleted newWebEventLocation={state.newWebEventLocation} />
        ) : (
          <TrimWebEventForm
            cues={cues}
            handleCloseModal={handleCloseModal}
            webEventProfileId={webEventProfileId}
            customerId={customerId}
            webEventId={webEventId}
            reducer={{ state, dispatch }}
            isWebEventLive={isWebEventLive}
            isWebEventProfileInUse={isWebEventProfileInUse}
          />
        )}
      </Modal>
    </Portal>
  );
};

TrimWebEventModal.displayName = 'TrimWebEventModal';

TrimWebEventModal.propTypes = {
  cues: PropTypes.array,
  handleCloseModal: PropTypes.func.isRequired,
  webEventId: PropTypes.string,
  customerId: PropTypes.string,
  webEventProfileId: PropTypes.string,
  webEventName: PropTypes.string
};

export default TrimWebEventModal;
