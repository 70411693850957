'use strict';

const jcs = require('../../jcs');
const { trackMixpanelEvent, MPEventProperty, MPEventName} = require('../../../../src/mixpanel');

function EncoderProfilesController($scope, $timeout, $routeParams, $http, focus, httpService, Authentication) {
	'ngInject';

	$(window).trigger('resize'); // ensure footer is properly positioned

	const VIDEO_FORMAT_REGEX = /(\d+)([a-z])(\d+(\.\d+)?)/;
	const PROGRESSIVE_FORMAT_CHAR = 'p';

	// https://docs.google.com/spreadsheets/d/1ilTkO8EpXPDUTdgyEpldtubltiAZIEcSMmkY6DcHw0A/
	// https://livingasone.atlassian.net/browse/MULTI-1331
	const BITS_PER_MEGABIT = 1000000;
	const VIDEO_CODEC_HEVC = 'HEVC';
	const VIDEO_CODEC_H264 = 'H264';
	const HEVC_CONSTANT = 0.0482;
	const H264_CONSTANT = 0.07;
	const FPS_THRESHOLD = 40;
	const FPS_CONSTANT_ABOVE_THRESHOLD = 0.7;
	const FPS_CONSTANT_BELOW_THRESHOLD = 1.0;
	const BIT_DEPTH_BASE = 8;
	const BANDWIDTH_FACTOR = 2.5;
	const HEIGHT_4K = 2160;
	const CODE_4K = '4k';
	const FPS_CONSTANT_BELOW_THRESHOLD_4K60 = 0.9;
	const MAX_ALLOWED_VIDEO_BITRATE_4K = 20;
	const MULTIPLIER_4K_BELOW_30 = 1.2;

	$scope.PROFILE_NAME_MAX_LENGTH = 50;
	$scope.ENCODER_PROFILE_NAME_DELIMITER = ' - ';

	$scope.is_loading = false; // show "loading ..." message
	$scope.is_working = false; // activity indicator (spinner icon)
	$scope.error_msg = null;

	$scope.encoder_profiles = null;
	$scope.is_loading_encoder_profiles = false;
	$scope.encoder_profiles_error_msg = null;

	$scope.web_profiles = null;
	$scope.is_loading_web_profiles = false;
	$scope.web_profiles_error_msg = null;

	$scope.encoder_to_view = null;
	$scope.encoder_to_view_combined_bitrate = null;
	$scope.encoder_to_view_bandwidth_recommendation = null;

	$scope.web_encoder_to_view = null;
	$scope.web_encoder_to_delete = null;
	// web encoder object that holds edit/add data
	$scope.web_profile_fields = null;

	// add/edit/copy encoder profile
	$scope.is_adding_editing_encoder_profile = false;
	$scope.profile_selections = null;
	$scope.total_bitrate_recommendation = null;
	$scope.total_combined_bitrate = null;
	$scope.bandwidth_recommendation = null;
	$scope.validation_errors = null;
	$scope.is_copying_profile = false;

	// custom profile edit
	$scope.profile_to_edit = null;
	$scope.profile_to_edit_name = '';
	$scope.profile_to_edit_ffmpeg = '';
	$scope.profile_to_edit_audio = '';
	$scope.profile_to_edit_video = '';
	$scope.profile_to_edit_map = '';
	$scope.profile_to_edit_input = '';

	// custom profile create
	$scope.is_adding_custom_profile = false;
	$scope.profile_to_add_name = '';
	$scope.profile_to_add_ffmpeg = '';
	$scope.profile_to_add_audio = '';
	$scope.profile_to_add_video = '';
	$scope.profile_to_add_map = '';
	$scope.profile_to_add_input = '';

	// profile delete
	$scope.profile_to_delete = null;

	// dropdown options
	$scope.yes_no_options = ['Yes', 'No'];
	$scope.web_encoder_video_channel_options = [1, 2];
	$scope.NONE_MONO_LABEL = 'None/Mono';
	$scope.left_audio_channel_options = [
		{ label: 'Ch 1', value: 1 },
		{ label: 'Ch 2', value: 2 },
		{ label: 'Ch 3', value: 3 },
		{ label: 'Ch 4', value: 4 },
		{ label: 'Ch 5', value: 5 },
		{ label: 'Ch 6', value: 6 },
		{ label: 'Ch 7', value: 7 },
		{ label: 'Ch 8', value: 8 },
		{ label: 'Ch 9', value: 9 },
		{ label: 'Ch 10', value: 10 },
		{ label: 'Ch 11', value: 11 },
		{ label: 'Ch 12', value: 12 },
		{ label: 'Ch 13', value: 13 },
		{ label: 'Ch 14', value: 14 },
		{ label: 'Ch 15', value: 15 },
		{ label: 'Ch 16', value: 16 },
	];
	$scope.right_audio_channel_options = $scope.left_audio_channel_options.concat([{ label: $scope.NONE_MONO_LABEL, value: null }]);

	$scope.web_encoder_framerate_options = [
		//				{value: '60', 			name: '60'},
		{ value: '60000/1001', name: '59.94' },
		//				{value: '30', 			name: '30'},
		{ value: '30000/1001', name: '29.97' },
		//				{value: '25', 			name: '25'},
		//				{value: '24', 			name: '24'},
		{ value: '24000/1001', name: '23.97' },
	];

	$scope.DEINTERLACE_YES = 'Yes';
	$scope.DEINTERLACE_NO = 'No';
	$scope.DEINTERLACE_DEFAULT = $scope.DEINTERLACE_NO;
	$scope.deinterlace_options = [$scope.DEINTERLACE_YES, $scope.DEINTERLACE_NO];

	$scope.HARDWARE_ACCELERATION_ENABLED = 'On';
	$scope.HARDWARE_ACCELERATION_DISABLED = 'Off';
	$scope.HARDWARE_ACCELERATION_DEFAULT = $scope.HARDWARE_ACCELERATION_ENABLED;
	$scope.hardware_acceleration_options = [$scope.HARDWARE_ACCELERATION_ENABLED, $scope.HARDWARE_ACCELERATION_DISABLED];

	$scope.video_input_format_pal_options = [];
	$scope.video_input_format_ntsc_options = [];
	$scope.video_output_map_ntsc = null;
	$scope.video_output_map_pal = null;

	$scope.max_video_bitrate = null;
	$scope.max_option_video_bitrate = null;

	$scope.STREAMING_TECHNIQUE_DEFAULT = 'DASH';

	$scope.audio_channel_count_options = [];
	$scope.audio_bitrate_options = [];
	$scope.video_channel_count_options = [];

	$scope.VIDEO_FORMAT_NTSC = 'NTSC';
	$scope.VIDEO_FORMAT_PAL = 'PAL';
	$scope.VIDEO_FORMAT_AUTO = 'auto';
	$scope.video_format_std_options = [$scope.VIDEO_FORMAT_NTSC, $scope.VIDEO_FORMAT_PAL];
	$scope.video_codec_options = [];
	$scope.video_bit_depth_options = [];
	$scope.VIDEO_CHANNEL_OFFSET_DEFAULT = 0;
	$scope.is_4k = false;

	$scope.video_input_format_options = $scope.video_input_format_ntsc_options;
	$scope.video_output_format_options = null;

	$scope.show_video_output_format_interlaced_warning = false;

	$scope.SHORT_SEGMENTS_ON = 'On';
	$scope.SHORT_SEGMENTS_OFF = 'Off';
	$scope.short_segments_options = [
		{ value: true, label: $scope.SHORT_SEGMENTS_ON },
		{ value: false, label: $scope.SHORT_SEGMENTS_OFF }
	];

	$scope.updateCodecHardwareAccel = function() {
		// Force usage of H264 and Hardware Acceleration for 4k
		if ($scope.profile_selections.video_output_format_code.startsWith(CODE_4K)) {
			$scope.profile_selections.hardware_acceleration = $scope.HARDWARE_ACCELERATION_ENABLED;
			$scope.profile_selections.video_codec = VIDEO_CODEC_H264;
			$scope.is_4k = true;
			$scope.updateBitrateRecommendation();
			$scope.updateAutoGeneratedName();
		} else {
			$scope.is_4k = false;
		}
	}

	$scope.updateHardwareAcceleration = function() {
		// Software HEVC doesn't actually work, and will really be software H264.
		// Force hardware acceleration on if HEVC is selected.
		if ($scope.profile_selections.video_codec === VIDEO_CODEC_HEVC) {
			$scope.profile_selections.hardware_acceleration = $scope.HARDWARE_ACCELERATION_ENABLED;
		}
	};

	$scope.getCurrentUser = function () {
		return Authentication.getCurrentUser();
	};

	$scope.canShowEncoderProfileAddBtn = function () {
		if ($scope.getCurrentUser().hasPerm('la1only')){
			return true;
		}
		return $scope.getCurrentUser().hasPerm('encoder_profiles.add');
	};

	$scope.canShowEncoderProfileEditBtn = function (profile) {
		if ($scope.getCurrentUser().hasPerm('la1only')){
			return true;
		}
		// the old v2 (custom) encoder profiles cannot be edited by non-la1 users
		return !profile.customProfile && $scope.getCurrentUser().hasPerm('encoder_profiles.update');
	};

	$scope.canShowEncoderProfileCopyBtn = function (profile) {
		if ($scope.getCurrentUser().hasPerm('la1only')){
			return true;
		}
		// the old v2 (custom) encoder profiles cannot be copied by non-la1 users
		return !profile.customProfile && $scope.getCurrentUser().hasPerm('encoder_profiles.add');
	};

	$scope.canShowEncoderProfileDeleteBtn = function (profile) {
		if ($scope.getCurrentUser().hasPerm('la1only')){
			return true;
		}
		// the old v2 (custom) encoder profiles cannot be deleted by non-la1 users
		return !profile.customProfile && $scope.getCurrentUser().hasPerm('encoder_profiles.delete');
	};

	$scope.canShowEncoderProfileActionBtns = function (profile) {
		if ($scope.getCurrentUser().hasPerm('la1only')){
			return true;
		}
		// non-la1 users cannot do anything with v2 profiles (other than just see name in list of profiles)
		return !profile.customProfile;
	};

	$scope.canShowShortSegments = function () {
		return $scope.getCurrentUser().hasPerm('la1only');
	}

	$scope.formatAutoGeneratedField = function (value, postfix){
		if (typeof value !== 'undefined' && value !== null && value !== ''){
			return value + postfix;
		}
		return '';
	}

	$scope.isEncoderAutoInputEnabled = function(){
		return Authentication.$rootScope?.ldClient.allFlags()['encoder-auto-input'];
	}

	
	$scope.isEncoder4kInputEnabled = function(){
		return Authentication.$rootScope?.ldClient.allFlags()['encoder-4k-input'];
	}
 
	$scope.updateAutoGeneratedName = function () {

		const input_format_code = $scope.profile_selections.video_input_format_code;
		const output_format_code = $scope.profile_selections.video_output_format_code;
		// if the input and output formats are different, then we want to display both in the name (to show the change in format). If they
		// are both the same, then only need to show the single format.
		let format = '';
		if (input_format_code !== null){
			const input_format =  (input_format_code != null && input_format_code != '') ? $scope.getFormatName(input_format_code) : '';
			if (output_format_code !== null && input_format_code !== output_format_code){
				const output_format =  (output_format_code != null && output_format_code != '') ? $scope.getFormatName(output_format_code) : '';
				format = `${input_format}->${output_format}`;
			} else {
				format = input_format;
			}
		}

		const parsed_bitrate = parseFloat($scope.profile_selections.video_encode_bitrate);
		const calculated_bitrate = isNaN(parsed_bitrate) ? '' : parsed_bitrate * $scope.profile_selections.video_channel_count;

		const vid_ch_count = $scope.formatAutoGeneratedField($scope.profile_selections.video_channel_count, '');
		const vid_enc_bitrate = $scope.formatAutoGeneratedField(calculated_bitrate, 'Mb');
		const vid_codec = $scope.formatAutoGeneratedField($scope.profile_selections.video_codec, '');
		const aud_ch_count = $scope.formatAutoGeneratedField($scope.profile_selections.audio_channel_count, '-Ch');

		$scope.profile_selections.profile_name = `${vid_ch_count}-${format} ${vid_enc_bitrate} ${vid_codec} ${aud_ch_count}`;
	};

	$scope.getOutputFormatInfo = function (output_options, output_format_code) {
		if (output_options !== null){
			for (const option of output_options){
				if (option.code === output_format_code){
					return option;
				}
			}
		}
		return null;
	};

	$scope.getWidthFromHeight = function (height) {
		// PAL apparently has one format that is 4x3 aspect ratio
		if (height === '576')
			return 720;
		return height / 9 * 16;
	};

	$scope.getFrameRate = function (is4K, framerate){
		if (is4K) {
			return framerate > FPS_THRESHOLD ? FPS_CONSTANT_ABOVE_THRESHOLD * FPS_CONSTANT_ABOVE_THRESHOLD : FPS_CONSTANT_BELOW_THRESHOLD_4K60;
		} else {
			return framerate > FPS_THRESHOLD ? FPS_CONSTANT_ABOVE_THRESHOLD : FPS_CONSTANT_BELOW_THRESHOLD;
		}
	}

	$scope.getBitrateRecommendation = function (output_format_info) {

		const codec = $scope.profile_selections.video_codec;
		const format_std = $scope.profile_selections.video_format_std;
		const channels = $scope.profile_selections.video_channel_count;
		const bit_depth = $scope.profile_selections.video_bit_depth;
		const height = parseInt(output_format_info.height);
		const framerate = parseFloat(output_format_info.framerate);

		if (codec === null || format_std === null || channels === null || bit_depth === null || isNaN(height) || isNaN(framerate)){
			return null;
		}

		const width = $scope.getWidthFromHeight(height);
		const pixels = height * width;
		const bit_depth_factor = bit_depth / BIT_DEPTH_BASE;
		const codec_factor = codec === VIDEO_CODEC_HEVC ? HEVC_CONSTANT : H264_CONSTANT;
		const is4K = height === HEIGHT_4K;
		const getFrameRateFactor = $scope.getFrameRate(is4K, framerate);
		const totalMbps = pixels * framerate * bit_depth_factor * codec_factor * getFrameRateFactor;
		const recommendation_mbps = is4K && framerate < 29.97 ? (totalMbps * MULTIPLIER_4K_BELOW_30 / BITS_PER_MEGABIT) : totalMbps / BITS_PER_MEGABIT;
		$scope.max_video_bitrate = is4K ? MAX_ALLOWED_VIDEO_BITRATE_4K : $scope.max_option_video_bitrate;
		// round up to the nearest whole number, and ensure we don't exceed max allowed bitrate
		const rounded_mbsp = Math.min(Math.ceil(recommendation_mbps), $scope.max_video_bitrate / channels);

		return rounded_mbsp;
	};

	// update the bitrate recommendation (this is for the total bitrate field in the video section)
	$scope.updateBitrateRecommendation = function () {

		const output_format = $scope.profile_selections.video_output_format_code;
		const output_format_info = $scope.getOutputFormatInfo($scope.video_output_format_options, output_format);

		if (output_format === null || output_format_info === null || $scope.max_video_bitrate === null){
			$scope.total_bitrate_recommendation = null;
			return;
		}

		$scope.total_bitrate_recommendation = $scope.getBitrateRecommendation(output_format_info);
	};

	// video and audio bitrate is per channel
	$scope.getTotalCombinedBitrate = function (video_channel_count, video_bitrate_mbps, audio_channel_count, audio_bitrate_kbps) {

		// ensure fields are filled
		if (video_channel_count === null || video_bitrate_mbps === null || audio_bitrate_kbps === null || audio_channel_count === null){
			return null;
		}
		// ensure fields are valid
		const parsed_video_channel_count = parseInt(video_channel_count);
		const parsed_video_bitrate_mbps = parseFloat(video_bitrate_mbps);
		const parsed_audio_channel_count = parseFloat(audio_channel_count);
		const parsed_audio_bitrate_kbps = parseFloat(audio_bitrate_kbps);
		if (isNaN(parsed_video_channel_count) || isNaN(parsed_video_bitrate_mbps) || isNaN(parsed_audio_channel_count) || isNaN(parsed_audio_bitrate_kbps)){
			return null;
		}

		const total_video_bitrate_mbps = parsed_video_bitrate_mbps * parsed_video_channel_count;
		const total_audio_bitrate_mbps = parsed_audio_bitrate_kbps * parsed_audio_channel_count / 1000;
		return (total_video_bitrate_mbps + total_audio_bitrate_mbps).toFixed(1);
	};

	$scope.getBandwidthRecommendation = function (bitrate) {
		return (bitrate * BANDWIDTH_FACTOR).toFixed(1);
	};

	// update the bandwidth recommendation (this takes both video and audio into account)
	$scope.updateBandwidthRecommendation = function () {

		$scope.total_combined_bitrate = $scope.getTotalCombinedBitrate($scope.profile_selections.video_channel_count, $scope.profile_selections.video_encode_bitrate, $scope.profile_selections.audio_channel_count, $scope.profile_selections.audio_bitrate);
		if ($scope.total_combined_bitrate !== null){
			$scope.bandwidth_recommendation = $scope.getBandwidthRecommendation($scope.total_combined_bitrate);
		}
	};

	$scope.getVideoFormatStandard = function (format) {
		if (format === 'auto') return $scope.VIDEO_FORMAT_AUTO;
		return $scope.isPALFormat(format) ? $scope.VIDEO_FORMAT_PAL : $scope.VIDEO_FORMAT_NTSC;
	};

	/*			$scope.getFrameRateName = function (value){

				  for (var i=0; i < $scope.web_encoder_framerate_options.length; i++){
					  var option = $scope.web_encoder_framerate_options[i];
					  if (value === option.value)
						  return option.name;
				  }
				  return value;
			  };

			  $scope.getAdaptiveBitRateText = function (video){
				  // use 720p59.94 format for 16x9 ratios
				  // for all others use 960 x 540p59.94
				  var is16x9 = video.width/16 === video.height/9;
				  if (is16x9){
					  return video.height + 'p' + $scope.getFrameRateName(video.frameRate);
				  }
				  return video.width + ' x ' + video.height + 'p' + $scope.getFrameRateName(video.frameRate);
			  };*/

	$scope.getAudioChannelText = function (channel) {
		if (channel === null) {
			return $scope.NONE_MONO_LABEL;
		}
		return 'Ch ' + channel;
	};

	$scope.getDeinterlaceAsText = function (profile) {
		if (profile != null && profile.hasOwnProperty('deinterlace')) {
			return profile.deinterlace ? 'Yes' : 'No';
		}
		return '';
	};

	$scope.getBooleanLabel = function (value) {
		return value ? 'Yes' : 'No';
	};

	$scope.getFormatName = function (format_code) {
		const format_list = $scope.isPALFormat(format_code) ? $scope.video_input_format_pal_options : $scope.video_input_format_ntsc_options;

		return $scope.getFormatNameFromList(format_list, format_code);
	};

	$scope.getFormatNameFromList = function (format_list, format_code) {
		for (let i = 0; i < format_list.length; i++) {
			if (format_list[i].code == format_code) {
				return format_list[i].name;
			}
		}
		return 'Unknown';
	};

	$scope.isPALFormat = function (format_code) {
		for (let i = 0; i < $scope.video_input_format_pal_options.length; i++) {
			if ($scope.video_input_format_pal_options[i].code == format_code) {
				return true;
			}
		}
		return false;
	};

	$scope.updateInputFormatDropdownOptions = function (selections) {
		if (selections.video_format_std == $scope.VIDEO_FORMAT_NTSC) {
			$scope.video_input_format_options = $scope.video_input_format_ntsc_options;
		} else if (selections.video_format_std == $scope.VIDEO_FORMAT_PAL) {
			$scope.video_input_format_options = $scope.video_input_format_pal_options;
		}
	};

	$scope.onVideoFormatStandardChanged = function (selections) {
		// update our dropdown options based on ntsc or pal
		$scope.updateInputFormatDropdownOptions(selections);

		$scope.profile_selections.video_input_format_code = null;
		$scope.profile_selections.video_output_format_code = null;
		$scope.updateAutoGeneratedName();

		$scope.total_bitrate_recommendation = null;
	};

	$scope.isValidOption = function (options, format_code) {
		if (!options?.length) return false;

		for (let i=0; i < options.length; i++){
			if (options[i].code === format_code){
				return true;
			}
		}
		return false;
	};

	$scope.getInputOptionInfo = function (format_code){
		if ($scope.video_input_format_options !== null){
			for (const option of $scope.video_input_format_options){
				if (option.code === format_code){
					return option;
				}
			}
		}
		return null;
	};

	$scope.showInterlacedOutputWarning = function (selections){
		return  selections !== null && selections.video_input_format_code !== null && $scope.show_video_output_format_interlaced_warning;
	};

	$scope.onVideoOutputFormatChanged = function (selections) {
		if (selections.video_output_format_code !== null){
			const info = $scope.getOutputFormatInfo($scope.video_output_format_options, selections.video_output_format_code);
			$scope.show_video_output_format_interlaced_warning = info !== null && !info.isProgressive;
		} else {
			$scope.show_video_output_format_interlaced_warning = false;
		}
	};

	// update output format dropdown with options that should be used with the new selected input
	$scope.updateOutputFormatOptions = function (selections) {

		const format_name = $scope.getFormatName(selections.video_input_format_code);
		if ($scope.isPALFormat(selections.video_input_format_code)){
			if ($scope.video_output_map_pal.hasOwnProperty(format_name)){
				$scope.video_output_format_options = $scope.video_output_map_pal[format_name];
			} else {
				console.error(`No output options found for PAL format: ${format_name}`);
			}
		} else {
			if ($scope.video_output_map_ntsc.hasOwnProperty(format_name)){
				$scope.video_output_format_options = $scope.video_output_map_ntsc[format_name];
			} else {
				console.error(`No output options found for NTSC format: ${format_name}`);
			}
		}
	};

	$scope.onVideoInputFormatChanged = function (selections) {
		if (selections.video_input_format_code != null){

			// update output format dropdown with options that should be used with the new selected input
			$scope.updateOutputFormatOptions(selections);

			// determine recommended output for the selected input
			const input_option_info = $scope.getInputOptionInfo(selections.video_input_format_code);
			const recommended_output_format_code = input_option_info !== null ? input_option_info.recommendedOutput : null;

			// check to see if recommended option is available, if so use it. Otherwise see if the existing output option is available. If not then match the input format.
			if ($scope.isValidOption($scope.video_output_format_options, recommended_output_format_code)){
				selections.video_output_format_code = recommended_output_format_code;
				$scope.onVideoOutputFormatChanged(selections);
			} else if (!$scope.isValidOption($scope.video_output_format_options, selections.video_output_format_code)){
				selections.video_output_format_code = selections.video_input_format_code;
				$scope.onVideoOutputFormatChanged(selections);
			}
		}
	};

	$scope.getVideoFormatInfo = function (item) {
		let found = item.format.match(VIDEO_FORMAT_REGEX);
		const isAuto = item.format === 'auto';

		if ((found === null || found.length < 3) && !isAuto){
			throw Error(`Unable to parse video format: ${item.format}`);
		}

		return {
			code: item.name,
			name: item.format,
			height: found?.[1] ?? '',
			framerate: found?.[3] ?? '',
			isProgressive: found?.[2] == PROGRESSIVE_FORMAT_CHAR || isAuto,
			recommendedOutput: item.preferredOutputName,
		};
	};

	// returns list of all valid output formats in the given format_list for the given input. Must match height (resolution). If given input
	// is progressive, then output must also be progressive. If given input is not progressive (interlaced), then any interlaced format with
	// matching height is fine, and any progressive format with matching height is fine as long as the framerate is half (rounded up) of
	// the interlaced format.
	$scope.getValidOutputFormats = function (format_list, input) {

		let matches = [];
		for (const output of format_list){
			if (input.code === output.code){
				// always include if it's the same
				matches.push(output);
			} else {
				// only outputs with the same height (resolution) are eligible
				if (input.height == output.height && (output.framerate - input.framerate) <= 1){
					// if input format is interlaced, then we can only show deinterlaced progressive options that are half the framerate
					if (!input.isProgressive && output.isProgressive){
						// is progressive framerate 1/2 of the interlaced (rounded up) or less?
						const half_framerate = Math.ceil(input.framerate/2.0);
						if (output.framerate <= half_framerate){
							matches.push(output);
						}
					}
					else if (!input.isProgressive || (input.isProgressive && output.isProgressive)){
						matches.push(output);
					}
				}
			}
		}
		return matches;
	};

	$scope.buildMap = function (format_list) {
		let map = {};
		for (let i=0; i < format_list.length; i++){
			let entry = format_list[i];
			map[entry.name] = $scope.getValidOutputFormats(format_list, entry);
		}
		return map;
	};

	$scope.setProfileOptions = function (options) {
		$scope.audio_channel_count_options = options.audioChannels;
		$scope.audio_bitrate_options = options.audioBitrates;
		$scope.video_channel_count_options = options.videoChannels;
		$scope.video_codec_options = options.codecs;
		$scope.video_bit_depth_options = options.bitDepths;
		$scope.max_video_bitrate = options.maxVideoBitrate;
		$scope.max_option_video_bitrate = options.maxVideoBitrate;

		// convert the video formats into info object that is easier to use
		$scope.video_input_format_pal_options = [];
		for (let i=0; i < options.palFormats.length; i++){
			if (options.palFormats[i].format === 'auto' && !$scope.isEncoderAutoInputEnabled()) continue;
			if (options.palFormats[i].format.startsWith(HEIGHT_4K.toString()) && !$scope.isEncoder4kInputEnabled()) continue;

			$scope.video_input_format_pal_options.push($scope.getVideoFormatInfo(options.palFormats[i]));
		}

		$scope.video_input_format_ntsc_options = [];
		for (let i=0; i < options.ntscFormats.length; i++){
			if (options.ntscFormats[i].format === 'auto' && !$scope.isEncoderAutoInputEnabled()) continue;
			if (options.ntscFormats[i].format.startsWith(HEIGHT_4K.toString()) && !$scope.isEncoder4kInputEnabled()) continue;
			
			$scope.video_input_format_ntsc_options.push($scope.getVideoFormatInfo(options.ntscFormats[i]));
		}

		// build our output format maps; when the input format changes, we'll use the map to determine what output format list to use in the dropdown
		$scope.video_output_map_ntsc = $scope.buildMap($scope.video_input_format_ntsc_options);
		$scope.video_output_map_pal = $scope.buildMap($scope.video_input_format_pal_options);

		// default to NTSC options
		$scope.video_input_format_options = $scope.video_input_format_ntsc_options;
	};

	/*			$scope.clearProfileOptions = function (){

				  $scope.streaming_technique_options = [];
				  $scope.audio_channel_count_options = [];
				  $scope.audio_bitrate_options = [];
				  $scope.video_channel_count_options = [];
				  $scope.video_codec_options = [];
				  $scope.video_bit_depth_options = [];
				  $scope.max_video_bitrate = null;

				  $scope.video_input_format_pal_options = [];
				  $scope.video_input_format_ntsc_options = [];
			  };*/

	$scope.enterViewEncoderProfileMode = function (profile) {
		$scope.error_msg = null;

		if (profile.customProfile) {
			$scope.is_loading = true;

			$http.get(`${jcs.api.url}/encoderprofiles/${profile.uuid}`, { withCredentials: true }).then(response => {

				$scope.encoder_to_view = response.data;

			}).catch(reason => {

				$scope.error_msg = 'Unable to retrieve profile information. Please try again, or report the problem if it persists.';
				$scope.encoder_to_view = null;

			}).finally(() => {

				$scope.is_loading = false;

			});

		} else {
			$scope.encoder_to_view = profile;
			$scope.encoder_to_view_combined_bitrate = $scope.getTotalCombinedBitrate(profile.video[0].input.length, profile.video[0].bitRate / profile.video[0].input.length, profile.audio.channels, profile.audio.bitratePerChan);
			$scope.encoder_to_view_bandwidth_recommendation = $scope.getBandwidthRecommendation($scope.encoder_to_view_combined_bitrate);
		}
	};

	$scope.enterViewWebEncoderProfileMode = function (profile) {
		$scope.error_msg = null;
		$scope.web_encoder_to_view = profile;
	};

	$scope.enterEditEncoderProfileMode = function (profile) {
		$scope.error_msg = null;
		$scope.encoder_to_view = null; // ensure this is cleared

		if (profile.customProfile) {
			$scope.is_loading = true;

			// custom profiles need to be fetched
			$http.get(`${jcs.api.url}/encoderprofiles/${profile.uuid}`, { withCredentials: true }).then(response => {

				$scope.profile_to_edit = response.data;

				$scope.profile_to_edit_name = $scope.profile_to_edit.name;
				$scope.profile_to_edit_ffmpeg = $scope.profile_to_edit.ffmpegOptions;
				$scope.profile_to_edit_audio = $scope.profile_to_edit.audioOptions;
				$scope.profile_to_edit_video = $scope.profile_to_edit.videoOptions;
				$scope.profile_to_edit_map = $scope.profile_to_edit.mapOptions;
				$scope.profile_to_edit_input = $scope.profile_to_edit.inputOptions;

				// see app.js for where focus is defined
				focus('edit-custom-profile-input');

			})
			.catch(reason => {

				$scope.error_msg = 'Unable to retrieve profile information. Please try again, or report the problem if it persists.';
				$scope.profile_to_edit = null;

			})
			.finally(() => {

				$scope.is_loading = false;

			});

		} else {

			$scope.validation_errors = {
				video_channels: [{}],
			};

			// initialize our form values
			$scope.profile_selections = {
				uuid: profile.uuid,
				profile_name: $scope.getAutoGeneratedName(profile.name, profile.customName),
				custom_profile_name: profile.customName,
				audio_channel_count: profile.audio.channels,
				audio_bitrate: profile.audio.bitratePerChan,
				video_channel_count: profile.video[0].input.length,
				hardware_acceleration: profile.video[0].hardwareAcceleration ? $scope.HARDWARE_ACCELERATION_ENABLED : $scope.HARDWARE_ACCELERATION_DISABLED,
				video_format_std: $scope.getVideoFormatStandard(profile.video[0].input[0].format),
				video_codec: profile.video[0].codec,
				video_bit_depth: profile.video[0].bitDepth,
				video_input_format_code: profile.video[0].input[0].format,
				video_output_format_code: profile.video[0].format,
				video_encode_bitrate: profile.video[0].bitRate / profile.video[0].input.length,
				video_channel_offset: profile.inputOffset,
				short_segments: profile.shortSegments
			};

			// initialize format dropdowns
			$scope.updateInputFormatDropdownOptions($scope.profile_selections);
			$scope.updateOutputFormatOptions($scope.profile_selections);

			$scope.is_adding_editing_encoder_profile = true;

			$scope.updateBitrateRecommendation();
			$scope.updateBandwidthRecommendation();
			$scope.updateHardwareAcceleration();
			$scope.updateCodecHardwareAccel();

			$scope.onVideoOutputFormatChanged($scope.profile_selections);

			// see app.js for where focus is defined
			focus('custom-profile-name');
		}
	};

	$scope.enterAddWebEncoderProfileMode = function () {
		$scope.web_profile_fields = {};

		$scope.web_profile_fields.uuid = null;
		$scope.web_profile_fields.name = '';
		$scope.web_profile_fields.video_channel = 1;
		$scope.web_profile_fields.left_audio_channel = 1;
		$scope.web_profile_fields.right_audio_channel = 2;
		$scope.web_profile_fields.deinterlace = $scope.DEINTERLACE_DEFAULT;

		// see app.js for where focus is defined
		focus('edit-web-encoder-input');
	};

	$scope.enterEditWebEncoderProfileMode = function (profile) {
		$scope.error_msg = null;
		$scope.web_encoder_to_view = null; // ensure this is cleared

		$scope.web_profile_fields = {};
		$scope.web_profile_fields.uuid = profile.uuid;
		$scope.web_profile_fields.name = profile.name;
		$scope.web_profile_fields.video_channel = profile.videoChannel;
		$scope.web_profile_fields.left_audio_channel = profile.audioAdaptationSets[0].left;
		$scope.web_profile_fields.right_audio_channel = profile.audioAdaptationSets[0].right;
		$scope.web_profile_fields.deinterlace = profile.deinterlace ? $scope.DEINTERLACE_YES : $scope.DEINTERLACE_NO;

		// see app.js for where focus is defined
		focus('edit-web-encoder-input');
	};

	$scope.enterAddProfileMode = function (profile_to_copy) {

		$scope.validation_errors = {
			video_channels: [{}],
		};

		if (profile_to_copy) {

			$scope.is_copying_profile = true;

			// initialize our form values based on the profile we are copying
			$scope.profile_selections = {
				audio_channel_count: profile_to_copy.audio.channels,
				audio_bitrate: profile_to_copy.audio.bitratePerChan,
				video_channel_count: profile_to_copy.video[0].input.length,
				hardware_acceleration: profile_to_copy.video[0].hardwareAcceleration ? $scope.HARDWARE_ACCELERATION_ENABLED : $scope.HARDWARE_ACCELERATION_DISABLED,
				video_format_std: $scope.getVideoFormatStandard(profile_to_copy.video[0].input[0].format),
				video_codec: profile_to_copy.video[0].codec,
				video_bit_depth: profile_to_copy.video[0].bitDepth,
				video_input_format_code: profile_to_copy.video[0].input[0].format,
				video_output_format_code: profile_to_copy.video[0].format,
				video_encode_bitrate: profile_to_copy.video[0].bitRate / profile_to_copy.video[0].input.length,
				video_channel_offset: profile_to_copy.inputOffset,
				short_segments: profile_to_copy.shortSegments
			};

			$scope.updateInputFormatDropdownOptions($scope.profile_selections);
			$scope.updateOutputFormatOptions($scope.profile_selections);
			$scope.updateBitrateRecommendation();
			$scope.updateBandwidthRecommendation();
			$scope.updateHardwareAcceleration();
			$scope.updateCodecHardwareAccel();

		} else {

			$scope.is_copying_profile = false;

			// initialize our form values
			$scope.profile_selections = {
				profile_name: '',
				custom_profile_name: '',
				audio_channel_count: null,
				audio_bitrate: null,
				video_channel_count: 1,
				video_format_std: $scope.VIDEO_FORMAT_NTSC,
				hardware_acceleration: $scope.HARDWARE_ACCELERATION_DEFAULT,
				video_codec: null,
				// if the user doesn't have the toggle then they can't change the value, so initialize to first entry
				video_bit_depth: $scope.getCurrentUser().hasToggle('v3BitDepth') ? null : $scope.video_bit_depth_options[0],
				video_input_format_code: null,
				video_output_format_code: null,
				video_encode_bitrate: null,
				video_channel_offset: $scope.VIDEO_CHANNEL_OFFSET_DEFAULT,
				short_segments: null
				
			};

			$scope.updateInputFormatDropdownOptions($scope.profile_selections);

			$scope.total_bitrate_recommendation = null;
			$scope.total_combined_bitrate = null;
			$scope.bandwidth_recommendation = null;
		}

		$scope.updateAutoGeneratedName();
		$scope.onVideoOutputFormatChanged($scope.profile_selections);

		$scope.is_adding_editing_encoder_profile = true;

		focus('custom-profile-name');
	};

	$scope.enterAddCustomProfileMode = function (profile_to_copy) {
		$scope.is_adding_custom_profile = true;
		$scope.profile_to_add_name = '';

		if (profile_to_copy) {
			$scope.profile_to_add_ffmpeg = profile_to_copy.ffmpegOptions;
			$scope.profile_to_add_audio = profile_to_copy.audioOptions;
			$scope.profile_to_add_video = profile_to_copy.videoOptions;
			$scope.profile_to_add_map = profile_to_copy.mapOptions;
			$scope.profile_to_add_input = profile_to_copy.inputOptions;
		} else {
			$scope.profile_to_add_ffmpeg = '';
			$scope.profile_to_add_audio = '';
			$scope.profile_to_add_video = '';
			$scope.profile_to_add_map = '';
			$scope.profile_to_add_input = '';
		}

		// see app.js for where focus is defined
		focus('add-custom-profile-input');
	};

	$scope.enterCopyEncoderProfileMode = function (profile_to_copy) {
		if (profile_to_copy.customProfile) {
			$scope.is_loading = true;

			// custom profiles will need to be fetched
			$http.get(`${jcs.api.url}/encoderprofiles/${profile_to_copy.uuid}`, { withCredentials: true }).then(response => {

				profile_to_copy = response.data;
				$scope.enterAddCustomProfileMode(profile_to_copy);

			}).catch(reason => {

				$scope.error_msg = 'Unable to retrieve profile information. Please try again, or report the problem if it persists.';

			}).finally(() => {

				$scope.is_loading = false;

			});

		} else {
			$scope.enterAddProfileMode(profile_to_copy);
		}
	};

	$scope.enterDeleteWebEncoderProfileMode = function (profile) {
		$scope.web_encoder_to_delete = profile;
	};

	$scope.enterDeleteEncoderProfileMode = function (profile) {
		$scope.profile_to_delete = profile;

		if (profile.customProfile) {
			$scope.is_loading = true;

			// custom profiles will need to be fetched
			$http.get(`${jcs.api.url}/encoderprofiles/${profile.uuid}`, { withCredentials: true }).then(response => {

				$scope.profile_to_delete = response.data;

			}).catch(reason => {

				$scope.error_msg = 'Unable to retrieve profile information. Please try again, or report the problem if it persists.';
				$scope.profile_to_delete = null;

			}).finally(() => {

				$scope.is_loading = false;

			});
		}
	};

	$scope.cancelView = function () {
		$scope.encoder_to_view = null;
		$scope.encoder_to_view_combined_bitrate = null;
		$scope.encoder_to_view_bandwidth_recommendation = null;
		$scope.web_encoder_to_view = null;
	};

	$scope.cancelAddEditProfile = function () {
		$scope.profile_selections = null;
		$scope.error_msg = null;
		$scope.is_adding_editing_encoder_profile = false;
	};

	$scope.cancelEdit = function () {
		$scope.profile_to_edit = null;
		$scope.web_profile_fields = null;
		$scope.error_msg = null;
	};

	$scope.cancelAddCustomProfile = function () {
		$scope.is_adding_custom_profile = false;
		$scope.error_msg = null;
	};

	$scope.cancelDelete = function () {
		$scope.profile_to_delete = null;
		$scope.web_encoder_to_delete = null;
		$scope.error_msg = null;
	};

	$scope.isEmpty = function (value) {
		return value == null || value == '';
	};

	// returns TRUE if we have a form validation issue that the user needs to address
	$scope.doesSaveProfileFormFailValidation = function (selections, errors) {
		// check required fields to ensure they aren't empty
		errors.profile_name = $scope.isEmpty(selections.profile_name);
		errors.audio_channel_count = $scope.isEmpty(selections.audio_channel_count);
		errors.audio_bitrate = $scope.isEmpty(selections.audio_bitrate);
		errors.video_channel_count = $scope.isEmpty(selections.video_channel_count);
		errors.video_format_std = $scope.isEmpty(selections.video_format_std);
		errors.video_codec = $scope.isEmpty(selections.video_codec);
		errors.video_bit_depth = $scope.isEmpty(selections.video_bit_depth);
		errors.hardware_acceleration = $scope.isEmpty(selections.hardware_acceleration);
		errors.video_input_format_code = $scope.isEmpty(selections.video_input_format_code);
		errors.video_output_format_code = $scope.isEmpty(selections.video_output_format_code);
		errors.video_encode_bitrate = $scope.isEmpty(selections.video_encode_bitrate);

		let has_error =
			errors.profile_name ||
			errors.audio_channel_count ||
			errors.audio_bitrate ||
			errors.video_channel_count ||
			errors.video_format_std ||
			errors.video_codec ||
			errors.video_bit_depth ||
			errors.hardware_acceleration ||
			errors.video_input_format_code ||
			errors.video_output_format_code ||
			errors.video_encode_bitrate;

		$scope.error_msg = has_error ? 'Please specify a value for the highlighted fields below.' : null;

		// if no "required field" errors, then ...
		if (!has_error) {
			// ensure video encode bitrate is valid
			if (isNaN(parseFloat(selections.video_encode_bitrate))){
				has_error = true;
				errors.video_encode_bitrate = true;
				$scope.error_msg = 'Please enter a valid video encode bitrate.';
			}
		}

		if (!has_error) {
			// ensure the video encode bitrate does not exceed the allowed max
			if ($scope.max_video_bitrate != null) {
				const total_video_bitrate = parseFloat(selections.video_encode_bitrate) * selections.video_channel_count;
				if (total_video_bitrate > $scope.max_video_bitrate){
					has_error = true;
					errors.video_encode_bitrate = true;
					const max_bitrate_per_channel = $scope.max_video_bitrate / selections.video_channel_count;
					$scope.error_msg = `The video Bitrate Per Channel exceeds the maximum allowed bitrate of ${max_bitrate_per_channel} Mbps. Please adjust the Bitrate Per Channel so as not to exceed the maximum limit.`;
				}

				if ($scope.profile_selections.video_input_format_code != null) {
					if ($scope.profile_selections.video_input_format_code.startsWith(CODE_4K) && $scope.profile_selections.video_channel_count === 2) {
						has_error = true;
						errors.video_channel_count = true;
					}
				}
			}
		}

		return has_error;
	};

	$scope.getAutoGeneratedName = function (name, custom_name) {
		let piece_to_remove = `${$scope.ENCODER_PROFILE_NAME_DELIMITER}${custom_name}`;
		let index = name.indexOf(piece_to_remove);
		if (index != -1){
			return name.substring(0, index);
		}
		return name;
	};

	$scope.buildProfileName = function (name, custom_name) {
		if (custom_name != null && custom_name.length > 0){
			return `${name}${$scope.ENCODER_PROFILE_NAME_DELIMITER}${custom_name}`;
		}
		return name;
	};

	$scope.getMixpanelDataForEncoderProfile = function (profile_data, uuid) {
		const data = {
			[MPEventProperty.ENCODER_PROFILE_NAME]: profile_data.name,
			[MPEventProperty.ENCODER_PROFILE_UUID]: $scope.profile_selections.uuid,
			[MPEventProperty.VIDEO_CHANNELS]: $scope.profile_selections.video_channel_count,
			[MPEventProperty.INPUT_FORMAT]: $scope.getFormatName($scope.profile_selections.video_input_format_code),
			[MPEventProperty.OUTPUT_FORMAT]: $scope.getFormatName($scope.profile_selections.video_output_format_code),
			[MPEventProperty.CODEC]: $scope.profile_selections.video_codec,
			[MPEventProperty.VIDEO_BITRATE_MBPS]: $scope.profile_selections.video_encode_bitrate,
			[MPEventProperty.HARDWARE_ACCELERATION]: $scope.profile_selections.hardware_acceleration === $scope.HARDWARE_ACCELERATION_ENABLED,
			[MPEventProperty.AUDIO_CHANNELS]: $scope.profile_selections.audio_channel_count,
			[MPEventProperty.AUDIO_BITRATE_KBPS]: $scope.profile_selections.audio_bitrate,
			[MPEventProperty.SHORT_SEGMENTS]: $scope.profile_selections.short_segments,
		};
		if (uuid){
			data[MPEventProperty.ENCODER_PROFILE_UUID] = uuid;
		}
		return data;
	};

	$scope.saveAddEditProfile = function () {

		// if we have form validation errors, then don't go any further
		if ($scope.doesSaveProfileFormFailValidation($scope.profile_selections, $scope.validation_errors)){
			return;
		}

		// prepare data to sent to API
		const profile_data = {
			name: $scope.buildProfileName($scope.profile_selections.profile_name, $scope.profile_selections.custom_profile_name),
			customName: $scope.profile_selections.custom_profile_name,
			type: $scope.STREAMING_TECHNIQUE_DEFAULT,
			audio: {
				channels: $scope.profile_selections.audio_channel_count,
				bitratePerChan: $scope.profile_selections.audio_bitrate,
			},
			video: [{
				input: [],
				format: $scope.profile_selections.video_output_format_code,
				bitRate: parseFloat($scope.profile_selections.video_encode_bitrate) * $scope.profile_selections.video_channel_count,
				codec: $scope.profile_selections.video_codec,
				bitDepth: $scope.profile_selections.video_bit_depth,
				hardwareAcceleration: $scope.profile_selections.hardware_acceleration === $scope.HARDWARE_ACCELERATION_ENABLED,
			}],
			shortSegments: $scope.profile_selections.short_segments
		};
		if ($scope.profile_selections.video_channel_count > 1){
			profile_data.inputOffset = $scope.profile_selections.video_channel_offset;
		}
		for (let i = 0; i < $scope.profile_selections.video_channel_count; i++) {
			profile_data.video[0].input.push({
				format: $scope.profile_selections.video_input_format_code
			});
		}

		const mixpanel_data = $scope.getMixpanelDataForEncoderProfile(profile_data, $scope.profile_selections.uuid);

		if ($scope.profile_selections.uuid != null){ // saving existing profile

			$scope.is_working = true;

			httpService.patch(`${jcs.api.url_v3}/customers/${Authentication.getCurrentUser().customerID}/encoderprofiles/${$scope.profile_selections.uuid}`, profile_data, { withCredentials: true }).then(response => {

				$scope.profile_selections = null;
				$scope.validation_errors = null;
				$scope.error_msg = null;
				$scope.is_adding_editing_encoder_profile = false;

				$scope.loadProfiles();
				trackMixpanelEvent(MPEventName.ENCODER_PROFILE_EDIT, mixpanel_data);

			}).catch(reason => {

				$scope.error_msg = 'An error occurred while attempting to update the profile. Please try again, or report the problem if it persists.';

			}).finally(() => {

				$scope.is_working = false;

			});

		} else { // saving new profile

			$scope.is_working = true;

			httpService.post(`${jcs.api.url_v3}/customers/${Authentication.getCurrentUser().customerID}/encoderprofiles`, profile_data, { withCredentials: true }).then(response => {

				$scope.profile_selections = null;
				$scope.validation_errors = null;
				$scope.error_msg = null;
				$scope.is_adding_editing_encoder_profile = false;

				$scope.loadProfiles();

				if ($scope.is_copying_profile){
					trackMixpanelEvent(MPEventName.ENCODER_PROFILE_COPY, mixpanel_data);
				} else {
					trackMixpanelEvent(MPEventName.ENCODER_PROFILE_ADD, mixpanel_data);
				}

			}).catch(reason => {

				$scope.error_msg = 'An error occurred while attempting to create the profile. Please try again, or report the problem if it persists.';

			}).finally(() => {

				$scope.is_working = false;

			});
		}
	};

	$scope.saveNewCustomProfile = function () {
		var new_profile_data = {
			name: $scope.profile_to_add_name,
			ffmpegOptions: $scope.profile_to_add_ffmpeg,
			audioOptions: $scope.profile_to_add_audio,
			videoOptions: $scope.profile_to_add_video,
			mapOptions: $scope.profile_to_add_map,
			inputOptions: $scope.profile_to_add_input,
		};

		$scope.is_working = true;

		// send ajax request to create new profile
		$http
			.post(jcs.api.url + '/encoderprofiles', new_profile_data, { withCredentials: true })
			.then(
				function () {
					// success

					$scope.is_adding_custom_profile = false;
					$scope.profile_to_add_name = '';
					$scope.profile_to_add_ffmpeg = '';
					$scope.profile_to_add_audio = '';
					$scope.profile_to_add_video = '';
					$scope.profile_to_add_map = '';
					$scope.profile_to_add_input = '';
					$scope.error_msg = null;

					$scope.loadProfiles();
				},
				function () {
					// error

					$scope.error_msg =
						'An error occurred while attempting to create the profile. Please try again, or report the problem if it persists.';
				}
			)
		['finally'](function () {
			// always called

			$scope.is_working = false;
		});
	};

	$scope.saveEditCustom = function () {
		var updated_profile_data = {
			name: $scope.profile_to_edit_name,
		};
		// only save 'options' if the user has special perm (otherwise they can only change the name)
		if (Authentication.getCurrentUser().hasPerm('encoder_profiles.options.update')) {
			updated_profile_data.ffmpegOptions = $scope.profile_to_edit_ffmpeg;
			updated_profile_data.audioOptions = $scope.profile_to_edit_audio;
			updated_profile_data.videoOptions = $scope.profile_to_edit_video;
			updated_profile_data.mapOptions = $scope.profile_to_edit_map;
			updated_profile_data.inputOptions = $scope.profile_to_edit_input;
		}

		$scope.is_working = true;

		// update profile
		$http
			.patch(jcs.api.url + '/encoderprofiles/' + $scope.profile_to_edit.uuid, updated_profile_data, {
				withCredentials: true,
			})
			.then(
				function () {
					// success

					$scope.error_msg = null;
					$scope.profile_to_edit = null;

					$scope.loadProfiles();
				},
				function () {
					// error

					$scope.error_msg =
						'An error occurred while attempting to update the profile. Please try again, or report the problem if it persists.';
				}
			)
		['finally'](function () {
			// always called

			$scope.is_working = false;
		});
	};

	$scope.getMixpanelDataForWebEncoderProfile = function (profile_data, uuid) {
		const data = {
			[MPEventProperty.WEB_ENCODER_PROFILE_NAME]: profile_data.name,
			[MPEventProperty.ENCODER_VIDEO_CHANNEL]: profile_data.videoChannel,
			[MPEventProperty.DEINTERLACE]: profile_data.deinterlace,
			[MPEventProperty.AUDIO_CHANNEL_LEFT]: profile_data.audioAdaptationSets[0].left,
			[MPEventProperty.AUDIO_CHANNEL_RIGHT]: profile_data.audioAdaptationSets[0].right ? profile_data.audioAdaptationSets[0].right : $scope.NONE_MONO_LABEL,
		};
		if (uuid){
			data[MPEventProperty.WEB_ENCODER_PROFILE_UUID] = uuid;
		}
		return data;
	};

	// returns TRUE if we have a form validation issue that the user needs to address
	$scope.doesSaveWebEncoderProfileFailValidation = function () {
		$scope.has_error = {};

		// check required fields to ensure they aren't empty
		$scope.has_error.web_enc_prof_name = $scope.isEmpty($scope.web_profile_fields.name);
		$scope.has_error.web_enc_prof_video_channel = $scope.isEmpty($scope.web_profile_fields.video_channel);

		var error_count = 0;
		for (var property in $scope.has_error) {
			if ($scope.has_error[property] === true) {
				error_count++;
			}
		}
		var has_validation_error = error_count > 0;

		$scope.error_msg = has_validation_error ? 'Please specify a value for the highlighted fields below.' : null;

		// if no "required field" errors, then ...
		if (!has_validation_error) {
			// ... check to ensure audio channels are different
			if ($scope.web_profile_fields.left_audio_channel === $scope.web_profile_fields.right_audio_channel) {
				has_validation_error = true;
				$scope.error_msg =
					'The left and right audio channels are the same. Please choose a different channel for either the left or right audio channel.';
				$scope.has_error.web_enc_prof_audio_channels = true;
			}
		}

		return has_validation_error;
	};

	$scope.saveWebEncoderProfile = function () {
		// if we have form validation errors, then don't go any further
		if ($scope.doesSaveWebEncoderProfileFailValidation()) return false;

		if ($scope.web_profile_fields.uuid !== null) {
			// updating existing profile

			$scope.is_working = true;

			var profile_data = {
				name: $scope.web_profile_fields.name,
				videoChannel: $scope.web_profile_fields.video_channel,
				deinterlace: $scope.web_profile_fields.deinterlace == $scope.DEINTERLACE_YES ? true : false,
				audioAdaptationSets: [
					{
						left: $scope.web_profile_fields.left_audio_channel,
						right: $scope.web_profile_fields.right_audio_channel,
					},
				],
			};

			const mixpanel_data = $scope.getMixpanelDataForWebEncoderProfile(profile_data, $scope.web_profile_fields.uuid);

			httpService.patch(`${jcs.api.url_v3}/customers/${Authentication.getCurrentUser().customerID}/webencoderprofiles/${$scope.web_profile_fields.uuid}`, profile_data, { withCredentials: true },
				function () { // success

					$scope.error_msg = null;
					$scope.web_profile_fields = null;

					$scope.loadProfiles();

					trackMixpanelEvent(MPEventName.WEB_ENCODER_PROFILE_EDIT, mixpanel_data);
				},
				function () { // error

					$scope.error_msg = 'An error occurred while attempting to update the web encoder profile. Please try again, or report the problem if it persists.';
				},
				function () { // always called

					$scope.is_working = false;
				}
			);
		} else {
			// saving brand new profile

			$scope.is_working = true;

			var profile_data = {
				name: $scope.web_profile_fields.name,
				videoChannel: $scope.web_profile_fields.video_channel,
				deinterlace: $scope.web_profile_fields.deinterlace == $scope.DEINTERLACE_YES ? true : false,
				audioAdaptationSets: [
					{
						left: $scope.web_profile_fields.left_audio_channel,
						right: $scope.web_profile_fields.right_audio_channel,
					},
				],
			};

			const mixpanel_data = $scope.getMixpanelDataForWebEncoderProfile(profile_data);

			// send ajax request to create new profile
			httpService.post(`${jcs.api.url_v3}/customers/${Authentication.getCurrentUser().customerID}/webencoderprofiles`, profile_data, { withCredentials: true },
				function () { // success

					$scope.error_msg = null;
					$scope.web_profile_fields = null;

					$scope.loadProfiles();

					trackMixpanelEvent(MPEventName.WEB_ENCODER_PROFILE_ADD, mixpanel_data);
				},
				function () { // error

					$scope.error_msg = 'An error occurred while attempting to create the web encoder profile. Please try again, or report the problem if it persists.';
				},
				function () { // always called

					$scope.is_working = false;
				}
			);
		}
	};

	// when deleting, we have to hit the API to pull custom profiles, so the object we use to display
	// the "Delete" page won't be the object from our list. Because of that we need to use this custom
	// method for finding our profiles in our profile list.
	$scope.getProfileIndex = function (profile_to_find) {
		if ($scope.encoder_profiles.length > 0) {
			for (var i = 0; i < $scope.encoder_profiles.length; i++) {
				if ($scope.encoder_profiles[i].uuid == profile_to_find.uuid) return i;
			}
		}
		return -1;
	};

	$scope.deleteWebEncoderProfile = function () {
		$scope.is_working = true;

		const mixpanel_data = {
			[MPEventProperty.WEB_ENCODER_PROFILE_NAME]: $scope.web_encoder_to_delete.name,
			[MPEventProperty.WEB_ENCODER_PROFILE_UUID]: $scope.web_encoder_to_delete.uuid,
		};

		// make http request to delete profile
		// NOTE: I normally would not include the "data: null", but IE11 and below seem to require it in order to work
		// see: https://github.com/angular/angular.js/issues/12141
		httpService.delete(`${jcs.api.url_v3}/customers/${Authentication.getCurrentUser().customerID}/webencoderprofiles/${$scope.web_encoder_to_delete.uuid}`, { withCredentials: true, data: null },
			function () { // success

				// remove web encoder profile from our list
				var index = $scope.web_profiles.indexOf($scope.web_encoder_to_delete);
				if (index > -1) {
					$scope.web_profiles.splice(index, 1);
				}

				$scope.web_encoder_to_delete = null;
				$scope.error_msg = null;

				trackMixpanelEvent(MPEventName.WEB_ENCODER_PROFILE_DELETE, mixpanel_data);
			},
			function () { // error

				$scope.error_msg = 'An error occurred while attempting to delete the web encoder profile. Please try again, or report the problem if it persists.';
			},
			function () { // always called

				$scope.is_working = false;
			}
		);
	};

	$scope.deleteProfile = function () {
		$scope.is_working = true;

		const mixpanel_data = {
			[MPEventProperty.ENCODER_PROFILE_NAME]: $scope.profile_to_delete.name,
			[MPEventProperty.ENCODER_PROFILE_UUID]: $scope.profile_to_delete.uuid,
		};

		// make http request to delete profile
		// NOTE: I normally would not include the "data: null", but IE11 and below seem to require it in order to work
		// see: https://github.com/angular/angular.js/issues/12141
		$http
			.delete(jcs.api.url + '/encoderprofiles/' + $scope.profile_to_delete.uuid, {
				withCredentials: true,
				data: null,
			})
			.then(
				function () {
					// success

					// remove profile from our profiles list
					var index = $scope.getProfileIndex($scope.profile_to_delete);
					if (index > -1) {
						$scope.encoder_profiles.splice(index, 1);
					}

					$scope.profile_to_delete = null;
					$scope.error_msg = null;

					trackMixpanelEvent(MPEventName.ENCODER_PROFILE_DELETE, mixpanel_data);
				},
				function () {
					// error

					$scope.error_msg =
						'An error occurred while attempting to delete the profile. Please try again, or report the problem if it persists.';
				}
			)
		['finally'](function () {
			// always called

			$scope.is_working = false;
		});
	};

	// returns the profile object with a matching UUID. Otherwise null is returned;
	$scope.findProfile = function (profile_uuid) {
		for (var i = 0; i < $scope.encoder_profiles.length; i++) {
			var profile = $scope.encoder_profiles[i];
			if (profile.uuid == profile_uuid) return profile;
		}
		return null;
	};

	$scope.loadProfiles = function (callback) {
		$scope.encoder_profiles_error_msg = null;
		$scope.web_profiles_error_msg = null;

		$scope.is_loading_encoder_profiles = true;

		httpService.get(`${jcs.api.url_v3}/customers/${Authentication.getCurrentUser().customerID}/encoderprofiles`, { withCredentials: true }).then(response => {

			$scope.encoder_profiles = response.data;
			// execute our callback method if provided
			if (typeof callback == 'function'){
				callback();
			}

		}).catch(reason => {

			$scope.encoder_profiles_error_msg = 'Unable to retrieve encoder profile information. Please try again, or report the problem if it persists.';
			$scope.encoder_profiles = null;

		}).finally(() => {

			$scope.is_loading_encoder_profiles = false;

		});

		if (Authentication.getCurrentUser().hasPerm('web_encoder_profiles.get')) {
			$scope.is_loading_web_profiles = true;

			httpService.get(`${jcs.api.url_v3}/customers/${Authentication.getCurrentUser().customerID}/webencoderprofiles`, { withCredentials: true }).then(response => {

				$scope.web_profiles = response.data;

			}).catch(reason => {

				$scope.web_profiles_error_msg = 'Unable to retrieve web encoder profile information. Please try again, or report the problem if it persists.';
				$scope.web_profiles = null;

			}).finally(() => {

				$scope.is_loading_web_profiles = false;

			});
		}
	};


	$scope.is_loading = true;
	//
	// We don't normally cache returned API values, but caching the encoder profile options (the info we
	// show in the dropdowns) will save alot of unnecessary API hits (see note). Since the options are
	// unlikely to change they should be safe to cache.
	// note: options are necessary even for screens like delete & view because we need to convert the video
	// format to a friendlier string (ex: hp25 into 720p25).
	//
	httpService.get(`${jcs.api.url_v3}/customers/${Authentication.getCurrentUser().customerID}/encoderprofiles/options`, { withCredentials: true }).then(response => {

		$scope.setProfileOptions(response.data);

		if (typeof $routeParams.id !== 'undefined') {
			// if a specific profile has been provided, then we'll want to go directly to the profile edit
			// page. Do this immediately after loading our profile list.
			// example URL: http://localhost/livingasone/control/encoderprofiles/5add0669-ad1f-4ee7-96ec-b9b6c6337e66
			$scope.loadProfiles(function () {
				var profile = $scope.findProfile($routeParams.id);
				if (profile != null) {
					$scope.enterEditEncoderProfileMode(profile);
				}
			});

		} else {
			$scope.loadProfiles();
		}

	}).catch(reason => {

		$scope.encoder_profiles_error_msg = 'An error occurred while attempting to retrieve profile options. Please try again, or report the problem if it persists.';

	}).finally(() => {

		$scope.is_loading = false;

	});

	// build our tooltips
	$timeout(function () {
		$('[data-toggle="tooltip"]').tooltip(); // needs to be done in timeout, otherwise for some reason the tooltip gets built before angular does it's magic

		// these tooltips uses a html "title/body" with special formatting
		$('.format-standard-tooltip').tooltip({
			template: '<div class="tooltip" role="tooltip"><div class="tooltip-arrow"></div><div class="tooltip-inner tooltip-inner-wide tooltip-inner-left"></div></div>',
			placement: 'top',
			title: '<div class="tooltip-section">NTSC is the standard broadcast format in the United States and should be used for 60/30 Hz compatible equipment.</div>PAL is the standard broadcast format in Europe, Australia and parts of Asia, and should be used for 50/25 Hz compatible equipment.',
			html: true,
		});
		$('.hardware-acceleration-tooltip').tooltip({
			template: '<div class="tooltip" role="tooltip"><div class="tooltip-arrow"></div><div class="tooltip-inner tooltip-inner-wide tooltip-inner-left"></div></div>',
			placement: 'top',
			title: '<div class="tooltip-section">Hardware encoding is faster and can allow for higher resolutions and framerates to be encoded in realtime, but video quality is lower compared to software encoding.</div><div class="tooltip-section">When Hardware Acceleration is On, the encoder will use hardware accelerated video encoding if possible.</div>When Hardware Acceleration is Off, the encoder will use software encoding if possible, which will result in higher video quality.',
			html: true,
		});
		$('.codec-tooltip').tooltip({
			placement: 'top',
			title: 'HEVC is recommended unless you are only streaming to the web and you have plenty of bandwidth.',
		});
		$('.channel-2-offset-tooltip').tooltip({
			placement: 'top',
			title: 'Offset of the second input device relative to the first input device. A negative offset indicates the second input device will encode content ahead of the first input device.',
		});
		$('.bit-depth-tooltip').tooltip({
			placement: 'top',
			title: 'Increases color space for encoded media. Equipment must be compatible to support higher bit depths (HDR).',
		});
		$('.total-combined-bitrate-tooltip').tooltip({
			template: '<div class="tooltip" role="tooltip"><div class="tooltip-arrow"></div><div class="tooltip-inner tooltip-inner-wide tooltip-inner-left"></div></div>',
			placement: 'top',
			title: 'Includes both video and audio streams, times each of their respective channels. Is used to calculate the recommended Internet Upload Bandwidth.',
			html: true,
		});
		$('.upload-bandwidth-tooltip').tooltip({
			template: '<div class="tooltip" role="tooltip"><div class="tooltip-arrow"></div><div class="tooltip-inner tooltip-inner-wide tooltip-inner-left"></div></div>',
			placement: 'top',
			title: '<div class="tooltip-section">Is 2.5 times the Total Combined Bitrate. This covers transmission overhead and will allow the stream to handle temporary internet outages so that it can be viewed without buffering.</div>If you do not have this amount of upload bandwidth, please lower your bandwidth settings.',
			html: true,
		});
		$('.output-format-warning-tooltip').tooltip({
			template: '<div class="tooltip" role="tooltip"><div class="tooltip-arrow"></div><div class="tooltip-inner tooltip-inner-wide tooltip-inner-left"></div></div>',
			placement: 'top',
			title: '<div class="tooltip-section">Encoding video as interlaced may affect quality when streaming to the web.</div>For multisite, only choose this option if all playback equipment at remote sites support interlaced video.',
			html: true,
		});
		$('.auto-format-tooltip').tooltip({
			template: '<div class="tooltip" role="tooltip"><div class="tooltip-arrow"></div><div class="tooltip-inner tooltip-inner-wide tooltip-inner-left"></div></div>',
			placement: 'top',
			title: '<div class="tooltip-section">Input format must match the detected incoming video signal. A mismatched configuration will result in color bars or dropped video signal.</div>Select “auto” input for 4K signals.',
			html: true,
		});
	});
}

module.exports = EncoderProfilesController;
