const moment = require('moment');

class TimeCtrl {

	constructor() {
		'ngInject';

		this.DEFAULT_FORMAT_IN = 'HH:mm'; // 24 hour
		this.DEFAULT_FORMAT_OUT = 'h:mm A'; // 12 hour

		this.time_formatted = '';
	}

	getFormattedTime (value, format_in, format_out) {

		if (value){
			format_in = format_in || this.DEFAULT_FORMAT_IN;
			format_out = format_out || this.DEFAULT_FORMAT_OUT;
			return moment(value, format_in).format(format_out);
		}
		return '';
	}

	$onInit () {
		this.time_formatted = this.getFormattedTime(this.value, this.formatIn, this.formatOut);
	}

	$onChanges(changes) {
		this.time_formatted = this.getFormattedTime(this.value, this.formatIn, this.formatOut);
	}
}

// example usage:
// <time value="startTime" format-in="HH:mm" format-out="h:mm a"></time>
//
// format-in: is the format of the time you are providing
// format-out: the format in which the provided time should be displayed
//
// for formatting options see: http://momentjs.com/
//
export default class TimeComponent {
	constructor() {
		this.template = require('./time.html').default;
		this.bindings = {
			value: '<',
			formatIn: '@',
			formatOut: '@',
		};
		this.controller = TimeCtrl;
	}
}
