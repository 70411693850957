import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { Dispatch } from 'react';
import { api } from '../../../v2.snapshot/js/jcs';
import { UploadPOSTUrls, ACTION_TYPE, UPLOAD_DESTINATION, UploadRequestParameters, ImageMetadata } from './imageUploadTypes';

const MAX_FILE_SIZE_BYTES = 4194304; // 4MB

export const fetchUploadUrls = async (destination: string, customerId: string, requestParams: UploadRequestParameters): Promise<UploadPOSTUrls> => {
  const url = `${api.url_v3}/customers/${customerId}/${destination.toString()}/upload`;
  const response = await axios.post(url, requestParams, { withCredentials: true });
  return {
    uploadUrl: response.data.uploadUrl,
    downloadUrl: response.data.downloadUrl,
    filePath: response.data.filePath,
  } as UploadPOSTUrls;
};

export const uploadFilePost = ({
  file,
  uploadUrl,
  dispatch,
  metadata,
}: {
  file: Blob;
  uploadUrl: string;
  dispatch: Dispatch<any>;
  metadata?: ImageMetadata;
}): Promise<AxiosResponse> => {
  const formData = new FormData();
  formData.append('file', file);
  const config: AxiosRequestConfig = {
    onUploadProgress: (progressEvent: ProgressEvent) => {
      const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      dispatch({ type: ACTION_TYPE.UPLOAD_PROGRESS, payload: percentCompleted });
    },
  };
  return axios.post(uploadUrl, formData, config);
};

export const uploadFilePut = ({
  file,
  uploadUrl,
  dispatch,
  metadata,
}: {
  file: Blob;
  uploadUrl: string;
  dispatch: Dispatch<any>;
  metadata?: ImageMetadata;
}): Promise<AxiosResponse> => {
  const headers = {
    "X-Metadata": JSON.stringify(metadata),
  };
  const config: AxiosRequestConfig = {
    headers,
    onUploadProgress: (progressEvent: ProgressEvent) => {
      const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      dispatch({ type: ACTION_TYPE.UPLOAD_PROGRESS, payload: percentCompleted });
    },
  };

  return axios.put(uploadUrl, file, config);
};

export const isFileValid = (file: File, maxFileSizeBytes = MAX_FILE_SIZE_BYTES): boolean => {
  const validFileTypes = ['image/png', 'image/jpeg'];
  if (!file) {
    throw new Error('A file is required for uploading.');
  }

  if (validFileTypes.indexOf(file.type) === -1) {
    throw new Error('File must be a JPG or PNG');
  }

  if (file.size > maxFileSizeBytes) {
    const maxFileSizeMb = maxFileSizeBytes / 1024 / 1024;
    throw new Error(`File size must not exceed ${maxFileSizeMb} MB.`);
  }

  return true;
};

export const getFileExtension = (file: File): string => {
  let fileExtension = '';
  if (file) {
    const parts: string[] = file.name.split('.');
    fileExtension = parts[parts.length - 1];
  }

  return fileExtension;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const readFile = (file: File): Promise<any> => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => resolve(reader.result), false);
    reader.readAsDataURL(file);
  });
};
