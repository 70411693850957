import React, { FC, useContext, useEffect, useState, ChangeEvent } from 'react';
import {
  TIME_WINDOW_VALUES,
  ALL_DESTINATIONS_STATE_CONTEXT_KEYS,
  TIME_WINDOW_SET,
  ALL_DESTINATIONS_GROUP_SET,
  EVENT_ANALYTICS_CONTEXT_KEYS,
} from '../../event-analytics-constants';
import { dateRangeValues, customDateRangeOption, lifeTimeValues } from '../../date-range-options';
import { DateType, lineChartDataType, TIME_WINDOWS } from '../../types';
import { AllDestinationsContext } from '../../../../contexts/eventanalytics';
import AllDestinationsStatsGraphView from './alldestinations-stats-graph-view';
import { Option } from '@resi-media/resi-ui';
import { fetchData } from '../api';
import { parseLineChartData, isMonthTabDisabled, getDateRangeOptions } from '../../utils';
import { EventAnalyticsContext } from '../../../../contexts/eventanalytics/event-analytics';
import { MPEventName, MPEventProperty, trackMixpanelEvent } from '../../../../mixpanel';

const AllDestinationsStatsGraph: FC = (): JSX.Element => {
  const { allDestinationsAnalyticsState, dispatch } = useContext(AllDestinationsContext);
  const { EventAnalyticsState, dispatch: dispatchEventAnalyticsState } = useContext(EventAnalyticsContext);
  const {
    viewAllData,
    dateRangeOption,
    eventId,
  } = EventAnalyticsState;
  const isMonthTimeWindowDisabled = isMonthTabDisabled(dateRangeOption?.value);
  const [timeWindow, setTimeWindow] = useState<number>(0);
  const [dataType, setDataType] = useState<0 | 1>(0);
  const [isDatePickerOpen, setDatePickerOpen] = useState<boolean>(false);
  const [timeWindowValue, setTimeWindowValue] = useState<string>(TIME_WINDOW_VALUES.DAY);
  const [chartData, setChartData] = useState<lineChartDataType>({ labels: [], datasets: {} });
  const [isChartLoading, setChartLoading] = useState<boolean>(false);

  const handleGroupByChange = (event: ChangeEvent<unknown>, newValue: 0 | 1): void => {
    setDataType(newValue);
    dispatch({ type: ALL_DESTINATIONS_STATE_CONTEXT_KEYS.GROUP_BY, payload: ALL_DESTINATIONS_GROUP_SET[newValue] });

    const mixpanelProps = {
      [MPEventProperty.TRANSCODED_EVENT_UUID]: eventId,
      [MPEventProperty.ANALYTICS_TYPE]: 'All Destinations',
      [MPEventProperty.ANALYTICS_SEGMENT]: newValue === 0 ? 'None' : 'Destination Type',
    };

    if (!eventId) {
      delete mixpanelProps[MPEventProperty.TRANSCODED_EVENT_UUID];
    }

    trackMixpanelEvent(MPEventName.ANALYTICS_SEGMENT, mixpanelProps);
  };

  const handleTimeWindowChange = (event: ChangeEvent<unknown> | null, newValue: TIME_WINDOWS): void => {
    setTimeWindowValue(TIME_WINDOW_SET[newValue]);
    setTimeWindow(newValue);
  };

  const handleDateRangeChange = (item: Option[]): void => {
    const selection = item[0]?.value;
    let dateRange: DateType = { startDate: '', endDate: '' };

    // Since we have only one custom handling case "c0".
    if (selection === customDateRangeOption.value) {
      setDatePickerOpen(true);
    } else if (selection === '0' && viewAllData) {
      dateRange = lifeTimeValues[selection];
      dispatchEventAnalyticsState({ type: EVENT_ANALYTICS_CONTEXT_KEYS.DATE_RANGE, payload: dateRange });
      dispatchEventAnalyticsState({ type: EVENT_ANALYTICS_CONTEXT_KEYS.DATE_RANGE_VALUE, payload: item[0] });
    } else if (selection) {
      dateRange = dateRangeValues[selection];
      dispatchEventAnalyticsState({ type: EVENT_ANALYTICS_CONTEXT_KEYS.DATE_RANGE, payload: dateRange });
      dispatchEventAnalyticsState({ type: EVENT_ANALYTICS_CONTEXT_KEYS.DATE_RANGE_VALUE, payload: item[0] });
    }
  };

  const handleCustomDateRangeSelection = (startDate: string, endDate: string): void => {
    if (startDate && endDate) {
      const dateRange = {
        startDate,
        endDate,
      };
      dispatchEventAnalyticsState({ type: EVENT_ANALYTICS_CONTEXT_KEYS.DATE_RANGE, payload: dateRange });
      dispatchEventAnalyticsState({
        type: EVENT_ANALYTICS_CONTEXT_KEYS.DATE_RANGE_VALUE,
        payload: customDateRangeOption,
      });
    }
  };

  const getChartData = async () => {
    setChartLoading(true);
    const response = await fetchData(EventAnalyticsState, allDestinationsAnalyticsState, timeWindowValue);
    setChartLoading(false);
    const lineChartData = parseLineChartData(response, timeWindowValue);
    setChartData(lineChartData);
  };

  useEffect(() => {
    getChartData();
  }, [allDestinationsAnalyticsState, timeWindowValue, EventAnalyticsState]);

  return (
    <AllDestinationsStatsGraphView
      data={chartData}
      isChartLoading={isChartLoading}
      dateRangeOptions={getDateRangeOptions(viewAllData)}
      dateRangeDropdownValue={EventAnalyticsState.dateRangeOption}
      dataType={dataType}
      isDatePickerOpen={isDatePickerOpen}
      timeWindow={timeWindow}
      disableMonthTab={isMonthTimeWindowDisabled}
      handleGroupByChange={handleGroupByChange}
      handleDateRangeChange={handleDateRangeChange}
      handleTimeWindowChange={handleTimeWindowChange}
      handleCustomDateRangeSelection={handleCustomDateRangeSelection}
      closeDatePicker={(): void => setDatePickerOpen(false)}
    />
  );
};

AllDestinationsStatsGraph.displayName = 'AllDestinationsStatsGraph';

export default AllDestinationsStatsGraph;
