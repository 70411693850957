class RegionCtrl {

	constructor() {
		'ngInject';

		this.region_name = '';
		this.region_code = '';
		this.country_code = '';

		this.region_map = {
			// United States https://en.wikipedia.org/wiki/ISO_3166-2:US
			'US-AL': 'Alabama',
			'US-AK': 'Alaska',
			'US-AZ': 'Arizona',
			'US-AR': 'Arkansas',
			'US-CA': 'California',
			'US-CO': 'Colorado',
			'US-CT': 'Connecticut',
			'US-DE': 'Delaware',
			'US-FL': 'Florida',
			'US-GA': 'Georgia',
			'US-HI': 'Hawaii',
			'US-ID': 'Idaho',
			'US-IL': 'Illinois',
			'US-IN': 'Indiana',
			'US-IA': 'Iowa',
			'US-KS': 'Kansas',
			'US-KY': 'Kentucky',
			'US-LA': 'Louisiana',
			'US-ME': 'Maine',
			'US-MD': 'Maryland',
			'US-MA': 'Massachusetts',
			'US-MI': 'Michigan',
			'US-MN': 'Minnesota',
			'US-MS': 'Mississippi',
			'US-MO': 'Missouri',
			'US-MT': 'Montana',
			'US-NE': 'Nebraska',
			'US-NV': 'Nevada',
			'US-NH': 'New Hampshire',
			'US-NJ': 'New Jersey',
			'US-NM': 'New Mexico',
			'US-NY': 'New York',
			'US-NC': 'North Carolina',
			'US-ND': 'North Dakota',
			'US-OH': 'Ohio',
			'US-OK': 'Oklahoma',
			'US-OR': 'Oregon',
			'US-PA': 'Pennsylvania',
			'US-RI': 'Rhode Island',
			'US-SC': 'South Carolina',
			'US-SD': 'South Dakota',
			'US-TN': 'Tennessee',
			'US-TX': 'Texas',
			'US-UT': 'Utah',
			'US-VT': 'Vermont',
			'US-VA': 'Virginia',
			'US-WA': 'Washington',
			'US-WV': 'West Virginia',
			'US-WI': 'Wisconsin',
			'US-WY': 'Wyoming',
			'US-DC': 'District of Columbia',
			'US-AS': 'American Samoa',
			'US-GU': 'Guam',
			'US-MP': 'Northern Mariana Islands',
			'US-PR': 'Puerto Rico',
			'US-UM': 'United States Minor Outlying Islands',
			'US-VI': 'Virgin Islands, U.S.',
			// Canada https://en.wikipedia.org/wiki/ISO_3166-2:CA
			'CA-AB': 'Alberta',
			'CA-BC': 'British Columbia',
			'CA-MB': 'Manitoba',
			'CA-NB': 'New Brunswick',
			'CA-NL': 'Newfoundland and Labrador',
			'CA-NS': 'Nova Scotia',
			'CA-ON': 'Ontario',
			'CA-PE': 'Prince Edward Island',
			'CA-QC': 'Quebec',
			'CA-SK': 'Saskatchewan',
			'CA-NT': 'Northwest Territories',
			'CA-NU': 'Nunavut',
			'CA-YT': 'Yukon',
			// Australia https://en.wikipedia.org/wiki/ISO_3166-2:AU
			'AU-NSW': 'New South Wales',
			'AU-QLD': 'Queensland',
			'AU-SA':  'South Australia',
			'AU-TAS': 'Tasmania',
			'AU-VIC': 'Victoria',
			'AU-WA':  'Western Australia',
			'AU-ACT': 'Australian Capital Territory',
			'AU-NT':  'Northern Territory',
		};
	}

	$onChanges(changes) {

		if (changes.hasOwnProperty('regionCode') && changes.regionCode.currentValue && changes.hasOwnProperty('countryCode') && changes.countryCode.currentValue) {
			this.region_code = changes.regionCode.currentValue.toUpperCase();
			this.country_code = changes.countryCode.currentValue.toUpperCase();
			// if we have a match for our code, then use the new name, otherwise just use the code
			let index = `${this.country_code}-${this.region_code}`;
			if (this.region_map.hasOwnProperty(index)){
				this.region_name = this.region_map[index];
			} else {
				this.region_name = this.region_code;
			}
		}
	}
}

// example usage:
// <region region-code="TX" country-code="US"></region>
//
export default class RegionComponent {
	constructor() {
		this.template = require('./region.html').default;
		this.bindings = {
			regionCode: '<',
			countryCode: '<',
		};
		this.controller = RegionCtrl;
	}
}
