import { ExportOutlined } from '@ant-design/icons';
import { Paper } from '@material-ui/core';
import { Grid, Inline, Text, customProperties as vars } from '@resi-media/resi-ui';
import PropTypes from 'prop-types';
import React, { FC, useContext } from 'react';
import { BarChart, DoughnutChart, VirtualizedTable } from '../../../../components';
import FillBlockSpinner from '../../../../components/FillBlockSpinner/fill-block-spinner';
import { VirtualizedTableData } from '../../../../components/VirtualizedTable/types';
import { EventAnalyticsContext } from '../../../../contexts/eventanalytics/event-analytics';
import { MPEventName, MPEventProperty, trackMixpanelEvent } from '../../../../mixpanel';
import {
  CHART_TYPES,
  EXTRA_INFO,
  GROUP_VALUES,
  LEGEND_NAMES,
  LOADER_SIZE_COMPONENT,
  TABLE_HEADER_STREAM,
  TOOLTIP_LABEL,
} from '../../event-analytics-constants';
import { barChartDataType, doughnutChartDataType, formatTableDataInputParamsType } from '../../types';
import { convertTableToCSV, downloadAsCSV, renameKey } from '../../utils';
import {
  ChartContent,
  ChartHeaderDiv,
  HeaderDiv,
  SegmentedStatsWrapper,
  TableContent,
} from './youtube-segmented-stats.styles';
import Link from '@material-ui/core/Link';
import Tooltip from '@material-ui/core/Tooltip';
import startCase from 'lodash/startCase';
import { LinkButtonContainer } from '../../event-analytics.styles';
import { YoutubeContext } from '../../../../contexts/eventanalytics';

interface AllDestinationsSegmentedStatsViewPropType {
  chartData: doughnutChartDataType[] | barChartDataType;
  isChartLoading: boolean;
}
type renderChartandTableType = {
  chart: JSX.Element | null;
  table: JSX.Element | null;
};

const YoutubeSegmentedStatsView: FC<AllDestinationsSegmentedStatsViewPropType> = ({
  chartData,
  isChartLoading,
}): JSX.Element => {
  let tableDataCSV: string;
  const {
    EventAnalyticsState: { eventId },
  } = useContext(EventAnalyticsContext);
  const {
    youtubeAnalyticsState: { groupBy },
  } = useContext(YoutubeContext);

  const formatTableData = ({
    inputChartData,
    chartType,
    groupBy,
    segmentBy,
  }: formatTableDataInputParamsType): VirtualizedTableData => {
    let tableData: VirtualizedTableData = { header: [], data: [] };

    const formatDoughnutTableData = (inputChartData: doughnutChartDataType[]): VirtualizedTableData => {
      const doughnutTableData: VirtualizedTableData = { header: [], data: [] };
      doughnutTableData.header = [
        { label: segmentBy?.toLowerCase().replace(/^[a-zA-z]|\s(.)/gi, (L) => L.toUpperCase()) },
        { label: 'Viewers' },
      ];
      inputChartData?.map((item: doughnutChartDataType) => {
        doughnutTableData.data.push({ row: [item.label, item.data] });
      });
      return doughnutTableData;
    };

    const formatBarTableData = (inputChartData: barChartDataType): VirtualizedTableData => {
      const barTableData: VirtualizedTableData = { header: [], data: [] };
      const labels: string[] = inputChartData.labels;
      const legends: string[] = Object.keys(inputChartData.datasets);
      const extraInfo: string[] = inputChartData[EXTRA_INFO];
      groupBy === GROUP_VALUES.NONE
        ? (barTableData.header = [{ label: segmentBy }, { label: 'Viewers' }])
        : (barTableData.header = [
            {
              label: groupBy
                .toLowerCase()
                .replace(/^[a-zA-z]|\s(.)/gi, (L) => L.toUpperCase())
                .replace('_type', ''),
            },
            { label: segmentBy },
            { label: 'Viewers' },
          ]);
      for (let i = 0; i < labels.length; i++) {
        for (let j = 0; j < legends.length; j++) {
          if (groupBy === GROUP_VALUES.NONE) {
            barTableData.data.push({ row: [labels[i], Number(inputChartData.datasets[legends[j]][i])] });
          } else {
            barTableData.data.push({
              row: [
                startCase(legends[j]),
                inputChartData[EXTRA_INFO].length > 0 ? `${labels[i]}, ${extraInfo[i]}` : labels[i],
                Number(inputChartData.datasets[legends[j]][i]),
              ],
            });
          }
        }
      }
      return barTableData;
    };

    if (inputChartData !== null) {
      if (chartType === CHART_TYPES.DOUGHNUT) {
        tableData = formatDoughnutTableData(inputChartData as doughnutChartDataType[]);
      } else if (chartType === CHART_TYPES.BAR) {
        tableData = formatBarTableData(inputChartData as barChartDataType);
      }
    }
    tableDataCSV = convertTableToCSV(
      tableData.header,
      tableData.data.map((rowData) => rowData.row)
    );
    return tableData;
  };

  const renderChartandTable = (): renderChartandTableType => {
    let chart: JSX.Element, table: JSX.Element;
    let doughnutChartData: doughnutChartDataType[] = [];
    let barChartData: barChartDataType = { labels: [], extraInfo: [], datasets: {} };

    if (chartData) {
      if (chartData.constructor === Array) {
        doughnutChartData = chartData;
        doughnutChartData.map((el) => (el.label = el.label in LEGEND_NAMES ? `${el.label}` : el.label));
      } else {
        barChartData = chartData as barChartDataType;
        Object.keys(barChartData.datasets).map((el) => {
          if (el in LEGEND_NAMES) {
            barChartData.datasets = renameKey(barChartData.datasets, el, `${el}`);
          }
        });
      }
    }

    chart = <div />;
    table = <div />;

    if (groupBy === GROUP_VALUES.NONE || groupBy === GROUP_VALUES.DEVICE_TYPE) {
      // Device Type
      chart = (
        <DoughnutChart data={doughnutChartData} tooltipLabel={TOOLTIP_LABEL.VIEWS} showPercentage isLegendOnRightSide />
      );
      table = (
        <VirtualizedTable
          data={formatTableData({
            inputChartData: doughnutChartData,
            chartType: CHART_TYPES.DOUGHNUT,
            groupBy: GROUP_VALUES.NONE,
            segmentBy: TABLE_HEADER_STREAM.DEVICE_TYPE, // to be finalized
          })}
        />
      );
    } else if (groupBy === GROUP_VALUES.EVENT_TYPE) {
      chart = (
        <ChartContent>
          <BarChart key={`${barChartData?.labels?.length}`} data={barChartData} stacked tooltipLabel="Views" />
        </ChartContent>
      );
      table = (
        <VirtualizedTable
          data={formatTableData({
            inputChartData: barChartData,
            chartType: CHART_TYPES.BAR,
            groupBy: GROUP_VALUES.EVENT_TYPE,
            segmentBy: TABLE_HEADER_STREAM.DEVICE_TYPE,
          })}
        />
      );
    }
    const dataRender: renderChartandTableType = { chart, table };
    return dataRender;
  };

  const { chart: renderChart, table: renderTable } = renderChartandTable();
  const chart: JSX.Element | null = renderChart;
  const table: JSX.Element | null = renderTable;

  const handleDownloadCSV = () => {
    downloadAsCSV(tableDataCSV);

    const mixpanelProps = {
      [MPEventProperty.TRANSCODED_EVENT_UUID]: eventId,
      [MPEventProperty.CSV_DETAIL]: 'All Data',
      [MPEventProperty.ANALYTICS_TYPE]: 'YouTube',
    };

    if (!eventId) {
      delete mixpanelProps[MPEventProperty.TRANSCODED_EVENT_UUID];
    }

    trackMixpanelEvent(MPEventName.CSV_EXPORT, mixpanelProps);
  };

  return (
    <Paper elevation={0} variant="outlined" data-testid="youtube-segmented-stats-view">
      <SegmentedStatsWrapper>
        <HeaderDiv>
          <ChartHeaderDiv data-testid="youtube-segmented-chartTitle">
            <Text.Heading type="h4" customCss={`color: ${vars.colorAccent800};`}>
              Views by Device Type
            </Text.Heading>
          </ChartHeaderDiv>
          <Tooltip title={<h6>{'Download all data as CSV'}</h6>} arrow placement="top">
            <Link
              component="button"
              underline="none"
              variant="body2"
              onClick={handleDownloadCSV}
              data-testid="youtube-segmented-exportCSV"
            >
              <LinkButtonContainer>
                <ExportOutlined />
                <span style={{ marginLeft: '5px' }}>Export CSV</span>
              </LinkButtonContainer>
            </Link>
          </Tooltip>
        </HeaderDiv>
        <Grid display="grid" gridTemplateColumns="8fr 4fr">
          {isChartLoading ? (
            <FillBlockSpinner fontSize={LOADER_SIZE_COMPONENT} />
          ) : (
            <>
              <Grid.Item>
                <Inline justifyContent="center">
                  <ChartContent data-testid="youtube-segmented-chart">{chart}</ChartContent>
                </Inline>
              </Grid.Item>
              <Grid.Item>
                <Inline justifyContent="center">
                  <TableContent data-testid="youtube-segmented-table">{table}</TableContent>
                </Inline>
              </Grid.Item>
            </>
          )}
        </Grid>
      </SegmentedStatsWrapper>
    </Paper>
  );
};

YoutubeSegmentedStatsView.propTypes = {
  chartData: PropTypes.arrayOf(
    PropTypes.exact({
      label: PropTypes.string.isRequired,
      data: PropTypes.number.isRequired,
    }).isRequired
  ).isRequired,
  isChartLoading: PropTypes.bool.isRequired,
};

YoutubeSegmentedStatsView.displayName = 'YoutubeSegmentedStatsView';

export default YoutubeSegmentedStatsView;
