'use strict';
const { trackMixpanelEvent, MPEventProperty, MPEventName} = require('../../../../src/mixpanel');
const moment = require('moment');
const constants = require('../../constants');
const libraryModule = require('./index').libraryModule;
const jcs = require('../../jcs');
const destination = require('../../../../src/components/ImageUpload/imageUploadTypes');

function LibraryController(
	$rootScope,
	$scope,
	$timeout,
	$routeParams,
	focus,
	formValidationService,
	libraryService,
	Authentication,
	webPlayerService,
	uiService,
	embedCodeService,
) {
	'ngInject';

	$(window).trigger('resize'); // ensure footer is properly positioned
	$scope.view_library = false;
	$scope.validation = formValidationService.init();
	$scope.WEB_PLAYER_WRAPPER_ID = 'library-media-video-player-wrapper';
	$scope.TIME_DATE_FORMAT = 'MMM D, YYYY h:mm:ss A';
	$scope.DATE_FORMAT = 'MMM D, YYYY';

	$scope.MANIFEST_ATTRIBUTE = 'cdnManifestUrls';

	$scope.webplayerEnv = jcs.webplayerEnv;

	$scope.is_loading = false; // show "loading ..." message
	$scope.is_working = false; // activity indicator (spinner icon)
	$scope.error_msg = null;

	$scope.modal_media = null;

	$scope.media_list = null;
	$scope.media_list_cache = [];

	$scope.media_to_view = null;

	$scope.media_to_edit = null;

	$scope.media_to_delete = null;
	$scope.media_to_delete_error = null;

	$scope.load_library_timeout = null;
	$scope.load_library_cache_timeout = null;
	$scope.LOAD_LIBRARY_QUICK_TIMEOUT = 4000;
	$scope.LOAD_LIBRARY_REGULAR_TIMEOUT = 8000;

	$scope.loaded_all = false;
	$scope.processing_media = [];

	$scope.media_download_error = null;

	$scope.selected_embed_code_type = constants.EMBED_CODE_OPTIONS.DEFAULT;

	$scope.getCurrentUser = function () {
		return Authentication.getCurrentUser();
	};

	$scope.isProcessing = function (media) {
		return !media.hasOwnProperty($scope.MANIFEST_ATTRIBUTE);
	};

	$scope.isAnyMediaProcessing = function () {
		return $scope.media_list?.find(media => $scope.isProcessing(media)) !== undefined;
	};

	$scope.getEmbedCode = function (media) {
		if ($scope.selected_embed_code_type === constants.EMBED_CODE_OPTIONS.STANDARD_IFRAME){
			return embedCodeService.getStandardIframeEmbedCode(media?.embedId, `&type=library&autoplay=false${$scope.webplayerEnv.queryParam}`);
		}
		if ($scope.selected_embed_code_type === constants.EMBED_CODE_OPTIONS.ALTERNATIVE_IFRAME){
			return embedCodeService.getAlternativeIframeEmbedCode(media?.embedId, `&type=library&autoplay=false${$scope.webplayerEnv.queryParam}`);
		}
		// old style embed code
		return `<div id="resi-video-player" data-embed-id="${media?.embedId}" data-type="library" data-autoplay="false" ${$scope.webplayerEnv.attribute}></div>
<script type="application/javascript" src="https://control.resi.io/webplayer/loader.min.js"></script>`;
	};

	$scope.getModalMedia = function() {
		return $scope.modal_media;
	};

	$scope.getModalEmbedUrl = function (media) {
		return `https://control.resi.io/webplayer/video.html?type=library&id=${media.embedId}${$scope.webplayerEnv.queryParam}`;
	};

	$scope.enterViewMediaMode = function (media) {
		$scope.media_to_view = media;
	};

	$scope.deleteThumbnail = function() {
		$scope.media_to_edit.thumbnails = [];
	};

	$scope.enterEditMediaMode = function (media) {
		$scope.validation.clear();
		$scope.media_to_edit = { ...media };
		if (!$scope.media_to_edit.startTime) {
			$scope.media_to_edit.startTime = new Date();
		}
		const formatted_start_time = moment($scope.media_to_edit.startTime).format($scope.TIME_DATE_FORMAT);
		$('#media-start-time').data('daterangepicker').setStartDate(formatted_start_time);
		$('#media-start-time').data('daterangepicker').setEndDate(formatted_start_time);
		focus('media-title-input');
	};

	$scope.cancelEditMedia = function () {
		$rootScope.goBack(libraryModule.routes.list);
		$(window).scrollTop(0);
	};

	$scope.onClipboardCopy = function (media, type) {
		const formatted_air_date = moment(media.startTime).format($scope.DATE_FORMAT);

		trackMixpanelEvent(MPEventName.COPY_TO_CLIPBOARD, {
		  [MPEventProperty.TITLE]: media.title,
		  [MPEventProperty.AIRDATE]: formatted_air_date,
		  [MPEventProperty.DESCRIPTION]: media.description,
		  [MPEventProperty.THUMBNAIL]: $scope.getImageUrl(),
		  [MPEventProperty.COPY_TYPE]: type,
		});
	  };

	$scope.doesUpdateMediaFailValidation = function () {
		$scope.error_msg = null;
		$scope.validation.clear();

		// ensure required fields are not empty
		$scope.validation.checkForEmpty('media_to_edit_title', $scope.media_to_edit.title);
		$scope.validation.checkForEmpty('media_to_edit_start_time', $scope.media_to_edit.startTime);

		var has_validation_error = $scope.validation.hasError();
		if (has_validation_error) {
			$scope.error_msg = 'Please specify a value for the highlighted fields.';
		}

		return has_validation_error;
	};

	$scope.updateMedia = function ($event) {
		//because of image uploader there are 2 submit buttons present, ignore if not explicitly the one for this form
		if($event?.submitter?.id === 'lib-media-update-btn'){
			// if we have form validation errors, then don't go any further
			if ($scope.doesUpdateMediaFailValidation()){
				return false;
			}

			$scope.is_working = true;

			const payload = {
				title: $scope.media_to_edit.title,
				description: $scope.media_to_edit.description,
				startTime: $scope.media_to_edit.startTime,
				thumbnails: $scope.media_to_edit.thumbnails,
			};
			libraryService.updateMedia($scope.media_to_edit.mediaId, payload).then(response => {

				// update item in list
				const index = $scope.media_list?.findIndex(media => media.mediaId === $scope.media_to_edit.mediaId);
				if (index && index !== -1){
					$scope.media_list[index] = $scope.media_to_edit;
				}

				$rootScope.goBack(libraryModule.routes.list);
				$(window).scrollTop(0);

			}).catch(reason => {

				$scope.error_msg = 'An error occurred while attempting to update the media. Please try again, or report the problem if it persists.';

			}).finally(() => {

				$scope.is_working = false;

			});
		}
	};

	$scope.showDeleteMediaConfirmation = function (media) {
		$scope.media_to_delete = media;
		$scope.media_to_delete_error = null;
		$('#confirm-delete-media').modal('show');

		const formatted_air_date = moment(media.startTime).format($scope.DATE_FORMAT);

		trackMixpanelEvent(
				MPEventName.MEDIA_DELETE, {
					[MPEventProperty.TITLE]: media.title,
					[MPEventProperty.MEDIA_ID]: media.mediaId,
					[MPEventProperty.PARENT_ID]: media.parentId,
					[MPEventProperty.AIRDATE]: formatted_air_date,
					[MPEventProperty.THUMBNAIL]: $scope.getImageUrl(),
					[MPEventProperty.DESCRIPTION]: media.description,
				}
			)
	};

	$scope.downloadMedia = function (media) {
		$scope.media_download_error = null;
		$('#download-media').modal('show');

		const link = document.createElement("a");
		link.setAttribute("target", "_blank");
		libraryService.getMediaDownloadUrl(media.mediaId)
			.then(({ downloadUrl }) => {
				link.setAttribute("href", downloadUrl);
				link.click();
				$('#download-media').modal('hide');
			})
			.catch(e => {
				console.error(e);
				$scope.media_download_error = `Failed to download ${media.title}. Please contact support if this persists.`;
			})
			.finally(() => {
				link.remove();
			});

		const formatted_air_date = moment(media.startTime).format($scope.DATE_FORMAT);

		trackMixpanelEvent(
				MPEventName.MEDIA_DOWNLOAD, {
					[MPEventProperty.TITLE]: media.title,
					[MPEventProperty.MEDIA_ID]: media.mediaId,
					[MPEventProperty.PARENT_ID]: media.parentId,
					[MPEventProperty.AIRDATE]: formatted_air_date,
					[MPEventProperty.THUMBNAIL]: $scope.getImageUrl(),
					[MPEventProperty.DESCRIPTION]: media.description,
				}
			);
	};

	$scope.closeDownloadMediaModal = function() {
		$('#download-media').modal('hide');
	};

	$scope.cancelDeleteMedia = function () {
		$scope.media_to_delete = null;
	};

	$scope.deleteMedia = function () {


		$scope.is_working = true;

		libraryService.deleteMedia($scope.media_to_delete.mediaId).then(response => {

			// remove deleted item from our media list
			$scope.media_list = $scope.media_list.filter(media => media.mediaId !== $scope.media_to_delete.mediaId);

			$('#confirm-delete-media').modal('hide');
			$scope.media_to_delete = null;

		}).catch(reason => {

			$scope.media_to_delete_error = 'An error occurred while attempting to delete the media. Please try again, or report the problem if it persists.';

		}).finally(() => {

			$scope.is_working = false;

		});
	};

	$scope.trackMixpanelAction = function (media, action) {
		const formatted_air_date = moment(media.startTime).format($scope.DATE_FORMAT);
		let mixPanelEventName;

		if (action === "edit") {
			mixPanelEventName = MPEventName.MEDIA_EDIT;
		} else if (action === "details") {
			mixPanelEventName = MPEventName.MEDIA_DETAILS_VIEW;
		}

		if (mixPanelEventName) {
			trackMixpanelEvent(
				mixPanelEventName, {
					[MPEventProperty.TITLE]: media.title,
					[MPEventProperty.MEDIA_ID]: media.mediaId,
					[MPEventProperty.PARENT_ID]: media.parentId,
					[MPEventProperty.AIRDATE]: formatted_air_date,
					[MPEventProperty.THUMBNAIL]: $scope.getImageUrl(),
					[MPEventProperty.DESCRIPTION]: media.description,
				}
			)
		}
	}

	$scope.trackMixpanelSortBy = function (sortBy) {
		trackMixpanelEvent(
			MPEventName.LIBRARY_SORT_BY, {
				[MPEventProperty.SORTED_BY]: sortBy,
			}
		)
	}

	$scope.openCloseWatchModal = function (media) {
		if (media) {
			media.embedUrl = $scope.getModalEmbedUrl(media);
			webPlayerService.initializeByManifestUrl(
				$scope.WEB_PLAYER_WRAPPER_ID,
				media.cdnManifestUrls[0].dashUrl,
				media.cdnManifestUrls[0].hlsUrl,
				(webplayer, error) => {
					$timeout(function () {
						if (webplayer == null) {
							$scope.error_msg = error;
						} else {
							$scope.web_player = webplayer;
						}
					});
				}, {}
			);
		}
		else {
			webPlayerService.unload($scope.WEB_PLAYER_WRAPPER_ID);
		}
		$scope.modal_media = media;
	};

	$scope.loadProcessingMedia = function () {
		$timeout.cancel($scope.load_library_timeout);
		// Currently we are pulling all media in chunks of 100 and want to wait until the list
		// is populated before pulling any processing items
		// Check that the media list is done loading before attempting to request processing elements
		if ($scope.media_list) {
			const mediaToProcess = $scope.processing_media;
			let mediaIdToPositionMap = {};
			$scope.media_list.map((media, index) => {
				if ($scope.isProcessing(media)) {
					mediaIdToPositionMap[media.mediaId] = index;
				}
			});
			$scope.processing_media = [];
			mediaToProcess.forEach((media, index) => {
				libraryService.getMedia(media.mediaId, false).then(response => {
					$scope.media_list.splice(mediaIdToPositionMap[response.mediaId], 1, response);
					if (mediaToProcess.length - 1 === index && $scope.isAnyMediaProcessing()) {
						$scope.processing_media = $scope.media_list?.filter(media => $scope.isProcessing(media)) ?? [];
						$scope.load_library_timeout = $timeout($scope.loadProcessingMedia, $scope.LOAD_LIBRARY_REGULAR_TIMEOUT);
					}
				});
			});
		}
	};

	// if pageUrl is undefined, then 1st page of results will be fetched
	$scope.loadLibrary = function (pageUrl) {
		$timeout.cancel($scope.load_library_timeout);
		if (Authentication.getCurrentUser().hasPerm('library.get') && !$scope.loaded_all) {
			$scope.is_loading = true;
			$scope.error_msg = null;
			if (!pageUrl){
				$scope.media_list_cache = [];
			}
			libraryService.getPaginatedMedia(pageUrl).then(response => {
				$scope.media_list_cache = $scope.media_list_cache ? $scope.media_list_cache.concat(...response.mediaList) : response.mediaList;
				if (response.nextPageUrl) {
					$scope.loadLibrary(response.nextPageUrl);
				} else {
					$scope.media_list = $scope.media_list_cache;
					$scope.is_loading = false;
					if ($scope.isAnyMediaProcessing()){
						$scope.processing_media = $scope.media_list?.filter(media => $scope.isProcessing(media)) ?? [];
						// since the user is redirected to the library page immediately after saving to the library, we want to show an
						// update quickly if items are still processing. So make a check quickly after arriving at page to make things more responsive.
						$scope.load_library_timeout = $timeout($scope.loadProcessingMedia, $scope.LOAD_LIBRARY_QUICK_TIMEOUT);
					}
				}
			}).catch(reason => {
				$scope.is_loading = false;
				$scope.error_msg = 'An error occurred while attempting to retrieve the media library. Please try again, or report the problem if it persists.';
			});
		}
	};

	$scope.initMedia = function (media_id){

		$scope.is_loading = true;

		return libraryService.getMedia(media_id).catch(reason => {

			$scope.error_msg = 'An error occurred while attempting to retrieve the media. Please try again, or report the problem if it persists.';
			$scope.media_list = null;

		}).finally(() => {

			$scope.is_loading = false;

		});
	};

	$scope.getMedia = function () {
		return $scope.media_to_edit ?? $scope.media_to_view;
	};

	$scope.getRegion = function () {
		return $scope.getMedia().region;
	};

	$scope.getRoutingKey = function () {
		return $scope.getMedia().routingKey;
	};

	$scope.getDestination = function () {
		return destination.UPLOAD_DESTINATION.ARCHIVE;
	};

	$scope.getImageUrl = function () {
		const img = $scope.media_to_view?.thumbnails?.[0]?.representations?.[0].publicUrl ?? $scope.media_to_edit?.thumbnails?.[0]?.representations?.[0].publicUrl ?? '';
		return img !== '' ? img : 'default';
	};

	$scope.getViewImageUrl = function () {
		const img = $scope.media_to_view?.thumbnails?.[0]?.representations?.[0].publicUrl ?? '';
		return img !== '' ? img : 'default';
	};

	$scope.getEditImageUrl = function () {
		const img = $scope.media_to_edit?.thumbnails?.[0]?.representations?.[0].publicUrl ?? '';
		return img !== '' ? img : 'default';
	};

	$scope.onFilePathChanged = (publicUrl, metadata) => {
		if (publicUrl && metadata) {
			const rep = {
				quality: 'default',
				width: metadata?.width ?? 0,
				height: metadata?.height ?? 0,
				publicUrl: publicUrl ?? '',
			};
			const tempThumb = {
				name: $scope.media_to_edit?.title ?? '',
				language: '',
				author: '',
				encodingFormat: 'jpeg',
				bucket: 'thumbnails',
				representations: [rep],
			};
			$scope.media_to_edit.thumbnails = [tempThumb];
		}
	};

	$scope.onImageCropped = function () {
		return '';
	};

	$scope.onImageUploadCancel = function (ex) {
		$scope.media_to_edit.thumbnails = [];
	};
	//
	// initialize
	//

	// if we are coming from another page/module in control, then lets clear our cache to ensure we are working with fresh data.
	// Only if we are moving between pages within the library module do we want to keep the cache. This is important because we create
	// new media items in a different module, so we want to ensure we always get an up-to-date list when arriving on the media page.
	if(!$rootScope.isPrevPathFromSameModule(libraryModule.routes.list)){
		libraryService.clearCache();
	}

	// check to see if child page is being loaded

	if (typeof $routeParams.detailsmediaid !== 'undefined') {

		if ($scope.getCurrentUser().hasPerm('library.get')){
			$scope.initMedia($routeParams.detailsmediaid).then(response => {
				if (response){
					$scope.enterViewMediaMode(response);
				}
			});
		} else {
			uiService.notAuthorized();
		}

	} else if (typeof $routeParams.editmediaid !== 'undefined') {

		if ($scope.getCurrentUser().hasPerm('library.update')){
			$scope.initMedia($routeParams.editmediaid).then(response => {
				if (response){
					$scope.enterEditMediaMode(response);
				}
			});
		} else {
			uiService.notAuthorized();
		}

	} else {
		$scope.view_library = true;
		$scope.loadLibrary();
	}

	$scope.setMediaStartTime = function (start, end, label) {
		$scope.media_to_edit.startTime = moment(start).toISOString();
	};

	// for options see: http://www.daterangepicker.com/
	$('#media-start-time').daterangepicker(
		{
			singleDatePicker: true,
			showDropdowns: true,
			timePicker: true,
			timePickerIncrement: 1,
			timePickerSeconds: true,
			locale: {
				format: 'll LTS', // <= looks like this is using moment.js formatting options
			},
		},
		$scope.setMediaStartTime
	);

	$scope.$on('$destroy', () => {
		$timeout.cancel($scope.load_library_timeout);
	});

	// build our tooltips
	$timeout(function () {
		$('.embed-code-tooltip').tooltip({
			template: '<div class="tooltip" role="tooltip"><div class="tooltip-arrow"></div><div class="tooltip-inner tooltip-inner-wide tooltip-inner-left"></div></div>',
			placement: 'top',
			title: '<div class="tooltip-section">In most cases the <strong>Standard iframe</strong> will allow you to embed a video that will adjust to fill the parent container.</div><div class="tooltip-section">The <strong>Alternative iframe</strong> can be used in cases where the parent container is a fixed height and does not match the video\'s aspect ratio.</div>The <strong>Script Tag</strong> may be required for use with certain third-party products such as Planning Center Publishing.',
			html: true,
		});
	});

}

module.exports = LibraryController;
