import 'angular-route';
import angular from 'angular';
import router from './module.encoders.routes';
import commonServicesModule from '../../services';
// import authModule from '../auth';
import '../../../plugins/promise-addons/angular-promise-addons.js';

export const encodersModule = {
	name: 'encoders',
	controllers: {
		default: 'encodersCtrl',
	},
	routes: {
		list: '/encoders',
	},
};

export default angular
	.module(encodersModule.name, ['ngRoute', 'ngPromiseAddons', commonServicesModule])
	.config(router)
	.controller(encodersModule.controllers.default, require('./encodersCtrl')).name;
