import * as jcs from '../jcs';
import { SOCIAL_MEDIA_TYPE_FACEBOOK, SOCIAL_MEDIA_TYPE_YOUTUBE } from '../constants';

class SocialMediaService {
	constructor($http, $q) {
		'ngInject';

		this.$http = $http;
		this.$q = $q;

		this.PATH_YT_CHANNELS = '/youtube/channels/';
		this.PATH_FB_CHANNELS = '/facebook/channels/';
		this.PATH_CHECKLIVE = '/checklive';
		this.ACCOUNT_STATUS_MAX_RETRIES = 2;
		// this.ACCT_STATUS_CHECKING = 'Checking ...'; // fetching status from youtube
		// this.ACCT_STATUS_OK = 'Access verified'; // Access verified and can live stream
		// this.ACCT_STATUS_NO_ACCESS = 'No Access'; // we do not have permission to manage their account
		// this.ACCT_STATUS_NO_LIVE_STREAM = 'Not allowed to Live Stream'; // their account does not have live streaming enabled
		// this.ACCT_STATUS_BAD_STATE = 'Channel Update Required'; // youtube accts can get in a weird state where we are unable to create a live stream even though live streaming is enabled
		// this.ACCT_STATUS_UNKNOWN = 'Unknown Status'; // any other error

		this.STATUS_CHECKING = 'Checking ...'; // for UI use only (not returned by API)
		this.STATUS_OK = 'OK';
		this.STATUS_INVALID_SIMULCAST = 'INVALID_SIMULCAST';
		this.STATUS_INVALID_SIMULCAST_MANIFEST = 'INVALID_SIMULCAST_MANIFEST';
		this.STATUS_CONFLICT = 'CONFLICT';
		this.STATUS_TOKEN_INVALID = 'TOKEN_INVALID';
		this.STATUS_NOT_ENABLED = 'NOT_ENABLED';
		this.STATUS_ENABLED_BUT_NOT_ACTIVE = 'ENABLED_BUT_NOT_ACTIVE';
		this.STATUS_PARTNER_ERROR = 'PARTNER_ERROR';
		this.STATUS_COMMUNICATION_ERROR = 'COMMUNICATION_ERROR';
		this.STATUS_TRANSCODER_ERROR = 'TRANSCODER_ERROR';
		this.STATUS_ERROR = 'ERROR';
		this.STATUS_UNKNOWN = 'Unknown'; // any other error (usually used when we are unable to fetch status from API)

		this.SCHEDULED_POST = 'scheduled_published';
		this.PUBLISHED_POST = 'published';

		this.RETRY_TIMEOUT_LENGTH = 500; // milliseconds
	}

	getAcctInfoFromUrl(url) {
		let acct_id = null;
		let acct_type = null;

		if (url != null) {
			let front = url.indexOf(this.PATH_YT_CHANNELS) + this.PATH_YT_CHANNELS.length;
			let back = url.indexOf(this.PATH_CHECKLIVE);

			// is this a YouTube URL?
			let yt_index = url.indexOf(this.PATH_YT_CHANNELS);
			if (yt_index != -1) {
				acct_type = SOCIAL_MEDIA_TYPE_YOUTUBE;

				front = yt_index + this.PATH_YT_CHANNELS.length;
				back = url.indexOf(this.PATH_CHECKLIVE);
				if (front != -1 && back != -1) {
					acct_id = url.substring(front, back);
				}
			} else {
				// is this a Facebook URL?
				let fb_index = url.indexOf(this.PATH_FB_CHANNELS);
				if (fb_index != -1) {
					acct_type = SOCIAL_MEDIA_TYPE_FACEBOOK;

					front = fb_index + this.PATH_FB_CHANNELS.length;
					back = url.indexOf(this.PATH_CHECKLIVE);
					if (front != -1 && back != -1) {
						acct_id = url.substring(front, back);
					}
				}
			}
		}
		return {
			id: acct_id,
			type: acct_type,
		};
	}

	getStatusData(response) {
		let data = {
			status: this.STATUS_UNKNOWN,
			expiresAt: null
		};
		if (response != null) {
			data.status = response.simulcastCode;
			data.expires_at = response.expiresAt;
		}
		return data;
	}

	getSocialMediaStatusDesc (status) {
		switch (status) {
			case this.STATUS_OK:
				return '';
			case this.STATUS_TOKEN_INVALID:
				return 'No Access';
			case this.STATUS_NOT_ENABLED:
				return 'Not allowed to Live Stream';
			case this.STATUS_ENABLED_BUT_NOT_ACTIVE:
				return 'Channel Update Required';
			default:
				return 'Unknown Status';
		}
	};

	startsWith (target, search_term) {
		if (target != null) {
			if (target.substring(0, search_term.length) == search_term) {
				return true;
			}
		}
		return false;
	}

	// sorts the provided social media account list first by type, and then by name
	sortByType(social_media_accts_list){

		social_media_accts_list.sort(function(first, second){
			// first check types
			let type1 = first.type;
			let type2 = second.type;
			if (type1 > type2) {
			  return -1;
			}
			if (type1 < type2) {
			  return 1;
			}
			// types are same, so check the name
			let name1 = first.name ? first.name.toUpperCase() : "";
			let name2 = second.name ? second.name.toUpperCase() : "";
			if (name1 < name2){
				return -1;
			}
			if (name1 > name2){
				return 1;
			}
			// types and names must be equal
			return 0;
		});
	}

	// acct_list needs to contain objects with following fields: id, type
	performCheckAccountStatus(acct_list, customer_id, updateStatusCB, retry_count) {
		for (var i = 0; i < acct_list.length; i++) {
			const acct = acct_list[i];
			if (acct.type === SOCIAL_MEDIA_TYPE_YOUTUBE) {
				this.$http.get(jcs.api.url_v3 + '/customers/' + customer_id + this.PATH_YT_CHANNELS + acct.id + this.PATH_CHECKLIVE, { withCredentials: true })
					.then(response => {
						const acct = this.getAcctInfoFromUrl(response.config.url);
						if (acct.id !== null) {
							updateStatusCB(acct.id, this.getStatusData(response.data));
						}
					})
					.catch(error => {
						 console.log(error);
						 updateStatusCB(acct.id, this.getStatusData(null)); // ACCT_STATUS_UNKNOWN
					});
			} else if (acct.type === SOCIAL_MEDIA_TYPE_FACEBOOK) {
				this.$http.get(jcs.api.url_v3 + '/customers/' + customer_id + this.PATH_FB_CHANNELS + acct.id + this.PATH_CHECKLIVE, { withCredentials: true })
				.then(response => {
					const acct = this.getAcctInfoFromUrl(response.config.url);
					if (acct.id !== null) {
						updateStatusCB(acct.id, this.getStatusData(response.data));
					}
				})
				.catch(error => {
					 console.log(error);
					 updateStatusCB(acct.id, this.getStatusData(null)); // ACCT_STATUS_UNKNOWN
				});
			}
		}
	}

	init() {
		return {
			STATUS_CHECKING: this.STATUS_CHECKING,
			STATUS_OK: this.STATUS_OK,
			STATUS_INVALID_SIMULCAST: this.STATUS_INVALID_SIMULCAST,
			STATUS_INVALID_SIMULCAST_MANIFEST: this.STATUS_INVALID_SIMULCAST_MANIFEST,
			STATUS_CONFLICT: this.STATUS_CONFLICT,
			STATUS_TOKEN_INVALID: this.STATUS_TOKEN_INVALID,
			STATUS_NOT_ENABLED: this.STATUS_NOT_ENABLED,
			STATUS_ENABLED_BUT_NOT_ACTIVE: this.STATUS_ENABLED_BUT_NOT_ACTIVE,
			STATUS_PARTNER_ERROR: this.STATUS_PARTNER_ERROR,
			STATUS_COMMUNICATION_ERROR: this.STATUS_COMMUNICATION_ERROR,
			STATUS_TRANSCODER_ERROR: this.STATUS_TRANSCODER_ERROR,
			STATUS_ERROR: this.STATUS_ERROR,
			STATUS_UNKNOWN: this.STATUS_UNKNOWN,

			SCHEDULED_POST: this.SCHEDULED_POST,
			PUBLISHED_POST: this.PUBLISHED_POST,

			ACCOUNT_STATUS_MAX_RETRIES: this.ACCOUNT_STATUS_MAX_RETRIES, // our jasmine tests use this

			TYPE_YOUTUBE: SOCIAL_MEDIA_TYPE_YOUTUBE,
			TYPE_FACEBOOK: SOCIAL_MEDIA_TYPE_FACEBOOK,

			checkAccountStatus: (acct_list, customer_id, updateStatusCB) => {
				this.performCheckAccountStatus(acct_list, customer_id, updateStatusCB, this.ACCOUNT_STATUS_MAX_RETRIES);
			},

			sortByType: this.sortByType,

			getSocialMediaStatusDesc: this.getSocialMediaStatusDesc,

			// our jasmine tests will need to use this
			setRetryTimeoutLength: timeout_duration => {
				this.RETRY_TIMEOUT_LENGTH = timeout_duration;
			},

			isYouTubeType: type => {
				return this.startsWith(type, SOCIAL_MEDIA_TYPE_YOUTUBE);
			},

			isFacebookType: type => {
				return this.startsWith(type, SOCIAL_MEDIA_TYPE_FACEBOOK);
			},

			getTypeAsLabel: type => {
				if (this.startsWith(type, SOCIAL_MEDIA_TYPE_YOUTUBE)) {
					return 'YouTube';
				}
				if (this.startsWith(type, SOCIAL_MEDIA_TYPE_FACEBOOK)) {
					return 'Facebook';
				}
				return 'Unknown';
			},
		};
	}
}

export default SocialMediaService;
