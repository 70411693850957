import { DataCenter, DATA_CENTERS, LatencyDetails } from '../../constants';
import { checkDataCenterLatency } from './client';

export const REQUIRED_SAMPLES = 7;
export const MAX_ACCEPTABLE_LATENCY = 150;

async function getRegionSampleLatencies(includedDataCenters: readonly DataCenter[]): Promise<LatencyDetails[]> {
  let regionList: LatencyDetails[] = [];
  // throw away first sample for each
  console.log('==BEGIN REGION LATENCY SAMPLES==');

  if (includedDataCenters.length === 1) {
    console.log('Only one region to check. Skipping latency checks.');
    const [dataCenter] = includedDataCenters;
    return [
      {
        dataCenter: dataCenter,
        isPassing: true,
      },
    ];
  }

  for (const dataCenter of includedDataCenters) {
    let iteration = 0;
    while (iteration <= REQUIRED_SAMPLES) {
      const res = await checkDataCenterLatency(dataCenter.url);
      // if cannot get response, break loop and throw out samples for this region
      if (res === null) {
        console.error(`Failed to receive required samples for ${dataCenter.displayName}`);
        regionList = regionList.filter((r) => r.dataCenter.id !== dataCenter.id);
        break;
      }
      // if iteration is 0, skip adding the responses its only purpose is for dns resolution
      if (iteration > 0) {
        console.info(`%c ${res.data.duration}ms`, 'margin-left: 5px');
        regionList.push({
          dataCenter: dataCenter,
          duration: res.data.duration,
          isPassing: res.data.duration <= MAX_ACCEPTABLE_LATENCY,
        });
      } else {
        console.log(`${dataCenter.displayName} Latencies:`);
      }
      iteration++;
    }
  }
  return regionList;
}

function findRegionWithBestMedianSample(
  regionList: LatencyDetails[],
  includedDataCenters: readonly DataCenter[]
): LatencyDetails | null {
  let lowestMedianLatencyRegion: LatencyDetails | null = null;
  console.log('==REGION MEDIAN LATENCIES==');
  for (const { id, displayName } of includedDataCenters) {
    const regionSamples = regionList
      .filter((r) => r.dataCenter.id === id)
      .sort((a, b) => {
        return a.duration > b.duration ? 1 : -1;
      });

    if (regionSamples.length < REQUIRED_SAMPLES) {
      continue;
    }
    // remove highest two responses to account for potential high outliers
    regionSamples.splice(-2, 2);
    const medianIndex = Math.floor(regionSamples.length / 2);
    const medianSample = regionSamples[medianIndex];
    console.log(
      `%c Median latency:  ${displayName}: ${medianSample.duration}ms`,
      `color: ${medianSample.duration > MAX_ACCEPTABLE_LATENCY ? 'red' : 'green'}; font-weight: 700 `
    );

    if (!lowestMedianLatencyRegion || medianSample.duration < lowestMedianLatencyRegion.duration) {
      lowestMedianLatencyRegion = medianSample;
    }
  }
  console.log(
    `%c Best Median Latency:  ${lowestMedianLatencyRegion?.dataCenter.displayName}: ${lowestMedianLatencyRegion?.duration}ms`,
    `font-weight: 700`
  );
  return lowestMedianLatencyRegion;
}

export async function getClosestRegion(includedRegionIds: string[]): Promise<LatencyDetails | null> {
  const includedDataCenters = DATA_CENTERS.filter(({ id }) => includedRegionIds.includes(id));
  const dataCentersToTest = includedDataCenters.length ? includedDataCenters : DATA_CENTERS;

  const regionList = await getRegionSampleLatencies(dataCentersToTest);

  // if length is 0, return null to let React component know that no region completed all required latency samples
  if (!regionList.length) {
    return null;
  }
  // if only one data center, we don't need to find best median sample and can just return the one data center we have
  if (regionList.length === 1) {
    return regionList[0];
  }

  // Find best median latency
  return findRegionWithBestMedianSample(regionList, dataCentersToTest);
}
