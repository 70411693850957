import styled from '@emotion/styled';
import { customProperties as vars } from '@resi-media/resi-ui';

export const EventStatsGraphWrapper = styled.div`
  height: 600px;
  padding: ${vars.spacingL} 3rem;
`;

export const EventStatsHeaderDiv = styled.div`
  display: grid;
  grid-template-columns: 4fr 4.2fr 3.7fr;
  gap: ${vars.spacingM};
`;

export const TabsDiv = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: ${vars.fontSize12};
  margin: ${vars.spacingM} 0;
`;

export const GraphWrapper = styled.div`
  height: 510px;
`;

export const DropDownWrapper = styled.div`
  margin: 0.25em 0px 0px !important;
`;

export const ChartControls = styled.div`
  max-width: ${vars.constraintS};
`;