import styled from '@emotion/styled';
import { css, SerializedStyles } from '@emotion/react';
import { customProperties as vars } from '@resi-media/resi-ui';

export const SurfaceContainer = styled.div`
  background: ${vars.colorSurface};
  height: 100%;
  box-sizing: border-box;
  border-radius: ${vars.borderRadius4};
  border: 0.5px solid ${vars.borderColorForSelect};
`;

export const surfaceCSS = (padding: string): SerializedStyles => css`
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  padding: ${padding};
`;
