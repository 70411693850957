import { ImportResponse } from "../components/uploadsInProgress";
import { mediaStatusApi } from "../jcs";
import { AUTH_TOKEN } from "../constants";

export default class UploadsInProgressService {
  constructor(private $http) {
    'ngInject';
    this.$http = $http;
  }

  public async getStatusesByCustomerId(customerId: string): Promise<ImportResponse[]> {
    const request = {
      method: 'GET',
      url: `${mediaStatusApi.v1}/customers/${customerId}/imports`,
      withCredentials: true,
      headers: {
        'Authorization': `X-Bearer ${sessionStorage.getItem(AUTH_TOKEN)}`
      }
    }
    try {
      const res = await this.$http(request);
      return res.data;
    } catch(e) {
      console.error(e);
      return [];
    }
  }

}
