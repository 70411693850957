import Axios, { AxiosResponse } from 'axios';
import { api } from '../../../v2.snapshot/js/jcs';

export interface ArchiveTrimWebEventPayload {
  webEventName?: string;
  startPosition?: string;
  endPosition?: string;
  published: boolean;
  hidden: boolean;
  webEventProfileId?: string;
}

export const archiveTrimWebEvent = async (
  payload: ArchiveTrimWebEventPayload,
  customerId: string,
  webEventId: string
): Promise<AxiosResponse> => {
  return Axios.post(`${api.url_v3}/customers/${customerId}/webevents/${webEventId}/republish`, payload, {
    withCredentials: true,
  });
};
