import mixpanel, { Config, Dict } from 'mixpanel-browser';

export function initMixpanel(apiKey: string, config?: Config): void {
  try {
    mixpanel.init(apiKey, config);
  } catch (e) {
    console.log(e);
  }
}

export function trackMixpanelEvent(name: string, props?: Dict): void {
  try {
    mixpanel.track(name, props);
  } catch (e) {
    console.log(e);
  }
}

export function identifyMixpanelUser(userId: string): void {
  try {
    mixpanel.identify(userId);
  } catch (e) {
    console.log(e);
  }
}

export function resetMixpanelUser(): void {
  try {
    mixpanel.reset();
  } catch (e) {
    console.log(e);
  }
}

export function setMixpanelUserProps(props: Dict): void {
  try {
    mixpanel.people.set(props);
  } catch (e) {
    console.log(e);
  }
}

export function setMixpanelGroup(orgId: string): void {
  try {
    mixpanel.set_group(MPEventProperty.ORG_UUID, orgId);
  } catch (e) {
    console.log(e);
  }
}

export function mixpanelRegister(props: Dict): void {
  try {
    mixpanel.register(props);
  } catch (e) {
    console.log(e);
  }
}

export function setMixpanelGroupProperty(groupKey: string, groupId: string, props: Dict): void {
  try {
    mixpanel.get_group(groupKey, groupId).set(props);
  } catch (e) {
    console.log(e);
  }
}

export const MPEventProperty = {
  USER_UUID: 'user_uuid',
  USER_NAME: 'user_name',
  USER_PERMISSIONS: 'user_permissions',
  ORG_UUID: 'org_uuid',
  ORG_NAME: 'org_name',
  CONTACT_EMAIL: 'contact_email',
  ENCODER_UUID: 'encoder_uuid',
  ENCODER_NAME: 'encoder_name',
  ENCODER_MODEL: 'encoder_model',
  ENCODER_VERSION: 'encoder_version',
  ENCODER_PROFILE_UUID: 'encoder_profile_uuid',
  ENCODER_PROFILE_NAME: 'encoder_profile_name',
  ENCODER_PROFILE_VERSION: 'encoder_profile_version',
  ENCODER_EVENT_PROFILE_UUID: 'encoder_event_profile_uuid',
  ENCODER_EVENT_PROFILE_NAME: 'encoder_event_profile_name',
  LAN_MODE: 'lan_mode',
  VIDEO_INPUT_SOURCE: 'video_input_source',
  WEB_ENCODER_PROFILE_UUID: 'web_encoder_profile_uuid',
  WEB_ENCODER_PROFILE_NAME: 'web_encoder_profile_name',
  ENCODER_VIDEO_CHANNEL: 'encoder_video_channel',
  DEINTERLACE: 'deinterlace',
  AUDIO_CHANNEL_LEFT: 'audio_channel_left',
  AUDIO_CHANNEL_RIGHT: 'audio_channel_right',
  WEB_EVENT_PROFILE_NAME: 'web_event_profile_name',
  WEB_EVENT_PROFILE_UUID: 'web_event_profile_uuid',
  WEB_EVENT_DELAY: 'web_event_delay',
  CHROMECAST_APP_ID: 'chromecast_app_id',
  COPY_TYPE: 'copy_type',
  WEB_EVENT_NAME: 'web_event_name',
  WEB_EVENT_UUID: 'web_event_uuid',
  NUMBER_OF_ENCODERS: 'number_of_encoders',
  DELETE_AFTER: 'delete_after',
  REGION: 'region',
  PROPERTY_MODIFIED: 'property_modified',
  ACCOUNT_MODIFIED_UUID: 'account_modified_uuid',
  ACCOUNT_MODIFIED_NAME: 'account_modified_name',
  EMAIL_INVITED: 'email_invited',
  TYPE: 'type',
  SHOW_LOGO: 'show_logo',
  SUBTITLES_ENABLED: 'subtitles_enabled',
  STREAM_URLS: 'stream_urls',
  SOCIAL_DESTINATION: 'social_destination',
  DESTINATION_ACCOUNT_NAME: 'destination_account_name',
  DESTINATION_GROUP_NAME: 'destination_group_name',
  DESTINATION_GROUP_UUID: "destination_group_uuid",
  FACEBOOK_DESTINATION_ACCOUNT_NAME: 'facebook_destination_account_name',
  FACEBOOK_DESTINATION: 'facebook_destination',
  FACEBOOK_PRIVACY: 'facebook_privacy',
  FACEBOOK_PUBLISH_STATUS: 'facebook_publish_status',
  FACEBOOK_CROSSPOST_DESTINATIONS: 'facebook_crosspost_destinations',
  YOUTUBE_DESTINATION_ACCOUNT_NAME: 'youtube_destination_account_name',
  YOUTUBE_PRIVACY: 'youtube_privacy',
  YOUTUBE_PUBLISH_STATUS: 'youtube_publish_status',
  CHANNEL_NAME: 'channel_name',
  PUBLISH_STATUS: 'publish_status',
  PRIVACY: 'privacy',
  SOCIAL_CROSSPOST_COUNT: 'crosspost_count',
  WEB_EVENT_COUNT: 'web_event_count',
  SOCIAL_DESTINATION_COUNT: 'social_destination_count',
  AVAILABLE_IN_PROPRESENTER: 'available_in_propresenter',
  EVENT_UUID: 'event_uuid',
  EVENT_DURATION: 'event_duration',
  EVENT_NAME: 'event_name',
  EVENT_STATUS: 'event_status',
  EVENT_FREQUENCY: 'event_frequency',
  PLAYOUT_BEHAVIOR: 'playout_behavior',
  DESTINATION: 'destination',
  TRANSCODED_EVENT_UUID: 'transcoded_event_uuid',
  TRANSCODED_SCHEDULE_UUID: 'transcoded_schedule_uuid',
  CSV_DETAIL: 'csv_detail',
  ANALYTICS_TYPE: 'analytics_type',
  ANALYTICS_DETAIL_TYPE: 'analytics_detail_type',
  ANALYTICS_SEGMENT: 'analytics_segment',
  SCHEDULE_TYPE: 'schedule_type',
  SCHEDULE_NAME: 'schedule_name',
  SCHEDULE_UUID: 'schedule_uuid',
  START_DATE: 'start_date',
  START_TIME_24H: 'start_time_24h',
  TIMEZONE: 'timezone',
  CUE_START: 'cue_start',
  CUE_STOP: 'cue_stop',
  CUE_NAME: 'cue_name',
  CUE_SHARED: 'cue_shared',
  VIDEO_FORMAT: 'video_format',
  AUDIO_FORMAT: 'audio_format',
  CREATE_WEB_EVENT: 'create_web_event',
  ERROR_MESSAGE: 'error_message',
  GOP_DURATION: 'gop_duration',
  KEYFRAME_MBPS: 'keyframe_mbps',
  FRAMERATE: 'framerate',
  RESOLUTION_WIDTH: 'resolution_width',
  RESOLUTION_HEIGHT: 'resolution_height',
  VIDEO_BITRATE_MBPS: 'video_bitrate_mbps',
  VIDEO_CHANNELS: 'video_channels',
  INPUT_FORMAT: 'input_format',
  OUTPUT_FORMAT: 'output_format',
  CODEC: 'codec',
  HARDWARE_ACCELERATION: 'hardware_acceleration',
  AUDIO_CHANNELS: 'audio_channels',
  AUDIO_BITRATE_KBPS: 'audio_bitrate_kbps',
  AUDIO_SAMPLE_RATE_KHZ: 'audio_sample_rate_khz',
  AUDIO_TRACKS: 'audio_tracks',
  SAMPLE_DURATION: 'sample_duration',
  SUCCESS_STATE: 'success_state',
  TIMESCALE: 'timescale',
  CONTACTS_ADDED: 'contacts_added',
  NUMBER_OF_USERS: 'number_of_users',
  NUMBER_OF_VENUES: 'number_of_venues',
  TOGGLE_TYPE: 'toggle_type',
  TOGGLES: 'toggles',
  PERMISSIONS: 'permissions',
  USER_PLAN: 'user_plan',
  USER_STATUS: 'user_status',
  LOG_DURATION: 'log_duration',
  LOG_DURATION_MIN: 'log_duration_min',
  LOG_TYPE: 'log_type',
  NIC_STATUS: 'nic_status',
  TITLE: 'title',
  MEDIA_ID: 'media_id',
  PARENT_ID: 'parent_id',
  AIRDATE: 'airdate',
  DURATION: 'duration',
  THUMBNAIL: 'thumbnail',
  DESCRIPTION: 'description',
  SORTED_BY: 'sorted_by',
  TRIMMED_DURATION:'trimmed_duration',
  IS_TRIMMED: 'is_trimmed',
};

export const MPBuiltInProps = {
  NAME: '$name',
};

export const MPEventName = {
  USER_LOGIN: 'User Login',
  USER_LOGOUT: 'User Logout',
  USER_REFRESH: 'User Refresh',
  CONTACT_ADD: 'Contact Add',
  PASSWORD_CHANGE: 'Password Change',
  USER_INVITE_SEND: 'User Invite Send',
  USER_INVITE_RESEND: 'User Invite Resend',
  USER_INVITE_REVOKE: 'User Invite Revoke',
  LOGS_UPLOAD: 'Logs Upload',
  LOGS_VIEW: 'Logs View',
  ENCODER_ADD: 'Encoder Add',
  ENCODER_CONFIGURE: 'Encoder Configure',
  ENCODER_DELETE: 'Encoder Delete',
  ENCODER_DETAILS: 'Encoder Details',
  ENCODER_UPDATE: 'Encoder Update',
  ENCODER_START: 'Encoder Start',
  ENCODER_STOP: 'Encoder Stop',
  ENCODER_RESTART: 'Encoder Restart',
  ENCODER_MOVE: 'Encoder Move',
  ENCODER_EVENT_EDIT: 'Encoder Event Edit',
  ENCODER_EVENT_DELETE: 'Encoder Event Delete',
  ENCODER_EVENT_CLOUD_URL: 'Encoder Event Cloud Url',
  ENCODER_PROFILE_ADD: 'Encoder Profile Add',
  ENCODER_PROFILE_EDIT: 'Encoder Profile Edit',
  ENCODER_PROFILE_COPY: 'Encoder Profile Copy',
  ENCODER_PROFILE_DELETE: 'Encoder Profile Delete',
  ENCODER_EVENT_PROFILE_ADD: 'Encoder Event Profile Add',
  ENCODER_EVENT_PROFILE_EDIT: 'Encoder Event Profile Edit',
  ENCODER_EVENT_PROFILE_DELETE: 'Encoder Event Profile Delete',
  ENCODER_EVENT_WATCH: 'Encoder Event Watch',
  WEB_ENCODER_PROFILE_ADD: 'Web Encoder Profile Add',
  WEB_ENCODER_PROFILE_EDIT: 'Web Encoder Profile Edit',
  WEB_ENCODER_PROFILE_DELETE: 'Web Encoder Profile Delete',
  WEB_EVENT_START: 'Web Event Start',
  WEB_EVENT_STOP: 'Web Event Stop',
  WEB_EVENT_EDIT: 'Web Event Edit',
  WEB_EVENT_WATCH: 'Web Event Watch',
  WEB_EVENT_DELETE: 'Web Event Delete',
  WEB_EVENT_PROFILE_ADD: 'Web Event Profile Add',
  WEB_EVENT_PROFILE_EDIT: 'Web Event Profile Edit',
  WEB_EVENT_PROFILE_DELETE: 'Web Event Profile Delete',
  ACTIVE_CONTENT_REMOVE: 'Active Content Remove',
  COPY_TO_CLIPBOARD: 'Copy To Clipboard',
  STREAM_URL_COPY: 'Stream Url Copy',
  SOCIAL_DESTINATION_ADD: 'Social Destination Add',
  SOCIAL_DESTINATION_EDIT: 'Social Destination Edit',
  SOCIAL_DESTINATION_DELETE: 'Social Destination Delete',
  SOCIAL_DESTINATION_GRANT: 'Social Destination Grant',
  SOCIAL_DESTINATION_EXTEND: 'Social Destination Extend',
  SOCIAL_DESTINATION_STOP: 'Social Destination Stop',
  SOCIAL_DESTINATION_VIEW: 'Social Destination View',
  SOCIAL_DESTINATION_PUBLISH: 'Social Destination Publish',
  DESTINATION_GROUP_ADD: 'Destination Group Add',
  DESTINATION_GROUP_EDIT: 'Destination Group Edit',
  DESTINATION_GROUP_DELETE: 'Destination Group Delete',
  CUE_ADD: 'Cue Add',
  CUE_HIDE: 'Cue Hide',
  CUE_SHOW: 'Cue Show',
  CUE_GOTO: 'Cue GoTo',
  CUE_UPDATE: 'Cue Update',
  CUE_DELETE: 'Cue Delete',
  DETAILED_REPORT_DOWNLOAD: 'Detailed Report Download',
  SUMMARY_REPORT_DOWNLOAD: 'Summary Report Download',
  CSV_EXPORT: 'CSV Export',
  ANALYTICS_VIEW: 'Analytics View',
  ANALYTICS_VIEW_DETAILS: 'Analytics View Details',
  ANALYTICS_SEGMENT: 'Analytics Segment',
  EVENT_SHOW_PAST: 'Event Show Past',
  EVENT_SHOW_PREVIOUS_MONTH: 'Event Show Previous Month',
  EVENT_SHOW_FUTURE: 'Event Show Future',
  EVENT_SCHEDULE_ADD: 'Event Schedule Add',
  EVENT_SCHEDULE_EDIT: 'Event Schedule Edit',
  EVENT_SCHEDULE_DELETE: 'Event Schedule Delete',
  WEB_EVENT_SCHEDULE_ADD: 'Web Event Schedule Add',
  WEB_EVENT_SCHEDULE_EDIT: 'Web Event Schedule Edit',
  WEB_EVENT_SCHEDULE_DELETE: 'Web Event Schedule Delete',
  SOCIAL_DESTINATION_SCHEDULE_ADD: 'Social Destination Schedule Add',
  SOCIAL_DESTINATION_SCHEDULE_EDIT: 'Social Destination Schedule Edit',
  SOCIAL_DESTINATION_SCHEDULE_DELETE: 'Social Destination Schedule Delete',
  WEB_EVENT_TRIM: 'Web Event Trim',
  EVENT_DOWNLOAD: 'Event Download',
  VIDEO_UPLOAD_START: 'Video Upload Start',
  VIDEO_UPLOAD_COMPLETE: 'Video Upload Complete',
  VIDEO_UPLOAD_ANALYZE_COMPLETE: 'Video Upload Analyze Complete',
  VIDEO_UPLOAD_PROCESSING_COMPLETE: 'Video Upload Processing Complete',
  VIDEO_UPLOAD_WEB_CREATE: 'Video Upload Web Create',
  CONTACT_IMPORT_COMLETE: 'Contact Import Complete',
  USER_VENUE_ADD: 'User/Venue Add',
  TOGGLE_EDIT: 'Toggle Edit',
  USER_RESI_EDIT: 'User (Resi) Edit',
  USER_BILLING_EDIT: 'User Billing Edit',
  USER_DELETE: 'User Delete',
  IP_ADDRESS_EDIT: 'IP Address Edit',
  ORGANIZATION_ADD: 'Organization Add',
  ORGANIZATION_EDIT: 'Organization Edit',
  ORGANIZATION_DELETE: 'Organization Delete',
  MEDIA_EDIT: 'Media Edit',
  MEDIA_DELETE: 'Media Delete',
  MEDIA_DETAILS_VIEW: 'Media Details View',
  MEDIA_DOWNLOAD: 'Media Download',
  LIBRARY_SORT_BY: 'Library Sort By',
  LIBRARY_VIEW: 'Library View',
  LIBRARY_SAVE_INITIATED: 'Library Save Initiated',
  LIBRARY_SAVE_TRIM: 'Library Save Trim',
  LIBRARY_SAVE_COMPLETE: 'Library Save Complete'
};
