import { FacebookContextType } from '../../../contexts/eventanalytics/facebook';
import axios from 'axios';
import {
  baseURL,
  fixedDateRangeValues,
  MAX_FETCH_LIMIT_FOR_CSV_EXPORT,
  PAGE_VALUE,
  TIME_WINDOW_VALUES,
} from '../event-analytics-constants';
import { API_RESPONSE_TYPE, BracketReference, CsvDataObject } from '../types';
import { DateTime, DurationObjectUnits } from 'luxon';
import { EventAnalyticsState } from '../../../contexts/eventanalytics/event-analytics';
import { dateTimeFormatUTC } from '../date-range-options';
import { EventsDataAPIResponseType } from '../../../components/ViewEvents/view-events';

export const fetchFacebookData = async (
  EventAnalyticsState: EventAnalyticsState,
  destinationType: string | null
): Promise<BracketReference<number | null>[]> => {
  const {
    customerId,
    token,
    eventId,
    dateRange: { startDate, endDate },
    dateRangeOption,
  } = EventAnalyticsState;
  const SummaryStatsAPI = `${baseURL}/${customerId}/facebook/statistics/summary`;
  const dateRangeValue = dateRangeOption.value.toString();
  const isFullMonth = fixedDateRangeValues.includes(dateRangeValue);
  try {
    const { data } = await axios.get(SummaryStatsAPI, {
      headers: token,
      params: {
        startDate: DateTime.fromISO(startDate).startOf('day').toFormat(dateTimeFormatUTC),
        endDate: DateTime.fromISO(endDate).endOf('day').toFormat(dateTimeFormatUTC),
        isFullMonth,
        ...(eventId && { eventId }),
        destinationType: destinationType,
      },
    });
    return data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const fetchDestinationTypeFromEventId = async (
  EventAnalyticsState: EventAnalyticsState
): Promise<EventsDataAPIResponseType[]> => {
  const { customerId, token, eventId } = EventAnalyticsState;
  const timeWindow = TIME_WINDOW_VALUES.DAY;
  const url = `${baseURL}/${customerId}/facebook/statistics/${timeWindow}/views/allEvents`;
  try {
    const { data } = await axios.get(url, {
      headers: token,
      params: {
        ...(eventId && { eventId }),
      },
    });
    return data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const fetchLineChartData = async (
  EventAnalyticsState: EventAnalyticsState,
  facebookAnalyticsState: FacebookContextType,
  timeWindowValue: string,
  dataPageName: string
): Promise<API_RESPONSE_TYPE> => {
  const {
    customerId,
    token,
    eventId,
    dateRange: { startDate, endDate },
  } = EventAnalyticsState;
  timeWindowValue = dataPageName === PAGE_VALUE.LIVE_VIEWS ? TIME_WINDOW_VALUES.MINUTE : timeWindowValue;
  const url = `${baseURL}/${customerId}/facebook/statistics/${timeWindowValue}/${dataPageName}`;
  try {
    const { data } = await axios.get(url, {
      headers: token,
      params: {
        startDate: DateTime.fromISO(startDate).startOf('day').toFormat(dateTimeFormatUTC),
        endDate: DateTime.fromISO(endDate).endOf('day').toFormat(dateTimeFormatUTC),
        ...(eventId && { eventId }),
        destinationType: facebookAnalyticsState.destinationType,
      },
    });
    let i = DateTime.fromISO(startDate)
      .startOf(timeWindowValue as keyof DurationObjectUnits)
      .minus({ day: timeWindowValue === 'week' ? 1 : 0 });
    let iFormatted = i.toFormat(timeWindowValue === 'day' ? `yyyy-MM-dd HH:mm:ss` : `yyyy-MM-dd`);
    const sanitizedData = [];

    do {
      sanitizedData.push(
        data.dates.find((d: { date: string }) => d.date === iFormatted) ?? {
          date: iFormatted,
        }
      );
      i = i.plus({ [`${timeWindowValue}s`]: 1 });
      iFormatted = i.toFormat(timeWindowValue === 'day' ? `yyyy-MM-dd HH:mm:ss` : `yyyy-MM-dd`);
    } while (
      timeWindowValue !== TIME_WINDOW_VALUES.MINUTE &&
      i <
        (timeWindowValue === 'day' ? DateTime.fromISO(endDate).endOf('day') : DateTime.fromISO(endDate).startOf('day'))
    );

    return timeWindowValue === TIME_WINDOW_VALUES.MINUTE ? data : { dates: sanitizedData };
  } catch (error) {
    console.error(error);
    return {};
  }
};

export const exportCSVData = async (
  EventAnalyticsState: EventAnalyticsState,
  facebookAnalyticsState: FacebookContextType,
  offset1?: string | number | undefined,
  offset2?: string | number | undefined
): Promise<CsvDataObject[]> => {
  const {
    customerId,
    eventId,
    token,
    dateRange: { startDate, endDate },
  } = EventAnalyticsState;
  const url = `${baseURL}/${customerId}/facebook/export`;
  let offset;
  if (offset1 && offset2) {
    offset = `${offset1},${offset2}`;
  }
  const defaultParams = {
    startDate: DateTime.fromISO(startDate).startOf('day').toFormat(dateTimeFormatUTC),
    endDate: DateTime.fromISO(endDate).endOf('day').toFormat(dateTimeFormatUTC),
    max: MAX_FETCH_LIMIT_FOR_CSV_EXPORT,
    destinationType: facebookAnalyticsState.destinationType,
  };
  const params = {
    ...defaultParams,
    ...(eventId && { eventId }),
    ...(offset && { offset }),
  };
  try {
    const { data } = await axios.get(url, {
      headers: token,
      params,
    });
    return data;
  } catch (error) {
    console.error(error);
    return [];
  }
};
