import 'angular-route';
import angular from 'angular';
import router from './module.contacts.routes';
import commonServicesModule from '../../services';

export const contactsModule = {
	name: 'contacts',
	controllers: {
		default: 'contactsCtrl',
	},
	routes: {
		list: '/contacts',
	},
};

export default angular
	.module(contactsModule.name, ['ngRoute', commonServicesModule])
	.config(router)
	.controller(contactsModule.controllers.default, require('./contactsCtrl')).name;
