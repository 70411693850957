import React, { ChangeEvent, FC, useContext, useState } from 'react';
import { ExpandAltOutlined, ExportOutlined } from '@ant-design/icons';
import { Paper } from '@material-ui/core';
import { Grid, Inline, Text, customProperties as vars } from '@resi-media/resi-ui';
import Link from '@material-ui/core/Link';
import Tooltip from '@material-ui/core/Tooltip';
import clone from 'lodash.clone';
import startCase from 'lodash/startCase';
import PropTypes from 'prop-types';
import {
  BarChart,
  DoughnutChart,
  FillBlockSpinner,
  ScrollableBarChart,
  VerticalTab,
  VerticalTabs,
  VirtualizedTable,
} from '../../../../components';
import Modal from '../../../../components/Modal';
import ModalHeader from '../../../../components/ModalHeader';
import { VirtualizedTableData } from '../../../../components/VirtualizedTable/types';
import { StreamContext } from '../../../../contexts/eventanalytics';
import { EventAnalyticsContext } from '../../../../contexts/eventanalytics/event-analytics';
import { MPEventName, MPEventProperty, trackMixpanelEvent } from '../../../../mixpanel';
import {
  CHART_TYPES,
  EXTRA_INFO,
  GROUP_VALUES,
  LEGEND_NAMES,
  LOADER_SIZE_COMPONENT,
  LOADER_SIZE_SUB_COMPONENT,
  locationTabHeaders,
  LOCATION_VALUES,
  SUB_LOCATIONS,
  SUB_LOCATIONS_ENUM,
  tabHeadersStreamURL,
  TABLE_HEADER_STREAM,
  TAB_VALUES_STREAM,
  TOOLTIP_LABEL,
} from '../../event-analytics-constants';
import { ExpandButtonWrapper, LinkButtonContainer } from '../../event-analytics.styles';
import {
  barChartDataType,
  doughnutChartDataType,
  EVENT_TYPES,
  formatTableDataInputParamsType,
  LOCATION_TAB_VALUES,
  STREAM_EXPANDBTN_COMBINATIONS_TYPE,
  STREAM_TAB_VALUES,
} from '../../types';
import { convertTableToCSV, downloadAsCSV, getCSVData, getLocationCSVData, renameKey } from '../../utils';
import { fetchData } from '../api';
import {
  ChartContent,
  ChartWrapper,
  ExpandChartWrapper,
  HeaderDiv,
  HorizontalLine,
  LocationTabsDiv,
  ModalWrapper,
  SegmentedStatsModalContent,
  SegmentedStatsWrapper,
  TableContent,
} from './stream-segmented-stats.styles';

interface StreamSegmentedStatsViewPropType {
  eventValue: EVENT_TYPES;
  tabValue: STREAM_TAB_VALUES;
  locationTabValue: LOCATION_TAB_VALUES;
  onTabChange: (event: ChangeEvent<unknown>, newValue: STREAM_TAB_VALUES) => void;
  chartData: doughnutChartDataType[] | barChartDataType;
  top10LocationChartData?: doughnutChartDataType[] | barChartDataType;
  locationOnTabChange: (event: ChangeEvent<unknown>, newValue: LOCATION_TAB_VALUES) => void;
  showLocationTab: boolean;
  isChartLoading: boolean;
  expandChartOpen: boolean;
  setExpandChartOpen: (value: boolean) => void;
}

const StreamSegmentedStatsView: FC<StreamSegmentedStatsViewPropType> = ({
  eventValue,
  tabValue,
  onTabChange,
  chartData,
  top10LocationChartData,
  locationTabValue,
  locationOnTabChange,
  showLocationTab,
  isChartLoading,
  expandChartOpen,
  setExpandChartOpen,
}): JSX.Element => {
  const { EventAnalyticsState } = useContext(EventAnalyticsContext);
  const { streamAnalyticsState } = useContext(StreamContext);
  const [isExportCSVLoading, setExportCSVLoading] = useState<boolean>(false);
  let tableDataCSV = '';
  let legendCount = 0;

  const formatTableData = ({
    inputChartData,
    chartType,
    groupBy,
    segmentBy,
  }: formatTableDataInputParamsType): VirtualizedTableData => {
    let tableData: VirtualizedTableData = { header: [], data: [] };

    const formatDoughnutTableData = (inputChartData: doughnutChartDataType[]): VirtualizedTableData => {
      const doughnutTableData: VirtualizedTableData = { header: [], data: [] };
      doughnutTableData.header = [
        { label: segmentBy?.toLowerCase().replace(/^[a-zA-z]|\s(.)/gi, (L) => L.toUpperCase()) },
        { label: 'Viewers' },
      ];
      inputChartData?.map((item: doughnutChartDataType) => {
        doughnutTableData.data.push({ row: [item.label, item.data] });
      });
      return doughnutTableData;
    };

    const formatBarTableData = (inputChartData: barChartDataType): VirtualizedTableData => {
      const barTableData: VirtualizedTableData = { header: [], data: [] };
      const labels: string[] = inputChartData.labels;
      const legends: string[] = Object.keys(inputChartData.datasets);
      const extraInfo: string[] = inputChartData[EXTRA_INFO];
      groupBy === GROUP_VALUES.NONE
        ? (barTableData.header = [{ label: segmentBy }, { label: 'Viewers' }])
        : (barTableData.header = [
            {
              label: groupBy
                .toLowerCase()
                .replace(/^[a-zA-z]|\s(.)/gi, (L) => L.toUpperCase())
                .replace('_type', ''),
            },
            { label: segmentBy },
            { label: 'Viewers' },
          ]);
      for (let i = 0; i < labels.length; i++) {
        for (let j = 0; j < legends.length; j++) {
          if (groupBy === GROUP_VALUES.NONE) {
            barTableData.data.push({ row: [labels[i], Number(inputChartData.datasets[legends[j]][i])] });
          } else {
            barTableData.data.push({
              row: [
                startCase(legends[j]),
                inputChartData[EXTRA_INFO].length > 0 ? `${labels[i]}, ${extraInfo[i]}` : labels[i],
                Number(inputChartData.datasets[legends[j]][i]),
              ],
            });
          }
        }
      }
      return barTableData;
    };

    if (inputChartData !== null) {
      if (chartType === CHART_TYPES.DOUGHNUT) {
        tableData = formatDoughnutTableData(inputChartData as doughnutChartDataType[]);
      } else if (chartType === CHART_TYPES.BAR) {
        tableData = formatBarTableData(inputChartData as barChartDataType);
      }
    }
    tableDataCSV = convertTableToCSV(
      tableData.header,
      tableData.data.map((rowData) => rowData.row)
    );
    return tableData;
  };

  const renderExpandBtnState = (
    eventValue: EVENT_TYPES,
    tabValue: STREAM_TAB_VALUES,
    locationTabValue: LOCATION_TAB_VALUES,
    showLocationTab: boolean
  ): boolean => {
    const expandBtnIsDisabledCombinations: STREAM_EXPANDBTN_COMBINATIONS_TYPE = {
      [GROUP_VALUES.NONE]: {
        [TAB_VALUES_STREAM.DEVICE_TYPE]: true,
        [TAB_VALUES_STREAM.LOCATION]: {
          [LOCATION_VALUES.COUNTRY]: false,
          [LOCATION_VALUES.REGION]: false,
          [LOCATION_VALUES.CITY]: false,
        },
      },
      [GROUP_VALUES.EVENT_TYPE]: {
        [TAB_VALUES_STREAM.DEVICE_TYPE]: true,
        [TAB_VALUES_STREAM.LOCATION]: {
          [LOCATION_VALUES.COUNTRY]: false,
          [LOCATION_VALUES.REGION]: false,
          [LOCATION_VALUES.CITY]: false,
        },
      },
      [GROUP_VALUES.DEVICE_TYPE]: {
        [TAB_VALUES_STREAM.DEVICE_TYPE]: true,
        [TAB_VALUES_STREAM.LOCATION]: {
          [LOCATION_VALUES.COUNTRY]: false,
          [LOCATION_VALUES.REGION]: false,
          [LOCATION_VALUES.CITY]: false,
        },
      },
    };
    if (!showLocationTab && tabValue !== TAB_VALUES_STREAM.LOCATION) {
      return expandBtnIsDisabledCombinations[eventValue][tabValue];
    } else {
      return expandBtnIsDisabledCombinations[eventValue][TAB_VALUES_STREAM.LOCATION][locationTabValue];
    }
  };

  type renderChartandTableType = {
    chartNormal: JSX.Element | null;
    chartExpanded: JSX.Element | null;
    table: JSX.Element | null;
  };
  const renderChartandTable = (): renderChartandTableType => {
    let chartNormal: JSX.Element, chartExpanded: JSX.Element, table: JSX.Element;
    const subLocations = [SUB_LOCATIONS.COUNTRY, SUB_LOCATIONS.REGION, SUB_LOCATIONS.CITY];

    chartNormal = <div />;
    chartExpanded = <div />;
    table = <div />;

    let doughnutChartData: doughnutChartDataType[] = [],
      top10LocationDoughnutChartData: doughnutChartDataType[] = [],
      barChartData: barChartDataType = { labels: [], extraInfo: [], datasets: {} },
      top10LocationBarChartData: barChartDataType = { labels: [], extraInfo: [], datasets: {} };
    if (chartData) {
      if (chartData.constructor === Array) {
        doughnutChartData = chartData;
        top10LocationDoughnutChartData = top10LocationChartData as doughnutChartDataType[];
        legendCount = top10LocationDoughnutChartData?.length;
        doughnutChartData.map((el) => (el.label = el.label in LEGEND_NAMES ? `${el.label}` : el.label));
      } else if (eventValue === GROUP_VALUES.NONE && tabValue === TAB_VALUES_STREAM.LOCATION) {
        top10LocationDoughnutChartData = top10LocationChartData as doughnutChartDataType[];
        legendCount = top10LocationDoughnutChartData?.length;
        barChartData = chartData as barChartDataType;
        Object.keys(barChartData.datasets).map((el) => {
          if (el in LEGEND_NAMES) {
            barChartData.datasets = renameKey(barChartData.datasets, el, `${el}`);
          }
        });
      } else {
        barChartData = chartData as barChartDataType;
        Object.keys(barChartData.datasets).map((el) => {
          if (el in LEGEND_NAMES) {
            barChartData.datasets = renameKey(barChartData.datasets, el, `${el}`);
          }
        });
        top10LocationBarChartData = top10LocationChartData as barChartDataType;
        legendCount = top10LocationBarChartData?.labels?.length;
        if (top10LocationBarChartData) {
          Object.keys(top10LocationBarChartData.datasets).map((el) => {
            if (el in LEGEND_NAMES) {
              top10LocationBarChartData.datasets = renameKey(top10LocationBarChartData.datasets, el, `${el}`);
            }
          });
        }
      }
    }
    chartNormal = <div />;
    chartExpanded = <div />;
    table = <div />;
    // None Type Combinations
    if (eventValue === GROUP_VALUES.NONE) {
      if (tabValue === TAB_VALUES_STREAM.DEVICE_TYPE) {
        // Device Type
        chartNormal = (
          <DoughnutChart
            key={`${eventValue}${tabValue}${locationTabValue}`}
            data={doughnutChartData}
            tooltipLabel={TOOLTIP_LABEL.VIEWERS}
            showPercentage
            isLegendOnRightSide
          />
        );
        table = (
          <VirtualizedTable
            data={formatTableData({
              inputChartData: doughnutChartData,
              chartType: CHART_TYPES.DOUGHNUT,
              groupBy: GROUP_VALUES.NONE,
              segmentBy: TABLE_HEADER_STREAM.DEVICE_TYPE,
            })}
          />
        );
      } else if (tabValue === TAB_VALUES_STREAM.LOCATION) {
        // Location
        chartNormal = (
          <DoughnutChart
            key={`${eventValue}${tabValue}${locationTabValue}`}
            data={top10LocationDoughnutChartData}
            tooltipLabel={TOOLTIP_LABEL.VIEWERS}
            showPercentage={false}
            isLegendOnRightSide
          />
        );
        if (expandChartOpen) {
          chartExpanded = (
            <ScrollableBarChart
              key={`${eventValue}${tabValue}${locationTabValue}`}
              data={barChartData}
              stacked={false}
              tooltipLabel="Viewers"
            />
          );
        }
        table = (
          <VirtualizedTable
            data={formatTableData({
              inputChartData: top10LocationDoughnutChartData,
              chartType: CHART_TYPES.DOUGHNUT,
              groupBy: GROUP_VALUES.NONE,
              segmentBy: subLocations[locationTabValue],
            })}
          />
        );
      }
    }
    // Event Type Combinations
    else if (eventValue === GROUP_VALUES.EVENT_TYPE) {
      if (tabValue === TAB_VALUES_STREAM.DEVICE_TYPE) {
        // Device Type
        chartNormal = (
          <ChartContent>
            <BarChart
              key={`${eventValue}${tabValue}${locationTabValue}${barChartData?.labels?.length}`}
              data={barChartData}
              stacked
              tooltipLabel="Viewers"
            />
          </ChartContent>
        );
        table = (
          <VirtualizedTable
            data={formatTableData({
              inputChartData: barChartData,
              chartType: CHART_TYPES.BAR,
              groupBy: GROUP_VALUES.EVENT_TYPE,
              segmentBy: TABLE_HEADER_STREAM.DEVICE_TYPE,
            })}
          />
        );
      } else if (tabValue === TAB_VALUES_STREAM.LOCATION) {
        // Location
        chartNormal = (
          <ChartContent>
            <BarChart
              key={`${eventValue}${tabValue}${locationTabValue}`}
              data={top10LocationBarChartData}
              stacked
              tooltipLabel="Viewers"
            />
          </ChartContent>
        );
        const findLabelsLength: barChartDataType = clone(barChartData);
        if (expandChartOpen && findLabelsLength.labels.length > 10) {
          chartExpanded = (
            <ScrollableBarChart
              key={`${eventValue}${tabValue}${locationTabValue}`}
              data={barChartData}
              stacked
              tooltipLabel="Viewers"
            />
          );
        } else if (expandChartOpen) {
          chartExpanded = (
            <ChartContent>
              <BarChart
                key={`${eventValue}${tabValue}${locationTabValue}`}
                data={barChartData}
                stacked
                tooltipLabel="Viewers"
              />
            </ChartContent>
          );
        }
        table = (
          <VirtualizedTable
            data={formatTableData({
              inputChartData: top10LocationBarChartData,
              chartType: CHART_TYPES.BAR,
              groupBy: GROUP_VALUES.EVENT_TYPE,
              segmentBy: subLocations[locationTabValue],
            })}
          />
        );
      }
    }

    // Device Type Combinations
    else if (eventValue === GROUP_VALUES.DEVICE_TYPE) {
      if (tabValue === TAB_VALUES_STREAM.DEVICE_TYPE) {
        // Device Type
        chartNormal = (
          <DoughnutChart
            key={`${eventValue}${tabValue}${locationTabValue}`}
            data={doughnutChartData}
            tooltipLabel={TOOLTIP_LABEL.VIEWERS}
            showPercentage
            isLegendOnRightSide
          />
        );
        table = (
          <VirtualizedTable
            data={formatTableData({
              inputChartData: doughnutChartData,
              chartType: CHART_TYPES.DOUGHNUT,
              groupBy: GROUP_VALUES.DEVICE_TYPE,
              segmentBy: TABLE_HEADER_STREAM.DEVICE_TYPE,
            })}
          />
        );
      } else if (tabValue === TAB_VALUES_STREAM.LOCATION) {
        // Location
        chartNormal = (
          <ChartContent>
            <BarChart key={10} data={top10LocationBarChartData} stacked tooltipLabel="Viewers" />
          </ChartContent>
        );
        const findLabelsLength: barChartDataType = clone(barChartData);
        if (findLabelsLength.labels.length > 10) {
          chartExpanded = (
            <ScrollableBarChart
              key={`${eventValue}${tabValue}${locationTabValue}`}
              data={barChartData}
              stacked
              tooltipLabel="Viewers"
            />
          );
        } else {
          chartExpanded = (
            <ChartContent>
              <BarChart
                key={`${eventValue}${tabValue}${locationTabValue}`}
                data={barChartData}
                stacked
                tooltipLabel="Viewers"
              />
            </ChartContent>
          );
        }
        table = (
          <VirtualizedTable
            data={formatTableData({
              inputChartData: top10LocationBarChartData,
              chartType: CHART_TYPES.BAR,
              groupBy: GROUP_VALUES.DEVICE_TYPE,
              segmentBy: subLocations[locationTabValue],
            })}
          />
        );
      }
    }
    const dataRender: renderChartandTableType = {
      chartNormal: chartNormal,
      chartExpanded: chartExpanded,
      table: table,
    };
    return dataRender;
  };

  const exportLocationCSV = async () => {
    setExportCSVLoading(true);
    const locationData = await fetchData(
      EventAnalyticsState,
      SUB_LOCATIONS_ENUM[locationTabValue],
      streamAnalyticsState
    );
    const exportCSVData = getLocationCSVData(locationData, eventValue, locationTabValue);
    downloadAsCSV(exportCSVData);
    setExportCSVLoading(false);
  };

  const getExportCSVData = (eventValue: string, tabValue: number, tableDataCSV: string) => {
    const barChartData = chartData as barChartDataType;
    if (eventValue === GROUP_VALUES.NONE) {
      if (tabValue === TAB_VALUES_STREAM.LOCATION) {
        exportLocationCSV();
      } else {
        downloadAsCSV(tableDataCSV);
      }
    } else if (eventValue === GROUP_VALUES.EVENT_TYPE) {
      if (tabValue === TAB_VALUES_STREAM.DEVICE_TYPE) {
        const headersArray = ['Device Type', 'Live', 'simlive', 'onDemand'];
        getCSVData(headersArray, barChartData);
      } else {
        exportLocationCSV();
      }
    } else if (eventValue === GROUP_VALUES.DEVICE_TYPE) {
      if (tabValue === TAB_VALUES_STREAM.LOCATION) {
        exportLocationCSV();
      } else {
        downloadAsCSV(tableDataCSV);
      }
    } else {
      downloadAsCSV(tableDataCSV);
    }

    const mixpanelProps = {
      [MPEventProperty.TRANSCODED_EVENT_UUID]: EventAnalyticsState.eventId,
      [MPEventProperty.CSV_DETAIL]: tabValue === 0 ? 'Device Type' : 'Location',
      [MPEventProperty.ANALYTICS_TYPE]: 'Stream URL',
    };

    if (!EventAnalyticsState.eventId) {
      delete mixpanelProps[MPEventProperty.TRANSCODED_EVENT_UUID];
    }

    trackMixpanelEvent(MPEventName.CSV_EXPORT, mixpanelProps);
  };

  const {
    chartNormal: renderChartNormal,
    chartExpanded: renderChartExpanded,
    table: renderTable,
  } = renderChartandTable();
  const chartNormal: JSX.Element | null = renderChartNormal;
  const chartExpanded: JSX.Element | null = renderChartExpanded;
  const table: JSX.Element | null = renderTable;
  const isExpandDisabled = renderExpandBtnState(eventValue, tabValue, locationTabValue, showLocationTab);
  return (
    <>
      <Paper elevation={0} variant="outlined">
        <SegmentedStatsWrapper>
          <HeaderDiv>
            <Text.Heading type="h4" customCss={`color: ${vars.colorAccent800};`}>
              {showLocationTab
                ? `Top ${legendCount} ${locationTabHeaders[locationTabValue]}`
                : `${tabHeadersStreamURL[tabValue]}`}
            </Text.Heading>
            <Inline justifyContent="flex-end" alignItems="center">
              <>
                <Tooltip
                  title={
                    <h6>
                      {isExportCSVLoading ? 'Please wait, your file is being prepared.' : 'Download all data as CSV'}
                    </h6>
                  }
                  arrow
                  placement="top"
                >
                  <Link
                    component="button"
                    underline="none"
                    variant="body2"
                    disabled={isExportCSVLoading}
                    onClick={() => getExportCSVData(eventValue, tabValue, tableDataCSV)}
                  >
                    <LinkButtonContainer>
                      <ExportOutlined />
                      <span style={{ marginLeft: '5px' }}>Export CSV</span>
                    </LinkButtonContainer>
                  </Link>
                </Tooltip>
                {isExportCSVLoading ? <FillBlockSpinner fontSize={LOADER_SIZE_SUB_COMPONENT} /> : null}
              </>
            </Inline>
          </HeaderDiv>
          <Grid display="grid" gridTemplateColumns="2fr 6fr 4fr">
            <Grid.Item>
              <VerticalTabs orientation="vertical" value={tabValue} onChange={onTabChange}>
                <VerticalTab label="Device Type" />
                <VerticalTab label="Location" />
              </VerticalTabs>
              {showLocationTab && (
                <>
                  <HorizontalLine />
                  <LocationTabsDiv>
                    <VerticalTabs orientation="vertical" value={locationTabValue} onChange={locationOnTabChange}>
                      <VerticalTab label="Country" />
                      <VerticalTab label="Region" />
                      <VerticalTab label="City" />
                    </VerticalTabs>
                  </LocationTabsDiv>
                </>
              )}
            </Grid.Item>
            {isChartLoading ? (
              <FillBlockSpinner fontSize={LOADER_SIZE_COMPONENT} />
            ) : (
              <>
                <Grid.Item>
                  <ChartWrapper>
                    <ChartContent>{chartNormal}</ChartContent>
                    <ExpandButtonWrapper>
                      <Tooltip
                        title={<h6>{isExpandDisabled ? 'Data is not Expandable' : 'Click expand to see all'}</h6>}
                        arrow
                        placement="top"
                      >
                        <div style={{ ...(isExpandDisabled && { opacity: '0.4' }) }}>
                          <Link
                            component="button"
                            underline="none"
                            variant="body2"
                            onClick={(): void => setExpandChartOpen(true)}
                            disabled={isExpandDisabled}
                          >
                            <LinkButtonContainer>
                              <ExpandAltOutlined />
                              <span style={{ marginLeft: '5px' }}>Expand</span>
                            </LinkButtonContainer>
                          </Link>
                        </div>
                      </Tooltip>
                    </ExpandButtonWrapper>
                  </ChartWrapper>
                </Grid.Item>
                <Grid.Item>
                  <Inline justifyContent="flex-end">
                    <TableContent>{table}</TableContent>
                  </Inline>
                </Grid.Item>
              </>
            )}
          </Grid>
        </SegmentedStatsWrapper>
      </Paper>
      {expandChartOpen && (
        <Modal width="fit-content">
          <Paper elevation={0}>
            <ModalWrapper>
              <ModalHeader
                titleText={showLocationTab ? locationTabHeaders[locationTabValue] : tabHeadersStreamURL[tabValue]}
                closeButton
                onClose={() => setExpandChartOpen(false)}
              />
              <SegmentedStatsModalContent>
                <ExpandChartWrapper>
                  <ChartContent>
                    {isChartLoading ? <FillBlockSpinner fontSize={LOADER_SIZE_COMPONENT} /> : chartExpanded}
                  </ChartContent>
                </ExpandChartWrapper>
              </SegmentedStatsModalContent>
            </ModalWrapper>
          </Paper>
        </Modal>
      )}
    </>
  );
};

StreamSegmentedStatsView.propTypes = {
  eventValue: PropTypes.oneOf<EVENT_TYPES>([GROUP_VALUES.NONE, GROUP_VALUES.EVENT_TYPE, GROUP_VALUES.DEVICE_TYPE])
    .isRequired,
  tabValue: PropTypes.oneOf<STREAM_TAB_VALUES>([0, 1]).isRequired,
  onTabChange: PropTypes.func.isRequired,
  locationTabValue: PropTypes.oneOf<LOCATION_TAB_VALUES>([0, 1, 2]).isRequired,
  isChartLoading: PropTypes.bool.isRequired,
  chartData: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.exact({
        labels: PropTypes.string.isRequired,
        tableLabels: PropTypes.string,
        data: PropTypes.number,
      })
    ).isRequired,
    PropTypes.objectOf(
      PropTypes.exact({
        labels: PropTypes.arrayOf(PropTypes.string),
        extraInfo: PropTypes.arrayOf(PropTypes.string),
        datasets: PropTypes.objectOf(
          PropTypes.exact({
            Live: PropTypes.arrayOf(PropTypes.number),
            'Sim-Live': PropTypes.arrayOf(PropTypes.number),
            'On-Demand': PropTypes.arrayOf(PropTypes.number),
            Desktop: PropTypes.arrayOf(PropTypes.number),
            Mobile: PropTypes.arrayOf(PropTypes.number),
            Other: PropTypes.arrayOf(PropTypes.number),
            live: PropTypes.arrayOf(PropTypes.number),
            simLive: PropTypes.arrayOf(PropTypes.number),
            onDemand: PropTypes.arrayOf(PropTypes.number),
            desktop: PropTypes.arrayOf(PropTypes.number),
            mobile: PropTypes.arrayOf(PropTypes.number),
            other: PropTypes.arrayOf(PropTypes.number),
          }).isRequired
        ).isRequired,
      }).isRequired
    ).isRequired,
  ]).isRequired,
  top10LocationChartData: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.exact({
        labels: PropTypes.string.isRequired,
        tableLabels: PropTypes.string,
        data: PropTypes.number,
      })
    ).isRequired,
    PropTypes.objectOf(
      PropTypes.exact({
        labels: PropTypes.arrayOf(PropTypes.string),
        extraInfo: PropTypes.arrayOf(PropTypes.string),
        datasets: PropTypes.objectOf(
          PropTypes.exact({
            Live: PropTypes.arrayOf(PropTypes.number),
            'Sim-Live': PropTypes.arrayOf(PropTypes.number),
            'On-Demand': PropTypes.arrayOf(PropTypes.number),
            Desktop: PropTypes.arrayOf(PropTypes.number),
            Mobile: PropTypes.arrayOf(PropTypes.number),
            Other: PropTypes.arrayOf(PropTypes.number),
            live: PropTypes.arrayOf(PropTypes.number),
            simLive: PropTypes.arrayOf(PropTypes.number),
            onDemand: PropTypes.arrayOf(PropTypes.number),
            desktop: PropTypes.arrayOf(PropTypes.number),
            mobile: PropTypes.arrayOf(PropTypes.number),
            other: PropTypes.arrayOf(PropTypes.number),
          })
        ),
      })
    ),
  ]).isRequired,
  locationOnTabChange: PropTypes.func.isRequired,
  showLocationTab: PropTypes.bool.isRequired,
};

StreamSegmentedStatsView.displayName = 'StreamSegmentedStatsView';

export default StreamSegmentedStatsView;
