import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { customProperties as vars } from '@resi-media/resi-ui';

export const HeatMapWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 800px;
  padding: ${vars.spacingXl};
`;

export const HeaderWrapper = styled.div`
  margin-bottom: ${vars.spacingM};
`;

export const MapWrapper = styled.div`
  position: relative;
  display: flex;
  top: 0;
  justify-content: center;
  width: 100%;
  height: 90%;
`;

export const ControlsWrapper = styled.div`
  position: absolute;
  display: flex;
  width: fit-content;
  box-sizing: border-box;
  top: 10px;
  padding: ${vars.spacingS};
  align-items: center;
  background: ${vars.colorSurface};
  border-radius: ${vars.borderRadius2};
  border: 0.5px solid ${vars.borderColorForInput};
  z-index: 10;
`;

export const spaceRight = css`
  margin-right: ${vars.spacingM};
`;
