import React, { FC } from 'react';
import PropTypes from 'prop-types';
import { Loading3QuartersOutlined } from '@ant-design/icons';
import { customProperties as vars } from '@resi-media/resi-ui';
import ResiLayout from '../ResiLayout';
import { BoxHeader, BoxWrap, BoxDescription } from '../Styled';
import { SpinnerWrapper } from './style';
import BlockError from '../BlockError';

export interface ErrorType {
  errorMessage: string;
  errorLinkText?: string | null;
  onClick?: (() => void) | null;
}

interface LoadingProps {
  header: string;
  subheader: string;
  error?: ErrorType;
}

const Loading: FC<LoadingProps> = ({ header, subheader, error }) => {
  return (
    <ResiLayout>
      <BoxWrap>
        <BoxHeader>{header}</BoxHeader>
        <BoxDescription>{subheader}</BoxDescription>
        <SpinnerWrapper>
          {error?.errorMessage ? (
            <BlockError errorLinkText={error.errorLinkText} onClick={error.onClick}>
              {error.errorMessage}
            </BlockError>
          ) : (
            <Loading3QuartersOutlined spin style={{ fontSize: '3em', color: vars.colorPrimary }} />
          )}
        </SpinnerWrapper>
      </BoxWrap>
    </ResiLayout>
  );
};

Loading.displayName = 'Loading';
Loading.propTypes = {
  header: PropTypes.string.isRequired,
  subheader: PropTypes.string.isRequired,
  error: PropTypes.shape({
    errorMessage: PropTypes.string.isRequired,
    errorLinkText: PropTypes.string,
    onClick: PropTypes.func,
  }),
};

export default Loading;
