import { createTheme } from '@material-ui/core/styles';

const typography = {
  fontFamily: [
    'SF Pro Display',
    '-apple-system',
    'Open Sans',
    'Cabin',
    'Cabin-Bold',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(','),
  overline: {
    fontFamily: 'Open Sans',
    fontWeight: 600,
    lineHeight: '12px',
    fontSize: '12px',
  },
};

const overrides = {
  MuiTab: {
    root: {
      minHeight: 0,
      textTransform: 'inherit',
      fontSize: 'inherit',
      '@media (min-width: 600px)': {
        minWidth: 0,
      },
    },
    wrapper: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
    },
    labelIcon: {
      minHeight: 'fit-content',
      marginRight: '0 !important',
    },
  },
  MuiTabs: {
    root: {
      minWidth: 0,
      minHeight: 0,
    },
  },
};

const resiTheme = {
  overrides,
  typography,
};

const materialTheme = createTheme(resiTheme);

export default materialTheme;
