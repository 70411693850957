import { AUTH_SYNTHETIC_TOGGLES } from "../../constants";
import { api } from "../../jcs";
import HttpService from "../../services/http.service";

export async function refreshSyntheticToggles($http: HttpService, customerId: string): Promise<string[]> {
  try {
    const {
      data: facebookFeatures,
    } = await $http.get(`${api.url_v3}/customers/${customerId}/detectedfeatures/facebook`, { withCredentials: true });

    if (!facebookFeatures) {
      return;
    }

    const syntheticToggles = facebookFeatures.enabled ? ['facebook'] : [];
    sessionStorage.setItem(AUTH_SYNTHETIC_TOGGLES, JSON.stringify(syntheticToggles));
    return syntheticToggles;
  } catch (e) {
    console.error(e);
  }
}
