import { api } from '../jcs';

class TimeZoneService {

	constructor (httpService, $q) {
		'ngInject';

		this.httpService = httpService;
		this.$q = $q;

		this.aliases = {};
		this.aliases["America/Chicago"] =  				"US/Central";
		this.aliases["America/Denver"] =  				"US/Mountain";
		this.aliases["America/Indiana/Indianapolis"] = 	"US/East-Indiana";
		this.aliases["America/Los_Angeles"] = 			"US/Pacific";
		this.aliases["America/New_York"] = 				"US/Eastern";
		this.aliases["America/Phoenix"] = 				"US/Arizona";
		this.aliases["America/Anchorage"] = 			"US/Alaska";
		this.aliases["Pacific/Honolulu"] = 				"US/Hawaii";

		this.time_zone_list = null;
	}

	getTimeZones () {

		var deferred = this.$q.defer();
		var self = this;

		// have our time zones already been loaded?
		if (this.time_zone_list != null){

			deferred.resolve(this.time_zone_list);

		} else {

			this.httpService.get(api.url + '/timezones', { withCredentials: true },
				function (response) { // success

					var zone_list = response.data;

					// add a formatted "alias" field to our timezone list
					for (var i=0; i < zone_list.length; i++){
						var timezone = zone_list[i];
						// see if time zone has an alias; will use for building formatted name, and also with sorting
						timezone.alias = self.getTimeZoneAlias(timezone.name);
						// build our formatted name, which includes the offset
						timezone.formatted = "(GMT" + timezone.offset + ") " + timezone.alias;
						// add upper case name to help with checking for matches
						timezone.name_upper = timezone.name.toUpperCase();
					}

					// since we've swapped out some time zone names with aliases, resort the list so it is alphabetical within each offset
					zone_list.sort(self.compare);

					self.time_zone_list = zone_list;

					deferred.resolve(self.time_zone_list);

				},
				function (reason) { // error

					deferred.reject(reason);

				},
				function () { // always called
				});
		}

		return deferred.promise;

	}

	getTimeZoneAlias (time_zone) {
		// if we have an alias then return it, otherwise just give back the time zone we were given
		return this.aliases.hasOwnProperty(time_zone) ? this.aliases[time_zone] : time_zone;
	}

	getFormattedTimeZone () {

		if (typeof time_zone != 'undefined' && this.time_zone_list != null) {
			for (var i = 0; i < this.time_zone_list.length; i++) {
				var tz_info = this.time_zone_list[i];
				if (tz_info.name == time_zone) {
					return tz_info.formatted;
				}
			}
		}
		// if no match was found, then return empty string
		return '';
	}

	// sorts time zones alphabetically within each offset
	compare (a, b) {

		// convert the offset into a number we can compare; swapping colon for decimal should work fine for offsets like 09:30
		var a_offset = parseFloat(a.offset.replace(':', '.'));
		var b_offset = parseFloat(b.offset.replace(':', '.'));

		if (a_offset < b_offset){
			return -1;
		}
		if (a_offset > b_offset){
			return 1;
		}
		// offsets are equal, so compare alias
		if (a.alias < b.alias) {
			return -1;
		}
		if (a.alias > b.alias) {
			return 1;
		}
		// a must be equal to b
		return 0;
	}

}

export default TimeZoneService;
