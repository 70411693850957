import { timingSafeEqual } from 'crypto';

const moment = require('moment');

class DateCtrl {

	constructor () {
		'ngInject';

		this.DEFAULT_FORMAT_IN = 'YYYY-MM-DD';
		this.DEFAULT_FORMAT_OUT = 'll'; // Sep 24, 2018

		this.date_formatted = '';
	}

	getFormattedDate (value, format_in, format_out) {

		if (value){
			format_in = format_in || this.DEFAULT_FORMAT_IN;
			format_out = format_out || this.DEFAULT_FORMAT_OUT;
			return moment(value, format_in).format(format_out);
		}
		return '';
	}

	$onInit () {
		this.date_formatted = this.getFormattedDate(this.value, this.formatIn, this.formatOut);
	}

	$onChanges (changes) {
		this.date_formatted = this.getFormattedDate(this.value, this.formatIn, this.formatOut);
	}
}

// example usage:
// <date value="startDate" format-in="YYYY-MM-DD" format-out="ll"></date>
//
// format-in: is the format of the date you are providing
// format-out: the format in which the provided date should be displayed
//
// for formatting options see: http://momentjs.com/
//
export default class DateComponent {
	constructor() {
		this.template = require('./date.html').default;
		this.bindings = {
			value: '<',
			formatIn: '@',
			formatOut: '@',
		};
		this.controller = DateCtrl;
	}
}
