import React, { FC, ChangeEvent, useState, useContext, useEffect } from 'react';
import * as LDClient from 'launchdarkly-js-client-sdk';
import { LineChartOutlined } from '@ant-design/icons';
import { Text, Grid, customProperties as vars } from '@resi-media/resi-ui';
import { NavTabs, NavTab, InfoBox } from '../../components';
import { DesktopOutlined, LinkOutlined } from '@ant-design/icons';
import { AnalyticsTitleIcon, AnalyticsTitleWrapper, EventAnalyticsWrapper } from './event-analytics.styles';
import { EmbedPlayerAnalytics } from './EmbedPlayer';
import { StreamAnalytics } from './StreamAnalytics';
import { YoutubeAnalytics } from './YouTubeAnalytics';
import { FacebookAnalytics } from './FacebookAnalytics';
import { EventAnalyticsContext } from '../../contexts/eventanalytics/event-analytics';
import EventHeaderPill from '../../components/EventHeaderPill/event-header-pill';
import { EVENT_ANALYTICS_CONTEXT_KEYS } from './event-analytics-constants';
import { fetchEventNamesFromEventIdList } from '../../components/ViewEvents/api';
import { MPEventName, MPEventProperty, trackMixpanelEvent } from '../../mixpanel';
import { dayRangeOptions, dateRangeValues } from './date-range-options';

const PageValues = {
  EMBED_PLAYER: 'embed-player',
  STREAM_URL: 'stream-url',
  YOUTUBE: 'youtube',
  FACEBOOK: 'facebook',
};

interface EventAnalyticsViewProps {
  ldFlags?: LDClient.LDFlagSet;
}

const EventAnalyticsView: FC<EventAnalyticsViewProps> = ({ ldFlags }) => {
  const [tabValue, setTabValue] = useState<string>('embed-player');
  const [isEventNameLoading, setIsEventNameLoading] = useState<boolean>(false);
  const [isInfoBoxDisplayed, setInfoBoxDisplayStatus] = useState<boolean>(true);
  const [eventHeaderLabelValue, setEventHeaderValue] = useState<string>('');
  const {
    EventAnalyticsState: { eventId, customerId, dateRangeOption },
    dispatch,
  } = useContext(EventAnalyticsContext);
  const onNavTabChange = (event: ChangeEvent<unknown>, value: string) => {
    if (value === 'youtube') {
      if (!['7', '28'].includes(dateRangeOption?.value)) {
        dispatch({ type: EVENT_ANALYTICS_CONTEXT_KEYS.DATE_RANGE, payload: dateRangeValues['28'] });
        dispatch({
          type: EVENT_ANALYTICS_CONTEXT_KEYS.DATE_RANGE_VALUE,
          payload: dayRangeOptions.find((v) => v.value === '28'),
        });
      }
    }
    setTabValue(value);
    sendViewMixpanelEvent(value);
  };

  const sendViewMixpanelEvent = (value: string) => {
    let analyticsType = '';

    switch (value) {
      case 'all-destination':
        analyticsType = 'All Destinations';
        break;
      case 'embed-player':
        analyticsType = 'Embed Player';
        break;
      case 'stream-url':
        analyticsType = 'Stream URL';
        break;
      case 'youtube':
        analyticsType = 'YouTube';
        break;
      case 'facebook':
        analyticsType = 'Facebook';
        break;
    }

    const mixpanelProps = {
      [MPEventProperty.TRANSCODED_EVENT_UUID]: eventId,
      [MPEventProperty.ANALYTICS_TYPE]: analyticsType,
    };

    if (!eventId) {
      delete mixpanelProps[MPEventProperty.TRANSCODED_EVENT_UUID];
    }

    trackMixpanelEvent(MPEventName.ANALYTICS_VIEW, mixpanelProps);
  };

  const displayPage = (value: string) => {
    const hasGraphPerms = true;
    switch (value) {
      case PageValues.EMBED_PLAYER:
        return <EmbedPlayerAnalytics hasGraphPerms={hasGraphPerms} />;
      case PageValues.STREAM_URL:
        return <StreamAnalytics hasGraphPerms={hasGraphPerms} />;
      case PageValues.YOUTUBE:
        return <YoutubeAnalytics hasGraphPerms={hasGraphPerms} />;
      case PageValues.FACEBOOK:
        return <FacebookAnalytics hasGraphPerms={hasGraphPerms} />;
      default:
        return <EmbedPlayerAnalytics hasGraphPerms={hasGraphPerms} />;
    }
  };

  const updateEventNameFromEventID = async (): Promise<void> => {
    setIsEventNameLoading(true);
    const [response] = await fetchEventNamesFromEventIdList([eventId || ''], customerId);
    if (response) {
      const { name: eventName } = response;
      if (eventName) {
        setEventHeaderValue(eventName);
      } else {
        setEventHeaderValue(`Event ID: ${eventId}`);
      }
      setIsEventNameLoading(false);
    } else {
      setEventHeaderValue(`Event ID: ${eventId}`);
      setIsEventNameLoading(false);
    }
  };

  const handleCloseEventHeader = (): void => {
    history.pushState({}, '', `event-analytics`);
    setEventHeaderValue('');
    dispatch({ type: EVENT_ANALYTICS_CONTEXT_KEYS.EVENT_ID, payload: null });
  };

  useEffect(() => {
    if (eventId) {
      updateEventNameFromEventID();
    }

    sendViewMixpanelEvent(tabValue);
  }, [eventId]);

  return (
    <EventAnalyticsWrapper>
      <Grid display="grid" style={{ padding: vars.spacingXl }} data-testid="analytics-page">
        <Grid display="grid" gridAutoColumns="1fr" gridTemplateColumns="repeat(3, auto)">
          <AnalyticsTitleWrapper>
            <AnalyticsTitleIcon data-testid="analytics-icon">
              <LineChartOutlined style={{ fontSize: vars.fontSize24, color: vars.colorAccent800 }} />
            </AnalyticsTitleIcon>
            <Text.Heading type="h4" customCss={`color: ${vars.colorAccent800};`} data-testid="analytics-page-header">
              Event Analytics
            </Text.Heading>
            {eventId ? (
              <EventHeaderPill
                isLoading={isEventNameLoading}
                eventHeaderLabel={eventHeaderLabelValue}
                onClose={handleCloseEventHeader}
              />
            ) : null}
          </AnalyticsTitleWrapper>
        </Grid>
        {isInfoBoxDisplayed && (
          <InfoBox
            closeButton
            infoText="We are currently working on a better experience in Analytics. Great things are coming soon!"
            onClose={() => setInfoBoxDisplayStatus(false)}
          />
        )}
        <Grid.Item>
          <NavTabs value={tabValue} onChange={onNavTabChange}>
            {/* Default Tab , Hence no permissions required */}
            <NavTab value={PageValues.EMBED_PLAYER} icon={<DesktopOutlined />} label="Embed Player" />
            <NavTab value={PageValues.STREAM_URL} icon={<LinkOutlined />} label="Stream URL" />
            {/* {ldFlags?.['analytics-you-tube'] && <NavTab value={PageValues.YOUTUBE} label="YouTube" />} */}
            <NavTab value={PageValues.FACEBOOK} label="Facebook" />
          </NavTabs>
        </Grid.Item>
        {displayPage(tabValue)}
      </Grid>
    </EventAnalyticsWrapper>
  );
};

EventAnalyticsView.displayName = 'EventAnalyticsView';

export default EventAnalyticsView;
