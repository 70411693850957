import { createContext, Context } from 'react';
import { EmailTokenValidation } from '../../pages/get-started/api';
import { User } from '../../constants';
import { ErrorType } from '../../components/Loading';

export enum GET_STARTED_PAGES {
  LOADING = 'LOADING',
  SET_PASSWORD = 'SET_PASSWORD',
  EVENT_PROFILES = 'EVENT_PROFILES',
}

export interface GetStartedAction<T> {
  payload: T;
  type: GET_STARTED_ACTIONS;
}

export interface GetStartedContextState {
  password: string;
  page: GET_STARTED_PAGES;
  encoderName: string;
  webEventProfileName: string;
  emailTokenValidation: EmailTokenValidation;
  user: User;
  error?: ErrorType;
}

export const initialGetStartedState: GetStartedContextState = {
  password: '',
  page: GET_STARTED_PAGES.LOADING,
  encoderName: 'Default Encoder Channel',
  webEventProfileName: 'Default Web Channel',
  emailTokenValidation: {
    adminUserId: '',
    adminUserEmail: '',
    passwordResetToken: '',
  },
  user: {
    userId: '',
    userName: '',
    userType: '',
    customerId: '',
    customerName: undefined,
    features: [],
    toggles: [],
    capabilities: [],
  },
  error: undefined,
};

interface GetStartedContextProps {
  state: GetStartedContextState;
  dispatch: React.Dispatch<GetStartedAction<any>>;
}

export enum GET_STARTED_ACTIONS {
  PAGE = 'PAGE',
  PASSWORD = 'PASSWORD',
  ENCODER_NAME = 'ENCODER_NAME',
  WEB_EVENT_PROF_NAME = 'WEB_EVENT_PROF_NAME',
  EMAIL_VALIDATION = 'EMAIL_VALIDATION',
  USER = 'USER',
  ERROR = 'ERROR',
}

export const dispatchers = {
  [GET_STARTED_ACTIONS.EMAIL_VALIDATION]: (
    state: GetStartedContextState,
    action: GetStartedAction<EmailTokenValidation>
  ): GetStartedContextState => {
    return { ...state, emailTokenValidation: action.payload };
  },
  [GET_STARTED_ACTIONS.PASSWORD]: (
    state: GetStartedContextState,
    action: GetStartedAction<string>
  ): GetStartedContextState => {
    return { ...state, password: action.payload };
  },
  [GET_STARTED_ACTIONS.PAGE]: (
    state: GetStartedContextState,
    action: GetStartedAction<GET_STARTED_PAGES>
  ): GetStartedContextState => {
    return { ...state, page: action.payload };
  },
  [GET_STARTED_ACTIONS.ENCODER_NAME]: (
    state: GetStartedContextState,
    action: GetStartedAction<string>
  ): GetStartedContextState => {
    return { ...state, encoderName: action.payload };
  },
  [GET_STARTED_ACTIONS.WEB_EVENT_PROF_NAME]: (
    state: GetStartedContextState,
    action: GetStartedAction<string>
  ): GetStartedContextState => {
    return { ...state, webEventProfileName: action.payload };
  },
  [GET_STARTED_ACTIONS.ERROR]: (
    state: GetStartedContextState,
    action: GetStartedAction<ErrorType>
  ): GetStartedContextState => {
    return { ...state, error: action.payload };
  },
  [GET_STARTED_ACTIONS.USER]: (
    state: GetStartedContextState,
    action: GetStartedAction<User>
  ): GetStartedContextState => {
    return { ...state, user: action.payload };
  },
};

export function getStartedReducer(
  state: GetStartedContextState,
  action: GetStartedAction<any>
): GetStartedContextState {
  return dispatchers[action.type](state, action);
}

const GetStartedContext: Context<GetStartedContextProps> = createContext<GetStartedContextProps>(
  {} as GetStartedContextProps
);

export default GetStartedContext;
