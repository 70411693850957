import React, { FC } from 'react';
import PropTypes from 'prop-types';
import './style.scss';
import { MAX_DURATION_HOURS_RELAXED, MAX_FILE_SIZE_GB_RELAXED } from '../EventUploadModal/eventUploadHelpers';

interface UploadFileRequirementsProps {
  visible: boolean;
}

const UploadFileRequirements: FC<UploadFileRequirementsProps> = ({ visible }): JSX.Element => (
  <div className="la1-upload-req" style={{ maxHeight: +visible * 185 }} data-testid="upload-requirements-wrapper">
    <div className="la1-upload-req-inner-wrap">
      <div className="la1-upload-req-header">File Requirements</div>
      <div className="la1-upload-req-container">
        <div className="la1-upload-req-item"><b>Video Codec:</b> H.264</div>
        <div className="la1-upload-req-item"><b>Number of Video Channels:</b> 1</div>
        <div className="la1-upload-req-item"><b>Number of Audio Channels:</b> 2</div>
        <div className="la1-upload-req-item"><b>Max Video Resolution:</b> 1920x1080</div>
        <div className="la1-upload-req-item" data-testid="upload-requirements-keyframe-interval"><b>Suggested Keyframe Interval:</b> 3.2 seconds</div>
        <div className="la1-upload-req-item"><b>Max Audio Bitrate:</b> 384 Kbps</div>
        <div className="la1-upload-req-item"><b>Scan Type:</b> Progressive (Required for Web)</div>
        <div className="la1-upload-req-item"><b>Constant Framerate:</b> 23.976, 24, 25, 29.97, 30, 50, 59.94, 60</div>
        <div className="la1-upload-req-item"><b>Audio Codec:</b> AAC-LE, AAC-HE</div>
        <div className="la1-upload-req-item"><b>Audio Sample Rate:</b> 48 kHz</div>
        <div className="la1-upload-req-item" data-testid="upload-requirements-max-bitrate"><b>Max Video Bitrate:</b>&nbsp;
            8 Mbps up to 30 fps / 12 Mbps up to 60 fps
        </div>
        <div className="la1-upload-req-item" data-testid="upload-requirements-max-duration"><b>Max Duration:</b> {MAX_DURATION_HOURS_RELAXED} hours</div>
        <div className="la1-upload-req-item" data-testid="upload-requirements-max-file-size"><b>Max File Size:</b> {MAX_FILE_SIZE_GB_RELAXED} GB</div>
      </div>
    </div>
  </div>
)

UploadFileRequirements.displayName = 'UploadFileRequirements';

UploadFileRequirements.propTypes = {
  visible: PropTypes.bool.isRequired,
};

export default UploadFileRequirements;
