'use strict';

// used so each copy to clipboard button will be guaranteed to have a unique ID attribute, so we can place multiple buttons on one page.
let copyToClipboardCounter = 1;

class CopyToClipboardButtonCtrl {

	constructor() {
		'ngInject';

		this.copyToClipboardButtonId = 'copy-to-clipboard-btn-' + copyToClipboardCounter++;
	}

	onClick = function () {
		this.copyToClipboard();
		this.showTooltip();
		if (this.onCopy){
			this.onCopy();
		}
	}

	onMouseOut = function () {
		this.hideTooltip();
	}

	onBlur = function () {
		this.hideTooltip();
	}

	copyToClipboard = function () {
		navigator.clipboard.writeText(this.textToCopy);
	}

	showTooltip = function () {
		// tooltip docs: https://getbootstrap.com/docs/3.4/javascript/#tooltips
		$(`#${this.copyToClipboardButtonId}`).tooltip({
			placement: 'top',
			title: 'Copied!',
		});
		$(`#${this.copyToClipboardButtonId}`).tooltip('show');
	}

	hideTooltip = function () {
		$(`#${this.copyToClipboardButtonId}`).tooltip('destroy');
	}
}

export default class CopyToClipboardButtonComponent {
	constructor() {
		this.template = require('./copyToClipboardButton.html').default;
		this.bindings = {
			textToCopy: '<',
			onCopy: '&',
		};
		this.controller = CopyToClipboardButtonCtrl;
	}
}
