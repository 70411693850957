'use strict';

const jcs = require('../../jcs');
const uploaderModule = require('./index').uploaderModule;

/*
Uploader page is a special login and registration page for ProPresenter. After a machine is registered it will show up in the encoders list.
This page will be shown in an embedded HTML window sized 700 x 700 pixels.
*/

function UploaderController($scope, $window, $location, $http, $routeParams, $q, $timeout, formValidationService, focus, socialMedia, webEventProfileService, Authentication) {
	'ngInject';

	const DEFAULT_DEST_GROUP_NAME = 'Default Destination Group';

	$scope.validation = formValidationService.init();
	$scope.social_media = socialMedia.init();

	$scope.unique_device_id = null;
	$scope.is_plugin_installed = $routeParams.pluginInstalled === '1';

	$scope.show_create_dest_group = false;
	$scope.show_registration = false;
	$scope.show_success = false;
	$scope.show_error = false;

	$scope.show_step_count = true;
	$scope.is_reregistration = false;

	$scope.destination_group_count = 0;
	$scope.load_destination_group_error = false;
	$scope.load_profiles_error = false;

	$scope.error_msg = '';
	$scope.encoder_name = '';

	$scope.event_profiles_list = null;
	$scope.web_event_profiles_list = null;
	$scope.social_media_acct_list = null;

	$scope.event_profile = null;
	$scope.web_event_profile = null;
	$scope.social_media_destinations = null;

	$scope.is_working = false;
	$scope.is_loading_dest_groups = false;
	$scope.is_loading_profiles = false;

	$scope.registration_error_msg = null;
	$scope.registration_no_licenses = false;

	// determine what page to show user
	if ($location.path() === uploaderModule.routes.success){
		$scope.show_success = true;
	} else if ($routeParams.hasOwnProperty('id') && $routeParams.id !== null){
		$scope.unique_device_id = $routeParams.id;
	} else {
		$scope.show_error = true;
		$scope.error_msg = 'Device identifier is required in order to sign in and register.';
	}

	$scope.isLoading = function (){
		return $scope.is_loading_dest_groups || $scope.is_loading_profiles;
	};

	$scope.updateSocialMediaAccountsStatus = function () {
		if ($scope.social_media_acct_list != null && $scope.social_media_acct_list.length > 0) {
			const acct_id_list = [];
			// add a "status" field for each account and queue up an API call to check the actual status; status will default to "OK"
			// until we know otherwise. We only want to warn the user about issues if we know for sure there is one.
			for (const acct of $scope.social_media_acct_list) {
				acct.status = $scope.social_media.STATUS_OK;
				acct.formatted_name = '[' + $scope.social_media.getTypeAsLabel(acct.type) + '] ' + acct.name;
				acct_id_list.push({
					id: acct.uuid,
					type: acct.type,
				});
			}

			$scope.social_media.checkAccountStatus(acct_id_list, Authentication.getCurrentUser().customerID, $scope.updateAcctStatus);
		}
	};

	// this is a callback we use with the socialMedia service (as it determines account status, it calls this method)
	$scope.updateAcctStatus = function (account_id, info) {
		for (const social_media_acct of $scope.social_media_acct_list) {
			if (social_media_acct.uuid === account_id) {
				social_media_acct.status = info.status;
				// update formatted name (which is used in dropdown) if account has a problem
				if (info.status !== $scope.social_media.STATUS_OK){
					social_media_acct.formatted_name += ` (${$scope.social_media.getSocialMediaStatusDesc(social_media_acct.status)})`;
				}
				return;
			}
		}
	};

	$scope.doesCreateDestGrpFailValidation = function () {

		this.dest_group_error_msg = null;
		this.validation.clear();

		// check required fields to ensure they aren't empty
		this.validation.checkForEmpty('destination_group_name', $scope.destination_group_name);
		this.validation.checkForEmpty('event_profile', $scope.event_profile);
		this.validation.checkForEmpty('web_event_profile', $scope.web_event_profile);

		const has_validation_error = this.validation.hasError();

		if (has_validation_error){
			this.dest_group_error_msg = 'Please specify a value for the highlighted fields.';
		}

		return has_validation_error;
	};

	$scope.createDestinationGroup = function () {

		$scope.dest_group_error_msg = null;

		// if we have form validation errors, then don't go any further
		if ($scope.doesCreateDestGrpFailValidation()){
			return;
		}

		const payload = {
			name: $scope.destination_group_name,
			enabled: true,
			streamProfileId: $scope.event_profile,
			webEventProfileId: $scope.web_event_profile,
			simulcasts: null,
		};

		if ($scope.social_media_destinations !== null && $scope.social_media_destinations.length > 0) {
			payload.simulcasts = $scope.social_media_destinations.map((s) => ({
				uuid: s.uuid,
				type: s.type,
				channelId: s.channelId,
				channelName: s.channelName,
				destinationId: s.destinationId,
				destinationName: s.destinationName,
				privacy: s.privacy,
				publishStatus: s.publishStatus,
				crossposts: s.crossposts,
				title: s.title,
				description: s.description,
			}));
		}

		$scope.is_working = true;

		$http.post(`${jcs.api.url_v3}/customers/${Authentication.getCurrentUser().customerID}/destinationgroups`, payload, { withCredentials: true }).then(() => {

			$scope.show_create_dest_group = false;
			$scope.show_registration = true;
			focus('encoder-name');

		}).catch(reason => {

			if (reason.status === 403){
				$scope.dest_group_error_msg = 'Your Resi account must be assigned the Admin permission in order to create a Destination Group. Contact your Resi Administrator for help on setting up this permission.';
			} else {
				$scope.dest_group_error_msg = 'An error occurred while attempting to create the destination group. Please try again, or report the problem if it persists.';
			}

		}).finally(() => {

			$scope.is_working = false;

		});

	};

	$scope.fetchProfiles = function (){

		$scope.load_profiles_error = false;

		$scope.is_loading_profiles = true;

		// load profiles
		var promises = [];
		promises.push($http.get(`${jcs.api.url}/streamprofiles`, { withCredentials: true }));
		promises.push($http.get(`${jcs.api.url_v3}/customers/${Authentication.getCurrentUser().customerID}/webeventprofiles`, {withCredentials: true}));
		promises.push($http.get(`${jcs.api.url_v3}/customers/${Authentication.getCurrentUser().customerID}/youtube/channels`, {withCredentials: true}));
		$q.all(promises).then(([event_profiles, web_event_profiles, social_accounts]) => {

			$scope.event_profiles_list = event_profiles.data;
			$scope.web_event_profiles_list = web_event_profiles.data;
			$scope.social_media_acct_list = social_accounts.data;

			$scope.social_media.sortByType($scope.social_media_acct_list);
			// determine what the status is for each social media account
			$scope.updateSocialMediaAccountsStatus();

			webEventProfileService.formatName($scope.web_event_profiles_list);

			// if they only have 1 profile, then auto select it for them
			if ($scope.event_profiles_list.length === 1){
				$scope.event_profile = $scope.event_profiles_list[0].uuid;
			}
			if ($scope.web_event_profiles_list.length === 1){
				$scope.web_event_profile = $scope.web_event_profiles_list[0].uuid;
			}

			$scope.show_create_dest_group = true;
			$scope.destination_group_name = $scope.default_dest_group_name_in_use ? '' : DEFAULT_DEST_GROUP_NAME;
			focus('destination-group-name');

		}).catch(reason => {

			$scope.load_profiles_error = true;

		}).finally(() => {

			$scope.is_loading_profiles = false;

		});

	};

	$scope.fetchDestinationGroups = function (){

		$scope.load_destination_group_error = false;

		$scope.is_loading_dest_groups = true;

		$http.get(`${jcs.api.url_v3}/customers/${Authentication.getCurrentUser().customerID}/destinationgroups`, {withCredentials: true}).then(response => {

			$scope.destination_group_count = response.data.filter(group => group.enabled).length;
			$scope.default_dest_group_name_in_use = response.data.some(group => group.name.toUpperCase() === DEFAULT_DEST_GROUP_NAME.toUpperCase());

			if ($scope.destination_group_count === 0){

				$scope.show_step_count = true;
				$scope.fetchProfiles();

			} else {

				$scope.show_step_count = false;
				$scope.show_registration = true;
				focus('encoder-name');
			}

		}).catch(reason => {

			$scope.load_destination_group_error = true;

		}).finally(() => {

			$scope.is_loading_dest_groups = false;

		});

	};

	$scope.loginSuccess = function (){

		$http.get(`${jcs.api.url_v3}/customers/${Authentication.getCurrentUser().customerID}/uploaders/registration/details/${$scope.unique_device_id}`, {withCredentials: true}).then(response => {

			// if this device has already been registered, then just show the form to enter the encoder name -- with the encoder name pre-populated with the current value
			$scope.encoder_name = response.data.name;
			$scope.show_step_count = false;
			$scope.is_reregistration = true;
			$scope.show_registration = true;
			focus('encoder-name');

		}).catch(reason => {

			// if device isn't already registered, then begin registration process by checking to see if they need to create a destination group
			$scope.fetchDestinationGroups();
		});

	};

	$scope.doesRegisterFailValidation = function () {
		$scope.registration_error_msg = null;
		$scope.validation.clear();

		// ensure required fields are not empty
		$scope.validation.checkForEmpty('encoder_name', $scope.encoder_name);

		const has_validation_error = $scope.validation.hasError();
		if (has_validation_error) {
			$scope.registration_error_msg = 'Please provide an encoder name.';
		}

		return has_validation_error;
	};

	$scope.register = function (){

		$scope.registration_no_licenses = false;
		$scope.registration_error_msg = null;

		// if we have form validation errors, then don't go any further
		if ($scope.doesRegisterFailValidation()){
			return;
		}

		$scope.registration_error_msg = null;
		$scope.is_working = true;

		const data = {
			'encoderName': $scope.encoder_name,
			'hardwareUniqueId': $scope.unique_device_id,
		};

		$http.post(`${jcs.api.url_v3}/customers/${Authentication.getCurrentUser().customerID}/uploaders/registration`, data, { withCredentials: true }).then(response => {

			// redirect to success page that ProPresenter will capture; since we are redirecting to another page we don't need to bother setting
			// is_working to false in the success case -- just leave it as is so the gear will continue spinning until the new page loads.
			$window.location.href = `${uploaderModule.routes.success}?token=${response.data.token}`;

		}).catch(reason => {

			if (reason.data.code === 'NO_LICENSE_AVAILABLE'){
				$scope.registration_no_licenses = true;
			} else if (reason.status === 403) {
				$scope.registration_error_msg = 'Your Resi account must be assigned the Admin permission in order to register a device. Contact your Resi Administrator for help on setting up this permission.';
			} else {
				$scope.registration_error_msg = 'An error occurred registering your device. Please try again, or report the problem if it persists.';
			}

			$scope.is_working = false;

		})
	};

	// build our tooltips
	$timeout(function () {
		// this tooltip uses a html "title/body" with special formatting
		$('.destination-group-tooltip').tooltip({
			template: '<div class="tooltip" role="tooltip"><div class="tooltip-arrow"></div><div class="tooltip-inner tooltip-inner-wide tooltip-inner-left"></div></div>',
			placement: 'bottom',
			title: '<div class="tooltip-section">A destination group is a collection of web streaming destinations (embed code, YouTube, Facebook) that are grouped together so that you can stream to multiple destinations by selecting only a single group.</div><div class="tooltip-section">Example name: Weekly Wednesday Broadcast</div>You can customize your destination groups and create new ones by signing into Resi.',
			html: true,
		});
	});

}

module.exports = UploaderController;
