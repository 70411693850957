import axios from 'axios';
import { api } from '../../../v2.snapshot/js/jcs';
import { EventAnalyticsState } from '../../contexts/eventanalytics/event-analytics';
import { DataObject } from '../../pages/EventAnalytics/types';

export const fetchVideoCueData = async (EventAnalyticsState: EventAnalyticsState): Promise<DataObject[]> => {
  const url = `${api.url_v3}/customers/${EventAnalyticsState?.customerId}/webevents/${EventAnalyticsState?.eventId}/cues`;
  try {
    const { data } = await axios.get(url, { withCredentials: true });
    return data;
  } catch (error) {
    console.log(error);
    return [];
  }
};
