import "regenerator-runtime/runtime"; // this needs to be imported first
import angular from 'angular';
import axios from 'axios';
import { api, mixpanelApiKey, studioUrl } from './jcs';
import auth from './modules/auth';
import encoders from './modules/encoders';
import contacts from './modules/contacts';
import contactSearch from './modules/contactsearch';
import { ADD_URLS_FOR_TRACKINGID, AUTH_NAME, AUTH_CUSTOMER_NAME, AUTH_CUSTOMER_ID, AUTH_PERMS, AUTH_TOGGLES, AUTH_USER_ID, AUTH_TOKEN, Toggles, TT_ORG_ID } from './constants';
import dashboard from './modules/dashboard';
import cues from './modules/cues';
import events from './modules/events';
import campusreview from './modules/campusreview';
import library from './modules/library';
import destinationGroups from './modules/destinationgroups';
import encoderProfiles from './modules/encoderprofiles';
import eventProfiles from './modules/eventprofiles';
import webEventProfiles from './modules/webeventprofiles';
import eventProfileUsers from './modules/eventprofileusers';
import getStarted from './modules/getstarted';
import licenseInfo from './modules/licenseinfo';
import schedule from './modules/schedule';
import socialMedia from './modules/socialmedia';
import monitor from './modules/monitor';
import onboarding from './modules/onboarding';
import orders from './modules/orders';
import organizations from './modules/organizations';
import players from './modules/players';
import unitDashboard from './modules/unitdashboard';
import unitOptions from './modules/unitoptions';
import units from './modules/units';
import users from './modules/users';
import uploader from './modules/uploader';
import unregisterUploader from './modules/unregisteruploader';
import webBilling from './modules/webbilling';
import passwordReset from './modules/passwordreset';
import simulcastErrorDlgContentComponent from './components/simulcastErrorDlgContent';
import helpCenterComponent from './components/helpCenter';
import mediaStatusComponent from './components/mediaStatus';
import heatmapComponent from './components/heatmap';
import countryComponent from './components/country';
import regionComponent from './components/region';
import cityComponent from './components/city';
import analyticsCsvButtonComponent from './components/analyticsCsvButton';
import timeComponent from './components/time';
import dateComponent from './components/date';
import datetimeComponent from './components/datetime';
import timezoneComponent from './components/timezone';
import uploaderLoginComponent from './components/uploaderLogin';
import durationComponent from './components/duration';
import copyToClipboardButtonComponent from './components/copyToClipboardButton';
import hardwareDetailsComponent from './components/hardwareDetails';
import hardwarePrintButton from './components/hardwarePrintButton';
import errorMsgComponent from './components/errorMsg';
import simulcastSelectorComponent from './components/simulcastSelector';
import cueListComponent from './components/cueList';
import addEditCueComponent from './components/addEditCue';
import updateCueComponent from './components/updateCue';
import deleteCueComponent from './components/deleteCue';
import cueManagerComponent from './components/cueManager';
import youTubeIconComponent from './components/youtubeIcon';
import youTubeLogoComponent from './components/youtubeLogo';
import facebookIconComponent from './components/facebookIcon';
import embedCodeSelectComponent from './components/embedCodeSelect';
import autoFormatPhoneDirective from './directives/autoFormatPhone.directive';
import dynamicTooltipDirective from './directives/dyntooltip.directive';
import userInvites from './modules/userInvites';
import eventAnalytics from './modules/eventanalytics';
import { refreshSyntheticToggles } from './helpers/synthetic-toggles';
import uuid from 'uuid';
import {
	initMixpanel,
	identifyMixpanelUser,
	setMixpanelUserProps,
	mixpanelRegister,
	setMixpanelGroup,
	trackMixpanelEvent,
	setMixpanelGroupProperty,
	MPBuiltInProps,
	MPEventProperty,
	MPEventName,
} from '../../src/mixpanel';

import '../scss/style.scss';
import '../../node_modules/jquery-slimscroll/jquery.slimscroll.js';
import '../../node_modules/admin-lte/dist/js/adminlte.js';
import '../../node_modules/angular-tablesort';
import '../../node_modules/angucomplete-alt';
import '../../node_modules/angularjs-slider/dist/rzslider.min';
import '../plugins/promise-addons/angular-promise-addons.js';
import 'jquery-sparkline';
import 'angular-sanitize';
import 'ui-select';
import '../../favicon.ico';

const authModule = require('./modules/auth/index').authModule;
const uploaderModule = require('./modules/uploader/index').uploaderModule;
const unregisterUploaderModule = require('./modules/unregisteruploader/index').unregisteruploaderModule;
const passwordResetModule = require('./modules/passwordreset/index').passwordResetModule;


axios.interceptors.request.use(
	(request) => {
		if	(ADD_URLS_FOR_TRACKINGID.some(path => request.url.includes(path))) {
			request.headers['TrackingID'] = `CONTROL_${uuid()}`;
		}
		return request;
	},
	(error) => {
		return Promise.reject(error);
	}
);

const myApp = angular
	.module('app', [
		'ngCookies',
		'ngSanitize',
		'tableSort',
		'angucomplete-alt',
		'rzSlider',
		'ngPromiseAddons',
		'ui.select',
		auth,
		encoders,
		contacts,
		contactSearch,
		dashboard,
		cues,
		events,
		campusreview,
		library,
		destinationGroups,
		encoderProfiles,
		eventProfiles,
		webEventProfiles,
		eventProfileUsers,
		getStarted,
		licenseInfo,
		schedule,
		socialMedia,
		monitor,
		onboarding,
		orders,
		organizations,
		players,
		unitDashboard,
		unitOptions,
		units,
		users,
		uploader,
		unregisterUploader,
		webBilling,
		simulcastErrorDlgContentComponent,
		helpCenterComponent,
		mediaStatusComponent,
		heatmapComponent,
		countryComponent,
		regionComponent,
		cityComponent,
		analyticsCsvButtonComponent,
		timeComponent,
		dateComponent,
		datetimeComponent,
		timezoneComponent,
		uploaderLoginComponent,
		durationComponent,
		copyToClipboardButtonComponent,
		hardwareDetailsComponent,
		hardwarePrintButton,
		errorMsgComponent,
		simulcastSelectorComponent,
		cueListComponent,
		addEditCueComponent,
		updateCueComponent,
		deleteCueComponent,
		cueManagerComponent,
		youTubeIconComponent,
		youTubeLogoComponent,
		facebookIconComponent,
		embedCodeSelectComponent,
		autoFormatPhoneDirective,
		dynamicTooltipDirective,
		passwordReset,
		userInvites,
		eventAnalytics
	])
	.factory('httpRequestInterceptor', function() {
		return {
			request: function (config) {
				if	(ADD_URLS_FOR_TRACKINGID.some(path => config.url.includes(path))) {
					config.headers['TrackingID'] = `CONTROL_${uuid()}`;
				}
				return config;
			}
		}
	})
	.factory('retryInterceptor', function ($q, $timeout, $injector) {
		'ngInject';
		const BACK_OFF_TIMER_MS = 350;
		return {
			responseError: function (rejection) {
				if (rejection.status !== 429 && rejection.status !== 502
				&& rejection.status !== 503 && rejection.status !== 504) {
					return $q.reject(rejection);
				}

				if (rejection.config.retry) {
					rejection.config.retry++;
				} else {
					rejection.config.retry = 1;
				}

				if (rejection.config.retry < 3) {
					return $timeout(() => {
						return $injector.get('$http')(rejection.config);
					}, BACK_OFF_TIMER_MS * rejection.config.retry);
				} else {
					return $q.reject(rejection);
				}
			}
		}
	})
	.config(function($locationProvider, $httpProvider) {
		'ngInject';
		$locationProvider.html5Mode({enabled: true, requireBase: false}).hashPrefix('');
		$httpProvider.interceptors.push('retryInterceptor', 'httpRequestInterceptor');
	});

const initInjector = angular.injector(['ng']);
const $http = initInjector.get('$http');
(async () => {
	deleteOldCookies();
	if (!sessionStorage.getItem(AUTH_NAME)) {
		await loginCheck();
	}
	if (!sessionStorage.getItem(AUTH_TOKEN)) {
		await getAuthToken();
	}

	if (await hasNewSchedulerVersion() && !JSON.parse(sessionStorage.getItem(AUTH_PERMS)).find((perm) => perm === 'deleteCustomers') && sessionStorage.getItem(AUTH_CUSTOMER_ID) !== TT_ORG_ID  && !isUploaderRoute(window.location.pathname)) {
		sessionStorage.removeItem(AUTH_NAME);
		sessionStorage.removeItem(AUTH_CUSTOMER_NAME);
		sessionStorage.removeItem(AUTH_CUSTOMER_ID);
		window.location.href = studioUrl;
		return;
	}

	const togglesJson = sessionStorage.getItem(AUTH_TOGGLES);
	const customerId = sessionStorage.getItem(AUTH_CUSTOMER_ID);
	if (togglesJson && customerId) {
		try {
			const toggles = JSON.parse(togglesJson);
			if (!toggles.includes(Toggles.FACEBOOK)) {
				await refreshSyntheticToggles($http, customerId);
			}
		} catch (e) {
			console.error(e);
		}
	}

	angular.element(document).ready(function() {
		angular.bootstrap(document, ['app']);
	});
})();


// keep track of previous page path, so our controllers can tell if the previous page was part of the same module or not.
// this will help with determining if a service should use cached values or not.
myApp.run(function ($rootScope, $location, $window, launchdarklyService) {
	'ngInject';

	var current = null;
	var previous = null;

	$rootScope.$on('$routeChangeSuccess', function() {
		previous = current;
		current = $location.path();
	});

	$rootScope.isPrevPathFromSameModule = function (module_root_path) {
		if (previous){
			return previous.indexOf(module_root_path) === 0;
		}
		return false;
	};

	$rootScope.goBack = function (last_resort_path){

		// first try to use previous path from angular (as long as it isn't login page)
		if (previous && previous != authModule.routes.login){
			$location.path(previous);
		}
		// otherwise if we have window.history then try that; using 2 because browser seems to count initial blank page as first entry in history
		else if ($window.history.length > 2){
			$window.history.back();
		}
		// otherwise use the provided last resort path
		else {
			$location.path(last_resort_path);
		}
	};

	initMixpanel(mixpanelApiKey);
	const userId = sessionStorage.getItem(AUTH_USER_ID);
	const userName = sessionStorage.getItem(AUTH_NAME);
	const customerId = sessionStorage.getItem(AUTH_CUSTOMER_ID);
	const customerName = sessionStorage.getItem(AUTH_CUSTOMER_NAME);
	if (customerId && userId) {
		trackMixpanelRefresh(
			userId,
			userName,
			customerId,
			customerName,
			sessionStorage.getItem(AUTH_PERMS)
		);
		launchdarklyService.trackLaunchDarkly(userId, userName, customerId, customerName);
	}
});

// The following directive/factory methods add auto focus functionality.
// see: http://stackoverflow.com/questions/14833326/how-to-set-focus-on-input-field
// ... in particular the answer by "Ben Lesh"
myApp.directive('focusOn', function () {
	return function (scope, elem, attr) {
		scope.$on('focusOn', function (e, name) {
			if (name === attr.focusOn) {
				elem[0].focus();
			}
		});
	};
});

myApp.directive('ngRightClick', function($parse) {
	return function(scope, element, attrs) {
			var fn = $parse(attrs.ngRightClick);
			element.bind('contextmenu', function(event) {
					scope.$apply(function() {
							event.preventDefault();
							fn(scope, {$event:event});
					});
			});
	};
});

myApp.factory('focus', function ($rootScope, $timeout) {
	'ngInject';
	return function (name) {
		$timeout(function () {
			$rootScope.$broadcast('focusOn', name);
		});
	};
});

if (module.hot) {
	module.hot.accept();
}

function isUploaderRoute(path) {
	const uploaderPaths = ['/uploader', '/uploader/success', '/unregisteruploader', '/unregisteruploader/success']
	return uploaderPaths.includes(path)
}

// this function can go away after a while (i.e. when this is rewritten with React)
function deleteOldCookies() {
	['ng-auth-name',
	 'ng-auth-customer-name',
	 'ng-auth-customer-id',
	 'ng-auth-perms',
	 'ng-auth-toggles',
	 'ng-auth-userid']
	 	.forEach(cookie => document.cookie = cookie + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT;');
}

async function loginCheck() {
	try {
		const response = await $http.get(`${api.url}/users/me`, { withCredentials: true });
		if (response.data && response.data.userName) {
			const { userName, capabilities, toggles, userId, customerName, customerId } = response.data;
			sessionStorage.setItem(AUTH_NAME, userName);
			sessionStorage.setItem(AUTH_CUSTOMER_NAME, customerName || '');
			sessionStorage.setItem(AUTH_CUSTOMER_ID, customerId);
			sessionStorage.setItem(AUTH_PERMS, JSON.stringify(capabilities));
			sessionStorage.setItem(AUTH_TOGGLES, JSON.stringify(toggles));
			sessionStorage.setItem(AUTH_USER_ID, userId);
			return;
		}
	} catch(e) {
		console.error(e);
	}

	const unauthenticatedRoutes = [
			'/login',
			uploaderModule.routes.login,
			unregisterUploaderModule.routes.login,
			passwordResetModule.routes.passwordReset,
			'/userinvites',
			'/emailverification'
		]
	if (unauthenticatedRoutes.every(r => window.location.pathname.includes(r) === false)) {
		window.location.pathname="/login";
	}
}

async function hasNewSchedulerVersion() {
	const customerId = sessionStorage.getItem(AUTH_CUSTOMER_ID);
	if (customerId) {
		const response = await $http.get(`${api.url_v3}/customers/${customerId}`, {
			withCredentials: true,
		});
		return response.data.schedulerVersion === 2;
	}
	return false;
}

async function getAuthToken() {
	try {
		const response = await $http.get(`${api.url}/token`, { withCredentials: true });
		sessionStorage.setItem(AUTH_TOKEN, response.data.token);
	} catch(e) {
		console.error(e);
	}
}

function trackMixpanelRefresh(userId, userName, customerId, customerName, capabilities) {
	try {
		identifyMixpanelUser(userId);
		setMixpanelUserProps({ [MPBuiltInProps.NAME]: userName, [MPEventProperty.USER_PERMISSIONS]: capabilities });
		mixpanelRegister({ [MPEventProperty.ORG_UUID]: customerId, [MPEventProperty.ORG_NAME]: customerName, [MPEventProperty.USER_NAME]: userName });
		setMixpanelGroup(customerId);
		trackMixpanelEvent(MPEventName.USER_REFRESH);
		setMixpanelGroupProperty(MPEventProperty.ORG_UUID, customerId, { [MPBuiltInProps.NAME]: customerName });
	} catch (err) {
		console.log(err);
	}
}