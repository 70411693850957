export const SOCIAL_MEDIA_TYPE_YOUTUBE = 'yt';
export const SOCIAL_MEDIA_TYPE_FACEBOOK = 'fb';
export const SORT_DIR_ASC = 'asc';
export const SORT_DIR_DESC = 'desc';

export const MODULE_APP_NAME = 'app';

// session storage keys
export const AUTH_NAME = 'auth-name';
export const AUTH_CUSTOMER_NAME = 'auth-customer-name';
export const AUTH_CUSTOMER_ID = 'auth-customer-id';
export const AUTH_PERMS = 'auth-perms';
export const AUTH_TOGGLES = 'auth-toggles';
export const AUTH_USER_ID = 'auth-user-id';
export const AUTH_TOKEN = 'la1-token';
export const AUTH_SYNTHETIC_TOGGLES = 'auth-synthetic-toggles';

export const TT_ORG_ID = '906b3113-e61b-4d53-b181-33c987f036e6';

// environment specific
export const LOCATIONS = {
  RESI_MAIN: 'control.resi.io',
  RESI_PROD: 'prod-control.resi.io',
  RESI_INT: 'int-control.resi.io',
} as const;

export const STUDIO_URLS = {
  MAIN: 'https://studio.resi.io',
  PROD: 'https://prod-studio.resi.io',
  INT: 'https://int-studio.resi.io',
} as const;

export const BASE_URLS_DEFAULTS = {
  CENTRAL: 'https://central.resi.io',
  MEDIA_STATUS: 'https://media-status.resi.io',
  MEDIA_METADATA: 'https://media-metadata.resi.io',
  ONBOARDING: 'https://onboarding.resi.io',
  TELEMETRY: 'https://telemetry.resi.io',
  SOCIAL_ANALYTICS_PROXY: 'https://social-analytics-proxy.resi.io',
} as const;

// mixpanel
export const MIXPANEL_API_KEYS = {
  INTEGRATION: 'd624e04bed2e9271ccecf7aace1330a5',
  PRODUCTION: 'ef27ebf8c7c5d4d6527965f68562cf81',
};

export const LD_CLIENT_ID = {
  INTEGRATION: '60f982a432beb9244b10e7ab',
  PRODUCTION: '60f982a432beb9244b10e7ac',
};

export const YOUTUBE_CLIENT_DEFAULTS = {
  ID: '526066391594-mrhc2863mm1uarsq368auq13erl5pl9n.apps.googleusercontent.com',
  SECRET: 'meKdI1vV_JzKxUK8z5G0DpbZ',
} as const;

export enum Toggles {
  FACEBOOK = 'facebook',
}

export const EMBED_CODE_OPTIONS = {
  DEFAULT: 'standard-iframe',
  ALTERNATIVE_IFRAME: 'alternative-iframe',
  STANDARD_IFRAME: 'standard-iframe',
  SCRIPT_TAG: 'script-tag',
};

export const ADD_URLS_FOR_TRACKINGID = [
  'central.resi.io',
] as const;

export const GOOGLE_API_KEY = 'AIzaSyAylM-vDvz4o2SbwHjI4GaaSUhZNYSqKtY';

export const INTEGRATION_REGION_UUID = 'c8bcf29c-ec0b-44f7-b941-7bc2b5fb1256';
