import React, { FC, useContext } from 'react';
import PropTypes from 'prop-types';
import { StatsArray } from '../types';
import YoutubeStatsGraph from './YoutubeStatsGraph/youtube-stats-graph';
import { Grid, customProperties as vars } from '@resi-media/resi-ui';
import { FillBlockSpinner, StatusCard } from '../../../components';
import { LOADER_SIZE_COMPONENT, PAGE_VALUE } from '../event-analytics-constants';
import YoutubeSegmentedStats from './YoutubeSegmentedStats/youtube-segmented-stats';
import { YoutubeContext } from '../../../contexts/eventanalytics';

interface YoutubeAnalyticsViewProps {
  isCardLoading: boolean;
  hasGraphPerms: boolean;
  statsArray: StatsArray;
}

const YoutubeAnalyticsView: FC<YoutubeAnalyticsViewProps> = ({ isCardLoading, hasGraphPerms, statsArray }): JSX.Element => {
  const { youtubeAnalyticsState: {page} } = useContext(YoutubeContext);

  return (
    <Grid display="grid" gridGap={vars.spacingXl} data-testid="analytics-page">
      <Grid.Item>
        <YoutubeStatsGraph hasGraphPerms={hasGraphPerms} />
      </Grid.Item>
      {isCardLoading ? (
        <FillBlockSpinner fontSize={LOADER_SIZE_COMPONENT} />
      ) : (
        <Grid display="grid" gridGap={vars.spacingXl} gridAutoColumns="1fr" gridTemplateColumns="repeat(4, 1fr)">
          {statsArray.map((item) => (
            <Grid.Item key={item.label}>
              <StatusCard
                label={item.label}
                change={item.change}
                count={item.count}
                isTrendUp={item.isTrendUp}
                data-testid="status-card"
              />
            </Grid.Item>
          ))}
        </Grid>
      )}
      {hasGraphPerms && (
        <Grid.Item>
          {page !== PAGE_VALUE.LIVE_VIEWS && <YoutubeSegmentedStats />}
        </Grid.Item>
      )}
    </Grid>
  );
};

YoutubeAnalyticsView.propTypes = {
  hasGraphPerms: PropTypes.bool.isRequired,
  statsArray: PropTypes.array.isRequired,
};

YoutubeAnalyticsView.displayName = 'YoutubeAnalyticsView';

export default YoutubeAnalyticsView;
