import { perms } from '../../jcs';
import { webeventprofilesModule } from './index';

function routeConfig($provide, $routeProvider) {
	'ngInject';

	$provide.factory('$routeProvider', () => {
		return $routeProvider;
	});

	// do more specific route first
	$routeProvider.when(webeventprofilesModule.routes.create, {
		controller: webeventprofilesModule.controllers.default,
		template: require('./webeventprofiles.tmpl.html').default,
		activenav: webeventprofilesModule.name,
		access: {
			loginRequired: true,
			permissions: [perms['event_profiles.get']],
		},
	});
	$routeProvider.when(webeventprofilesModule.routes.list, {
		controller: webeventprofilesModule.controllers.default,
		template: require('./webeventprofiles.tmpl.html').default,
		activenav: webeventprofilesModule.name,
		access: {
			loginRequired: true,
			permissions: [perms['event_profiles.get']],
		},
	});
}

export default routeConfig;
