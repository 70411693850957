import React, { FC, Reducer, useReducer } from 'react';
import {LibraryThumbnailPreviewProps, LibraryThumbnailPreviewState, Action, ACTION_TYPE, reducer} from './context';
import './style.scss';

const initialState: LibraryThumbnailPreviewState = {
  imageBroken: true,
  showImage: true,
  showAnimate: false,
};

const LibraryThumbnailPreview: FC<LibraryThumbnailPreviewProps> = ({
  imageUrl,
  showDelete,
  onClickDelete,
}) => {
  const [state, dispatch] = useReducer<Reducer<LibraryThumbnailPreviewState, Action>>(reducer, initialState);

  const onImageError = (error: any) => {
    dispatch({type: ACTION_TYPE.BROKEN, payload: true});
  };

  const onImageLoad = () => {
    dispatch({type: ACTION_TYPE.BROKEN, payload: false});
  };

  const getImageClassName = () => {
    let classNames = 'thumbnail-image-container';
    if (state.imageBroken || !state.showImage) {
      classNames += ' thumbnail-image-container-empty';
    }
    return classNames;
  };

  const deleteThumbnail = () => {
    dispatch({type: ACTION_TYPE.DISPLAY_IMAGE, payload: false});
    if (onClickDelete) {
      onClickDelete();
    }
  };

  const getImageUrl = () => {
    let img: string | undefined = '/default';

    if (state.showImage) {
      img = imageUrl;
    }
    return img;
  };

  const getDeleteIconClassName = () => {
    let classname = "thumbnail-delete-container";
    if (state.showAnimate) {
      classname = `${classname} thumbnail-delete-animate-fade-out`;
    }
    return classname;
  };

  const onMouseEnter = () => {
    if (!state.showAnimate) {
      dispatch({type: ACTION_TYPE.SHOW_ANIMATE, payload: true});
    }
  };

  return (
    <div className={getImageClassName()}>
      {showDelete && state.showImage && !state.imageBroken &&
      <div className={getDeleteIconClassName()} onMouseEnter={onMouseEnter} onClick={deleteThumbnail} role="img" aria-label="delete" aria-hidden="true">
        <div className="thumbnail-trashcan-icon-container">
          <span role="img" aria-label="delete-icon">
            <svg viewBox="64 64 896 896" focusable="false" data-icon="delete" width="1em" height="1em" fill="currentColor" aria-hidden="true" className="thumbnail-delete-icon">
              <path d="M360 184h-8c4.4 0 8-3.6 8-8v8h304v-8c0 4.4 3.6 8 8 8h-8v72h72v-80c0-35.3-28.7-64-64-64H352c-35.3 0-64 28.7-64 64v80h72v-72zm504 72H160c-17.7 0-32 14.3-32 32v32c0 4.4 3.6 8 8 8h60.4l24.7 523c1.6 34.1 29.8 61 63.9 61h454c34.2 0 62.3-26.8 63.9-61l24.7-523H888c4.4 0 8-3.6 8-8v-32c0-17.7-14.3-32-32-32zM731.3 840H292.7l-24.2-512h487l-24.2 512z"></path>
            </svg>
          </span>
        </div>
      </div>
      }
      <img src={getImageUrl()} className="thumbnail-image" alt="" onError={onImageError} onLoad={onImageLoad}/>
    </div>
  );
};

LibraryThumbnailPreview.displayName = 'LibraryThumbnailPreview';

export default LibraryThumbnailPreview;
