import { ImageUploadState, Action, ACTION_TYPE } from './imageUploadTypes';

export function reducer(state: ImageUploadState, action: Action): ImageUploadState {
  switch (action.type) {
    case ACTION_TYPE.FILE:
      return { ...state, file: action.payload };
    case ACTION_TYPE.FILE_SRC:
      return { ...state, fileSrc: action.payload };
    case ACTION_TYPE.IS_UPLOADING:
      return { ...state, isUploading: action.payload };
    case ACTION_TYPE.UPLOAD_PROGRESS:
      return { ...state, uploadProgress: action.payload };
    case ACTION_TYPE.UPLOAD_FINISHED:
      return { ...state, uploadProgress: action.payload };
    case ACTION_TYPE.UPLOAD_URLS:
      return { ...state, uploadUrls: action.payload };
    case ACTION_TYPE.CROP_AREA_PIXELS:
      return { ...state, cropAreaPixels: action.payload };
    case ACTION_TYPE.CROPPED_IMAGE_INFO:
      return { ...state, croppedImageInfo: action.payload };
    case ACTION_TYPE.IMAGE_DELETED:
      return { ...state, imageDeleted: action.payload };
    case ACTION_TYPE.METADATA_CHANGED:
      return { ...state, metadata: action.payload };
    case ACTION_TYPE.RETRIES:
      return { ...state, metadata: action.payload };
    case ACTION_TYPE.ERROR:
      return { ...state, error: action.payload };
    default:
      throw new Error('Invalid Action Type: ' + action.type);
  }
}
