import img from './facebook-logo.png';

class FacebookIconCtrl {

	constructor() {
		'ngInject';
		this.icon_img = img;
	}

	$onInit () {
		const right_margin = this.rightMargin || '8px';
		this.margins = `5px ${right_margin} 5px 0px`;
	}
}

// example usage:
// <facebook-icon></facebook-icon>
//
export default class FacebookIconComponent {
	constructor() {
		this.template = require('./facebookIcon.html').default;
		this.bindings = {
			rightMargin: '@',
		};
		this.controller = FacebookIconCtrl;
	}
}
