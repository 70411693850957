'use strict';

class DeleteCueCtrl {

	constructor() {
		'ngInject';
	}
}

export default class DeleteCueComponent {
	constructor() {
		this.template = require('./deleteCue.html').default;
		this.bindings = {
			cue: '<',
			isBusy: '<',
			onDelete: '&',
			onCancel: '&',
		};
		this.controller = DeleteCueCtrl;
	}
}
