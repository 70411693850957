import img from './youtube-logo.png';

// shows YouTube Icon + YouTube name
class YouTubeLogoCtrl {

	constructor() {
		'ngInject';
		this.logo_img = img;
	}

	$onInit () {
		this.margins = `5px 8px 5px 0px`;
		this.yt_url = this.url ? this.url : 'https://www.youtube.com/';
	}
}

// example usage:
// <youtube-logo></youtube-logo>
//
export default class YouTubeLogoComponent {
	constructor() {
		this.template = require('./youtubeLogo.html').default;
		this.bindings = {
			url: '<',
		};
		this.controller = YouTubeLogoCtrl;
	}
}
