const { EMBED_CODE_OPTIONS } = require('../../constants');

class EmbedCodeSelectCtrl {

	constructor() {
		'ngInject';
		this.standardIframeOptionLabel = 'Standard iframe';
		this.alternativeIframeOptionLabel = 'Alternative iframe';
		this.scriptTagOptionLabel = 'Script Tag';
		this.selectedOptionLabel = this.standardIframeOptionLabel;
	}

	selectStandardIframe () {
		this.selectedOptionLabel = this.standardIframeOptionLabel;
		if (this.onChange){
			this.onChange({type: EMBED_CODE_OPTIONS.STANDARD_IFRAME});
		}
	}

	selectAlternativeIframe () {
		this.selectedOptionLabel = this.alternativeIframeOptionLabel;
		if (this.onChange){
			this.onChange({type: EMBED_CODE_OPTIONS.ALTERNATIVE_IFRAME});
		}
	}

	selectScriptTag () {
		this.selectedOptionLabel = this.scriptTagOptionLabel;
		if (this.onChange){
			this.onChange({type: EMBED_CODE_OPTIONS.SCRIPT_TAG});
		}
	}
}

// example usage:
// <embed-code-select on-change="selected_type = type"></embed-code-select>
//
export default class EmbedCodeSelectComponent {
	constructor() {
		this.template = require('./embedCodeSelect.html').default;
		this.bindings = {
			onChange: '&',
		};
		this.controller = EmbedCodeSelectCtrl;
	}
}
