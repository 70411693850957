import angular from 'angular';
import Authentication from './authentication';
import { Authorized, PermissionCheckType } from './constants';

class Authorization {
	constructor(Authentication) {
		'ngInject';
		this.Authentication = Authentication;
	}

	authorize(loginRequired, requiredPermissions, permissionCheckType) {
		let result = Authorized.AUTHORIZED,
			user = this.Authentication.getCurrentLoginUser(),
			loweredPermissions = [], // note: no longer lowercasing these
			hasPermission = true,
			permission,
			i;

		permissionCheckType = permissionCheckType || PermissionCheckType.AT_LEAST_ONE;
		if (loginRequired === true && (user === null || typeof user === 'undefined')) {
			result = Authorized.LOGIN_REQUIRED;
		} else if (
			loginRequired === true &&
			user !== undefined &&
			(requiredPermissions === undefined || requiredPermissions.length === 0)
		) {
			// Login is required but no specific permissions are specified.
			result = Authorized.AUTHORIZED;
		} else if (requiredPermissions) {
			loweredPermissions = [];
			angular.forEach(user.permissions, function (permission) {
				loweredPermissions.push(permission); //.toLowerCase());
			});

			for (i = 0; i < requiredPermissions.length; i += 1) {
				permission = requiredPermissions[i]; //.toLowerCase();

				if (permissionCheckType === PermissionCheckType.COMBINATION_REQUIRED) {
					hasPermission = hasPermission && loweredPermissions.indexOf(permission) > -1;
					// if all the permissions are required and hasPermission is false there is no point carrying on
					if (hasPermission === false) {
						break;
					}
				} else if (permissionCheckType === PermissionCheckType.AT_LEAST_ONE) {
					hasPermission = loweredPermissions.indexOf(permission) > -1;
					// if we only need one of the permissions and we have it there is no point carrying on
					if (hasPermission) {
						break;
					}
				}
			}

			result = hasPermission ? Authorized.AUTHORIZED : Authorized.NOT_AUTHORIZED;
		}

		return result;
	}
}

export default Authorization;
