import React, { FC } from 'react';

const WarningStatusSvg: FC = () => (
  <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.7994 13.0469L8.48687 0.390625C8.37789 0.202539 8.1898 0.109375 7.99996 0.109375C7.81011 0.109375 7.62027 0.202539 7.51304 0.390625L0.200543 13.0469C-0.0156678 13.423 0.255035 13.8906 0.687457 13.8906H15.3125C15.7449 13.8906 16.0156 13.423 15.7994 13.0469ZM7.43746 5.3125C7.43746 5.23516 7.50074 5.17188 7.57808 5.17188H8.42183C8.49918 5.17188 8.56246 5.23516 8.56246 5.3125V8.54688C8.56246 8.62422 8.49918 8.6875 8.42183 8.6875H7.57808C7.50074 8.6875 7.43746 8.62422 7.43746 8.54688V5.3125ZM7.99996 11.5C7.77916 11.4955 7.56893 11.4046 7.41438 11.2469C7.25982 11.0891 7.17325 10.8771 7.17325 10.6562C7.17325 10.4354 7.25982 10.2234 7.41438 10.0656C7.56893 9.90788 7.77916 9.81701 7.99996 9.8125C8.22075 9.81701 8.43098 9.90788 8.58554 10.0656C8.74009 10.2234 8.82666 10.4354 8.82666 10.6562C8.82666 10.8771 8.74009 11.0891 8.58554 11.2469C8.43098 11.4046 8.22075 11.4955 7.99996 11.5Z"
      fill="#FAAD14" />
  </svg>
)

export default WarningStatusSvg;
