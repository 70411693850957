import { API_RESPONSE_DIFFS, EMBED_SEGMENTED_REDUCER_TYPE, GROUP_VALUES } from '../../event-analytics-constants';
import { Dispatch } from 'react';
import {
  doughnutChartDataType,
  barChartDataType,
  EMBED_TAB_VALUES,
  LOCATION_TAB_VALUES,
  API_RESPONSE_TYPE,
  BracketReference,
  StateDispatcher,
} from '../../types';
import { getBarChartData, getDoughnutChartData } from '../../utils';

interface SegmentedStatsActions<T> {
  payload: T;
  type: EMBED_SEGMENTED_REDUCER_TYPE;
}

interface SegmentedStatsType {
  // Tab Operations
  tabValue: EMBED_TAB_VALUES;
  locationTabValue: LOCATION_TAB_VALUES;
  showLocationTab: boolean;

  // Doughnut Charts
  resolutionStats: doughnutChartDataType[];
  deviceStats: doughnutChartDataType[];

  //None Type expand view 
  countryStats: barChartDataType;
  regionStats: barChartDataType;
  cityStats: barChartDataType;

  // Bar Charts
  watchTimeStats: barChartDataType;
  watchTimeTableStats?: barChartDataType;
  resolutionStatsGroupBy: barChartDataType;
  deviceStatsGroupBy: barChartDataType;
  countryStatsGroupBy: barChartDataType;
  regionStatsGroupBy: barChartDataType;
  cityStatsGroupBy: barChartDataType;
}

export const initialSegmentedStatsState: SegmentedStatsType = {
  tabValue: 3,
  locationTabValue: 2,
  showLocationTab: true,
  resolutionStats: [],
  deviceStats: [],
  countryStats: { labels: [], extraInfo: [], datasets: {} },
  regionStats: { labels: [], extraInfo: [], datasets: {} },
  cityStats: { labels: [], extraInfo: [], datasets: {} },
  watchTimeStats: { labels: [], extraInfo: [], datasets: {} },
  watchTimeTableStats: { labels: [], extraInfo: [], datasets: {} },
  resolutionStatsGroupBy: { labels: [], extraInfo: [], datasets: {} },
  deviceStatsGroupBy: { labels: [], extraInfo: [], datasets: {} },
  countryStatsGroupBy: { labels: [], extraInfo: [], datasets: {} },
  regionStatsGroupBy: { labels: [], extraInfo: [], datasets: {} },
  cityStatsGroupBy: { labels: [], extraInfo: [], datasets: {} },
};

const dispatchers = {
  [EMBED_SEGMENTED_REDUCER_TYPE.TAB_VALUE]: (
    state: SegmentedStatsType,
    action: SegmentedStatsActions<EMBED_TAB_VALUES>
  ) => ({
    ...state,
    [EMBED_SEGMENTED_REDUCER_TYPE.TAB_VALUE]: action.payload,
  }),
  [EMBED_SEGMENTED_REDUCER_TYPE.LOCATION_TAB_VALUE]: (
    state: SegmentedStatsType,
    action: SegmentedStatsActions<LOCATION_TAB_VALUES>
  ) => ({
    ...state,
    [EMBED_SEGMENTED_REDUCER_TYPE.LOCATION_TAB_VALUE]: action.payload,
  }),
  [EMBED_SEGMENTED_REDUCER_TYPE.SHOW_LOCATION_TAB]: (
    state: SegmentedStatsType,
    action: SegmentedStatsActions<boolean>
  ) => ({
    ...state,
    [EMBED_SEGMENTED_REDUCER_TYPE.SHOW_LOCATION_TAB]: action.payload,
  }),
  [EMBED_SEGMENTED_REDUCER_TYPE.RESOLUTION_STATS]: (
    state: SegmentedStatsType,
    action: SegmentedStatsActions<doughnutChartDataType[]>
  ) => ({
    ...state,
    [EMBED_SEGMENTED_REDUCER_TYPE.RESOLUTION_STATS]: action.payload,
  }),
  [EMBED_SEGMENTED_REDUCER_TYPE.DEVICE_STATS]: (
    state: SegmentedStatsType,
    action: SegmentedStatsActions<doughnutChartDataType[]>
  ) => ({
    ...state,
    [EMBED_SEGMENTED_REDUCER_TYPE.DEVICE_STATS]: action.payload,
  }),
  [EMBED_SEGMENTED_REDUCER_TYPE.COUNTRY_STATS]: (
    state: SegmentedStatsType,
    action: SegmentedStatsActions<barChartDataType>
  ) => ({
    ...state,
    [EMBED_SEGMENTED_REDUCER_TYPE.COUNTRY_STATS]: action.payload,
  }),
  [EMBED_SEGMENTED_REDUCER_TYPE.REGION_STATS]: (
    state: SegmentedStatsType,
    action: SegmentedStatsActions<barChartDataType>
  ) => ({
    ...state,
    [EMBED_SEGMENTED_REDUCER_TYPE.REGION_STATS]: action.payload,
  }),
  [EMBED_SEGMENTED_REDUCER_TYPE.CITY_STATS]: (
    state: SegmentedStatsType,
    action: SegmentedStatsActions<barChartDataType>
  ) => ({
    ...state,
    [EMBED_SEGMENTED_REDUCER_TYPE.CITY_STATS]: action.payload,
  }),

  [EMBED_SEGMENTED_REDUCER_TYPE.RESOLUTION_STATS_GROUP_BY]: (
    state: SegmentedStatsType,
    action: SegmentedStatsActions<barChartDataType>
  ) => ({
    ...state,
    [EMBED_SEGMENTED_REDUCER_TYPE.RESOLUTION_STATS_GROUP_BY]: action.payload,
  }),
  [EMBED_SEGMENTED_REDUCER_TYPE.WATCHTIME_STATS]: (
    state: SegmentedStatsType,
    action: SegmentedStatsActions<barChartDataType>
  ) => ({
    ...state,
    [EMBED_SEGMENTED_REDUCER_TYPE.WATCHTIME_STATS]: action.payload,
  }),
  [EMBED_SEGMENTED_REDUCER_TYPE.WATCHTIME_TABLE_STATS]: (
    state: SegmentedStatsType,
    action: SegmentedStatsActions<barChartDataType>
  ) => ({
    ...state,
    [EMBED_SEGMENTED_REDUCER_TYPE.WATCHTIME_TABLE_STATS]: action.payload,
  }),
  [EMBED_SEGMENTED_REDUCER_TYPE.DEVICE_STATS_GROUP_BY]: (
    state: SegmentedStatsType,
    action: SegmentedStatsActions<barChartDataType>
  ) => ({
    ...state,
    [EMBED_SEGMENTED_REDUCER_TYPE.DEVICE_STATS_GROUP_BY]: action.payload,
  }),
  [EMBED_SEGMENTED_REDUCER_TYPE.COUNTRY_STATS_GROUP_BY]: (
    state: SegmentedStatsType,
    action: SegmentedStatsActions<barChartDataType>
  ) => ({
    ...state,
    [EMBED_SEGMENTED_REDUCER_TYPE.COUNTRY_STATS_GROUP_BY]: action.payload,
  }),
  [EMBED_SEGMENTED_REDUCER_TYPE.REGION_STATS_GROUP_BY]: (
    state: SegmentedStatsType,
    action: SegmentedStatsActions<barChartDataType>
  ) => ({
    ...state,
    [EMBED_SEGMENTED_REDUCER_TYPE.REGION_STATS_GROUP_BY]: action.payload,
  }),
  [EMBED_SEGMENTED_REDUCER_TYPE.CITY_STATS_GROUP_BY]: (
    state: SegmentedStatsType,
    action: SegmentedStatsActions<barChartDataType>
  ) => ({
    ...state,
    [EMBED_SEGMENTED_REDUCER_TYPE.CITY_STATS_GROUP_BY]: action.payload,
  }),
};

export const EmbedSegmentedStatsReducer = (
  state: SegmentedStatsType,
  action: SegmentedStatsActions<any>
): SegmentedStatsType => {
  return dispatchers[action.type](state, action);
};

interface getDispatcherParams {
  groupBy: string;
  dispatch: Dispatch<SegmentedStatsActions<any>>;
  countriesList: BracketReference<string>;
  regionsList: BracketReference<BracketReference<string>>;
}
export const getDispatcher = ({
  groupBy,
  dispatch,
  countriesList,
  regionsList,
}: getDispatcherParams): {
  setResolution: StateDispatcher;
  setDeviceType: StateDispatcher;
  setCountry: StateDispatcher;
  setRegion: StateDispatcher;
  setCity: StateDispatcher;
} => {
  let setResolution: StateDispatcher;
  let setDeviceType: StateDispatcher;
  let setCountry: StateDispatcher;
  let setRegion: StateDispatcher;
  let setCity: StateDispatcher;
  if (groupBy === GROUP_VALUES.NONE) {
    setResolution = (resolution: API_RESPONSE_TYPE) => {
      dispatch({
        type: EMBED_SEGMENTED_REDUCER_TYPE.RESOLUTION_STATS,
        payload: getDoughnutChartData({
          responseObject: resolution,
          countriesList: {},
          regionsList: {},
          chartName: API_RESPONSE_DIFFS.RESOLUTION,
        }),
      });
    };
    setDeviceType = (deviceType: API_RESPONSE_TYPE) => {
      dispatch({
        type: EMBED_SEGMENTED_REDUCER_TYPE.DEVICE_STATS,
        payload: getDoughnutChartData({
          responseObject: deviceType,
          countriesList: {},
          regionsList: {},
          chartName: API_RESPONSE_DIFFS.DEVICE_TYPE,
        }),
      });
    };
    setCountry = (country: API_RESPONSE_TYPE) => {
      dispatch({
        type: EMBED_SEGMENTED_REDUCER_TYPE.COUNTRY_STATS,
        payload: getBarChartData({
          responseObject: country,
          countriesList,
          regionsList,
          chartName: API_RESPONSE_DIFFS.LOCATION.COUNTRY,
        }),
      });
    };
    setRegion = (region: API_RESPONSE_TYPE) => {
      if (region) {
        dispatch({
          type: EMBED_SEGMENTED_REDUCER_TYPE.REGION_STATS,
          payload: getBarChartData({
            responseObject: region,
            countriesList,
            regionsList,
            chartName: API_RESPONSE_DIFFS.LOCATION.REGION,
          }),
        });
      }
    };
    setCity = (city: API_RESPONSE_TYPE) => {
      dispatch({
        type: EMBED_SEGMENTED_REDUCER_TYPE.CITY_STATS,
        payload: getBarChartData({
          responseObject: city,
          countriesList,
          regionsList,
          chartName: API_RESPONSE_DIFFS.LOCATION.CITY,
        }),
      });
    };
  }
  // Event or device type chart data combinations
  else {
    setResolution = (resolution: API_RESPONSE_TYPE) => {
      dispatch({
        type: EMBED_SEGMENTED_REDUCER_TYPE.RESOLUTION_STATS_GROUP_BY,
        payload: getBarChartData({
          responseObject: resolution,
          countriesList: {},
          regionsList: {},
          chartName: API_RESPONSE_DIFFS.RESOLUTION,
        }),
      });
    };
    setCountry = (country: API_RESPONSE_TYPE) => {
      dispatch({
        type: EMBED_SEGMENTED_REDUCER_TYPE.COUNTRY_STATS_GROUP_BY,
        payload: getBarChartData({
          responseObject: country,
          countriesList,
          regionsList,
          chartName: API_RESPONSE_DIFFS.LOCATION.COUNTRY,
        }),
      });
    };
    setRegion = (region: API_RESPONSE_TYPE) => {
      dispatch({
        type: EMBED_SEGMENTED_REDUCER_TYPE.REGION_STATS_GROUP_BY,
        payload: getBarChartData({
          responseObject: region,
          countriesList,
          regionsList,
          chartName: API_RESPONSE_DIFFS.LOCATION.REGION,
        }),
      });
    };
    setCity = (city: API_RESPONSE_TYPE) => {
      dispatch({
        type: EMBED_SEGMENTED_REDUCER_TYPE.CITY_STATS_GROUP_BY,
        payload: getBarChartData({
          responseObject: city,
          countriesList,
          regionsList,
          chartName: API_RESPONSE_DIFFS.LOCATION.CITY,
        }),
      });
    };
    // For device type (segmentBy) - because it is different in event and device type (groupBy)
    if (groupBy === GROUP_VALUES.EVENT_TYPE) {
      setDeviceType = (deviceType: API_RESPONSE_TYPE) => {
        dispatch({
          type: EMBED_SEGMENTED_REDUCER_TYPE.DEVICE_STATS_GROUP_BY,
          payload: getBarChartData({
            responseObject: deviceType,
            countriesList: {},
            regionsList: {},
            chartName: API_RESPONSE_DIFFS.DEVICE_TYPE,
          }),
        });
      };
    } else {
      setDeviceType = (deviceType: API_RESPONSE_TYPE) => {
        dispatch({
          type: EMBED_SEGMENTED_REDUCER_TYPE.DEVICE_STATS,
          payload: getDoughnutChartData({
            responseObject: deviceType,
            countriesList: {},
            regionsList: {},
            chartName: API_RESPONSE_DIFFS.DEVICE_TYPE,
          }),
        });
      };
    }
  }
  return {
    setResolution,
    setDeviceType,
    setCountry,
    setRegion,
    setCity,
  };
};
