import 'angular-route';
import angular from 'angular';
import router from './module.campusreview.routes';
import commonServicesModule from '../../services';

export const campusreviewModule = {
	name: 'campusreview',
	controllers: {
		default: 'campusReviewCtrl',
	},
	routes: {
		list: '/campusreview',
		watch_web: '/campusreview/watch/:watchwebeventid',
	},
};

export default angular
	.module(campusreviewModule.name, ['ngRoute', commonServicesModule])
	.config(router)
	.controller(campusreviewModule.controllers.default, require('./campusReviewCtrl')).name;
