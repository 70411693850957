const loadGoogleMapsApi = require('load-google-maps-api');

// used to guarantee each heatmap will have a unique ID (to allow placing multiple heatmaps on one page)
var heatmapCtrlCounter = 1;

class HeatmapCtrl {

	constructor($timeout) {
		'ngInject';

		this.$timeout = $timeout;
		this.api_key = 'AIzaSyAylM-vDvz4o2SbwHjI4GaaSUhZNYSqKtY';

		this.map_api = null;
		this.map = null;
		this.heatmap = null;
		this.pin_layers = null;

		this.formatted_heatmap_data = null;
		this.center_point = null;

		this.map_id = 'web-event-heatmap-' + heatmapCtrlCounter++;

		this.message = 'Loading Map...';
	}

	$onChanges(changes) {

		if (changes.hasOwnProperty('heatmapData') && changes.heatmapData.currentValue) {

			if (changes.heatmapData.currentValue != null && changes.heatmapData.currentValue.length == 0){

				this.message = 'This event has no data';

			} else if (this.map_api === null){

				// load google maps and heatmap
				// see: https://www.npmjs.com/package/load-google-maps-api
				let map_options = {
					key: this.api_key,
					libraries: ['visualization']
				};

				loadGoogleMapsApi(map_options).then((googleMaps) => {
					this.map_api = googleMaps;

					this.formatHeatmapData();

					let options = {
						zoom: 8,
						maxZoom: 12,
						minZoom: 2,
						streetViewControl: false, // hide the street view Pegman control
						styles: [{
							stylers: [{
								saturation: -70
							}]
						}]
					};
					if (this.center_point){
						options.center = {lat: this.center_point.lat, lng: this.center_point.lng};
					}

					this.map = new googleMaps.Map(document.getElementById(this.map_id), options);

					this.heatmap = new googleMaps.visualization.HeatmapLayer({
						data: this.formatted_heatmap_data,
						map: this.map,
						radius: 40,
					});

					// creat custom layer for our location pins
					// see: https://stackoverflow.com/questions/23033878/showing-sets-of-markers-on-different-layers-of-google-map
					this.pin_layers = new google.maps.MVCObject();
					this.pin_layers.setValues({locations:null});
					this.pin_layers.set('locations', this.map);

					// build pin for each lat/lon location and attach to pin layer
					for (let entry of this.heatmapData){

						let formatted_city = this.toTitleCase(entry.city);
						let viewers = entry.count > 1 ? 'viewers' : 'viewer';
						let contentString = `${entry.count} ${viewers} in ${formatted_city}`;

						let infowindow = new google.maps.InfoWindow({
							content: contentString
						});

						let marker = new google.maps.Marker({
							position: {lat: parseFloat(entry.latitude), lng: parseFloat(entry.longitude)},
							title: contentString
						});
						marker.bindTo('map', this.pin_layers, 'locations');
						marker.addListener('click', function() {
							infowindow.open(this.map, marker);
						});
					}

					// create checkboxes
					let heatmap_checkbox = this.createLabelWithCheckbox('Heatmap', this.isHeatmapVisible(), event => {
						if (event.target.checked){
							// show heatmap if not already visible
							if (!this.isHeatmapVisible()){
								this.heatmap.setMap(this.map);
							}
						} else {
							this.heatmap.setMap(null); // hide heatmap
						}
					});
					heatmap_checkbox.style.borderRight = "2px solid rgb(233,233,233)";
					let location_pins_checkbox =  this.createLabelWithCheckbox('Location Pins', this.isPinLocationsVisible(), event => {
						if (event.target.checked){
							// show location pins if not already visible
							if (!this.isPinLocationsVisible()){
								this.pin_layers.set('locations', this.map);
							}
						} else {
							this.pin_layers.set('locations', null); // hide location pins
						}
					});
					// create control wrapper for our toggle checkboxes
					let control_wrapper = this.createControlsWrapper();
					control_wrapper.appendChild(heatmap_checkbox);
					control_wrapper.appendChild(location_pins_checkbox);
					// add wrapper to map (top center position)
					this.map.controls[google.maps.ControlPosition.TOP_CENTER].push(control_wrapper);

				}).catch((error) => {
					this.$timeout(() => {
						this.message = 'There was an error loading the map';
					});
					console.log("Map Error:");
					console.error(error);
				});

			} else {

				// map is already loaded, so all we need to do is update our heatmap data
				this.formatHeatmapData();
				this.heatmap.setData(this.formatted_heatmap_data);
			}
		}
	}

	isPinLocationsVisible () {
		return this.pin_layers.get('locations') != null;
	}

	isHeatmapVisible () {
		return this.heatmap.getMap() != null;
	}

	// should do equivalent of style="text-transform: capitalize"
	toTitleCase (str) {
		return str.replace(/\w\S*/g, function(txt){
			return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
		});
	}

	// custom map controls; see: https://developers-dot-devsite-v2-prod.appspot.com/maps/documentation/javascript/controls
	createLabelWithCheckbox (label_text, is_checked, onCheckboxChangeCB) {

		let span = document.createElement('span');
		span.style.marginLeft = '7px';
		span.innerHTML = label_text;

		let checkbox = document.createElement('input');
		checkbox.type = 'checkbox';
		checkbox.checked = is_checked;
		checkbox.addEventListener('change', onCheckboxChangeCB);

		let label = document.createElement('label');
		label.style.color = 'rgb(86,86,86)';
		label.style.cursor = 'pointer';
		label.style.fontFamily = 'Roboto,Arial,sans-serif';
		label.style.fontWeight = 'normal';
		label.style.fontSize = '16px';
		label.style.lineHeight = '40px';
		label.style.paddingLeft = '17px';
		label.style.paddingRight = '17px';
		label.style.marginBottom = '0';
		label.style.textAlign = 'center';
		label.appendChild(checkbox);
		label.appendChild(span);

		return label;
	}

	createControlsWrapper () {
		let wrapper = document.createElement('div');
		wrapper.style.marginTop = '10px';
		wrapper.style.backgroundColor = '#fff';
		wrapper.style.borderRadius = '2px';
		wrapper.style.boxShadow = '0 1px 4px -1px rgba(0,0,0,.3)';
		wrapper.index = 1;
		return wrapper;
	}

	formatHeatmapData (){

		let heatmap_list = [];
		for (let entry of this.heatmapData){
			// convert to format heatmap can use
			heatmap_list.push({
				location: new this.map_api.LatLng(parseFloat(entry.latitude), parseFloat(entry.longitude)),
				weight: parseInt(entry.count),
			});
			// determine what point to center map on
			if (this.center_point == null || entry.count > this.center_point.viewers){
				this.center_point = {
					lat: parseFloat(entry.latitude),
					lng: parseFloat(entry.longitude),
					viewers: parseInt(entry.count)
				};
			}
		}

		this.formatted_heatmap_data = heatmap_list;
	}
}

// example usage:
// <heatmap heatmap-data=""></heatmap>
//
export default class HeatmapComponent {
	constructor() {
		this.template = require('./heatmap.html').default;
		this.bindings = {
			heatmapData: '<'
		};
		this.controller = HeatmapCtrl;
	}
}
