import 'angular-route';
import angular from 'angular';
import router from './module.webeventprofiles.routes';
import commonServicesModule from '../../services';
import { react2angular } from "react2angular";
import DataCenterLatency from "../../../../src/components/DataCenterLatency";

export const webeventprofilesModule = {
	name: 'webeventprofiles',
	controllers: {
		default: 'webEventProfilesCtrl',
	},
	routes: {
		create: '/webeventprofiles/new',
	},
};

export default angular
	.module(webeventprofilesModule.name, ['ngRoute', commonServicesModule])
	.config(router)
	.controller(webeventprofilesModule.controllers.default, require('./webEventProfilesCtrl'))
	.component('regionLatency', react2angular(DataCenterLatency, ['onRegionFound', 'regionIdToInclude', 'onTimeout']))
	.name;
