import { api } from '../jcs';

interface Region {
  uuid: string;
  name: string;
  zone: string;
}

class RegionService {
  private isLoadingRegions = false;
  private multisiteRegions = null;
  private webRegions = null;

  constructor(private $q: ng.IQService, private $http: ng.IHttpService) {
    'ngInject';
  }

  public isLoading(): boolean {
    return this.isLoadingRegions;
  }

  public getMultisiteRegions(): ng.IPromise<any> {
    this.isLoadingRegions = true;
    return this.$http
      .get(this.getMultisiteRegionsUrl(), { withCredentials: true })
      .then(response => {
        this.multisiteRegions = response.data;
        this.multisiteRegions.sort(this.sortRegions);
        return this.multisiteRegions;
      })
      .finally(() => (this.isLoadingRegions = false))
  }

  public getWebRegions(): ng.IPromise<any> {
    this.isLoadingRegions = true;
    return this.$http
      .get(this.getWebRegionsUrl(), { withCredentials: true })
      .then(response => {
        this.webRegions = response.data;
        this.webRegions.sort(this.sortRegions);
        return this.webRegions;
      })
      .finally(() => (this.isLoadingRegions = false))
  }

  public clearCache(): void {
    this.multisiteRegions = null;
    this.webRegions = null;
  }

  private getMultisiteRegionsUrl(): string {
    return `${api.url_v3}/regions?multisite=true`;
  }

  private getWebRegionsUrl(): string {
    return `${api.url_v3}/regions?web=true`;
  }

  // sort "North America" always first, "Integration" always last, otherwise alphabetical
  private sortRegions(a: Region, b: Region): number {

    const REGION_NORTH_AMERICA = 'North America';
    const REGION_INTEGRATION = 'Integration';

    if (a.name === REGION_NORTH_AMERICA || b.name === REGION_INTEGRATION){
      return -1;
    }
    if (a.name === REGION_INTEGRATION || b.name === REGION_NORTH_AMERICA){
      return 1;
    }
    if (a.name < b.name){
      return -1;
    }
    if (a.name > b.name){
      return 1;
    }
    return 0;
  }

}

export default RegionService;