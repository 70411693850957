import React, { FC } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles, createStyles } from '@material-ui/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { VirtualizedTableHeader, VirtualizedTableProp, VirtualizedTableRowType } from './types';

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 350,
    fontSize: '24px',
  },
  tableHeading: {
    '&:focus': { outline: 'none' },
  },
});

const StyledTableRow = withStyles(() =>
  createStyles({
    root: {
      height: '40px',
      '&:nth-of-type(even)': {
        backgroundColor: '#FAFAFA',
      },
    },
  })
)(TableRow);

const StyledCell = withStyles(() =>
  createStyles({
    root: {
      boxSizing: 'border-box',
      color: '#595959',
      fontSize: '12px',
    },
  })
)(TableCell);

const VirtualizedTable: FC<VirtualizedTableProp> = ({
  data,
  onRowClick,
  sortDirectionName,
  handleSort,
}): JSX.Element => {
  const tableHeader: VirtualizedTableHeader = data?.header;
  const tableData: VirtualizedTableRowType[] = data?.data;
  const classes = useStyles();
  return (
    <Paper className={classes.root} elevation={0} variant="outlined">
      <TableContainer className={classes.container} data-testid="table-component">
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow data-testid="table-header">
              {tableHeader.map((column, _index) =>
                column.sortable ? (
                  <StyledCell>
                    <TableSortLabel key={_index} direction={sortDirectionName as 'asc' | 'desc'}>
                      <div
                        className={classes.tableHeading}
                        role="button"
                        tabIndex={_index}
                        onClick={(event) => handleSort && handleSort(event, sortDirectionName, column.label)}
                        onKeyDown={(event) => handleSort && handleSort(event, sortDirectionName, column.label)}
                      >
                        {column.label}
                      </div>
                    </TableSortLabel>
                  </StyledCell>
                ) : (
                  <StyledCell key={_index}>{column.label}</StyledCell>
                )
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData.map((rowData, _index) => {
              return (
                <StyledTableRow
                  hover
                  key={_index}
                  role="checkbox"
                  style={{ ...(onRowClick && { cursor: 'pointer' }) }}
                  onMouseDown={() => (onRowClick ? onRowClick(rowData, _index) : null)}
                >
                  {tableHeader.map((column, _index) => {
                    return <StyledCell key={_index}>{rowData?.row[_index]}</StyledCell>;
                  })}
                </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

VirtualizedTable.propTypes = {
  data: PropTypes.exact({
    header: PropTypes.arrayOf(PropTypes.object),
    data: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
  isDataSortable: PropTypes.bool,
  sortDirectionName: PropTypes.string,
  handleSort: PropTypes.func,
  sortByColumn: PropTypes.string,
};

VirtualizedTable.displayName = 'VirtualizedTable';

export default VirtualizedTable;
