class TimezoneCtrl {

	constructor(timeZoneService) {
		'ngInject';

		this.timeZoneService = timeZoneService;

		this.timezone_formatted = '';
	}

	$onInit () {
		this.timezone_formatted = this.timeZoneService.getTimeZoneAlias(this.value);
	}

	$onChanges(changes) {
		this.timezone_formatted = this.timeZoneService.getTimeZoneAlias(this.value);
	}
}

// example usage:
// <timezone value="America/Chicago"></timezone>
//
export default class TimezoneComponent {
	constructor() {
		this.template = require('./timezone.html').default;
		this.bindings = {
			value: '<',
		};
		this.controller = TimezoneCtrl;
	}
}
