'use strict';

const jcs = require('../../jcs');
const unregisteruploaderModule = require('./index').unregisteruploaderModule;

/*
The unregister uploader page is a special page that allows a user to unregister a ProPresenter device. This will free up the license so that
it can be used on another device. This page will be shown in an embedded HTML window sized 700 x 700 pixels.
*/

function UnregisterUploaderController($scope, $window, $location, $http, $routeParams, Authentication) {
	'ngInject';

	$scope.unique_device_id = null;
	$scope.uploader_uuid = null;

	$scope.show_unregister_device = false;
	$scope.show_success = false;
	$scope.show_error = false;

	$scope.error_msg = '';
	$scope.unregister_error_msg = null;

	$scope.is_working = false;

	// determine what page to show user
	if ($location.path() === unregisteruploaderModule.routes.success){
		$scope.show_success = true;
	} else if ($routeParams.hasOwnProperty('id') && $routeParams.id !== null){
		$scope.unique_device_id = $routeParams.id;
	} else {
		$scope.show_error = true;
		$scope.error_msg = 'Device identifier is required in order to unregister.';
	}

	$scope.loginSuccess = function (){

		$scope.show_unregister_device = true;

	};

	$scope.unregister = function (){

		$scope.unregister_error_msg = null;
		$scope.is_working = true;

		$http.get(`${jcs.api.url_v3}/customers/${Authentication.getCurrentUser().customerID}/uploaders/registration/details/${$scope.unique_device_id}`, {withCredentials: true}).then(response => {

			$scope.uploader_uuid = response.data.uuid;

			$http.delete(`${jcs.api.url_v3}/customers/${Authentication.getCurrentUser().customerID}/uploaders/${$scope.uploader_uuid}`, { withCredentials: true, data: null }).then(response => {

				// redirect to success page that ProPresenter will capture; since we are redirecting to another page we don't need to bother setting
				// is_working to false in the success case -- just leave it as is so the gear will continue spinning until the new page loads.
				$window.location.href = unregisteruploaderModule.routes.success;

			}).catch(reason => {

				$scope.unregister_error_msg = $scope.getErrorMsg(reason.status);
				$scope.is_working = false;

			});

		}).catch(reason => {

			$scope.unregister_error_msg = $scope.getErrorMsg(reason.status);
			$scope.is_working = false;

		});

	};

	$scope.getErrorMsg = function (status) {
		switch (status) {
			case 403:
				return 'You do not have permission to unregister this device.';
			default:
				return `An error occurred unregistering your device (status=${status}). Please try again, or report the problem if it persists.`;
		}
	};

}

module.exports = UnregisterUploaderController;
