import React, { FC, useRef, useReducer, Reducer, useEffect, useState } from 'react';
import MP4Box, { ISOFile, Mp4BoxInfo } from 'mp4box';
import type * as LDClient from 'launchdarkly-js-client-sdk';
import PropTypes from 'prop-types';
import { getWebEventProfiles, getEncoderProfiles, getSignedUrl, uploadFile, updateUploadStatus } from './api';
import {
  validateVideoType,
  validateDimensions,
  validateSubmit,
  validateFileType,
  validateFileSize,
  validateFramerateMode,
  validateDuration,
  validateAudioType,
  validateMaxAudioChannels,
  validateAudioBitrate,
  validateMaxVideoChannels,
  validateMaxVideoBitrate,
  validateMaxResolution,
  validateSampleRate,
  validateUploadFileHasAudio,
  validations,
  validateFps,
  spliceMp4,
  logSampleDuration,
  logTimescale,
  calculateGop,
  ChecksumTask,
  computeChecksum,
  Mp4ValidationError,
} from './eventUploadHelpers';
import { Action, ACTION_TYPE, EventUploadState, EventUploadContext, initialState, reducer } from './context';
import './style.scss';
import { ValidationsObject, VALIDATIONS, WebEventProfile, SignedUrlResponse } from './eventUploadTypes';
import { UploadWizard } from '../UploadWizard';
import { AxiosError, AxiosResponse } from 'axios';
import { trackMixpanelEvent, MPEventName, MPEventProperty } from '../../mixpanel';

const VARIABLE_FRAMERATE = 'variable';

export interface EventUploadModalProps {
  closeFunction(): void;
  customerId: string;
  getRecentUploads: () => void;
  ldFlags?: LDClient.LDFlagSet;
}

const EventUploadModal: FC<EventUploadModalProps> = ({
  closeFunction,
  customerId,
  getRecentUploads,
  ldFlags,
}): JSX.Element => {
  const fileRef = useRef<HTMLInputElement | null>(null);
  const checksumTaskRef = useRef<ChecksumTask | null>(null);
  const [source, setSource] = useState<any>(null);
  const [state, dispatch] = useReducer<Reducer<EventUploadState, Action>>(reducer, initialState);
  const mp4boxFile = useRef(MP4Box.createFile());
  const activeReaders = useRef<FileReader[]>([]);
  const anyReaderAborted = useRef(false);
  const uploadCanceled = useRef(false);

  function beforeUnloadEvent(e: any) {
    const preferredMessage = 'Your upload will be lost if you leave this window.';
    (e || window.event).returnValue = preferredMessage;
    return preferredMessage;
  }

  function updateError(type: VALIDATIONS, invalid = true) {
    const errorsClone: ValidationsObject = { ...state.errors };
    errorsClone[type].invalid = invalid;
    dispatch({
      type: ACTION_TYPE.ERROR_LIST,
      payload: { ...errorsClone },
    });
  }

  const resetValidations = () => Object.values(validations).forEach((x) => (x.invalid = false));

  function validateAndLogMp4BoxInfo(file: ISOFile, info: Mp4BoxInfo, allowVariableFrameRate: boolean, allowNon48KhzAudio: boolean) {
    console.log(info);

    const updateErrorAndCancelChecksum = (type: VALIDATIONS, invalid?: boolean): void => {
      if (invalid) {
        checksumTaskRef.current?.cancel();
      }
      updateError(type, invalid);
    };

    const mixPanelData = {
      [MPEventProperty.EVENT_NAME]: state.eventName,
      [MPEventProperty.ENCODER_EVENT_PROFILE_UUID]: state.eventProfile,
    };

    const isVariableFrameRate = validateFramerateMode(mp4boxFile.current, info, updateErrorAndCancelChecksum, allowVariableFrameRate);
    if (isVariableFrameRate) {
      dispatch({type: ACTION_TYPE.FRAMERATE, payload: VARIABLE_FRAMERATE });
    } else {
      validateFps(info, updateErrorAndCancelChecksum, dispatch, mixPanelData);
      calculateGop(mp4boxFile.current, info, updateErrorAndCancelChecksum, dispatch, mixPanelData);
    }
    validateUploadFileHasAudio(info, updateErrorAndCancelChecksum);
    validateMaxResolution(info, updateErrorAndCancelChecksum, dispatch, mixPanelData);
    validateMaxVideoBitrate(info, updateErrorAndCancelChecksum, dispatch, mixPanelData);
    validateMaxVideoChannels(info, updateErrorAndCancelChecksum, mixPanelData);
    validateAudioBitrate(info, updateErrorAndCancelChecksum, dispatch, mixPanelData);
    validateSampleRate(file, info, updateErrorAndCancelChecksum, dispatch, allowNon48KhzAudio, mixPanelData);
    validateMaxAudioChannels(info, updateErrorAndCancelChecksum, mixPanelData);
    validateAudioType(info, updateErrorAndCancelChecksum);
    validateVideoType(info, updateErrorAndCancelChecksum);
    validateDimensions(info, updateErrorAndCancelChecksum);
    validateDuration(info, updateErrorAndCancelChecksum);
    validateFileSize(info, updateErrorAndCancelChecksum);
    logSampleDuration(info, mixPanelData);
    logTimescale(info, mixPanelData);
    // Release the references to the ArrayBuffers associated w/ the mp4boxFile
    mp4boxFile.current = MP4Box.createFile();

    const errorMessages = Object.values(state.errors)
      .filter(({ invalid }) => invalid)
      .map(({ message }) => message);

    trackMixpanelEvent(MPEventName.VIDEO_UPLOAD_ANALYZE_COMPLETE, {
      ...mixPanelData,
      [MPEventProperty.ERROR_MESSAGE]: errorMessages,
      [MPEventProperty.SUCCESS_STATE]: errorMessages?.length > 0 ? 0 : 1,
    });
  }

  const hasNoErrors = (): boolean => Object.values(state.errors).every(({ invalid }) => !invalid);

  const handleFileChange = React.useCallback(function (e: any) {
    // todo once uploadWizard toggle goes away, a file will be passed as parameter always; not an event. Will remove references to e.target then.
    const [videoFile]: [File] = e?.target?.files ?? e;
    const fileName = videoFile ? videoFile.name : '';
    const mime = videoFile ? videoFile.type : '';
    mp4boxFile.current = MP4Box.createFile();
    activeReaders.current.length = 0;
    anyReaderAborted.current = false;
    uploadCanceled.current = false;

    // reset value of hidden input so user can
    // select same file twice in a row
    if (fileRef?.current?.value) {
      fileRef.current.value = '';
    }
    checksumTaskRef.current?.cancel();

    updateError(VALIDATIONS.MAX_GOP, false);
    dispatch({ type: ACTION_TYPE.ANALYZING_TOTAL, payload: videoFile.size });
    dispatch({ type: ACTION_TYPE.FILE_NAME, payload: fileName });
    dispatch({ type: ACTION_TYPE.UPLOAD_PROGRESS, payload: 0 });
    dispatch({ type: ACTION_TYPE.FILE_CHECKSUM_PROGRESS, payload: 0 });
    dispatch({ type: ACTION_TYPE.FILE_CHECKSUM, payload: null });
    dispatch({ type: ACTION_TYPE.SET_ANALYSIS_RUNNING, payload: true });
    dispatch({ type: ACTION_TYPE.SET_CHECKSUM_TASK_RUNNING, payload: true });
    dispatch({ type: ACTION_TYPE.CLEAR_WARNINGS });

    if (!videoFile) {
      updateError(VALIDATIONS.NO_UPLOAD_FILE);
    } else {
      updateError(VALIDATIONS.NO_UPLOAD_FILE, false);
    }

    if (!mime.includes('video')) {
      updateError(VALIDATIONS.NOT_A_VIDEO);
    } else {
      updateError(VALIDATIONS.NOT_A_VIDEO, false);
    }

    validateFileType(fileName, updateError);

    mp4boxFile.current.onError = (e: any) => {
      console.log(e);
      updateError(VALIDATIONS.INVALID_FORMAT);
      return;
    };

    const blobFile = new Blob([videoFile], { type: 'video/mp4' });

    const checksumTask = computeChecksum(blobFile, (checksumProgress) => {
      dispatch({ type: ACTION_TYPE.FILE_CHECKSUM_PROGRESS, payload: checksumProgress });
    });

    checksumTaskRef.current = checksumTask;
    checksumTask
      .hash()
      .then((checksum) => {
        console.log(`File checksum: ${checksum}`);
        dispatch({ type: ACTION_TYPE.FILE_CHECKSUM, payload: checksum });
      })
      .catch((e) => {
        console.error('Checksum error', e);
        updateError(VALIDATIONS.BACK_END);
      })
      .finally(() => {
        checksumTaskRef.current = null;
        dispatch({ type: ACTION_TYPE.SET_CHECKSUM_TASK_RUNNING, payload: false });
      });

      const addToMp4 = (boxBuffer: ArrayBuffer, fileStart: number): void => {
        Object.defineProperty(boxBuffer, 'fileStart', { value: fileStart });
        mp4boxFile.current.appendBuffer(boxBuffer);
      };
      const reportProgress = (progressBytes: number): void => {
        if (progressBytes >= videoFile.size && !uploadCanceled.current) {
          validateAndLogMp4BoxInfo(mp4boxFile.current, mp4boxFile.current.getInfo(), true, true);
        }
        dispatch({ type: ACTION_TYPE.ANALYZING_LOADED, payload: progressBytes });
      };

      spliceMp4(videoFile, addToMp4, reportProgress, () => uploadCanceled.current)
        .catch((e) => {
          if (e instanceof Mp4ValidationError) {
            console.error(e);
            updateError(VALIDATIONS.INVALID_CONTAINER_FORMAT);
            checksumTask.cancel();
            return;
          }
          console.error(e);
          updateError(VALIDATIONS.BACK_END);
          checksumTask.cancel();
        })
        .finally(() => {
          dispatch({ type: ACTION_TYPE.SET_ANALYSIS_RUNNING, payload: false });
        });

    dispatch({ type: ACTION_TYPE.FILE, payload: blobFile });
  }, [ldFlags]);

  const removeFields = () => {
    resetValidations();

    dispatch({ type: ACTION_TYPE.FILE, payload: null });
    dispatch({ type: ACTION_TYPE.FILE_NAME, payload: '' });
    dispatch({ type: ACTION_TYPE.FILE_CHECKSUM, payload: null });
    dispatch({ type: ACTION_TYPE.FILE_CHECKSUM_PROGRESS, payload: 0 });
    dispatch({ type: ACTION_TYPE.ERROR_LIST, payload: validations });
    dispatch({ type: ACTION_TYPE.IS_UPLOADING, payload: false });
    dispatch({ type: ACTION_TYPE.UPLOAD_PROGRESS, payload: 0 });
    dispatch({ type: ACTION_TYPE.ANALYZING_LOADED, payload: 0 });
    dispatch({ type: ACTION_TYPE.ANALYZING_TOTAL, payload: 0 });
    dispatch({ type: ACTION_TYPE.UPLOAD_TOTAL, payload: 0 });
    dispatch({ type: ACTION_TYPE.SIGNED_URL_RES, payload: null });
    dispatch({ type: ACTION_TYPE.SET_ANALYSIS_RUNNING, payload: false });
    dispatch({ type: ACTION_TYPE.SET_CHECKSUM_TASK_RUNNING, payload: false });
  };

  const closeModal = () => {
    const leaveConfirmed = state.isUploading
      ? window.confirm(
          'If you leave this window before your upload is finished, your upload will be cancelled. Are you sure you want to exit this modal?'
        )
      : true;
    if (!leaveConfirmed) {
      return;
    }
    window.removeEventListener('beforeunload', beforeUnloadEvent);
    checksumTaskRef.current?.cancel();
    resetValidations();
    dispatch({ type: ACTION_TYPE.RESET_STATE });
    closeFunction();
  };

  async function handleSubmit() {
    dispatch({ type: ACTION_TYPE.UPLOAD_CANCELED, payload: false });
    if (state.uploadProgress >= 100) {
      closeModal();
      return;
    }
    if (state.showCreateWebEvent && !state.webEventProfileUuid) {
      updateError(VALIDATIONS.BLANK_WEB_EVENT_PROFILE);
    }
    if (!validateSubmit(state, updateError) || !hasNoErrors()) {
      return;
    }
    if (state.isUploading || !state.file || !state.fileChecksum) {
      console.warn('Submit blocked: upload is loading, there is no file, or the checksum was not set');
      return;
    }
    if (state.file.size === 0) {
      updateError(VALIDATIONS.FILE_SIZE_0);
      return;
    }
    if (state.framerate !== VARIABLE_FRAMERATE && (state.gopSeconds === undefined || state.gopSeconds <= 0)) {
      console.error(`GOP seconds must be > 0; got ${state.gopSeconds}`);
      updateError(VALIDATIONS.BACK_END);
      return;
    }

    if (state.fileChecksum === null) {
      throw new Error('fileChecksum was not set; the FILE_CHECKSUM action must be dispatched');
    }

    window.addEventListener('beforeunload', beforeUnloadEvent);

    try {
      const signedUrlResponse: AxiosResponse<SignedUrlResponse> = await getSignedUrl(
        customerId,
        state.eventProfile,
        JSON.stringify({
          eventName: state.eventName,
          fileName: state.fileName,
          gopSeconds: state.gopSeconds,
          audioSampleRate: state.audioSampleRate,
          audioBitrateKbps: state.audioBitrateKbps,
          videoBitrateKbps: state.videoBitrateKbps,
          framerate: state.framerate,
          videoHeight: state.resolutionHeight,
          webEventProfileId: state.showCreateWebEvent ? state.webEventProfileUuid : null,
          published: state.showCreateWebEvent ? state.webEventImmediatePublish : false,
          crc32c: state.fileChecksum
        })
      );

      dispatch({ type: ACTION_TYPE.SIGNED_URL_RES, payload: signedUrlResponse.data });
      dispatch({ type: ACTION_TYPE.IS_UPLOADING, payload: true });

      await uploadFile({
        file: state.file,
        checksumBase64: state.fileChecksum,
        uploadUrl: signedUrlResponse.data.uploadUrl,
        dispatch,
        setSource,
      });
    } catch (e) {
      // checking e.request prevents an error from appearing on cancel
      if (e.request) {
        e.status ? updateError(VALIDATIONS.BACK_END) : updateError(VALIDATIONS.NETWORK_ERROR);
      }
      console.error(e);
      trackMixpanelEvent(MPEventName.VIDEO_UPLOAD_COMPLETE, {
        [MPEventProperty.ENCODER_EVENT_PROFILE_UUID]: state.eventProfile,
        [MPEventProperty.EVENT_NAME]: state.eventName,
        [MPEventProperty.SUCCESS_STATE]: 0,
        [MPEventProperty.ERROR_MESSAGE]: [e.message],
      });
    }
    dispatch({ type: ACTION_TYPE.IS_UPLOADING, payload: false });
    dispatch({ type: ACTION_TYPE.UPLOAD_FINISHED, payload: true });
    window.removeEventListener('beforeunload', beforeUnloadEvent);
  }

  const cancelUpload = () => {
    uploadCanceled.current = true;
    checksumTaskRef.current?.cancel();
    checksumTaskRef.current = null;
    dispatch({ type: ACTION_TYPE.UPLOAD_CANCELED, payload: true });
    if (source?.cancel) {
      source.cancel();
    }
    removeFields();
    activeReaders.current.forEach((x) => x.abort());
  };

  useEffect(() => {
    (async () => {
      const encoderProfiles: WebEventProfile[] = await getEncoderProfiles();
      encoderProfiles.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));
      dispatch({ type: ACTION_TYPE.PROFILE_LIST, payload: encoderProfiles });
    })();

    (async () => {
      const webEventProfiles = await getWebEventProfiles(customerId);
      webEventProfiles.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));
      dispatch({ type: ACTION_TYPE.WEB_EVENT_PROFILE_LIST, payload: webEventProfiles });
    })();

    return () => window.removeEventListener('beforeunload', beforeUnloadEvent);
  }, []);

  useEffect(() => {
    if (state.analyzingTotal > 0 && state.analyzingLoaded / state.analyzingTotal >= 1 && state.fileChecksum !== null) {
      handleSubmit();
    }
  }, [state.analyzingLoaded, state.analyzingTotal, state.fileChecksum]);

  useEffect(() => {
    if (state.uploadFinished) {
      if (!state.uploadCanceled && state.uploadProgress >= 100) {
        if (!state.signedUrlResponse) {
          console.error('Signed Url is null');
        } else {
          updateUploadStatus(state.signedUrlResponse.statusCallback)
            .then(getRecentUploads)
            .catch((error: AxiosError) => {
              if (error.response?.status === 400) {
                getRecentUploads();
              }
            });
        }
      } else if (!state.uploadCanceled) {
        updateError(VALIDATIONS.BACK_END);
      }
      dispatch({ type: ACTION_TYPE.UPLOAD_FINISHED, payload: false });
      dispatch({ type: ACTION_TYPE.UPLOAD_CANCELED, payload: false });
    }
  }, [state.uploadFinished]);

    return (
      <EventUploadContext.Provider value={{ state, dispatch }}>
        <UploadWizard cancelUpload={cancelUpload} closeModal={closeModal} handleFileChange={handleFileChange} />
      </EventUploadContext.Provider>
    );
};

EventUploadModal.displayName = 'EventUplodadModal';

EventUploadModal.propTypes = {
  closeFunction: PropTypes.func.isRequired,
  customerId: PropTypes.string.isRequired,
};

export default EventUploadModal;
