'use strict';

const jcs = require('../../jcs');
var libphonenumber = require('libphonenumber-js');
const { trackMixpanelEvent, MPEventProperty, MPEventName} = require('../../../../src/mixpanel');

function UsersController($scope, $location, $http, $q, httpService, userService, formValidationService, focus, Authentication) {
  'ngInject';
  $(window).trigger('resize'); // ensure footer is properly positioned

  $scope.validation = formValidationService.init();
  $scope.userSvc = userService;
  $scope.userSvc.clearCache();

  $scope.invites = null;
  $scope.users = null;
  $scope.sites = null;
  $scope.loading_users = false;
  $scope.loading_invites = false;
  $scope.users_error_msg = null;
  $scope.invites_error_msg = null;
  $scope.MAX_USERNAME_LENGTH = 254;
  $scope.MAX_ADDRESS_LENGTH = 250;

  $scope.ACTIVE = 'Active';
  $scope.INACTIVE = 'Disabled';
  $scope.ACTIVE_DEFAULT = $scope.ACTIVE;
  $scope.active_status_options = [$scope.ACTIVE, $scope.INACTIVE];

  // change password
  $scope.user_to_chg_pswd = null;
  $scope.is_busy_changing_pswd = false; // activity indicator
  $scope.user_to_chg_pswd_error = null;
  $scope.new_password = null;
  $scope.new_password2 = null;

  // edit billing
  $scope.user_to_edit_billing = null;
  $scope.user_to_edit_billing_address = '';
  $scope.user_to_edit_billing_plan = null;
  $scope.user_to_edit_billing_active = $scope.ACTIVE_DEFAULT;
  $scope.available_plans = [];
  $scope.user_to_edit_billing_error = null;

  // edit user
  $scope.user_to_edit = null;
  $scope.is_busy_editing_user = false; // activity indicator
  $scope.is_busy_loading_user = false; // activity indicator
  $scope.user_to_edit_name = '';
  $scope.user_to_edit_error = null;
  $scope.available_roles = [];
  $scope.user_to_edit_roles = null;

  // user create
  $scope.is_adding_user = false;
  $scope.is_busy_adding_user = false; // activity indicator
  $scope.user_to_add_name = '';
  $scope.user_to_add_address = '';
  $scope.user_to_add_plan = null;
  $scope.user_to_add_active = null;
  $scope.add_user_error = null;
  $scope.user_to_add_roles = null;
  $scope.userToAddFeatures = null;
  $scope.user_to_add_event_profiles = null;
  $scope.customer_event_profile_list = null;

  // user delete
  $scope.user_to_delete = null;
  $scope.is_busy_deleting_user = false; // activity indicator
  $scope.delete_user_error = null;

  // user profile
  $scope.user_profile = null; // set when current user wants to view their own profile

  // resend invite
  $scope.invite_to_resend = null;
  $scope.resend_invite_error = null;

  // revoke invite
  $scope.invite_to_revoke = null;
  $scope.revoke_invite_error = null;

  // invite user (used by customers)
  $scope.invite_edit_fields = null;
  $scope.invite_user_error = null;
  $scope.invite_user_loading_error = null;

  // edit venue (used by customers)
  $scope.edit_venue_fields = null;
  $scope.edit_venue_error = null;
  $scope.edit_venue_loading_error = null;

  // toggles
  $scope.toggles_map = null; // use for quick lookup of toggle names
  $scope.toggles_list = null;
  $scope.customer_toggles = null;
  $scope.user_toggles = null;
  $scope.is_loading_toggle_data = false;
  $scope.is_busy_saving_toggles = false;
  $scope.loading_toggles_error = null;
  $scope.saving_toggles_error = null;

  // toggle shortcuts - to help support team set toggles quickly
  let TOGGLE_GUID_FB = 'a4008a17-c782-21e2-3ce3-32010a100122';
  $scope.social_media_toggles = [TOGGLE_GUID_FB];
  $scope.is_working = false;
  $scope.toggle_shortcut_social_media = false;

  $scope.getCurrentUser = function () {
    return Authentication.getCurrentUser();
  };

  $scope.canShowTogglesBtn = function () {
    return Authentication.getCurrentUser().hasPerm('toggles.update');
  };

  $scope.canShowAddUserBtn = function () {
    return Authentication.getCurrentUser().hasPerm('users.add');
  };

  $scope.canShowInviteUserBtn = function () {
    return Authentication.getCurrentUser().hasPerm('invited_users.add');
  };

  $scope.canShowResendInviteBtn = function () {
    return Authentication.getCurrentUser().hasPerm('invited_users.resend');
  };

  $scope.canShowRevokeInviteBtn = function () {
    return Authentication.getCurrentUser().hasPerm('invited_users.revoke');
  };

  $scope.canShowChangePswdBtn = function (site_or_user) {
    return (
      Authentication.getCurrentUser().hasPerm('users.update') ||
      Authentication.getCurrentUser().name.toLowerCase() == site_or_user.userName.toLowerCase()
    );
  };

  // this is the edit button available to customers
  $scope.canShowNewEditBtn = function () {
    return Authentication.getCurrentUser().hasPerm('users.update');
  };

  // edit button for resi only
  $scope.canShowEditBtn = function () {
    return Authentication.getCurrentUser().hasPerm('la1only');
  };

  $scope.canShowEditBillingBtn = function () {
    return Authentication.getCurrentUser().hasPerm('users.billing');
  };

  $scope.canShowUserDecoderStatusBtn = function () {
    return Authentication.getCurrentUser().hasPerm('users.decoder_status');
  };

  $scope.canShowDeleteBtn = function () {
    if (Authentication.getCurrentUser().hasPerm('la1only')){
      return true;
    }
    return Authentication.getCurrentUser().hasPerm('users.delete');
  };

  $scope.hasInvites = function () {
    return $scope.invites && $scope.invites.length > 0;
  };

  // returns true if the UI should be displaying the site/user listing
  $scope.showListing = function () {
    return (
      !$scope.user_to_chg_pswd &&
      !$scope.user_to_edit &&
      !$scope.user_to_edit_billing &&
      !$scope.is_adding_user &&
      !$scope.user_profile &&
      !$scope.customer_toggles
    );
  };

  $scope.getStatusText = function (site) {
    return site.active ? $scope.ACTIVE : $scope.INACTIVE;
  };

  $scope.enterInviteUserMode = function () {

    $scope.validation.clear();
    $scope.invite_user_error = null;
    $scope.invite_user_loading_error = null;

    $scope.invite_edit_fields = {
      uuid: null,
      email: '',
      title: '',
      first: '',
      last: '',
      mobile: '',
      sendStreamingAlerts: false,
      selected_roles: {}
    };

    $('#invite-user').modal('show');

    userService.getAvailableRoles(Authentication.getCurrentUser().customerID)
      .then(available_roles => {
        // remove "Resi" and socialMedia roles
        $scope.available_roles = available_roles.filter(role => !role.description.includes(userService.RESI_ROLE) && !role.name.includes(userService.SOCIAL_MEDIA_ROLE));
      })
      .catch(() => {
        $scope.invite_user_loading_error = 'An error occurred while retrieving info needed to invite a user. Please try again, or report the problem if it persists.';
      });
  };

  $scope.doesInviteUserFailValidation = function (ignoreEmail) {
    $scope.validation.clear();

    // ensure required fields are not empty
    $scope.validation.checkForEmpty('invite_user_email', $scope.invite_edit_fields.email);
    $scope.validation.checkForEmpty('invite_user_first', $scope.invite_edit_fields.first);
    $scope.validation.checkForEmpty('invite_user_last', $scope.invite_edit_fields.last);
    $scope.validation.checkForEmpty('invite_user_title', $scope.invite_edit_fields.title);

    const has_validation_error = $scope.validation.hasError();
    if (has_validation_error) {
      $scope.invite_user_error = 'Please specify a value for the highlighted fields.';
    } else if (!ignoreEmail) {
      // ensure email is valid
      const rEmail = /^((([a-z]|\d|[!#\$%&‘\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&‘\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i;
      if (!rEmail.test($scope.invite_edit_fields.email)){
        $scope.validation.setError('invite_user_email');
        $scope.invite_user_error = 'Please enter a valid email address.';
        return true;
      }
    }

    return has_validation_error;
  };

  $scope.doesEditVenueFailValidation = function () {
    $scope.validation.clear();
    // ensure required fields are not empty
    $scope.validation.checkForEmpty('edit_venue_username', $scope.edit_venue_fields.userName);
    const has_validation_error = $scope.validation.hasError();

    if (has_validation_error) {
      $scope.edit_venue_error = 'Please specify a value for the highlighted fields.';
    }
    return has_validation_error;
  }

  $scope.inviteOrEditUser = function () {
    if ($scope.invite_edit_fields.uuid !== null){
      $scope.editUser();
    } else {
      $scope.inviteUser();
    }
  };

  // this is the edit user function that a customer would use
  $scope.editUser = function () {
    $scope.invite_user_error = null;

    if ($scope.doesInviteUserFailValidation(true)){
      return false;
    }

    $scope.is_working = true;

    const rolesPayload = {
      roles: [],
    };

    const userPayload = {
      contact: {
        first: $scope.invite_edit_fields.first,
        last: $scope.invite_edit_fields.last,
        title: $scope.invite_edit_fields.title,
        mobile: $scope.getPhoneFormattedForDB($scope.invite_edit_fields.mobile),
        sendStreamingAlerts: $scope.invite_edit_fields.sendStreamingAlerts,
      }
    }

    // determine what roles have been assigned to this user (iterate thru role list and add selected roles to array)
    for (const role of $scope.available_roles){
      if ($scope.invite_edit_fields.selected_roles[role.id] === role.id){
        rolesPayload.roles.push(role.name);
      }
    }

    const requests = [$http.put(`${jcs.api.url}/users/${$scope.invite_edit_fields.uuid}/roles`, rolesPayload, { withCredentials: true }),
    $http.patch(`${jcs.api.url_v3}/customers/${Authentication.getCurrentUser().customerID}/users/${$scope.invite_edit_fields.uuid}`, userPayload, { withCredentials: true })];

    $q.all(requests).then(() => {
      $('#invite-user').modal('hide');
      $scope.loadUsers();
    },
    (error) => {
      console.log(error);
      $scope.invite_user_error =
        'An error occurred while attempting to update the user. Please try again, or report the problem if it persists.';
    }).finally(() => {
      $scope.is_working = false
    });
  };

  $scope.inviteUser = function () {
    $scope.invite_user_error = null;

    // if we have form validation errors, then don't go any further
    if ($scope.doesInviteUserFailValidation()) {
      return false;
    }

    $scope.is_working = true;

    const selected_roles = [];
    for (const role of $scope.available_roles){
      if (role.id in $scope.invite_edit_fields.selected_roles && $scope.invite_edit_fields.selected_roles[role.id] !== ''){
        selected_roles.push(role.id);
      }
    }

    const payload = {
      email: $scope.invite_edit_fields.email,
      first: $scope.invite_edit_fields.first,
      last: $scope.invite_edit_fields.last,
      title: $scope.invite_edit_fields.title,
      mobile: $scope.getPhoneFormattedForDB($scope.invite_edit_fields.mobile),
      sendStreamingAlerts: $scope.invite_edit_fields.sendStreamingAlerts,
      roleIds: selected_roles,
    };

    const mixpanel_data = {
      [MPEventProperty.EMAIL_INVITED]: payload.email,
      [MPEventProperty.PERMISSIONS]: payload.roleIds.map(roleId =>
        $scope.available_roles.find(available_role => available_role.id === roleId).description
      ),
    };

    $http
      .post(`${jcs.api.url_v3}/customers/${Authentication.getCurrentUser().customerID}/userinvites`, payload, { withCredentials: true })
      .then(() => {
        $('#invite-user').modal('hide');
        $scope.loadInvites();

      trackMixpanelEvent(MPEventName.USER_INVITE_SEND, mixpanel_data);
      })
      .catch(reason => {
        if (reason.status === 409){
          $scope.invite_user_error = 'An invitation already exists for this email.';
        } else {
          $scope.invite_user_error = 'An error occurred while attempting to invite the user. Please try again, or report the problem if it persists.';
        }
      })
      .finally(() => {
        $scope.is_working = false;
      });
  };

  $scope.editVenue = function () {
    $scope.edit_venue_error = null;

    if ($scope.doesEditVenueFailValidation()) {
      return false;
    }

    $scope.is_working = true;

    const selected_roles = [];
    for (const role of $scope.available_roles){
      if (role.id in $scope.edit_venue_fields.selected_roles && $scope.edit_venue_fields.selected_roles[role.id] !== ''){
        selected_roles.push(role.name);
      }
    }

    const rolesPayload = {
      roles: selected_roles,
    }

    const venuePayload = {
      userName: $scope.edit_venue_fields.userName,
    }

    const promises = [$http.put(`${jcs.api.url}/users/${$scope.edit_venue_fields.uuid}/roles`, rolesPayload, { withCredentials: true }),
      $http.patch(`${jcs.api.url_v3}/customers/${Authentication.getCurrentUser().customerID}/venues/${$scope.edit_venue_fields.uuid}`, venuePayload, { withCredentials: true })];

    $q.all(promises).then(() => {
      $scope.loadVenues();
      $('#edit-venue').modal('hide');
    }).catch(error => {
      // check to see if the name we provided is already in use
      if (error.status == 409) {
        $scope.edit_venue_error = `The name ${$scope.edit_venue_fields.userName} is already in use. Please choose another name.`;
      } // or we got a different type of error
      else {
        $scope.edit_venue_error =
          'An error occurred while attempting to update the venue. Please try again, or report the problem if it persists.';
      }
    }).finally(() => $scope.is_working = false);
  }

  $scope.enterVenueEditMode = function (site) {
    $scope.validation.clear();
    $scope.edit_venue_error = null;
    $scope.edit_venue_loading_error = null;

    $scope.edit_venue_fields = {
      uuid: site.uuid,
      userName: site.userName,
      selected_roles: {}
    };

    $scope.is_loading_venue = true;
    $('#edit-venue').modal('show');

    const promises = [
      userService.getAvailableRoles(Authentication.getCurrentUser().customerID),
      $http.get(`${jcs.api.url}/users/${site.uuid}/roles`, { withCredentials: true })
    ];

    $q.all(promises).then(([available_roles, user_role_response]) => {

      // filter out socialMedia roles
      $scope.available_roles = available_roles.filter(role => !role.name.includes(userService.SOCIAL_MEDIA_ROLE));

      // mark this venues's roles as 'selected'
      for (const role of user_role_response.data) {
        $scope.edit_venue_fields.selected_roles[role.uuid] = role.uuid;
      }
    }).catch(() => {
      $scope.edit_venue_loading_error = 'An error occurred while retrieving venue information. Please try again, or report the problem if it persists.';
    }).finally(() => $scope.is_loading_venue = false);
  };

  $scope.enterResendInviteMode = function (invite) {
    $scope.invite_to_resend = invite;
    $scope.resend_invite_error = null;
    $('#confirm-resend-invite').modal('show');
  };

  $scope.cancelResendInvite = function () {
    $scope.invite_to_resend = null;
  }

  $scope.enterRevokeInviteMode = function (invite) {
    $scope.invite_to_revoke = invite;
    $scope.revoke_invite_error = null;
    $('#confirm-revoke-invite').modal('show');
  };

  $scope.cancelRevokeInvite = function () {
      $scope.invite_to_revoke = null;
  }

  $scope.resendInvite = function () {

    $scope.resend_invite_error = null;
    $scope.is_working = true;

    const mixpanel_data = {
      [MPEventProperty.EMAIL_INVITED]: $scope.invite_to_resend.email,
    };

    $http
      .post(`${jcs.api.url_v3}/customers/${Authentication.getCurrentUser().customerID}/userinvites/${$scope.invite_to_resend.uuid}/resend`, {}, { withCredentials: true })
      .then(() => {
        $('#confirm-resend-invite').modal('hide');
        $scope.invite_to_resend = null;

        trackMixpanelEvent(MPEventName.USER_INVITE_RESEND, mixpanel_data);
      })
      .catch(reason => {
        $scope.resend_invite_error = 'An error occurred while attempting to resend the invitation. Please try again, or report the problem if it persists.';
      })
      .finally(() => {
        $scope.is_working = false;
      });
  };

  $scope.revokeInvite = function () {

    $scope.revoke_invite_error = null;
    $scope.is_working = true;

    const mixpanel_data = {
      [MPEventProperty.EMAIL_INVITED]: $scope.invite_to_revoke.email,
    };

    $http
      .delete(`${jcs.api.url_v3}/customers/${Authentication.getCurrentUser().customerID}/userinvites/${$scope.invite_to_revoke.uuid}`, { withCredentials: true, data: null })
      .then(() => {

        const index = $scope.invites.indexOf($scope.invite_to_revoke);
        if (index !== -1){
          $scope.invites.splice(index, 1);
        }

        $('#confirm-revoke-invite').modal('hide');
        $scope.invite_to_revoke = null;

		trackMixpanelEvent(MPEventName.USER_INVITE_REVOKE, mixpanel_data);
      })
      .catch(reason => {
        $scope.revoke_invite_error = 'An error occurred while attempting to revoke the invitation. Please try again, or report the problem if it persists.';
      })
      .finally(() => {
        $scope.is_working = false;
      });
  };

  $scope.enterChgPswdMode = function (user) {
    $scope.user_to_chg_pswd = {
      name: user.userName,
      uuid: user.uuid,
    };

    // see app.js for where focus is defined
    focus('new-password');
  };

  $scope.enterChgCurrentUserPswdMode = function () {
    $scope.user_to_chg_pswd = {
      name: $scope.user_profile.name,
      uuid: $scope.user_profile.userID,
    };

    // see app.js for where focus is defined
    focus('new-password');
  };

  $scope.enterAddSocialToOrgMode = function () {
    $scope.toggle_shortcut_social_media = true;
  };

  $scope.cancelAddSocialToOrgMode = function () {
    $scope.saving_toggles_error = null;
    $scope.toggle_shortcut_social_media = false;
  };

  $scope.saveAllSocialTogglesToOrg = function () {
    $scope.saving_toggles_error = null;
    $scope.is_working = true;

    let selected_toggles = $scope.customer_toggles.toggle_ids != null ? $scope.customer_toggles.toggle_ids : [];
    // iterate thru social media toggles and add them if they aren't already there
    for (let i = 0; i < $scope.social_media_toggles.length; i++) {
      const toggle = $scope.social_media_toggles[i];
      if (!selected_toggles.includes(toggle)) {
        selected_toggles.push(toggle);
      }
    }

    const updated_data = {
      toggleIds: selected_toggles,
    };

    $http
      .put(`${jcs.api.url_v3}/customers/${Authentication.getCurrentUser().customerID}/toggleregs`, updated_data, {
        withCredentials: true,
      })
      .then(() => {
        $scope.toggle_shortcut_social_media = false;
        $scope.enterViewTogglesMode();
      })
      .catch(() => {
        $scope.saving_toggles_error =
          'An error occurred while attempting to add the social media toggles to the organization. Please try again, or report the problem if it persists.';
      })
      .finally(() => ($scope.is_working = false));
  };

  $scope.enterEditBillingMode = function (user) {
    $scope.user_to_edit_billing = user;
    $scope.user_to_edit_billing_name = user.userName;
    $scope.user_to_edit_billing_address = user.address;
    $scope.user_to_edit_billing_plan = user.planId;
    $scope.user_to_edit_billing_active = user.active ? $scope.ACTIVE : $scope.INACTIVE;

    $scope.is_busy_loading_user = true;
    trackMixpanelEvent(MPEventName.USER_BILLING_EDIT, {
      [MPEventProperty.ACCOUNT_MODIFIED_UUID]: $scope.user_to_edit_billing.uuid,
      [MPEventProperty.USER_STATUS]: $scope.user_to_edit_billing.active,
      [MPEventProperty.USER_PLAN]: $scope.user_to_edit_billing.userPlanName
    });

    // fetch the available user plans
    $http
      .get(`${jcs.api.url}/userplans`, { withCredentials: true })
      .then((response) => {
        $scope.available_plans = response.data;

        if (!$scope.isBroadcastSite(user) && $scope.hasBroadcastSite()) {
          // if this user isn't the broadcast site, and another site has already been
          // designated as the broadcast site, then remove the broadcast option (because we
          // don't want to allow two broadcast sites)
          let index = -1;
          for (let i = 0; i < $scope.available_plans.length; i++) {
            if ($scope.available_plans[i].name.toLowerCase() == 'broadcast') {
              index = i;
            }
          }
          if (index != -1) {
            $scope.available_plans.splice(index, 1);
          }
        }
      })
      .catch(() => {
        $scope.user_to_edit_billing_error =
          'An error occurred while retrieving the user information. Please try again, or report the problem if it persists.';
        $scope.user_to_edit_billing = null;
      })
      .finally(() => ($scope.is_busy_loading_user = false));
  };

  $scope.getToggleName = function (toggleId) {
    if ($scope.toggles_map != null) {
      if ($scope.toggles_map.hasOwnProperty(toggleId)) {
        return $scope.toggles_map[toggleId];
      }
    }
    return 'Unknown';
  };

  // returns a string of text for the given list of toggle IDs
  $scope.getTogglesAsText = function (list) {
    if (list === null) {
      return '';
    }

    const toggle_names = [];
    for (let i = 0; i < list.length; i++) {
      toggle_names.push($scope.getToggleName(list[i]));
    }
    toggle_names.sort();
    return toggle_names.join(', ');
  };

  $scope.cancelViewTogglesMode = function () {
    $scope.customer_toggles = null;
    $scope.user_toggles = null;
  };

  $scope.enterViewTogglesMode = function () {
    $scope.is_loading_toggle_data = true;

    const promises = [];
    const loadTogglesList = $scope.toggles_map == null;
    // load the toggles for this customer
    promises.push(
      $http.get(`${jcs.api.url_v3}/customers/${Authentication.getCurrentUser().customerID}/toggleregs`, {
        withCredentials: true,
      })
    );
    // if it hasn't already bene loaded, load the list of all available toggles
    if (loadTogglesList) {
      promises.push(
        $http.get(`${jcs.api.url_v3}/customers/${Authentication.getCurrentUser().customerID}/toggles`, {
          withCredentials: true,
        })
      );
    }

    $q.all(promises)
      .then((response) => {
        $scope.loading_toggles_error = null;

        // if complete toggle list was loaded, then format it into a map (for easy lookup of toggle names)
        if (loadTogglesList) {
          $scope.toggles_list = response[1].data;
          // format
          $scope.toggles_map = {};
          for (let i = 0; i < $scope.toggles_list.length; i++) {
            const entry = $scope.toggles_list[i];
            $scope.toggles_map[entry.uuid] = entry.name;
          }
        }

        // convert toggles response into format that is easier to work with
        const toggles = response[0].data;
        // add customer
        $scope.customer_toggles = {
          toggle_list: $scope.getTogglesAsText(toggles.toggleIds),
          toggle_ids: toggles.toggleIds,
        };
        // add users
        $scope.user_toggles = [];
        for (let i = 0; i < toggles.users.length; i++) {
          const user = toggles.users[i];
          $scope.user_toggles.push({
            name: user.userName,
            toggle_list: $scope.getTogglesAsText(user.toggleIds),
            toggle_ids: user.toggleIds,
            userId: user.userId,
            userPlanId: user.userPlanId,
          });
        }
      })
      .catch((error) => {
        if (!httpService.isStatus406(error)) {
          $scope.loading_toggles_error =
            'An error occurred while attempting to load toggle data. Please try again, or report the problem if it persists.';
        }
        $scope.customer_toggles = null;
        $scope.user_toggles = null;
      })
      .finally(() => ($scope.is_loading_toggle_data = false));
  };

  $scope.cancelEditToggles = function () {
    $scope.edit_toggles = null;
    $scope.saving_toggles_error = null;
  };

  $scope.editUserToggles = function (user) {
    $scope.saving_toggles_error = null;

    let selected_toggles = {};
    for (let i = 0; i < user.toggle_ids.length; i++) {
      const toggle = user.toggle_ids[i];
      selected_toggles[toggle] = toggle;
    }

    $scope.edit_toggles = {
      is_user: true,
      user_name: user.name,
      uuid: user.userId,
      selected: selected_toggles,
    };
  };

  $scope.editCustomerToggles = function () {
    $scope.saving_toggles_error = null;

    const selected_toggles = {};
    if ($scope.customer_toggles.toggle_ids != null) {
      for (let i = 0; i < $scope.customer_toggles.toggle_ids.length; i++) {
        const toggle = $scope.customer_toggles.toggle_ids[i];
        selected_toggles[toggle] = toggle;
      }
    }

    $scope.edit_toggles = {
      is_user: false,
      uuid: Authentication.getCurrentUser().customerID,
      selected: selected_toggles,
    };
  };

  $scope.saveToggleChanges = function () {
    $scope.saving_toggles_error = null;
    $scope.is_busy_saving_toggles = true;

    const customerId = Authentication.getCurrentUser().customerID;

    const http_url = $scope.edit_toggles.is_user
      ? `${jcs.api.url_v3}/customers/${customerId}/users/${
          $scope.edit_toggles.uuid
        }/toggleregs`
      : `${jcs.api.url_v3}/customers/${customerId}/toggleregs`;

    const selected_toggles = [];
    // iterate thru our selected toggles and build a list of IDs
    for (let property in $scope.edit_toggles.selected) {
      if ($scope.edit_toggles.selected.hasOwnProperty(property)) {
        if ($scope.edit_toggles.selected[property] == property) {
          selected_toggles.push(property);
        }
      }
    }

    const updated_data = {
      toggleIds: selected_toggles,
    };

    // update user
    $http
      .put(http_url, updated_data, { withCredentials: true })
      .then(() => {
        trackMixpanelEvent(MPEventName.TOGGLE_EDIT, {
          [MPEventProperty.TOGGLE_TYPE]: $scope.edit_toggles.is_user ? 'user' : 'organization',
          [MPEventProperty.TOGGLES]: selected_toggles.map((e) => $scope.toggles_map[e]),
          [MPEventProperty.ACCOUNT_MODIFIED_UUID]: $scope.edit_toggles.is_user ? $scope.edit_toggles.uuid : customerId
        });

        $scope.edit_toggles = null;
        // reload our toggle list
        $scope.enterViewTogglesMode();
      })
      .catch(() => {
        $scope.saving_toggles_error =
          'An error occurred while attempting to update the toggles. Please try again, or report the problem if it persists.';
      })
      .finally(() => ($scope.is_busy_saving_toggles = false));
  };

  // edit mode available for customers
  $scope.enterNewEditMode = function (user) {

    $scope.validation.clear();
    $scope.invite_user_error = null;
    $scope.invite_user_loading_error = null;

    $scope.invite_edit_fields = {
      uuid: user.uuid,
      first: user.contact?.first,
      last: user.contact?.last,
      title: user.contact?.title,
      email: user.userName,
      mobile: $scope.getPhoneFormattedForUI(user.contact?.mobile),
      sendStreamingAlerts: user.contact?.sendStreamingAlerts,
      planName: user.planName,
      selected_roles: {},
    };

    $scope.is_loading_new_user = true;

    $('#invite-user').modal('show');

    const promises = [
      userService.getAvailableRoles(Authentication.getCurrentUser().customerID),
      $http.get(`${jcs.api.url}/users/${user.uuid}/roles`, { withCredentials: true })
    ];

    $q.all(promises).then(([available_roles, user_role_response]) => {

      // filter out socialMedia roles
      $scope.available_roles = available_roles.filter(role => !role.name.includes(userService.SOCIAL_MEDIA_ROLE));

      // mark this user's roles as 'selected'
      for (const role of user_role_response.data){
        $scope.invite_edit_fields.selected_roles[role.uuid] = role.uuid;
      }

    }).catch(reason => {

      $scope.invite_user_loading_error = 'An error occurred while retrieving user information. Please try again, or report the problem if it persists.';

    }).finally(() => {

      $scope.is_loading_new_user = false;

    });
  };

  $scope.enterEditMode = function (user) {
    $scope.user_to_edit = user;
    $scope.user_to_edit_name = user.userName;
    $scope.user_to_edit_roles = { selected: {} };
    $scope.userToEditFeatures = { selected: {} };

    $scope
      .fetchAvailableFeatures()
      .then((features) => {
        $scope.availableFeatures = features;
        features.forEach((feature) => {
          $scope.userToEditFeatures.selected[feature.key] = '';
        });

        $scope.fetchAssignedFeatures(user).then((assignedFeatures) => {
          assignedFeatures.forEach((assignedFeature) => {
            $scope.userToEditFeatures.selected[assignedFeature.featureKey] = assignedFeature.fkFeature;
          });
        });
      })
      .catch((error) => {
        $scope.add_user_error =
          'An error occurred while retrieving user feature information. Please try again, or report the problem if it persists.';
      });

    // since we have nested api calls, we'll have to handle turning off the "busy indicator" differently. We'll have to do it
    // once in the outer api call's error clause, and once in the inner api call's finally clause.
    $scope.is_busy_loading_user = true;

    // get list of all available roles
    userService.getAvailableRoles(Authentication.getCurrentUser().customerID)
      .then(available_roles => {
        // assign available roles (this will control what roles are displayed in the UI)
        $scope.available_roles = available_roles;

        // initialize our selected roles object (we need each role property to be set to empty)
        for (let i = 0; i < $scope.available_roles.length; i++) {
          const role = $scope.available_roles[i].name;
          $scope.user_to_edit_roles.selected[role] = '';
        }

        // get the roles that have been assigned to this user
        $http
          .get(`${jcs.api.url}/users/${$scope.user_to_edit.uuid}/roles`, { withCredentials: true })
          .then((response) => {
            // mark this user's roles as 'selected'
            for (let j = 0; j < response.data.length; j++) {
              const role = response.data[j].name;
              $scope.user_to_edit_roles.selected[role] = role;
            }
          })
          .catch(() => {
            $scope.user_to_edit_error =
              'An error occurred while retrieving the user information. Please try again, or report the problem if it persists.';
            $scope.user_to_edit = null;
          })
          .finally(() => {
            $scope.is_busy_loading_user = false;

            // see app.js for where focus is defined
            focus('user-name-input');
          });
      })
      .catch(() => {
        $scope.user_to_edit_error =
          'An error occurred while retrieving the user information. Please try again, or report the problem if it persists.';
        $scope.is_busy_loading_user = false;
        $scope.user_to_edit = null;
      });
  };

  $scope.enterAddUserMode = function () {
    $scope.is_adding_user = true;
    $scope.user_to_add_name = '';
    $scope.user_to_add_address = '';
    $scope.user_to_add_roles = { selected: {} };
    $scope.userToAddFeatures = { selected: {} };
    $scope.user_to_add_plan = null;
    $scope.user_to_add_active = $scope.ACTIVE;

    $scope.is_busy_loading_user = true;

    $scope
      .fetchAvailableFeatures()
      .then((features) => {
        $scope.availableFeatures = features;
        features.forEach((feature) => {
          $scope.userToAddFeatures.selected[feature.key] = '';
        });
      })
      .catch((error) => {
        $scope.add_user_error =
          'An error occurred while retrieving user feature information. Please try again, or report the problem if it persists.';
      });

    // get list of all available roles
    userService.getAvailableRoles(Authentication.getCurrentUser().customerID)
      .then(available_roles => {
        // assign available roles (this will control what roles are displayed in the UI)
        $scope.available_roles = available_roles;

        // initialize our selected roles object (we need each role property to be set to empty)
        for (let i = 0; i < $scope.available_roles.length; i++) {
          const role = $scope.available_roles[i].name;
          $scope.user_to_add_roles.selected[role] = '';
        }

        // fetch all event profiles for this customer
        $http
          .get(`${jcs.api.url}/streamprofiles`, { withCredentials: true })
          .then((response) => {
            $scope.add_user_error = null;
            $scope.customer_event_profile_list = response.data;

            // intialize all event profiles to be "selected"
            $scope.user_to_add_event_profiles = { selected: {} };
            for (let i = 0; i < $scope.customer_event_profile_list.length; i++) {
              const uuid = $scope.customer_event_profile_list[i].uuid;
              $scope.user_to_add_event_profiles.selected[uuid] = uuid;
            }

            // fetch the available user plans
            $http
              .get(`${jcs.api.url}/userplans`, { withCredentials: true })
              .then((response) => {
                $scope.available_plans = response.data;

                if ($scope.hasBroadcastSite()) {
                  // if another site has already been designated as the broadcast site, then
                  // remove the broadcast option (because we don't want to allow two broadcast sites)
                  let index = -1;
                  for (let i = 0; i < $scope.available_plans.length; i++) {
                    if ($scope.available_plans[i].name.toLowerCase() == 'broadcast') {
                      index = i;
                    }
                  }
                  if (index != -1) {
                    $scope.available_plans.splice(index, 1);
                  }
                }
              })
              .catch(() => {
                $scope.add_user_error =
                  'An error occurred while retrieving user plan information. Please try again, or report the problem if it persists.';
                $scope.is_adding_user = false;
              })
              .finally(() => {
                $scope.is_busy_loading_user = false;
                // see app.js for where focus is defined
                focus('add-user-input');
              });
          })
          .catch(() => {
            $scope.add_user_error =
              'An error occurred while retrieving event profile information. Please try again, or report the problem if it persists.';
            $scope.customer_event_profile_list = null;
            $scope.is_adding_user = false;
            $scope.is_busy_loading_user = false;
          });
      })
      .catch(() => {
        $scope.add_user_error =
          'An error occurred while retrieving user role information. Please try again, or report the problem if it persists.';
        $scope.is_adding_user = false;
        $scope.is_busy_loading_user = false;
      });
  };

  $scope.fetchAvailableFeatures = function () {
    return $http
      .get(`${jcs.api.url_v3}/customers/${Authentication.getCurrentUser().customerID}/features`, {
        withCredentials: true,
      })
      .then((response) => response.data);
  };

  $scope.fetchAssignedFeatures = function (user) {
    return $http
      .get(`${jcs.api.url_v3}/customers/${Authentication.getCurrentUser().customerID}/users/${user.uuid}/features`, {
        withCredentials: true,
      })
      .then((response) => response.data);
  };

  $scope.selectAllEventProfiles = function () {
    for (let i = 0; i < $scope.customer_event_profile_list.length; i++) {
      const uuid = $scope.customer_event_profile_list[i].uuid;
      $scope.user_to_add_event_profiles.selected[uuid] = uuid;
    }
  };

  $scope.clearAllEventProfiles = function () {
    $scope.user_to_add_event_profiles = { selected: {} };
  };

  $scope.enterDeleteUserMode = function (user) {
    $scope.user_to_delete = user;
    $scope.delete_user_error = null;
    $('#confirm-delete-user').modal('show');
  };

  $scope.cancelChgPswd = function () {
    $scope.user_to_chg_pswd = null;
    $scope.user_to_chg_pswd_error = null;
    $scope.new_password = null;
    $scope.new_password2 = null;
    $scope.validation.clear();
  };

  $scope.cancelEditBilling = function () {
    $scope.user_to_edit_billing = null;
    $scope.user_to_edit_billing_name = '';
    $scope.user_to_edit_billing_address = '';
    $scope.user_to_edit_billing_active = $scope.ACTIVE_DEFAULT;
    $scope.user_to_edit_billing_plan = null;
    $scope.user_to_edit_billing_error = null;
  };

  $scope.cancelEdit = function () {
    $scope.user_to_edit = null;
    $scope.user_to_edit_error = null;
    $scope.user_to_edit_name = '';
    $scope.validation.clear();
  };

  $scope.cancelAdd = function () {
    $scope.is_adding_user = false;
    $scope.add_user_error = null;
  };

  $scope.cancelDelete = function () {
    $scope.user_to_delete = null;
    $scope.delete_user_error = null;
  };

  // returns TRUE if we have a form validation issue that the user needs to address
  $scope.doesSavePswdFailValidation = function () {
    $scope.user_to_chg_pswd_error = null;
    $scope.validation.clear();

    // ensure required fields are not empty
    $scope.validation.checkForEmpty('new_password', $scope.new_password);
    $scope.validation.checkForEmpty('new_password2', $scope.new_password2);

    const has_validation_error = $scope.validation.hasError();
    if (has_validation_error) {
      $scope.user_to_chg_pswd_error = 'Please specify a value for the highlighted fields.';
    } else {
      // ensure passwords match
      if ($scope.new_password != $scope.new_password2) {
        $scope.user_to_chg_pswd_error = 'Both passwords do not match. Please re-enter the new password.';
        return true;
      }
    }

    return has_validation_error;
  };

  $scope.savePswd = function () {
    // if we have form validation errors, then don't go any further
    if ($scope.doesSavePswdFailValidation()) return false;

    const updated_data = {
      password: $scope.new_password,
    };

    $scope.is_busy_changing_pswd = true;

	const mixpanel_data = {
		[MPEventProperty.ACCOUNT_MODIFIED_UUID]: $scope.user_to_chg_pswd.uuid,
		[MPEventProperty.ACCOUNT_MODIFIED_NAME]: $scope.user_to_chg_pswd.name,
	};

    // update user
    $http
      .patch(`${jcs.api.url}/users/${$scope.user_to_chg_pswd.uuid}`, updated_data, { withCredentials: true })
      .then(() => {
        // update was successful. since only password was changed, no fields to update
        $scope.user_to_chg_pswd = null;
        $scope.user_to_chg_pswd_error = null;
        $scope.new_password = null;
        $scope.new_password2 = null;

		trackMixpanelEvent(MPEventName.PASSWORD_CHANGE, mixpanel_data);
      })
      .catch(() => {
        $scope.user_to_chg_pswd_error =
          'An error occurred while attempting to change the password. Please try again, or report the problem if it persists.';
      })
      .finally(() => ($scope.is_busy_changing_pswd = false));
  };

  $scope.getPlanNameForId = function (planId) {
    for (let i = 0; i < $scope.available_plans.length; i++) {
      if (planId == $scope.available_plans[i].uuid) {
        return $scope.available_plans[i].name;
      }
    }
    return '';
  };

  $scope.saveEditBilling = function () {
    const updated_data = {
      planId: $scope.user_to_edit_billing_plan,
      address: $scope.user_to_edit_billing_address,
      active: $scope.user_to_edit_billing_active == $scope.ACTIVE ? true : false,
    };

    // since we have nested api calls, we'll have to handle turning off the "busy indicator" differently. We'll have to do it
    // once in the outer api call's error clause, and once in the inner api call's finally clause.
    $scope.is_busy_editing_user = true;

    // update user
    $http
      .patch(`${jcs.api.url}/users/${$scope.user_to_edit_billing.uuid}/billing`, updated_data, {
        withCredentials: true,
      })
      .then(() => {
        // update was successful, so update our actual user in the model
        // $scope.user_to_edit_billing.address = $scope.user_to_edit_billing_address;
        // $scope.user_to_edit_billing.planId = $scope.user_to_edit_billing_plan;
        // $scope.user_to_edit_billing.planName = $scope.getPlanNameForId($scope.user_to_edit_billing_plan);
        // $scope.user_to_edit_billing.active = ($scope.user_to_edit_billing_active == $scope.ACTIVE) ? true : false;

        $scope.user_to_edit_billing = null;
        $scope.user_to_edit_billing_error = null;

        $scope.loadUsers();
      })
      .catch(() => {
        $scope.user_to_edit_billing_error =
          'An error occurred while attempting to update the user. Please try again, or report the problem if it persists.';
      })
      .finally(() => ($scope.is_busy_editing_user = false));
  };

  // returns TRUE if we have a form validation issue that the user needs to address
  $scope.doesSaveEditFailValidation = function () {
    $scope.user_to_edit_error = null;
    $scope.validation.clear();

    // ensure required fields are not empty
    $scope.validation.checkForEmpty('user_to_edit_name', $scope.user_to_edit_name);

    const has_validation_error = $scope.validation.hasError();
    if (has_validation_error) {
      $scope.user_to_edit_error = 'Please specify a value for the highlighted fields.';
    }

    return has_validation_error;
  };

  $scope.saveEdit = function () {
    // if we have form validation errors, then don't go any further
    if ($scope.doesSaveEditFailValidation()) return false;

    const updated_data = {
      userName: $scope.user_to_edit_name,
    };

    // since we have nested api calls, we'll have to handle turning off the "busy indicator" differently. We'll have to do it
    // once in the outer api call's error clause, and once in the inner api call's finally clause.
    $scope.is_busy_editing_user = true;

    // update user
    $http
      .patch(`${jcs.api.url}/users/${$scope.user_to_edit.uuid}`, updated_data, { withCredentials: true })
      .then(() => {
        // update was successful, so update our actual user in the model
        $scope.user_to_edit.userName = $scope.user_to_edit_name;

        // determine what roles have been assigned to this user (iterate thru role list and add selected roles to array)
        const selected_roles = [];
        for (let i = 0; i < $scope.available_roles.length; i++) {
          const role = $scope.available_roles[i].name;
          if ($scope.user_to_edit_roles.selected[role] != '') selected_roles.push(role);
        }

        const updated_roles = {
          roles: selected_roles,
        };

        trackMixpanelEvent(MPEventName.USER_RESI_EDIT, {
          [MPEventProperty.ACCOUNT_MODIFIED_UUID]: $scope.user_to_edit.uuid,
          [MPEventProperty.PERMISSIONS]: selected_roles,
          [MPEventProperty.PERMISSIONS]: $scope.user_to_edit.userPlanName
        });

        // update user roles
        $http
          .put(`${jcs.api.url}/users/${$scope.user_to_edit.uuid}/roles`, updated_roles, { withCredentials: true })
          .then(() => {
            $scope.user_to_edit = null;
            $scope.user_to_edit_error = null;
          })
          .catch((response) => {
            if (response.status == 401) {
              // if user renamed their own account, then they will need to reauth themselves
              authentication.logout();
            } else {
              $scope.user_to_edit_error =
                'An error occurred while attempting to update the user. Please try again, or report the problem if it persists.';
            }
          });

        // update user features
        const selectedFeatures = [];
        $scope.availableFeatures.forEach((feature) => {
          if (
            feature.key in $scope.userToEditFeatures.selected &&
            $scope.userToEditFeatures.selected[feature.key] !== ''
          ) {
            selectedFeatures.push(feature.uuid);
          }
        });
        $http
          .put(
            `${jcs.api.internal_url}/users/${$scope.user_to_edit.uuid}/features`,
            { featureIds: selectedFeatures },
            { withCredentials: true }
          )
          .catch(() => {
            $scope.add_user_error =
              'An error occurred while attempting to update the user. Please try again, or report the problem if it persists.';
          })
          .finally(() => {
            $scope.is_busy_editing_user = false;
          });
      })
      .catch((error) => {
        // check to see if the name we provided is already in use
        if (error.status == 409) {
          $scope.user_to_edit_error = `The name ${$scope.user_to_edit_name} is already in use. Please choose another name.`;
        } // or we got a different type of error
        else {
          $scope.user_to_edit_error =
            'An error occurred while attempting to update the user. Please try again, or report the problem if it persists.';
        }
        $scope.is_busy_editing_user = false;
      });
  };

  $scope.saveNewUser = function () {
    const new_user_data = {
      userName: $scope.user_to_add_name,
      password: 'temp.pass.change.me',
      encoderEventProfiles:  $scope.customer_event_profile_list
        .filter(({uuid}) => Boolean(uuid) && uuid in $scope.user_to_add_event_profiles.selected)
        .map(({uuid}) => ({streamProfileId: uuid, canSetCues: true})),
    };
    // only LAO admin can set plan
    if (Authentication.getCurrentUser().hasPerm('users.billing')) {
      new_user_data.planId = $scope.user_to_add_plan;
      new_user_data.address = $scope.user_to_add_address;
      new_user_data.active = $scope.user_to_add_active == $scope.ACTIVE ? true : false;
    }

    // since we have nested api calls, we'll have to handle turning off the "busy indicator" differently. We'll have to do it
    // once in the outer api call's error clause, and once in the inner api call's finally clause.
    $scope.is_busy_adding_user = true;

    // send ajax request to create new user
    $http
      .post(`${jcs.api.url}/users`, new_user_data, { withCredentials: true })
      .then((data, status, headers, config) => {
        // get the uuid of the user that was just created
        const new_uuid = data.headers('uuid');

        // determine what roles have been assigned to this user (iterate thru role list and add selected roles to array)
        const selected_roles = [];
        for (let i = 0; i < $scope.available_roles.length; i++) {
          const role = $scope.available_roles[i].name;
          if (role in $scope.user_to_add_roles.selected && $scope.user_to_add_roles.selected[role] != '')
            selected_roles.push(role);
        }

        const updated_roles = {
          roles: selected_roles,
        };

        trackMixpanelEvent(MPEventName.USER_VENUE_ADD, {
          [MPEventProperty.ACCOUNT_MODIFIED_UUID]: new_uuid,
          [MPEventProperty.USER_PLAN]: $scope.available_plans.find((e) => e.uuid === $scope.user_to_add_plan)?.name
        });

        // update user roles
        $http
          .put(`${jcs.api.url}/users/${new_uuid}/roles`, updated_roles, { withCredentials: true })
          .then(() => {
            // process features
            const selectedFeatures = [];
            $scope.availableFeatures.forEach((feature) => {
              if (
                feature.key in $scope.userToAddFeatures.selected &&
                $scope.userToAddFeatures.selected[feature.key] !== ''
              ) {
                selectedFeatures.push(feature.uuid);
              }
            });
            $http
              .put(
                `${jcs.api.internal_url}/users/${new_uuid}/features`,
                { featureIds: selectedFeatures },
                { withCredentials: true }
              )
              .catch(() => {
                $scope.add_user_error =
                  'The user has been created, but an error occurred while attempting to assign features. Please report this problem if it persists.';
              });

            $scope.is_adding_user = false;
            $scope.user_to_add_name = '';
            $scope.user_to_add_address = '';
            $scope.user_to_add_plan = null;
            $scope.user_to_add_active = null;
            $scope.add_user_error = null;

            $scope.loadUsers();
          })
          .catch(() => {
            $scope.add_user_error =
              'The user has been created, but an error occurred while attempting to assign the permission roles. Press "Cancel" to go back to the user\'s list and try editing the user to assign them the proper roles. Please report this problem if it persists.';
            $scope.loadUsers();
          })
          .finally(() => ($scope.is_busy_adding_user = false));
      })
      .catch((error) => {
        // check to see if the name we provided is already in use
        if (error.status == 409) {
          $scope.add_user_error = `The name ${$scope.user_to_add_name} is already in use. Please choose another name.`;
        } // or we got a different type of error
        else {
          $scope.add_user_error =
            'An error occurred while attempting to create the user. Please try again, or report the problem if it persists.';
        }
        $scope.is_busy_adding_user = false;
      });
  };

  $scope.deleteUser = function () {
    $scope.delete_user_error = null;
    $scope.is_busy_deleting_user = true;

    // make http request to delete user
    // NOTE: I normally would not include the "data: null", but IE11 and below seem to require it in order to work
    // see: https://github.com/angular/angular.js/issues/12141
    $http
      .delete(`${jcs.api.url_v3}/customers/${Authentication.getCurrentUser().customerID}/users/${$scope.user_to_delete.uuid}`, { withCredentials: true, data: null, })
      .then(() => {
        // determine which list user/site is from
        let list = $scope.isUser($scope.user_to_delete) ? $scope.users : $scope.sites;
        // remove from list
        const index = list.indexOf($scope.user_to_delete);
        if (index > -1) {
          list.splice(index, 1);
        }

        $('#confirm-delete-user').modal('hide');

        trackMixpanelEvent(MPEventName.USER_DELETE, {
          [MPEventProperty.ACCOUNT_MODIFIED_UUID]: $scope.user_to_delete.uuid,
        });

        $scope.user_to_delete = null;
        $scope.delete_user_error = null;
      })
      .catch(() => {
        $scope.delete_user_error = 'An error occurred while attempting to delete the user. Please try again, or report the problem if it persists.';
      })
      .finally(() => {
        $scope.is_busy_deleting_user = false;
      });
  };

  // returns true if the given site is the "broadcast" site
  $scope.isBroadcastSite = function (site) {
    // make sure planName doesn't happen to be null
    if (site.planName == null) return false;
    return site.planName.toLowerCase() == 'broadcast';
  };

  // returns true if one of our sites has been designated as the "broadcast" site
  $scope.hasBroadcastSite = function () {
    if ($scope.sites != null) {
      for (let i = 0; i < $scope.sites.length; i++) {
        const site = $scope.sites[i];
        if ($scope.isBroadcastSite(site)) {
          return true;
        }
      }
    }
    return false;
  };

  $scope.isUser = function (item) {
    // make sure planName doesn't happen to be null
    if (!item || item.planName == null) return false;
    return item.planName.toLowerCase() === 'user';
  };

  $scope.load = function () {
    $scope.loadInvites();
    $scope.loadUsers();
    $scope.loadVenues();
  };

  $scope.loadInvites = function () {
    if (!Authentication.getCurrentUser().hasPerm('invited_users.get')){
      return;
    }

    $scope.invites_error_msg = null;
    $scope.loading_invites = true;

    $http
      .get(`${jcs.api.url_v3}/customers/${Authentication.getCurrentUser().customerID}/userinvites`, { withCredentials: true })
      .then(response => {
        $scope.invites = response.data;
      })
      .catch(() => {
        $scope.invites_error_msg = 'Unable to retrieve user invitations. Please try again, or report the problem if it persists.';
      })
      .finally(() => ($scope.loading_invites = false));
  };

  $scope.loadUsers = function () {
    $scope.users_error_msg = null;
    $scope.loading_users = true;
    $http.get(`${jcs.api.url_v3}/customers/${Authentication.getCurrentUser().customerID}/users`, { withCredentials: true })
      .then((response) => $scope.users = response.data)
      .catch(() => {
        $scope.users_error_msg = 'Unable to retrieve sites and users. Please try again, or report the problem if it persists.';
        $scope.users_error_msg = 'Unable to retrieve users. Please try again, or report the problem if it persists.';
        $scope.users = null;
      })
      .finally(() => $scope.loading_users = false);
  };

    $scope.loadVenues = function () {
      $scope.users_error_msg = null;
      $scope.loading_users = true;
      $http.get(`${jcs.api.url_v3}/customers/${Authentication.getCurrentUser().customerID}/venues`, { withCredentials: true })
        .then((response) => $scope.sites = response.data)
        .catch(() => {
          $scope.users_error_msg = 'Unable to retrieve sites. Please try again, or report the problem if it persists.';
           $scope.sites = null;
         })
        .finally(() => ($scope.loading_users = false));
    };

  //
  // determine if we need to load the current user's profile page, or we should just show the users list
  //
  if ($location.search().action == 'changepswd') {
    $scope.user_profile = Authentication.getCurrentUser();
  } else if (Authentication.getCurrentUser().hasPerm('users.get')) {
    // initialize by loading invites/sites/users
    $scope.load();
  } else {
    // if not changing password and attempting to load users list but don't have permission, then send to "not authorised" page
    $location.url('/not-authorised');
  }

	$scope.getPhoneFormattedForUI = function (phone_number) {
		if (libphonenumber && phone_number) {
			const phoneNumber = libphonenumber.parsePhoneNumberFromString(phone_number, 'US');
			if (phoneNumber) {
				return phoneNumber.country == 'US' ? phoneNumber.formatNational() : phoneNumber.formatInternational();
			}
		}
		return phone_number;
	};

	$scope.getPhoneFormattedForDB = function (phone_number) {
		if (libphonenumber && phone_number) {
			const phoneNumber = libphonenumber.parsePhoneNumberFromString(phone_number, 'US');
			if (phoneNumber) {
				return phoneNumber.number; // will return number with no spaces (ex: +12149718025 or +4915254193315)
			}
		}
		return phone_number;
	};

  // auto focus input element when dialog is opened
  $('#invite-user').on('shown.bs.modal', function (e) {
    focus('invite-user-email');
  });
}

module.exports = UsersController;
