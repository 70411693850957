import { LinkButton, PrimaryButton } from '@resi-media/resi-ui';
import React, { FC, useContext, useEffect, useState } from 'react';
import { ModalContent, ModalFooter } from '../../..';
import { MODAL_CONTENT_MIN_HEIGHT } from '../../../../constants/wizard-constants';
import useUpdateUploadError from '../../../../hooks/use-update-upload-errror';
import { ACTION_TYPE, EventUploadContext } from '../../../EventUploadModal/context';
import { validateRequiredField } from '../../../EventUploadModal/eventUploadHelpers';
import { VALIDATIONS } from '../../../EventUploadModal/eventUploadTypes';
import { UploadWizardSteps } from '../../upload-wizard';
import { trackMixpanelEvent, MPEventName, MPEventProperty } from '../../../../mixpanel';

interface Step1ProfileAndNameProps {
  closeModal: () => void;
}

const Step1ProfileAndName: FC<Step1ProfileAndNameProps> = ({ closeModal }): JSX.Element => {
  const { state, dispatch } = useContext(EventUploadContext);
  const [enableNext, setEnableNext] = useState(false);
  const { updateError } = useUpdateUploadError();

  useEffect(() => {
    if (!state.eventName?.trim?.()?.length || !state.eventProfile?.length) {
      setEnableNext(false);
      return;
    }
    setEnableNext(true);
  }, [state.eventName, state.eventProfile]);

  const handleEventName = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    validateRequiredField(value, VALIDATIONS.BLANK_EVENT_NAME, updateError);
    dispatch({ type: ACTION_TYPE.NAME, payload: value });
  };

  const handleEventProfile = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target;
    validateRequiredField(value, VALIDATIONS.BLANK_EVENT_PROFILE, updateError);
    dispatch({ type: ACTION_TYPE.PROFILE, payload: value });
  };

  return (
    <>
      <ModalContent minHeight={MODAL_CONTENT_MIN_HEIGHT}>
        <p>
          Uploading a file creates an encoder event that may be played back on a decoder or to create a web event for
          playback on an embed code, stream URLs, and social destinations.
        </p>
        <div className="resi-upload-wizard-half-input-wrap">
          <label className="control-label resi-upload-wizard-label" htmlFor="event-name">
            Event Name
          </label>
          <input
            onChange={handleEventName}
            disabled={state.isUploading}
            value={state.eventName}
            className="form-control la1-evt-upload-input"
            id="event-name"
            data-testid="event-name-input"
            maxLength={45}
          />
        </div>
        <br />
        <div className="resi-upload-wizard-half-input-wrap">
          <label className="control-label resi-upload-wizard-label" htmlFor="event-profile">
            Encoder Event Profile
          </label>
          {/* eslint-disable-next-line jsx-a11y/no-onchange */}
          <select
            value={state.eventProfile}
            disabled={state.isUploading}
            onChange={handleEventProfile}
            id="event-profile"
            data-testid="event-profiles-select"
            className="form-control la1-evt-upload-input"
          >
            <option value="" disabled></option>
            {state.profileList.map((x) => (
              <option value={x.uuid} key={x.uuid} data-testid={x.name}>
                {x.name}
              </option>
            ))}
          </select>
        </div>
        <div className="resi-upload-wizard-input-help-text">This is where you will store your event</div>
      </ModalContent>
      <ModalFooter>
        <div className="la1-evt-upload-m-t-1 float-left">
          <a
            href="https://docs.resi.io/articles/#!easy-upload-video-guide/uploading-a-video-file-in-control"
            target="_blank"
            rel="noopener noreferrer"
          >
            Upload Video Documentation
          </a>
        </div>
        <div className="resi-upload-wizard-footer-wrap split-elements">
          <LinkButton onClick={closeModal} label="Cancel" />
          <PrimaryButton
            isDisabled={!enableNext}
            onClick={() => {
              dispatch({ type: ACTION_TYPE.SET_WIZARD_STEP, payload: UploadWizardSteps.WEB_EVENT_CREATE });
              trackMixpanelEvent(MPEventName.VIDEO_UPLOAD_START, {
                [MPEventProperty.EVENT_NAME]: state.eventName,
                [MPEventProperty.ENCODER_EVENT_PROFILE_UUID]: state.eventProfile,
                [MPEventProperty.ENCODER_EVENT_PROFILE_NAME]: state.profileList?.find(
                  (e) => e.uuid === state.eventProfile
                )?.name,
              });
            }}
            label="Next"
          />
        </div>
      </ModalFooter>
    </>
  );
};

export default Step1ProfileAndName;
