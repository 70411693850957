import React, { FC, useState } from 'react';
import PropTypes from 'prop-types';
import { SetPassword } from '../../components';
import { resetPassword } from './api';
import { theme, ThemeProvider } from '@resi-media/resi-ui';

interface PasswordSetPageProps {
  encryptedToken: string;
}

const PasswordSetPage: FC<PasswordSetPageProps> = ({ encryptedToken }) => {
  const [error, setError] = useState(undefined);

  const handleSubmit = async (password: string): Promise<void> => {
    try {
      setError(undefined);
      await resetPassword(encryptedToken, password);
      // redirect user to events
      window.location.assign('/events');
    } catch (e) {
      setError(e.message);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <SetPassword
        header="Set Password"
        subheader="Please set your password"
        buttonLabel="Set Password"
        error={error}
        onSubmit={handleSubmit}
        />
      </ThemeProvider>
  );
};

PasswordSetPage.displayName = 'PasswordSetPage';
PasswordSetPage.propTypes = {
  encryptedToken: PropTypes.string.isRequired,
};

export default PasswordSetPage;
