import React, { FC, useEffect, useRef } from 'react';
import { customProperties as vars } from '@resi-media/resi-ui';
import { ChartTypeRegistry, TooltipItem } from 'chart.js';
import Chart from 'chart.js/auto';
import startCase from 'lodash/startCase';
import PropTypes from 'prop-types';
import { DataObject, lineChartDataType } from '../../../pages/EventAnalytics/types';
import { legendItemType } from '../types';
import { LineChartCanvas, LineChartLegendList, LineChartLegendWrapper, LineChartWrapper } from './line-chart.styles';
import { getLineChartProps } from './utils';
import zoomPlugin, { resetZoom } from 'chartjs-plugin-zoom';
import annotationPlugin from 'chartjs-plugin-annotation';
import { Duration } from 'luxon';
import { DESTINATION_TYPES } from '../../../pages/EventAnalytics/event-analytics-constants';

Chart.register(zoomPlugin, annotationPlugin);
export interface LineChartProp {
  data: lineChartDataType;
  dataPageName: string;
  destinationPage?: DESTINATION_TYPES;
  tooltipLabel?: string;
  videoCuesData?: DataObject[] | null;
  yAxisTickGenerator?: (label: number) => string;
  toolTipFormatGenerator?: (tooltipItem: TooltipItem<keyof ChartTypeRegistry>) => string;
}

const LineChart: FC<LineChartProp> = ({
  data,
  dataPageName,
  destinationPage,
  tooltipLabel,
  videoCuesData,
  yAxisTickGenerator,
  toolTipFormatGenerator,
}): JSX.Element => {
  const lineChartRef = useRef<Chart>(null);
  const lineChartObjectRef = useRef<Chart>(null);
  const lineChartLegendRef = useRef<HTMLUListElement>(null);
  const dataObject = getLineChartProps(data, dataPageName, destinationPage);

  const annotations = videoCuesData?.map((element) => {
    const minutes = Duration.fromISOTime(element.position as string).toFormat('m');
    if (videoCuesData !== null) {
      return {
        type: 'line',
        scaleID: 'x',
        borderWidth: 1,
        borderColor: '#627ea3',
        value: Number(minutes) / 5,
        label: {
          enabled: true,
          backgroundColor: '#293545',
          content: element.name,
        },
      };
    } else {
      return {};
    }
  });

  const chartOptions = {
    maintainAspectRatio: false,
    animation: false,
    scales: {
      y: {
        stacked: true,
        beginAtZero: true,
        ticks: {
          precision: 0,
          callback: (value: number): string | number => {
            if (yAxisTickGenerator) {
              return yAxisTickGenerator(value as number);
            }
            return Math.abs(value as number) > 999 ? Number((value as number) / 1000).toFixed(1) + 'K' : value;
          },
        },
      },
      x: {
        grid: {
          display: false,
        },
        ticks: {
          autoSkip: true,
          maxTicksLimit: 12,
          minRotation: 0,
          maxRotation: 0,
        },
      },
    },
    plugins: {
      annotation: {
        annotations: annotations,
      },
      zoom: {
        zoom: {
          wheel: {
            enabled: true,
          },
          pinch: {
            enabled: true,
          },
          mode: 'x',
        },
      },
      lineChartLegend: {
        // ID of the container to put the legend in
        containerID: 'linechart-legend',
      },
      legend: {
        display: false,
      },
      tooltip: {
        position: 'nearest',
        enabled: true,
        callbacks: {
          label: (tooltipItem: TooltipItem<keyof ChartTypeRegistry>) => {
            if (toolTipFormatGenerator) {
              return toolTipFormatGenerator(tooltipItem);
            }
            return ` ${startCase(tooltipItem.dataset.label) || ''} : ${
              tooltipItem.dataset.data[tooltipItem.dataIndex]
            } ${tooltipLabel}`;
          },
        },
      },
    },
  };

  const htmlLegendPlugin = {
    id: 'lineChartLegend',
    afterUpdate(chart: Chart) {
      const ul = lineChartLegendRef.current;
      if (!ul) {
        return;
      }
      ul.style.flexWrap = 'wrap';
      ul.style.justifyContent = 'space-between';

      // Remove old legend items
      while (ul.firstChild) {
        ul.firstChild.remove();
      }
      // Reuse the built-in legendItems generator
      const items = chart.options.plugins.legend.labels.generateLabels(chart);

      items.forEach((item: legendItemType, index: number) => {
        const li = document.createElement('li');
        li.style.alignItems = 'center';
        li.style.cursor = 'pointer';
        li.style.display = 'flex';
        li.style.flexDirection = 'row';
        li.style.marginLeft = '10px';

        li.onclick = () => {
          chart.setDatasetVisibility(item.datasetIndex, !chart.isDatasetVisible(item.datasetIndex));
          chart.update();
        };

        // Color box
        const boxSpan = document.createElement('span');
        boxSpan.style.background = dataObject.datasets[index].borderColor;
        boxSpan.style.borderColor = item.strokeStyle;
        boxSpan.style.borderWidth = item.lineWidth + 'px';
        boxSpan.style.display = 'inline-block';
        boxSpan.style.height = vars.spacingM;
        boxSpan.style.width = vars.spacingM;
        boxSpan.style.marginRight = vars.spacingXs;
        boxSpan.style.opacity = item.hidden ? '0.4' : '1';
        boxSpan.style.borderRadius = vars.borderRadius2;

        // Text
        const textContainer = document.createElement('p');
        textContainer.style.color = item.fontColor;
        textContainer.style.fontWeight = 'bold';
        textContainer.style.margin = '0 0 0 5px';
        textContainer.style.padding = '0';
        textContainer.style.textDecoration = item.hidden ? 'line-through' : '';
        textContainer.style.opacity = item.hidden ? '0.4' : '1';

        const text = document.createTextNode(startCase(item.text));
        textContainer.appendChild(text);

        li.appendChild(boxSpan);
        li.appendChild(textContainer);
        ul.appendChild(li);
      });
    },
  };

  const resetZoomButton = {
    id: 'reset-zoom',
    afterUpdate(chart: Chart) {
      const zoomButtom = document.getElementById('reset-zoom');
      if (zoomButtom !== null) {
        zoomButtom.onclick = () => {
          resetZoom(chart);
          chart.update();
        };
      }
    },
  };

  const zoomPlusButton = {
    id: 'zoomPlus',
    afterUpdate(chart: Chart) {
      const zoomButtom = document.getElementById('zoomPlus');
      if (zoomButtom !== null) {
        zoomButtom.onclick = () => {
          chart.zoom(1.1);
        };
      }
    },
  };

  const zoomMinusButton = {
    id: 'zoomMinus',
    afterUpdate(chart: Chart) {
      const zoomButtom = document.getElementById('zoomMinus');
      if (zoomButtom !== null) {
        zoomButtom.onclick = () => {
          chart.zoom(0.9);
        };
      }
    },
  };

  useEffect(() => {
    if (lineChartRef.current !== null) {
      lineChartObjectRef.current?.destroy();
      lineChartObjectRef.current = new Chart(lineChartRef.current, {
        data: dataObject,
        type: 'line',
        options: chartOptions,
        plugins: [htmlLegendPlugin, resetZoomButton, zoomPlusButton, zoomMinusButton],
      });
    }
  }, [lineChartRef.current, lineChartRef, dataObject]);

  return (
    <LineChartWrapper data-testid="line-chart">
      <LineChartCanvas ref={lineChartRef} id="line-chart" />
      <LineChartLegendWrapper>
        <LineChartLegendList ref={lineChartLegendRef} />
      </LineChartLegendWrapper>
    </LineChartWrapper>
  );
};

LineChart.propTypes = {
  data: PropTypes.shape({
    labels: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    datasets: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        data: PropTypes.arrayOf(PropTypes.number.isRequired),
      })
    ),
  }),
  tooltipLabel: PropTypes.string,
  dataPageName: PropTypes.string.isRequired,
  destinationPage: PropTypes.oneOf<DESTINATION_TYPES>([
    DESTINATION_TYPES.ALL_DESTINATIONS,
    DESTINATION_TYPES.EMBED,
    DESTINATION_TYPES.FACEBOOK,
    DESTINATION_TYPES.STREAM,
    DESTINATION_TYPES.YOUTUBE,
  ]),
  yAxisTickGenerator: PropTypes.func,
  toolTipFormatGenerator: PropTypes.func,
};

LineChart.displayName = 'LineChart';

export default LineChart;
