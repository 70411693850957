import React, { FC } from 'react';
import ResiLogoSvg from '../../svgs/ResiLogoSvg';
import { ResiLogoWrapper, LayoutWrapper, FooterDownWrap } from './style';
import PropTypes from 'prop-types';
import ResiFooter from '../ResiFooter';

const ResiLayout: FC = ({ children }): JSX.Element => {
  return (
    <LayoutWrapper>
      <FooterDownWrap>
        <ResiLogoWrapper>
          <ResiLogoSvg />
        </ResiLogoWrapper>
        {children}
      </FooterDownWrap>
      <ResiFooter />
    </LayoutWrapper>
  );
};

ResiLayout.propTypes = {
  children: PropTypes.element,
};

export default ResiLayout;
