import 'angular-route';
import angular from 'angular';
import { react2angular } from 'react2angular';
import router from './module.library.routes';
import commonServicesModule from '../../services';
import { LibraryThumbnailUploader, LibraryThumbnailPreview } from '../../../../src/components';

export const libraryModule = {
	name: 'library',
	controllers: {
		default: 'libraryCtrl',
	},
	routes: {
		list: '/library',
		details: '/library/details/:detailsmediaid',
		edit: '/library/edit/:editmediaid',
	},
};

export default angular
	.module(libraryModule.name, ['ngRoute', commonServicesModule])
	.config(router)
	.component(
		'libraryThumbnailUploader',
		react2angular(LibraryThumbnailUploader, ['destination', 'customerId', 'imageUrl', 'label', 'width', 'onFilePathChanged', 'onImageCropped', 'onCancel', 'getRegion', 'getRoutingKey', 'onDeleteThumbnails']))
	.component('libraryThumbnailPreview', react2angular(LibraryThumbnailPreview, ['imageUrl']))
	.controller(libraryModule.controllers.default, require('./libraryCtrl')).name;
