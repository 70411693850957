import React, { FC, useContext } from 'react';
import { Modal, ModalHeader } from '..';
import { EventUploadContext } from '../EventUploadModal/context';
import { Step1ProfileAndName } from './steps';
import Step2WebEventCreate from './steps/Step2WebEventCreate/step-2-web-event-create';
import Step3UploadAndFinish from './steps/Step3UploadAndFinish/step-3-upload-and-finish';
import { theme, ThemeProvider } from '@resi-media/resi-ui';
import './style.scss';

export enum UploadWizardSteps {
  PROFILE_AND_NAME,
  WEB_EVENT_CREATE,
  UPLOAD_AND_FINISH,
}

interface UploadWizardProps {
  handleFileChange: (files: File[]) => void;
  closeModal: () => void;
  cancelUpload: () => void;
}

const UploadWizard: FC<UploadWizardProps> = ({ closeModal, handleFileChange, cancelUpload }): JSX.Element => {
  const { state } = useContext(EventUploadContext);

  return (
    <ThemeProvider theme={theme}>
      <Modal width="900px">
        <ModalHeader
          onClose={closeModal}
          closeButton={true}
          titleIconClass="fa fa-cog la1-evt-upload-cog"
          titleText="Upload a New Event"
        />

        {(() => {
          switch (state.wizardStep) {
            case UploadWizardSteps.PROFILE_AND_NAME:
              return <Step1ProfileAndName closeModal={closeModal} />;
            case UploadWizardSteps.WEB_EVENT_CREATE:
              return <Step2WebEventCreate />;
            case UploadWizardSteps.UPLOAD_AND_FINISH:
              return (
                <Step3UploadAndFinish
                  cancelUpload={cancelUpload}
                  closeModal={closeModal}
                  handleFileChange={handleFileChange}
                />
              );
            default:
              return <p>Something went wrong. Please try again or contact us.</p>;
          }
        })()}
        </Modal>
      </ThemeProvider>
  );
};

UploadWizard.displayName = 'UploadWizard';

export default UploadWizard;
