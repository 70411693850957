// The api does not currently return a "stopping" status for simulcasts. Until it does, the UI will attempt to keep track
// of what simulcasts are stopping itself -- so we can show feedback to the user that a web event is being stopped. Once
// the API is updated to return a "stopping" state, then we can remove this service.
class SimulcastsService {
	private list: string[] = [];

	public isStopping(uuid: string): boolean {
		return this.list.indexOf(uuid) != -1;
	}

	public add(uuid: string): void {
		this.list.push(uuid);
	}

	public remove(uuid: string): void {
		const index = this.list.indexOf(uuid);
		if (index > -1) {
			this.list.splice(index, 1);
		}
	}
}

export default SimulcastsService;
