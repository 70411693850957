import React, { FC, useEffect, useReducer, Reducer } from 'react';
import PropTypes from 'prop-types';
import { SetPassword, Loading, InvitationExpired } from '../../components';
import { acceptInvite, getInvite, logout } from './api';
import { theme, ThemeProvider } from '@resi-media/resi-ui';
import {
  acceptInviteReducer,
  initialAcceptInviteState,
  AcceptInviteState,
  AcceptInviteAction,
  ACCEPT_INVITE_ACTIONS,
  ACCEPT_INVITE_PAGES,
} from './accept-invite.reducer';

interface AcceptInviteProps {
  inviteId: string;
}

const AcceptInvitePage: FC<AcceptInviteProps> = ({ inviteId }) => {
  const [state, dispatch] = useReducer<Reducer<AcceptInviteState, AcceptInviteAction<any>>>(
    acceptInviteReducer,
    initialAcceptInviteState
  );

  useEffect(() => {
    (async (): Promise<void> => {
      await logout();
      try {
        const response = await getInvite(inviteId);
        dispatch({ type: ACCEPT_INVITE_ACTIONS.EMAIL, payload: response.data.email });
        // pause for 3/4 of a second to avoid loading screen flash
        setTimeout(() => {
          dispatch({ type: ACCEPT_INVITE_ACTIONS.PAGE, payload: ACCEPT_INVITE_PAGES.SET_PASSWORD });
        }, 750);
      } catch (error) {
        if (error.response.status === 404) {
          dispatch({ type: ACCEPT_INVITE_ACTIONS.PAGE, payload: ACCEPT_INVITE_PAGES.INVITATION_EXPIRED });
        } else {
          dispatch({
            type: ACCEPT_INVITE_ACTIONS.ERROR,
            payload: {
              errorMessage: 'There was an error accessing your invitation.',
            },
          });
        }
      }
    })();
  }, []);

  const handleSubmit = async (password: string): Promise<void> => {
    try {
      dispatch({ type: ACCEPT_INVITE_ACTIONS.ERROR, payload: undefined });
      await acceptInvite(inviteId, password);
      window.location.assign('/login');
    } catch (e) {
      dispatch({
        type: ACCEPT_INVITE_ACTIONS.ERROR,
        payload: { errorMessage: 'There was an error setting your password.' },
      });
    }
  };

  return (
    <ThemeProvider theme={theme}>
      {state.page === ACCEPT_INVITE_PAGES.LOADING && (
        <Loading
          header="Validating Email..."
          subheader="Please wait while we access your account."
          error={state.error}
        />
      )}
      {state.page === ACCEPT_INVITE_PAGES.INVITATION_EXPIRED && <InvitationExpired />}
      {state.page === ACCEPT_INVITE_PAGES.SET_PASSWORD && (
        <SetPassword
          header="Set Password"
          subheader="Please set your password"
          buttonLabel="Set Password"
          error={state.error?.errorMessage}
          onSubmit={handleSubmit}
          email={state.email}
        />
      )}
    </ThemeProvider>
  );
};

AcceptInvitePage.displayName = 'AcceptInvitePage';
AcceptInvitePage.propTypes = {
  inviteId: PropTypes.string.isRequired,
};

export default AcceptInvitePage;
