import React, { FC } from 'react';
import Modal from '../Modal';
import ModalHeader from '../ModalHeader';
import Portal from '../Portal';

interface LibraryWatchModalProps {
  mediaId: string;
  embedUrl: string;
  mediaTitle: string;
  handleCloseModal: () => void;
}

const LibraryWatchModal: FC<LibraryWatchModalProps> = ({
  mediaId,
  embedUrl,
  mediaTitle,
  handleCloseModal,
}) => {

  return (
    <Portal>
      <Modal>
        <ModalHeader titleText={mediaTitle} closeButton={true} onClose={handleCloseModal}/>
        <div className="modal-body" style={{padding: 0}}>
          <div style={{position: 'relative', overflow: 'hidden', paddingTop: '56.25%'}}>
            <iframe title={`${mediaTitle}-${mediaId}`} allow="autoplay; fullscreen" allowFullScreen={true} src={embedUrl} style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', border: 'none'}}/>
          </div>
        </div>
      </Modal>
    </Portal>
  );
};

LibraryWatchModal.displayName = 'LibraryWatchModal';

export default LibraryWatchModal;
