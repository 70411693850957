import React, { FC, useState } from 'react';
import PropTypes from 'prop-types';
import { StatusCard, HeatMap, FillBlockSpinner } from '../../../components';
import '../event-analytics.styles.ts';
import StreamStatsGraph from './StreamStatsGraph/stream-stats-graph';
import { EventAnalyticsProps, GROUP_BY_VALUE, STREAM_TAB_VALUES } from '../types';
import StreamSegmentedStats from './StreamSegmentedStats/stream-segmented-stats';
import { Grid, customProperties as vars } from '@resi-media/resi-ui';
import { LOADER_SIZE_COMPONENT } from '../event-analytics-constants';

const StreamAnalyticsView: FC<EventAnalyticsProps> = ({
  statsArray,
  isCardLoading,
  hasGraphPerms,
  heatMapData,
  isMapLoading,
}): JSX.Element => {
  const [csvDetail, setCSVDetail] = useState<STREAM_TAB_VALUES>(1);
  const [segmentType, setSegmentType] = useState<GROUP_BY_VALUE>(0);
  return (
    <Grid display="grid" gridGap={vars.spacingXl} data-testid="stream-analytics-page">
      <Grid.Item>
        <StreamStatsGraph csvDetail={csvDetail} hasGraphPerms={hasGraphPerms} setSegmentType={setSegmentType} />
      </Grid.Item>
      {isCardLoading ? (
        <FillBlockSpinner fontSize={LOADER_SIZE_COMPONENT} />
      ) : (
        <Grid display="grid" gridGap={vars.spacingXl} gridAutoColumns="1fr" gridTemplateColumns="repeat(4, 1fr)">
          {statsArray.map((item) => (
            <Grid.Item key={item.label}>
              <StatusCard
                label={item.label}
                change={item.change}
                count={item.count}
                isTrendUp={item.isTrendUp}
                data-testid="status-card"
              />
            </Grid.Item>
          ))}
        </Grid>
      )}
      {hasGraphPerms && (
        <>
          <StreamSegmentedStats setCSVDetail={setCSVDetail} segmentType={segmentType} />
          {heatMapData?.length ? <HeatMap positions={heatMapData} defaultZoomLevel={4} isLoading={!!isMapLoading} /> : null}
        </>
      )}
    </Grid>
  );
};

StreamAnalyticsView.displayName = 'StreamAnalyticsView';

StreamAnalyticsView.propTypes = {
  statsArray: PropTypes.array.isRequired,
  hasGraphPerms: PropTypes.bool.isRequired,
  heatMapData: PropTypes.array.isRequired,
  isMapLoading: PropTypes.bool.isRequired,
};

export default StreamAnalyticsView;
