import styled from '@emotion/styled';
import { customProperties as vars } from '@resi-media/resi-ui';

export const EventStatsGraphWrapper = styled.div<{ hasGraphPerms: boolean, isInfoBoxDisplayed: boolean }>`
  height: ${({ isInfoBoxDisplayed, hasGraphPerms }) => {
    if(hasGraphPerms && isInfoBoxDisplayed){
      return '670px'
    } else if(hasGraphPerms){
      return '625px'
    }
    return '165px'
  }};
  padding: ${vars.spacingL} 3rem;
`;

export const EventStatsHeaderDiv = styled.div`
  display: grid;
  grid-template-columns: 4fr 5fr 3fr;
  gap: ${vars.spacingM};
`;

export const TabsDiv = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: ${vars.fontSize12};
  margin: ${vars.spacingM} 0;
`;

export const GraphWrapper = styled.div`
  height: 510px;
`;

export const DropDownWrapper = styled.div`
  margin: 0.25em 0px 0px !important;
`;

export const InfoWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  font-size: ${vars.fontSize24};
`;

export const ChartControls = styled.div`
  max-width : ${vars.constraintS};
`;

export const IconWrapper = styled.i`
  padding-top: 5px;
`;

export const InfoBoxWrapper = styled.div`
  padding-bottom : 10px;
`;