import HeatmapComponent from './heatmap.component';

export const heatmapModule = {
	name: 'heatmapComponent',
	component: {
		name: 'heatmap'
	}
};

export default angular
	.module(heatmapModule.name, [])
	.component(heatmapModule.component.name, new HeatmapComponent()).name;
