function routeConfig($provide, $routeProvider) {
	'ngInject';

	$provide.factory('$routeProvider', () => {
		return $routeProvider;
	});

	$routeProvider.when('/login', {
		controller: 'LoginController as $ctrl',
		template: require('./html/login.tmpl.html').default,
	});
	$routeProvider.when('/not-authorised', {
		controller: 'LoginController as $ctrl',
		template: require('./html/not-authorised.tmpl.html').default,
	});

	$routeProvider.otherwise({
		redirectTo: '/login',
	});
}

export default routeConfig;
