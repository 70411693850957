import styled from '@emotion/styled';
import { customProperties as vars } from '@resi-media/resi-ui';

export const SegmentedStatsWrapper = styled.div`
  height: 600px;
  padding: ${vars.spacingXl} 4em 4em 4em;
`;

export const SegmentedStatsModalContent = styled.div`
  width: 900px;
  min-height: 390px;
  padding: ${vars.spacingXl} 4em 4em 4em;
`;

export const LegendsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: ${vars.spacingXl};
  margin-right: ${vars.spacingXl};
`;

export const HeaderDiv = styled.div`
  display: flex;
  justify-content: space-between;
  margin: ${vars.spacingL} 0;
  font-family: ${vars.fontFamily};
  align-items: center;
`;

export const ChartWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  position: relative;
`;

export const ExpandChartWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 390px;
  position: relative;
`;

export const ChartContent = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
`;

export const TableContent = styled.div`
  width: 100%;
  max-width: 800px;
`;

export const ChartHeaderDiv = styled.div`
  position: absolute;
  right: 0;
  padding-right: 8em;
  font-family: ${vars.fontFamily};
`;

export const HorizontalLine = styled.hr`
  width: 65px;
  margin: 0.25em 0 0 0.75em;
  border: none;
  height: 5px;
  border-top: 1px solid lightgrey;
`;
export const LocationTabsDiv = styled.div`
  padding: 0 0 0 0.75em;
`;
export const ModalWrapper = styled.div`
  width: fit-content;
`;
