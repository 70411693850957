import React, { FC, useState, useEffect } from 'react';
import moment from 'moment';
import propTypes from 'prop-types';
import ProgressBar from '../ProgressBar';
import { SuccessStatusSvg, ErrorStatusSvg, WarningStatusSvg } from '../../svgs';
import MoreInfoModal from '../MoreInfoModal';
import './style.scss';
import { FillBlockSpinner } from '../FillBlockSpinner';

export enum MEDIA_STATUS_ENUM {
  PENDING = 'PENDING',
  STARTING = 'STARTING',
  IN_PROGRESS = 'IN_PROGRESS',
  SEGMENTING = 'SEGMENTING',
  TRANSCODING = 'TRANSCODING',
  RE_SEGMENTING = 'RE_SEGMENTING',
  FAILED = 'FAILED',
  COMPLETE = 'COMPLETE',
  CANCELLED = 'CANCELLED',
  RETRYING = 'RETRYING',
  TIMED_OUT = 'TIMED_OUT',
}

const EVENT_STATUS_TEXT = {
  PENDING: 'Pending...',
  STARTING: 'Starting...',
  PROCESSING: 'Progress (Processing)',
  SEGMENTING: 'Segmenting...',
  TRANSCODING: 'Transcoding...',
  RE_SEGMENTING: 'Finalizing...',
  FAILED: 'Processing Failed',
  CANCELLED: 'Upload Cancelled',
  RETRY: 'Retrying Upload',
  TIMED_OUT: 'Failed to Process in a Timely Manner',
  READY: 'Event Ready',
};

export interface ImportResponse {
  customerId: string;
  importId: string;
  userId: string;
  name: string;
  created: string;
  updated: string;
  percentProcessed: number;
  status: MEDIA_STATUS_ENUM;
  complete: boolean;
  message?: string;
}

export interface UploadStatusProgressChartProps {
  eventList: ImportResponse[];
  backToEvents: () => void;
  isResiAdmin: boolean;
}

function isInProgress(eventStatus: MEDIA_STATUS_ENUM): boolean {
  switch (eventStatus) {
    case MEDIA_STATUS_ENUM.PENDING:
    case MEDIA_STATUS_ENUM.STARTING:
    case MEDIA_STATUS_ENUM.IN_PROGRESS:
    case MEDIA_STATUS_ENUM.SEGMENTING:
    case MEDIA_STATUS_ENUM.TRANSCODING:
    case MEDIA_STATUS_ENUM.RE_SEGMENTING:
      return true;
  }
  return false;
}

const UploadStatusProgressChart: FC<UploadStatusProgressChartProps> = ({
  backToEvents,
  eventList,
  isResiAdmin,
}): JSX.Element => {
  const [moreInfoMsg, setMoreInfoMsg] = useState<string | null>(null);
  const [orderedEventList, setOrderedEventList] = useState<ImportResponse[]>([]);

  useEffect(() => {
    setOrderedEventList(eventList.sort((a, b) => (new Date(a.created) < new Date(b.created) ? 1 : -1)));
  }, [eventList]);

  function eventTextSwitch(eventStatus: MEDIA_STATUS_ENUM, progress: number, statusMsg?: string): JSX.Element {
    switch (eventStatus) {
      case MEDIA_STATUS_ENUM.PENDING:
        return (
          <div data-testid="pending-row" className="uploadsInProgress-status-wrap">
            {EVENT_STATUS_TEXT.PENDING}
          </div>
        );
      case MEDIA_STATUS_ENUM.STARTING:
        return (
          <div data-testid="starting-row" className="uploadsInProgress-status-wrap">
            {EVENT_STATUS_TEXT.STARTING}
          </div>
        );
      case MEDIA_STATUS_ENUM.IN_PROGRESS:
        return (
          <div data-testid="in-progress-row" className="uploadsInProgress-status-wrap">
            <div>
              <div>{EVENT_STATUS_TEXT.PROCESSING}</div>
              <div className="uploadsInProgress-progress-bar-wrap">
                <div className="uploadsInProgress-progress-inner-wrap">
                  <ProgressBar progressUpdate={progress}></ProgressBar>
                </div>
                <div className="uploadsInProgress-progress-txt">({`${progress || 0}%`})</div>
              </div>
            </div>
          </div>
        );
      case MEDIA_STATUS_ENUM.SEGMENTING:
        return (
          <div data-testid="segmenting-row" className="uploadsInProgress-status-wrap">
            {EVENT_STATUS_TEXT.SEGMENTING}
          </div>
        );
      case MEDIA_STATUS_ENUM.TRANSCODING:
        return (
          <div data-testid="transcoding-row" className="uploadsInProgress-status-wrap">
            {EVENT_STATUS_TEXT.TRANSCODING}
          </div>
        );
      case MEDIA_STATUS_ENUM.RE_SEGMENTING:
        return (
          <div data-testid="resegmenting-row" className="uploadsInProgress-status-wrap">
            {EVENT_STATUS_TEXT.RE_SEGMENTING}
          </div>
        );
      case MEDIA_STATUS_ENUM.FAILED:
        return (
          <div data-testid="failed-row" className="uploadsInProgress-status-wrap">
            <ErrorStatusSvg />
            {EVENT_STATUS_TEXT.FAILED}
            {statusMsg && statusMsg.length > 0 && (
              <button
                data-testid="more-info-btn"
                onClick={() => setMoreInfoMsg(statusMsg)}
                className="btn btn-default uploadsInProgress-more-info-btn"
              >
                More Info
              </button>
            )}
          </div>
        );
      case MEDIA_STATUS_ENUM.COMPLETE:
        return (
          <div data-testid="complete-row" className="uploadsInProgress-status-wrap">
            <SuccessStatusSvg />
            {EVENT_STATUS_TEXT.READY}
          </div>
        );
      case MEDIA_STATUS_ENUM.CANCELLED:
        return (
          <div data-testid="cancelled-row" className="uploadsInProgress-status-wrap">
            <WarningStatusSvg />
            <div>{EVENT_STATUS_TEXT.CANCELLED}</div>
          </div>
        );
      case MEDIA_STATUS_ENUM.RETRYING:
        return (
          <div data-testid="retrying-row" className="uploadsInProgress-status-wrap">
            <WarningStatusSvg />
            <div>{EVENT_STATUS_TEXT.RETRY}</div>
          </div>
        );
      case MEDIA_STATUS_ENUM.TIMED_OUT:
        return (
          <div data-testid="timed-out-row" className="uploadsInProgress-status-wrap">
            <ErrorStatusSvg />
            <div>{EVENT_STATUS_TEXT.TIMED_OUT}</div>
          </div>
        );
      default:
        return <div>Invalid Event Status</div>;
    }
  }

  function backBtnKeypress(e: any) {
    if (e.keyCode === 27) {
      backToEvents();
    }
  }

  return (
    <>
      <div className="col-md-12">
        <div className="uploadsInProgress-main-header-wrap">
          <i className="fa fa-upload" id="uploadsInProgress-upload-icon-size"></i>
          <div className="uploadsInProgress-header-wrap">
            <h1 className="uploadsInProgress-main-header">
              Recent Uploads
              <i
                className="fa fa-question-circle fa-icon-muted"
                id="uploadsInProgress-tooltip-icon"
                data-toggle="tooltip"
                data-placement="right"
                title=""
                data-original-title="Recent uploads includes uploads from the past 72 hours."
              />
            </h1>
            <button onClick={backToEvents} onKeyPress={backBtnKeypress} className="btn btn-default">
              <i className="fa fa-mail-reply" style={{ marginRight: 7 }} />
              Back
            </button>
          </div>
        </div>
        <div className="box">
          {orderedEventList.length === 0 && (
            <div className="uploadsInProgress-no-existing-events">There are no recent uploads.</div>
          )}
          <table className="table table-hover align-middle ng-scope">
            {orderedEventList.length > 0 && (
              <tbody>
                <tr>
                  <th>Event Name</th>
                  <th>Upload Time</th>
                  <th>Last Updated</th>
                  <th className="uploadsInProgress-status-width">Status</th>
                </tr>
                {orderedEventList &&
                  orderedEventList.map((event) => {
                    return (
                      <tr key={`event-progress-${event.importId}`}>
                        <td>{event.name}</td>
                        <td>{moment(event.created).format('ddd, MMMM D, YYYY h:mm:ss A')}</td>
                        <td>{moment(event.updated).format('ddd, MMMM D, YYYY h:mm:ss A')}</td>
                        <td>
                          <div className="uploadsInProgress-status-container">
                            {isInProgress(event.status) && (
                              <div className="uploadsInProgress-spinner-container">
                                <FillBlockSpinner fontSize="11" />
                              </div>
                            )}
                            {eventTextSwitch(event.status, +event.percentProcessed, event.message)}
                          </div>
                        </td>
                        {isResiAdmin && (
                          <td>
                            <a
                              className="btn btn-default"
                              href={`https://console.cloud.google.com/logs/query;query=resource.type%3D%22k8s_container%22%0AlogName%3D%22projects%2Flao-multisite%2Flogs%2Fstdout%22%0Aresource.labels.container_name%3D%22stream-import%22%20OR%20resource.labels.container_name%3D%22media-transcoder%22%0Alabels.importId%3D%22${event.importId}%22%20OR%20labels.transcodeId%3D%22${event.importId}%22;timeRange=P1D;summaryFields=resource%252Flabels%252Fnamespace_name:true?project=lao-multisite`}
                              target="_blank"
                              rel="noreferrer noopener"
                              data-testid="view-logs-button"
                            >
                              View Logs
                            </a>
                          </td>
                        )}
                      </tr>
                    );
                  })}
              </tbody>
            )}
          </table>
        </div>
      </div>
      {moreInfoMsg !== null && (
        <MoreInfoModal title="Upload Status More Info" closeModal={() => setMoreInfoMsg(null)}>
          <div>{moreInfoMsg}</div>
          <a
            href="https://docs.resi.io/articles/#!easy-upload-video-guide/uploading-a-video-file-in-control"
            target="_blank"
            rel="noopener noreferrer"
          >
            Upload Video Documentation
          </a>
        </MoreInfoModal>
      )}
    </>
  );
};

UploadStatusProgressChart.propTypes = {
  eventList: propTypes.array.isRequired,
  backToEvents: propTypes.func.isRequired,
  isResiAdmin: propTypes.bool.isRequired,
};

UploadStatusProgressChart.displayName = 'UploadStatusProgressChart';

export default UploadStatusProgressChart;
