import React, { FC, useState, useEffect } from 'react';
import { CloseOutlined, CheckCircleOutlined } from '@ant-design/icons';
import { customProperties as vars } from '@resi-media/resi-ui';
import './style.scss';

interface ProgressBarLargeProps {
  progressUpdate: number;
  isFinished: boolean;
  label: string;
  color?: string;
  hasError: boolean;
  cancel: () => void;
}

const ProgressBarLarge: FC<ProgressBarLargeProps> = ({
  progressUpdate,
  isFinished,
  label,
  hasError,
  color = '#F5F7FA',
  cancel,
}): JSX.Element => {
  const errorStyles = {
    border: `1px solid ${vars.colorError}`,
    backgroundColor: '#FFF1F0', // not yet defined in tool kit
  };

  const [progress, setProgress] = useState(0);
  useEffect(() => {
    setProgress(progressUpdate);
  }, [progressUpdate]);

  useEffect(() => {
    if (hasError) {
      setProgress(0);
    }
  }, [hasError]);

  return (
    <>
      <div className="la1-progress-bar-lg" data-testid="progress-bar-background" style={hasError ? errorStyles : {}}>
        <div
          className="la1-progress-bar-lg__progress"
          style={{
            width: progress + '%',
            color: hasError ? vars.colorError : 'inherit',
            backgroundColor: hasError ? '' : color,
            transition: progress && !isFinished ? 'all .2s ease-in' : 'unset',
          }}
          data-testid="progress-bar-progress-lg"
        >
          <div className="la1-progress-bar-lg__progress-txt" data-testid="progress-bar-progress-lg-label">
            {label}
          </div>
        </div>
        {!hasError && (
          <div className="la1-progress-bar-lg-info-and-close">
            <div className="la1-progress-bar-lg-percent-txt" data-testid="progress-bar-lg-percent">
              {isFinished ? '' : `${progressUpdate}%`}
            </div>
            {isFinished ? (
              <CheckCircleOutlined style={{ color: vars.colorSuccess }} data-testid="completed-icon" />
            ) : (
              <CloseOutlined onClick={cancel} style={{ cursor: 'pointer' }} data-testid="cancel-x" />
            )}
          </div>
        )}
      </div>
    </>
  );
};

ProgressBarLarge.displayName = 'ProgressBarLarge';

export default ProgressBarLarge;
