'use strict';

const jcs = require('../../jcs');
const moment = require('moment');
const eventsModule = require('./index').eventsModule;
const COOKIES = require('../../../../src/constants').COOKIES;
const constants = require('../../constants');
const { trackMixpanelEvent, MPEventProperty, MPEventName} = require('../../../../src/mixpanel');

function EventsController(
	$rootScope,
	$scope,
	$timeout,
	$http,
	$q,
	$route,
	$routeParams,
	focus,
	httpService,
	webPlayerService,
	cueService,
	encoderService,
	eventProfileService,
	webEventProfileService,
	webEventsService,
	simulcastsService,
	eventService,
	formValidationService,
	uploadsInProgressService,
	Authentication,
	embedCodeService,
	uiService,
	socialMedia
) {
	'ngInject';

	$(window).trigger('resize'); // ensure footer is properly positioned

	const NAME_UNKNOWN = 'Unknown';

	$scope.has_social_media_perm = Authentication.getCurrentUser().hasPerm('social_media.get');

	$scope.isiOSDevice = uiService.isiOSDevice();

	$scope.webplayerEnv = jcs.webplayerEnv;

	$scope.encoderSvc = encoderService;
	$scope.eventProfileSvc = eventProfileService;
	$scope.webEventProfileSvc = webEventProfileService;

	$scope.social_media = socialMedia.init();
	$scope.validation = formValidationService.init();

	$scope.loading_web_events_timeout_id = null;
	$scope.CHECK_WEB_EVENT_STATUS_TIME_DELAY_SHORT = 10000; // 10 seconds
	$scope.CHECK_WEB_EVENT_STATUS_TIME_DELAY_LONG = 60000; // 60 seconds
	$scope.STATUS_CHECK_INTERVAL = 5000; // 5 seconds
	$scope.UPLOAD_PROGRESS_HOURS_IN_PAST = 72; // 72 hours

	$scope.load_analytics_data_timeout_id = null;
	$scope.UPDATE_ANALYTICS_TIME_DELAY = 1000 * 60; // 1 minute
	$scope.showUploadModal = false;

	$scope.DESTINATION_TYPE_FB_PAGE = 'fb_page';

	$scope.STATUS_STARTED = 'started';
	$scope.STATUS_STARTING = 'starting';
	$scope.STATUS_STOPPED = 'stopped';
	$scope.STATUS_STOPPING = 'stopping';
	$scope.STATUS_ABORTED = 'aborted';
	$scope.STATUS_ERROR = 'error';
	$scope.STATUS_IDLE = 'idle';

	$scope.DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm';
	$scope.DEFAULT_WEB_START_POSITION = 'Beginning of Encoder Event';
	$scope.DEFAULT_WEB_STOP_POSITION = 'End of Encoder Event';

	$scope.loading_webplayer_timeout_id = null;
	$scope.CHECK_MANIFEST_AVAILABILITY_TIME_DELAY = 1500;
	$scope.DISPLAY_STATUS_SHOW = 'show-player';
	$scope.DISPLAY_STATUS_NOT_YET_AVAILABLE = 'not-yet-available';
	$scope.DISPLAY_STATUS_ERROR = 'error';
	$scope.web_event_webplayer_display_status = $scope.DISPLAY_STATUS_SHOW;

	$scope.showInProgressUploads = false;
	$scope.isAnalyticsView = false;
	$scope.statisticsError = false;
	$scope.statusError = false;

	$scope.events = null;
	$scope.events_error_msg = null;
	$scope.stream_id = $routeParams.streamID;

	$scope.web_events = null;
	$scope.is_loading_web_events = false;
	$scope.web_events_error_msg = null;

	$scope.is_loading = false; // show "loading ..." message
	$scope.is_working = false; // activity indicator (spinner icon)
	$scope.error_msg = null;
	$scope.has_error = {}; // NOTE: can get rid of this when fully switch over to using formValidationService

	$scope.selected_embed_code_type = constants.EMBED_CODE_OPTIONS.DEFAULT;

	// upload event progress
	$scope.upcomingUploadEventsTimer = null;
	$scope.upcomingUploadEvents = [];
	$scope.uploadsInProgress = false;

	// event to view
	$scope.event_to_view = null;

	// event edit
	$scope.event_to_edit = null;
	$scope.event_to_edit_name = '';
	$scope.event_to_edit_start_time = '';
	$scope.event_to_edit_remove_time = '';

	// event delete
	$scope.event_to_delete = null;

	// event to transcode
	$scope.event_to_transcode = null;
	$scope.web_event_profiles = null;
	$scope.web_event_profile = null;
	$scope.web_encoder_profiles = null;
	$scope.web_encoder_profile = null;
	$scope.start_pos_list = null;
	$scope.stop_pos_list = null;
	$scope.web_event_start_position = $scope.DEFAULT_WEB_START_POSITION;
	$scope.web_event_stop_position = $scope.DEFAULT_WEB_STOP_POSITION;
	$scope.social_media_accts = null;
	$scope.web_event_simulcasts = [];
	$scope.web_event_name = null;
	$scope.PLAYOUT_BEHAVIOR = {
		REAL_TIME: 'REAL_TIME',
		ON_DEMAND: 'ON_DEMAND',
		ON_DEMAND_UNPUBLISHED: 'ON_DEMAND_UNPUBLISHED',
	}
	$scope.realtimePlayback = true;
	$scope.playoutBehavior = $scope.PLAYOUT_BEHAVIOR.REAL_TIME,
	$scope.encoder_event_region_id = null;
	// web event to delete
	$scope.web_event_to_delete = null;

	// web event to edit
	$scope.web_event_to_edit = null;
	$scope.web_event_to_edit_name = '';
	$scope.web_event_to_edit_desc = '';

	// web event to view
	$scope.web_event_to_view = null;
	$scope.view_web_event_error_msg = null;

	// web event to watch
	$scope.web_event_to_watch = null;
	$scope.web_event_to_watch_profile = null;
	$scope.WEB_PLAYER_WRAPPER_ID = 'web-event-video-player-wrapper'; // needs to be unique across control
	$scope.web_player = null;
	$scope.watch_web_event_error_msg = null;
	$scope.cues = null;
	$scope.visible_cues = null; // used by our react buttons
	$scope.is_busy_loading_cues = false;
	$scope.cues_error = null;
	$scope.can_show_cues = true;
	$scope.can_share_cues_web_event = false;
	$scope.cue_time_range_filter = null;
	$scope.visible_cue_count = 0;
	$scope.update_cue_visibility_interval_id = null;
	$scope.update_cues_interval_id = null;
	$scope.UPDATE_CUES_INTERVAL = 10000; // update cues once every 10 seconds
	$scope.UPDATE_CUE_VISIBILITY_INTERVAL = 2000; // update every 2 seconds (no api calls are made)
	$scope.is_showing_cues = true; // controls visibility of cues (can be toggled by user)
	$scope.is_src_event_available = true; // can't show cues if source event isn't available

	// crossposts
	$scope.is_loading_crossposts = false;
	$scope.crosspost_error = null;

	// web event cues
	$scope.CUE_MILLISECOND_DIGITS = 3;
	$scope.cue_to_add = null;
	$scope.cue_to_edit = null;
	$scope.cue_to_update = null;
	$scope.cue_to_delete = null;
	$scope.cue_error_msg = null;

	// web event to stop
	$scope.web_event_to_stop = null;

	// event to examine analytics
	$scope.event_to_analyze = null;
	$scope.event_analytics = null;

	$scope.analytics_update_time = null;
	$scope.viewers_by_city = null;
	$scope.viewers_by_region = null;
	$scope.viewers_by_country = null;
	$scope.heatmap_data = null;

	$scope.edit_fields = {};

	// simulcast to stop
	$scope.simulcast_to_stop = null;
	$scope.is_stopping_simulcast = false;

	// event which to load on selected decoders
	$scope.event_to_load_on_decoder = null;
	$scope.load_on_decoder_sites = null; // sorted list of sites
	$scope.site_monitor_data = null; // site status records grouped by userName
	// built using a combination of both users/sites and our decoder monitoring data; some sites may not have any monitor records (if they haven't
	// logged in) and others could have multiple entries (if there have been multiple logins)
	$scope.load_on_decoder_options_list = null;
	$scope.show_multiple_logins_warning = false;

	$scope.OPTION_YES = 'Yes';
	$scope.OPTION_NO = 'No';
	$scope.yes_no_options = [$scope.OPTION_YES, $scope.OPTION_NO];

	$scope.authCookie = Authentication.getCurrentUser().authCookie;

	$scope.ldFlags = $rootScope.ldFlags;
	$rootScope.$on('ldFlagsChanged', (_, data) => {
		$scope.ldFlags = data;
		$rootScope.$digest();
	});

	const updateEventAnalytics = (update) => $scope.event_analytics = { ...$scope.event_analytics, ...update };

	$scope.$on("$destroy", () => window.clearInterval($scope.upcomingUploadEventsTimer));

	$scope.back = function () {
		$rootScope.goBack(eventsModule.routes.list);
	};

	$scope.isDash = function (event) {
		return event.format == 'DASH';
	};

	$scope.disableSaveButton = function () {
		let disable = false;
		if ($scope?.web_event_to_watch_profile?.type) {
			disable = $scope?.web_event_to_watch_profile?.type !== webEventProfileService.TYPE_PUBLIC.value;
		}
		return disable;
	};

	$scope.showMoreInfoDialog = function (simulcast, web_event_name) {
		$scope.more_info = {
			title: 'Unable to Stream',
			web_event_name: web_event_name,
			code: simulcast.code,
			type: simulcast.type,
		};

		// update title in some situations
		switch ($scope.more_info.code) {
			case 'CONFLICT':
				$scope.more_info.title = $scope.social_media.getTypeAsLabel($scope.more_info.type) + ' Account Already in Use';
				break;
			case 'TOKEN_INVALID':
				$scope.more_info.title =
					'Unable to Access ' + $scope.social_media.getTypeAsLabel($scope.more_info.type) + ' Account';
				break;
			case 'NOT_ENABLED':
				if ($scope.more_info.type == $scope.social_media.TYPE_YOUTUBE) {
					$scope.more_info.title = 'Not Allowed to Live Stream';
				} else if ($scope.more_info.type == $scope.social_media.TYPE_FACEBOOK) {
					$scope.more_info.title = 'Not Allowed to Publish Video';
				}
				break;
			case 'ENABLED_BUT_NOT_ACTIVE':
				$scope.more_info.title = 'Live Streaming Not Yet Approved';
				break;
		}

		$timeout(function () {
			$('#simulcast-error-more-info').modal('show');
		});
	};

	$scope.onCopyToClipboard = function (event){
		trackMixpanelEvent(MPEventName.COPY_TO_CLIPBOARD, {
			[MPEventProperty.WEB_EVENT_UUID]: event.uuid,
			[MPEventProperty.WEB_EVENT_NAME]: event.name,
			[MPEventProperty.COPY_TYPE]: 'embed',
		});
	};

	$scope.onReportDownload = function (){
		trackMixpanelEvent(MPEventName.DETAILED_REPORT_DOWNLOAD, {
			[MPEventProperty.TRANSCODED_EVENT_UUID]: $scope.event_to_analyze.uuid,
		});
	};

	$scope.onEncoderEventWatch= function (event){
		trackMixpanelEvent(MPEventName.ENCODER_EVENT_WATCH, {
			[MPEventProperty.ENCODER_EVENT_PROFILE_UUID]: event.uuid,
			[MPEventProperty.ENCODER_EVENT_PROFILE_NAME]: event.name,
		});
	};

	$scope.onWebEventWatch= function (event){
		trackMixpanelEvent(MPEventName.WEB_EVENT_WATCH, {
			[MPEventProperty.WEB_EVENT_UUID]: event.uuid,
			[MPEventProperty.WEB_EVENT_NAME]: event.name,
		});
	};

	$scope.getEmbedCode = function (web_event) {
		if ($scope.selected_embed_code_type === constants.EMBED_CODE_OPTIONS.STANDARD_IFRAME){
			return embedCodeService.getStandardIframeEmbedCode(web_event?.uuid, `&type=event${$scope.webplayerEnv.queryParam}`);
		}
		if ($scope.selected_embed_code_type === constants.EMBED_CODE_OPTIONS.ALTERNATIVE_IFRAME){
			return embedCodeService.getAlternativeIframeEmbedCode(web_event?.uuid, `&type=event${$scope.webplayerEnv.queryParam}`);
		}
		// old style embed code
		return `<div id="resi-video-player" data-embed-id="${web_event?.uuid}" data-type="event" ${$scope.webplayerEnv.attribute}></div>
<script type="application/javascript" src="https://control.resi.io/webplayer/loader.min.js"></script>`;
	};

	$scope.getCurrentUser = function () {
		return Authentication.getCurrentUser();
	};

	$scope.canShowViewCuesBtn = function () {
		return Authentication.getCurrentUser().hasPerm('cues.get');
	};

	$scope.canShowAddCueBtn = function () {
		return Authentication.getCurrentUser().hasPerm('cues.add');
	};

	$scope.canShowEditBtn = function () {
		return Authentication.getCurrentUser().hasPerm('events.update');
	};

	$scope.canShowDeleteBtn = function () {
		return Authentication.getCurrentUser().hasPerm('events.delete');
	};

	$scope.canShowCloudBtn = function () {
		return Authentication.getCurrentUser().hasPerm('event_profiles.add');
	};

	$scope.canShowStartWebEventBtn = function (event) {
		return $scope.isDash(event) && Authentication.getCurrentUser().hasPerm('events.start_transcoding');
	};

	$scope.canShowLoadOnDecoderBtn = function () {
		return Authentication.getCurrentUser().hasToggle('decoderRemote');
	};

	$scope.canShowPublishBtn = function () {
		return Authentication.getCurrentUser().hasPerm('social_media.publish');
	};

	$scope.showUploadWizard = function () {
		return Authentication.getCurrentUser().hasToggle('uploadWizard');
    }

	// NOTE: we were using the Date "toLocaleString" method, but it turns out this isn't implemented consistently across
	// browsers. That is why we are using a javascript library (momentjs).
	// for formatting options see: http://momentjs.com/
	$scope.showDateTimeAsLocal = function (dateTimeToConvert) {
		return moment(dateTimeToConvert).format('ddd, MMM D, YYYY h:mm:ss A');
	};
	$scope.formatNumber = function (value) {
		return value ? value.toLocaleString() : value;
	};

	$scope.closeUploadProgressEvents = function () {
		$route.reload();
	}

	$scope.cancelViewEvent = function (){
		$scope.event_to_view = null;
	};

	$scope.enterViewEventMode = function (event) {

		$scope.event_to_view = event;

		// get the name of the encoder used for this event
		if (event.hasOwnProperty('encoderId') && event.encoderId !== null){
			$scope.encoderSvc.getEncoder(event.encoderId).then(response => {
				$scope.event_to_view.encoder_name = response.name;
			}).catch(reason => {
				$scope.event_to_view.encoder_name = NAME_UNKNOWN;
			});
		} else {
			$scope.event_to_view.encoder_name = NAME_UNKNOWN;
		}

		// get the name of the event profile used for this event
		if (event.hasOwnProperty('eventProfileId') && event.eventProfileId !== null){
			$scope.eventProfileSvc.getEventProfile(event.eventProfileId).then(response => {
				$scope.event_to_view.event_profile_name = response.name;
			}).catch(reason => {
				$scope.event_to_view.event_profile_name = NAME_UNKNOWN;
			});
		} else {
			$scope.event_to_view.event_profile_name = NAME_UNKNOWN;
		}
	};

	$scope.enterEditEventMode = function (event) {
		$scope.validation.clear();
		$scope.event_to_edit = event;

		$scope.event_to_edit_name = event.name;
		$scope.event_to_edit_start_time = event.startTime;
		$scope.event_to_edit_remove_time = event.removeTime;

		// see app.js for where focus is defined
		focus('edit-event-input');
	};

	$scope.enterEditWebEventMode = function (event) {
		$scope.validation.clear();

		$scope.web_event_to_edit = event;
		$scope.web_event_to_edit_name = event.name;
		$scope.web_event_to_edit_desc = event.description;

		// see app.js for where focus is defined
		focus('edit-web-event-input');
	};

	$scope.enterUploadVideoMode = function () {
		$scope.showUploadModal = true;
	}

	$scope.exitUploadVideoMode = function() {
		$scope.getRecentUploads();
		$scope.showUploadModal = false;
		$scope.$apply();
	}

	$scope.enterDeleteEventMode = function (event) {
		$scope.event_to_delete = event;
	};

	$scope.enterDeleteWebEventMode = function (event) {
		$scope.web_event_to_delete = event;
	};

	$scope.enterStopWebEventMode = function (event) {
		$scope.web_event_to_stop = event;
	};

	// status is stored by web events. So cycle through them, see if event was created by given profile.
	$scope.isWebEventProfileInUse = function (profile) {
		for (var i = 0; i < $scope.web_events.length; i++) {
			var web_event = $scope.web_events[i];
			if (web_event.webEventProfileId == profile.uuid && web_event.status != $scope.STATUS_STOPPED && web_event.status != $scope.STATUS_ABORTED && web_event.status != $scope.STATUS_ERROR) {
				return true;
			}
		}
		return false;
	};

	$scope.getIsWebEventProfileInUse = function () {
		if (!$scope.web_events || !$scope.web_event_to_watch){
			return undefined;
		}

		return $scope.isWebEventProfileInUse($scope.web_event_to_watch_profile);
	}

	$scope.isWebEventProfileReturnFeed = function (profile){
		return profile.type == 'return_feed';
	}

	$scope.isWebEventProfileSocial = function (profile){
		return profile.type == 'social';
	}

	// this should be called when we get a updated list of web events from the api; we will need to update
	// our list of "stopping" web events (remove them if they are already stopped)
	$scope.updateWebEventsThatAreStopping = function (web_events) {
		for (var i = 0; i < web_events.length; i++) {
			var web_evt = web_events[i];
			if (web_evt.status == $scope.STATUS_STOPPED) {
				webEventsService.remove(web_evt.uuid);
			}
		}
	};

	$scope.getSocialMediaStatusDesc = function (status) {
		switch (status) {
			case $scope.social_media.STATUS_TOKEN_INVALID:
				return 'No Access';
			case $scope.social_media.STATUS_NOT_ENABLED:
				return 'Not allowed to Live Stream';
			case $scope.social_media.STATUS_ENABLED_BUT_NOT_ACTIVE:
				return 'Channel Update Required'; // or 'Live Stream Approval Pending' or 'Possible Channel Update Required'
			default:
				return 'Unknown Status';
		}
	};

	// this is a callback we use with the socialMedia service (as it determines account status, it calls this method)
	$scope.updateAcctStatus = function (account_id, info) {
		for (var i = 0; $scope.social_media_accts.length; i++) {
			if ($scope.social_media_accts[i].uuid == account_id) {
				// update status
				$scope.social_media_accts[i].status = info.status;
				// update formatted name (which is used in dropdown) if account has a problem
				if (info.status != $scope.social_media.STATUS_OK){
					$scope.social_media_accts[i].formatted_name += ` (${$scope.getSocialMediaStatusDesc($scope.social_media_accts[i].status)})`;
				}
				return;
			}
		}
	};

	$scope.updateSocialMediaAccountsStatus = function () {

		if ($scope.social_media_accts != null && $scope.social_media_accts.length > 0) {
			var acct_id_list = [];

			// add a "status" field for each account and queue up an API call to check the actual status; status will default to "OK"
			// until we know otherwise. We only want to warn the user about issues if we know for sure there is one.
			for (var i = 0; i < $scope.social_media_accts.length; i++) {
				var acct = $scope.social_media_accts[i];
				acct.status = $scope.social_media.STATUS_OK;
				acct.formatted_name = '[' + $scope.social_media.getTypeAsLabel(acct.type) + '] ' + acct.name;
				acct_id_list.push({
					id: acct.uuid,
					type: acct.type,
				});
			}

			$scope.social_media.checkAccountStatus(acct_id_list, Authentication.getCurrentUser().customerID, $scope.updateAcctStatus);
		}
	};

	// sets the select status of users in "add users" page
	$scope.addSitesSetSelectedStatus = function (status) {
		for (var i = 0; i < $scope.load_on_decoder_options_list.length; i++) {
			var site = $scope.load_on_decoder_options_list[i];
			// don't change status of sites that are offline
			if (site.status != "offline"){
				site.isSelected = status;
			}
		}
	};

	$scope.isUser = function (item) {
		if (item.planName == null){
			return false;
		}
		return item.planName.toLowerCase() == 'user';
	};

	$scope.showLoadOnDecoderInfo = function (){
		$('#load-on-decoder-info').modal('show');
	};

	$scope.sortLastUpdateByMostRecentFirst = function (a, b) {
		var lastUpdateA = moment(a.lastUpdate);
		var lastUpdateB = moment(b.lastUpdate);
		if (lastUpdateA.isAfter(lastUpdateB)) {
			return -1;
		}
		if (lastUpdateA.isBefore(lastUpdateB)) {
			return 1;
		}
		return 0;
	};

	$scope.groupSiteMonitoringData = function (data) {
		var grouped_data = {};

		for (var i = 0; i < data.length; i++) {
			var entry = data[i];

			if (!grouped_data.hasOwnProperty(entry.userName)) {
				grouped_data[entry.userName] = [];
			}
			grouped_data[entry.userName].push(entry);
		}

		for (var userName in grouped_data) {
			// sort entries by lastUpdate, most recent first
			grouped_data[userName].sort($scope.sortLastUpdateByMostRecentFirst);
		}

		return grouped_data;
	};

	$scope.getMonitorDataForSite = function (site) {
		var dataList = [];

		if ($scope.site_monitor_data.hasOwnProperty(site.userName)) {
			var list = $scope.site_monitor_data[site.userName];
			if (list.length > 1) {
				// look at two most recent entries
				// if they are within 5 minutes and have a different hardwareId, then return both
				var entryA = list[0];
				var entryB = list[1];
				if (entryA.playerHardwareId == entryB.playerHardwareId) {
					dataList.push(entryA);
				} else {
					var lastUpdateA = moment(entryA.lastUpdate);
					var lastUpdateB = moment(entryB.lastUpdate);

					lastUpdateA.subtract(5, 'minutes');
					if (lastUpdateB.isAfter(lastUpdateA)) {
						// looks like we have duplicate logins, so return both
						dataList.push(entryA);
						dataList.push(entryB);
					} else {
						// 2nd entry is older than 5 minutes, so ignore it
						dataList.push(entryA);
					}
				}
			} else if (list.length == 1) {
				return list;
			}
		}
		return dataList;
	};

	$scope.prepareSiteMonitorData = function (site_status_data) {

		$scope.show_multiple_logins_warning = false;
		$scope.site_monitor_data = $scope.groupSiteMonitoringData(site_status_data);

		$scope.load_on_decoder_options_list = [];
		var offline_list = [];
		var online_list = [];
		for (var i = 0; i < $scope.load_on_decoder_sites.length; i++) {
			var site = $scope.load_on_decoder_sites[i];
			var list = $scope.getMonitorDataForSite(site);
			if (list.length == 0) {
				// if a site has no monitoring data, then add a "offline" entry
				offline_list.push({
					userName: site.userName,
					uuid: site.uuid,
					status: 'offline',
					hasDuplicateLogins: false,
					isSelected: false
				});
			} else if (list.length >= 1) {

				var site_online_list = []; // online entries for this particular site
				for (var j = 0; j < list.length; j++) {
					var entry = list[j];
					entry.isSelected = false;
					entry.hasDuplicateLogins = false;
					entry.uuid = site.uuid;
					if (entry.status == 'offline'){
						offline_list.push(entry);
					} else {
						site_online_list.push(entry);
					}
				}
				if (site_online_list.length == 1){
					online_list.push(site_online_list[0]);
				} else if (site_online_list.length > 1){
					// if a site is logged into multiple decoders, then we only want to show one
					// entry in the list. Also, instead of status we want to show a "duplicate login" indicator.
					var item = site_online_list[0];
					item.hasDuplicateLogins = true;
					online_list.push(item);
					$scope.show_multiple_logins_warning = true;
				}
			}
		}

		// combine our lists so that offline entries are at the bottom
		$scope.load_on_decoder_options_list = online_list.concat(offline_list);
	};

	$scope.enterLoadOnDecoder = function (event) {

		$scope.is_loading = true;

		var promises = [];
		promises.push($http.get(jcs.api.url + '/users', { withCredentials: true }));
		promises.push($http.get(jcs.api.url_v3 + '/customers/' + Authentication.getCurrentUser().customerID + '/monitors', {withCredentials: true}));

		$q.all(promises).then(
			function (response) { // success

				$scope.event_to_load_on_decoder = event;
				$scope.edit_fields = {
					auto_play: $scope.OPTION_YES,
					clear_cache: $scope.OPTION_NO
				};

				// filter out our sites from our users data
				var list = [];
				for (var i = 0; i < response[0].data.length; i++) {
					var item = response[0].data[i];
					if (!$scope.isUser(item)) {
						item.isSelected = false;
						list.push(item);
					}
				}
				// sort our list by userName
				list.sort(function (a, b) {
					if (a.userName.toUpperCase() < b.userName.toUpperCase()) return -1;
					if (a.userName.toUpperCase() > b.userName.toUpperCase()) return 1;
					return 0;
				});

				$scope.load_on_decoder_sites = list;

				$scope.prepareSiteMonitorData(response[1].data.decoderStatus);

			},
			function () { // error

				$scope.load_org_users_error = 'An error occurred while retrieving the decoder information. Please try again, or report the problem if it persists.';

			}).finally(function () { // always called

				$scope.is_loading = false;
			});

	};

	$scope.handleMixpanelCloudUrl = function (event) {
		trackMixpanelEvent(MPEventName.ENCODER_EVENT_CLOUD_URL, {
			[MPEventProperty.EVENT_UUID]: event.uuid,
		});
	}

	$scope.cancelLoadOnDecoder = function () {
		$scope.event_to_load_on_decoder = null;
		$scope.load_on_decoder_sites = null;
		$scope.site_monitor_data = null;
		$scope.load_on_decoder_options_list = null;
		$scope.show_multiple_logins_warning = false;

		$scope.edit_fields = {};
		$scope.error_msg = null;
		$scope.has_error = {};
	};

	$scope.doesLoadOnDecoderFailValidation = function () {

		$scope.has_error = {};

		// check required fields to ensure they aren't empty
		$scope.has_error.auto_play = $scope.isEmpty($scope.edit_fields.auto_play);
		$scope.has_error.clear_cache = $scope.isEmpty($scope.edit_fields.clear_cache);

		var error_count = 0;
		for (var property in $scope.has_error) {
			if ($scope.has_error[property] === true) {
				error_count++;
			}
		}
		var has_validation_error = error_count > 0;

		$scope.error_msg = has_validation_error ? 'Please specify a value for the highlighted fields below.' : null;

		// if no "required field" errors, then ...
		if (!has_validation_error) {
			// ... ensure that at least 1 site has been selected
			var site_count = 0;
			for (var i = 0; i < $scope.load_on_decoder_options_list.length; i++) {
				if ($scope.load_on_decoder_options_list[i].isSelected){
					site_count++;
				}
			}
			if (site_count <= 0){
				has_validation_error = true;
				$scope.error_msg = 'Please choose at least 1 decoder on which to load the event.';
			}
		}

		return has_validation_error;
	};

	$scope.loadOnDecoder = function () {

		// if we have form validation errors, then don't go any further
		if ($scope.doesLoadOnDecoderFailValidation()){
			return false;
		}

		var list_of_decoders = [];
		for (var i=0; i < $scope.load_on_decoder_options_list.length; i++){
			var decoder = $scope.load_on_decoder_options_list[i];
			if (decoder.isSelected){
				list_of_decoders.push(decoder.uuid);
			}
		}

		var data = {
			"eventId": $scope.event_to_load_on_decoder.uuid,
			"userIds": list_of_decoders,
			"autoPlay": $scope.edit_fields.auto_play == $scope.OPTION_YES,
			"clearCache": $scope.edit_fields.clear_cache == $scope.OPTION_YES
		};

		$scope.is_working = true;

		$http.patch(jcs.api.url_v3 + '/customers/' + Authentication.getCurrentUser().customerID + '/actions/loadeventondecoders', data, { withCredentials: true }).then(
			function (response) { // success

				$scope.event_to_load_on_decoder = null;
				$scope.load_on_decoder_sites = null;
				$scope.site_monitor_data = null;
				$scope.load_on_decoder_options_list = null;
				$scope.show_multiple_logins_warning = false;

			},
			function (reason) { // error

				$scope.error_msg = 'An error occurred processing the request to load this event onto the selected decoders. Please try again, or report the problem if it persists.';

			})['finally'](function () { // always called

				$scope.is_working = false;

			});

	};

	$scope.enterStartWebEvent = function (event) {
		$scope.event_to_transcode = event;
		$scope.web_event_name = event.name;

		$scope.web_event_start_position = $scope.DEFAULT_WEB_START_POSITION;
		$scope.web_event_stop_position = $scope.DEFAULT_WEB_STOP_POSITION;
		$scope.web_event_simulcasts = [];

		$scope.is_loading = true;

		var promises = [];
		// retrieve web event profiles
		promises.push($http.get(jcs.api.url_v3 + '/customers/' + Authentication.getCurrentUser().customerID + '/webeventprofiles', {withCredentials: true}));
		// retrieve cues for this event
		promises.push($http.get(jcs.api.url + '/streamprofiles/' + event.eventProfileId + '/events/' + event.uuid + '/cues', {withCredentials: true}));
		// retrieve fresh web events list
		promises.push($http.get(jcs.api.url_v3 + '/customers/' + Authentication.getCurrentUser().customerID + '/webevents', {withCredentials: true}));
		// retrieve web encoder profiles
		promises.push($http.get(jcs.api.url_v3 + '/customers/' + Authentication.getCurrentUser().customerID + '/webencoderprofiles', {withCredentials: true}));
		// retrieve encoder event profiles
		promises.push($scope.eventProfileSvc.getEventProfile(event.eventProfileId));
		// retrieve social media accts
		if ($scope.has_social_media_perm && $scope.social_media_accts == null){
			promises.push($http.get(jcs.api.url_v3 + '/customers/' + Authentication.getCurrentUser().customerID + '/youtube/channels', { withCredentials: true }));
		}

		$q.all(promises).then(
			function (response) {
				$scope.error_msg = null;

				// refresh our web event list
				$scope.web_events = response[2].data; // do this before we call isWebEventProfileInUse
				$scope.formatWebEvents();
				// remove any stopped web events from our web events stopping list
				$scope.updateWebEventsThatAreStopping($scope.web_events);

				// process our returned web event profiles
				$scope.web_event_profiles = response[0].data;
				// add a "formatted_name" field we will use for our dropdown (so we can alert user to profiles that are "return feed" or "in use")
				for (var i = 0; i < $scope.web_event_profiles.length; i++) {
					var profile = $scope.web_event_profiles[i];
					var postfix_type = '';
					if ($scope.isWebEventProfileReturnFeed(profile)){
						postfix_type = ' [Return Feed]';
					} else if ($scope.isWebEventProfileSocial(profile)){
						postfix_type = ' [Social]';
					}
					var postfix_in_use = $scope.isWebEventProfileInUse(profile) ? ' [IN USE]' : '';
					profile.formatted_name = profile.name + postfix_type + postfix_in_use;
				}
				// auto select the first profile
				if ($scope.web_event_profiles.length > 0) $scope.web_event_profile = $scope.web_event_profiles[0].uuid;

				// process our returned cues
				var cues_list = response[1].data;
				// sort our list by position
				cues_list.sort(function (a, b) {
					if (a.position < b.position) return -1;
					if (a.position > b.position) return 1;
					return 0;
				});
				// build our start position list
				$scope.start_pos_list = [
					{ label: $scope.DEFAULT_WEB_START_POSITION, position: $scope.DEFAULT_WEB_START_POSITION },
				];
				$scope.stop_pos_list = [
					{ label: $scope.DEFAULT_WEB_STOP_POSITION, position: $scope.DEFAULT_WEB_STOP_POSITION },
				];
				for (var i = 0; i < cues_list.length; i++) {
					var cue = cues_list[i];
					$scope.start_pos_list.push({
						label: cue.position + ' - ' + cue.name,
						position: cue.position,
					});
					$scope.stop_pos_list.push({
						label: cue.position + ' - ' + cue.name,
						position: cue.position,
					});
				}

				// process our returned web encoder profiles
				$scope.web_encoder_profiles = response[3].data;
				// add "Default" option (same as "None")
				$scope.web_encoder_profiles.unshift({ uuid: null, name: 'Default' });
				// auto select the first profile
				if ($scope.web_encoder_profiles.length > 0) {
					$scope.web_encoder_profile = $scope.web_encoder_profiles[0].uuid;
				}

				// save off the region ID of the encoder event profile
				$scope.encoder_event_region_id = response[4].regionId;

				// determine the status of our social media accounts
				if ($scope.has_social_media_perm && $scope.social_media_accts == null){
					$scope.social_media_accts = response[5].data;
					$scope.social_media.sortByType($scope.social_media_accts);
					$scope.updateSocialMediaAccountsStatus();
				}
			},
			function (reason) { // error

				if (!httpService.isStatus406(reason)) {
					$scope.error_msg = 'An error occurred while attempting to retrieve information needed to start a web event. Please try again, or report the problem if it persists.';
				}
				$scope.web_event_profiles = null;
				$scope.web_encoder_profiles = null;
			})
			.finally(function () { // always called

				$scope.is_loading = false;

			});
	};

	$scope.enterWebEventStatsMode = function (event) {
		$scope.event_to_analyze = event;
		$scope.loadEventAnalytics();
	};

	$scope.enterWebEventViewMode = function (event) {

		$scope.web_event_to_view = event;

		$scope.is_loading = true;

		$http.get(`${jcs.api.url_v3}/customers/${Authentication.getCurrentUser().customerID}/webeventprofiles/${event.webEventProfileId}`, {withCredentials: true}).then(response => {

			$scope.web_event_to_view.webEventProfileType = response.data.type;
			$scope.web_event_to_view.nextSimLiveEventName = response.data.nextSimLive;
			$scope.web_event_to_view.activeEventName = response.data.active;

		}).catch(reason => {

			$scope.view_web_event_error_msg = 'An error occurred while loading the web event. Please try again, or report the problem if it persists.';

		}).finally(() => {

			$scope.is_loading = false;

		});

		// if this web event has at least one simulcast that is a FB page, then load the web event so we can get the crosspost information (which isn't returned when fetching list of web events)
		if ($scope.hasSimulcastType($scope.web_event_to_view, $scope.DESTINATION_TYPE_FB_PAGE)){
			$scope.loadCrossposts($scope.web_event_to_view);
		}
	};

	$scope.gotoCue = function (cue) {
		webPlayerService.seek($scope.WEB_PLAYER_WRAPPER_ID, cueService.getCuePositionInSeconds(cue.position));

		const mixpanelProps = {
			[MPEventProperty.CUE_NAME]: cue.name,
			[MPEventProperty.TRANSCODED_EVENT_UUID]: $scope.web_event_to_watch.uuid
		}

		trackMixpanelEvent(MPEventName.CUE_GOTO, mixpanelProps)
	};

	$scope.loadCuesForWatchedWebEvent = function () {
		$scope.cues_error = null;
		$scope.is_busy_loading_cues = true;

		// pull the cues for the web event that is being watched
		$http.get(`${jcs.api.url_v3}/customers/${Authentication.getCurrentUser().customerID}/webevents/${$scope.web_event_to_watch.uuid}/cues?canISetCues=1`, { withCredentials: true }).then(response => {

			var cues = response.data;
			// add a position field that is formatted as seconds only -- so we can compare to our time range filter
			for (var i = 0; i < cues.length; i++) {
				cues[i].position_in_seconds = cueService.getCuePositionInSeconds(cues[i].position);
			}
			$scope.cues = cues;

			// cansetcues is returned in response header if we send "canISetCues=1" param to API
			var can_share_cues_header = response.headers('cansetcues');
			if (can_share_cues_header){
				$scope.can_share_cues_web_event = can_share_cues_header.toLowerCase() == 'true';
			}

			// now that new position field is added, determine the visibility of each cue
			$scope.updateCueVisibility();

		}).catch(reason => {

			if (reason.status === 412){
				// NOTE: if we get a 412, then it means the source event no longer exists, and therefore we can't show cues for this web event.
				// But this is temporary. Eventually, we'll be able to manage cues on web events regardless if the source encoder event has been deleted or not.
				$scope.is_src_event_available = false;
				if ($scope.update_cues_interval_id != null) {
					window.clearInterval($scope.update_cues_interval_id);
					$scope.update_cues_interval_id = null;
				}
			} else {
				$scope.cues_error = 'There was an error loading the cues.';
			}

		}).finally(() => {

			$scope.is_busy_loading_cues = false;

		});
	};

	$scope.loadCuesForWatchedWebEventSilent = function () {

		// pull the cues for the web event that is being watched
		$http.get(`${jcs.api.url_v3}/customers/${Authentication.getCurrentUser().customerID}/webevents/${$scope.web_event_to_watch.uuid}/cues`, { withCredentials: true }).then(response => {

			$scope.cues_error = null;

			var cues = response.data;
			// add a position field that is formatted as seconds only -- so we can compare to our time range filter
			for (var i = 0; i < cues.length; i++) {
				cues[i].position_in_seconds = cueService.getCuePositionInSeconds(cues[i].position);
			}
			$scope.cues = cues;

			// now that new position field is added, determine the visibility of each cue
			$scope.updateCueVisibility();

		}).catch(reason => {

			if (reason.status === 412){
				// NOTE: if we get a 412, then it means the source event no longer exists, and therefore we can't show cues for this web event.
				// But this is temporary. Eventually, we'll be able to manage cues on web events regardless if the source encoder event has been deleted or not.
				$scope.is_src_event_available = false;
				if ($scope.update_cues_interval_id != null) {
					window.clearInterval($scope.update_cues_interval_id);
					$scope.update_cues_interval_id = null;
				}
			}
		});
	};

	// TODO: once the backed is updated and we don't have to set the cue visibility anymore, remove is_visible check from cueList.html
	$scope.updateCueVisibility = function () {
		const visible_cues = [];
		var count = 0;
		if ($scope.cues != null) {
			for (var i = 0; i < $scope.cues.length; i++) {
				var is_visible = $scope.isCueVisible($scope.cues[i]);
				$scope.cues[i].is_visible = is_visible;
				if (is_visible){
					count++;
					visible_cues.push($scope.cues[i]);
				}
			}
		}
		$scope.visible_cue_count = count;
		$scope.visible_cues = visible_cues;
	};

	$scope.updateCueVisibilityOnInterval = function () {
		$scope.$apply(function () {
			$scope.cue_time_range_filter = $scope.web_player.getTimeRange(); // time range will constantly be changing for live events
			$scope.updateCueVisibility();
		});
	};

	$scope.isCueVisible = function (cue) {
		// don't display cues if not able, or time range filter has not been initialized
		if (cue == null || !$scope.can_show_cues || $scope.cue_time_range_filter == null){
			return false;
		}
		// cue is visible if it resides within our time range filter
		if (cue.position_in_seconds >= $scope.cue_time_range_filter.start && cue.position_in_seconds <= $scope.cue_time_range_filter.end){
			return true;
		}
		return false;
	};

	$scope.initializeCueUpdates = function () {
		$scope.web_player.getVideo().removeEventListener('playing', $scope.initializeCueUpdates);

		$scope.$apply(function () {
			// get the time range we will filter the cues by (we don't want to show cues that are outside our range)
			$scope.cue_time_range_filter = $scope.web_player.getTimeRange();
			$scope.loadCuesForWatchedWebEvent();

			// setup intervals that will keep our cues up-to-date and visibility accurate
			$scope.update_cues_interval_id = window.setInterval(
				$scope.loadCuesForWatchedWebEventSilent,
				$scope.UPDATE_CUES_INTERVAL
			);
			$scope.update_cue_visibility_interval_id = window.setInterval(
				$scope.updateCueVisibilityOnInterval,
				$scope.UPDATE_CUE_VISIBILITY_INTERVAL
			);
		});
	};

	// returns the matching cue (we check UUID) from the $scope.cues list. Since the cues list gets auto
	// updated periodically, you should use this method to ensure you are working with a cue that is in the list.
	$scope.findCue = function (cueToFind) {
		for (var i = 0; i < $scope.cues.length; i++) {
			if ($scope.cues[i].uuid == cueToFind.uuid) {
				return $scope.cues[i];
			}
		}
		return null;
	};

	$scope.getTimeWithLeadingZero = function (time) {
		if (time >= 10) return time;
		return '0' + time;
	};

	$scope.convertSecondsToCuePosition = function (seconds) {
		if (seconds > 3600) {
			var hours = parseInt(seconds / 3600);
			var remainingSeconds = seconds % 3600;
			var minutes = parseInt(remainingSeconds / 60);
			remainingSeconds = remainingSeconds % 60;
			remainingSeconds = remainingSeconds.toFixed($scope.CUE_MILLISECOND_DIGITS);
			return (
				$scope.getTimeWithLeadingZero(hours) +
				':' +
				$scope.getTimeWithLeadingZero(minutes) +
				':' +
				$scope.getTimeWithLeadingZero(remainingSeconds)
			);
		} else if (seconds > 60) {
			var minutes = parseInt(seconds / 60);
			var remainingSeconds = seconds % 60;
			remainingSeconds = remainingSeconds.toFixed($scope.CUE_MILLISECOND_DIGITS);
			return '00:' + $scope.getTimeWithLeadingZero(minutes) + ':' + $scope.getTimeWithLeadingZero(remainingSeconds);
		} else {
			seconds = seconds.toFixed($scope.CUE_MILLISECOND_DIGITS);
			return '00:00:' + $scope.getTimeWithLeadingZero(seconds);
		}
	};

	$scope.getVideoCurrentPosition = function (){
		if ($scope.web_player.getVideo() != null){
			return $scope.convertSecondsToCuePosition($scope.web_player.getVideo().currentTime);
		}
		return '00:00:00.000';
	};

	$scope.canShowCueList = function () {
		// don't show cues list if we are editing, updating, or deleting a cue. don't show cues if on iOS device. also don't show if adding a cue, but we don't have any cues in our list
		// if we do have 1 or more cues, then the list remains displayed when adding a new cue -- so user can refer to them if necessary.
		return $scope.is_showing_cues && !$scope.isiOSDevice && ( !$scope.cue_to_edit && !$scope.cue_to_update && !$scope.cue_to_delete && !($scope.cue_to_add && $scope.cues.length == 0));
	}

	$scope.enterUpdateCueMode = function (cue) {

		$scope.cue_to_add = null;
		$scope.cue_to_update = {
			uuid: cue.uuid,
			user: cue.user,
			name: cue.name,
			position: cue.position,
			new_position: $scope.getVideoCurrentPosition()
		};
	};

	$scope.cancelUpdateCue = function () {
		$scope.cue_to_update = null;
		$scope.cue_error_msg = null;
	};

	$scope.updateCue = function () {

		$scope.is_working = true;

		var updated_cue_data = {
			position: $scope.cue_to_update.new_position
		};

		// update cue
		$http.patch(jcs.api.url_v3 + '/customers/' + Authentication.getCurrentUser().customerID + '/webevents/' + $scope.web_event_to_watch.uuid + '/cues/' + $scope.cue_to_update.uuid, updated_cue_data, { withCredentials: true }).then(
			function () {
				const mixPanelProps = {
					[MPEventProperty.CUE_NAME]: $scope.cue_to_update.name,
					[MPEventProperty.TRANSCODED_EVENT_UUID]: $scope.web_event_to_watch.uuid,
					[MPEventProperty.CUE_SHARED]: $scope.cue_to_update.shared === $scope.OPTION_YES
				}

				trackMixpanelEvent(MPEventName.CUE_UPDATE, mixPanelProps)

				var cue = $scope.findCue($scope.cue_to_update);
				if (cue != null) {
					cue.position = updated_cue_data.position
				}

				$scope.cue_to_update = null;
				$scope.cue_error_msg = null;

			},
			function () {

				$scope.cue_error_msg = 'An error occurred while attempting to update the cue. Please try again, or report the problem if it persists.';

			})['finally'](function () {

				$scope.is_working = false;

			});
	};

	$scope.enterDeleteCueMode = function (cue) {
		$scope.cue_to_add = null;
		$scope.cue_to_delete = cue;
	};

	$scope.cancelDeleteCue = function (){
		$scope.cue_to_delete = null;
		$scope.cue_error_msg = null;
	};

	$scope.deleteCue = function (){

		$scope.is_working = true;

		// make http request to delete cue
		// NOTE: I normally would not include the "data: null", but IE11 and below seem to require it in order to work
		// see: https://github.com/angular/angular.js/issues/12141
		$http.delete(jcs.api.url_v3 + '/customers/' + Authentication.getCurrentUser().customerID + '/webevents/' + $scope.web_event_to_watch.uuid + '/cues/' + $scope.cue_to_delete.uuid, { withCredentials: true, data: null }).then(
			function (response) { // success

			const mixpanelProps = {
				[MPEventProperty.CUE_NAME]: $scope.cue_to_delete.name,
				[MPEventProperty.TRANSCODED_EVENT_UUID]: $scope.web_event_to_watch.uuid
			}

			trackMixpanelEvent(MPEventName.CUE_DELETE, mixpanelProps)

				// use findCue to ensure we have a matching cue that is currently in $scope.cues list
				var cue = $scope.findCue($scope.cue_to_delete);
				if (cue != null) {
					// get the index of the cue so we can remove it from the cue list
					var index = $scope.cues.indexOf(cue);
					if (index > -1) {
						$scope.cues.splice(index, 1);
					}
				}

				$scope.cue_to_delete = null;
				$scope.cue_error_msg = null;

			},
			function (reason) { // error

				$scope.cue_error_msg = 'An error occurred trying to create the cue. Please try again, or report the problem if it persists.';

			})['finally'](function () { // always called

				$scope.is_working = false;

			});

	};

	$scope.enterEditCueMode = function (cue){
		$scope.cue_to_add = null;
		$scope.cue_to_edit = cue;
		$scope.cue_to_edit.shared = cue.privateCue ? $scope.OPTION_NO : $scope.OPTION_YES;
	};

	$scope.cancelEditCue = function (){
		$scope.cue_to_edit = null;
		$scope.cue_error_msg = null;
	};

	$scope.toggleShowCues = function (showCues) {
		$scope.is_showing_cues = showCues

		trackMixpanelEvent(
			showCues ? MPEventName.CUE_SHOW : MPEventName.CUE_HIDE,
			{[MPEventProperty.TRANSCODED_EVENT_UUID]: $scope.web_event_to_watch.uuid}
		)
	}

	$scope.editCue = function (){

		var data = {
			name: $scope.cue_to_edit.name,
			position: $scope.cue_to_edit.position,
		};

		// when editing a cue, only set the privateCue value if the user is able to share cues. Otherwise the UI doesn't display a editable
		// field and so we don't need to be changing this value.
		if ($scope.can_share_cues_web_event){
			data.privateCue = $scope.cue_to_edit.shared == $scope.OPTION_NO;
		}

		$scope.is_working = true;

		$http.patch(jcs.api.url_v3 + '/customers/' + Authentication.getCurrentUser().customerID + '/webevents/' + $scope.web_event_to_watch.uuid + '/cues/' + $scope.cue_to_edit.uuid, data, { withCredentials: true }).then(
			function (response) { // success

				// use findCue to ensure we are updating a matching cue that is currently in $scope.cues list
				var cue = $scope.findCue($scope.cue_to_edit);
				if (cue != null) {
					cue.name = $scope.cue_to_edit.name;
					cue.position = $scope.cue_to_edit.position;
					cue.privateCue = $scope.cue_to_edit.shared == $scope.OPTION_NO;
					cue.user = $scope.cue_to_edit.user;
				}

				$scope.cue_to_edit = null;
				$scope.cue_error_msg = null;

			},
			function (reason) { // error

				$scope.cue_error_msg = 'An error occurred trying to create the cue. Please try again, or report the problem if it persists.';

			})['finally'](function () { // always called

				$scope.is_working = false;

			});

	};

	$scope.enterAddCueMode = function (){
		$scope.cue_to_add = {
			name: '',
			position: '',
			shared: $scope.OPTION_YES
		};

		if ($scope.web_player != null){
			var video = $scope.web_player.getVideo();
			if (video != null){
				$scope.cue_to_add.position = $scope.convertSecondsToCuePosition(video.currentTime);
			}
		}
	};

	$scope.addCue = function (){

		var data = {
			name: $scope.cue_to_add.name,
			position: $scope.cue_to_add.position,
			privateCue: $scope.cue_to_add.shared == $scope.OPTION_NO,
		};

		$scope.is_working = true;

		$http.post(jcs.api.url_v3 + '/customers/' + Authentication.getCurrentUser().customerID + '/webevents/' + $scope.web_event_to_watch.uuid + '/cues', data, { withCredentials: true }).then(
			function (response) { // success

				const mixPanelProps = {
					[MPEventProperty.CUE_NAME]: $scope.cue_to_add.name,
					[MPEventProperty.CUE_SHARED]: $scope.cue_to_add.shared === $scope.OPTION_YES,
					[MPEventProperty.TRANSCODED_EVENT_UUID]: $scope.web_event_to_watch.uuid
				}

				trackMixpanelEvent(MPEventName.CUE_ADD, mixPanelProps)

				$scope.cue_to_add = null;
				$scope.cue_error_msg = null;
				// reload our cues
				$scope.loadCuesForWatchedWebEvent();

			},
			function (reason) { // error

				$scope.cue_error_msg = 'An error occurred trying to create the cue. Please try again, or report the problem if it persists.';

			})['finally'](function () { // always called

				$scope.is_working = false;

			});
	};

	$scope.cancelAddCue = function (){
		$scope.cue_to_add = null;
		$scope.cue_error_msg = null;
	};

	$scope.is_publishing = false;
	$scope.publishSimulcast = function (simulcast){

		simulcast.ui.error = null;

		var data = {
			uuid: simulcast.uuid,
			broadcastId: simulcast.broadcastId
		};

		$scope.is_publishing = true;

		$http.patch(jcs.api.url_v3 + '/customers/' + Authentication.getCurrentUser().customerID + '/facebook/channels/' + simulcast.channelId + '/publish', data, { withCredentials: true }).then(
			function (response) { // success
				trackMixpanelEvent(MPEventName.SOCIAL_DESTINATION_PUBLISH, {
					[MPEventProperty.DESTINATION]: simulcast.type === 'fb_page' ? 'Facebook': 'YouTube',
					[MPEventProperty.TRANSCODED_EVENT_UUID]: $scope.web_event_to_watch.uuid,
				});
				simulcast.publishStatus = 'published';
				simulcast.ui.web_player.setStatusLive();

			},
			function (reason) { // error

				simulcast.ui.error = 'An error occurred trying to publish the event. Please try again, or report the problem if it persists.';

			})['finally'](function () { // always called

				$scope.is_publishing = false;

			});

	};

	$scope.next_webplayer_id_index = 1;
	$scope.getUniqueWebplayerId = function (){
		return 'simulcast-webplayer-id-' + $scope.next_webplayer_id_index++;
	};

	// returns true if the given simulcast can contain crossposts
	$scope.isEligibleForCrossposts = function (simulcast) {

		return simulcast.type == $scope.DESTINATION_TYPE_FB_PAGE;
	}

	// returns true if the given web event contains at least one simulcast of the given type
	$scope.hasSimulcastType = function (web_event, simulcast_type) {

		if (web_event.simulcasts != null){
			for (let simulcast of web_event.simulcasts){
				if (simulcast.type == simulcast_type){
					return true;
				}
			}
		}
		return false;
	}

	$scope.loadWebplayerForEvent = function (event) {

		const manifest_url = $scope.isiOSDevice ? event.cloudUrlHls : event.cloudUrlDash;

		// check to see if the manifest is ready; if not, then we'll want to try again in a bit
		$http.get(`https://${manifest_url}`).then(response => {

			$scope.web_event_webplayer_display_status = $scope.DISPLAY_STATUS_SHOW;

			const options = {
				chromeCastAppId: event.chromeCastAppId
			};

			webPlayerService.initializeByManifestUrl(
				$scope.WEB_PLAYER_WRAPPER_ID,
				event.cloudUrlDash,
				event.cloudUrlHls,
				function (webplayer, error) {
					// I was doing a $scope.$apply, but that was having issues. Apparently using $timeout is better ...
					// see: https://stackoverflow.com/questions/12729122/angularjs-prevent-error-digest-already-in-progress-when-calling-scope-apply
					$timeout(function () {
						// anything you want can go here and will safely be run on the next digest.

						if (webplayer == null) {

							$scope.error_msg = error;

						} else {

							$scope.web_player = webplayer;

							// currently we don't support showing cues on iOS devices (we are unable to do filtering using the <video> tag element info)
							// this is because iOS always seems to show the start time as zero, instead of the actual start position within the entire stream
							$scope.can_show_cues = $scope.web_player.canGetTimeRange();

							// load cues
							if ($scope.can_show_cues) {
								// choose an event listener that will fire once the video is going (and we can access the time range)
								$scope.web_player.getVideo().addEventListener('playing', $scope.initializeCueUpdates);
							}

						}
					});
				},
				options
			);

		}).catch(reason => {

			if ((reason.status === 403 || reason.status === 404) && event.status !== $scope.STATUS_STOPPED && event.status !== $scope.STATUS_ERROR && event.status !== $scope.STATUS_ABORTED){

				$scope.web_event_webplayer_display_status = $scope.DISPLAY_STATUS_NOT_YET_AVAILABLE;

				if ($scope.loading_webplayer_timeout_id !== null){
					window.clearTimeout($scope.loading_webplayer_timeout_id);
					$scope.loading_webplayer_timeout_id = null;
				}
				// manifest isn't ready yet, so try again shortly
				$scope.loading_webplayer_timeout_id = window.setTimeout(() => {
					$scope.loadWebplayerForEvent(event);
				}, $scope.CHECK_MANIFEST_AVAILABILITY_TIME_DELAY);

			} else {

				$scope.web_event_webplayer_display_status = $scope.DISPLAY_STATUS_ERROR;

			}
		});
	};

	$scope.loadWebplayersForSimulcasts = function (event) {

		if ($scope.has_social_media_perm){
			// initialize simulcast webplayers and UI
			if (event.simulcasts != null){
				for (let i=0; i < event.simulcasts.length; i++){
					let simulcast = event.simulcasts[i];
					simulcast.ui = {
						element_id: $scope.getUniqueWebplayerId(),
						web_player: null,
						error: null,
						preview_not_available: false,
						timeout_id: null,
						stream_ended: simulcast.status == $scope.STATUS_STOPPED || simulcast.status == $scope.STATUS_ABORTED
					};
				}

				$timeout(function (){
					for (let i=0; i < event.simulcasts.length; i++){
						let simulcast = event.simulcasts[i];
						if (simulcast.dashPreviewUrl == null || ($scope.social_media.isFacebookType(simulcast.type) && $scope.isiOSDevice)){
							simulcast.ui.preview_not_available = true;
						} else {
							if ($scope.canLoadVideoPlayer(simulcast)){
								$scope.loadPreview(simulcast);
							} else {
								console.log("NOT SHOWING WEBPLAYER for " + simulcast.formattedChannelName);
							}
						}
					}
				});
			}
		}
	};

	$scope.loadCrossposts = function (event) {

		$scope.is_loading_crossposts = true;

		$http.get(`${jcs.api.url_v3}/customers/${Authentication.getCurrentUser().customerID}/webevents/${event.uuid}`, { withCredentials: true }).then((response) => {

			$scope.crosspost_error = null;

			// find matching simulcast and replace crossposts
			if (response.data.hasOwnProperty('simulcasts') && response.data.simulcasts != null){
				for (let simulcast of response.data.simulcasts){
					for (let simulcast_to_update of event.simulcasts){
						if (simulcast.uuid == simulcast_to_update.uuid){
							simulcast_to_update.crossposts = simulcast.crossposts;
							break;
						}
					}
				}
			}

		}).catch(() => {

			$scope.crosspost_error = 'Unable to load crosspost information';

		}).finally(() => {

			$scope.is_loading_crossposts = false;

		});
	};

	$scope.enterWebEventWatchMode = async function (event) {

		$scope.cues = null;
		$scope.visible_cue_count = 0;
		$scope.cue_time_range_filter = null;
		$scope.is_showing_cues = true;
		$scope.web_event_webplayer_display_status = $scope.DISPLAY_STATUS_SHOW;
		$scope.web_event_to_watch = event;
		const promises = [];
		promises.push($http.get(`${jcs.api.url_v3}/customers/${Authentication.getCurrentUser().customerID}/webeventprofiles/${$scope.web_event_to_watch.webEventProfileId}/`, {withCredentials: true}));

		$q.all(promises).then((response) => {
			$scope.web_event_to_watch_profile = response[0].data;
		});

		if (!$scope.web_events) {
			$scope.loadWebEventsSilent()
		}

		// ensure we are scrolled to the top
		$(window).scrollTop(0);

		// if this web event has at least one simulcast that is a FB page, then load the web event so we can get the crosspost information (which isn't returned when fetching list of web events)
		if ($scope.hasSimulcastType($scope.web_event_to_watch, $scope.DESTINATION_TYPE_FB_PAGE)){
			$scope.loadCrossposts(event);
		}
		$scope.loadWebplayerForEvent(event);
		$scope.loadWebplayersForSimulcasts(event);
	};

	$scope.loadPreview = function (simulcast){

		// So currently we "ping" the manifest to see if it is available. If it isn't available to display a message to the user. Once
		// the manifest does become available, then we auto display the webplayer.

		$http.get(simulcast.dashPreviewUrl).then(
			function (response) { // success

//console.log("WAITING 0 SECONDS BEFORE STARTING PLAYER ...");
// waiting does seem to help ... but the webplayer area is just blank (our "event has not yet started") message isn't displayed during the
// waiting period. So we need to continue showing previous message, or show an updated message.
// IT MAY simply have to do with how long the facebook stream has been going. If you catch it right when it is starting up, it looks like a
// delay has some benefit. But if it's been going a while, then it looks like you can remove the timeout without much issue.
//				$timeout(function(){

					var options = {
						muted: true,
						showControlsBelow: true
					};

					webPlayerService.initializeByManifestUrl(
						simulcast.ui.element_id,
						simulcast.dashPreviewUrl,
						null, // no HLS manifest
						function (webplayer, error) {
//							$timeout(function () {
								// anything you want can go here and will safely be run on the next digest.
								if (webplayer == null) {
									simulcast.ui.error = error;
								} else {
									if (simulcast.publishStatus == 'published'){
										webplayer.setStatusLive();
									}
									else if (simulcast.publishStatus == 'unpublished'){
										webplayer.setStatusUnpublished();
									}
									// register to be notified when the player reaches the end of the live stream, so we can
									// hide the webplayer and let the user know the event has ended.
									webplayer.registerOnStreamEndedCallback(simulcast.ui.element_id, function(event, element_id){
//console.log("[FCH] ON STREAM ENDED. element_id=" + element_id);console.log(event);
// TODO: double check to see if we need the $apply stuff
										$scope.$apply(function () {
											simulcast.ui.stream_ended = true;
//console.log("[FCH] FOUND SIMULCAST ... UPDATING stream_ended to TRUE");
										});
									});
									// register to be notified when the stream has started. Since our simulcast status may be stale (it may
									// be "started" even though it has really stopped) we need to check the "isLive" status of the webplayer.
									// If our stream isn't live, then we should close the player and let the user know the event has ended.
									webplayer.registerOnStreamStartedCallback(function(event){
//console.log("[FCH] ON STREAM STARTED. event=");console.log(event);
										simulcast.ui.is_live = simulcast.ui.web_player.isLive();
										simulcast.ui.stream_ended = !simulcast.ui.web_player.isLive();
										if (simulcast.ui.stream_ended){
//console.log("[FCH] Unloading webplayer!! element_id = " + simulcast.ui.element_id);
											simulcast.ui.web_player.unload();
										}
									});
									simulcast.ui.web_player = webplayer;
								}
//							});
						},
						options
					);

//				}, 1000);

			},
			function (reason) { // error

				// check to see if we have a scheduled timeout
				if (simulcast.ui.timeout_id != null) {
					// if so, then clear it
					window.clearTimeout(simulcast.ui.timeout_id);
					simulcast.ui.timeout_id = null;
				}
				// manifest isn't ready yet, so try again shortly
				simulcast.ui.timeout_id = window.setTimeout(function(){
					$scope.loadPreview(simulcast);
				}, 2000);

			})
			['finally'](function () { // always called
			});

	};

	// returns true if it is okay to load the video player
	$scope.canLoadVideoPlayer = function (simulcast){
		return simulcast.status != $scope.STATUS_STOPPED && simulcast.status != $scope.STATUS_ABORTED && simulcast.status != $scope.STATUS_ERROR;
	};

	$scope.canShowVideoPlayer = function (simulcast){
		return simulcast.hasOwnProperty('ui') && simulcast.ui.web_player != null && !simulcast.ui.stream_ended;
	};

	$scope.isEventLive = function (web_event) {
		let formatted_status = $scope.formatWebEventStatus(web_event);
		return formatted_status == $scope.STATUS_STARTED || formatted_status == $scope.STATUS_STARTING;
	};

	$scope.processLocationCounts = function () {

		let city_index = {};
		let region_index = {};
		let country_index = {};
		for (let entry of $scope.event_analytics.locations){
			// city
			let index = `${entry.city}-${entry.region}-${entry.country}`;
			if (!city_index.hasOwnProperty(index)){
				city_index[index] = {
					city: entry.city,
					region: entry.region,
					country: entry.country,
					value: parseInt(entry.count)
				}
			} else {
				city_index[index].value += parseInt(entry.count);
			}
			// region
			index = `${entry.region}-${entry.country}`;
			if (!region_index.hasOwnProperty(index)){
				region_index[index] = {
					region: entry.region,
					country: entry.country,
					value: parseInt(entry.count)
				};
			} else {
				region_index[index].value += parseInt(entry.count);
			}
			// country
			index = `${entry.country}`;
			if (!country_index.hasOwnProperty(entry.country)){
				country_index[index] = {
					country: entry.country,
					value: parseInt(entry.count),
				};
			} else {
				country_index[index].value += parseInt(entry.count);
			}
		}
		$scope.viewers_by_city = Object.values(city_index);
		$scope.viewers_by_region = Object.values(region_index);
		$scope.viewers_by_country = Object.values(country_index);
	};

	$scope.processEventAnalytics = function (data) {
		const noNullLocations = data.locations.filter(location => !!location.latitude && !!location.longitude);
		data = { ...data, locations: noNullLocations };

		updateEventAnalytics(data);
		$scope.heatmap_data = $scope.event_analytics.locations;
		// determine number of viewers by city, region, country
		$scope.processLocationCounts();

		// convert resolutions data into array
		$scope.event_analytics.resolutions_array = [];
		for (const property in $scope.event_analytics.resolutions) {
			const entry = {
				label: property,
				value: $scope.event_analytics.resolutions[property],
			};
			$scope.event_analytics.resolutions_array.push(entry);
		}

		// organize data/labels for time watched
		$scope.event_analytics.watchTimesArray = [];
		let totalWatched = 0;
		let watchedLittle = 0; // <= 10 minutes
		let watchedSome = 0; // 10 to 30 minutes
		let watchedMost = 0; // 30+ minutes
		for (const timeRange in $scope.event_analytics.watchTimes) {
			const value = $scope.event_analytics.watchTimes[timeRange];
			totalWatched += value;
			// add info for overview
			if (timeRange <= 5) {
				// 5 will include 5 to 10 minutes
				watchedLittle += value;
			} else if (timeRange <= 25) {
				// 25 will include 25 to 30 minutes
				watchedSome += value;
			} else {
				watchedMost += value;
			}
			// add array entry for detailed view
			if (timeRange == 0) {
				$scope.event_analytics.watchTimesArray.push({ label: '1.5 to 5 minutes', value: value });
			} else {
				const timeRangeEnd = parseInt(timeRange) + 5;
				$scope.event_analytics.watchTimesArray.push({
					label: timeRange + ' to ' + timeRangeEnd + ' minutes',
					value: value,
				});
			}
		}
		// any viewers that don't show up in the watchTimes count are assumed to have dropped off before their first status report at 90 seconds
		$scope.event_analytics.lessThanNinetySeconds = $scope.event_analytics.totalViewers - totalWatched;
		// calculate data for "time watched" overview
		$scope.event_analytics.timeWatchedOverview = [
			{ label: '0 to 10 minutes', value: watchedLittle + $scope.event_analytics.lessThanNinetySeconds },
			{ label: '10 to 30 minutes', value: watchedSome },
			{ label: '30+ minutes', value: watchedMost },
		];
		$scope.event_analytics.showTimeWatchedOverview = true;

	};

	// no seconds
	$scope.showTimeAsLocalHM = function (dateTimeToConvert) {
		if (dateTimeToConvert == null) return '';

		// NOTE: we were using the Date "toLocaleString" method, but it turns out this isn't implemented consistently across
		// browsers. That is why we are using a javascript library (momentjs).

		// is the given time today?
		var currentDay = moment().format('MMM D, YYYY');
		var givenTimeDay = moment(dateTimeToConvert).format('MMM D, YYYY');
		if (currentDay == givenTimeDay) {
			return 'today at ' + moment(dateTimeToConvert).format('h:mm a');
		}

		// is the given time this year?
		var currentYear = moment().format('YYYY');
		var givenTimeYear = moment(dateTimeToConvert).format('YYYY');
		if (currentYear == givenTimeYear) {
			return moment(dateTimeToConvert).format('MMM D, h:mm a');
		}

		// for formatting options see: http://momentjs.com/
		return moment(dateTimeToConvert).format('MMM D, YYYY h:mm a');
	};

	$scope.loadEventAnalyticsSilent = function () {

		const promises = [];
		promises.push($http.get(`${jcs.api.url}/public/events/${$scope.event_to_analyze.uuid}/status/rep?geoData=true`, { withCredentials: true }));
		promises.push($http.get(`${jcs.api.url_v3}/customers/${Authentication.getCurrentUser().customerID}/webevents/${$scope.event_to_analyze.uuid}`, { withCredentials: true }));

		$q.all(promises).then(([event, customer]) => {

			$scope.error_msg = null;
			$scope.analytics_update_time = moment().format();
			$scope.processEventAnalytics(event.data);

			$scope.event_to_analyze.status = customer.data.status;

		}).catch(e => {
			// don't show an error since the user did not initiate this action
			console.error(e)
		}).finally(() => {

			// if we think the event is still live, then set timeout so analytics data will auto refresh
			if ($scope.isEventLive($scope.event_to_analyze)){
				$scope.load_analytics_data_timeout_id = window.setTimeout(
					$scope.loadEventAnalyticsSilent,
					$scope.UPDATE_ANALYTICS_TIME_DELAY
				);
			}
		});
	};

	$scope.loadEventAnalytics = async function () {
		$scope.event_analytics = null;
		const errorMsg = 'An error occurred while attempting to retrieve the analytics data. You can use the Detailed Report and Summary buttons to download analytics data.';

		$scope.is_loading = true;

		let errorCount = 0;
		try {
			const statsResponse = await eventService.getEventStats($scope.event_to_analyze.uuid);
			updateEventAnalytics(statsResponse);
		} catch(e) {
			console.error(e);
			$scope.statisticsError = true;
			$scope.error_msg = errorMsg;
			errorCount++;
		}

		const promises = [];
		promises.push($http.get(`${jcs.api.url}/public/events/${$scope.event_to_analyze.uuid}/status/rep?geoData=true`, { withCredentials: true }));
		promises.push($http.get(`${jcs.api.url_v3}/customers/${Authentication.getCurrentUser().customerID}/webevents/${$scope.event_to_analyze.uuid}`, { withCredentials: true }));

		$q.all(promises).then(([event, customer]) => {
			$scope.processEventAnalytics(event.data);
			$scope.event_to_analyze.status = customer.data.status;
		}).catch(e => {
			console.error(e);

			$scope.statusError = true;
			$scope.error_msg = errorMsg;
			errorCount++;
		}).finally(() => {

			if (errorCount === 0) {
				$scope.error_msg = null;
			}

			$scope.is_loading = false;

			// if we think the event is still live, then set timeout so analytics data will auto refresh
			if ($scope.isEventLive($scope.event_to_analyze)){
				$scope.load_analytics_data_timeout_id = window.setTimeout(
					$scope.loadEventAnalyticsSilent,
					$scope.UPDATE_ANALYTICS_TIME_DELAY
				);
			}
		});
	};

	$scope.cancelEdit = function () {
		$scope.event_to_edit = null;
		$scope.error_msg = null;
		$scope.validation.clear();
	};

	$scope.cancelDelete = function () {
		$scope.event_to_delete = null;
		$scope.error_msg = null;
	};

	$scope.cancelTranscode = function () {
		$scope.event_to_transcode = null;
		$scope.error_msg = null;
		$scope.has_error = {};
	};

	$scope.isEmpty = function (value) {
		return value == null || value === '';
	};

	$scope.varsAreDefined = function() {
		return [...arguments].every(a => a !== undefined && a !== null);
	}

	$scope.getWebEventProfileRegionId = function (event_profile_uuid) {
		if ($scope.web_event_profiles){
			const match = $scope.web_event_profiles.find(profile => profile.uuid === event_profile_uuid);
			if (match){
				return match.regionId;
			}
		}
		return null;
	};

	$scope.doesStartWebEventFailValidation = function () {
		$scope.has_error = {};

		// check required fields to ensure they aren't empty
		$scope.has_error.web_event_name = $scope.isEmpty($scope.web_event_name);
		$scope.has_error.web_event_profile = $scope.isEmpty($scope.web_event_profile);
		//                $scope.has_error.web_encoder_profile = $scope.isEmpty($scope.web_encoder_profile);
		$scope.has_error.web_start_position = $scope.isEmpty($scope.web_event_start_position);
		$scope.has_error.web_stop_position = $scope.isEmpty($scope.web_event_stop_position);

		let has_validation_error = Object.values($scope.has_error).some(isErr => isErr);

		$scope.error_msg = has_validation_error ? 'Please specify a value for the highlighted fields below.' : null;

		if (!has_validation_error){
			const web_event_profile_region_id = $scope.getWebEventProfileRegionId($scope.web_event_profile);
			if ($scope.encoder_event_region_id !== null && web_event_profile_region_id !== null && web_event_profile_region_id !== constants.INTEGRATION_REGION_UUID && $scope.encoder_event_region_id !== web_event_profile_region_id){
				has_validation_error = true;
				$scope.has_error.web_event_profile = true;
				$scope.error_msg = `The region of the web event profile must match the region of the encoder event. Please select a profile with a matching region.`;
				return has_validation_error;
			}
		}

		//ensure no "<" or ">" characters are included within the edited web event name
		if (!has_validation_error) {
			if ($scope.web_event_name.split('').find(char => char === "<" || char === ">")) {
				has_validation_error = true;
				$scope.error_msg = 'The web event name can not include the characters "<" or ">".';
				$scope.has_error.web_event_name = true;
				return has_validation_error;
			}
		}

		if (has_validation_error ||
			$scope.web_event_start_position === $scope.DEFAULT_WEB_START_POSITION ||
			$scope.web_event_stop_position === $scope.DEFAULT_WEB_STOP_POSITION) {
			return has_validation_error;
		}
		if ($scope.web_event_start_position > $scope.web_event_stop_position) {
			has_validation_error = true;
			$scope.has_error.web_stop_position = true;
			$scope.error_msg = 'Please choose a stop position that comes after the start position.';
			return has_validation_error;
		}
		if ($scope.web_event_start_position === $scope.web_event_stop_position) {
			has_validation_error = true;
			$scope.has_error.web_start_position = true;
			$scope.has_error.web_stop_position = true;
			$scope.error_msg = 'The start and stop positions should not be equal. Please choose a different start or stop position.';
			return has_validation_error;
		}

		return has_validation_error;
	};

	$scope.startWebEvent = function () {
		// if we have form validation errors, then don't go any further
		if ($scope.doesStartWebEventFailValidation()) {
			return false;
		}

		let realtimePlayback;
		let published;

		if ($scope.playoutBehavior === $scope.PLAYOUT_BEHAVIOR.REAL_TIME) {
			realtimePlayback = true;
			published = true;
		} else if ($scope.playoutBehavior === $scope.PLAYOUT_BEHAVIOR.ON_DEMAND) {
			realtimePlayback = false;
			published = true;
		} else if ($scope.playoutBehavior === $scope.PLAYOUT_BEHAVIOR.ON_DEMAND_UNPUBLISHED) {
			realtimePlayback = false;
			published = false;
		}

		const data = {
			webEventProfileId: $scope.web_event_profile,
			webEncoderProfileId: $scope.web_encoder_profile,
			webEventName: $scope.web_event_name,
			realtimePlayback,
			published,
		};

		// add simulcasts
		if ($scope.web_event_simulcasts != null && $scope.web_event_simulcasts.length > 0) {
			data.simulcasts = [];
			for (let i=0; i < $scope.web_event_simulcasts.length; i++) {
				data.simulcasts.push({
					channelId: $scope.web_event_simulcasts[i].channelId,
					type: $scope.web_event_simulcasts[i].type,
					destinationId: $scope.web_event_simulcasts[i].destinationId,
					destinationName: $scope.web_event_simulcasts[i].destinationName,
					privacy: $scope.web_event_simulcasts[i].privacy,
					publishStatus: $scope.web_event_simulcasts[i].publishStatus,
					channelName: $scope.web_event_simulcasts[i].channelName,
					title: $scope.web_event_simulcasts[i].title,
					description: $scope.web_event_simulcasts[i].description,
					crossposts: $scope.web_event_simulcasts[i].crossposts,
				});
			}
		}

		// add start/stop positions if specified
		if ($scope.web_event_start_position !== $scope.DEFAULT_WEB_START_POSITION) {
			data.startPosition = $scope.web_event_start_position;
		}
		if ($scope.web_event_stop_position !== $scope.DEFAULT_WEB_STOP_POSITION) {
			data.endPosition = $scope.web_event_stop_position;
		}

		const getSocialDestDetails = (simulcasts, type) => simulcasts?.find((simulcast) => simulcast.type === type);

		$scope.is_working = true;

		// make http request to begin transcoding
		const beginTranscodeUrl = `${jcs.api.url_v3}/customers/${Authentication.getCurrentUser().customerID}/events/${$scope.event_to_transcode.uuid}/transcode`;
		httpService.post(beginTranscodeUrl, data, { withCredentials: true,},
			() => { // success
				trackMixpanelEvent(MPEventName.WEB_EVENT_START, {
					[MPEventProperty.PLAYOUT_BEHAVIOR]: $scope.playoutBehavior,
					[MPEventProperty.EVENT_UUID]: $scope.event_to_transcode.uuid,
					[MPEventProperty.WEB_EVENT_NAME]: $scope.web_event_name,
					[MPEventProperty.WEB_EVENT_PROFILE_NAME]: $scope.web_event_profiles?.find((profile) => profile.uuid === $scope.web_event_profile)?.name ,
					[MPEventProperty.WEB_EVENT_PROFILE_UUID]: $scope.web_event_profiles?.find((profile) => profile.uuid === $scope.web_event_profile)?.uuid,
					[MPEventProperty.WEB_ENCODER_PROFILE_NAME]: $scope.web_encoder_profiles?.find((profile) => profile.uuid === $scope.web_encoder_profile)?.name ,
					[MPEventProperty.WEB_ENCODER_PROFILE_UUID]: $scope.web_encoder_profiles?.find((profile) => profile.uuid === $scope.web_encoder_profile)?.uuid,
					[MPEventProperty.CUE_START]: $scope.web_event_start_position,
					[MPEventProperty.CUE_STOP]: $scope.web_event_stop_position,
					[MPEventProperty.FACEBOOK_DESTINATION_ACCOUNT_NAME]: getSocialDestDetails(data.simulcasts, 'fb_page')?.channelName,
					[MPEventProperty.FACEBOOK_DESTINATION]: getSocialDestDetails(data.simulcasts, 'fb_page')?.destinationName,
					[MPEventProperty.FACEBOOK_PRIVACY]: getSocialDestDetails(data.simulcasts, 'fb_page')?.privacy,
					[MPEventProperty.FACEBOOK_PUBLISH_STATUS]: getSocialDestDetails(data.simulcasts, 'fb_page')?.publishStatus,
					[MPEventProperty.FACEBOOK_CROSSPOST_DESTINATIONS]: getSocialDestDetails(data.simulcasts, 'fb_page')?.crossposts?.map((cp) => cp.destinationName),
					[MPEventProperty.YOUTUBE_DESTINATION_ACCOUNT_NAME]: getSocialDestDetails(data.simulcasts, 'yt_create_new')?.channelName,
					[MPEventProperty.YOUTUBE_PRIVACY]: getSocialDestDetails(data.simulcasts, 'yt_create_new')?.privacy,
					[MPEventProperty.YOUTUBE_PUBLISH_STATUS]: getSocialDestDetails(data.simulcasts, 'yt_create_new')?.publishStatus,
				});
				$scope.event_to_transcode = null;
				$scope.error_msg = null;
				$scope.playoutBehavior = $scope.PLAYOUT_BEHAVIOR.REAL_TIME;
				$scope.loadWebEventsSilent();
			},
			({ status }) => { // error
				if (status == 409) {
					$scope.error_msg = 'The web event cannot be started, because the web event profile you have selected is already in use.';
					return;
				}
				$scope.error_msg = 'An error occurred while attempting to start the web event. Please try again, or report the problem if it persists.';
			},
			() => $scope.is_working = false ); // finally

	};

	$scope.deleteEvent = function () {
		$scope.is_working = true;

		// make http request to delete event
		// NOTE: I normally would not include the "data: null", but IE11 and below seem to require it in order to work
		// see: https://github.com/angular/angular.js/issues/12141
		//                $http.delete(jcs.api.url+'/streamprofiles/' + $scope.stream_id + '/events/' + $scope.event_to_delete.uuid, {withCredentials: true, data: null})
		$http.delete($scope.event_to_delete.url, { withCredentials: true, data: null }).then(
			function () { // success
				trackMixpanelEvent(MPEventName.ENCODER_EVENT_DELETE, {
					[MPEventProperty.EVENT_UUID]: $scope.event_to_delete.uuid,
				});
				// remove event from our events list
				var index = $scope.events.indexOf($scope.event_to_delete);
				if (index > -1) {
					$scope.events.splice(index, 1);
				}

				$scope.event_to_delete = null;
				$scope.error_msg = null;
			},
			function () { // error

				$scope.error_msg = 'An error occurred while attempting to delete the event. Please try again, or report the problem if it persists.';

			})
			['finally'](function () { // always called

				$scope.is_working = false;

			});
	};

	$scope.deleteWebEvent = function () {
		$scope.is_working = true;

		eventService.deleteWebEvent($scope.web_event_to_delete).then(() => {

			trackMixpanelEvent(MPEventName.WEB_EVENT_DELETE, {
				[MPEventProperty.TRANSCODED_EVENT_UUID]: $scope.web_event_to_delete.uuid,
			});

			eventService.clearWebCache();
			eventService.loadWebEvents().then(response => {

				$scope.web_events_error_msg = null;
				$scope.web_events = response;
				$scope.formatWebEvents();
				// remove any stopped web events from our web events stopping list
				$scope.updateWebEventsThatAreStopping($scope.web_events);

			}).catch(reason => {

				$scope.web_events_error_msg = 'An error occurred while attempting to refresh the web events. Please try again, or report the problem if it persists.';
				$scope.web_events = null;

			}).finally(() => {

				// send user back to previous location
				$scope.back();
				$scope.is_working = false;

			});

		}).catch(reason => {

			$scope.error_msg = 'An error occurred while attempting to delete the web event. Please try again, or report the problem if it persists.';
			$scope.is_working = false;

		});
	};

	$scope.stopWebEvent = function () {

		$scope.is_working = true;

		eventService.stopWebEvent($scope.web_event_to_stop).then(
			function () { // success
				trackMixpanelEvent(MPEventName.WEB_EVENT_STOP, {
					[MPEventProperty.TRANSCODED_EVENT_UUID]: $scope.web_event_to_stop.uuid,
				});
				// send user back to previous location
				$scope.back();

			},
			function (reason) { // error

				$scope.error_msg = 'An error occurred while attempting to stop the web event. Please try again, or report the problem if it persists.';

			})['finally'](function(){

				$scope.is_working = false;

			});
	};

	// returns TRUE if we have a form validation issue that the user needs to address
	$scope.doesEditWebEventFailValidation = function () {
		$scope.error_msg = null;
		$scope.validation.clear();

		// ensure required fields are not empty
		$scope.validation.checkForEmpty('web_event_to_edit_name', $scope.web_event_to_edit_name);

		var has_validation_error = $scope.validation.hasError();
		if (has_validation_error) {
			$scope.error_msg = 'Please specify a value for the highlighted fields.';
		}

		//ensure no "<" or ">" characters are included within the edited web event name
		if(!has_validation_error) {
			if ($scope.web_event_to_edit_name?.split('').find(char => char === "<" || char === ">")) {
				has_validation_error = true;
				$scope.error_msg = 'The web event name can not include the characters "<" or ">".';
				$scope.validation.setError('web_event_to_edit_name');
			}
		}

		return has_validation_error;
	};

	$scope.saveWebEventEdit = function () {
		// if we have form validation errors, then don't go any further
		if ($scope.doesEditWebEventFailValidation()) return false;

		var updated_event_data = {
			name: $scope.web_event_to_edit_name,
			description: $scope.web_event_to_edit_desc,
		};

		$scope.is_working = true;

		eventService.editWebEvent($scope.web_event_to_edit, updated_event_data).then(
			function () { // success
				trackMixpanelEvent(MPEventName.WEB_EVENT_EDIT, {
					[MPEventProperty.TRANSCODED_EVENT_UUID]: $scope.web_event_to_edit.uuid,
				});
				// send user back to previous location
				$scope.back();

			},
			function (reason) { // error

				$scope.error_msg = 'An error occurred while attempting to update the web event. Please try again, or report the problem if it persists.';

			})['finally'](function(){

				$scope.is_working = false;

			});
	};

	// returns TRUE if we have a form validation issue that the user needs to address
	$scope.doesEditEventFailValidation = function () {
		$scope.error_msg = null;
		$scope.validation.clear();

		// ensure required fields are not empty
		$scope.validation.checkForEmpty('event_to_edit_name', $scope.event_to_edit_name);

		var has_validation_error = $scope.validation.hasError();
		if (has_validation_error) {
			$scope.error_msg = 'Please specify a value for the highlighted fields.';
		}

		return has_validation_error;
	};

	$scope.saveEdit = function () {
		// if we have form validation errors, then don't go any further
		if ($scope.doesEditEventFailValidation()) return false;

		var updated_event_data = {
			name: $scope.event_to_edit_name,
		};

		$scope.is_working = true;

		// update event
		$http.patch($scope.event_to_edit.url, updated_event_data, { withCredentials: true }).then(
			function () { // success
				trackMixpanelEvent(MPEventName.ENCODER_EVENT_EDIT, {
					[MPEventProperty.EVENT_UUID]: $scope.event_to_edit.uuid,
				});
				// update was successful, so update our actual event in the model
				$scope.event_to_edit.name = $scope.event_to_edit_name;

				$scope.event_to_edit = null;
				$scope.error_msg = null;
			},
			function () { // error

				$scope.error_msg = 'An error occurred while attempting to update the event. Please try again, or report the problem if it persists.';

			})
			['finally'](function () { // always called

				$scope.is_working = false;
			});
	};

	$scope.showMoreInfoAboutStopDialog = function (simulcast_type) {
		if($scope.social_media.isFacebookType(simulcast_type)){
			$('#explain-fb-stop-delay').modal('show');
		} else if ($scope.social_media.isYouTubeType(simulcast_type)){
			$('#explain-yt-stop-delay').modal('show');
		}
	};

	$scope.enterStopSimulcastMode = function (simulcast) {
		$scope.simulcast_to_stop = simulcast;
		$('#stop-simulcast-confirmation').modal('show');
	};

	$scope.canStopSimulcast = function (status) {
		if (status != $scope.STATUS_STOPPED && status != $scope.STATUS_STOPPING && status != $scope.STATUS_ABORTED && status != $scope.STATUS_ERROR){
			return true;
		}
		return false;
	};

	$scope.showActivityIndicator = function (status) {
		return status == $scope.STATUS_STARTING || status == $scope.STATUS_STOPPING;
	};

	$scope.stopSimulcast = function () {

		$scope.is_stopping_simulcast = true;

		let web_event = $scope.web_event_to_watch != null ? $scope.web_event_to_watch : $scope.web_event_to_view;
		let url = `${jcs.api.url_v3}/customers/${Authentication.getCurrentUser().customerID}/webevents/${web_event.uuid}/simulcasts/${$scope.simulcast_to_stop.uuid}/stop`;

		$http.post(url, {}, { withCredentials: true }).then(() => {
			trackMixpanelEvent(MPEventName.SOCIAL_DESTINATION_STOP, {
				[MPEventProperty.DESTINATION]: $scope.simulcast_to_stop.type === 'fb_page' ? 'Facebook': 'YouTube',
				[MPEventProperty.TRANSCODED_EVENT_UUID]: $scope.web_event_to_watch.uuid,
			});
			simulcastsService.add($scope.simulcast_to_stop.uuid);
			$scope.simulcast_to_stop.ui.error = null;

		}).catch(() => {

			$scope.simulcast_to_stop.ui.error = 'An error occurred while attempting to stop the event. Please try again, or report the problem if it persists.';

		}).finally(() => {

			$scope.is_stopping_simulcast = false;
			$scope.simulcast_to_stop = null;

		});
	};

	$scope.handleMixpanelDestinationView = function (type) {
		trackMixpanelEvent(MPEventName.SOCIAL_DESTINATION_VIEW, {
			[MPEventProperty.DESTINATION]: type === 'fb_page' ? 'Facebook': 'YouTube',
			[MPEventProperty.TRANSCODED_EVENT_UUID]: $scope.web_event_to_watch.uuid,
		});
	}

	$scope.cancelStopSimulcast = function () {
		$scope.simulcast_to_stop = null;
	};

	// instead of displaying "idle", lets say "starting"
	$scope.formatWebEventStatus = function (web_event) {
		if (web_event == null) {
			return '';
		}
		if (web_event.status == $scope.STATUS_IDLE) {
			return $scope.STATUS_STARTING;
		}
		if (web_event.status == $scope.STATUS_STARTED && webEventsService.isStopping(web_event.uuid)) {
			return $scope.STATUS_STOPPING;
		}
		return web_event.status;
	};

	$scope.formatSimulcastStatus = function (status, uuid){
		if (status != $scope.STATUS_STOPPED && simulcastsService.isStopping(uuid)) {
			return $scope.STATUS_STOPPING;
		}
		if (status == $scope.STATUS_IDLE){
			return $scope.STATUS_STARTED;
		}
		return status;
	};

	$scope.getFormattedPublishStatus = function (option){
		if (option == null){
			return '';
		}
		if (option == $scope.social_media.SCHEDULED_POST){
			return 'Scheduled Post';
		}
		return option;
	};

	// returns true if any of this web event's simulcasts are in the started state
	$scope.hasRunningSimulcasts = function (web_event) {
		if (web_event.simulcasts != null) {
			for (var i = 0; i < web_event.simulcasts.length; i++) {
				var simulcast = web_event.simulcasts[i];
				if (simulcast.status != $scope.STATUS_STOPPED && simulcast.status != $scope.STATUS_ABORTED && simulcast.status != $scope.STATUS_ERROR) {
					return true;
				}
			}
		}
		return false;
	};

	// the web event list should update periodically to ensure the status is accurate. when a web event has just started (status=idle)
	// then we'll want to refresh the status more frequently.
	$scope.getWebEventStatusUpdateTimeInSec = function () {

		if ($scope.web_events != null) {
			for (var i = 0; i < $scope.web_events.length; i++) {
				var status = $scope.formatWebEventStatus($scope.web_events[i]);
				// transitional states will use a quick update time
				if (status == $scope.STATUS_STARTING || status == $scope.STATUS_STOPPING){
					return $scope.CHECK_WEB_EVENT_STATUS_TIME_DELAY_SHORT;
				}
			}
		}
		return $scope.CHECK_WEB_EVENT_STATUS_TIME_DELAY_LONG; // otherwise a longer update time is sufficient
	};


	$scope.formatWebEvent = function (web_event){
		if (web_event.simulcasts != null) {
			for (var j = 0; j < web_event.simulcasts.length; j++) {
				var simulcast = web_event.simulcasts[j];
				web_event.simulcasts[j].formattedChannelName = simulcast.channelName;
				web_event.simulcasts[j].formattedDestination = simulcast.destinationName != null ? simulcast.destinationName : 'Stream Now';
			}
		}
		web_event.google_log_url = webEventsService.getGoogleLogUrl(web_event.uuid);
		return web_event;
	};

	// format web event fields for easy display on the UI
	$scope.formatWebEvents = function () {
		if ($scope.web_events != null) {
			for (var i = 0; i < $scope.web_events.length; i++) {
				$scope.formatWebEvent($scope.web_events[i]);
			}
		}
	};

	$scope.goToUploadsInProgress = function() {
		$scope.getRecentUploads();
		$scope.showInProgressUploads = true;
	}

	//Create a link on the top of the Events Page for users who have actively processing uploads OR recent uploads (72 hour window).
	$scope.getRecentUploads = async function () {
		const events = await uploadsInProgressService.getStatusesByCustomerId(Authentication.getCurrentUser().customerID);
		if (!events) {
			return;
		}

		$scope.uploadsInProgress = events.filter(x => x.status === 'IN_PROGRESS');

		$scope.upcomingUploadEvents = events.filter(event => {
			const createdDate = new Date(event.created);
			const recentDate = moment(new Date()).subtract($scope.UPLOAD_PROGRESS_HOURS_IN_PAST, 'hours');
			return moment(createdDate).isAfter(recentDate);
		});
	}

	$scope.loadEvents = async function () {

		$scope.is_loading = true;

		window.clearInterval($scope.upcomingUploadEventsTimer);
		$scope.upcomingUploadEventsTimer = null;

		await $scope.getRecentUploads();
		$scope.upcomingUploadEventsTimer = setInterval(() => {
			if ($scope.showInProgressUploads) {
				$scope.getRecentUploads()
			}
		}, $scope.STATUS_CHECK_INTERVAL);

		eventService.loadEncoderEvents().then(
			function (response) { // success

				$scope.events_error_msg = null;
				$scope.events = response;
			},
			function () { // error

				$scope.events_error_msg = 'An error occurred while attempting to retrieve the event list. Please try again, or report the problem if it persists.';
				$scope.events = null;

			})['finally'](function(){

				$scope.is_loading = false;

			});

		// retrieve web events (if user has permission)
		if (Authentication.getCurrentUser().hasPerm('web_events.get')) {

			$scope.is_loading_web_events = true;
			$scope.web_events_error_msg = null;

			// check to see if we have a scheduled timeout
			if ($scope.loading_web_events_timeout_id != null) {
				// if so, then clear it (we'll check the web event status down below and set a timeout if necessary)
				window.clearTimeout($scope.loading_web_events_timeout_id);
				$scope.loading_web_events_timeout_id = null;
			}

			eventService.loadWebEvents().then(
				function(response){

					$scope.web_events = response;
					$scope.formatWebEvents();
					// remove any stopped web events from our web events stopping list
					$scope.updateWebEventsThatAreStopping($scope.web_events);

					// setup timeout so our web event list will auto refresh
					$scope.loading_web_events_timeout_id = window.setTimeout(
						$scope.loadWebEventsSilent,
						$scope.getWebEventStatusUpdateTimeInSec()
					);
				},
				function(reason){
					$scope.web_events_error_msg = 'An error occurred while attempting to retrieve the web events. Please try again, or report the problem if it persists.';
					$scope.web_events = null;
				})['finally'](function(){
					$scope.is_loading_web_events = false;
				});
		}
	};

	$scope.findMatchingWebEvent = function (event){
		if ($scope.web_events != null){
			for (var i = 0; i < $scope.web_events.length; i++) {
				var web_event = $scope.web_events[i];
				if (web_event.uuid == event.uuid) {
					return web_event;
				}
			}
		}
		return null;
	};

	$scope.findMatchingSimulcast = function (web_event, simulcast_uuid){
		if (web_event.simulcasts != null && web_event.simulcasts.length > 0){
			for (var i=0; i < web_event.simulcasts.length; i++){
				var simulcast = web_event.simulcasts[i];
				if (simulcast.uuid == simulcast_uuid){
					return simulcast;
				}
			}
		}
		return null;
	};

	$scope.updateWatchedOrViewedWebEvent = function (){

		if ($scope.web_event_to_watch != null){

			let match = $scope.findMatchingWebEvent($scope.web_event_to_watch);
			if (match != null){
				// update web event status
				$scope.web_event_to_watch.status = match.status;
				// update status of each simulcast
				if ($scope.web_event_to_watch.simulcasts != null && $scope.web_event_to_watch.simulcasts.length > 0){
					for (let i=0; i < $scope.web_event_to_watch.simulcasts.length; i++){
						let matching_simulcast = $scope.findMatchingSimulcast(match, $scope.web_event_to_watch.simulcasts[i].uuid);
						$scope.web_event_to_watch.simulcasts[i].status = matching_simulcast.status;
					}
				}
			}

		} else if ($scope.web_event_to_view != null){

			let match = $scope.findMatchingWebEvent($scope.web_event_to_view);
			if (match != null){
				// update web event status
				$scope.web_event_to_view.status = match.status;
				// update status of each simulcast
				if ($scope.web_event_to_view.simulcasts != null && $scope.web_event_to_view.simulcasts.length > 0){
					for (let i=0; i < $scope.web_event_to_view.simulcasts.length; i++){
						let matching_simulcast = $scope.findMatchingSimulcast(match, $scope.web_event_to_view.simulcasts[i].uuid);
						$scope.web_event_to_view.simulcasts[i].status = matching_simulcast.status;
					}
				}
			}
		}
	};

	$scope.loadWebEventsSilent = function () {

		// retrieve web events (if user has permission)
		if (Authentication.getCurrentUser().hasPerm('web_events.get')) {

			if (!uiService.isMenuOpen()){

					// check to see if we have a scheduled timeout
					if ($scope.loading_web_events_timeout_id != null) {
						// if so, then clear it (we'll check the web event status down below and set a timeout if necessary)
						window.clearTimeout($scope.loading_web_events_timeout_id);
						$scope.loading_web_events_timeout_id = null;
					}

					eventService.clearWebCache();
					eventService.loadWebEvents().then(
						function(response){

							$scope.web_events_error_msg = null;
							$scope.web_events = response;
							$scope.formatWebEvents();
							// remove any stopped web events from our web events stopping list
							$scope.updateWebEventsThatAreStopping($scope.web_events);
							// update our watched or viewed web event info
							$scope.updateWatchedOrViewedWebEvent();
						},
						function(reason){
							// If our "silent" load gets an error, lets not display an error message -- I think it would be confusing for the user to see
							// an error about an action that they did not initiate. So instead of displaying an error, setup another timeout in the hopes
							// that the error we got as a one-time thing, and upcoming check will work.
						})
						['finally'](function(){

							// setup timeout so our web event list will auto refresh
							$scope.loading_web_events_timeout_id = window.setTimeout($scope.loadWebEventsSilent, $scope.getWebEventStatusUpdateTimeInSec());
						});

			} else {

				// setup timeout so our web event list will auto refresh
				$scope.loading_web_events_timeout_id = window.setTimeout($scope.loadWebEventsSilent, $scope.getWebEventStatusUpdateTimeInSec());
			}
		}

	};

	$scope.handleCsvDownloadError = function (error) {
		$scope.error_msg = error;
	}

	$scope.initWebEvent = function (web_event_id, success_func){

		$scope.is_loading_web_events = true;

		// fetch data for the specified web event
		eventService.getWebEvent(web_event_id).then(
			function(response){

				if (success_func){
					success_func(response);
				}

			},
			function(reason){
				const errorMsg = 'An error occurred while attempting to retrieve the web event. Please try again, or report the problem if it persists.';
				if ($scope.isAnalyticsView) {
					$scope.statisticsError = true;
					$scope.statusError = true;
					$scope.error_msg = errorMsg;
				} else {
					$scope.web_events_error_msg = errorMsg;
					$scope.web_events = null;
				}
			})
			['finally'](function(){
				$scope.is_loading_web_events = false;
			});

	};

	//
	// initialize
	//

	// if we are coming from another page/module in control, then lets clear our cache to ensure we are working with fresh data.
	// only if we are moving between pages within the events module do we want to keep the cache.
	if(!$rootScope.isPrevPathFromSameModule(eventsModule.routes.list)){
		eventService.clearCache();
	}

	// check to see if watching web event
	if (typeof $routeParams.watchwebeventid !== 'undefined') {

		$scope.initWebEvent($routeParams.watchwebeventid, function(response){
			// if we don't use $timeout, then for some reason loading our webplayer can't find the proper DIV (like it isn't loaded yet).
			$timeout(function(){
				$scope.enterWebEventWatchMode($scope.formatWebEvent(response));
			});
		});
		// update events silently in background (which will also update the status of this event)
		$scope.loading_web_events_timeout_id = window.setTimeout($scope.loadWebEventsSilent, $scope.getWebEventStatusUpdateTimeInSec());

	} else if (typeof $routeParams.detailswebeventid !== 'undefined') {

		if ($scope.getCurrentUser().hasPerm('web_events.update')){
			$scope.initWebEvent($routeParams.detailswebeventid, function(response){
				$scope.enterWebEventViewMode($scope.formatWebEvent(response));
			});
		} else {
			uiService.notAuthorized();
		}
		// update events silently in background (which will also update the status of this event)
		$scope.loading_web_events_timeout_id = window.setTimeout($scope.loadWebEventsSilent, $scope.getWebEventStatusUpdateTimeInSec());

	} else if (typeof $routeParams.editwebeventid !== 'undefined') {

		if ($scope.getCurrentUser().hasPerm('web_events.update')){
			$scope.initWebEvent($routeParams.editwebeventid, function(response){
				$scope.enterEditWebEventMode($scope.formatWebEvent(response));
			});
		} else {
			uiService.notAuthorized();
		}

	} else if (typeof $routeParams.deletewebeventid !== 'undefined') {

		if ($scope.getCurrentUser().hasPerm('web_events.delete')){
			$scope.initWebEvent($routeParams.deletewebeventid, function(response){
				$scope.enterDeleteWebEventMode($scope.formatWebEvent(response));
			});
		} else {
			uiService.notAuthorized();
		}

	} else if (typeof $routeParams.stopwebeventid !== 'undefined') {

		if ($scope.getCurrentUser().hasPerm('events.start_transcoding')){
			$scope.initWebEvent($routeParams.stopwebeventid, function(response){
				$scope.enterStopWebEventMode($scope.formatWebEvent(response));
			});
		} else {
			uiService.notAuthorized();
		}

	} else if (typeof $routeParams.analyticswebeventid !== 'undefined') {

		if ($scope.getCurrentUser().hasPerm('web_events.update')){
			$scope.isAnalyticsView = true;
			window.scrollTo(0,0);
			$scope.initWebEvent($routeParams.analyticswebeventid, function(response){
				$scope.enterWebEventStatsMode($scope.formatWebEvent(response));
			});
		} else {
			uiService.notAuthorized();
		}

	} else {
		$scope.loadEvents();
	}

	// build our tooltips
	$timeout(function () {
		$('[data-toggle="tooltip"]').tooltip(); // needs to be done in timeout, otherwise for some reason the tooltip gets built before angular does it's magic

		// these tooltips uses a html "title/body" with special formatting
		$('.views-tooltip').tooltip({
			template: '<div class="tooltip" role="tooltip"><div class="tooltip-arrow"></div><div class="tooltip-inner tooltip-inner-wide tooltip-inner-left"></div></div>',
			placement: 'top',
			title: '<div class="tooltip-section">The total number of views of a web event from the embed code or web player. If a person refreshes or leaves the page and comes back, they will be counted for each session.</div>Social destinations and stream URLs are not included in this number.',
			html: true,
		});
		$('.unique-viewers-tooltip').tooltip({
			template: '<div class="tooltip" role="tooltip"><div class="tooltip-arrow"></div><div class="tooltip-inner tooltip-inner-wide tooltip-inner-left"></div></div>',
			placement: 'top',
			title: '<div class="tooltip-section">The total number of unique devices that have viewed a web event from the embed code or web player.</div>Social destinations and stream URLs are not included in this number.',
			html: true,
		});
		$('.start-web-event-playout-behavior-unpublished').tooltip({
			template: '<div class="tooltip" role="tooltip"><div class="tooltip-arrow"></div><div class="tooltip-inner tooltip-inner-wide tooltip-inner-left"></div></div>',
			placement: 'top',
			title: '<div class="tooltip-section"><strong>Realtime playout</strong> allows a user to start a web event that behaves like a simulated live event. All destinations will play as if live and not allow a user to fast-forward past the “live” point in time.</div>' +
				   '<div class="tooltip-section"><strong>Faster than realtime (ASAP), event active immediately</strong> allows a user to add an event to a web event profile for immediate playback. The event will process and show the full content available for scrubbable playback as fast as possible.</div>' +
				   '<strong>Faster than realtime (ASAP), use for next scheduled sim-live</strong> allows a user to add a web event to a web event profile to use for the next scheduled sim-live event without it being visible on the web event profile beforehand. Once this event is used for the next scheduled sim-live, the sim-live event will become the active event for playback.',
			html: true,
		});
		$('.embed-code-tooltip').tooltip({
			template: '<div class="tooltip" role="tooltip"><div class="tooltip-arrow"></div><div class="tooltip-inner tooltip-inner-wide tooltip-inner-left"></div></div>',
			placement: 'top',
			title: '<div class="tooltip-section">In most cases the <strong>Standard iframe</strong> will allow you to embed a video that will adjust to fill the parent container.</div><div class="tooltip-section">The <strong>Alternative iframe</strong> can be used in cases where the parent container is a fixed height and does not match the video\'s aspect ratio.</div>The <strong>Script Tag</strong> may be required for use with certain third-party products such as Planning Center Publishing.',
			html: true,
		});
	});

	// if the video player has been created, then we need to make sure it is cleaned up if the user changes to another page.
	// we need to clean up the web player if the user changes to another page. When they change back to this page, all the
	// html will be reloaded, so all the webplayer UI will need to be rebuilt.
	$scope.$on('$destroy', function () {
		console.log('Unloading web player');
		webPlayerService.unload($scope.WEB_PLAYER_WRAPPER_ID);
		$scope.web_player = null;

		// if we are watching a web event ...
		if ($scope.web_event_to_watch !== null){
			// close any simulcast webplayers we may have open
			if ($scope.web_event_to_watch.simulcasts !== null){
				for (var i=0; i < $scope.web_event_to_watch.simulcasts.length; i++){
					var simulcast = $scope.web_event_to_watch.simulcasts[i];
					if (simulcast.hasOwnProperty('ui')){
						webPlayerService.unload(simulcast.ui.element_id);
						if (simulcast.ui.timeout_id){
							window.clearInterval(simulcast.ui.timeout_id);
							simulcast.ui.timeout_id = null;
						}
					}
				}
			}
		}

		// clear any active timers we might have
		window.clearTimeout($scope.loading_web_events_timeout_id);
		$scope.loading_web_events_timeout_id = null;
		window.clearInterval($scope.update_cue_visibility_interval_id);
		$scope.update_cue_visibility_interval_id = null;
		window.clearInterval($scope.update_cues_interval_id);
		$scope.update_cues_interval_id = null;
		window.clearTimeout($scope.load_analytics_data_timeout_id);
		$scope.load_analytics_data_timeout_id = null;
		window.clearInterval($scope.upcomingUploadEventsTimer);
		$scope.upcomingUploadEventsTimer = null;
		window.clearTimeout($scope.loading_webplayer_timeout_id);
		$scope.loading_webplayer_timeout_id = null;
	});

}

module.exports = EventsController;
