import { eventAnalyticsModule } from './index';

function routeConfig($provide, $routeProvider) {
  'ngInject'
  $provide.factory('$routeProvider', () => $routeProvider);
  const defaultOptions = {
    controllerAs: '$ctrl',
    controller: eventAnalyticsModule.controllers.default,
    template: require('./eventanalytics.tmpl.html').default,
    activenav: eventAnalyticsModule.name,
    access: { loginRequired: true},
  }
  $routeProvider.when(eventAnalyticsModule.routes.eventanalytics, defaultOptions);
  $routeProvider.when(eventAnalyticsModule.routes.eventanalyticsId, defaultOptions);
}

export default routeConfig;
