import React, { FC, useEffect, useState } from 'react';
import { Cue } from '../../helpers/events/types';
import TrimWebEventModal from '../TrimWebEventModal';
import PropTypes from 'prop-types';

interface TrimWebEventButtonProps {
  cues?: Cue[];
  webPlayer: any;
  webEventId?: string;
  webEventProfileId?: string;
  customerId?: string;
  webEventName?: string;
  isWebEventLive?: boolean;
  getIsWebEventProfileInUse: () => boolean | undefined;
  webEvents: any[];
}

const TrimWebEventButton: FC<TrimWebEventButtonProps> = ({
  cues,
  webPlayer,
  webEventId,
  customerId,
  webEventProfileId,
  webEventName,
  isWebEventLive,
  getIsWebEventProfileInUse,
  webEvents,
}): JSX.Element => {
  const [isOpenTrimWebEventModal, setIsOpenTrimWebEventModal] = useState(false);
  const [isWebEventProfileInUse, setWebEventProfileInUse] = useState<boolean | undefined>();

  const handleTrimWebEventBtnClick = (): void => {
    if (webPlayer) {
      webPlayer.getVideo().pause();
    }
    setIsOpenTrimWebEventModal(true);
  };

  useEffect(() => {
    setWebEventProfileInUse(getIsWebEventProfileInUse());
  }, [webEvents]);

  return (
    <>
      <button className="btn btn-primary" onClick={(): void => handleTrimWebEventBtnClick()}>
        <i className="fa fa-cut" aria-hidden="true" style={{ marginRight: 7 }}></i>
        Trim Event
      </button>
      {isOpenTrimWebEventModal && (
        <TrimWebEventModal
          cues={cues}
          handleCloseModal={(): void => setIsOpenTrimWebEventModal(false)}
          webEventId={webEventId}
          customerId={customerId}
          webEventProfileId={webEventProfileId}
          webEventName={webEventName}
          isWebEventLive={isWebEventLive}
          isWebEventProfileInUse={isWebEventProfileInUse}
        />
      )}
    </>
  );
};

TrimWebEventButton.displayName = 'TrimWebEventButton';

TrimWebEventButton.propTypes = {
  cues: PropTypes.array,
  webPlayer: PropTypes.any,
  webEventId: PropTypes.string,
  customerId: PropTypes.string,
  webEventProfileId: PropTypes.string,
  webEventName: PropTypes.string,
};

export default TrimWebEventButton;
