const jcs = require('../../jcs');

class HelpCenterCtrl {

	constructor($timeout, $http, Authentication) {
		'ngInject';

		this.$timeout = $timeout;
		this.$http = $http;
		this.auth = Authentication;

		this.is_loading = false;
		this.show_setup = false;
		this.setup_topic_error = false;

		this.WEB_PRO_LEGACY = "20351fc9-ae81-4483-8c1a-16097c2fb619";
		this.WEB_PRO_PLUS_SOCIAL = "30551fc2-b585-5348-8c13-36097c2f5613";
		this.WEB_ENTERPRISE = "40351fc9-be81-3483-8c1a-26097c2fb616";
		this.WEB_SOCIAL_ONLY = "50351fc5-be85-5348-8c15-56097c2f5615";
		this.WEB_DEMO = "71351fc9-be82-5483-8c1a-36097c2fb618";
		this.WEB_CAMPUS_REVIEW = "825607ee-1616-4d1f-b206-541eb8400227";
		this.WEB_BASIC_EMBED = "825607ee-1616-4d1f-b206-541eb8401590";
		this.WEB_STARTER = "d7e04d47-e349-4cb6-9072-c2002e48baf2";
		this.WEB_PRO = "f8362a41-7958-42ad-9db0-a94b738a86dd";

		this.WEB_PLANS_WITH_SOCIAL = [this.WEB_PRO_PLUS_SOCIAL, this.WEB_ENTERPRISE, this.WEB_SOCIAL_ONLY, this.WEB_STARTER, this.WEB_PRO];

		this.visible_setup_topics = [];
		this.all_setup_topics = [
			{
				title: 'Add a ProPresenter encoder?',
				subtitle: 'Configure an encoder and destination groups',
				href: 'https://docs.resi.io/articles/#!resi-propresenter-stream/streaming-video-through-the-resi-software-encoder',
				is_multisite: true,
				is_web: true,
				is_social: true,
			},
			{
				title: 'Trying to stream to church online or a website?',
				subtitle: 'Share event content across multiple platforms',
				href: 'https://docs.resi.io/articles/#!common-tasks/chop-webpage',
				is_multisite: true,
				is_web: true,
				is_social: true,
			},
			{
				title: 'Streaming to social media?',
				subtitle: 'Configure Facebook and YouTube destinations',
				href: 'https://docs.resi.io/articles/#!common-tasks/stream-to-social-media',
				is_multisite: true,
				is_web: true,
				is_social: true,
			},
			{
				title: 'Need to add team members and contacts?',
				subtitle: 'Allow a team to manage your streaming',
				href: 'https://docs.resi.io/articles/#!common-tasks/add-users-and-contacts',
				is_multisite: true,
				is_web: true,
				is_social: true,
			},
			{
				title: 'How do I schedule events?',
				subtitle: 'Capture and stream automatically each week',
				href: 'https://docs.resi.io/articles/#!common-tasks/schedule-events',
				is_multisite: true,
				is_web: true,
				is_social: true,
			},
		];
	}

	$onInit () {
		// watch for event when popup is closed, and reset popup to "home" page
		$('#help-center-btn').parent().on('hide.bs.dropdown', () => {
			this.$timeout(() => {
				this.show_setup = false;
			});
		});

		if (localStorage.getItem('controlPrefs.showSetupMenu')) {
			this.loadTopics();
			this.show_setup = true;
			$('#help-center-btn').dropdown('toggle');
			localStorage.removeItem('controlPrefs.showSetupMenu');
		}
	}

	close () {
		$('#help-center-btn').dropdown('toggle');
	}

	dropdownClick (event) {
		event.stopPropagation(); // prevent event from closing our popup
	}

	backToHome (event) {
		this.show_setup = false;
		event.stopPropagation(); // prevent event from closing our popup
	}

	setupResi (event) {
		this.show_setup = true;
		event.stopPropagation(); // prevent event from closing our popup

		if (this.visible_setup_topics.length === 0){
			this.loadTopics();
		}
	}

	loadTopics () {

		this.visible_setup_topics = this.all_setup_topics;

		// NOTE: if we change back to a set of help topics that depends on what type of plans the customer has,
		// then we can use the code below.
//		this.is_loading = true;
//		this.setup_topic_error = false;
//
//		this.$http.get(`${jcs.api.url_v3}/customers/${this.auth.getCurrentUser().customerID}`, { withCredentials: true})
//		.then(response => {
//
//			this.determineVisibleTopics(response.data.multisitePlanId, response.data.webPlanId);
//
//		})
//		.catch(reason => {
//
//			this.setup_topic_error = true;
//
//		}).finally(() => {
//
//			this.is_loading = false;
//
//		});
	}

	determineVisibleTopics (multisite_plan_id, web_plan_id) {

		const can_see_multisite_topics = multisite_plan_id !== null;
		const can_see_web_topics = web_plan_id !== null && web_plan_id !== this.WEB_SOCIAL_ONLY;
		const can_see_social_topics = this.WEB_PLANS_WITH_SOCIAL.includes(web_plan_id);

		this.visible_setup_topics = [];
		for (const topic of this.all_setup_topics){
			if ( (topic.is_multisite && can_see_multisite_topics) || (topic.is_web && can_see_web_topics) || (topic.is_social && can_see_social_topics) ){
				this.visible_setup_topics.push(topic);
			}
		}
	}

	// can show the Give Feedback link. This is currently tied to the Subtitles release (controled by LaunchDarkly)
	canShowGiveFeedback() {
		if (this.auth.$rootScope.ldClient) {
			return this.auth.$rootScope.ldClient.allFlags()['automated-subtitles'];
		}
		return false;
	}
}

// example usage:
// <help-center></help-center>
//
export default class HelpCenterComponent {
	constructor() {
		this.template = require('./helpCenter.html').default;
		this.bindings = {};
		this.controller = HelpCenterCtrl;
	}
}
