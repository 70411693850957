import { api, mediaMetaDataApi } from '../jcs';
const constants = require('../constants');
const parse = require('parse-link-header');

class LibraryService {

	constructor ($rootScope, $http, $q, Authentication) {
		'ngInject';

		this.$rootScope = $rootScope;
		this.$http = $http;
		this.$q = $q;
		this.Authentication = Authentication;

		this.mediaList = [];
		this.isAllMediaLoaded = false;
	}

	getHeaders() {
		return {
			headers: {
				Authorization: `X-Bearer ${sessionStorage.getItem(constants.AUTH_TOKEN)}`
			}
		};
	}

	clearCache() {
		this.mediaList = [];
		this.isAllMediaLoaded = false;
	}

	getAllMedia () {

		const deferred = this.$q.defer();

		if (this.isAllMediaLoaded){
			deferred.resolve(this.mediaList);
		} else {
			this.$http.get(this.getAllMediaUrl(), this.getHeaders())
				.then(response => {
					this.isAllMediaLoaded = true;
					this.mediaList = response.data;
					deferred.resolve(this.mediaList);
				})
				.catch(reason => {
					deferred.reject(reason);
				});
		}

		return deferred.promise;
	}

	getPaginatedMedia (nextPageUrl) {
		const deferred = this.$q.defer();

		if (this.isAllMediaLoaded){
			deferred.resolve({
				nextPageUrl: null,
				mediaList: this.mediaList,
			});
		} else {
			let url = nextPageUrl ? nextPageUrl : this.getAllMediaUrl();
			this.$http.get(url, this.getHeaders())
			.then(response => {
				this.mediaList = this.mediaList.concat(...response.data);

				const mediaResults = {
					nextPageUrl: null,
					mediaList: null,
				};
				const linkHeader = parse(response.headers('link'));
				if (linkHeader.next){
					this.isAllMediaLoaded = false;
					mediaResults.nextPageUrl = linkHeader.next.url;
				} else {
					this.isAllMediaLoaded = true;
				}
				mediaResults.mediaList = response.data;
				deferred.resolve(mediaResults);
			})
			.catch(reason => {
				deferred.reject(reason);
			});
		}

		return deferred.promise;
	}

	getMedia (mediaId, fromCache = true) {

		const deferred = this.$q.defer();

		const media = this.mediaList.find(media => media.mediaId === mediaId);

		if (media && fromCache){
			deferred.resolve(media);
		} else {
			this.$http.get(this.getMediaUrl(mediaId), this.getHeaders())
				.then(({ data }) => {
					const filtered = this.mediaList.filter(media => media.mediaId !== mediaId);
					deferred.resolve(data);
					filtered.push(data);
					this.mediaList = filtered;
				})
				.catch(reason => {
					deferred.reject(reason);
				});
		}

		return deferred.promise;
	}

	getMediaDownloadUrl (mediaId) {
		return this.$http.get(this.getMediaDownloadApiUrl(mediaId), { withCredentials: true })
			.then(({ data }) => data);
	}

	updateMedia (mediaId, payload) {
		return this.$http.patch(this.getMediaUrl(mediaId), payload, this.getHeaders())
			.then(response => {
				const index = this.mediaList.findIndex(media => media.mediaId === mediaId);
				if (index !== -1){
					this.mediaList[index] = { ...this.mediaList[index], ...payload };
				}
			});
	}

	deleteMedia (mediaId) {
		return this.$http.delete(this.getMediaUrl(mediaId), this.getHeaders())
			.then(response => {
				this.mediaList = this.mediaList.filter(media => media.mediaId !== mediaId);
			});
	}

	getMediaUrl (mediaId) {
		return `${mediaMetaDataApi.v1}/customers/${this.Authentication.getCurrentUser().customerID}/media/${mediaId}`;
	}

	getAllMediaUrl () {
		return `${mediaMetaDataApi.v1}/customers/${this.Authentication.getCurrentUser().customerID}/media`;
	}

	getMediaDownloadApiUrl (mediaId) {
		return `${api.url_v3}/customers/${this.Authentication.getCurrentUser().customerID}/library/${mediaId}/download`
	}
}

export default LibraryService;
