const moment = require('moment');

class DatetimeCtrl {

	constructor() {
		'ngInject';

		this.DEFAULT_FORMAT_OUT = 'ddd, MMM D, YYYY h:mm:ss A';

		this.datetime_formatted = '';
	}

	getFormattedDateTime (value, format_in, format_out) {

		if (value){
			format_out = format_out || this.DEFAULT_FORMAT_OUT;
			return moment(value, format_in).format(format_out);
		}
		return '';
	}

	$onInit () {
		this.datetime_formatted = this.getFormattedDateTime(this.value, this.formatIn, this.formatOut);
	}

	$onChanges(changes) {
		this.datetime_formatted = this.getFormattedDateTime(this.value, this.formatIn, this.formatOut);
	}
}

// example usage:
// <datetime value="startDateTime" format-in="YYYY-MM-DD" format-out="ll"></datetime>
//
// format-in: is the format of the date&time you are providing
// format-out: the format in which the provided date&time should be displayed
//
// for formatting options see: http://momentjs.com/
//
export default class DatetimeComponent {
	constructor() {
		this.template = require('./datetime.html').default;
		this.bindings = {
			value: '<',
			formatIn: '@',
			formatOut: '@',
		};
		this.controller = DatetimeCtrl;
	}
}
