import 'angular-route';
import angular from 'angular';
import router from './module.unitoptions.routes';
import commonServicesModule from '../../services';

export const unitoptionsModule = {
	name: 'unitoptions',
	controllers: {
		default: 'unitOptionsCtrl',
	},
	routes: {
		list: '/unitoptions',
	},
};

export default angular
	.module(unitoptionsModule.name, ['ngRoute', commonServicesModule])
	.config(router)
	.controller(unitoptionsModule.controllers.default, require('./unitOptionsCtrl')).name;
