import styled from '@emotion/styled';
import { customProperties as vars } from '@resi-media/resi-ui';

export const ScrollChartWrapper = styled.div`
  position: relative;
`;

export const ScrollChartAreaWrapper = styled.div`
  width: 750px;
  overflow-x: auto;
  margin: 0 35px;
`;

export const FixedYAxis = styled.div`
  position: absolute;
  overflow: hidden;
  width: 775px;
  left: 12px;
`;

export const BarChartContainer = styled.div`
  position: relative;
`;

export const IndividualYValues = styled.div`
  margin: 0.62em;
  font-size: 0.75em;
`;

export const LegendsWrapper = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  list-style: none;
  padding: 20px 0 0 20px;
`;

export const scrollableBarChartBoxSpanStyle = {
  width: vars.spacingM,
  height: vars.spacingM,
  borderRadius: vars.borderRadius2,
  display: 'inline-block',
  marginHight: vars.spacingXs,
};

