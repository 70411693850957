import { API_RESPONSE_DIFFS, GROUP_VALUES, STREAM_SEGMENTED_REDUCER_TYPE } from '../../event-analytics-constants';
import { Dispatch } from 'react';
import {
  STREAM_TAB_VALUES,
  LOCATION_TAB_VALUES,
  doughnutChartDataType,
  barChartDataType,
  API_RESPONSE_TYPE,
  BracketReference,
  StateDispatcher,
} from '../../types';
import { getDoughnutChartData, getBarChartData } from '../../utils';

interface StreamSegmentedReducerActions<T> {
  payload: T;
  type: STREAM_SEGMENTED_REDUCER_TYPE;
}

interface StreamSegmentedReducerType {
  // Tab Operations
  tabValue: STREAM_TAB_VALUES;
  locationTabValue: LOCATION_TAB_VALUES;
  showLocationTab: boolean;

  // Doughnut Charts
  deviceStats: doughnutChartDataType[];

  //None Type Expand view
  countryStats: barChartDataType;
  regionStats: barChartDataType;
  cityStats: barChartDataType;

  // Bar Charts
  deviceStatsGroupBy: barChartDataType;
  countryStatsGroupBy: barChartDataType;
  regionStatsGroupBy: barChartDataType;
  cityStatsGroupBy: barChartDataType;
}

export const initialSegmentedReducer: StreamSegmentedReducerType = {
  tabValue: 1,
  locationTabValue: 2,
  showLocationTab: true,
  deviceStats: [],
  countryStats: { labels: [], extraInfo: [], datasets: {} },
  regionStats: { labels: [], extraInfo: [], datasets: {} },
  cityStats: { labels: [], extraInfo: [], datasets: {} },
  deviceStatsGroupBy: { labels: [], extraInfo: [], datasets: {} },
  countryStatsGroupBy: { labels: [], extraInfo: [], datasets: {} },
  regionStatsGroupBy: { labels: [], extraInfo: [], datasets: {} },
  cityStatsGroupBy: { labels: [], extraInfo: [], datasets: {} },
};

const dispatchers = {
  [STREAM_SEGMENTED_REDUCER_TYPE.TAB_VALUE]: (
    state: StreamSegmentedReducerType,
    action: StreamSegmentedReducerActions<STREAM_TAB_VALUES>
  ) => ({
    ...state,
    [STREAM_SEGMENTED_REDUCER_TYPE.TAB_VALUE]: action.payload,
  }),
  [STREAM_SEGMENTED_REDUCER_TYPE.LOCATION_TAB_VALUE]: (
    state: StreamSegmentedReducerType,
    action: StreamSegmentedReducerActions<LOCATION_TAB_VALUES>
  ) => ({
    ...state,
    [STREAM_SEGMENTED_REDUCER_TYPE.LOCATION_TAB_VALUE]: action.payload,
  }),
  [STREAM_SEGMENTED_REDUCER_TYPE.SHOW_LOCATION_TAB]: (
    state: StreamSegmentedReducerType,
    action: StreamSegmentedReducerActions<boolean>
  ) => ({
    ...state,
    [STREAM_SEGMENTED_REDUCER_TYPE.SHOW_LOCATION_TAB]: action.payload,
  }),

  //Doughnut Chart
  [STREAM_SEGMENTED_REDUCER_TYPE.DEVICE_STATS]: (
    state: StreamSegmentedReducerType,
    action: StreamSegmentedReducerActions<doughnutChartDataType[]>
  ) => ({
    ...state,
    [STREAM_SEGMENTED_REDUCER_TYPE.DEVICE_STATS]: action.payload,
  }),
  [STREAM_SEGMENTED_REDUCER_TYPE.COUNTRY_STATS]: (
    state: StreamSegmentedReducerType,
    action: StreamSegmentedReducerActions<barChartDataType>
  ) => ({
    ...state,
    [STREAM_SEGMENTED_REDUCER_TYPE.COUNTRY_STATS]: action.payload,
  }),
  [STREAM_SEGMENTED_REDUCER_TYPE.REGION_STATS]: (
    state: StreamSegmentedReducerType,
    action: StreamSegmentedReducerActions<barChartDataType>
  ) => ({
    ...state,
    [STREAM_SEGMENTED_REDUCER_TYPE.REGION_STATS]: action.payload,
  }),
  [STREAM_SEGMENTED_REDUCER_TYPE.CITY_STATS]: (
    state: StreamSegmentedReducerType,
    action: StreamSegmentedReducerActions<barChartDataType>
  ) => ({
    ...state,
    [STREAM_SEGMENTED_REDUCER_TYPE.CITY_STATS]: action.payload,
  }),

  //Bar Chart
  [STREAM_SEGMENTED_REDUCER_TYPE.DEVICE_STATS_GROUP_BY]: (
    state: StreamSegmentedReducerType,
    action: StreamSegmentedReducerActions<barChartDataType>
  ) => ({
    ...state,
    [STREAM_SEGMENTED_REDUCER_TYPE.DEVICE_STATS_GROUP_BY]: action.payload,
  }),
  [STREAM_SEGMENTED_REDUCER_TYPE.COUNTRY_STATS_GROUP_BY]: (
    state: StreamSegmentedReducerType,
    action: StreamSegmentedReducerActions<barChartDataType>
  ) => ({
    ...state,
    [STREAM_SEGMENTED_REDUCER_TYPE.COUNTRY_STATS_GROUP_BY]: action.payload,
  }),
  [STREAM_SEGMENTED_REDUCER_TYPE.REGION_STATS_GROUP_BY]: (
    state: StreamSegmentedReducerType,
    action: StreamSegmentedReducerActions<barChartDataType>
  ) => ({
    ...state,
    [STREAM_SEGMENTED_REDUCER_TYPE.REGION_STATS_GROUP_BY]: action.payload,
  }),
  [STREAM_SEGMENTED_REDUCER_TYPE.CITY_STATS_GROUP_BY]: (
    state: StreamSegmentedReducerType,
    action: StreamSegmentedReducerActions<barChartDataType>
  ) => ({
    ...state,
    [STREAM_SEGMENTED_REDUCER_TYPE.CITY_STATS_GROUP_BY]: action.payload,
  }),
};

export const StreamSegmentedReducer = (
  state: StreamSegmentedReducerType,
  action: StreamSegmentedReducerActions<any>
): StreamSegmentedReducerType => {
  return dispatchers[action.type](state, action);
};

interface getDispatcherParams {
  groupBy: string;
  dispatch: Dispatch<StreamSegmentedReducerActions<any>>;
  countriesList: BracketReference<string>;
  regionsList: BracketReference<BracketReference<string>>;
}

export const getDispatcher = ({
  groupBy,
  dispatch,
  countriesList,
  regionsList,
}: getDispatcherParams): {
  setDeviceType: StateDispatcher;
  setCountry: StateDispatcher;
  setRegion: StateDispatcher;
  setCity: StateDispatcher;
} => {
  let setDeviceType: StateDispatcher;
  let setCountry: StateDispatcher;
  let setRegion: StateDispatcher;
  let setCity: StateDispatcher;
  if (groupBy === GROUP_VALUES.NONE) {
    setDeviceType = (deviceType: API_RESPONSE_TYPE) => {
      dispatch({
        type: STREAM_SEGMENTED_REDUCER_TYPE.DEVICE_STATS,
        payload: getDoughnutChartData({
          responseObject: deviceType,
          countriesList: {},
          regionsList: {},
          chartName: API_RESPONSE_DIFFS.DEVICE_TYPE,
        }),
      });
    };
    setCountry = (country: API_RESPONSE_TYPE) => {
      dispatch({
        type: STREAM_SEGMENTED_REDUCER_TYPE.COUNTRY_STATS,
        payload: getBarChartData({
          responseObject: country,
          countriesList,
          regionsList,
          chartName: API_RESPONSE_DIFFS.LOCATION.COUNTRY,
        }),
      });
    };
    setRegion = (region: API_RESPONSE_TYPE) => {
      if (region) {
        dispatch({
          type: STREAM_SEGMENTED_REDUCER_TYPE.REGION_STATS,
          payload: getBarChartData({
            responseObject: region,
            countriesList,
            regionsList,
            chartName: API_RESPONSE_DIFFS.LOCATION.REGION,
          }),
        });
      }
    };
    setCity = (city: API_RESPONSE_TYPE) => {
      dispatch({
        type: STREAM_SEGMENTED_REDUCER_TYPE.CITY_STATS,
        payload: getBarChartData({
          responseObject: city,
          countriesList,
          regionsList,
          chartName: API_RESPONSE_DIFFS.LOCATION.CITY,
        }),
      });
    };
  }
  // Event or device type chart data combinations
  else {
    setCountry = (country: API_RESPONSE_TYPE) => {
      dispatch({
        type: STREAM_SEGMENTED_REDUCER_TYPE.COUNTRY_STATS_GROUP_BY,
        payload: getBarChartData({
          responseObject: country,
          countriesList,
          regionsList,
          chartName: API_RESPONSE_DIFFS.LOCATION.COUNTRY,
        }),
      });
    };
    setRegion = (region: API_RESPONSE_TYPE) => {
      dispatch({
        type: STREAM_SEGMENTED_REDUCER_TYPE.REGION_STATS_GROUP_BY,
        payload: getBarChartData({
          responseObject: region,
          countriesList,
          regionsList,
          chartName: API_RESPONSE_DIFFS.LOCATION.REGION,
        }),
      });
    };
    setCity = (city: API_RESPONSE_TYPE) => {
      dispatch({
        type: STREAM_SEGMENTED_REDUCER_TYPE.CITY_STATS_GROUP_BY,
        payload: getBarChartData({
          responseObject: city,
          countriesList,
          regionsList,
          chartName: API_RESPONSE_DIFFS.LOCATION.CITY,
        }),
      });
    };
    // For device type (segmentBy) - because it is different in event and device type (groupBy)
    if (groupBy === GROUP_VALUES.EVENT_TYPE) {
      setDeviceType = (deviceType: API_RESPONSE_TYPE) => {
        dispatch({
          type: STREAM_SEGMENTED_REDUCER_TYPE.DEVICE_STATS_GROUP_BY,
          payload: getBarChartData({
            responseObject: deviceType,
            countriesList: {},
            regionsList: {},
            chartName: API_RESPONSE_DIFFS.DEVICE_TYPE,
          }),
        });
      };
    } else {
      setDeviceType = (deviceType: API_RESPONSE_TYPE) => {
        dispatch({
          type: STREAM_SEGMENTED_REDUCER_TYPE.DEVICE_STATS,
          payload: getDoughnutChartData({
            responseObject: deviceType,
            countriesList: {},
            regionsList: {},
            chartName: API_RESPONSE_DIFFS.DEVICE_TYPE,
          }),
        });
      };
    }
  }
  return {
    setDeviceType,
    setCountry,
    setRegion,
    setCity,
  };
};
