'use strict';

// https://www.gstatic.com/cv/js/sender/v1/cast_sender.js (we need this for chromecast to work)
(function() {var e=function(a){return!!document.currentScript&&(-1!=document.currentScript.src.indexOf("?"+a)||-1!=document.currentScript.src.indexOf("&"+a))},f=e("loadGamesSDK")?"/cast_game_sender.js":"/cast_sender.js",g=e("loadCastFramework")||e("loadCastApplicationFramework"),h=function(){return"function"==typeof window.__onGCastApiAvailable?window.__onGCastApiAvailable:null},k=["pkedcjkdefgpdelpbcmbmeomcjbeemfm","enhhojjnijigcajfphajepfemndkmdlo"],m=function(a){a.length?l(a.shift(),function(){m(a)}):n()},
p=function(a){return"chrome-extension://"+a+f},l=function(a,c,b){var d=document.createElement("script");d.onerror=c;b&&(d.onload=b);d.src=a;(document.head||document.documentElement).appendChild(d)},n=function(){var a=h();a&&a(!1,"No cast extension found")},q=function(){if(g){var a=2,c=h(),b=function(){a--;0==a&&c&&c(!0)};window.__onGCastApiAvailable=b;l("//www.gstatic.com/cast/sdk/libs/sender/1.0/cast_framework.js",n,b)}};if(0<=window.navigator.userAgent.indexOf("Android")&&0<=window.navigator.userAgent.indexOf("Chrome/")&&window.navigator.presentation){q();var r=window.navigator.userAgent.match(/Chrome\/([0-9]+)/);m(["//www.gstatic.com/eureka/clank/"+(r?parseInt(r[1],10):0)+f,"//www.gstatic.com/eureka/clank"+f])}else!window.chrome||!window.navigator.presentation||0<=window.navigator.userAgent.indexOf("Edge")?n():(q(),m(k.map(p)));})();

const jcs = require('../../js/jcs');

// see: https://tylermcginnis.com/angularjs-factory-vs-service-vs-provider/
// see: https://stackoverflow.com/questions/14324451/angular-service-vs-angular-factory

// web player service singleton (webPlayerService object will only be created once by this factory)

function WebPlayerService($rootScope, $timeout, Authentication) {
	'ngInject';
	var shaka;
	var MANIFEST_API_URL = 'https://webevents.resi.io/api/v1/eventprofiles/latest/';

	var La1WebPlayer = function (unique_id) {
		var REPORT_ANALYTICS = false; // we may turn this on later

		/***************************************************/
		/********** Start of webplayer.js (v1.29) **********/

		var STAT_UPDATE_FREQ_DEFAULT = 60000; // report in once per minute
		var STAT_UPDATE_FREQ_MIN = 30000; //
		var EMBED_CODE_ATTR_NAME = 'data-embed-id'; // <div id="la1-video-player" data-embed-id="embed code goes here"></div>
		var EMBED_CODE_TYPE_ATTR_NAME = 'data-type'; // <div id="la1-video-player" data-embed-id="embed code goes here" data-type="event"></div>
		var CC_APP_ID = '7AF33776'; // LA1 Chromecast Receiver App Id

		var BITRATE_AUTO = 'auto';
		var ID_PREFIX = unique_id;
		var VIDEO_CONTAINER_ID = ID_PREFIX + '-videoContainer';
		var VIDEO_ID = ID_PREFIX + '-video';

		// element IDs must use prefix to ensure they are unique on the page (in case there are multiple webplayers)
		var GIANT_PLAY_BTN_CONTAINER_ID = ID_PREFIX + '-giantPlayButtonContainer';
		var GIANT_PLAY_BTN_ID = ID_PREFIX + '-giantPlayButton';
		var AUTO_PLAY_MUTED_MSG_ID = ID_PREFIX + '-autoplay-muted-msg-container';
		var BUFFERING_SPINNER_ID = ID_PREFIX + '-bufferingSpinner';
		var CAST_RECEIVER_NAME_ID = ID_PREFIX + '-castReceiverName';
		var ERROR_MSG_ID = ID_PREFIX + '-errorMessage';
		var CONTROLS_CONTAINER_ID = ID_PREFIX + '-controlsContainer';
		var CONTROLS_ID = ID_PREFIX + '-controls';
		var PLAY_PAUSE_BTN_ID = ID_PREFIX + '-playPauseButton';
		var PLAY_BTN_ICON_ID = ID_PREFIX + '-playBtnIcon';
		var PAUSE_BTN_ICON_ID = ID_PREFIX + '-pauseBtnIcon';
		var SEEK_BAR_ID = ID_PREFIX + '-seekBar';
		var CURRENT_TIME_ID = ID_PREFIX + '-currentTime';
		var MUTE_BTN_ID = ID_PREFIX + '-muteButton';
		var UNMUTE_BTN_ICON_ID = ID_PREFIX + '-unmuteBtnIcon';
		var MUTE_BTN_ICON_ID = ID_PREFIX + '-muteBtnIcon';
		var VOLUME_BAR_ID = ID_PREFIX + '-volumeBar';
		var BITRATE_BTN_ID = ID_PREFIX + '-bitrateButton';
		var BITRATE_MENU_CONTENT_ID = ID_PREFIX + '-bitrate-menu-content';
		var CAST_BTN_ID = ID_PREFIX + '-castButton';
		var CAST_BTN_ICON_ID = ID_PREFIX + '-castBtnIcon';
		var STOP_CASTING_BTN_ICON_ID = ID_PREFIX + '-stopCastingBtnIcon';
		var FULLSCREEN_BTN_ID = ID_PREFIX + '-fullscreenButton';
		var FULLSCREEN_ICON_ID = ID_PREFIX + '-fullscreenIcon';
		var EXIT_FULLSCREEN_ICON_ID = ID_PREFIX + '-fullscreenExitIcon';
		var STATUS_LABEL_ID = ID_PREFIX + "-statusLabel";
		var SUBTITLES_BUTTON_ID = ID_PREFIX + "-subtitlesButton"

		// Adaptive Bit Rate restrictions (dependent on whether fullscreen or not)
		var ABR_FULLSCREEN = {
			abr: {
				restrictions: {
					maxHeight: Infinity,
					minHeight: 0,
				},
			},
		};
		var ABR_NOT_FULLSCREEN = {
			abr: {
				restrictions: {
					maxHeight: Infinity, // this will usually get reset by api response
					minHeight: 0,
				},
			},
		};

		var options = null;

		var apiResponse = null;
		var statsUrl = null;
		var statsUpdateFreq = STAT_UPDATE_FREQ_DEFAULT; // default to 60 seconds, but will be set by api

		var video = null;
		var videoPlayer = null;
		var castProxy = null;
		var isCastAllowed = false;

		var videoEl = null;
		var videoContainerEl = null;
		var controlsContainerEl = null;
		var controlsEl = null;
		var bufferingSpinnerEl = null;
		var castReceiverNameEl = null;
		var errorMessageEl = null;
		var autoplayMuteWarnEl = null;
		var playPauseBtnEl = null;
		var playBtnIconEl = null;
		var pauseBtnIconEl = null;
		var muteUnmuteBtnEl = null;
		var muteBtnIconEl = null;
		var unmuteBtnIconEl = null;
		var currentTimeEl = null;
		var seekBarEl = null;
		var volumeBarEl = null;
		var fullscreenIconEl = null;
		var fullscreenExitIconEl = null;
		var castBtnEl = null;
		var giantPlayButtonContainerEl = null;
		var giantPlayButtonEl = null;
		var bitrateMenuEl = null;
		var bitrateBtnEl = null;
		var fullScreenBtnEl = null;
		var statusLabelEl = null;
		var subtitlesButtonEl = null;

		var bitrateValues = []; // currently we use video height
		var selectedBitrate = BITRATE_AUTO;

		var hlsPresentationDelay = 60; // defaults to 60 seconds

		var lastTouchEventTime = null;
		var mouseStillTimeoutId = null;

		var isSeeking = false;
		var wasPlayingBeforeSeek = false;

		var playerStateIntervalId = null;
		var previousPosition = ''; // used for displaying/sending analytics data

		var onStreamStartedCallback = null;
		var onStreamEndedCallbackInfo = null;

		var consoleEl = null; // used for iOS testing

		function init() {
			// see if parent document has a textarea to output console info
			// see notes on 9/5/18 as to why this is commented out
			//	if (parent && parent.document){
			//		consoleEl = parent.document.getElementById('console-output');
			//		if (consoleEl){
			//			consoleEl.value = "initialization successful.";
			//		}
			//	}
		}

		function log(message) {
			console.log(message);
			if (consoleEl != null && message != null) {
				// see: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/typeof
				var formatted = typeof message === 'object' ? JSON.stringify(message) : message;
				consoleEl.value += '\n' + formatted;
			}
		}

		// the meaning of error category and codes can be found at: https://shaka-player-demo.appspot.com/docs/api/shaka.util.Error.html
		function onLoadError(error) {
			if (error.code == 1001 || error.code == 1002 || error.code == 1003) {
				showErrorMessage(
					'A problem with the video was encountered.<br /><br />Please try refreshing your browser window.'
				);
			} else {
				showErrorMessage(
					'A problem with the video was encountered. (Category = ' + error.category + ' Code = ' + error.code + ')'
				);
			}
			var message =
				'Shaka.onLoadError Category ' + error.category + ' Code ' + error.code + ' Severity ' + error.severity;
			message += buildAdditionalShakaErrorMsg(error);
			sendErrorReport(message);
		}

		function showErrorMessage(message) {
			// hide video player & controls
			bufferingSpinnerEl.style.display = 'none';
			videoEl.style.display = 'none';
			controlsContainerEl.style.display = 'none';
			giantPlayButtonContainerEl.style.display = 'none';
			giantPlayButtonEl.style.display = 'none';
			statusLabelEl.style.display = 'none';
			// show error message area
			errorMessageEl.style.display = 'block';
			errorMessageEl.innerHTML = message;
		}

		function onStreamEnded (event){
			// fire callback if one has been provided
			if (onStreamEndedCallbackInfo != null){
				onStreamEndedCallbackInfo.callback(event, onStreamEndedCallbackInfo.returnData);
			}
		}

		function onStreamStarted (event){
			if (onStreamStartedCallback != null){
				onStreamStartedCallback(event);
			}
		}

		function onBufferingStateChange(event) {
			// show our video buffering stuff
			bufferingSpinnerEl.style.display = event.buffering ? 'inherit' : 'none';
		}

		function setCastAppId(app_id) {
			CC_APP_ID = app_id;
			isCastAllowed = true;
			log('Enabling Chromecast');
		}

		// allows the application to inhibit casting
		function allowCast(allow) {
			isCastAllowed = allow;
			onCastStatusChange(null);
			if (allow) {
				log('CC_APP_ID = ' + CC_APP_ID);
			}
		}
		window.la1AllowCast = allowCast; // this will allow us to turn on/off from the console

		// controls state of cast button based on the current status
		var previousIsCasting = null;
		function onCastStatusChange(event) {
			var canCast = castProxy.canCast() && isCastAllowed;
			var isCasting = castProxy.isCasting();

			if (canCast) {
				castBtnEl.style.display = 'inherit';
				// TODO: if they refresh page while casting, then isCasting=true, but canCast=false ... so we need to rethink this.
				if (isCasting) {
					castReceiverNameEl.style.display = 'inherit';
					castReceiverNameEl.textContent = 'Casting to ' + castProxy.receiverName();
					controlsEl.classList.add('la1-casting');
				} else {
					// not casting
					castReceiverNameEl.style.display = 'none';
					castReceiverNameEl.textContent = '';
					controlsEl.classList.remove('la1-casting');
				}
				// update volume settings; when turning off casting chromecast will set the volume back to it's original settings before casting started.
				// settings appear to be accurate when this method is called, whereas they aren't when turing casting on
				onVolumeStateChange();
			} else {
				castBtnEl.style.display = 'none';
				castReceiverNameEl.style.display = 'none';
				castReceiverNameEl.textContent = '';
			}

			// if casting status changes, then we'll need to reconfigure ABR (since casting is considered same as fullscreen)
			if ((previousIsCasting == null && isCasting) || (previousIsCasting != null && previousIsCasting != isCasting)) {
				// for some reason, calling configure immediately doesn't work (shaka doesn't get the message), but giving it a moment seems to work
				window.setTimeout(configureAutoABR, 1000);
			}
			previousIsCasting = isCasting;

			//	castBtnEl.style.display = canCast ? 'inherit' : 'none';
			//	castBtnEl.textContent = isCasting ? 'cast_connected' : 'cast';
			//	castReceiverName.style.display = isCasting ? 'inherit' : 'none';
			//	castReceiverName.textContent = isCasting ? 'Casting to ' + castProxy.receiverName() : '';
			//	if (castProxy.isCasting()) {
			//		controls.classList.add('casting');
			//	} else {
			//		controls.classList.remove('casting');
			//	}
		}

		function onCastBtnClick(event) {
			if (castProxy.isCasting()) {
				castProxy.suggestDisconnect();
			} else {
				castBtnEl.disabled = true;
				castProxy.cast().then(
					function () {
						// success
						castBtnEl.disabled = false;
						// ensure our UI reflects the fact that chromecast will play with audio on
						unmuteUI();
					}.bind(this),
					function (error) {
						castBtnEl.disabled = false;
						if (error.code != shaka.util.Error.Code.CAST_CANCELED_BY_USER) {
							log('Cast Error:');
							log(error);
							//				this.onError_(error);
						}
					}.bind(this)
				);
			}
		}

		function onContainerTouch(event) {
			if (!video || !video.duration) {
				// video isn't loaded yet, so ignore
				return;
			}

			if (controlsEl.style.opacity == 1) {
				lastTouchEventTime = Date.now();
				// The controls are showing.
			} else {
				// The controls are hidden, so show them.
				onMouseMove(event);
			}
		}

		function videoPlayerPlayPause(event) {
			if (!video || !video.duration) {
				// video isn't loaded yet, so ignore
				return;
			}

			if (video.paused) {
				video.play();
			} else {
				video.pause();
			}
		}

		function onPlayStateChange() {
			if (video.paused && !isSeeking) {
				pauseBtnIconEl.style.display = 'none';
				playBtnIconEl.style.display = 'block';
				giantPlayButtonEl.style.display = 'block';
			} else {
				pauseBtnIconEl.style.display = 'block';
				playBtnIconEl.style.display = 'none';
				giantPlayButtonEl.style.display = 'none';
			}

			// call onMouseMove so our controls will appear/hide appropriately when the user clicks the mouse. Otherwise they can click the mouse
			// without moving it to pause, and the controls won't appear properly.
			onMouseMove({ type: 'mousemove' });
		}

		function onSeekStart() {
			wasPlayingBeforeSeek = !video.paused;
			isSeeking = true;
			video.pause();
		}

		function onSeekInput() {
			if (!video.duration) {
				// can't seek yet
				return;
			}
			// update the UI right away
			updateTimeAndSeekBar();
		}

		function onSeekEnd() {
			// TODO: if we want to control how close to live the user can seek
			//	var seekRange = videoPlayer.seekRange();
			//	var seekDestination = parseFloat(seekBarEl.value);
			//	var maxSeekAllowed = seekRange.end - 30.0;
			//	if (seekDestination > maxSeekAllowed) {
			//		log("Forcing seek to be 30 seconds behind live/end");
			//		seekDestination = maxSeekAllowed;
			//	}

			//	video.currentTime = seekDestination;
			video.currentTime = parseFloat(seekBarEl.value);
			isSeeking = false;

			if (wasPlayingBeforeSeek) {
				video.play();
			}
		}

		function buildTimeString(displayTime, showHour) {
			var h = Math.floor(displayTime / 3600);
			var m = Math.floor((displayTime / 60) % 60);
			var s = Math.floor(displayTime % 60);
			if (s < 10) s = '0' + s;
			var text = m + ':' + s;
			if (showHour) {
				if (m < 10) text = '0' + text;
				text = h + ':' + text;
			}
			return text;
		}

		function updateTime() {
			var displayTime = isSeeking ? seekBarEl.value : video.currentTime;

			if (videoPlayer.isLive()) {
				var seekRange = videoPlayer.seekRange();
				var seekRangeSize = seekRange.end - seekRange.start;
				var showHour = seekRangeSize >= 3600;

				// The amount of time we are behind the live edge.
				var behindLive = Math.floor(seekRange.end - displayTime);
				displayTime = Math.max(0, behindLive);

				// Consider "LIVE" when less than 1 second behind the live-edge.  Always
				// show the full time string when seeking, including the leading '-';
				// otherwise, the time string "flickers" near the live-edge.
				if (displayTime >= 1 || isSeeking) {
					currentTimeEl.textContent = '- ' + buildTimeString(displayTime, showHour);
					//			currentTimeEl.style.cursor = 'pointer';
				} else {
					currentTimeEl.textContent = 'LIVE';
					//			currentTimeEl.style.cursor = '';
				}
			} else {
				var showHour = video.duration >= 3600;
				currentTimeEl.textContent = buildTimeString(displayTime, showHour);
				//		currentTimeEl.style.cursor = '';
			}
		}

		function updateSeekBar() {
			var seekRange = videoPlayer.seekRange();
			seekBarEl.min = seekRange.start;
			seekBarEl.max = seekRange.end;

			if (!isSeeking) {
				seekBarEl.value = video.currentTime;
			}

			var seekRangeSize = seekRange.end - seekRange.start;
			// NOTE: the fallback to zero eliminates NaN.
			var playheadFraction = (video.currentTime - seekRange.start) / seekRangeSize || 0;
			var currentPosFraction = (seekBarEl.value - seekRange.start) / seekRangeSize || 0;

			// we always first draw light gray up to our current position (the seek bar handle). Then after that
			// we may draw our playhead position (in a darker gray). And then from then to the end is black. Because
			// we always draw in that order, we need to ensure the playheadFraction is always >= currentPosFraction.
			playheadFraction = Math.max(playheadFraction, currentPosFraction);

			// https://css-tricks.com/css3-gradients/
			var gradient = ['to right'];
			// 1) draw light gray up to the seek bar handle
			gradient.push('#ccc');
			gradient.push('#ccc ' + currentPosFraction * 100 + '%');
			// 2) draw darker gray up to the playhead position (which sometimes may be farther along than the handle)
			gradient.push('#444 ' + currentPosFraction * 100 + '%');
			gradient.push('#444 ' + playheadFraction * 100 + '%');
			// 3) draw black to the end of the bar
			gradient.push('#000 ' + playheadFraction * 100 + '%');

			seekBarEl.style.background = 'linear-gradient(' + gradient.join(',') + ')';
		}

		function updateTimeAndSeekBar() {
			updateTime();
			updateSeekBar();
		}

		function onVolumeStateChange() {
			if (video.muted) {
				volumeBarEl.value = 0;
				muteBtnIconEl.style.display = 'none';
				unmuteBtnIconEl.style.display = 'block';
			} else {
				volumeBarEl.value = video.volume;
				muteBtnIconEl.style.display = 'block';
				unmuteBtnIconEl.style.display = 'none';
				// any interaction with either unmute button or the volume slider should result in this being called; in that case we want
				// to ensure that we hide the autoplay warning if it is visible.
				hideAutoplayMuteWarning();
			}

			var gradient = ['to right'];
			gradient.push('#ccc ' + volumeBarEl.value * 100 + '%');
			gradient.push('#000 ' + volumeBarEl.value * 100 + '%');
			gradient.push('#000 100%');
			volumeBarEl.style.background = 'linear-gradient(' + gradient.join(',') + ')';
		}

		// this is used when we start casting with chromecast -- which always plays video and has audio on
		function unmuteUI() {
			hideAutoplayMuteWarning();
			volumeBarEl.value = video.volume;
			muteBtnIconEl.style.display = 'block';
			unmuteBtnIconEl.style.display = 'none';

			var gradient = ['to right'];
			gradient.push('#ccc ' + volumeBarEl.value * 100 + '%');
			gradient.push('#000 ' + volumeBarEl.value * 100 + '%');
			gradient.push('#000 100%');
			volumeBarEl.style.background = 'linear-gradient(' + gradient.join(',') + ')';
		}

		function onMuteToggle(event) {
			if (!video || !video.duration) {
				// video isn't loaded yet, so ignore
				return;
			}
			video.muted = !video.muted;
		}

		function toggleSubtitles() {
			const ccOn = !videoPlayer?.isTextTrackVisible();
			videoPlayer?.setTextTrackVisibility(ccOn);

			if (ccOn) {
				document.getElementById("subtitlesOff").style.display = "none"
				document.getElementById("subtitlesOn").style.display = "block"
			} else {
				document.getElementById("subtitlesOff").style.display = "block"
				document.getElementById("subtitlesOn").style.display = "none"
			}
		}

		function onUnmuteAutoplay(event) {
			// normally changing the muted state would cause onVolumeStateChange to get called which calls hideAutoplayMuteWarning. But we need to guarantee that
			// the autoplay warning is hidden, and I've seen some cases (involving chromecast) where video.muted was already false when this method was called, which
			// means setting it to false won't "change" it, so onVolumeStateChange doesn't get called. So just to be safe we'll call the hide method here too.
			hideAutoplayMuteWarning();
			video.muted = false;
		}

		function showAutoplayMuteWarning() {
			if (autoplayMuteWarnEl) {
				autoplayMuteWarnEl.style.display = 'block';
			}
		}

		function hideAutoplayMuteWarning() {
			if (autoplayMuteWarnEl) {
				autoplayMuteWarnEl.style.display = 'none';
			}
		}

		function onVolumeInput() {
			video.volume = parseFloat(volumeBarEl.value);
			video.muted = false;
		}

		function isFullscreen() {
			// if they are using Chromecast, then consider as fullscreen
			if (castProxy != null && castProxy.isCasting()) return true;

			// see: https://developer.mozilla.org/en-US/docs/Web/API/Fullscreen_API
			var element =
				document.fullscreenElement ||
				document.webkitFullscreenElement ||
				document.mozFullScreenElement ||
				document.msFullscreenElement;
			return element && (element.id == VIDEO_CONTAINER_ID || element.id == VIDEO_ID) ? true : false;
		}

		function onFullscreenChange() {
			if (isFullscreen()) {
				fullscreenIconEl.style.display = 'none';
				fullscreenExitIconEl.style.display = 'block';
			} else {
				fullscreenIconEl.style.display = 'block';
				fullscreenExitIconEl.style.display = 'none';
			}
			configureAutoABR();
		}

		function onFullscreenClick(event) {
			if (isFullscreen()) {
				document.exitFullscreen();
			} else {
				videoContainerEl.requestFullscreen();
			}
		}

		/**
		 * Hiding the cursor when the mouse stops moving seems to be the only decent UX
		 * in fullscreen mode.  Since we can't use pure CSS for that, we use events both
		 * in and out of fullscreen mode.
		 */
		function onMouseMove(event) {
			if (event.type == 'touchstart' || event.type == 'touchmove' || event.type == 'touchend') {
				lastTouchEventTime = Date.now();
			} else if (lastTouchEventTime + 1000 < Date.now()) {
				// It has been a while since the last touch event, this is probably a real
				// mouse moving, so treat it like a mouse.
				lastTouchEventTime = null;
			}

			// When there is a touch, we can get a 'mousemove' event after touch events.
			// This should be treated as part of the touch, which has already been handled
			if (lastTouchEventTime && event.type == 'mousemove') return;

			// Use the cursor specified in the CSS file.
			videoContainerEl.style.cursor = '';
			giantPlayButtonContainerEl.style.cursor = '';
			giantPlayButtonEl.style.cursor = '';
			// Show the controls.
			controlsEl.style.opacity = 1;
			updateTimeAndSeekBar();

			// Hide the cursor when the mouse stops moving.
			// Only applies while the cursor is over the video container.
			if (mouseStillTimeoutId) {
				// Reset the timer.
				window.clearTimeout(mouseStillTimeoutId);
				mouseStillTimeoutId = null;
			}

			// Only start a timeout on 'touchend' or for 'mousemove' with no touch events.
			if (event.type == 'touchend' || !lastTouchEventTime) {
				mouseStillTimeoutId = window.setTimeout(onMouseStill.bind(this), 2000);
			}
		}

		function onMouseOut(event) {
			// determine if the mouse is leaving the video player; this method will be called when the
			// mouse moves from the giantPlayButtonContainer to the giantPlayButton and it's child elements
			// (we want to ignore those events)
			if (!giantPlayButtonContainerEl.contains(event.toElement)) {
				// We sometimes get 'mouseout' events with touches. Since we can never leave
				// the video element when touching, ignore.
				if (lastTouchEventTime) return;

				// Expire the timer early.
				if (mouseStillTimeoutId) {
					window.clearTimeout(mouseStillTimeoutId);
					mouseStillTimeoutId = null;
				}
				// Run the timeout callback to hide the controls.
				// If we don't, the opacity style we set in onMouseMove_ will continue to
				// override the opacity in CSS and force the controls to stay visible.
				onMouseStill();
			}
		}

		function onMouseStill() {
			// The mouse has stopped moving.
			mouseStillTimeoutId = null;
			// unless we are paused or the controls are below the video (and should always be shown), hide the cursor and controls
			if (!video.paused && !options.showControlsBelow) {
				// <= new
				// Hide the cursor.  (NOTE: not supported on IE)
				videoContainerEl.style.cursor = 'none';
				giantPlayButtonContainerEl.style.cursor = 'none';
				giantPlayButtonEl.style.cursor = 'none';
				// Revert opacity control to CSS.  Hovering directly over the controls will
				// keep them showing, even in fullscreen mode. Unless there were touch events,
				// then override the hover and hide the controls.
				controlsEl.style.opacity = lastTouchEventTime ? '0' : '';
			}
		}

		function onShowBitrateOptionsClick() {
			if (bitrateMenuEl.style.display == 'none') {
				bitrateMenuEl.style.display = 'block';
			} else {
				bitrateMenuEl.style.display = 'none';
			}
		}

		function configureAutoABR() {
			if (videoPlayer != null && selectedBitrate == BITRATE_AUTO) {
				var updatedConfig = isFullscreen() ? ABR_FULLSCREEN : ABR_NOT_FULLSCREEN;
				videoPlayer.configure(updatedConfig);
			}
		}

		function selectBitrate(bitrate) {

			// ignore if given bitrate isn't different from current setting
			if (selectedBitrate == bitrate) {
				bitrateMenuEl.style.display = 'none';
				return;
			}

			selectedBitrate = bitrate;

			// update the video player's ABR restriction config
			if (bitrate == BITRATE_AUTO) {
				log('ABR on auto');
				configureAutoABR();
			} else {
				log('ABR restricted to ' + bitrate + 'p');
				// need to use parseInt, otherwise "+" will just perform a string concat
				var newMaxHeight = parseInt(bitrate) + parseInt(1);
				var newMinHeight = bitrate - 1;

				var updatedConfig = {
					abr: {
						restrictions: {
							maxHeight: newMaxHeight,
							minHeight: newMinHeight,
						},
					},
				};

				videoPlayer.configure(updatedConfig);
			}

			// hide the bitrate popup menu
			bitrateMenuEl.style.display = 'none';
			// rebuild our menu (now that new option is selected)
			buildBitrateMenu();
		}
		// we need to assign this to window so some of our dynamically generated html can access it
		// TODO: this is a bit of a hack, need to find a better way to do it. Be nice if the selectBitrate method could examine the event.target or event.currentTarget
		// and check an attribute for it's value. Ex: <li data-bitrate="360">360p</li>.
		var globalSelectBitrateFuncName = ID_PREFIX.replace(/-/g, '_') + 'la1SelectBitrate';
		window[globalSelectBitrateFuncName] = selectBitrate;

		function initBitrateMenu() {
			// determine available bitrate options
			initBitrateValues();
			// build bitrate popup menu
			buildBitrateMenu();
		}

		function buildBitrateMenu() {
			var html = '';
			for (var i = 0; i < bitrateValues.length; i++) {
				var height = bitrateValues[i];
				var onclick = 'onclick="' + globalSelectBitrateFuncName + '(\'' + height + '\');"';
				var checkmarkOpacity = '0.0';
				if (height == selectedBitrate) {
					checkmarkOpacity = '1.0';
				}
				var bitrateCheckmark =
					'<svg style="vertical-align:bottom;opacity:' +
					checkmarkOpacity +
					'" fill="#FFFFFF" height="18" viewBox="0 0 24 24" width="18" xmlns="http://www.w3.org/2000/svg"><path d="M0 0h24v24H0z" fill="none"/><path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z"/></svg> ';
				html += '<li ' + onclick + '>' + bitrateCheckmark + height + 'p</li>';
			}
			// include "auto" option
			var checkmarkOpacity = '0.0';
			if (selectedBitrate == BITRATE_AUTO) {
				checkmarkOpacity = '1.0';
			}
			var bitrateCheckmark =
				'<svg style="vertical-align:bottom;opacity:' +
				checkmarkOpacity +
				'" fill="#FFFFFF" height="18" viewBox="0 0 24 24" width="18" xmlns="http://www.w3.org/2000/svg"><path d="M0 0h24v24H0z" fill="none"/><path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z"/></svg> ';
			html += '<li onclick="' + globalSelectBitrateFuncName + '(\'auto\');">' + bitrateCheckmark + 'Auto</li>';
			bitrateMenuEl.innerHTML = html;
		}

		function initBitrateValues() {
			bitrateValues = [];
			var tracks = videoPlayer.getVariantTracks();
			for (var i = 0; i < tracks.length; i++) {
				var track = tracks[i];
				if (bitrateValues.indexOf(track.height) == -1) bitrateValues.push(track.height);
			}
			// ensure height options are in proper order
			bitrateValues.sort(function (a, b) {
				return b - a; // high to low
				//return a - b; // low to high
			});
		}

		function getActiveTrack() {
			var tracks = videoPlayer.getVariantTracks();
			for (var i = 0; i < tracks.length; i++) {
				var track = tracks[i];
				if (track.active) return track;
			}
			return null;
		}

		// This is for detecting if video is live on iOS
		var IS_LIVE_THRESHOLD = 4294967296; // 2^32
		var previousVideoDuration = null;
		// Returns null if state is unknown
		// https://www.w3.org/TR/html5/embedded-content-0.html#dom-media-duration
		// Says:
		// The duration attribute must return the time of the end of the media resource, in seconds, on the media timeline.
		// If no media data is available, then the attributes must return the Not-a-Number (NaN) value. If the media resource
		// is not known to be bounded (e.g. streaming radio, or a live event with no announced end time), then the attribute
		// must return the positive Infinity value.
		//
		// NOTE: WE WILL NEED TO TEST THIS THOUROUGHLY WITH LIVE HLS ... not 100% convinced it will work properly.
		function isLive(currentVideoDuration) {
			log('currentVideoDuration: ' + currentVideoDuration);
			// Right now, testing on iPad shows currentVideoDuration is Infinity when the stream is live. So using
			// the IS_LIVE_THRESHOLD wasn't of any use.
			// TODO: this is new implementation based on link above. If this works well, then we can remove
			// IS_LIVE_THRESHOLD and previousVideoDuration
			if (!isFinite(currentVideoDuration)) {
				return true;
			} else if (isNaN(currentVideoDuration)) {
				return null;
			}
			return false;

			/*	// do we have enough info to tell if live?
		  if (previousVideoDuration != null){

			  // is the video duration changing? if so then we are live
			  if (previousVideoDuration != currentVideoDuration){
				  previousVideoDuration = currentVideoDuration;
				  return true;
			  }

			  if (previousVideoDuration == currentVideoDuration){

				  // if a live event has no announced end time, then the current duration is supposed to be positive Infinity,
				  // but that isn't what I've seen testing LA1 streams. They have the duration set to 2^32. So for now we'll
				  // use that to do our checks.
				  return currentVideoDuration >= IS_LIVE_THRESHOLD;
			  }

		  } else {
			  // previousVideoDuration was not set, so don't have enough info to tell if live
			  previousVideoDuration = currentVideoDuration;
			  return null;
		  }*/
		}

		// only valid if isLive is true
		// NOTE: right now this is always coming back "null" when LA1 stream is live (b/c duration is always 2^32)
		// The Dash Forum live stream also does the same thing ... duration is 2^32
		function getDistBehindLive(position, duration) {
			// since we don't have a proper duration, we can't determine the distance behind
			//	if (duration >= IS_LIVE_THRESHOLD) // <= old method, we may be able to get rid of this
			// Testing on iPad, when live, duration comes back as Infinity, which means we can't calculate
			// a "distance behind". So just return null.
			if (!isFinite(duration)) return null;
			return duration - position;
		}

		function reportAnalyticsHls() {
			try {
				var videoCurrentTime = video.currentTime;
				// only output player state data and stats if the position has changed
				if (videoCurrentTime === previousPosition) {
					log(
						"Skipping analytics report because player position hasn't changed. videoCurrentTime=" +
						videoCurrentTime +
						' previousPosition=' +
						previousPosition
					);
					return;
				}
				previousPosition = videoCurrentTime;

				var currentPositionFormatted = buildTimeString(videoCurrentTime, true);

				var isVideoLive = isLive(video.duration);
				var distBehindLive = '';
				var behindLiveFormatted = '';
				if (isVideoLive != null && isVideoLive) {
					distBehindLive = getDistBehindLive(video.currentTime, video.duration);
					behindLiveFormatted = buildTimeString(distBehindLive, true);
				}

				var isFullScreen = isFullscreen();

				log('===== PLAYER STATE (HLS) =====');
				log('State: ' + (video.paused ? 'Paused' : 'Playing'));
				log('Bitrate Setting: unknown');
				log('Video Height: ' + video.videoHeight);
				log('Video Width: ' + video.videoWidth);
				log('Video Bandwidth: unknown');
				log('Video/Audio Bandwidth unknown:');
				log('Current Position: ' + currentPositionFormatted);
				log('Current Time: ' + video.currentTime);
				log('Is Content Live: ' + (isVideoLive != null ? (isVideoLive ? 'Yes' : 'No') : 'unknown'));
				log('Dist Behind Live: ' + distBehindLive);
				log('Dist Behind Live (formatted): ' + behindLiveFormatted);
				log('Domain: ' + document.domain);
				log('User Agent: ' + window.navigator.userAgent);
				log('Stream Duration: ' + (Number.isFinite(video.duration) ? video.duration : 'Infinity'));
				log('Is Fullscreen: ' + (isFullScreen ? 'Yes' : 'No'));

				var totalTimeWatched = 0;
				log('----- WATCHED (' + video.played.length + ') -----');
				if (video.played.length > 0) {
					for (var i = 0; i < video.played.length; i++) {
						var startTime = video.played.start(i);
						var endTime = video.played.end(i);
						totalTimeWatched += endTime - startTime;
						log('[' + startTime + ' to ' + endTime + ']');
					}
					log('Total Time Watched: ' + totalTimeWatched);
				}
				log('Buffered...');
				//	log(video.buffered);
				if (video.buffered.length > 0) {
					for (var i = 0; i < video.buffered.length; i++) {
						log('[' + video.buffered.start(i) + ' to ' + video.buffered.end(i) + ']');
					}
				}
				log('Video Object:');
				log(video);

				if (statsUrl != null) {
					var data = {
						state: video.paused ? 'Paused' : 'Playing',
						videoHeight: video.videoHeight,
						videoWidth: video.videoWidth,
						currentPosition: currentPositionFormatted,
						live: isVideoLive,
						distBehindLive: isVideoLive ? behindLiveFormatted : null,
						totalTimeWatched: totalTimeWatched,
						bitrateSetting: null,
						videoBandwidth: null,
						audioBandwidth: null,
						bufferingTime: null,
						droppedFrames: null,
						estimatedBandwidth: null,
						bitrateSwitchCount: null,
						fullScreen: isFullScreen,
					};

					sendStatsUpdate(data);
				}
			} catch (ex) {
				// send a report about the exception we got
				sendErrorReport(buildExceptionMsg(ex));
			}
		}

		// Polyfill for IE11 See: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Number/isFinite
		Number.isFinite =
			Number.isFinite ||
			function (value) {
				return typeof value === 'number' && isFinite(value);
			};

		function reportAnalyticsDash() {
			try {
				var videoCurrentTime = video.currentTime;
				// only output player state data and stats if the position has changed
				if (videoCurrentTime === previousPosition) {
					log(
						"Skipping analytics report because player position hasn't changed. videoCurrentTime=" +
						videoCurrentTime +
						' previousPosition=' +
						previousPosition
					);
					return;
				}
				previousPosition = videoCurrentTime;

				var currentPositionFormatted = buildTimeString(videoCurrentTime, true);

				var isLive = videoPlayer.isLive();
				var behindLiveFormatted = 'n/a';
				if (isLive) {
					// The amount of time we are behind the live edge.
					var seekRange = videoPlayer.seekRange();
					var behindLive = Math.floor(seekRange.end - videoCurrentTime);
					displayTime = Math.max(0, behindLive);
					behindLiveFormatted = buildTimeString(displayTime, true);
				}

				var isFullScreen = isFullscreen();

				var currentVideoHeight = null;
				var currentVideoWidth = null;
				var currentVideoBandwidth = null;
				var currentAudioBandwidth = null;
				var currentVideoAudioBandwidth = null;
				var activeTrack = getActiveTrack();
				if (activeTrack != null) {
					currentVideoHeight = activeTrack.height;
					currentVideoWidth = activeTrack.width;
					currentVideoBandwidth = activeTrack.videoBandwidth;
					currentAudioBandwidth = activeTrack.audioBandwidth;
					currentVideoAudioBandwidth = activeTrack.bandwidth;
				}

				log('===== PLAYER STATE (DASH) =====');
				log('State: ' + (video.paused ? 'Paused' : 'Playing'));
				log('Bitrate Setting: ' + selectedBitrate);
				log('Video Height: ' + currentVideoHeight);
				log('Video Width: ' + currentVideoWidth);
				log('Video Bandwidth: ' + currentVideoBandwidth);
				log('Audio Bandwidth: ' + currentAudioBandwidth);
				log('Video/Audio Bandwidth: ' + currentVideoAudioBandwidth);
				log('Current Position: ' + currentPositionFormatted);
				log('Current Time: ' + video.currentTime);
				log('Is Content Live: ' + (isLive ? 'Yes' : 'No'));
				log('Dist Behind Live: ' + behindLiveFormatted);
				log('Domain: ' + document.domain);
				log('User Agent: ' + window.navigator.userAgent);
				log('Stream Duration: ' + (Number.isFinite(video.duration) ? video.duration : 'Infinity'));
				log('Is Fullscreen: ' + (isFullScreen ? 'Yes' : 'No'));

				var totalTimeWatched = 0;
				if (video.played) {
					log('----- WATCHED (' + video.played.length + ') -----');
					if (video.played.length > 0) {
						for (var i = 0; i < video.played.length; i++) {
							var startTime = video.played.start(i);
							var endTime = video.played.end(i);
							totalTimeWatched += endTime - startTime;
							log('[' + startTime + ' to ' + endTime + ']');
						}
						log('Total Time Watched: ' + totalTimeWatched);
					}
				} else {
					totalTimeWatched = null;
					log('Video Watched Not Available');
				}

				//	log("Buffered...");
				//	if (video.buffered.length > 0){
				//		for(var i=0; i < video.buffered.length; i++){
				//			log("[" + video.buffered.start(i) + " to " + video.buffered.end(i) + "]");
				//		}
				//	}

				log('***** SHAKA STATS *****');
				log(videoPlayer.getStats());

				//	var tracks = videoPlayer.getVariantTracks();
				//	log("** TRACKS **");
				//	log(tracks);

				if (statsUrl != null) {
					var shakaStats = videoPlayer.getStats();

					var data = {
						state: video.paused ? 'Paused' : 'Playing',
						videoHeight: currentVideoHeight,
						videoWidth: currentVideoWidth,
						currentPosition: currentPositionFormatted,
						live: isLive,
						distBehindLive: isLive ? behindLiveFormatted : null,
						totalTimeWatched: totalTimeWatched,
						bitrateSetting: selectedBitrate,
						videoBandwidth: currentVideoBandwidth,
						audioBandwidth: currentAudioBandwidth,
						bufferingTime: shakaStats.bufferingTime,
						droppedFrames: shakaStats.droppedFrames,
						estimatedBandwidth: shakaStats.estimatedBandwidth,
						bitrateSwitchCount: shakaStats.switchHistory.length,
						fullScreen: isFullScreen,
					};

					sendStatsUpdate(data);
				}
			} catch (ex) {
				// send a report about the exception we got
				sendErrorReport(buildExceptionMsg(ex));
			}
		}

		function buildExceptionMsg(ex) {
			var msg_elements = [];
			if (ex != null) {
				if (ex.name) {
					msg_elements.push('NAME: ' + ex.name);
				}
				if (ex.message) {
					msg_elements.push('MESSAGE: ' + ex.message);
				}
				if (ex.stack) {
					msg_elements.push('STACK: ' + ex.stack);
				}
			}
			return msg_elements.join(' ');
		}

		function sendStatsUpdate(stats) {
			var httpRequest = new XMLHttpRequest();
			httpRequest.open('PUT', statsUrl, true); // true = use async request
			httpRequest.setRequestHeader('Content-type', 'application/json; charset=utf-8');
			httpRequest.onreadystatechange = function () {
				if (httpRequest.readyState === XMLHttpRequest.DONE) {
					if (httpRequest.status === 200) {
						log('Stats update was sent successfully');
					} else {
						// There was a problem with the request.
						// For example, the response may have a 404 (Not Found)
						// or 500 (Internal Server Error) response code.
						log('Unable to send stats update. Status: ' + httpRequest.status);
						//				log("Stats URL: " + statsUrl);
					}
				}
			};
			httpRequest.send(JSON.stringify(stats));
		}

		function sendErrorReport(error_msg) {
			if (statsUrl != null) {
				log('Reporting Error: ' + error_msg);

				var data = {
					state: 'Error - ' + error_msg,
				};

				var httpRequest = new XMLHttpRequest();
				httpRequest.open('PUT', statsUrl, true); // true = use async request
				httpRequest.setRequestHeader('Content-type', 'application/json; charset=utf-8');
				httpRequest.onreadystatechange = function () {
					if (httpRequest.readyState === XMLHttpRequest.DONE) {
						if (httpRequest.status === 200) {
							log('Error report was sent successfully');
						} else {
							// There was a problem with the request.
							// For example, the response may have a 404 (Not Found)
							// or 500 (Internal Server Error) response code.
							log('Unable to send error report. Status: ' + httpRequest.status);
							//					log("Report URL: " + statsUrl);
						}
					}
				};
				httpRequest.send(JSON.stringify(data));
			}
		}

		// returns the video player HTML and other elements that reside in that same space
		function getVideoHtml (){

			// see: https://css-tricks.com/snippets/sass/maintain-aspect-ratio-mixin/
			// so our "la1-aspect-ratio" div is our scaffolding so-to-speak. It controls the height of the videoContainer, whereas
			// everything else is absolutely positioned and in general will fill the width/height. If we need to change the
			// aspect ratio dynamically for some reason they we'd need to change "padding-top" (check out the css file)

			var html =
			'<div class="la1-aspect-ratio"></div>' +
			'<video id="' + VIDEO_ID + '" class="la1-overlay la1-video"></video>' +
			'<div id="' + STATUS_LABEL_ID + '" class="la1-overlay label la1-statusLabel"></div>' +
			'<div id="' + GIANT_PLAY_BTN_CONTAINER_ID + '" class="la1-overlay la1-giantPlayButtonContainer">' +
			'  <div id="' + GIANT_PLAY_BTN_ID + '" class="la1-giantPlayButton">' +
			'    <div class="la1-giantPlayButtonInner">' +
			'      <svg fill="#FFFFFF" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M8 5v14l11-7z"></path><path d="M0 0h24v24H0z" fill="none"></path></svg>' +
			'    </div>' +
			'  </div>' +
			'</div>' +
			'<button id="' + AUTO_PLAY_MUTED_MSG_ID + '" class="la1-overlay la1-autoplay-muted-msg-container" type="button" aria-label="Tap to Unmute">' +
			'  <div class="la1-overlay la1-autoplay-muted-msg">' +
			'    <span class="la1-muted-msg-icon"><svg fill="#FFFFFF" height="36" viewBox="0 0 24 24" width="36" xmlns="http://www.w3.org/2000/svg"><path d="M16.5 12c0-1.77-1.02-3.29-2.5-4.03v2.21l2.45 2.45c.03-.2.05-.41.05-.63zm2.5 0c0 .94-.2 1.82-.54 2.64l1.51 1.51C20.63 14.91 21 13.5 21 12c0-4.28-2.99-7.86-7-8.77v2.06c2.89.86 5 3.54 5 6.71zM4.27 3L3 4.27 7.73 9H3v6h4l5 5v-6.73l4.25 4.25c-.67.52-1.42.93-2.25 1.18v2.06c1.38-.31 2.63-.95 3.69-1.81L19.73 21 21 19.73l-9-9L4.27 3zM12 4L9.91 6.09 12 8.18V4z"/><path d="M0 0h24v24H0z" fill="none"/></svg></span>' +
			'    <span class="la1-muted-msg-text">TAP TO UNMUTE</span>' +
			'  </div>' +
			'</button>' +
			'<div id="' + BUFFERING_SPINNER_ID + '" class="la1-overlay la1-bufferingSpinner">' +
			'  <svg class="la1-spinnerSvg" viewBox="25 25 50 50">' +
			'    <circle class="la1-spinnerPath" cx="50" cy="50" r="20" fill="none" stroke-width="2" stroke-miterlimit="10" />' +
			'  </svg>' +
			'</div>' +
			'<div id="' + CAST_RECEIVER_NAME_ID + '" class="la1-castReceiverName"></div>' +
			'<div id="' + ERROR_MSG_ID + '" class="la1-errorMessage la1-overlay"></div>';
			return html;
		}

		function getPlayerControlsHtml (){
			var html =
			'<div id="' + CONTROLS_ID + '" class="la1-controls">' +
			'  <button id="' + PLAY_PAUSE_BTN_ID + '" class="la1-playPauseButton">' +
			'    <svg id="' + PLAY_BTN_ICON_ID + '" class="la1-playBtnIcon" fill="#FFFFFF" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M8 5v14l11-7z"/><path d="M0 0h24v24H0z" fill="none"/></svg>' +
			'    <svg id="' + PAUSE_BTN_ICON_ID + '" class="la1-pauseBtnIcon" fill="#FFFFFF" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M6 19h4V5H6v14zm8-14v14h4V5h-4z"/><path d="M0 0h24v24H0z" fill="none"/></svg>' +
			'  </button>' +
			'  <div class="la1-inputRangeWrapper la1-seekBarWrapper">' +
			'    <input id="' + SEEK_BAR_ID + '" class="la1-seekBar" type="range" step="any" min="0" max="1" value="0">' +
			'  </div>' +
			'  <div id="' + CURRENT_TIME_ID + '" class="la1-currentTime">0:00</div>' +
			'  <button id="' + MUTE_BTN_ID + '">' +
			'    <svg id="' + UNMUTE_BTN_ICON_ID + '" class="la1-unmuteBtnIcon" fill="#FFFFFF" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M16.5 12c0-1.77-1.02-3.29-2.5-4.03v2.21l2.45 2.45c.03-.2.05-.41.05-.63zm2.5 0c0 .94-.2 1.82-.54 2.64l1.51 1.51C20.63 14.91 21 13.5 21 12c0-4.28-2.99-7.86-7-8.77v2.06c2.89.86 5 3.54 5 6.71zM4.27 3L3 4.27 7.73 9H3v6h4l5 5v-6.73l4.25 4.25c-.67.52-1.42.93-2.25 1.18v2.06c1.38-.31 2.63-.95 3.69-1.81L19.73 21 21 19.73l-9-9L4.27 3zM12 4L9.91 6.09 12 8.18V4z"/><path d="M0 0h24v24H0z" fill="none"/></svg>' +
			'    <svg id="' + MUTE_BTN_ICON_ID + '" class="la1-muteBtnIcon" fill="#FFFFFF" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M3 9v6h4l5 5V4L7 9H3zm13.5 3c0-1.77-1.02-3.29-2.5-4.03v8.05c1.48-.73 2.5-2.25 2.5-4.02zM14 3.23v2.06c2.89.86 5 3.54 5 6.71s-2.11 5.85-5 6.71v2.06c4.01-.91 7-4.49 7-8.77s-2.99-7.86-7-8.77z"/><path d="M0 0h24v24H0z" fill="none"/></svg>' +
			'  </button>' +
			'  <div class="la1-inputRangeWrapper la1-volumeWrapper">' +
			'    <input id="' + VOLUME_BAR_ID + '" class="la1-volumeBar" type="range" step="any" min="0" max="1" value="0">' +
			'  </div>' +
			'  <div class="la1-bitrate-menu">' +
			'    <button id="' + BITRATE_BTN_ID + '" class="la1-bitrateButton">' +
			'      <svg fill="#FFFFFF" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M0 0h24v24H0z" fill="none"/><path d="M19.43 12.98c.04-.32.07-.64.07-.98s-.03-.66-.07-.98l2.11-1.65c.19-.15.24-.42.12-.64l-2-3.46c-.12-.22-.39-.3-.61-.22l-2.49 1c-.52-.4-1.08-.73-1.69-.98l-.38-2.65C14.46 2.18 14.25 2 14 2h-4c-.25 0-.46.18-.49.42l-.38 2.65c-.61.25-1.17.59-1.69.98l-2.49-1c-.23-.09-.49 0-.61.22l-2 3.46c-.13.22-.07.49.12.64l2.11 1.65c-.04.32-.07.65-.07.98s.03.66.07.98l-2.11 1.65c-.19.15-.24.42-.12.64l2 3.46c.12.22.39.3.61.22l2.49-1c.52.4 1.08.73 1.69.98l.38 2.65c.03.24.24.42.49.42h4c.25 0 .46-.18.49-.42l.38-2.65c.61-.25 1.17-.59 1.69-.98l2.49 1c.23.09.49 0 .61-.22l2-3.46c.12-.22.07-.49-.12-.64l-2.11-1.65zM12 15.5c-1.93 0-3.5-1.57-3.5-3.5s1.57-3.5 3.5-3.5 3.5 1.57 3.5 3.5-1.57 3.5-3.5 3.5z"/></svg>' +
			'    </button>' +
			'    <ul id="' + BITRATE_MENU_CONTENT_ID + '" class="la1-bitrate-menu-content"></ul>' +
			'  </div>' +
			'  <button id="' + CAST_BTN_ID + '" class="la1-castButton">' +
			'    <svg id="' + CAST_BTN_ICON_ID + '" class="la1-castBtnIcon" fill="#FFFFFF" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M0 0h24v24H0z" fill="none" opacity=".1"/><path d="M0 0h24v24H0z" fill="none"/><path d="M21 3H3c-1.1 0-2 .9-2 2v3h2V5h18v14h-7v2h7c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zM1 18v3h3c0-1.66-1.34-3-3-3zm0-4v2c2.76 0 5 2.24 5 5h2c0-3.87-3.13-7-7-7zm0-4v2c4.97 0 9 4.03 9 9h2c0-6.08-4.93-11-11-11z"/></svg>' +
			'    <svg id="' + STOP_CASTING_BTN_ICON_ID + '" class="la1-stopCastingBtnIcon" fill="#FFFFFF" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M0 0h24v24H0z" fill="none" opacity=".1"/><path d="M0 0h24v24H0z" fill="none"/><path d="M1 18v3h3c0-1.66-1.34-3-3-3zm0-4v2c2.76 0 5 2.24 5 5h2c0-3.87-3.13-7-7-7zm18-7H5v1.63c3.96 1.28 7.09 4.41 8.37 8.37H19V7zM1 10v2c4.97 0 9 4.03 9 9h2c0-6.08-4.93-11-11-11zm20-7H3c-1.1 0-2 .9-2 2v3h2V5h18v14h-7v2h7c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z"/></svg>' +
			'  </button>' +
			'	 <button style="display:none;" id="' + SUBTITLES_BUTTON_ID + '">' +
			'    <svg style="display:none;" id="subtitlesOn" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">' +
			'      <path d="M6.69768 0C2.99865 0 0 3.07026 0 6.85761V16.5726C0 20.3599 2.99865 23.4302 6.69768 23.4302H17.3023C21.0013 23.4302 24 20.3599 24 16.5726V6.85761C24 3.07026 21.0013 0 17.3023 0H6.69768Z" fill="white" />' +
			'      <path fill-rule="evenodd" clip-rule="evenodd" d="M5.02344 11.799C5.02344 8.98731 7.62999 6.942 10.2817 7.67297L10.895 7.84204C11.4903 8.00615 11.843 8.63329 11.6827 9.24281C11.5225 9.85234 10.9099 10.2134 10.3146 10.0493L9.7013 9.88025C8.46815 9.54032 7.256 10.4915 7.256 11.799C7.256 13.0219 8.38968 13.9115 9.54301 13.5936L10.3146 13.3809C10.9099 13.2168 11.5225 13.5779 11.6827 14.1874C11.843 14.7969 11.4903 15.4241 10.895 15.5882L10.1234 15.8009C7.55153 16.5098 5.02344 14.5261 5.02344 11.799Z" fill="black" />' +
			'      <path fill-rule="evenodd" clip-rule="evenodd" d="M12.2791 11.799C12.2791 8.98731 14.8856 6.942 17.5373 7.67297L18.1507 7.84204C18.746 8.00615 19.0986 8.63329 18.9383 9.24281C18.7781 9.85234 18.1656 10.2134 17.5702 10.0493L16.9569 9.88025C15.7238 9.54032 14.5116 10.4915 14.5116 11.799C14.5116 13.0219 15.6453 13.9115 16.7986 13.5936L17.5702 13.3809C18.1656 13.2168 18.7781 13.5779 18.9383 14.1874C19.0986 14.7969 18.746 15.4241 18.1507 15.5882L17.379 15.8009C14.8071 16.5098 12.2791 14.5261 12.2791 11.799Z" fill="black" />' +
			'    </svg>' +
			'    <svg id="subtitlesOff" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">' +
			'      <path fill-rule="evenodd" clip-rule="evenodd" d="M17.3023 2.28587H6.69768C4.23166 2.28587 2.23256 4.33271 2.23256 6.85761V16.5726C2.23256 19.0975 4.23166 21.1443 6.69768 21.1443H17.3023C19.7683 21.1443 21.7674 19.0975 21.7674 16.5726V6.85761C21.7674 4.33271 19.7683 2.28587 17.3023 2.28587ZM6.69768 0C2.99865 0 0 3.07026 0 6.85761V16.5726C0 20.3599 2.99865 23.4302 6.69768 23.4302H17.3023C21.0013 23.4302 24 20.3599 24 16.5726V6.85761C24 3.07026 21.0013 0 17.3023 0H6.69768Z" fill="#FFFFFF"/>' +
			'      <path fill-rule="evenodd" clip-rule="evenodd" d="M5.02344 11.799C5.02344 8.98731 7.62999 6.942 10.2817 7.67297L10.895 7.84204C11.4903 8.00615 11.843 8.63329 11.6827 9.24281C11.5225 9.85234 10.9099 10.2134 10.3146 10.0493L9.7013 9.88025C8.46815 9.54032 7.256 10.4915 7.256 11.799C7.256 13.0219 8.38968 13.9115 9.54301 13.5936L10.3146 13.3809C10.9099 13.2168 11.5225 13.5779 11.6827 14.1874C11.843 14.7969 11.4903 15.4241 10.895 15.5882L10.1234 15.8009C7.55153 16.5098 5.02344 14.5261 5.02344 11.799Z" fill="#FFFFFF"/>' +
			'      <path fill-rule="evenodd" clip-rule="evenodd" d="M12.2791 11.799C12.2791 8.98731 14.8856 6.942 17.5373 7.67297L18.1507 7.84204C18.746 8.00615 19.0986 8.63329 18.9383 9.24281C18.7781 9.85234 18.1656 10.2134 17.5702 10.0493L16.9569 9.88025C15.7238 9.54032 14.5116 10.4915 14.5116 11.799C14.5116 13.0219 15.6453 13.9115 16.7986 13.5936L17.5702 13.3809C18.1656 13.2168 18.7781 13.5779 18.9383 14.1874C19.0986 14.7969 18.746 15.4241 18.1507 15.5882L17.379 15.8009C14.8071 16.5098 12.2791 14.5261 12.2791 11.799Z" fill="#FFFFFF"/>' +
			'    </svg>' +
			'  </button>' +
			'  <button id="' + FULLSCREEN_BTN_ID + '" class="la1-fullscreenButton">' +
			'    <svg id="' + FULLSCREEN_ICON_ID + '" fill="#FFFFFF" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M0 0h24v24H0z" fill="none"/><path d="M7 14H5v5h5v-2H7v-3zm-2-4h2V7h3V5H5v5zm12 7h-3v2h5v-5h-2v3zM14 5v2h3v3h2V5h-5z"/></svg>' +
			'    <svg id="' + EXIT_FULLSCREEN_ICON_ID + '" class="la1-fullscreenExitIcon" fill="#FFFFFF" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M0 0h24v24H0z" fill="none"/><path d="M5 16h3v3h2v-5H5v2zm3-8H5v2h5V5H8v3zm6 11h2v-3h3v-2h-5v5zm2-11V5h-2v5h5V8h-3z"/></svg>' +
			'  </button>' +
			'</div>';
			return html;
		}

		function initDashControls(videoPlayerWrapper) {

			// build our video player html and add to the page
			if (options.showControlsBelow){

				// show controls below the video
				videoPlayerWrapper.innerHTML =
					'<div id="' + VIDEO_CONTAINER_ID + '" class="la1-overlay-parent la1-videoContainer">' +
						getVideoHtml() +
					'</div>' +
					'<div id="' + CONTROLS_CONTAINER_ID + '" class="la1-controlsContainer">' +
						getPlayerControlsHtml() +
					'</div>';

			} else {

				// overlay the controls on top of the video at the bottom
				videoPlayerWrapper.innerHTML =
					'<div id="' + VIDEO_CONTAINER_ID + '" class="la1-overlay-parent la1-videoContainer">' +
						getVideoHtml() +
						'<div id="' + CONTROLS_CONTAINER_ID + '" class="la1-overlay la1-controlsContainer">' +
						getPlayerControlsHtml() +
						'</div>' +
					'</div>';
			}

			videoEl = document.getElementById(VIDEO_ID);
			controlsContainerEl = document.getElementById(CONTROLS_CONTAINER_ID);
			controlsEl = document.getElementById(CONTROLS_ID);
			bufferingSpinnerEl = document.getElementById(BUFFERING_SPINNER_ID);
			castReceiverNameEl = document.getElementById(CAST_RECEIVER_NAME_ID);
			errorMessageEl = document.getElementById(ERROR_MSG_ID);
			autoplayMuteWarnEl = document.getElementById(AUTO_PLAY_MUTED_MSG_ID);
			playPauseBtnEl = document.getElementById(PLAY_PAUSE_BTN_ID);
			playBtnIconEl = document.getElementById(PLAY_BTN_ICON_ID);
			pauseBtnIconEl = document.getElementById(PAUSE_BTN_ICON_ID);
			muteUnmuteBtnEl = document.getElementById(MUTE_BTN_ID);
			muteBtnIconEl = document.getElementById(MUTE_BTN_ICON_ID);
			unmuteBtnIconEl = document.getElementById(UNMUTE_BTN_ICON_ID);
			currentTimeEl = document.getElementById(CURRENT_TIME_ID);
			seekBarEl = document.getElementById(SEEK_BAR_ID);
			castBtnEl = document.getElementById(CAST_BTN_ID);
			fullscreenIconEl = document.getElementById(FULLSCREEN_ICON_ID);
			fullscreenExitIconEl = document.getElementById(EXIT_FULLSCREEN_ICON_ID);
			volumeBarEl = document.getElementById(VOLUME_BAR_ID);
			giantPlayButtonContainerEl = document.getElementById(GIANT_PLAY_BTN_CONTAINER_ID);
			giantPlayButtonEl = document.getElementById(GIANT_PLAY_BTN_ID);
			videoContainerEl = document.getElementById(VIDEO_CONTAINER_ID);
			bitrateMenuEl = document.getElementById(BITRATE_MENU_CONTENT_ID);
			bitrateBtnEl = document.getElementById(BITRATE_BTN_ID);
			fullScreenBtnEl = document.getElementById(FULLSCREEN_BTN_ID);
			statusLabelEl = document.getElementById(STATUS_LABEL_ID);
			subtitlesButtonEl = document.getElementById(SUBTITLES_BUTTON_ID)
		}

		function buildAdditionalShakaErrorMsg(error) {
			var message = '';
			try {
				switch (error.code) {
					case 1001:
						message += ' Additional: BAD_HTTP_STATUS Status Code=' + error.data[1] + ' URI=' + error.data[0]; break;
					case 1002:
						message += ' Additional: HTTP_ERROR URI=' + error.data[0]; break;
					case 3014:
						message += ' Additional: MEDIA_SOURCE_OPERATION_FAILED Media Error Code=' + error.data[0]; break;
					case 3015:
						message += ' Additional: MEDIA_SOURCE_OPERATION_THREW Exception=' + error.data[0]; break;
					case 3016:
						message += ' Additional: VIDEO_ERROR Media Error Code=' + error.data[0] + ' MSExtErrorCode=' + error.data[1] + ' Chrome Details=' + error.data[2]; break;
				}
			} catch (ex) {
				message += ' Additional: error formatting extra info. Ex=' + ex.toString();
			}
			return message;
		}

		function initDashPlayer(manifestUrl) {
			// ensure the buffering spinner is displayed while we load
			bufferingSpinnerEl.style.display = 'inherit';

			var localPlayer = new shaka.Player(videoEl);
			castProxy = new shaka.cast.CastProxy(videoEl, localPlayer, CC_APP_ID);
			log('CASTPROXY created using ' + CC_APP_ID);

			video = castProxy.getVideo();
			videoPlayer = castProxy.getPlayer();

			videoPlayer.addEventListener('error', function (event) {
				var message = 'Shaka video player reported an error.';
				if (event != null && event.detail) {
					message +=
						' Category ' + event.detail.category + ' Code ' + event.detail.code + ' Severity ' + event.detail.severity;
					message += buildAdditionalShakaErrorMsg(event.detail);
				}
				log(message);
				log(event);

				sendErrorReport(message);
			});

			videoPlayer.addEventListener('buffering', onBufferingStateChange.bind(this));
			videoPlayer.addEventListener('streaming', onStreamStarted);

			video.addEventListener('play', onPlayStateChange.bind(this));
			video.addEventListener('pause', onPlayStateChange.bind(this));
			video.addEventListener('volumechange', onVolumeStateChange.bind(this));
			video.addEventListener('ended', onStreamEnded);

			castProxy.addEventListener('caststatuschanged', onCastStatusChange.bind(this));
			castBtnEl.addEventListener('click', onCastBtnClick);

			seekBarEl.addEventListener('mousedown', onSeekStart.bind(this));
			seekBarEl.addEventListener('touchstart', onSeekStart.bind(this));
			seekBarEl.addEventListener('input', onSeekInput.bind(this));
			seekBarEl.addEventListener('touchend', onSeekEnd.bind(this));
			seekBarEl.addEventListener('mouseup', onSeekEnd.bind(this));

			volumeBarEl.addEventListener('input', onVolumeInput.bind(this));

			giantPlayButtonContainerEl.addEventListener('touchstart', onContainerTouch);
			giantPlayButtonContainerEl.addEventListener('click', videoPlayerPlayPause);

			playPauseBtnEl.addEventListener('click', videoPlayerPlayPause);
			muteUnmuteBtnEl.addEventListener('click', onMuteToggle);
			autoplayMuteWarnEl.addEventListener('click', onUnmuteAutoplay);
			subtitlesButtonEl.addEventListener('click', toggleSubtitles)

			videoContainerEl.addEventListener('mousemove', onMouseMove.bind(this));
			videoContainerEl.addEventListener('touchmove', onMouseMove.bind(this));
			videoContainerEl.addEventListener('touchend', onMouseMove.bind(this));
			videoContainerEl.addEventListener('mouseout', onMouseOut.bind(this));

			fullScreenBtnEl.addEventListener('click', onFullscreenClick);

			document.addEventListener('fullscreenchange', onFullscreenChange);

			bitrateMenuEl.style.display = 'none';
			bitrateBtnEl.addEventListener('click', onShowBitrateOptionsClick);

			// normally we kept track of interval ID so we could turn this off when angular page change occurred.
			// But think we don't need to worry about that in embedded widget case?
			var videoPlayerIntervalId = window.setInterval(updateTimeAndSeekBar.bind(this), 125);

			// set initial configuration for manifest and segment timeouts (in milliseconds)
			var initialConfig = {
				manifest: {
					retryParameters: {
						timeout: 15000,
						maxAttempts: 3,
					},
				},
				streaming: {
					retryParameters: {
						timeout: 15000,
						maxAttempts: 3,
					},
				},
			};
			videoPlayer.configure(initialConfig);
		}

		function initHlsPlayer(manifestUrl) {
			// build our video player html and add to the page
			videoPlayerWrapperEl.innerHTML =
				'<video id="' + VIDEO_ID + '" width="100%" controls autoplay playsinline>' +
				'  <source src="' + manifestUrl + '" type="application/x-mpegURL">' +
				'  Your browser or operating system version is not supported. Please ensure you are using iOS 10 or later and your browser is up-to-date.' +
				'</video>';

			video = document.getElementById(VIDEO_ID);
			video.addEventListener('loadeddata', presentationDelayListener);
			video.addEventListener('error', onErrorListener);

			// check for stalled video player (once every 5 seconds)
			//	window.setInterval(function(){
			//		stalledPlayerCheck();
			//	}, 5000);

			if (REPORT_ANALYTICS) {
				// start interval for reporting analytics (report once per minute)
				if (playerStateIntervalId == null) {
					playerStateIntervalId = window.setInterval(reportAnalyticsHls, statsUpdateFreq);
					log('Analytics interval has been set using ' + statsUpdateFreq);
				} else {
					log('Analytics interval not set because ID already in use');
				}
			}

			// NOTE: analytics get reported right away in presentationDelayListener (which should be called after video has loaded)
		}

		function getBufferSizeInSeconds() {
			if (video != null) {
				var current = video.currentTime;
				if (video.buffered.length > 0) {
					for (var i = 0; i < video.buffered.length; i++) {
						var start = video.buffered.start(i);
						var end = video.buffered.end(i);
						if (current >= start && current <= end) {
							var size = end - current;
							return size;
						}
					}
				}
			}
			return 0;
		}

		var previousBufferSize = 0;
		var previousBufferSizeTime = null;
		var initialPlayheadPosition = null;
		var canStartCheckingForStall = false;
		var lastTimePlayheadMovedTime = null;
		var lastTimePlayheadMovedPosition = null;
		var hasLoadedDataEventFired = false;
		var resetToThisPositionAfterReload = null;
		var RELOAD_PLAYER_TIME_THRESHOLD = 15000; // 15 seconds
		var BUFFER_SIZE_TIME_THRESHOLD = 10000; // 10 seconds

		// we consider the player stalled if:
		// 1) the video player is in the "playing" state
		// 2) the playhead hasn't moved in > 15 seconds
		// 3) the buffer size hasn't grown in > 10 seconds
		// ... we also don't want to start checking for stall until the player actually starts playing after load. So we wait
		// for the playhead to move after being loaded before checking for a stall.
		function stalledPlayerCheck() {
			if (video.paused) {
				//		log("-- stalledPlayerCheck -- video is paused, so not checking anything.");
			} else {
				var current = video.currentTime;
				var now = Date.now();
				var bufferSize = getBufferSizeInSeconds();

				if (initialPlayheadPosition == null) {
					if (current > 0 && current != resetToThisPositionAfterReload) {
						//				log("-- stalledPlayerCheck -- first check, initializing variables. [current: " + current + "]");
						initialPlayheadPosition = current;
					} else {
						//				log("-- stalledPlayerCheck -- video.currentTime is still zero, so won't do anything yet.");
					}
				} else if (!canStartCheckingForStall) {
					if (current != initialPlayheadPosition) {
						//				log("-- stalledPlayerCheck -- player appears past loading stage, will start checking for stall [initialPlayhead: " + initialPlayheadPosition + "] [current: " + current + "].");
						canStartCheckingForStall = true;
						lastTimePlayheadMovedTime = now;
						lastTimePlayheadMovedPosition = current;
					} else {
						//				log("-- stalledPlayerCheck -- looks like player is still loading, so we won't start checking for stall.");
					}
				} else {
					if (lastTimePlayheadMovedPosition == current) {
						if (now - lastTimePlayheadMovedTime > RELOAD_PLAYER_TIME_THRESHOLD) {
							var timeSinceBufferSizeChanged = previousBufferSizeTime == null ? 0 : now - previousBufferSizeTime;

							if (bufferSize > previousBufferSize) {
								//						log("-- stalledPlayerCheck -- playhead has not moved for > 15 seconds, BUT buffer is growing, so will wait.");
							} else if (timeSinceBufferSizeChanged < BUFFER_SIZE_TIME_THRESHOLD) {
								//						log("-- stalledPlayerCheck -- playhead has not moved for > 15 seconds, and buffer isn't growing, but only for " + (timeSinceBufferSizeChanged/1000) + " seconds, so will wait.");
							} else {
								// we are playing and playhead hasn't moved for 15 seconds
								//						log("-- stalledPlayerCheck -- playhead has not moved for > 15 seconds and buffer hasn't grown in > 10 seconds; WILL NOW RELOAD PLAYER [playhead=" + current + "]");
								// reset our stall variables since we are going to reload
								initialPlayheadPosition = null;
								canStartCheckingForStall = false;
								lastTimePlayheadMovedTime = null;
								lastTimePlayheadMovedPosition = null;
								previousBufferSize = 0;
								previousBufferSizeTime = null;

								resetToThisPositionAfterReload = current;
								video.addEventListener('loadeddata', resetPlayerPositionAfterReload);
								hasLoadedDataEventFired = false;
								window.setTimeout(checkIfLoadedEventHasFired, 12000);
								video.load();
							}
						} else {
							var seconds = (now - lastTimePlayheadMovedTime) / 1000;
							//					log("-- stalledPlayerCheck -- playhead has not moved, but only for " + seconds + " seconds [playhead=" + current + "]");
						}
					} else {
						// playhead is moving ... reset our "lastTime" variables
						//				log("-- stalledPlayerCheck -- looks good. [playhead=" + current + "]");
						lastTimePlayheadMovedTime = now;
						lastTimePlayheadMovedPosition = current;
					}
				}

				// did our buffer size change? if so, then record the size and timestamp
				if (bufferSize != previousBufferSize) {
					previousBufferSize = bufferSize;
					previousBufferSizeTime = now;
				}
			}
		}

		// the video.load was only working half the time for brad's iPad; so we are adding this check and calling
		// load again if it looks like it hasn't worked. This appeared to fix the issue for him.
		function checkIfLoadedEventHasFired() {
			if (!hasLoadedDataEventFired) {
				//		log("-- stalledPlayerCheck -- loadeddata event has not fired after 12 seconds, CALLING video.load AGAIN.");
				video.load();
			} else {
				//		log("-- stalledPlayerCheck -- loadeddata event has already fired.");
			}
		}

		function resetPlayerPositionAfterReload() {
			hasLoadedDataEventFired = true;
			video.removeEventListener('loadeddata', resetPlayerPositionAfterReload);

			if (video != null && resetToThisPositionAfterReload != null) {
				//		log("-- stalledPlayerCheck -- reseting player position after reload to " + resetToThisPositionAfterReload);
				video.currentTime = resetToThisPositionAfterReload;
				video.play();
			} else {
				//		log("-- stalledPlayerCheck -- UNABLE to reset player position after reload!!!");
			}
		}

		function onErrorListener() {
			// error codes may be found here:
			// https://developer.mozilla.org/en-US/docs/Web/API/MediaError
			sendErrorReport('video.onError Code = ' + video.error.code + ' Message: ' + video.error.message);
			log('The following error was encountered. Code: ' + video.error.code + ' Message: ' + video.error.message);
		}

		// if an HLS stream is live, then we want to reposition the initial start point backwards to give
		// ourselves a "safety buffer". For DASH, a "safety buffer" can be built into the manifest, but
		// apparently HLS doesn't have that option, so we will do it ourselves in javascript.
		function presentationDelayListener() {
			// this method should only be called once, so remove our listener
			video.removeEventListener('loadeddata', presentationDelayListener);

			var currentTime = video.currentTime;
			// are we live? (if video.currentTime isn't zero, then we should be live)
			if (currentTime > 0) {
				if (currentTime > hlsPresentationDelay) {
					video.currentTime = currentTime - hlsPresentationDelay;
					log('Using presentation delay of ' + hlsPresentationDelay + ' seconds');
				} else {
					video.currentTime = 0;
					log('Close to beginning, so only using presentation delay of ' + currentTime + ' seconds');
				}
			} else {
				log('Stream is on-demand, not using presentation delay.');
			}

			if (REPORT_ANALYTICS) {
				// report analytics right away
				window.setTimeout(reportAnalyticsHls, 1000);
			}
		}

		// has given option been set?
		// we will only support using 1 embed code per page. If for some reason a customer wants to put more than 1 embed code on a single page,
		// they will need to use iframes.
		// Embed Code option usage:
		// <div id="la1-video-player" data-embed-id="cfb3a1f9-e662-4575-8075-06653adb7bf0" data-type="event"></div>
		// <script type="application/javascript">
		// var la1WebPlayerOptions = {
		//   changePageTitle: "[[event-name]]"
		// };
		// </script>
		// <script type="application/javascript" data-main="//control.resi.io/webplayer/loader.js" src="//control.resi.io/webplayer/require.js"></script>
		function isOptionSet(option) {
			return (
				typeof la1WebPlayerOptions !== 'undefined' &&
				la1WebPlayerOptions != null &&
				la1WebPlayerOptions.hasOwnProperty(option)
			);
		}

		function checkChangePageTitleOption(apiResponse) {
			// check to see if changePageTitle option was set and ensure api reponse included an event name
			if (isOptionSet('changePageTitle') && apiResponse != null && apiResponse.hasOwnProperty('name')) {
				var pageTitlePattern = la1WebPlayerOptions.changePageTitle;
				document.title = pageTitlePattern.replace('[[event-name]]', apiResponse.name);
			}
		}

		function intializeOptions (user_options){
			// setup defaults
			options = {
				muted: false,
				showControlsBelow: false // show player controls below video, or overlay on top?
			};
			// check to see if any options were provided
			if (typeof user_options !== 'undefined' && user_options != null){
				var valid_options = ['muted', 'showControlsBelow'];
				for (var i=0; i < valid_options.length; i++){
					var option = valid_options[i];
					if (user_options.hasOwnProperty(option)){
						options[option] = user_options[option];
					}
				}
			}
		}

		var videoPlayerWrapperEl = null; // we need to hold onto this when loading for iOS

		// determines what operating system the user has, and then initializes the appropriate video player
		function initializePlayer(manifestOptions, videoPlayerWrapper, user_options) {
			videoPlayerWrapperEl = videoPlayerWrapper;

			intializeOptions(user_options);

			// get our stats/analytics URL and frequency we should update
			if (REPORT_ANALYTICS) {
				statsUrl = manifestOptions.statsUrl;
				statsUpdateFreq = manifestOptions.hasOwnProperty('statsUpdateFreq')
					? parseInt(manifestOptions.statsUpdateFreq) * 1000 // convert to milliseconds
					: STAT_UPDATE_FREQ_DEFAULT;
				// may not need this, but just to be safe. If our calculated statsUpdateFreq seems low, then set to default
				if (statsUpdateFreq < STAT_UPDATE_FREQ_MIN) {
					statsUpdateFreq = STAT_UPDATE_FREQ_DEFAULT;
				}
				log('statsUpdateFreq = ' + statsUpdateFreq);
			}

			if (isIOS()) {
				log('iOS detected.');
				// set presentation delay if one was specified
				if (manifestOptions.hasOwnProperty('presentationDelay')) {
					hlsPresentationDelay = parseFloat(manifestOptions.presentationDelay);
					log('hlsPresentationDelay: ' + hlsPresentationDelay);
				}
			} else {
				//
				// user is not on iOS, so use DASH stream with Shaka
				//

				// Install built-in polyfills to patch browser incompatibilities.
				shaka.polyfill.installAll();

				// check to see if we should enable Chromecast
				if (typeof user_options !== 'undefined' && user_options.hasOwnProperty('chromeCastAppId') && user_options.chromeCastAppId != null) {
					setCastAppId(user_options.chromeCastAppId);
				}

				// we'll need to init the controls even if the browser isn't supported
				initDashControls(videoPlayerWrapper);

				// Check to see if the browser supports the basic APIs Shaka needs.
				if (shaka.Player.isBrowserSupported()) {
					log('Your browser is supported.');
					// add our video player html to the page
					initDashPlayer(manifestOptions.cloud.dashUrl);
				} else {
					log('Your browser is NOT supported.');
					showErrorMessage('Your browser is not supported.<br /><br />Please ensure that you are using the most up-to-date version of your browser.');
					sendErrorReport('browser not supported');
				}
			}
		}

		function isIOS() {
			var userAgent = navigator.userAgent || navigator.vendor || window.opera;
			if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
				return true;
			}
			return false;
		}

		function loadPlayer(manifestOptions) {
			if (isIOS()) {
				//
				// user is using iOS, so use HLS stream with video tag
				// see: https://stackoverflow.com/questions/21741841/detecting-ios-android-operating-system
				//
				initHlsPlayer(manifestOptions.cloud.hlsUrl);
			} else {
				// ensure error messages are cleared/hidden
				errorMessageEl.style.display = 'none';
				errorMessageEl.innerHTML = '';

				videoPlayer
					.load(manifestOptions.cloud.dashUrl)
					.then(function () {
						// This runs if the asynchronous load is successful.
						bufferingSpinnerEl.style.display = 'none';

						// check to see if "muted" option was set
						if (options.muted){
							video.muted = true;
						}

						onPlayStateChange();
						onVolumeStateChange();

						// selected bitrate defaults to "auto" when loading new video
						selectedBitrate = BITRATE_AUTO;

						// determine available bitrates
						initBitrateMenu();

						// determine if we need to adjust our player configuration options
						/*            if (apiResponse.hasOwnProperty('notFullScreenRestrictionMaxHeight')){
							log("notFullScreenRestrictionMaxHeight: " + apiResponse.notFullScreenRestrictionMaxHeight);
							if (apiResponse.notFullScreenRestrictionMaxHeight != null){

								// do we have any tracks that will meet the notFullScreenRestrictionMaxHeight restriction?
								var validTracks = 0;
								var tracks = videoPlayer.getVariantTracks();
								for (var i=0; i < tracks.length; i++){
									var track = tracks[i];
									if (track.height <= apiResponse.notFullScreenRestrictionMaxHeight){
										validTracks++;
									}
								}
								// if we do have tracks that meet the restriction, then put it in place; otherwise we will
								// not change our configuration options
								if (validTracks >= 1){
									ABR_NOT_FULLSCREEN.abr.restrictions.maxHeight = apiResponse.notFullScreenRestrictionMaxHeight;
								} else {
									log('No matching tracks found, so ABR restrictions will be left "as is".');
								}
							}
						} else {
							log("notFullScreenRestrictionMaxHeight: not provided");
						}*/

						configureAutoABR();

						// attempt auto play
						var promise = video.play();
						if (promise !== undefined) {
							promise
								.then(function () {
									// Autoplay started!
								})
								.catch(function () {
									// auto play didn't work, so mute the player and try again
									video.muted = true;
									var inner_promise = video.play();
									if (inner_promise !== undefined) {
										inner_promise.then(function () {
											// auto play worked, so let user know video is muted
											showAutoplayMuteWarning();
										})
										.catch(function(reason){
											// Chrome shows an error in the console if you don't handle promise exceptions
											// This may occur if we decide we need to close the player after calling play
										});
									}
								});
						}

						if (REPORT_ANALYTICS) {
							// start interval for reporting analytics
							if (playerStateIntervalId == null) {
								playerStateIntervalId = window.setInterval(reportAnalyticsDash, statsUpdateFreq);
								log('Analytics interval has been set using ' + statsUpdateFreq);
							} else {
								log('Analytics interval not set because ID already in use');
							}

							// report analytics right away
							window.setTimeout(reportAnalyticsDash, 1000);
						}

						if (videoPlayer.getTextTracks().length) {
							subtitlesButtonEl.style.display = "block"
						}
					})
					.catch(onLoadError); // onLoadError is executed if the asynchronous load fails.
			}
		}

		// this should be called when the webplayer is no longer needed/visible, but the user is still on the same page, and
		// may need to reuse the webplayer depending on what the user does.
		function unloadPlayer() {
			if (isIOS()) {
				if (video != null) {
					video.removeEventListener('loadeddata', presentationDelayListener);
					video.removeEventListener('error', onErrorListener);
					video = null;
				}
				if (videoPlayerWrapperEl != null) {
					videoPlayerWrapperEl.innerHTML = '';
				}
			} else {
				// ensure error messages are cleared/hidden
				errorMessageEl.style.display = 'none';
				errorMessageEl.innerHTML = '';

				videoPlayer.unload();
				videoPlayer.destroy();
			}
		}

		return {
			initializePlayer: initializePlayer,
			load: loadPlayer,
			unload: unloadPlayer,

			getCurrentPosition: function () {
				// in seconds
				return video.currentTime;
			},

			getDuration: function () {
				return video.duration;
			},

			canGetTimeRange: function () {
				return videoPlayer != null;
			},

			getTimeRange: function () {
				if (videoPlayer != null) {
					return videoPlayer.seekRange(); // will return object with "start" and "end" properties
				}
				return null;
			},

			getVideo: function () {
				return video;
			},

			//    isLive: function (){
			//        if (videoPlayer != null){
			//            return videoPlayer.isLive(); // DASH check
			//        }
			//        return isLive(video.duration); // HLS check
			//    },

			seek: function (position) {
				// position should be in seconds (just like how currentTime returns it)
				video.currentTime = position;
			},

			isLive: function (){
				if (videoPlayer != null){
					return videoPlayer.isLive();
				}
				return false;
			},

			setStatusLive: function (){
				if (statusLabelEl){
					statusLabelEl.style.display = 'block';
					statusLabelEl.innerHTML = 'LIVE';
					var element = angular.element(statusLabelEl);
					element.addClass('la1-statusLive');
					element.removeClass('la1-statusUnpublished');
				}
			},

			setStatusUnpublished: function (){
				if (statusLabelEl){
					statusLabelEl.style.display = 'block';
					statusLabelEl.innerHTML = 'Unpublished';
					var element = angular.element(statusLabelEl);
					element.addClass('la1-statusUnpublished');
					element.removeClass('la1-statusLive');
				}
			},

			registerOnStreamStartedCallback: function (callback){
				onStreamStartedCallback = callback;
			},

			// register to be notified when buffering state changes
			registerOnStreamEndedCallback: function (data, callback){ // TODO: we don't need the data part anymore ...
				onStreamEndedCallbackInfo = {
					callback: callback,
					returnData: data
				};
			},
		};
	}; // La1WebPlayer

	/********** End of webplayer.js **********/
	/*****************************************/

	// each videoPlayerWrapperId will get its own web player. So each separate page on control will need to
	// pass in a unique videoPlayerWrapperId.
	var webPlayerMap = {};
	var hasVideoPlayerFrameworkBeenLoaded = false;

	function initializeByEmbedCodeCallback(videoPlayerWrapperId, embedCode, callback) {
		//console.log("In webPlayer.initializeByEmbedCode; Embed Code=" + embedCode);

		// ensure video player wrapper element exists
		var videoPlayerWrapperEl = document.getElementById(videoPlayerWrapperId);
		if (!videoPlayerWrapperEl) {
			log('Video player DIV not found.');
			return callback(null, 'Video player DIV not found');
		}

		// get our manifest URLs from the API using our embed code
		// see: https://developer.mozilla.org/en-US/docs/AJAX/Getting_Started
		var httpRequest = new XMLHttpRequest();
		httpRequest.onreadystatechange = function () {
			if (httpRequest.readyState === XMLHttpRequest.DONE) {
				if (httpRequest.status === 200) {
					// we've successfully retrieved our manifest URLs, so finish init of the video player
					var manifestOptions = JSON.parse(httpRequest.responseText);
					//console.log("API RESPONSE:");
					//console.log(manifestOptions);

					// TODO: having trouble with our webplayer caching using html elements that are dynamically created by ng-repeat statments.
					// So for now we will just always create a new webplayer.
//					if (webPlayerMap.hasOwnProperty(videoPlayerWrapperId) && webPlayerMap[videoPlayerWrapperId] != null) {
//						console.log('Existing webplayer found for [' + videoPlayerWrapperId + ']. Unloading and reloading webplayer.');
//						var webplayer = webPlayerMap[videoPlayerWrapperId];
//						webplayer.unload();
//						webplayer.load(manifestOptions);
//						return callback(webPlayerMap[videoPlayerWrapperId]);
//					} else {
						console.log('No webplayer found for [' + videoPlayerWrapperId + ']. Creating new webplayer.');
						console.log("CREATING NEW WEBPLAYER");
						var webplayer = new La1WebPlayer(videoPlayerWrapperId);
						webplayer.initializePlayer(manifestOptions, videoPlayerWrapperEl);
						webplayer.load(manifestOptions);
						webPlayerMap[videoPlayerWrapperId] = webplayer;
						return callback(webplayer);
//					}
				} else {
					// There was a problem with the request.
					// For example, the response may have a 404 (Not Found) or 500 (Internal Server Error) response code.
					// STATUS == 404 when embed code doesn't exist
					if (httpRequest.status == 0) {
						callback(null, 'There was a problem loading the event (Status: ' + httpRequest.status + '). Please try refreshing your browser window.');
					} else {
						// we can't use showErrorMessage() here, b/c elements it references have not been init'd yet
						callback(null, 'Event is not currently available (Status: ' + httpRequest.status + ').');
					}
				}
			}
		};
		httpRequest.open('GET', MANIFEST_API_URL + embedCode, true);
		httpRequest.send();
	}

	// adds https:// to the manifest if it doesn't contain a protocol
	function prepareManifestUrl (manifest_url){
		if (manifest_url != null){
			return manifest_url.indexOf('http') != 0 ? 'https://' + manifest_url : manifest_url;
		}
		return manifest_url;
	}

	function initializeByManifestUrlCallback(videoPlayerWrapperId, manifestDashUrl, manifestHlsUrl, callback, user_options) {
		//console.log("In webPlayer.initializeByManifestUrl; Dash=" + manifestDashUrl + "; HLS=" + manifestHlsUrl);

		// ensure video player wrapper element exists
		var videoPlayerWrapperEl = document.getElementById(videoPlayerWrapperId);
		if (!videoPlayerWrapperEl) {
			console.log('Video player DIV not found. ID=' + videoPlayerWrapperId);
			return callback(null, 'Video player DIV not found');
		}

		var manifestOptions = {
			statsUrl: null,
			cloud: {
				hlsUrl: prepareManifestUrl(manifestHlsUrl),
				dashUrl: prepareManifestUrl(manifestDashUrl),
			},
		};

		// TODO: having trouble with our webplayer caching using html elements that are dynamically created by ng-repeat statments.
		// So for now we will just always create a new webplayer.
//		if (webPlayerMap.hasOwnProperty(videoPlayerWrapperId) && webPlayerMap[videoPlayerWrapperId] != null) {
//			console.log('Existing webplayer found for [' + videoPlayerWrapperId + ']. Unloading and reloading webplayer.');
//			var webplayer = webPlayerMap[videoPlayerWrapperId];
//			webplayer.unload();
//			webplayer.load(manifestOptions);
//			return callback(webPlayerMap[videoPlayerWrapperId]);
//		} else {
			console.log('No webplayer found for [' + videoPlayerWrapperId + ']. Creating new webplayer.');
			console.log("CREATING NEW WEBPLAYER");
			var webplayer = new La1WebPlayer(videoPlayerWrapperId);
			webplayer.initializePlayer(manifestOptions, videoPlayerWrapperEl, user_options);
			webplayer.load(manifestOptions);
			webPlayerMap[videoPlayerWrapperId] = webplayer;
			return callback(webplayer);
//		}
	}

	return {
		seek: function (videoPlayerWrapperId, position) {
			if (webPlayerMap.hasOwnProperty(videoPlayerWrapperId) && webPlayerMap[videoPlayerWrapperId] != null) {
				var webplayer = webPlayerMap[videoPlayerWrapperId];
				webplayer.seek(position);
			} else {
				console.log('Unable to seek because no matching webplayer was found.');
			}
		},

		// this should be called when the user navigates away from the page a webplayer is on
		unload: function (videoPlayerWrapperId) {
			if (webPlayerMap.hasOwnProperty(videoPlayerWrapperId) && webPlayerMap[videoPlayerWrapperId] != null) {
				var webplayer = webPlayerMap[videoPlayerWrapperId];
				webplayer.unload();
				webPlayerMap[videoPlayerWrapperId] = null;
			}
		},

		// videoPlayerWrapperId - each wrapper must have a unique ID across the application (so use part of page to build it)
		// usage:
		// webPlayerService.initializeByEmbedCode(id, embedCode, function (webplayer, error){
		//      $scope.web_player = webplayer;
		//      // error message is only valid if webplayer is null
		// });
		initializeByEmbedCode: function (videoPlayerWrapperId, embedCode, callback) {
			if (hasVideoPlayerFrameworkBeenLoaded) {
				console.log('initializeByEmbedCode ... video player framework already loaded');
				initializeByEmbedCodeCallback(videoPlayerWrapperId, embedCode, callback);
			} else {
				import(
          /* webpackChunkName: "shaka" */
          `./shaka-player-${jcs.shaka.v30}.compiled.js`
        ).then((module) => {
          shaka = module.default;
          console.log('initializeByEmbedCode ... video player framework LAZY loaded');
          hasVideoPlayerFrameworkBeenLoaded = true;
          initializeByEmbedCodeCallback(videoPlayerWrapperId, embedCode, callback);
        });
			}
		},

		// videoPlayerWrapperId - each wrapper must have a unique ID across the application (so use part of page to build it)
		// usage:
		// webPlayerService.initializeByManifestUrl(id, dashUrl, hlsUrl, function (webplayer, error){
		//      $scope.web_player = webplayer;
		//      // error message is only valid if webplayer is null
		// });
		initializeByManifestUrl: function (videoPlayerWrapperId, manifestDashUrl, manifestHlsUrl, callback, options) {
			if (hasVideoPlayerFrameworkBeenLoaded) {
				console.log('initializeByManifestUrl ... video player framework already loaded');
				initializeByManifestUrlCallback(videoPlayerWrapperId, manifestDashUrl, manifestHlsUrl, callback, options);
			} else {				
				import(
          /* webpackChunkName: "shaka" */
          `./shaka-player-${jcs.shaka.v30}.compiled.js`
        ).then((module) => {
          shaka = module.default;
          console.log('initializeByManifestUrl ... video player framework LAZY loaded');
          hasVideoPlayerFrameworkBeenLoaded = true;
          initializeByManifestUrlCallback(videoPlayerWrapperId, manifestDashUrl, manifestHlsUrl, callback, options);
        });
			}
		},
	};
}

module.exports = WebPlayerService;
