import React from 'react';
import Tab from '@material-ui/core/Tab';
import { withStyles, createStyles } from '@material-ui/styles';
import { tabItemStyles } from './material-styles';

interface StyledTabProps {
  label: string;
}

const VerticalTab = withStyles(() => createStyles(tabItemStyles))((props: StyledTabProps) => (
  <Tab disableRipple {...props} />
));

export default VerticalTab;
