import { BracketReference, HistoricalWebEventResponseObject } from '../../pages/EventAnalytics/types';
import { fetchEventNamesFromEventIdList } from './api';

const getCachedEventNamesFetch = async (
  eventIDList: string[],
  customerID: string,
  cacheRefObject: BracketReference<HistoricalWebEventResponseObject | null>
): Promise<{ data: HistoricalWebEventResponseObject[] }> => {
  const uniqueEventIds = Array.from(new Set(eventIDList));
  const initialCacheState: BracketReference<HistoricalWebEventResponseObject | null> = {};
  uniqueEventIds.forEach((uniqueEventId) => (initialCacheState[uniqueEventId] = null));
  const newEventIds = eventIDList.filter((eventId) => {
    if (cacheRefObject && !cacheRefObject[eventId]) {
      return eventId;
    }
  });
  const fetchData = async () => {
    if (newEventIds.length) {
      const response = await fetchEventNamesFromEventIdList(newEventIds, customerID);
      response.forEach((eventObject: HistoricalWebEventResponseObject) => {
        const { webEventId = '', name, startRequestTime } = eventObject;
        cacheRefObject
          ? (cacheRefObject[webEventId] = {
              webEventId,
              name,
              startRequestTime,
            })
          : null;
      });
    }
  };
  await fetchData();

  const data: HistoricalWebEventResponseObject[] = Object.values(cacheRefObject).map((value) => ({
    webEventId: value?.webEventId,
    name: value?.name,
    startRequestTime: value?.startRequestTime,
  }));
  return { data };
};

export default getCachedEventNamesFetch;
