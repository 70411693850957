import { perms } from '../../jcs';
import { onboardingModule } from './index';

function routeConfig($provide, $routeProvider) {
	'ngInject';

	$provide.factory('$routeProvider', () => {
		return $routeProvider;
	});

	$routeProvider.when(onboardingModule.routes.list, {
		controller: onboardingModule.controllers.default,
		template: require('./onboarding.tmpl.html').default,
		activenav: onboardingModule.name,
		access: {
			loginRequired: true,
			permissions: [perms['encoders.add']],
		},
	});
}

export default routeConfig;
