'use strict';

const jcs = require('../../jcs');

function UnitOptionsController(
	$scope,
	$http,
	$q,
	$routeParams,
	$location,
	httpService,
	formValidationService,
	focus,
	Authentication
) {
	'ngInject';
	$(window).trigger('resize'); // ensure footer is properly positioned

	$scope.validation = formValidationService.init();

	$scope.MODEL_TYPE_DECODER = 'decoder';
	$scope.MODEL_TYPE_ENCODER = 'encoder';

	$scope.TAB_STATUS_LOCATION = 'statuses-locations';
	$scope.TAB_MODELS = 'models';
	$scope.current_tab = $scope.TAB_MODELS;

	$scope.is_loading = false;
	$scope.is_busy = false;

	$scope.status_options = null;
	$scope.location_options = null;
	$scope.decoder_model_options = null;
	$scope.encoder_model_options = null;
	$scope.cycle_options = null;
	$scope.cycle_lookup = null; // indexed by uuid
	$scope.DEFAULT_CLASSIFICATION = null;

	$scope.error_msg = null;

	$scope.edit_fields = null; // used when create new options, or editing existing options

	$scope.is_adding_status = false;
	$scope.status_to_edit = null;
	$scope.status_to_delete = null;

	$scope.is_adding_location = false;
	$scope.location_to_edit = null;
	$scope.location_to_delete = null;

	$scope.is_adding_model = false;
	$scope.model_to_edit = null;
	$scope.model_to_delete = null;

	$scope.getCurrentUser = function () {
		return Authentication.getCurrentUser();
	};

	$scope.cancel = function () {
		$scope.edit_fields = null;

		$scope.status_to_edit = null;
		$scope.status_to_delete = null;

		$scope.location_to_edit = null;
		$scope.location_to_delete = null;

		$scope.model_to_edit = null;
		$scope.model_to_delete = null;

		$scope.is_adding_status = false;
		$scope.is_adding_location = false;
		$scope.is_adding_model = false;

		$scope.error_msg = null;
		$scope.validation.clear();
	};

	$scope.enterAddStatusMode = function () {
		$scope.edit_fields = {
			name: '',
		};
		$scope.is_adding_status = true;

		focus('edit-status');
	};

	$scope.enterAddLocationMode = function () {
		$scope.edit_fields = {
			name: '',
		};
		$scope.is_adding_location = true;

		focus('edit-location');
	};

	$scope.enterAddDecoderMode = function () {
		$scope.edit_fields = {
			name: '',
			type: $scope.MODEL_TYPE_DECODER,
			classification: $scope.DEFAULT_CLASSIFICATION,
		};
		$scope.is_adding_model = true;

		focus('edit-model');
	};

	$scope.enterAddEncoderMode = function () {
		$scope.edit_fields = {
			name: '',
			type: $scope.MODEL_TYPE_ENCODER,
			classification: $scope.DEFAULT_CLASSIFICATION,
		};
		$scope.is_adding_model = true;

		focus('edit-model');
	};

	$scope.enterEditStatusMode = function (option) {
		$scope.status_to_edit = option;
		$scope.edit_fields = {
			name: option.name,
		};

		focus('edit-status');
	};

	$scope.enterEditLocationMode = function (option) {
		$scope.location_to_edit = option;
		$scope.edit_fields = {
			name: option.name,
		};

		focus('edit-location');
	};

	$scope.enterEditModelMode = function (option) {
		$scope.model_to_edit = option;
		$scope.edit_fields = {
			name: option.name,
			type: option.type,
			classification: option.cycleId,
		};

		focus('edit-model');
	};

	$scope.enterDeleteStatusMode = function (option) {
		$scope.status_to_delete = option;
	};

	$scope.enterDeleteLocationMode = function (option) {
		$scope.location_to_delete = option;
	};

	$scope.enterDeleteModelMode = function (option) {
		$scope.model_to_delete = option;
	};

	$scope.isEmpty = function (value) {
		return value == null || value === '';
	};

	$scope.doesOptionFailValidation = function () {
		$scope.error_msg = null;
		$scope.validation.clear();

		// ensure required fields are not empty
		$scope.validation.checkForEmpty('name', $scope.edit_fields.name);

		var has_validation_error = $scope.validation.hasError();
		if (has_validation_error) {
			$scope.error_msg = 'Please specify a value for the highlighted fields.';
		}

		return has_validation_error;
	};

	$scope.addOrUpdateStatus = function () {
		// if we have form validation errors, then don't go any further
		if ($scope.doesOptionFailValidation()) return false;

		$scope.performSaveForAddOrUpdateStatus();
	};

	$scope.addOrUpdateLocation = function () {
		// if we have form validation errors, then don't go any further
		if ($scope.doesOptionFailValidation()) return false;

		$scope.performSaveForAddOrUpdateLocation();
	};

	$scope.addOrUpdateModel = function () {
		// if we have form validation errors, then don't go any further
		if ($scope.doesOptionFailValidation()) return false;

		$scope.performSaveForAddOrUpdateModel();
	};

	$scope.performSaveForAddOrUpdateStatus = function () {
		$scope.is_busy = true;

		var api_data = {
			name: $scope.edit_fields.name,
		};

		if ($scope.status_to_edit != null) {
			$http
				.patch(jcs.api.internal_url + '/hardwarestatuses/' + $scope.status_to_edit.uuid, api_data, {
					withCredentials: true,
				})
				.then(
					function () {
						// success

						$scope.status_to_edit.name = $scope.edit_fields.name;

						$scope.edit_fields = null;
						$scope.status_to_edit = null;
					},
					function () {
						// error

						$scope.error_msg =
							'An error occurred while attempting to save your change. Please try again, or report the problem if it persists.';
					}
				)
			['finally'](function () {
				// always called

				$scope.is_busy = false;
			});
		} else {
			// create new status

			// update profile
			$http
				.post(jcs.api.internal_url + '/hardwarestatuses', api_data, { withCredentials: true })
				.then(
					function () {
						// success

						$scope.is_adding_status = false;
						$scope.edit_fields = null;

						$scope.loadHardwareOptions();
					},
					function () {
						// error

						$scope.error_msg =
							'An error occurred while attempting to create the new status. Please try again, or report the problem if it persists.';
					}
				)
			['finally'](function () {
				// always called

				$scope.is_busy = false;
			});
		}
	};

	$scope.performSaveForAddOrUpdateLocation = function () {
		$scope.is_busy = true;

		var api_data = {
			name: $scope.edit_fields.name,
		};

		if ($scope.location_to_edit != null) {
			$http
				.patch(jcs.api.internal_url + '/hardwarelocations/' + $scope.location_to_edit.uuid, api_data, {
					withCredentials: true,
				})
				.then(
					function () {
						// success

						$scope.location_to_edit.name = $scope.edit_fields.name;

						$scope.edit_fields = null;
						$scope.location_to_edit = null;
					},
					function () {
						// error

						$scope.error_msg =
							'An error occurred while attempting to save your change. Please try again, or report the problem if it persists.';
					}
				)
			['finally'](function () {
				// always called

				$scope.is_busy = false;
			});
		} else {
			// create new location

			// update profile
			$http
				.post(jcs.api.internal_url + '/hardwarelocations', api_data, { withCredentials: true })
				.then(
					function () {
						// success

						$scope.is_adding_location = false;
						$scope.edit_fields = null;

						$scope.loadHardwareOptions();
					},
					function () {
						// error

						$scope.error_msg =
							'An error occurred while attempting to create the new location. Please try again, or report the problem if it persists.';
					}
				)
			['finally'](function () {
				// always called

				$scope.is_busy = false;
			});
		}
	};

	$scope.performSaveForAddOrUpdateModel = function () {
		$scope.is_busy = true;

		var api_data = {
			name: $scope.edit_fields.name,
			type: $scope.edit_fields.type,
			cycleId: $scope.edit_fields.classification,
		};

		if ($scope.model_to_edit != null) {
			$http
				.patch(jcs.api.internal_url + '/hardwaremodels/' + $scope.model_to_edit.uuid, api_data, {
					withCredentials: true,
				})
				.then(
					function () {
						// success

						$scope.model_to_edit.name = $scope.edit_fields.name;
						$scope.model_to_edit.cycleId = $scope.edit_fields.classification;
						$scope.model_to_edit.cycle_label = $scope.getCycleLabel($scope.model_to_edit.cycleId);

						$scope.edit_fields = null;
						$scope.model_to_edit = null;
					},
					function () {
						// error

						$scope.error_msg =
							'An error occurred while attempting to save your change. Please try again, or report the problem if it persists.';
					}
				)
			['finally'](function () {
				// always called

				$scope.is_busy = false;
			});
		} else {
			// create new model

			$http
				.post(jcs.api.internal_url + '/hardwaremodels', api_data, { withCredentials: true })
				.then(
					function () {
						// success

						$scope.is_adding_model = false;
						$scope.edit_fields = null;

						$scope.loadHardwareOptions();
					},
					function () {
						// error

						$scope.error_msg =
							'An error occurred while attempting to create the new model number. Please try again, or report the problem if it persists.';
					}
				)
			['finally'](function () {
				// always called

				$scope.is_busy = false;
			});
		}
	};

	$scope.deleteStatus = function () {
		$scope.is_busy = true;

		// update profile
		$http
			.delete(jcs.api.internal_url + '/hardwarestatuses/' + $scope.status_to_delete.uuid, {
				withCredentials: true,
			})
			.then(
				function () {
					// success

					$scope.error_msg = null;
					$scope.status_to_delete = null;

					$scope.loadHardwareOptions();
				},
				function () {
					// error

					$scope.error_msg =
						'An error occurred while attempting to delete the status. Please try again, or report the problem if it persists.';
				}
			)
		['finally'](function () {
			// always called

			$scope.is_busy = false;
		});
	};

	$scope.deleteLocation = function () {
		$scope.is_busy = true;

		// update profile
		$http
			.delete(jcs.api.internal_url + '/hardwarelocations/' + $scope.location_to_delete.uuid, {
				withCredentials: true,
			})
			.then(
				function () {
					// success

					$scope.error_msg = null;
					$scope.location_to_delete = null;

					$scope.loadHardwareOptions();
				},
				function () {
					// error

					$scope.error_msg =
						'An error occurred while attempting to delete the location. Please try again, or report the problem if it persists.';
				}
			)
		['finally'](function () {
			// always called

			$scope.is_busy = false;
		});
	};

	$scope.deleteModel = function () {
		$scope.is_busy = true;

		// update profile
		$http
			.delete(jcs.api.internal_url + '/hardwaremodels/' + $scope.model_to_delete.uuid, {
				withCredentials: true,
			})
			.then(
				function () {
					// success

					$scope.error_msg = null;
					$scope.model_to_delete = null;

					$scope.loadHardwareOptions();
				},
				function () {
					// error

					$scope.error_msg =
						'An error occurred while attempting to delete the model number. Please try again, or report the problem if it persists.';
				}
			)
		['finally'](function () {
			// always called

			$scope.is_busy = false;
		});
	};

	$scope.getCycleLabel = function (cycle_id) {
		if ($scope.cycle_lookup.hasOwnProperty(cycle_id)) {
			return $scope.cycle_lookup[cycle_id];
		}
		return 'Unknown';
	};

	$scope.loadHardwareOptions = function () {
		$scope.is_loading = true;

		var promises = [];
		promises.push($http.get(jcs.api.internal_url + '/hardwarestatuses', { withCredentials: true }));
		promises.push($http.get(jcs.api.internal_url + '/hardwarelocations', { withCredentials: true }));
		promises.push($http.get(jcs.api.internal_url + '/hardwaremodels', { withCredentials: true }));
		promises.push($http.get(jcs.api.internal_url + '/hardwarecycles', { withCredentials: true }));

		$q.all(promises)
			.then(
				function (response) {
					$scope.status_options = response[0].data;
					$scope.location_options = response[1].data;
					var model_options = response[2].data;
					$scope.cycle_options = response[3].data;

					// create cycle lookup and set default classification
					$scope.cycle_lookup = {};
					for (var j = 0; j < $scope.cycle_options.length; j++) {
						var option = $scope.cycle_options[j];
						$scope.cycle_lookup[option.uuid] = option.name;
						if (option.name == 'Current Gen') {
							$scope.DEFAULT_CLASSIFICATION = option.uuid;
						}
					}

					// separate models into encoders & decoders
					$scope.decoder_model_options = [];
					$scope.encoder_model_options = [];
					for (var i = 0; i < model_options.length; i++) {
						var option = model_options[i];
						option.cycle_label = $scope.getCycleLabel(option.cycleId);
						if (option.type == $scope.MODEL_TYPE_DECODER) {
							$scope.decoder_model_options.push(option);
						} else {
							$scope.encoder_model_options.push(option);
						}
					}
				},
				function (reason) {
					if (!httpService.isStatus406(reason)) {
						$scope.error_msg =
							'An error occurred while loading the hardware options. Please try again, or report the problem if it persists.';
					}
				}
			)
			.finally(function () {
				$scope.is_loading = false;
			});
	};

	$scope.loadHardwareOptions();
}
module.exports = UnitOptionsController;
