'use strict';

const jcs = require('../../jcs');
const moment = require('moment');

function MonitorController($scope, $http, $q, httpService, webEventsService, encoderService, $timeout, focus) {
	'ngInject';
	$(window).trigger('resize'); // ensure footer is properly positioned

	$scope.encoderSvc = encoderService;

	$scope.loading_monitor_data_timeout_id = null;
	$scope.MILLISECONDS_PER_DAY = 1000 * 60 * 60 * 24;
	$scope.UPDATE_MONITOR_DATA_TIME_DELAY = 1000 * 120; // 2 minutes
	$scope.MAX_DATA_POINTS = $scope.MILLISECONDS_PER_DAY / $scope.UPDATE_MONITOR_DATA_TIME_DELAY; // only show 24 hours worth of data

	$scope.timezone_update_timeout_id = null; // timeout for updating our timezone list
	$scope.time_zones = {
		us_pacific: null,
		us_mountain: null,
		us_central: null,
		us_eastern: null,
		europe_london: null,
		europe_berlin: null,
		australia_sydney: null,
		pacific_auckland: null,
	};

	$scope.monitor_data = null;
	$scope.is_busy_loading_monitor_data = false;
	$scope.monitor_data_error = null;
	$scope.monitor_data_last_update = null;

	$scope.customer_names = null;

	$scope.is_working = false;
	$scope.error_msg = null;

	// more detailed decoder data
	$scope.monitor_decoder_data = null;
	$scope.is_busy_loading_decoder_data = false;
	$scope.monitor_decoder_data_error = null;
	$scope.decoder_version_counts = [];

	// more detailed encoder data
	$scope.monitor_encoder_data = null;
	$scope.is_busy_loading_encoder_data = false;
	$scope.monitor_encoder_data_error = null;
	$scope.encoder_hw_version_counts = []; // hardware encoders
	$scope.encoder_sw_version_counts = []; // software encoders
	$scope.total_hw_encoders = 0;
	$scope.total_sw_encoders = 0;

	// more detailed web data
	$scope.monitor_web_data = null;
	$scope.is_busy_loading_web_data = false;
	$scope.monitor_web_data_error = null;

	// web event to stop
	$scope.web_event_to_stop = null;

	// TEMPORARY METHODS - START
	// So we are experiencing performance issues using the <media-status> component (see line 367 in html file). With around 1600 online encoders it takes chrome
	// around 12 seconds to render the page. If we remove the component, and just place the same code directly in this controller, then it only takes around
	// 3 seconds. So until the online encoders API gets updated to return paginated results (story has been written), we'll stop using the <media-status> component
	// on this page. Once the pagination is ready we can resume use of the component and remove the methods below.
	$scope.isPlaying = function (status){
		return status && status.toLowerCase() == 'playing';
	};
	$scope.isStarted = function (status){
		return status && status.toLowerCase() == 'started';
	};
	$scope.isWarning = function (status){
		return status && status.toLowerCase() == 'warning';
	};
	$scope.isAborted = function (status){
		return status && status.toLowerCase() == 'aborted';
	};
	// TEMPORARY METHODS - END

	$scope.canShowStopBtn = function (web_event) {
		return web_event.status != 'stopped';
	};

	$scope.enterStopWebEventMode = function (web_event){
		$scope.web_event_to_stop = web_event;
	};

	$scope.cancelWebEventStop = function (){
		$scope.web_event_to_stop = null;
		$scope.error_msg = null;
	};

	$scope.removeWebEvent = function (web_event_uuid){

		// find matching entry index
		var index = -1;
		for (var i=0; i < $scope.monitor_web_data.length; i++){
			if ($scope.monitor_web_data[i].uuid == web_event_uuid){
				index = i;
				break;
			}
		}
		// remove entry
		if (index != -1){
			$scope.monitor_web_data.splice(index, 1);
		}
	};

	$scope.stopWebEvent = function (){

		$scope.is_working = true;

		httpService.post(jcs.api.url_v3 + '/customers/' + $scope.web_event_to_stop.customerId + '/webevents/' + $scope.web_event_to_stop.uuid + '/stop', {}, { withCredentials: true },
			function () {

				// if we call showWebEventDetails immediately we'll still get back the web event we just stopped (we need to give the backend some time
				// to finish processing); So we'll just remove the entry manually. When the person revisits the running web events it should reload and
				// the stopped web event should no longer be listed.
				$scope.removeWebEvent($scope.web_event_to_stop.uuid);

				$scope.web_event_to_stop = null;
				$scope.error_msg = null;

			},
			function () { // error

				$scope.error_msg = 'An error occurred while attempting to stop the web event. Please try again, or report the problem if it persists.';
			},
			function () { // always called

				$scope.is_working = false;
			});
	};

	// NOTE: default chart options and formatting functions need to be declared before they are used
	$scope.getEncoderChartOptions = function () {
		return {
			fillColor: 'rgba(60,141,188,0.4)',
			lineColor: 'rgba(60,141,188,1.0)',
			minSpotColor: 'rgb(0,0,0)',
			maxSpotColor: 'rgb(0,0,0)',
			spotColor: false,
			height: '43px',
			chartRangeMin: 0,
		};
	};
	$scope.getDecoderChartOptions = function () {
		return {
			fillColor: 'rgba(0,166,90,0.4)',
			lineColor: 'rgba(0,166,90,1.0)',
			minSpotColor: 'rgb(0,0,0)',
			maxSpotColor: 'rgb(0,0,0)',
			spotColor: false,
			height: '43px',
			chartRangeMin: 0,
		};
	};
	$scope.getWebChartOptions = function () {
		return {
			fillColor: 'rgba(96,92,166,0.4)',
			lineColor: 'rgba(96,92,166,1.0)',
			minSpotColor: 'rgb(0,0,0)',
			maxSpotColor: 'rgb(0,0,0)',
			spotColor: false,
			height: '43px',
			chartRangeMin: 0,
		};
	};
	// returns the formatted timestamp associated with the datapoint for the tooltip
	$scope.getTooltipSuffix = function (timestamps, index) {
		var suffix = '';
		if (index < timestamps.length) {
			suffix = ' (' + $scope.showTimeAsLocalHM(timestamps[index]) + ')';
		}
		return suffix;
	};

	// web events
	$scope.web_events_started_values = [];
	$scope.web_events_started_timestamps = [];
	$scope.web_events_started_options_all = $scope.getWebChartOptions();
	$scope.web_events_started_options_all.tooltipFormatter = function (sparkline, options, fields) {
		return fields.y + ' started' + $scope.getTooltipSuffix($scope.web_events_started_timestamps, fields.x);
	};

	// encoders
	$scope.encoder_avg_bandwidth_values = [];
	$scope.encoder_avg_bandwidth_timestamps = []; // used to create tooltips
	$scope.encoder_avg_bandwidth_options_all = $scope.getEncoderChartOptions();
	$scope.encoder_avg_bandwidth_options_all.tooltipFormatter = function (sparkline, options, fields) {
		return fields.y + ' Mbps' + $scope.getTooltipSuffix($scope.encoder_avg_bandwidth_timestamps, fields.x);
	};

	$scope.encoder_online_values = [];
	$scope.encoder_online_timestamps = []; // used to create tooltips
	$scope.encoder_online_options_all = $scope.getEncoderChartOptions();
	$scope.encoder_online_options_all.tooltipFormatter = function (sparkline, options, fields) {
		return fields.y + ' online' + $scope.getTooltipSuffix($scope.encoder_online_timestamps, fields.x);
	};

	$scope.encoder_started_values = [];
	$scope.encoder_started_timestamps = []; // used to create tooltips
	$scope.encoder_started_options_all = $scope.getEncoderChartOptions();
	$scope.encoder_started_options_all.tooltipFormatter = function (sparkline, options, fields) {
		return fields.y + ' started' + $scope.getTooltipSuffix($scope.encoder_started_timestamps, fields.x);
	};

	// decoders
	$scope.decoder_cloud_bandwidth_values = [];
	$scope.decoder_cloud_bandwidth_timestamps = []; // used to create tooltips
	$scope.decoder_cloud_bandwidth_options_all = $scope.getDecoderChartOptions();
	$scope.decoder_cloud_bandwidth_options_all.tooltipFormatter = function (sparkline, options, fields) {
		return fields.y + ' Mbps' + $scope.getTooltipSuffix($scope.decoder_cloud_bandwidth_timestamps, fields.x);
	};

	$scope.decoder_lan_bandwidth_values = [];
	$scope.decoder_lan_bandwidth_timestamps = []; // used to create tooltips
	$scope.decoder_lan_bandwidth_options_all = $scope.getDecoderChartOptions();
	$scope.decoder_lan_bandwidth_options_all.tooltipFormatter = function (sparkline, options, fields) {
		return fields.y + ' Mbps' + $scope.getTooltipSuffix($scope.decoder_lan_bandwidth_timestamps, fields.x);
	};

	$scope.decoder_online_values = [];
	$scope.decoder_online_timestamps = []; // used to create tooltips
	$scope.decoder_online_options_all = $scope.getDecoderChartOptions();
	$scope.decoder_online_options_all.tooltipFormatter = function (sparkline, options, fields) {
		return fields.y + ' online' + $scope.getTooltipSuffix($scope.decoder_online_timestamps, fields.x);
	};

	$scope.decoder_playing_values = [];
	$scope.decoder_playing_timestamps = []; // used to create tooltips
	$scope.decoder_playing_options_all = $scope.getDecoderChartOptions();
	$scope.decoder_playing_options_all.tooltipFormatter = function (sparkline, options, fields) {
		return fields.y + ' playing' + $scope.getTooltipSuffix($scope.decoder_playing_timestamps, fields.x);
	};

	$scope.decoder_lan_mode_values = [];
	$scope.decoder_lan_mode_timestamps = []; // used to create tooltips
	$scope.decoder_lan_mode_options_all = $scope.getDecoderChartOptions();
	$scope.decoder_lan_mode_options_all.tooltipFormatter = function (sparkline, options, fields) {
		return fields.y + ' LAN clients' + $scope.getTooltipSuffix($scope.decoder_lan_mode_timestamps, fields.x);
	};

	$scope.formatPosition = function (position) {
		var decimalPosition = position.indexOf('.');
		if (decimalPosition != -1) {
			//                    console.log("Position:" + position + " Formatted:" + position.substring(0,decimalPosition+3));
			// only show 2 places after the decimal
			return position.substring(0, decimalPosition + 3);
		}
		// position does not need formatting
		return position;
	};

	$scope.formatBandwidth = function (bandwidth) {
		if (bandwidth != null) {
			if (bandwidth < 10) return bandwidth.toFixed(1);

			return bandwidth.toFixed(0);
		}

		return bandwidth;
	};

	$scope.getLanBandwidth = function (playerOverview) {
		var lan_bandwidth = 0;

		if (playerOverview) {
			lan_bandwidth = playerOverview.averageBandwidth - playerOverview.averageCloudBandwidth;
			if (lan_bandwidth < 0) lan_bandwidth = 0; // ensure we don't return negative values
		}

		return lan_bandwidth;
	};

	$scope.getMonitorUpdateTimeInSec = function () {
		return $scope.UPDATE_MONITOR_DATA_TIME_DELAY;
	};

	// no seconds
	$scope.showTimeAsLocalHM = function (dateTimeToConvert) {
		// NOTE: we were using the Date "toLocaleString" method, but it turns out this isn't implemented consistently across
		// browsers. That is why we are using a javascript library (momentjs).

		// is the given time today?
		var currentDay = moment().format('MMM D, YYYY');
		var givenTimeDay = moment(dateTimeToConvert).format('MMM D, YYYY');
		if (currentDay == givenTimeDay) {
			return 'today at ' + moment(dateTimeToConvert).format('h:mm a');
		}

		// is the given time this year?
		var currentYear = moment().format('YYYY');
		var givenTimeYear = moment(dateTimeToConvert).format('YYYY');
		if (currentYear == givenTimeYear) {
			return moment(dateTimeToConvert).format('MMM D, h:mm a');
		}

		// for formatting options see: http://momentjs.com/
		return moment(dateTimeToConvert).format('MMM D, YYYY h:mm a');
	};

	// adds data point to given list, and ensures list doesn't grow beyond max allowed size
	$scope.addDataPoint = function (list, data_point) {
		list.push(data_point);
		if (list.length > $scope.MAX_DATA_POINTS) {
			list.shift(); // remove first entry
		}
	};

	$scope.updateCharts = function (data) {
		var now = moment().format();

		//
		// Encoders
		//

		// update encoder avg bandwidth data
		$scope.addDataPoint(
			$scope.encoder_avg_bandwidth_values,
			$scope.formatBandwidth(data.encoderOverview.averageBandwidth)
		);
		$scope.addDataPoint($scope.encoder_avg_bandwidth_timestamps, now);
		// update encoder online data
		$scope.addDataPoint($scope.encoder_online_values, data.encoderOverview.online);
		$scope.addDataPoint($scope.encoder_online_timestamps, now);
		// update encoder started data
		$scope.addDataPoint($scope.encoder_started_values, data.encoderOverview.started);
		$scope.addDataPoint($scope.encoder_started_timestamps, now);

		//
		// Decoders
		//

		// update decoder cloud bandwidth data
		$scope.addDataPoint(
			$scope.decoder_cloud_bandwidth_values,
			$scope.formatBandwidth(data.playerOverview.averageCloudBandwidth)
		);
		$scope.addDataPoint($scope.decoder_cloud_bandwidth_timestamps, now);
		// update decoder lan bandwidth data
		$scope.addDataPoint(
			$scope.decoder_lan_bandwidth_values,
			$scope.formatBandwidth($scope.getLanBandwidth(data.playerOverview))
		);
		$scope.addDataPoint($scope.decoder_lan_bandwidth_timestamps, now);
		// update decoder online data
		$scope.addDataPoint($scope.decoder_online_values, $scope.formatBandwidth(data.playerOverview.online));
		$scope.addDataPoint($scope.decoder_online_timestamps, now);
		// update decoder playing data
		$scope.addDataPoint($scope.decoder_playing_values, $scope.formatBandwidth(data.playerOverview.playing));
		$scope.addDataPoint($scope.decoder_playing_timestamps, now);
		// update decoder lan client data
		$scope.addDataPoint($scope.decoder_lan_mode_values, $scope.formatBandwidth(data.playerOverview.lanModeClients));
		$scope.addDataPoint($scope.decoder_lan_mode_timestamps, now);

		//
		// Web Events
		//

		$scope.addDataPoint($scope.web_events_started_values, data.webOverview.runningEvents);
		$scope.addDataPoint($scope.web_events_started_timestamps, now);
	};

	$scope.renderCharts = function () {
		// bandwidth charts may look better if they are relative to each other -- meaning they use the same max y value
		var max_encoder_bandwidth = Math.max.apply(null, $scope.encoder_avg_bandwidth_values);
		var max_decoder_cloud_bandwidth = Math.max.apply(null, $scope.decoder_cloud_bandwidth_values);
		var max_decoder_lan_bandwidth = Math.max.apply(null, $scope.decoder_lan_bandwidth_values);
		var max_bandwidth_overall = Math.max(max_encoder_bandwidth, max_decoder_cloud_bandwidth, max_decoder_lan_bandwidth);

		$scope.encoder_avg_bandwidth_options_all.chartRangeMax = max_bandwidth_overall;
		$scope.decoder_cloud_bandwidth_options_all.chartRangeMax = max_bandwidth_overall;
		$scope.decoder_lan_bandwidth_options_all.chartRangeMax = max_bandwidth_overall;

		// online & started charts will look better if they are relative to each other -- meaning they use the same max y value
		var max_online = Math.max.apply(null, $scope.encoder_online_values);
		var max_started = Math.max.apply(null, $scope.encoder_started_values);
		var max_overall = Math.max(max_online, max_started);

		$scope.encoder_online_options_all.chartRangeMax = max_overall;
		$scope.encoder_started_options_all.chartRangeMax = max_overall;

		// online, playing, & lan mode charts will look better if they are relative to each other -- meaning they use the same max y value
		var max_online = Math.max.apply(null, $scope.decoder_online_values);
		var max_playing = Math.max.apply(null, $scope.decoder_playing_values);
		var max_lan_mode = Math.max.apply(null, $scope.decoder_lan_mode_values);
		var max_overall = Math.max(max_online, max_playing, max_lan_mode);

		$scope.decoder_online_options_all.chartRangeMax = max_overall;
		$scope.decoder_playing_options_all.chartRangeMax = max_overall;
		$scope.decoder_lan_mode_options_all.chartRangeMax = max_overall;

		// set chart widths
		$scope.encoder_avg_bandwidth_options_all.width = $scope.getChartWidth(
			$scope.encoder_avg_bandwidth_values.length,
			$('#encoder-avg-bandwidth-chart-all').width()
		);
		$scope.encoder_online_options_all.width = $scope.getChartWidth(
			$scope.encoder_online_values.length,
			$('#encoder-online-chart-all').width()
		);
		$scope.encoder_started_options_all.width = $scope.getChartWidth(
			$scope.encoder_started_values.length,
			$('#encoder-started-chart-all').width()
		);
		$scope.decoder_cloud_bandwidth_options_all.width = $scope.getChartWidth(
			$scope.decoder_cloud_bandwidth_values.length,
			$('#decoder-cloud-bandwidth-chart-all').width()
		);
		$scope.decoder_lan_bandwidth_options_all.width = $scope.getChartWidth(
			$scope.decoder_lan_bandwidth_values.length,
			$('#decoder-lan-bandwidth-chart-all').width()
		);
		$scope.decoder_online_options_all.width = $scope.getChartWidth(
			$scope.decoder_online_values.length,
			$('#decoder-online-chart-all').width()
		);
		$scope.decoder_playing_options_all.width = $scope.getChartWidth(
			$scope.decoder_playing_values.length,
			$('#decoder-playing-chart-all').width()
		);
		$scope.decoder_lan_mode_options_all.width = $scope.getChartWidth(
			$scope.decoder_lan_mode_values.length,
			$('#decoder-lan-mode-chart-all').width()
		);
		$scope.web_events_started_options_all.width = $scope.getChartWidth(
			$scope.web_events_started_values.length,
			$('#web-started-chart-all').width()
		);

		//
		// encoders
		//

		$('#encoder-avg-bandwidth-chart-all').sparkline(
			$scope.encoder_avg_bandwidth_values,
			$scope.encoder_avg_bandwidth_options_all
		);
		$('#encoder-online-chart-all').sparkline($scope.encoder_online_values, $scope.encoder_online_options_all);
		$('#encoder-started-chart-all').sparkline($scope.encoder_started_values, $scope.encoder_started_options_all);

		//
		// decoders
		//

		$('#decoder-cloud-bandwidth-chart-all').sparkline(
			$scope.decoder_cloud_bandwidth_values,
			$scope.decoder_cloud_bandwidth_options_all
		);
		$('#decoder-lan-bandwidth-chart-all').sparkline(
			$scope.decoder_lan_bandwidth_values,
			$scope.decoder_lan_bandwidth_options_all
		);
		$('#decoder-online-chart-all').sparkline($scope.decoder_online_values, $scope.decoder_online_options_all);
		$('#decoder-playing-chart-all').sparkline($scope.decoder_playing_values, $scope.decoder_playing_options_all);
		$('#decoder-lan-mode-chart-all').sparkline($scope.decoder_lan_mode_values, $scope.decoder_lan_mode_options_all);

		//
		// web events
		//

		$('#web-started-chart-all').sparkline($scope.web_events_started_values, $scope.web_events_started_options_all);
	};

	$scope.getChartWidth = function (numberOfChartDataPts, widthOfChartArea) {
		// only set a width in pixels if we determine that sparkline will draw data beyond the width we have available
		// note: if the width provided is zero, then just use sparklines 'auto' width
		// 3 = default width sparkline assigns to each data point
		if (widthOfChartArea > 0 && numberOfChartDataPts * 3 > widthOfChartArea) return widthOfChartArea + 'px';

		return 'auto';
	};

	$scope.getBufferSize = function (playerInfo) {
		// calculate the diff between player position and buffer position in milliseconds
		var player_pos_ms = moment.duration(playerInfo.position).asMilliseconds();
		var buffer_pos_ms = moment.duration(playerInfo.buffer).asMilliseconds();
		var diff_ms = buffer_pos_ms > player_pos_ms ? buffer_pos_ms - player_pos_ms : 0;

		// create duration object, which can convert our milliseconds duration into hours/minutes/seconds
		var duration = moment.duration(diff_ms);
		var hours = duration.hours();
		var minutes = duration.minutes();
		var seconds = duration.seconds();

		// format with leading zeros
		var hours_formatted = hours < 10 ? '0' + hours : hours;
		var minutes_formatted = minutes < 10 ? '0' + minutes : minutes;
		var seconds_formatted = seconds < 10 ? '0' + seconds : seconds;

		return hours_formatted + ':' + minutes_formatted + ':' + seconds_formatted;
	};

	$scope.showWebEventDetails = function () {
		$scope.is_busy_loading_web_data = true;

		var promises = [];
		promises.push($http.get(jcs.api.url + '/monitors/webevents/live', { withCredentials: true }));
		// do we need to fetch the customer names list?
		if ($scope.customer_names == null) {
			promises.push($http.get(jcs.api.url + '/customers/names', { withCredentials: true }));
		}

		$q.all(promises)
			.then(
				function (response) {
					// if we fetched customer names, then build our customer name map indexed by UUID
					if (response.length == 2) {
						$scope.buildOrganizationNameMap(response[1].data);
					}

					// process web event data
					$scope.monitor_web_data = response[0].data;
					$scope.monitor_web_data_error = null;

					// determine customer name for each entry & build google log url
					for (var i = 0; i < $scope.monitor_web_data.length; i++) {
						var web_event = $scope.monitor_web_data[i];
						$scope.monitor_web_data[i].orgName = $scope.getOrganizationName($scope.monitor_web_data[i].customerId);
						web_event.google_log_url = webEventsService.getGoogleLogUrl(web_event.uuid);
					}
				},
				function (reason) {
					// error

					if (!httpService.isStatus406(reason)) {
						$scope.monitor_web_data_error =
							'Unable to retrieve web event monitoring information. Please try again (use the refresh button), or report the problem if it persists.';
					}
				}
			)
			.finally(function () {
				// always called

				$scope.is_busy_loading_web_data = false;
			});
	};

	$scope.cancelShowWebEventDetails = function () {
		$scope.monitor_web_data = null;
		$scope.monitor_web_data_error = null;
		$scope.is_busy_loading_web_data = false;

		// we can't call renderCharts immediately, because our charts are still hidden b/c the statements above haven't been "applied" yet.
		// use $timeout to render the charts during the next digest
		$timeout(function () {
			$scope.renderCharts();
		});
	};

	$scope.formatAvgBandwidth = function (value) {
		// see: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Number/toLocaleString
		return value.toLocaleString(undefined, {maximumFractionDigits: 2});
	};

	$scope.cancelShowDecoderDetails = function () {
		$scope.monitor_decoder_data = null;
		$scope.monitor_decoder_data_error = null;
		$scope.is_busy_loading_decoder_data = false;

		// we can't call renderCharts immediately, because our charts are still hidden b/c the statements above haven't been "applied" yet.
		// use $timeout to render the charts during the next digest
		$timeout(function () {
			$scope.renderCharts();
		});
	};

	$scope.showDecoderDetails = function () {
		$scope.is_busy_loading_decoder_data = true;

		var promises = [];
		promises.push($http.get(jcs.api.url + '/monitors/players', { withCredentials: true }));
		// do we need to fetch the customer names list?
		if ($scope.customer_names == null) {
			promises.push($http.get(jcs.api.url + '/customers/names', { withCredentials: true }));
		}

		$q.all(promises).then(
			function (response) {
				// if we fetched customer names, then build our customer name map indexed by UUID
				if (response.length == 2) {
					$scope.buildOrganizationNameMap(response[1].data);
				}

				// process decoder data
				$scope.monitor_decoder_data = response[0].data;
				$scope.monitor_decoder_data_error = null;

				// calculate our buffer sizes, create sortable version number, & calculate version number counts
				var version_counts = {};
				for (var i = 0; i < $scope.monitor_decoder_data.onlinePlayers.length; i++) {
					var player = $scope.monitor_decoder_data.onlinePlayers[i];
					$scope.monitor_decoder_data.onlinePlayers[i].bufferSize = $scope.getBufferSize(player);
					$scope.monitor_decoder_data.onlinePlayers[i].playerVersionSortable = $scope.getSortableDecoderVersionNumber(player.playerVersion);
					$scope.monitor_decoder_data.onlinePlayers[i].orgName = $scope.getOrganizationName($scope.monitor_decoder_data.onlinePlayers[i].fkCustomer);
					$scope.monitor_decoder_data.onlinePlayers[i].averageBandwidthFormatted = $scope.formatAvgBandwidth($scope.monitor_decoder_data.onlinePlayers[i].averageBandwidth);

					if (!version_counts.hasOwnProperty(player.playerVersion)) {
						version_counts[player.playerVersion] = 1;
					} else {
						version_counts[player.playerVersion]++;
					}
				}

				// build list of decoder version counts
				$scope.decoder_version_counts = [];
				for (var version in version_counts) {
					if (version_counts.hasOwnProperty(version)) {
						$scope.decoder_version_counts.push({
							version: version,
							count: version_counts[version],
							versionSortable: $scope.getSortableDecoderVersionNumber(version),
						});
					}
				}
			},
			function (reason) { // error

				if (!httpService.isStatus406(reason)) {
					$scope.monitor_decoder_data_error = 'Unable to retrieve decoder monitoring information. Please try again (use the refresh button), or report the problem if it persists.';
				}
			})
			.finally(function () { // always called

				$scope.is_busy_loading_decoder_data = false;
			});
	};

	$scope.getOrganizationName = function (customer_uuid) {
		if ($scope.customer_names.hasOwnProperty(customer_uuid)) {
			return $scope.customer_names[customer_uuid];
		}
		return 'Unknown';
	};

	// creates map so we can easily look up organization names by uuid
	$scope.buildOrganizationNameMap = function (data) {
		$scope.customer_names = {};
		for (var i = 0; i < data.length; i++) {
			var info = data[i];
			$scope.customer_names[info.uuid] = info.name;
		}
	};

	$scope.cancelShowEncoderDetails = function () {
		$scope.monitor_encoder_data = null;
		$scope.monitor_encoder_data_error = null;
		$scope.is_busy_loading_encoder_data = false;

		// we can't call renderCharts immediately, because our charts are still hidden b/c the statements above haven't been "applied" yet.
		// use $timeout to render the charts during the next digest
		$timeout(function () {
			$scope.renderCharts();
		});
	};

	$scope.showEncoderDetails = function () {
		$scope.is_busy_loading_encoder_data = true;

		const promises = [];
		promises.push($http.get(`${jcs.api.url}/monitors/encoders`, { withCredentials: true }));
		// do we need to fetch the customer names list?
		if ($scope.customer_names == null) {
			promises.push($http.get(`${jcs.api.url}/customers/names`, { withCredentials: true }));
		}

		$q.all(promises).then(response => {

			// if we fetched customer names, then build our customer name map indexed by UUID
			if (response.length == 2) {
				$scope.buildOrganizationNameMap(response[1].data);
			}

			// process encoder data
			$scope.monitor_encoder_data = response[0].data;
			$scope.monitor_encoder_data_error = null;

			// create sortable version number, & calculate version number counts
			$scope.total_hw_encoders = 0;
			$scope.total_sw_encoders = 0;
			const hw_version_counts = {}; // hardware
			const sw_version_counts = {}; // software
			for (const encoder of $scope.monitor_encoder_data.onlineEncoders) {
				encoder.encoderVersionSortable = $scope.getSortableEncoderVersionNumber(encoder.encoderVersion);
				encoder.orgName = $scope.getOrganizationName(encoder.customerId);
				encoder.averageBandwidthFormatted = $scope.formatAvgBandwidth(encoder.averageBandwidth);

				if (encoderService.isSoftwareEncoder(encoder)){
					$scope.total_sw_encoders++;
					if (!sw_version_counts.hasOwnProperty(encoder.encoderVersion)) {
						sw_version_counts[encoder.encoderVersion] = 1;
					} else {
						sw_version_counts[encoder.encoderVersion]++;
					}
				} else {
					$scope.total_hw_encoders++;
					if (!hw_version_counts.hasOwnProperty(encoder.encoderVersion)) {
						hw_version_counts[encoder.encoderVersion] = 1;
					} else {
						hw_version_counts[encoder.encoderVersion]++;
					}
				}
			}

			// build list of encoder version counts
			$scope.encoder_hw_version_counts = [];
			for (const version in hw_version_counts) {
				$scope.encoder_hw_version_counts.push({
					version: version,
					count: hw_version_counts[version],
					versionSortable: $scope.getSortableEncoderVersionNumber(version),
				});
			}
			$scope.encoder_sw_version_counts = [];
			for (const version in sw_version_counts) {
				$scope.encoder_sw_version_counts.push({
					version: version,
					count: sw_version_counts[version],
					versionSortable: $scope.getSortableEncoderVersionNumber(version),
				});
			}

		}).catch(reason => {

			if (!httpService.isStatus406(reason)) {
				$scope.monitor_encoder_data_error = 'Unable to retrieve encoder monitoring information. Please try again (use the refresh button), or report the problem if it persists.';
			}

		}).finally(() => {

			$scope.is_busy_loading_encoder_data = false;

		});
	};

	// converts decoder version number (1.2.3.4) into int that can be sorted
	$scope.getSortableDecoderVersionNumber = function (version) {
		// break version into parts
		var firstDotIndex = version.indexOf('.');
		var first = parseInt(version.substring(0, firstDotIndex));
		var secondDotIndex = version.indexOf('.', firstDotIndex + 1);
		var second = parseInt(version.substring(firstDotIndex + 1, secondDotIndex));
		var thirdDotIndex = version.indexOf('.', secondDotIndex + 1);
		var third = parseInt(version.substring(secondDotIndex + 1, thirdDotIndex));
		var fourth = parseInt(version.substring(thirdDotIndex + 1));
		// this should work as long as components of version number don't get too large (100+)
		var sortable = first * 1000000 + second * 10000 + third * 100 + fourth;
		return sortable;
	};

	// converts encoder version number (1.2.3) into int that can be sorted
	$scope.getSortableEncoderVersionNumber = function (version) {
		// break version into parts
		var firstDotIndex = version.indexOf('.');
		var first = parseInt(version.substring(0, firstDotIndex));
		var secondDotIndex = version.indexOf('.', firstDotIndex + 1);
		var second = parseInt(version.substring(firstDotIndex + 1, secondDotIndex));
		var third = parseInt(version.substring(secondDotIndex + 1));
		// this should work as long as components of version number don't get too large (100+)
		var sortable = first * 10000 + second * 100 + third;
		return sortable;
	};

	$scope.updateTimeZones = function () {
		var currentTime = moment();
		var tzFormat = 'LT'; // 9:04 AM

		$scope.time_zones.us_pacific = currentTime.tz('US/Pacific').format(tzFormat);
		$scope.time_zones.us_mountain = currentTime.tz('US/Mountain').format(tzFormat);
		$scope.time_zones.us_central = currentTime.tz('US/Central').format(tzFormat);
		$scope.time_zones.us_eastern = currentTime.tz('US/Eastern').format(tzFormat);
		$scope.time_zones.europe_london = currentTime.tz('Europe/London').format(tzFormat);
		$scope.time_zones.europe_berlin = currentTime.tz('Europe/Berlin').format(tzFormat);
		$scope.time_zones.australia_sydney = currentTime.tz('Australia/Sydney').format(tzFormat);
		$scope.time_zones.pacific_auckland = currentTime.tz('Pacific/Auckland').format(tzFormat);
	};

	// since we are only showing hours/minutes on our timezones, we calculate the next update time to be right after the
	// next minute mark (so requiring the system to do the minimum of work).
	$scope.getTimeZoneDelayTime = function () {
		var now = new Date();
		var seconds_remaining = 60 - now.getSeconds();
		var delay_time = seconds_remaining * 1000 + 500; // add 500 milliseconds to ensure that we have moved to next minute
		return delay_time;
	};

	$scope.startTimeZoneUpdates = function () {
		$scope.updateTimeZones();

		// setup timeout so our timezone data will auto refresh
		$scope.timezone_update_timeout_id = window.setTimeout(
			$scope.updateTimeZonesCallback,
			$scope.getTimeZoneDelayTime()
		);
	};

	$scope.updateTimeZonesCallback = function () {
		$scope.updateTimeZones();
		$scope.$apply();

		// setup timeout so our timezone data will auto refresh
		$scope.timezone_update_timeout_id = window.setTimeout(
			$scope.updateTimeZonesCallback,
			$scope.getTimeZoneDelayTime()
		);
	};

	$scope.loadMonitorDataSilent = function () {
		// pull fresh monitoring data
		$http
			.get(jcs.api.url + '/monitors', { withCredentials: true })
			.then(
				function (response) {
					// success

					$scope.monitor_data = response.data;
					$scope.monitor_data_error = null;

					$scope.monitor_data_last_update = moment().format();

					$scope.updateCharts(response.data);
					$scope.renderCharts();
				},
				function () {
					// error
					// If our "silent" load gets an error, lets not display an error message -- I think it would be confusing for the user to see
					// an error about an action that they did not initiate. So instead of displaying an error, setup another timeout in the hopes
					// that the error we got as a one-time thing, and upcoming check will work.
				}
			)
		['finally'](function () {
			// always called

			// setup timeout so our monitor data will auto refresh
			$scope.loading_monitor_data_timeout_id = window.setTimeout(
				$scope.loadMonitorDataSilent,
				$scope.getMonitorUpdateTimeInSec()
			);
		});
	};

	$scope.loadMonitorData = function () {
		$scope.is_busy_loading_monitor_data = true;

		// check to see if we have a scheduled timeout
		if ($scope.loading_monitor_data_timeout_id != null) {
			// if so, then clear it (we'll pull monitor data down below and set a timeout if necessary)
			window.clearTimeout($scope.loading_monitor_data_timeout_id);
			$scope.loading_monitor_data_timeout_id = null;
		}

		// pull fresh monitoring data
		$http.get(jcs.api.url + '/monitors', { withCredentials: true }).then(
			function (response) { // success

				$scope.monitor_data = response.data;
				$scope.monitor_data_error = null;

				$scope.monitor_data_last_update = moment().format();

				$scope.updateCharts(response.data);
				$scope.renderCharts();

				// setup timeout so our monitor data will auto refresh
				$scope.loading_monitor_data_timeout_id = window.setTimeout(
					$scope.loadMonitorDataSilent,
					$scope.getMonitorUpdateTimeInSec()
				);
			},
			function () { // error

				$scope.monitor_data_error = 'Unable to retrieve system monitoring information. Please try again (use the refresh button), or report the problem if it persists.';

			})
			['finally'](function () { // always called

				$scope.is_busy_loading_monitor_data = false;
			});
	};

	//
	// initialize by loading our system monitoring info
	//
	$scope.loadMonitorData();

	$scope.startTimeZoneUpdates();

	// since this page has timers, we need to know when the user changes to another page so we can turn the timers
	// off (this will prevent unnecessary http requests).
	$scope.$on('$locationChangeSuccess', function () {
		// clear any active timers we might have
		if ($scope.loading_monitor_data_timeout_id != null) {
			window.clearTimeout($scope.loading_monitor_data_timeout_id);
			$scope.loading_monitor_data_timeout_id = null;
		}
		if ($scope.timezone_update_timeout_id != null) {
			window.clearTimeout($scope.timezone_update_timeout_id);
			$scope.timezone_update_timeout_id = null;
		}
	});
}

module.exports = MonitorController;
