import 'angular-route';
import angular from 'angular';
import router from './module.unitdashboard.routes';
import commonServicesModule from '../../services';

export const unitdashboardModule = {
	name: 'unitdashboard',
	controllers: {
		default: 'unitDashboardCtrl',
	},
	routes: {
		list: '/unitdashboard',
	},
};

export default angular
	.module(unitdashboardModule.name, ['ngRoute', commonServicesModule])
	.config(router)
	.controller(unitdashboardModule.controllers.default, require('./unitDashboardCtrl')).name;
