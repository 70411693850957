interface RouteParams extends ng.route.IRouteParamsService {
  token: string;
}

class GetStartedController {
  public encryptedToken: string;

  constructor(private $routeParams: RouteParams) {
    'ngInject';
    this.encryptedToken = this.$routeParams.token;
  }
}
module.exports = GetStartedController;
