import React, { ChangeEvent, FC, useContext, useEffect, useState } from 'react';
import YoutubeStatsGraphView from './youtube-stats-graph-view';
import {
  API_RESPONSE_TYPE,
  DateType,
  GROUP_BY_VALUE,
  lineChartDataType,
  TIME_WINDOWS,
  YOUTUBE_PAGES,
} from '../../types';
import { Option } from '@resi-media/resi-ui';
import { dateRangeValues, customDateRangeOption, lifeTimeValues } from '../../date-range-options';
import { parseLineChartData, getDateRangeOptions, isMonthTabDisabled } from '../../utils';
import {
  fetchDataCollectionToggle,
  fetchLineChartData,
  fetchLineChartOnDemandData,
  fetchSocialMediaAccounts,
  toggleDataCollection,
} from '../api';
import { YoutubeContext } from '../../../../contexts/eventanalytics';
import {
  EVENT_ANALYTICS_CONTEXT_KEYS,
  GROUP_SET,
  PAGE_VALUE,
  TIME_WINDOW_SET,
  TIME_WINDOW_VALUES,
  YOUTUBE_PAGE,
  YOUTUBE_PAGE_STATE_CONTEXT_KEYS,
} from '../../event-analytics-constants';
import { EventAnalyticsContext } from '../../../../contexts/eventanalytics/event-analytics';
import { MPEventName, MPEventProperty, trackMixpanelEvent } from '../../../../mixpanel';

const YoutubeStatsGraph: FC<{ hasGraphPerms: boolean }> = ({ hasGraphPerms }): JSX.Element => {
  const { youtubeAnalyticsState, dispatch } = useContext(YoutubeContext);
  const { EventAnalyticsState, dispatch: dispatchEventAnalyticsState } = useContext(EventAnalyticsContext);
  const { viewAllData, dateRangeOption, eventId } = EventAnalyticsState;
  const [timeWindow, setTimeWindow] = useState<number>(0);
  const [isDatePickerOpen, setDatePickerOpen] = useState<boolean>(false);
  const [isDataCollectionOn, setIsDataCollectionOn] = useState<boolean>(false);
  const [isTogglingDataCollection, setIsTogglingDataCollection] = useState<boolean>(false);
  const isMonthTimeWindowDisabled = isMonthTabDisabled(dateRangeOption?.value);
  const [dataType, setDataType] = useState<GROUP_BY_VALUE>(0);
  const [dataPage, setDataPage] = useState<YOUTUBE_PAGES>(0);
  const [dataPageName, setDataPageName] = useState<string>(YOUTUBE_PAGE[0]);
  const [timeWindowValue, setTimeWindowValue] = useState<string>(TIME_WINDOW_VALUES.DAY);
  const [chartData, setChartData] = useState<lineChartDataType>({ labels: [], datasets: {} });
  const [channelData, setChannelData] = useState<Option[]>([]);
  const [isChartLoading, setChartLoading] = useState<boolean>(false);
  const [areChannelsLoading, setAreChannelsLoading] = useState<boolean>(false);
  const [selectedChannel, setSelectedChannel] = useState<string>('');

  const handleGroupByChange = (event: ChangeEvent<unknown>, newValue: GROUP_BY_VALUE): void => {
    setDataType(newValue);
    dispatch({ type: YOUTUBE_PAGE_STATE_CONTEXT_KEYS.GROUP_BY, payload: GROUP_SET[newValue] });

    let dataSegmentType = '';

    switch (newValue) {
      case 0:
        dataSegmentType = 'None';
        break;
      case 1:
        dataSegmentType = 'Event Type';
        break;
      case 2:
        dataSegmentType = 'Device Type';
        break;
    }

    const mixpanelProps = {
      [MPEventProperty.TRANSCODED_EVENT_UUID]: EventAnalyticsState.eventId,
      [MPEventProperty.ANALYTICS_SEGMENT]: dataSegmentType,
      [MPEventProperty.ANALYTICS_TYPE]: 'YouTube',
    };

    if (!EventAnalyticsState.eventId) {
      delete mixpanelProps[MPEventProperty.TRANSCODED_EVENT_UUID];
    }

    trackMixpanelEvent(MPEventName.ANALYTICS_SEGMENT, mixpanelProps);
  };

  const handleTimeWindowChange = (event: ChangeEvent<unknown> | null, newValue: TIME_WINDOWS) => {
    setTimeWindowValue(TIME_WINDOW_SET[newValue]);
    setTimeWindow(newValue);
  };

  const handleDataPageChange = (event: ChangeEvent<unknown>, newValue: YOUTUBE_PAGES): void => {
    setDataPage(newValue);
    setDataPageName(YOUTUBE_PAGE[newValue]);
    dispatch({ type: YOUTUBE_PAGE_STATE_CONTEXT_KEYS.PAGE, payload: YOUTUBE_PAGE[newValue] });
  };

  const handleDateRangeChange = (item: Option[]): void => {
    const selection = item[0]?.value;
    let dateRange: DateType = { startDate: '', endDate: '' };
    // Since we have only one custom handling case "c0".
    if (selection === customDateRangeOption.value) {
      setDatePickerOpen(true);
    } else if (selection === '0' && viewAllData) {
      dateRange = lifeTimeValues[selection];
      dispatchEventAnalyticsState({ type: EVENT_ANALYTICS_CONTEXT_KEYS.DATE_RANGE, payload: dateRange });
      dispatchEventAnalyticsState({ type: EVENT_ANALYTICS_CONTEXT_KEYS.DATE_RANGE_VALUE, payload: item[0] });
    } else if (selection) {
      dateRange = dateRangeValues[selection];
      dispatchEventAnalyticsState({ type: EVENT_ANALYTICS_CONTEXT_KEYS.DATE_RANGE, payload: dateRange });
      dispatchEventAnalyticsState({ type: EVENT_ANALYTICS_CONTEXT_KEYS.DATE_RANGE_VALUE, payload: item[0] });
    }
  };

  const handleCustomDateRangeSelection = (startDate: string, endDate: string) => {
    if (startDate && endDate) {
      const dateRange = {
        startDate,
        endDate,
      };
      dispatchEventAnalyticsState({ type: EVENT_ANALYTICS_CONTEXT_KEYS.DATE_RANGE, payload: dateRange });
      dispatchEventAnalyticsState({
        type: EVENT_ANALYTICS_CONTEXT_KEYS.DATE_RANGE_VALUE,
        payload: customDateRangeOption,
      });
    }
  };

  const handleChannelChange = async (channel: string) => {
    setSelectedChannel(channel);
    setChartLoading(true);

    const response = await fetchLineChartData(EventAnalyticsState, timeWindowValue, channel);
    const lineChartData =
      eventId === null
        ? parseLineChartData(response, timeWindowValue)
        : parseLineChartData(response, timeWindowValue, dataPageName);

    setChartLoading(false);
    setChartData(lineChartData);
  };

  const handleToggleDataCollection = async (isToggled: boolean) => {
    setIsTogglingDataCollection(true);

    await toggleDataCollection(EventAnalyticsState, selectedChannel, isToggled);

    setTimeout(() => {
      // fake spinner because API resolves quickly
      setIsTogglingDataCollection(false);
    }, 300);
  };

  const getChartData = async () => {
    let response: API_RESPONSE_TYPE;
    let lineChartData: lineChartDataType = { labels: [], datasets: {} };
    setChartLoading(true);
    if (dataPageName !== PAGE_VALUE.LIVE_VIEWS) {
      response = await fetchLineChartOnDemandData(EventAnalyticsState, youtubeAnalyticsState, timeWindowValue);
      lineChartData = parseLineChartData(response, timeWindowValue);
    } else {
      response = await fetchLineChartData(EventAnalyticsState, timeWindowValue, selectedChannel);
      lineChartData =
        eventId === null
          ? parseLineChartData(response, timeWindowValue)
          : parseLineChartData(response, timeWindowValue, dataPageName);
    }
    setChartLoading(false);
    setChartData(lineChartData);
  };

  const getYouTubeChannels = React.useCallback(async () => {
    setAreChannelsLoading(true);

    const data = await fetchSocialMediaAccounts(EventAnalyticsState);
    const selectOptions = data?.length ? data.map((profile) => ({ label: profile.name, value: profile.uuid })) : [];

    setAreChannelsLoading(false);
    setChannelData(selectOptions);
    !selectedChannel && selectOptions?.length > 0 && setSelectedChannel(selectOptions[0].value);
  }, []);

  const getDataCollectionToggle = React.useCallback(async () => {
    const isCollectingYoutubeData = await fetchDataCollectionToggle(EventAnalyticsState, selectedChannel);
    setIsDataCollectionOn(isCollectingYoutubeData);
  }, [selectedChannel]);

  useEffect(() => {
    getChartData();
  }, [youtubeAnalyticsState, timeWindowValue, EventAnalyticsState]);

  useEffect(() => {
    getYouTubeChannels();
  }, [getYouTubeChannels]);

  useEffect(() => {
    if (selectedChannel) {
      getDataCollectionToggle();
    }
  }, [getDataCollectionToggle, selectedChannel]);

  return (
    <YoutubeStatsGraphView
      data={chartData}
      dataPageName={dataPageName}
      dataType={dataType}
      dataPage={dataPage}
      channelOptions={channelData}
      dateRangeOptions={getDateRangeOptions(viewAllData, true)}
      isMonthTimeWindowDisabled={isMonthTimeWindowDisabled}
      isChartLoading={isChartLoading}
      isDataCollectionOn={isDataCollectionOn}
      isDatePickerOpen={isDatePickerOpen}
      isTogglingDataCollection={isTogglingDataCollection}
      hasGraphPerms={hasGraphPerms}
      areChannelsLoading={areChannelsLoading}
      selectedChannel={selectedChannel}
      timeWindow={timeWindow}
      handleChannelChange={handleChannelChange}
      handleGroupByChange={handleGroupByChange}
      handleDataPageChange={handleDataPageChange}
      handleDateRangeChange={handleDateRangeChange}
      handleTimeWindowChange={handleTimeWindowChange}
      handleCustomDateRangeSelection={handleCustomDateRangeSelection}
      handleIsDataCollectionToggled={setIsDataCollectionOn}
      toggleDataCollection={handleToggleDataCollection}
      closeDatePicker={() => setDatePickerOpen(false)}
    />
  );
};

YoutubeStatsGraph.displayName = 'YoutubeStatsGraph';

export default YoutubeStatsGraph;
