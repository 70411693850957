import React, { FC } from 'react';
import PropTypes from 'prop-types';
import { FillBlockSpinner, StatusCard } from '../../../components';
import { Grid, customProperties as vars } from '@resi-media/resi-ui';
import FacebookStatsGraph from './FacebookStatsGraph/facebook-stats-graph';
import { StatsArray } from '../types';
import { LOADER_SIZE_COMPONENT } from '../event-analytics-constants';

export interface FacebookAnalyticsViewProps {
  isCardLoading: boolean;
  isDestinationTypeFetching: boolean;
  hasGraphPerms: boolean;
  statsArray: StatsArray;
}
const FacebookAnalyticsView: FC<FacebookAnalyticsViewProps> = ({
  isCardLoading,
  isDestinationTypeFetching,
  hasGraphPerms,
  statsArray,
}): JSX.Element => {
  return (
    <Grid display="grid" gridGap={vars.spacingXl} data-testid="facebook-analytics-page">
      <Grid.Item>
        <FacebookStatsGraph isDestinationTypeFetching={isDestinationTypeFetching} hasGraphPerms={hasGraphPerms} />
      </Grid.Item>
      {isCardLoading ? (
        <FillBlockSpinner fontSize={LOADER_SIZE_COMPONENT} />
      ) : (
        <Grid display="grid" gridGap={vars.spacingXl} gridAutoColumns="1fr" gridTemplateColumns="repeat(4, 1fr)">
          {statsArray.map((item) => (
            <Grid.Item key={item.label}>
              <StatusCard
                label={item.label}
                change={item.change}
                count={item.count}
                isTrendUp={item.isTrendUp}
                data-testid="status-card"
              />
            </Grid.Item>
          ))}
        </Grid>
      )}
    </Grid>
  );
};

FacebookAnalyticsView.displayName = 'FacebookAnalyticsView';

FacebookAnalyticsView.propTypes = {
  hasGraphPerms: PropTypes.bool.isRequired,
  statsArray: PropTypes.array.isRequired,
};

export default FacebookAnalyticsView;
